const UPDATE_TOOLBOX = 'scratch-gui/toolbox/UPDATE_TOOLBOX';
const UPDATE_FLYOUT_VISIBLE = 'scratch-gui/toolbox/UPDATE_FLYOUT_VISIBLE';
const UPDATE_TOOLBOX_VISIBLE = 'scratch-gui/toolbox/UPDATE_TOOLBOX_VISIBLE';
const UPDATE_HAS_MAXIMIZED_COMMENT = 'scratch-gui/toolbox/UPDATE_HAS_MAXIMIZED_COMMENT';

import makeToolboxXML from '../lib/make-toolbox-xml';

const initialState = {
    toolboxXML: makeToolboxXML(true),
    isFlyoutVisible: true,
    isToolboxVisible: true,
    hasMaximizedComment: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case UPDATE_TOOLBOX:
            return Object.assign({}, state, {
                toolboxXML: action.toolboxXML
            });
        case UPDATE_FLYOUT_VISIBLE:
                console.log(`action.isFlyoutVisible: ${action.isFlyoutVisible}`)
            return Object.assign({}, state, {
                isFlyoutVisible: action.isFlyoutVisible
            });
        case UPDATE_TOOLBOX_VISIBLE:
            console.log(`action.isToolboxVisible: ${action.isToolboxVisible}`)
            return Object.assign({}, state, {
                isToolboxVisible: action.isToolboxVisible
            });
        case UPDATE_HAS_MAXIMIZED_COMMENT:
            console.log(`action.hasMaximizedComment: ${action.hasMaximizedComment}`)
            return Object.assign({}, state, {
                hasMaximizedComment: action.hasMaximizedComment
            });
        default:
            return state;
    }
};

const updateToolbox = function (toolboxXML) {
    return {
        type: UPDATE_TOOLBOX,
        toolboxXML: toolboxXML
    };
};

const updateFlyoutVisible = function (isFlyoutVisible) {
    return {
        type: UPDATE_FLYOUT_VISIBLE,
        isFlyoutVisible: isFlyoutVisible
    };
};

const updateToolboxVisible = function (isToolboxVisible) {
    console.log(`action.updateToolboxVisible: ${isToolboxVisible}`)
    return {
        type: UPDATE_TOOLBOX_VISIBLE,
        isToolboxVisible: isToolboxVisible
    };
};

const updateHasMaximizedComment = function (hasMaximizedComment) {
    console.log(`action.updateHasMaximizedComment: ${hasMaximizedComment}`)
    return {
        type: UPDATE_HAS_MAXIMIZED_COMMENT,
        hasMaximizedComment: hasMaximizedComment
    }
}

export {
    reducer as default,
    initialState as toolboxInitialState,
    updateToolbox,
    updateFlyoutVisible,
    updateToolboxVisible,
    updateHasMaximizedComment
};
