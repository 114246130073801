import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import {FormattedMessage} from 'react-intl';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';

import backIcon from '../../../common-svg/back-left.svg';
import helpIcon from '../../lib/assets/icon--help.svg';

import styles from './modal.css';



const ModalComponent = props => (
    <ReactModal
        isOpen
        className={classNames(props.isCustomProcedure ? styles.procedureModalContent : styles.modalContent, props.className, {
            [styles.fullScreen]: props.fullScreen
        })}
        contentLabel={props.contentLabel}
        overlayClassName={styles.modalOverlay}
        onRequestClose={props.onRequestClose}
    >
        <Box
            dir={props.isRtl ? 'rtl' : 'ltr'}
            direction="column"
            grow={1}
            uis={props.uis}
        >
            <div className={classNames(props.isCustomProcedure ? styles.procedureHeader : styles.header, props.headerClassName)}>
                {props.onHelp ? (
                    <div
                        className={classNames(
                            styles.headerItem,
                            styles.headerItemHelp
                        )}
                    >
                        <Button
                            className={styles.helpButton}
                            iconSrc={helpIcon}
                            onClick={props.onHelp}
                        >
                            <FormattedMessage
                                defaultMessage="Help"
                                description="Help button in modal"
                                id="gui.modal.help"
                            />
                        </Button>
                    </div>
                ) : null}
                <div
                    className={props.isCustomProcedure ?
                        classNames(styles.procedureHeaderItem, styles.procedureHeaderItemTitle) :
                        classNames(styles.headerItem, styles.headerItemTitle)}
                >
                    {props.headerImage ? (
                        <img
                            className={styles.headerImage}
                            src={props.headerImage}
                        />
                    ) : null}
                    {props.contentLabel}
                </div>
                {props.isCustomProcedure ?
                    null : (
                        <div
                            className={classNames(
                                styles.headerItem,
                                styles.headerItemClose
                            )}
                        >
                            <Button
                                className={styles.backButton}
                                iconSrc={backIcon}
                                onClick={props.onRequestClose}
                            >
                                <FormattedMessage
                                    defaultMessage="Back to homepage"
                                    description="Back button in modal"
                                    id="gui.modal.backHome"
                                />
                            </Button>
                        </div>
                    )
                }
            </div>
            {props.children}
        </Box>
    </ReactModal>
);

ModalComponent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    contentLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    fullScreen: PropTypes.bool,
    headerClassName: PropTypes.string,
    headerImage: PropTypes.string,
    isRtl: PropTypes.bool,
    onHelp: PropTypes.func,
    onRequestClose: PropTypes.func
};

export default ModalComponent;
