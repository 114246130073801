import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import LibraryItem from '../../containers/library-item.jsx';
import Modal from '../../containers/modal.jsx';
import TagButton from '../../containers/tag-button.jsx';
import Spinner from '../spinner/spinner.jsx';
import storage from '../../lib/storage';

import styles from './library.css';

import {
    tagColorCN,
    tagColorWW,
    tagColorVR
} from './tag-color';

import { BRAIN_TYPE } from '../../lib/brains'
import {
    isEnableEntry,
    isEnableEduAndEntry,
    getPickedBrainType
} from '../../reducers/picked-brain-type'

import { uiType } from '../../reducers/ui-style';

const messages = defineMessages({
    allTag: {
        id: 'gui.library.allTag',
        defaultMessage: 'All',
        description: 'Label for library tag to revert to all items after filtering by tag.'
    }
});

const ALL_TAG = { tag: 'all', intlLabel: messages.allTag };
let tagListPrefix = [];
let brainListPrefix = [];

/**
 * Find the AssetType which corresponds to a particular file extension. For example, 'png' => AssetType.ImageBitmap.
 * @param {string} fileExtension - the file extension to look up.
 * @returns {AssetType} - the AssetType corresponding to the extension, if any.
 */
const getAssetTypeForFileExtension = function (fileExtension) {
    const compareOptions = {
        sensitivity: 'accent',
        usage: 'search'
    };
    for (const assetTypeId in storage.AssetType) {
        const assetType = storage.AssetType[assetTypeId];
        if (fileExtension.localeCompare(assetType.runtimeFormat, compareOptions) === 0) {
            return assetType;
        }
    }
};

/**
 * Figure out an `imageSource` (URI or asset ID & type) for a library item's icon.
 * @param {object} item - either a library item or one of a library item's costumes.
 * @returns {object} - an `imageSource` ready to be passed to a `ScratchImage`.
 */
const getItemImageSource = function (item) {
    if (item.rawURL) {
        return {
            uri: item.rawURL
        };
    }

    // TODO: adjust libraries to be more storage-friendly; don't use split() here.
    const md5 = item.md5 || item.baseLayerMD5;
    if (md5) {
        const [assetId, fileExtension] = md5.split('.');
        return {
            assetId: assetId,
            assetType: getAssetTypeForFileExtension(fileExtension)
        };
    }
};

class ExampleLibraryComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleMouseEnter',
            'handleMouseLeave',
            'handleSelect',
            'handleTagClick',
            'handleBrainTagClick',
            'setFilteredDataRef'
        ]);
        brainListPrefix = [];
        tagListPrefix = [];
        brainListPrefix = brainListPrefix.concat(this.props.brainTags);
        tagListPrefix = tagListPrefix.concat(props.tags);
        this.state = {
            playingItem: null,
            selectedBrainTag: this.props.getPickedBrainType.toLowerCase(),
            selectedTag: tagListPrefix[0].tag,
            loaded: false
        };
    }
    componentDidMount() {
        // Allow the spinner to display before loading the content
        setTimeout(() => {
            this.setState({ loaded: true });
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedTag !== this.state.selectedTag ||
            prevState.selectedBrainTag !== this.state.selectedBrainTag) {
            this.scrollToTop();
        }
    }
    handleSelect(id) {
        // this.handleClose();
        console.log(id)
        console.log(this.getFilteredData()[id])
        this.props.onItemSelected(this.getFilteredData()[id]);
    }
    handleClose() {
        this.props.onRequestClose();
    }
    handleTagClick(tag) {
        this.setState({
            selectedTag: tag.toLowerCase()
        });
    }
    handleBrainTagClick(tag) {
        this.setState({
            selectedBrainTag: tag.toLowerCase()
        });
    }
    handleMouseEnter(id) {
        // don't restart if mouse over already playing item
        console.log("handleMouseEnter")
        if (this.props.onItemMouseEnter && this.state.playingItem !== id) {
            this.props.onItemMouseEnter(this.getFilteredData()[id]);
            this.setState({
                playingItem: id
            });
        }
    }
    handleMouseLeave(id) {
        console.log("handleMouseLeave")
        if (this.props.onItemMouseLeave) {
            this.props.onItemMouseLeave(this.getFilteredData()[id]);
            this.setState({
                playingItem: null
            });
        }
    }
    getFilteredData() {
        console.log("data: ", this.props.data)
        if (this.state.selectedTag === 'all') {
            return this.props.data.filter(dataItem => (
                dataItem.brainType &&
                dataItem.brainType
                    .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                    .indexOf(this.state.selectedBrainTag) !== -1
            ));
        }
        if (this.state.selectedBrainTag === 'all') {
            return this.props.data.filter(dataItem => (
                dataItem.tags &&
                dataItem.tags
                    .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                    .indexOf(this.state.selectedTag) !== -1
            ));
        }
        return this.props.data.filter(dataItem => (
            dataItem.tags &&
            dataItem.tags
                .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                .indexOf(this.state.selectedTag) !== -1 &&
            dataItem.brainType &&
            dataItem.brainType
                .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                .indexOf(this.state.selectedBrainTag) !== -1
        ));
    }
    scrollToTop() {
        this.filteredDataRef.scrollTop = 0;
    }
    setFilteredDataRef(ref) {
        this.filteredDataRef = ref;
    }
    render() {
        let tagColorArray = tagColorCN;
        if (this.props.uis == uiType.ww) {
            tagColorArray = tagColorWW;
        } else if (this.props.uis == uiType.vr) {
            tagColorArray = tagColorVR;
        }

        return (
            <Modal
                fullScreen
                contentLabel={this.props.title}
                id={this.props.id}
                onRequestClose={this.handleClose}
                uis={this.props.uis}
            >
                <div className={styles.exampleFullContent}>
                    {
                        this.props.uis == uiType.vr ? null : (
                            <div className={styles.filterBrain}>
                                {brainListPrefix.map((tagProps, id) => {
                                    console.log("tagProps: ", tagProps)
                                    console.log("id: ", id)
                                    console.log("this.state.selectedBrainTag: ", this.state.selectedBrainTag)
                                    let enableTag = true;
                                    if ((tagProps.tag == BRAIN_TYPE.ENTRY.toLowerCase() && !this.props.isEnableEntry) ||
                                        (tagProps.tag == BRAIN_TYPE.EDU_AND_ENTRY.toLowerCase() && !this.props.isEnableEduAndEntry)) {
                                        enableTag = false;
                                    }

                                    return (
                                        <TagButton
                                            className={classNames(styles.exampleBrainTag, enableTag ? null : styles.disable)}
                                            active={this.state.selectedBrainTag === tagProps.tag.toLowerCase()}
                                            contentStyle={classNames(styles.exampleBrainTag, styles.content)}
                                            activeStyle={styles.exampleBrainTagActive}
                                            key={`brain-tag-button-${id}`}
                                            onClick={this.handleBrainTagClick}
                                            {...tagProps}
                                        />
                                    )
                                })}
                            </div>
                        )
                    }
                    <div className={styles.exampleContent}>
                        {
                            (
                                <div className={styles.filterBar}>
                                    {tagListPrefix.map((tagProps, id) => {
                                        console.log("filterBar tagProps: ", tagProps)
                                        console.log("id: ", id)
                                        console.log("this.state.selectedTag: ", this.state.selectedTag)
                                        const tagCircleStyle = {
                                            width: "22px",
                                            height: "22px",
                                            borderRadius: "15px",
                                            boxShadow: "0 2px 3px 0 rgba(56, 132, 157, 0.5)",
                                            backgroundImage: tagColorArray[tagProps.tag].color,
                                            marginLeft: "44px",
                                            marginRight: "24px",
                                        };
                                        return (
                                            <TagButton
                                                className={styles.exampleCatagoryTag}
                                                active={this.state.selectedTag === tagProps.tag.toLowerCase()}
                                                circleStyle={tagCircleStyle}
                                                key={`tag-button-${id}`}
                                                onClick={this.handleTagClick}
                                                {...tagProps}
                                            />
                                        )
                                    })}
                                </div>
                            )
                        }
                        <div
                            className={styles.libraryScrollGrid}
                            ref={this.setFilteredDataRef}
                        >
                            {(
                                <div className={styles.exampleTitle}>
                                    <FormattedMessage {...tagListPrefix[tagListPrefix.findIndex(obj => obj.tag == this.state.selectedTag)].intlLabel} />
                                </div>
                            )
                            }
                            <div className={styles.exampleContentGrid}>
                                {this.state.loaded ? this.getFilteredData().map((dataItem, index) => {
                                    const iconSource = getItemImageSource(dataItem);
                                    const icons = dataItem.json && dataItem.json.costumes.map(getItemImageSource);
                                    let tagIcon = tagColorArray[this.state.selectedTag].tagIcon;
                                    if (this.state.selectedTag == 'all') {
                                        tagIcon = tagColorArray[dataItem.mainTag].tagIcon;
                                    }
                                    console.log(typeof dataItem.name === 'string' ? dataItem.name : index)
                                    console.log(dataItem)
                                    return (
                                        <LibraryItem
                                            bluetoothRequired={dataItem.bluetoothRequired}
                                            collaborator={dataItem.collaborator}
                                            description={dataItem.description}
                                            disabled={dataItem.disabled}
                                            extensionId={dataItem.extensionId}
                                            featured={dataItem.featured}
                                            hidden={dataItem.hidden}
                                            icon={dataItem.icon}
                                            level={dataItem.level}
                                            tagIcon={tagIcon}
                                            id={index}
                                            insetIconURL={dataItem.insetIconURL}
                                            internetConnectionRequired={dataItem.internetConnectionRequired}
                                            isPlaying={this.state.playingItem === index}
                                            key={typeof dataItem.name === 'string' ? dataItem.name : index}
                                            name={dataItem.name}
                                            showPlayButton={this.props.showPlayButton}
                                            onMouseEnter={this.handleMouseEnter}
                                            onMouseLeave={this.handleMouseLeave}
                                            onSelect={this.handleSelect}
                                            uis={this.props.uis}
                                        />);
                                }) : (
                                    <div className={styles.spinnerWrapper}>
                                        <Spinner
                                            large
                                            level="primary"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

ExampleLibraryComponent.propTypes = {
    data: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            md5: PropTypes.string,
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]),
            rawURL: PropTypes.string
        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    id: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onItemMouseEnter: PropTypes.func,
    onItemMouseLeave: PropTypes.func,
    onItemSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    setStopHandler: PropTypes.func,
    showPlayButton: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.shape(TagButton.propTypes)),
    brainTags: PropTypes.arrayOf(PropTypes.shape(TagButton.propTypes)),
    title: PropTypes.string.isRequired,
    uis: PropTypes.string
};

ExampleLibraryComponent.defaultProps = {
    showPlayButton: false
};

const mapStateToProps = state => ({
    isEnableEntry: isEnableEntry(state),
    isEnableEduAndEntry: isEnableEduAndEntry(state),
    getPickedBrainType: getPickedBrainType(state),
});

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExampleLibraryComponent));