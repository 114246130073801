import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import visionSettingStyles from './vision-setting-dialog.css'
import { DetectedNormalTag, TAG_TYPE } from './tag.jsx'

import log from '../../../lib/log';
import {
    isVisionConnected,
    isVisionInitiated,
    isVisionRecongInitiated,
    getImageTagList,
    setImageTagList
} from '../../../reducers/vision.js'
import loadingImg from '../svg/loading.svg'

class VisionFace extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        // this.state = {
        //     tagList: this.props.getImageTagList,
        // }
    }


    componentDidMount() {
        if (this.props.isVisionDistribute) {
            // this.refreshTagList();
            this.props.startVisionRealtime('VisionFace.componentDidMount');
        }
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.getImageTagList != this.props.getImageTagList) {
        //     this.refreshTagList();
        // }
        if (prevProps.isVisionInitiated != this.props.isVisionInitiated) {
            if (this.props.isVisionConnected == true) {
                if (this.props.isVisionInitiated == true) {
                    his.props.startVisionRealtime('VisionFace.componentDidUpdate prevProps.isVisionInitiated != this.props.isVisionInitiated');
                } else {
                    this.props.initVision();
                    this.clearImage();
                }
            } else {
                this.clearImage();
            }
        }
    }
    clearImage() {
        this.props.clearImageTagList();
        // this.setState({ tagList: [] });
    }


    createTagLayout() {
        if (!this.props.getImageTagList || !Array.isArray(this.props.getImageTagList) || this.props.getImageTagList.length < 1) return null;
        return this.getTagList().map((data, index) =>
            <DetectedNormalTag
                key={index}
                detectedTag={data}
                imageWidth={this.props.imageWidth}
                imageHeight={this.props.imageHeight}
            />
        )
    }

    // refreshTagList() {
    //     const list = this.props.getImageTagList;
    //     const isSkip = !this.props.isVisionDistribute || !list || !Array.isArray(list) || (list.length > 0 && list[0].type !== TAG_TYPE.Face)
    //     this.setState({ tagList: isSkip ? [] : list });
    // }

    getTagList() {
        const list = this.props.getImageTagList;
        const isSkip = !this.props.isVisionDistribute || !list || !Array.isArray(list) || (list.length > 0 && list[0].type !== TAG_TYPE.Face)
        return isSkip ? [] : list;
    }

    render() {
        return (
            <div className={classNames(visionSettingStyles.visionContentArea)}>
                <div className={classNames(visionSettingStyles.visionArea)}>
                    {/* <div className={classNames(visionSettingStyles.visionBarArea)}>
                    </div> */}
                    <div className={classNames(visionSettingStyles.visionImgArea)}>
                        {(this.props.isVisionDistribute && this.props.realtimeImage) ? <img src={this.props.realtimeImage} id={'visionImg'}
                            className={classNames(visionSettingStyles.visionImgSize)} /> :
                            <div className={classNames(visionSettingStyles.visionDisconnected)}>
                                <div className={classNames(visionSettingStyles.visionDisconnectedText)}>
                                    {this.props.getVisionInitStateString && this.props.getVisionInitStateString != "" ? this.props.getVisionInitStateString :
                                        <img src={loadingImg} className={classNames(visionSettingStyles.loading, visionSettingStyles.main)} />}
                                </div>
                            </div>}

                    </div>
                    <div className={classNames(visionSettingStyles.visionImgArea)}>
                        <div className={classNames(visionSettingStyles.relativeArea)}>
                            {this.createTagLayout()}
                            {/* <DetectedColorTag /> */}
                        </div>
                    </div>
                </div >
                <div className={classNames(visionSettingStyles.visionInfoArea)}>

                    <div className={classNames(visionSettingStyles.visionDetectionResultTitle)} >
                        <div className={classNames(visionSettingStyles.visionPoint)} />
                        <FormattedMessage
                            id="gui.dialog.vision.face.detection.result.title"
                        />
                    </div>

                    <div className={classNames(visionSettingStyles.qrcodeDetectionResult, this.props.isVisionDistribute ? null : visionSettingStyles.disable)} >
                        {(this.props.isVisionDistribute) ?
                            (this.props.getImageTagList && Array.isArray(this.props.getImageTagList) && this.props.getImageTagList.length > 0 && this.props.getImageTagList[0].type == TAG_TYPE.Face) ?
                                <FormattedMessage
                                    id="gui.dialog.vision.face.detection.result"
                                    values={{ number: this.props.getImageTagList.length }}
                                /> :
                                <FormattedMessage
                                    id="gui.dialog.vision.face.detection.none"
                                />
                            : <FormattedMessage
                                id="gui.dialog.vision.face.detection.none"
                            />}
                    </div>
                </div >

            </div >
        );
    }
}

VisionFace.propTypes = {
};

const mapStateToProps = state => ({
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state) && isVisionRecongInitiated(state),
    getImageTagList: getImageTagList(state)
});

const mapDispatchToProps = dispatch => ({
    clearImageTagList: () => dispatch(setImageTagList([]))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisionFace);