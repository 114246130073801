import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import dialogStyles from '../dialog.css';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import log from '../../../lib/log';
import { EditUtils } from '../../device-manager/edit-page/edit-utils';

import {
    showErrorDialog,
    errorType,
    hideSpeakerConceptDialog,
} from '../../../reducers/dialog';
import {
    getWorkspace
} from '../../../reducers/block';
import {
    setImportConcepts,
    isConceptsImported,
    SPEAKER_DIALOG_ACTION,
    setTempConcepts,
    getTempConcepts,
    clearTempConcepts
} from '../../../reducers/speaker';
import { uiType, getUIStyle } from '../../../reducers/ui-style';
import styles from './speaker-dialog.css'
import VM from 'scratch-vm';
import deleteIcon from '../svg/delete_concept.svg'
import importIcon from '../svg/csv_import.svg'
import exportIcon from '../svg/csv_export.svg'
import importIconWW from '../svg/ww/csv_import.svg'
import exportIconWW from '../svg/ww/csv_export.svg'

import { importXLSX, IMPORT_ERROR } from '../../../lib/import-xlsx';
import exportXLSX from '../../../lib/export-xlsx';
import { CONCEPTS_LIMIT } from '../../gui/speaker-import.js'
import { variableRegSp } from '../../../lib/variable-utils';
const TITLE_HEIGHT = 58

const DIALOG_WIDTH = 400;
const DIALOG_HEIGHT = 595;
const DIALOG_EDIT_MIN_HEIGHT = 300;

const conceptFilePath = './static/aiSpeaker';
const conceptFileName = 'example.xlsx';
const SYMBOLS_NOT_ALLOWED = 'SYMBOLS_NOT_ALLOWED';
const NAME_REQUIRED = 'NAME_REQUIRED';

let resizeFunc = () => { };
class SpeakerConceptDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = {
            addConcept: {
                tag: "",
                value: new Array(10).fill("")
            },
            editConceptList: [],
            translateX: (window.innerWidth - DIALOG_WIDTH) / 2,
            translateY: (window.innerHeight - this.getDialogHeigth()) / 2,
        }
    }

    componentDidMount() {
        this.reloadData();
        this.props.setTempConcepts(this.parseListFromVm());
        resizeFunc = e => {
            this.setState({
                translateX: (window.innerWidth - DIALOG_WIDTH) / 2,
                translateY: (window.innerHeight - this.getDialogHeigth()) / 2,
            })
        }
        window.addEventListener('resize', resizeFunc);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', resizeFunc);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.editConceptList != this.state.editConceptList || prevProps.show != this.props.show) {
            this.setState({
                translateX: (window.innerWidth - DIALOG_WIDTH) / 2,
                translateY: (window.innerHeight - this.getDialogHeigth()) / 2,
            })
        }
        if (prevProps.isConceptsImported != this.props.isConceptsImported && this.props.isConceptsImported == false) {
            this.reloadData();
        }
    }

    getDialogHeigth() {
        return (this.props.action == SPEAKER_DIALOG_ACTION.add)
            || (this.state && Array.isArray(this.state.editConceptList) && this.state.editConceptList.length > 0)
            ? DIALOG_HEIGHT : DIALOG_EDIT_MIN_HEIGHT
    }



    parseListFromVm() {
        let vmConcepts = Array.from(this.props.vm.getSpeakerConcepts());
        let concepts = [];
        vmConcepts.forEach(concept => concepts.push({ id: concept.id, tag: concept.tag, value: concept.value }))
        concepts.forEach(concept => {
            while (concept.value.length < 10) {
                concept.value.push("")
            }
        })
        return concepts
    }


    getTitle() {
        if (this.props.action == SPEAKER_DIALOG_ACTION.add) {
            return <FormattedMessage id={"gui.dialog.speaker.concept.title.add"} />
        } else if (this.props.action == SPEAKER_DIALOG_ACTION.edit) {
            return <FormattedMessage id={"gui.dialog.speaker.concept.title.edit"} />
        }
    }

    getContent() {
        if (this.props.action == SPEAKER_DIALOG_ACTION.add) {
            return this.getAddContent();
        } else if (this.props.action == SPEAKER_DIALOG_ACTION.edit) {
            return this.getEditContent();
        }
    }

    getAddContent() {
        return <div className={classNames(styles.conceptContentArea)}>
            <ConceptEdit
                action={SPEAKER_DIALOG_ACTION.add}
                list={this.state.addConcept.value}
                index={1}
                name={this.state.addConcept.tag}
                onConceptGroupNameChange={(event) => this.onConceptGroupNameChange(event)}
                onWordChange={(event, index) => this.onWordChange(event, index)}
                isNameContainSpace={(name) => this.isNameContainSpace(name)}
                isNameContainSp={(name) => this.isNameContainSp(name)}
                isConceptNameDuplicate={(name) => this.isNewConceptNameDuplicate(name)}
            />
        </div>
    }

    onWordChange(event, index) {
        let list = this.state.addConcept.value;
        list[index] = event.target.value;
        this.setAddWordList(list);
    }

    setAddWordList(list) {
        this.setState({
            addConcept: {
                tag: this.state.addConcept.tag,
                value: list
            }
        })
    }

    isNewConceptNameDuplicate(name) {
        const concepts = Array.from(this.props.vm.getSpeakerConcepts());
        return name != "" && concepts.filter(concept => concept.tag == name).length > 0;
    }

    isEditConceptNameDuplicate(name, index) {
        const concepts = Array.from(this.state.editConceptList);
        return name != "" && concepts.filter((concept, i) => concept.tag == name && i != index).length > 0;
    }

    onConceptGroupNameChange(event) {
        this.setState({
            addConcept: {
                tag: event.target.value,
                value: this.state.addConcept.value
            }
        })
    }


    getEditContent() {
        const list = Array.from(this.state.editConceptList);
        return <div className={classNames(styles.conceptContentArea)}>
            <div className={classNames(styles.conceptFileManagerArea)}>
                <div className={classNames(styles.conceptFileManagerButton)}
                    onClick={() => this.onClickImport()}>
                    <img
                        src={this.props.getUIStyle == uiType.ww ? importIconWW : importIcon}
                        className={classNames(styles.conceptFileIcon)} />
                    <div className={classNames(styles.conceptFileText)}>
                        <FormattedMessage id={"gui.dialog.speaker.concept.import"} />
                    </div>
                </div>
                <div className={classNames(styles.conceptFileDivide)} />

                <div className={classNames(styles.conceptFileManagerButton)}
                    onClick={() => this.onClickExport()}>
                    <img
                        src={this.props.getUIStyle == uiType.ww ? exportIconWW : exportIcon}
                        className={classNames(styles.conceptFileIcon)} />
                    <div className={classNames(styles.conceptFileText)}>
                        <FormattedMessage id={"gui.dialog.speaker.concept.export"} />
                    </div>
                </div>
            </div>

            {(list.length == 0) ?
                <div className={classNames(styles.conceptEmptyText)}>
                    <FormattedMessage id={"gui.dialog.speaker.concept.empty"} />
                </div>
                : <div>{list.map((concept, conceptIndex) => {
                    return <div key={"concept" + conceptIndex}>
                        <ConceptEdit
                            action={SPEAKER_DIALOG_ACTION.edit}
                            index={conceptIndex}
                            list={this.state.editConceptList[conceptIndex].value}
                            name={this.state.editConceptList[conceptIndex].tag}
                            onConceptGroupNameChange={(event) => this.onEditGroupName(event, conceptIndex)}
                            onWordChange={(event, wordIndex) => this.onEditGroupWord(event, wordIndex, conceptIndex)}
                            isConceptNameDuplicate={(name) => this.isEditConceptNameDuplicate(name, conceptIndex)}
                            isNameContainSpace={(name) => this.isNameContainSpace(name)}
                            isNameContainSp={(name) => this.isNameContainSp(name, conceptIndex)}
                            onClickRemoveConcept={() => this.onClickRemoveConcept(conceptIndex)}
                        />
                        {(conceptIndex < list.length - 1) ?
                            <div className={classNames(styles.conceptDivide)} />
                            : null}
                    </div>
                })}
                </div>
            }

        </div >
    }

    onClickImport() {
        this.props.vm.setSpeakerConcepts(this.state.editConceptList);
        /*
        // worksheet:
        // C1: {t: "s", v: "字詞集合名稱", r: "<t>字詞集合名稱</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>", h: "字詞集合名稱", w: "字詞集合名稱"}
        // C2: {t: "s", v: "地點", r: "<t>地點</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>", h: "地點", w: "地點"}
        // C3: {t: "s", v: "水果", r: "<t>水果</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>", h: "水果", w: "水果"}
        // C4: {t: "s", v: "字詞集合名稱", r: "<t>字詞集合名稱</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>", h: "字詞集合名稱", w: "字詞集合名稱"}
        // C5: {t: "n", v: 123, w: "123"}
        // C6: {t: "n", v: 111, w: "111"}
        // D1: {t: "s", v: "字詞內容", r: "<t>字詞內容</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>", h: "字詞內容", w: "字詞內容"}
        // D2: {t: "s", v: "台北", r: "<t>台北</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>", h: "台北", w: "台北"}
        // D3: {t: "s", v: "蘋果", r: "<t>蘋果</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>", h: "蘋果", w: "蘋果"}
        // D4: {t: "s", v: "字詞01", r: "<t>字詞01</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>", h: "字詞01", w: "字詞01"}
        // D5: {t: "n", v: 456, w: "456"}
        // D6: {t: "n", v: 222, w: "222"}
        // E2: {t: "s", v: "上海", r: "<t>上海</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>", h: "上海", w: "上海"}
        // E3: {t: "s", v: "香蕉", r: "<t>香蕉</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>", h: "香蕉", w: "香蕉"}
        // E4: {t: "s", v: "字詞02", r: "<t>字詞02</t>", h: "字詞02", w: "字詞02"}
        // E5: {t: "n", v: 789, w: "789"}
        // E6: {t: "n", v: 333, w: "333"}
        */
        let defaultNameList = this.getConceptDefaultNameList();
        let titleNum = 5;
        let refColumns = ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M']
        importXLSX().then(worksheet => {
            let concepts = [];
            for (let i = titleNum; i < (CONCEPTS_LIMIT + titleNum); i++) {
                let conceptTagKey = refColumns[0] + i;
                let values = [];
                let conceptValueKey;
                for (let j = 1; j < refColumns.length; j++) {
                    conceptValueKey = refColumns[j] + i;
                    if (worksheet[conceptValueKey] && worksheet[conceptValueKey].v) {
                        values.push(worksheet[conceptValueKey].v.toString());
                    }
                }
                if (values.length > 0) {
                    let tagName = defaultNameList[0];
                    if (worksheet[conceptTagKey] && worksheet[conceptTagKey].v) {
                        tagName = worksheet[conceptTagKey].v;
                    } else {
                        defaultNameList.shift();
                    }

                    concepts.push({
                        id: null,
                        tag: tagName.toString(),
                        value: values
                    })
                }
            }
            this.props.setImportConcepts(concepts);
        }).catch((error) => {
            log.error('catch, import XLSX is not valid', error);
            if (error == IMPORT_ERROR.invalid_xml_sheet) {
                this.props.onShowErrorDialog(errorType.XLSX_SHEETNAME_INVALID);
            } else {
                this.props.onShowErrorDialog(errorType.XLSX_FILE_INVALID);
            }
            //dialog
        });
    }

    onClickExport() {
        exportXLSX(`${conceptFilePath}/${this.props.locale}/${conceptFileName}`, conceptFileName);
    }

    isConfirmEnable() {
        if (this.props.action == SPEAKER_DIALOG_ACTION.add) {
            if (this.state.addConcept.value.filter(c => c == "").length == 10) return false;
            if (this.isNameDuplicate(this.state.addConcept.tag) || this.isNameContainSpace(this.state.addConcept.tag)) return false
            let values = this.state.addConcept.value
            for (let i = 0; i < values.length; i++) {
                if (this.isNameContainSp(values[i])) return false;
            }
        } else {
            let list = this.state.editConceptList
            for (let i = 0; i < list.length; i++) {
                let values = list[i].value;
                if (values.filter(c => c == "").length == 10) return false;
                if (this.isNameDuplicate(list[i].tag, list[i].id) || this.isNameContainSpace(list[i].tag)) return false;
                for (let i = 0; i < values.length; i++) {
                    if (this.isNameContainSp(values[i])) return false;
                }
            }
        }
        return true;
    }

    isNameContainSp(word) {
        // check only letter, number and down line
        let invalid = false;
        let spReg = /^[A-Za-z0-9\u4e00-\u9fa5]$/;
        for (let i = 0; i < word.length; i++) {
            if (!spReg.test(word[i])) {
                invalid = true;
            }
        }
        return invalid;
    }

    isNameContainSpace(word) {
        // check only letter, number and down line
        let spaceReg = /\s/;
        return spaceReg.test(word);
    }

    isNameDuplicate(name, id) {
        if (this.props.action == SPEAKER_DIALOG_ACTION.add) {
            return this.isNewNameDuplicate(name)
        } else if (this.props.action == SPEAKER_DIALOG_ACTION.edit) {
            return this.isEditNameDuplicate(name, id)
        }
    }

    isNewNameDuplicate(name) {
        const concepts = Array.from(this.props.vm.getSpeakerConcepts());
        return name != "" && concepts.filter(concept => concept.tag == name).length > 0;
    }

    isEditNameDuplicate(name, id) {
        const concepts = Array.from(this.props.vm.getSpeakerConcepts());
        return name != "" && concepts.filter((concept) => concept.tag == name && concept.id != id).length > 0;
    }

    onClickRemoveConcept(index) {
        let list = Array.from(this.state.editConceptList);
        list.splice(index, 1);
        this.setEditList(list);
    }

    onEditGroupName(event, conceptIndex) {
        let list = Array.from(this.state.editConceptList);
        list[conceptIndex].tag = event.target.value;
        this.setEditList(list)
    }

    onEditGroupWord(event, wordIndex, conceptIndex) {
        let list = Array.from(this.state.editConceptList);
        list[conceptIndex].value = Array.from(this.state.editConceptList[conceptIndex].value);
        list[conceptIndex].value[wordIndex] = event.target.value;
        this.setEditList(list);
    }

    setEditList(list) {
        this.setState({ editConceptList: list });
    }

    reloadData() {
        if (this.props.action == SPEAKER_DIALOG_ACTION.add) {
            this.initAddConcept()
        } else if (this.props.action == SPEAKER_DIALOG_ACTION.edit) {
            this.loadEditConcepts();
        }
    }

    initAddConcept() {
        const emptyList = new Array(10).fill("")
        this.setState({
            addConcept: {
                tag: "",
                value: emptyList
            }
        })
        this.setState({ editConceptList: [] });
    }

    loadEditConcepts() {
        this.setState({ editConceptList: this.parseListFromVm() })
    }

    onClickCancel() {
        const tempConcepts = this.props.getTempConcepts;
        this.setEditList(tempConcepts)
        this.props.vm.setSpeakerConcepts(tempConcepts);
        this.reloadData();
        this.props.hideSpeakerConceptDialog();
    }

    getConceptDefaultNameList() {
        const vmConcepts = this.parseListFromVm();
        let vmConceptNameList = vmConcepts.map(concept => concept.tag)
        const defaultName = EditUtils.getLocaleString("gui.dialog.speaker.concept");
        let defaultNameList = [];
        for (let i = 1; i <= (CONCEPTS_LIMIT * 2); i++) {
            if (!vmConceptNameList.includes(defaultName + i)) {
                defaultNameList.push(defaultName + i);
            }
        }
        return defaultNameList
    }

    onClickConfirm() {
        let defaultNameList = this.getConceptDefaultNameList();
        if (this.props.action == SPEAKER_DIALOG_ACTION.add) {
            let list = this.state.addConcept.value;
            list = list.filter(word => word != "")
            let conceptName = this.state.addConcept.tag
            if (!conceptName || conceptName == "") conceptName = defaultNameList[0];
            this.props.vm.addSpeakerConcept(conceptName, list);
        } else if (this.props.action == SPEAKER_DIALOG_ACTION.edit) {
            let concepts = this.state.editConceptList;
            concepts.forEach(concept => {
                if (!concept.tag || concept.tag == "") {
                    concept.tag = defaultNameList[0];
                    defaultNameList.shift();
                }
                concept.value = concept.value.filter(word => word != "")
            });
            this.props.vm.setSpeakerConcepts(concepts);
            this.props.setTempConcepts(concepts);
        }
        this.props.getWorkspace.refreshToolboxSelection_();
        this.props.hideSpeakerConceptDialog();
    }

    render() {
        return (
            <div className={classNames(dialogStyles.backdropStyle)}>
                <div className={classNames(dialogStyles.floatStyle)}
                    style={{ top: this.state.translateY + 'px', left: this.state.translateX + 'px' }}>
                    <div
                        id={"dialog_height"}
                        className={classNames(dialogStyles.floatModalStyle)}
                        style={{
                            width: DIALOG_WIDTH + "px",
                            height: this.getDialogHeigth() + "px"
                        }}>
                        <div
                            className={classNames(dialogStyles.helpHeader)}
                            style={{ height: TITLE_HEIGHT + "px", padding: 0 + "px" }}
                        >
                            <div className={classNames(styles.speakerTitle, styles.speakerConceptTitle)}
                                style={{ height: TITLE_HEIGHT + "px" }}>
                                {this.getTitle()}
                            </div>
                        </div>
                        {this.getContent()}
                        <div className={classNames(styles.cancelButton, styles.conceptCancelButton)}
                            onClick={() => this.onClickCancel()}>
                            <FormattedMessage
                                id={'gui.deviceManagerStage.page.cancel'}
                            />
                        </div>
                        <div className={classNames(styles.confirmButton, styles.conceptConfirmButton, (this.isConfirmEnable()) ? null : styles.disableConfirm)}
                            onClick={() => { if (this.isConfirmEnable()) this.onClickConfirm() }
                            }>
                            <FormattedMessage
                                id={"gui.dialog.confirm"}
                            />
                        </div>
                    </div >
                </div >
            </div >
        );
    }
}

const ConceptEdit = (props) => {
    const {
        list,
        name,
        onConceptGroupNameChange,
        onWordChange,
        action,
        index,
        isConceptNameDuplicate,
        isNameContainSpace,
        isNameContainSp,
        onClickRemoveConcept
    } = props
    const isEditMode = action == SPEAKER_DIALOG_ACTION.edit
    return <div>
        <div className={classNames(styles.conceptNameArea)}>
            <div className={classNames(styles.conceptItemTitle)}>
                {isEditMode ? (index + 1) + ". " : null}
                <FormattedMessage id={"gui.dialog.speaker.concept.name"} />
            </div>
            {isEditMode ?
                <div className={classNames(styles.conceptDeleteArea)}
                    onClick={() => onClickRemoveConcept()}>
                    <img src={deleteIcon} className={classNames(styles.conceptDeleteIcon)} />
                    <div className={classNames(styles.conceptDeleteText)}>
                        <FormattedMessage id={"gui.dialog.speaker.concept.name.delete"} />
                    </div>
                </div> : null}
            <div className={classNames(styles.inputSpeakerArea,
                styles.inputConceptName,
                (isConceptNameDuplicate(name) || isNameContainSpace(name)) ?
                    styles.inputSpeakerAreaError : null)}>
                <input
                    type="text"
                    autoComplete="off"
                    name="intent"
                    value={name}
                    placeholder={EditUtils.getLocaleString("gui.dialog.speaker.concept.enter.name")}
                    maxLength="20"
                    onChange={e => onConceptGroupNameChange(e)}
                    className={classNames(styles.inputSpeaker)}
                />
            </div>
            {isConceptNameDuplicate(name) ? <div className={classNames(styles.inputConceptNameReminder)}>
                <FormattedMessage id={"gui.dialog.speaker.concept.name.reminder.duplicate"} />
            </div> : isNameContainSpace(name) ? <div className={classNames(styles.inputConceptNameReminder)}>
                <FormattedMessage id={"gui.dialog.speaker.concept.name.reminder.blank.word"} />
            </div> : null}
        </div>
        <div className={classNames(styles.conceptWordsArea)}>
            <div className={classNames(styles.conceptItemTitle)}>
                <FormattedMessage id={"gui.dialog.speaker.concept.content"} />
            </div>
            <div>
                {list.map((word, i) => {
                    return <div className={classNames(styles.conceptWordArea)} key={"intent" + i}>
                        <div className={classNames(styles.inputSpeakerArea, styles.inputConceptName, (isNameContainSp(word)) ? styles.inputSpeakerAreaError : null)}>
                            <div className={classNames(styles.wordIndex)}>{i + 1}</div>
                            <div className={classNames(styles.wordDivide)} />
                            <input
                                type="text"
                                autoComplete="off"
                                name="intent"
                                value={word}
                                placeholder={EditUtils.getLocaleString("gui.dialog.speaker.concept.enter.concept")}
                                maxLength="20"
                                onChange={e => onWordChange(e, i)}
                                className={classNames(styles.inputSpeaker, styles.inputConceptWord)}
                            />
                        </div>
                        {isNameContainSpace(word) ? <div className={classNames(styles.inputConceptNameReminder)}>
                            <FormattedMessage id={"gui.dialog.speaker.concept.name.reminder.blank.word"} /></div>
                            : isNameContainSp(word) ? <div className={classNames(styles.inputConceptNameReminder)}>
                                <FormattedMessage id={"gui.dialog.speaker.concept.name.reminder.special.word"} /></div> : null}
                    </div>
                })}
            </div>
        </div>
    </div>
}

SpeakerConceptDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
    action: PropTypes.string.isRequired,
    getWorkspace: PropTypes.object,
    locale: PropTypes.string
};

const mapStateToProps = state => ({
    locale: state.locales.locale,
    getWorkspace: getWorkspace(state),
    isConceptsImported: isConceptsImported(state),
    getUIStyle: getUIStyle(state),
    getTempConcepts: getTempConcepts(state)
});

const mapDispatchToProps = dispatch => ({
    hideSpeakerConceptDialog: () => dispatch(hideSpeakerConceptDialog()),
    setImportConcepts: (concepts) => dispatch(setImportConcepts(concepts)),
    onShowErrorDialog: (errorType, msg) => dispatch(showErrorDialog(errorType, msg)),
    setTempConcepts: (concepts) => dispatch(setTempConcepts(concepts)),
    clearTempConcepts: () => dispatch(clearTempConcepts()),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpeakerConceptDialog);