import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import styles from './brain.css';
import pwmImg from '../pictures/port_edu_pwm.svg'
import adcImg from '../pictures/port_edu_adc.svg'
import pwmHoverImg from '../pictures/port_edu_pwm_mouseover.svg'
import adcHoverImg from '../pictures/port_edu_adc_mouseover.svg'
import b1Line1Img from '../pictures/brain-line/b1_line1.svg'
import b1Line2Img from '../pictures/brain-line/b1_line2.svg'
import b1Line3Img from '../pictures/brain-line/b1_line3.svg'
import b1Line4Img from '../pictures/brain-line/b1_line4.svg'
import b1Line5Img from '../pictures/brain-line/b1_line5.svg'
import b1Line6Img from '../pictures/brain-line/b1_line6.svg'
import b1Line7Img from '../pictures/brain-line/b1_line7.svg'
import b1Line8Img from '../pictures/brain-line/b1_line8.svg'

import {
    getHoveredConnectedDevice,
    isConnectedDeviceHovered
} from '../../../reducers/select-option'

class EduLine extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }

    isConnectedDeviceHovered(port) {
        let isConnectedDeviceHovered = this.props.isConnectedDeviceHovered;
        if (isConnectedDeviceHovered) {
            let hoveredArray = this.props.getHoveredConnectedDevice;
            if (hoveredArray && hoveredArray.length > 0) {
                isConnectedDeviceHovered = hoveredArray.includes(port);
            }
        }
        return isConnectedDeviceHovered;
    }

    render() {
        const {
        } = this.props;
        return (
            <div>
                <div className={classNames(styles.lineEdu1, this.isConnectedDeviceHovered('A1') ? styles.lineSelected : null)}><img src={b1Line1Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEdu2, this.isConnectedDeviceHovered('A2') ? styles.lineSelected : null)}><img src={b1Line2Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEdu3, this.isConnectedDeviceHovered('A3') ? styles.lineSelected : null)}><img src={b1Line3Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEdu4, this.isConnectedDeviceHovered('A4') ? styles.lineSelected : null)}><img src={b1Line4Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEdu5, this.isConnectedDeviceHovered('B4') ? styles.lineSelected : null)}><img src={b1Line5Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEdu6, this.isConnectedDeviceHovered('B3') ? styles.lineSelected : null)}><img src={b1Line6Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEdu7, this.isConnectedDeviceHovered('B2') ? styles.lineSelected : null)}><img src={b1Line7Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEdu8, this.isConnectedDeviceHovered('B1') ? styles.lineSelected : null)}><img src={b1Line8Img} alt={"line"} /></div>
                <div className={classNames(styles.portImg1, styles.portImgPosition)}><img src={this.isConnectedDeviceHovered('B1') ? adcHoverImg : adcImg} alt={"port image"} /></div>
                <div className={classNames(styles.portImg2, styles.portImgPosition)}><img src={this.isConnectedDeviceHovered('B2') ? adcHoverImg : adcImg} alt={"port image"} /></div>
                <div className={classNames(styles.portImg3, styles.portImgPosition)}><img src={this.isConnectedDeviceHovered('B3') ? adcHoverImg : adcImg} alt={"port image"} /></div>
                <div className={classNames(styles.portImg4, styles.portImgPosition)}><img src={this.isConnectedDeviceHovered('B4') ? adcHoverImg : adcImg} alt={"port image"} /></div>
                <div className={classNames(styles.portImg5, styles.portImgPosition)}><img src={this.isConnectedDeviceHovered('A4') ? pwmHoverImg : pwmImg} alt={"port image"} /></div>
                <div className={classNames(styles.portImg6, styles.portImgPosition)}><img src={this.isConnectedDeviceHovered('A3') ? pwmHoverImg : pwmImg} alt={"port image"} /></div>
                <div className={classNames(styles.portImg7, styles.portImgPosition)}><img src={this.isConnectedDeviceHovered('A2') ? pwmHoverImg : pwmImg} alt={"port image"} /></div>
                <div className={classNames(styles.portImg8, styles.portImgPosition)}><img src={this.isConnectedDeviceHovered('A1') ? pwmHoverImg : pwmImg} alt={"port image"} /></div>
            </div >
        );
    }

}

EduLine.propTypes = {
    deviceList: PropTypes.array,
};

const mapStateToProps = state => ({
    getHoveredConnectedDevice: getHoveredConnectedDevice(state),
    isConnectedDeviceHovered: isConnectedDeviceHovered(state),
});

export default connect(
    mapStateToProps,
)(EduLine);
