import { PROJECT_STYLE, DEFAULT_LOCALE } from '../config/project-config'
import { selectLocale, localesInitialState } from './locales'
import store from 'store';

const SET_UI_STYLE = 'SET_UI_STYLE';

const uiType = {
    vr: "vr",
    ww: "ww",
    cn: "cn"
};

const initialState = {
    style: PROJECT_STYLE
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_UI_STYLE:
            return Object.assign({}, state, {
                style: action.style
            });
        default:
            return state;
    }
};

const setUIStyle = (style) => {
    let locale = store.get("locale", DEFAULT_LOCALE);
    selectLocale(locale, style, localesInitialState);
    return ({
        type: SET_UI_STYLE,
        style: style
    });
}

const getUIStyle = state => state.scratchGui.uiStyle.style;

export {
    reducer as default,
    initialState as uiStyleInitialState,
    uiType,
    setUIStyle,
    getUIStyle
};