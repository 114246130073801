const xlsx = require('xlsx');
const sheetNameEN = 'Enity lists import form';
const sheetNameTW = '匯入字詞集合表格';
const sheetNameCN = '匯入字詞集合表格';

const isValidSheetName = (name) => {
    return name == sheetNameEN || name == sheetNameTW || name == sheetNameCN;
};

export const IMPORT_ERROR = {
    invalid_file: "invalid_file",
    invalid_xml_sheet: "invalid_xml_sheet"
}

export const importXLSX = () => new Promise((resolve, reject) => {
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', '.xlsx'); // parser auto-detects delimiter
    fileInput.onchange = event => {
        var fReader = new FileReader();
        fReader.readAsBinaryString(event.target.files[0]);
        fReader.onload = (e) => {
            try {
                let fileBuf = e.target.result;
                let workbook = xlsx.read(fileBuf, { type: 'binary' });
                let first_sheet_name = workbook.SheetNames[0]; // 獲取工作簿中的工作表名字
                if (isValidSheetName(first_sheet_name)) {
                    let worksheet = workbook.Sheets[first_sheet_name]; // 獲取對應的工作表對象
                    resolve(worksheet);
                } else {
                    reject(IMPORT_ERROR.invalid_xml_sheet);
                }
            } catch (e) {
                reject(IMPORT_ERROR.invalid_file);
            }
        }
    };
    document.body.appendChild(fileInput);
    fileInput.click();
});
