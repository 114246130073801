export default function aiSpeechEntry(deviceInfo, isEnableAISpeech) {
    return `
        ${(deviceInfo.speakerInfo.hasspeaker && isEnableAISpeech) ? `
            <category
                name="%{BKY_CATEGORY_AISPEECH}"
                id="aiSpeech"
                colour="#FF6680"
                secondaryColour="#FF4D6A"
                custom="AI_SPEECH">
            </category>`: ``
        }
    `;
};