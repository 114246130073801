import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import VM from 'scratch-vm';
import Converter from 'Converter';

import { setProjectUnchanged } from '../reducers/project-changed';
import { resetColorDataList, resetFaceIdentificationList, DEFAULT_COLOR_DATA_LIST, getColorDataDefaultList } from '../reducers/vision';
import {
    LoadingStates,
    getIsLoadingWithId,
    onLoadedProject,
    projectError
} from '../reducers/project-state';
import {
    getWorkspace
} from '../reducers/block';

import {
    setProjectTitle,
    setProjectPreTitle,
    setProjectNew
} from '../reducers/project-title';

import { BLOCKS_DEFAULT_SCALE } from '../lib/layout-constants';

import {
    setCode
} from '../reducers/code-editor';

import { isEnableWebVR } from '../reducers/picked-brain-type';

import { BRAIN_TYPE } from '../lib/brains';
import { DEFAULT_BLOCKID } from '../config/block-config';

import store from 'store';

import LocalKey from '../lib/local-storage-key';

import {
    getUnityMessageFunc
} from '../reducers/vr';

/*
 * Higher Order Component to manage events emitted by the VM
 * @param {React.Component} WrappedComponent component to manage VM events for
 * @returns {React.Component} connected component with vm events bound to redux
 */
const vmManagerHOC = function (WrappedComponent) {
    class VMManager extends React.Component {
        constructor(props) {
            super(props);
            bindAll(this, [
                'loadProject'
            ]);
        }
        componentDidMount() {
            if (!this.props.vm.initialized) {
                this.props.vm.setCompatibilityMode(true);
                this.props.vm.initialized = true;
                this.props.vm.setLocale(this.props.locale, this.props.messages);
            }
            // if (!this.props.isPlayerOnly && !this.props.isStarted) {
            //     this.props.vm.start();
            // }
        }
        componentDidUpdate(prevProps) {
            // if project is in loading state, AND fonts are loaded,
            // and they weren't both that way until now... load project!
            // if (this.props.isLoadingWithId && this.props.fontsLoaded &&
            //     (!prevProps.isLoadingWithId || !prevProps.fontsLoaded)) {
            if (this.props.isLoadingWithId && !prevProps.isLoadingWithId) {
                this.loadProject();
            }
            // Start the VM if entering editor mode with an unstarted vm
            // if (!this.props.isPlayerOnly && !this.props.isStarted) {
            //     this.props.vm.start();
            // }
        }
        loadProject() {
            if (this.props.getWorkspace) {
                this.props.getWorkspace.zoomCenter(0);
                let workspaceMetrics = { scrollX: 0, scrollY: 0, scale: BLOCKS_DEFAULT_SCALE }
                this.props.getWorkspace.scrollX = workspaceMetrics.scrollX;
                this.props.getWorkspace.scrollY = workspaceMetrics.scrollY;
                this.props.getWorkspace.scale = workspaceMetrics.scale;
                this.props.getWorkspace.setScale(workspaceMetrics.scale);
                this.props.getWorkspace.resize();
            }

            // reset start block position
            var resetProjectData = JSON.parse(this.props.projectData);
            if (resetProjectData && resetProjectData.targets && resetProjectData.targets[0].blocks[DEFAULT_BLOCKID.event_whenstarted]) {
                if (this.props.getWorkspace) {
                    resetProjectData.targets[0].blocks[DEFAULT_BLOCKID.event_whenstarted].x = (this.props.getWorkspace.getWidth() - 100) / 2;
                }
                resetProjectData.targets[0].blocks[DEFAULT_BLOCKID.event_whenstarted].y = document.body.offsetHeight * 0.3;
                if (this.props.isEnableWebVR) {
                    resetProjectData.brainType = BRAIN_TYPE.WEB_VR;
                    if (this.props.getUnityMessageFunc) {
                        this.props.getUnityMessageFunc('EventSystem', 'NewFile');
                    }
                }
            }
            this.initPythonCode(resetProjectData);
            this.props.setProjectNew(true);
            return this.props.vm.loadProject(JSON.stringify(resetProjectData))
                .then(() => {
                    this.props.onLoadedProject(this.props.loadingState, this.props.canSave);
                    // Wrap in a setTimeout because skin loading in
                    // the renderer can be async.
                    setTimeout(() => this.props.onSetProjectUnchanged());
                    const messages = defineMessages({
                        defaultProjectTitle: {
                            id: 'gui.gui.defaultProjectTitle',
                            description: 'Default title for project',
                            defaultMessage: 'Scratch Project'
                        }
                    });
                    let title = this.props.intl.formatMessage(messages.defaultProjectTitle);
                    this.props.setProjectTitle(title);
                    this.props.setProjectPreTitle(title);
                    this.props.vm.setProjectName(title);
                    this.props.vm.restoreCvTagSettings(this.props.colorDataDefaultList);
                    this.props.vm.clearCvProfiles();
                    this.props.vm.clearProfilePhoteSave();
                    this.props.vm.clearSpeakerSettings();
                    this.props.vm.initSpeakerMananger();
                    this.props.resetColorDataList(this.props.colorDataDefaultList);
                    this.props.resetFaceIdentificationList();
                    store.set(LocalKey.nowPreSavePath, "");
                    store.set(LocalKey.nowPreSavePath, "");
                    store.set(LocalKey.isPhotoSave, false);
                })
                .catch(e => {
                    this.props.onError(e);
                });
        }
        initPythonCode(resetProjectData) {
            console.log("initPythonCode");
            const generator = new Converter(resetProjectData);
            try {
                const code = generator.getPythonEditorCode();
                resetProjectData.pythonCode = code
                this.props.setCode(code);
            } catch (e) {
                if (e instanceof TypeError) {
                    console.log('initPythonCode TypeError: ', e)
                } else {
                    console.log('initPythonCode ConverterError: ', e)
                }
            }
        }
        render() {
            const {
                /* eslint-disable no-unused-vars */
                fontsLoaded,
                loadingState,
                locale,
                messages,
                isStarted,
                onError: onErrorProp,
                onLoadedProject: onLoadedProjectProp,
                onSetProjectUnchanged,
                projectData,
                /* eslint-enable no-unused-vars */
                isLoadingWithId: isLoadingWithIdProp,
                vm,
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    isLoading={isLoadingWithIdProp}
                    vm={vm}
                    {...componentProps}
                />
            );
        }
    }

    VMManager.propTypes = {
        canSave: PropTypes.bool,
        cloudHost: PropTypes.string,
        fontsLoaded: PropTypes.bool,
        isLoadingWithId: PropTypes.bool,
        isPlayerOnly: PropTypes.bool,
        isStarted: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        locale: PropTypes.string,
        messages: PropTypes.objectOf(PropTypes.string),
        onError: PropTypes.func,
        onLoadedProject: PropTypes.func,
        onSetProjectUnchanged: PropTypes.func,
        projectData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        username: PropTypes.string,
        vm: PropTypes.instanceOf(VM).isRequired,
        getWorkspace: PropTypes.object,
        setProjectTitle: PropTypes.func,
        setProjectPreTitle: PropTypes.func,
        setProjectNew: PropTypes.func,
        intl: intlShape,
        setCode: PropTypes.func,
        isEnableWebVR: PropTypes.bool,
        resetColorDataList: PropTypes.func,
        getUnityMessageFunc: PropTypes.func,
    };

    const mapStateToProps = state => {
        const loadingState = state.scratchGui.projectState.loadingState;
        return {
            fontsLoaded: state.scratchGui.fontsLoaded,
            isLoadingWithId: getIsLoadingWithId(loadingState),
            locale: state.locales.locale,
            messages: state.locales.messages,
            projectData: state.scratchGui.projectState.projectData,
            projectId: state.scratchGui.projectState.projectId,
            loadingState: loadingState,
            isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
            isStarted: state.scratchGui.vmStatus.started,
            getWorkspace: getWorkspace(state),
            isEnableWebVR: isEnableWebVR(state),
            getUnityMessageFunc: getUnityMessageFunc(state),
            colorDataDefaultList: getColorDataDefaultList(state)
        };
    };

    const mapDispatchToProps = dispatch => ({
        onError: error => dispatch(projectError(error)),
        onLoadedProject: (loadingState, canSave) =>
            dispatch(onLoadedProject(loadingState, canSave, true)),
        onSetProjectUnchanged: () => dispatch(setProjectUnchanged()),
        setProjectTitle: title => dispatch(setProjectTitle(title)),
        setProjectPreTitle: title => dispatch(setProjectPreTitle(title)),
        setProjectNew: isNew => dispatch(setProjectNew(isNew)),
        setCode: code => dispatch(setCode(code)),
        resetColorDataList: (list) => dispatch(resetColorDataList(list)),
        resetFaceIdentificationList: () => dispatch(resetFaceIdentificationList())
    });

    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );

    return injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(VMManager));
};

export default vmManagerHOC;
