const imgAltMsgsPlusPlatformByAsus = {
    "ww": {
        "en": {
            "gui.menuBar.logo.icon.alt": "PINBO Lab",
        },
        "zh-tw": {
            "gui.menuBar.logo.icon.alt": "PINBO 實驗室",
        },
        'zh-cn': {
            "gui.menuBar.logo.icon.alt": "PINBO Lab编程",
        },
    },

    "cn": {
        "en": {
            "gui.menuBar.logo.icon.alt": "Aliyun Coding for Education",
        },
        "zh-tw": {
            "gui.menuBar.logo.icon.alt": "阿里雲編程",
        },
        'zh-cn': {
            "gui.menuBar.logo.icon.alt": "阿里云编程",
        },
    },

    "vr": {
        "en": {
            "gui.menuBar.logo.icon.alt": "PINBO Fun",
        },
        "zh-tw": {
            "gui.menuBar.logo.icon.alt": "PINBO Fun",
        },
        'zh-cn': {
            "gui.menuBar.logo.icon.alt": "PINBO Fun",
        },
    },
}

const imgAltMsgsByAsus = {
    "en": {
        "gui.menuBar.logo.icon.alt": "PINBO Lab",
    },

    "zh-tw": {
        "gui.menuBar.logo.icon.alt": "PINBO Lab",
    },


    "zh-cn": {
        "gui.menuBar.logo.icon.alt": "PINBO Lab",
    }
}

export {
    imgAltMsgsPlusPlatformByAsus,
    imgAltMsgsByAsus,
}