const SocketStatus = {
    unauthorized: "unauthorized",
    connectfail: "connectfail",
    scaning: "scaning",
    scanStop: "scanstop",
    connecting: "connecting",
    connected: "connected",
    disconnected: "disconnected",
    connectFail: "connectfail",
    writting: "writting"
};

const SocketAction = {
    unauthorized: "unauthorized",
    getWifiSsid: "getWifiSsid",
    scanWifi: "scanWifi",
    connectWifi: "connectWifi",
    disconnectWifi: "disconnectWifi",
    open: "open",
    refresh: "refresh",
    connect: "connect",
    disconnect: "disconnect",
    download: "download",
    run: "run",
    stop: "stop",
    delete: "delete",
    getDevice: "getDevice",
    startPortState: "startPortState",
    stopPortState: "stopPortState",
    startcvRealtime: "startcvRealtime",
    stopcvRealtime: "stopcvRealtime",
    downloadcvPic: "downloadcvPic",
    getDevStatus: "getDevStatus",
    getScreenText: "getScreenText",
    getCtrlerStatus: "getCtrlerStatus",
    startCtrlerReport: "startCtrlerReport",
    stopCtrlerReport: "stopCtrlerReport",
    cvSendCmd: "cvSendCmd",
    cvImageSave: "cvImageSave",
    getWifiStatus: "getWifiStatus",
    getProjNames: "getprojnames"
};

const SocketDownload = {
    start: "start",
    downloading: "downloading",
    end: "end"
};

export {
    SocketStatus,
    SocketAction,
    SocketDownload
}