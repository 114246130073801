import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash.omit';
import bindAll from 'lodash.bindall';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styles from './block-control-button.css';
import { connect } from 'react-redux';
import { uiType, getUIStyle } from '../../reducers/ui-style';
import { getDeviceManagerVisible } from '../../reducers/device-manager-controller';

import redoIcon from '../../../common-svg/redo.svg';
import undoIcon from '../../../common-svg/undo.svg';
import zoomInIcon from '../../../common-svg/zoom-in.svg';
import zoomOutIcon from '../../../common-svg/zoom-out.svg';
import zoomResetIcon from '../../../common-svg/zoom-reset.svg';
import asusLogoIcon from '../../../common-svg/asus_logo.svg';
import codeIcon from './code.svg';

import redoIconVR from '../../../common-svg/redo_vr.svg';
import undoIconVR from '../../../common-svg/undo_vr.svg';
import zoomInIconVR from '../../../common-svg/zoom-in_vr.svg';
import zoomOutIconVR from '../../../common-svg/zoom-out_vr.svg';
import zoomResetIconVR from '../../../common-svg/zoom-reset_vr.svg';
import codeIconVR from './code_vr.svg';

import redoIconWW from '../../../common-svg/redo_ww.svg';
import undoIconWW from '../../../common-svg/undo_ww.svg';
import zoomInIconWW from '../../../common-svg/zoom-in_ww.svg';
import zoomOutIconWW from '../../../common-svg/zoom-out_ww.svg';
import zoomResetIconWW from '../../../common-svg/zoom-reset_ww.svg';
import codeIconWW from './code_ww.svg';

import {
    getWorkspace
} from '../../reducers/block';

import {
    setCodeView,
    setHighLightMode,
    getCodeViewState
} from '../../reducers/code-editor';

import { BRAIN_TYPE } from '../../lib/brains';

import { getPickedBrainType } from '../../reducers/picked-brain-type';

import {
    platformType,
    isPad,
    getPlatform
} from '../../lib/platform'

import { EditUtils } from '../device-manager/edit-page/edit-utils.js';

class BlockControlButton extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleClickOpenCodeViewer',
            'setRedoIconSrc',
            'setUndoIconSrc',
            'setZoomInIconSrc',
            'setZoomOutIconSrc',
            'setZoomResetIconSrc',
            'setCodeIconSrc'
        ]);
    }

    onClickUndo(redo) {
        this.props.getWorkspace.undo(redo);
    }

    onClickZoomCenter(type) {
        this.props.getWorkspace.zoomCenter(type);
    }

    handleClickOpenCodeViewer() {
        this.props.setCodeView(true);
        this.props.setHighLightMode(true);
    }

    setRedoIconSrc() {
        if (this.props.getUIStyle == uiType.vr) {
            return redoIconVR;
        } else if (this.props.getUIStyle == uiType.ww) {
            return redoIconWW;
        } else {
            return redoIcon;
        }
    }

    setUndoIconSrc() {
        if (this.props.getUIStyle == uiType.vr) {
            return undoIconVR
        } else if (this.props.getUIStyle == uiType.ww) {
            return undoIconWW;
        } else {
            return undoIcon;
        }
    }

    setZoomInIconSrc() {
        if (this.props.getUIStyle == uiType.vr) {
            return zoomInIconVR
        } else if (this.props.getUIStyle == uiType.ww) {
            return zoomInIconWW;
        } else {
            return zoomInIcon;
        }
    }

    setZoomOutIconSrc() {
        if (this.props.getUIStyle == uiType.vr) {
            return zoomOutIconVR
        } else if (this.props.getUIStyle == uiType.ww) {
            return zoomOutIconWW;
        } else {
            return zoomOutIcon;
        }
    }

    setZoomResetIconSrc() {
        if (this.props.getUIStyle == uiType.vr) {
            return zoomResetIconVR
        } else if (this.props.getUIStyle == uiType.ww) {
            return zoomResetIconWW;
        } else {
            return zoomResetIcon;
        }
    }

    setCodeIconSrc() {
        if (this.props.getUIStyle == uiType.vr) {
            return codeIconVR;
        } else if (this.props.getUIStyle == uiType.ww) {
            return codeIconWW;
        } else {
            return codeIcon;
        }
    }

    isPadCloseStyle() {
        return !this.props.getDeviceManagerVisible && isPad()
    }

    render() {
        const {
            hasRedoStack,
            hasUndoStack,
            enableZoomIn,
            enableZoomOut,
            ...componentProps
        } = omit(this.props, 'dispatch');
        return (
            <div>
                <div className={styles.redoUndoControls}>
                    <img
                        className={classNames(
                            styles.icon,
                            styles.redoIcon,
                            (this.props.getPickedBrainType == BRAIN_TYPE.ENTRY || this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) && !this.props.getCodeViewState ? styles.codeBtn : null,
                            hasRedoStack ? styles.enable : styles.disable
                        )}
                        src={this.setRedoIconSrc()}
                        onClick={() => this.onClickUndo(true)}
                        alt={EditUtils.getLocaleString('gui.menuBar.undo')}
                    />
                    <img
                        className={classNames(
                            styles.icon,
                            styles.undoIcon,
                            (this.props.getPickedBrainType == BRAIN_TYPE.ENTRY || this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) && !this.props.getCodeViewState ? styles.codeBtn : null,
                            hasUndoStack ? styles.enable : styles.disable
                        )}
                        src={this.setUndoIconSrc()}
                        onClick={() => this.onClickUndo(false)}
                        alt={EditUtils.getLocaleString('gui.menuBar.redo')}
                    />
                    {
                        (this.props.getPickedBrainType == BRAIN_TYPE.ENTRY || this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) && !this.props.getCodeViewState ? (
                            <div className={styles.pythonBtnBorder}>
                                <div className={styles.pythonBtn} onClick={this.handleClickOpenCodeViewer}>
                                    <div className={styles.codeCircle}>
                                        <img src={this.setCodeIconSrc()} alt={EditUtils.getLocaleString('gui.codeview.highLight.open')} />
                                    </div>
                                    <FormattedMessage
                                        defaultMessage="Open high light code"
                                        description="Open high light code"
                                        id="gui.codeview.highLight.open"
                                    />
                                </div>
                            </div>) : null
                    }
                </div>
                <div className={styles.zoomControls}>
                    <img
                        className={classNames(styles.icon, styles.zoomResetIcon, this.isPadCloseStyle() ? styles.zoomResetIconPad : null)}
                        src={this.setZoomResetIconSrc()}
                        onClick={() => this.onClickZoomCenter(0)}
                        alt={'zoom reset'}
                    />
                    <img
                        className={classNames(styles.icon, styles.zoomInIcon, enableZoomIn ? styles.enable : styles.disable, this.isPadCloseStyle() ? styles.zoomInIconPad : null)}
                        src={this.setZoomInIconSrc()}
                        onClick={() => this.onClickZoomCenter(1)}
                        alt={'zoom in'}
                    />
                    <img
                        className={classNames(styles.icon, styles.zoomOutIcon, enableZoomOut ? styles.enable : styles.disable, this.isPadCloseStyle() ? styles.zoomOutIconPad : null)}
                        src={this.setZoomOutIconSrc()}
                        onClick={() => this.onClickZoomCenter(-1)}
                        alt={'zoom out'}
                    />
                    <img
                        className={classNames(styles.icon, styles.asusLogoIcon, this.isPadCloseStyle() ? styles.asusLogoIconPad : null)}
                        src={asusLogoIcon}
                        alt={'asus logo'}
                    />
                </div>
            </div>
        );
    }
}

BlockControlButton.propTypes = {
    getWorkspace: PropTypes.object,
    hasUndoStack: PropTypes.bool,
    hasRedoStack: PropTypes.bool,
    enableZoomIn: PropTypes.bool,
    enableZoomOut: PropTypes.bool,
    setCodeView: PropTypes.func,
    setHighLightMode: PropTypes.func,
    getCodeViewState: PropTypes.bool,
    getPickedBrainType: PropTypes.string,
    getUIStyle: PropTypes.string
};

const mapStateToProps = state => ({
    getWorkspace: getWorkspace(state),
    getCodeViewState: getCodeViewState(state),
    getPickedBrainType: getPickedBrainType(state),
    getUIStyle: getUIStyle(state),
    getDeviceManagerVisible: getDeviceManagerVisible(state)
});

const mapDispatchToProps = dispatch => ({
    setCodeView: show => dispatch(setCodeView(show)),
    setHighLightMode: show => dispatch(setHighLightMode(show)),
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockControlButton));