import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import styles from './port.css';
import { FormattedMessage } from 'react-intl';

import {
    openDeviceList,
    closeDeviceList,
    isPortOpen
} from '../../../reducers/device-manager-controller';

import {
    isOptionSelected,
    getSelectedOption,
    isDeviceHovered
} from '../../../reducers/select-option'

class DisconnectedPort extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }

    isPortSelected() {
        let isPortSelected = this.props.isOptionSelected;
        if (isPortSelected) {
            let selectedArray = this.props.getSelectedOption;
            if (selectedArray && selectedArray.length > 0) {
                isPortSelected = selectedArray.includes(this.props.port);
            }
        }
        return isPortSelected;
    }

    isDeviceHovered() {
        let isDeviceHovered = this.props.isDeviceHovered;
        if (isDeviceHovered) {
            let selectedArray = this.props.getSelectedOption;
            if (selectedArray && selectedArray.length > 0) {
                isDeviceHovered = selectedArray.includes(this.props.port);
            }
        }
        return isDeviceHovered;
    }

    render() {
        const {
            port,
            isBrainLeft
        } = this.props;
        return (
            (this.isDeviceHovered()) ?
                <div className={classNames(styles.addPortArea)}>
                    <div className={classNames(styles.portOptionSelected, (!isBrainLeft) ? styles.reverse : null)}>
                        {port}
                    </div>
                    <div className={classNames(styles.addPortDisconnected, (!isBrainLeft) ? styles.reverse : null)}>
                        <FormattedMessage id="gui.deviceManager.port.disconnected" />
                    </div>
                </div>
                : (this.isPortSelected()) ?
                    <div className={classNames(styles.addPortArea)}>
                        <div className={classNames(styles.portOptionSelected, (!isBrainLeft) ? styles.reverse : null)}>
                            {port}
                        </div>
                    </div>
                    : <div >
                        <div className={classNames(styles.emptyPort, (!isBrainLeft) ? styles.reverse : null)}>
                            {port}
                        </div>
                    </div>

        );
    }
}

DisconnectedPort.propTypes = {
    port: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    isPortOpen: PropTypes.bool,
    isOptionSelected: PropTypes.bool,
    isDeviceHovered: PropTypes.bool,
    isBrainLeft: PropTypes.bool
};

const mapStateToProps = state => ({
    isPortOpen: isPortOpen(state),
    getSelectedOption: getSelectedOption(state),
    isOptionSelected: isOptionSelected(state),
    isDeviceHovered: isDeviceHovered(state)
});

const mapDispatchToProps = dispatch => ({
    openDeviceList: (port) => dispatch(openDeviceList(port)),
    closeDeviceList: () => dispatch(closeDeviceList())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisconnectedPort);


