import bindAll from 'lodash.bindall';
import debounce from 'lodash.debounce';
import defaultsDeep from 'lodash.defaultsdeep';
import makeToolboxXML from '../lib/make-toolbox-xml';
import PropTypes from 'prop-types';
import React from 'react';
import VMScratchBlocks from '../lib/blocks';
import VM from 'scratch-vm';
import store from 'store';
import { FormattedMessage, injectIntl } from 'react-intl';

import { EDIT_MODE } from '../lib/edit-mode.js';
import getBlockColour from '../lib/block-colour/colour';
import log from '../lib/log.js';
import Prompt from './prompt.jsx';
import BlocksComponent from '../components/blocks/blocks.jsx';
import extensionData from '../lib/libraries/extensions/index.jsx';
import CustomProcedures from './custom-procedures.jsx';
import errorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import { BLOCKS_DEFAULT_SCALE, STAGE_DISPLAY_SIZES } from '../lib/layout-constants';
import DropAreaHOC from '../lib/drop-area-hoc.jsx';
import BlockControlButton from '../components/block-control-button/block-control-button.jsx';
import DragConstants from '../lib/drag-constants';
import defineDynamicBlock from '../lib/define-dynamic-block';
import {
    platformType,
    isPad,
    getPlatform
} from '../lib/platform'
import { getDeprecatedBlocksList, getMappingBlocksList } from '../lib/deprecated-blocks';
import { connect } from 'react-redux';
import { updateToolbox, updateFlyoutVisible, updateToolboxVisible, updateHasMaximizedComment } from '../reducers/toolbox';
import { activateColorPicker } from '../reducers/color-picker';
import { activateCustomProcedures, deactivateCustomProcedures } from '../reducers/custom-procedures';
import { setDeviceChanged } from '../reducers/device';
import { getUIStyle } from '../reducers/ui-style';
import {
    errorType,
    showErrorDialog,
    hideErrorDialog,
    showHintBlockHelpDialog,
    showBlockHelpDialog,
    showAlertDialog,
    showEditAISpeechGroupDialog,
    showQuestionDeleteAISpeechGroupDialog,
    showQuestionDeleteSpeakerIntentArrayDialog,
    showAudioUpperBoundErrorDialog,
    hideAudioUpperBoundErrorDialog,
    isAudioUpperBoundErrorDialogShow
} from '../reducers/dialog';
import { showLoadingBar, LOADING_TYPE_NUM } from '../reducers/loading-bar';
import AudioLoadingDialog from '../components/dialog/audio-loading-dialog.jsx';
import ErrorDialog from '../components/dialog/error-dialog.jsx';
import {
    getPickedBrainType,
    isPickedBrainType,
    setPickedBrainType
} from '../reducers/picked-brain-type'
import { BRAIN_TYPE } from '../lib/brains';
import {
    setCode,
    setHighLightBlockId,
    setHighLightField,
    getCodeViewState,
    getEditorDisplayMode
} from '../reducers/code-editor';

import styles from '../css/blocks.css';

import {
    setBlocks,
    setWorkspace,
    setBlockHelpBlockType
} from '../reducers/block';

import { USER_GUIDE_STATE } from '../lib/user-guide-state'
import {
    isShowUserGuide,
    getUserGuideCurrentState,
    updateUserGuideState
} from '../reducers/user-guide';
import { extractFileName, handleFileUpload, soundUpload } from '../lib/file-uploader.js';
import SBFileUploader from './sb-file-uploader.jsx';

import {
    editBlockType,
    showEditBlockDialog,
    hideEditBlockDialog,
    editBlockDialogShow
} from '../reducers/dialog';
import QuestionDialog from '../components/dialog/question-dialog.jsx';

import BlockEditDialog from '../components/dialog/block-edit-dialog.jsx';
import Converter from 'Converter';
import { updateMetrics } from '../reducers/workspace-metrics';

import { DEVICE_INFO } from '../lib/deviceInfo';

import soundBlocks from '../lib/blocks/block-vertical-blocks/sound';

import { closeBrainInfoMenu } from '../reducers/menus';
import { setImportSentence, isEnableAISpeech, PROMPT_TYPE } from '../reducers/speaker';
import classNames from 'classnames';

import {
    viewPage,
    getFullScreen
} from '../reducers/stage-size';
import { VISION_DEFAULT_LANGUAGE_MAPPING } from '../components/dialog/vision/vision-utils'
import {
    EditUtils
} from '../components/device-manager/edit-page/edit-utils.js';

const addFunctionListener = (object, property, callback) => {
    const oldFn = object[property];
    object[property] = function () {
        const result = oldFn.apply(this, arguments);
        callback.apply(this, result);
        return result;
    };
};

const DroppableBlocks = DropAreaHOC([
    DragConstants.BACKPACK_CODE
])(BlocksComponent);

const AUDIO_SIZE_UPPER_BOUND = 100000000; // 100MB

class Blocks extends React.Component {
    constructor(props) {
        super(props);
        this.ScratchBlocks = VMScratchBlocks(props.vm);
        props.setBlocksToState(this.ScratchBlocks);
        bindAll(this, [
            'attachVM',
            'detachVM',
            'getToolboxXML',
            'handleCategorySelected',
            'handleDrop',
            'handleStatusButtonUpdate',
            'handleOpenSoundRecorder',
            'handleDeleteSoundRecorder',
            'handleSelectInfoChange',
            'handleSelectListInfoChange',
            'handleClearUndo',
            'handlePromptStart',
            'handlePromptCallback',
            'handlePromptClose',
            'handleReloadCachedAreasOpen',
            'handleReloadCachedAreasClose',
            'handleCustomProceduresClose',
            'updateBlockEditState',
            'handleExtensionAdded',
            'handleBlocksInfoUpdate',
            'onTargetsUpdate',
            'onPartialWorkspaceUpdate',
            'onSentenceUpdate',
            'updateIntentBlockUses',
            'onVisualReport',
            'onWorkspaceUpdate',
            'onWorkspaceMetricsChange',
            'onBrainTypeUpdate',
            'setBlocks',
            'setLocale',
            'handleFlyoutArrowLeftButton',
            'handleToolboxArrowLeftButton',
            'handleToolboxArrowRightButton',
            'handleWorkspaceArrowRightButton',
            'checkBlockLimit',
            'handleAudioManagementStart',
            'handleEditPromptStart',
            'handleEditBlockConfirm',
            'handleEditBlockClose',
            'updateHighlightBlock',
            'clearHighlightBlock',
            'updateHighlightField',
            'clearHighlightField',
            'handleCloseButton',
            'handleDocumentBodyClick',
            'handlePadCloseRef',
            'manageAudioDataCallback',
            'setFileUploader',
            'importSpeakerIntent',
            'verifyBlockNameInBlock',
            'getVMTarget',
            'showUpperBoundErrorDialog'
        ]);
        this.ScratchBlocks.prompt = this.handlePromptStart;
        this.ScratchBlocks.addSound = this.handleOpenSoundRecorder;
        this.ScratchBlocks.handleAudioManagementStart = this.handleAudioManagementStart;
        this.ScratchBlocks.deleteSound = this.handleDeleteSoundRecorder;
        this.ScratchBlocks.editPrompt = this.handleEditPromptStart;
        this.ScratchBlocks.eventStartedUpperBoundprompt = () => this.showUpperBoundErrorDialog();
        this.ScratchBlocks.showBlockCodes = this.updateHighlightBlock;
        this.ScratchBlocks.hideBlockCodes = this.clearHighlightBlock;
        this.ScratchBlocks.showFieldCodes = this.updateHighlightField;
        this.ScratchBlocks.hideFieldCodes = this.clearHighlightField;
        this.ScratchBlocks.showExtensionBlockNeedWifiDialog = () => props.showExtensionBlockNeedWifiDialog();
        this.ScratchBlocks.getStoreData = (storeKey, defautValue) => store.get(storeKey, defautValue);
        this.ScratchBlocks.setStoreData = (storeKey, data) => store.set(storeKey, data);
        this.setBlockMapSetting();

        this.ScratchBlocks.AISpeech.getSpeakerGroups = () => props.vm.getSpeakerGroups();
        this.ScratchBlocks.AISpeech.getSpeakerGroupById = (groupId) => props.vm.getSpeakerGroupById(groupId);
        this.ScratchBlocks.AISpeech.getSpeakerGroupIntentsById = (groupId) => props.vm.getSpeakerGroupIntentsById(groupId);
        this.ScratchBlocks.AISpeech.restoreSpeakerGroupById = (groupName, id, intentsinGroup) => props.vm.restoreSpeakerGroupById(groupName, id, intentsinGroup);
        this.ScratchBlocks.AISpeech.addSpeakerIntent = (intentName, groupId, patternArray, responseArray, id, defaultGroupName) => props.vm.addSpeakerIntent(intentName, groupId, patternArray, responseArray, id, defaultGroupName);
        this.ScratchBlocks.AISpeech.getSpeakerIntentById = (intentId) => props.vm.getSpeakerIntentById(intentId);
        this.ScratchBlocks.AISpeech.setSpeakerGroupExpanded = (groupId, expanded) => props.vm.setSpeakerGroupExpanded(groupId, expanded);
        this.ScratchBlocks.AISpeech.isSpeakerGroupExpanded = (groupId) => props.vm.isSpeakerGroupExpanded(groupId);
        this.ScratchBlocks.AISpeech.removeSpeakerGroupById = (groupId) => props.vm.removeSpeakerGroupById(groupId);
        this.ScratchBlocks.AISpeech.getSpeakerIntentArray = () => props.vm.getSpeakerIntentArray();
        this.ScratchBlocks.AISpeech.showEditSpeakerIntent = (intentId) => props.showEditCustomizinDialog(intentId);
        this.ScratchBlocks.AISpeech.deleteSpeakerIntent = (intentId) => {
            props.vm.removeSpeakerIntentById(intentId);
        }
        this.ScratchBlocks.AISpeech.showDeleteSpeakerIntentArrayDialog = (callback) => props.onShowQuestionDeleteSpeakerIntentArrayDialog(callback);
        this.ScratchBlocks.AISpeech.getSpeakerConcepts = () => props.vm.getSpeakerConcepts();
        this.ScratchBlocks.AISpeech.showAddIntentDialog = () => props.showAddCustomizinDialog();
        this.ScratchBlocks.AISpeech.showAddConceptDialog = () => props.showAddConceptDialog();
        this.ScratchBlocks.AISpeech.showEditConceptDialog = () => props.showEditConceptDialog();
        this.ScratchBlocks.AISpeech.importSpeakerIntent = this.importSpeakerIntent;

        this.ScratchBlocks.verifyBlockName = (allBlocks, workspace) => this.verifyBlockNameInBlock(allBlocks, workspace);

        this.ScratchBlocks.ContextMenu.showDeleteAISpeechGroupDialog = (group, callback) => props.onShowQuestionDeleteAISpeechGroupDialog(group, callback);
        this.ScratchBlocks.ContextMenu.showEditAISpeechGroupDialog = (group) => props.onShowEditAISpeechGroupDialog(group);

        this.state = {
            prompt: null,
            reloadCachedAreas: false,
            editBlock: null,
            hasUndoStack: false,
            hasRedoStack: false,
            enableZoomIn: true,
            enableZoomOut: true,
            padCloseisExpand: false,

            audioLoadingPercentage: 0,
            showAudioLoading: false
        };
        this.onTargetsUpdate = debounce(this.onTargetsUpdate, 100);
        this.toolboxUpdateQueue = [];

        this.padCloseButton = new DOMParser().parseFromString('<div/>', "text/xml");
        this.sbfuploader = null;
    }
    componentDidMount() {
        this.ScratchBlocks.FieldColourSlider.activateEyedropper_ = this.props.onActivateColorPicker;
        this.ScratchBlocks.Procedures.externalProcedureDefCallback = this.props.onActivateCustomProcedures;
        this.ScratchBlocks.ScratchMsgs.setLocale(this.props.locale);

        const workspaceConfig = defaultsDeep({},
            Blocks.defaultOptions,
            this.props.options,
            { rtl: this.props.isRtl, toolbox: this.props.toolboxXML, uis: this.props.getUIStyle },
            getBlockColour(this.props.getUIStyle)
        );
        this.workspace = this.ScratchBlocks.inject(this.blocks, workspaceConfig);
        this.workspace.getFlyout().setVisible(this.props.isFlyoutVisible);
        this.workspace.toolbox_.setVisible(this.props.isToolboxVisible);
        this.props.setWorkspaceToState(this.workspace);
        if (this.workspace.getFlyout().isVisible()) {
            this.workspace.toolbox_.setVisible(true);
            this.props.updateToolboxVisibleState(true);
        }

        // Register buttons under new callback keys for creating variables,
        // lists, and procedures from extensions.

        const toolboxWorkspace = this.workspace.getFlyout().getWorkspace();

        const varListButtonCallback = type =>
            (() => this.ScratchBlocks.Variables.createVariable(this.workspace, null, type));
        const procButtonCallback = () => {
            this.ScratchBlocks.Procedures.createProcedureDefCallback_(this.workspace);
        };

        toolboxWorkspace.registerButtonCallback('MAKE_A_VARIABLE', varListButtonCallback(''));
        toolboxWorkspace.registerButtonCallback('MAKE_A_LIST', varListButtonCallback('list'));
        toolboxWorkspace.registerButtonCallback('MAKE_A_PROCEDURE', procButtonCallback);

        // Store the xml of the toolbox that is actually rendered.
        // This is used in componentDidUpdate instead of prevProps, because
        // the xml can change while e.g. on the costumes tab.
        this._renderedToolboxXML = this.props.toolboxXML;

        if (!this.padCloseButton && (isPad())) {
            this.workspace.blockTouchEvent = (e) => {
                this.handleDocumentBodyClick(e);
                this.props.onRequestCloseBrain();
            };
        }

        // we actually never want the workspace to enable "refresh toolbox" - this basically re-renders the
        // entire toolbox every time we reset the workspace.  We call updateToolbox as a part of
        // componentDidUpdate so the toolbox will still correctly be updated
        // this.setToolboxRefreshEnabled = this.workspace.setToolboxRefreshEnabled.bind(this.workspace);
        // this.workspace.setToolboxRefreshEnabled = () => {
        this.workspace.setToolboxRefreshEnabled(false);
        // };

        // @todo change this when blockly supports UI events
        addFunctionListener(this.workspace, 'translate', this.onWorkspaceMetricsChange);
        addFunctionListener(this.workspace, 'zoom', this.onWorkspaceMetricsChange);

        this.attachVM();
        // Only update blocks/vm locale when visible to avoid sizing issues
        // If locale changes while not visible it will get handled in didUpdate
        if (this.props.isVisible) {
            this.setLocale();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.updateToolboxHeight();
        return (
            this.state.prompt !== nextState.prompt ||
            this.state.reloadCachedAreas !== nextState.reloadCachedAreas ||
            this.props.isVisible !== nextProps.isVisible ||
            this._renderedToolboxXML !== nextProps.toolboxXML ||
            this.props.extensionLibraryVisible !== nextProps.extensionLibraryVisible ||
            this.props.customProceduresVisible !== nextProps.customProceduresVisible ||
            this.props.locale !== nextProps.locale ||
            this.props.anyModalVisible !== nextProps.anyModalVisible ||
            this.props.stageSize !== nextProps.stageSize ||
            this.props.isFlyoutVisible !== nextProps.isFlyoutVisible ||
            this.props.isToolboxVisible !== nextProps.isToolboxVisible ||
            this.props.hasMaximizedComment !== nextProps.hasMaximizedComment ||
            this.props.isShowUserGuide !== nextProps.isShowUserGuide ||
            this.props.isDeviceChanged !== nextProps.isDeviceChanged ||
            this.props.pickedBrainType !== nextProps.pickedBrainType ||
            this.props.projectChanged !== nextProps.projectChanged ||
            this.props.editBlockDialogShow !== nextProps.editBlockDialogShow ||
            this.props.getCodeViewState !== nextProps.getCodeViewState ||
            this.props.isAudioUpperBoundErrorDialogShow !== nextProps.isAudioUpperBoundErrorDialogShow ||
            this.state.hasUndoStack != nextState.hasUndoStack ||
            this.state.hasRedoStack != nextState.hasRedoStack ||
            this.state.enableZoomIn != nextState.enableZoomIn ||
            this.state.enableZoomOut != nextState.enableZoomOut ||
            this.state.padCloseisExpand != nextState.padCloseisExpand ||
            this.props.getVRFullScreen !== nextProps.getVRFullScreen
        );
    }

    componentDidUpdate(prevProps) {
        var flyoutLeftButton = document.getElementById("flyoutLeftButton");
        const style = getComputedStyle(flyoutLeftButton)
        this.workspace.translateWorkspaceByLeftPanel(parseInt(style.left, 0));
        this.workspace.recordCachedAreas();

        if (this.props.pickedBrainType == BRAIN_TYPE.ENTRY || this.props.pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
            this.workspace.setEventNeedToCheckList([
                'event_whenstarted'
            ]);
        } else {
            this.workspace.setEventNeedToCheckList([
                'event_whenstarted',
                'event_whentimergreaterthan',
                'event_whenbroadcastreceived',
                'event_whenrfiddetectsdata',
                'event_whenbumperbutton',
                'event_whencontrollerbutton',
                'event_whencontrolleraxischanged'
            ]);
        }

        if (this.state.reloadCachedAreas == true) {
            setTimeout(() => this.handleReloadCachedAreasClose(), 10)
        }

        // If any modals are open, call hideChaff to close z-indexed field editors
        if (this.props.anyModalVisible && !prevProps.anyModalVisible) {
            this.ScratchBlocks.hideChaff();
        }

        // Only rerender the toolbox when the blocks are visible and the xml is
        // different from the previously rendered toolbox xml.
        // Do not check against prevProps.toolboxXML because that may not have been rendered.
        if (this.props.isVisible && this.props.toolboxXML !== this._renderedToolboxXML) {
            this.requestToolboxUpdate();
        }

        if (!this.props.projectChanged && prevProps.projectChanged) {
            if (this.props.pickedBrainType != BRAIN_TYPE.WEB_VR) {
                this.checkBlockLimit();
                this.verifyBlockName(this.workspace.getAllBlocks(false));
            }
            this.resetBlockValue();
        }

        if (this.props.isDeviceChanged && !prevProps.isDeviceChanged) {
            if (this.getVMTarget()) {
                const toolboxXML = this.getToolboxXML();
                if (toolboxXML) {
                    this.props.updateToolboxState(toolboxXML);
                }
                this.requestToolboxUpdate();

                this.resetMotorBlockUpdate();

                this.verifyBlockName(this.workspace.getAllBlocks(false));
            }
            this.props.updateDeviceChangedState(false);
        }

        if (this.props.pickedBrainType != prevProps.pickedBrainType) {
            const toolboxXML = this.getToolboxXML();
            if (toolboxXML) {
                this.props.updateToolboxState(toolboxXML);
            }
            this.ScratchBlocks.clearCopyBlocks();
        }

        if (this.props.getCodeViewState && this.props.getCodeViewState != prevProps.getCodeViewState) {
            console.log("syncEditCode");
            setTimeout(() => { this.syncEditCode() });
        }

        if (this.props.isVisible === prevProps.isVisible) {
            if (this.props.stageSize !== prevProps.stageSize ||
                this.props.getCodeViewState !== prevProps.getCodeViewState ||
                this.props.pickedBrainType !== prevProps.pickedBrainType ||
                this.props.getVRFullScreen !== prevProps.getVRFullScreen) {
                // force workspace to redraw for the new stage size
                setTimeout(() => window.dispatchEvent(new Event('resize')));
            }
            return;
        }
        // @todo hack to resize blockly manually in case resize happened while hidden
        // @todo hack to reload the workspace due to gui bug #413
        if (this.props.isVisible) { // Scripts tab
            this.workspace.setVisible(true);
            if (prevProps.locale !== this.props.locale || this.props.locale !== this.props.vm.getLocale()) {
                // call setLocale if the locale has changed, or changed while the blocks were hidden.
                // vm.getLocale() will be out of sync if locale was changed while not visible
                this.setLocale();
            } else {
                this.props.vm.refreshWorkspace();
                this.requestToolboxUpdate();
            }

            window.dispatchEvent(new Event('resize'));
        } else {
            this.workspace.setVisible(false);
        }
    }
    componentWillUnmount() {
        this.detachVM();
        this.workspace.dispose();
        clearTimeout(this.toolboxUpdateTimeout);
    }
    resetBlockValue() {
        var blockIds = [
            'event_whentimergreaterthan',
            'event_whenrfiddetectsdata',
            'control_wait',
            'control_repeat',
            'operator_add',
            'operator_subtract',
            'operator_multiply',
            'operator_divide',
            'operator_random',
            'operator_gt',
            'operator_lt',
            'operator_equals',
            'operator_round',
            'operator_mathop',
            'operator_remainder',
            'data_setvariableto',
            'data_changevariableby',
            'comments_normal'
        ];

        this.resetValue(blockIds);
    }
    resetValue(blockIds) {
        for (var blockId of blockIds) {
            var block = this.workspace.getBlockById(blockId);
            if (block) {
                switch (blockId) {
                    case 'operator_random':
                        block.childBlocks_[1].inputList[0].fieldRow[0].setValue("10");
                    case 'event_whentimergreaterthan':
                    case 'control_wait':
                    case 'data_changevariableby':
                        block.childBlocks_[0].inputList[0].fieldRow[0].setValue("1");
                        break;
                    case 'control_repeat':
                        block.childBlocks_[0].inputList[0].fieldRow[0].setValue("10");
                        break;
                    case 'event_whenrfiddetectsdata':
                        block.inputList[0].fieldRow[1].setValue("any");
                        break;
                    case 'operator_add':
                    case 'operator_subtract':
                    case 'operator_multiply':
                    case 'operator_divide':
                    case 'operator_gt':
                    case 'operator_lt':
                    case 'operator_equals':
                    case 'operator_multiply':
                    case 'operator_remainder':
                        block.childBlocks_[1].inputList[0].fieldRow[0].setValue("");
                    case 'operator_round':
                    case 'operator_mathop':
                        block.childBlocks_[0].inputList[0].fieldRow[0].setValue("");
                        break;
                    case 'comments_normal':
                        const { intl } = this.props;
                        let commentText = intl.formatMessage({ id: "gui.block.CommentDefaultText" });
                        block.childBlocks_[0].inputList[0].fieldRow[0].setValue(commentText);
                        break;
                }
            }
        }
    }
    requestToolboxUpdate() {
        clearTimeout(this.toolboxUpdateTimeout);
        this.toolboxUpdateTimeout = setTimeout(() => {
            this.updateToolbox();
        }, 0);
    }
    resetMotorBlockUpdate() {
        clearTimeout(this.motorBlockUpdateTimeout);
        this.motorBlockUpdateTimeout = setTimeout(() => {
            this.updateMotionSpinValue('motion_spin');
            this.updateMotionSpinValue('motion_spindegree');
            this.updateMotionSpinValue('motion_3wire_spin');
        }, 100);
    }
    setLocale() {
        this.ScratchBlocks.ScratchMsgs.setLocale(this.props.locale);
        this.props.vm.setLocale(this.props.locale, this.props.messages)
            .then(() => {
                this.workspace.getFlyout().setRecyclingEnabled(false);
                this.props.vm.refreshWorkspace();
                this.requestToolboxUpdate();
                this.withToolboxUpdates(() => {
                    this.workspace.getFlyout().setRecyclingEnabled(true);
                });
            });
    }

    setBlockMapSetting() {
        this.ScratchBlocks.setDeprecatedBlocksList(getDeprecatedBlocksList());
        this.props.vm.setMappingBlocksList(getMappingBlocksList());
    }

    updateToolboxHeight() {
        if (this.props.pickedBrainType == BRAIN_TYPE.ENTRY || this.props.pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
            this.workspace.getToolbox().categoryMenu_.setHeight(document.body.offsetHeight - 90)
        } else {
            this.workspace.getToolbox().categoryMenu_.setHeight(document.body.offsetHeight - 54)
        }
    }

    updateToolbox() {
        let originVisible = this.workspace.getFlyout().isVisible();
        this.toolboxUpdateTimeout = false;
        this.workspace.updateToolbox(this.props.toolboxXML);
        this.updateToolboxHeight();
        this._renderedToolboxXML = this.props.toolboxXML;
        if (originVisible) {
            const categoryId = this.workspace.toolbox_.getSelectedCategoryId();
            const offset = this.workspace.toolbox_.getCategoryScrollOffset();

            // In order to catch any changes that mutate the toolbox during "normal runtime"
            // (variable changes/etc), re-enable toolbox refresh.
            // Using the setter function will rerender the entire toolbox which we just rendered.
            this.workspace.toolboxRefreshEnabled_ = true;

            const currentCategoryPos = this.workspace.toolbox_.getCategoryPositionById(categoryId);
            const currentCategoryLen = this.workspace.toolbox_.getCategoryLengthById(categoryId);
            if (offset < currentCategoryLen) {
                this.workspace.toolbox_.setFlyoutScrollPos(currentCategoryPos + offset);
            } else {
                this.workspace.toolbox_.setFlyoutScrollPos(currentCategoryPos);
            }

            const queue = this.toolboxUpdateQueue;
            this.toolboxUpdateQueue = [];
            queue.forEach(fn => fn());
        } else {
            this.workspace.getToolbox().clearSelection();
        }
        this.workspace.getFlyout().setVisible(originVisible);
        this.props.updateFlyoutVisibleState(this.workspace.getFlyout().isVisible());

        this.workspace.resize();
        this.workspace.recordCachedAreas();

        this.checkBlockLimit();
    }

    withToolboxUpdates(fn) {
        // if there is a queued toolbox update, we need to wait
        if (this.toolboxUpdateTimeout) {
            this.toolboxUpdateQueue.push(fn);
        } else {
            fn();
        }
    }

    attachVM() {
        this.ScratchBlocks.Toolbox.prototype.addChangeListener('setToolboxVisible', this.setToolboxVisible());
        this.workspace.addGuiBlockListener('setHasMaximizedComment', this.setHasMaximizedComment());
        this.workspace.addGuiBlockListener('showBlockHelp', this.handleShowBlockHelp());
        this.workspace.addGuiBlockListener('showAlertDialog', this.handleShowAlertDialog());
        this.workspace.addGuiBlockListener('startBlockDrag', this.handleStartBlockDrag());
        this.workspace.addGuiBlockListener('recordUndo', this.handleRecordUndo());
        this.workspace.addGuiBlockListener('updateRedoUndoButtonOpacity', this.handleUpdateRedoUndoButtonOpacity());
        this.workspace.addGuiBlockListener('updateZoomButtonOpacity', this.handleUpdateZoomButtonOpacity());
        this.workspace.addChangeListener(this.props.vm.blockListener);
        this.flyoutWorkspace = this.workspace
            .getFlyout()
            .getWorkspace();
        this.flyoutWorkspace.addChangeListener(this.props.vm.flyoutBlockListener);
        this.flyoutWorkspace.addChangeListener(this.props.vm.monitorBlockListener);
        this.props.vm.addListener('workspaceUpdate', this.onWorkspaceUpdate);
        this.props.vm.addListener('targetsUpdate', this.onTargetsUpdate);
        this.props.vm.addListener('EXTENSION_ADDED', this.handleExtensionAdded);
        this.props.vm.addListener('BLOCKSINFO_UPDATE', this.handleBlocksInfoUpdate);
        this.props.vm.addListener('PERIPHERAL_CONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.addListener('PERIPHERAL_DISCONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.addListener('BLOCKS_SELECT_INFO_UPDATE', this.handleSelectInfoChange);
        this.props.vm.addListener('BLOCKS_SELECT_LIST_INFO_UPDATE', this.handleSelectListInfoChange);
        this.props.vm.addListener('START_CLEAR_PROJECT_UNDO_STACK', this.handleClearUndo);
        this.props.vm.addListener('partialWorkspaceUpdate', this.onPartialWorkspaceUpdate);
        this.props.vm.addListener('sentenceUpdate', this.onSentenceUpdate);
        this.props.vm.addListener('brainTypeUpdate', this.onBrainTypeUpdate);
    }
    detachVM() {
        this.ScratchBlocks.Toolbox.prototype.removeChangeListener('setToolboxVisible');
        this.props.vm.removeListener('workspaceUpdate', this.onWorkspaceUpdate);
        this.props.vm.removeListener('targetsUpdate', this.onTargetsUpdate);
        this.props.vm.removeListener('EXTENSION_ADDED', this.handleExtensionAdded);
        this.props.vm.removeListener('BLOCKSINFO_UPDATE', this.handleBlocksInfoUpdate);
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.removeListener('BLOCKS_SELECT_INFO_UPDATE', this.handleSelectInfoChange);
        this.props.vm.removeListener('BLOCKS_SELECT_LIST_INFO_UPDATE', this.handleSelectListInfoChange);
        this.props.vm.removeListener('START_CLEAR_PROJECT_UNDO_STACK', this.handleClearUndo);
        this.props.vm.removeListener('sentenceUpdate', this.onSentenceUpdate);
        this.props.vm.removeListener('brainTypeUpdate', this.onBrainTypeUpdate);
    }
    handleShowBlockHelp() {
        return (blockType) => {
            console.log('handleShowBlockHelp: ' + blockType);
            this.props.setBlockHelpBlockTypeToState(blockType);
            if (blockType != 'show_all_block') {
                // set block help block type
            }
            if (store.get("firstHintBlockHelp")) {
                this.props.onRequestOpenBlockHelp();
            } else {
                store.set("firstHintBlockHelp", true);
                this.props.onRequestOpenHintBlockHelp();
            }
        };
    }

    handleShowAlertDialog() {
        return (type, msg, callback) => {
            this.props.onRequestOpenAlertDialog(type, msg, callback);
        }
    }

    handleRecordUndo() {
        return (event) => {
            if (this.props.isShowUserGuide) {
                if (this.props.userGuideCurrentState == USER_GUIDE_STATE.ADD_BLOCK_DRAGGING ||
                    this.props.userGuideCurrentState == USER_GUIDE_STATE.DELETE_BLOCK_DRAGGING ||
                    this.props.userGuideCurrentState == USER_GUIDE_STATE.LET_DEVICE_WORK_DRAGGING) {
                    var allBlocks = this.workspace.getAllBlocks(false);
                    this.checkUserGuideState(allBlocks);
                }
            } else if (this.props.isPickedBrainType) {
                this.updateBlockEditState(event.type == 'move');
            }
        }
    }

    handleStartBlockDrag() {
        return () => {
            this.props.onUpdateUserGuideState(this.props.userGuideCurrentState + 1);
        }
    }

    handleSelectInfoChange(data) {
        if (data.opcode === 'motion_spin') {
            this.updateMotionSpinValue(data.targetBlock, `${data.forward}`);
        } else if (data.opcode === 'motion_spindegree') {
            this.updateMotionSpinValue(data.targetBlock, `${data.forward}`);
        } else if (data.opcode === 'motion_3wire_spin') {
            this.updateMotionSpinValue(data.targetBlock, `${data.forward}`);
        }
    }

    handleSelectListInfoChange(data) {
        if (data.targetBlock === 'data_setlistto' || data.targetBlock === 'data_string_setlistto') {
            this.updateListFieldNum(data.targetBlock, `${data.selectedListLength}`);
        }
    }

    handleClearUndo() {
        this.workspace.clearUndo();
    }

    handleUpdateRedoUndoButtonOpacity() {
        return () => {
            this.setState({
                hasUndoStack: this.workspace ? this.workspace.hasUndoStack() : false,
                hasRedoStack: this.workspace ? this.workspace.hasRedoStack() : false
            })
        }
    }

    handleUpdateZoomButtonOpacity() {
        return (enableZoomIn, enableZoomOut) => {
            this.setState({
                enableZoomIn: enableZoomIn,
                enableZoomOut: enableZoomOut
            })
        }
    }

    updateToolboxBlockValue(id, value) {
        this.withToolboxUpdates(() => {
            const block = this.workspace
                .getFlyout()
                .getWorkspace()
                .getBlockById(id);
            if (block) {
                block.inputList[0].fieldRow[0].setValue(value);
            }
        });
    }

    updateMotionSpinValue(id, value) {
        this.withToolboxUpdates(() => {
            const block = this.workspace
                .getFlyout()
                .getWorkspace()
                .getBlockById(id);
            if (block) {
                let field;
                if (this.props.locale == 'en') {
                    field = block.inputList[0].fieldRow[2];
                } else {
                    field = block.inputList[0].fieldRow[1];
                }
                if (value) {
                    field.setText(value);
                }
                field.setValue('forward');
            }
        });
    }

    updateListFieldNum(id, value) {
        this.withToolboxUpdates(() => {
            const block = this.workspace
                .getFlyout()
                .getWorkspace()
                .getBlockById(id);
            if (block) {
                this.ScratchBlocks.WorkspaceSvg.prototype.setListToOptions(id, value);
            }
        });
    }

    updateSoundFieldValue(id, needResetFieldName, needResetName, resetValue) {
        this.withToolboxUpdates(() => {
            const block = this.workspace
                .getFlyout()
                .getWorkspace()
                .getBlockById(id);
            if (block) {
                let field = block.getField(needResetFieldName);
                if (field.getValue() === needResetName) {
                    field.setValue(resetValue);
                }
            }
        });
    }

    onTargetsUpdate() {
        if (this.props.vm.editingTarget && this.workspace.getFlyout()) {
            ['glide', 'move', 'set'].forEach(prefix => {
                this.updateToolboxBlockValue(`${prefix}x`, Math.round(this.props.vm.editingTarget.x).toString());
                this.updateToolboxBlockValue(`${prefix}y`, Math.round(this.props.vm.editingTarget.y).toString());
            });
        }
    }
    onWorkspaceMetricsChange() {
        const target = this.props.vm.editingTarget;
        if (target && target.id) {
            // Dispatch updateMetrics later, since onWorkspaceMetricsChange may be (very indirectly)
            // called from a reducer, i.e. when you create a custom procedure.
            // TODO: Is this a vehement hack?
            setTimeout(() => {
                this.props.updateMetrics({
                    targetID: target.id,
                    scrollX: this.workspace.scrollX,
                    scrollY: this.workspace.scrollY,
                    scale: this.workspace.scale,
                    undo: this.workspace.getUndoStack(),
                    redo: this.workspace.getRedoStack()
                });
            }, 0);
        }
    }

    onPartialWorkspaceUpdate() {
        this.workspace.refreshToolboxSelection_();
        this.updateIntentBlockUses();
    }

    onSentenceUpdate(data) {
        this.props.setImportSentence(data);
    }

    updateIntentBlockUses() {
        let intents = this.props.vm.getSpeakerIntentArray();
        let uses = [];
        let blocks = this.workspace.getAllBlocks();
        let intentName = "";
        let intentUsed = {};
        let intentField = null;
        let responseField = null;
        for (var i = 0; i < blocks.length; i++) {
            if (blocks[i].type == 'ai_speech_when_i_hear_intention_answer_with') {
                intentField = blocks[i].childBlocks_[0].inputList[0].fieldRow[0];
                responseField = blocks[i].childBlocks_[1].inputList[0].fieldRow[0];
                intentUsed = intents.filter(i => i.id == intentField.getValue());
                if (intentUsed && intentUsed.length) {
                    intentName = intentUsed[0].IntentName;
                    intentField.setText(intentName);
                    responseField.setText(this.ScratchBlocks.AISpeech.getResponseList()[intentField.getValue()]);
                } else {
                    blocks[i].dispose(true, true);
                }
            }
        }
        if (this.ScratchBlocks.Events.isEnabled()) {
            // group is set to true in "Blockly.AISpeech.makeDeleteOption"
            this.ScratchBlocks.Events.setGroup(false);
        } else {
            this.workspace.clearRedo();
            this.ScratchBlocks.Events.enable();
        }
        return uses;
    }

    updateBlockEditState(isIgnoreEvent) {
        if (!isIgnoreEvent && this.props.pickedBrainType != BRAIN_TYPE.WEB_VR) { // create or delete
            this.checkBlockLimit();
            this.verifyBlockName(this.workspace.getAllBlocks(false));
        }
        // TODO: if BLOCK_MODE and show editor
        // becuase of waiting project json update finish
        if (this.props.pickedBrainType != BRAIN_TYPE.EDU &&
            this.props.editorDisplayMode == EDIT_MODE.BLOCK_MODE && this.props.getCodeViewState) {
            setTimeout(() => { this.syncEditCode() });
        }
    }

    checkBlockLimit() {
        if (this.props.pickedBrainType == BRAIN_TYPE.EDU) {
            this.workspace.showBlockLimitWarning();
        } else {
            this.workspace.hideBlockLimitWarning();
        }
    }

    syncEditCode() {
        let jsonString = this.props.getProjectJson();
        const generator = new Converter(JSON.parse(jsonString));

        try {
            const code = generator.workspaceToCode();
            let pythonCode = this.props.getPythonCode();
            let isSameCode = Object.is(pythonCode, code);

            if (!isSameCode) {
                this.props.updateCode(code);
                this.props.vm.setPythonCode(code);
            }
        } catch (e) {
            if (e instanceof TypeError) {
                log.info('syncEditCode TypeError: ', e)
            } else {
                log.info('syncEditCode ConverterError: ', e)
            }
        }
    }

    verifyBlockNameInBlock(allBlocks, workspace) {
        this.verifyBlockName(allBlocks, workspace);
    }

    verifyBlockName(allBlocks, workspace = this.workspace) {
        let deviceNameWarningList = [];
        let wordNameWarningList = [];
        wordNameWarningList = this.verifyWordName(allBlocks);
        deviceNameWarningList = this.verifyDeviceName(allBlocks);
        workspace.showWaringSvg(deviceNameWarningList, wordNameWarningList);
    }

    verifyWordName(allBlocks) {
        let warningIntentList = this.ScratchBlocks.getWarningIntentList();
        let concepts = this.props.vm.getSpeakerConcepts();
        let needVerifyIntentName = ['INTENTION'];
        let needVerifyConceptName = ['CONCEPT'];
        let warningList = [];
        let childBlocks = [];
        let inputList = [];
        let fieldRow = [];
        allBlocks.forEach((block) => {
            if (block.type == 'ai_speech_when_i_hear_intention_answer_with') {
                if (warningList.indexOf(block.id) == -1) {
                    childBlocks = block.childBlocks_;
                    childBlocks.forEach((childBlock) => {
                        inputList = childBlock.inputList;
                        if (inputList) {
                            inputList.forEach((input) => {
                                fieldRow = input.fieldRow;
                                if (fieldRow) {
                                    fieldRow.forEach((field) => {
                                        if (needVerifyIntentName.indexOf(field.inputName_) != -1) {
                                            if (warningIntentList.indexOf(field.value_) != -1) {
                                                warningList.push(block.id);
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            } else if (block.type == 'ai_speech_first_concept_heard' || block.type == 'ai_speech_second_concept_heard') {
                if (warningList.indexOf(block.id) == -1) {
                    inputList = block.inputList;
                    if (inputList) {
                        inputList.forEach((input) => {
                            fieldRow = input.fieldRow;
                            if (fieldRow) {
                                fieldRow.forEach((field) => {
                                    if (needVerifyConceptName.indexOf(field.name) != -1) {
                                        if (!this.isConceptIdValid(field, concepts)) {
                                            warningList.push(block.id);
                                        }
                                    }
                                })
                            }
                        })
                    }
                }
            }
        });
        return warningList;
    }

    verifyDeviceName(allBlocks) {
        let needVerifyName = []
        if (this.props.pickedBrainType == BRAIN_TYPE.EDU) {
            needVerifyName = ['MOTORNAME', 'BUZZERNAME', 'BUMPERNAME', 'CONTROLLERNAME', 'LEDNAME', 'LINETRACKERNAME', 'SONARNAME']
        } else if (this.props.pickedBrainType == BRAIN_TYPE.ENTRY) {
            needVerifyName = ['MOTORNAME', 'COLORNAME', 'BUMPERNAME', 'CONTROLLERNAME', 'GYRONAME', 'TOUCHLEDNAME']
        } else if (this.props.pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
            needVerifyName = ['MOTORNAME', 'BUZZERNAME', 'BUMPERNAME', 'CONTROLLERNAME', 'LEDNAME', 'LINETRACKERNAME', 'SONARNAME', 'COLORNAME', 'GYRONAME', 'TOUCHLEDNAME']
        }
        let drivetrainBlockId = /drivetrain_./;
        let drivetrainSensingBlockId = /sensing_drivetrain./;
        let soundBlockId = /sound_./;
        let visionBlockId = /sensing_vision./;
        let deviceList = this.props.vm.getDeviceListForBlock();
        deviceList = deviceList.concat(this.getControllerNameList());
        deviceList = deviceList.concat(this.getGyroonDrivetrainNameList());
        let inputList = [];
        let fieldRow = [];
        let warningList = [];
        allBlocks.forEach((block) => {
            // Check special case
            if (!DEVICE_INFO.drivetrainInfo.hasDrivetrain && (drivetrainBlockId.test(block.type) || drivetrainSensingBlockId.test(block.type))) {
                warningList.push(block.id);
            } else if (DEVICE_INFO.drivetrainInfo.hasDrivetrain && !DEVICE_INFO.drivetrainInfo.hasDrivetrainwithGyro && this.getDrivetrainWithGyroBlocks().indexOf(block.type) != -1) {
                warningList.push(block.id);
            } else if (soundBlockId.test(block.type)) {
                if ((this.props.pickedBrainType == BRAIN_TYPE.EDU || this.props.pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) && !DEVICE_INFO.buzzerInfo.hasbuzzer) {
                    if (Object.keys(soundBlocks.EDU).indexOf(block.type) != -1) {
                        warningList.push(block.id);
                    }
                }
                if ((this.props.pickedBrainType == BRAIN_TYPE.ENTRY || this.props.pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) && !DEVICE_INFO.speakerInfo.hasspeaker) {
                    if (Object.keys(soundBlocks.Entry).indexOf(block.type) != -1) {
                        warningList.push(block.id);
                    }
                }
            } else if (visionBlockId.test(block.type) || block.type == 'sensing_detectingface' || block.type == 'sensing_facedetected') {
                if (warningList.indexOf(block.id) == -1) {
                    if (block.type == 'sensing_visionobjectexistsinthepicture') {
                        if (this.checkVisionColorPatternBlock(block)) {
                            warningList.push(block.id);
                        }
                    }
                    if (this.getVisionwithNameBlocks().indexOf(block.type) != -1) {
                        if (this.checkVisionFaceDetectBlock(block)) {
                            warningList.push(block.id);
                        }
                    }
                    let visionObj = deviceList.find(device => device.type == 'vision');
                    if (visionObj) {
                        let visionNameList = visionObj['nameList'];
                        if (visionNameList.length == 0) {
                            warningList.push(block.id);
                        }
                    } else {
                        warningList.push(block.id);
                    }
                }
            }

            // Check device name 
            if (warningList.indexOf(block.id) == -1) {
                inputList = block.inputList;
                if (inputList) {
                    inputList.forEach((input) => {
                        fieldRow = input.fieldRow;
                        if (fieldRow) {
                            fieldRow.forEach((field) => {
                                if (needVerifyName.indexOf(field.name) != -1) {
                                    if (!this.isDeviceNameValid(block.type, field.text_, deviceList)) {
                                        warningList.push(block.id);
                                    }
                                }
                            })
                        }
                    })
                }
            }
        })
        return warningList;
    }

    checkVisionFaceDetectBlock(block) {
        let visionFaceNames = this.props.vm.getCvProfiles().filter(profile => profile.feature.length > 0).map(profile => profile.name);
        if (visionFaceNames.length == 0) {
            visionFaceNames.push(this.ScratchBlocks.Msg.SENSING_VISIONWHOM);
        }

        let inputList = block.inputList;
        let fieldRow = [];
        let warning = false;
        if (inputList) {
            inputList.forEach((input) => {
                fieldRow = input.fieldRow;
                if (fieldRow) {
                    fieldRow.forEach((field) => {
                        if (field.name == 'FACENAME') {
                            if (visionFaceNames.indexOf(field.text_) == -1) {
                                if (DEVICE_INFO.visionInfo.hasvision && field.text_ == this.ScratchBlocks.Msg.SENSING_VISIONWHOM && visionFaceNames.length > 0) {
                                    this.withToolboxUpdates(() => {
                                        field.setValue(visionFaceNames[0]);
                                    });
                                } else {
                                    warning = true;
                                }
                            }
                        }
                    })
                }
            })
        }
        return warning;
    }

    checkVisionColorPatternBlock(block) {
        let visionTags = this.props.vm.getCvTagSettings().filter(tag => tag.color_name != null).map(tag => tag.color_name);
        visionTags.forEach((tagName, index) => {
            visionTags[index] = VISION_DEFAULT_LANGUAGE_MAPPING[this.props.locale][tagName] || tagName;
        })
        visionTags = [this.ScratchBlocks.Msg.SENSING_VISION_ANY_TAGGED].concat(visionTags);

        let inputList = block.inputList;
        let fieldRow = [];
        let warning = false;
        if (inputList) {
            inputList.forEach((input) => {
                fieldRow = input.fieldRow;
                if (fieldRow) {
                    fieldRow.forEach((field) => {
                        if (field.name == 'TAG') {
                            if (visionTags.indexOf(field.text_) == -1) {
                                if (DEVICE_INFO.visionInfo.hasvision && visionTags.length > 0) {
                                    this.withToolboxUpdates(() => {
                                        field.setValue(visionTags[0]);
                                    });
                                } else {
                                    warning = true;
                                }
                            }
                        }
                    })
                }
            })
        }
        return warning;
    }

    isDeviceNameValid(type, name, deviceList) {
        let position = -1;
        let isValid = false;
        for (let i = 0; i < deviceList.length; i++) {
            position = deviceList[i].nameList.indexOf(name);
            if (position != -1) {
                isValid = true;
                break;
            }
        }
        return isValid;
    }

    isConceptIdValid(field, conceptList) {
        let value = field.getValue()
        let position = -1;
        let isValid = false;
        for (let i = 0; i < conceptList.length; i++) {
            position = conceptList[i].id.indexOf(value);
            if (position != -1) {
                isValid = true;
                field.setText(conceptList[i].tag);
                break;
            }
        }
        return isValid;
    }

    getDrivetrainWithGyroBlocks() {
        return [
            'drivetrain_turntoheading',
            'drivetrain_turntorotation',
            'drivetrain_setdriveheadingto',
            'drivetrain_setdriverotationto',
            'sensing_drivetraindriveheading',
            'sensing_drivetraindriverotation'
        ];
    }

    getVisionwithNameBlocks() {
        return [
            'sensing_visionInThePicture',
            'sensing_visionProbabilityOfInThePicture'
        ];
    }

    getNoNameDeviceExist(deviceType) {
        let isDeviceExist = false;
        let deviceNameList = this.props.vm.getDeviceListForBlock();
        if (deviceNameList.length > 0) {
            for (let i = 0; i < deviceNameList.length; i++) {
                if (deviceNameList[i].type == deviceType) {
                    isDeviceExist = !!deviceNameList[i].nameList.length
                    break;
                }
            }
        }
        return isDeviceExist;
    }

    getControllerNameList() {
        let controllerListFromVM = this.props.vm.getControllerList();
        let controllerNameList = [];
        controllerListFromVM.forEach((controller, i) => {
            if (controller != null) {
                controllerNameList.push(this.ScratchBlocks.Msg.DEFAULT_NAME_CONTROLLER + (i + 1))
            }
        })
        return {
            type: 'controller',
            nameList: controllerNameList
        };
    }

    getGyroonDrivetrainNameList() {
        let drivetrainFromVM = this.props.vm.getDrivetrain();
        let gyroNameList = [];
        if (drivetrainFromVM && drivetrainFromVM.connectPortArray.length > 2 && drivetrainFromVM.other.gyro) {
            gyroNameList.push(drivetrainFromVM.other.gyroName);
        }
        return {
            type: 'gyro_on_drivetrain',
            nameList: gyroNameList
        };
    }

    checkUserGuideState(allBlocks) {
        switch (this.props.userGuideCurrentState) {
            case USER_GUIDE_STATE.ADD_BLOCK_DRAGGING:
                if (allBlocks.length == 3) {
                    var startBlock;
                    for (var block of allBlocks) {
                        if (block.type == "event_whenstarted") {
                            startBlock = block;
                            break;
                        }
                    }
                    if (startBlock) {
                        var childBlocks = startBlock.childBlocks_;
                        if (childBlocks.length == 1) {
                            if (childBlocks[0].type == "control_wait") {
                                this.props.onUpdateUserGuideState(this.props.userGuideCurrentState + 1);
                            }
                        } else {
                            this.props.onUpdateUserGuideState(this.props.userGuideCurrentState - 1);
                            this.workspace.undo(false);
                        }
                    }
                }
                break;
            case USER_GUIDE_STATE.DELETE_BLOCK_DRAGGING:
                if (allBlocks.length == 1) {
                    if (allBlocks[0].type == "event_whenstarted") {
                        this.props.onUpdateUserGuideState(this.props.userGuideCurrentState + 1);
                    }
                } else if (allBlocks.length == 3) { // when block is not deleted
                    var startBlock;
                    for (var block of allBlocks) {
                        if (block.type == "event_whenstarted") {
                            startBlock = block;
                            break;
                        }
                    }
                    if (startBlock) {
                        var childBlocks = startBlock.childBlocks_;
                        if (childBlocks.length != 1) {
                            this.workspace.undo(false);
                        } else {
                            this.props.onUpdateUserGuideState(this.props.userGuideCurrentState - 1);
                        }
                    }
                }
                break;
            case USER_GUIDE_STATE.LET_DEVICE_WORK_DRAGGING:
                if (allBlocks.length == 2) {
                    var startBlock;
                    for (var block of allBlocks) {
                        if (block.type == "event_whenstarted") {
                            startBlock = block;
                            break;
                        }
                    }
                    if (startBlock) {
                        var childBlocks = startBlock.childBlocks_;
                        if (childBlocks.length == 1) {
                            let connectedBlock = this.props.pickedBrainType == BRAIN_TYPE.EDU ? "motion_3wire_spin" : "motion_spin";
                            if (childBlocks[0].type == connectedBlock) {
                                this.props.onUpdateUserGuideState(this.props.userGuideCurrentState + 1);
                            }
                        } else {
                            this.props.onUpdateUserGuideState(this.props.userGuideCurrentState - 1);
                            this.workspace.undo(false);
                        }
                    }
                }
                break;
        }
    }

    onVisualReport(data) {
        this.workspace.reportValue(data.id, data.value);
    }
    getVMTarget() {
        let { editingTarget: target, runtime } = this.props.vm;
        const stage = runtime.getTargetForStage();
        if (!target) target = stage; // If no editingTarget, use the stage
        return target;
    }
    getToolboxXML() {
        // Use try/catch because this requires digging pretty deep into the VM
        // Code inside intentionally ignores several error situations (no stage, etc.)
        // Because they would get caught by this try/catch
        try {
            let { editingTarget: target, runtime } = this.props.vm;
            const stage = runtime.getTargetForStage();
            if (!target) target = stage; // If no editingTarget, use the stage
            const targetSounds = target.getSounds();

            const dynamicBlocksXML = this.props.vm.runtime.getBlocksXML();
            // EDU
            this.setThreeWireMotorInfo();
            this.setDeviceInfo('lineTracker');
            this.setDeviceInfo('buzzer');
            this.setDeviceInfo('led');
            this.setDeviceInfo('ultrasonic');
            this.setDeviceInfo('bumper');
            this.setDrivetrainInfo();

            // Entry
            this.setControllerInfo();
            this.setMotorInfo();
            this.setDeviceInfo('lightSensor');
            this.setDeviceInfo('touchLed');
            this.setDeviceInfo('gyro');
            this.setDeviceInfo('colorSensor');
            this.setDeviceInfo('speaker');
            this.setDeviceInfo('vision');

            if (this.props.pickedBrainType == BRAIN_TYPE.WEB_VR) {
                this.openWebVRSensor();
            }

            return makeToolboxXML(this.workspace, this.props.pickedBrainType, target.id, dynamicBlocksXML, targetSounds, DEVICE_INFO, this.handleAudioManagementStart, this.props.isEnableAISpeech);
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    onWorkspaceUpdate(data) {
        // When we change sprites, update the toolbox to have the new sprite's blocks
        const toolboxXML = this.getToolboxXML();
        if (toolboxXML) {
            this.props.updateToolboxState(toolboxXML);
        }

        if (this.props.vm.editingTarget && !this.props.workspaceMetrics.targets[this.props.vm.editingTarget.id]) {
            this.onWorkspaceMetricsChange();
        }

        // Remove and reattach the workspace listener (but allow flyout events)
        this.workspace.removeChangeListener(this.props.vm.blockListener);

        const dom = this.ScratchBlocks.Xml.textToDom(data.xml);
        try {
            this.ScratchBlocks.Xml.clearWorkspaceAndLoadFromXml(dom, this.workspace);
        } catch (error) {
            // The workspace is likely incomplete. What did update should be
            // functional.
            //
            // Instead of throwing the error, by logging it and continuing as
            // normal lets the other workspace update processes complete in the
            // gui and vm, which lets the vm run even if the workspace is
            // incomplete. Throwing the error would keep things like setting the
            // correct editing target from happening which can interfere with
            // some blocks and processes in the vm.
            if (error.message) {
                error.message = `Workspace Update Error: ${error.message}`;
            }
            log.error(error);
        }
        this.workspace.addChangeListener(this.props.vm.blockListener);

        if (this.props.vm.editingTarget && this.props.workspaceMetrics.targets[this.props.vm.editingTarget.id]) {
            const { scrollX, scrollY, scale, redo, undo } = this.props.workspaceMetrics.targets[this.props.vm.editingTarget.id];
            this.workspace.scrollX = scrollX;
            this.workspace.scrollY = scrollY;
            this.workspace.scale = scale;
            this.workspace.resize();
            redo.forEach((element) => {
                element.workspaceId = this.workspace.id;
            })
            this.workspace.setRedoStack(redo);
            undo.forEach((element) => {
                element.workspaceId = this.workspace.id;
            })
            this.workspace.setUndoStack(undo);
        }

        // this.props.pickedBrainType is not sync when project loading

        //Sync Workspace Block to SyncEditCode
        // TODO: if BLOCK_MODE and show editor
        // setTimeout(() => {this.syncEditCode()});
    }
    onBrainTypeUpdate() {
        console.log("onBrainTypeUpdate setPickedBrainType: ", this.props.vm.getBrainType());
        this.props.setPickedBrainType(this.props.vm.getBrainType());
    }

    handleExtensionAdded(categoryInfo) {
        const defineBlocks = blockInfoArray => {
            if (blockInfoArray && blockInfoArray.length > 0) {
                const staticBlocksJson = [];
                const dynamicBlocksInfo = [];
                blockInfoArray.forEach(blockInfo => {
                    if (blockInfo.info && blockInfo.info.isDynamic) {
                        dynamicBlocksInfo.push(blockInfo);
                    } else if (blockInfo.json) {
                        staticBlocksJson.push(blockInfo.json);
                    }
                    // otherwise it's a non-block entry such as '---'
                });

                this.ScratchBlocks.defineBlocksWithJsonArray(staticBlocksJson);
                dynamicBlocksInfo.forEach(blockInfo => {
                    // This is creating the block factory / constructor -- NOT a specific instance of the block.
                    // The factory should only know static info about the block: the category info and the opcode.
                    // Anything else will be picked up from the XML attached to the block instance.
                    const extendedOpcode = `${categoryInfo.id}_${blockInfo.info.opcode}`;
                    const blockDefinition =
                        defineDynamicBlock(this.ScratchBlocks, categoryInfo, blockInfo, extendedOpcode);
                    this.ScratchBlocks.Blocks[extendedOpcode] = blockDefinition;
                });
            }
        };

        // scratch-blocks implements a menu or custom field as a special kind of block ("shadow" block)
        // these actually define blocks and MUST run regardless of the UI state
        defineBlocks(
            Object.getOwnPropertyNames(categoryInfo.customFieldTypes)
                .map(fieldTypeName => categoryInfo.customFieldTypes[fieldTypeName].scratchBlocksDefinition));
        defineBlocks(categoryInfo.menus);
        defineBlocks(categoryInfo.blocks);

        // Update the toolbox with new blocks if possible
        const toolboxXML = this.getToolboxXML();
        if (toolboxXML) {
            this.props.updateToolboxState(toolboxXML);
        }
    }
    handleBlocksInfoUpdate(categoryInfo) {
        // @todo Later we should replace this to avoid all the warnings from redefining blocks.
        this.handleExtensionAdded(categoryInfo);
    }
    handleCategorySelected(categoryId) {
        const extension = extensionData.find(ext => ext.extensionId === categoryId);

        this.withToolboxUpdates(() => {
            this.workspace.toolbox_.setSelectedCategoryById(categoryId);
        });
    }
    setBlocks(blocks) {
        this.blocks = blocks;
    }
    handlePromptStart(message, defaultValue, callback, optTitle, optVarType) {
        const p = { prompt: { callback, message, defaultValue } };
        p.prompt.title = optTitle ? optTitle :
            this.ScratchBlocks.Msg.VARIABLE_MODAL_TITLE;
        p.prompt.varType = typeof optVarType === 'string' ?
            optVarType : this.ScratchBlocks.SCALAR_VARIABLE_TYPE;
        p.prompt.showListOptions = p.prompt.title == this.ScratchBlocks.Msg.LIST_MODAL_TITLE;

        let variableNames = (optVarType == this.ScratchBlocks.BROADCAST_MESSAGE_VARIABLE_TYPE)
            ? this.workspace.getVariablesOfType(optVarType).map((item) => {
                if (item.name != defaultValue) {
                    return item.name;
                }
            })
            : this.workspace.getAllVariables().map((item) => {
                if (item.type != this.ScratchBlocks.BROADCAST_MESSAGE_VARIABLE_TYPE && item.name != defaultValue) {
                    return item.name
                }
            });
        p.prompt.names = variableNames;
        this.setState(p);
    }
    handleStatusButtonUpdate() {
        this.ScratchBlocks.refreshStatusButtons(this.workspace);
    }
    handleOpenSoundRecorder(e, file, setNameCallback = () => { }) {
        let currentAudioSize = this.props.vm.getCurrentAudioSize();
        console.log(`handleOpenSoundRecorder fileType: ${file.type} currentAudioSize: ${currentAudioSize}`)
        if (!this.isValidSoundType(file.type) || file.size > AUDIO_SIZE_UPPER_BOUND || (file.size + currentAudioSize) > AUDIO_SIZE_UPPER_BOUND) {
            this.props.showAudioUpperBoundErrorDialog(errorType.OVER_AUDIO_SIZE_UPPER_BOUND);
            return;
        }
        this.props.showAudioLoadingBar(true, 0);
        const storage = this.props.vm.runtime.storage;
        handleFileUpload(e.target, currentAudioSize, (buffer, fileType, fileName, fileIndex, fileCount) => {
            let newUnusedName = this.props.vm.getSoundName(fileName);
            soundUpload(buffer, fileType, storage, newSound => {
                newSound.name = newUnusedName;
                newSound.size = file.size;
                this.props.vm.addSound(newSound).then(() => {
                    console.log(`handleFileUpload loaded`)
                    setTimeout(() => {
                        this.props.showAudioLoadingBar(false, 0);
                    }, 1000)

                    const toolboxXML = this.getToolboxXML();
                    if (toolboxXML) {
                        this.props.updateToolboxState(toolboxXML);
                    }
                });
                let { editingTarget: target, runtime } = this.props.vm;
                const stage = runtime.getTargetForStage();
                if (!target) target = stage; // If no editingTarget, use the stage
                setNameCallback(newUnusedName);
            }, newUnusedName);
        }, () => {
            console.log(`handleFileUpload onerror`)
            this.props.showAudioLoadingBar(false, 0);
            setNameCallback();
            this.props.showAudioUpperBoundErrorDialog(errorType.OVER_AUDIO_SIZE_UPPER_BOUND);
        }, (progress) => {
            console.log(`handleFileUpload onprogress: `, progress)
            this.props.showAudioLoadingBar(true, progress);
        }, (allData) => {
            console.log(`handleFileUpload setCurrentAudioSize: ${allData}`)
            this.props.vm.setCurrentAudioSize(allData);
        });
    }

    showUpperBoundErrorDialog() {
        if (this.props.pickedBrainType != BRAIN_TYPE.EDU) {
            this.props.onShowErrorDialog(errorType.OVER_EVENT_STARTED_UPPER_BOUND);
        } else {
            this.props.onShowErrorDialog(errorType.OVER_EVENT_STARTED_UPPER_BOUND_EDU);
        }
    }

    isValidSoundType(type) {
        return type == 'audio/mp3' ||
            type == 'audio/mpeg' ||
            type == 'audio/wav' ||
            type == 'audio/wave' ||
            type == 'audio/x-wav' ||
            type == 'audio/x-pn-wav';
    }

    handleDeleteSoundRecorder(selectedName) {
        if (selectedName) {
            let { editingTarget: target, runtime } = this.props.vm;
            const stage = runtime.getTargetForStage();
            if (!target) target = stage; // If no editingTarget, use the stage
            const targetSounds = target.getSounds();
            for (let selectedItemIndex = 0; selectedItemIndex < targetSounds.length; selectedItemIndex++) {
                if (targetSounds[selectedItemIndex].name === selectedName) {
                    console.log(`handleDeleteSoundRecorder sound size: `, targetSounds[selectedItemIndex].size)
                    this.props.vm.setCurrentAudioSize(this.props.vm.getCurrentAudioSize() - targetSounds[selectedItemIndex].size);
                    this.props.vm.deleteSound(selectedItemIndex);
                    break;
                }
            }
            let resetValue = targetSounds.length > 0 ? targetSounds[0].name : this.ScratchBlocks.Msg.SHEETMUSIC_IMPORT_FILE;
            this.updateSoundFieldValue('sound_sounds_menu', 'SOUND_MENU', selectedName, resetValue);
            this.resetDropdownName('sound_sounds_menu', 'SOUND_MENU', selectedName, resetValue);
            const toolboxXML = this.getToolboxXML();
            if (toolboxXML) {
                this.props.updateToolboxState(toolboxXML);
            }
        }
    }

    resetDropdownName(needResetFieldType, needResetFieldName, needResetName, resetValue) {
        this.workspace.getAllBlocks(false).forEach((block) => {
            if (block.type == needResetFieldType) {
                let field = block.getField(needResetFieldName);
                if (field.getValue() === needResetName) {
                    field.setValue(resetValue);
                }
            }
        })
    }

    /*
     * Pass along information about proposed name and variable options (scope and isCloud)
     * and additional potentially conflicting variable names from the VM
     * to the variable validation prompt callback used in scratch-blocks.
     */
    handlePromptCallback(input, variableOptions) {
        let variableNames = (this.state.prompt.varType == this.ScratchBlocks.BROADCAST_MESSAGE_VARIABLE_TYPE)
            ? this.props.vm.runtime.getAllVarNamesOfType(this.state.prompt.varType)
            : this.props.vm.runtime.getAllVariableNamesWithoutBroadcast();
        this.state.prompt.callback(
            input,
            variableNames,
            variableOptions);
        this.handlePromptClose();
    }
    handlePromptClose() {
        this.setState({ prompt: null });
    }
    handleReloadCachedAreasOpen() {
        this.setState({ reloadCachedAreas: true });
    }
    handleReloadCachedAreasClose() {
        this.setState({ reloadCachedAreas: false });
    }
    handleCustomProceduresClose(data) {
        this.props.onRequestCloseCustomProcedures(data);
        const ws = this.workspace;
        ws.setToolboxRefreshEnabled(true);
        ws.refreshToolboxSelection_();
        ws.toolbox_.scrollToCategoryById('myBlocks');
        ws.setToolboxRefreshEnabled(false);
    }
    handleDrop(dragInfo) {
        fetch(dragInfo.payload.bodyUrl)
            .then(response => response.json())
            .then(blocks => this.props.vm.shareBlocksToTarget(blocks, this.props.vm.editingTarget.id))
            .then(() => {
                this.props.vm.refreshWorkspace();
                this.updateToolbox(); // To show new variables/custom blocks
            });
    }
    handleFlyoutArrowLeftButton() {
        return () => {
            this.workspace.onClearWidget();
            this.workspace.toolbox_.flyout_.setVisible(false);
            if (this.workspace.toolbox_.selectedItem_) {
                this.workspace.toolbox_.selectedItem_.setSelected(false);
            }
            this.props.updateFlyoutVisibleState(false);
            this.workspace.resize();
            this.handleReloadCachedAreasOpen(true);
            this.handleCloseButton(this);
        };
    }
    handleToolboxArrowLeftButton() {
        return () => {
            if (isPad() && !this.state.padCloseisExpand) {
                return;
            }
            this.workspace.onClearWidget();
            this.workspace.toolbox_.setVisible(false);
            this.props.updateToolboxVisibleState(false);
            this.workspace.resize();
            this.handleReloadCachedAreasOpen(true);
            this.handleCloseButton(this);
        };
    }
    handleToolboxArrowRightButton() {
        return () => {
            if (isPad() && !this.state.padCloseisExpand) {
                return;
            }
            this.workspace.onClearWidget();
            this.workspace.toolbox_.flyout_.setVisible(true);
            if (this.workspace.toolbox_.selectedItem_) {
                this.workspace.toolbox_.selectedItem_.setSelected(true);
            } else {
                this.workspace.toolbox_.setSelectedItem(this.workspace.toolbox_.categoryMenu_.categories_[0]);
            }
            this.props.updateFlyoutVisibleState(true);
            this.workspace.resize();
            this.handleReloadCachedAreasOpen(true);
            this.handleCloseButton(this);
        };
    }
    handleWorkspaceArrowRightButton() {
        return () => {
            this.workspace.onClearWidget();
            this.workspace.toolbox_.setVisible(true);
            this.props.updateToolboxVisibleState(true);
            this.workspace.resize();
            this.handleReloadCachedAreasOpen(true);
            this.handleCloseButton(this);
        };
    }
    handlePadCloseExpand() {
        return () => {
            this.setState({
                padCloseisExpand: true
            });
        };
    }
    handleCloseButton(blocks) {
        blocks.setState({
            padCloseisExpand: false
        });
    }
    handleDocumentBodyClick(event) {
        if (!this.padCloseButton.contains(event.target)) {
            this.handleCloseButton(this);
        }
    }
    setArrowClassNamestyles() {
        if (isPad()) {
            if (this.props.isFlyoutVisible && this.props.isToolboxVisible) {
                return styles.flyout_drawer_left_pad;
            } else if (!this.props.isFlyoutVisible && this.props.isToolboxVisible) {
                document.body.addEventListener('click', this.handleDocumentBodyClick, true);
                return styles.toolbox_drawer_left_pad;
            } else if (!this.props.isFlyoutVisible && !this.props.isToolboxVisible) {
                return styles.workspace_drawer_left_pad;
            }
        } else {
            if (this.props.isFlyoutVisible && this.props.isToolboxVisible) {
                return styles.flyout_drawer_left;
            } else if (!this.props.isFlyoutVisible && this.props.isToolboxVisible) {
                return styles.toolbox_drawer_left;
            } else if (!this.props.isFlyoutVisible && !this.props.isToolboxVisible) {
                return styles.workspace_drawer_left;
            }
        }
    }
    setToolboxVisible() {
        return () => {
            this.props.updateFlyoutVisibleState(this.workspace.getFlyout().isVisible());
        };
    }
    setHasMaximizedComment() {
        return () => {
            this.props.updateHasMaximizedCommentState(this.workspace.hasMaximizedComment());
        };
    }
    openWebVRSensor() {
        DEVICE_INFO.drivetrainInfo.hasDrivetrain = true;
        DEVICE_INFO.ledInfo.hasled = true;
        DEVICE_INFO.buzzerInfo.hasbuzzer = true;
        DEVICE_INFO.bumperInfo.hasbumper = true;
        DEVICE_INFO.lineTrackerInfo.haslineTracker = true;
        DEVICE_INFO.ultrasonicInfo.hasultrasonic = true;
    }
    setDeviceInfo(deviceType = '') {
        let deviceNameList = this.props.vm.getDeviceListForBlock();
        let deviceData = deviceNameList.find(device => device.type == deviceType);
        if (deviceNameList.length > 0 && deviceData) {
            if (deviceData.nameList == undefined) {
                DEVICE_INFO[`${deviceType}Info`][`has${deviceType}`] = false;
                DEVICE_INFO[`${deviceType}Info`][`${deviceType}Name`] = EditUtils.getLocaleString("gui.device.no.device");
            } else {
                DEVICE_INFO[`${deviceType}Info`][`has${deviceType}`] = (deviceData.nameList.length > 0 ? true : false);
                DEVICE_INFO[`${deviceType}Info`][`${deviceType}Name`] = deviceData.nameList[0];
            }
        } else {
            DEVICE_INFO[`${deviceType}Info`][`has${deviceType}`] = false;
            DEVICE_INFO[`${deviceType}Info`][`${deviceType}Name`] = EditUtils.getLocaleString("gui.device.no.device");
        }
    }
    setDrivetrainInfo() {
        let drivetrainList = this.props.vm.getDrivetrain();
        if (drivetrainList) {
            DEVICE_INFO.drivetrainInfo.hasDrivetrain = true;
            DEVICE_INFO.drivetrainInfo.hasDrivetrainwithGyro = drivetrainList.connectPortArray.length > 2 && drivetrainList.other.gyro;
            DEVICE_INFO.drivetrainInfo.drivetrainName = drivetrainList.name;
        } else {
            DEVICE_INFO.drivetrainInfo.hasDrivetrain = false;
            DEVICE_INFO.drivetrainInfo.hasDrivetrainwithGyro = false;
            DEVICE_INFO.drivetrainInfo.drivetrainName = EditUtils.getLocaleString("gui.device.no.device");
        }
    }
    setMotorInfo() {
        let motorList = this.props.vm.getMotor100List().concat(this.props.vm.getMotor300List());
        DEVICE_INFO.motorInfo.hasMotor = (motorList.length > 0 ? true : false);
        DEVICE_INFO.motorInfo.motorName = (motorList.length > 0 ? motorList[0].name : EditUtils.getLocaleString("gui.device.no.device"));
    }
    setControllerInfo() {
        let controllerListFromVM = this.props.vm.getControllerList();
        let controllerExistList = controllerListFromVM.filter(controller => controller != null);
        DEVICE_INFO.controllerInfo.hasController = !!controllerExistList.length;
    }
    setThreeWireMotorInfo() {
        let motorList = this.props.vm.getThreeWireMotorList();
        DEVICE_INFO.threeWireMotorInfo.hasThreeWireMotor = (motorList.length > 0 ? true : false);
        DEVICE_INFO.threeWireMotorInfo.threeWireMotorName = (motorList.length > 0 ? motorList[0].name : EditUtils.getLocaleString("gui.device.no.device"));
    }

    getSoundUsesByName(name) {
        var uses = [];
        var blocks = this.workspace.getAllBlocks();
        for (var i = 0; i < blocks.length; i++) {
            var blockAudio = blocks[i].getField("SOUND_MENU");
            if (blockAudio) {
                if (blockAudio.getValue() == name) {
                    uses.push(blocks[i]);
                }
            }
        }
        return uses;
    };

    manageAudioDataCallback(modifyAudioList) {
        let target = this.getVMTarget();
        const targetSounds = target.getSounds();

        let needToDeleteAudioList = [];
        let isCheck = false;
        let needResetName;
        for (var i = 0; i < targetSounds.length; i++) {
            for (var j = 0; j < modifyAudioList.length; j++) {
                if (targetSounds[i].assetId == modifyAudioList[j].id) {
                    isCheck = true;
                    needResetName = targetSounds[i].name;
                    this.props.vm.renameSound(i, modifyAudioList[j].name);
                    this.updateSoundFieldValue('sound_sounds_menu', 'SOUND_MENU', needResetName, modifyAudioList[j].name)
                    this.resetDropdownName('sound_sounds_menu', 'SOUND_MENU', needResetName, modifyAudioList[j].name);
                }
            }
            if (!isCheck) {
                needToDeleteAudioList.push(targetSounds[i].name);
            }
            isCheck = false;
        }
        needToDeleteAudioList.forEach((name) => {
            this.handleDeleteSoundRecorder(name);
        });
    }

    handleAudioManagementStart(callback, type) {
        let { editingTarget: target, runtime } = this.props.vm;
        const stage = runtime.getTargetForStage();
        if (!target) target = stage; // If no editingTarget, use the stage
        const targetSounds = target.getSounds();

        this.setState({
            editBlock: {
                type: type,
                data: targetSounds.map((item) => ({
                    id: item.assetId, name: item.name, listLength: 0, count: this.getSoundUsesByName(item.name).length, type: editBlockType.sound_audio
                })),
                callback: this.manageAudioDataCallback,
                nonData: []
            }
        });
        this.props.showEditBlockDialog();
    }

    handleEditPromptStart(callback, optVarType) {
        console.log("handleEditPromptStart");

        let data = [];
        let appendData = [];
        let nonData = [];
        if (optVarType == editBlockType.number_list) {
            data = this.workspace.getVariablesOfType(editBlockType.number_list).map((item) => ({
                id: item.id_, name: item.name, listLength: item.listLength, count: this.workspace.getVariableUsesById(item.id_).length,
                type: editBlockType.number_list
            }));

            appendData = this.workspace.getVariablesOfType(editBlockType.string_list).map((item) => ({
                id: item.id_, name: item.name, listLength: item.listLength, count: this.workspace.getVariableUsesById(item.id_).length,
                type: editBlockType.string_list
            }));
            if (appendData.length > 0) {
                data = data.concat(appendData);
            }

            nonData = this.workspace.getVariablesOfType().map((item) => ({
                id: item.id_, name: item.name, listLength: item.listLength, count: this.workspace.getVariableUsesById(item.id_).length,
                type: editBlockType.number_variable
            }))
            appendData = this.workspace.getVariablesOfType(editBlockType.string_variable).map((item) => ({
                id: item.id_, name: item.name, listLength: item.listLength, count: this.workspace.getVariableUsesById(item.id_).length,
                type: editBlockType.string_variable
            }))
            if (appendData.length > 0) {
                nonData = nonData.concat(appendData);
            }
            appendData = this.workspace.getVariablesOfType(editBlockType.boolean_variable).map((item) => ({
                id: item.id_, name: item.name, listLength: item.listLength, count: this.workspace.getVariableUsesById(item.id_).length,
                type: editBlockType.boolean_variable
            }))
            if (appendData.length > 0) {
                nonData = nonData.concat(appendData);
            }
        } else {
            data = this.workspace.getVariablesOfType().map((item) => ({
                id: item.id_, name: item.name, listLength: item.listLength, count: this.workspace.getVariableUsesById(item.id_).length,
                type: editBlockType.number_variable
            }))
            appendData = this.workspace.getVariablesOfType(editBlockType.string_variable).map((item) => ({
                id: item.id_, name: item.name, listLength: item.listLength, count: this.workspace.getVariableUsesById(item.id_).length,
                type: editBlockType.string_variable
            }))
            if (appendData.length > 0) {
                data = data.concat(appendData);
            }
            appendData = this.workspace.getVariablesOfType(editBlockType.boolean_variable).map((item) => ({
                id: item.id_, name: item.name, listLength: item.listLength, count: this.workspace.getVariableUsesById(item.id_).length,
                type: editBlockType.boolean_variable
            }))
            if (appendData.length > 0) {
                data = data.concat(appendData);
            }

            nonData = this.workspace.getVariablesOfType(editBlockType.number_list).map((item) => ({
                id: item.id_, name: item.name, listLength: item.listLength, count: this.workspace.getVariableUsesById(item.id_).length,
                type: editBlockType.number_list
            }));

            appendData = this.workspace.getVariablesOfType(editBlockType.string_list).map((item) => ({
                id: item.id_, name: item.name, listLength: item.listLength, count: this.workspace.getVariableUsesById(item.id_).length,
                type: editBlockType.string_list
            }));
            if (appendData.length > 0) {
                nonData = nonData.concat(appendData);
            }
        }

        console.log(`handleEditPromptStart optVarType: ${optVarType}, data: ${JSON.stringify(data)}`);

        this.setState({
            editBlock: {
                type: optVarType,
                data: data,
                callback: callback,
                nonData: nonData
            }
        });
        this.props.showEditBlockDialog();
    }

    handleEditBlockConfirm(data) {
        console.log("handleEditBlockConfirm data = ", data);
        this.props.hideEditBlockDialog();
        this.state.editBlock.callback(data);
    }

    handleEditBlockClose() {
        console.log("handleEditBlockClose");
        this.props.hideEditBlockDialog();
    }

    updateHighlightBlock(blockId) {
        console.log("updateHighlightBlock blockId = ", blockId);
        this.props.setHighLightBlockId(blockId);
    }

    clearHighlightBlock(blockId) {
        console.log("clearHighlightBlock blockId = ", blockId);
        this.props.setHighLightBlockId(null);
        this.props.setHighLightField(null);
    }

    updateHighlightField(fieldName) {
        console.log("updateHighlightFiled fieldName = ", fieldName);
        this.props.setHighLightField(fieldName);
    }

    clearHighlightField(fieldName) {
        console.log("clearHighlightFiled fieldName = ", fieldName);
        this.props.setHighLightField(null);
    }

    handlePadCloseRef(current) {
        this.padCloseButton = current;
    }

    setFileUploader(sbfuploader) {
        this.sbfuploader = sbfuploader;
    }

    importSpeakerIntent() {
        if (getPlatform() == platformType.Web) {
            this.sbfuploader.click();
        } else {
            this.props.onClickImportSentences();
        }
    }

    addStretchButton() {
        if (isPad()) {
            let arrowClassName = this.setArrowClassNamestyles();
            return (
                <div id="flyoutLeftButton" className={this.props.hasMaximizedComment ? styles.btn_hide : arrowClassName} ref={this.handlePadCloseRef}>
                    {this.props.isShowUserGuide ? null :
                        ((this.props.isFlyoutVisible && this.props.isToolboxVisible) ?
                            <div className={styles.flyoutLeftButtonPad}
                                onClick={this.handleFlyoutArrowLeftButton()}>
                            </div> :
                            ((!this.props.isFlyoutVisible && this.props.isToolboxVisible) ?
                                <div className={classNames(styles.toolboxButtonPad, this.state.padCloseisExpand ? styles.click : null)}
                                    onClick={this.handlePadCloseExpand()}>
                                    <div className={styles.toolboxRightArrowButtonPad}
                                        onClick={this.handleToolboxArrowRightButton()}>
                                    </div>
                                    <div className={styles.toolboxCloseButtonCloseLine1Pad} />
                                    <div className={styles.toolboxCloseButtonCloseLine2Pad} />
                                    <div className={styles.toolboxLeftArrowButtonPad}
                                        onClick={this.handleToolboxArrowLeftButton()}>
                                    </div>
                                </div> :
                                ((!this.props.isFlyoutVisible && !this.props.isToolboxVisible) ?
                                    <div className={styles.workspaceLeftButtonPad}
                                        onClick={this.handleWorkspaceArrowRightButton()}>
                                    </div> : null
                                )
                            )
                        )}
                </div>
            )
        } else {
            let arrowClassName = this.setArrowClassNamestyles();
            return (
                <div id="flyoutLeftButton" className={this.props.hasMaximizedComment ? styles.btn_hide : arrowClassName} ref={this.handlePadCloseRef}>
                    {this.props.isShowUserGuide ? null :
                        ((this.props.isFlyoutVisible && this.props.isToolboxVisible) ?
                            <div className={styles.flyoutLeftButton}
                                onClick={this.handleFlyoutArrowLeftButton()}>
                            </div> :
                            ((!this.props.isFlyoutVisible && this.props.isToolboxVisible) ?
                                <div>
                                    <div className={styles.toolboxRightArrowButton}
                                        onClick={this.handleToolboxArrowRightButton()}>
                                    </div>
                                    <div className={styles.toolboxLeftArrowButton}
                                        onClick={this.handleToolboxArrowLeftButton()}>
                                    </div>
                                </div> :
                                ((!this.props.isFlyoutVisible && !this.props.isToolboxVisible) ?
                                    <div className={styles.workspaceLeftButton}
                                        onClick={this.handleWorkspaceArrowRightButton()}>
                                    </div> : null
                                )
                            )
                        )}
                </div>
            )
        }
    }

    render() {
        /* eslint-disable no-unused-vars */
        const {
            anyModalVisible,
            canUseCloud,
            customProceduresVisible,
            options,
            stageSize,
            vm,
            isRtl,
            isVisible,
            onActivateColorPicker,
            updateToolboxState,
            onActivateCustomProcedures,
            onRequestCloseCustomProcedures,
            toolboxXML,
            isFlyoutVisible,
            isToolboxVisible,
            updateFlyoutVisibleState,
            updateToolboxVisibleState,
            updateHasMaximizedCommentState,
            hasMaximizedComment,
            isDeviceChanged,
            pickedBrainType,
            onRequestCloseErrorDialog,
            onShowErrorDialog,
            hideEditBlockDialog,
            showEditBlockDialog,
            onUpdateUserGuideState,
            setBlockHelpBlockTypeToState,
            onRequestOpenAlertDialog,
            onRequestOpenBlockHelp,
            onRequestOpenHintBlockHelp,
            setWorkspaceToState,
            setBlocksToState,
            updateDeviceChangedState,
            getProjectJson,
            editBlockDialogShow,
            isPickedBrainType,
            updateCode,
            projectChanged,
            isShowUserGuide,
            userGuideCurrentState,
            setHighLightField,
            setHighLightBlockId,
            updateMetrics,
            editorDisplayMode,
            getCodeViewState,
            getPythonCode,
            workspaceMetrics,
            onClickOpenfile,
            isEnableAISpeech,
            onRequestCloseBrain,
            setImportSentence,
            setPickedBrainType,
            showAddCustomizinDialog,
            showEditCustomizinDialog,
            showAddConceptDialog,
            showEditConceptDialog,
            showExtensionBlockNeedWifiDialog,
            onClickImportSentences,
            showAudioLoadingBar,
            getUIStyle,
            getVRFullScreen,
            isAudioUpperBoundErrorDialogShow,
            onShowQuestionDeleteAISpeechGroupDialog,
            onShowQuestionDeleteSpeakerIntentArrayDialog,
            onShowEditAISpeechGroupDialog,
            showAudioUpperBoundErrorDialog,
            hideAudioUpperBoundErrorDialog,
            ...props
        } = this.props;
        /* eslint-enable no-unused-vars */
        return (
            <React.Fragment>
                <div className={this.props.isToolboxVisible ? styles.flyout_background : null} />
                {this.addStretchButton()}
                <DroppableBlocks
                    componentRef={this.setBlocks}
                    onDrop={this.handleDrop}
                    {...props}
                />
                {this.state.prompt ? (
                    <Prompt
                        type={PROMPT_TYPE.var}
                        defaultValue={this.state.prompt.defaultValue}
                        label={this.state.prompt.message}
                        showListOptions={this.state.prompt.showListOptions}
                        title={this.state.prompt.title}
                        names={this.state.prompt.names}
                        vm={vm}
                        onCancel={this.handlePromptClose}
                        onOk={this.handlePromptCallback}
                    />
                ) : null}
                {customProceduresVisible ? (
                    <CustomProcedures
                        options={{
                            media: options.media
                        }}
                        onRequestClose={this.handleCustomProceduresClose}
                    />
                ) : null}
                {this.props.editBlockDialogShow ? (
                    <BlockEditDialog
                        onClose={this.handleEditBlockClose}
                        onConfirm={this.handleEditBlockConfirm}
                        data={this.state.editBlock.data}
                        // data formate = [{"id": "jkfgldfs", "name": "123", "listLength": "1"}]
                        nonData={this.state.editBlock.nonData}
                        type={this.state.editBlock.type}
                        show={this.props.editBlockDialogShow}
                    />
                ) : null}
                <BlockControlButton hasUndoStack={this.state.hasUndoStack} hasRedoStack={this.state.hasRedoStack} enableZoomIn={this.state.enableZoomIn} enableZoomOut={this.state.enableZoomOut} />
                <ErrorDialog
                    selfDefinedShow={this.props.isAudioUpperBoundErrorDialogShow}
                    onClose={this.props.hideAudioUpperBoundErrorDialog}
                    onReUpload={() => this.workspace.importFile(this.handleOpenSoundRecorder)}
                />
                <SBFileUploader importSentences={true}>
                    {(className, renderFileInput, handleLoadProject) => (
                        <div className={styles.pickerOpenFileBtn} onClick={handleLoadProject} ref={this.setFileUploader}>
                            {renderFileInput()}
                        </div>
                    )}
                </SBFileUploader>
            </React.Fragment>
        );
    }
}

Blocks.propTypes = {
    anyModalVisible: PropTypes.bool,
    canUseCloud: PropTypes.bool,
    customProceduresVisible: PropTypes.bool,
    isRtl: PropTypes.bool,
    isVisible: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    messages: PropTypes.objectOf(PropTypes.string),
    onActivateColorPicker: PropTypes.func,
    onActivateCustomProcedures: PropTypes.func,
    onRequestCloseCustomProcedures: PropTypes.func,
    updateCode: PropTypes.func,
    options: PropTypes.shape({
        media: PropTypes.string,
        zoom: PropTypes.shape({
            controls: PropTypes.bool,
            wheel: PropTypes.bool,
            startScale: PropTypes.number
        }),
        colours: PropTypes.shape({
            event: PropTypes.shape({
                primary: PropTypes.string,
                secondary: PropTypes.string,
                tertiary: PropTypes.string,
            }),
            workspace: PropTypes.string,
            flyout: PropTypes.string,
            toolbox: PropTypes.string,
            toolboxSelected: PropTypes.string,
            scrollbar: PropTypes.string,
            scrollbarHover: PropTypes.string,
            insertionMarker: PropTypes.string,
            insertionMarkerOpacity: PropTypes.number,
            fieldShadow: PropTypes.string,
            dragShadowOpacity: PropTypes.number
        }),
        comments: PropTypes.bool,
        collapse: PropTypes.bool
    }),
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    toolboxXML: PropTypes.string,
    updateMetrics: PropTypes.func,
    updateToolboxState: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
    workspaceMetrics: PropTypes.shape({
        targets: PropTypes.objectOf(PropTypes.object)
    }),
    updateFlyoutVisibleState: PropTypes.func,
    updateToolboxVisibleState: PropTypes.func,
    isFlyoutVisible: PropTypes.bool,
    isToolboxVisible: PropTypes.bool,
    updateHasMaximizedCommentState: PropTypes.func,
    hasMaximizedComment: PropTypes.bool,
    updateDeviceChangedState: PropTypes.func,
    isDeviceChanged: PropTypes.bool,
    pickedBrainType: PropTypes.string,
    setBlocksToState: PropTypes.func,
    setWorkspaceToState: PropTypes.func,
    setBlockHelpBlockTypeToState: PropTypes.func,
    onRequestOpenHintBlockHelp: PropTypes.func,
    onRequestOpenBlockHelp: PropTypes.func,
    onRequestOpenAlertDialog: PropTypes.func,
    isShowUserGuide: PropTypes.bool,
    showEditBlockDialog: PropTypes.func,
    hideEditBlockDialog: PropTypes.func,
    editBlockDialogShow: PropTypes.bool,
    onRequestCloseErrorDialog: PropTypes.func,
    onShowErrorDialog: PropTypes.func,
    onUpdateUserGuideState: PropTypes.func,
    getProjectJson: PropTypes.func,
    isPickedBrainType: PropTypes.bool,
    projectChanged: PropTypes.bool,
    userGuideCurrentState: PropTypes.number,
    setHighLightBlockId: PropTypes.func,
    setHighLightField: PropTypes.func,
    getCodeViewState: PropTypes.bool,
    getUIStyle: PropTypes.string,
    showAddCustomizinDialog: PropTypes.func,
    showEditCustomizinDialog: PropTypes.func,
    showAddConceptDialog: PropTypes.func,
    showEditConceptDialog: PropTypes.func,
    showExtensionBlockNeedWifiDialog: PropTypes.func,
    showRemoveGroupDialog: PropTypes.func,
    onClickOpenfile: PropTypes.func,
    onClickImportSentences: PropTypes.func,
    getVRFullScreen: PropTypes.bool,
};

Blocks.defaultOptions = {
    zoom: {
        controls: true,
        wheel: true,
        startScale: BLOCKS_DEFAULT_SCALE
    },
    isTranslateWorkspace: false,
    grid: {
        spacing: 40,
        length: 1,
        colour: '#000000'
    },
    colours: {
        workspace: '#F9F9F9',
        flyout: '#F9F9F9',
        toolbox: '#FFFFFF',
        toolboxSelected: '#FFFFFF',
        scrollbar: '#CECDCE',
        scrollbarHover: '#CECDCE',
        insertionMarker: '#000000',
        insertionMarkerOpacity: 0.2,
        fieldShadow: 'rgba(255, 255, 255, 0.5)',
        dragShadowOpacity: 0.6
    },
    comments: true,
    collapse: false,
    sounds: false
};

Blocks.defaultProps = {
    isVisible: true,
    options: Blocks.defaultOptions
};

const mapStateToProps = state => ({
    anyModalVisible: (
        Object.keys(state.scratchGui.modals).some(key => state.scratchGui.modals[key]) ||
        state.scratchGui.mode.isFullScreen
    ),
    isRtl: state.locales.isRtl,
    locale: state.locales.locale,
    messages: state.locales.messages,
    toolboxXML: state.scratchGui.toolbox.toolboxXML,
    customProceduresVisible: state.scratchGui.customProcedures.active,
    workspaceMetrics: state.scratchGui.workspaceMetrics,
    isFlyoutVisible: state.scratchGui.toolbox.isFlyoutVisible,
    isToolboxVisible: state.scratchGui.toolbox.isToolboxVisible,
    hasMaximizedComment: state.scratchGui.toolbox.hasMaximizedComment,
    isDeviceChanged: state.scratchGui.device.isDeviceChanged,
    pickedBrainType: getPickedBrainType(state),
    userGuideCurrentState: getUserGuideCurrentState(state),
    isShowUserGuide: isShowUserGuide(state),
    projectChanged: state.scratchGui.projectChanged,
    isPickedBrainType: isPickedBrainType(state),
    editBlockDialogShow: editBlockDialogShow(state),
    getProjectJson: state.scratchGui.vm.getProjectJson.bind(state.scratchGui.vm),
    getPythonCode: state.scratchGui.vm.getPythonCode.bind(state.scratchGui.vm),
    getCodeViewState: getCodeViewState(state),
    editorDisplayMode: getEditorDisplayMode(state),
    getUIStyle: getUIStyle(state),
    getVRFullScreen: getFullScreen(state, viewPage.vr),
    isAudioUpperBoundErrorDialogShow: isAudioUpperBoundErrorDialogShow(state),
    isEnableAISpeech: isEnableAISpeech(state)
});

const mapDispatchToProps = dispatch => ({
    onActivateColorPicker: callback => dispatch(activateColorPicker(callback)),
    onActivateCustomProcedures: (data, callback) => dispatch(activateCustomProcedures(data, callback)),
    onRequestCloseCustomProcedures: data => {
        dispatch(deactivateCustomProcedures(data));
    },
    updateCode: data => {
        dispatch(setCode(data));
    },
    updateToolboxState: toolboxXML => {
        dispatch(updateToolbox(toolboxXML));
    },
    updateMetrics: metrics => {
        dispatch(updateMetrics(metrics));
    },
    updateFlyoutVisibleState: isFlyoutVisible => {
        dispatch(updateFlyoutVisible(isFlyoutVisible));
    },
    updateToolboxVisibleState: isToolboxVisible => {
        dispatch(updateToolboxVisible(isToolboxVisible));
    },
    updateHasMaximizedCommentState: hasMaximizedComment => {
        dispatch(updateHasMaximizedComment(hasMaximizedComment));
    },
    updateDeviceChangedState: isDeviceChanged => {
        dispatch(setDeviceChanged(isDeviceChanged));
    },
    setBlocksToState: (blocks) => dispatch(setBlocks(blocks)),
    setWorkspaceToState: (workspace) => dispatch(setWorkspace(workspace)),
    onRequestOpenHintBlockHelp: () => dispatch(showHintBlockHelpDialog()),
    onRequestOpenBlockHelp: () => dispatch(showBlockHelpDialog(getPlatform())),
    onRequestOpenAlertDialog: (type, msg, callback) => dispatch(showAlertDialog(type, msg, callback)),
    setBlockHelpBlockTypeToState: (blockType) => dispatch(setBlockHelpBlockType(blockType)),
    onUpdateUserGuideState: state => dispatch(updateUserGuideState(state)),
    showEditBlockDialog: () => dispatch(showEditBlockDialog()),
    hideEditBlockDialog: () => dispatch(hideEditBlockDialog()),
    onShowErrorDialog: (errorType, msg) => dispatch(showErrorDialog(errorType, msg)),
    onRequestCloseErrorDialog: () => dispatch(hideErrorDialog()),
    setHighLightBlockId: (blockId) => dispatch(setHighLightBlockId(blockId)),
    setHighLightField: (fieldName) => dispatch(setHighLightField(fieldName)),
    onRequestCloseBrain: () => dispatch(closeBrainInfoMenu()),
    onShowQuestionDeleteAISpeechGroupDialog: (groupId, callback) => dispatch(showQuestionDeleteAISpeechGroupDialog(groupId, callback)),
    onShowQuestionDeleteSpeakerIntentArrayDialog: (callback) => dispatch(showQuestionDeleteSpeakerIntentArrayDialog(callback)),
    onShowEditAISpeechGroupDialog: (groupId) => dispatch(showEditAISpeechGroupDialog(groupId)),
    setImportSentence: (sentence) => dispatch(setImportSentence(sentence)),
    setPickedBrainType: (type) => dispatch(setPickedBrainType(type)),
    showAudioUpperBoundErrorDialog: (errorType) => dispatch(showAudioUpperBoundErrorDialog(errorType)),
    hideAudioUpperBoundErrorDialog: () => dispatch(hideAudioUpperBoundErrorDialog()),
    showAudioLoadingBar: (show, percent) => dispatch(showLoadingBar(show, percent, LOADING_TYPE_NUM.TYPE_AUDIO)),
});

export default injectIntl(errorBoundaryHOC('Blocks')(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Blocks)
));