import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import styles from './controller.css';
import store from 'store';
import VM from 'scratch-vm';
import { BRAIN_TYPE } from '../../../lib/brains'
import { DEFAULT_LOCALE } from '../../../config/project-config';

import {
    BUTTON,
    GROUP_BUTTON,
    POWER_TRAIN_MODE,
    TYPE,
    ROTATION,
    setSelectedControllerType,
    getSelectedControllerType,
    getSelectedDevice,
    cleanSelectedControllerType,
    getSelectedGroupButton,
    resetDeleteController,
    isDeleteControllerConfirm
} from '../../../reducers/controller';

import { showQuestionDialog, questionType } from '../../../reducers/dialog'

import trashcanImg from '../pictures/device_trashcan.svg'

import controllerImg from '../pictures/controller.png'
import controllerUpsideImg from '../pictures/controller_upside.png'
import lineUpDownImg from '../pictures/line_up_down.svg'
import lineLeftRightImg from '../pictures/line_left_right.svg'
import lineXYImg from '../pictures/line_x_y.svg'
import lineABImg from '../pictures/line_a_b.svg'
import lineL3R3Img from '../pictures/line_l3_r3.png'
import lineL1L2Img from '../pictures/line_l1_l2.svg'
import lineR1R2Img from '../pictures/line_r1_r2.svg'

import modeLeftImg from '../pictures/mode_left.svg'
import modeRightImg from '../pictures/mode_right.svg'
import modeTankImg from '../pictures/mode_tank.svg'
import modeDirectImg from '../pictures/mode_direct.svg'

import ControllerString from './string.js';


const OPTION_ACTION = {
    new: "new",
    select: "select"
}

class Controller extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            isOptionExpand: false,
            selectedType: null,
            controllerList: [],
            selectedGroupButton: null,
        };
    }

    closeOption() {
        if (this.state.isOptionExpand) {
            this.setState({ isOptionExpand: false })
        }
    }

    updateControllerListState() {
        if (this.hasController()) {
            let list = this.props.vm.getControllerList();
            let type = this.props.type ? this.props.type : (list[0] ? TYPE.main : list[1] ? TYPE.minor : null)
            this.setControllerTypeState(type);
        }
        this.setState({ controllerList: this.props.vm.getControllerList() })
    }

    setControllerTypeState(type) {
        this.setState({
            selectedType: type,
        })
        this.props.setSelectedControllerType(type)
    }

    getOptionText(type) {
        let language = store.get("locale", DEFAULT_LOCALE);
        return (!type) ? ControllerString[language]['newController1'] : (type == TYPE.main) ? ControllerString[language]['controller1'] : ControllerString[language]['controller2']
    }

    componentDidMount() {
        this.updateControllerListState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.getSelectedDevice != this.props.getSelectedDevice
            || prevProps.type != this.props.type
            || prevProps.show != this.props.show) {
            this.updateControllerListState();
        }
        if (prevProps.selectedGroupButton != this.props.selectedGroupButton) {
            this.setState({ selectedGroupButton: this.props.selectedGroupButton });
        }
        if (prevProps.isDeleteControllerConfirm != this.props.isDeleteControllerConfirm
            && this.props.isDeleteControllerConfirm == true) {
            this.props.resetDeleteController();
            this.updateControllerListState();
            this.switchController()
        }
    }

    hasController() {
        let list = this.props.vm.getControllerList();
        if (!list) return false;
        for (let i = 0; i < list.length; i++) {
            if (!!list[i]) {
                return true;
            }
        }
    }

    newController(type) {
        if (type == TYPE.main) {
            this.props.vm.addMainController();
        } else {
            this.props.vm.addMinorController();
        }
        this.setControllerTypeState(type);
    }

    handleClickOptionList() {
        this.setState({ isOptionExpand: !this.state.isOptionExpand });
    }

    isOptionSelected(option) {
        return option.type == this.state.selectedType;
    }

    handleClickOption(option) {
        this.closeOption();
        switch (option.action) {
            case OPTION_ACTION.new:
                this.newController(option.type);
                break;
            case OPTION_ACTION.select:
                this.setControllerTypeState(option.type);
                break;
            default:
                break;
        }
    }

    handleClickTrashcan() {
        this.closeOption();
        if (this.props.vm.getControllerList()[0] && this.props.vm.getControllerList()[1] && this.state.selectedType == TYPE.main) {
            this.props.showQuestionDeleteController1();
        } else {
            this.props.vm.removeControllerByType(this.props.type);
            this.updateControllerListState();
            this.switchController();
        }
    }

    switchController() {
        if (this.props.vm.getControllerList()[0] && this.props.vm.getControllerList()[1]) return;
        if (this.props.vm.getControllerList()[0] || this.props.vm.getControllerList()[1]) {
            this.props.setSelectedControllerType(this.props.vm.getControllerList()[0] ? TYPE.main : TYPE.minor);
        } else {
            this.props.cleanSelectedControllerType();
        }
        this.setControllerTypeState(this.props.vm.getControllerList()[0] ? TYPE.main : this.props.vm.getControllerList()[1] ? TYPE.minor : null);
    }

    createOption() {
        if (!this.props.vm.getControllerList()) return null;
        let optionList = [];
        let controllerList = this.props.vm.getControllerList();
        let language = store.get("locale", DEFAULT_LOCALE);
        optionList.push({
            text: (controllerList[0]) ? ControllerString[language]['controller1'] : ControllerString[language]['newController1'],
            type: TYPE.main,
            action: (controllerList[0]) ? OPTION_ACTION.select : OPTION_ACTION.new
        })

        optionList.push({
            text: (controllerList[1]) ? ControllerString[language]['controller2'] : ControllerString[language]['newController2'],
            type: TYPE.minor,
            action: (controllerList[1]) ? OPTION_ACTION.select : OPTION_ACTION.new
        })

        return optionList.map((option, index) =>
            <div key={index}
                className={classNames(styles.option, (this.isOptionSelected(option)) ? styles.optionSelected : null)}
                onClick={() => { this.handleClickOption(option) }}>
                {option.text}
            </div>
        )
    }

    isButtonShow(button) {
        let controller = this.props.vm.getControllerByType(this.props.type);
        if (!controller) return false;
        return controller && !!controller[button].deviceId
    }

    getConnectDeviceName(button) {
        let controller = this.props.vm.getControllerByType(this.props.type);
        if (!controller) return null;
        return (controller) ? controller[button].deviceName : null;
    }

    getConnectDirection(button) {
        let controller = this.props.vm.getControllerByType(this.props.type);
        let rotation = (controller) ? "(" + ControllerString[store.get("locale", DEFAULT_LOCALE)][controller[button].rotation] + ")" : null;
        return (rotation ? (<div className={classNames(controller[button].rotation == ROTATION.forward ? styles.forward : styles.reverse)}>{rotation}</div>) : null)
    }

    getDrivetrainModeImg() {
        let controller = this.props.vm.getControllerByType(this.props.type);
        if (!controller || !controller.drivetrain) return null;
        let mode = controller.drivetrain.mode;
        switch (mode) {
            case POWER_TRAIN_MODE.left:
                return modeLeftImg;
            case POWER_TRAIN_MODE.right:
                return modeRightImg;
            case POWER_TRAIN_MODE.tank:
                return modeTankImg;
            case POWER_TRAIN_MODE.direct:
                return modeDirectImg
            default:
                return null;
        }
    }

    render() {
        return (
            <div>
                <Option
                    hasController={this.hasController()}
                    newController={() => { this.newController(TYPE.main) }}
                    handleClickOptionList={() => { this.handleClickOptionList() }}
                    getOptionText={this.getOptionText(this.props.type)}
                    handleClickTrashcan={() => { this.handleClickTrashcan() }}
                    isOptionExpand={this.state.isOptionExpand}
                    optionList={this.createOption()} />
                {(this.hasController()
                    ? <div>
                        <div className={classNames(styles.controllerUpsideImg)}>
                            <img src={controllerUpsideImg} className={classNames(styles.controllerUpsideImgSize)} alt={"controller upside image"} />
                        </div>
                        <div className={classNames(styles.controllerImg)}>
                            <img src={controllerImg} className={classNames(styles.controllerImgSize)} alt={"controller image"} />
                        </div>
                        <div className={classNames(styles.buttonSettingArea)}>
                            <ButtonMotor
                                show={this.isButtonShow(BUTTON.Up)}
                                fistButton={BUTTON.Up}
                                secondButton={BUTTON.Down}
                                fistButtonPosition={styles.buttonUpPosition}
                                secondButtonPosition={styles.buttonDownPosition}
                                getConnectDeviceName={(button) => this.getConnectDeviceName(button)}
                                getConnectDirection={(button) => this.getConnectDirection(button)}
                                linePosition={styles.lineUpDownImg}
                                lineImg={lineUpDownImg}
                                lineSize={styles.lineUpDownImgSize}
                                isGroupSelected={this.state.selectedGroupButton == GROUP_BUTTON.UpDown} />
                            <ButtonMotor
                                show={this.isButtonShow(BUTTON.Left)}
                                fistButton={BUTTON.Left}
                                secondButton={BUTTON.Right}
                                fistButtonPosition={styles.buttonLeftPosition}
                                secondButtonPosition={styles.buttonRightPosition}
                                getConnectDeviceName={(button) => this.getConnectDeviceName(button)}
                                getConnectDirection={(button) => this.getConnectDirection(button)}
                                linePosition={styles.lineLeftRightImg}
                                lineImg={lineLeftRightImg}
                                lineSize={styles.lineLeftRightImgSize}
                                isGroupSelected={this.state.selectedGroupButton == GROUP_BUTTON.LeftRight} />
                            <ButtonMotor
                                show={this.isButtonShow(BUTTON.X)}
                                fistButton={BUTTON.X}
                                secondButton={BUTTON.Y}
                                fistButtonPosition={styles.buttonXPosition}
                                secondButtonPosition={styles.buttonYPosition}
                                getConnectDeviceName={(button) => this.getConnectDeviceName(button)}
                                getConnectDirection={(button) => this.getConnectDirection(button)}
                                linePosition={styles.lineXYImg}
                                lineImg={lineXYImg}
                                lineSize={styles.lineXYImgSize}
                                isGroupSelected={this.state.selectedGroupButton == GROUP_BUTTON.XY} />
                            <ButtonMotor
                                show={this.isButtonShow(BUTTON.A)}
                                fistButton={BUTTON.A}
                                secondButton={BUTTON.B}
                                fistButtonPosition={styles.buttonAPosition}
                                secondButtonPosition={styles.buttonBPosition}
                                getConnectDeviceName={(button) => this.getConnectDeviceName(button)}
                                getConnectDirection={(button) => this.getConnectDirection(button)}
                                linePosition={styles.lineABImg}
                                lineImg={lineABImg}
                                lineSize={styles.lineABImgSize}
                                isGroupSelected={this.state.selectedGroupButton == GROUP_BUTTON.AB} />
                            <ButtonMotor
                                show={this.isButtonShow(BUTTON.L1)}
                                fistButton={BUTTON.L1}
                                secondButton={BUTTON.L2}
                                fistButtonPosition={styles.buttonL1Position}
                                secondButtonPosition={styles.buttonL2Position}
                                getConnectDeviceName={(button) => this.getConnectDeviceName(button)}
                                getConnectDirection={(button) => this.getConnectDirection(button)}
                                linePosition={styles.lineL1L2Img}
                                lineImg={lineL1L2Img}
                                lineSize={styles.lineL1L2ImgSize}
                                isGroupSelected={this.state.selectedGroupButton == GROUP_BUTTON.L1L2} />
                            <ButtonMotor
                                show={this.isButtonShow(BUTTON.R1)}
                                fistButton={BUTTON.R1}
                                secondButton={BUTTON.R2}
                                fistButtonPosition={styles.buttonR1Position}
                                secondButtonPosition={styles.buttonR2Position}
                                getConnectDeviceName={(button) => this.getConnectDeviceName(button)}
                                getConnectDirection={(button) => this.getConnectDirection(button)}
                                linePosition={styles.lineR1R2Img}
                                lineImg={lineR1R2Img}
                                lineSize={styles.lineR1R2ImgSize}
                                isGroupSelected={this.state.selectedGroupButton == GROUP_BUTTON.R1R2} />
                            <ButtonDrivetrain
                                show={this.isButtonShow(GROUP_BUTTON.Drivetrain)}
                                drivetrainName={this.getConnectDeviceName(GROUP_BUTTON.Drivetrain)}
                                drivetrainModeImg={this.getDrivetrainModeImg()}
                                isGroupSelected={this.props.selectedGroupButton == GROUP_BUTTON.Drivetrain} />
                        </div>
                    </div>
                    : null)}

            </div>
        );
    }
}

const ButtonMotor = (props) => {
    if (!props.show) return null;
    return (
        <div>
            <div className={classNames(props.fistButtonPosition, styles.buttonSetting, (props.isGroupSelected) ? styles.buttonSelected : styles.buttonUnselected)}>
                <div className={classNames(styles.buttonText)}>
                    {props.getConnectDeviceName(props.fistButton)}
                </div>
                {props.getConnectDirection(props.fistButton)}
            </div>
            <div className={classNames(props.secondButtonPosition, styles.buttonSetting, (props.isGroupSelected) ? styles.buttonSelected : styles.buttonUnselected)}>
                <div className={classNames(styles.buttonText)}>
                    {props.getConnectDeviceName(props.secondButton)}
                </div>
                {props.getConnectDirection(props.secondButton)}
            </div>
            <div className={classNames(props.linePosition)}>
                <img src={props.lineImg} className={classNames(props.lineSize)} alt={"line"} />
            </div>
        </div>
    )
}

const ButtonDrivetrain = (props) => {
    if (!props.show) return null;
    return (
        <div>
            <div className={classNames(styles.buttonDrivetrainPosition, styles.buttonSetting, (props.isGroupSelected) ? styles.buttonSelected : styles.buttonUnselected)}>
                <div className={classNames(styles.buttonText)}>
                    {props.drivetrainName}
                </div>
                <div className={classNames(styles.modePosition)}>
                    <img src={props.drivetrainModeImg} alt={"drivetrain image"} />
                </div>
            </div>
            <div className={classNames(styles.lineL3, (props.isGroupSelected) ? styles.buttonSelected : styles.lineUnselected)} />
            <div className={classNames(styles.lineR3, (props.isGroupSelected) ? styles.buttonSelected : styles.lineUnselected)} />
            <div className={classNames(styles.lineDrivetrainImg)}>
                <img src={lineL3R3Img} className={classNames(styles.lineL3R3Img)} alt={"line"} />
            </div>
        </div>
    )
}



const Option = (props) => {
    return (
        <div>
            <div className={classNames(styles.buttonOptionArea)}>
                {(!props.hasController) ?
                    <div className={classNames(styles.buttonOption)}
                        onClick={() => { props.newController() }}>
                        <div className={classNames(styles.plus)}>+</div>
                        <div className={classNames(styles.newControllerButtonText)}>
                            <FormattedMessage
                                defaultMessage="New Controller"
                                description="New Controller"
                                id="gui.controller.newController"
                            />
                        </div>
                    </div>
                    : <div>
                        <div className={classNames(styles.buttonOption)}
                            onClick={() => props.handleClickOptionList()}>
                            <div className={classNames(styles.optionText)} >
                                {props.getOptionText}</div>
                            <div className={classNames(styles.arrow)} />
                        </div>
                        <div className={classNames(styles.trashcan)}
                            onClick={() => props.handleClickTrashcan()}>
                            <img src={trashcanImg} alt={"trashcan"} />
                        </div>
                    </div>
                }
            </div>
            {(props.isOptionExpand)
                ? <div className={classNames(styles.optionArea)} >
                    {props.optionList}
                </div> : null}
        </div>
    )
}

Controller.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired,
    show: PropTypes.bool,
    brainType: PropTypes.string
};


const mapStateToProps = state => ({
    type: getSelectedControllerType(state),
    selectedControllerType: getSelectedControllerType(state),
    selectedGroupButton: getSelectedGroupButton(state),
    getSelectedDevice: getSelectedDevice(state),
    isDeleteControllerConfirm: isDeleteControllerConfirm(state),
});

const mapDispatchToProps = dispatch => ({
    setSelectedControllerType: (type) => dispatch(setSelectedControllerType(type)),
    cleanSelectedControllerType: () => dispatch(cleanSelectedControllerType()),
    showQuestionDeleteController1: () => dispatch(showQuestionDialog(questionType.QUESTION_DELETE_CONTROLLER_1)),
    resetDeleteController: () => dispatch(resetDeleteController())
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Controller));