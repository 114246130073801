import log from '../../lib/log.js';
import store from 'store';

const defaultReleaseNotes = [{
    "version": "",
    "time": "",
    "data": {
        "en": [
            {
                "title": "",
                "content": [""]
            }
        ],
        "zh-tw": [
            {
                "title": "",
                "content": [""]
            }
        ],
        "zh-cn": [
            {
                "title": "",
                "content": [""]
            }
        ]
    }
}];

function parseReleaseNoteJson(locale) {
    var releaseNotesJson = store.get("releaseNotes", defaultReleaseNotes);
    try {
        if (releaseNotesJson.length == 0 || !releaseNotesJson[0] || !releaseNotesJson[0]['data'] ||
            !(releaseNotesJson[0]['data'][locale] && releaseNotesJson[0]['data'][locale].length > 0 &&
                releaseNotesJson[0]['data'][locale][0]['content'] && releaseNotesJson[0]['data'][locale][0]['content'].length > 0)) {
            releaseNotesJson = defaultReleaseNotes;
        }
    }
    catch (e) {
        log.error("parseReleaseNoteJson error: " + e + ", releaseNotesJson: " + JSON.stringify(releaseNotesJson));
        releaseNotesJson = defaultReleaseNotes;
    }
    log.info("parseReleaseNoteJson: " + JSON.stringify(releaseNotesJson));
    return releaseNotesJson;
}

export {
    defaultReleaseNotes,
    parseReleaseNoteJson
}