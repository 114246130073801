import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './brain.css';
import Point from '../port/point-on-brain.jsx';

class EntryPoint extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }

    render() {
        const {
            deviceList
        } = this.props;
        return (
            <div>
                <div className={classNames(styles.pointEntry1)}>
                    <Point
                        number={deviceList[0].port}
                        isConnected={!!deviceList[0].type} />
                </div>
                <div className={classNames(styles.pointEntry2)}>
                    <Point
                        number={deviceList[1].port}
                        isConnected={!!deviceList[1].type} />
                </div>
                <div className={classNames(styles.pointEntry3)}>
                    <Point
                        number={deviceList[2].port}
                        isConnected={!!deviceList[2].type} />
                </div>
                <div className={classNames(styles.pointEntry4)}>
                    <Point
                        number={deviceList[3].port}
                        isConnected={!!deviceList[3].type} />
                </div>
                <div className={classNames(styles.pointEntry5)}>
                    <Point
                        number={deviceList[4].port}
                        isConnected={!!deviceList[4].type} />
                </div>
                <div className={classNames(styles.pointEntry6)}>
                    <Point
                        number={deviceList[5].port}
                        isConnected={!!deviceList[5].type} />
                </div>
                <div className={classNames(styles.pointEntry7)}>
                    <Point
                        number={deviceList[6].port}
                        isConnected={!!deviceList[6].type} />
                </div>
            </div>
        );
    }

}

EntryPoint.propTypes = {
    deviceList: PropTypes.array,
    hide: PropTypes.bool
};

export default EntryPoint;