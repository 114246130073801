import { ENABLE_AI_SPEECH } from '../config/project-config'

const CONFIG_ENABLE_AI_SPEECH = 'configEnableAISpeech';

const SET_ENABLE_SKILL_LIST = 'scratch-gui/speaker/SET_ENABLE_SKILL_LIST';
const SET_SKILL_LOCATION = 'scratch-gui/speaker/SET_SKILL_LOCATION';
const SET_CONCEPT_ACTION = 'scratch-gui/speaker/SET_CONCEPT_ACTION';
const SET_CUSTOMIZING_ACTION = 'scratch-gui/speaker/SET_CUSTOMIZING_ACTION';
const SET_EDIT_INTENT_ID = 'scratch-gui/speaker/SET_EDIT_INTENT_ID';
const SET_SENTENCE = 'scratch-gui/speaker/SET_SENTENCE';
const SET_CONCEPTS = 'scratch-gui/speaker/SET_CONCEPTS';
const CLEAR_SENTENCE = 'scratch-gui/speaker/CLEAR_SENTENCE';
const CLEAR_CONCEPTS = 'scratch-gui/speaker/CLEAR_CONCEPTS';

const SET_SORTED_SENTENCE = 'scratch-gui/speaker/SET_SORTED_SENTENCE';
const SET_SORTED_CONCEPT = 'scratch-gui/speaker/SET_SORTED_CONCEPT';
const SET_TEMP_CONCEPTS = 'scratch-gui/speaker/SET_TEMP_CONCEPTS';

const SKILL_LIST = "skillList"
const SKILL_LOCATION = "skillLocation"
const CONCEPT_ACTION = "conceptAction"
const CUSTOMIZING_ACTION = "customizingAction"
const EDIT_INTENT_ID = "editIntentId"
const STATE_SENTENCE = 'setSentence';
const SENTENCE_DATA = 'sentenceData';
const IS_SENTENCE_IMPORTED = 'isSentenceImported';
const STATE_CONCEPTS = 'setConcepts';
const CONCEPTS_DATA = 'conceptsData';
const IS_CONCEPTS_IMPORTED = 'isConceptsImported';
const SORTED_SENTENCE = 'sortedSentence';
const SORTED_CONCEPT = 'sortedConcept';
const DIFFERENT = 'different';
const DUPLICATED = 'duplicated';
const TEMP_CONCEPTS = 'tempConcepts';

const SPEAKER_DIALOG_ACTION = {
    add: "add",
    edit: "edit"
}
const initialState = {
    [CONFIG_ENABLE_AI_SPEECH]: ENABLE_AI_SPEECH,
    [SKILL_LIST]: [],
    [SKILL_LOCATION]: "Taipei",
    [CONCEPT_ACTION]: SPEAKER_DIALOG_ACTION.add,
    [CUSTOMIZING_ACTION]: SPEAKER_DIALOG_ACTION.edit,
    [EDIT_INTENT_ID]: "",

    [STATE_SENTENCE]: {
        [IS_SENTENCE_IMPORTED]: false,
        [SENTENCE_DATA]: null
    },
    [STATE_CONCEPTS]: {
        [IS_CONCEPTS_IMPORTED]: false,
        [CONCEPTS_DATA]: null
    },
    [SORTED_SENTENCE]: {
        [DIFFERENT]: [],
        [DUPLICATED]: []
    },
    [SORTED_CONCEPT]: {
        [DIFFERENT]: [],
        [DUPLICATED]: []
    },
    [TEMP_CONCEPTS]: []
};
const PROMPT_TYPE = {
    group: "group",
    var: "var"
}
const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case CONFIG_ENABLE_AI_SPEECH:
            return Object.assign({}, state, {
                [CONFIG_ENABLE_AI_SPEECH]: action.enable
            });
        case SET_ENABLE_SKILL_LIST:
            return Object.assign({}, state, {
                [SKILL_LIST]: action.list
            });
        case SET_SKILL_LOCATION:
            return Object.assign({}, state, {
                [SKILL_LOCATION]: action.location
            });
        case SET_CONCEPT_ACTION:
            return Object.assign({}, state, {
                [CONCEPT_ACTION]: action.action
            });
        case SET_CUSTOMIZING_ACTION:
            return Object.assign({}, state, {
                [CUSTOMIZING_ACTION]: action.action
            });
        case SET_EDIT_INTENT_ID:
            return Object.assign({}, state, {
                [EDIT_INTENT_ID]: action.intentId
            });
        case SET_SENTENCE:
            return Object.assign({}, state, {
                [STATE_SENTENCE]: {
                    [IS_SENTENCE_IMPORTED]: true,
                    [SENTENCE_DATA]: action.sentence
                }
            });
        case SET_CONCEPTS:
            return Object.assign({}, state, {
                [STATE_CONCEPTS]: {
                    [IS_CONCEPTS_IMPORTED]: true,
                    [CONCEPTS_DATA]: action.concepts
                }
            });
        case CLEAR_SENTENCE:
            return Object.assign({}, state, {
                [STATE_SENTENCE]: {
                    [IS_SENTENCE_IMPORTED]: false,
                    [SENTENCE_DATA]: null
                }
            });
        case CLEAR_CONCEPTS:
            return Object.assign({}, state, {
                [STATE_CONCEPTS]: {
                    [IS_CONCEPTS_IMPORTED]: false,
                    [CONCEPTS_DATA]: null
                }
            });
        case SET_SORTED_SENTENCE:
            return Object.assign({}, state, {
                [SORTED_SENTENCE]: {
                    [DIFFERENT]: action.different,
                    [DUPLICATED]: action.duplicated
                }
            });
        case SET_SORTED_CONCEPT:
            return Object.assign({}, state, {
                [SORTED_CONCEPT]: {
                    [DIFFERENT]: action.different,
                    [DUPLICATED]: action.duplicated
                }
            });
        case SET_TEMP_CONCEPTS:
            return Object.assign({}, state, {
                [TEMP_CONCEPTS]: action.concepts
            });

        default:
            return state;
    }
};

const setEnableAISpeech = (status) => ({
    type: CONFIG_ENABLE_AI_SPEECH,
    enable: status
})

const isEnableAISpeech = (state) => state.scratchGui.speaker[CONFIG_ENABLE_AI_SPEECH];

const setSkillList = (list) => ({
    type: SET_ENABLE_SKILL_LIST,
    list: list
})

const getSkillList = (state) => state.scratchGui.speaker[SKILL_LIST];
const clearSkillList = () => setSkillList([]);

const setSkillLocation = (location) => ({
    type: SET_SKILL_LOCATION,
    location: location
})

const getSkillLocation = (state) => state.scratchGui.speaker[SKILL_LOCATION];
const clearSkillLocation = () => setSkillLocation("Taipei");

const setConceptAction = (action) => ({
    type: SET_CONCEPT_ACTION,
    action: action
})

const getConceptAction = (state) => state.scratchGui.speaker[CONCEPT_ACTION];
const clearConceptAction = () => setConceptAction(SPEAKER_DIALOG_ACTION.add);

const setCustomizingAction = (action) => ({
    type: SET_CUSTOMIZING_ACTION,
    action: action
})

const getCustomizingAction = (state) => state.scratchGui.speaker[CUSTOMIZING_ACTION];
const clearCustomizingAction = () => setCustomizingAction(SPEAKER_DIALOG_ACTION.add);


const setEditIntentId = (intentId) => ({
    type: SET_EDIT_INTENT_ID,
    intentId: intentId
})

const getEditIntentId = (state) => state.scratchGui.speaker[EDIT_INTENT_ID];
const clearEditIntentId = () => setEditIntentId("");

const setImportSentence = (sentence) => ({
    type: SET_SENTENCE,
    sentence: sentence
});

const setImportConcepts = (concepts) => ({
    type: SET_CONCEPTS,
    concepts: concepts
});

const isSentenceImported = state => state.scratchGui.speaker[STATE_SENTENCE][IS_SENTENCE_IMPORTED];
const isConceptsImported = state => state.scratchGui.speaker[STATE_CONCEPTS][IS_CONCEPTS_IMPORTED];

const getImportSentence = state => state.scratchGui.speaker[STATE_SENTENCE][SENTENCE_DATA];
const getImportConcepts = state => state.scratchGui.speaker[STATE_CONCEPTS][CONCEPTS_DATA];

const clearImportSentence = () => ({
    type: CLEAR_SENTENCE
});

const clearImportConcepts = () => ({
    type: CLEAR_CONCEPTS
});

const setSortedSentence = (different, duplicated) => ({
    type: SET_SORTED_SENTENCE,
    different: different,
    duplicated: duplicated
});
const getSortedSentence = state => state.scratchGui.speaker[SORTED_SENTENCE];
const clearSortedSentence = () => ({
    type: SET_SORTED_SENTENCE,
    different: [],
    duplicated: []
});
const setSortedConcept = (different, duplicated) => ({
    type: SET_SORTED_CONCEPT,
    different: different,
    duplicated: duplicated
});
const getSortedConcept = state => state.scratchGui.speaker[SORTED_CONCEPT];
const clearSortedConcept = () => ({
    type: SET_SORTED_CONCEPT,
    different: [],
    duplicated: []
});

const setTempConcepts = (concepts) => ({
    type: SET_TEMP_CONCEPTS,
    concepts: concepts,
});
const getTempConcepts = state => state.scratchGui.speaker[TEMP_CONCEPTS];
const clearTempConcepts = () => ({
    type: SET_TEMP_CONCEPTS,
    concepts: [],
});



export {
    reducer as default,
    initialState as speakerInitialState,

    setEnableAISpeech,
    isEnableAISpeech,

    setSkillList,
    getSkillList,
    clearSkillList,

    setSkillLocation,
    getSkillLocation,
    clearSkillLocation,

    SPEAKER_DIALOG_ACTION,
    setConceptAction,
    getConceptAction,
    clearConceptAction,
    setCustomizingAction,
    getCustomizingAction,
    clearCustomizingAction,
    setEditIntentId,
    getEditIntentId,
    clearEditIntentId,

    setImportSentence,
    setImportConcepts,

    isSentenceImported,
    isConceptsImported,

    getImportSentence,
    getImportConcepts,

    clearImportSentence,
    clearImportConcepts,

    setSortedConcept,
    getSortedConcept,
    clearSortedConcept,

    setSortedSentence,
    getSortedSentence,
    clearSortedSentence,

    setTempConcepts,
    getTempConcepts,
    clearTempConcepts,

    PROMPT_TYPE
};
