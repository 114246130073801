import soundBlocks from '../block-vertical-blocks/sound';

export default function soundEntry(workspace, deviceInfo, soundName, soundsNum, handleAudioManagementStart, blockSeparator) {
    return `
        ${deviceInfo.speakerInfo.hasspeaker ? `
            <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
                ${deviceInfo.speakerInfo.hasspeaker ? `
                    ${soundBlocks.Entry.sound_import_audio_button(workspace)}
                    ${soundsNum > 0 ? `
                        ${soundBlocks.Entry.sound_audio_management_button(workspace, handleAudioManagementStart)}
                    ` : ``}
                    ${soundBlocks.Entry.sound_play_audio(soundName)}
                    ${soundBlocks.Entry.sound_play_sound}
                    ${soundBlocks.Entry.sound_play_recording}
                    ${soundBlocks.Entry.sound_set_speaker_volume_to}
                    ${soundBlocks.Entry.sound_stop_playing_sound}
                    ${blockSeparator}
                    ${soundBlocks.Entry.sound_start_recording_for_seconds}
                ` : ``}
            </category>`: ``
        }
    `;
};