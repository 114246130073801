import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import deleteIcon from './svg/delete_icon.svg';
import {
    hideBottomHintDialog,
    isBottomHintDialogShow
} from '../../reducers/dialog';

class BowserUnsupportedDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
    }

    onClose() {
        this.props.onClose();
    }

    render() {
        // Render nothing if the "show" prop is false
        if (!this.props.show) {
            return null;
        }

        return (
            <div className={styles.bottomHintStyle}>
                <div className={styles.bottomHintTitle}>
                    <FormattedMessage
                        defaultMessage="Bottom Hint Title"
                        description="Bottom Hint Title"
                        id="gui.dialog.bottom.hint.title"
                    />
                </div>
                <div className={styles.bottomHintGoDownloadButton} onClick={() => window.open(`https://www.google.com/intl/${this.props.locale}/chrome/`)}>
                    <FormattedMessage
                        defaultMessage="Go to download"
                        description="Go to download"
                        id="gui.dialog.bottom.hint.button"
                    />
                </div>
                <div className={styles.bottomHintClose} onClick={this.props.onClose}>
                    <img src={deleteIcon} />
                </div>
            </div>
        );
    }
}

BowserUnsupportedDialog.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool,
    locale: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    show: isBottomHintDialogShow(state),
});

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(hideBottomHintDialog()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BowserUnsupportedDialog);