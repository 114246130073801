import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import {
    questionType,
    quesitonDialogShow
} from '../../reducers/dialog';

import {
    projectTitleisSave,
    projectTitleisEdit,
    setProjectTitleOverflow,
    setProjectTitleisEdit
} from '../../reducers/project-title-changed';

import {
    projectPreTitle,
    setProjectTitle,
    setProjectPreTitle,
    projectIsNew
} from '../../reducers/project-title';

import { validFilename } from '../../lib/validFilename';
import {
    platformType,
    isPad,
    getPlatform
} from '../../lib/platform';

/**
 * Higher Order Component to manage inputs that submit on blur and <enter>
 * @param {React.Component} Input text input that consumes onChange, onBlur, onKeyPress
 * @returns {React.Component} Buffered input that calls onSubmit on blur and <enter>
 */
export default function (Input) {
    class BufferedInput extends React.Component {
        constructor(props) {
            super(props);
            bindAll(this, [
                'handleChange',
                'handleKeyPress',
                'handleFlush',
                'handleFocus'
            ]);
            this.state = {
                value: null
            };
        }

        componentDidUpdate(preProps, preState) {
            if (this.props.value != preProps.value) {
                this.setState({
                    value: this.props.value
                });
            }
            if (preProps.projectTitleisEdit != this.props.projectTitleisEdit &&
                !this.props.projectTitleisEdit) {
                // if(preProps.isProjectNameDialogShow != this.props.isProjectNameDialogShow &&
                //     !this.props.isProjectNameDialogShow) {
                //     if(!this.props.projectTitleisSave) {
                //         console.log("isProjectNameDialogShow = ", this.props.isProjectNameDialogShow);
                //         console.log("projectTitleisSave = ", this.props.projectTitleisSave);
                //         this.props.setProjectTitle(this.props.projectPreTitle);
                //         this.props.vm.setProjectName(this.props.projectPreTitle);
                //     }
                //     else {
                //         this.props.setProjectPreTitle(this.props.value);
                //     }
                // }
                if (this.props.isProjectNameDialogShow) {
                    this.props.setProjectTitle(this.props.projectPreTitle);
                    this.props.vm.setProjectName(this.props.projectPreTitle);
                    this.props.onChange(false, false);
                } else {
                    this.props.setProjectPreTitle(this.props.value);
                }
            }
        }

        handleKeyPress(e) {
            if (e.key === 'Enter') {
                e.target.blur();
            }
        }

        handleFlush() {
            console.log("handleFlush");
            this.props.setProjectTitleisEdit(false);
            const isNumeric = typeof this.props.value === 'number';
            const validatesNumeric = isNumeric ? !isNaN(this.state.value) : true;
            if (this.state.value !== null && validFilename(this.state.value)) {
                this.props.setProjectPreTitle(this.state.value);
            }
            if (this.state.value !== null && validatesNumeric) {
                this.props.onSubmit(isNumeric ? Number(this.state.value) : this.state.value);
            }
            this.setState({ value: null });
        }

        handleChange(e) {
            console.log("BufferedInput e.target.value = ", e.target.value);
            this.setState({ value: e.target.value });
            this.props.onSubmit(e.target.value);
            if (e.target.value.length >= 50) {
                console.log("overflow 50 text");
                this.props.setProjectTitleOverflow(true);
                this.props.onChange(true, true);
            }
            else {
                this.props.setProjectTitleOverflow(false);
                if (!validFilename(e.target.value)) {
                    this.props.onChange(true, true);
                }
                else {
                    this.props.onChange(true, false);
                }
            }

        }

        handleFocus(e) {
            console.log("handleFocus");
            this.props.setProjectTitleisEdit(true);
            if (e.target.value.length >= 50) {
                console.log("overflow 50 text");
                this.props.setProjectTitleOverflow(true);
                this.props.onChange(true, true);
            }
            if (getPlatform() == platformType.Web) {
                return;
            }
            if (isPad()) {
                this.props.saveProject();
                e.target.blur();
            }
            // if (this.props.projectIsNew) {
            //     this.props.saveProject();
            //     e.target.blur();
            // }
        }

        render() {
            const bufferedValue = this.state.value === null ? this.props.value : this.state.value;
            const {
                saveProject,
                projectTitleisSave,
                isProjectNameDialogShow,
                projectPreTitle,
                setProjectPreTitle,
                setProjectTitle,
                projectIsNew,
                setProjectTitleOverflow,
                setProjectTitleisEdit,
                projectTitleisEdit,
                ...props
            } = this.props;
            return (
                <Input
                    {...props}
                    value={bufferedValue}
                    onFocus={this.handleFocus}
                    onBlur={this.handleFlush}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    tabIndex="-1"
                />
            );
        }
    }

    BufferedInput.propTypes = {
        onSubmit: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChange: PropTypes.func,
        saveProject: PropTypes.func,
        projectTitleisSave: PropTypes.bool,
        isProjectNameDialogShow: PropTypes.bool,
        projectPreTitle: PropTypes.string,
        setProjectPreTitle: PropTypes.func,
        setProjectTitle: PropTypes.func,
        projectIsNew: PropTypes.bool,
        setProjectTitleOverflow: PropTypes.func,
        setProjectTitleisEdit: PropTypes.func,
        projectTitleisEdit: PropTypes.bool,
        vm: PropTypes.shape({
            setProjectName: PropTypes.func
        })
    };

    const mapStateToProps = state => ({
        projectTitleisSave: projectTitleisSave(state),
        isProjectNameDialogShow: quesitonDialogShow(state, questionType.QUESTION_SAVE_PROJECTNAME),
        projectPreTitle: projectPreTitle(state),
        projectIsNew: projectIsNew(state),
        projectTitleisEdit: projectTitleisEdit(state),
        vm: state.scratchGui.vm
    });

    const mapDispatchToProps = dispatch => ({
        setProjectPreTitle: title => dispatch(setProjectPreTitle(title)),
        setProjectTitle: title => dispatch(setProjectTitle(title)),
        setProjectTitleOverflow: isOverflow => dispatch(setProjectTitleOverflow(isOverflow)),
        setProjectTitleisEdit: isEdit => dispatch(setProjectTitleisEdit(isEdit))
    });

    return connect(mapStateToProps, mapDispatchToProps)(BufferedInput);
}
