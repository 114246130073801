import soundBlocks from '../block-vertical-blocks/sound';

export default function soundEDU(deviceInfo, blockSeparator) {

    return `
        ${deviceInfo.buzzerInfo.hasbuzzer ? `
            <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
                ${deviceInfo.buzzerInfo.hasbuzzer ? `
                    ${soundBlocks.EDU.sound_playnote}
                    ${soundBlocks.EDU.sound_restforbeats}
                    ${soundBlocks.EDU.sound_settempoto}
                    ${soundBlocks.EDU.sound_changetempoby}
                    ${soundBlocks.EDU.sound_tempo}
                    ${soundBlocks.EDU.sound_play}
                ` : ``}
            </category>`: ``
        }
    `;
};