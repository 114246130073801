import { EDIT_MODE } from '../lib/edit-mode'

const CODE = 'scratch-gui/codeEditor/CODE';
const DISPLAY_MODE = 'scratch-gui/codeEditor/DISPLAY_MODE';
const EDITOR = 'scratch-gui/codeEditor/EDITOR';
const NEW_EDITOR = 'scratch-gui/codeEditor/NEW_EDITOR';
const UNDO_STATE = 'scratch-gui/codeEditor/UNDO_STATE';
const REDO_STATE = 'scratch-gui/codeEditor/REDO_STATE';
const CODE_VIEW = 'scratch-gui/codeEditor/CODE_VIEW';
const HIGHLIGHT_MODE = 'scratch-gui/codeEditor/HIGHLIGHT_MODE';
const HIGHLIGHT_BLOCK = 'scratch-gui/codeEditor/HIGHLIGHT_BLOCK';
const HIGHLIGHT_FIELD = 'scratch-gui/codeEditor/HIGHLIGHT_FIELD';

const initialState = {
    editMode: EDIT_MODE.BLOCK_MODE,
    editor: null,
    newEditor: false,
    undo: false,
    redo: false,
    code: null,
    codeView: false,
    highLightMode: false,
    highLightBlockId: null,
    highLightField: null
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case CODE:
            return Object.assign({}, state, {
                code: action.code
            });
        case DISPLAY_MODE:
            return Object.assign({}, state, {
                editMode: action.editMode
            });
        case EDITOR:
            return Object.assign({}, state, {
                editor: action.editor
            });
        case NEW_EDITOR:
            return Object.assign({}, state, {
                newEditor: action.state
            });
        case UNDO_STATE:
            return Object.assign({}, state, {
                undo: action.state
            });
        case REDO_STATE:
            return Object.assign({}, state, {
                redo: action.state
            });
        case CODE_VIEW:
            return Object.assign({}, state, {
                codeView: action.show
            });
        case HIGHLIGHT_MODE:
            return Object.assign({}, state, {
                highLightMode: action.show
            });
        case HIGHLIGHT_BLOCK:
            return Object.assign({}, state, {
                highLightBlockId: action.id
            });
        case HIGHLIGHT_FIELD:
            return Object.assign({}, state, {
                highLightField: action.field
            });
        default:
            return state;
    }
};

const setCode = (code) => ({
    type: CODE,
    code: code
});

const setEditorDisplayMode = (mode) => ({
    type: DISPLAY_MODE,
    editMode: mode
});

const setUndoState = (state) => ({
    type: UNDO_STATE,
    state: state
});

const setRedoState = (state) => ({
    type: REDO_STATE,
    state: state
});

const setEditor = (editor) => ({
    type: EDITOR,
    editor: editor
});

const setNewEditor = (state) => ({
    type: NEW_EDITOR,
    state: state
});

const setCodeView = (show) => ({
    type: CODE_VIEW,
    show: show
});

const setHighLightMode = (show) => ({
    type: HIGHLIGHT_MODE,
    show: show
});

const setHighLightBlockId = (id) => ({
    type: HIGHLIGHT_BLOCK,
    id: id
});

const setHighLightField = (field) => ({
    type: HIGHLIGHT_FIELD,
    field: field
});

const getCode = (state) => state.scratchGui.codeEditor.code;
const getEditorDisplayMode = (state) => state.scratchGui.codeEditor.editMode;
const getEditor = (state) => state.scratchGui.codeEditor.editor;
const getNewEditorState = (state) => state.scratchGui.codeEditor.newEditor;
const getUndoState = (state) => state.scratchGui.codeEditor.undo;
const getRedoState = (state) => state.scratchGui.codeEditor.redo;
const getCodeViewState = (state) => state.scratchGui.codeEditor.codeView;
const getHighLightMode = (state) => state.scratchGui.codeEditor.highLightMode;
const getHighLightBlockId = (state) => state.scratchGui.codeEditor.highLightBlockId;
const getHighLightField = (state) => state.scratchGui.codeEditor.highLightField;

export {
    reducer as default,
    initialState as codeEditorInitialState,
    setCode,
    getCode,
    setEditorDisplayMode,
    getEditorDisplayMode,
    setEditor,
    getEditor,
    setNewEditor,
    getNewEditorState,
    setUndoState,
    getUndoState,
    setRedoState,
    getRedoState,
    setCodeView,
    getCodeViewState,
    setHighLightMode,
    getHighLightMode,
    setHighLightBlockId,
    getHighLightBlockId,
    setHighLightField,
    getHighLightField
};
