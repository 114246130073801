const CMD_CV = {
    // ENTRY cv commands
    INIT: "@$cvcmd[@:]cv_init",
    START_REALTIME: "@$cvcmd[@:]cv_startrealtime",
    SET_FUNCTION: "@$cvcmd[@:]cv_setfunction",
    STOP_REALTIME: "@$cvcmd[@:]cv_stoprealtime",
    PAUSE_REALTIME: "@$cvcmd[@:]cv_pauserealtime",
    SNAPSHOT: "@$cvcmd[@:]cv_snapshot",
    LOADIMAGE: "@$cvcmd[@:]cv_loadimage",
    STOPSTREAM: "@$cvcmd[@:]cv_stopstream",
    GETTAGS: "@$cvcmd[@:]cv_gettags",
    SETTAGS: "@$cvcmd[@:]cv_settags",
    SELECTCOLOR: "@$cvcmd[@:]cv_selectcolor",
    ISQRCODEDETECTED: "@$cvcmd[@:]cv_isqrcodedetected",
    GETQRCODEMSG: "@$cvcmd[@:]cv_getqrcodemsg",
    ISOBJECTDETECTED: "@$cvcmd[@:]cv_isobjectdetected",
    COUNTDETECTEDOBJECT: "@$cvcmd[@:]cv_countdetectedobject",
    GETDETECTEDOBJECTATTR: "@$cvcmd[@:]cv_getdetectedobjectattr",
    FACERECOG_INIT: "@$cvcmd[@:]cv_initfacerecogmodule",
    FACERECOG_GET_FEATURE: "@$cvcmd[@:]cv_getfacefeature",
    FACERECOG_LOAD_DATA: "@$cvcmd[@:]cv_loadfacedata",
    FACERECOG_GET_RESULT: "@$cvcmd[@:]cv_getfaceresult",
    OCR_SETLANGUAGE: "@$cvcmd[@:]cv_setocrlanguage",
    OCR_GETRESULT: "@$cvcmd[@:]cv_getocrresult",
}

const CV_PATH_SNAPSHOT_DEFAULT = "/tmp/snapshot/image.jpg";

const CMD_CV_Feedback = {
    INIT: "@$cvres[@:]cv_init[@:]True[@:]",
    FACERECOG_INIT: "@$cvres[@:]cv_initfacerecogmodule[@:]None",
    FALSE_VM: "@$cvres[@:]cv_init[@:]False[@:]vm",
    INTERRUPT_VM: "@$cvres[@:]cv_init[@:]Interrupt[@:]vm",
}

function cvInitFaceRecogModule() {
    return CMD_CV.FACERECOG_INIT;
}

function cvStartRealtime(mode, langList) {
    var cmd;
    if (mode && mode !== "") {
        if (langList) {
            cmd = CMD_CV.START_REALTIME + "[@:]" + mode.trim() + "[@:]" + JSON.stringify(langList);
        } else {
            cmd = CMD_CV.START_REALTIME + "[@:]" + mode.trim() + "[@:]" + '["eng"]';
        }
    } else {
        if (langList) {
            cmd = CMD_CV.START_REALTIME + "[@:]" + "1" + "[@:]" + JSON.stringify(langList);
        } else {
            cmd = CMD_CV.START_REALTIME + "[@:]" + "1" + "[@:]" + '["eng"]';
        }
    }
    return cmd;
}

function cvStopRealtime() {
    return CMD_CV.STOP_REALTIME;
}

function cvSetFunction(s) {
    var cmd;
    if (s && s !== "") {
        cmd = CMD_CV.SET_FUNCTION + "[@:]" + s.trim();
    } else {
        cmd = CMD_CV.SET_FUNCTION + "[@:]" + "1";
    }
    return cmd;
}

function cvSetTags(list) {
    return CMD_CV.SETTAGS + "[@:]" + JSON.stringify(list).trim();
}

function cvGetTags() {
    return CMD_CV.GETTAGS;
}

function cvSnapshot() {
    return CMD_CV.SNAPSHOT;
}

function cvSelectColor(rect) {
    // rect: [x, y, width, height]
    var rectstr = "[" + rect.toString() + "]";
    return CMD_CV.SELECTCOLOR + "[@:]" + rectstr;
}

function cvSetOCRLanguage(language) {
    if (!language) {
        language = ["eng"];
    }
    return CMD_CV.OCR_SETLANGUAGE + "[@:]" + JSON.stringify(language).trim();
}

function cvGetFaceRecogResult() {
    return CMD_CV.FACERECOG_GET_RESULT + "[@:]" + CV_PATH_SNAPSHOT_DEFAULT;
}

function cvGetOCRResult(lang) {
    if (!lang) {
        lang = "eng";
    }
    return CMD_CV.OCR_GETRESULT + "[@:]" + CV_PATH_SNAPSHOT_DEFAULT + "[@:]" + lang.trim();
}

function cvgetQRCodeMsg(imgPath) {
    // imgPath: image path, ex:/tmp/snapshot/image.jpg
    if (!imgPath) {
        imgPath = CV_PATH_SNAPSHOT_DEFAULT;
    }
    return CMD_CV.GETQRCODEMSG + "[@:]" + imgPath.trim();
}

function cvGetFaceFeature() {
    return CMD_CV.FACERECOG_GET_FEATURE + "[@:]" + CV_PATH_SNAPSHOT_DEFAULT;
}

function cvLoadFaceData(data) {
    if(!data || data == "") {
        return CMD_CV.FACERECOG_LOAD_DATA + "[@:]" + JSON.stringify([]);
    } else {
        return CMD_CV.FACERECOG_LOAD_DATA + "[@:]" + JSON.stringify(data);
    } 
}

export {
    CMD_CV_Feedback,
    cvInitFaceRecogModule,
    cvStartRealtime,
    cvStopRealtime,
    cvSetFunction,
    cvSetTags,
    cvGetTags,
    cvSnapshot,
    cvSelectColor,
    cvSetOCRLanguage,
    cvGetFaceRecogResult,
    cvGetOCRResult,
    cvgetQRCodeMsg,
    cvGetFaceFeature,
    cvLoadFaceData,
}