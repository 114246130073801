import ScratchBlocks from 'scratch-blocks';
import { VISION_DEFAULT_LANGUAGE_MAPPING } from '../components/dialog/vision/vision-utils'
import {
    EditUtils
} from '../components/device-manager/edit-page/edit-utils.js';

/**
 * Connect scratch blocks with the vm
 * @param {VirtualMachine} vm - The scratch vm
 * @return {ScratchBlocks} ScratchBlocks connected with the vm
 */
export default function (vm) {

    const jsonForMenuBlock = function (name, menuOptionsFn, colors, start) {
        return {
            message0: '%1',
            args0: [
                {
                    type: 'field_dropdown',
                    name: name,
                    options: function () {
                        return start.concat(menuOptionsFn());
                    }
                }
            ],
            inputsInline: true,
            output: 'String',
            colour: colors.secondary,
            colourSecondary: colors.secondary,
            colourTertiary: colors.tertiary,
            colourQuaternary: colors.quaternary,
            outputShape: ScratchBlocks.OUTPUT_SHAPE_ROUND
        };
    };

    const jsonForHatBlockMenu = function (hatName, name, menuOptionsFn, colors, start) {
        return {
            message0: hatName,
            args0: [
                {
                    type: 'field_dropdown',
                    name: name,
                    options: function () {
                        return start.concat(menuOptionsFn());
                    }
                }
            ],
            colour: colors.primary,
            colourSecondary: colors.secondary,
            colourTertiary: colors.tertiary,
            colourQuaternary: colors.quaternary,
            extensions: ['shape_hat']
        };
    };


    const jsonForSensingMenus = function (menuOptionsFn) {
        return {
            message0: ScratchBlocks.Msg.SENSING_OF,
            args0: [
                {
                    type: 'field_dropdown',
                    name: 'PROPERTY',
                    options: function () {
                        return menuOptionsFn();
                    }

                },
                {
                    type: 'input_value',
                    name: 'OBJECT'
                }
            ],
            output: true,
            colour: ScratchBlocks.Colours.sensing.primary,
            colourSecondary: ScratchBlocks.Colours.sensing.secondary,
            colourTertiary: ScratchBlocks.Colours.sensing.tertiary,
            colourQuaternary: ScratchBlocks.Colours.sensing.quaternary,
            outputShape: ScratchBlocks.OUTPUT_SHAPE_ROUND
        };
    };

    const jsonForMotorBlock = function (name, menuOptionsFn, colors, start) {
        return {
            message0: '%1',
            args0: [
                {
                    type: 'field_dropdown',
                    name: name,
                    options: function () {
                        return start.concat(menuOptionsFn());
                    }
                }
            ],
            inputsInline: true,
            output: 'Motion_motor',
            colour: colors.secondary,
            colourSecondary: colors.tertiary,
            colourTertiary: colors.tertiary,
            colourQuaternary: colors.quaternary,
            outputShape: ScratchBlocks.OUTPUT_SHAPE_ROUND
        };
    };

    const jsonForDynamicBlockMenu = function (messageName, arg, colors, extensions) {
        return {
            message0: messageName,
            args0: arg,
            colour: colors.primary,
            colourSecondary: colors.secondary,
            colourTertiary: colors.tertiary,
            colourQuaternary: colors.quaternary,
            extensions: extensions
        };
    };

    const soundsMenu = function () {
        let menu = [];
        if (vm.editingTarget && vm.editingTarget.sprite.sounds.length > 0) {
            menu = vm.editingTarget.sprite.sounds.map(sound => [sound.name, sound.name]);
        }
        menu.push([
            ScratchBlocks.Msg.SHEETMUSIC_NEW,
            ScratchBlocks.NEW_SOUND_ID
        ]);
        if (vm.editingTarget && vm.editingTarget.sprite.sounds.length > 0) {
            menu.push([
                ScratchBlocks.Msg.SHEETMUSIC_DELETE_FILE,
                ScratchBlocks.DELETE_SOUND_ID
            ]);
        }
        return menu;
    };

    const costumesMenu = function () {
        if (vm.editingTarget && vm.editingTarget.getCostumes().length > 0) {
            return vm.editingTarget.getCostumes().map(costume => [costume.name, costume.name]);
        }
        return [['', '']];
    };

    const backdropsMenu = function () {
        const next = ScratchBlocks.ScratchMsgs.translate('LOOKS_NEXTBACKDROP', 'next backdrop');
        const previous = ScratchBlocks.ScratchMsgs.translate('LOOKS_PREVIOUSBACKDROP', 'previous backdrop');
        const random = ScratchBlocks.ScratchMsgs.translate('LOOKS_RANDOMBACKDROP', 'random backdrop');
        if (vm.runtime.targets[0] && vm.runtime.targets[0].getCostumes().length > 0) {
            return vm.runtime.targets[0].getCostumes().map(costume => [costume.name, costume.name])
                .concat([[next, 'next backdrop'],
                [previous, 'previous backdrop'],
                [random, 'random backdrop']]);
        }
        return [['', '']];
    };

    const backdropNamesMenu = function () {
        const stage = vm.runtime.getTargetForStage();
        if (stage && stage.getCostumes().length > 0) {
            return stage.getCostumes().map(costume => [costume.name, costume.name]);
        }
        return [['', '']];
    };

    const spriteMenu = function () {
        const sprites = [];
        for (const targetId in vm.runtime.targets) {
            if (!vm.runtime.targets.hasOwnProperty(targetId)) continue;
            if (vm.runtime.targets[targetId].isOriginal) {
                if (!vm.runtime.targets[targetId].isStage) {
                    if (vm.runtime.targets[targetId] === vm.editingTarget) {
                        continue;
                    }
                    sprites.push([vm.runtime.targets[targetId].sprite.name, vm.runtime.targets[targetId].sprite.name]);
                }
            }
        }
        return sprites;
    };

    const cloneMenu = function () {
        if (vm.editingTarget && vm.editingTarget.isStage) {
            const menu = spriteMenu();
            if (menu.length === 0) {
                return [['', '']]; // Empty menu matches Scratch 2 behavior
            }
            return menu;
        }
        const myself = ScratchBlocks.ScratchMsgs.translate('CONTROL_CREATECLONEOF_MYSELF', 'myself');
        return [[myself, '_myself_']].concat(spriteMenu());
    };

    const deviceNamesMenu = function (deviceType = ['']) {
        let defaultNameList = [EditUtils.getLocaleString("gui.device.no.device")]
        let deviceNameList = vm.getDeviceListForBlock();
        for (let i = 0; i < deviceNameList.length; i++) {
            if (deviceType.indexOf(deviceNameList[i].type) != -1) {
                defaultNameList = defaultNameList.concat(deviceNameList[i].nameList)
            }
        }
        let resultNameList = []
        defaultNameList.forEach((deviceName) => {
            resultNameList.push([deviceName, deviceName])
        })
        if (resultNameList.length > 1) {
            resultNameList.splice(0, 1)
        }
        return resultNameList;
    };

    const TYPE_THREE_WIRE_MOTOR = 'threeWireMotor';
    const TYPE_MOTOR = 'motor';
    const TYPE_MOTOR_100 = 'motor100';
    const TYPE_MOTOR_300 = 'motor300';

    const motor3wireNamesMenu = function () {
        return deviceNamesMenu(TYPE_THREE_WIRE_MOTOR);
    };

    const motorNamesMenu = function (contain3wire) {
        if (contain3wire) {
            return deviceNamesMenu([TYPE_THREE_WIRE_MOTOR, TYPE_MOTOR_100, TYPE_MOTOR_300]);
        } else {
            return deviceNamesMenu([TYPE_MOTOR_100, TYPE_MOTOR_300]);
        }
    };

    const lightSensorNamesMenu = function () {
        return deviceNamesMenu(['lightSensor']);
    };

    const lineTrackerNamesMenu = function () {
        return deviceNamesMenu(['lineTracker']);
    };

    const buzzerNamesMenu = function () {
        return deviceNamesMenu(['buzzer']);
    };

    const ledNamesMenu = function () {
        return deviceNamesMenu(['led']);
    };

    const sonarNamesMenu = function () {
        return deviceNamesMenu(['ultrasonic']);
    };

    const bumperNamesMenu = function () {
        return deviceNamesMenu(['bumper']);
    };

    const touchledNamesMenu = function () {
        return deviceNamesMenu(['touchLed']);
    };

    const gyroNamesMenu = function () {
        return deviceNamesMenu(['gyro']);
    };

    const gyroNamesWithDrivetrainMenu = function () {
        let deviceList = [[EditUtils.getLocaleString("gui.device.no.device"), EditUtils.getLocaleString("gui.device.no.device")]]
        let drivetrainList = vm.getDrivetrain();
        if (drivetrainList && drivetrainList.connectPortArray.length > 2 && drivetrainList.other.gyro) {
            deviceList = deviceList.concat([[drivetrainList.other.gyroName, drivetrainList.other.gyroName]]);
        }
        let deviceNameList = vm.getDeviceListForBlock();
        let deviceData = deviceNameList.find(device => device.type == 'gyro');
        if (deviceNameList.length > 0 && deviceData && deviceData.nameList.length > 0) {
            deviceList = deviceList.concat(deviceNamesMenu(['gyro']));
        }
        if (deviceList.length > 1) {
            deviceList.splice(0, 1);
        }
        return deviceList;
    };

    const drivetrainNamesMenu = function () {
        return deviceNamesMenu(['drivetrain']);
    };

    const colorNamesMenu = function () {
        return deviceNamesMenu(['colorSensor']);
    };

    const controllerNamesMenu = function () {
        let controllerListFromVM = vm.getControllerList();
        let controllerNameList = [[EditUtils.getLocaleString("gui.device.no.device"), EditUtils.getLocaleString("gui.device.no.device")]];
        controllerListFromVM.forEach((controller, i) => {
            if (controller != null) {
                controllerNameList.push([ScratchBlocks.Msg.DEFAULT_NAME_CONTROLLER + (i + 1), 'Controller' + (i + 1)])
            }
        })
        if (controllerNameList.length > 1) {
            controllerNameList.splice(0, 1)
        }
        return controllerNameList;
    };

    const getforwardMenu = function (selectedItem, motorType) {
        let deviceNameList = getMotorList(motorType);
        let index = deviceNameList.findIndex((item, i) => {
            return item.name === selectedItem
        });
        if (index == -1) {
            index = 0;
        }
        if (deviceNameList.length > 0) {
            return [[deviceNameList[index].forward, 'forward'], [deviceNameList[index].reverse, 'reverse']];
        } else {
            return [[ScratchBlocks.Msg.MOTION_FORWARD, 'forward'], [ScratchBlocks.Msg.MOTION_REVERSE, 'reverse']];
        }
    };

    const getMotorList = function (motorType) {
        if (motorType == TYPE_THREE_WIRE_MOTOR) {
            return vm.getThreeWireMotorList();
        } else if (motorType == TYPE_MOTOR) {
            return vm.getMotor100List().concat(vm.getMotor300List());
        }
    }

    const conceptNameMenu = function () {
        let concepts = vm.getSpeakerConcepts();
        if (concepts && concepts.length > 0) {
            return concepts.map(concept => [concept.tag.toString(), concept.id]);
        } else {
            return [['defaultConcept', 'defaultConcept-123456']]
        }
    };

    const soundColors = ScratchBlocks.Colours.sounds;

    const looksColors = ScratchBlocks.Colours.looks;

    const motionColors = ScratchBlocks.Colours.motion;

    const sensingColors = ScratchBlocks.Colours.sensing;

    const controlColors = ScratchBlocks.Colours.control;

    const eventColors = ScratchBlocks.Colours.event;

    const dataListsColors = ScratchBlocks.Colours.data_lists;

    const aiSpeechColors = ScratchBlocks.Colours.ai_speech;

    const extensionColors = ScratchBlocks.Colours.extensions;

    ScratchBlocks.Blocks.sound_sounds_menu.init = function () {
        const json = jsonForMenuBlock('SOUND_MENU', soundsMenu, soundColors, []);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.looks_costume.init = function () {
        const json = jsonForMenuBlock('COSTUME', costumesMenu, looksColors, []);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.looks_backdrops.init = function () {
        const json = jsonForMenuBlock('BACKDROP', backdropsMenu, looksColors, []);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.event_whentouchledbutton.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.EVENT_WHENTOUCHLEDBUTTON,
            [{
                type: 'field_dropdown',
                name: 'TOUCHLEDNAME',
                options: function () {
                    return start.concat(touchledNamesMenu());
                }
            },
            {
                "type": "field_dropdown",
                "name": "PRESSED",
                "options": [
                    [ScratchBlocks.Msg.EVENT_PRESSED, 'pressed'],
                    [ScratchBlocks.Msg.EVENT_RELEASED, 'released']
                ]
            }]
            , eventColors, ['shape_hat']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.event_whenbackdropswitchesto.init = function () {
        const json = jsonForHatBlockMenu(
            ScratchBlocks.Msg.EVENT_WHENBACKDROPSWITCHESTO,
            'BACKDROP', backdropNamesMenu, eventColors, []);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.event_whenbumperbutton.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.EVENT_WHENBUMPERBUTTON,
            [{
                type: 'field_dropdown',
                name: 'BUMPERNAME',
                options: function () {
                    return start.concat(bumperNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'PRESSED',
                options: [
                    [ScratchBlocks.Msg.EVENT_PRESSED, 'pressed'],
                    [ScratchBlocks.Msg.EVENT_RELEASED, 'released']
                ]
            }]
            , eventColors, ['shape_hat']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.event_whencontrollerbutton.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.EVENT_WHENCONTROLLERBUTTON,
            [{
                type: 'field_dropdown',
                name: 'CONTROLLERNAME',
                options: function () {
                    return start.concat(controllerNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'BUTTON',
                options: [
                    [ScratchBlocks.Msg.CONTROLLER_UP, 'Up'],
                    [ScratchBlocks.Msg.CONTROLLER_RIGHT, 'Right'],
                    [ScratchBlocks.Msg.CONTROLLER_DOWN, 'Down'],
                    [ScratchBlocks.Msg.CONTROLLER_LEFT, 'Left'],
                    [ScratchBlocks.Msg.CONTROLLER_X, 'X'],
                    [ScratchBlocks.Msg.CONTROLLER_Y, 'Y'],
                    [ScratchBlocks.Msg.CONTROLLER_B, 'B'],
                    [ScratchBlocks.Msg.CONTROLLER_A, 'A'],
                    [ScratchBlocks.Msg.CONTROLLER_R1, 'R1'],
                    [ScratchBlocks.Msg.CONTROLLER_R2, 'R2'],
                    [ScratchBlocks.Msg.CONTROLLER_L1, 'L1'],
                    [ScratchBlocks.Msg.CONTROLLER_L2, 'L2']
                ]
            },
            {
                type: 'field_dropdown',
                name: "PRESSED",
                options: [
                    [ScratchBlocks.Msg.EVENT_PRESSED, 'pressed'],
                    [ScratchBlocks.Msg.EVENT_RELEASED, 'released']
                ]
            }]
            , eventColors, ['shape_hat']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.event_whencontrolleraxischanged.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.EVENT_WHENCONTROLLERAXISCHANGED,
            [{
                type: 'field_dropdown',
                name: 'CONTROLLERNAME',
                options: function () {
                    return start.concat(controllerNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'AXIS',
                options: [
                    [ScratchBlocks.Msg.CONTROLLER_LEFT_JOYSTICK_RIGHT_LEFT, 'Left_joystick_right_left'],
                    [ScratchBlocks.Msg.CONTROLLER_LEFT_JOYSTICK_UP_DOWN, 'Left_joystick_up_down'],
                    [ScratchBlocks.Msg.CONTROLLER_RIGHT_JOYSTICK_RIGHT_LEFT, 'Right_joystick_right_left'],
                    [ScratchBlocks.Msg.CONTROLLER_RIGHT_JOYSTICK_UP_DOWN, 'Right_joystick_up_down']
                ]
            }]
            , eventColors, ['shape_hat']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_controllerbuttonpressed.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_CONTROLLERBUTTONPRESSED,
            [{
                type: 'field_dropdown',
                name: 'CONTROLLERNAME',
                options: function () {
                    return start.concat(controllerNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'BUTTON',
                options: [
                    [ScratchBlocks.Msg.CONTROLLER_UP, 'Up'],
                    [ScratchBlocks.Msg.CONTROLLER_RIGHT, 'Right'],
                    [ScratchBlocks.Msg.CONTROLLER_DOWN, 'Down'],
                    [ScratchBlocks.Msg.CONTROLLER_LEFT, 'Left'],
                    [ScratchBlocks.Msg.CONTROLLER_X, 'X'],
                    [ScratchBlocks.Msg.CONTROLLER_Y, 'Y'],
                    [ScratchBlocks.Msg.CONTROLLER_B, 'B'],
                    [ScratchBlocks.Msg.CONTROLLER_A, 'A'],
                    [ScratchBlocks.Msg.CONTROLLER_R1, 'R1'],
                    [ScratchBlocks.Msg.CONTROLLER_R2, 'R2'],
                    [ScratchBlocks.Msg.CONTROLLER_L1, 'L1'],
                    [ScratchBlocks.Msg.CONTROLLER_L2, 'L2']
                ]
            }]
            , sensingColors, ['output_boolean']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_controllerposition.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_CONTROLLERPOSITION,
            [{
                type: 'field_dropdown',
                name: 'CONTROLLERNAME',
                options: function () {
                    return start.concat(controllerNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'BUTTON',
                options: [
                    [ScratchBlocks.Msg.CONTROLLER_LEFT_JOYSTICK_RIGHT_LEFT, 'Left_joystick_right_left'],
                    [ScratchBlocks.Msg.CONTROLLER_LEFT_JOYSTICK_UP_DOWN, 'Left_joystick_up_down'],
                    [ScratchBlocks.Msg.CONTROLLER_RIGHT_JOYSTICK_RIGHT_LEFT, 'Right_joystick_right_left'],
                    [ScratchBlocks.Msg.CONTROLLER_RIGHT_JOYSTICK_UP_DOWN, 'Right_joystick_up_down']
                ]
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_bumperpressed.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_BUMPERPRESSED,
            [{
                type: 'field_dropdown',
                name: 'BUMPERNAME',
                options: function () {
                    return start.concat(bumperNamesMenu());
                }
            }]
            , sensingColors, ['output_boolean']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_touchledpressed.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_TOUCHLEDPRESSED,
            [{
                type: 'field_dropdown',
                name: 'TOUCHLEDNAME',
                options: function () {
                    return start.concat(touchledNamesMenu());
                }
            }]
            , sensingColors, ['output_boolean']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_gyrocalibrateforseconds.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_GYROCALIBRATEFORSECONDS,
            [{
                type: 'field_dropdown',
                name: 'GYRONAME',
                options: function () {
                    return start.concat(gyroNamesWithDrivetrainMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'SECONDS',
                options: [
                    ['2', '2'],
                    ['4', '4'],
                    ['8', '8']
                ]
            }]
            , sensingColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_gyrosetheadingtodegree.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_GYROSETHEADINGTODEGREE,
            [{
                type: 'field_dropdown',
                name: 'GYRONAME',
                options: function () {
                    return start.concat(gyroNamesMenu());
                }
            },
            {
                type: 'input_value',
                name: 'DEGREE',
                check: ["String", "Number"]
            }]
            , sensingColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_gyrosetrotationtodegree.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_GYROSETROTATIONTODEGREE,
            [{
                type: 'field_dropdown',
                name: 'GYRONAME',
                options: function () {
                    return start.concat(gyroNamesMenu());
                }
            },
            {
                type: 'input_value',
                name: 'DEGREE',
                check: ["String", "Number"]
            }]
            , sensingColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_gyroheadingofindegree.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_GYROHEADINGOFINDEGREE,
            [{
                type: 'field_dropdown',
                name: 'GYRONAME',
                options: function () {
                    return start.concat(gyroNamesMenu());
                }
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_gyroheadingindegree.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_GYROHEADINGINDEGREE,
            [{
                type: 'field_dropdown',
                name: 'GYRONAME',
                options: function () {
                    return start.concat(gyroNamesMenu());
                }
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_gyrorotationindegree.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_GYROROATIONINDEGREE,
            [{
                type: 'field_dropdown',
                name: 'GYRONAME',
                options: function () {
                    return start.concat(gyroNamesMenu());
                }
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_gyrorateindps.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_GYRORATEINDPS,
            [{
                type: 'field_dropdown',
                name: 'GYRONAME',
                options: function () {
                    return start.concat(gyroNamesMenu());
                }
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_linetrackerreflectivityin.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_LINETRACKERREFLECTIVITYIN,
            [{
                type: 'field_dropdown',
                name: 'LINETRACKERNAME',
                options: function () {
                    return start.concat(lineTrackerNamesMenu());
                }
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_sonarfoundobject.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_SONARFOUNDOBJECT,
            [{
                type: 'field_dropdown',
                name: 'SONARNAME',
                options: function () {
                    return start.concat(sonarNamesMenu());
                }
            }]
            , sensingColors, ['output_boolean']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_sonardistancein.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_SONARDISTANCEIN,
            [{
                type: 'field_dropdown',
                name: 'SONARNAME',
                options: function () {
                    return start.concat(sonarNamesMenu());
                }
            },
            {
                "type": "field_dropdown",
                "name": "UNIT",
                "options": [
                    [ScratchBlocks.Msg.UNIT_CM, 'cm'],
                    [ScratchBlocks.Msg.UNIT_INCHES, 'inches']
                ]
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_colorisnearobject.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_COLORISNEAROBJECT,
            [{
                type: 'field_dropdown',
                name: 'COLORNAME',
                options: function () {
                    return start.concat(colorNamesMenu());
                }
            }]
            , sensingColors, ['output_boolean']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_colordetects.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_COLORDETECTS,
            [{
                type: 'field_dropdown',
                name: 'COLORNAME',
                options: function () {
                    return start.concat(colorNamesMenu());
                }
            },
            {
                "type": "field_dropdown",
                "name": "UNIT",
                "options": [
                    [ScratchBlocks.Msg.COLOR_RED, 'red'],
                    [ScratchBlocks.Msg.COLOR_ORANGE, 'orange'],
                    [ScratchBlocks.Msg.COLOR_YELLOW, 'yellow'],
                    [ScratchBlocks.Msg.COLOR_GREEN, 'green'],
                    [ScratchBlocks.Msg.COLOR_SKY_BLUE, 'sky blue'],
                    [ScratchBlocks.Msg.COLOR_BLUE, 'blue'],
                    [ScratchBlocks.Msg.COLOR_PURPLE, 'purple'],
                    [ScratchBlocks.Msg.COLOR_PINK, 'pink']
                ]
            }]
            , sensingColors, ['output_boolean']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_colorname.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_COLORNAME,
            [{
                type: 'field_dropdown',
                name: 'COLORNAME',
                options: function () {
                    return start.concat(colorNamesMenu());
                }
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_colorhueindegrees.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_COLORHUEINDEGREES,
            [{
                type: 'field_dropdown',
                name: 'COLORNAME',
                options: function () {
                    return start.concat(colorNamesMenu());
                }
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_colorbrightnessin.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_COLORBRIGHTNESSIN,
            [{
                type: 'field_dropdown',
                name: 'COLORNAME',
                options: function () {
                    return start.concat(colorNamesMenu());
                }
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_visionobjectexistsinthepicture.init = function () {
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_VISIONOBJECTEXISTSINTHEPICTURE,
            [{
                type: 'field_dropdown',
                name: 'TAG',
                options: function () {
                    return getTags();
                }
            }]
            , sensingColors, ['output_boolean']);
        this.jsonInit(json);
    }

    const getTags = function () {
        let visionTag = vm.getCvTagSettings().filter(tag => tag.color_name != null).map(tag => tag.color_name);
        let tags = [];
        let tagTranslate;
        visionTag.forEach((tagName, index) => {
            tagTranslate = VISION_DEFAULT_LANGUAGE_MAPPING[vm.getLocale()][tagName] || tagName;
            tags.push([tagTranslate, visionTag[index]]);
        })
        tags = [[ScratchBlocks.Msg.SENSING_VISION_ANY_TAGGED, 'any tagged']].concat(tags);
        return tags;
    };

    ScratchBlocks.Blocks.sensing_visionInThePicture.init = function () {
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_VISIONINTHEPICTURE,
            [{
                type: 'field_dropdown',
                name: 'FACENAME',
                options: function () {
                    return getFaceName().length > 0 ? getFaceName() : [[ScratchBlocks.Msg.SENSING_VISIONWHOM, ScratchBlocks.Msg.SENSING_VISIONWHOM]];
                }
            }]
            , sensingColors, ['output_boolean']);
        this.jsonInit(json);
    }

    ScratchBlocks.Blocks.sensing_visionProbabilityOfInThePicture.init = function () {
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_VISIONPROBABILITYOFINTHEPICTURE,
            [{
                type: 'field_dropdown',
                name: 'FACENAME',
                options: function () {
                    return getFaceName().length > 0 ? getFaceName() : [[ScratchBlocks.Msg.SENSING_VISIONWHOM, ScratchBlocks.Msg.SENSING_VISIONWHOM]];
                }
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    }

    const getFaceName = function () {
        let visionTag = vm.getCvProfiles().filter(profile => profile.feature.length > 0).map(profile => profile.name);
        let tagDropdown = [];
        visionTag.forEach((tag) => {
            tagDropdown.push([tag, tag]);
        })
        return tagDropdown;
    };

    ScratchBlocks.Blocks.motion_motor.init = function () {
        const json = jsonForMotorBlock('MOTORNAME', motorNamesMenu, motionColors, []);
        this.jsonInit(json);
    };

    var forwardMenufn = function (blockId, motorType) {
        if (vm.editingTarget) {
            let lookupBlocks = vm.editingTarget.blocks;
            let motionSpinBlock = lookupBlocks.getBlock(blockId);
            // The block doesn't exist, but should be in the flyout. Look there.
            if (!motionSpinBlock) {
                motionSpinBlock = vm.runtime.flyoutBlocks.getBlock(blockId);

                // If we still don't have a block, just return an empty list . This happens during
                // scratch blocks construction.
                if (!motionSpinBlock) {
                    return getforwardMenu('Motor', motorType);;
                }
                // The block was in the flyout so look up future block info there.
                lookupBlocks = vm.runtime.flyoutBlocks;
            }
            // const menuBlock = lookupBlocks.getBlock(motionSpinBlock.inputs.MOTORNAME.shadow);
            const selectedItem = motionSpinBlock.fields.MOTORNAME.value;
            return getforwardMenu(selectedItem, motorType);
        }
        return [[ScratchBlocks.Msg.MOTION_FORWARD, 'forward'],
        [ScratchBlocks.Msg.MOTION_REVERSE, 'reverse']];
    }

    ScratchBlocks.Blocks.motion_spin.init = function () {
        let blockId = this.id;
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SPIN,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu(true));
                }
            },
            {
                type: 'field_dropdown',
                name: 'FORWARD',
                options: function () {
                    return forwardMenufn(blockId, TYPE_MOTOR)
                }
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_spindegree.init = function () {
        let blockId = this.id;
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SPINDEGREE,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'FORWARD',
                options: function () {
                    return forwardMenufn(blockId, TYPE_MOTOR)
                }
            },
            {
                type: 'input_value',
                name: 'NUMBER',
                check: ["String", "Number"]
            },
            {
                type: "field_dropdown",
                name: "DEGREE",
                options: [
                    [ScratchBlocks.Msg.MOTION_DEGREES, 'degrees'],
                    [ScratchBlocks.Msg.MOTION_TURNS, 'turns']
                ]
            },
            {
                type: "field_dropdown",
                name: "WAIT",
                options: [
                    [ScratchBlocks.Msg.UNIT_WAIT, 'wait'],
                    [ScratchBlocks.Msg.UNIT_DONT_WAIT, 'dont_wait']
                ]
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_spinpositiondegree.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SPINPOSITIONDEGREE,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            },
            {
                "type": "input_value",
                "name": "NUMBER",
                "check": ["String", "Number"]
            },
            {
                "type": "field_dropdown",
                "name": "DEGREE",
                "options": [
                    [ScratchBlocks.Msg.MOTION_DEGREES, 'degrees'],
                    [ScratchBlocks.Msg.MOTION_TURNS, 'turns']
                ]
            },
            {
                type: "field_dropdown",
                name: "WAIT",
                options: [
                    [ScratchBlocks.Msg.UNIT_WAIT, 'wait'],
                    [ScratchBlocks.Msg.UNIT_DONT_WAIT, 'dont_wait']
                ]
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_stop.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_STOP,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu(true));
                }
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_setpositiondegree.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SETPOSITIONDEGREE,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            },
            {
                "type": "input_value",
                "name": "NUMBER",
                "check": ["String", "Number"]
            },
            {
                "type": "field_dropdown",
                "name": "DEGREE",
                "options": [
                    [ScratchBlocks.Msg.MOTION_DEGREES, 'degrees'],
                    [ScratchBlocks.Msg.MOTION_TURNS, 'turns']
                ]
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_setvelocitypercent.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SETVELOCITYPERCENT,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'DEGREE',
                options: [
                    ['1', '1'],
                    ['2', '2'],
                    ['3', '3'],
                    ['4', '4'],
                    ['5', '5']
                ]
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_setvelocitypercent_entry.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SETVELOCITYPERCENT_ENTRY,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu(true));
                }
            },
            {
                type: 'input_value',
                name: 'NUMBER',
                check: ["String", "Number"]
            },
            {
                type: 'field_dropdown',
                name: 'DEGREE',
                options: [
                    [ScratchBlocks.Msg.MOTION_PERCENT, 'percent'],
                    [ScratchBlocks.Msg.MOTION_RPM, 'rpm']
                ]
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    const degreeUnitPercent = 'percent';
    const degreeUnitRpm = 'rpm';

    const getMotorMenu = function (selectedItem) {
        if (selectedItem == degreeUnitRpm) {
            return motorNamesMenu(false);
        } else {
            return motorNamesMenu(true);
        }
    };

    var motorNamesMenufn = function (blockId) {
        let start = [];
        if (vm.editingTarget) {
            let lookupBlocks = vm.editingTarget.blocks;
            let motionSpinBlock = lookupBlocks.getBlock(blockId);
            // The block doesn't exist, but should be in the flyout. Look there.
            if (!motionSpinBlock) {
                motionSpinBlock = vm.runtime.flyoutBlocks.getBlock(blockId);

                // If we still don't have a block, just return an empty list . This happens during
                // scratch blocks construction.
                if (!motionSpinBlock) {
                    return getMotorMenu('Motor');
                }
                // The block was in the flyout so look up future block info there.
                lookupBlocks = vm.runtime.flyoutBlocks;
            }
            // const menuBlock = lookupBlocks.getBlock(motionSpinBlock.inputs.MOTORNAME.shadow);
            const selectedItem = motionSpinBlock.fields.DEGREE.value;
            return getMotorMenu(selectedItem);
        }
        return start.concat(motorNamesMenu(true));
    }

    const getdegreeMenu = function (selectedItem) {
        if (is3WireMotor(selectedItem)) {
            return [[ScratchBlocks.Msg.MOTION_PERCENT, degreeUnitPercent]];
        } else {
            return [[ScratchBlocks.Msg.MOTION_PERCENT, degreeUnitPercent], [ScratchBlocks.Msg.MOTION_RPM, degreeUnitRpm]];
        }
    };

    const is3WireMotor = function (selectedItem) {
        if (vm.getThreeWireMotorList().map(motor => motor.name).indexOf(selectedItem) != -1) {
            return true; // TYPE_THREE_WIRE_MOTOR
        } else {
            return false;
        }
    }

    var degreeMenufn = function (blockId) {
        if (vm.editingTarget) {
            let lookupBlocks = vm.editingTarget.blocks;
            let motionSpinBlock = lookupBlocks.getBlock(blockId);
            // The block doesn't exist, but should be in the flyout. Look there.
            if (!motionSpinBlock) {
                motionSpinBlock = vm.runtime.flyoutBlocks.getBlock(blockId);

                // If we still don't have a block, just return an empty list . This happens during
                // scratch blocks construction.
                if (!motionSpinBlock) {
                    return getdegreeMenu('Motor');
                }
                // The block was in the flyout so look up future block info there.
                lookupBlocks = vm.runtime.flyoutBlocks;
            }
            // const menuBlock = lookupBlocks.getBlock(motionSpinBlock.inputs.MOTORNAME.shadow);
            const selectedItem = motionSpinBlock.fields.MOTORNAME.value;
            return getdegreeMenu(selectedItem);
        }
        return [
            [ScratchBlocks.Msg.MOTION_PERCENT, degreeUnitPercent],
            [ScratchBlocks.Msg.MOTION_RPM, degreeUnitRpm]];
    }

    ScratchBlocks.Blocks.motion_setvelocitypercent_eduandentry.init = function () {
        let blockId = this.id;
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SETVELOCITYPERCENT_ENTRY,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return motorNamesMenufn(blockId);
                }
            },
            {
                type: 'input_value',
                name: 'NUMBER',
                check: ["String", "Number"]
            },
            {
                type: 'field_dropdown',
                name: 'DEGREE',
                options: function () {
                    return degreeMenufn(blockId);
                }
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_setstopping.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SETSTOPPING,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'DEGREE',
                options: [
                    [ScratchBlocks.Msg.MOTION_BRAKE, 'brake'],
                    [ScratchBlocks.Msg.MOTION_COAST, 'coast'],
                    [ScratchBlocks.Msg.MOTION_HOLD, 'hold']
                ]
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_setmaxtorque.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SETMAXTORQUE,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            },
            {
                type: "input_value",
                name: "NUMBER",
                check: ["String", "Number"]
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_settimeout.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SETTIMEOUT,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            },
            {
                type: "input_value",
                name: "NUMBER",
                check: ["String", "Number"]
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_3wire_spin.init = function () {
        let blockId = this.id;
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SPIN,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motor3wireNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'FORWARD',
                options: function () {
                    return forwardMenufn(blockId, TYPE_THREE_WIRE_MOTOR)
                }
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_3wire_stop.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_STOP,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motor3wireNamesMenu());
                }
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_3wire_setvelocitypercent.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SETVELOCITYPERCENT,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motor3wireNamesMenu());
                }
            },
            {
                type: "input_value",
                name: "DEGREE",
                check: ["String", "Number"]
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_3wire_setvelocitypercent_entry.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.MOTION_SETVELOCITYPERCENT_ENTRY,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motor3wireNamesMenu());
                }
            },
            {
                type: "input_value",
                name: "NUMBER",
                check: ["String", "Number"]
            },
            {
                "type": "field_dropdown",
                "name": "DEGREE",
                "options": [
                    [ScratchBlocks.Msg.MOTION_PERCENT, 'percent'],
                    [ScratchBlocks.Msg.MOTION_RPM, 'rpm']
                ]
            }]
            , motionColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.looks_setlight.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.LOOKS_SETLIGHT,
            [{
                type: 'field_dropdown',
                name: 'LEDNAME',
                options: function () {
                    return start.concat(ledNamesMenu());
                }
            },
            {
                type: "field_dropdown",
                name: "UNIT",
                options: [
                    [ScratchBlocks.Msg.UNIT_ON, 'on'],
                    [ScratchBlocks.Msg.UNIT_OFF, 'off']
                ]
            }]
            , looksColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sound_play.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SOUND_PLAY_MUSIC,
            [{
                type: 'field_dropdown',
                name: 'BUZZERNAME',
                options: function () {
                    return start.concat(buzzerNamesMenu());
                }
            },
            {
                type: "field_dropdown",
                name: "SHEETMUSIC",
                options: [
                    [ScratchBlocks.Msg.SHEETMUSIC_LITTLESTAR, 'little_star'],
                    [ScratchBlocks.Msg.SHEETMUSIC_WIN, 'win'],
                    [ScratchBlocks.Msg.SHEETMUSIC_LOSE, 'lose']
                ]
            }]
            , soundColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.looks_setcolorto.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.LOOKS_SETCOLORTO,
            [{
                type: 'field_dropdown',
                name: 'TOUCHLEDNAME',
                options: function () {
                    return start.concat(touchledNamesMenu());
                }
            },
            {
                "type": "field_dropdown",
                "name": "UNIT",
                "options": [
                    [ScratchBlocks.Msg.COLOR_RED, 'red'],
                    [ScratchBlocks.Msg.COLOR_ORANGE, 'orange'],
                    [ScratchBlocks.Msg.COLOR_YELLOW, 'yellow'],
                    [ScratchBlocks.Msg.COLOR_GREEN, 'green'],
                    [ScratchBlocks.Msg.COLOR_SKY_BLUE, 'sky blue'],
                    [ScratchBlocks.Msg.COLOR_BLUE, 'blue'],
                    [ScratchBlocks.Msg.COLOR_PURPLE, 'purple'],
                    [ScratchBlocks.Msg.COLOR_VIOLET, 'violet'],
                    [ScratchBlocks.Msg.COLOR_WHITE, 'white']
                ]
            }]
            , looksColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.looks_setcolortorgb.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.LOOKS_SETCOLORTORGB,
            [{
                type: 'field_dropdown',
                name: 'TOUCHLEDNAME',
                options: function () {
                    return start.concat(touchledNamesMenu());
                }
            },
            {
                type: "input_value",
                name: "RED",
                check: ["String", "Number"]
            },
            {
                type: "input_value",
                name: "GREEN",
                check: ["String", "Number"]
            },
            {
                type: "input_value",
                name: "BLUE",
                check: ["String", "Number"]
            }]
            , looksColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.looks_setfadeto.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.LOOKS_SETFADETO,
            [{
                type: 'field_dropdown',
                name: 'TOUCHLEDNAME',
                options: function () {
                    return start.concat(touchledNamesMenu());
                }
            },
            {
                "type": "field_dropdown",
                "name": "SPEED",
                "options": [
                    [ScratchBlocks.Msg.SPEED_SLOW, 'slow'],
                    [ScratchBlocks.Msg.SPEED_FAST, 'fast'],
                    [ScratchBlocks.Msg.SPEED_OFF, 'off']
                ]
            }]
            , looksColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.looks_setbrightnessto.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.LOOKS_SETBRIGHTNESSTO,
            [{
                type: 'field_dropdown',
                name: 'TOUCHLEDNAME',
                options: function () {
                    return start.concat(touchledNamesMenu());
                }
            },
            {
                "type": "input_value",
                "name": "BRIGHTNESS",
                "check": ["String", "Number"]
            }]
            , looksColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.looks_colorsetlightto.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.LOOKS_COLORSETLIGHTTO,
            [{
                type: 'field_dropdown',
                name: 'COLORNAME',
                options: function () {
                    return start.concat(colorNamesMenu());
                }
            },
            {
                "type": "input_value",
                "name": "LIGHT",
                "check": ["String", "Number"]
            }]
            , looksColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sound_playnote.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SOUND_PLAYNOTE,
            [{
                type: 'field_dropdown',
                name: 'BUZZERNAME',
                options: function () {
                    return start.concat(buzzerNamesMenu());
                }
            },
            {
                "type": "input_value",
                "name": "NOTE",
                "check": ["String", "Number"]
            },
            {
                "type": "input_value",
                "name": "BEATS",
                "check": ["String", "Number"]
            }]
            , soundColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sound_restforbeats.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SOUND_RESTFORBEATS,
            [{
                type: 'field_dropdown',
                name: 'BUZZERNAME',
                options: function () {
                    return start.concat(buzzerNamesMenu());
                }
            },
            {
                "type": "input_value",
                "name": "BEATS",
                "check": ["String", "Number"]
            }]
            , soundColors, ['shape_statement']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_pointtowards_menu.init = function () {
        const mouse = ScratchBlocks.ScratchMsgs.translate('MOTION_POINTTOWARDS_POINTER', 'mouse-pointer');
        const json = jsonForMenuBlock('TOWARDS', spriteMenu, motionColors, [
            [mouse, '_mouse_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_goto_menu.init = function () {
        const random = ScratchBlocks.ScratchMsgs.translate('MOTION_GOTO_RANDOM', 'random position');
        const mouse = ScratchBlocks.ScratchMsgs.translate('MOTION_GOTO_POINTER', 'mouse-pointer');
        const json = jsonForMenuBlock('TO', spriteMenu, motionColors, [
            [random, '_random_'],
            [mouse, '_mouse_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_glideto_menu.init = function () {
        const random = ScratchBlocks.ScratchMsgs.translate('MOTION_GLIDETO_RANDOM', 'random position');
        const mouse = ScratchBlocks.ScratchMsgs.translate('MOTION_GLIDETO_POINTER', 'mouse-pointer');
        const json = jsonForMenuBlock('TO', spriteMenu, motionColors, [
            [random, '_random_'],
            [mouse, '_mouse_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_of_object_menu.init = function () {
        const stage = ScratchBlocks.ScratchMsgs.translate('SENSING_OF_STAGE', 'Stage');
        const json = jsonForMenuBlock('OBJECT', spriteMenu, sensingColors, [
            [stage, '_stage_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_of.init = function () {
        const blockId = this.id;
        const blockType = this.type;

        // Get the sensing_of block from vm.
        let defaultSensingOfBlock;
        const blocks = vm.runtime.flyoutBlocks._blocks;
        Object.keys(blocks).forEach(id => {
            const block = blocks[id];
            if (id === blockType || (block && block.opcode === blockType)) {
                defaultSensingOfBlock = block;
            }
        });
        // Function that fills in menu for the first input in the sensing block.
        // Called every time it opens since it depends on the values in the other block input.
        const menuFn = function () {
            const stageOptions = [
                [ScratchBlocks.Msg.SENSING_OF_BACKDROPNUMBER, 'backdrop #'],
                [ScratchBlocks.Msg.SENSING_OF_BACKDROPNAME, 'backdrop name'],
                [ScratchBlocks.Msg.SENSING_OF_VOLUME, 'volume']
            ];
            const spriteOptions = [
                [ScratchBlocks.Msg.SENSING_OF_XPOSITION, 'x position'],
                [ScratchBlocks.Msg.SENSING_OF_YPOSITION, 'y position'],
                [ScratchBlocks.Msg.SENSING_OF_DIRECTION, 'direction'],
                [ScratchBlocks.Msg.SENSING_OF_COSTUMENUMBER, 'costume #'],
                [ScratchBlocks.Msg.SENSING_OF_COSTUMENAME, 'costume name'],
                [ScratchBlocks.Msg.SENSING_OF_SIZE, 'size'],
                [ScratchBlocks.Msg.SENSING_OF_VOLUME, 'volume']
            ];
            if (vm.editingTarget) {
                let lookupBlocks = vm.editingTarget.blocks;
                let sensingOfBlock = lookupBlocks.getBlock(blockId);

                // The block doesn't exist, but should be in the flyout. Look there.
                if (!sensingOfBlock) {
                    sensingOfBlock = vm.runtime.flyoutBlocks.getBlock(blockId) || defaultSensingOfBlock;
                    // If we still don't have a block, just return an empty list . This happens during
                    // scratch blocks construction.
                    if (!sensingOfBlock) {
                        return [['', '']];
                    }
                    // The block was in the flyout so look up future block info there.
                    lookupBlocks = vm.runtime.flyoutBlocks;
                }
                const sort = function (options) {
                    options.sort(ScratchBlocks.scratchBlocksUtils.compareStrings);
                };
                // Get all the stage variables (no lists) so we can add them to menu when the stage is selected.
                const stageVariableOptions = vm.runtime.getTargetForStage().getAllVariableNamesInScopeByType('');
                sort(stageVariableOptions);
                const stageVariableMenuItems = stageVariableOptions.map(variable => [variable, variable]);
                if (sensingOfBlock.inputs.OBJECT.shadow !== sensingOfBlock.inputs.OBJECT.block) {
                    // There's a block dropped on top of the menu. It'd be nice to evaluate it and
                    // return the correct list, but that is tricky. Scratch2 just returns stage options
                    // so just do that here too.
                    return stageOptions.concat(stageVariableMenuItems);
                }
                const menuBlock = lookupBlocks.getBlock(sensingOfBlock.inputs.OBJECT.shadow);
                const selectedItem = menuBlock.fields.OBJECT.value;
                if (selectedItem === '_stage_') {
                    return stageOptions.concat(stageVariableMenuItems);
                }
                // Get all the local variables (no lists) and add them to the menu.
                const target = vm.runtime.getSpriteTargetByName(selectedItem);
                let spriteVariableOptions = [];
                // The target should exist, but there are ways for it not to (e.g. #4203).
                if (target) {
                    spriteVariableOptions = target.getAllVariableNamesInScopeByType('', true);
                    sort(spriteVariableOptions);
                }
                const spriteVariableMenuItems = spriteVariableOptions.map(variable => [variable, variable]);
                return spriteOptions.concat(spriteVariableMenuItems);
            }
            return [['', '']];
        };

        const json = jsonForSensingMenus(menuFn);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_distancetomenu.init = function () {
        const mouse = ScratchBlocks.ScratchMsgs.translate('SENSING_DISTANCETO_POINTER', 'mouse-pointer');
        const json = jsonForMenuBlock('DISTANCETOMENU', spriteMenu, sensingColors, [
            [mouse, '_mouse_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_touchingobjectmenu.init = function () {
        const mouse = ScratchBlocks.ScratchMsgs.translate('SENSING_TOUCHINGOBJECT_POINTER', 'mouse-pointer');
        const edge = ScratchBlocks.ScratchMsgs.translate('SENSING_TOUCHINGOBJECT_EDGE', 'edge');
        const json = jsonForMenuBlock('TOUCHINGOBJECTMENU', spriteMenu, sensingColors, [
            [mouse, '_mouse_'],
            [edge, '_edge_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_motorisdone.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_MOTORISDONE,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            }]
            , sensingColors, ['output_boolean']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_motorisspinning.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_MOTORISSPINNING,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            }]
            , sensingColors, ['output_boolean']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_motorposition.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_MOTORPOSITION,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'DEGREE',
                options: [
                    [ScratchBlocks.Msg.MOTION_DEGREES, 'degrees'],
                    [ScratchBlocks.Msg.MOTION_TURNS, 'turns']
                ]
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_motorvelocity.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_MOTORVELOCITY,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'DEGREE',
                options: [
                    [ScratchBlocks.Msg.SENSING_PERCENT, 'percent'],
                    [ScratchBlocks.Msg.SENSING_RPM, 'rpm']
                ]
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_motorcurrent.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.SENSING_MOTORCURRENT,
            [{
                type: 'field_dropdown',
                name: 'MOTORNAME',
                options: function () {
                    return start.concat(motorNamesMenu());
                }
            },
            {
                type: 'field_dropdown',
                name: 'DEGREE',
                options: [
                    [ScratchBlocks.Msg.SENSING_PERCENT, 'percent'],
                    [ScratchBlocks.Msg.SENSING_AMPS, 'amps']
                ]
            }]
            , sensingColors, ['output_number']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.control_create_clone_of_menu.init = function () {
        const json = jsonForMenuBlock('CLONE_OPTION', cloneMenu, controlColors, []);
        this.jsonInit(json);
    };

    const getLocaleList = function () {
        let typeList = [];
        switch (vm.getLocale()) {
            case 'zh-cn':
                typeList = [
                    [ScratchBlocks.Msg.UNIT_QUERY_IN_SIMPLIFIELD_CHINESE, 'simplified_chinese'],
                    [ScratchBlocks.Msg.UNIT_QUERY_IN_TRADITIONAL_CHINESE, 'traditional_chinese'],
                    [ScratchBlocks.Msg.UNIT_QUERY_IN_ENGLISH, 'english']
                ];
                break;
            case 'en':
                typeList = [
                    [ScratchBlocks.Msg.UNIT_QUERY_IN_ENGLISH, 'english'],
                    [ScratchBlocks.Msg.UNIT_QUERY_IN_TRADITIONAL_CHINESE, 'traditional_chinese'],
                    [ScratchBlocks.Msg.UNIT_QUERY_IN_SIMPLIFIELD_CHINESE, 'simplified_chinese']
                ];
                break;
            default:
                typeList = [
                    [ScratchBlocks.Msg.UNIT_QUERY_IN_TRADITIONAL_CHINESE, 'traditional_chinese'],
                    [ScratchBlocks.Msg.UNIT_QUERY_IN_SIMPLIFIELD_CHINESE, 'simplified_chinese'],
                    [ScratchBlocks.Msg.UNIT_QUERY_IN_ENGLISH, 'english']
                ];
                break;
        }
        return typeList;
    };

    ScratchBlocks.Blocks.extensions_wifi_setting_notification_highest_tempature.init = function () {
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.WIFI_SETTING_NOTIFICATION_HIGHEST_TEMPATURE,
            [{
                type: "input_value",
                name: "LOCATION",
                check: ["String", "Number"]
            },
            {
                type: "field_dropdown",
                name: "TEMP_UNIT",
                options: [
                    [ScratchBlocks.Msg.UNIT_DEGREES_CELSIUS, 'degree_c'],
                    [ScratchBlocks.Msg.UNIT_DEGREES_FAHRENHEIT, 'degree_f']
                ]
            },
            {
                type: 'field_dropdown',
                name: 'TYPE',
                options: getLocaleList()
            }]
            , extensionColors, ['output_string']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.extensions_wifi_setting_notification_lowest_tempature.init = function () {
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.WIFI_SETTING_NOTIFICATION_LOWEST_TEMPATURE,
            [{
                type: "input_value",
                name: "LOCATION",
                check: ["String", "Number"]
            },
            {
                type: "field_dropdown",
                name: "TEMP_UNIT",
                options: [
                    [ScratchBlocks.Msg.UNIT_DEGREES_CELSIUS, 'degree_c'],
                    [ScratchBlocks.Msg.UNIT_DEGREES_FAHRENHEIT, 'degree_f']
                ]
            },
            {
                type: 'field_dropdown',
                name: 'TYPE',
                options: getLocaleList()
            }]
            , extensionColors, ['output_string']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.extensions_wifi_setting_notification_humidity.init = function () {
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.WIFI_SETTING_NOTIFICATION_HUMIDITY,
            [{
                type: "input_value",
                name: "LOCATION",
                check: ["String", "Number"]
            },
            {
                type: 'field_dropdown',
                name: 'TYPE',
                options: getLocaleList()
            }]
            , extensionColors, ['output_string']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.extensions_wifi_setting_notification_air_quality.init = function () {
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.WIFI_SETTING_NOTIFICATION_AIR_QUALITY,
            [{
                type: "input_value",
                name: "LOCATION",
                check: ["String", "Number"]
            },
            {
                type: 'field_dropdown',
                name: 'TYPE',
                options: getLocaleList()
            }]
            , extensionColors, ['output_string']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.extensions_wifi_setting_notification_translate_to.init = function () {
        let typeList = [];
        if (vm.getLocale() == 'en') {
            typeList = [
                [ScratchBlocks.Msg.UNIT_TRADITIONAL_CHINESE, 'traditional_chinese'],
                [ScratchBlocks.Msg.UNIT_SIMPLIFIELD_CHINESE, 'simplified_chinese'],
                [ScratchBlocks.Msg.UNIT_ENGLISH, 'english']
            ]
        } else {
            typeList = [
                [ScratchBlocks.Msg.UNIT_ENGLISH, 'english'],
                [ScratchBlocks.Msg.UNIT_TRADITIONAL_CHINESE, 'traditional_chinese'],
                [ScratchBlocks.Msg.UNIT_SIMPLIFIELD_CHINESE, 'simplified_chinese']
            ]
        }
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.WIFI_SETTING_NOTIFICATION_TRANSLATE_TO,
            [{
                type: 'input_value',
                name: 'LOCATION',
                check: ["String", "Number"]
            },
            {
                type: 'field_dropdown',
                name: 'QUERY_TYPE',
                options: typeList
            }]
            , extensionColors, ['output_string']);
        this.jsonInit(json);
    };

    const getDefaultListMenu = function (selectedItem, inputSentence, ws, list_type, list_name) {
        let srcIcon = ScratchBlocks.mainWorkspace.options.pathToMedia + "icons/variable_number_white.svg";
        if (list_type == 'list') {
            srcIcon = ScratchBlocks.mainWorkspace.options.pathToMedia + (ScratchBlocks.mainWorkspace.options.UIS == ScratchBlocks.UI_STYLE_CN ? "icons/variable_number_white.svg" : "icons/variable_number_dark.svg");
        } else {
            srcIcon = ScratchBlocks.mainWorkspace.options.pathToMedia + (ScratchBlocks.mainWorkspace.options.UIS == ScratchBlocks.UI_STYLE_CN ? "icons/variable_word_white.svg" : "icons/variable_word_dark.svg");
        }
        let start = [
            {
                type: "field_image",
                src: srcIcon,
                width: 12,
                height: 12,
                alt: "*",
                flip_rtl: true
            },
            {
                type: 'field_variable',
                name: list_name,
                variableTypes: [list_type]
            }
        ]
        let sentence = inputSentence;
        let listVariables = [];
        if (ws) {
            listVariables = ws.getVariablesOfType(list_type);
        }
        listVariables.sort(function (a, b) {
            return ScratchBlocks.scratchBlocksUtils.compareStrings(a.name, b.name);
        });

        let listLength = 0;
        if (listVariables && selectedItem) {
            for (let i = 0; i < listVariables.length; i++) {
                if (listVariables[i].name == selectedItem) {
                    listLength = listVariables[i].listLength;
                    break;
                }
            }
        } else {
            listLength = listVariables[0].listLength;
        }

        return {
            sentence: sentence,
            listmenu: start,
            listLength: listLength
        };
    };

    var getDataListToInfo = function (blockId, block_type, list_type, list_name, sentence, ws) {
        if (vm.editingTarget) {
            let lookupBlocks = vm.editingTarget.blocks;
            let setListToBlock = lookupBlocks.getBlock(blockId);
            var selectedItem = ScratchBlocks.WorkspaceSvg.prototype.getListToOptions(block_type);
            // The block doesn't exist, but should be in the flyout. Look there.
            if (!setListToBlock) {
                setListToBlock = vm.runtime.flyoutBlocks.getBlock(blockId);

                // If we still don't have a block, just return an empty list . This happens during
                // scratch blocks construction.
                if (!setListToBlock) {
                    selectedItem = ScratchBlocks.WorkspaceSvg.prototype.getListToOptions(blockId) ? ScratchBlocks.WorkspaceSvg.prototype.getListToOptions(blockId) : selectedItem
                    return getDefaultListMenu(selectedItem, sentence, ws, list_type, list_name);
                }
                // The block was in the flyout so look up future block info there.
                lookupBlocks = vm.runtime.flyoutBlocks;
            }
            selectedItem = setListToBlock.fields[list_name].value;
            return getDefaultListMenu(selectedItem, sentence, ws, list_type, list_name);
        }
    }

    ScratchBlocks.Blocks.data_setlistto.init = function () {
        let blockId = this.id;
        let ws = this.workspace;
        let { sentence, listmenu, listLength } = getDataListToInfo(blockId, 'data_setlistto'
            , ScratchBlocks.LIST_VARIABLE_NUMBER_TYPE, 'LIST', ScratchBlocks.Msg.DATA_SETLISTTO, ws);
        const json = jsonForDynamicBlockMenu(sentence, listmenu, dataListsColors, ['shape_statement']);
        this.jsonInit(json);

        if (blockId != 'data_setlistto') {
            for (let i = 0; i < listLength; i++) {
                let input = this.appendValueInput(`INDEX${i}`).appendField('', 'NUM');
                this.attachShadow_(input, 'n', false)
                input.setCheck(["Number"]);
            }
        }
    };

    ScratchBlocks.Blocks.data_string_setlistto.init = function () {
        let blockId = this.id;
        let ws = this.workspace;
        let { sentence, listmenu, listLength } = getDataListToInfo(blockId, 'data_string_setlistto'
            , ScratchBlocks.LIST_VARIABLE_STRING_TYPE, 'STRING_LIST', ScratchBlocks.Msg.DATA_SETLISTTO, ws);
        const json = jsonForDynamicBlockMenu(sentence, listmenu, dataListsColors, ['shape_statement']);
        this.jsonInit(json);

        if (blockId != 'data_string_setlistto') {
            for (let i = 0; i < listLength; i++) {
                let input = this.appendValueInput(`INDEX${i}`).appendField('', 'TEXT');
                this.attachShadow_(input, 's', false)
                input.setCheck(["String"]);
            }
        }
    };

    ScratchBlocks.Blocks.ai_speech_first_concept_heard.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.AI_SPEECH_FIRST_CONCEPT_HEARD,
            [{
                type: 'field_dropdown',
                name: 'CONCEPT',
                options: function () {
                    return start.concat(conceptNameMenu());
                }
            }]
            , aiSpeechColors, ['output_string']);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.ai_speech_second_concept_heard.init = function () {
        let start = [];
        const json = jsonForDynamicBlockMenu(ScratchBlocks.Msg.AI_SPEECH_SECOND_CONCEPT_HEARD,
            [{
                type: 'field_dropdown',
                name: 'CONCEPT',
                options: function () {
                    return start.concat(conceptNameMenu());
                }
            }]
            , aiSpeechColors, ['output_string']);
        this.jsonInit(json);
    };

    ScratchBlocks.VerticalFlyout.getCheckboxState = function (blockId) {
        const monitoredBlock = vm.runtime.monitorBlocks._blocks[blockId];
        return monitoredBlock ? monitoredBlock.isMonitored : false;
    };

    ScratchBlocks.FlyoutExtensionCategoryHeader.getExtensionState = function (extensionId) {
        if (vm.getPeripheralIsConnected(extensionId)) {
            return ScratchBlocks.StatusButtonState.READY;
        }
        return ScratchBlocks.StatusButtonState.NOT_READY;
    };

    ScratchBlocks.FieldNote.playNote_ = function (noteNum, extensionId) {
        vm.runtime.emit('PLAY_NOTE', noteNum, extensionId);
    };

    // Use a collator's compare instead of localeCompare which internally
    // creates a collator. Using this is a lot faster in browsers that create a
    // collator for every localeCompare call.
    const collator = new Intl.Collator([], {
        sensitivity: 'base',
        numeric: true
    });
    ScratchBlocks.scratchBlocksUtils.compareStrings = function (str1, str2) {
        return collator.compare(str1, str2);
    };

    // Blocks wants to know if 3D CSS transforms are supported. The cross
    // section of browsers Scratch supports and browsers that support 3D CSS
    // transforms will make the return always true.
    //
    // Shortcutting to true lets us skip an expensive style recalculation when
    // first loading the Scratch editor.
    ScratchBlocks.utils.is3dSupported = function () {
        return true;
    };

    ScratchBlocks.Xml.getThreeWireMotorListFromVM = function () {
        return vm.getThreeWireMotorList();
    };

    ScratchBlocks.Xml.getMotorListFromVM = function () {
        return vm.getMotor100List().concat(vm.getMotor300List());
    };

    return ScratchBlocks;
}
