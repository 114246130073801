export default (filePath, fileName) => {
    fetch(filePath)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
            const downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);

            if ('download' in HTMLAnchorElement.prototype) {
                const url = window.URL.createObjectURL(blob);
                downloadLink.href = url;
                downloadLink.download = fileName;
                downloadLink.type = '.xlsx';
                downloadLink.click();
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(url);
            } else {
                // iOS Safari, open a new page and set href to data-uri
                let popup = window.open('', '_blank');
                const reader = new FileReader();
                reader.onloadend = function () {
                    popup.location.href = reader.result;
                    popup = null;
                };
                reader.readAsDataURL(blob);
            }
        });
};