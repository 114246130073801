import soundBlocks from '../block-vertical-blocks/sound';

export default function soundWEBVR(deviceInfo, blockSeparator) {

    return `
        ${deviceInfo.buzzerInfo.hasbuzzer ? `
            <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
                ${deviceInfo.buzzerInfo.hasbuzzer ? `
                    ${soundBlocks.WebVR.sound_playnote_vr}
                    ${soundBlocks.WebVR.sound_restforbeats_vr}
                    ${soundBlocks.WebVR.sound_settempoto}
                    ${soundBlocks.WebVR.sound_changetempoby}
                    ${soundBlocks.WebVR.sound_tempo}
                    ${soundBlocks.WebVR.sound_play_vr}
                ` : ``}
            </category>`: ``
        }
    `;
};