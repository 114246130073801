const SWITCH_TAG_INFORMATION = 'scratch-gui/device/vision/SWITCH_TAG_INFORMATION';
const SET_COLOR_DATA_LIST = 'scratch-gui/device/vision/SET_COLOR_DATA_LIST';
const SET_COLOR_DATA_DEFAULT_LIST = 'scratch-gui/device/vision/SET_COLOR_DATA_DEFAULT_LIST';
const SET_TAG_INFORMATION_STATE = 'scratch-gui/device/vision/SET_TAG_INFORMATION_STATE';

const SET_IMAGE_BASE64 = 'scratch-gui/device/vision/SET_IMAGE_BASE64';
const SET_IMAGE_TAG_LIST = 'scratch-gui/device/vision/SET_IMAGE_TAG_LIST';
const SET_IMAGE_PIXEL = 'scratch-gui/device/vision/SET_IMAGE_PIXEL';
const SET_SELECTED_COLOR = 'scratch-gui/device/vision/SET_SELECTED_COLOR';
const SET_VISION_INITIATED = 'scratch-gui/device/vision/SET_VISION_INITIATED';
const SET_VISION_CONNECTED = 'scratch-gui/device/vision/SET_VISION_CONNECTED';
const SET_VISION_TEMP_FEATURE = 'scratch-gui/device/vision/SET_VISION_TEMP_FEATURE';
const SET_PHOTO_SAVE = 'scratch-gui/device/vision/SET_PHOTO_SAVE';
const SET_VISION_COLOR_DEFAULT_LIST_INITIATED = 'scratch-gui/device/vision/SET_VISION_COLOR_DEFAULT_LIST_INITIATED';

const SET_VISION_INIT_STATE = 'scratch-gui/device/vision/SET_VISION_INIT_STATE';
const SET_VISION_ROCONG_INIT_STATE = 'scratch-gui/device/vision/SET_VISION_ROCONG_INIT_STATE';

const SET_FACE_IDNENTIFICATION_LIST = 'scratch-gui/device/vision/SET_FACE_IDNENTIFICATION_LIST';

const COLOR_DATA_LIST = "colorDataList";
const COLOR_DATA_DEFAULT_LIST = "colorDataDefaultList";
const IS_TAG_INFORMATION_ENABLE = "isTagInformationEnable";
const IS_VISION_INITIATED = "isVisionInitiated";
const IS_VISION_CONNECTED = "isVisionConnected";
const IS_VISION_RECONG_INITIATED = "isVisionRecongInitiated"
const IS_VISION_COLOR_DEFAULT_LIST_INITIATED = "isVisionColorDefaultListInitiated";

const FACE_IDNENTIFICATION_LIST = "faceIdentificationList";

const IMAGE_BASE64 = "ImageBase64";
const IMAGE_TAG_LIST = "imageTagList";
const IMAGE_WIDTH = "imageWidth";
const IMAGE_HEIGHT = "imageHeight";
const SELECTED_COLOR = "selectedColor";
const LAUNCH_WAY = "launchWay";
const VISION_ID = "visionId";
const VISION_NAME = "visionName";
const VISION_TEMP_FEATURE = "visionTempFeature";
const IS_PHOTO_SAVE = "isPhotoSave";
const UPLOAD_IMAGE_PATH = "uploadImagePath";
const VISION_INIT_STATE = "visionInitState";
const WIFI_VISION = "wifiVision"

const DEFAULT_COLOR_DATA_LIST = [
    { "color_name": null, 'bgr': [-1, -1, -1], 'lowerbound': [-1, -1, -1], 'upperbound': [-1, -1, -1], "bar": 5.0 },
    { "color_name": null, 'bgr': [-1, -1, -1], 'lowerbound': [-1, -1, -1], 'upperbound': [-1, -1, -1], "bar": 5.0 },
    { "color_name": null, 'bgr': [-1, -1, -1], 'lowerbound': [-1, -1, -1], 'upperbound': [-1, -1, -1], "bar": 5.0 },
    { "color_name": null, 'bgr': [-1, -1, -1], 'lowerbound': [-1, -1, -1], 'upperbound': [-1, -1, -1], "bar": 5.0 },
    { "color_name": null, 'bgr': [-1, -1, -1], 'lowerbound': [-1, -1, -1], 'upperbound': [-1, -1, -1], "bar": 5.0 },
    { "color_name": null, 'bgr': [-1, -1, -1], 'lowerbound': [-1, -1, -1], 'upperbound': [-1, -1, -1], "bar": 5.0 },
    { "color_name": null, 'bgr': [-1, -1, -1], 'lowerbound': [-1, -1, -1], 'upperbound': [-1, -1, -1], "bar": 5.0 },
    { "color_name": null, 'bgr': [-1, -1, -1], 'lowerbound': [-1, -1, -1], 'upperbound': [-1, -1, -1], "bar": 5.0 },
    { "color_name": null, 'bgr': [-1, -1, -1], 'lowerbound': [-1, -1, -1], 'upperbound': [-1, -1, -1], "bar": 5.0 },
    { "color_name": null, 'bgr': [-1, -1, -1], 'lowerbound': [-1, -1, -1], 'upperbound': [-1, -1, -1], "bar": 5.0 },
]

const DEFAULT_FACE_IDNENTIFICATION_LIST = [
    { name: "Person1", image: "", feature: [] },
    { name: "Person2", image: "", feature: [] },
    { name: "Person3", image: "", feature: [] },
    { name: "Person4", image: "", feature: [] },
    { name: "Person5", image: "", feature: [] },
    { name: "Person6", image: "", feature: [] },
]

const VISION_INIT_STATE_TYPE = {
    INITIALIZING: "initializing",
    OCCUPY: "occupy",
    INTERRUPT: "interupt",
    SUCCESS: "success",
    NONE: "none",
    FAILED: "failed"
}

const LAUNCH_FROM = {
    ADD: 'add',
    EDIT: 'edit'
}

const initialState = {
    [IS_TAG_INFORMATION_ENABLE]: true,
    [COLOR_DATA_LIST]: DEFAULT_COLOR_DATA_LIST,
    [COLOR_DATA_DEFAULT_LIST]: DEFAULT_COLOR_DATA_LIST,
    [FACE_IDNENTIFICATION_LIST]: DEFAULT_FACE_IDNENTIFICATION_LIST,
    [IMAGE_WIDTH]: 640,
    [IMAGE_HEIGHT]: 480,
    [IMAGE_BASE64]: "",
    [IMAGE_TAG_LIST]: [],
    [SELECTED_COLOR]: [],
    [IS_VISION_INITIATED]: false,
    [IS_VISION_CONNECTED]: false,
    [LAUNCH_WAY]: LAUNCH_FROM.ADD,
    [VISION_ID]: null,
    [VISION_NAME]: null,
    [VISION_TEMP_FEATURE]: [],
    [IS_PHOTO_SAVE]: false,
    [UPLOAD_IMAGE_PATH]: "",
    [VISION_INIT_STATE]: VISION_INIT_STATE_TYPE.NONE,
    [WIFI_VISION]: {},
    [IS_VISION_RECONG_INITIATED]: false,
    [IS_VISION_COLOR_DEFAULT_LIST_INITIATED]: false,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_COLOR_DATA_LIST:
            return Object.assign({}, state, {
                [COLOR_DATA_LIST]: action.dataList
            });
        case SET_COLOR_DATA_DEFAULT_LIST:
            return Object.assign({}, state, {
                [COLOR_DATA_DEFAULT_LIST]: action.dataList
            });
        case SET_FACE_IDNENTIFICATION_LIST:
            return Object.assign({}, state, {
                [FACE_IDNENTIFICATION_LIST]: action.faceList
            });
        case SWITCH_TAG_INFORMATION:
            return Object.assign({}, state, {
                [IS_TAG_INFORMATION_ENABLE]: !state[IS_TAG_INFORMATION_ENABLE]
            });
        case SET_TAG_INFORMATION_STATE:
            return Object.assign({}, state, {
                [IS_TAG_INFORMATION_ENABLE]: action.enable
            });
        case SET_VISION_TEMP_FEATURE:
            return Object.assign({}, state, {
                [VISION_TEMP_FEATURE]: action.feature
            });
        case SET_IMAGE_PIXEL:
            return Object.assign({}, state, {
                [IMAGE_WIDTH]: action.width,
                [IMAGE_HEIGHT]: action.height
            });
        case SET_IMAGE_BASE64:
            return Object.assign({}, state, {
                [IMAGE_BASE64]: action.stream
            });
        case SET_IMAGE_TAG_LIST:
            return Object.assign({}, state, {
                [IMAGE_TAG_LIST]: action.tagList
            });
        case SET_SELECTED_COLOR:
            console.log('SET_SELECTED_COLOR ', action.color)
            return Object.assign({}, state, {
                [SELECTED_COLOR]: action.color
            });
        case SET_VISION_CONNECTED:
        case SET_VISION_INITIATED:
            console.log(action.index + ' : ' + action.status)
            return Object.assign({}, state, {
                [action.index]: action.status
            });
        case SET_VISION_COLOR_DEFAULT_LIST_INITIATED:
            return Object.assign({}, state, {
                [IS_VISION_COLOR_DEFAULT_LIST_INITIATED]: action.state
            });
        case LAUNCH_WAY:
            return Object.assign({}, state, {
                [LAUNCH_WAY]: action.status,
                [VISION_ID]: action.id,
                [VISION_NAME]: action.name
            });
        case SET_PHOTO_SAVE:
            return Object.assign({}, state, {
                [IS_PHOTO_SAVE]: action.save
            });
        case SET_VISION_INIT_STATE:
            return Object.assign({}, state, {
                [VISION_INIT_STATE]: action.state
            });
        case SET_VISION_ROCONG_INIT_STATE:
            return Object.assign({}, state, {
                [IS_VISION_RECONG_INITIATED]: action.state
            });
        case UPLOAD_IMAGE_PATH:
            return Object.assign({}, state, {
                [UPLOAD_IMAGE_PATH]: action.path
            });
        case WIFI_VISION:
            return Object.assign({}, state, {
                [WIFI_VISION]: action.res
            });
        default:
            return state;
    }
};

const setImagePixel = (width, height) => {
    return {
        type: SET_IMAGE_PIXEL,
        width: width,
        height: height
    };
};

const getImageWidth = state => state.scratchGui.vision[IMAGE_WIDTH];
const getImageHeight = state => state.scratchGui.vision[IMAGE_HEIGHT];

const setImageBase64 = (stream) => {
    return {
        type: SET_IMAGE_BASE64,
        stream: stream
    };
};

const getImageBase64 = state => state.scratchGui.vision[IMAGE_BASE64];

const setImageTagList = (list) => {
    return {
        type: SET_IMAGE_TAG_LIST,
        tagList: list
    };
};

const getImageTagList = state => state.scratchGui.vision[IMAGE_TAG_LIST];
// Remove set list function
const setColorDataList = (list) => {
    return {
        type: SET_COLOR_DATA_LIST,
        dataList: list
    };
};

const getColorDataList = state => state.scratchGui.vision[COLOR_DATA_LIST];
// Remove set list function
const resetColorDataList = (list) => {
    return {
        type: SET_COLOR_DATA_LIST,
        dataList: DEFAULT_COLOR_DATA_LIST
    };
}

const setColorDataDefaultList = (list) => {
    return {
        type: SET_COLOR_DATA_DEFAULT_LIST,
        dataList: list
    };
};

const getColorDataDefaultList = state => state.scratchGui.vision[COLOR_DATA_DEFAULT_LIST];

const getTempFaceFeature = state => state.scratchGui.vision[VISION_TEMP_FEATURE];
const setTempFaceFeature = (feature) => {
    return {
        type: SET_VISION_TEMP_FEATURE,
        feature: feature
    }
}
const resetTempFaceFeature = () => {
    return {
        type: SET_VISION_TEMP_FEATURE,
        feature: []
    };
}


const setFaceIdentificationList = (list) => {
    return {
        type: SET_FACE_IDNENTIFICATION_LIST,
        faceList: list
    };
};

const getFaceIdentificationList = state => state.scratchGui.vision[FACE_IDNENTIFICATION_LIST];
const resetFaceIdentificationList = () => {
    console.log('resetFaceIdentificationList ')
    return {
        type: SET_FACE_IDNENTIFICATION_LIST,
        faceList: DEFAULT_FACE_IDNENTIFICATION_LIST
    };
}
const switchTagInformation = () => {
    return {
        type: SWITCH_TAG_INFORMATION,
    };
};

const setTagInformationState = (enable) => {
    return {
        type: SET_TAG_INFORMATION_STATE,
        enable: enable
    };
}
const isTagInformationEnable = state => state.scratchGui.vision[IS_TAG_INFORMATION_ENABLE];

const getSelectedColor = state => state.scratchGui.vision[SELECTED_COLOR];

const setLaunchSettingWay = (way, name, id) => {
    return {
        type: LAUNCH_WAY,
        status: way,
        id: id,
        name: name
    };
}
const getLaunchSettingWay = state => state.scratchGui.vision[LAUNCH_WAY];
const getVisionId = state => state.scratchGui.vision[VISION_ID];
const getVisionName = state => state.scratchGui.vision[VISION_NAME];
const clearLaunchWay = () => setLaunchSettingWay(LAUNCH_FROM.ADD);
const setSelectedColor = (color) => {
    return {
        type: SET_SELECTED_COLOR,
        color: color,
    };
};

const cleanSelectedColor = () => {
    return {
        type: SET_SELECTED_COLOR,
        color: [],
    };
};


const setVisionConnected = (status) => {
    return {
        type: SET_VISION_CONNECTED,
        index: IS_VISION_CONNECTED,
        status: status,
    };
};

const isVisionConnected = state => state.scratchGui.vision[IS_VISION_CONNECTED];

const setVisionInitiated = (status) => {
    return {
        type: SET_VISION_INITIATED,
        index: IS_VISION_INITIATED,
        status: status,
    };
};

const isVisionInitiated = state => state.scratchGui.vision[IS_VISION_INITIATED];

const setVisionInitState = (state) => {
    return {
        type: SET_VISION_INIT_STATE,
        state: state
    };
};


const isVisionRecongInitiated = state => state.scratchGui.vision[IS_VISION_RECONG_INITIATED];

const setVisionRecongInitiated = (state) => {
    return {
        type: SET_VISION_ROCONG_INIT_STATE,
        state: state
    };
};

const getVisionInitState = state => state.scratchGui.vision[VISION_INIT_STATE];
const resetVisionInitState = () => {
    return {
        type: SET_VISION_INIT_STATE,
        state: VISION_INIT_STATE_TYPE.NONE
    };
}

const setVisionColorDefaultListInitiated = (state) => {
    return {
        type: SET_VISION_COLOR_DEFAULT_LIST_INITIATED,
        state: state,
    };
};

const isVisionColorDefaultListInitiated = state => state.scratchGui.vision[IS_VISION_COLOR_DEFAULT_LIST_INITIATED];

const setProfilePhotoSave = (save) => {
    return {
        type: SET_PHOTO_SAVE,
        save: save
    };
}
const isProfilePhotoSave = state => state.scratchGui.vision[IS_PHOTO_SAVE];

const setUploadImagePath = (path) => {
    return {
        type: UPLOAD_IMAGE_PATH,
        path: path
    };
};

const getUploadImagePath = state => state.scratchGui.vision[UPLOAD_IMAGE_PATH];

const setWifiVision = (res) => {
    return {
        type: WIFI_VISION,
        res: res
    };
};

const getWifiVision = state => state.scratchGui.vision[WIFI_VISION];
export {
    reducer as default,
    initialState as visionInitialState,
    setColorDataList,
    getColorDataList,
    resetColorDataList,
    setColorDataDefaultList,
    getColorDataDefaultList,
    setTagInformationState,
    switchTagInformation,
    isTagInformationEnable,
    COLOR_DATA_LIST,
    IS_TAG_INFORMATION_ENABLE,

    setImageBase64,
    getImageBase64,
    setImagePixel,
    getImageWidth,
    getImageHeight,
    setImageTagList,
    getImageTagList,
    setSelectedColor,
    getSelectedColor,
    cleanSelectedColor,

    setVisionConnected,
    isVisionConnected,
    setVisionInitiated,
    isVisionInitiated,
    DEFAULT_COLOR_DATA_LIST,

    setLaunchSettingWay,
    getLaunchSettingWay,
    getVisionId,
    getVisionName,
    LAUNCH_FROM,
    clearLaunchWay,

    setFaceIdentificationList,
    getFaceIdentificationList,
    resetFaceIdentificationList,
    DEFAULT_FACE_IDNENTIFICATION_LIST,

    getTempFaceFeature,
    setTempFaceFeature,
    resetTempFaceFeature,

    setProfilePhotoSave,
    isProfilePhotoSave,

    setUploadImagePath,
    getUploadImagePath,

    setVisionInitState,
    getVisionInitState,
    resetVisionInitState,
    VISION_INIT_STATE_TYPE,

    setWifiVision,
    getWifiVision,
    isVisionRecongInitiated,
    setVisionRecongInitiated,

    setVisionColorDefaultListInitiated,
    isVisionColorDefaultListInitiated
};
