const SET_UNITYFUNCTION = 'vr/SET_UNITYFUNCTION';
const SET_VR_SAVE_FILE = 'vr/SET_VR_SAVE_FILE';
const SET_VR_UNITY_CONTEXT_LOADING_PROGRESS = 'vr/SET_VR_UNITY_CONTEXT_LOADING_PROGRESS';
const SET_VR_LANG_CHANGE = 'vr/SET_VR_LANG_CHANGE';
const SET_VR_IS_READY = 'vr/SET_VR_IS_READY';

const UNITYFUNCTION = 'unityFunction';
const VR_SAVE_FILE = 'vr_save_file';
const VR_UNITY_CONTEXT_LOADING_PROGRESS = 'vrUnityContextLoadingProgress';
const VR_LANG_CHANGE = 'vr_lang_change';
const VR_IS_READY = 'vr_is_ready'; // for language change

const initialState = {
    [UNITYFUNCTION]: undefined,
    [VR_SAVE_FILE]: false,
    [VR_UNITY_CONTEXT_LOADING_PROGRESS]: 0,
    [VR_LANG_CHANGE]: false,
    [VR_IS_READY]: true,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_UNITYFUNCTION:
            return Object.assign({}, state, {
                [UNITYFUNCTION]: action.func
            });
        case SET_VR_SAVE_FILE:
            return Object.assign({}, state, {
                [VR_SAVE_FILE]: action.status
            });
        case SET_VR_UNITY_CONTEXT_LOADING_PROGRESS:
            return Object.assign({}, state, {
                [VR_UNITY_CONTEXT_LOADING_PROGRESS]: action.status
            });
        case SET_VR_LANG_CHANGE:
            return Object.assign({}, state, {
                [VR_LANG_CHANGE]: action.status
            });
        case SET_VR_IS_READY:
            return Object.assign({}, state, {
                [VR_IS_READY]: action.status
            });
        default:
            return state;
    }
};

const setUnityMessageFunc = (func) => ({
    type: SET_UNITYFUNCTION,
    func: func
});

const setVRSaveFile = (status) => ({
    type: SET_VR_SAVE_FILE,
    status: status
});

const setVRUnityContextLoadingProgress = (status) => ({
    type: SET_VR_UNITY_CONTEXT_LOADING_PROGRESS,
    status: status
});

const setVRLangChange = (status) => ({
    type: SET_VR_LANG_CHANGE,
    status: status
});

const setVRIsReady = (status) => ({
    type: SET_VR_IS_READY,
    status: status
});

const getUnityMessageFunc = (state) => state.scratchGui.vr[UNITYFUNCTION];
const getVRSaveFile = (state) => state.scratchGui.vr[VR_SAVE_FILE];
const getVRUnityContextLoadingProgress = (state) => state.scratchGui.vr[VR_UNITY_CONTEXT_LOADING_PROGRESS];
const getVRLangChange = (state) => state.scratchGui.vr[VR_LANG_CHANGE];
const getVRIsReady = (state) => state.scratchGui.vr[VR_IS_READY];

export {
    reducer as default,
    initialState as vrInitialState,
    setUnityMessageFunc,
    getUnityMessageFunc,

    setVRSaveFile,
    getVRSaveFile,

    setVRUnityContextLoadingProgress,
    getVRUnityContextLoadingProgress,

    setVRLangChange,
    getVRLangChange,

    setVRIsReady,
    getVRIsReady,
};