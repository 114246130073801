import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import dialogStyles from '../dialog.css';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { EditUtils, SUPPORT_TYPES, STRING_ID, SYMBOLS_NOT_ALLOWED } from '../../device-manager/edit-page/edit-utils.js'
import {
    platformType,
    getPlatform,
    isPad,
} from '../../../lib/platform'

import log from '../../../lib/log';
import {
    cleanPortOption,
} from '../../../reducers/select-option';
import { setPortArray } from '../../../reducers/device-manager-controller';
import {
    hideVisionSettingDialog,
} from '../../../reducers/dialog';
import {
    getBrainRunningStatus,
    getBrainInfo
} from '../../../reducers/brain';
import {
    setColorDataList,
    getColorDataList,
    setTagInformationState,
    isTagInformationEnable,
    COLOR_DATA_LIST,
    IS_TAG_INFORMATION_ENABLE,
    QRCODE_RESULT,
    isVisionConnected,
    isVisionInitiated,
    isVisionRecongInitiated,
    DEFAULT_COLOR_DATA_LIST,
    clearLaunchWay,
    resetColorDataList,
    getLaunchSettingWay,
    getVisionId,
    getVisionName,
    LAUNCH_FROM,
    getFaceIdentificationList,
    setFaceIdentificationList,
    DEFAULT_FACE_IDNENTIFICATION_LIST,
    isProfilePhotoSave,
    setImageBase64,
    getImageBase64,
    setImageTagList,
    getVisionInitState,
    VISION_INIT_STATE_TYPE,
    getImageHeight,
    getImageWidth,
    getColorDataDefaultList,
    getImageTagList
} from '../../../reducers/vision';
import VisionColor from './vision-color.jsx'
import VisionFace from './vision-face.jsx'
import VisionQrcode from './vision-qrcode.jsx'
import VisionFaceIdentification from './vision-face-identification.jsx'
import VisionTextIdentification from './vision-text-identification.jsx'
import visionSettingStyles from './vision-setting-dialog.css'
import VM from 'scratch-vm';
import store from 'store';
import { getUIStyle, uiType } from '../../../reducers/ui-style';
import editImg from '../svg/cv_edit_white.svg'
import editWWImg from '../svg/ww/cv_edit.svg'
import exclamationIcon from '../svg/exclamation_mark.svg';
import crossIcon from '../svg/cross_mark.svg';
import { VISION_REALTIME_MODE, STATE_COMMAND_DELAY, VISION_TAB, VISION_TAB_MODE_MAPPING } from './vision-utils.js'
const TITLE_HEIGHT = 59

const DIALOG_WIDTH = 1171;
const SMALL_DIALOG_WIDTH = 930;
const DIALOG_HEIGHT = 704;

const SMALL_WINDOWS_WIDTH = 1260;

var stopTimeoutId = null;

class VisionDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = {
            // imgBase64: null,
            translateX: (window.innerWidth - DIALOG_WIDTH) / 2,
            translateY: (window.innerHeight - DIALOG_HEIGHT) / 2,
            selected: VISION_TAB.COLOR,
            isVisionConnected: this.props.isVisionConnected,
            name: null,
            tempName: null,
            isEditName: false,
            lockTab: false,
            enableTag: true
        };
        this.platform = getPlatform();
    }

    componentDidMount() {
        window.addEventListener('resize', e => {
            this.setState({
                translateX: (window.innerWidth - this.getDialogWidth()) / 2,
                translateY: (window.innerHeight - DIALOG_HEIGHT) / 2,
            })
        });
        this.setState({
            enableTag: store.get('enableTagInfo', true),
            // imgBase64: null,
            translateX: (window.innerWidth - this.getDialogWidth()) / 2,
            translateY: (window.innerHeight - DIALOG_HEIGHT) / 2,
        });
        this.setTabLock(false);
        this.parseFaceListFromReducer();
        this.parseColorListFromReducer();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', e => {
            this.setState({
                translateX: (window.innerWidth - this.getDialogWidth()) / 2,
                translateY: (window.innerHeight - DIALOG_HEIGHT) / 2,
            })
        });
        this.clearReducerImage();
    }

    clearReducerImage() {
        log.info('VisionDialog clearReducerImage')
        // this.setState({ imgBase64: null });
        this.props.clearImageBase64();
        this.props.clearImageTagList();
    }

    isWWVersion() {
        return this.props.getUIStyle == uiType.ww
    }

    // refreshImage() {
    //     this.setState({ imgBase64: this.props.isVisionConnected && this.props.isVisionInitiated ? this.props.getImageBase64 : null });
    // }

    getDialogWidth() {
        return this.isSmallDialogStyle() ? DIALOG_WIDTH : SMALL_DIALOG_WIDTH
    }

    isSmallDialogStyle() {
        return window.innerWidth > SMALL_WINDOWS_WIDTH
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isVisionConnected != this.props.isVisionConnected) {
            this.setState({ isVisionConnected: this.props.isVisionConnected });
        }
        if (this.props.isVisionInitiated != prevProps.isVisionInitiated || this.props.isVisionConnected != prevProps.isVisionConnected) {
            if (this.props.isVisionConnected == false || this.props.isVisionInitiated == false) {
                this.clearReducerImage();
            }
        }
        if (prevProps.isBrainRunning !== this.props.isBrainRunning) {
            if (this.props.show == true && this.props.isVisionConnected == true
                && this.props.isVisionInitiated == false && this.props.isBrainRunning == false) {
                this.props.initVision();
            }
        }
        if (prevProps.isVisionInitiated !== this.props.isVisionInitiated) {
            if (this.props.show == true && this.props.isVisionInitiated == true) {
                this.loadName();
                this.setVisionDataFromProject();
                const tab = store.get('visionTab', VISION_TAB.COLOR)
                this.startVisionRealtime(VISION_TAB_MODE_MAPPING[tab], 'VisionDialog.componentDidUpdate prevProps.isVisionInitiated !== this.props.isVisionInitiated');
            }
        }
        if (prevProps.show !== this.props.show) {
            this.setState({ enableTag: store.get('enableTagInfo', true) });
            if (this.props.show == true) {
                this.loadName();
                this.setTabLock(false);
                const tab = store.get('visionTab', VISION_TAB.COLOR)
                this.setSelected(tab);
                if (this.props.isVisionInitiated == false && this.props.isVisionConnected == true) {
                    this.props.initVision();
                }
                this.props.setTagInformationState(true);
            } else {
                store.remove('visionData');
            }
            if (this.props.isVisionInitiated == true && this.props.isVisionConnected == true) {
                if (this.props.show == true) {
                    if (stopTimeoutId) {
                        clearTimeout(stopTimeoutId);
                        stopTimeoutId = null;
                    }
                    const tab = store.get('visionTab', VISION_TAB.COLOR)
                    this.startVisionRealtime(VISION_TAB_MODE_MAPPING[tab], 'VisionDialog.componentDidUpdate prevProps.show !== this.props.show');
                    this.setVisionDataFromProject();
                } else {
                    stopTimeoutId = setTimeout(() => { this.stopVisionRealtime('this.props.show == false'); }, 1000);
                }
            } else {
                log.info('VisionDialog isVisionInitiated > ', this.props.isVisionInitiated);
                log.info('VisionDialog isVisionConnected > ', this.props.isVisionConnected);
            }
        }
    }

    setTabLock(lock) {
        if (lock == this.state.lockTab) return;
        this.setState({ lockTab: lock })
    }

    setVisionDataFromProject() {
        if (!store.get('visionData')) {
            store.set('visionData', {
                [COLOR_DATA_LIST]: this.props.getColorDataList || this.props.colorDataDefaultList,
                [IS_TAG_INFORMATION_ENABLE]: this.props.isTagInformationEnable,
            })
        }
    }

    stopVisionRealtime(from) {
        if (from) {
            log.info(`VisionSettingDialog.stopVisionRealtime from ${from}`);
        }
        this.props.stopVisionRealtime();
    }

    startVisionRealtime(mode, from) {
        if (from) {
            log.info(`VisionSettingDialog.startVisionRealtime from ${from}`);
        }
        this.stopVisionRealtime('init startVisionRealtime')
        if (isPad()) {
            setTimeout(() => {
                this.props.startVisionRealtime(mode);
            }, STATE_COMMAND_DELAY)
        } else {
            setTimeout(() => {
                this.props.startVisionRealtime(0);
            }, STATE_COMMAND_DELAY)
            setTimeout(() => {
                this.props.setVisionRealtimeMode(mode);
            }, STATE_COMMAND_DELAY * 2)
        }
    }

    getRealtimeImg() {
        return this.props.isVisionConnected && this.props.isVisionInitiated && this.props.getImageBase64 ? `data:image/jpeg;base64, ${this.props.getImageBase64}` : null
    }

    switchTagInfo() {
        store.set('enableTagInfo', !this.state.enableTag)
        this.setState({ enableTag: !this.state.enableTag });
    }

    getVisionInitStateString() {
        if (this.props.isVisionConnected == false) return <FormattedMessage id="gui.dialog.vision.disconnected" />;
        switch (this.props.getVisionInitState) {
            case VISION_INIT_STATE_TYPE.FAILED:
                return <FormattedMessage id="gui.dialog.vision.disconnected" />;
            case VISION_INIT_STATE_TYPE.INITIALIZING:
                return <FormattedMessage id="gui.dialog.vision.init" />;
            case VISION_INIT_STATE_TYPE.INTERRUPT:
            case VISION_INIT_STATE_TYPE.OCCUPY:
                return <FormattedMessage id="gui.dialog.vision.occupied" />;
            case VISION_INIT_STATE_TYPE.NONE:
            case VISION_INIT_STATE_TYPE.SUCCESS:
            default:
                return null;
        }
    }


    getContent() {
        let tab = this.state.selected ? this.state.selected : VISION_TAB.COLOR;
        let isVisionDistribute = this.props.isVisionConnected && this.props.isVisionInitiated
        switch (tab) {
            case VISION_TAB.COLOR:
            default:
                return (<div onClick={() => this.handleSaveName()}>
                    <VisionColor
                        initVision={this.props.initVision}
                        closeVision={this.props.closeVision}
                        startVisionRealtime={(from) => this.startVisionRealtime(VISION_REALTIME_MODE.COLOR, from)}
                        stopVisionRealtime={(from) => this.stopVisionRealtime(from)}
                        setVisionTagSetting={(list) => this.setVisionTagSetting(list)}
                        getVisionTagSetting={this.props.getVisionTagSetting}
                        selectVisionColor={this.props.selectVisionColor}
                        vm={this.props.vm}
                        realtimeImage={this.getRealtimeImg()}
                        isVisionDistribute={isVisionDistribute}
                        switchTagInfo={() => this.switchTagInfo()}
                        enableTag={this.state.enableTag}
                        getVisionInitStateString={this.getVisionInitStateString()}
                        imageWidth={this.props.getImageWidth}
                        imageHeight={this.props.getImageHeight}
                        isWWVersion={this.isWWVersion()}
                        show={this.props.show}
                    /></div>
                )
            case VISION_TAB.QRCODE:
                return (<div onClick={() => this.handleSaveName()}>
                    <VisionQrcode
                        initVision={this.props.initVision}
                        closeVision={this.props.closeVision}
                        startVisionRealtime={(from) => this.startVisionRealtime(VISION_REALTIME_MODE.NONE, from)}
                        stopVisionRealtime={(from) => this.stopVisionRealtime(from)}
                        realtimeImage={this.getRealtimeImg()}
                        isVisionDistribute={isVisionDistribute}
                        switchTagInfo={() => this.switchTagInfo()}
                        enableTag={this.state.enableTag}
                        getVisionInitStateString={this.getVisionInitStateString()}
                        getQrCodeResult={this.props.getQrCodeResult}
                        imageWidth={this.props.getImageWidth}
                        setTabLock={(lock) => this.setTabLock(lock)}
                        takeVisionSnapshot={this.props.takeVisionSnapshot}
                        imageHeight={this.props.getImageHeight} />
                </div>)
            case VISION_TAB.FACE:
                return (<div onClick={() => this.handleSaveName()}>
                    <VisionFace
                        initVision={this.props.initVision}
                        closeVision={this.props.closeVision}
                        startVisionRealtime={(from) => this.startVisionRealtime(VISION_REALTIME_MODE.FACE, from)}
                        stopVisionRealtime={(from) => this.stopVisionRealtime(from)}
                        realtimeImage={this.getRealtimeImg()}
                        isVisionDistribute={isVisionDistribute}
                        switchTagInfo={() => this.switchTagInfo()}
                        enableTag={this.state.enableTag}
                        getVisionInitStateString={this.getVisionInitStateString()}
                        imageWidth={this.props.getImageWidth}
                        imageHeight={this.props.getImageHeight} />
                </div>)
            case VISION_TAB.FACE_IDENTIFICATION:
                return (<div onClick={() => this.handleSaveName()}>
                    <VisionFaceIdentification
                        initVision={this.props.initVision}
                        closeVision={this.props.closeVision}
                        startVisionRealtime={(from) => this.startVisionRealtime(VISION_REALTIME_MODE.NONE, from)}
                        stopVisionRealtime={(from) => this.stopVisionRealtime(from)}
                        takeVisionSnapshot={this.props.takeVisionSnapshot}
                        enrollVisionProfileList={this.props.enrollVisionProfileList}
                        getVisionFaceRecongResult={this.props.getVisionFaceRecongResult}
                        getVisionFaceFeature={this.props.getVisionFaceFeature}
                        uploadVisionImage={this.props.uploadVisionImage}
                        setTabLock={(lock) => this.setTabLock(lock)}
                        vm={this.props.vm}
                        realtimeImage={this.getRealtimeImg()}
                        isVisionDistribute={isVisionDistribute}
                        switchTagInfo={() => this.switchTagInfo()}
                        enableTag={this.state.enableTag}
                        getVisionInitStateString={this.getVisionInitStateString()}
                        isWWVersion={this.isWWVersion()}
                        imageWidth={this.props.getImageWidth}
                        imageHeight={this.props.getImageHeight}
                        clearReducerImage={() => this.clearReducerImage()} />
                </div>)
            case VISION_TAB.TEXT_IDENTIFICATION:
                return (<div onClick={() => this.handleSaveName()}>
                    <VisionTextIdentification
                        initVision={this.props.initVision}
                        closeVision={this.props.closeVision}
                        startVisionRealtime={(from) => this.startVisionRealtime(VISION_REALTIME_MODE.NONE, from)}
                        stopVisionRealtime={(from) => this.stopVisionRealtime(from)}
                        setCvOCRLanguage={this.props.setCvOCRLanguage}
                        setTabLock={(lock) => this.setTabLock(lock)}
                        realtimeImage={this.getRealtimeImg()}
                        getVisionOcrResult={this.props.getVisionOcrResult}
                        takeVisionSnapshot={this.props.takeVisionSnapshot}
                        isVisionDistribute={isVisionDistribute}
                        switchTagInfo={() => this.switchTagInfo()}
                        enableTag={this.state.enableTag}
                        getVisionInitStateString={this.getVisionInitStateString()}
                        imageWidth={this.props.getImageWidth}
                        imageHeight={this.props.getImageHeight}
                        clearReducerImage={() => this.clearReducerImage()} />
                </div>)
        }
    }

    setSelected(tab) {
        if (!tab || this.state.lockTab == true) return;
        this.props.clearImageTagList();
        store.set('visionTab', tab)
        this.setState({ selected: tab })
    }

    onClickDelete() {
        this.props.vm.removeDeviceById(this.props.visionId);
        this.props.vm.initCvTagSettings(this.props.getColorDataList || this.props.colorDataDefaultList);
        this.props.vm.clearCvProfiles();
        this.props.vm.clearProfilePhoteSave();
        store.remove('visionData')
        store.set('identifyLanguage', "繁中");
        store.set('visionTab', VISION_TAB.COLOR);
        store.set('isPhotoSave', false);
        store.set('enableTagInfo', true);
        this.props.hideVisionSettingDialog();
        this.props.setFaceIdentificationList(DEFAULT_FACE_IDNENTIFICATION_LIST);
        this.props.resetColorDataList(this.props.getColorDataList || this.props.colorDataDefaultList);
        this.props.cleanPortOption();
    }

    onClickCancel() {
        this.props.hideVisionSettingDialog();
        this.props.cleanPortOption();
        store.remove('visionData')
        setTimeout(() => {
            this.parseColorListFromReducer();
            this.parseFaceListFromReducer();
            store.set('isPhotoSave', this.props.isProfilePhotoSave);
            this.props.vm.setProfilePhoteSave(this.props.isProfilePhotoSave);
        }, STATE_COMMAND_DELAY)
    }

    parseFaceListFromReducer() {
        let list = [];
        let enrollList = [];
        const reducerList = this.props.getFaceIdentificationList || DEFAULT_FACE_IDNENTIFICATION_LIST;
        if (Array.isArray(reducerList)) {
            reducerList.forEach(profile => {
                list.push({ name: profile.name, image: profile.image, feature: profile.feature })
                if (profile.feature && Array.isArray(profile.feature) && profile.feature.length > 0) {
                    enrollList.push({ name: profile.name, feature: profile.feature })
                }
            });
            this.props.vm.setCvProfiles(list);
            if (this.props.enrollVisionProfileList) this.props.enrollVisionProfileList(enrollList)
        }
    }

    parseColorListFromReducer() {
        let list = [];
        const reducerList = this.props.getColorDataList || this.props.colorDataDefaultList;
        if (Array.isArray(reducerList)) {
            reducerList.forEach(data => list.push({ color_name: data.color_name, bar: data.bar, bgr: data.bgr, lowerbound: data.lowerbound, upperbound: data.upperbound }));
            this.props.vm.restoreCvTagSettings(reducerList);
        }
        if (this.props.isVisionConnected && this.props.isVisionInitiated) {
            this.setVisionTagSetting(this.parseColorDataListToCv(this.props.getColorDataList));
        }
    }

    setVisionTagSetting(list) {
        this.props.setVisionTagSetting(this.parseColorDataListToCv(list));
    }

    parseColorDataListToCv(setting) {
        let list = [];
        if (Array.isArray(setting)) {
            setting.forEach(data => {
                if (data.color_name) {
                    list.push(data)
                }
            })
        }
        list = list.filter(color => color.color_name && Array.isArray(color.bgr) && color.bgr[0] >= 0 && color.bgr[1] >= 0 && color.bgr[2] >= 0)
        return list;
    }

    onClickConfirm() {
        this.handleSaveName();
        setTimeout(() => {
            store.remove('visionData')
            let faceList = this.props.vm.getCvProfiles();
            const isPhotoSave = store.get('isPhotoSave', false);
            this.props.vm.setProfilePhoteSave(isPhotoSave);
            if (isPhotoSave == false) {
                faceList.forEach(data => data.image = "");
            }
            this.props.setFaceIdentificationList(faceList);
            this.props.vm.setCvProfiles(faceList);
            if (this.props.isOpenFromAdd) {
                this.props.vm.addCommonDevice(SUPPORT_TYPES.vision, this.state.name, ['L'], false);
            } else if (this.props.isOpenFromEdit) {
                this.props.vm.editCommonDevice(this.props.visionId, this.state.name, ['L'], false);
            }
            this.props.setPortArray(['L']);
            this.props.hideVisionSettingDialog();
            this.props.setColorDataList(this.props.vm.getCvTagSettings())
            this.props.cleanPortOption();
        }, (this.state.isEditName) ? 50 : 0);
    }

    onClickEdit() {
        this.setState({ isEditName: true })
    }

    handleSaveName() {
        if (!this.state.isEditName) return;
        let name = this.state.tempName.replace(/^\s*/, "");
        let validName = this.isNameValid(name) && !EditUtils.isNameContainSp(name);
        this.setState({
            isEditName: false,
            name: (validName) ? name : this.state.name,
            tempName: (validName) ? name : this.state.name
        });
    }

    isNameValid(name) {
        if (name && name.length > 20) return false;
        return this.props.vm.checkDeviceNameAvailable(name, this.props.visionId) === "NAME_AVAILABLE";
    }

    onNameChange(event) {
        this.setState({
            tempName: event.target.value
        })
    }

    loadName() {
        if (this.props.visionName) {
            this.setState({
                name: this.props.visionName,
                tempName: this.props.visionName,
                isEditName: false
            })
        } else {
            this.setState({
                name: EditUtils.getDeviceDefaultName(SUPPORT_TYPES.vision),
                tempName: EditUtils.getDeviceDefaultName(SUPPORT_TYPES.vision),
                isEditName: false
            })
        }
    }

    hintDeviceType() {
        if (!this.state.tempName
            || this.state.tempName.toUpperCase().includes(EditUtils.getEnString(STRING_ID[SUPPORT_TYPES.vision]).toUpperCase())
            || this.state.tempName.toUpperCase().includes(EditUtils.getCnString(STRING_ID[SUPPORT_TYPES.vision]).toUpperCase())
            || this.state.tempName.toUpperCase().includes(EditUtils.getTwString(STRING_ID[SUPPORT_TYPES.vision]).toUpperCase())) return null;
        return (<div className={classNames(visionSettingStyles.invalidNameArea, visionSettingStyles.hintDeviceTypeText)} style={{ lineHeight: TITLE_HEIGHT + "px" }}>{EditUtils.getLocaleString(STRING_ID.deviceType) + "：" + EditUtils.getDeviceDefaultName(SUPPORT_TYPES.vision)}</div>)
    }

    getInvalidNameMsg() {
        return (EditUtils.isNameContainSp(this.state.tempName)) ? SYMBOLS_NOT_ALLOWED : this.props.vm.checkDeviceNameAvailable(this.state.tempName, this.props.visionId)
    }

    render() {
        if (!this.props.show) { return null; }
        const invalidEditName = (this.state.isEditName && (!this.isNameValid(this.state.tempName) || EditUtils.isNameContainSp(this.state.tempName)))
        const invalidName = !this.state.tempName || !this.isNameValid(this.state.tempName) || EditUtils.isNameContainSp(this.state.tempName)
        const hintNameOverflow = (this.state.isEditName && this.state.tempName.length == 20)
        return (
            <div className={classNames(dialogStyles.backdropStyle)}>
                <div className={classNames(dialogStyles.floatStyle)}
                    style={{ top: this.state.translateY + 'px', left: this.state.translateX + 'px' }}>
                    <div className={classNames(dialogStyles.floatModalStyle, visionSettingStyles.visionDialogSize)}>
                        <div
                            className={classNames(dialogStyles.helpHeader)}
                            style={{ height: TITLE_HEIGHT + "px", padding: 0 + "px" }}
                        >
                            {this.state.isEditName ? <div className={classNames(visionSettingStyles.nameCount, (hintNameOverflow) ? visionSettingStyles.nameCountHint : null)}>{`${this.state.tempName.length}/20`}</div> : null}

                            <div className={classNames(visionSettingStyles.visionTitle)}
                                style={{ height: TITLE_HEIGHT + "px" }}>
                                <VisionNameInput
                                    isEditName={this.state.isEditName}
                                    onClickEdit={() => { this.onClickEdit() }}
                                    tempName={this.state.tempName}
                                    isNameAvailable={this.isNameValid(this.state.tempName) && !EditUtils.isNameContainSp(this.state.tempName)}
                                    onNameChange={(e) => this.onNameChange(e)}
                                    isWWVersion={this.isWWVersion()}
                                    onBlur={() => this.handleSaveName()}
                                />
                            </div>
                            {(invalidEditName) ?
                                <div className={classNames(visionSettingStyles.invalidNameArea)}>
                                    <img src={crossIcon} className={classNames(visionSettingStyles.invalidNameImg)} />
                                    <div className={classNames(visionSettingStyles.invalidNameText)}>
                                        {EditUtils.getNameErrorString(this.getInvalidNameMsg())}</div>
                                </div> :
                                hintNameOverflow ?
                                    <div className={classNames(visionSettingStyles.invalidNameArea)}>
                                        <img src={exclamationIcon} className={classNames(visionSettingStyles.hintNameImg)} />
                                        <div className={classNames(visionSettingStyles.invalidNameText)}>
                                            {EditUtils.getLocaleString(STRING_ID.nameOverflow)}</div>
                                    </div>
                                    : this.hintDeviceType()}

                            <div className={classNames(dialogStyles.isConnectedText)}>
                                <FormattedMessage
                                    id={(this.state.isVisionConnected) ? "gui.brainInfo.mobile.connect" : "gui.brainInfo.mobile.noConnect"}
                                />
                            </div>
                            <div className={classNames(dialogStyles.isConnectedLight,
                                (this.state.isVisionConnected) ? dialogStyles.connected : dialogStyles.disconnected)} />
                        </div>
                        <div className={classNames(visionSettingStyles.tabArea)}
                            onClick={() => this.handleSaveName()}>
                            <VisionTab
                                position={visionSettingStyles.colorDetection}
                                tabIndex={VISION_TAB.COLOR}
                                isSelected={this.state.selected == VISION_TAB.COLOR}
                                setSelected={() => this.setSelected(VISION_TAB.COLOR)}
                                defalutMessage="Color Detection"
                                id="gui.dialog.vision.tab.color.detection"
                                isLocking={this.state.lockTab}
                            />
                            <VisionTab
                                position={visionSettingStyles.faceDetection}
                                tabIndex={VISION_TAB.FACE}
                                isSelected={this.state.selected == VISION_TAB.FACE}
                                setSelected={() => this.setSelected(VISION_TAB.FACE)}
                                defalutMessage="Face Detection"
                                id="gui.dialog.vision.tab.face.detection"
                                isLocking={this.state.lockTab}
                            />
                            <VisionTab
                                position={visionSettingStyles.faceIdentification}
                                tabIndex={VISION_TAB.FACE_IDENTIFICATION}
                                isSelected={this.state.selected == VISION_TAB.FACE_IDENTIFICATION}
                                setSelected={() => this.setSelected(VISION_TAB.FACE_IDENTIFICATION)}
                                defalutMessage="Face Identification"
                                id="gui.dialog.vision.tab.face.identification"
                                isLocking={this.state.lockTab}
                            />
                            <VisionTab
                                position={visionSettingStyles.qrcodeDetection}
                                tabIndex={VISION_TAB.QRCODE}
                                isSelected={this.state.selected == VISION_TAB.QRCODE}
                                setSelected={() => this.setSelected(VISION_TAB.QRCODE)}
                                defalutMessage="QR code Detection"
                                id="gui.dialog.vision.tab.qrcode.detection"
                                isLocking={this.state.lockTab}
                            />
                            {/* <VisionTab
                                position={visionSettingStyles.textIdentification}
                                tabIndex={VISION_TAB.TEXT_IDENTIFICATION}
                                isSelected={this.state.selected == VISION_TAB.TEXT_IDENTIFICATION}
                                setSelected={() => this.setSelected(VISION_TAB.TEXT_IDENTIFICATION)}
                                defalutMessage="Text Identification"
                                id="gui.dialog.vision.tab.text.identification"
                                isLocking={this.state.lockTab}
                            /> */}
                        </div>
                        {this.getContent()}
                        <div className={classNames(visionSettingStyles.buttonSet)}>
                            {this.props.isOpenFromEdit ?
                                <div className={classNames(visionSettingStyles.deleteButton)}
                                    onClick={() => this.onClickDelete()}>
                                    {EditUtils.getLocaleString(STRING_ID.delete)}
                                </div>
                                : <div className={classNames(visionSettingStyles.deleteWidth)}>

                                </div>}

                            <div className={classNames(visionSettingStyles.cancelButton)}
                                onClick={() => this.onClickCancel()}>
                                <FormattedMessage
                                    id={'gui.deviceManagerStage.page.cancel'}
                                />
                            </div>
                            <div className={classNames(visionSettingStyles.confirmButton, invalidName ? visionSettingStyles.disable : null)}
                                onClick={() => {
                                    invalidName ? null : this.onClickConfirm()
                                }
                                }>
                                <FormattedMessage
                                    id={'gui.deviceManagerStage.page.done'}
                                />
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        );
    }
}

const VisionTab = props => {
    return <div className={classNames(visionSettingStyles.tabButton,
        props.position,
        props.isSelected ? visionSettingStyles.selected : visionSettingStyles.unselected, props.isLocking ? visionSettingStyles.disableButton : null)}
        onClick={() => props.setSelected()}>
        <FormattedMessage
            defalutMessage={props.defalutMessage}
            id={props.id}
        />
    </div >
}

const VisionNameInput = props => {
    return (<div className={classNames(visionSettingStyles.nameArea)}>
        {(!props.isEditName)
            ?
            <div onClick={() => props.onClickEdit()} className={classNames(visionSettingStyles.pointer)}>
                <div className={classNames(visionSettingStyles.editImgPostion)}>
                    <img src={props.isWWVersion ? editWWImg : editImg} className={classNames(visionSettingStyles.editImg)} alt={props.tempName} />
                </div>
                <div className={classNames(visionSettingStyles.name)}>
                    {props.tempName}
                </div>
            </div>
            : <div className={classNames(visionSettingStyles.inputNameArea,
                (!props.isNameAvailable) ?
                    visionSettingStyles.inputNameErrorBorder :
                    (typeof props.tempName == 'string' && props.tempName.length == 20) ?
                        visionSettingStyles.inputNameHintBorder : null)}>
                <input type="text"
                    autoFocus={true}
                    name="device_name"
                    value={props.tempName}
                    maxLength="20"
                    onChange={e => props.onNameChange(e)}
                    onBlur={() => props.onBlur()}
                    className={classNames(visionSettingStyles.inputName)}
                />
            </div>
        }
    </div>)
}

VisionDialog.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool.isRequired,
    initVision: PropTypes.func,
    closeVision: PropTypes.func,
    startVisionRealtime: PropTypes.func,
    setVisionRealtimeMode: PropTypes.func,
    stopVisionRealtime: PropTypes.func,
    setVisionTagSetting: PropTypes.func,
    getVisionTagSetting: PropTypes.func,
    selectVisionColor: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    isVisionConnected: isVisionConnected(state) && getBrainInfo(state).brain_type != undefined,
    isVisionInitiated: isVisionInitiated(state) && isVisionRecongInitiated(state),
    getColorDataList: getColorDataList(state),
    isTagInformationEnable: isTagInformationEnable(state),
    isOpenFromAdd: getLaunchSettingWay(state) == LAUNCH_FROM.ADD,
    isOpenFromEdit: getLaunchSettingWay(state) == LAUNCH_FROM.EDIT,
    visionName: getVisionName(state),
    visionId: getVisionId(state),
    isBrainRunning: getBrainRunningStatus(state),
    getFaceIdentificationList: getFaceIdentificationList(state),
    isProfilePhotoSave: isProfilePhotoSave(state),
    getImageBase64: getImageBase64(state),
    getUIStyle: getUIStyle(state),
    getVisionInitState: getVisionInitState(state),
    getImageHeight: getImageHeight(state),
    getImageWidth: getImageWidth(state),
    colorDataDefaultList: getColorDataDefaultList(state),
    getImageTagList: getImageTagList(state)
});

const mapDispatchToProps = dispatch => ({
    hideVisionSettingDialog: () => dispatch(hideVisionSettingDialog()),
    setColorDataList: (list) => dispatch(setColorDataList(list)),
    setTagInformationState: (enable) => dispatch(setTagInformationState(enable)),
    clearLaunchWay: () => dispatch(clearLaunchWay()),
    resetColorDataList: (list) => dispatch(resetColorDataList(list)),
    cleanPortOption: () => dispatch(cleanPortOption()),
    setPortArray: (array) => dispatch(setPortArray(array)),
    setFaceIdentificationList: (array) => dispatch(setFaceIdentificationList(array)),
    clearImageBase64: () => dispatch(setImageBase64()),
    clearImageTagList: () => dispatch(setImageTagList([]))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisionDialog);