import motionBlocks from '../block-vertical-blocks/motion';

export default function motionEDU(targetId, deviceInfo, blockSeparator) {
    let motionBlocksWithTarget = motionBlocks(targetId);

    return `
        ${deviceInfo.threeWireMotorInfo.hasThreeWireMotor ?
            ` <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
                ${motionBlocksWithTarget.motion_3wire_spin}
                ${motionBlocksWithTarget.motion_3wire_stop}
                ${motionBlocksWithTarget.motion_3wire_setvelocitypercent}
            </category>` : ``
        }
    `;
};