import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import Button from '../button/button.jsx';

import styles from './tag-button.css';

const TagButtonComponent = ({
    active,
    iconClassName,
    circleStyle,
    activeStyle,
    contentStyle,
    className,
    tag, // eslint-disable-line no-unused-vars
    intlLabel,
    ...props
}) => (
    <div
        className={classNames(
            styles.tagButton,
            className
        )}
        {...props}
    >
        <div 
            className={classNames(
                styles.tagContent, {
                    [activeStyle? activeStyle:styles.active]: active
                },
                contentStyle
            )}
        >
            <div style={circleStyle} />
            <FormattedMessage {...intlLabel} />
        </div>
    </div>
);

TagButtonComponent.propTypes = {
    ...Button.propTypes,
    active: PropTypes.bool,
    intlLabel: PropTypes.shape({
        defaultMessage: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.string
    }).isRequired,
    tag: PropTypes.string.isRequired,
    circleStyle: PropTypes.object,
    activeStyle: PropTypes.string,
    contentStyle: PropTypes.string,
};

TagButtonComponent.defaultProps = {
    active: false
};

export default TagButtonComponent;
