import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    aiSpeechGroupType,
    getEditAISpeechGroupData
} from '../../reducers/dialog';
import {
    PROMPT_TYPE
} from '../../reducers/speaker';
import VM from 'scratch-vm';
import ScratchBlocks from 'scratch-blocks';
import Prompt from '../../containers/prompt.jsx';

class NewGroupDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'setPromptState'
        ]);
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
    }
    componentWillUnmount() {
    }

    setPromptState() {
        this.prompt = {};
        switch (this.props.type) {
            case aiSpeechGroupType.NEW_GROUP:
                this.prompt.message = ScratchBlocks.Msg.NEW_GROUP_NAME_TITLE;
                this.prompt.defaultValue = '';
                this.prompt.title = ScratchBlocks.Msg.GROUP_MODAL_TITLE;
                this.prompt.showListOptions = false;
                this.prompt.names = this.props.vm.getSpeakerGroups().map((group) => (group.name));
                break;
            case aiSpeechGroupType.EDIT_GROUP:
                let name = this.props.editAISpeechGroupData ? this.props.editAISpeechGroupData.getGroupName() : '';
                let id = this.props.editAISpeechGroupData ? this.props.editAISpeechGroupData.getGroupId() : '';
                let groups = this.props.vm.getSpeakerGroups();
                groups = groups.filter((group) => group.id != id);
                this.prompt.message = ScratchBlocks.Msg.NEW_GROUP_NAME_TITLE;
                this.prompt.defaultValue = name;
                this.prompt.title = ScratchBlocks.Msg.RENAME_GROUP_MODAL_TITLE;
                this.prompt.showListOptions = false;
                this.prompt.names = groups.map((group) => (group.name));
                break;
        }
    }

    render() {
        this.setPromptState();
        return (
            <Prompt
                type={PROMPT_TYPE.group}
                defaultValue={this.prompt.defaultValue}
                label={this.prompt.message}
                showListOptions={this.prompt.showListOptions}
                title={this.prompt.title}
                names={this.prompt.names}
                vm={this.props.vm}
                onCancel={this.props.onClose}
                onOk={this.props.onUpdate}
            />
        );
    }
}

NewGroupDialog.propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    class: PropTypes.string,
    type: PropTypes.string,
    show: PropTypes.bool,
    onUpdate: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
};

NewGroupDialog.defaultProps = {
    onClose: () => { },
    onUpdate: () => { },
    onSave: () => { },
}

const mapStateToProps = state => ({
    editAISpeechGroupData: getEditAISpeechGroupData(state),
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewGroupDialog);