import React from 'react';
import classNames from 'classnames';

import styles from './device-list.css';
import { EditUtils } from './../edit-page/edit-utils.js'
import { FormattedMessage } from 'react-intl';

export const DeviceItem = (props) => {
    return (
        <td align="center" className={classNames(styles.tableTd)}>
            <label>
                <div className={classNames(styles.radioBorder, (props.isDeviceEnable && props.isHover) ? styles.radioBorderHover : null)}>
                    <div
                        onClick={() => props.handleClickDevice()}
                        onMouseEnter={() => props.handleHoverDevice()}
                        onMouseLeave={() => props.handleHoverOff()}>
                        <img src={props.image} className={classNames((props.isDeviceEnable) ? styles.img : styles.disableImg)} alt={EditUtils.getLocaleString(props.messages[props.type].id)} />
                        <input type="radio"
                            className={classNames((props.isDeviceEnable) ? styles.radio : styles.disableRadio)}
                            name={props.deviceList}
                            value={props.type}
                            defaultChecked={props.isChecked} />
                        <div />
                    </div>
                </div>
                <div className={classNames((props.isDeviceEnable) ? styles.deviceType : styles.disableDeviceType,
                    (props.deviceList == "EduEntryDeviceList") ? styles.deviceTypeCombo : null)}>
                    {EditUtils.getDeviceNumber(props.type) + " "}<FormattedMessage {...props.messages[props.type]} />
                </div>
            </label>
        </td>
    )
}
