import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import { connect } from 'react-redux';
import { STAGE_DISPLAY_SIZES, STAGE_DISPLAY_TYPE } from '../lib/layout-constants.js';

import DeviceManagerStageComponent from '../components/device-manager/device-manager-stage/device-manager-stage.jsx';


class DeviceManagerStage extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }


    render() {
        const brainInfo = { name: "my brain", version: "0.1.1" };
        const {
            stageSize,
            brainType,
            vm,
            ...props
        } = this.props;
        return (
            <DeviceManagerStageComponent
                stageSize={stageSize}
                brainInfo={brainInfo}
                vm={vm}
                setVisionTagSetting={this.props.setVisionTagSetting}
                getVisionTagSetting={this.props.getVisionTagSetting}
                {...props}
            />
        );
    }

}

DeviceManagerStage.propTypes = {
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    stageType: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_TYPE)),
    deviceList: PropTypes.object,
    vm: PropTypes.instanceOf(VM).isRequired,
    setVisionTagSetting: PropTypes.func,
    getVisionTagSetting: PropTypes.func,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceManagerStage);
