import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import tabCategoryStyles from './tab-category-color.css';
import index from '../../../static/help/blockhelp/index.json'
import HelpDialog from './help-dialog.jsx'
import { editorMsgsByAsus } from '../../locales/editor-msgs';

import { getPickedBrainType } from '../../reducers/picked-brain-type'
import { uiType, getUIStyle } from '../../reducers/ui-style';
import { connect } from 'react-redux';
import store from 'store';
import tabIndexIcon from './svg/tab_index.svg';
import tabIndexWWIcon from './svg/tab_index_ww.svg';
import { getBlockHelpBlockType, getBlockHelpBlockItem, setBlockHelpBlockItem, setBlockHelpBlockType } from '../../reducers/block'
import { focusDialog, getForegroundDialog, DIALOG_BLOCK_HELP } from '../../reducers/dialog'
import { DEFAULT_LOCALE } from '../../config/project-config';

const DIALOG_DEFAULT_SIZE = {
    width: 798,
    height: 634,
    tabWidth: 306,
    minWidth: 360,
    minHeight: 487,
    minContentWidth: 241
}

const tablist = index

class BlockHelpDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.list = this.parseJson();
        this.setSelectTab();
    }

    componentDidUpdate(prevProps) {
        if (this.props.getPickedBrainType != prevProps.getPickedBrainType) {
            this.list = this.parseJson()
        }

        if (this.props.getBlockHelpBlockType != prevProps.getBlockHelpBlockType) {
            this.setSelectTab();
        }
    }

    setSelectTab() {
        let select_tab = this.getSelectTabIdByHelpBlockType(this.props.getBlockHelpBlockType);
        this.props.setBlockHelpBlockItemToState(select_tab);
    }

    getSelectTabIdByHelpBlockType(blockType) {
        let returnCategoryId = this.list ? this.list[0].id : "motion";
        Object.entries(this.list).forEach((category) => {
            let categoryId = category[1].id;
            let blocks = category[1].blocks;
            Object.keys(blocks).map((blockId) => {
                if (blockType == blockId) {
                    returnCategoryId = categoryId;
                }
            })
        })
        return returnCategoryId;
    }

    clickTab(categoryId) {
        this.props.setBlockHelpBlockItemToState(categoryId);
        // Set first block in category
        for (let i in this.list) {
            let category = this.list[i]
            if (category.id == categoryId) {
                let blockList = Object.keys(category.blocks);
                if (blockList.length > 0) {
                    this.props.setBlockHelpBlockTypeToState(blockList[0]);
                }
            }
        }
    }

    clickBlock(blockId) {
        this.props.setBlockHelpBlockTypeToState(blockId);
    }

    parseJson() {
        let type = this.props.getPickedBrainType || "EDU";
        let language = store.get("locale", DEFAULT_LOCALE);
        let supportBlocks = tablist.support[type];

        let list = [];
        Object.entries(tablist.category).forEach((category) => {
            let categoryBlock = {}
            let categoryId = category[0];
            let msgId = category[1].msg_id;
            let categorySupportBlocks = category[1].block;

            categorySupportBlocks.forEach((block) => {
                if (supportBlocks && supportBlocks.includes(block)) {
                    if (tablist.block[block] && tablist.block[block].block_svg && tablist.block[block].block_desc) {
                        let icon_name = tablist.block[block].block_svg[language];
                        if (this.props.getUIStyle != uiType.cn) {
                            icon_name = icon_name.replace(".png", "_" + this.props.getUIStyle + ".png")
                        }

                        categoryBlock[block] = {
                            height: this.getHeightByLevel(tablist.block[block]['height_level']),
                            icon: tablist.block[block]['url'] + icon_name,
                            description: tablist.block[block]['url'] + tablist.block[block].block_desc[language] + '?style=' + this.props.getUIStyle,
                        }
                    }
                }
            });

            //Hide block tab if not exists data
            if (Object.keys(categoryBlock).length > 0) {
                list.push({
                    id: categoryId,
                    name: editorMsgsByAsus[language][msgId],
                    blocks: categoryBlock,
                })

            }
        })
        return list;
    }
    getHeightByLevel(level) {
        switch (level) {
            case 0:
                return 35
            case 1:
                return 40
            case 2:
                return 51
            case 3:
                return 92
            case 4:
                return 144.2
            default:
                return 40
        }
    }

    isSelectTab(categoryId) {
        return this.props.getBlockHelpBlockItem == categoryId;
    }

    getCategoryTabStyle(categoryId) {
        switch (categoryId) {
            case 'motion':
                return tabCategoryStyles.tabCategoryMotion
            case 'drivetrain':
                return tabCategoryStyles.tabCategoryDrivetrain
            case 'looks':
                return tabCategoryStyles.tabCategoryLooks
            case 'sound':
                return tabCategoryStyles.tabCategorySound
            case 'event':
                return tabCategoryStyles.tabCategoryEvent
            case 'control':
                return tabCategoryStyles.tabCategoryControl
            case 'sensing':
                return tabCategoryStyles.tabCategorySensing
            case 'operators':
                return tabCategoryStyles.tabCategoryOperators
            case 'variables':
                return tabCategoryStyles.tabCategoryVariables
            case 'comment':
                return tabCategoryStyles.tabCategoryComment
            default:
                return tabCategoryStyles.tabCategoryMotion
        }
    }

    getTabList() {
        return <div className={classNames(styles.blockHelpTabList)}>
            {this.list.map((category, index) =>
                <div key={index} className={classNames(styles.tab)} >
                    <div className={classNames((this.isSelectTab(category.id) ? styles.blockHelpTabActive : null, styles.blockHelpTab))}
                        onClick={() => { this.isSelectTab(category.id) ? this.clickTab("") : this.clickTab(category.id) }}
                        key={index}>
                        <div className={(this.isSelectTab(category.id) ? styles.blockHelpTabTextActive : styles.blockHelpTabText)}>{((this.isSelectTab(category.id)) ? "－　" : "＋　") + category.name}</div>
                    </div>

                    <div className={classNames(styles.tabLine)} />

                    <div className={classNames(this.isSelectTab(category.id) ? styles.blockHelpTabBlockArea : null)}>
                        {Object.keys(category.blocks).map((blockId) =>

                            <div className={classNames((this.props.getBlockHelpBlockItem == category.id) ? styles.blockHelpShowBlockTab : styles.blockHelpHideBlockTab)}
                                onClick={() => this.clickBlock(blockId)}
                                key={blockId}>
                                <div className={classNames((blockId == this.props.getBlockHelpBlockType) ? styles.tabIndexSelected : styles.tabIndex)}>
                                    <img src={(this.props.getUIStyle == uiType.ww || this.props.getUIStyle == uiType.vr) ? tabIndexWWIcon : tabIndexIcon} alt={"tab"} />
                                </div>

                                <div className={classNames(styles.block)} style={{ height: category.blocks[blockId].height + 'px' }}>
                                    <img src={category.blocks[blockId].icon} alt={blockId} className={blockId} draggable="false" >
                                    </img>
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            )}
        </div>
    }

    getTabCotent() {
        let content;
        this.list.forEach(category => {
            Object.keys(category.blocks).map((blockId) => {
                if (blockId == this.props.getBlockHelpBlockType) {
                    content = category.blocks[blockId]
                }
            })
        })
        //TODO open hint html
        if (content) {
            return <div className={classNames(styles.blockHelpContent)}>
                <iframe src={content.description}
                    height="100%"
                    width="100%"
                    frameBorder="0"
                    scrolling="overlay" />
            </div>
        }
    }



    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div onMouseDown={() => this.props.focusDialog()}
                onTouchStart={() => this.props.focusDialog()}>
                <HelpDialog
                    dialogType={DIALOG_BLOCK_HELP}
                    onClose={this.props.onClose}
                    show={this.props.show}
                    height={DIALOG_DEFAULT_SIZE.height}
                    width={DIALOG_DEFAULT_SIZE.width}
                    defaultTabWidth={DIALOG_DEFAULT_SIZE.tabWidth}
                    minContentWidth={DIALOG_DEFAULT_SIZE.minContentWidth}
                    minHeight={DIALOG_DEFAULT_SIZE.minHeight}
                    minWidth={DIALOG_DEFAULT_SIZE.minWidth}
                    tabList={this.getTabList()}
                    content={this.getTabCotent()}
                    titleId="gui.dialog.block.help.title">
                </HelpDialog>
            </div >
        );
    }

}

BlockHelpDialog.propTypes = {
    setBlockHelpBlockItemToState: PropTypes.func,
    setBlockHelpBlockTypeToState: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool
};

const mapStateToProps = state => ({
    getPickedBrainType: getPickedBrainType(state),
    getBlockHelpBlockItem: getBlockHelpBlockItem(state),
    getBlockHelpBlockType: getBlockHelpBlockType(state),
    foregroundDialog: getForegroundDialog(state),
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
    focusDialog: () => dispatch(focusDialog(DIALOG_BLOCK_HELP)),
    setBlockHelpBlockItemToState: (blockItem) => dispatch(setBlockHelpBlockItem(blockItem)),
    setBlockHelpBlockTypeToState: (blockType) => dispatch(setBlockHelpBlockType(blockType))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockHelpDialog);