
import log from './log.js';

export const isServerNewer = (local, server) => {
    if (!local || !server) return false;
    let localArray = (typeof local == 'string') ? local.split("-")[0].split('.').map(Number) : new Array(0);
    let serverArray = (typeof server == 'string') ? server.split("-")[0].split('.').map(Number) : new Array(0);
    if (localArray.length > 0 && isNaN(localArray[0])) localArray.shift();
    if (serverArray.length > 0 && isNaN(serverArray[0])) serverArray.shift();
    while (localArray.length != serverArray.length) {
        if (localArray.length > serverArray.length) serverArray.push(0);
        if (serverArray.length > localArray.length) localArray.push(0);
    }
    log.info('isServerNewer localArray ', localArray);
    log.info('isServerNewer serverArray ', serverArray);
    let isServerNewer = false;
    for (let i = 0; i < serverArray.length; i++) {
        if (serverArray[i] > localArray[i]) {
            isServerNewer = true;
            break;
        }
        if (serverArray[i] < localArray[i]) {
            break;
        }
    }
    log.info('isServerNewer > ', isServerNewer);
    return isServerNewer;
}

export const filterBrainVer = (brainVer) => (brainVer && typeof brainVer == 'string') ? brainVer.split("-")[0].split("_").filter(e => e.includes("MB"))[0] : "0.0.0.1";
export const filterControllerVer = (controllerVer) => (controllerVer && typeof controllerVer == 'string') ? controllerVer.split("-")[0].split("_").filter(e => e.includes("RMC"))[0] : "0.0.0.1";