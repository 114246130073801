const OPEN_MENU = 'scratch-gui/menus/OPEN_MENU';
const CLOSE_MENU = 'scratch-gui/menus/CLOSE_MENU';
const CLOSE_ALL_MENU = 'scratch-gui/menus/CLOSE_ALL_MENU';

const MENU_ACCOUNT = 'accountMenu';
const MENU_FILE = 'fileMenu';
const MENU_EDIT = 'editMenu';
const MENU_LANGUAGE = 'languageMenu';
const MENU_LOGIN = 'loginMenu';
const MENU_HELP = 'helpMenu';
const MENU_SETTING = 'settingMenu';
const MENU_MORE = 'moreMenu';
const MENU_SLOT = 'slotMenu';

//brain info
const MENU_BRAIN_INFO = 'brainInfoMenu';
const LIST_BRAIN_INFO = 'brainInfoList';
const MENU_CONTROLLER_INFO = 'controllerInfoMenu';

const initialState = {
    [MENU_ACCOUNT]: false,
    [MENU_FILE]: false,
    [MENU_EDIT]: false,
    [MENU_LANGUAGE]: false,
    [MENU_LOGIN]: false,
    [MENU_HELP]: false,
    [MENU_SETTING]: false,
    [MENU_BRAIN_INFO]: false,
    [LIST_BRAIN_INFO]: false,
    [MENU_MORE]: false,
    [MENU_SLOT]: false,
    [MENU_CONTROLLER_INFO]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case OPEN_MENU:
            let menu = {};
            if (LIST_BRAIN_INFO == action.menu) {
                menu = { [LIST_BRAIN_INFO]: true }
            } else {
                menu = {
                    [MENU_ACCOUNT]: MENU_ACCOUNT == action.menu,
                    [MENU_FILE]: MENU_FILE == action.menu,
                    [MENU_EDIT]: MENU_EDIT == action.menu,
                    [MENU_LANGUAGE]: MENU_LANGUAGE == action.menu,
                    [MENU_LOGIN]: MENU_LOGIN == action.menu,
                    [MENU_HELP]: MENU_HELP == action.menu,
                    [MENU_SETTING]: MENU_SETTING == action.menu,
                    [MENU_BRAIN_INFO]: MENU_BRAIN_INFO == action.menu,
                    [MENU_MORE]: MENU_MORE == action.menu,
                    [MENU_SLOT]: MENU_SLOT == action.menu,
                    [MENU_CONTROLLER_INFO]: MENU_CONTROLLER_INFO == action.menu,
                }
            }
            return Object.assign({}, state, menu);
        case CLOSE_MENU:
            return Object.assign({}, state, {
                [action.menu]: false
            });
        case CLOSE_ALL_MENU:
            return Object.assign({}, state, initialState);
        default:
            return state;
    }
};
const openMenu = menu => ({
    type: OPEN_MENU,
    menu: menu
});
const closeMenu = menu => ({
    type: CLOSE_MENU,
    menu: menu
});
const closeAllMenu = () => ({
    type: CLOSE_ALL_MENU
});
const openAccountMenu = () => openMenu(MENU_ACCOUNT);
const closeAccountMenu = () => closeMenu(MENU_ACCOUNT);
const accountMenuOpen = state => state.scratchGui.menus[MENU_ACCOUNT];
const openFileMenu = () => openMenu(MENU_FILE);
const closeFileMenu = () => closeMenu(MENU_FILE);
const fileMenuOpen = state => state.scratchGui.menus[MENU_FILE];
const openEditMenu = () => openMenu(MENU_EDIT);
const closeEditMenu = () => closeMenu(MENU_EDIT);
const editMenuOpen = state => state.scratchGui.menus[MENU_EDIT];
const openLanguageMenu = () => openMenu(MENU_LANGUAGE);
const closeLanguageMenu = () => closeMenu(MENU_LANGUAGE);
const languageMenuOpen = state => state.scratchGui.menus[MENU_LANGUAGE];
const openLoginMenu = () => openMenu(MENU_LOGIN);
const closeLoginMenu = () => closeMenu(MENU_LOGIN);
const loginMenuOpen = state => state.scratchGui.menus[MENU_LOGIN];
const openHelpMenu = () => openMenu(MENU_HELP);
const closeHelpMenu = () => closeMenu(MENU_HELP);
const helpMenuOpen = state => state.scratchGui.menus[MENU_HELP];
const openSettingMenu = () => openMenu(MENU_SETTING);
const closeSettingMenu = () => closeMenu(MENU_SETTING);
const settingMenuOpen = state => state.scratchGui.menus[MENU_SETTING];
const openMoreMenu = () => openMenu(MENU_MORE);
const closeMoreMenu = () => closeMenu(MENU_MORE);
const moreMenuOpen = state => state.scratchGui.menus[MENU_MORE];
const openSlotMenu = () => openMenu(MENU_SLOT);
const closeSlotMenu = () => closeMenu(MENU_SLOT);
const isSlotMenuOpen = state => state.scratchGui.menus[MENU_SLOT];

const openBrainInfoMenu = () => openMenu(MENU_BRAIN_INFO);
const closeBrainInfoMenu = () => closeMenu(MENU_BRAIN_INFO);
const brainInfoMenuOpen = state => state.scratchGui.menus[MENU_BRAIN_INFO];

const openBrainInfoList = () => openMenu(LIST_BRAIN_INFO);
const closeBrainInfoList = () => closeMenu(LIST_BRAIN_INFO);
const brainInfoListOpen = state => state.scratchGui.menus[LIST_BRAIN_INFO];

const openControllerInfoMenu = () => openMenu(MENU_CONTROLLER_INFO);
const closeControllerInfoMenu = () => closeMenu(MENU_CONTROLLER_INFO);
const isControllerInfoMenuOpen = state => state.scratchGui.menus[MENU_CONTROLLER_INFO];

export {
    reducer as default,
    initialState as menuInitialState,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    openHelpMenu,
    closeHelpMenu,
    helpMenuOpen,
    openSettingMenu,
    closeSettingMenu,
    settingMenuOpen,
    openBrainInfoMenu,
    closeBrainInfoMenu,
    brainInfoMenuOpen,
    openMoreMenu,
    closeMoreMenu,
    moreMenuOpen,
    openSlotMenu,
    closeSlotMenu,
    isSlotMenuOpen,
    openBrainInfoList,
    closeBrainInfoList,
    brainInfoListOpen,
    openControllerInfoMenu,
    closeControllerInfoMenu,
    isControllerInfoMenuOpen,
    closeAllMenu
};
