const LocalKey = {
    platform: "platform",
    bluetoothStatus: "bluetoothStatus",
    nowSavePath: "nowSavePath",
    nowPreSavePath: "nowPreSavePath",
    nowOpenRecent: "nowOpenRecent",
    userGuideEntryPath: "userGuideEntryPath",
    locale: "locale",
    debugMode: "debugMode",
    serverType: "serverType",
    isPhotoSave: "isPhotoSave",
    releaseNotes: "releaseNotes",
    wifiPermissionStatus: "wifiPermissionStatus",
    localNetworkPermissionStatus: "localNetworkPermissionStatus",
};

export default LocalKey;