import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styles from './message-box.css';
import store from 'store';
import { USER_GUIDE_STATE } from '../../../lib/user-guide-state'
import { getUserGuideCurrentState, updateUserGuideState } from '../../../reducers/user-guide';
import {
    getIsShowingProject
} from '../../../reducers/project-state';
import {
    getPickedBrainType,
    updatePickedBrainType,
    isEnableWebVR
} from '../../../reducers/picked-brain-type'
import { BRAIN_TYPE } from '../../../lib/brains'
import { openExampleLibrary } from '../../../reducers/modals';
import {
    platformType,
    isPad,
    getPlatform
} from '../../../lib/platform';
import {
    BluetoothStatus,
    BluetoothAction
} from '../../../lib/bluetooth';
import LocalKey from '../../../lib/local-storage-key';
import {
    Catagory,
    postMessage
} from '../../../lib/postmessage';
import {
    getBluetoothIsOpen
} from '../../../reducers/brain';
import { uiType } from '../../../reducers/ui-style';
import { DEFAULT_LOCALE } from '../../../config/project-config';

import vrIntroSendorsCN from '../svg/introSensors-cn.png'
import vrIntroSendorsTW from '../svg/introSensors-tw.png'
import vrIntroSendorsEN from '../svg/introSensors-en.png'

class MessageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vrIntroSensorSrc: vrIntroSendorsCN,
        }
    }

    componentDidMount() {
        this.initIntroSensorImage();
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentState == USER_GUIDE_STATE.OPEN_BLUETOOTH_SETTING) {
            if (!prevProps.getBluetoothIsOpen && this.props.getBluetoothIsOpen) {
                this.props.onUpdateUserGuideState(USER_GUIDE_STATE.BLUETOOTH_CONNECTING);
            }
        }
    }

    initIntroSensorImage() {
        switch (store.get("locale", DEFAULT_LOCALE)) {
            case "en":
                this.setState({
                    vrIntroSensorSrc: vrIntroSendorsEN,
                });
                break;
            case "zh-cn":
                this.setState({
                    vrIntroSensorSrc: vrIntroSendorsCN,
                });
                break;
            case "zh-tw":
                this.setState({
                    vrIntroSensorSrc: vrIntroSendorsTW,
                });
                break;
        }
    }

    handleClickStart() {
        store.set("hasShowUserGuide", true);
        this.props.onUpdateUserGuideState(USER_GUIDE_STATE.BLOCK_GUIDE);
    }

    handleClickSkip() {
        store.set("hasShowUserGuide", true);
        this.props.onClose();
    }

    handleClickSkipVR() {
        this.props.onUpdateUserGuideState(USER_GUIDE_STATE.VR_INTRO_SENSORS);
    }

    handleClickCloseVR() {
        store.set("hasShowUserGuide", true);
        this.props.onClose();
    }

    handleVRClickNextFirst() {
        store.set("hasShowUserGuide", true);
        this.props.onUpdateUserGuideState(this.props.currentState + 1);
    }

    handleClickNext() {
        this.props.onUpdateUserGuideState(this.props.currentState + 1);
    }

    checkBluetoothState() {
        if (store.get(LocalKey.bluetoothStatus) == BluetoothStatus.poweredOn) {
            this.props.onUpdateUserGuideState(USER_GUIDE_STATE.BLUETOOTH_CONNECTING);
        } else {
            this.props.onUpdateUserGuideState(USER_GUIDE_STATE.OPEN_BLUETOOTH_SETTING);
        }
    }

    openBluetoothSetting() {
        postMessage(Catagory.Bluetooth, { "action": BluetoothAction.open });
    }

    checkWifiState() {
        if (this.props.onBrainConnected()) {
            this.props.onUpdateUserGuideState(USER_GUIDE_STATE.INTRO_SLOT);
        } else {
            this.props.onUpdateUserGuideState(USER_GUIDE_STATE.WIFI_CONNECTING);
        }
    }

    handleClickConnectLater() {
        this.props.onUpdateUserGuideState(USER_GUIDE_STATE.INTRO_SLOT_DISCONNECT);
    }

    handleClickConnect() {
        if (this.props.onBrainConnected()) {
            this.props.onUpdateUserGuideState(USER_GUIDE_STATE.INTRO_SLOT);
        } else {
            if (isPad()) {
                this.props.onUpdateUserGuideState(USER_GUIDE_STATE.INTRO_SLOT_DISCONNECT);
            } else {
                this.props.onUpdateUserGuideState(this.props.currentState + 1);
            }
        }
    }

    handleClickExample() {
        this.props.onOpenExampleLibrary();
        this.props.onPickedBrainType();
        this.props.onClose();
    }

    goToFinishState() {
        this.props.onUpdateUserGuideState(USER_GUIDE_STATE.FINISH);
    }

    initialBlockPosition() {
        this.draggedBlock = {
            top: 115,
            left: document.body.offsetHeight * 0.285 + 3
        }
    }

    renderTitle() {
        switch(this.props.currentState) {
            case USER_GUIDE_STATE.WELCOME:
                return (
                    <FormattedMessage id="gui.userGuide.title.welcome" />
                );
            case USER_GUIDE_STATE.BLOCK_GUIDE:
                return (
                    <FormattedMessage id="gui.userGuide.title.blockGuide" />
                );
            case USER_GUIDE_STATE.HOW_TO_USE_BLOCK:
                return (
                    <FormattedMessage id="gui.userGuide.title.howToUseBlock" />
                );
            case USER_GUIDE_STATE.ADD_BLOCK:
                return (
                    <FormattedMessage id="gui.userGuide.title.addBlock" />
                );
            case USER_GUIDE_STATE.ADD_BLOCK_FINISH:
                return (
                    <FormattedMessage id="gui.userGuide.title.addBlockFinish" />
                );
            case USER_GUIDE_STATE.DELETE_BLOCK:
                return (
                    <FormattedMessage id="gui.userGuide.title.deleteBlock" />
                );
            case USER_GUIDE_STATE.DEVICE_MANAGER_GUIDE:
                return (
                    <FormattedMessage id="gui.userGuide.title.deviceManagerGuide" />
                );
            case USER_GUIDE_STATE.NEW_DEVICE:
                return (
                    <FormattedMessage id="gui.userGuide.title.newDevice" />
                );
            case USER_GUIDE_STATE.NEW_DEVICE_SELECT_PORT:
                return (
                    <FormattedMessage id="gui.userGuide.title.newDeviceSelectPort" />
                );
            case USER_GUIDE_STATE.NEW_DEVICE_FINISH:
                return (
                    <FormattedMessage id="gui.userGuide.title.newDeviceFinish" />
                );
            case USER_GUIDE_STATE.LET_DEVICE_WORK:
                return (
                    <FormattedMessage id="gui.userGuide.title.letDeviceWork" />
                );
            case USER_GUIDE_STATE.LET_DEVICE_WORK_FINISH:
                return (
                    <FormattedMessage id="gui.userGuide.title.letDeviceWorkFinish" />
                );
            case USER_GUIDE_STATE.INTRO_BRAIN:
                return (
                    <FormattedMessage id="gui.userGuide.title.introBrain" />
                );
            case USER_GUIDE_STATE.OPEN_BLUETOOTH_SETTING:
                return (
                    <FormattedMessage id="gui.userGuide.title.openBluetoothSetting" />
                );
            case USER_GUIDE_STATE.BLUETOOTH_CONNECTING:
            case USER_GUIDE_STATE.WIFI_CONNECTING:
                return (
                    <FormattedMessage id="gui.userGuide.title.bluetoothConnecting" />
                );
            case USER_GUIDE_STATE.RECONNECT:
                return (
                    <FormattedMessage id="gui.userGuide.title.reconnect" />
                );
            case USER_GUIDE_STATE.DISCONNECT:
                return (
                    <FormattedMessage id="gui.userGuide.title.disconnect" />
                );
            case USER_GUIDE_STATE.INTRO_SLOT_DISCONNECT:
            case USER_GUIDE_STATE.INTRO_SLOT:
                return (
                    <FormattedMessage id="gui.userGuide.title.introSlot" />
                );
            case USER_GUIDE_STATE.INTRO_DOWNLOAD_AND_RUN:
                return (
                    <FormattedMessage id="gui.userGuide.title.introDownloadAndRun" />
                );
            case USER_GUIDE_STATE.DOWNLOAD_AND_RUN:
                return (
                    <FormattedMessage id="gui.userGuide.title.downloadAndRun" />
                );
            case USER_GUIDE_STATE.DOWNLOAD_FAIL:
                return (
                    <FormattedMessage id="gui.userGuide.title.downloadFail" />
                );
            case USER_GUIDE_STATE.FINISH:
                return (
                    <FormattedMessage id="gui.userGuide.title.Finish" />
                );
            case USER_GUIDE_STATE.VR_TOOLBAR_GUIDE:
                return (
                    <FormattedMessage id="gui.userGuide.title.toolbarGuide" />
                );
            case USER_GUIDE_STATE.VR_SELECT_SCENE_AND_EDIT:
                return (
                    <FormattedMessage id="gui.userGuide.title.selectSceneAndEdit" />
                );
            case USER_GUIDE_STATE.VR_PUT_OBJECT:
                return (
                    <FormattedMessage id="gui.userGuide.title.putObject" />
                );
            case USER_GUIDE_STATE.VR_PUT_TERRAIN:
                return (
                    <FormattedMessage id="gui.userGuide.title.putTerrain" />
                );
            case USER_GUIDE_STATE.VR_DRAW_TRACE:
                return (
                    <FormattedMessage id="gui.userGuide.title.drawTrace" />
                );
            case USER_GUIDE_STATE.VR_INTRO_SENSORS:
                return (
                    <FormattedMessage id="gui.userGuide.title.introSensors" />
                );
            default:
                return null;
            }
    }

    renderText(messageId) {
        const { intl } = this.props;
        var text = intl.formatMessage({ id: messageId });
        var subTexts = text.split('**');
        var texts = [];
        for (let i = 0; i < subTexts.length; ++i) {
            if (i % 2 == 0) {
                texts.push(subTexts[i]);
            } else {
                texts.push(<highlight-text key={i}>{subTexts[i]}</highlight-text>);
            }
        }
        return (
            <div className={styles.message}>{texts}</div>
        )
    }

    renderMessage() {
        switch(this.props.currentState) {
            case USER_GUIDE_STATE.WELCOME:
                return this.renderText("gui.userGuide.message.welcome");
            case USER_GUIDE_STATE.BLOCK_GUIDE:
                return this.renderText("gui.userGuide.message.blockGuide");
            case USER_GUIDE_STATE.HOW_TO_USE_BLOCK:
                return this.renderText("gui.userGuide.message.howToUseBlock");
            case USER_GUIDE_STATE.ADD_BLOCK:
                return this.renderText("gui.userGuide.message.addBlock");
            case USER_GUIDE_STATE.ADD_BLOCK_FINISH:
                return this.renderText("gui.userGuide.message.addBlockFinish");
            case USER_GUIDE_STATE.DELETE_BLOCK:
                return this.renderText("gui.userGuide.message.deleteBlock");
            case USER_GUIDE_STATE.DEVICE_MANAGER_GUIDE:
                return this.renderText("gui.userGuide.message.deviceManagerGuide");
            case USER_GUIDE_STATE.NEW_DEVICE:
                return this.renderText("gui.userGuide.message.newDevice");
            case USER_GUIDE_STATE.NEW_DEVICE_SELECT_PORT:
                return this.renderText("gui.userGuide.message.newDeviceSelectPort");
            case USER_GUIDE_STATE.NEW_DEVICE_FINISH:
                return this.renderText("gui.userGuide.message.newDeviceFinish");
            case USER_GUIDE_STATE.LET_DEVICE_WORK:
                return this.renderText("gui.userGuide.message.letDeviceWork");
            case USER_GUIDE_STATE.LET_DEVICE_WORK_FINISH:
                return isPad() ?
                this.renderText("gui.userGuide.message.letDeviceWorkFinishPad") :
                this.renderText("gui.userGuide.message.letDeviceWorkFinish");
            case USER_GUIDE_STATE.INTRO_BRAIN:
                if (this.props.getPickedBrainType == BRAIN_TYPE.EDU) {
                    return isPad() ? this.renderText("gui.userGuide.message.introBrainPad") : this.renderText("gui.userGuide.message.introBrain");
                } else {
                    return isPad() ? this.renderText("gui.userGuide.message.introBrainEntryPad") : this.renderText("gui.userGuide.message.introBrainEntry");
                }
            case USER_GUIDE_STATE.OPEN_BLUETOOTH_SETTING:
                return this.renderText("gui.userGuide.message.openBluetoothSetting");
            case USER_GUIDE_STATE.BLUETOOTH_CONNECTING:
                return this.renderText("gui.userGuide.message.bluetoothConnecting");
            case USER_GUIDE_STATE.WIFI_CONNECTING:
                return isPad() ?
                this.renderText("gui.userGuide.message.wifiConnectingPad") :
                this.renderText("gui.userGuide.message.wifiConnecting");
            case USER_GUIDE_STATE.RECONNECT:
                return this.renderText("gui.userGuide.message.reconnect");
            case USER_GUIDE_STATE.DISCONNECT:
                return this.renderText("gui.userGuide.message.disconnect");
            case USER_GUIDE_STATE.INTRO_SLOT_DISCONNECT:
                return this.renderText("gui.userGuide.message.introSlotDisconnect");
            case USER_GUIDE_STATE.INTRO_DOWNLOAD_AND_RUN:
                return this.renderText("gui.userGuide.message.introDownloadAndRun");
            case USER_GUIDE_STATE.INTRO_SLOT:
                return this.renderText("gui.userGuide.message.introSlot");
            case USER_GUIDE_STATE.DOWNLOAD_AND_RUN:
                return this.renderText("gui.userGuide.message.downloadAndRun");
            case USER_GUIDE_STATE.DOWNLOAD_FAIL:
                return this.renderText("gui.userGuide.message.downloadFail");
            case USER_GUIDE_STATE.FINISH:
                return this.renderText("gui.userGuide.message.Finish");
            case USER_GUIDE_STATE.VR_TOOLBAR_GUIDE:
                return this.renderText("gui.userGuide.message.toolbarGuide");
            case USER_GUIDE_STATE.VR_SELECT_SCENE_AND_EDIT:
                return this.renderText("gui.userGuide.message.selectSceneAndEdit");
            case USER_GUIDE_STATE.VR_PUT_OBJECT:
                return this.renderText("gui.userGuide.message.putObject");
            case USER_GUIDE_STATE.VR_PUT_TERRAIN:
                return this.renderText("gui.userGuide.message.putTerrain");
            case USER_GUIDE_STATE.VR_DRAW_TRACE:
                return this.renderText("gui.userGuide.message.drawTrace");
            case USER_GUIDE_STATE.VR_INTRO_SENSORS:
                return this.renderText("gui.userGuide.message.introSensors");
            default:
                return null;
            }
    }

    renderContentImage() {
        let contentStyle;
        switch(this.props.currentState) {
            case USER_GUIDE_STATE.WELCOME:
                contentStyle = classNames(styles.contentImg, isPad() ? styles.welcomePad : styles.welcome);
                break;
            case USER_GUIDE_STATE.LET_DEVICE_WORK_FINISH:
                contentStyle = classNames(styles.contentImg, isPad() ? styles.letDeviceWorkFinishPad : styles.letDeviceWorkFinish);
                break;
            case USER_GUIDE_STATE.FINISH:
                contentStyle = classNames(styles.contentImg, styles.finish);
                break;
            case USER_GUIDE_STATE.VR_INTRO_SENSORS:
                contentStyle = classNames(styles.contentImgVr);
                return (
                    <div>
                        <img className={contentStyle} src={this.state.vrIntroSensorSrc} alt={'simulator description'}/>
                    </div>
                )
            default:
                return null;
        }
        return (
            <div>
                <img className={contentStyle} alt={'content'} />
            </div>
        )
    }

    renderButton() {
        switch(this.props.currentState) {
            case USER_GUIDE_STATE.WELCOME:
                return(
                    <div className={styles.buttonContent} >
                        <div className={styles.button} onClick={() => this.handleClickSkip()}>
                            <FormattedMessage
                                defaultMessage="Skip"
                                description="skip user guide"
                                id="gui.userGuide.welcome.button.skip"
                            />
                        </div>
                        <div className={this.props.isShowingProject ? styles.button : styles.buttonDisable} onClick={() => this.handleClickStart()}>
                            <FormattedMessage
                                defaultMessage="Start"
                                description="start user guide"
                                id="gui.userGuide.welcome.button.start"
                            />
                        </div>
                    </div>
                )
            case USER_GUIDE_STATE.BLOCK_GUIDE:
            case USER_GUIDE_STATE.HOW_TO_USE_BLOCK:
            case USER_GUIDE_STATE.ADD_BLOCK_FINISH:
            case USER_GUIDE_STATE.DEVICE_MANAGER_GUIDE:
            case USER_GUIDE_STATE.NEW_DEVICE_FINISH:
            case USER_GUIDE_STATE.LET_DEVICE_WORK_FINISH:
            case USER_GUIDE_STATE.INTRO_SLOT_DISCONNECT:
            case USER_GUIDE_STATE.INTRO_DOWNLOAD_AND_RUN:
            case USER_GUIDE_STATE.INTRO_SLOT:
            case USER_GUIDE_STATE.DOWNLOAD_FAIL:
                return(
                    <div className={styles.buttonContent} >
                        <div className={styles.button}
                            onClick={this.props.currentState == USER_GUIDE_STATE.INTRO_DOWNLOAD_AND_RUN ?
                             () => this.goToFinishState() : () => this.handleClickNext()} >
                            <FormattedMessage
                                defaultMessage="Next"
                                description="user guide next"
                                id="gui.userGuide.button.next"
                            />
                        </div>
                    </div>
                )
            case USER_GUIDE_STATE.INTRO_BRAIN:
                return(
                    <div className={styles.buttonContent} >
                        <div className={styles.button} onClick={() => this.handleClickConnectLater()}>
                            <FormattedMessage
                                defaultMessage="Skip"
                                description="skip user guide"
                                id="gui.userGuide.button.connectLater"
                            />
                        </div>
                        { this.props.getPickedBrainType == BRAIN_TYPE.EDU ? isPad() ?
                            <div className={styles.button} onClick={() => this.checkBluetoothState()}>
                                <FormattedMessage
                                    defaultMessage="Next"
                                    description="user guide next"
                                    id="gui.userGuide.button.next"
                                />
                            </div> :
                            <div className={styles.button} onClick={() => this.handleClickConnect()}>
                            <FormattedMessage
                                defaultMessage="Start"
                                description="start user guide"
                                id="gui.userGuide.button.connected"
                            />
                            </div> :
                            <div className={styles.button} onClick={() => this.checkWifiState()}>
                                <FormattedMessage
                                    defaultMessage="Next"
                                    description="user guide next"
                                    id="gui.userGuide.button.next"
                                />
                           </div>
                        }
                    </div>
                )
            case USER_GUIDE_STATE.OPEN_BLUETOOTH_SETTING:
                return(
                    <div className={styles.buttonContent} >
                        <div className={styles.button} onClick={() => this.handleClickConnectLater()}>
                            <FormattedMessage
                                id="gui.userGuide.button.tryLater"
                            />
                        </div>
                        <div className={styles.button} onClick={() => this.openBluetoothSetting()}>
                            <FormattedMessage
                                id="gui.userGuide.button.bluetoothSetting"
                            />
                        </div>
                    </div>
                )
            case USER_GUIDE_STATE.BLUETOOTH_CONNECTING:
            case USER_GUIDE_STATE.WIFI_CONNECTING:
                return(
                    <div className={styles.buttonContent} >
                        <div className={styles.button} onClick={() => this.handleClickConnectLater()}>
                            <FormattedMessage
                                id="gui.userGuide.button.tryLater"
                            />
                        </div>
                        <div className={this.props.onBrainConnected() ? styles.button : styles.buttonDisable} onClick={() => this.handleClickConnect()}>
                            <FormattedMessage
                                id="gui.userGuide.button.confirm"
                            />
                        </div>
                    </div>
                )
            case USER_GUIDE_STATE.RECONNECT:
                return(
                    <div className={styles.buttonContent} >
                        <div className={styles.button} onClick={() => this.handleClickConnectLater()}>
                            <FormattedMessage
                                id="gui.userGuide.button.connectLater"
                            />
                        </div>
                        <div className={styles.button} onClick={() => this.handleClickConnect()}>
                            <FormattedMessage
                                id="gui.userGuide.button.confirm"
                            />
                        </div>
                    </div>
                )
            case USER_GUIDE_STATE.DISCONNECT:
                return(
                    <div className={styles.buttonContent} >
                        <div className={styles.button} onClick={() => this.handleClickNext()}>
                            <FormattedMessage
                                id="gui.userGuide.button.Iknow"
                            />
                        </div>
                    </div>
                )
            case USER_GUIDE_STATE.FINISH:
                return(
                    <div className={styles.buttonContent} >
                        <div className={styles.button} onClick={() => this.handleClickExample()}>
                            <FormattedMessage
                                id="gui.userGuide.button.example"
                            />
                        </div>
                        <div className={styles.button} onClick={() => this.props.onClose()}>
                            <FormattedMessage
                                id="gui.userGuide.button.discover"
                            />
                        </div>
                    </div>
                )
            case USER_GUIDE_STATE.VR_TOOLBAR_GUIDE:
                return (
                    <div className={styles.buttonContent} >
                        <div className={styles.button} onClick={() => this.handleVRClickNextFirst()}>
                            <FormattedMessage
                                defaultMessage="Next"
                                description="user guide next"
                                id="gui.userGuide.button.next"
                            />
                        </div>
                    </div>
                );
            case USER_GUIDE_STATE.VR_SELECT_SCENE_AND_EDIT:
            case USER_GUIDE_STATE.VR_PUT_OBJECT:
            case USER_GUIDE_STATE.VR_PUT_TERRAIN:
            case USER_GUIDE_STATE.VR_DRAW_TRACE:
                return(
                    <div className={styles.buttonContent} >
                        <div className={styles.button} onClick={() => this.handleClickNext()}>
                            <FormattedMessage
                                defaultMessage="Next"
                                description="user guide next"
                                id="gui.userGuide.button.next"
                            />
                        </div>
                    </div>
                )
            case USER_GUIDE_STATE.VR_INTRO_SENSORS:
                return(
                    <div className={styles.buttonContent} >
                        <div className={styles.button} onClick={() => this.handleClickCloseVR()}>
                            <FormattedMessage
                                id="gui.userGuide.button.finish"
                            />
                        </div>
                    </div>
                )
            default:
                return null;
        }
    }

    enableSkipButton() {
        switch(this.props.currentState) {
            case USER_GUIDE_STATE.BLOCK_GUIDE:
            case USER_GUIDE_STATE.HOW_TO_USE_BLOCK:
            case USER_GUIDE_STATE.ADD_BLOCK:
            case USER_GUIDE_STATE.ADD_BLOCK_FINISH:
            case USER_GUIDE_STATE.DELETE_BLOCK:
            case USER_GUIDE_STATE.DEVICE_MANAGER_GUIDE:
            case USER_GUIDE_STATE.NEW_DEVICE:
            case USER_GUIDE_STATE.NEW_DEVICE_SELECT_PORT:
            case USER_GUIDE_STATE.NEW_DEVICE_FINISH:
            case USER_GUIDE_STATE.LET_DEVICE_WORK:
            case USER_GUIDE_STATE.LET_DEVICE_WORK_FINISH:
            case USER_GUIDE_STATE.INTRO_BRAIN:
            case USER_GUIDE_STATE.OPEN_BLUETOOTH_SETTING:
            case USER_GUIDE_STATE.BLUETOOTH_CONNECTING:
            case USER_GUIDE_STATE.WIFI_CONNECTING:
            case USER_GUIDE_STATE.RECONNECT:
            case USER_GUIDE_STATE.DISCONNECT:
            case USER_GUIDE_STATE.INTRO_SLOT_DISCONNECT:
            case USER_GUIDE_STATE.INTRO_DOWNLOAD_AND_RUN:
            case USER_GUIDE_STATE.INTRO_SLOT:
            case USER_GUIDE_STATE.DOWNLOAD_AND_RUN:
            case USER_GUIDE_STATE.DOWNLOAD_FAIL:
            case USER_GUIDE_STATE.VR_TOOLBAR_GUIDE:
            case USER_GUIDE_STATE.VR_SELECT_SCENE_AND_EDIT:
            case USER_GUIDE_STATE.VR_PUT_OBJECT:
            case USER_GUIDE_STATE.VR_PUT_TERRAIN:
            case USER_GUIDE_STATE.VR_DRAW_TRACE:
                return true;
            default:
                return false;
        }
    }

    render() {
        var messageBoxStyle;
        var arrowStyleIn = null, arrowStyleOut = null;
        var messageBoxLeft;
        var messageBoxTop;
        var messageBoxBottom;
        var messageBoxRight;
        var contentFadeIn = false;
        if (isPad()) {
            if (this.props.getPickedBrainType == BRAIN_TYPE.EDU) {
                if (this.props.currentState == USER_GUIDE_STATE.OPEN_BLUETOOTH_SETTING) {
                    if (store.get(LocalKey.bluetoothStatus) == BluetoothStatus.poweredOn) {
                        this.props.onUpdateUserGuideState(USER_GUIDE_STATE.BLUETOOTH_CONNECTING);
                    }
                }
            }
        }
        switch(this.props.currentState) {
            case USER_GUIDE_STATE.WELCOME:
                messageBoxStyle = classNames(styles.welcome, styles.alignCenter);
                break;
            case USER_GUIDE_STATE.BLOCK_GUIDE:
                messageBoxStyle = styles.blockGuide;
                arrowStyleIn = classNames(styles.arrow_t_int, styles.left);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.left);
                break;
            case USER_GUIDE_STATE.HOW_TO_USE_BLOCK:
                // contentFadeIn = true;
                messageBoxStyle = classNames(styles.howToUseBlock, this.props.hasMenubarTag() ? styles.entry : null);
                arrowStyleIn = classNames(styles.arrow_t_int, styles.topLeft);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.topLeft);
                break;
            case USER_GUIDE_STATE.ADD_BLOCK:
                this.initialBlockPosition();
                messageBoxStyle = styles.addBlock;
                arrowStyleIn = classNames(styles.arrow_t_int, styles.top);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.top);
                messageBoxLeft = (this.props.startBlock.left + this.draggedBlock.left) / 2;
                messageBoxTop = this.props.startBlock.top + 115;
                break;
            case USER_GUIDE_STATE.ADD_BLOCK_FINISH:
                messageBoxStyle = styles.addBlockFinish;
                arrowStyleIn = classNames(styles.arrow_t_int, styles.top);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.top);
                messageBoxLeft = this.props.startBlock.left + 50;
                messageBoxTop = this.props.startBlock.top + 115;
                break;
            case USER_GUIDE_STATE.DELETE_BLOCK:
                messageBoxStyle = styles.deleteBlock;
                arrowStyleIn = classNames(styles.arrow_t_int, styles.top);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.top);
                messageBoxLeft = this.props.startBlock.left + 50;
                messageBoxTop = this.props.startBlock.top + 115;
                break;
            case USER_GUIDE_STATE.DEVICE_MANAGER_GUIDE:
                messageBoxStyle = styles.deviceManagerGuide;
                arrowStyleIn = classNames(styles.arrow_t_int, styles.right);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.right);
                break;
            case USER_GUIDE_STATE.NEW_DEVICE:
                let newDevicePos = styles.newDevice;
                if (this.props.getPickedBrainType == BRAIN_TYPE.ENTRY) {
                    newDevicePos = classNames(newDevicePos, styles.entry);
                } else if (this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
                    newDevicePos = classNames(newDevicePos, styles.b1b2);
                }
                messageBoxStyle = classNames(newDevicePos, isPad() ? styles.pad : null);
                arrowStyleIn = classNames(styles.arrow_t_int, styles.right);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.right);
                break;
            case USER_GUIDE_STATE.NEW_DEVICE_SELECT_PORT:
                messageBoxStyle = styles.newDeviceSelectPort;
                arrowStyleIn = classNames(styles.arrow_t_int, styles.right);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.right);
                var height = Math.max(480, document.body.offsetHeight - 480);
                messageBoxBottom = height - 190;
                break;
            case USER_GUIDE_STATE.NEW_DEVICE_FINISH:
                // contentFadeIn = true;
                messageBoxStyle = classNames(styles.newDeviceFinish, this.props.hasMenubarTag() ? styles.entry : null);
                arrowStyleIn = classNames(styles.arrow_t_int, styles.topLeft);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.topLeft);
                break;
            case USER_GUIDE_STATE.LET_DEVICE_WORK:
                messageBoxStyle = styles.letDeviceWork;
                arrowStyleIn = classNames(styles.arrow_t_int, styles.top);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.top);
                messageBoxLeft = (this.props.startBlock.left + this.draggedBlock.left) / 2;
                messageBoxTop = this.props.startBlock.top + 115;
                break;
            case USER_GUIDE_STATE.LET_DEVICE_WORK_FINISH:
                messageBoxStyle = classNames(styles.letDeviceWorkFinish, styles.alignCenter);
                break;
            case USER_GUIDE_STATE.INTRO_BRAIN:
            case USER_GUIDE_STATE.OPEN_BLUETOOTH_SETTING:
                if (window.innerWidth < 1024) {
                    messageBoxStyle = classNames(styles.introBrain, styles.min);
                } else {
                    messageBoxStyle = classNames(styles.introBrain, styles.max);
                }
                arrowStyleIn = classNames(styles.arrow_t_int, styles.top);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.top);
                break;
            case USER_GUIDE_STATE.BLUETOOTH_CONNECTING:
            case USER_GUIDE_STATE.WIFI_CONNECTING:
                if (getPlatform() == platformType.Desktop) {
                    messageBoxStyle = classNames(styles.bluetoothConnecting, styles.desktop);
                } else {
                    if (window.innerWidth < 1024) {
                        messageBoxStyle = classNames(styles.bluetoothConnecting, styles.min);
                    } else {
                        messageBoxStyle = classNames(styles.bluetoothConnecting, styles.max);
                    }
                }

                arrowStyleIn = classNames(styles.arrow_t_int, styles.right);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.right);
                break;
            case USER_GUIDE_STATE.RECONNECT:
            case USER_GUIDE_STATE.DISCONNECT:
                if (window.innerWidth < 1024) {
                    messageBoxStyle = classNames(styles.reconnect, styles.min);
                } else {
                    messageBoxStyle = classNames(styles.reconnect, styles.max);
                }
                arrowStyleIn = classNames(styles.arrow_t_int, styles.topRight);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.topRight);
                break;
            case USER_GUIDE_STATE.INTRO_SLOT_DISCONNECT:
            case USER_GUIDE_STATE.INTRO_SLOT:
                if (window.innerWidth < 1024) {
                    messageBoxStyle = classNames(styles.introSlot, styles.min);
                } else {
                    messageBoxStyle = classNames(styles.introSlot, styles.max);
                }
                arrowStyleIn = classNames(styles.arrow_t_int, styles.topRight);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.topRight);
                break;
            case USER_GUIDE_STATE.INTRO_DOWNLOAD_AND_RUN:
                if (window.innerWidth < 1024) {
                    messageBoxStyle = classNames(styles.introDownloadAndRun, styles.min);
                } else {
                    messageBoxStyle = classNames(styles.introDownloadAndRun, styles.max);
                }
                arrowStyleIn = classNames(styles.arrow_t_int, styles.topRight);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.topRight);
                break;
            case USER_GUIDE_STATE.DOWNLOAD_AND_RUN:
                if (window.innerWidth < 1024) {
                    messageBoxStyle = classNames(styles.downloadAndRun, styles.min);
                } else {
                    messageBoxStyle = classNames(styles.downloadAndRun, styles.max);
                }
                arrowStyleIn = classNames(styles.arrow_t_int, this.props.uis == uiType.ww ? styles.topRight : styles.top);
                arrowStyleOut = classNames(styles.arrow_t_out, this.props.uis == uiType.ww ? styles.topRight : styles.top);
                break;
            case USER_GUIDE_STATE.DOWNLOAD_FAIL:
                messageBoxStyle = classNames(styles.downloadFail, styles.alignCenter);
                break;
            case USER_GUIDE_STATE.FINISH:
                messageBoxStyle = classNames(styles.finish, styles.alignCenter);
                break;
            case USER_GUIDE_STATE.VR_TOOLBAR_GUIDE:
                messageBoxStyle = classNames(styles.vrToolbarGuide, styles.alignCenter);
                arrowStyleIn = classNames(styles.arrow_t_int, styles.topRight);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.topRight);
                break;
            case USER_GUIDE_STATE.VR_SELECT_SCENE_AND_EDIT:
                messageBoxStyle = classNames(styles.vrSelectSceneAndEdit, styles.alignCenter);
                arrowStyleIn = classNames(styles.arrow_t_int, styles.top);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.top);
                break;
            case USER_GUIDE_STATE.VR_PUT_OBJECT:
                messageBoxStyle = classNames(styles.vrPutObject, styles.alignCenter);
                arrowStyleIn = classNames(styles.arrow_t_int, styles.rightTop);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.rightTop);
                break;
            case USER_GUIDE_STATE.VR_PUT_TERRAIN:
                messageBoxStyle = classNames(styles.vrPutTerrain, styles.alignCenter);
                arrowStyleIn = classNames(styles.arrow_t_int, styles.rightTop);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.rightTop);
                break;
            case USER_GUIDE_STATE.VR_DRAW_TRACE:
                messageBoxStyle = classNames(styles.vrDrawTrace, styles.alignCenter);
                arrowStyleIn = classNames(styles.arrow_t_int, styles.rightTop);
                arrowStyleOut = classNames(styles.arrow_t_out, styles.rightTop);
                break;
            case USER_GUIDE_STATE.VR_INTRO_SENSORS:
                messageBoxStyle = classNames(styles.vrIntroSensors, styles.alignCenter);
                break;
            default:
                return null;
        }
        return (
            <div key={this.props.currentState} className={classNames(styles.messageBox, messageBoxStyle) }
                style={{
                    left: `${messageBoxLeft ? messageBoxLeft : null}px`,
                    top: `${messageBoxTop ? messageBoxTop : null}px`,
                    bottom: `${messageBoxBottom ? messageBoxBottom : null}px`,
                    right:`${messageBoxRight ? messageBoxRight : null}px`}}>
                <span className={arrowStyleIn}></span>
                <span className={arrowStyleOut}></span>
                <div className={contentFadeIn ? styles.contentFadeIn : styles.content}>
                    <div className={styles.title}>
                        {this.renderTitle()}
                    </div>
                    {this.renderMessage()}
                </div>
                {this.renderContentImage()}
                {this.renderButton()}
                <div className={classNames(styles.skipButton, this.enableSkipButton() ? null : styles.disable)} onClick={() => this.props.isEnableWebVR ? this.handleClickSkipVR() : this.props.onClose()}>
                    <FormattedMessage id="gui.userGuide.messageBox.skip" />
                </div>
            </div>
        )
    }
}

MessageBox.propTypes = {
    onClose: PropTypes.func.isRequired,
    onBrainConnected: PropTypes.func.isRequired,
    startBlock: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number
    }),
    isShowingProject: PropTypes.bool,
    hasMenubarTag: PropTypes.func.isRequired,
};

MessageBox.defaultProps = {
    onClose: () => onclose(),
    onBrainConnected: () => { },
    hasMenubarTag: () => { },
    uis: PropTypes.string
};

const mapStateToProps = state => ({
    onUpdateUserGuideState: state => dispatch(updateUserGuideState(state)),
    currentState: getUserGuideCurrentState(state),
    isShowingProject: getIsShowingProject(state.scratchGui.projectState.loadingState),
    getPickedBrainType: getPickedBrainType(state),
    getBluetoothIsOpen: getBluetoothIsOpen(state),
    isEnableWebVR: isEnableWebVR(state)
});

const mapDispatchToProps = dispatch => ({
    onUpdateUserGuideState: state => dispatch(updateUserGuideState(state)),
    onOpenExampleLibrary: () => dispatch(openExampleLibrary()),
    onPickedBrainType: () => dispatch(updatePickedBrainType()),
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageBox));