import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import { STAGE_DISPLAY_SIZES, STAGE_DISPLAY_TYPE } from '../lib/layout-constants.js';
import DeviceManagerStageComponent from './device-manager-stage.jsx';

const StageWrapper = props => {
    return <DeviceManagerStageComponent
        setVisionTagSetting={props.setVisionTagSetting}
        getVisionTagSetting={props.getVisionTagSetting}
        {...props}
    />;
}

StageWrapper.propTypes = {
    stageType: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_TYPE)),
    isRendererSupported: PropTypes.bool.isRequired,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
    setVisionTagSetting: PropTypes.func,
    getVisionTagSetting: PropTypes.func,
};

export default StageWrapper;
