import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import VM from 'scratch-vm';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import styles from './port.css';
import checkIcon from '../pictures/check.svg';
import checkWWIcon from '../pictures/ww/check.svg';
import { uiType, getUIStyle } from '../../../reducers/ui-style';

import {
    openEditPage,
} from '../../../reducers/device-manager-controller';

import {
    showVisionTutorialDialog,
    showVisionSettingDialog,
    hideStreamingDialog
} from '../../../reducers/dialog';
import {
    isTagInformationEnable,
    setLaunchSettingWay,
    LAUNCH_FROM,
} from '../../../reducers/vision';
import store from 'store';
import {
    isOptionSelected,
    getSelectedOption,
    hoverConnectedDevice,
    cleanConnectedDeviceHovered,
    getHoveredConnectedDevice,
    isConnectedDeviceHovered
} from '../../../reducers/select-option'

import {

    isDeviceSelected,
    selectDeviceToDelete,
    getSelectedDeviceIdArray,
    isDeleteDeviceMode,
    closeDeleteMode
} from '../../../reducers/delete-device';

const MODE = {
    OPTION: "option",
    DELETE: "delete"
}

class TypeCPort extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            hover: false
        }
    }

    handleClickPortToEdit(port, name, type, id) {
        if (type == 'vision') {
            this.props.setLaunchSettingWay(LAUNCH_FROM.EDIT, name, id)
            if (!store.get('skipVisionTutorial')) {
                this.props.showVisionTutorialDialog();
            } else {
                this.props.showVisionSettingDialog();
            }
        } else {
            this.props.openEditPage(port, name, type, id);
        }
        this.handleLeaveDevice()
    }

    handleClickPortToDelete(connectedDeviceList) {
        if (!connectedDeviceList) return;
        let portDevice = connectedDeviceList[this.props.index]
        let selectedArray = this.props.getSelectedDeviceIdArray;
        let temp = [];
        let result = [];
        let includePort = selectedArray.includes(portDevice.port);
        connectedDeviceList.forEach(device => {
            if (!temp.includes(device.port) && device.id === portDevice.id) {
                temp.push(device.port);
            }
        })
        if (!includePort) {
            result = temp.concat(selectedArray);
        } else {
            selectedArray.forEach(port => {
                if (!temp.includes(port)) {
                    result.push(port)
                }
            })
        }
        result.sort();
        this.props.seletDevice(result);
    }

    isPortSelected(mode) {
        let isPortSelected;
        let selectedArray = [];
        let port = this.props.connectedDeviceList[this.props.index].port
        if (mode == MODE.OPTION) {
            isPortSelected = this.props.isOptionSelected;
            selectedArray = this.props.getSelectedOption;
        }
        if (mode == MODE.DELETE) {
            isPortSelected = this.props.isDeviceSelected;
            selectedArray = this.props.getSelectedDeviceIdArray;
        }
        if (isPortSelected) {
            if (selectedArray && selectedArray.length > 0) {
                isPortSelected = selectedArray.includes(port);
            }
        }
        return isPortSelected;
    }

    handleHoverDevice(id) {
        let deviceInfo = this.props.vm.getDeviceById(id)
        if (deviceInfo && deviceInfo.connectPortArray) {
            this.props.hoverConnectedDevice(deviceInfo.connectPortArray);
        }
        this.setState({ hover: true });
    }

    handleLeaveDevice() {
        this.props.cleanConnectedDeviceHovered()
        this.setState({ hover: false });
    }

    isConnectedDeviceHovered() {
        let port = this.props.connectedDeviceList[this.props.index].port;
        let isConnectedDeviceHovered = this.props.isConnectedDeviceHovered;
        if (isConnectedDeviceHovered) {
            let hoveredArray = this.props.getHoveredConnectedDevice;
            if (hoveredArray && hoveredArray.length > 0) {
                isConnectedDeviceHovered = hoveredArray.includes(port);
            }
        }
        return isConnectedDeviceHovered;
    }

    render() {
        const {
            connectedDeviceList,
            isFirst,
            index,
            vm
        } = this.props;

        return (
            <div className={classNames((this.props.hide) ? styles.hide : styles.fadeIn)}>
                {connectedDeviceList[index].type ?
                    this.props.hide ?
                        null : <ConnectedTypeCPort
                            port={connectedDeviceList[index].port}
                            name={connectedDeviceList[index].name}
                            type={connectedDeviceList[index].type}
                            id={connectedDeviceList[index].id}
                            connectedDeviceList={connectedDeviceList}
                            isFirst={isFirst}
                            isDeleteDeviceMode={this.props.isDeleteDeviceMode}
                            handleClickPortToDelete={(connectedDeviceList) => this.handleClickPortToDelete(connectedDeviceList)}
                            isPortSelected={(mode) => this.isPortSelected(mode)}
                            handleClickPortToEdit={(port, name, type, id) => this.handleClickPortToEdit(port, name, type, id)}
                            handleHoverDevice={(id) => this.handleHoverDevice(id)}
                            handleLeaveDevice={() => this.handleLeaveDevice()}
                            isConnectedDeviceHovered={this.isConnectedDeviceHovered()}
                            vm={vm}
                            uIStyle={this.props.getUIStyle}
                        />
                    : null
                }
            </div>
        );
    }
}

TypeCPort.propTypes = {
    index: PropTypes.number,
    connectedDeviceList: PropTypes.array,
    hide: PropTypes.bool,
    isFirst: PropTypes.bool
};

const ConnectedTypeCPort = (props) => {

    const {
        port,
        name,
        type,
        id,
        connectedDeviceList,
        isFirst,
        isDeleteDeviceMode,
        handleClickPortToDelete,
        isPortSelected,
        handleClickPortToEdit,
        isConnectedDeviceHovered,
        handleHoverDevice,
        handleLeaveDevice,
        uIStyle
    } = props;
    return (
        <div className={classNames(isFirst ? styles.typeCPosition : styles.typeCPosition2)}>
            <div>
                {(isDeleteDeviceMode) ?
                    <div onClick={() => handleClickPortToDelete(connectedDeviceList, id)}>
                        <div className={classNames(styles.deleteTypeCPortbackground)} />
                        <div className={classNames(styles.typeCPort, styles.connectedPort)} />
                        <div className={classNames(styles.textTypeC)}>
                            {name}
                        </div>
                        <div className={classNames(((isPortSelected(MODE.DELETE)) ? styles.checkedPort : styles.checkedBox))}>
                            {(isPortSelected(MODE.DELETE)) ? <img className={classNames(styles.checkedImgSize)} src={uIStyle == uiType.ww ? checkWWIcon : checkIcon} /> : null}
                        </div>
                    </div>
                    : (isPortSelected(MODE.OPTION)) ?
                        <div className={classNames(styles.typeCPort, styles.portOptionSelected)} />
                        : <div onClick={() => handleClickPortToEdit(port, name, type, id)}
                            onMouseEnter={() => handleHoverDevice(id)}
                            onMouseLeave={() => handleLeaveDevice()}>
                            <div className={classNames(styles.typeCPortBackground,
                                (isConnectedDeviceHovered) ? styles.connectedPortBackgroundHover : null,
                                (isConnectedDeviceHovered) ? styles.breathPort : null)} />
                            <div className={classNames(styles.typeCPort, styles.connectedTypec,
                                (isConnectedDeviceHovered) ? styles.connectedPortHover : null)} />
                            <div className={classNames(styles.textTypeC,
                                (isConnectedDeviceHovered) ? styles.textHover : null)}>
                                {name}
                            </div>
                        </div>}
            </div>
        </div>
    )
}

TypeCPort.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    getSelectedOption: getSelectedOption(state),
    isOptionSelected: isOptionSelected(state),
    isDeviceSelected: isDeviceSelected(state),
    isDeleteDeviceMode: isDeleteDeviceMode(state),
    getSelectedDeviceIdArray: getSelectedDeviceIdArray(state),
    isTagInformationEnable: isTagInformationEnable(state),
    getSelectedDeviceIdArray: getSelectedDeviceIdArray(state),
    getHoveredConnectedDevice: getHoveredConnectedDevice(state),
    isConnectedDeviceHovered: isConnectedDeviceHovered(state),
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
    openEditPage: (port, name, type, id) => {
        dispatch(openEditPage(port, name, type, id))
        dispatch(closeDeleteMode())
    },
    seletDevice: (array) => dispatch(selectDeviceToDelete(array)),
    showVisionTutorialDialog: () => dispatch(showVisionTutorialDialog()),
    showVisionSettingDialog: () => {
        dispatch(hideStreamingDialog())
        dispatch(showVisionSettingDialog())
    },
    setLaunchSettingWay: (way, name, id) => dispatch(setLaunchSettingWay(way, name, id)),
    hoverConnectedDevice: (array) => dispatch(hoverConnectedDevice(array)),
    cleanConnectedDeviceHovered: () => dispatch(cleanConnectedDeviceHovered())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TypeCPort);


