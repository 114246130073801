import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { getLoadingType, LOADING_TYPE_NUM } from '../../reducers/loading-bar';

const messages = defineMessages({
    audioLoading: {
        defaultMessage: 'Importing audio files...',
        description: 'Import audio file',
        id: 'gui.audio.loading.dialog.loading'
    }
})

class AudioLoadingDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'getHeaderString'
        ]);
    }

    shouldComponentUpdate(nextProps) {
        return (this.props.percentage != nextProps.percentage);
    }

    getHeaderString() {
        switch (this.props.loadingType) {
            case LOADING_TYPE_NUM.TYPE_AUDIO:
                return (<FormattedMessage {...messages.audioLoading} />);
        }
    }

    render() {
        return (
            <div className={styles.backdropStyle}>
                <div className={styles.firmwareStyle} ref={this.ref}>
                    <div className={styles.firmwareHeader}>
                        {this.getHeaderString()}
                    </div>
                    <div className={styles.audioLoadingProgress}>
                        <ProgressBar percentage={this.props.percentage} />
                    </div>
                </div>
            </div>
        );
    }
}

AudioLoadingDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    percentage: PropTypes.number.isRequired,
    loadingType: PropTypes.number
};

const ProgressBar = (props) => {
    return (
        <div className={styles.audioProgressBar}>
            {(props.percentage != 0) ? <Filler percentage={props.percentage} /> : null}
        </div>
    )
}

const Filler = (props) => {
    return <div className={styles.filler} style={{ width: `${props.percentage}%` }} />
}

const mapStateToProps = state => ({
    loadingType: getLoadingType(state),
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AudioLoadingDialog);