import { addLocaleData } from 'react-intl';

import { localeData } from 'scratch-l10n';
import editorMessages from 'scratch-l10n/locales/editor-msgs';
import { isRtl } from 'scratch-l10n';
import { editorMsgsByAsus } from '../locales/editor-msgs';

import store from 'store';

import { PROJECT_STYLE } from '../config/project-config'
import { appString } from '../locales/editor-msgs'


addLocaleData(localeData);

const UPDATE_LOCALES = 'scratch-gui/locales/UPDATE_LOCALES';
const SELECT_LOCALE = 'scratch-gui/locales/SELECT_LOCALE';

const initialState = {
    isRtl: false,
    locale: 'en',
    messagesByLocale: editorMessages,
    messages: editorMessages.en
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SELECT_LOCALE:
            return Object.assign({}, state, {
                isRtl: isRtl(action.locale),
                locale: action.locale,
                messagesByLocale: state.messagesByLocale,
                messages: state.messagesByLocale[action.locale]
            });
        case UPDATE_LOCALES:
            return Object.assign({}, state, {
                isRtl: state.isRtl,
                locale: state.locale,
                messagesByLocale: action.messagesByLocale,
                messages: action.messagesByLocale[state.locale]
            });
        default:
            return state;
    }
};

const selectLocale = function (locale, uiStyle, currentState) {
    store.set("locale", locale);
    updateCustomizedLocaleMessage(currentState, locale, uiStyle);
    return {
        type: SELECT_LOCALE,
        locale: locale,
        messagesByLocale: currentState.messagesByLocale
    };
};

const setLocales = function (localesMessages) {
    return {
        type: UPDATE_LOCALES,
        messagesByLocale: localesMessages
    };
};

const updateCustomizedLocaleMessage = function (currentState, locale, uiStyle = PROJECT_STYLE) {
    let appName = store.get("appName", appString[uiStyle][locale]['appName']);

    // import asus string by locale
    Object.entries(editorMsgsByAsus).forEach(([locale, value]) => {
        Object.entries(editorMsgsByAsus[locale]).forEach(([id, msgs]) => {
            currentState.messagesByLocale[locale][id] = msgs.replaceAll('%APP_NAME%', appName);
        });
    });
}

const initLocale = function (currentState, locale) {
    updateCustomizedLocaleMessage(currentState, locale);
    if (currentState.messagesByLocale.hasOwnProperty(locale)) {
        return Object.assign(
            {},
            currentState,
            {
                isRtl: isRtl(locale),
                locale: locale,
                messagesByLocale: currentState.messagesByLocale,
                messages: currentState.messagesByLocale[locale]
            }
        );
    }
    // don't change locale if it's not in the current messages
    return currentState;
};
export {
    reducer as default,
    initialState as localesInitialState,
    initLocale,
    selectLocale,
    setLocales
};
