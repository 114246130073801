import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import store from 'store';
import VM from 'scratch-vm';
import boardGameStyles from './board-game-dialog.css';
import dialogStyles from './dialog.css';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import index from '../../../static/boardgame/index.json'
import HelpDialog from './help-dialog.jsx'
import { getPickedBrainType } from '../../reducers/picked-brain-type'
import {
    focusDialog,
    getForegroundDialog,
    DIALOG_BOARD_GAME,
    showQuestionDialog,
    quesitonDialogShow,
    hideQuestionDialog,
    getFeedback,
    setFeedback,
    questionType
} from '../../reducers/dialog'
import { uiType, getUIStyle } from '../../reducers/ui-style';
import tabIndexIcon from './svg/tab_index.svg';
import tabIndexWWIcon from './svg/tab_index_ww.svg';
import { DEFAULT_LOCALE } from '../../config/project-config';
import answerIcon from './svg/answer.svg'
import answerWWIcon from './svg/ww/answer.svg'
import backIcon from './svg/back.svg'
import { isProjectChanged } from '../../reducers/project-changed'
import {
    setProjectPreTitle
} from '../../reducers/project-title';
import {
    getWorkspace
} from '../../reducers/block';
import QuestionDialog from './question-dialog.jsx';
import { BLOCKS_DEFAULT_SCALE } from '../../lib/layout-constants';
import { DEFAULT_BLOCKID } from '../../config/block-config';
import log from '../../lib/log.js';
import LocalKey from '../../lib/local-storage-key';
import { EditUtils } from '../device-manager/edit-page/edit-utils.js';
const DIALOG_DEFAULT_SIZE = {
    width: 686,
    height: 634,
    tabWidth: 174,
    minWidth: 360,
    minHeight: 487,
    minContentWidth: 220,

    titleHegith: 54,
    minPadding: 22,
    normalPadding: 44,
    minQuestionHeight: 105,
    normalQuestionHeight: 68,
    minAnswerHeight: 65,
    normalAnswerHeight: 70,
    minButtonHeght: 116,
    normalButtonHeght: 72,
}

const table = index
const DISPALY_TYPE = {
    question: "question",
    answer: "answer"
}
class BoardGameDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.list = this.parseJson()
        let task = this.list.filter(task => task.type == "C-1")
        this.state = {
            tab_select: "C-1",
            width: DIALOG_DEFAULT_SIZE.width,
            height: DIALOG_DEFAULT_SIZE.height,
            display: DISPALY_TYPE.question,
            iframe: task[0].question,
            padding: 43,
            worksapce: "",
            title: ""
        };
    }


    clickTab(type) {
        let task = this.list.filter(task => task.type == type)
        this.setState({ tab_select: type, display: DISPALY_TYPE.question, iframe: task[0].question })
    }

    componentDidUpdate(prevProps) {
        if (this.props.getPickedBrainType != prevProps.getPickedBrainType) this.list = this.parseJson()
        if (this.props.getDialogFeedback) {
            if (this.props.isShowOpenExampleDialog) {
                if (!this.props.projectChanged) {
                    log.info("Save finish to handleOpenExample");
                    this.handleOpenExample(this.state.worksapce, this.state.title);
                    this.resetStoreSavePath();
                }
            }
            this.props.setDialogFeedback(false);
        }
        if (this.props.show != prevProps.show) {
            this.setState({
                width: DIALOG_DEFAULT_SIZE.width,
                height: DIALOG_DEFAULT_SIZE.height,
                tab_select: "C-1",
                display: DISPALY_TYPE.question,
                iframe: this.list[0].question,
                worksapce: "",
                title: ""
            })
            this.props.hideOpenExampleDialog();
        }
    }

    parseJson() {
        let uiStyle = this.props.getUIStyle || "cn";
        let language = store.get("locale", DEFAULT_LOCALE);
        let supportDeviceList = table.support.EDU;
        let list = [];
        Object.entries(table[uiStyle]["task"]).forEach((task) => {
            if (supportDeviceList && supportDeviceList.includes(task[0])) {
                list.push({
                    type: task[0],
                    tab: task[1][language].tab,
                    title: task[1][language].title,
                    question: task[1][language].question,
                    answer: task[1][language].answer,
                    questionCode: task[1][language].questionCode ? task[1][language].questionCode : null,
                    answerCode: task[1][language].answerCode ? task[1][language].answerCode : null
                })
            }
        })
        return list;
    }

    resetStoreSavePath() {
        store.set(LocalKey.nowSavePath, "");
        store.set(LocalKey.nowPreSavePath, "");
        store.set(LocalKey.nowOpenRecent, "");
    }
    getTabList() {
        return <div className={classNames(dialogStyles.deviceHelpTabList)}>
            {this.list.map((location, index) =>
                <div key={index}
                    className={classNames(dialogStyles.tab)}
                    onClick={() => this.clickTab(location.type)}>
                    <div className={classNames((this.state.tab_select == location.type) ? dialogStyles.tabIndexSelected : dialogStyles.tabIndex)}>
                        <img src={(this.props.getUIStyle == uiType.ww) ? tabIndexWWIcon : tabIndexIcon} alt={"tab icon"} />
                    </div>
                    <div className={classNames((this.state.tab_select == location.type) ? dialogStyles.deviceHelpTabActive : dialogStyles.deviceHelpTab)} style={{ fontWeight: 'bold' }}>
                        {location.tab}
                    </div>
                    <div className={classNames(dialogStyles.tabLine)} />
                </div>)}
        </div>
    }

    setDialogSize(width, height) {
        this.setState({ width: width, height: height })
    }

    onClickAnswer(url) {
        this.setState({ display: DISPALY_TYPE.answer, iframe: url })
    }

    onClickImport(worksapce, title) {
        this.setState({ worksapce: worksapce, title: title })
        if (this.props.isProjectChanged) {
            this.props.onShowOpenExampleDialog();
        } else {
            this.handleOpenExample(worksapce, title);
        }
    }

    handleOpenExample(worksapce, title) {
        log.info("handleOpenExample ", { worksapce: worksapce, title: title })
        if (!worksapce || worksapce == "") return;
        const {
            vm,
            onUpdateProjectTitle,
            setProjectPreTitle,
            getWorkspace
        } = this.props;
        // reset workspace scale
        setTimeout(() => {
            if (getWorkspace) {
                let workspaceMetrics = { scrollX: 0, scrollY: 0, scale: BLOCKS_DEFAULT_SCALE }
                getWorkspace.scrollX = workspaceMetrics.scrollX;
                getWorkspace.scrollY = workspaceMetrics.scrollY;
                getWorkspace.scale = workspaceMetrics.scale;
                getWorkspace.setScale(workspaceMetrics.scale)
                getWorkspace.resize();
            }
        });

        // reset start block position
        let resetProjectData = JSON.parse(worksapce);
        let centerX = 100;
        let blockHeaderID = ["!5g-lQYkGvcn!|DZpya^", "-}KW[4;S9L9P4)U8Y(vQ"];
        if (resetProjectData && resetProjectData.targets && resetProjectData.targets[0].blocks[DEFAULT_BLOCKID.event_whenstarted]) {
            resetProjectData.targets[0].blocks[DEFAULT_BLOCKID.event_whenstarted].x = centerX;
            resetProjectData.targets[0].blocks[DEFAULT_BLOCKID.event_whenstarted].y = 220;
            // reset comments position
            if (resetProjectData.targets[0].comments["m8rC]l:-m~bBDWb+otUU"]) {
                resetProjectData.targets[0].comments["m8rC]l:-m~bBDWb+otUU"].x = centerX;
                resetProjectData.targets[0].comments["m8rC]l:-m~bBDWb+otUU"].y = 0
            }
            // reset second or more start block position
            let blockKey = DEFAULT_BLOCKID.event_whenstarted;
            let offsetY = 220;
            for (const id of blockHeaderID) {
                if (resetProjectData.targets[0].blocks[id]) {
                    let count = 1;
                    do {
                        count += 1;
                        blockKey = resetProjectData.targets[0].blocks[blockKey].next;
                    } while (blockKey);
                    offsetY = offsetY + count * 40;
                    resetProjectData.targets[0].blocks[id].x = centerX;
                    resetProjectData.targets[0].blocks[id].y = offsetY;
                }
                blockKey = id;
            }
        }
        //open selected example
        vm.loadProject(JSON.stringify(resetProjectData)).then(() => {
            setProjectPreTitle(title);
            onUpdateProjectTitle(title);
            store.set(LocalKey.nowPreSavePath, "");
            store.set(LocalKey.nowSavePath, "");
        }).catch(error => {
            log.error(error);
        });
        this.props.onClose();
    }


    onClickCopy(workspace, copy) {
        let resetProjectData = JSON.parse(workspace);
        this.props.vm.loadVariables(JSON.stringify(resetProjectData)).then(() => {
            for (var index in copy) {
                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(copy[index], "text/xml");
                let updateBound = this.props.getWorkspace.getBlocksCommentsBoundingBox();
                xmlDoc.getElementsByTagName("block")[0].setAttribute("x", updateBound.x + updateBound.width + 10);
                xmlDoc.getElementsByTagName("block")[0].setAttribute("y", updateBound.y);
                this.props.getWorkspace.pasteBlock_(xmlDoc.getElementsByTagName("block")[0]);
            }
            this.props.getWorkspace.copyBlockCenter();
        }).catch(error => {
            log.error(error);
        });
        this.props.onClose();
    }

    onClickQuestion(url) {
        this.setState({ display: DISPALY_TYPE.question, iframe: url })
    }

    isSmallDialog() {
        return this.state.width < 560
    }

    getIframeHeight(copy) {
        let iframeHeight = this.state.height - DIALOG_DEFAULT_SIZE.titleHegith;
        let bottomHeight = 0;
        let upHeight = 0;
        if (this.state.display == DISPALY_TYPE.answer) {
            upHeight = this.isSmallDialog() ? DIALOG_DEFAULT_SIZE.minAnswerHeight : DIALOG_DEFAULT_SIZE.normalAnswerHeight;
        } else {
            upHeight = this.isSmallDialog() ? DIALOG_DEFAULT_SIZE.minQuestionHeight : DIALOG_DEFAULT_SIZE.normalQuestionHeight;
        }
        if (copy) {
            bottomHeight = this.isSmallDialog() ? DIALOG_DEFAULT_SIZE.minButtonHeght : DIALOG_DEFAULT_SIZE.normalButtonHeght;
        }
        iframeHeight = iframeHeight - upHeight - bottomHeight
        return iframeHeight;
    }

    isEnVersion() {
        return store.get("locale", DEFAULT_LOCALE) == "en";
    }

    getTabCotent() {
        let content;
        this.list.forEach(task => {
            if (task.type == this.state.tab_select) {
                content = task
            }
        })
        let copy = null;
        let worksapce = null;
        if (this.state.display == DISPALY_TYPE.answer && content.answerCode) {
            copy = content.answerCode.copy;
            worksapce = content.answerCode.worksapce
        }
        if (this.state.display == DISPALY_TYPE.question && content.questionCode) {
            copy = content.questionCode.copy;
            worksapce = content.questionCode.worksapce
        }
        let iframeHeight = this.getIframeHeight(copy)
        if (content) {
            return <div className={classNames(boardGameStyles.content)}
                key={content.type}
                style={{
                    paddingLeft: this.isSmallDialog() ? DIALOG_DEFAULT_SIZE.minPadding + "px" : DIALOG_DEFAULT_SIZE.normalPadding + "px",
                }}>
                {this.state.display == DISPALY_TYPE.question ?
                    <div>
                        <div className={classNames(boardGameStyles.titleArea)}
                            style={{
                                paddingRight: this.isSmallDialog() ? DIALOG_DEFAULT_SIZE.minPadding + "px" : DIALOG_DEFAULT_SIZE.normalPadding + "px",
                                paddingLeft: "8px",
                                height: this.isSmallDialog() ? "67px" : "30px"
                            }}>
                            <div className={classNames(boardGameStyles.title)}
                                style={{ float: this.isSmallDialog() ? "none" : "left" }}>
                                {content.title}
                            </div>
                            <div
                                className={classNames(boardGameStyles.answerButton)}
                                onClick={() => this.onClickAnswer(content.answer)}
                                style={{ marginTop: this.isSmallDialog() ? "8px" : "0px", float: this.isSmallDialog() ? "left" : "right" }}>
                                <div className={classNames(boardGameStyles.answerIcon)} >
                                    <img src={this.props.getUIStyle == uiType.ww ? answerWWIcon : answerIcon} alt={EditUtils.getLocaleString("gui.boardgame.answer")} />
                                </div>
                                <div className={classNames(boardGameStyles.answerText)}>
                                    <FormattedMessage
                                        id="gui.boardgame.answer"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classNames(boardGameStyles.iframeArea)}
                            style={{ height: iframeHeight + "px" }}>
                            <div className={classNames(boardGameStyles.iframe)}>
                                <iframe
                                    src={this.state.iframe}
                                    height="100%"
                                    width="100%"
                                    frameBorder="0"
                                    scrolling="overlay"
                                    title="board game iframe"
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div
                            className={classNames(boardGameStyles.questionButton)}
                            onClick={() => this.onClickQuestion(content.question)} >
                            <div className={classNames(boardGameStyles.backIcon)} >
                                <img src={backIcon} alt={"back icon"}/>
                            </div>
                            <div className={classNames(boardGameStyles.backText)}>
                                <FormattedMessage
                                    id="gui.boardgame.answer"
                                />
                            </div>
                        </div>
                        <div className={classNames(boardGameStyles.iframeArea)}
                            style={{ height: iframeHeight + "px" }}>
                            <div className={classNames(boardGameStyles.iframe)}>
                                <iframe
                                    src={this.state.iframe}
                                    height="100%"
                                    width="100%"
                                    frameBorder="0"
                                    scrolling="overlay"
                                    title="board game iframe"
                                />
                            </div>
                        </div>
                    </div>
                }
                {copy && worksapce ? <div className={classNames(boardGameStyles.buttonArea)}
                    style={{ width: this.isSmallDialog() ? "calc(100% - " + (DIALOG_DEFAULT_SIZE.minPadding * 2) + "px)" : "calc(100% - " + (DIALOG_DEFAULT_SIZE.normalPadding * 2) + "px)" }}>
                    <div className={classNames(boardGameStyles.importButton)}
                        onClick={() => this.onClickImport(worksapce, content.type)}
                        style={{
                            float: this.isSmallDialog() ? "none" : "left",
                            marginLeft: this.isSmallDialog() ? "calc(50% - 72px)" : "calc(50% - 150px)"
                        }}>
                        <FormattedMessage
                            id="gui.dialog.example.open"
                        />
                    </div>
                    <div className={classNames(boardGameStyles.copyButton)}
                        onClick={() => this.onClickCopy(worksapce, copy)}
                        style={{
                            marginTop: this.isSmallDialog() ? "10px" : "0px",
                            marginLeft: this.isSmallDialog() ? "calc(50% - 72px)" : "6px",
                            lineHeight: this.isEnVersion() ? "16px" : "32px"
                        }}>
                        <FormattedMessage
                            id="gui.dialog.example.copyBlock"
                        />
                    </div>
                </div> : null}

            </div>
        }
    }



    render() {
        if (!this.props.show) { return null; }
        return (
            <div onMouseDown={() => this.props.focusDialog()}
                onTouchStart={() => this.props.focusDialog()}>
                <HelpDialog
                    dialogType={DIALOG_BOARD_GAME}
                    onClose={this.props.onClose}
                    show={this.props.show}
                    height={DIALOG_DEFAULT_SIZE.height}
                    width={DIALOG_DEFAULT_SIZE.width}
                    defaultTabWidth={DIALOG_DEFAULT_SIZE.tabWidth}
                    minContentWidth={DIALOG_DEFAULT_SIZE.minContentWidth}
                    minHeight={DIALOG_DEFAULT_SIZE.minHeight}
                    minWidth={DIALOG_DEFAULT_SIZE.minWidth}
                    titleId="gui.menuBar.boardgame"
                    tabList={this.getTabList()}
                    content={this.getTabCotent()}
                    setDialogSize={(w, h) => this.setDialogSize(w, h)}>
                </HelpDialog>
                {this.props.isShowOpenExampleDialog ?
                    <QuestionDialog
                        show={this.props.isShowOpenExampleDialog}
                        type={questionType.QUESTION_OPEN_EXAMPLE}
                        onNoSave={() => this.handleOpenExample(this.state.worksapce, this.state.title)}
                        onSave={() => this.props.onSave()}
                    />
                    : null}
            </div>
        );
    }
}

BoardGameDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    getPickedBrainType: getPickedBrainType(state),
    foregroundDialog: getForegroundDialog(state),
    getUIStyle: getUIStyle(state),
    getWorkspace: getWorkspace(state),
    isProjectChanged: isProjectChanged(state),
    isShowOpenExampleDialog: quesitonDialogShow(state, questionType.QUESTION_OPEN_EXAMPLE),
    getDialogFeedback: getFeedback(state),
});

const mapDispatchToProps = dispatch => ({
    focusDialog: () => dispatch(focusDialog(DIALOG_BOARD_GAME)),
    onShowOpenExampleDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_OPEN_EXAMPLE)),
    hideOpenExampleDialog: () => dispatch(hideQuestionDialog(questionType.QUESTION_OPEN_EXAMPLE)),
    setProjectPreTitle: (title) => dispatch(setProjectPreTitle(title)),
    setDialogFeedback: status => dispatch(setFeedback(status)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardGameDialog);