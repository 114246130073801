import extensionsBlocks from '../block-vertical-blocks/extensions';

export default function extensionsEntry(deviceInfo, blockSeparator, translateMsg, isEnableAISpeech) {
    return `
        ${isEnableAISpeech ? `
            <category name="%{BKY_CATEGORY_EXTENSIONS}" id="extensions" colour="#FE8982" secondaryColour="#CF3128">
                ${extensionsBlocks.extensions_wifi_setting_notification_highest_tempature(translateMsg)}
                ${extensionsBlocks.extensions_wifi_setting_notification_lowest_tempature(translateMsg)}
                ${extensionsBlocks.extensions_wifi_setting_notification_humidity(translateMsg)}
                ${extensionsBlocks.extensions_wifi_setting_notification_air_quality(translateMsg)}
                ${blockSeparator}
                ${extensionsBlocks.extensions_wifi_setting_notification_current_time(translateMsg)}
                ${blockSeparator}
                ${extensionsBlocks.extensions_wifi_setting_notification_translate_to(translateMsg)}
            </category>`: ``
        }
    `;
};