import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import styles from './device-manager-stage.css';
import { getPickedBrainType } from '../../../reducers/picked-brain-type'
import { BRAIN_TYPE } from '../../../lib/brains'
import VM from 'scratch-vm';
import {
    openDeviceList,
    closeDeviceList,
    isPortOpen,
    getPortNumber,
    closeEditPage,
    isEditPageOpen,
    getPortDevice,
    isAddPageOpen,
} from '../../../reducers/device-manager-controller';

import {
    cleanPortOption,
} from '../../../reducers/select-option';

import EduEditPage from '../edit-page/edu-edit-page.jsx';
import EduBrain from '../brain/edu-brain.jsx';
import EduDeviceList from '../device-list/edu-device-list.jsx';


import EntryEditPage from '../edit-page/entry-edit-page.jsx';
import EntryBrain from '../brain/entry-brain.jsx';
import EntryDeviceList from '../device-list/entry-device-list.jsx';

import EduEntryBrain from '../brain/edu-entry-brain.jsx';
import EduEntryDeviceList from '../device-list/edu-entry-device-list.jsx';

import Controller from '../controller/controller.jsx';
import ButtonList from '../controller/button-list.jsx';

import {
    isDeviceHelpDialogShow,
    hideDeviceHelpDialog
} from '../../../reducers/dialog';

import {
    setSelectedTab,
    getSelectedTab,
    TAB_INDEX,
    isControllerEnable
} from '../../../reducers/controller';

import {
    getWorkspace
} from '../../../reducers/block';

import {
    viewPage,
    setStageSize,
    getFullScreen
} from '../../../reducers/stage-size';

import { STAGE_SIZE_MODES, STAGE_DISPLAY_TYPE, STAGE_DISPLAY_SIZES } from '../../../lib/layout-constants';
import { isShowUserGuide } from '../../../reducers/user-guide';
import VRViewer from '../../vr/vr-viewer.jsx';
import { setDeviceManagerVisible } from '../../../reducers/device-manager-controller';

import {
    platformType,
    isPad,
    getPlatform
} from '../../../lib/platform'

import { uiType, getUIStyle } from '../../../reducers/ui-style';

import help from '../pictures/help.svg';
import helpWW from '../pictures/ww/help.svg';

const messages = defineMessages({
    hintTitle: {
        defaultMessage: "Before running the file, please make sure that the device slot position on the host is the same as this setting",
        description: "hint title",
        id: "gui.deviceManagerStage.hint.title"
    }
})

const eduDeviceTypes = [
    'threeWireMotor',
    'lineTracker',
    'buzzer',
    'led',
    'ultrasonic',
    'bumper'
]

const entryDeviceTypes = [
    'motor',
    'motor100',
    'motor300',
    'drivetrain',
    'vision',
    'speaker',
    'gyro',
    'touchLed',
    'colorSensor'
]

class DeviceManagerStage extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'openDeviceManage',
            'closeDeviceManage',
            'getDeviceManagerStyle'
        ]);
        this.state = {
            brainType: this.props.getPickedBrainType,
            tabIndex: TAB_INDEX.brain,
            isControllerEnable: this.props.isControllerEnable
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.isAddPageOpen !== 'undefined' && !this.props.isAddPageOpen) && (this.props.isEditPageOpen !== 'undefined' && !this.props.isEditPageOpen)) {
            this.props.openDeviceList();
        }
        if (this.props.getPickedBrainType != prevProps.getPickedBrainType) this.setState({ brainType: this.props.getPickedBrainType });
        if (this.props.getSelectedTab != prevProps.getSelectedTab
            && this.props.getSelectedTab != this.state.tabIndex) this.setState({ tabIndex: this.props.getSelectedTab });
        if (this.props.isControllerEnable != prevProps.isControllerEnable) this.setState({ isControllerEnable: this.props.isControllerEnable });
    }

    onClickTab(index) {
        if (index != this.state.tabIndex) {
            this.setState({ tabIndex: this.props.getSelectedTab });
            this.props.setSelectedTab(index);
        }
        if (index == TAB_INDEX.controller) this.props.closeEditPage();
    }

    getTitle() {
        switch (this.state.brainType) {
            case BRAIN_TYPE.EDU:
                return ((this.state.isControllerEnable) ?
                    <div className={classNames(styles.tabBackground)}>
                        <div className={classNames(styles.entryTabLeft, (this.state.tabIndex == TAB_INDEX.brain) ? styles.tabActive : styles.tab)}
                            onClick={() => this.onClickTab(TAB_INDEX.brain)}>
                            <FormattedMessage
                                defaultMessage="Education"
                                description="Title of brain EDU"
                                id="gui.deviceManagerStage.title.edu" />
                        </div>
                        <div className={classNames(styles.tabTriangle, (this.state.tabIndex == TAB_INDEX.brain) ? styles.tabTriangleLeft : styles.tabTriangleRight)}> </div>
                        <div className={classNames(styles.entryTabRight, (this.state.tabIndex == TAB_INDEX.controller) ? styles.tabActive : styles.tab)}
                            onClick={() => this.onClickTab(TAB_INDEX.controller)}>
                            <FormattedMessage
                                defaultMessage="Controller"
                                description="Title of brain Controller"
                                id="gui.deviceManagerStage.title.controller" />
                        </div>
                    </div>
                    : <div
                        className={classNames(styles.title)}>
                        <div>
                            <FormattedMessage
                                defaultMessage="Education"
                                description="Title of brain EDU"
                                id="gui.deviceManagerStage.title.edu" />
                        </div>
                    </div>);
            case BRAIN_TYPE.ENTRY:
            case BRAIN_TYPE.EDU_AND_ENTRY:
                return (<div className={classNames(styles.tabBackground)}>
                    <div className={classNames(styles.entryTabLeft, (this.state.tabIndex == TAB_INDEX.brain) ? styles.tabActive : styles.tab)}
                        onClick={() => this.onClickTab(TAB_INDEX.brain)}>
                        <FormattedMessage
                            defaultMessage="Entry"
                            description="Title of brain Entry"
                            id="gui.deviceManagerStage.title.entry" />
                    </div>
                    <div className={classNames(styles.tabTriangle, (this.state.tabIndex == TAB_INDEX.brain) ? styles.tabTriangleLeft : styles.tabTriangleRight)}> </div>
                    <div className={classNames(styles.entryTabRight, (this.state.tabIndex == TAB_INDEX.controller) ? styles.tabActive : styles.tab)}
                        onClick={() => this.onClickTab(TAB_INDEX.controller)}>
                        <FormattedMessage
                            defaultMessage="Controller"
                            description="Title of brain Controller"
                            id="gui.deviceManagerStage.title.controller" />
                    </div>
                </div>);
            case BRAIN_TYPE.WEB_VR:
            default:
                return (<div
                    className={classNames(styles.title)}>
                    {this.state.brainType}</div>
                );
        }
    }

    getImageComponent(vm) {
        switch (this.state.brainType) {
            case BRAIN_TYPE.EDU:
                return (this.state.isControllerEnable) ?
                    ((this.state.tabIndex == TAB_INDEX.brain)
                        ? <EduBrain vm={vm} />
                        : <Controller vm={vm} />)
                    : <EduBrain vm={vm} brainType={this.state.brainType} />;
            case BRAIN_TYPE.ENTRY:
                return ((this.state.tabIndex == TAB_INDEX.brain)
                    ? <EntryBrain vm={vm} />
                    : <Controller vm={vm} brainType={this.state.brainType} />);
            case BRAIN_TYPE.EDU_AND_ENTRY:
                return ((this.state.tabIndex == TAB_INDEX.brain)
                    ? <EduEntryBrain vm={vm} />
                    : <Controller vm={vm} brainType={this.state.brainType} />);
            case BRAIN_TYPE.WEB_VR:
            default:
                return null;
        }
    }

    getListComponent(vm) {
        switch (this.state.brainType) {
            case BRAIN_TYPE.EDU:
                return (this.state.isControllerEnable) ?
                    <div className={classNames(styles.deviceListArea)}>
                        {(this.state.tabIndex == TAB_INDEX.brain)
                            ? (!this.props.isEditPageOpen && !this.props.isAddPageOpen)
                                ? <EduDeviceList vm={vm} brainType={this.state.brainType} />
                                : null
                            : <ButtonList vm={vm} motorList={vm.getAllMotor()} drivetrain={vm.getDrivetrain()} />}
                    </div>
                    : (<EduDeviceList vm={vm} />);
            case BRAIN_TYPE.ENTRY:
                return (
                    <div className={classNames(styles.deviceListArea)}>
                        {(this.state.tabIndex == TAB_INDEX.brain)
                            ? (!this.props.isEditPageOpen && !this.props.isAddPageOpen)
                                ? <EntryDeviceList vm={vm} brainType={this.state.brainType} />
                                : null
                            : <ButtonList vm={vm} motorList={vm.getAllMotor()} drivetrain={vm.getDrivetrain()} />}
                    </div>
                );
            case BRAIN_TYPE.EDU_AND_ENTRY:
                return (
                    <div className={classNames(styles.deviceListArea)}>
                        {(this.state.tabIndex == TAB_INDEX.brain)
                            ? (!this.props.isEditPageOpen && !this.props.isAddPageOpen)
                                ? <EduEntryDeviceList vm={vm} brainType={this.state.brainType} />
                                : null
                            : <ButtonList vm={vm} motorList={vm.getAllMotor()} drivetrain={vm.getDrivetrain()} />}
                    </div>
                );
            case BRAIN_TYPE.WEB_VR:
            default:
                return null;
        }
    }

    getEditPage(vm) {
        switch (this.state.brainType) {
            case BRAIN_TYPE.EDU:
                return (<EduEditPage vm={vm} brainType={this.state.brainType} />);
            case BRAIN_TYPE.ENTRY:
                return (<EntryEditPage vm={vm} brainType={this.state.brainType} setVisionTagSetting={this.props.setVisionTagSetting} />);
            case BRAIN_TYPE.EDU_AND_ENTRY:
                return eduDeviceTypes.includes(this.props.getPortDevice) ?
                    <EduEditPage vm={vm} brainType={this.state.brainType} /> :
                    entryDeviceTypes.includes(this.props.getPortDevice) ?
                        (<EntryEditPage vm={vm} brainType={this.state.brainType} setVisionTagSetting={this.props.setVisionTagSetting} />)
                        : null
            case BRAIN_TYPE.WEB_VR:
            default:
                return null;
        }
    }

    getDeviceManagerStyle() {
        if (isPad()) {
            return styles.flexContentPad;
        } else {
            if (this.props.getVRFullScreen) {
                return styles.fullScreen;
            } else {
                return styles.flexContent;
            }
        }
    }

    getCloseButtonStyle() {
        if (isPad()) {
            if (this.props.stageSize == 'small') {
                return styles.openStagePad;
            } else {
                return styles.closeStagePad;
            }
        } else {
            if (this.props.stageSize == 'small') {
                return styles.openStage;
            } else {
                return styles.closeStage;
            }
        }
    }

    openDeviceManage() {
        this.props.onOpenDeviceManage();
        this.props.setDeviceManagerVisible(true);
    }

    closeDeviceManage() {
        this.props.onCloseStage();
        this.props.setDeviceManagerVisible(false);
    }

    render() {
        const {
            stageSize,
            showUserGuide,
            vm,
        } = this.props;

        return (
            <div className={this.getDeviceManagerStyle()}>
                <div
                    className={classNames(
                        this.getCloseButtonStyle(),
                        showUserGuide ? styles.hide : null
                    )}
                    onClick={(stageSize == 'small') ? this.openDeviceManage : this.closeDeviceManage}
                />
                {
                    this.props.getPickedBrainType == BRAIN_TYPE.WEB_VR ? (
                        <VRViewer stageSize={stageSize} onLoadFinish={this.props.onLoadFinish} />
                    ) : (
                        <div className={classNames(styles.stage, styles.stageDiff,
                            stageSize == STAGE_DISPLAY_SIZES.small ? styles.stageHidden : null)} onClick={() => this.props.getWorkspace.onClearWidget()}>
                            <div className={classNames(styles.content)}>
                                <div className={classNames(styles.contentUpBackground, (this.state.tabIndex == TAB_INDEX.brain) ? styles.brainContent : styles.controllerContent)}>
                                    {this.getTitle()}
                                    {(this.state.tabIndex == TAB_INDEX.brain) ?
                                        (<div className={classNames(styles.hintTitle)}>
                                            <img className={classNames(styles.hint)}
                                                src={this.props.getUIStyle == uiType.ww ? helpWW : help} alt={"hint"}/>
                                            <div style={{ fontSize: '11px' }}>
                                                <FormattedMessage
                                                    {...messages.hintTitle}
                                                />
                                            </div>
                                        </div>) : null}
                                    {(this.state.tabIndex == TAB_INDEX.brain) ?
                                        <div style={{ top: '-47px', position: 'relative' }}>
                                            {this.getImageComponent(vm)}
                                        </div> : this.getImageComponent(vm)}
                                </div>
                                <div className={classNames((this.state.tabIndex == TAB_INDEX.brain) ? styles.contentBottomArea : styles.buttonListArea)}>
                                    {this.getListComponent(vm)}
                                </div>
                                {
                                    (!this.props.isAddPageOpen && !this.props.isEditPageOpen) ? null :
                                        <div className={classNames(styles.editPageArea)}>
                                            {this.getEditPage(vm)}
                                        </div>
                                }
                            </div>
                        </div >
                    )
                }
            </div>
        );
    }

}

DeviceManagerStage.propTypes = {
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    brainInfo: PropTypes.object,
    isPortAdd: PropTypes.bool,
    getPortNumber: PropTypes.string,
    isEditPageOpen: PropTypes.bool,
    isAddPageOpen: PropTypes.bool,
    getPortDevice: PropTypes.string,
    vm: PropTypes.instanceOf(VM).isRequired,
    getWorkspace: PropTypes.object,
    onOpenDeviceManage: PropTypes.func,
    onCloseStage: PropTypes.func,
    showUserGuide: PropTypes.bool,
    getVRFullScreen: PropTypes.bool,
    setVisionTagSetting: PropTypes.func,
    getVisionTagSetting: PropTypes.func,
};


const mapStateToProps = state => ({
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    stageTypeMode: state.scratchGui.stageSize.stageType,
    isPortAdd: isPortOpen(state),
    getPortNumber: getPortNumber(state),
    isEditPageOpen: isEditPageOpen(state),
    getPortDevice: getPortDevice(state),
    isAddPageOpen: isAddPageOpen(state),
    isDeviceHelpDialogShow: isDeviceHelpDialogShow(state),
    isAddPageOpen: isAddPageOpen(state),
    getPickedBrainType: getPickedBrainType(state),
    getSelectedTab: getSelectedTab(state),
    getWorkspace: getWorkspace(state),
    isControllerEnable: isControllerEnable(state),
    showUserGuide: isShowUserGuide(state),
    getVRFullScreen: getFullScreen(state, viewPage.vr),
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
    closeDeviceList: () => dispatch(closeDeviceList()),
    closeEditPage: () => {
        dispatch(closeEditPage());
        dispatch(cleanPortOption());
    },
    closeHelpDialog: () => dispatch(hideDeviceHelpDialog()),
    openDeviceList: (number) => dispatch(openDeviceList(number)),
    setSelectedTab: (tab) => dispatch(setSelectedTab(tab)),
    onOpenDeviceManage: () => dispatch(setStageSize(STAGE_SIZE_MODES.large, STAGE_DISPLAY_TYPE.deviceManager)),
    onCloseStage: () => dispatch(setStageSize(STAGE_SIZE_MODES.small)),
    setDeviceManagerVisible: (visible) => dispatch(setDeviceManagerVisible(visible)),
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceManagerStage));