import bindAll from 'lodash.bindall';
import PropTypes, { number } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import styles from './edit-page.css';
import VM from 'scratch-vm';
import {
    closeDeviceList,
    isEditPageOpen,
    getPortDevice,
    getPortNumber,
    getPortDeviceId,
    closeAddPage,
    closeEditPage,
    isAddPageOpen,
    setPortArray
} from '../../../reducers/device-manager-controller';
import store from 'store';
import { DEFAULT_LOCALE } from '../../../config/project-config';
import {
    SpeakerCityMapTable,
    SupportLocale
} from '../../dialog/speaker/skill-sentence'
import {
    EditUtils,
    STRING_ID,
    STATE_INDEX,
    PATH_TYPE,
    SUPPORT_TYPES,
    ROTATION, OPTION_TYPE,
    HINT_WHEELBASE,
    UNIT_OF_LENGTH,

    NAME_AVAILABLE,
    DEFAULT_MM_LENGTH,
    DEFAULT_INCHES_LENGTH,
    SPEAKER_LOCATION,
    SPEAKER_ON_OPTION,
    CONTROL_OPTION,
    mappingLanguage,
    mappingSpeakerOnArray,
    DEFAULT_CALL_WORD,
    DEFAULT_RESPONSE_WORD
} from './edit-utils.js';
import { Motor, SelectOption, EntryDriverTrain, SmartSpeaker, CALL_WORD_RULE } from './edit-page-device-component.jsx';
import {
    selectPortOption,
    cleanPortOption,
} from '../../../reducers/select-option';
import editImg from '../pictures/device_edit.svg';

import smartSmallWhiteImg from '../pictures/port_entry_smart.svg'

import smartSmallGreenImg from '../pictures/port_entry_smart_green.svg'
import smartSmallWWImg from '../pictures/port_entry_smart_ww.svg'
import exclamationIcon from '../pictures/exclamation_mark.svg';
import crossIcon from '../pictures/cross_mark.svg';

import { BRAIN_TYPE } from '../../../lib/brains';
import { setProjectChanged } from '../../../reducers/project-changed';
import {
    showVisionTutorialDialog,
    hideVisionTutorialDialog,
    showVisionSettingDialog,
    hideVisionSettingDialog,
    showSpeakerSkillDialog,
    hideStreamingDialog
} from '../../../reducers/dialog';

import {
    getSkillList,
    setSkillList,
    clearSkillList,

    getSkillLocation,
    setSkillLocation,
    clearSkillLocation
} from '../../../reducers/speaker';
import {
    setColorDataList,
    getColorDataList,
    setTagInformationState,
    isTagInformationEnable,
    COLOR_DATA_LIST,
    IS_TAG_INFORMATION_ENABLE,
    QRCODE_RESULT,
    isVisionConnected,
    isVisionInitiated,
    isVisionRecongInitiated,
    DEFAULT_COLOR_DATA_LIST,
} from '../../../reducers/vision';
import { getUIStyle, uiType } from '../../../reducers/ui-style';
import { SYMBOLS_NOT_ALLOWED } from './edit-utils.js';
const initialState = {
    name: null,
    isNameEdited: false,
    smart1: null,
    smart2: null,
    typeC1: null,
    isEditName: false,
    path: null,
    rotation: null,
    forwardName: null,
    isEditForwardName: false,
    reverseName: null,
    isEditReverseName: false,
    tempSmart1: null,
    tempSmart2: null,
    tempTypeC1: null,
    tempName: null,
    tempForwardName: null,
    tempReverseName: null,
    smart3: null,
    tempSmart3: null,
    expandSelect: null,
    isVisionDistribute: false,
    isCheckedGyro: false,
    wheelUnit: UNIT_OF_LENGTH.cm,
    wheelSize: DEFAULT_MM_LENGTH.wheelSize,
    wheelbaseLR: DEFAULT_MM_LENGTH.wheelbaseLR,
    wheelbaseFB: DEFAULT_MM_LENGTH.wheelbaseFB,
    hoverWheelHint: '',
    firstRatioOfWheel: 1,
    secondRatioOfWheel: 1,
    callWord: DEFAULT_CALL_WORD.zh,
    responseWord: DEFAULT_RESPONSE_WORD,
    isCallEnable: true,
    isResponseEnable: true,
    controlLanguage: mappingLanguage.zh,
    focusInput: null,
    hoverPortOption: -1
};


const ICON_SIZE = {
    small: 'small',
    normal: 'normal'
}

const convertUnitToInch = (cm) => { return (Math.round(cm * 0.3937 * 100) / 100) >= 999.99 ? 999.99 : (Math.round(cm * 0.3937 * 100) / 100) }
const convertUnitToCM = (inch) => { return (Math.round(inch * 2.54 * 10) / 10) >= 999.9 ? 999.9 : (Math.round(inch * 2.54 * 10) / 10) }

class EntryEditPage extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = initialState;
    }

    isWWVersion() {
        return this.props.getUIStyle == uiType.ww
    }

    cleanState() {
        this.setState(initialState)
    }

    closeSelect() {
        if (this.state.expandSelect) {
            this.setState({ expandSelect: null })
        }
    }

    cleanFocus() {
        if (this.state.focusInput) {
            this.setState({ focusInput: null })
        }
    }

    isSelectExpand(selectType) {
        return this.state.expandSelect == selectType;
    }

    isCallWordValid() {
        if (this.state.controlLanguage == mappingLanguage.en) return CALL_WORD_RULE.VALID;
        let value = this.state.callWord
        var rex = /[^\u4e00-\u9fa5]/; //Only zh is valid
        if (!value || value == "") {
            return CALL_WORD_RULE.EMPTY;
        } else if (rex.test(value)) {
            return CALL_WORD_RULE.SPECIAL_SYMBOL;
        } else {
            return CALL_WORD_RULE.VALID
        }
    }

    handleClickSave() {
        let portArray = this.parsePortArray();
        let name = this.isNameValid(this.state.tempName, STATE_INDEX.name) ? this.state.tempName : this.state.name;
        name = name.replace(/^\s*/, "");
        let forwardName = this.state.tempForwardName && this.isNameValid(this.state.tempForwardName, STATE_INDEX.forwardName) ? this.state.tempForwardName : this.state.forwardName;
        let reverseName = this.state.tempReverseName && this.isNameValid(this.state.tempReverseName, STATE_INDEX.reverseName) ? this.state.tempReverseName : this.state.reverseName;
        let isNameEdited = this.isDeviceNameEdited(name) || this.state.isNameEdited;
        if (!(this.isNameValid(name, STATE_INDEX.name) && this.checkSavePort())) return;
        if (this.props.isAddPageOpen) {
            this.addDevice(name, portArray, isNameEdited, forwardName, reverseName)
        } else if (this.props.isEditPageOpen) {
            this.editDevice(name, portArray, isNameEdited, forwardName, reverseName)
        }
        this.props.setPortArray(portArray);
        // project changed
        this.props.onProjectChanged();
        this.props.closeAddPage();
        this.cleanState();
        if (this.props.deviceType == SUPPORT_TYPES.vision) {
            this.saveVisionData();
        }
        if (this.props.deviceType == SUPPORT_TYPES.speaker) {
            this.saveSpeakerData();
        }
    }

    saveVisionData() {
        this.props.vm.setCvTagSettings(this.props.getColorDataList);
        if (this.state.isVisionDistribute) {
            this.props.setVisionTagSetting(this.props.getColorDataList);
        }
        store.set('visionData', {
            [COLOR_DATA_LIST]: this.props.getColorDataList,
            [IS_TAG_INFORMATION_ENABLE]: this.props.isTagInformationEnable,
        })
        this.props.hideVisionTutorialDialog();
        this.props.hideVisionSettingDialog();
    }

    saveSpeakerData() {
        this.props.vm.setSpeakerSettings({
            callWord: this.state.controlLanguage == mappingLanguage.en ? DEFAULT_CALL_WORD.en : this.state.callWord,
            responseWord: this.state.responseWord,
            isCallEnable: this.state.isCallEnable,
            isResponseEnable: this.state.isResponseEnable,
            controlLanguage: mappingLanguage[this.state.controlLanguage],
            skillEnableList: this.props.getSkillList,
            location: {
                [SupportLocale.tw]: SpeakerCityMapTable[SupportLocale.tw][this.getCityKeyByValue(this.props.getSkillLocation)],
                [SupportLocale.cn]: SpeakerCityMapTable[SupportLocale.cn][this.getCityKeyByValue(this.props.getSkillLocation)],
                [SupportLocale.en]: SpeakerCityMapTable[SupportLocale.en][this.getCityKeyByValue(this.props.getSkillLocation)]
            },
            backupTwCallWord: this.state.callWord
        });
    }

    addDevice(name, portArray, isNameEdited, forwardName, reverseName) {
        let device = this.props.deviceType;
        if (device == SUPPORT_TYPES.motor100) {
            this.props.vm.addMotor100(name, portArray, this.state.rotation, forwardName, reverseName, isNameEdited);
        } else if (device == SUPPORT_TYPES.motor300) {
            this.props.vm.addMotor300(name, portArray, this.state.rotation, forwardName, reverseName, isNameEdited);
        } else if (device == SUPPORT_TYPES.drivetrain) {
            this.props.vm.addEntryDrivetrain(name, portArray, this.state.path, this.state.wheelSize, this.state.wheelSize, isNameEdited, this.state.smart3, this.getGyroName(), this.getWheelSetting());
        } else {
            this.props.vm.addCommonDevice(device, name, portArray, isNameEdited);
        }
    }

    editDevice(name, portArray, isNameEdited, forwardName, reverseName) {
        let device = this.props.deviceType;
        if (device == SUPPORT_TYPES.motor100) {
            this.props.vm.editMotor100(this.props.deviceId, name, portArray, this.state.rotation, forwardName, reverseName, isNameEdited);
        } else if (device == SUPPORT_TYPES.motor300) {
            this.props.vm.editMotor300(this.props.deviceId, name, portArray, this.state.rotation, forwardName, reverseName, isNameEdited);
        } else if (device == SUPPORT_TYPES.drivetrain) {
            this.props.vm.editEntryDrivetrain(this.props.deviceId, name, portArray, this.state.path, this.state.wheelSize, this.state.wheelSize, isNameEdited, this.state.smart3, this.getGyroName(), this.getWheelSetting());
        } else {
            this.props.vm.editCommonDevice(this.props.deviceId, name, portArray, isNameEdited);
        }
    }

    getGyroName() {
        let deviceInfo = this.getDeviceInfo();
        return this.state.smart3 ? deviceInfo.other && deviceInfo.other.gyroName ? deviceInfo.other.gyroName : EditUtils.getLocaleString("gui.device.drivetrain.gyro") : null
    }

    getWheelSetting() {
        return {
            wheelUnit: this.state.wheelUnit ? this.state.wheelUnit : UNIT_OF_LENGTH.cm,
            wheelbaseLR: this.state.isCheckedGyro ? DEFAULT_MM_LENGTH.wheelbaseLR : this.state.wheelbaseLR ? this.state.wheelbaseLR : DEFAULT_MM_LENGTH.wheelbaseLR,
            wheelbaseFB: this.state.isCheckedGyro ? DEFAULT_MM_LENGTH.wheelbaseFB : this.state.wheelbaseFB ? this.state.wheelbaseFB : DEFAULT_MM_LENGTH.wheelbaseFB,
            ratioOfGear: this.state.firstRatioOfWheel && this.state.secondRatioOfWheel ? this.state.firstRatioOfWheel + ":" + this.state.secondRatioOfWheel : "1:1",
        }
    }

    checkSavePort() {
        let type = this.props.deviceType;
        const request = this.getDeviceRequest();
        let selectPort = this.parsePortArray();

        if (type && type == SUPPORT_TYPES.drivetrain) return (selectPort.length == (request.smart - 1) && !this.state.smart3) || selectPort.length == request.smart
        return (request && (selectPort.length == (request.smart + request.typeC)))
    }

    handleClickCancel() {
        this.cleanState();
        this.props.cleanPortOption;
        this.props.closeAddPage();
        if (this.props.deviceType == SUPPORT_TYPES.vision) {
            this.parseVisionDataToReducer();
            this.props.hideVisionTutorialDialog();
            this.props.hideVisionSettingDialog();
        }
        if (this.props.deviceType == SUPPORT_TYPES.speaker) {
            let speakerSettings = this.props.vm.getSpeakerSettings();
            this.props.setSkillList((speakerSettings && speakerSettings.skillEnableList) ? Array.from(speakerSettings.skillEnableList) : [])
            this.props.setSkillLocation((speakerSettings && speakerSettings.location) ? (speakerSettings.location[SupportLocale.en])
                ? this.getCityKeyByValue(speakerSettings.location[SupportLocale.en]) : this.getCityKeyByValue(speakerSettings.location)
                : SPEAKER_LOCATION.TAIPEI)
        }
    }

    getCityKeyByValue(value) {
        return Object.keys(SpeakerCityMapTable['en']).find(key => SpeakerCityMapTable['en'][key] === value) || Object.keys(SpeakerCityMapTable['zh-tw']).find(key => SpeakerCityMapTable['zh-tw'][key] === value);
    }

    handleCheckGyro() {
        this.setState({
            isCheckedGyro: !this.state.isCheckedGyro,
            smart3: null,
            wheelbaseLR: this.state.wheelbaseLR ? this.state.wheelbaseLR : this.state.wheelUnit == UNIT_OF_LENGTH.cm ? DEFAULT_MM_LENGTH.wheelbaseLR : DEFAULT_INCHES_LENGTH.wheelbaseLR,
            wheelbaseFB: this.state.wheelbaseFB ? this.state.wheelbaseFB : this.state.wheelUnit == UNIT_OF_LENGTH.cm ? DEFAULT_MM_LENGTH.wheelbaseFB : DEFAULT_INCHES_LENGTH.wheelbaseFB,
        })
        this.updateDefaultPort();
        this.closeSelect();
    }

    handleSelectWheelUnit(type) {
        if (type != this.state.wheelUnit) this.setState({ wheelUnit: type })
    }

    parseVisionDataToReducer() {
        let data = store.get('visionData');
        if (data && typeof data == 'object') {
            this.props.setColorDataList(data[COLOR_DATA_LIST]);
            this.props.vm.restoreCvTagSettings(data[COLOR_DATA_LIST]);
            this.props.setTagInformationState(data[IS_TAG_INFORMATION_ENABLE]);
            if (this.state.isVisionDistribute) {
                this.props.setVisionTagSetting(data[COLOR_DATA_LIST]);
            }
        }
    }

    handleClickDelete() {
        this.props.vm.removeDeviceById(this.props.deviceId)
        if (this.props.deviceType == SUPPORT_TYPES.speaker) {
            this.props.vm.clearSpeakerSettings()
            this.props.clearSkillList()
            this.props.clearSkillLocation()
        }
        this.handleClickCancel();
    }

    handleClickPath(path) {
        if (path && this.state.path != path) {
            this.setState({ path: path })
        }
    }

    handleClickEdit(isEditIndex) {
        this.handleSaveName();
        this.setState({
            [isEditIndex]: true
        })
    }

    handleClickMotorSwitch() {
        this.handleSaveName();
        this.setState({
            rotation: this.state.rotation == ROTATION.forward ? ROTATION.reverse : ROTATION.forward
        })
    }

    handleClickRotationRadio(rotation) {
        this.handleSaveName();
        this.setState({
            rotation: rotation
        })
    }

    handleClickDefault(index) {
        this.handleSaveName();
        this.setState({
            [index]: initialState[index]
        })
    }

    handleClickInput(index) {
        this.handleSaveName();
        this.setState({
            focusInput: index
        })
    }

    handleSaveName() {
        this.saveName(this.state.tempName, STATE_INDEX.name, STATE_INDEX.tempName, STATE_INDEX.isEditName);
        this.saveName(this.state.tempForwardName, STATE_INDEX.forwardName, STATE_INDEX.tempForwardName, STATE_INDEX.isEditForwardName);
        this.saveName(this.state.tempReverseName, STATE_INDEX.reverseName, STATE_INDEX.tempReverseName, STATE_INDEX.isEditReverseName);
        this.closeSelect();
        this.cleanFocus();
    }

    saveName(name, nameIndex, tempIndex, isEditIndex) {
        if (!this.state[isEditIndex]) return;
        name = name.replace(/^\s*/, "");
        let validName = this.isNameValid(name, nameIndex) && !EditUtils.isNameContainSp(name);;
        if (nameIndex == STATE_INDEX.name) {
            let list = this.props.vm.getDeviceList();
            let duplicate = list.filter(device => ((this.props.deviceId && this.props.deviceId != device.id && name == device.name) || (!this.props.deviceId && name == device.name)));
            validName = (validName && duplicate.length == 0)
            if (validName) this.isDeviceNameEdited(name);
        }
        this.setState({
            [isEditIndex]: false,
            [nameIndex]: (validName) ? name : this.state[nameIndex],
            [tempIndex]: (validName) ? name : this.state[nameIndex]
        });
    }

    isNameValid(name, nameIndex) {
        if (name && name.length > 20) return false;
        if (nameIndex && nameIndex == STATE_INDEX.name) {
            return this.props.vm.checkDeviceNameAvailable(name, this.props.deviceId) === NAME_AVAILABLE;
        } else {
            return EditUtils.isNameValid(name) && !EditUtils.isRotationNameDuplicate(name, nameIndex, this.state);
        }
    }

    getInvalidNameMsg() {
        return (EditUtils.isNameContainSp(this.state.tempName)) ? SYMBOLS_NOT_ALLOWED : this.props.vm.checkDeviceNameAvailable(this.state.tempName, this.props.deviceId)
    }

    isDeviceNameEdited(name) {
        let isNameEdited = false
        if (!this.state.smart1 && !this.state.smart2) {
            isNameEdited = name != EditUtils.getDeviceDefaultName(this.props.deviceType);
        } else {
            isNameEdited = name != this.getDefaultName();
        }
        this.setState({
            isNameEdited: isNameEdited
        })
        return isNameEdited;
    }

    onNameChange(event, index) {
        if (!this.isInputValid(event.target.value, index)) return;
        this.setState({
            [index]: event.target.value
        })
    }

    onMethodSwitch(index) {
        this.setState({
            [index]: !this.state[index]
        })
    }

    handleSelectOption(option, index) {
        this.setState({
            [index]: option
        })
    }

    isInputValid(value, index) {
        if (index == STATE_INDEX.wheelSize || index == STATE_INDEX.wheelbaseFB || index == STATE_INDEX.wheelbaseLR) {
            let validChar = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];
            let valueArray = value.split('');
            if (value[0] == '.' || value[0] == '0') return false;
            let allNum = true;
            valueArray.forEach((v) => {
                if (!validChar.includes(v)) {
                    allNum = false;
                }
            })
            if (!allNum) return false;
            if (value.includes('.')) {
                if ((valueArray.filter(e => e == '.').length > 1) || (value.length > value.indexOf('.') + 3)) return false;
            } else {
                if (value.length > 3 && value[3] != '.') return false;
            }
        }
        if (index == STATE_INDEX.firstRatioOfWheel || index == STATE_INDEX.secondRatioOfWheel) {
            let validChar = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
            let valueArray = value.split('');
            if (value[0] == '0') return false;
            let allNum = true;
            valueArray.forEach((v) => {
                if (!validChar.includes(v)) {
                    allNum = false;
                }
            })
            if (!allNum) return false;

        }
        return true;
    }

    setDefaultName() {
        if (this.state.isNameEdited) return;
        this.setState({
            name: this.getDefaultName(),
            tempName: this.getDefaultName()
        })
    }

    getDefaultName() {
        let deviceType = this.props.deviceType;
        switch (deviceType) {
            case SUPPORT_TYPES.drivetrain:
            case SUPPORT_TYPES.vision:
            case SUPPORT_TYPES.speaker:
                return EditUtils.getDeviceDefaultName(this.props.deviceType);
            case SUPPORT_TYPES.motor100:
            case SUPPORT_TYPES.motor300:
                return EditUtils.getDeviceDefaultName(this.props.deviceType) + ((this.state.smart1) ? "-" + this.state.smart1 : "");
            default:
                return EditUtils.getDeviceDefaultName(deviceType) + ((this.state.smart1) ? this.state.smart1 : "") + ((this.state.typeC1) ? this.state.typeC1 : "");
        }
    }

    parsePortArray() {
        let array = [];
        if (this.state.smart1) array.push(this.state.smart1);
        if (this.state.smart2) array.push(this.state.smart2);
        if (this.state.smart3) array.push(this.state.smart3);
        if (this.state.typeC1) array.push(this.state.typeC1);
        return array;
    }

    createAvailableList(request, availableSmart, availableTypeC) {
        let result = [];
        if (!request) return result;
        let availableSmartList = this.createSelectPortOption(request, 1, availableSmart, 'smart', this.state.smart1);
        let secondAvailableSmartList = this.createSelectPortOption(request, 2, availableSmart, 'smart', this.state.smart2);
        let thirdAvailableSmartList = this.createSelectPortOption(request, 3, availableSmart, 'smart', this.state.smart3);
        let availableTypeCList = this.createSelectPortOption(request, 1, availableTypeC, 'typeC', this.state.typeC1);

        if (availableSmartList && availableSmartList.length > 0) result.push({ type: 'smart1', list: EditUtils.isOptionExist(availableSmartList) ? availableSmartList : null })
        if (secondAvailableSmartList && secondAvailableSmartList.length > 0) result.push({ type: 'smart2', list: EditUtils.isOptionExist(secondAvailableSmartList) ? secondAvailableSmartList : null })
        if (thirdAvailableSmartList && thirdAvailableSmartList.length > 0) result.push({ type: 'smart3', list: EditUtils.isOptionExist(thirdAvailableSmartList) ? thirdAvailableSmartList : null })
        if (availableTypeCList && availableTypeCList.length > 0) result.push({ type: 'typeC1', list: EditUtils.isOptionExist(availableTypeCList) ? availableTypeCList : null })
        return result;
    }

    handleClickSelect(selectType) {
        this.handleSaveName();
        this.setState({ expandSelect: (this.state.expandSelect) ? null : selectType })
    }

    handleClickOption(option, port) {
        this.handleSaveName();
        if (option == EditUtils.getLocaleString('gui.deviceManagerStage.page.delete')) {
            this.setState({ [port]: null })
        } else {
            this.setState({ [port]: option })
        }
        this.handleMouseHover(-1);
        if (this.props.isAddPageOpen) {
            setTimeout(() => {
                this.updateDefaultPort();
                this.closeSelect();
            }, 50)
        }
    }

    handleMouseHover(index) {
        this.setState({ hoverPortOption: index });
    }

    createSelectPortOption(request, number, optionList, type, selectedStatus) {
        if (!EditUtils.checkRequest(request, number, type)) return null
        optionList.sort((a, b) => a - b);
        if (number == 3 && selectedStatus && optionList[optionList.length - 1] != EditUtils.getLocaleString('gui.deviceManagerStage.page.delete')) {
            optionList.push(EditUtils.getLocaleString('gui.deviceManagerStage.page.delete'));
        }
        const portImg = (this.props.getUIStyle == uiType.ww) ? smartSmallWWImg : smartSmallGreenImg;
        return optionList.map((location, index) => {
            let isSelected = EditUtils.isOptionSelected(location, type, this.state) || (location == selectedStatus)
            return <div key={index}>
                <div className={classNames(
                    ((isSelected) ? styles.portOptionNoneHover : styles.portOption),
                    (isSelected) ? styles.optionDisable : null)}
                    onClick={() => { isSelected ? {} : this.handleClickOption(location, type + number) }}
                    onMouseEnter={() => isSelected ? {} : this.handleMouseHover(index)}
                    onMouseOut={() => isSelected ? {} : this.handleMouseHover(-1)}>
                    {
                        location == EditUtils.getLocaleString('gui.deviceManagerStage.page.delete') ? null :
                            <div className={classNames(styles.portOptionImg, (isSelected) ? styles.optionDisable : null)}>
                                <img src={(this.state.hoverPortOption == index) ? smartSmallWhiteImg : portImg}
                                    className={classNames(styles.smartPortOptionImgPosition)} />
                            </div>
                    }
                    {location}
                </div>
            </div >
        });
    }

    handleHoverHint(type) {
        this.setState({ hoverWheelHint: type })
    }

    handleClickWheelOption(option) {
        this.setState({ wheelSize: option })
        this.closeSelect();
    }

    handleClickDrivertrainSwitch() {
        if (!this.state.smart1 && !this.state.smart2) return;
        this.setState({
            smart1: this.state.smart2,
            smart2: this.state.smart1,
        })
        this.closeSelect();
        setTimeout(() => {
            this.updateDefaultPort();
        }, 50)
    }

    handleCheckUnit(unit) {
        if (this.state.wheelUnit == unit) return;
        this.closeSelect();
        let converter = (unit == UNIT_OF_LENGTH.cm) ? convertUnitToCM : convertUnitToInch;
        this.setState({
            wheelUnit: unit,
            wheelSize: this.isDefaultWheel(unit, this.state.wheelSize, STATE_INDEX.wheelSize) ? this.convertDefaultWheel(unit, STATE_INDEX.wheelSize) : converter(this.state.wheelSize),
            wheelbaseLR: this.isDefaultWheel(unit, this.state.wheelbaseLR, STATE_INDEX.wheelbaseLR) ? this.convertDefaultWheel(unit, STATE_INDEX.wheelbaseLR) : converter(this.state.wheelbaseLR),
            wheelbaseFB: this.isDefaultWheel(unit, this.state.wheelbaseFB, STATE_INDEX.wheelbaseFB) ? this.convertDefaultWheel(unit, STATE_INDEX.wheelbaseFB) : converter(this.state.wheelbaseFB)
        })
    }

    isDefaultWheel(unit, value, type) {
        return (unit == UNIT_OF_LENGTH.inches) ? value == DEFAULT_MM_LENGTH[type] : value == DEFAULT_INCHES_LENGTH[type]
    }

    convertDefaultWheel(unit, type) {
        return (unit == UNIT_OF_LENGTH.cm) ? DEFAULT_MM_LENGTH[type] : DEFAULT_INCHES_LENGTH[type]
    }

    getSelectImage(type, size) {
        if (type.includes('smart') && this.state[type])
            return size && size == ICON_SIZE.small ? (<img className={classNames(styles.smallSelectSmartImgPosition, styles.smallSelectSmartImgSize)} src={this.isWWVersion() ? smartSmallWWImg : smartSmallGreenImg} />)
                : (<img className={classNames(styles.selectSmartImgPosition, styles.selectSmartImgSize)} src={this.isWWVersion() ? smartSmallWWImg : smartSmallGreenImg} />)
        if (type.includes('typeC') && this.state[type])
            return (<img className={classNames(styles.selectSmartImgPosition, styles.selectSmartImgSize)} src={this.isWWVersion() ? smartSmallWWImg : smartSmallGreenImg} />)
        return null
    }

    getDeviceInfo() {
        let deviceInfo = this.props.vm.getDeviceById(this.props.deviceId)
        let port = {
            smart: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            typeC: ['L', 'R']
        }
        let tempDevice = {
            smart: [],
            typeC: [],
            name: deviceInfo && deviceInfo.name ? deviceInfo.name : EditUtils.getDeviceDefaultName(this.props.deviceType),
            isNameEdited: deviceInfo && deviceInfo.isNameEdited ? deviceInfo.isNameEdited : false,
            other: deviceInfo && deviceInfo.other ? deviceInfo.other : null
        }
        if (deviceInfo && deviceInfo.connectPortArray) {
            for (let i = 0; i < deviceInfo.connectPortArray.length; i++) {
                if (port.smart.includes(deviceInfo.connectPortArray[i])) {
                    tempDevice.smart.push(deviceInfo.connectPortArray[i])
                    continue;
                }
                if (port.typeC.includes(deviceInfo.connectPortArray[i])) {
                    tempDevice.typeC.push(deviceInfo.connectPortArray[i])
                    continue;
                }
            }
        }
        return tempDevice;
    }

    getDefaultPort() {
        let tempPort = {
            smart: [],
            typeC: []
        }
        if (!this.props.vm.getAvailablePortAmount(this.props.brainType)) return tempPort;
        var availableSmart = (this.props.vm.getAvailablePortAmount(this.props.brainType).smart instanceof Array) ? this.props.vm.getAvailablePortAmount(this.props.brainType).smart.sort((a, b) => a - b) : [];
        var availableTypeC = (this.props.vm.getAvailablePortAmount(this.props.brainType).typeC instanceof Array) ? this.props.vm.getAvailablePortAmount(this.props.brainType).typeC.sort((a, b) => a - b) : [];

        const request = this.getDeviceRequest();
        if (!request) return tempPort;
        if (request && request.smart && request.smart > 0 && availableSmart.length >= request.smart) {
            for (let i = 0; i < request.smart; i++) {
                if (availableSmart[i] != this.state.smart1 && availableSmart[i] != this.state.smart2 && availableSmart[i] != this.state.smart3)
                    tempPort.smart.push(availableSmart[i]);
            }
        }
        if (request && request.typeC && request.typeC > 0 && availableTypeC.length >= request.typeC) {
            for (let i = 0; i < request.typeC; i++) {
                if (availableTypeC[i] != this.state.typeC1)
                    tempPort.typeC.push(availableTypeC[i]);
            }
        }
        return tempPort;
    }

    initState() {
        this.updateDefaultPort();
        this.setState({
            smart1: null,
            smart2: null,
            typeC1: null,
            isEditName: false,
            name: EditUtils.getDeviceDefaultName(this.props.deviceType),
            tempName: EditUtils.getDeviceDefaultName(this.props.deviceType)
        })
        if (this.props.deviceType == SUPPORT_TYPES.drivetrain) {
            this.setState({
                path: PATH_TYPE.front,
                wheelSize: DEFAULT_MM_LENGTH.wheelSize,
                smart3: null,
                isCheckedGyro: false,
                wheelUnit: UNIT_OF_LENGTH.cm,
                wheelbaseLR: DEFAULT_MM_LENGTH.wheelbaseLR,
                wheelbaseFB: DEFAULT_MM_LENGTH.wheelbaseFB,
                firstRatioOfWheel: 1,
                secondRatioOfWheel: 1,
            });
        }
        if (this.props.deviceType == SUPPORT_TYPES.motor100 || this.props.deviceType == SUPPORT_TYPES.motor300) {
            this.setState({
                rotation: ROTATION.forward,
                forwardName: EditUtils.getLocaleString(STRING_ID.motorForward),
                reverseName: EditUtils.getLocaleString(STRING_ID.motorReverse),
                tempForwardName: EditUtils.getLocaleString(STRING_ID.motorForward),
                tempReverseName: EditUtils.getLocaleString(STRING_ID.motorReverse)
            });
        }
        if (this.props.deviceType == SUPPORT_TYPES.vision) this.setState({ typeC1: 'L', tempTypeC1: 'L' });
        if (this.props.deviceType == SUPPORT_TYPES.speaker) {
            this.setState({
                typeC1: 'R', tempTypeC1: 'R',
                callWord: DEFAULT_CALL_WORD.zh,
                responseWord: DEFAULT_RESPONSE_WORD,
                isCallEnable: true,
                isResponseEnable: true,
                controlLanguage: mappingLanguage.zh
            });
        }
    }

    updateDefaultPort() {
        let defaultPort = this.getDefaultPort();
        this.setState({
            tempSmart1: (!this.state.smart1 && defaultPort.smart && defaultPort.smart.length > 0) ? defaultPort.smart.shift() : null,
            tempSmart2: (!this.state.smart2 && defaultPort.smart && defaultPort.smart.length > 0) ? defaultPort.smart.shift() : null,
            tempSmart3: (!this.state.smart3 && defaultPort.smart && defaultPort.smart.length > 0) ? defaultPort.smart.shift() : null,
            tempTypeC1: (!this.state.typeC1 && defaultPort.typeC && defaultPort.typeC.length > 0) ? defaultPort.typeC.shift() : null,
        })
    }

    loadState() {
        let defaultPort = this.getDefaultPort();
        let deviceInfo = this.getDeviceInfo();
        let speakerSettings = this.props.vm.getSpeakerSettings();
        let splitRatio = [];
        if (deviceInfo.other && deviceInfo.other.ratioOfGear) splitRatio = deviceInfo.other.ratioOfGear.split(":")
        this.setState({
            name: deviceInfo.name,
            isEditName: deviceInfo.isEditName,
            isNameEdited: (deviceInfo.isNameEdited != null && deviceInfo.isNameEdited != 'undefined')
                ? (deviceInfo.name.includes(EditUtils.getDeviceDefaultName(this.props.deviceType)))
                    ? deviceInfo.isNameEdited : true : false,
            smart1: (deviceInfo.smart && deviceInfo.smart.length > 0) ? deviceInfo.smart.shift() : null,
            smart2: (deviceInfo.smart && deviceInfo.smart.length > 0) ? deviceInfo.smart.shift() : null,
            typeC1: (deviceInfo.typeC && deviceInfo.typeC.length > 0) ? deviceInfo.typeC.shift() : null,
            path: (deviceInfo.other && deviceInfo.other.path) ? deviceInfo.other.path : PATH_TYPE.front,
            wheelSize: (deviceInfo.other && deviceInfo.other.leftSize) ? deviceInfo.other.leftSize : DEFAULT_MM_LENGTH.wheelSize,
            rotation: (deviceInfo.other && deviceInfo.other.rotation) ? deviceInfo.other.rotation : ROTATION.forward,
            smart3: (deviceInfo.other && deviceInfo.other.gyro) ? deviceInfo.other.gyro : null,
            tempSmart3: (!this.state.smart3 && defaultPort.smart && defaultPort.smart.length > 0) ? defaultPort.smart.shift() : null,
            forwardName: (deviceInfo.other && deviceInfo.other.forwardName) ? deviceInfo.other.forwardName : EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.forward'),
            reverseName: (deviceInfo.other && deviceInfo.other.reverseName) ? deviceInfo.other.reverseName : EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.reverse'),
            expandSelect: null,
            tempName: deviceInfo.name,
            tempForwardName: (deviceInfo.other && deviceInfo.other.forwardName) ? deviceInfo.other.forwardName : EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.forward'),
            tempReverseName: (deviceInfo.other && deviceInfo.other.reverseName) ? deviceInfo.other.reverseName : EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.reverse'),
            isCheckedGyro: (deviceInfo.other && deviceInfo.other.gyro) ? true : false,
            wheelUnit: (deviceInfo.other && deviceInfo.other.wheelUnit) ? deviceInfo.other.wheelUnit : UNIT_OF_LENGTH.cm,
            wheelbaseLR: (deviceInfo.other && deviceInfo.other.wheelbaseLR) ? deviceInfo.other.wheelbaseLR : DEFAULT_MM_LENGTH.wheelbaseLR,
            wheelbaseFB: (deviceInfo.other && deviceInfo.other.wheelbaseFB) ? deviceInfo.other.wheelbaseFB : DEFAULT_MM_LENGTH.wheelbaseFB,
            firstRatioOfWheel: (splitRatio.length > 1) ? splitRatio[0] : 1,
            secondRatioOfWheel: (splitRatio.length > 1) ? splitRatio[1] : 1,
            callWord: (speakerSettings && speakerSettings.callWord) ? speakerSettings.callWord : DEFAULT_CALL_WORD.zh,
            responseWord: (speakerSettings && speakerSettings.responseWord) ? speakerSettings.responseWord : DEFAULT_RESPONSE_WORD,
            isCallEnable: (speakerSettings && speakerSettings.isCallEnable !== null && speakerSettings.isCallEnable !== undefined) ? speakerSettings.isCallEnable : true,
            isResponseEnable: (speakerSettings && speakerSettings.isResponseEnable !== null && speakerSettings.isCallEnable !== undefined) ? speakerSettings.isResponseEnable : true,
            controlLanguage: (speakerSettings && speakerSettings.controlLanguage) ? mappingLanguage[speakerSettings.controlLanguage] : mappingLanguage.zh,
            focusInput: null,
            backupTwCallWord: (speakerSettings && speakerSettings.backupTwCallWord) ? speakerSettings.backupTwCallWord : DEFAULT_CALL_WORD.zh,
        })

        if (this.props.deviceType == SUPPORT_TYPES.speaker) {
            this.props.setSkillList((speakerSettings && speakerSettings.skillEnableList) ? Array.from(speakerSettings.skillEnableList) : [])
            this.props.setSkillLocation((speakerSettings && speakerSettings.location) ? this.getCityKeyByValue(speakerSettings.location) : SPEAKER_LOCATION.TAIPEI)
            this.props.setSkillLocation((speakerSettings && speakerSettings.location) ? (speakerSettings.location[SupportLocale.en])
                ? this.getCityKeyByValue(speakerSettings.location[SupportLocale.en]) : this.getCityKeyByValue(speakerSettings.location)
                : SPEAKER_LOCATION.TAIPEI)
        }
    }

    getEditMotorComponent(isEditIndex, tempNameIndex) {
        return (
            (!this.state[isEditIndex]) ?
                <table align="center">
                    <tbody>
                        <tr onClick={() => this.handleClickEdit(isEditIndex)} className={classNames(styles.pointer)}>
                            <td>
                                <div className={classNames(styles.motorName)}>
                                    {this.state[tempNameIndex]}
                                </div>
                            </td>
                            <td>
                                <img src={editImg} className={classNames(styles.editImg)} alt={"edit"} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                : <div className={classNames(styles.inputMotorNameArea, (!this.isNameValid(this.state[tempNameIndex], tempNameIndex)) ? styles.inputNameErrorBorder : null)}>
                    <input type="text"
                        name={tempNameIndex}
                        value={this.state[tempNameIndex]}
                        maxLength="20"
                        onChange={e => this.onNameChange(e, tempNameIndex)}
                        className={classNames(styles.inputMotorName)}
                    />
                </div>
        )
    }

    hintDeviceType() {
        if (!this.state.tempName
            || this.state.tempName.toUpperCase().includes(EditUtils.getDeviceDefaultName(this.props.deviceType, 'en').toUpperCase())
            || this.state.tempName.toUpperCase().includes(EditUtils.getDeviceDefaultName(this.props.deviceType, 'zh-cn').toUpperCase())
            || this.state.tempName.toUpperCase().includes(EditUtils.getDeviceDefaultName(this.props.deviceType, 'zh-tw').toUpperCase())) return null;
        return (<div className={classNames(styles.invalidNameArea, styles.hintDeviceTypeText)}>{EditUtils.getLocaleString(STRING_ID.deviceType) + "：" + EditUtils.getDeviceDefaultName(this.props.deviceType)}</div>)
    }

    componentDidMount() {
        let deviceInfo = this.getDeviceInfo();
        this.setState({
            name: (this.props.deviceType) ? EditUtils.getDeviceDefaultName(this.props.deviceType) : deviceInfo.name,
            tempName: (this.props.deviceType) ? EditUtils.getDeviceDefaultName(this.props.deviceType) : deviceInfo.name,
        })
        if (this.props.isAddPageOpen) this.initState()
        if (this.props.isEditPageOpen) this.loadState()
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.isAddPageOpen) && (this.props.deviceType && (this.props.deviceType !== prevProps.deviceType))) this.initState()
        if ((this.props.isEditPageOpen)
            && ((this.props.portNumber && this.props.portNumber !== prevProps.portNumber) ||
                (this.props.deviceId && this.props.deviceId !== prevProps.deviceId))) this.loadState()
        if (this.state != prevState) {
            let selectedPortArray = this.parsePortArray();
            if (selectedPortArray && selectedPortArray.length > 0) {
                this.props.selectPortOption(selectedPortArray);
            }
        }
        if (this.state.smart1 != prevState.smart1 || this.state.smart2 != prevState.smart2 || this.state.typeC1 != prevState.typeC1) {
            this.setDefaultName();
        }
        if (this.props.isVisionInitiated != prevProps.isVisionInitiated || this.props.isVisionConnected != prevProps.isVisionConnected) {
            this.setState({ isVisionDistribute: this.props.isVisionConnected && this.props.isVisionInitiated })
        }
        if (prevState.controlLanguage != this.state.controlLanguage) {
            if (this.state.controlLanguage == CONTROL_OPTION[0] && this.state.backupTwCallWord) this.setState({ callWord: this.state.backupTwCallWord });
        }
    }

    getSelectedPort(selectType) {
        switch (selectType) {
            case OPTION_TYPE.port1:
            default:
                return this.state.smart1 ? this.state.smart1 : this.state.typeC1 ? this.state.typeC1 :
                    this.state.tempSmart1 ? this.state.tempSmart1 : this.state.tempTypeC1 ? this.state.tempTypeC1 : null
            case OPTION_TYPE.port2:
                return this.state.smart2 ? this.state.smart2 : this.state.tempSmart2 ? this.state.tempSmart2 : null
            case OPTION_TYPE.port3:
                return this.state.smart3 ? this.state.smart3 : this.state.tempSmart3 ? this.state.tempSmart3 : null;
            case OPTION_TYPE.wheelSize:
                return this.state.wheelSize + EditUtils.getLocaleString(STRING_ID.unitOfMM);
        }
    }

    getDeviceRequest() {
        const defaultRequest = { adc: 0, pwm: 0, smart: 0, typeC: 0 };
        return this.props.vm.getRequestPort(this.props.deviceType, this.props.brainType) || defaultRequest;
    }

    render() {
        if (!this.props.isAddPageOpen && !this.props.isEditPageOpen) return null;
        const { intl } = this.props;
        let device = this.props.deviceType;
        var availableSmart = this.props.vm.getAvailablePortAmount(this.props.brainType).smart;
        var availableTypeC = this.props.vm.getAvailablePortAmount(this.props.brainType).typeC;
        var isNameAvailable = this.isNameValid(this.state.tempName, STATE_INDEX.name) && !EditUtils.isNameContainSp(this.state.tempName);
        var enableDone = true;
        var enableDelete = false;
        if (this.props.isEditPageOpen) {
            enableDelete = true;
            enableDone = isNameAvailable;
            let tempPort = this.getDeviceInfo()
            if (tempPort.smart && tempPort.smart.length > 0) { availableSmart = availableSmart.concat(tempPort.smart); availableSmart.sort((a, b) => a - b); }
            if (tempPort.typeC && tempPort.typeC.length > 0) { availableTypeC = availableTypeC.concat(tempPort.typeC); availableTypeC.sort((a, b) => a - b); }
            //TODO check enable done
        }
        if (this.props.isAddPageOpen) {
            enableDone = isNameAvailable && this.checkSavePort();
            if (this.props.isAddPageOpen && (!this.props.vm.isDeviceAvailableToAdd(device, this.props.brainType) || !this.props.vm.isPortEnoughForDeviceToAdd(device, this.props.brainType))) return null;
        }
        if (this.props.deviceType == SUPPORT_TYPES.threeWireMotor) {
            enableDone = enableDone && this.isNameValid(this.state.tempForwardName, STATE_INDEX.forwardName) && this.isNameValid(this.state.tempReverseName, STATE_INDEX.reverseName)
        }
        if (this.props.deviceType == SUPPORT_TYPES.drivetrain && (this.props.brainType == BRAIN_TYPE.ENTRY || this.props.brainType == BRAIN_TYPE.EDU_AND_ENTRY)) {
            enableDone = enableDone && this.state.wheelSize && this.state.firstRatioOfWheel && this.state.secondRatioOfWheel && this.state.wheelbaseFB && this.state.wheelbaseLR && ((this.state.isCheckedGyro) ? (this.state.smart3) : true);
        }
        if (this.props.deviceType == SUPPORT_TYPES.speaker) {
            if (this.state.isCallEnable) {
                enableDone = enableDone && this.isCallWordValid() == CALL_WORD_RULE.VALID
            }
        }
        const request = this.getDeviceRequest();
        var availableListArray = this.createAvailableList(request, availableSmart, availableTypeC);
        const hintNameOverflow = (this.state.isEditName && this.state.tempName.length == 20)
        return (
            <div className={classNames(styles.deviceAddPage, styles.deviceAddPageDiff)} >
                <div className={classNames(styles.otherArea)} onClick={() => this.handleSaveName()} />
                <div className={classNames(styles.nameArea, styles.nameAreaDiff)}>
                    {(!this.state.isEditName)
                        ? <table align="center">
                            <tbody>
                                <tr onClick={() => this.handleClickEdit(STATE_INDEX.isEditName)} className={classNames(styles.pointer)}>
                                    <td>
                                        <div className={classNames(styles.name)}>
                                            {this.state.tempName}
                                        </div>
                                    </td>
                                    <td>
                                        <img src={editImg} className={classNames(styles.editImg)} alt={"edit"} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        : <div className={classNames(styles.inputNameArea,
                            (!isNameAvailable) ?
                                styles.inputNameErrorBorder : (hintNameOverflow) ? styles.inputNameHintBorder : null)}>
                            <input type="text"
                                name="device_name"
                                value={this.state.tempName}
                                maxLength="20"
                                onChange={e => this.onNameChange(e, STATE_INDEX.tempName)}
                                className={classNames(styles.inputName)}
                            />
                        </div>
                    }
                </div>
                {(isNameAvailable) ? (hintNameOverflow) ?
                    <div className={classNames(styles.invalidNameArea)}>
                        <table align="center">
                            <tbody>
                                <tr>
                                    <td>
                                        <img src={exclamationIcon} className={classNames(styles.hintNameImg)} />
                                    </td>
                                    <td className={classNames(styles.invalidNameText)}>
                                        {EditUtils.getLocaleString(STRING_ID.nameOverflow)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    : this.hintDeviceType() :
                    <div className={classNames(styles.invalidNameArea)}>
                        <table align="center">
                            <tbody>
                                <tr>
                                    <td >
                                        <img src={crossIcon} className={classNames(styles.invalidNameImg)} />
                                    </td>
                                    <td className={classNames(styles.invalidNameText)}>
                                        {EditUtils.getNameErrorString(this.getInvalidNameMsg())}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
                {this.state.isEditName ? <div className={classNames(styles.nameCount, (hintNameOverflow) ? styles.nameCountHint : null)}>{`${this.state.tempName.length}/20`}</div> : null}
                <div className={classNames(styles.editArea)}>
                    <div className={classNames(styles.saveArea)} onClick={() => this.handleSaveName()} />
                    {(device != SUPPORT_TYPES.speaker) ?
                        <div className={classNames(styles.text)}>
                            {EditUtils.getSelectMsg(request, this.props.deviceType)}
                        </div>
                        : null}
                    {/* select option area*/}
                    {((request.smart + request.typeC) == 1 && availableListArray.length == 1
                        && (device != SUPPORT_TYPES.vision && device != SUPPORT_TYPES.speaker)) ?
                        <SelectOption
                            styleSelectPosition={styles.oneSelectPosition}
                            styleArrowPosition={styles.onePortArrowPosition}
                            imgPortType={this.getSelectImage(availableListArray[0].type)}
                            styleSelect={styles.onePortSelect}
                            styleSeletedText={styles.portText}
                            handleClickSelect={() => { this.handleClickSelect(OPTION_TYPE.port1) }}
                            isPortSelected={!!this.state.smart1 || !!this.state.typeC1}
                            selectedPort={this.getSelectedPort(OPTION_TYPE.port1)}
                            isSelectExpand={this.isSelectExpand(OPTION_TYPE.port1)}
                            styleOptionArea={styles.portOptionArea}
                            optionList={availableListArray[0].list}
                            enable={!!availableListArray[0].list}
                        /> : null}
                    {/* motor*/}
                    {(device == SUPPORT_TYPES.motor100 || device == SUPPORT_TYPES.motor300) ?
                        <Motor
                            handleClickMotorSwitch={() => this.handleClickMotorSwitch()}
                            getEditMotorComponent={(isEditName, tempName) => this.getEditMotorComponent(isEditName, tempName)}
                            rotation={this.state.rotation}
                            isWWVersion={this.isWWVersion()}
                        /> : null}
                    {/* drivetrain*/}
                    {(device == SUPPORT_TYPES.drivetrain) ?
                        <EntryDriverTrain
                            state={this.state}
                            availableListArray={availableListArray}
                            getSelectImage={(type, size) => this.getSelectImage(type, size)}
                            handleClickSelect={(selectType) => this.handleClickSelect(selectType)}
                            getSelectedPort={(selectType) => this.getSelectedPort(selectType)}
                            isSelectExpand={(selectType) => this.isSelectExpand(selectType)}
                            handleClickPath={(path) => this.handleClickPath(path)}
                            handleCheckGyro={() => this.handleCheckGyro()}
                            handleClickDrivertrainSwitch={() => this.handleClickDrivertrainSwitch()}
                            handleCheckUnit={(unit) => this.handleCheckUnit(unit)}
                            handleHoverHint={(type => { this.handleHoverHint(type) })}
                            onNameChange={(e, tempNameIndex) => this.onNameChange(e, tempNameIndex)}
                        /> : null}
                    {(device == SUPPORT_TYPES.speaker) ?
                        <SmartSpeaker
                            state={this.state}
                            onMethodSwitch={(index) => { this.onMethodSwitch(index) }}
                            onClickSkillSetting={() => { this.props.showSpeakerSkillDialog() }}
                            handleExpandOption={(selectType) => this.handleClickSelect(selectType)}
                            handleSelectOption={(option, index) => { this.handleSelectOption(option, index) }}
                            onNameChange={(e, tempNameIndex) => this.onNameChange(e, tempNameIndex)}
                            systemLanguage={store.get("locale", DEFAULT_LOCALE)}
                            speakerOnList={SPEAKER_ON_OPTION}
                            controlLanguageList={CONTROL_OPTION}
                            handleClickDefault={(index) => this.handleClickDefault(index)}
                            handleClickInput={(index) => this.handleClickInput(index)}
                            isCallWordValid={this.isCallWordValid()}
                            enableSkillList={this.props.getSkillList || []}
                        /> : null}
                </div>
                {/* button area */}
                <div className={classNames(styles.line)} />
                <div className={classNames(styles.buttonArea)}>
                    <div className={classNames(styles.saveArea)} onClick={() => this.handleSaveName()} />
                    {(enableDelete) ? <div className={classNames(styles.buttonDelete, styles.buttonDeleteDiff)}
                        style={{ lineHeight: store.get("locale", DEFAULT_LOCALE) == "en" ? "17px" : "34px" }}
                        onClick={() => this.handleClickDelete()}>
                        {EditUtils.getLocaleString(STRING_ID.delete)}
                    </div> : null}
                    <div className={classNames(styles.buttonCancel, styles.buttonCancelDiff)}
                        onClick={() => this.handleClickCancel()}>
                        {EditUtils.getLocaleString(STRING_ID.cancel)}
                    </div>
                    <div className={classNames(enableDone ? styles.buttonDone : styles.disableButtonDone,
                        enableDone ? styles.buttonDoneDiff : styles.disableButtonDoneDiff)}
                        onClick={() => {
                            (enableDone) ? this.handleClickSave() : null
                        }}>
                        {EditUtils.getLocaleString(STRING_ID.done)}
                    </div>
                </div>
            </div >
        );
    }
}


EntryEditPage.propTypes = {
    isAddPageOpen: PropTypes.bool,
    isEditPageOpen: PropTypes.bool,
    portNumber: PropTypes.string,
    deviceType: PropTypes.string,
    vm: PropTypes.instanceOf(VM).isRequired,
    parsePortArray: PropTypes.func,
    selectPortOption: PropTypes.func,
    deviceId: PropTypes.string,
    brainType: PropTypes.string,
    onProjectChanged: PropTypes.func,
    setVisionTagSetting: PropTypes.func,
};

const mapStateToProps = state => ({
    portNumber: getPortNumber(state),
    isAddPageOpen: isAddPageOpen(state),
    deviceType: getPortDevice(state),
    isEditPageOpen: isEditPageOpen(state),
    deviceId: getPortDeviceId(state),
    getColorDataList: getColorDataList(state),
    isTagInformationEnable: isTagInformationEnable(state),
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state) && isVisionRecongInitiated(state),
    getUIStyle: getUIStyle(state),
    getSkillList: getSkillList(state),
    getSkillLocation: getSkillLocation(state)
});

const mapDispatchToProps = dispatch => ({
    selectPortOption: (portArray) => { dispatch(selectPortOption(portArray)) },
    closeAddPage: () => {
        dispatch(closeDeviceList());
        dispatch(closeAddPage());
        dispatch(cleanPortOption());
        dispatch(closeEditPage());
    },
    onProjectChanged: () => dispatch(setProjectChanged()),
    showVisionTutorialDialog: () => dispatch(showVisionTutorialDialog()),
    hideVisionTutorialDialog: () => dispatch(hideVisionTutorialDialog()),
    showVisionSettingDialog: () => {
        dispatch(hideStreamingDialog())
        dispatch(showVisionSettingDialog())
    },
    hideVisionSettingDialog: () => dispatch(hideVisionSettingDialog()),

    setColorDataList: (list) => dispatch(setColorDataList(list)),
    setTagInformationState: (enable) => dispatch(setTagInformationState(enable)),
    setPortArray: (portArray) => dispatch(setPortArray(portArray)),
    showSpeakerSkillDialog: () => dispatch(showSpeakerSkillDialog()),
    clearSkillList: () => dispatch(clearSkillList()),
    setSkillList: (list) => dispatch(setSkillList(list)),
    setSkillLocation: (location) => dispatch(setSkillLocation(location)),
    clearSkillLocation: () => dispatch(clearSkillLocation()),
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryEditPage));