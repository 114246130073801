import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import dialogStyles from '../dialog.css';
import wifiStyles from './wifi-setting-dialog.css';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import closeIcon from '../svg/close_btn.svg';
import { hideWifiSettingDialog } from '../../../reducers/dialog'
import {
    getWifiList,
    setWifiList,
    getWifiScanResult,
    getConnectedWifi,
    setConnectedWifi,
    getWifiStatus,
    wifiStatus,
    interuptConnectedWifi,
    setWifiScanResult
} from '../../../reducers/wifi-list'
import log from '../../../lib/log.js';

import {
    getBrainInfo
} from '../../../reducers/brain';


import {
    isWifiSettingDialogShow
} from '../../../reducers/dialog';

import {
    WifiAp,
    OtherAp,
    getText
} from './wifiAP.jsx'

const wifiGetResultStatusTime = 3 * 1000;

class WifiSettingDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = this.getInitState();
    }

    getInitState() {
        return {
            wifiScanResult: this.getWifiScanResultAndStatus().concat({ ssid: "Other" }),
            connectedWifi: this.props.getConnectedWifi,
            wifiStatus: this.getFormatWifiStatus(this.props.wifiStatus),
            selectedAp: "",
            expandAp: "",
            apName: "",
            password: "",
            isPasswordVisiable: false,
            editIndex: -1
        }
    }

    componentDidMount() {
        this.setState(this.getInitState());
    }

    componentDidUpdate(prevProps, prevStates) {
        if (prevProps.wifiStatus !== this.props.wifiStatus) {
            this.setState({
                wifiStatus: this.getFormatWifiStatus(this.props.wifiStatus)
            })
        }
        if (prevProps.getWifiList !== this.props.getWifiList ||
            prevProps.getWifiScanResult !== this.props.getWifiScanResult ||
            prevProps.getConnectedWifi !== this.props.getConnectedWifi) {
            this.setState({
                wifiScanResult: this.getWifiScanResultAndStatus().concat({ ssid: "Other" }),
                connectedWifi: this.props.getConnectedWifi,
            })
        }
        if (prevProps.brainInfo.ssn !== this.props.brainInfo.ssn) {
            this.onClose()
        }
        if (prevProps.show !== this.props.show && this.props.show == true) {
            this.setState(this.getInitState());
            this.props.resetWifiScanResult();
            this.props.onClickWifiScan();
            if (!this.timeId) {
                this.timeId = setInterval(() => {
                    log.info('query wifi list status');
                    if (this.props.onWifiScanResult) this.props.onWifiScanResult();
                }, wifiGetResultStatusTime);
            }
        }
        if (prevStates.editIndex !== this.state.editIndex) {
            this.setState({ password: "" })
        }
    }

    getWifiStateString(state) {
        log.info('getWifiStateString: ', state);
        switch (state) {
            case wifiStatus.DISABLED:
                return getText("gui.dialog.wifi.status.disable");
            case wifiStatus.NO_SETTING:
                return getText("gui.dialog.wifi.status.noSetting");
            case wifiStatus.SCANNING:
                return getText("gui.dialog.wifi.status.scanning");
            case wifiStatus.CONNECTING:
                return getText("gui.dialog.wifi.status.connecting");
            case wifiStatus.COMPLETED:
                return getText("gui.dialog.wifi.status.completed");
            case wifiStatus.AUTH_FAIL:
                return getText("gui.dialog.wifi.status.authFail");
            case wifiStatus.LINK_FAIL:
                return getText("gui.dialog.wifi.status.linkFail");
            default:
                return state;
        }
    }

    getFormatWifiStatus(wifiStatus) {
        wifiStatus.stateString = this.getWifiStateString(wifiStatus.state)
        return wifiStatus;
    }

    getWifiScanResultAndStatus() {
        let wifiList = [];
        if (this.props.getWifiScanResult) {
            for (let i = 0; i < this.props.getWifiScanResult.length; i++) {
                let ssid = this.props.getWifiScanResult[i].ssid;
                if (this.props.getWifiList[ssid]) {
                    wifiList.push({
                        ssid: ssid,
                        state: this.props.getWifiList[ssid],
                        stateString: this.getWifiStateString(this.props.getWifiList[ssid])
                    })
                } else {
                    wifiList.push({
                        ssid: ssid
                    })
                }
            }
        }
        console.log('getWifiScanResultAndStatus ', wifiList)
        return wifiList;
    }

    onClickWifiAp(index) {
        this.setState({ selectedAp: this.state.wifiScanResult[index].ssid })
    }

    onClickExpandWifiSetting(index) {
        this.setState({
            expandAp: this.state.wifiScanResult[index] && this.state.wifiScanResult[index].ssid ? this.state.wifiScanResult[index].ssid : "Other",
            editIndex: index, isPasswordVisiable: false
        })
    }

    switchPasswordVisiable() {
        this.setState({ isPasswordVisiable: !this.state.isPasswordVisiable })
    }

    onApNameChange(event) {
        let input = event.target.value
        this.setState({
            apName: input
        })
    }

    onPasswordChange(event) {
        let input = event.target.value
        let regSp = /[(\")]/;
        if (regSp.test(input)) return;
        this.setState({
            password: input
        })
    }

    onClickCancel() {
        this.setState({
            expandAp: "",
            apName: "",
            password: "",
            editIndex: -1
        })
    }

    getApNameFromIndex(index) {
        let apName = ""
        if (index == this.state.wifiScanResult.length - 1) {
            apName = this.state.apName
            this.props.setWifiList(this.props.getWifiScanResult.concat({ ssid: apName }))
        } else {
            apName = this.state.wifiScanResult[index].ssid
        }
        return apName;
    }

    onClickConfirm(index) {
        // connect wifi by index, name and password
        let apName = this.getApNameFromIndex(index);
        this.props.setConnectedWifi(apName);

        this.props.onClickAddWifi(apName, this.state.password);
        this.props.onClickSelectWifi(apName);
        this.onClickCancel();
    }

    onClickInteruptWifi(index) {
        // disconnect wifi by index and name
        let apName = this.getApNameFromIndex(index);
        this.props.onClickDeleteWifi(apName);
        this.props.interuptConnectedWifi();
    }

    getCheckHintTitleMsg() {
        if (this.state.wifiScanResult.length == 1 && this.state.wifiScanResult[0].ssid == "Other") {
            if (this.state.wifiStatus.state != wifiStatus.SCANNING) {
                //Not exists ap
                return (
                    <div className={classNames(wifiStyles.wifiSettingHint)}>
                        <FormattedMessage
                            defaultMessage="If you cannot find the wifi, please go to the system wifi to search again or enter another wifi"
                            description="Wifi Setting dialog hint"
                            id="gui.dialog.wifi.empty.hint"
                        />
                    </div>
                )
            } else {
                // Scaning
                return (
                    <div className={classNames(wifiStyles.wifiSettingHint)}>
                        <FormattedMessage
                            defaultMessage="Wifi Scaning"
                            description="Wifi Scaning"
                            id="gui.dialog.wifi.scaning"
                        />
                    </div>
                )
            }
        }
    }


    getWifiListLayout() {
        let wifiScanResult = this.state.wifiScanResult;
        log.info('getWifiListLayout connectedWifi: ', this.state.connectedWifi);
        log.info('getWifiListLayout wifiScanResult: ', this.state.wifiScanResult);
        log.info('getWifiListLayout wifiStatus: ', this.state.wifiStatus);

        let listLayout = [];
        listLayout = wifiScanResult.map((ap, index) => <div key={index}>
            {ap.ssid !== "Other" ?
                <WifiAp
                    isWifiSelected={this.state.selectedAp == ap.ssid}
                    isWifiExpand={this.state.expandAp == ap.ssid}
                    onClickExpandWifiSetting={() => this.onClickExpandWifiSetting(index)}
                    onClickWifiAp={() => this.onClickWifiAp(index)}
                    ap={ap}
                    ssid={ap.ssid}
                    index={index}
                    password={this.state.password}
                    wifiStatus={this.state.wifiStatus}
                    isWifiConnected={this.state.connectedWifi == ap.ssid}
                    onClickCancel={() => this.onClickCancel()}
                    onClickConfirm={() => this.onClickConfirm(index)}
                    onPasswordChange={e => this.onPasswordChange(e)}
                    onClickInteruptWifi={() => this.onClickInteruptWifi(index)}
                    switchPasswordVisiable={() => this.switchPasswordVisiable()}
                    isPasswordVisiable={this.state.isPasswordVisiable}
                    haspw={false}
                    hideConnectButton={false}
                />
                :
                <OtherAp
                    isWifiSelected={this.state.selectedAp == ap.ssid}
                    isWifiExpand={this.state.expandAp == ap.ssid}
                    onClickExpandWifiSetting={() => this.onClickExpandWifiSetting(index)}
                    onClickWifiAp={() => this.onClickWifiAp(index)}
                    ap={ap}
                    ssid={this.state.apName}
                    index={index}
                    password={this.state.password}
                    wifiStatus={this.state.wifiStatus}
                    isWifiConnected={this.state.connectedWifi == ap.ssid}
                    onClickCancel={() => this.onClickCancel()}
                    onClickConfirm={() => this.onClickConfirm(index)}
                    onApNameChange={(e) => this.onApNameChange(e)}
                    onPasswordChange={(e) => this.onPasswordChange(e)}
                    onClickInteruptWifi={() => this.onClickInteruptWifi(index)}
                    switchPasswordVisiable={() => this.switchPasswordVisiable()}
                    isPasswordVisiable={this.state.isPasswordVisiable}
                    haspw={false}
                />}
        </div>)
        return listLayout;
    }


    onClose() {
        clearInterval(this.timeId);
        this.timeId = null;
        this.props.hideWifiSettingDialog();
    }

    render() {
        // Render nothing if the "show" prop is false
        if (!this.props.show) {
            return null;
        }
        return (
            <div className={dialogStyles.backdropStyle}>
                <div className={classNames(wifiStyles.modalStyle,
                    (this.props.isBrainConnected) ? dialogStyles.connected : null)}>
                    <div className={dialogStyles.aboutHeader}>
                        <div className={dialogStyles.aboutTitle}>
                            <FormattedMessage
                                defaultMessage="Wifi Setting"
                                description="Wifi Setting dialog title"
                                id="gui.dialog.wifi.title"
                            />
                        </div>
                        <div className={dialogStyles.aboutClose} onClick={() => this.onClose()}>
                            <img src={closeIcon} />
                        </div>
                    </div>
                    <div className={classNames(wifiStyles.wifiSettingArea)}>
                        <div className={classNames(wifiStyles.wifiSettingDivide)} />
                        <div className={classNames(wifiStyles.wifiApListArea)}>

                            {this.getCheckHintTitleMsg()}
                            {this.getWifiListLayout()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

WifiSettingDialog.propTypes = {
    isBrainConnected: PropTypes.bool,
    show: PropTypes.bool,
    onClickWifiStatus: PropTypes.func,
    onClickAddWifi: PropTypes.func,
    onClickSwitchWifi: PropTypes.func,
    onClickWifiList: PropTypes.func,
    onClickDeleteWifi: PropTypes.func,
    onClickSelectWifi: PropTypes.func,
    onClickWifiScan: PropTypes.func
};

WifiSettingDialog.defaultProps = {
    isBrainConnected: false,
    show: false
};

const mapStateToProps = state => ({
    brainInfo: getBrainInfo(state),
    getConnectedWifi: getConnectedWifi(state),
    getWifiList: getWifiList(state),
    wifiStatus: getWifiStatus(state),
    getWifiScanResult: getWifiScanResult(state),
    isWifiSettingDialogShow: isWifiSettingDialogShow(state),
});

const mapDispatchToProps = dispatch => ({
    hideWifiSettingDialog: () => dispatch(hideWifiSettingDialog()),
    interuptConnectedWifi: () => dispatch(interuptConnectedWifi()),
    setConnectedWifi: (apName) => dispatch(setConnectedWifi(apName)),
    setWifiList: (list) => dispatch(setWifiList(list)),
    resetWifiScanResult: () => dispatch(setWifiScanResult([]))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WifiSettingDialog)