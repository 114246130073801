
const store = require('store');
const { editorMsgsByAsus } = require('../../../locales/editor-msgs.js');
const { imgAltMsgsPlusPlatformByAsus, imgAltMsgsByAsus } = require('../../../locales/img-alt-msgs.js');
const { DEFAULT_LOCALE, PROJECT_STYLE } = require('../../../config/project-config.js');

const NAME_REQUIRED = "NAME_REQUIRED";
const SYMBOLS_NOT_ALLOWED = "SYMBOLS_NOT_ALLOWED";
const LETTER_REQUIRED = "LETTER_REQUIRED";
const NAME_DUPLICATE = "NAME_DUPLICATE";
const NAME_AVAILABLE = "NAME_AVAILABLE";

const DEFAULT_MM_LENGTH = {
    wheelSize: 6.3,
    wheelbaseLR: 16.3,
    wheelbaseFB: 11.2
}

const DEFAULT_INCHES_LENGTH = {
    wheelSize: 2.48,
    wheelbaseLR: 6.42,
    wheelbaseFB: 4.4
}

const SPEAKER_LOCATION = {
    TAIPEI: "Taipei",
    NEW_TAIPEI: "New_Taipei",
    TAOYUAN: "Taoyuan",
    TAICHUNG: "Taichung",
    TAINAN: "Tainan",
    KAOHSIUNG: "Kaohsiung",
}

const SPEAKER_ON_OPTION = ['2秒', '5秒', '10秒', "直到停止說話"]

const CONTROL_OPTION = ['中文', 'English']

const mappingLanguage = {
    '中文': 'zh',
    'English': 'en',
    'zh': '中文',
    'en': 'English',
}

const mappingSpeakerOnArray = {
    "2": 0,
    "5": 1,
    "10": 2,
    "-1": 3,
}


const OPTION_TYPE = {
    port1: "port1",
    port2: "port2",
    port3: "port3",
    wheelSize: "wheelSize",
    speakerOn: "speakerOn",
    controlLanguage: "controlLanguage",

}

const STRING_ID = {
    motor100: "gui.device.motor100",
    motor300: "gui.device.motor300",
    colorSensor: "gui.device.colorSensor",
    touchLed: "gui.device.touchLed",
    motor120: "gui.device.motor100",
    motor360: "gui.device.motor300",
    color: "gui.device.colorSensor",
    touch_led: "gui.device.touchLed",
    gyro: "gui.device.gyro",
    vision: "gui.device.vision",
    speaker: "gui.device.speaker",
    drivetrain: "gui.device.drivetrain",
    normal: "gui.deviceManagerStage.edit.normal",
    reverse: "gui.deviceManagerStage.edit.reverse",
    bumper: "gui.device.bumper",
    led: "gui.device.led",
    motor: "gui.device.motor",
    threeWireMotor: "gui.device.threeWireMotor",
    lineTracker: "gui.device.lineTracker",
    buzzer: "gui.device.buzzer",
    ultrasonic: "gui.device.ultrasonic",
    motorForward: "gui.deviceManagerStage.page.motor.forward",
    motorReverse: "gui.deviceManagerStage.page.motor.reverse",
    nameRequired: "gui.device.name.rule.required",
    nameDuplicate: "gui.device.name.rule.duplicate",
    nameOverflow: "gui.device.name.rule.nameOverflow",
    symbolsNotAllowed: "gui.device.name.rule.symbol",
    invalidName: "gui.deviceManagerStage.page.invalidName",
    selectPort: "gui.deviceManagerStage.page.selectPort",
    selectTwoPorts: "gui.deviceManagerStage.page.selectTwoPorts",
    selectThreePorts: "gui.deviceManagerStage.page.selectThreePorts",
    selectDrivetrainMotorPorts: "gui.deviceManagerStage.page.selectDrivetrainMotorPorts",
    selectDrivetrainGryo: "gui.deviceManagerStage.page.selectDrivetrainGryo",
    cancel: "gui.deviceManagerStage.page.cancel",
    done: "gui.deviceManagerStage.page.done",
    delete: "gui.deviceManagerStage.page.deleteDevice",
    wheelSize: "gui.deviceManagerStage.page.wheelSize",
    motorText: "gui.deviceManagerStage.page.motorText",
    deviceType: "gui.deviceManagerStage.page.deviceType",
    visionHint: "gui.deviceManagerStage.page.vision.hint",
    speakerHint: "gui.deviceManagerStage.page.speaker.hint",
    drivertrainSetting: "gui.deviceManagerStage.page.drivertrain.setting.title",
    editWheelSize: "gui.deviceManagerStage.page.drivertrain.wheel.size",
    gearRatio: "gui.deviceManagerStage.page.drivertrain.gear.ratio",
    wheelbaseLR: "gui.deviceManagerStage.page.drivertrain.wheelbase.left.right",
    wheelbaseFB: "gui.deviceManagerStage.page.drivertrain.wheelbase.fornt.back",
    unitOfMM: "gui.deviceManagerStage.page.drivertrain.unit.mm",
    unitOfInch: "gui.deviceManagerStage.page.drivertrain.unit.inch",
}

const SUPPORT_TYPES = {
    lineTracker: 'lineTracker',
    buzzer: 'buzzer',
    led: 'led',
    ultrasonic: 'ultrasonic',
    bumper: 'bumper',
    drivetrain: 'drivetrain',
    motor100: 'motor100',
    motor300: 'motor300',
    colorSensor: 'colorSensor',
    touchLed: 'touchLed',
    gyro: 'gyro',
    vision: 'vision',
    speaker: 'speaker',
    motor: 'motor',
    threeWireMotor: 'threeWireMotor',
    motor120: 'motor120',
    motor360: 'motor360',
    color: 'color',
    touch_led: 'touch_led',
}

const DEVICE_NUMBER = {
    lineTracker: '[T1]',
    buzzer: '[S1]',
    led: '[L1]',
    ultrasonic: '[D1]',
    bumper: '[O1]',
    drivetrain: '',
    motor100: '[M2]',
    motor300: '[M3]',
    colorSensor: '[C1]',
    touchLed: '[L2]',
    gyro: '[G1]',
    vision: '[V1]',
    speaker: '[S1]',
    motor: '[M1]',
    threeWireMotor: '[M1]',
    motor120: '[M2]',
    motor360: '[M3]',
    color: '[C1]',
    touch_led: '[L2]',
}

const PATH_TYPE = {
    front: "front",
    back: "back"
}


const ROTATION = {
    forward: "forward",
    reverse: "reverse"
}

const STATE_INDEX = {
    name: 'name',
    forwardName: 'forwardName',
    reverseName: 'reverseName',
    tempName: 'tempName',
    tempForwardName: 'tempForwardName',
    tempReverseName: 'tempReverseName',
    isEditName: 'isEditName',
    isEditForwardName: 'isEditForwardName',
    isEditReverseName: 'isEditReverseName',
    isNameEdited: 'isNameEdited',
    wheelSize: 'wheelSize',
    wheelbaseFB: 'wheelbaseFB',
    wheelbaseLR: 'wheelbaseLR',
    firstRatioOfWheel: 'firstRatioOfWheel',
    secondRatioOfWheel: 'secondRatioOfWheel',
    callWord: "callWord",
    responseWord: "responseWord",
    isCallEnable: "isCallEnable",
    isResponseEnable: "isResponseEnable",
    isSkillEnable: "isSkillEnable",
    controlLanguage: "controlLanguage",
}

const PORT_TYPE = {
    smart: 'smart',
    typeC: 'typeC',
    adc: 'adc',
    pwm: 'pwm'
}

const HINT_WHEELBASE = {
    LeftRight: 'left_and_right',
    FrontBack: 'front_and_back'
}

const UNIT_OF_LENGTH = {
    cm: 'cm',
    inches: 'inches'
}

class EditUtils {


    static checkRequest(request, number, type) {
        switch (type) {
            case PORT_TYPE.smart:
                if (number == 1 && !(request && request.smart && request.smart > 0)) return false
                if (number == 2 && !(request && request.smart && request.smart > 1)) return false
                if (number == 3 && !(request && request.smart && request.smart > 2)) return false
                break;
            case PORT_TYPE.typeC:
                if (number == 1 && !(request && request.typeC && request.typeC > 0)) return false
                break;
            case PORT_TYPE.adc:
                if (number == 1 && !(request && request.adc && request.adc > 0)) return false
                if (number == 2 && !(request && request.adc && request.adc > 1)) return false
                if (number == 3 && !(request && request.adc && request.adc > 2)) return false
                break;
            case PORT_TYPE.pwm:
                if (number == 1 && !(request && request.pwm && request.pwm > 0)) return false
                if (number == 2 && !(request && request.pwm && request.pwm > 1)) return false
                break;
            default:
                break;
        }
        return true;
    }

    static getSelectMsg(request, deviceType) {
        let count = 0;
        for (var key in request) {
            count += request[key];
        }
        if (deviceType == SUPPORT_TYPES.vision) return this.getLocaleString(STRING_ID.visionHint)
        if (deviceType == SUPPORT_TYPES.speaker) return this.getLocaleString(STRING_ID.speakerHint)
        if (deviceType == SUPPORT_TYPES.drivetrain) return this.getLocaleString(STRING_ID.selectDrivetrainMotorPorts)
        if (count == 1) return this.getLocaleString(STRING_ID.selectPort)
        if (count == 2) return this.getLocaleString(STRING_ID.selectTwoPorts)
        if (count == 3) return this.getLocaleString(STRING_ID.selectThreePorts)
        return null
    }

    static isOptionExist(optionList) {
        if (!optionList) return false;
        let hasOption = false;
        for (let i = 0; i < optionList.length; i++) {
            if (optionList[i]) {
                hasOption = true;
                break;
            }
        }
        return hasOption;
    }

    static isOptionSelected(location, type, state) {
        switch (type) {
            case PORT_TYPE.smart:
                return location == state.smart1 || location == state.smart2 || location == state.smart3;
            case PORT_TYPE.typeC:
                return location == state.typeC1;
            case PORT_TYPE.adc:
                return location == state.adc1 || location == state.adc2 || location == state.adc3
            case PORT_TYPE.pwm:
                return location == state.pwm1 || location == state.pwm2;
            default:
                return false;
        }
    }

    static isRotationNameDuplicate(name, nameIndex, state) {
        let duplicate = false;
        if (nameIndex && nameIndex == (STATE_INDEX.tempForwardName)) {
            duplicate = name == (state.reverseName);
        } else if (nameIndex && nameIndex == (STATE_INDEX.tempReverseName)) {
            duplicate = name == (state.forwardName);
        }
        return duplicate
    }

    static isNameValid(name) {
        if (!name) return false;
        name = name.replace(/^\s*/, "");
        if (name.length === 0) return false;
        let valid = true
        let regSp = /[\\\|\/\?"*\:<>\.]/;
        for (let i = 0; i < name.length; i++) {
            if (regSp.test(name[i])) return false;
        }
        return valid
    }

    static getEnString(messageId) {
        return editorMsgsByAsus['en'][messageId];
    }

    static getCnString(messageId) {
        return editorMsgsByAsus['zh-cn'][messageId];
    }

    static getTwString(messageId) {
        return editorMsgsByAsus['zh-tw'][messageId];
    }

    static getLocaleString(messageId) {
        let language = store.get("locale", DEFAULT_LOCALE);
        return editorMsgsByAsus[language][messageId];
    }

    static getImgAltPlusPlatformLocaleString(messageId) {
        let language = store.get("locale", DEFAULT_LOCALE);
        return imgAltMsgsPlusPlatformByAsus[PROJECT_STYLE][language][messageId];
    }

    static getImgAltLocaleString(messageId) {
        let language = store.get("locale", DEFAULT_LOCALE);
        return imgAltMsgsByAsus[language][messageId];
    }

    static getDeviceDefaultName(deviceType, language) {
        let defaultName = deviceType
        if (!language) {
            defaultName = this.getLocaleString(STRING_ID[deviceType]);
        } else {
            defaultName = editorMsgsByAsus[language][STRING_ID[deviceType]];
        }
        if (deviceType.toUpperCase().includes(SUPPORT_TYPES.motor.toUpperCase())) {
            defaultName = this.getMotorDefaultName(deviceType, defaultName)
        }
        return defaultName
    }

    static getMotorDefaultName(deviceType, defaultName) {
        let language = store.get("locale", DEFAULT_LOCALE);
        if (language == 'en') {
            defaultName = '-' + defaultName;
        }
        if (deviceType == SUPPORT_TYPES.threeWireMotor) {
            defaultName = "M1" + defaultName
        }
        if (deviceType == SUPPORT_TYPES.motor100 || deviceType == SUPPORT_TYPES.motor120) {
            defaultName = "M2" + defaultName
        }
        if (deviceType == SUPPORT_TYPES.motor300 || deviceType == SUPPORT_TYPES.motor360) {
            defaultName = "M3" + defaultName
        }
        return defaultName;
    }

    static getNameErrorString(message) {
        switch (message) {
            case NAME_REQUIRED:
                return this.getLocaleString(STRING_ID.nameRequired);
            case NAME_DUPLICATE:
                return this.getLocaleString(STRING_ID.nameDuplicate);
            case SYMBOLS_NOT_ALLOWED:
                return this.getLocaleString(STRING_ID.symbolsNotAllowed);
            default:
                return this.getLocaleString(STRING_ID.invalidName);
        }
    }

    static getDeviceNumber(deviceType) {
        return DEVICE_NUMBER[deviceType]
    }

    static isNameContainSp(word) {
        // check only letter, number and down line
        if (!word || word == "") return false
        let invalid = false;
        let spReg = /^[A-Za-z0-9\u4e00-\u9fa5\-\ ]$/;
        for (let i = 0; i < word.length; i++) {
            if (!spReg.test(word[i])) {
                invalid = true;
            }
        }
        return invalid;
    }

    static isNameContainSpExcludeSpace(word) {
        // check only letter, number and down line
        if (!word || word == "") return false
        let invalid = false;
        let spReg = /^[A-Za-z0-9\u4e00-\u9fa5\-]$/;
        for (let i = 0; i < word.length; i++) {
            if (!spReg.test(word[i])) {
                invalid = true;
            }
        }
        return invalid;
    }

    static getTextWidth(text, fontSize) {
        // re-use canvas object for better performance
        var canvas = (!document.getElementById("canvas")) ? document.createElement("canvas") : document.getElementById("canvas");
        var context = canvas.getContext("2d");
        context.font = `${fontSize}px Roboto`;
        var metrics = context.measureText(text);
        return metrics.width;
    }
}

const DEFAULT_CALL_WORD = {
    zh: "拼寶拼寶",
    en: "Hey! PINBO!"
}

const DEFAULT_RESPONSE_WORD = "是"

module.exports.EditUtils = EditUtils;
module.exports.STRING_ID = STRING_ID;
module.exports.STATE_INDEX = STATE_INDEX;
module.exports.PATH_TYPE = PATH_TYPE;
module.exports.SUPPORT_TYPES = SUPPORT_TYPES;
module.exports.ROTATION = ROTATION;
module.exports.OPTION_TYPE = OPTION_TYPE;
module.exports.HINT_WHEELBASE = HINT_WHEELBASE;
module.exports.UNIT_OF_LENGTH = UNIT_OF_LENGTH;
module.exports.NAME_AVAILABLE = NAME_AVAILABLE;
module.exports.DEFAULT_MM_LENGTH = DEFAULT_MM_LENGTH;
module.exports.DEFAULT_INCHES_LENGTH = DEFAULT_INCHES_LENGTH;
module.exports.SPEAKER_LOCATION = SPEAKER_LOCATION;
module.exports.SPEAKER_ON_OPTION = SPEAKER_ON_OPTION;
module.exports.CONTROL_OPTION = CONTROL_OPTION;
module.exports.mappingLanguage = mappingLanguage;
module.exports.mappingSpeakerOnArray = mappingSpeakerOnArray;
module.exports.DEFAULT_CALL_WORD = DEFAULT_CALL_WORD;
module.exports.DEFAULT_RESPONSE_WORD = DEFAULT_RESPONSE_WORD;
module.exports.SYMBOLS_NOT_ALLOWED = SYMBOLS_NOT_ALLOWED;