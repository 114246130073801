import {
    EditUtils
} from '../components/device-manager/edit-page/edit-utils.js';

const DEVICE_INFO = {
    drivetrainInfo: {
        hasDrivetrain: false,
        hasDrivetrainwithGyro: false,
        drivetrainName: getDefaultName()
    },
    controllerInfo: {
        hasController: false,
    },

    // EDU
    threeWireMotorInfo: {
        hasThreeWireMotor: false,
        threeWireMotorName: getDefaultName()
    },
    lineTrackerInfo: {
        haslineTracker: false,
        lineTrackerName: getDefaultName()
    },
    buzzerInfo: {
        hasbuzzer: false,
        buzzerName: getDefaultName()
    },
    ledInfo: {
        hasled: false,
        ledName: getDefaultName()
    },
    ultrasonicInfo: {
        hasultrasonic: false,
        ultrasonicName: getDefaultName()
    },
    bumperInfo: {
        hasbumper: false,
        bumperName: getDefaultName()
    },

    // Entry
    motorInfo: {
        hasMotor: false,
        motorName: getDefaultName()
    },
    lightSensorInfo: {
        haslightSensor: false,
        lightSensorName: getDefaultName()
    },
    touchLedInfo: {
        hastouchLed: false,
        touchLedName: getDefaultName()
    },
    gyroInfo: {
        hasgyro: false,
        gyroName: getDefaultName()
    },
    colorSensorInfo: {
        hascolorSensor: false,
        colorSensorName: getDefaultName()
    },
    speakerInfo: {
        hasspeaker: false,
        speakerName: getDefaultName()
    },
    visionInfo: {
        hasvision: false,
        visionName: getDefaultName()
    }
};

function getDefaultName() {
    return EditUtils.getLocaleString("gui.device.no.device");
}

export {
    DEVICE_INFO
}