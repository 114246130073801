export default {
    "zh-tw": {
        "joystick":"搖桿控制",
        "button":"按鍵",
        "Up":"上",
        "Down":"下",
        "Left":"左",
        "Right":"右",
        "X":"X",
        "Y":"Y",
        "A":"A",
        "B":"B",
        "L1":"L1",
        "R1":"R1",
        "L2":"L2",
        "R2":"R2",
        "forward":"Forward",
        "reverse":"Reverse",
        "leftDrive":"左驅動",
        "rightDrive":"右驅動",
        "tankDrive":"坦克驅動",
        "directDrive":"分別驅動",
        "motor":"馬達",
        "drivetrain":"底盤動力系統",
        "controller1":"遙控器1(主)",
        "controller2":"遙控器2(副)",
        "newController1":"新增遙控器1(主)",
        "newController2":"新增遙控器2(副)",
        "delete":"無"
    },
    "en": {
        "joystick":"Joystick controll",
        "button":"Button",
        "Up":"Up",
        "Down":"Down",
        "Left":"Left",
        "Right":"Right",
        "X":"X",
        "Y":"Y",
        "A":"A",
        "B":"B",
        "L1":"L1",
        "R1":"R1",
        "L2":"L2",
        "R2":"R2",
        "forward":"Forward",
        "reverse":"Reverse",
        "leftDrive":"Left Drive",
        "rightDrive":"Right Drive",
        "tankDrive":"Tank Drive",
        "directDrive":"Direct Drive",
        "motor":"Motor",
        "drivetrain":"Power Train",
        "controller1":"Controller 1(Main)",
        "controller2":"Controller 2(Minor)",
        "newController1":"New Controller 1(Main)",
        "newController2":"New Controller 2(Minor)",
        "delete":"None"
    }, 
    "zh-cn": {
        "joystick":"摇杆控制",
        "button":"按键",
        "Up":"上",
        "Down":"下",
        "Left":"左",
        "Right":"右",
        "X":"X",
        "Y":"Y",
        "A":"A",
        "B":"B",
        "L1":"L1",
        "R1":"R1",
        "L2":"L2",
        "R2":"R2",
        "forward":"Forward",
        "reverse":"Reverse",
        "leftDrive":"左驱动",
        "rightDrive":"右驱动",
        "tankDrive":"坦克驱动",
        "directDrive":"分別驱动",
        "motor":"电机",
        "drivetrain":"动力传动系统",
        "controller1":"手柄1(主)",
        "controller2":"手柄2(副)",
        "newController1":"添加手柄1(主)",
        "newController2":"添加手柄2(副)",
        "delete":"無"
    }
}