import looksBlocks from '../block-vertical-blocks/looks';

export default function looksEDUandEntry(deviceInfo, blockSeparator) {

    return `
        <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
            ${looksBlocks.looks_print}
            ${blockSeparator}
            ${looksBlocks.looks_setcursortorow}
            ${looksBlocks.looks_nextrow}
            ${looksBlocks.looks_setprecisionto}
            ${blockSeparator}
            ${looksBlocks.looks_clearallrows}
            ${looksBlocks.looks_clearrow}
            ${deviceInfo.ledInfo.hasled ? `
                ${looksBlocks.looks_setlight}
            `: ``}
            ${deviceInfo.touchLedInfo.hastouchLed ? `
                ${blockSeparator}
                ${looksBlocks.looks_setcolorto}
                ${looksBlocks.looks_setcolortorgb}
                ${looksBlocks.looks_setfadeto}
                ${looksBlocks.looks_setbrightnessto}
            `: ``}
            ${deviceInfo.colorSensorInfo.hascolorSensor ? `
                ${blockSeparator}
                ${looksBlocks.looks_colorsetlightto}
            `: ``}
        </category>
    `;
};