import classNames from 'classnames';
import React from 'react';
import visionSettingStyles from './vision-setting-dialog.css';
import { EditUtils } from '../../device-manager/edit-page/edit-utils.js';
import { VISION_IMAGE_MODE } from './vision-utils.js';

const previewWidth = 640;
const previewHeight = 480;

const takePhotoWidth = 320;
const takePhotoHeight = 240;

const snapshotWidth = 303;
const snapshotHeight = 256;

export const TAG_TYPE = {
    color: "color",
    Face: "Face",
    QR_code: "QR_code",
    OCR: "OCR",
    FaceRecognition: "FaceRecognition",
}

export const DetectedColorTag = props => {
    if (props.detectedTag.type && props.detectedTag.type !== TAG_TYPE.color) return null;
    const tagNameHeight = 17;
    const indexLength = props.enableTag ? 12 : 6;
    const tagDevide = 4;
    let tag = parseTag(props.detectedTag);
    const originTagSize = backupOriginData(tag);
    tag = scaleUpTagSize(tag);
    return (
        <div style={{
            marginLeft: tag.x + 'px',
            marginTop: tag.y - tagNameHeight + 'px',
            position: 'absolute'
        }}>
            <div className={classNames(visionSettingStyles.relativeArea)}>
                {/* tag Name */}
                {props.enableTag ? <div className={classNames(visionSettingStyles.tagName)} style={{ width: tag.width + 'px' }}>{(tag.tag) ? tag.tag : null}</div> : null}
                {/* tag Number */}
                <div className={classNames(visionSettingStyles.tagNumber)}
                    style={{ marginTop: tagNameHeight + tag.height - indexLength + 'px', marginLeft: tag.width - indexLength + 'px' }}>
                    {(props.index) ? props.index : null}</div>
                {/* tag Rectangle */}
                <div className={classNames(visionSettingStyles.tagOriginRectangle)}
                    style={{ width: tag.width + 'px', height: tag.height + 'px', marginTop: tagNameHeight + 'px', marginLeft: 0 + 'px' }} />
                {/* tag info area */}
                {props.enableTag ? <div className={classNames(visionSettingStyles.tagInfo)}
                    style={{
                        marginTop: tagNameHeight + tag.height + tagDevide + 'px', marginLeft: 0 + 'px'
                    }}>
                    <div className={classNames(visionSettingStyles.tagText)}>{'Width:' + originTagSize.width}</div>
                    <div className={classNames(visionSettingStyles.tagText)}>{'Height:' + originTagSize.height}</div>
                    <div className={classNames(visionSettingStyles.tagText)}>{'CX:' + originTagSize.center_x}</div>
                    <div className={classNames(visionSettingStyles.tagText)}>{'CY:' + originTagSize.center_y}</div>
                </div> : null}

            </div>
        </div>
    )
}

export const DetectedQRcodeTag = props => {
    if (props.detectedTag.type && props.detectedTag.type !== TAG_TYPE.QR_code) return null;
    const tagNameHeight = 17;
    const tagDevide = 4;
    let tag = parseTag(props.detectedTag);
    const msg = props.detectedTag.message;
    const size = parseFixedToFloat(props.detectedTag.width * props.detectedTag.height, 0);
    tag = scaleUpTagSize(tag);
    return (
        <div style={{
            marginLeft: tag.x + 'px',
            marginTop: tag.y - tagNameHeight + 'px',
            position: 'absolute'
        }}>
            <div className={classNames(visionSettingStyles.relativeArea)}>
                {/* tag Rectangle */}
                <div className={classNames(visionSettingStyles.tagRectangle, (size.toString() != props.mainSize.toString()) ? visionSettingStyles.minorTag : null)}
                    style={{ width: tag.width + 'px', height: tag.height + 'px', marginTop: tagNameHeight + 'px', marginLeft: 0 + 'px' }} />
                {/* tag info area */}
                {(size.toString() != props.mainSize.toString()) ? null :
                    props.enableTag ? <div className={classNames(visionSettingStyles.tagInfo)}
                        style={{ marginTop: tagNameHeight + tag.height + tagDevide + 'px', marginLeft: 0 + 'px', maxWidth: tag.width + 'px' }}>
                        <div className={classNames(visionSettingStyles.tagText)}>{msg}</div>
                    </div> : null}

            </div>
        </div>
    )
}

export const DetectedNormalTag = props => {
    if (props.detectedTag.type && props.detectedTag.type !== TAG_TYPE.Face) return null;
    const tagNameHeight = 9;
    let tag = parseTag(props.detectedTag);
    tag = scaleUpTagSize(tag);
    return (
        <div style={{
            marginLeft: tag.x + 'px',
            marginTop: tag.y - tagNameHeight + 'px',
            position: 'absolute'
        }}>
            {/* tag Rectangle */}
            <div className={classNames(visionSettingStyles.tagRectangle)}
                style={{ width: tag.width + 'px', height: tag.height + 'px', marginTop: tagNameHeight + 'px', marginLeft: 0 + 'px' }} />
        </div>
    )
}

export const DetectedTextTag = props => {
    if (props.detectedTag.type && props.detectedTag.type !== TAG_TYPE.OCR) return null;
    const tagNameHeight = 17;
    const indexLength = 12;
    const tagDevide = 4;
    let tag = parseTag(props.detectedTag);
    tag = scaleUpTagSize(tag);
    return (
        <div style={{
            marginLeft: tag.x + 'px',
            marginTop: tag.y - tagNameHeight + 'px',
            position: 'absolute'
        }}>
            <div className={classNames(visionSettingStyles.relativeArea)}>
                {/* tag Number */}
                <div className={classNames(visionSettingStyles.tagNumber)}
                    style={{ marginTop: tagNameHeight + tag.height - indexLength + 'px', marginLeft: tag.width - indexLength + 'px' }}>
                    {(props.index) ? props.index : null}</div>
                {/* tag Rectangle */}
                <div className={classNames(visionSettingStyles.tagOriginRectangle)}
                    style={{ marginTop: tagNameHeight + 'px', width: tag.width + 'px', height: tag.height + 'px', marginLeft: 0 + 'px' }} />
                {/* tag info area */}
                {props.enableTag ? <div className={classNames(visionSettingStyles.tagInfo)}
                    style={{
                        marginTop: tagNameHeight + tag.height + tagDevide + 'px', marginLeft: 0 + 'px'
                    }}>
                    <div className={classNames(visionSettingStyles.tagText)}>{tag.text}</div>
                </div> : null}

            </div>
        </div>
    )
}


export const DetectedSnapshotTag = props => {
    const {
        detectedTag,
        photoMode,
        imageSize
    } = props;
    const tagNameHeight = 9;
    let tag = parseTag(detectedTag);
    if (photoMode == VISION_IMAGE_MODE.REALTIME) {
        tag = scaleDownTagSize(tag);
    }
    const resizeTag = getTagResizeData(tag, imageSize.originHeight, imageSize.originWidth, snapshotHeight, snapshotWidth)
    return (
        <div style={{
            left: parseFixedToFloat(resizeTag.x) + imageSize.marginLeft + 'px',
            top: parseFixedToFloat(resizeTag.y) + imageSize.marginTop - tagNameHeight + 'px',
            position: 'absolute'
        }}>
            {/* tag Rectangle */}
            <div className={classNames(visionSettingStyles.tagRectangle, visionSettingStyles.snapshot)}
                style={{
                    position: 'absolute',
                    width: parseFixedToFloat(resizeTag.width) + 'px',
                    height: parseFixedToFloat(resizeTag.height) + 'px',
                    top: tagNameHeight + 'px',
                    left: 0 + 'px'
                }} />
        </div>
    )
}



export const DetectedFaceIdentificationTag = props => {
    if (props.detectedTag.type && props.detectedTag.type !== TAG_TYPE.FaceRecognition) return null;
    const {
        detectedTag,
        enableTag,
        mainTag,
        imgWidth,
        imgHeight,
        photoMode,
        imageSize
    } = props;
    const tagNameHeight = 17;
    const tagDevide = 4;
    let tag = parseTag(detectedTag);
    const result = detectedTag.recog_result;
    //result format ["unknown", -1] 
    if (!result || !Array.isArray(result)) return null;
    if (photoMode == VISION_IMAGE_MODE.REALTIME) {
        tag = scaleDownTagSize(tag);
    }
    var name = 'unknown';
    var score = -1;
    let preScore = -1
    result.forEach(data => {
        if (Array.isArray(data) && data.length > 1) {
            score = Math.max(score, data[1]);
            if (score == data[1] && preScore < score) {
                name = data[0];
                preScore = score;
            }
        }
    })
    const size = parseFixedToFloat(detectedTag.width * detectedTag.height, 0);
    const isMainTag = (size == mainTag.size) && (detectedTag.width == mainTag.width) && (detectedTag.height == mainTag.height)
    const resizeTag = getTagResizeData(tag, imageSize.originHeight, imageSize.originWidth, previewHeight, previewWidth)
    return (
        <div style={{
            left: parseFixedToFloat(resizeTag.x) + imageSize.marginLeft + 'px',
            top: parseFixedToFloat(resizeTag.y) + imageSize.marginTop - tagNameHeight + 'px',
            position: 'absolute'
        }}>
            <div className={classNames(visionSettingStyles.relativeArea)}>
                {/* tag Rectangle */}
                <div className={classNames(visionSettingStyles.tagRectangle, (!isMainTag) ? visionSettingStyles.minorTag : null)}
                    style={{
                        width: parseFixedToFloat(resizeTag.width) + 'px',
                        height: parseFixedToFloat(resizeTag.height) + 'px',
                        marginTop: tagNameHeight + 'px',
                        marginLeft: 0 + 'px'
                    }} />
                {/* tag info */}
                {enableTag && isMainTag ? <div className={classNames(visionSettingStyles.tagInfo)}
                    style={{
                        marginTop: tagNameHeight + parseFixedToFloat(resizeTag.height) + tagDevide + 'px',
                        marginLeft: 0 + 'px',
                        width: parseFixedToFloat(resizeTag.width) + 'px'
                    }}>
                    <div className={classNames(visionSettingStyles.tagText)}>{name == 'unknown' && score == -1 ? EditUtils.getLocaleString('gui.dialog.vision.face.identification.stranger') : name}</div>
                    {score == -1 ? null : <div className={classNames(visionSettingStyles.tagText)}>{`${EditUtils.getLocaleString('gui.dialog.vision.face.identification.confidence')}${parseFixedToFloat(score, 1)}%`}</div>}
                </div> : null}
            </div>
        </div>
    )
}

const getTagResizeData = (tag, imgHeight, imgWidth, previewHeight, previewWidth) => {
    let x = tag.x || 0;
    let y = tag.y || 0;
    let finalHeight = tag.height || previewHeight;
    let finalWidth = tag.width || previewWidth;
    let ratio = 1;
    if (previewHeight / previewWidth < imgHeight / imgWidth) {
        ratio = parseFixedToFloat(previewHeight / imgHeight, 10)
    } else {
        ratio = parseFixedToFloat(previewWidth / imgWidth, 10)
    }
    finalHeight = finalHeight * ratio
    finalWidth = finalWidth * ratio
    x = x * ratio
    y = y * ratio
    return { height: finalHeight, width: finalWidth, x: x, y: y }
}

export const parseTag = (tag) => {
    let formatTag = {
        "tag": tag.tag,
        "x": parseFixedToFloat(tag.x, 0),
        "y": parseFixedToFloat(tag.y, 0),
        "width": parseFixedToFloat(tag.width, 0),
        "height": parseFixedToFloat(tag.height, 0),
        "center_x": parseFixedToFloat(tag.center_x, 0),
        "center_y": parseFixedToFloat(tag.center_y, 0),
        "bgr": [
            206,
            193,
            178
        ],
    }
    if (tag.text) formatTag['text'] = tag.text;
    return formatTag;
}

export const backupOriginData = (tag) =>{
    return {
        x:tag.x,
        y:tag.y,
        width:tag.width,
        height:tag.height,
        center_x:tag.center_x,
        center_y:tag.center_y
    }
}

export const scaleUpTagSize = (tag) => {
    const widthScale = previewWidth / takePhotoWidth;
    const heightScale = previewHeight / takePhotoHeight;
    if (tag.x) tag.x = tag.x * widthScale;
    if (tag.y) tag.y = tag.y * heightScale;
    if (tag.width) tag.width = tag.width * widthScale;
    if (tag.height) tag.height = tag.height * heightScale;
    if (tag.center_x) tag.center_x = tag.center_x * widthScale;
    if (tag.center_y) tag.center_y = tag.center_y * heightScale;
    return tag;
}

const scaleDownTagSize = (tag) => {
    const widthScale = takePhotoWidth / previewWidth;
    const heightScale = takePhotoHeight / previewHeight;
    if (tag.x) tag.x = tag.x * widthScale;
    if (tag.y) tag.y = tag.y * heightScale;
    if (tag.width) tag.width = tag.width * widthScale;
    if (tag.height) tag.height = tag.height * heightScale;
    if (tag.center_x) tag.center_x = tag.center_x * widthScale;
    if (tag.center_y) tag.center_y = tag.center_y * heightScale;
    return tag;
}

export const parseFixedToFloat = (value, fixed) => {
    if (!fixed) fixed = 0;
    if (!value) return 0;
    return parseFloat(value.toFixed(fixed), 10)
};

export const getMainTag = (tagList) => {
    let maxTagSize = -1;
    let mainData = {};
    if (Array.isArray(tagList)) {
        tagList.forEach(data => {
            const size = parseFixedToFloat(data.width * data.height, 0);
            maxTagSize = Math.max(size, maxTagSize);
            if (maxTagSize == size) {
                mainData = data;
            }
        });
        mainData.size = maxTagSize;
    }
    return mainData;
}