import { BATTERY_CONNECTED_STATE } from '../lib/battery-connected-state'
import log from '../lib/log.js';
import {
    BluetoothStatus
} from '../lib/bluetooth';

import {
    BRAIN_TYPE
} from '../lib/brains';
import { SocketStatus } from '../lib/socket';

const ENTRY_BRAIN_SCREEN = 'entryBrainScreen'
const ENTRY_DEVICE_STATUS = 'entryDeviceStatus'
const BRAIN_IS_INSTALL_DRIVER = 'brainIsInstallDriver'
const BRAIN_DOWNLOAD_TYPE = 'brainDownloadType';
const BRAIN_DOWNLOADING = 'brainDownloading';
const BRAIN_SWITCHING = 'brainSwitching';
const BRAIN_DOWNLOAD_RUN = 'brainDownloadRun';
const BRAIN_LOADING = 'brainLoading';
const BRAIN_FW_UPDATING = 'brainFWUpdating';
const BRAIN_SCRIPT_ERASING = 'brainScriptErasing';
const BRAIN_RUNNING = 'brainRunning';
const BRAIN_CONNECT = 'brainConnect';
const BRAIN_UPDATE = 'brainUpdate';
const BRAIN_INFO = 'brainInfo';
const BRAIN_VER = 'brainVer';
const BRAIN_STATUS = 'brainStatus';
const CHECK_BATTERY_CONNECTED = 'batteryConnected';
const CHECK_BLUETOOTH_STATUS = 'checkBluetoothStatus';
const ALL_PORT_INFO = 'allPortInfo';
const CURRENT = "current";
const LATEST = "latest";
const BRAIN = "brain";
const VM = "vm";
const LINK = "link";
const BRAIN_BLUETOOTH_VER = 'brainBluetoothVer';
const FIRMWARE_CONFIG = "firmwareConfig";
const BLUETOOTH_IS_OPEN = "bluetoothIsOpen";
const BLUETOOTH_STATUS = "bluetoothStatus";
const AP_BRAIN_LIST = "apBrainList";
const BRAIN_WIFI_LIST = "brainWifiList";
const BRAIN_CONNECT_STATE = "brainConnectState";
const SOCKET_STATUS = "socketStatus";
const FORCE_OPEN_BRAIN_INFO = "forceOpenBrainInfo";
const DownloadType = {
    download: "download",
    downloadAndRun: "downloadAndRun"
};

const BRAIN_STATUS_TYPE = {
    IDLE: 0,
    RUNNING: 1,
    RFID_MODE: 2,
    BLUETOOTH_MODE: 3, //BT script running
    DOWNLOAD_SCRIPT: 4,
    BLUETOOTH_DOWNLOAD_PHONE_SCRIPT: 5, //BT script download (phone)
    BLUETOOTH_DOWNLOAD_BLOCKLY_SCRIPT: 6 //BT script download (ipad)
}

const MB_TYPE = {
    EDU: "EDUMB",
    ENTRY: "ENTMB",
    controller: "ENTRMC",
    btmodule: "EDUBTM",
    motor: "ENTMTR",
    color: "ENTCOL",
    touch: "ENTTOU",
    gyro: "ENTGYR",
    edu: "EDUMB",
    vision: "",
    ds: ""
}


const MODEL_TYPE = {
    EDU: "RB100",
    ENTRY: "RB101",
    EDU_AND_ENTRY: "RB100+RB101"
}

const WIFI_CONNECT_STATE = {
    SUCCESS: "success",
    FAILED: "failed",
    CONNECTING: "connecting"
}

const INIT_ENTRY_SCREEN = { line1: "", line2: "", line3: "", line4: "", reverse: "" }
const INIT_ENTRY_DEVICE = [
    { port: 1, type: "none", status: [] },
    { port: 2, type: "none", status: [] },
    { port: 3, type: "none", status: [] },
    { port: 4, type: "none", status: [] },
    { port: 5, type: "none", status: [] },
    { port: 6, type: "none", status: [] },
    { port: 7, type: "none", status: [] },
    { port: 8, type: "none", status: [] },
    { port: 9, type: "none", status: [] },
    { port: 10, type: "none", status: [] },
    { port: 11, type: "none", status: [] },
    { port: 12, type: "none", status: [] },
]

const initialState = {
    [BRAIN_IS_INSTALL_DRIVER]: true,
    [BRAIN_DOWNLOAD_TYPE]: DownloadType.downloadAndRun,
    [BRAIN_DOWNLOADING]: false,
    [BRAIN_SWITCHING]: false,
    [BRAIN_DOWNLOAD_RUN]: false,
    [BRAIN_LOADING]: false,
    [BRAIN_FW_UPDATING]: false,
    [BRAIN_SCRIPT_ERASING]: false,
    [BRAIN_RUNNING]: false,
    [BRAIN_CONNECT]: false,
    [BRAIN_STATUS]: BRAIN_STATUS_TYPE.IDLE,
    [BRAIN_UPDATE]: false,
    [CHECK_BATTERY_CONNECTED]: BATTERY_CONNECTED_STATE.UNCHECKED,
    [CHECK_BLUETOOTH_STATUS]: false,
    [BRAIN_INFO]: {},
    [ALL_PORT_INFO]: [],
    [BRAIN_VER + CURRENT]: { [BRAIN]: "0.0.0.1", [VM]: "0.0.1", [LINK]: "0.0.1" },
    [BRAIN_VER + LATEST]: { [BRAIN]: "0.0.0.1", [VM]: "0.0.1", [LINK]: "0.0.1" },
    [BRAIN_BLUETOOTH_VER]: "",
    [FIRMWARE_CONFIG]: null,
    [BLUETOOTH_IS_OPEN]: false,
    [BLUETOOTH_STATUS]: BluetoothStatus.scanStop,
    [AP_BRAIN_LIST]: [],
    [BRAIN_WIFI_LIST]: {},
    [BRAIN_CONNECT_STATE]: null,
    [ENTRY_BRAIN_SCREEN]: INIT_ENTRY_SCREEN,
    [ENTRY_DEVICE_STATUS]: INIT_ENTRY_DEVICE,
    [SOCKET_STATUS]: SocketStatus.scanStop,
    [FORCE_OPEN_BRAIN_INFO]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case BRAIN_IS_INSTALL_DRIVER:
        case BRAIN_DOWNLOADING:
        case BRAIN_SWITCHING:
        case BRAIN_DOWNLOAD_RUN:
        case BRAIN_LOADING:
        case BRAIN_FW_UPDATING:
        case BRAIN_SCRIPT_ERASING:
        case BRAIN_RUNNING:
        case BRAIN_CONNECT:
        case BRAIN_UPDATE:
        case BRAIN_STATUS:
        case CHECK_BATTERY_CONNECTED:
        case CHECK_BLUETOOTH_STATUS:
        case BRAIN_DOWNLOAD_TYPE:
        case BRAIN_BLUETOOTH_VER:
        case BRAIN_CONNECT_STATE:
        case ENTRY_DEVICE_STATUS:
        case ENTRY_BRAIN_SCREEN:
            return Object.assign({}, state, {
                [action.type]: action.status
            });
        case BRAIN_INFO:
            return Object.assign({}, state, {
                [action.type]: action.info,
            });
        case BRAIN_VER:
            return Object.assign({}, state, {
                [action.type + action.verType]: action.ver
            });
        case ALL_PORT_INFO:
            return Object.assign({}, state, {
                [action.type]: action.deivces
            });
        case FIRMWARE_CONFIG:
            if (typeof action.config === 'string') action.config = JSON.parse(action.config)
            return Object.assign({}, state, {
                [action.type]: action.config
            });
        case BLUETOOTH_IS_OPEN:
            return Object.assign({}, state, {
                [action.type]: action.bluetoothIsOpen
            });
        case BLUETOOTH_STATUS:
            return Object.assign({}, state, {
                [action.type]: action.bluetoothStatus
            });
        case AP_BRAIN_LIST:
        case BRAIN_WIFI_LIST:
            return Object.assign({}, state, {
                [action.type]: action.list
            });
        case SOCKET_STATUS:
            return Object.assign({}, state, {
                [action.type]: action.socketStatus
            });
        case FORCE_OPEN_BRAIN_INFO:
            return Object.assign({}, state, {
                [action.type]: action.open
            });
        default:
            return state;
    }
};


const setDriverInstallStatus = status => ({
    type: BRAIN_IS_INSTALL_DRIVER,
    status: status
});
const setBrainDownloadType = status => {
    log.info("setBrainDownloadType = ", status);
    return {
        type: BRAIN_DOWNLOAD_TYPE,
        status: status
    }
};
const setBrainDownloadingStatus = status => ({
    type: BRAIN_DOWNLOADING,
    status: status
});

const setBrainSwitchingStatus = status => ({
    type: BRAIN_SWITCHING,
    status: status
});

const setBrainDownloadAndRun = status => {
    log.info("setBrainDownloadAndRun = ", status);
    return {
        type: BRAIN_DOWNLOAD_RUN,
        status: status
    }
};
const setBrainLoadingStatus = status => ({
    type: BRAIN_LOADING,
    status: status
});
const setBrainFWUpdatingStatus = status => ({
    type: BRAIN_FW_UPDATING,
    status: status
});
const setBrainScriptErasingStatus = status => ({
    type: BRAIN_SCRIPT_ERASING,
    status: status
});
const setBrainRunningStatus = status => ({
    type: BRAIN_RUNNING,
    status: status
});
const setBrainConnectStatus = status => ({
    type: BRAIN_CONNECT,
    status: status
});
const setBrainUpdateStatus = status => ({
    type: BRAIN_UPDATE,
    status: status
});
const setBatteryConnectStatus = status => ({
    type: CHECK_BATTERY_CONNECTED,
    status: status
});
const setCheckBluetoothStatus = status => ({
    type: CHECK_BLUETOOTH_STATUS,
    status: status
});
const setBrainInfo = info => ({
    type: BRAIN_INFO,
    info: info
});
const setAllPortInfo = deivces => ({
    type: ALL_PORT_INFO,
    deivces: deivces
});

const setBrainVer = (verType, ver) => ({
    type: BRAIN_VER,
    verType: verType,
    ver: ver
});

const setBrainStatus = status => ({
    type: BRAIN_STATUS,
    status: status
});

const setFirmwareConfig = config => ({
    type: FIRMWARE_CONFIG,
    config: config
});

const setBrainBtVer = ver => ({
    type: BRAIN_BLUETOOTH_VER,
    status: ver
});

const setBluetoothIsOpen = bluetoothIsOpen => ({
    type: BLUETOOTH_IS_OPEN,
    bluetoothIsOpen: bluetoothIsOpen
});

const setBluetoothStatus = bluetoothStatus => ({
    type: BLUETOOTH_STATUS,
    bluetoothStatus: bluetoothStatus
});

const setApBrainList = listObj => ({
    type: AP_BRAIN_LIST,
    list: listObj || {}
});

const setBrainWifiList = list => ({
    type: BRAIN_WIFI_LIST,
    list: list || []
});

const setBrainConnectState = state => ({
    type: BRAIN_CONNECT_STATE,
    status: state
});

const setEntryBrainScreen = state => ({
    type: ENTRY_BRAIN_SCREEN,
    status: state
});

const setEntryDeviceStatus = state => ({
    type: ENTRY_DEVICE_STATUS,
    status: state
});

const setSocketStatus = socketStatus => ({
    type: SOCKET_STATUS,
    socketStatus: socketStatus
});

const forceOpenBrainInfo = open => ({
    type: FORCE_OPEN_BRAIN_INFO,
    open: open
});

const getDriverInstallStatus = state => state.scratchGui.brain[BRAIN_IS_INSTALL_DRIVER];
const getBrainDownloadType = state => state.scratchGui.brain[BRAIN_DOWNLOAD_TYPE];
const getBrainDownloadingStatus = state => state.scratchGui.brain[BRAIN_DOWNLOADING];
const getBrainBrainSwitchingStatus = state => state.scratchGui.brain[BRAIN_SWITCHING];
const getBrainDownloadAndRun = state => state.scratchGui.brain[BRAIN_DOWNLOAD_RUN];
const getBrainLoadingStatus = state => state.scratchGui.brain[BRAIN_LOADING];
const getBrainFWUpdatingStatus = state => state.scratchGui.brain[BRAIN_FW_UPDATING];
const getBrainScriptErasingStatus = state => state.scratchGui.brain[BRAIN_SCRIPT_ERASING];
const getBrainRunningStatus = state => state.scratchGui.brain[BRAIN_RUNNING];
const getBrainConnectStatus = state => state.scratchGui.brain[BRAIN_CONNECT];
const getBrainUpdateStatus = state => state.scratchGui.brain[BRAIN_UPDATE];
const getBrainInfo = state => state.scratchGui.brain[BRAIN_INFO];
const getBrainStatus = state => state.scratchGui.brain[BRAIN_STATUS];
const getBatteryConnectStatus = state => state.scratchGui.brain[CHECK_BATTERY_CONNECTED];
const getCheckBluetoothStatus = state => state.scratchGui.brain[CHECK_BLUETOOTH_STATUS];
const getFirmwareConfig = state => state.scratchGui.brain[FIRMWARE_CONFIG];
const getBrainBtVer = state => state.scratchGui.brain[BRAIN_BLUETOOTH_VER];
const getApBrainList = state => state.scratchGui.brain[AP_BRAIN_LIST];
const getBrainWifiList = state => state.scratchGui.brain[BRAIN_WIFI_LIST];
const getBrainConnectState = state => state.scratchGui.brain[BRAIN_CONNECT_STATE];
const getEntryBrainScreen = state => state.scratchGui.brain[ENTRY_BRAIN_SCREEN];
const getEntryDeviceStatus = state => state.scratchGui.brain[ENTRY_DEVICE_STATUS];
const getForceOpenBrainInfoStatus = state => state.scratchGui.brain[FORCE_OPEN_BRAIN_INFO];

const getBrainModelName = state => {
    let brainInfo = state.scratchGui.brain[BRAIN_INFO];
    switch (brainInfo.brain_type) {
        case BRAIN_TYPE.EDU:
            return MODEL_TYPE.EDU;
        case BRAIN_TYPE.ENTRY:
            return MODEL_TYPE.ENTRY;
        case BRAIN_TYPE.EDU_AND_ENTRY:
            return MODEL_TYPE.EDU_AND_ENTRY;
        default:
            return brainInfo.brain_type;
    }
}


const getAllPortInfo = state => {
    //Only return 5 devices
    const devicesLimit = 5;
    var resultDevices = [];

    var allDevices = state.scratchGui.brain[ALL_PORT_INFO];
    var connectDevice = state.scratchGui.brain[BRAIN_INFO];
    var connectDeviceIndex = -1;

    if (allDevices.length > devicesLimit) {
        //check connect device index
        for (let i in allDevices) {
            let device = allDevices[i];
            if (device.path == connectDevice.path) {
                connectDeviceIndex = i;
                break;
            }
        }

        if (devicesLimit > connectDeviceIndex && connectDeviceIndex != -1) {
            for (let i = 0; i < devicesLimit; i++) {
                resultDevices.push(allDevices[i])
            }
        } else {
            for (let i = 0; i < devicesLimit - 1; i++) {
                resultDevices.push(allDevices[i])
            }
            resultDevices.push(allDevices[connectDeviceIndex])
        }
        return resultDevices
    } else {
        return state.scratchGui.brain[ALL_PORT_INFO]
    }
};


const getBrainVer = (state, verType) => state.scratchGui.brain[BRAIN_VER + verType];
const getBluetoothIsOpen = state => state.scratchGui.brain[BLUETOOTH_IS_OPEN];
const getBluetoothStatus = state => state.scratchGui.brain[BLUETOOTH_STATUS];
const getSocketStatus = state => state.scratchGui.brain[SOCKET_STATUS];

export {
    reducer as default,
    initialState as brainInitialState,
    DownloadType,
    BRAIN_STATUS_TYPE,
    setDriverInstallStatus,
    getDriverInstallStatus,
    setBrainDownloadType,
    getBrainDownloadType,
    setBrainDownloadingStatus,
    getBrainDownloadingStatus,
    setBrainSwitchingStatus,
    getBrainBrainSwitchingStatus,
    setBrainDownloadAndRun,
    getBrainDownloadAndRun,
    setBrainFWUpdatingStatus,
    getBrainFWUpdatingStatus,
    setBrainScriptErasingStatus,
    getBrainScriptErasingStatus,
    setBrainRunningStatus,
    getBrainRunningStatus,
    setBrainConnectStatus,
    getBrainConnectStatus,
    setBrainUpdateStatus,
    getBrainUpdateStatus,
    setBrainStatus,
    getBrainStatus,
    setBrainInfo,
    getBrainInfo,
    getBrainModelName,
    setAllPortInfo,
    getAllPortInfo,
    setBrainVer,
    getBrainVer,
    setBrainLoadingStatus,
    getBrainLoadingStatus,
    setBatteryConnectStatus,
    getBatteryConnectStatus,
    setCheckBluetoothStatus,
    getCheckBluetoothStatus,
    setFirmwareConfig,
    getFirmwareConfig,
    setBrainBtVer,
    getBrainBtVer,
    setBluetoothIsOpen,
    getBluetoothIsOpen,
    setBluetoothStatus,
    getBluetoothStatus,
    MB_TYPE,
    setApBrainList,
    getApBrainList,
    setBrainWifiList,
    getBrainWifiList,
    WIFI_CONNECT_STATE,
    setBrainConnectState,
    getBrainConnectState,

    setEntryBrainScreen,
    getEntryBrainScreen,
    INIT_ENTRY_SCREEN,
    setEntryDeviceStatus,
    getEntryDeviceStatus,
    INIT_ENTRY_DEVICE,

    setSocketStatus,
    getSocketStatus,

    forceOpenBrainInfo,
    getForceOpenBrainInfoStatus,
};
