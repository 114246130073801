const CONCEPT_KEY = ":var"
export const SENTENCES_LIMIT = 50;
export const CONCEPTS_LIMIT = 50;

// export const sortUsingGroups = (sentenceData) => {
//     const groupSet = getUsingGroupSet(sentenceData.IntentArray);
//     const allGroups = sentenceData.Groups;
//     let usingGroups = [];
//     let uselessGroups = [];
//     allGroups.forEach(group => (groupSet.has(group.id) ? usingGroups.push(group)
//         : uselessGroups.push(group)))
//     return {
//         usingGroups: usingGroups,
//         uselessGroups: uselessGroups
//     }
// }

// const getUsingGroupSet = (intentArray) => {
//     let set = new Set();
//     intentArray.forEach(intent => {
//         set.add(intent.group)
//     });
//     return set
// }

// export const sortUsingConcepts = (sentenceData) => {
//     const conceptSet = getUsingConceptSet(sentenceData.IntentArray);
//     const allConcepts = sentenceData.Concepts;
//     let usingConcepts = [];
//     let uselessConcepts = [];
//     allConcepts.forEach(concept => (conceptSet.has(concept.id) ? usingConcepts.push(concept)
//         : uselessConcepts.push(concept)))
//     return {
//         usingConcepts: usingConcepts,
//         uselessConcepts: uselessConcepts
//     }
// }

// const getUsingConceptSet = (intentArray) => {
//     let set = new Set();
//     intentArray.forEach(intent => {
//         intent.pattern.forEach(sentence => {
//             sentence.forEach(word => {
//                 if (word.includes(CONCEPT_KEY)) {
//                     set.add(getConceptId(word))
//                 }
//             })
//         })
//         intent.response.forEach(sentence => {
//             sentence.forEach(word => {
//                 if (word.includes(CONCEPT_KEY)) {
//                     set.add(getConceptId(word))
//                 }
//             })
//         })
//     });
//     return set
// }

// const getConceptId = (concept) => {
//     const reg = /[<>]/g
//     return concept.replace(reg, "").split(":")[2];
// }

// export const sortUsingSentenceData = (sortedData, sortedUsingGroups, sortedUsingConcepts) => {
//     let groupSet = new Set();
//     sortedUsingGroups.forEach(group => {
//         groupSet.add(group.id)
//         groupSet.add(group.name)
//     })
//     let conceptSet = new Set();
//     sortedUsingConcepts.forEach(concept => {
//         conceptSet.add(concept.id)
//         conceptSet.add(concept.tag)
//     })

//     let different = sortedData.different
//     let differentGroups = [];
//     let differentConcepts = [];
//     different.Groups.forEach(group => {
//         if (groupSet.has(group.id) || groupSet.has(group.name)) differentGroups.push(group)
//     })
//     different.Concepts.forEach(concept => {
//         if (conceptSet.has(concept.id) || conceptSet.has(concept.tag)) differentConcepts.push(concept)
//     })
//     let duplicated = sortedData.duplicated
//     let duplicatedGroups = [];
//     let duplicatedConcepts = [];
//     duplicated.Groups.forEach(group => {
//         if (groupSet.has(group.id) || groupSet.has(group.name)) duplicatedGroups.push(group)
//     })
//     duplicated.Concepts.forEach(concept => {
//         if (conceptSet.has(concept.id) || conceptSet.has(concept.tag)) duplicatedConcepts.push(concept)
//     })
//     return {
//         different: {
//             Groups: differentGroups,
//             IntentArray: sortedData.different.IntentArray,
//             Concepts: differentConcepts,
//         },
//         duplicated: {
//             Groups: duplicatedGroups,
//             IntentArray: sortedData.duplicated.IntentArray,
//             Concepts: duplicatedConcepts,
//         }
//     }

// }

export const sortSentenceDifferentData = (importSentenceData, vmSentenceData) => {
    const sortedGroups = sortImportGroups(importSentenceData.Groups, vmSentenceData.Groups)
    const sortedIntentArray = sortImportIntents(importSentenceData.IntentArray, vmSentenceData.IntentArray)
    const sortedConcepts = sortImportConcepts(importSentenceData.Concepts, vmSentenceData.Concepts)
    return {
        different: {
            Groups: sortedGroups.different,
            IntentArray: sortedIntentArray.different,
            Concepts: sortedConcepts.different,
        },
        duplicated: {
            Groups: sortedGroups.duplicated,
            IntentArray: sortedIntentArray.duplicated,
            Concepts: sortedConcepts.duplicated,
        }
    }
}

const sortImportGroups = (importGroups, vmGroups) => {
    return sortImport(importGroups, vmGroups, "id", "name")
}
const sortImportIntents = (importIntentArray, vmIntentArray) => {
    return sortImport(importIntentArray, vmIntentArray, "id", "IntentName")
}

export const sortImportConcepts = (importConcepts, vmConcepts) => {
    return sortImport(importConcepts, vmConcepts, "id", "tag")
}

const sortImport = (importData, vmData, idIndex, nameIndex) => {
    let set = new Set();
    importData.forEach(data => {
        set.add(data[idIndex])
        set.add(data[nameIndex])
    })
    let duplicatedSet = new Set();
    const duplicated = vmData.filter(data => data && (set.has(data[idIndex]) || set.has(data[nameIndex])))
    duplicated.forEach(data => {
        duplicatedSet.add(data[idIndex])
        duplicatedSet.add(data[nameIndex])
    })
    return {
        different: importData.filter(data => data && (!duplicatedSet.has(data[idIndex]) && !duplicatedSet.has(data[nameIndex]))),
        duplicated: importData.filter(data => data && ((duplicatedSet.has(data[idIndex]) || duplicatedSet.has(data[nameIndex]))))
    }
}

export const getExceedIntentNumber = (importIntents, vmSentenceIntents) => {
    const intentNumber = (importIntents.length + vmSentenceIntents.length) - SENTENCES_LIMIT;
    return intentNumber > 0 ? intentNumber : 0
}

export const getExceedConceptNumber = (importConcepts, vmConcepts) => {
    const conceptNumber = (importConcepts.length + vmConcepts.length) - CONCEPTS_LIMIT
    return conceptNumber > 0 ? conceptNumber : 0
}

// export const concatDifferentData = (differentData, vmSentenceData) => {
//     return {
//         Groups: vmSentenceData.Groups.concat(differentData.Groups),
//         IntentArray: vmSentenceData.IntentArray.concat(differentData.IntentArray),
//         Concepts: vmSentenceData.Concepts.concat(differentData.Concepts)
//     }
// }

export const renameSortedDifferentData = (sortedDifferentData, vmSentenceData) => {
    sortedDifferentData = renameGroups(sortedDifferentData, vmSentenceData);
    sortedDifferentData = renameIntents(sortedDifferentData, vmSentenceData);
    sortedDifferentData = renameConcepts(sortedDifferentData, vmSentenceData);
    return sortedDifferentData
}

const renameGroups = (sortedDifferentData, vmSentenceData) => {
    const groups = sortedDifferentData.duplicated.Groups;
    let newGroups = [];
    for (let i = 0; i < groups.length; i++) {
        newGroups.push({
            id: groups[i].id,
            name: groups[i].name,
            expanded: groups[i].expanded,
        })
    }
    let newDuplicatedIntentArray = parseNewIntentArray(sortedDifferentData.duplicated.IntentArray);
    let newDifferentIntentArray = parseNewIntentArray(sortedDifferentData.different.IntentArray);
    let allDiffIds = vmSentenceData.Groups.concat(sortedDifferentData.different.Groups).map(group => group.id)
    let allDiffNames = vmSentenceData.Groups.concat(sortedDifferentData.different.Groups).map(group => group.name)

    newGroups.forEach((group, i) => {
        let originId = "" + group.id;
        let originName = "" + group.name;
        let newId = refreshId("group", allDiffIds)
        let newName = refreshName(originName, allDiffNames)
        allDiffIds.push(newId);
        allDiffNames.push(newName);
        newDuplicatedIntentArray.forEach(intent => {
            if (intent.group == originId) {
                intent.group = newId;
            }
        })
        newDifferentIntentArray.forEach(intent => {
            if (intent.group == originId) {
                intent.group = newId;
            }
        })
        newGroups[i].id = newId;
        newGroups[i].name = newName;
    })
    return {
        different: {
            Groups: sortedDifferentData.different.Groups,
            IntentArray: newDifferentIntentArray,
            Concepts: sortedDifferentData.different.Concepts,
        },
        duplicated: {
            Groups: newGroups,
            IntentArray: newDuplicatedIntentArray,
            Concepts: sortedDifferentData.duplicated.Concepts,
        }
    };
}

const renameIntents = (sortedDifferentData, vmSentenceData) => {
    let newIntentArray = parseNewIntentArray(sortedDifferentData.duplicated.IntentArray);
    let allDiffIds = vmSentenceData.IntentArray.concat(sortedDifferentData.different.IntentArray).map(intnent => intnent.id);
    let allDiffNames = vmSentenceData.IntentArray.concat(sortedDifferentData.different.IntentArray).map(intnent => intnent.IntentName);

    newIntentArray.forEach((intnent, i) => {
        const originName = "" + intnent.IntentName;
        let newId = refreshId("intent", allDiffIds)
        let newName = refreshName(originName, allDiffNames)
        allDiffIds.push(newId);
        allDiffNames.push(newName);
        newIntentArray[i].id = newId;
        newIntentArray[i].IntentName = newName;
    })

    return {
        different: sortedDifferentData.different,
        duplicated: {
            Groups: sortedDifferentData.duplicated.Groups,
            IntentArray: newIntentArray,
            Concepts: sortedDifferentData.duplicated.Concepts,
        }
    };
}

const parseNewIntentArray = (intentArray) => {
    let newIntentArray = [];
    for (let i = 0; i < intentArray.length; i++) {
        newIntentArray.push({
            id: intentArray[i].id,
            IntentName: intentArray[i].IntentName,
            group: intentArray[i].group,
            pattern: intentArray[i].pattern,
            response: intentArray[i].response,
        })
    }
    return newIntentArray
}


const renameConcepts = (sortedDifferentData, vmSentenceData) => {
    const concepts = sortedDifferentData.duplicated.Concepts;
    let newConcepts = [];
    for (let i = 0; i < concepts.length; i++) {
        newConcepts.push({
            id: concepts[i].id,
            tag: concepts[i].tag,
            value: concepts[i].value,
        })
    }
    let newDifferentIntentArray = parseNewIntentArray(sortedDifferentData.different.IntentArray);
    let newDuplicatedIntentArray = parseNewIntentArray(sortedDifferentData.duplicated.IntentArray);
    let allDiffIds = vmSentenceData.Concepts.concat(sortedDifferentData.different.Concepts).map(concept => concept.id)
    let allDiffNames = vmSentenceData.Concepts.concat(sortedDifferentData.different.Concepts).map(concept => concept.tag)

    newConcepts.forEach((concept, i) => {
        let originId = "" + concept.id;
        let originName = "" + concept.tag;
        let newId = refreshId("concept", allDiffIds)
        let newName = refreshName(originName, allDiffNames)
        allDiffIds.push(newId);
        allDiffNames.push(newName);
        newDifferentIntentArray = refreshConceptInIntent(newDifferentIntentArray, originId, newName, newId);
        newDuplicatedIntentArray = refreshConceptInIntent(newDuplicatedIntentArray, originId, newName, newId);
        newConcepts[i].id = newId;
        newConcepts[i].tag = newName;
    })
    return {
        different: {
            Groups: sortedDifferentData.different.Groups,
            IntentArray: newDifferentIntentArray,
            Concepts: sortedDifferentData.different.Concepts,
        },
        duplicated: {
            Groups: sortedDifferentData.duplicated.Groups,
            IntentArray: newDuplicatedIntentArray,
            Concepts: newConcepts,
        }
    };
}

const refreshConceptInIntent = (intentArray, originId, newName, newId) => {
    intentArray.forEach(intent => {
        intent.pattern.forEach((sentence, i) => {
            sentence.forEach(word => {
                const originWordArray = word.split(":");
                const originVarNum = originWordArray[1];
                const originOrder = originWordArray[3];
                if (word.includes(originId)) {
                    sentence[i] = "<" + newName + ":" + originVarNum + ":" + newId + ":" + originOrder + ">"
                }
            })
        })
        intent.response.forEach((sentence, i) => {
            sentence.forEach(word => {
                const originWordArray = word.split(":");
                const originVarNum = originWordArray[1];
                const originOrder = originWordArray[3];
                if (word.includes(originId)) {
                    sentence[i] = "<" + newName + ":" + originVarNum + ":" + newId + ":" + originOrder + ">"
                }
            })
        })
    })
    return intentArray
}

export const renameDupicatedConceptsOnly = (duplicatedConcepts, vmSentenceConcepts) => {
    let newConcepts = [];
    for (let i = 0; i < duplicatedConcepts.length; i++) {
        newConcepts.push({
            id: duplicatedConcepts[i].id,
            tag: duplicatedConcepts[i].tag,
            value: duplicatedConcepts[i].value,
        })
    }
    let allDiffIds = vmSentenceConcepts.map(concept => concept.id)
    let allDiffNames = vmSentenceConcepts.map(concept => concept.tag)
    newConcepts.forEach((concept, i) => {
        let originName = "" + concept.tag;
        let newId = refreshId("concept", allDiffIds)
        let newName = refreshName(originName, allDiffNames)
        allDiffIds.push(newId);
        allDiffNames.push(newName);
        newConcepts[i].id = newId;
        newConcepts[i].tag = newName;
    })
    return newConcepts;
}

const refreshId = (index, idArray) => {
    let number = Math.floor(Math.random() * 1000000);
    while (idArray.includes(index + "-" + number)) {
        number = Math.floor(Math.random() * 1000000)
    }
    return index + "-" + number
}

const refreshName = (name, nameArray) => {
    let count = 1
    while (nameArray.includes(name + "(" + count + ")")) {
        count++
    }
    return name + "(" + count + ")";
}




