
const store = require('store');
const { DEFAULT_LOCALE } = require('../../../config/project-config.js');

export const VISION_REALTIME_MODE = {
    NONE: "0",
    COLOR: "1",
    FACE: "2",
    QRCODE: "3",
    TEXT_IDENTIFICATION: "4",
    FACE_IDENTIFICATION: "5",
}

export const VISION_IMAGE_MODE = {
    REALTIME: "realtime",
    PICTURE: "picture"
}

export const VISION_NAME_RULE = {
    VALID: 0,
    DUPLICATE: 1,
    EMPTY: 2,
    INVALID: 3
}

export const VISION_TAB = {
    COLOR: "color",
    QRCODE: "qrcode",
    FACE: "face",
    FACE_IDENTIFICATION: "faceIdentification",
    TEXT_IDENTIFICATION: "textIdentification"
}

export const VISION_TAB_MODE_MAPPING = {
    [VISION_TAB.COLOR]: VISION_REALTIME_MODE.COLOR,
    [VISION_TAB.QRCODE]: VISION_REALTIME_MODE.QRCODE,
    [VISION_TAB.FACE]: VISION_REALTIME_MODE.FACE,
    [VISION_TAB.FACE_IDENTIFICATION]: VISION_REALTIME_MODE.NONE,
    [VISION_TAB.TEXT_IDENTIFICATION]: VISION_REALTIME_MODE.NONE,
}

export const IS_ANALYZING = "analyzing"
export const IS_UPLOADING = "uploading"
export const OPEN_IMAGE_FILE_FAILED = "openImageFileFailed"
export const OPEN_IMAGE_FILE_SUCCESS = "openImageFileSuccess"

export const CACNEL_MARK_TIMEOUT = 10 * 1000;
export const ANALYZING_TIMEOUT = 40 * 1000;
export const LINK_COMMAND_DELAY = 1500;
export const STATE_COMMAND_DELAY = 100;
export const COLOR_TAG_AMOUNT = 7

export const VISION_DEFAULT_LANGUAGE_MAPPING = {
    "en": {
        "RED": "RED",
        "ORANGE": "ORANGE",
        "YELLOW": "YELLOW",
        "GREEN": "GREEN",
        "SKY_BLUE": "SKY_BLUE",
        "BLUE": "BLUE",
        "PURPLE": "PURPLE",
        "PINK": "PINK",
        "Person1": "Person1",
        "Person2": "Person2",
        "Person3": "Person3",
        "Person4": "Person4",
        "Person5": "Person5",
        "Person6": "Person6",
    },
    "zh-cn": {
        "RED": "红色",
        "ORANGE": "橘色",
        "YELLOW": "黄色",
        "GREEN": "绿色",
        "SKY_BLUE": "天蓝色",
        "BLUE": "蓝色",
        "PURPLE": "紫色",
        "PINK": "粉红色",
        "Person1": "人物1",
        "Person2": "人物2",
        "Person3": "人物3",
        "Person4": "人物4",
        "Person5": "人物5",
        "Person6": "人物6",
    },
    "zh-tw": {
        "RED": "紅色",
        "ORANGE": "橘色",
        "YELLOW": "黃色",
        "GREEN": "綠色",
        "SKY_BLUE": "天藍色",
        "BLUE": "藍色",
        "PURPLE": "紫色",
        "PINK": "粉紅色",
        "Person1": "人物1",
        "Person2": "人物2",
        "Person3": "人物3",
        "Person4": "人物4",
        "Person5": "人物5",
        "Person6": "人物6",
    },
}

export const parseDataLanguage = (colorDataList) => {
    let list = [];
    if (colorDataList && Array.isArray(colorDataList)) {
        const language = store.get("locale", DEFAULT_LOCALE);
        colorDataList.forEach(data => {
            list.push(
                {
                    color_name: VISION_DEFAULT_LANGUAGE_MAPPING[language][data.color_name] || data.color_name,
                    bar: data.bar || 5.0,
                    bgr: data.bgr || [-1, -1, -1],
                    lowerbound: data.lowerbound || [-1, -1, -1],
                    upperbound: data.upperbound || [-1, -1, -1]
                })
        })
    }
    return list;
}

