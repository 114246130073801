import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import store from 'store';
import styles from './dialog.css';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import index from '../../../static/help/deviceInfo/index.json'
import HelpDialog from './help-dialog.jsx'
import { getPickedBrainType } from '../../reducers/picked-brain-type'
import { getSelectedDevice } from '../../reducers/device-help-select'
import { focusDialog, getForegroundDialog, DIALOG_DEVICE_HELP } from '../../reducers/dialog'
import { uiType, getUIStyle } from '../../reducers/ui-style';
import tabIndexIcon from './svg/tab_index.svg';
import tabIndexWWIcon from './svg/tab_index_ww.svg';
import { DEFAULT_LOCALE } from '../../config/project-config';

const DIALOG_DEFAULT_SIZE = {
    width: 686,
    height: 634,
    tabWidth: 174,
    minWidth: 360,
    minHeight: 487,
    minContentWidth: 241
}

const tablist = index

class DeviceHelpDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.list = this.parseJson();
        this.state = {
            tab_select: this.list ? this.list[0].type : "led",
            width: DIALOG_DEFAULT_SIZE.width,
            height: DIALOG_DEFAULT_SIZE.height
        };
    }


    clickTab(type) {
        this.setState({ tab_select: type })
    }

    componentDidUpdate(prevProps) {
        if (this.props.getPickedBrainType != prevProps.getPickedBrainType) this.list = this.parseJson()
        if (this.props.getSelectedDevice != prevProps.getSelectedDevice) this.setState({ tab_select: this.props.getSelectedDevice })
    }

    parseJson() {
        let type = this.props.getPickedBrainType || "EDU";
        let language = store.get("locale", DEFAULT_LOCALE);
        let supportDeviceList = tablist.support[type];
        let list = [];
        Object.entries(tablist.deviceInfo).forEach((device) => {
            if (supportDeviceList && supportDeviceList.includes(device[0])) {
                list.push({
                    type: device[0],
                    name: device[1][language].name,
                    description: device[1][language].description,
                    hint: device[1][language].hint,
                    update: device[1].update,
                    icon: this.props.getUIStyle == uiType.ww && device[1].iconWW ? device[1].iconWW : device[1].icon,
                })
            }
        })
        return list;
    }

    getTabList() {
        return <div className={classNames(styles.deviceHelpTabList)}>
            {this.list.map((location, index) =>
                <div key={index}
                    className={classNames(styles.tab)}
                    onClick={() => this.clickTab(location.type)}>
                    <div className={classNames((this.state.tab_select == location.type) ? styles.tabIndexSelected : styles.tabIndex)}>
                        <img src={(this.props.getUIStyle == uiType.ww) ? tabIndexWWIcon : tabIndexIcon} alt={"tab"}/>
                    </div>
                    <div className={classNames((this.state.tab_select == location.type) ? styles.deviceHelpTabActive : styles.deviceHelpTab)}>
                        {location.name}
                    </div>
                    <div className={classNames(styles.tabLine)} />
                </div>)}
        </div>
    }

    setDialogSize(width, height) {
        this.setState({ width: width, height: height })
    }

    getTabCotent() {
        let content;
        this.list.forEach(device => {
            if (device.type == this.state.tab_select) {
                content = device
            }
        })
        if (content) {
            return <div className={classNames(styles.deviceHelpContent)}>
                <div className={classNames(styles.deviceHelpIcon)}>
                    <img className={classNames(styles.iconSize)}
                        src={content.icon}
                        alt={"device help"} />
                </div>
                <div className={classNames(styles.deviceHelpTitleArea)}>
                    <div className={classNames(styles.deviceHelpDescription)}>{content.description}</div>
                    <div className={classNames(styles.deviceHelpName)}>{content.name}</div>
                </div>
                <div className={classNames(styles.deviceHelpHintArea)} style={{ height: this.state.width < 515 ? "calc(100% - 200px)" : "calc(100% - 108px)" }}>
                    <div className={classNames(styles.deviceHelpHint)}>
                        <iframe
                            src={content.hint}
                            height="100%"
                            width="100%"
                            frameBorder="0"
                            scrolling="overlay"
                            title="device help iframe"
                        />
                    </div>
                </div>
            </div>
        }
    }



    render() {
        return (
            <div onMouseDown={() => this.props.focusDialog()}
                onTouchStart={() => this.props.focusDialog()}>
                <HelpDialog
                    dialogType={DIALOG_DEVICE_HELP}
                    onClose={this.props.onClose}
                    show={this.props.show}
                    height={DIALOG_DEFAULT_SIZE.height}
                    width={DIALOG_DEFAULT_SIZE.width}
                    defaultTabWidth={DIALOG_DEFAULT_SIZE.tabWidth}
                    minContentWidth={DIALOG_DEFAULT_SIZE.minContentWidth}
                    minHeight={DIALOG_DEFAULT_SIZE.minHeight}
                    minWidth={DIALOG_DEFAULT_SIZE.minWidth}
                    titleId="gui.dialog.device.help.title"
                    tabList={this.getTabList()}
                    content={this.getTabCotent()}
                    setDialogSize={(w, h) => this.setDialogSize(w, h)}>
                </HelpDialog>
            </div>
        );
    }
}

DeviceHelpDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool
};

const mapStateToProps = state => ({
    getPickedBrainType: getPickedBrainType(state),
    getSelectedDevice: getSelectedDevice(state),
    foregroundDialog: getForegroundDialog(state),
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
    focusDialog: () => dispatch(focusDialog(DIALOG_DEVICE_HELP))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceHelpDialog);