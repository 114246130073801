import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import styles from './brain.css';
import Port from '../port/port.jsx';
import TypeCPort from '../port/type-c-port.jsx';
import EntryPoint from './entry-point.jsx';
import EntryLine from './entry-line.jsx';
import EduPoint from './edu-point.jsx';
import EduLine from './edu-line.jsx';
import VM from 'scratch-vm';
import {
    openDeviceList,
    isEditPageOpen,
    isAddPageOpen,
    isPortOpen,
    isCheckingImportDevice,
    getPortArray,
    setPortArray
} from '../../../reducers/device-manager-controller';

import arrowImg from '../pictures/arrow.svg'
import arrowWWImg from '../pictures/ww/arrow.svg'

import {
    isVisionSettingDialogShow,
} from '../../../reducers/dialog';
import {
    cleanPortOption,
} from '../../../reducers/select-option';

import {
    clearSkillList,
    clearSkillLocation
} from '../../../reducers/speaker';
import {
    EditUtils,
    SUPPORT_TYPES,
} from '../edit-page/edit-utils.js';

import {
    getSelectedTab,
    TAB_INDEX
} from '../../../reducers/controller';

import {
    isDeviceSelected,
    selectDeviceToDelete,
    cleanSeletedDevice,
    getSelectedDeviceIdArray,
    isDeleteDeviceMode,
    openDeleteMode,
    closeDeleteMode
} from '../../../reducers/delete-device';
import { BRAIN_TYPE } from '../../../lib/brains'

import {
    getUIStyle,
    uiType
} from '../../../reducers/ui-style';
import eduBrainImg from '../pictures/brain_edu.png'
import entryBrainImg from '../pictures/brain_entry.png'
import switchBrainImg from '../pictures/switch_brain.svg'
import switchBrainWWImg from '../pictures/ww/switch_brain.svg'

import {
    getColorDataDefaultList,
} from '../../../reducers/vision';

const messagesId = {
    delete: "gui.deviceManagerStage.page.delete",
    selectAll: "gui.deviceManagerStage.page.selectAll",
    cancel: "gui.deviceManagerStage.page.cancel",
    edit: "gui.deviceManagerStage.page.edit"
}

export const parseDeviceList = deviceList => {
    var result = [
        { port: "B1", portType: "adc", type: "", name: "", id: "" },
        { port: "B2", portType: "adc", type: "", name: "", id: "" },
        { port: "B3", portType: "adc", type: "", name: "", id: "" },
        { port: "B4", portType: "adc", type: "", name: "", id: "" },
        { port: "A4", portType: "pwm", type: "", name: "", id: "" },
        { port: "A3", portType: "pwm", type: "", name: "", id: "" },
        { port: "A2", portType: "pwm", type: "", name: "", id: "" },
        { port: "A1", portType: "pwm", type: "", name: "", id: "" },
        { port: "1", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "2", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "3", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "4", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "5", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "6", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "L", portType: "typeC", type: "", name: "", id: "", other: "" },
        { port: "12", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "11", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "10", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "9", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "8", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "7", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "R", portType: "typeC", type: "", name: "", id: "", other: "" },
    ]
    if (Array.isArray(deviceList)) {
        result.forEach(element => {
            deviceList.forEach(device => {
                if (device.connectPortArray.includes(element.port)) {
                    element.type = device.type;
                    element.name = device.name;
                    element.id = device.id;
                }
                if (device.other && device.other.gyro && device.other.gyro == element.port) {
                    element.other = 'gyro'
                    element.name = device.other.gyroName ? device.other.gyroName : EditUtils.getLocaleString("gui.device.drivetrain.gyro");
                }
            });
        });
    }
    return result;
}

const POSTION = {
    LEFT: 'left',
    RIGHT: 'right'
}

const FOCUS = {
    EDU: 'edu',
    ENTRY: 'entry'
}

const PORT_ON_IMAGE_POSITION = {
    LEFT: ["7", "8", "9", "10", "11", "12"],
    RIGHT: ["1", "2", "3", "4", "5", "6", "L", "R"],
    ENTRY: ["1", "2", "3", "4", "5", "6", "L", "R", "7", "8", "9", "10", "11", "12"],
    EDU: ["A1", "A2", "A3", "A4", "B1", "B2", "B3", "B4"]
}

const BRAIN_FOCUS_STATE = {
    EDU: 0,
    ENTRY_LEFT: 1,
    ENTRY_RIGHT: 2
}

const ANIMATION_STATE = {
    oneToTwo: 0,
    twoToThree: 1,
    threeToOne: 2,
    threeToTwo: 3,
    twoToOne: 4,
}

class EduEntryBrain extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            brainPosition: POSTION.RIGHT,
            refreshByImport: false,
            refreshByVision: false,
            focus: FOCUS.ENTRY,
            animationState: null,
            arrowImg: this.props.getUIStyle == uiType.ww ? arrowWWImg : arrowImg,
            switchImg: this.props.getUIStyle == uiType.ww ? switchBrainWWImg : switchBrainImg,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.getSelectedTab != prevProps.getSelectedTab && this.props.getSelectedTab == TAB_INDEX.brain) this.setState({ brainPosition: POSTION.RIGHT });
        if (this.props.isCheckingImportDevice != prevProps.isCheckingImportDevice) this.setState({ refreshByImport: this.props.isCheckingImportDevice });
        if (this.props.isVisionSettingDialogShow != prevProps.isVisionSettingDialogShow) {
            this.setState({ refreshByVision: this.props.isVisionSettingDialogShow });
        }
        if (this.props.isDeviceListOpen != prevProps.isDeviceListOpen) this.setState({ deviceList: parseDeviceList(this.props.vm.getDeviceList()) })
        if (prevProps.getPortArray != this.props.getPortArray) {
            this.autoSwitchBrain();
        }
    }

    autoSwitchBrain() {
        let portArray = Array.from(this.props.getPortArray);
        if (!Array.isArray(portArray) || portArray.length <= 0) return;
        portArray.sort((a, b) => a - b);
        let prevStatus = this.getFocusStatus();
        let nextStatus = this.getFocusStatus(PORT_ON_IMAGE_POSITION.EDU.includes(portArray[0]) ? false : true, PORT_ON_IMAGE_POSITION.LEFT.includes(portArray[0]) ? true : false);
        let animationState = this.getAnimationStatus(prevStatus, nextStatus)
        this.setState({
            focus: PORT_ON_IMAGE_POSITION.EDU.includes(portArray[0]) ? FOCUS.EDU : FOCUS.ENTRY,
            brainPosition: PORT_ON_IMAGE_POSITION.LEFT.includes(portArray[0]) ? POSTION.LEFT : POSTION.RIGHT,
            animationState: animationState
        })
        this.props.cleanPortArray();
    }

    parseDevicePort(deviceList) {
        let result = []
        deviceList.forEach(device => {
            if (device.id && device.id.length > 0) {
                result.push(device.port)
            }
        });
        return result;
    }

    parseDeviceId(deviceList) {
        let selectedDeviceArray = this.props.getSelectedDeviceIdArray;
        let result = [];
        selectedDeviceArray.forEach(port => {
            deviceList.forEach(device => {
                if (device.port == port && !result.includes(device.id)) {
                    result.push(device.id)
                }
            });
        })
        return result;
    }

    handleClickTrashcan(deviceList) {
        if (!this.isTrashcanEnable()) return
        if (this.props.isDeleteDeviceMode) {
            let isIncludeVision = deviceList.filter(portInfo => portInfo.type == SUPPORT_TYPES.vision)[0]
            if (isIncludeVision) {
                this.props.vm.initCvTagSettings(this.props.colorDataDefaultList);
                this.props.vm.clearCvProfiles();
                this.props.vm.clearProfilePhoteSave();
            }
            let isIncludeSpeaker = deviceList.filter(portInfo => portInfo.type == SUPPORT_TYPES.speaker)[0]
            if (isIncludeSpeaker) {
                this.props.vm.clearSpeakerSettings()
                this.props.clearSkillList()
                this.props.clearSkillLocation()
            }
            this.props.vm.removeMultiDeviceById(this.parseDeviceId(deviceList))
            if (this.props.isDeviceSelected) {
                this.props.closeDelete();
            }
        } else {
            this.props.openDelete()
        }
        this.props.openDeviceList()
    }

    handleClickSelectAll(deviceList) {
        if (deviceList && this.props.isDeleteDeviceMode) {
            var deviceIdArray = this.parseDevicePort(deviceList);
            (this.props.getSelectedDeviceIdArray.length == deviceIdArray.length) ? this.props.cleanSeletedDevice() : this.props.seletDevice(deviceIdArray);
        }
    }

    handleClickBack() {
        if (this.props.isDeleteDeviceMode) {
            this.props.closeDelete();
        }
    }

    handleClickMove(direction) {
        if (this.state.brainPosition != direction) {
            this.setState({ brainPosition: direction })
        }
    }

    handleClickSwitchBrain() {
        this.setState({
            focus: this.state.focus == FOCUS.ENTRY ? FOCUS.EDU : FOCUS.ENTRY,
            brainPosition: POSTION.RIGHT
        });
    }

    isTrashcanEnable() {
        return (this.props.vm.getDeviceList() && this.props.vm.getDeviceList().length > 0 && !this.props.isEditPageOpen && !this.props.isAddPageOpen)
    }

    isBrainLeft() {
        return this.state.brainPosition == POSTION.LEFT;
    }

    isFocusEntry() {
        return this.state.focus == FOCUS.ENTRY;
    }

    setAnimationState(state) {
        this.setState({ animationState: state })
    }

    getFocusStatus(isFocusEntry, isBrainLeft) {
        if (isFocusEntry == null) isFocusEntry = this.isFocusEntry();
        if (isBrainLeft == null) isBrainLeft = this.isBrainLeft();
        if (isFocusEntry && isBrainLeft) return BRAIN_FOCUS_STATE.ENTRY_LEFT;
        if (isFocusEntry && !isBrainLeft) return BRAIN_FOCUS_STATE.ENTRY_RIGHT;
        if (!isFocusEntry) return BRAIN_FOCUS_STATE.EDU;
    }

    getAnimationStatus(prev, next) {
        if (prev == BRAIN_FOCUS_STATE.EDU && next == BRAIN_FOCUS_STATE.ENTRY_RIGHT) return ANIMATION_STATE.oneToTwo;
        if (prev == BRAIN_FOCUS_STATE.EDU && next == BRAIN_FOCUS_STATE.ENTRY_LEFT) return ANIMATION_STATE.oneToThree;
        if (prev == BRAIN_FOCUS_STATE.ENTRY_RIGHT && next == BRAIN_FOCUS_STATE.ENTRY_LEFT) return ANIMATION_STATE.twoToThree;
        if (prev == BRAIN_FOCUS_STATE.ENTRY_RIGHT && next == BRAIN_FOCUS_STATE.EDU) return ANIMATION_STATE.twoToOne;
        if (prev == BRAIN_FOCUS_STATE.ENTRY_LEFT && next == BRAIN_FOCUS_STATE.ENTRY_RIGHT) return ANIMATION_STATE.threeToTwo;
        if (prev == BRAIN_FOCUS_STATE.ENTRY_LEFT && next == BRAIN_FOCUS_STATE.EDU) return ANIMATION_STATE.threeToOne;
        return null;
    }
    getButtonUI() {
        let state = this.getFocusStatus();
        switch (state) {
            // state 1
            case BRAIN_FOCUS_STATE.EDU:
                return <div className={classNames(styles.buttonRightCombo)}
                    onClick={() => {
                        this.handleClickSwitchBrain();
                        this.setAnimationState(ANIMATION_STATE.oneToTwo)
                    }}>
                    <img className={classNames(styles.buttonSwitchSize)} src={this.state.switchImg} /></div>
            // state 2
            case BRAIN_FOCUS_STATE.ENTRY_RIGHT:
                return (
                    <div>
                        <div className={classNames(styles.buttonRightCombo, styles.reverseImg)}
                            onClick={() => {
                                this.handleClickMove(POSTION.LEFT);
                                this.setAnimationState(ANIMATION_STATE.twoToThree);
                            }}><img src={this.state.arrowImg} alt={"arrow"} /></div>
                        <div className={classNames(styles.buttonLeftCombo)}
                            onClick={() => {
                                this.handleClickSwitchBrain();
                                this.setAnimationState(ANIMATION_STATE.twoToOne);
                            }}>
                            <img className={classNames(styles.buttonSwitchSize)} src={this.state.switchImg} alt={"switch"} /></div>
                    </div>
                )
            // state 3
            case BRAIN_FOCUS_STATE.ENTRY_LEFT:
                return (
                    <div>
                        <div className={classNames(styles.buttonLeftCombo)}
                            onClick={() => {
                                this.handleClickMove(POSTION.RIGHT);
                                this.setAnimationState(ANIMATION_STATE.threeToTwo);
                            }}><img src={this.state.arrowImg} alt={"arrow"} /></div>
                        <div className={classNames(styles.buttonRightCombo)}
                            onClick={() => {
                                this.handleClickSwitchBrain();
                                this.setAnimationState(ANIMATION_STATE.threeToOne);
                            }}>
                            <img className={classNames(styles.buttonSwitchSize)} src={this.state.switchImg} alt={"switch"} /></div>
                    </div>
                )

        }
    }

    getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }

    getBrainUI() {
        let state = this.getFocusStatus();
        var deviceList = parseDeviceList(this.props.vm.getDeviceList());
        let eduDeviceList = deviceList.slice(0, 8)
        let entryDeviceList = deviceList.slice(8)
        const { vm } = this.props
        switch (state) {
            case BRAIN_FOCUS_STATE.EDU:
                return (
                    <div>
                        <EduBrain
                            isFocusEntry={this.isFocusEntry()}
                            eduDeviceList={eduDeviceList}
                            brainTopPostion={styles.brainTopEduCombo}
                            brainLeftPostion={styles.brainLeftEduCombo}
                            animation={this.getBrainAnimation(BRAIN_TYPE.EDU)}
                            vm={vm} />
                        <EntryBrain
                            isFocusEntry={this.isFocusEntry()}
                            isBrainLeft={this.isBrainLeft()}
                            entryDeviceList={entryDeviceList}
                            brainTopPostion={styles.brainTopEntryCombo}
                            brainLeftPostion={styles.brainLeftSideRightEntryCombo}
                            animation={this.getBrainAnimation(BRAIN_TYPE.ENTRY)}
                            vm={vm} />
                    </div>
                )
            case BRAIN_FOCUS_STATE.ENTRY_RIGHT:
                return (
                    <div>
                        <EduBrain
                            isFocusEntry={this.isFocusEntry()}
                            eduDeviceList={eduDeviceList}
                            brainTopPostion={styles.brainTopEduCombo}
                            brainLeftPostion={styles.brainLeftSideLeftEduCombo}
                            animation={this.getBrainAnimation(BRAIN_TYPE.EDU)}
                            vm={vm} />
                        <EntryBrain
                            isFocusEntry={this.isFocusEntry()}
                            isBrainLeft={this.isBrainLeft()}
                            entryDeviceList={entryDeviceList}
                            brainTopPostion={styles.brainTopEntryCombo}
                            brainLeftPostion={this.isBrainLeft() ? styles.brainLeftEntryLeftCombo : styles.brainLeftEntryRightCombo}
                            animation={this.getBrainAnimation(BRAIN_TYPE.ENTRY)}
                            vm={vm} />
                    </div>
                )
            case BRAIN_FOCUS_STATE.ENTRY_LEFT:
            default:
                return (
                    <div>
                        <EduBrain
                            isFocusEntry={this.isFocusEntry()}
                            eduDeviceList={eduDeviceList}
                            brainTopPostion={styles.brainTopEduCombo}
                            brainLeftPostion={styles.brainLeftSideRightEduCombo}
                            animation={this.getBrainAnimation(BRAIN_TYPE.EDU)}
                            vm={vm} />
                        <EntryBrain
                            isFocusEntry={this.isFocusEntry()}
                            isBrainLeft={this.isBrainLeft()}
                            entryDeviceList={entryDeviceList}
                            brainTopPostion={styles.brainTopEntryCombo}
                            brainLeftPostion={this.isBrainLeft() ? styles.brainLeftEntryLeftCombo : styles.brainLeftEntryRightCombo}
                            animation={this.getBrainAnimation(BRAIN_TYPE.ENTRY)}
                            vm={vm} />
                    </div>
                )
        }
    }
    getBrainAnimation(brainType) {
        // return null;
        let state = this.state.animationState;
        switch (state) {
            case ANIMATION_STATE.oneToTwo:
                return brainType == BRAIN_TYPE.EDU ? styles.animationEdu1to2 : styles.animationEntry1to2;
            case ANIMATION_STATE.twoToThree:
                return brainType == BRAIN_TYPE.EDU ? styles.animationEdu2to3 : styles.animationEntry2to3;
            case ANIMATION_STATE.threeToOne:
                return brainType == BRAIN_TYPE.EDU ? styles.animationEdu3to1 : styles.animationEntry3to1;
            case ANIMATION_STATE.threeToTwo:
                return brainType == BRAIN_TYPE.EDU ? styles.animationEdu3to2 : styles.animationEntry3to2;
            case ANIMATION_STATE.twoToOne:
                return brainType == BRAIN_TYPE.EDU ? styles.animationEdu2to1 : styles.animationEntry2to1;
            // For auto switch
            case ANIMATION_STATE.oneToThree:
                return brainType == BRAIN_TYPE.EDU ? styles.animationEdu1to3 : styles.animationEntry1to3;
            default:
                return null;
        }
    }

    render() {
        const {
            vm,
        } = this.props;
        var deviceList = parseDeviceList(vm.getDeviceList());
        return (
            <div>
                {this.props.isDeleteDeviceMode ?
                    <div>
                        <div className={classNames((this.props.isDeviceSelected) ? styles.trashcan : styles.trashcanDisable,
                            (this.props.isDeviceSelected) ? styles.trashcanDiff : styles.trashcanDisableDiff)} onClick={() => this.handleClickTrashcan(deviceList)}>
                            <FormattedMessage id={messagesId.delete} />
                        </div>
                        <div className={classNames(styles.closeDeleteMode, styles.closeDeleteModeDiff)} onClick={() => this.handleClickBack()}>
                            <FormattedMessage id={messagesId.cancel} />
                        </div>
                        <div className={classNames(styles.selectAll, styles.selectAllDiff)} onClick={() => this.handleClickSelectAll(deviceList)}>
                            <FormattedMessage id={messagesId.selectAll} />
                        </div>
                    </div> :
                    <div className={classNames((this.isTrashcanEnable()) ? styles.trashcan : styles.trashcanDisable,
                        (this.isTrashcanEnable()) ? styles.trashcanDiff : styles.trashcanDisableDiff)} onClick={() => this.handleClickTrashcan(deviceList)}>
                        <FormattedMessage id={messagesId.edit} />
                    </div>}
                {this.getButtonUI()}
                {this.getBrainUI()}
            </div >
        );
    }
}

const EntryBrain = (props) => {
    const {
        entryDeviceList,
        isBrainLeft,
        isFocusEntry,
        brainTopPostion,
        brainLeftPostion,
        animation,
        vm
    } = props;
    return (
        <div className={classNames(brainTopPostion, brainLeftPostion, animation)}>
            {/* entry */}
            <div className={classNames(styles.brainEntryImg)}>
                <img src={entryBrainImg} className={classNames(styles.brainEntrySize)} alt={"entry brain"} />
            </div>
            <div className={classNames(styles.portEntryArea, (isBrainLeft) ? styles.portAreaLeftLeft : styles.portAreaRightLeft)}>
                {(isFocusEntry) ?
                    <div>
                        <div className={classNames(styles.portEntry1, styles.portEntryPosition)}>
                            <Port index={0} connectedDeviceList={entryDeviceList} hide={isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry2, styles.portEntryPosition)}>
                            <Port index={1} connectedDeviceList={entryDeviceList} hide={isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry3, styles.portEntryPosition)}>
                            <Port index={2} connectedDeviceList={entryDeviceList} hide={isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry4, styles.portEntryPosition)}>
                            <Port index={3} connectedDeviceList={entryDeviceList} hide={isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry5, styles.portEntryPosition)}>
                            <Port index={4} connectedDeviceList={entryDeviceList} hide={isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry6, styles.portEntryPosition)}>
                            <Port index={5} connectedDeviceList={entryDeviceList} hide={isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <EntryLine reverse={false} />
                    </div> : null}
                <EntryPoint deviceList={entryDeviceList} />
            </div>
            <div className={classNames(styles.portEntryArea, (isBrainLeft) ? styles.portAreaLeftRight : styles.portAreaRightRight)}>
                {(isFocusEntry) ?
                    <div>
                        <div className={classNames(styles.portEntry1, styles.portEntryPosition)}>
                            <Port index={7} connectedDeviceList={entryDeviceList} hide={!isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry2, styles.portEntryPosition)}>
                            <Port index={8} connectedDeviceList={entryDeviceList} hide={!isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry3, styles.portEntryPosition)}>
                            <Port index={9} connectedDeviceList={entryDeviceList} hide={!isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry4, styles.portEntryPosition)}>
                            <Port index={10} connectedDeviceList={entryDeviceList} hide={!isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry5, styles.portEntryPosition)}>
                            <Port index={11} connectedDeviceList={entryDeviceList} hide={!isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry6, styles.portEntryPosition)}>
                            <Port index={12} connectedDeviceList={entryDeviceList} hide={!isBrainLeft} isBrainLeft={isBrainLeft} vm={vm} />
                        </div>
                        <EntryLine reverse={true} />
                    </div>
                    : null}
                <EntryPoint deviceList={entryDeviceList.slice(7)} />
                <TypeCPort index={6} connectedDeviceList={entryDeviceList} hide={false} isFirst={true} vm={vm} />
                <TypeCPort index={13} connectedDeviceList={entryDeviceList} hide={false} isFirst={entryDeviceList[6].id == ""} vm={vm} />
            </div>
        </div>
    )
}

const EduBrain = (props) => {
    const {
        isFocusEntry,
        eduDeviceList,
        brainTopPostion,
        brainLeftPostion,
        animation,
        vm
    } = props;
    return (
        <div className={classNames(brainTopPostion, brainLeftPostion, animation)}>
            {/* edu */}
            <div className={classNames(styles.brainEduImg)}>
                <img src={eduBrainImg} className={classNames(styles.brainEduSize)} alt={'edu brain'} />
            </div>
            <div className={classNames(styles.portArea, styles.portAreaDiff)}>
                {(!isFocusEntry) ?
                    <div>
                        <div className={classNames(styles.portEdu1, styles.portPosition)}>
                            <Port index={0} connectedDeviceList={eduDeviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu2, styles.portPosition)}>
                            <Port index={1} connectedDeviceList={eduDeviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu3, styles.portPosition)}>
                            <Port index={2} connectedDeviceList={eduDeviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu4, styles.portPosition)}>
                            <Port index={3} connectedDeviceList={eduDeviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu5, styles.portPosition)}>
                            <Port index={4} connectedDeviceList={eduDeviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu6, styles.portPosition)}>
                            <Port index={5} connectedDeviceList={eduDeviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu7, styles.portPosition)}>
                            <Port index={6} connectedDeviceList={eduDeviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu8, styles.portPosition)}>
                            <Port index={7} connectedDeviceList={eduDeviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <EduLine />
                    </div>
                    : null}
                <EduPoint deviceList={eduDeviceList} />
            </div>
        </div>
    )
}

EduEntryBrain.propTypes = {
    isEditPageOpen: PropTypes.bool,
    isAddPageOpen: PropTypes.bool,
    isDeleteDeviceMode: PropTypes.bool,
    isDeviceListOpen: PropTypes.bool,
    getSelectedDeviceIdArray: PropTypes.array,
    vm: PropTypes.instanceOf(VM).isRequired,
};


const mapStateToProps = state => ({
    isEditPageOpen: isEditPageOpen(state),
    isAddPageOpen: isAddPageOpen(state),
    isDeleteDeviceMode: isDeleteDeviceMode(state),
    getSelectedDeviceIdArray: getSelectedDeviceIdArray(state),
    isDeviceSelected: isDeviceSelected(state),
    isDeviceListOpen: isPortOpen(state),
    getSelectedTab: getSelectedTab(state),
    isCheckingImportDevice: isCheckingImportDevice(state),
    isVisionSettingDialogShow: isVisionSettingDialogShow(state),
    getPortArray: getPortArray(state),
    getUIStyle: getUIStyle(state),
    colorDataDefaultList: getColorDataDefaultList(state)
});

const mapDispatchToProps = dispatch => ({
    closeDelete: () => dispatch(closeDeleteMode()),
    openDelete: () => dispatch(openDeleteMode()),
    cleanSeletedDevice: () => dispatch(cleanSeletedDevice()),
    seletDevice: (array) => dispatch(selectDeviceToDelete(array)),
    openDeviceList: (number) => dispatch(openDeviceList(number)),
    cleanPortOption: () => dispatch(cleanPortOption()),
    cleanPortArray: () => dispatch(setPortArray([])),
    clearSkillList: () => dispatch(clearSkillList()),
    clearSkillLocation: () => dispatch(clearSkillLocation()),
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(EduEntryBrain));