import looksBlocks from '../block-vertical-blocks/looks';

export default function looksEDU(deviceInfo, blockSeparator) {

    return `
        ${deviceInfo.ledInfo.hasled ? `
            <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
                ${deviceInfo.ledInfo.hasled ? `
                    ${looksBlocks.looks_setlight}
                `: ``}
            </category>`: ``
        }
    `;
};