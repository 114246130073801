export class Stack {
    constructor()
    {
        this.items = [];
    }

    push(element) {
        this.items.push(element);
    }

    pop() {
        if (this.isEmpty()) {
            return null;
        }
        return this.items.pop();
    }

    peek() {
        if (this.isEmpty()) {
            return null;
        }
        return this.items[this.items.length - 1];
    }

    isEmpty() {
        return this.items.length === 0;
    }

    clear() {
        this.items = [];
    }

    size() {
        return this.items.length;
    }

    getContentAsString() {
        let res = '';
        for (let i = 0; i < this.items.length; ++i) {
            res += this.items[i];
        }
        return res;
    }

    print() {
        console.log("stack: " + this.items.toString());
    }
}