import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import dialogStyles from '../dialog.css';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import log from '../../../lib/log';
import { DEFAULT_LOCALE } from '../../../config/project-config';
import {
    hideSpeakerSkillDialog,
} from '../../../reducers/dialog';
import {
    setSkillList,
    getSkillList,
    setSkillLocation,
    getSkillLocation,
} from '../../../reducers/speaker';
import styles from './speaker-dialog.css'
import VM from 'scratch-vm';
import { SpeakerSkillSentence, SpeakerCityMapTable, SpeakerSupportCity } from './skill-sentence'

import alarmIcon from '../svg/skill/skill-alarm-icon.svg'
import calculatorIcon from '../svg/skill/skill-calculator-icon.svg'
// import converterIcon from '../svg/skill/skill-converter-icon.svg'
import timeIcon from '../svg/skill/skill-time-icon.svg'
import translatorIcon from '../svg/skill/skill-translator-icon.svg'
import weatherIcon from '../svg/skill/skill-weather-icon.svg'
import writeIcon from '../svg/skill/skill-write-icon.svg'
import jokeIcon from '../svg/skill/skill-joke-icon.svg'
import commonIcon from '../svg/skill/skill-common-icon.svg'
const store = require('store');
const SKILL = {
    common: "common",
    weather: "weather",
    translator: "translator",
    calculator_converter: "calculator_converter",
    alarm: "alarm",
    calculator: "calculator",
    write: "write",
    time: "time",
    joke: "joke",
}

const TITLE_HEIGHT = 89;

const DIALOG_HIGH_WIDTH = 735;
const DIALOG_HIGH_HEIGHT = 715;

const DIALOG_LOW_WIDTH = 726;
const DIALOG_LOW_HEIGHT = 609;

let resizeFunc = () => { };
class SpeakerSkillDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = {
            translateX: (window.innerWidth - (window.innerWidth >= 1260 ? DIALOG_HIGH_WIDTH : DIALOG_LOW_WIDTH)) / 2,
            translateY: (window.innerHeight - (window.innerWidth >= 1260 ? DIALOG_HIGH_HEIGHT : DIALOG_LOW_HEIGHT)) / 2,
            enableList: Array.from(this.props.getSkillList),
            allSkill: this.parseAllSkill(),
            location: this.props.getSkillLocation,
            isLocationExpanded: false,
        };
    }

    componentDidMount() {
        resizeFunc = e => {
            this.setState({
                translateX: (window.innerWidth - (window.innerWidth >= 1260 ? DIALOG_HIGH_WIDTH : DIALOG_LOW_WIDTH)) / 2,
                translateY: (window.innerHeight - (window.innerWidth >= 1260 ? DIALOG_HIGH_HEIGHT : DIALOG_LOW_HEIGHT)) / 2,
            })
        }
        window.addEventListener('resize', resizeFunc);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', resizeFunc);
    }

    getIcon(skill) {
        switch (skill) {
            case SKILL.weather:
                return weatherIcon
            case SKILL.translator:
                return translatorIcon
            case SKILL.calculator_converter:
                return calculatorIcon
            case SKILL.alarm:
                return alarmIcon
            case SKILL.write:
                return writeIcon
            case SKILL.time:
                return timeIcon
            case SKILL.joke:
                return jokeIcon
            case SKILL.common:
                return commonIcon
        }
    }

    getCity(cityKey) {
        const language = store.get("locale", DEFAULT_LOCALE);
        return SpeakerCityMapTable[language][cityKey];
    }

    getContent() {
        return <div className={classNames(styles.skillContentArea)}>
            <div className={classNames(styles.skillLocationArea)}>
                <div className={classNames(styles.skillLocationTitle)}>
                    <FormattedMessage
                        id={"gui.deviceManagerStage.speaker.skill.location.default"} />
                </div>
                <div className={classNames(styles.skillLocationSelectedArea)}
                    onClick={() => this.onClickLocation()}>
                    <div className={classNames(styles.skillLocationSelected)}>
                        {this.getCity(this.state.location)}
                    </div>
                    <div className={classNames(styles.skillLocationArrow)} />
                    {this.state.isLocationExpanded ?
                        <div className={classNames(styles.skillLocationOptionArea)}>
                            {SpeakerSupportCity.map((location, index) => {
                                return <div className={classNames(styles.skillLocationOption)}
                                    key={index}
                                    onClick={() => this.onSelectLocation(location)}>
                                    {this.getCity(location)}
                                </div>
                            })}
                        </div>
                        : null}
                </div>
                <div className={classNames(styles.skillLocationReminder)}>
                    <FormattedMessage
                        id={"gui.deviceManagerStage.speaker.skill.location.reminder"} />
                </div>


            </div>
            <div className={classNames(styles.skillListArea)}>
                {this.state.allSkill.map((skill, index) =>
                    <SkillItem
                        onClickEnableSkill={(skill) => this.onClickEnableSkill(skill)}
                        isSkillEnble={this.state.enableList.includes(skill.name)}
                        title={skill.content.zh}
                        skill={skill.name}
                        exampleList={skill.content.sentence}
                        key={index}
                        icon={this.getIcon(skill.name)}
                    />)}
            </div>
        </div>
    }

    onClickLocation() {
        this.setState({ isLocationExpanded: !this.state.isLocationExpanded })
    }

    onSelectLocation(location) {
        this.setState({ location: location })
        this.closeLocation()
    }

    closeLocation() {
        if (this.state.isLocationExpanded) this.setState({ isLocationExpanded: false })
    }

    parseAllSkill() {
        let array = [];
        for (let key in SpeakerSkillSentence) {
            array.push({
                name: key,
                content: SpeakerSkillSentence[key]
            })
        }
        return array;
    }

    onClickEnableSkill(skill) {
        let list = this.state.enableList;
        if (!list.includes(skill)) {
            list.push(skill);
        } else {
            list = list.filter(s => s != skill);
        }
        this.setState({ enableList: list })
        this.closeLocation()
    }

    onClickCancel() {
        this.loadEnableSkill();
        this.props.hideSpeakerSkillDialog();
    }

    onClickConfirm() {
        this.props.setSkillList(this.state.enableList)
        this.props.setSkillLocation(this.state.location)
        this.props.hideSpeakerSkillDialog();
    }

    loadEnableSkill() {
        this.setState({
            enableList: Array.from(this.props.getSkillList),
            location: this.props.getSkillLocation
        })
    }

    render() {
        return (
            <div className={classNames(dialogStyles.backdropStyle)}>
                <div className={classNames(dialogStyles.floatStyle)}
                    style={{ top: this.state.translateY + 'px', left: this.state.translateX + 'px' }}>
                    <div className={classNames(dialogStyles.floatModalStyle, styles.skillDialogSize)}>
                        <div
                            className={classNames(dialogStyles.helpHeader)}
                            style={{ height: TITLE_HEIGHT + "px", padding: 0 + "px" }}
                        >
                            <div className={classNames(styles.speakerSkillTitleArea)}
                                style={{ height: TITLE_HEIGHT + "px" }}>
                                <div className={classNames(styles.speakerSkillTitle)}>
                                    <FormattedMessage
                                        id={"gui.deviceManagerStage.speaker.skill.title"}
                                    />
                                </div>
                                <div className={classNames(styles.speakerSkillReminder)}>
                                    <FormattedMessage
                                        id={"gui.deviceManagerStage.speaker.skill.title.reminder"}
                                    />
                                    <br />
                                    <FormattedMessage
                                        id={"gui.deviceManagerStage.speaker.skill.title.reminder2"}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.getContent()}
                        <div className={classNames(styles.buttonArea)}>
                            <div className={classNames(styles.cancelButton, styles.skillCancelButton)}
                                onClick={() => this.onClickCancel()}>
                                <FormattedMessage
                                    id={'gui.deviceManagerStage.page.cancel'}
                                />
                            </div>
                            <div className={classNames(styles.confirmButton, styles.skillConfirmButton)}
                                onClick={() => {
                                    this.onClickConfirm()
                                }
                                }>
                                <FormattedMessage
                                    id={'gui.dialog.confirm'}
                                />
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        );
    }
}

const SwitchButton = (props) => {
    return <div className={classNames((props.enble)
        ? styles.switchButtonEnable
        : styles.switchArea)}
        onClick={() => { props.enableSkill(props.skill) }} />
}

const SkillItem = (props) => {
    const {
        onClickEnableSkill,
        isSkillEnble,
        skill,
        title,
        exampleList,
        icon
    } = props;
    return <div className={classNames(styles.skillItemArea)}>
        <div className={classNames(styles.skillSwitchPosition)}>
            <SwitchButton
                enableSkill={(skill) => onClickEnableSkill(skill)}
                enble={isSkillEnble}
                skill={skill}
            /></div>
        <div className={classNames(styles.skillItemContentArea)}>
            <img src={icon} className={classNames(styles.skillItemIcon)} />
            <div className={classNames(styles.skillItemTitle)}>{title}</div>
            <div className={classNames(styles.skillItemSentenceArea)}>
                {exampleList.map((sentence, index) => {
                    return <div className={classNames(styles.skillItemSentence)} key={index}>
                        {sentence}
                    </div>
                })}</div>
        </div>

    </div>
}


SpeakerSkillDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    getSkillLocation: getSkillLocation(state) || "Taipei",
    getSkillList: getSkillList(state) || [],
});

const mapDispatchToProps = dispatch => ({
    hideSpeakerSkillDialog: () => dispatch(hideSpeakerSkillDialog()),
    setSkillList: (list) => dispatch(setSkillList(list)),
    setSkillLocation: (location) => dispatch(setSkillLocation(location)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpeakerSkillDialog);