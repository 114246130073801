import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './slot-info.css';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import {
    selectSlot,
    getSlotList,
    getSelectedSlot
} from '../../reducers/slot-list';
import {
    BRAIN_STATUS_TYPE,
    getBrainStatus
} from '../../reducers/brain';
import classNames from 'classnames';
import deleteIcon from './svg/slot_delete.svg';
import { getPickedBrainType } from '../../reducers/picked-brain-type'
import { showQuestionDeleteSlotDialog, showErrorDialog, errorType } from '../../reducers/dialog'
import { getPlatform, platformType } from '../../lib/platform';

const LOW_RES_WINDOW_WIDTH = 1399;
const HIGH_RES_NAME_MAX_WIDTH = 242;
const LOW_RES_NAME_MAX_WIDTH = 196;
const NAME_LIST_DISTANCE = 94;
const SPACE = 5;

class SlotInfo extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'addListeners',
            'removeListeners',
            'handleClick',
            'ref'
        ]);
        this.state = {
            pickedBrainType: this.props.getPickedBrainType,
            slotSelected: this.props.getSelectedSlot,
            nameWidth: 0,
            windowWidth: window.innerWidth
        }
    }

    componentDidMount() {
        this.calculateNameWidth();
        window.addEventListener('resize', () => {
            this.setState({ windowWidth: window.innerWidth })
            this.calculateNameWidth()
        });
        if (this.props.show) this.addListeners();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => {
            this.setState({ windowWidth: window.innerWidth })
            this.calculateNameWidth()
        });
        this.removeListeners();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.props.getSlotList != nextProps.getSlotList) ||
            (this.props.getSelectedSlot != nextProps.getSelectedSlot) ||
            (this.props.getPickedBrainType != nextProps.getPickedBrainType) ||
            (this.state.nameWidth != nextState.nameWidth) ||
            (this.state.windowWidth != nextState.windowWidth) ||
            (this.props.show != nextProps.show)
    }

    componentDidUpdate(prevProps) {
        if (this.props.getSelectedSlot != prevProps.getSelectedSlot) {
            this.setState({ slotSelected: this.props.getSelectedSlot })
        }
        if (this.props.getPickedBrainType != prevProps.getPickedBrainType) {
            this.setState({
                pickedBrainType: this.props.getPickedBrainType,
                slotSelected: this.props.getSelectedSlot
            })
        }
        if (this.props.getSlotList != prevProps.getSlotList) {
            this.calculateNameWidth()
        }
        if (this.props.show && !prevProps.show) this.addListeners();
        if (!this.props.show && prevProps.show) this.removeListeners();
    }

    handleClickDelete(slotIndex) {
        this.props.close();
        if (this.props.brainStatus == BRAIN_STATUS_TYPE.BLUETOOTH_DOWNLOAD_PHONE_SCRIPT ||
            this.props.brainStatus == BRAIN_STATUS_TYPE.BLUETOOTH_DOWNLOAD_BLOCKLY_SCRIPT) {
            this.props.onShowBrainStatusModeErrorDialog();
            return;
        } else {
            this.props.showQuestionDeleteSlotDialog(slotIndex);
        }
    }

    handleSelectItem(slotIndex) {
        if (getPlatform() == platformType.Desktop) {
            this.props.onClickSetWriteSlotNumber(slotIndex + 1);
        }
        this.props.selectSlot(slotIndex);
        this.props.close();
    }

    isSelected(slotIndex) {
        return slotIndex == this.props.getSelectedSlot;
    }

    calculateNameWidth() {
        let limitWidth = (window.innerWidth > LOW_RES_WINDOW_WIDTH) ? HIGH_RES_NAME_MAX_WIDTH : LOW_RES_NAME_MAX_WIDTH;
        let maxNameLength = 0;
        let maxName = "";
        this.props.getSlotList.forEach(name => {
            if (name.length > maxNameLength) {
                maxNameLength = name.length;
                maxName = name;
            }
        })
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.font = "16px 微軟正黑體";
        let maxWidth = ctx.measureText(maxName).width + SPACE;
        this.setState({ nameWidth: (maxWidth < limitWidth) ? maxWidth : limitWidth })
    }

    getTabList() {
        return <div>
            {this.props.getSlotList.map((location, index) =>
                <div
                    key={index}
                    className={classNames(
                        styles.item,
                        (this.isSelected(index)) ? styles.itemSelectedColor : styles.itemColor
                    )}
                    onClick={() => this.handleSelectItem(index)}>
                    <div className={classNames(styles.slotIndex, (this.isSelected(index)) ? styles.active: null)}>
                        {index + 1}
                    </div>
                    <div className={classNames(styles.projectName, styles.projectNameDiff)} >
                        {(location && location != "") ? location : ""}
                    </div>
                    {(location && location != "") ?
                        <div className={classNames(styles.delete)}
                            onClick={() => this.handleClickDelete(index)}>
                            <img src={deleteIcon} />
                        </div> :
                        null}
                </div>
            )}
        </div>
    }

    addListeners() {
        document.addEventListener('mouseup', this.handleClick);
    }

    removeListeners() {
        document.removeEventListener('mouseup', this.handleClick);
    }

    handleClick(e) {
        if (this.props.show && !this.slot.contains(e.target)) {
            this.props.close();
        }
    }

    ref(c) {
        this.slot = c;
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div
                className={classNames(styles.slotList)}
                ref={this.ref}
            >
                {this.getTabList()}
            </div>
        );
    }
}

SlotInfo.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func,
    handleEraseSlot: PropTypes.func,
    onClickSetWriteSlotNumber: PropTypes.func,
};

const mapStateToProps = state => ({
    getSlotList: getSlotList(state),
    getSelectedSlot: getSelectedSlot(state),
    getPickedBrainType: getPickedBrainType(state),
    brainStatus: getBrainStatus(state),
});

const mapDispatchToProps = dispatch => ({
    selectSlot: (slotIndex) => dispatch(selectSlot(slotIndex)),
    showQuestionDeleteSlotDialog: (slotIndex) => dispatch(showQuestionDeleteSlotDialog(slotIndex)),
    onShowBrainStatusModeErrorDialog: (msg) => dispatch(showErrorDialog(errorType.BRAIN_STATUS_MODE_ERROR, msg)),
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SlotInfo);