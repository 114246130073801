import store from 'store';

const platformType = {
    Web: "web",
    Desktop: "desktop",
    iPad: "iPad",
    RN: "RN"
}

function getPlatform() {
    return store.get("platform", platformType.Web);
}

function isPad() {
    let platform = store.get("platform", platformType.Web)
    return platform == platformType.iPad || platform == platformType.RN;
}

function isReactNative() {
    let platform = store.get("platform", platformType.Web)
    return platform == platformType.RN;
}

export {
    platformType,
    getPlatform,
    isPad,
    isReactNative
}