const USER_GUIDE_STATE = {
    WELCOME: 0,
    BLOCK_GUIDE: 1,
    HOW_TO_USE_BLOCK: 2,
    ADD_BLOCK: 3,
    ADD_BLOCK_DRAGGING: 4,
    ADD_BLOCK_FINISH: 5,
    DELETE_BLOCK: 6,
    DELETE_BLOCK_DRAGGING: 7,
    DEVICE_MANAGER_GUIDE: 8,
    NEW_DEVICE: 9,
    NEW_DEVICE_SELECT_PORT: 10,
    NEW_DEVICE_FINISH: 11,
    LET_DEVICE_WORK: 12,
    LET_DEVICE_WORK_DRAGGING: 13,
    LET_DEVICE_WORK_FINISH: 14,
    INTRO_BRAIN: 15,
    RECONNECT: 16,
    DISCONNECT: 17,
    INTRO_SLOT_DISCONNECT: 18,
    INTRO_DOWNLOAD_AND_RUN: 19,
    INTRO_SLOT: 20,
    DOWNLOAD_AND_RUN: 21,
    DOWNLOADING: 22,
    DOWNLOAD_FAIL: 23,
    FINISH: 24,
    OPEN_BLUETOOTH_SETTING: 25,
    BLUETOOTH_CONNECTING: 26,
    VR_TOOLBAR_GUIDE: 27,
    VR_SELECT_SCENE_AND_EDIT: 28,
    VR_PUT_OBJECT: 29,
    VR_PUT_TERRAIN: 30,
    VR_DRAW_TRACE: 31,
    VR_INTRO_SENSORS: 32,
    WIFI_CONNECTING: 33,
};

export {
    USER_GUIDE_STATE
}