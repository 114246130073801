import * as monaco from 'monaco-editor';
import classNames from 'classnames';
import React from "react";
import { render } from "react-dom";
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import MonacoEditor from 'react-monaco-editor';
import Converter from 'Converter';
import log from '../../lib/log.js';
import styles from './code-viewer.css';
import { FormattedMessage } from 'react-intl';

import {
    questionType,
    showQuestionDialog
} from '../../reducers/dialog';

import {
    getWorkspace
} from '../../reducers/block';

import {
    setCodeView,
    setHighLightMode,
    getCode,
    getCodeViewState,
    getHighLightMode,
    getHighLightBlockId,
    getHighLightField
} from '../../reducers/code-editor';
import { EDIT_MODE } from '../../lib/edit-mode.js';
import theme from './theme.js';

import block2pyIcon from './svg/block2py.svg';
import closeIcon from './svg/close.svg';
import { EditUtils } from '../device-manager/edit-page/edit-utils.js';

let editor = null;

class CodeViewer extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'editorDidMount',
            'editorWillMount',
            'onChange',
            'handleClickCloseCodeViewer',
            'handleClickCodeEditor',
            'updateHighLighCode',
            'handleClearWorkspace',
            'refCodeViewer'
        ]);
        this.state = {
            selected: 0,
            themeName: "myCustomTheme"
        };
    }

    onChange(newValue) {
    }

    editorDidMount(e, m) {
        editor = e;
    }

    editorWillMount(monaco) {
        monaco.editor.defineTheme('myCustomTheme', theme);
    }

    componentDidUpdate(prevProps) {
        if (this.props.getCodeViewState != prevProps.getCodeViewState && this.props.getCodeViewState) {
            this.codeViewer.addEventListener('mouseup', this.handleClearWorkspace);
        } else if (this.props.getCodeViewState != prevProps.getCodeViewState && !this.props.getCodeViewState) {
            this.codeViewer.removeEventListener('mouseup', this.handleClearWorkspace);
        }
        if (this.props.getHighLightMode) {
            this.updateHighLighCode();
        }
    }

    updateHighLighCode() {
        if (editor) {
            editor.setValue(this.props.code);
            let wsCode = JSON.parse(this.props.getVM.getProjectJson());
            if (wsCode.targets[0] && wsCode.targets[0].blocks) {
                const generator = new Converter(wsCode);
                try {
                    generator.workspaceToCode();
                    if (this.props.highHightBlockId) {
                        console.log("highHightBlockId = ", this.props.highHightBlockId);
                        let codeLines = generator.getHighlightPosition(this.props.highHightBlockId);
                        let fieldLines = [];
                        if (this.props.highHightField) {
                            fieldLines = generator.getHighlightPosition(this.props.highHightBlockId, this.props.highHightField);
                        }
                        console.log("codeLines = ", codeLines);
                        codeLines.forEach(item => {
                            let highlightArray = [{
                                range: new monaco.Range(item.line + 1, item.position[0] + 1, item.line + 1, item.position[1] + 1), options: {
                                    inlineClassName: styles.highligh
                                }
                            }];
                            editor.deltaDecorations([], highlightArray);
                        })
                        fieldLines.forEach(item => {
                            let highlightArray = [{
                                range: new monaco.Range(item.line + 1, item.position[0] + 1, item.line + 1, item.position[1] + 1), options: {
                                    inlineClassName: styles.field
                                }
                            }];
                            editor.deltaDecorations([], highlightArray);
                        })
                    }
                } catch (e) {
                    console.log('ConverterError: ', e)
                }
            }
        }
    }

    handleClickCloseCodeViewer() {
        this.props.setCodeView(false);
        this.props.setHighLightMode(false);
    }

    handleClickCodeEditor() {
        if (this.props.projectChanged) {
            this.props.showOpenEditorSaveDialog();
        } else {
            this.props.showOpenEditorDialog();
        }
    }

    handleClearWorkspace() {
        this.props.getWorkspace.onClearWidget();
    }

    refCodeViewer(c) {
        this.codeViewer = c;
    }

    render() {
        const {
            themeName
        } = this.state;

        const options = {
            selectOnLineNumbers: true,
            roundedSelection: false,
            readOnly: true,
            cursorStyle: "line",
            automaticLayout: true,
            autoIndent: true,
            formatOnPaste: true,
            formatOnType: true,
            minimap: {
                enabled: false
            },
            contextmenu: false
        };

        return (
            <div className={
                classNames(
                    styles.codeViewerBody,
                    this.props.getCodeViewState ? null : styles.hidden
                )}
                ref={this.refCodeViewer}
            >
                <div className={styles.codeViewHeader}>
                    <div className={classNames(styles.buttonBorder, styles.codeeditor)}>
                        <div className={classNames(styles.button, styles.codeeditor)} onClick={this.handleClickCodeEditor}>
                            <FormattedMessage
                                defaultMessage="Open code editor"
                                description="Open code editor"
                                id="gui.codeview.codeEditor"
                            />
                            <img className={styles.icon} src={block2pyIcon} alt={EditUtils.getLocaleString('gui.codeview.codeEditor')} />
                        </div>
                    </div>
                    <div className={styles.buttonBorder}>
                        <div className={styles.button} onClick={this.handleClickCloseCodeViewer}>
                            <img src={closeIcon} alt={"close"} />
                        </div>
                    </div>
                </div>
                <div className={styles.font}>
                    <MonacoEditor
                        language="python"
                        value={this.props.code}
                        options={options}
                        onChange={this.onChange}
                        editorDidMount={this.editorDidMount}
                        editorWillMount={this.editorWillMount}
                        theme={themeName}
                    />
                </div>
            </div>
        );
    }

}

CodeViewer.propTypes = {
    code: PropTypes.string,
    displayMode: PropTypes.string,
    getVM: PropTypes.object,
    setCodeView: PropTypes.func,
    getCodeViewState: PropTypes.bool,
    setHighLightMode: PropTypes.func,
    getHighLightMode: PropTypes.bool,
    highHightBlockId: PropTypes.string,
    highHightField: PropTypes.string,
    showOpenEditorDialog: PropTypes.func,
    showOpenEditorSaveDialog: PropTypes.func,
    projectChanged: PropTypes.bool,
    getWorkspace: PropTypes.object
};


const mapStateToProps = state => ({
    code: getCode(state),
    getVM: state.scratchGui.vm,
    getCodeViewState: getCodeViewState(state),
    getHighLightMode: getHighLightMode(state),
    highHightBlockId: getHighLightBlockId(state),
    highHightField: getHighLightField(state),
    projectChanged: state.scratchGui.projectChanged,
    getWorkspace: getWorkspace(state)
});

const mapDispatchToProps = dispatch => ({
    setCodeView: show => dispatch(setCodeView(show)),
    setHighLightMode: show => dispatch(setHighLightMode(show)),
    showOpenEditorDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_OPEN_EDITOR)),
    showOpenEditorSaveDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_OPEN_EDITOR_SAVE))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CodeViewer);