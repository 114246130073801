import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './controller-info.css';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import {
} from '../../reducers/slot-list';

import classNames from 'classnames';

const SN_DISPLAY_LENGTH = 7;

class ControllerInfo extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.props.getControllerInfo != nextProps.getControllerInfo) ||
            (this.props.show != nextProps.show)
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        if (!this.props.show) {return null;}
        return (
            <div className={classNames(styles.controllerInfoDialog)} >
                <span className={classNames(styles.arrow_t_int)}></span>
                <span className={classNames(styles.arrow_t_out)}></span>
                <div className={classNames(styles.item)}>
                    <div className={classNames(styles.title)}>
                        <FormattedMessage
                            defaultMessage="Controller Name"
                            description="Controller Name"
                            id="gui.firmware.controller.name"
                        />
                        </div>
                    <div className={classNames(styles.content)}>
                        {(this.props.controllerInfo.ssn.length >= SN_DISPLAY_LENGTH) ?
                            this.props.controllerInfo.ssn.substr(this.props.controllerInfo.ssn.length - SN_DISPLAY_LENGTH, SN_DISPLAY_LENGTH) :
                            this.props.controllerInfo.ssn}
                    </div>
                </div>
                <div className={classNames(styles.divide)} />
                <div className={classNames(styles.item)}>
                    <div className={classNames(styles.title)}>
                        <FormattedMessage
                            defaultMessage="Firmware Version"
                            description="Firmware Version"
                            id="gui.firmware.brain.firmwareTitle"
                        />
                    </div>
                    <div className={classNames(styles.content)}>{this.props.controllerInfo.ver}</div>
                </div>
            </div>
        );
    }
}

ControllerInfo.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    ssn: PropTypes.string,
    name: PropTypes.string
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ControllerInfo);