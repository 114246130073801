import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import React from 'react';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import { hidePadFileOpenDialog, isPadFileOpenDialogShow } from '../../reducers/dialog.js'
import { getFileList } from '../../reducers/pad-file-list.js'
import eduBrainImg from '../device-manager/pictures/brain_edu.png'
import entryBrainImg from '../device-manager/pictures/brain_entry.png'
import {
    Catagory,
    FileAction,
    postMessage
} from '../../lib/postmessage';
const FILE_TYPE = {
    EDU: ".abot",
    ENTRY: ".bbot",
    EDU_AND_ENTRY: ".cbot"
}

class PadFileOpenDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            select: -1
        }
    }
    handleClickCancel() {
        this.props.close();
    }
    handleClickOpen() {
        if (this.state.select == -1) {
            this.props.close();
            return;
        }
        let fileList = this.props.getFileList;
        if (fileList && Array.isArray(fileList)) {
            let filePath = fileList[this.state.select].path;
            let fileName = (fileList[this.state.select].name).split(".")[0];
            postMessage(Catagory.File, { action: FileAction.load, path: filePath, name: fileName });
            this.props.close();
        }

    }
    handleSelectItem(index) {
        this.setState({ select: index })
    }

    creatFileList() {
        let fileList = this.props.getFileList;
        if (fileList && Array.isArray(fileList)) {
            return fileList.map((file, index) =>
                <div key={index} className={classNames(styles.fileListItem, (this.state.select == index) ? styles.fileListItemSelected : null)} onClick={() => this.handleSelectItem(index)}>
                    <div className={classNames(styles.fileListItemImg)} ><img src={(file.name.includes(FILE_TYPE.EDU) ? eduBrainImg : entryBrainImg)} className={classNames(styles.fileListItemImgSize)} /></div>
                    <div className={classNames(styles.fileListItemName, (this.state.select == index) ? styles.fileListItemNameSelected : null)} >{file.name}</div>
                </div>
            )
        }
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div className={classNames(styles.backdropStyle)}>
                <div className={styles.modalStyle}>
                    <div className={styles.aboutHeader}>
                        <div className={styles.updateTitle}>
                            <FormattedMessage
                                defaultMessage="Open file"
                                description="Open file"
                                id="gui.dialog.brainTypePicker.open"
                            />
                        </div>
                    </div>
                    <div className={classNames(styles.fileListArea)}>
                        {this.creatFileList()}
                    </div>
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={() => this.handleClickCancel()}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={() => this.handleClickOpen()}>
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="question dialog confirm button"
                                id="gui.hint.deleteBlocks.confirm"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PadFileOpenDialog.propTypes = {
};

PadFileOpenDialog.defaultProps = {
}

const mapStateToProps = state => ({
    show: isPadFileOpenDialogShow(state),
    getFileList: getFileList(state)
});

const mapDispatchToProps = dispatch => ({
    close: () => dispatch(hidePadFileOpenDialog())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PadFileOpenDialog);