import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './brain-info-mobile.css';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import {
    Catagory,
    postMessage
} from '../../lib/postmessage';
import {
    BRAIN_STATUS_TYPE,
    DownloadType,
    setBrainConnectStatus,
    setBrainInfo,
    setBrainDownloadingStatus,
    setBrainDownloadAndRun,
    setBrainRunningStatus,
    setBrainScriptErasingStatus,
    getBrainInfo,
    getBrainDownloadType,
    setBluetoothIsOpen,
    setBluetoothStatus,
    getBluetoothStatus,
    setBrainStatus,
    setFirmwareConfig,
    setBrainBtVer,
    setBatteryConnectStatus,
    getBrainModelName,
    setApBrainList,
    getApBrainList,
    setSocketStatus,
    getSocketStatus,
    setEntryDeviceStatus,
    setEntryBrainScreen,
    getBrainConnectStatus,
} from '../../reducers/brain';
import { EditUtils } from '../device-manager/edit-page/edit-utils.js'

import { BRAIN_TYPE } from '../../lib/brains'

import {
    taskType,
    setTaskProgress,
    runTask,
    finishTask
} from '../../reducers/task';

import {
    setSlotList
} from '../../reducers/slot-list';

import {
    getPickedBrainType
} from '../../reducers/picked-brain-type'

import {
    BluetoothStatus,
    BluetoothAction,
    BluetoothDownload
} from '../../lib/bluetooth';

import {
    updateList,
    getList
} from '../../reducers/bluetooth'

import {
    showWifiDirectionDialog,
    showWifiiPadSettingDialog,
    showWifiIpSettingDialog,
    showWhatsNewDialog,
    showQuestionDialog,
    questionType,
    errorType,
    showErrorDialog
} from '../../reducers/dialog';

import loading from './svg/loading.svg';
import refresh from './svg/refresh.svg';
import { SocketAction, SocketStatus } from '../../lib/socket';
import LocalKey from '../../lib/local-storage-key';

import store from 'store';
import { defaultReleaseNotes } from '../dialog/utilities';
import { isVisionConnected, isVisionInitiated, resetVisionInitState, setImageBase64, setImagePixel, setImageTagList, getImageTagList, setVisionColorDefaultListInitiated, setVisionConnected, setVisionInitiated, setVisionInitState, VISION_INIT_STATE_TYPE, setVisionRecongInitiated, setColorDataList, setColorDataDefaultList, setTempFaceFeature, setSelectedColor, setUploadImagePath } from '../../reducers/vision';
import { enableCheckingImportDevice, setRealEntryDeviceList } from '../../reducers/device-manager-controller';
import { setControllerStatus, setControllerResponse } from '../../reducers/controller';
import { CMD_CV_Feedback, cvGetTags, cvInitFaceRecogModule } from '../../lib/cvstream.js';
import { setWifiStatus } from '../../reducers/wifi-list';

class BrainInfoMobile extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleRefresh',
            'handleConnect',
            'handleDisconnect',
            'renderConnectState',
            'handleResetEntry',
            'handleClickBrainMore',
            'handleClickMasterSetting',
            'addListeners',
            'removeListeners',
            'handleClick',
            'ref'
        ]);
        this.state = {
            selected: -1,
            isMorePopup: false,
        };
    }

    componentDidMount() {
        if (window.brainInfoMobile == null)
            window.brainInfoMobile = this;
    }

    componentDidUpdate(preProps, preState) {
        if (preProps.bluetoothStatus != this.props.bluetoothStatus) {
            this.setState({
                selected: -1
            });
            if (this.props.bluetoothStatus == BluetoothStatus.connected) {
                this.props.setConnectState(true);
            }
            else if (this.props.bluetoothStatus == BluetoothStatus.disconnected || this.props.bluetoothStatus == BluetoothStatus.poweredOff) {
                if (this.props.socketStatus == SocketStatus.connected) {
                    return;
                }
                this.props.setConnectState(false);
                this.props.setBrainInfo({});
            }
            else if (this.props.bluetoothStatus == BluetoothStatus.connectfail) {
            }
        }
        if (preProps.socketStatus != this.props.socketStatus) {
            this.setState({
                selected: -1
            });
            if (this.props.socketStatus == SocketStatus.connected) {
                this.props.setConnectState(true);
            } else if (this.props.socketStatus == SocketStatus.disconnected) {
                this.props.setWifiStatus({});
                this.props.setVisionInitiated(false)
                this.props.setVisionColorDefaultListInitiated(false);
                if (this.props.bluetoothStatus == BluetoothStatus.connected) {
                    return;
                }
                this.props.setConnectState(false);
                this.props.setBrainInfo({});
            } else if (this.props.socketStatus == SocketStatus.connectfail) {
            }
        }
        if (this.props.show && !preProps.show) {
            this.addListeners();
            this.handleRefresh();
        }
        if (!this.props.show && preProps.show) this.removeListeners();
    }

    componentWillUnmount() {
    }

    setBluetoothList(list) {
        console.log("setBluetoothList ", list);
        if (typeof list == 'string') {
            list = JSON.parse(list);
        }
        console.log("list ", list);
        this.props.updateList(list);
    }

    setBluetoothStatus(status) {
        if (status == BluetoothStatus.poweredOn) {
            this.props.setBluetoothIsOpen(true);
        } else if (status == BluetoothStatus.poweredOff) {
            this.props.setBluetoothIsOpen(false);
        }

        this.props.setBluetoothStatus(status);
    }

    setBrainInfo(info) {
        if (typeof info == 'string') info = JSON.parse(info);
        console.log("setBrainInfo ", info);
        this.props.setBrainInfo(info);
        this.props.setBrainBtVer(info["bt"]);
        this.props.setBatteryConnectStatus(info["battery"]);
        this.props.enableCheckingImportDevice(false);
        setTimeout(() => {
            this.props.enableCheckingImportDevice(info["brain_type"] != BRAIN_TYPE.EDU);
            if (info["brain_type"] != BRAIN_TYPE.EDU) {
                postMessage(Catagory.Socket, { "action": SocketAction.startPortState });
            }
        }, 1000);
    }

    setBrainType(type) {
        console.log("setBrainType ", type);
        if (this.props.getBrainInfo.brain_type != type) {
            let newBrainInfo = this.props.getBrainInfo;
            newBrainInfo.brain_type = type;
            this.props.setBrainInfo(newBrainInfo);
        }
    }

    setSlotInfo(info) {
        if (typeof info == 'string') info = JSON.parse(info);
        console.log("setSlotInfo ", info);
        this.props.setSlotList(info["res"]);
    }

    setDownloadStatus(status, percentage) {
        console.log("status = ", status, " percentage = ", percentage);
        switch (status) {
            case BluetoothDownload.start:
                this.props.setBrainDownloadingStatus(true);
                this.props.runTask(taskType.DOWNLOAD_SCRIPT);
                break;
            case BluetoothDownload.downloading:
                this.props.setTaskProgress(percentage, taskType.DOWNLOAD_SCRIPT);
                break;
            case BluetoothDownload.end:
                this.props.setBrainDownloadingStatus(false);
                this.props.setBrainScriptErasingStatus(false);
                this.props.finishTask(taskType.DOWNLOAD_SCRIPT);
                break;
        }
    }

    setBlocklyStatus(status) {
        this.props.setBrainStatus(status);
        switch (status) {
            case BRAIN_STATUS_TYPE.IDLE:
            case BRAIN_STATUS_TYPE.RFID_MODE:
            case BRAIN_STATUS_TYPE.BLUETOOTH_MODE:
            case BRAIN_STATUS_TYPE.DOWNLOAD_SCRIPT:
            case BRAIN_STATUS_TYPE.BLUETOOTH_DOWNLOAD_PHONE_SCRIPT:
            case BRAIN_STATUS_TYPE.BLUETOOTH_DOWNLOAD_BLOCKLY_SCRIPT:
                this.props.setBrainRunningStatus(false);
                break;
            case BRAIN_STATUS_TYPE.RUNNING:
                this.props.setBrainRunningStatus(true);
                break;
        }
    }

    setFirmwareConfig(config) {
        if (typeof config == 'string') config = JSON.parse(config);
        console.log("setFirmwareConfig ", config);
        this.props.setFirmwareConfig(config);
    }

    setEntrySocketStatus(status) {
        this.props.setSocketStatus(status);
    }

    setEntryApList(list) {
        console.log("setEntryApList ", list);
        if (typeof list == 'string') {
            list = JSON.parse(list);
        }
        console.log("list ", list);
        this.props.setApBrainList(list);
    }

    finishEntrySlotDelete() {
        this.props.setBrainScriptErasingStatus(false);
    }

    setSoftwareConfig(config) {
        console.log("setSoftwareConfig ", config);
        config = JSON.parse(config);
        if (config && config != null && config.length > 0) {
            let isNeedToShow = this.isNeedToShowWhatsNewDialog(config);
            store.set(LocalKey.releaseNotes, config)
            if (isNeedToShow) {
                this.props.showWhatsNewDialog();
            }
        }
    }

    isNeedToShowWhatsNewDialog(config) {
        let releaseNotesJson = store.get(LocalKey.releaseNotes, defaultReleaseNotes);
        if (releaseNotesJson && releaseNotesJson[0] && releaseNotesJson[0].version != "") {
            return releaseNotesJson[0].version != config[0].version
        } else {
            return true;
        }
    }

    setPortState(data) {
        console.log("setPortState ", data);
        if (typeof data == 'string') {
            data = JSON.parse(data);
        }
        let connected = (data.usb_r == 'vision' || data.usb_l == 'vision');
        this.props.setVisionConnected(connected);
        if (connected) {
            setTimeout(() => { this.initVision(); }, 1000);
        } else {
            this.props.setVisionInitiated(false)
            this.props.setVisionColorDefaultListInitiated(false);
        }
        this.props.setRealEntryDeviceList(this.parseRealDeviceListToReducer(data));
    }

    parseRealDeviceListToReducer(list) {
        let result = [
            { port: "1", device: list.port1, name: "" },
            { port: "2", device: list.port2, name: "" },
            { port: "3", device: list.port3, name: "" },
            { port: "4", device: list.port4, name: "" },
            { port: "5", device: list.port5, name: "" },
            { port: "6", device: list.port6, name: "" },
            { port: "7", device: list.port7, name: "" },
            { port: "8", device: list.port8, name: "" },
            { port: "9", device: list.port9, name: "" },
            { port: "10", device: list.port10, name: "" },
            { port: "11", device: list.port11, name: "" },
            { port: "12", device: list.port12, name: "" },
            { port: "R", device: list.usb_r, name: "" },
            { port: "L", device: list.usb_l, name: "" }
        ]
        for (let i = 0; i < result.length; i++) {
            if (result[i].device == "ds") {
                result[i].device = "speaker";
            } else if (result[i].device == "none") {
                result[i].device = "";
            }
        }
        console.log("parseRealDeviceListToReducer ", result);
        return result;
    }

    initVision() {
        if (!this.props.isVisionConnected || this.props.isVisionInitiated) return;
        this.props.resetVisionInitState();
        setTimeout(() => {
            this.props.setVisionInitState(VISION_INIT_STATE_TYPE.INITIALIZING);
            postMessage(Catagory.Socket, { "action": SocketAction.startcvRealtime });
        }, 100)
    }

    setEntryDeviceStatus(list) {
        console.log("setEntryDeviceStatus ", list);
        if (typeof list == 'string') {
            list = JSON.parse(list);
        }
        this.props.setEntryDeviceStatus(list)
    }

    setControllerStatus(list) {
        console.log("setControllerStatus ", list);
        if (typeof list == 'string') {
            list = JSON.parse(list);
        }
        this.props.setControllerStatus(list)
    }

    setEntryBrainScreen(data) {
        console.log("setEntryBrainScreen ", data);
        if (typeof data == 'string') {
            data = JSON.parse(data);
        }
        this.props.setEntryBrainScreen(data)
    }

    setControllerResponse(data) {
        console.log("setControllerResponse ", data);
        if (typeof data == 'string') {
            data = JSON.parse(data);
        }
        this.props.setControllerResponse(data);
    }

    setCVStatus(status) {
        console.log("setCVStatus ", status);
        if (status == CMD_CV_Feedback.INIT) {
            this.props.setVisionInitiated(true);
            postMessage(Catagory.Socket, { "action": SocketAction.cvSendCmd, "cmd": cvInitFaceRecogModule() });
        } else if (status == CMD_CV_Feedback.FACERECOG_INIT) {
            this.props.setVisionRecongInitiated(true);
            this.props.setVisionInitState(VISION_INIT_STATE_TYPE.SUCCESS);
            postMessage(Catagory.Socket, { "action": SocketAction.cvSendCmd, "cmd": cvSetTags([]) });
        } else if (status == CMD_CV_Feedback.FALSE_VM) {
            this.props.setVisionInitiated(false);
            this.props.setVisionRecongInitiated(false);
            this.props.setVisionColorDefaultListInitiated(false);
            this.props.setVisionInitState(VISION_INIT_STATE_TYPE.OCCUPY);
        } else if (status == CMD_CV_Feedback.INTERRUPT_VM) {
            this.props.setVisionInitiated(false);
            this.props.setVisionRecongInitiated(false);
            this.props.setVisionColorDefaultListInitiated(false);
            this.props.setVisionInitState(VISION_INIT_STATE_TYPE.INTERRUPT);
        }
    }

    setCVImage(data) {
        // console.log("setCVImage ", data);
        if (typeof data == 'string') {
            data = JSON.parse(data);
        }
        let stream = data.image ? data.image : "";
        let tagList = data.detected_objects ? data.detected_objects : [];
        let width = data.frame_width ? data.frame_width : 320;
        let height = data.frame_height ? data.frame_height : 240;
        this.props.setImageBase64(stream);
        this.props.setImagePixel(width, height);
        if (!Array.isArray(this.props.getImageTagList) ||
            (Array.isArray(tagList) &&
                (tagList.length != this.props.getImageTagList.length ||
                    (tagList.length > 0 && tagList[0] != this.props.getImageTagList[0])))) {

            this.props.setImageTagList(tagList);
        }
    }

    setCVRecogImage(data) {
        console.log("setCVRecogImage ", data);
        if (typeof data == 'string') {
            data = JSON.parse(data);
            console.log("setCVRecogImage JSON ", data);
        }
        this.props.setImageTagList(data);
    }

    setFaceFeature(data) {
        console.log("setFaceFeature ", data);
        if (typeof data == 'string') {
            data = JSON.parse(data);
            console.log("setFaceFeature JSON ", data);
        }
        this.props.setTempFaceFeature(data);
    }

    setVisionTagSetting(data) {
        console.log("setVisionTagSetting ", data);
        if (typeof data == 'string') {
            data = JSON.parse(data);
        }
        if (!this.props.vm.getIsCvTagsChanged()) {
            let colorDataList = this.parseColorDataListToReducer(data.res);
            this.props.setColorDataList(colorDataList);
        }
        let colorDataDefaultList = this.parseColorDataListToReducer(data.res);
        this.props.setColorDataDefaultList(colorDataDefaultList);
        this.props.setVisionColorDefaultListInitiated(true);
    }

    parseColorDataListToReducer(res) {
        let list = [];
        if (Array.isArray(res)) {
            res.forEach((data) => { list.push(data) })
            while (list.length < 10) {
                list.push({ color_name: null, bar: 5.0, bgr: [0, 0, 0], lowerbound: [0, 0, 0], upperbound: [0, 0, 0] })
            }
        }
        return list;
    }

    setSelectedColor(color) {
        console.log("setSelectedColor ", color);
        if (typeof color == 'string') {
            color = JSON.parse(color);
        }
        this.props.setSelectedColor(color);
    }

    setImagePixel(width, height) {
        console.log("setImagePixel ", parseInt(width), parseInt(height));
        this.props.setImagePixel(parseInt(width), parseInt(height));
        this.props.setUploadImagePath("fakepath");
    }

    setUploadImageResult(res, data) {
        console.log("setUploadImageResult ", res);
        this.props.setImageBase64("");
        setTimeout(() => {
            if (data) {
                try {
                    this.props.setUploadImagePath(res);
                    this.props.setImageBase64(data);
                } catch (e) {
                    console.error('handleOpenVsionUploadImage e ', e)
                    this.props.setUploadImagePath('openImageFileFailed');
                    this.props.showErrorDialog(errorType.FILE_FORMAT_INVALID);
                }
            }
        }, 100)
    }

    setWifiStatus(data) {
        console.log("setWifiStatus ", data);
        if (typeof data == 'string') {
            data = JSON.parse(data);
        }
        let wifiStatus = {
            state: data["state"],
            conn_ssid: data["conn_ssid"],
            current_ap: data["current_ap"],
            selected_ap: data["selected_ap"],
            rssi: data["rssi"]
        }
        this.props.setWifiStatus(wifiStatus);
    }

    handleRefresh() {
        if (this.props.getPickedBrainType == BRAIN_TYPE.EDU) {
            postMessage(Catagory.Bluetooth, { "action": BluetoothAction.refresh });
        } else {
            postMessage(Catagory.Socket, { "action": SocketAction.refresh });
        }
    }

    handleConnect(index) {
        if (this.props.getPickedBrainType == BRAIN_TYPE.EDU) {
            let device = this.props.getList[index];
            if (device.isConnect == true) return;
            postMessage(Catagory.Bluetooth, { "action": BluetoothAction.connect, "index": index });
        } else {
            let device = this.props.getApBrainList[index];
            if (device.isConnect == true) return;
            postMessage(Catagory.Socket, { "action": SocketAction.connect, "index": index });
        }
        this.setState({
            selected: index
        });
    }

    handleDisconnect() {
        postMessage(Catagory.Socket, { "action": SocketAction.disconnect });
    }

    handleResetEntry() {
        this.handleClickBrainMore();
        this.props.showQuestionResetBrainFirmwareDialog();
    }

    handleClickMasterSetting() {
        if (store.get(LocalKey.wifiPermissionStatus) == SocketStatus.unauthorized) {
            postMessage(Catagory.Socket, { "action": SocketAction.unauthorized, "type": "wifi" });
        } else if (store.get(LocalKey.localNetworkPermissionStatus) == SocketStatus.unauthorized) {
            postMessage(Catagory.Socket, { "action": SocketAction.unauthorized, "type": "localnetwork" });
        } else {
            this.props.showWifiiPadSettingDialog();
        }
    }

    renderConnectState(item, index) {
        if (this.state.selected == index) {
            return (
                <FormattedMessage
                    defaultMessage="Connecting"
                    description="Connecting master"
                    id="gui.brainInfo.mobile.connecting"
                />);
        }
        else {
            if (item.isConnect) {
                return (
                    <FormattedMessage
                        defaultMessage="Connected"
                        description="connected master"
                        id="gui.brainInfo.mobile.connect"
                    />);
            }
            else {
                return (
                    <FormattedMessage
                        defaultMessage="No connect"
                        description="No connect master"
                        id="gui.brainInfo.mobile.noConnect"
                    />);
            }
        }
    }

    handleClickBrainMore() {
        let more = this.state.isMorePopup;
        this.setState({
            isMorePopup: !more
        });
    }

    addListeners() {
        document.addEventListener('mouseup', this.handleClick);
    }

    removeListeners() {
        document.removeEventListener('mouseup', this.handleClick);
    }

    handleClick(e) {
        if (this.props.show && !this.brainInfo.contains(e.target)) {
            this.props.onRequestClose();
        }
    }

    ref(c) {
        this.brainInfo = c;
    }

    render() {
        if (!this.props.show) {
            return null
        }
        if (this.props.getPickedBrainType == BRAIN_TYPE.EDU) {
            return (
                <div className={classNames(styles.brainInfoMobile)} ref={this.ref} >
                    <div className={classNames(styles.brainInfoMobileDialog)} >
                        <div className={classNames(styles.brainInfoMobileDialogInside)} >
                            <div className={classNames(styles.brainInfoMobileType)}>
                                <div className={classNames(styles.brainInfoMobileTitle, styles.type)}>
                                    <FormattedMessage
                                        defaultMessage="Main controller model"
                                        description="title for main controller model"
                                        id="gui.brainInfo.mobile.type"
                                    />
                                </div>
                                <div className={classNames(styles.brainInfoMobileTypeName)}>
                                    <div>{this.props.brainModelName}</div>
                                </div>
                            </div>

                            <div className={classNames(styles.divider)} />

                            <div className={classNames(styles.brainInfoMobileFirmware)}>
                                <div className={classNames(styles.brainInfoMobileTitle, styles.firmware)}>
                                    <FormattedMessage
                                        defaultMessage="Firmware Version"
                                        description="Firmware Version"
                                        id="gui.firmware.brain.firmwareTitle"
                                    />
                                </div>
                                <div className={classNames(styles.brainInfoMobileVersion)}>
                                    {this.props.getBrainInfo.brain_ver}
                                </div>
                            </div>

                            <div className={classNames(styles.divider)} />

                            <div className={classNames(styles.brainInfoMobileHeader)} >
                                <div className={classNames(styles.brainInfoMobileTitle, styles.select)}>
                                    <FormattedMessage
                                        defaultMessage="Select master"
                                        description="title for select master"
                                        id="gui.brainInfo.mobile.title"
                                    />
                                </div>
                                {
                                    this.props.bluetoothStatus == BluetoothStatus.scaning ? (
                                        <div className={classNames(styles.refresh)}>
                                            <img className={classNames(styles.brainInfoMobileLoading)} src={loading} />
                                        </div>
                                    ) : (
                                        <div className={classNames(styles.refresh)} onClick={this.handleRefresh} >
                                            <img src={refresh} />
                                        </div>
                                    )
                                }
                            </div>

                            <div className={classNames(styles.divider)} />

                            <div className={classNames(styles.brainInfoMobileContent)} >
                                {
                                    this.props.getList.length == 0 ? (
                                        <div className={classNames(styles.brainInfoMobileSearch, styles.no)} >
                                            <FormattedMessage
                                                defaultMessage="No search master"
                                                description="content for no search master"
                                                id="gui.brainInfo.mobile.noSearch"
                                            />
                                        </div>
                                    ) : this.props.getList.map(
                                        (item, index) => (
                                            <div
                                                key={`bluetooth-peripheral-${index}`}
                                                className={
                                                    classNames(
                                                        styles.brainInfoMobileSearch,
                                                        styles.item,
                                                        item.isConnect ? styles.selected : null)
                                                }
                                                onClick={() => this.handleConnect(index)}
                                            >
                                                <div>
                                                    {item.name}
                                                </div>
                                                <div className={classNames(styles.brainInfoMobileStatus)} >
                                                    {this.renderConnectState(item, index)}
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else { //entry
            return (
                <div className={classNames(styles.brainInfoMobile)} ref={this.ref} >
                    <div className={classNames(styles.brainInfoMobileDialog)} >
                        <div className={classNames(styles.brainInfoMobileDialogInside)} >
                            <div className={classNames(styles.brainInfoMobileType)}>
                                <div className={classNames(styles.brainInfoMobileTitle, styles.name)}>
                                    <FormattedMessage
                                        defaultMessage="Main controller name"
                                        description="title for main controller name"
                                        id="gui.firmware.brain.brainName"
                                    />
                                </div>
                                <div className={classNames(styles.brainInfoMobileTypeName)}>
                                    <div>
                                        {
                                            this.props.getBrainInfo.ssn ? this.props.getBrainInfo.ssn.substr(this.props.getBrainInfo.ssn.length - 7, 7) : ""
                                        }
                                    </div>
                                </div>

                                <img
                                    className={classNames(
                                        styles.more,
                                        this.props.getBrainConnectStatus ? null : styles.disable,
                                        {
                                            [styles.active]: this.state.isMorePopup
                                        }
                                    )}
                                    onClick={this.handleClickBrainMore}
                                />

                                {this.state.isMorePopup ?
                                    <div className={classNames(styles.moreOption)}
                                        onClick={this.handleResetEntry}>
                                        <FormattedMessage
                                            defaultMessage="Restore original"
                                            description="Restore original"
                                            id="gui.firmware.brain.restore"
                                        />
                                    </div>
                                    : null}
                            </div>

                            <div className={classNames(styles.divider)} />

                            <div className={classNames(styles.brainInfoMobileType)}>
                                <div className={classNames(styles.brainInfoMobileTitle, styles.type)}>
                                    <FormattedMessage
                                        defaultMessage="Main controller model"
                                        description="title for main controller model"
                                        id="gui.brainInfo.mobile.type"
                                    />
                                </div>
                                <div className={classNames(styles.brainInfoMobileTypeName)}>
                                    <div>{this.props.brainModelName}</div>
                                </div>
                            </div>

                            <div className={classNames(styles.divider)} />

                            <div className={classNames(styles.brainInfoMobileFirmware)}>
                                <div className={classNames(styles.brainInfoMobileTitle, styles.firmware)}>
                                    <FormattedMessage
                                        defaultMessage="Firmware Version"
                                        description="Firmware Version"
                                        id="gui.firmware.brain.firmwareTitle"
                                    />
                                </div>
                                <div className={classNames(styles.brainInfoMobileVersion)}>
                                    <div>{this.props.getBrainInfo.brain_ver}</div>
                                </div>
                            </div>

                            <div className={classNames(styles.divider)} />

                            <div className={classNames(styles.brainInfoMobileHeader)} >
                                <div className={classNames(styles.brainInfoMobileTitle, styles.select)}>
                                    <FormattedMessage
                                        defaultMessage="Select master"
                                        description="title for select master"
                                        id="gui.brainInfo.mobile.title"
                                    />
                                </div>
                                {
                                    this.props.socketStatus == SocketStatus.scaning ? (
                                        <div className={classNames(styles.refresh)}>
                                            <img className={classNames(styles.brainInfoMobileLoading)} src={loading} />
                                        </div>
                                    ) : (
                                        <div className={classNames(styles.refresh)} onClick={this.handleRefresh} >
                                            <img src={refresh} />
                                        </div>
                                    )
                                }
                            </div>

                            <div className={classNames(styles.divider)} />

                            <div className={classNames(styles.brainInfoMobileContent)} >
                                {
                                    this.props.socketStatus == SocketStatus.scaning && this.props.getApBrainList.length == 0 ? (
                                        <div className={classNames(styles.brainInfoMobileSearch, styles.no)} >
                                            <FormattedMessage
                                                defaultMessage="Searching master"
                                                description="content for searching master"
                                                id="gui.brainInfo.mobile.searching"
                                            />
                                        </div>
                                    ) : null
                                }
                                {
                                    this.props.getApBrainList.length > 0 ? this.props.getApBrainList.map(
                                        (item, index) => (
                                            <div
                                                key={`entry-ap-${index}`}
                                                className={
                                                    classNames(
                                                        styles.brainInfoMobileSearch,
                                                        styles.item,
                                                        item.isConnect ? styles.selected : null)
                                                }
                                                onClick={() => this.handleConnect(index)}
                                            >
                                                <div>
                                                    {item.ssn.substr(item.ssn.length - 7, 7)}
                                                </div>
                                                <div className={classNames(styles.brainInfoMobileStatusEntry)} >
                                                    ({this.renderConnectState(item, index)})
                                                </div>
                                                <div
                                                    className={
                                                        classNames(styles.brainInfoMobileDisconnect,
                                                            item.isConnect ? null : styles.hidden)
                                                    }
                                                    onClick={this.handleDisconnect}>
                                                    <FormattedMessage id="gui.dialog.wifi.interrupt" />
                                                </div>
                                            </div>
                                        )
                                    ) : this.props.socketStatus != SocketStatus.scaning ? (
                                        <div className={classNames(styles.brainInfoMobileSearch, styles.no)} >
                                            <FormattedMessage
                                                defaultMessage="No search master"
                                                description="content for no search master"
                                                id="gui.brainInfo.mobile.noSearchEntry"
                                            />
                                        </div>
                                    ) : null
                                }
                            </div>

                            <div className={classNames(styles.divider)} />

                            <div className={classNames(styles.brainInfoMobileEntrySetting)}>
                                <div className={classNames(styles.brainInfoMobileEntrySettingButton)}>
                                    <div className={classNames(styles.brainInfoMobileEntryButton)}
                                        style={(EditUtils.getTextWidth(EditUtils.getLocaleString("gui.firmware.brain.ap.setting"), 14) > 90) ?
                                            { whiteSpace: "pre-wrap", padding: "0 5px", lineHeight: "14px" } : {}}
                                        onClick={this.handleClickMasterSetting}>
                                        <FormattedMessage
                                            defaultMessage="Setting master"
                                            description="setting master"
                                            id="gui.firmware.brain.ap.setting"
                                        />
                                    </div>
                                </div>
                                <div className={classNames(styles.divider, styles.v)} />
                                <div className={classNames(styles.brainInfoMobileEntrySettingButton)}>
                                    <div className={classNames(styles.brainInfoMobileEntryButton)}
                                        style={(EditUtils.getTextWidth(EditUtils.getLocaleString("gui.firmware.brain.ap.ip"), 14) > 90) ?
                                            { whiteSpace: "pre-wrap", padding: "0 5px", lineHeight: "14px" } : {}}
                                        onClick={this.props.showWifiIpSettingDialog}>
                                        <FormattedMessage
                                            defaultMessage="Input master ip"
                                            description="input master ip"
                                            id="gui.firmware.brain.ap.ip"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={classNames(styles.divider)} />

                            <div className={classNames(styles.brainInfoMobileEntryDirection)}>
                                <div className={classNames(styles.brainInfoMobileEntryDirectionText)} onClick={this.props.showWifiDirectionDialog}>
                                    <FormattedMessage
                                        defaultMessage="no find master"
                                        description="no find master"
                                        id="gui.firmware.brain.ap.not.found"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

BrainInfoMobile.propTypes = {
    vm: PropTypes.object,
    show: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func,
    setConnectState: PropTypes.func,
    setBrainScriptErasingStatus: PropTypes.func,
    setBrainDownloadingStatus: PropTypes.func,
    setTaskProgress: PropTypes.func,
    runTask: PropTypes.func,
    finishTask: PropTypes.func,
    setBrainRunningStatus: PropTypes.func,
    setBrainDownloadAndRun: PropTypes.func,
    setBrainInfo: PropTypes.func,
    getBrainInfo: PropTypes.object,
    setSlotList: PropTypes.func,
    getList: PropTypes.array,
    updateList: PropTypes.func,
    brainDownloadType: PropTypes.string,
    getPickedBrainType: PropTypes.string,
    setBluetoothStatus: PropTypes.func,
    setBrainStatus: PropTypes.func,
    bluetoothStatus: PropTypes.string,
    setFirmwareConfig: PropTypes.func,
    setBrainBtVer: PropTypes.func,
    setBatteryConnectStatus: PropTypes.func,
    showWifiDirectionDialog: PropTypes.func,
    showWifiiPadSettingDialog: PropTypes.func,
    showWifiIpSettingDialog: PropTypes.func,
    setApBrainList: PropTypes.func,
    getApBrainList: PropTypes.array,
    setSocketStatus: PropTypes.func,
    socketStatus: PropTypes.string,
    showWhatsNewDialog: PropTypes.func,
    enableCheckingImportDevice: PropTypes.func,
    setVisionConnected: PropTypes.func,
    setVisionInitiated: PropTypes.func,
    setVisionColorDefaultListInitiated: PropTypes.func,
    setRealEntryDeviceList: PropTypes.func,
    isVisionConnected: PropTypes.bool,
    isVisionInitiated: PropTypes.bool,
    resetVisionInitState: PropTypes.func,
    setVisionInitState: PropTypes.func,
    setEntryDeviceStatus: PropTypes.func,
    setControllerStatus: PropTypes.func,
    setEntryBrainScreen: PropTypes.func,
    setControllerResponse: PropTypes.func,
    setImageBase64: PropTypes.func,
    setImagePixel: PropTypes.func,
    setImageTagList: PropTypes.func,
    getImageTagList: PropTypes.any,
    setVisionRecongInitiated: PropTypes.func,
    setColorDataList: PropTypes.func,
    setColorDataDefaultList: PropTypes.func,
    setVisionColorDefaultListInitiated: PropTypes.func,
    setTempFaceFeature: PropTypes.func,
    setSelectedColor: PropTypes.func,
    setUploadImagePath: PropTypes.func,
    showQuestionResetBrainFirmwareDialog: PropTypes.func,
    getBrainConnectStatus: PropTypes.bool,
    setWifiStatus: PropTypes.func,
};

BrainInfoMobile.defaultPropTypes = {
    show: false
}

const mapStateToProps = state => ({
    vm: state.scratchGui.vm,
    getList: getList(state),
    getBrainInfo: getBrainInfo(state),
    brainModelName: getBrainModelName(state),
    getPickedBrainType: getPickedBrainType(state),
    brainDownloadType: getBrainDownloadType(state),
    bluetoothStatus: getBluetoothStatus(state),
    getApBrainList: getApBrainList(state),
    socketStatus: getSocketStatus(state),
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state),
    getImageTagList: getImageTagList(state),
    getBrainConnectStatus: getBrainConnectStatus(state),
});

const mapDispatchToProps = dispatch => ({
    setConnectState: isConnect => dispatch(setBrainConnectStatus(isConnect)),
    setBrainScriptErasingStatus: status => dispatch(setBrainScriptErasingStatus(status)),
    setBrainDownloadingStatus: status => dispatch(setBrainDownloadingStatus(status)),
    setBrainDownloadAndRun: status => dispatch(setBrainDownloadAndRun(status)),
    setTaskProgress: (percentage, taskName) => dispatch(setTaskProgress(percentage, taskName)),
    runTask: taskName => dispatch(runTask(taskName)),
    finishTask: taskName => dispatch(finishTask(taskName)),
    setBrainRunningStatus: status => dispatch(setBrainRunningStatus(status)),
    setBrainInfo: info => dispatch(setBrainInfo(info)),
    setSlotList: info => dispatch(setSlotList(info)),
    updateList: list => dispatch(updateList(list)),
    setBluetoothIsOpen: status => dispatch(setBluetoothIsOpen(status)),
    setBluetoothStatus: status => dispatch(setBluetoothStatus(status)),
    setBrainStatus: status => dispatch(setBrainStatus(status)),
    setFirmwareConfig: config => dispatch(setFirmwareConfig(config)),
    setBrainBtVer: version => dispatch(setBrainBtVer(version)),
    setBatteryConnectStatus: status => dispatch(setBatteryConnectStatus(status)),
    showWifiDirectionDialog: () => dispatch(showWifiDirectionDialog()),
    showWifiiPadSettingDialog: () => dispatch(showWifiiPadSettingDialog()),
    showWifiIpSettingDialog: () => dispatch(showWifiIpSettingDialog()),
    setApBrainList: list => dispatch(setApBrainList(list)),
    setSocketStatus: status => dispatch(setSocketStatus(status)),
    showWhatsNewDialog: () => dispatch(showWhatsNewDialog()),
    enableCheckingImportDevice: status => dispatch(enableCheckingImportDevice(status)),
    setVisionConnected: status => dispatch(setVisionConnected(status)),
    setVisionInitiated: status => dispatch(setVisionInitiated(status)),
    setVisionColorDefaultListInitiated: status => dispatch(setVisionColorDefaultListInitiated(status)),
    setRealEntryDeviceList: list => dispatch(setRealEntryDeviceList(list)),
    resetVisionInitState: () => dispatch(resetVisionInitState()),
    setVisionInitState: status => dispatch(setVisionInitState(status)),
    setEntryDeviceStatus: list => dispatch(setEntryDeviceStatus(list)),
    setControllerStatus: list => dispatch(setControllerStatus(list)),
    setEntryBrainScreen: data => dispatch(setEntryBrainScreen(data)),
    setControllerResponse: data => dispatch(setControllerResponse(data)),
    setImageBase64: data => dispatch(setImageBase64(data)),
    setImagePixel: (width, height) => dispatch(setImagePixel(width, height)),
    setImageTagList: list => dispatch(setImageTagList(list)),
    setVisionRecongInitiated: (state) => dispatch(setVisionRecongInitiated(state)),
    setColorDataList: (list) => dispatch(setColorDataList(list)),
    setColorDataDefaultList: (list) => dispatch(setColorDataDefaultList(list)),
    setVisionColorDefaultListInitiated: (state) => dispatch(setVisionColorDefaultListInitiated(state)),
    setTempFaceFeature: (feature) => dispatch(setTempFaceFeature(feature)),
    setSelectedColor: color => dispatch(setSelectedColor(color)),
    setUploadImagePath: path => dispatch(setUploadImagePath(path)),
    showQuestionResetBrainFirmwareDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_RESET_BRAIN_FIRMWARE)),
    setWifiStatus: data => dispatch(setWifiStatus(data)),
    showErrorDialog: (errorType, msg) => dispatch(showErrorDialog(errorType, msg)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BrainInfoMobile);