import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import store from 'store';
import { showWhatsNewDialog } from '../../reducers/dialog';
import { parseReleaseNoteJson } from './utilities';

class WhatsNewDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            releaseNotesJson: []
        };
    }

    componentDidMount() {
        let releaseNotesJson = parseReleaseNoteJson(this.props.locale);
        console.log(`WhatsNewDialog componentDidMount releaseNotesJson: `, releaseNotesJson);
        this.setState({
            releaseNotesJson: releaseNotesJson
        })
    }

    handleClickIKnow() {
        this.props.onClose();
    }

    getContent() {
        let releaseNoteObj = [];
        let item = null;
        let versionObj = null;
        let releaseNotes = this.state.releaseNotesJson;
        let showVersionCount = 3;
        if (releaseNotes && releaseNotes.length > 0) {
            for (let i = 0; i < releaseNotes.length && i < showVersionCount; i++) {
                versionObj = (
                    <tr className={styles.aboutContentTrTitle}>
                        <td colspan="4">{releaseNotes[i]['version']} - {releaseNotes[i]['time']}</td>
                    </tr>);
                releaseNoteObj.push(versionObj);
                if (releaseNotes[i]['data'][this.props.locale] && releaseNotes[i]['data'][this.props.locale].length > 0) {
                    releaseNotes[i]['data'][this.props.locale].forEach((paragraph) => {
                        let content = [];
                        paragraph.content.forEach((word) => {
                            if (word.length > 0) {
                                item = (
                                    <tr className={styles.aboutContentTr}>
                                        <td width="5%"></td>
                                        {(paragraph.title.length > 0) ? <td width="5%"></td> : null}
                                        <td width="1%" className={styles.aboutContentTd}>．</td>
                                        {(paragraph.title.length > 0) ?
                                            <td>{word}</td> :
                                            <td colspan="2">{word}</td>
                                        }
                                    </tr>);
                                content.push(item);
                            }
                        })
                        if (paragraph.title.length > 0) {
                            item = (
                                <tr className={styles.aboutContentTrTitle}>
                                    <td width="5%"></td>
                                    <td colspan="3">{paragraph.title}</td>
                                </tr>);
                            releaseNoteObj.push(item);
                        }
                        releaseNoteObj.push(content);
                    })
                }
            }
        }
        return (
            <table className={styles.whatsNewContentTable}>
                <tbody>
                    {releaseNoteObj}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle, styles.whatsNew)}>
                    <div className={styles.whatsNewHeader}>
                        <div className={styles.whatsNewTitle}>
                            <FormattedMessage
                                defaultMessage="whats new title"
                                description="whats new title"
                                id="gui.dialog.whatsNew.title"
                            />
                        </div>
                    </div>
                    <div className={styles.whatsNewContent} >
                        <div className={styles.whatsNewContentWord} >
                            {this.getContent()}
                        </div>
                        <div className={styles.whatsNewButtonContent} >
                            <div className={styles.whatsNewButton} onClick={() => this.handleClickIKnow()}>
                                <FormattedMessage
                                    defaultMessage="I know"
                                    description="I know"
                                    id="gui.dialog.whatsNew.Iknow"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

WhatsNewDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    locale: PropTypes.string,
    onShowWhatsNewDialog: PropTypes.func,
};

const mapStateToProps = state => ({
    locale: state.locales.locale,
});

const mapDispatchToProps = dispatch => ({
    onShowWhatsNewDialog: () => dispatch(showWhatsNewDialog()),
})

WhatsNewDialog.defaultProps = {
    onClose: () => { },
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(WhatsNewDialog));