import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes, { number } from 'prop-types';
import React from 'react';
import dialogStyles from '../dialog.css';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EditUtils, SUPPORT_TYPES, STRING_ID } from '../../device-manager/edit-page/edit-utils.js'
import { DEFAULT_LOCALE } from '../../../config/project-config';
import {
    platformType,
    getPlatform,
    isPad,
    isReactNative,
} from '../../../lib/platform'

import { projectTitle } from '../../../reducers/project-title';
import log from '../../../lib/log';
import {
    getRealEntryDeviceList,
} from '../../../reducers/device-manager-controller';
import {
    hideStreamingDialog,
    isStreamingDialogShow,
    focusDialog,
    getForegroundDialog,
    FOREGROUND_INDEX,
    BACKGROUND_INDEX,
    DIALOG_STREAMING,
} from '../../../reducers/dialog';
import {
    getBrainInfo,
    getBrainRunningStatus,
    getEntryBrainScreen,
    getEntryDeviceStatus,
    INIT_ENTRY_DEVICE,
    INIT_ENTRY_SCREEN
} from '../../../reducers/brain';
import {
    getControllerResponse,
    getControllerStatus,
    INIT_CONTROLLER_STATUS,
    BUTTON,
    RES_BUTTON
} from '../../../reducers/controller';
import {
    getWifiStatus,
    setWifiStatus
} from '../../../reducers/wifi-list';
import {
    isVisionConnected,
    isVisionInitiated,
    setImageBase64,
    getImageBase64,
    setImageTagList,
    getVisionInitState,
    VISION_INIT_STATE_TYPE,
    getImageHeight,
    getImageWidth,
    getWifiVision,
    setWifiVision
} from '../../../reducers/vision';
import streamingStyles from './streaming-dialog.css'
import VM from 'scratch-vm';
import store from 'store';
import { getUIStyle, uiType } from '../../../reducers/ui-style';
import controllerSideImg from './img/controller_upside.png'
import controllerImg from './img/controller.png'

import videoStopImg from './img/stop.svg'
import videoStartImg from './img/video.svg'
import videoCloseImg from './img/close.svg'
import takePictureImg from './img/picture.svg'
import popupImg from './img/popup.svg'
import loadingImg from '../svg/loading.svg'
import StreamingPopupDialog from './streaming-popup-dialog.jsx';
import { BRAIN_TYPE } from '../../../lib/brains';
import { getSensorDataString, SENSOR, INDEX, getDataUnit, getDataValueString } from './sensor-data-string.js'
import CONTROLLER_STRING from '../../device-manager/controller/string.js';
import { Catagory, postMessage } from '../../../lib/postmessage';
import { SocketAction } from '../../../lib/socket';
const TITLE_HEIGHT = 86;

const DIALOG_WIDTH = 1065;
const DIALOG_HEIGHT = 965;


const DIRECTION = {
    horizontal: "horizontal",
    vertical: "vertical"
}
const ACTION = {
    move: 'move',
}
const EMPTY_DATA = 'N/A';
const INIT_STATE = {
    brainStatus: {
        ssn: EMPTY_DATA,
        battery: EMPTY_DATA,
        connected: EMPTY_DATA,
        wifiSignal: EMPTY_DATA
    },
    src: "",
    translateX: (window.innerWidth - DIALOG_WIDTH) / 2,
    translateY: (window.innerHeight - DIALOG_HEIGHT) / 2,
    z_index: BACKGROUND_INDEX,
    controller: INIT_CONTROLLER_STATUS,
    controllerRealtime: [],
    entryScreen: INIT_ENTRY_SCREEN,
    entryPortInfo: INIT_ENTRY_DEVICE,
    isStreamingDialogPopup: false,
    isPhotoSaved: false,
    isVideoMode: false,
    dialogWidth: DIALOG_WIDTH,
    dialogHeight: DIALOG_HEIGHT,
    showCommand: false,
    isConnectedVision: false,
    isConnectedSpeaker: false,
    isSocket: false,
    screenShot: false,
    onFullStringShow: ""
}
const brainSNDisplayNum = 7
const CONTROLLER = {
    main: 'controller1',
    minor: 'controller2'
}
const REALTIME_CONTROLLER = {
    main: 1,
    minor: 2
}
var intervalSensorId = null
var intervalScreenId = null
var intervalWifiStatusId = null;
var dispearCommandRealtimeTimeoutId = null
var clearCommandTimeoutId = null
// var recordIntervalId = null;
var isPhotoSavedTimeoutId = null;
var wifiSignalValidTimeoutId = null;
const baseDelay = 200;
const baseFps = 24;
const WEAK_WIFI_SIGNAL = -88;
const REALTIME_COMMAND_LIMIT = 5
let mousedownFunc = () => { };
let mousemoveFunc = () => { };
let mouseupFunc = () => { };
let resizeFunc = () => { };
class StreamingDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = INIT_STATE;
        this.moving = false;
        this.lastX = null;
        this.lastY = null;
        // this.videoArray = [];
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.getBrainInfo != this.props.getBrainInfo) {
            if (this.props.getBrainInfo.brain_type != undefined) {
                this.updateBrainStatus();
                if ((this.props.getBrainInfo.brain_type == BRAIN_TYPE.ENTRY
                    || this.props.getBrainInfo.brain_type == BRAIN_TYPE.EDU_AND_ENTRY)) {
                    this.getEntryRealtimeInfo();
                } else {
                    this.initGlobalVar();
                    this.props.resetWifiStatus();
                    this.props.onClose();
                }
                this.standardDialogSize();
            } else {
                this.initGlobalVar();
                this.props.resetWifiStatus();
                this.props.onClose();
            }
        }
        if (this.props.foregroundDialog && this.props.foregroundDialog != prevProps.foregroundDialog) {
            this.setState({ z_index: (this.props.foregroundDialog == this.props.dialogType) ? FOREGROUND_INDEX : BACKGROUND_INDEX })
        }
        if (this.props.getBrainInfo.brain_type != undefined && this.props.getBrainInfo.brain_type != BRAIN_TYPE.EDU) {
            if (prevProps.getRealEntryDeviceList !== this.props.getRealEntryDeviceList) {
                this.props.wifiGetDevStatus();
                this.updateTypeCStatus();
            }
            if (prevProps.getEntryBrainScreen !== this.props.getEntryBrainScreen) {
                this.updateBrainScreen();
            }
            if (prevProps.getControllerStatus !== this.props.getControllerStatus) {
                this.updateControllerStaticStatus();
            }
            if (prevProps.getControllerResponse !== this.props.getControllerResponse) {
                this.updateControllerRealtime();
            }
        }
        if (prevProps.getWifiStatus !== this.props.getWifiStatus) {
            if (this.props.getBrainInfo.brain_type != undefined && this.props.getBrainInfo.brain_type != BRAIN_TYPE.EDU) {
                this.setWifiSignalValidTimeout();
                this.updateWifiSignal();
            }
        }
        if (prevProps.isVisionInitiated !== this.props.isVisionInitiated) {
            if (this.props.isVisionInitiated == true) {
                this.props.startVisionRealtime();
            } else {
                this.clearImage();
            }
        }
        if (prevProps.isVisionConnected !== this.props.isVisionConnected && this.props.isVisionConnected == false) {
            this.clearImage();
        }
        if (prevProps.isBrainRunning !== this.props.isBrainRunning) {
            if (this.props.isVisionConnected == true
                && this.props.isVisionInitiated == false && this.props.isBrainRunning == false) {
                this.props.initVision();
            }
            if (this.props.isBrainRunning == true) {
                this.regularWifiGetScreen()
            }
        }
    }

    clearImage() {
        this.props.clearImageBase64();
        this.setState({ isStreamingDialogPopup: false })
    }

    initStreamingDialog() {
        this.setState({ isSocket: this.props.getBrainInfo.isSocket })
        log.info('initStreamingDialog this.props.getBrainInfo.brain_type ', this.props.getBrainInfo.brain_type)
        this.setState(INIT_STATE);
        this.props.focusDialog(DIALOG_STREAMING)
        this.getEntryRealtimeInfo();
        if (this.props.getBrainInfo.brain_type != undefined) this.updateBrainStatus();
        if (this.props.isVisionInitiated == false && this.props.isVisionConnected == true) {
            this.props.initVision();
        }
        this.standardDialogSize();
    }

    initGlobalVar() {
        this.clearRunningInterval();
        this.setState(INIT_STATE);
        this.moving = false;
        this.lastX = null;
        this.lastY = null;
        this.timestamp = null;
    }

    updateTypeCStatus() {
        let realDeviceList = this.props.getRealEntryDeviceList;
        if (!realDeviceList) return;
        let isConnectedVision = false;
        let isConnectedSpeaker = false;
        realDeviceList.forEach(data => {
            if (data.device && data.device == "vision") isConnectedVision = true
            if (data.device && data.device == "speaker") isConnectedSpeaker = true
        })
        if (isConnectedVision == false) this.props.resetWifiVision();
        this.setState({
            isConnectedVision: isConnectedVision,
            isConnectedSpeaker: isConnectedSpeaker
        })
    }

    updateBrainScreen() {
        let screen = this.props.getEntryBrainScreen;
        this.setState({
            entryScreen: {
                line1: screen.line1,
                line2: screen.line2,
                line3: screen.line3,
                line4: screen.line4,
                reverse: screen.reverse
            }
        })
    }

    setWifiSignalValidTimeout() {
        if (wifiSignalValidTimeoutId) {
            clearTimeout(wifiSignalValidTimeoutId);
            wifiSignalValidTimeoutId = null;
        }
        wifiSignalValidTimeoutId = setTimeout(() => {
            //Fixed TT-1372569. Set wifi signal to weak if getWifiStatus did not send response.
            log.warn('Set wifi signal to weak before interupt.');
            let wifiStatus = this.props.getWifiStatus;
            wifiStatus.rssi = WEAK_WIFI_SIGNAL;
            this.props.setWifiStatus(wifiStatus);
            this.updateWifiSignal();
        }, baseDelay * 10)
    }

    updateWifiSignal() {
        const signal = this.getWifiSignal();
        let brainState = this.state.brainStatus
        this.setState({
            brainStatus: {
                ssn: brainState.ssn,
                battery: brainState.battery,
                connected: brainState.connected,
                wifiSignal: signal
            }
        })
    }

    updateBrainStatus() {
        let brainInfo = this.props.getBrainInfo;
        let brainType = brainInfo.brain_type;
        if (brainType && (brainType == BRAIN_TYPE.ENTRY || brainType == BRAIN_TYPE.EDU_AND_ENTRY)) {
            this.setState({
                brainStatus: {
                    ssn: brainInfo.ssn || EMPTY_DATA,
                    battery: (brainInfo.battery * 20) || EMPTY_DATA,
                    connected: brainType,
                    wifiSignal: this.getWifiSignal()
                },
                isSocket: brainInfo.isSocket,
            })
        } else {
            this.setState({
                brainStatus: {
                    ssn: EMPTY_DATA,
                    battery: EMPTY_DATA,
                    connected: EMPTY_DATA,
                    wifiSignal: EMPTY_DATA
                },
                isSocket: false,
            })
        }
    }

    getWifiSignal() {
        let wifiStatus = this.props.getWifiStatus;
        if (wifiStatus && wifiStatus.rssi) {
            const rssi = wifiStatus.rssi;
            if (rssi >= -55 && rssi <= 0)
                return EditUtils.getLocaleString("gui.dialog.streaming.index.wifi.signal.high")
            else if ((rssi >= -67 && rssi < -55) || (rssi >= 189 && rssi < 200))
                return EditUtils.getLocaleString("gui.dialog.streaming.index.wifi.signal.mid")
            else if ((rssi >= -78 && rssi < -67) || (rssi >= 178 && rssi < 189))
                return EditUtils.getLocaleString("gui.dialog.streaming.index.wifi.signal.low")
            else if ((rssi >= -88 && rssi < -78) || (rssi >= 168 && rssi < 178))
                return EditUtils.getLocaleString("gui.dialog.streaming.index.wifi.signal.weak")
            else
                return EMPTY_DATA;
        } else {
            return EMPTY_DATA;
        }
    }

    getEntryRealtimeInfo() {
        this.clearRunningInterval();
        if (this.props.getBrainInfo.brain_type == undefined || this.props.getBrainInfo.brain_type == BRAIN_TYPE.EDU) return;
        this.updateTypeCStatus();
        if (this.props.isBrainRunning) this.props.wifiGetScreenText();

        setTimeout(() => { if (this.props.isVisionInitiated) this.props.startVisionRealtime(); }, 1 * baseDelay)
        setTimeout(() => { this.props.wifiGetDevStatus(); }, 2 * baseDelay)
        setTimeout(() => { this.props.wifiGetCtrlerStatus(); }, 3 * baseDelay)
        setTimeout(() => { this.props.wifiStartCtrlerReport() }, 4 * baseDelay)
        setTimeout(() => { this.regularWifiGetDevStatus(); }, 5 * baseDelay)
        setTimeout(() => { if (this.props.isBrainRunning) this.regularWifiGetScreen(); }, 6 * baseDelay)
        setTimeout(() => { this.regularGetWifiStatus(); }, 7 * baseDelay)
    }

    regularGetWifiStatus() {
        if (intervalWifiStatusId) {
            clearInterval(intervalWifiStatusId)
            intervalWifiStatusId = null;
        }
        intervalWifiStatusId = setInterval(() => { this.props.getWifiSignal(); }, baseDelay)
    }
    regularWifiGetDevStatus() {
        if (intervalSensorId) {
            clearInterval(intervalSensorId)
            intervalSensorId = null;
        }
        intervalSensorId = setInterval(() => { this.props.wifiGetDevStatus(); }, baseDelay)
    }
    regularWifiGetScreen() {
        if (!this.props.isBrainRunning) return;
        if (intervalScreenId) {
            clearInterval(intervalScreenId)
            intervalScreenId = null;
        }
        intervalScreenId = setInterval(() => { this.props.wifiGetScreenText(); }, baseDelay)
    }
    clearRunningInterval() {
        if (intervalSensorId) {
            clearInterval(intervalSensorId)
            intervalSensorId = null
        }
        if (intervalScreenId) {
            clearInterval(intervalScreenId)
            intervalScreenId = null
        }
        if (intervalWifiStatusId) {
            clearInterval(intervalWifiStatusId)
            intervalWifiStatusId = null
        }
        if (wifiSignalValidTimeoutId) {
            clearTimeout(wifiSignalValidTimeoutId);
            wifiSignalValidTimeoutId = null;
        }
        // if (recordIntervalId) {
        //     clearInterval(recordIntervalId)
        //     recordIntervalId = null
        // }
    }

    updateEntryPortInfo() {
        let realPortInfo = this.props.getEntryDeviceStatus;
        if (!realPortInfo || this.props.getBrainInfo.brain_type == undefined || this.props.getBrainInfo.brain_type == BRAIN_TYPE.EDU) {
            return INIT_ENTRY_DEVICE;
        }
        let list = [];
        for (let i = 1; i <= 12; i++) {
            const sensor = realPortInfo['port' + i];
            if (!sensor) break;
            let resultSensor = { port: i, type: sensor.type, status: [] };
            for (let [key, value] of Object.entries(sensor)) {
                if (key != "type") {
                    resultSensor.status.push(`${key}：${value}`)
                }
            }
            list.push(resultSensor)
        }
        if (list.length > 0) {
            return list;
        } else {
            return INIT_ENTRY_DEVICE;
        }
    }
    updateWifiSrc() {
        let data = this.props.getWifiVision
        if (data && data.res == 'success') {
            this.setState({ src: data.ip + ":" + data.port })
        }
    }
    standardDialogSize() {
        const width = (window.innerWidth > DIALOG_WIDTH) ? DIALOG_WIDTH : window.innerWidth;
        const height = (window.innerHeight > DIALOG_HEIGHT) ? DIALOG_HEIGHT : window.innerHeight;

        this.setState({
            dialogWidth: width,
            dialogHeight: height,
            translateX: (window.innerWidth - width) / 2,
            translateY: (window.innerHeight - height) / 2,
        })
    }
    updateControllerStaticStatus() {
        let res = this.props.getControllerStatus;
        if (res && res[CONTROLLER.main]) {
            this.setState({
                controller: {
                    [CONTROLLER.main]: res[CONTROLLER.main],
                    [CONTROLLER.minor]: res[CONTROLLER.minor]
                }
            })
        } else {
            this.setState({ controller: INIT_CONTROLLER_STATUS })
        }
    }

    updateControllerRealtime() {
        let data = this.props.getControllerResponse;
        if (data && data.res) {
            if (data.res[CONTROLLER.main]) this.parseControllerRealtimeArray(data.res[CONTROLLER.main], CONTROLLER.main)
            if (data.res[CONTROLLER.minor]) this.parseControllerRealtimeArray(data.res[CONTROLLER.minor], CONTROLLER.minor)
        }
        if (isPad()) {
            if (data[CONTROLLER.main]) this.parseControllerRealtimeArray(data[CONTROLLER.main], CONTROLLER.main)
            if (data[CONTROLLER.minor]) this.parseControllerRealtimeArray(data[CONTROLLER.minor], CONTROLLER.minor)
        }
    }

    parseControllerRealtimeArray(controllerRes, priority) {
        let list = this.state.controllerRealtime;
        let controller = this.state.controller[priority];
        const num = priority == CONTROLLER.main ? REALTIME_CONTROLLER.main : REALTIME_CONTROLLER.minor;
        let joystickLeftCommandList = [];
        let joystickRightCommandList = [];
        if (controllerRes) {
            for (const button in controllerRes) {
                const value = parseInt(controllerRes[button])
                if (button) {
                    if (button != RES_BUTTON.L_AXIS_X
                        && button != RES_BUTTON.L_AXIS_Y
                        && button != RES_BUTTON.R_AXIS_X
                        && button != RES_BUTTON.R_AXIS_Y) {
                        controller[button] = value != 0 ? true : false
                        let buttonString = button
                        if (buttonString == RES_BUTTON.up) buttonString = EditUtils.getLocaleString("gui.dialog.streaming.button.joystick.up")
                        if (buttonString == RES_BUTTON.down) buttonString = EditUtils.getLocaleString("gui.dialog.streaming.button.joystick.down")
                        if (buttonString == RES_BUTTON.left) buttonString = EditUtils.getLocaleString("gui.dialog.streaming.button.joystick.left")
                        if (buttonString == RES_BUTTON.right) buttonString = EditUtils.getLocaleString("gui.dialog.streaming.button.joystick.right")
                        console.log('parseControllerRealtimeArray buttonString ', buttonString)
                        if (value != 0) {
                            list.unshift(num + `;${EditUtils.getLocaleString("gui.dialog.streaming.button")} ` + buttonString + `: ${EditUtils.getLocaleString("gui.dialog.streaming.button.press")}`)
                        }
                    } else if (button == RES_BUTTON.L_AXIS_X
                        || button == RES_BUTTON.L_AXIS_Y
                        || button == RES_BUTTON.R_AXIS_X
                        || button == RES_BUTTON.R_AXIS_Y) {
                        let joystickAction = this.getJoyStickAction(button, value)
                        if (button == RES_BUTTON.L_AXIS_X || button == RES_BUTTON.L_AXIS_Y) {
                            controller[RES_BUTTON.L3] = (value > 10 || value < -10) ? true : false
                            if (joystickAction && joystickAction != "") joystickLeftCommandList.push(joystickAction)
                        } else if (button == RES_BUTTON.R_AXIS_X || button == RES_BUTTON.R_AXIS_Y) {
                            controller[RES_BUTTON.R3] = (value > 10 || value < -10) ? true : false
                            if (joystickAction && joystickAction != "") joystickRightCommandList.push(joystickAction)
                        }
                    }
                    this.setState({
                        controller: {
                            [CONTROLLER.main]: num == 1 ? controller : this.state.controller[CONTROLLER.main],
                            [CONTROLLER.minor]: num == 2 ? controller : this.state.controller[CONTROLLER.minor],
                        }
                    })
                }
            }
            if (joystickLeftCommandList.length > 0) {
                list.unshift(num + `;${EditUtils.getLocaleString("gui.dialog.streaming.button.left.joystick")} : ` + joystickLeftCommandList.join(","))
            }
            if (joystickRightCommandList.length > 0) {
                list.unshift(num + `;${EditUtils.getLocaleString("gui.dialog.streaming.button.right.joystick")} : ` + joystickRightCommandList.join(","))
            }
        }
        this.setState({ controllerRealtime: list, showCommand: true })
        this.dispearCommandRealtime()
    }

    dispearCommandRealtime() {
        if (dispearCommandRealtimeTimeoutId) {
            clearTimeout(dispearCommandRealtimeTimeoutId)
            dispearCommandRealtimeTimeoutId = null;
            this.setState({ showCommand: true })
        }
        if (clearCommandTimeoutId) {
            clearTimeout(clearCommandTimeoutId)
            clearCommandTimeoutId = null;
        }
        dispearCommandRealtimeTimeoutId = setTimeout(() => {
            let controllerMain = this.state.controller[CONTROLLER.main];
            let controllerMinor = this.state.controller[CONTROLLER.minor];
            controllerMain[RES_BUTTON.L3] = "false"
            controllerMain[RES_BUTTON.R3] = "false"
            controllerMinor[RES_BUTTON.L3] = "false"
            controllerMinor[RES_BUTTON.R3] = "false"
            this.setState({
                showCommand: false,
                controller: {
                    [CONTROLLER.main]: controllerMain,
                    [CONTROLLER.minor]: controllerMinor
                }
            })
        }, 3500)
        clearCommandTimeoutId = setTimeout(() => {
            if (!this.state.showCommand) {
                this.setState({
                    controllerRealtime: []
                })
            }
        }, 5000)
    }

    getJoyStickAction(button, value) {
        let action = "";
        if (!button || value == 0) return ""
        if (button == RES_BUTTON.L_AXIS_X || button == RES_BUTTON.R_AXIS_X) {
            action = (value >= 0) ? EditUtils.getLocaleString("gui.dialog.streaming.button.joystick.right") : EditUtils.getLocaleString("gui.dialog.streaming.button.joystick.left")
        } else if (button == RES_BUTTON.L_AXIS_Y || button == RES_BUTTON.R_AXIS_Y) {
            action = (value >= 0) ? EditUtils.getLocaleString("gui.dialog.streaming.button.joystick.up") : EditUtils.getLocaleString("gui.dialog.streaming.button.joystick.down")
        }
        return (!action || action == "") ? "" : (action + Math.abs(value) + "%")
    }

    componentDidMount() {
        mousedownFunc = e => { this.onMouseDown(e) };
        mousemoveFunc = e => { this.onMouseMove(e) };
        mouseupFunc = e => { this.onMouseUp(e) };
        resizeFunc = e => { this.standardDialogSize(); };
        if (isPad()) {
            window.addEventListener('touchstart', mousedownFunc);
            window.addEventListener('touchmove', mousemoveFunc);
            window.addEventListener('touchend', mouseupFunc);
        } else {
            window.addEventListener('mousedown', mousedownFunc);
            window.addEventListener('mousemove', mousemoveFunc);
            window.addEventListener('mouseup', mouseupFunc);
        }

        window.addEventListener('resize', resizeFunc);
        this.initStreamingDialog();
    }

    componentWillUnmount() {
        if (isPad()) {
            window.removeEventListener('touchstart', mousedownFunc);
            window.removeEventListener('touchmove', mousemoveFunc);
            window.removeEventListener('touchend', mouseupFunc);
        } else {
            window.removeEventListener('mousedown', mousedownFunc);
            window.removeEventListener('mousemove', mousemoveFunc);
            window.removeEventListener('mouseup', mouseupFunc);
        }
        window.removeEventListener('resize', resizeFunc);
        this.clearRunningInterval();
    }

    onMouseDown(e) {
        if (this.props.foregroundDialog != DIALOG_STREAMING || e.button != 0) return
        // this.setState({ frame: Z_INDEX.ignore, mask: Z_INDEX.focus })
        e.stopPropagation();
        if (this.state.action == ACTION.move) {
            this.moving = true;
        }
    }

    onMouseUp(e) {
        if (this.props.foregroundDialog != DIALOG_STREAMING || e.button != 0) return
        // this.setState({ frame: Z_INDEX.focus, mask: Z_INDEX.ignore })
        e.stopPropagation();
        this.adjustDialogPosition();
        this.moving = false;
        this.lastX = null;
        this.lastY = null;
        this.setState({ action: "" })
    }

    onMouseMove(e) {
        if (this.props.foregroundDialog != DIALOG_STREAMING || e.button != 0) return
        this.moving && this.onMove(e);
    }

    onMove(e) {
        if (!this.moving) return
        var x = e.clientX, y = e.clientY;
        if (isPad()) {
            x = e.pageX;
            y = e.pageY;
        }
        if (isReactNative()) {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        }
        if (this.lastX && this.lastY) {
            let dx = x - this.lastX;
            let dy = y - this.lastY;
            this.moveX = this.state.translateX + dx;
            this.moveY = this.state.translateY + dy;
            this.setState({
                translateX: this.state.translateX + dx,
                translateY: this.state.translateY + dy,
            })
        }
        this.lastX = x;
        this.lastY = y;
    }

    adjustDialogPosition() {
        let adjustTransX = this.calculateMove(this.state.translateX, DIALOG_WIDTH / 5, window.innerWidth, DIRECTION.horizontal);
        let adjustTransY = this.calculateMove(this.state.translateY, TITLE_HEIGHT, window.innerHeight, DIRECTION.vertical)
        this.setState({
            translateX: adjustTransX,
            translateY: adjustTransY,
        })
        if (this.moveX != adjustTransX) this.moveX = adjustTransX;
        if (this.moveY != adjustTransY) this.moveY = adjustTransY;
    }

    calculateMove(move, dialogLength, maxLength, direction) {
        let min = (direction && direction == DIRECTION.horizontal) ? (dialogLength - DIALOG_WIDTH) : 0;
        let max = maxLength - dialogLength
        return this.calculateLength(move, min, max)
    }

    calculateLength(length, min, max) {
        if (min < length && length < max) {
            return length
        } else if (min >= length) {
            return min
        } else {
            return max
        }
    }

    isWWVersion() {
        return this.props.getUIStyle == uiType.ww
    }

    onClickClose() {
        this.props.onClose();
    }

    onClickTakeShot() {
        //take a picture
        if (this.state.screenShot == true) return;
        if (isPhotoSavedTimeoutId) {
            clearTimeout(isPhotoSavedTimeoutId);
            isPhotoSavedTimeoutId = null;
        }
        this.timestamp = this.getCurrenDate();
        this.setState({ isPhotoSaved: true, screenShot: true });
        setTimeout(() => { this.setState({ screenShot: false }) }, 100)
        isPhotoSavedTimeoutId = setTimeout(() => { this.setState({ isPhotoSaved: false }) }, 3500)
        if (this.props.screenshotWifiVision) this.props.screenshotWifiVision(this.props.getImageBase64, this.props.projectTitle, this.timestamp);
    }

    getCurrenDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = this.formateNum(date.getMonth() + 1);
        const day = this.formateNum(date.getDate());
        const hour = this.formateNum(date.getHours());
        const min = this.formateNum(date.getMinutes());
        const second = this.formateNum(date.getSeconds());
        return ` ${year}-${month}-${day} ${hour}${min}${second}`
    }
    formateNum(num) {
        if (typeof num == 'string') num = parseInt(num)
        return (num < 10) ? '0' + num : num.toString();
    }

    isEnVersion() {
        return store.get("locale", DEFAULT_LOCALE) == "en";
    }
    //save the video
    // onClickStartVideo() {
    //     //start to record
    //     const dateTime = new Date().getTime();
    //     this.timestamp = Math.floor(dateTime / 1000);
    //     this.setState({ isVideoMode: true })
    //     this.videoArray = [];
    //     recordIntervalId = setInterval(() => {
    //         this.parseRecodingVideo();
    //     }, 5 * baseDelay)
    // }
    // onClickStopVideo() {
    //     //save the video

    //     if (recordIntervalId) {
    //         clearInterval(recordIntervalId)
    //         recordIntervalId = null
    //     }
    //     this.setState({ isPhotoSaved: true });
    //     this.parseRecodingVideo();
    //     setTimeout(() => { this.setState({ isPhotoSaved: false }) }, 1000)
    //     this.resetRecordState();
    // }
    // parseRecodingVideo() {
    //     if (this.videoArray.length > 0) {
    //         let resultArray = [];
    //         let frameNumber = this.videoArray.length
    //         if (frameNumber < baseFps) {
    //             this.videoArray.forEach(frame => {
    //                 for (let i = 0; i < baseFps / frameNumber; i++) {
    //                     resultArray.push(frame)
    //                 }
    //             })
    //         } else {
    //             resultArray = this.videoArray
    //         }

    //         this.props.saveRecordingVideo(resultArray, this.props.projectTitle, this.timestamp);
    //     }
    //     this.videoArray = [];
    // }
    // onClickCloseVideo() {
    //     //close video mode
    //     this.props.removeTempRecodingVideo(this.props.projectTitle, this.timestamp);
    //     this.resetRecordState();
    // }
    // resetRecordState() {
    //     if (recordIntervalId) {
    //         clearInterval(recordIntervalId)
    //         recordIntervalId = null
    //     }
    //     this.videoArray = [];
    //     this.timestamp = null;
    //     this.setState({ isVideoMode: false })
    // }
    onMoveDialog() {
        this.props.focusDialog(DIALOG_STREAMING)
        this.setState({ action: ACTION.move })
    }

    getVisionInitStateString() {
        if (this.props.isVisionConnected == false) return <FormattedMessage id="gui.dialog.streaming.vision.disconnected.reminder" />;
        switch (this.props.getVisionInitState) {
            case VISION_INIT_STATE_TYPE.FAILED:
                return <FormattedMessage id="gui.dialog.streaming.vision.disconnected.reminder" />;
            case VISION_INIT_STATE_TYPE.INITIALIZING:
                return <FormattedMessage id="gui.dialog.vision.init" />;
            case VISION_INIT_STATE_TYPE.INTERRUPT:
            case VISION_INIT_STATE_TYPE.OCCUPY:
                return <FormattedMessage id="gui.dialog.vision.occupied" />;
            case VISION_INIT_STATE_TYPE.NONE:
            case VISION_INIT_STATE_TYPE.SUCCESS:
            default:
                return null;
        }
    }

    setShowingFullString(data) {
        this.setState({ onFullStringShow: data });
    }

    clearShowingFullString() {
        this.setState({ onFullStringShow: '' });
    }

    render() {
        return (
            <div className={classNames(dialogStyles.floatStyle)}
                style={{ top: this.state.translateY + 'px', left: this.state.translateX + 'px', zIndex: this.state.z_index }}>
                <div className={classNames(dialogStyles.floatModalStyle)}
                    style={{
                        height: this.state.dialogHeight + 'px',
                        width: this.state.dialogWidth + 'px'
                    }}>
                    <div
                        className={classNames(dialogStyles.helpHeader)}
                        onTouchStart={() => this.onMoveDialog()}
                        onMouseDown={() => this.onMoveDialog()}
                        style={{ height: TITLE_HEIGHT + "px", padding: 0 + "px", cursor: "move" }}
                    >
                        <div style={{ width: "100%" }}>
                            <div className={classNames(streamingStyles.title)}>
                                <FormattedMessage
                                    id={"gui.dialog.streaming.title"} />
                            </div>
                            <div className={classNames(streamingStyles.reminder)}>
                                <FormattedMessage
                                    id={"gui.dialog.streaming.reminder"} /></div>
                        </div>

                        <div className={classNames(streamingStyles.connectedArea)}>
                            <div className={classNames(dialogStyles.isConnectedText)}>
                                <FormattedMessage
                                    id={(this.props.getBrainInfo.brain_type && this.props.getBrainInfo.brain_type != BRAIN_TYPE.EDU) ? "gui.brainInfo.mobile.connect" : "gui.brainInfo.mobile.noConnect"}
                                />
                            </div>
                            <div className={classNames(dialogStyles.isConnectedLight,
                                (this.props.getBrainInfo.brain_type && this.props.getBrainInfo.brain_type != BRAIN_TYPE.EDU) ? dialogStyles.connected : dialogStyles.disconnected)} />
                        </div>
                    </div>
                    <div className={classNames(streamingStyles.contentArea)}>
                        <div className={classNames(streamingStyles.entryScreenArea)}>
                            <EntryScreen
                                ssn={this.state.brainStatus.ssn}
                                battery={this.state.brainStatus.battery}
                                connected={this.props.getBrainInfo && this.props.getBrainInfo.brain_type && this.props.getBrainInfo.brain_type != BRAIN_TYPE.EDU ? this.props.getBrainInfo.brain_type : EMPTY_DATA}
                                wifiSignal={this.getWifiSignal()}
                                entryScreen={this.state.entryScreen}
                            />
                        </div>

                        <div className={classNames(streamingStyles.visionPreviewArea)}>
                            <VisionPreview
                                src={this.state.src}
                                imgBase64={this.props.getImageBase64}
                                onClickTakeShot={() => this.onClickTakeShot()}
                                // onClickStartVideo={() => this.onClickStartVideo()}
                                // onClickStopVideo={() => this.onClickStopVideo()}
                                // onClickCloseVideo={() => this.onClickCloseVideo()}
                                onClickPopUp={() => { this.setState({ isStreamingDialogPopup: true }) }}
                                // isVideoMode={this.state.isVideoMode}
                                isEnVersion={this.isEnVersion()}
                                isVisionInitiated={this.props.isVisionInitiated}
                                isPhotoSaved={this.state.isPhotoSaved}
                                isStreamingDialogPopup={this.state.isStreamingDialogPopup}
                                isVisionConnected={this.state.isConnectedVision}
                                screenShot={this.state.screenShot}
                                getVisionInitStateString={this.getVisionInitStateString()}
                            />
                        </div>
                        <div className={classNames(streamingStyles.controllerArea)}>
                            <Controller
                                main={this.state.controller[CONTROLLER.main]}
                                minor={this.state.controller[CONTROLLER.minor]}
                                commandList={this.state.controllerRealtime}
                                showCommand={this.state.showCommand}
                            />
                        </div>
                        <div className={classNames(streamingStyles.sensorInfoArea)}>
                            <SensorInfo
                                entryPortInfo={this.updateEntryPortInfo()}
                                isConnectedSpeaker={this.state.isConnectedSpeaker}
                                isConnectedVision={this.state.isConnectedVision}
                                setShowingFullString={(data) => this.setShowingFullString(data)}
                                clearShowingFullString={() => this.clearShowingFullString()}
                                fullString={this.state.onFullStringShow}
                            />
                        </div>

                    </div>
                    <div className={classNames(streamingStyles.buttonCloseArea)}>
                        <div className={classNames(streamingStyles.button, streamingStyles.close)}
                            onClick={() => this.onClickClose()}>
                            <FormattedMessage
                                id={"gui.dialog.question.close"}
                            />
                        </div>
                    </div>
                </div >
                {(this.state.isStreamingDialogPopup) ?
                    <StreamingPopupDialog
                        vm={this.props.vm}
                        src={this.state.src}
                        imgBase64={this.props.getImageBase64}
                        onClickTakeShot={() => this.onClickTakeShot()}
                        // onClickStartVideo={() => this.onClickStartVideo()}
                        // onClickStopVideo={() => this.onClickStopVideo()}
                        // onClickCloseVideo={() => this.onClickCloseVideo()}
                        // isVideoMode={this.state.isVideoMode}
                        isEnVersion={this.isEnVersion()}
                        isPhotoSaved={this.state.isPhotoSaved}
                        screenShot={this.state.screenShot}
                        onClose={() => this.setState({ isStreamingDialogPopup: false })}
                    /> : null}

            </div >
        );
    }
}

const EntryScreen = (props) => {
    const {
        ssn,
        battery,
        connected,
        wifiSignal,
        entryScreen,
    } = props;
    return <div>
        <div className={classNames(streamingStyles.headingArea)}>
            <div className={classNames(streamingStyles.headingPoint)} />
            <div className={classNames(streamingStyles.heading)} >
                <FormattedMessage
                    id={"gui.dialog.streaming.index.screen"} />
            </div>
        </div>
        <div className={classNames(streamingStyles.entryScreenViewArea)}>
            <div className={classNames(streamingStyles.entryScreenText, (entryScreen.reverse == 1) ? streamingStyles.entryScreenReverse : null)}>{entryScreen.line1}</div>
            <div className={classNames(streamingStyles.entryScreenText, (entryScreen.reverse == 2) ? streamingStyles.entryScreenReverse : null)}>{entryScreen.line2}</div>
            <div className={classNames(streamingStyles.entryScreenText, (entryScreen.reverse == 3) ? streamingStyles.entryScreenReverse : null)}>{entryScreen.line3}</div>
            <div className={classNames(streamingStyles.entryScreenText, (entryScreen.reverse == 4) ? streamingStyles.entryScreenReverse : null)}>{entryScreen.line4}</div>
        </div>
        {(connected == EMPTY_DATA || connected == BRAIN_TYPE.EDU) ? <div className={classNames(streamingStyles.entryScreenCenter)}>
            <div className={classNames(streamingStyles.entryScreenCenterText)} >
                <FormattedMessage id={"gui.dialog.streaming.brain.disconnected"} />
            </div>
        </div> : null}
        <div className={classNames(streamingStyles.entryStatusArea)}>
            <div className={classNames(streamingStyles.entryStatus)}>
                <div className={classNames(streamingStyles.contentPoint)} />
                <div className={classNames(streamingStyles.contentHeading)} >
                    <FormattedMessage
                        id={"gui.dialog.streaming.index.ssn"} />
                </div>
                <div className={classNames(streamingStyles.contentStatus)} >
                    {ssn.length >= brainSNDisplayNum ? ssn.substr(ssn.length - brainSNDisplayNum, brainSNDisplayNum) : ssn}
                </div>
            </div>
            <div className={classNames(streamingStyles.entryStatus)}>
                <div className={classNames(streamingStyles.contentPoint)} />
                <div className={classNames(streamingStyles.contentHeading)} >
                    <FormattedMessage
                        id={"gui.dialog.streaming.index.battery"} />
                </div>
                <div className={classNames(streamingStyles.contentStatus)} >
                    {!battery.toString().includes("%") && !battery.toString().includes(EMPTY_DATA) ? battery + "%" : battery}
                </div>
            </div>
            <div className={classNames(streamingStyles.entryStatus)}>
                <div className={classNames(streamingStyles.contentPoint)} />
                <div className={classNames(streamingStyles.contentHeading)} >
                    <FormattedMessage
                        id={"gui.dialog.streaming.index.connected"} />
                </div>
                <div className={classNames(streamingStyles.contentStatus)} >
                    {getConnectedB1Text(connected)}
                </div>
            </div>
            <div className={classNames(streamingStyles.entryStatus)}>
                <div className={classNames(streamingStyles.contentPoint)} />
                <div className={classNames(streamingStyles.contentHeading)} >
                    <FormattedMessage
                        id={"gui.dialog.streaming.index.wifi"} />
                </div>
                <div className={classNames(streamingStyles.contentStatus)} >
                    {wifiSignal}
                </div>
            </div>
        </div>
    </div>
}

const getConnectedB1Text = (brainType) => {
    switch (brainType) {
        case BRAIN_TYPE.EDU_AND_ENTRY:
            return <FormattedMessage id={"gui.dialog.streaming.connected.b1.already"} />
        case BRAIN_TYPE.ENTRY:
            return <FormattedMessage id={"gui.dialog.streaming.connected.b1.none"} />
        default:
            return EMPTY_DATA
    }
}
const VisionPreview = (props) => {
    const {
        src,
        imgBase64,
        onClickTakeShot,
        screenShot,
        // onClickStartVideo,
        onClickPopUp,
        // onClickStopVideo,
        // onClickCloseVideo,
        // isVideoMode,
        isEnVersion,
        getVisionInitStateString,
        isVisionInitiated,
        isPhotoSaved,
        isStreamingDialogPopup,
        isVisionConnected
    } = props;
    const hasPreview = (src && src != "") || (imgBase64 && imgBase64 != "");
    const isUsbConnected = imgBase64 && imgBase64 != "";

    return <div>
        <div className={classNames(streamingStyles.headingArea)}>
            <div className={classNames(streamingStyles.headingPoint)} />
            <div className={classNames(streamingStyles.heading)} >
                <FormattedMessage
                    id={"gui.dialog.streaming.index.vision"} />
            </div>
        </div>
        <div className={classNames(streamingStyles.viewArea)}
            style={{ overflow: "hidden" }}>
            <div className={classNames(streamingStyles.view)}>
                {!isStreamingDialogPopup ? <div>
                    <div className={classNames(streamingStyles.photoSaved, isPhotoSaved ? streamingStyles.fadeIn : streamingStyles.fadeOut)}
                        style={{ lineHeight: isEnVersion ? '15px' : '30px' }}>
                        <FormattedMessage
                            id={"gui.dialog.streaming.vision.photo.saved"} /></div>
                    <div className={classNames(streamingStyles.shot, screenShot ? streamingStyles.shotFadeIn : streamingStyles.shotFadeOut)} />
                </div> : null}
                {!isStreamingDialogPopup ?
                    isVisionConnected ?
                        isVisionInitiated ?
                            hasPreview ?
                                // isUsbConnected ?
                                <img src={`data:image/jpeg;base64, ${imgBase64}`} id={'visionImg'}
                                    className={classNames(streamingStyles.smallIframe)} />
                                // : 
                                // <iframe
                                //     src={'//' + src}
                                //     className={classNames(streamingStyles.smallIframe)}
                                //     frameBorder="0"
                                // />
                                : <div className={classNames(streamingStyles.loadingPreviewPosition)}>
                                    <img src={loadingImg} className={classNames(streamingStyles.loading, streamingStyles.preview)} />
                                </div>
                            : <div className={classNames(streamingStyles.visionViewReminder)}>
                                {getVisionInitStateString}
                            </div>
                        : <div className={classNames(streamingStyles.visionViewReminder)}>
                            <FormattedMessage
                                id={"gui.dialog.streaming.vision.disconnected.reminder"} /></div>
                    : <div className={classNames(streamingStyles.visionViewReminder)}>
                        <FormattedMessage
                            id={"gui.dialog.streaming.vision.popup.reminder"} /></div>}
            </div>
            {hasPreview ?
                <div className={classNames(streamingStyles.popup, isStreamingDialogPopup ? streamingStyles.disable : null)}
                    onClick={() => onClickPopUp()}>
                    <img src={popupImg} className={classNames(streamingStyles.previewIcon)} />
                </div>
                : null}
            {/* <div className={classNames(streamingStyles.takeshot)}
                onClick={() => onClickTakeShot()}>
                <img src={takePictureImg} className={classNames(streamingStyles.previewIcon)} />
            </div> */}
            {isVisionConnected && hasPreview &&
                !isStreamingDialogPopup ?
                // !isVideoMode ?
                <div className={classNames(streamingStyles.takeshot)}
                    onClick={() => onClickTakeShot()}>
                    <img src={takePictureImg} className={classNames(streamingStyles.previewIcon)} />
                </div>
                // : <div className={classNames(streamingStyles.takeshot)}
                //     onClick={() => onClickStopVideo()}>
                //     <img src={videoStopImg} className={classNames(streamingStyles.previewIcon)} />
                // </div>
                : null}
            {/* {isVisionConnected && hasPreview && !isStreamingDialogPopup ? !isVideoMode ?
                <div className={classNames(streamingStyles.video)}
                    onClick={() => onClickStartVideo()}>
                    <img src={videoStartImg} className={classNames(streamingStyles.previewIcon)} />
                </div>
                : <div className={classNames(streamingStyles.video)}
                    onClick={() => onClickCloseVideo()}>
                    <img src={videoCloseImg} className={classNames(streamingStyles.previewIcon)} />
                </div> : null} */}
        </div>
    </div>
}

const SensorInfo = (props) => {
    const {
        entryPortInfo,
        isConnectedSpeaker,
        isConnectedVision,
        setShowingFullString,
        clearShowingFullString,
        fullString
    } = props;
    let language = store.get("locale", DEFAULT_LOCALE);
    let dsString = getSensorDataString(language, SENSOR.ds, INDEX.type);
    let visionString = getSensorDataString(language, SENSOR.vision, INDEX.type);
    return <div>
        <div className={classNames(streamingStyles.headingArea)}>
            <div className={classNames(streamingStyles.headingPoint)} />
            <div className={classNames(streamingStyles.heading)} >
                <FormattedMessage
                    id={"gui.dialog.streaming.index.sensorinfo"} />
            </div>
        </div>
        <div className={classNames(streamingStyles.sensorListArea)}>
            {entryPortInfo.map((portData, index) => {
                return <SmartSensorItem
                    key={'SmartSensorItem' + index}
                    number={portData.port}
                    type={portData.type}
                    status={portData.status}
                    language={language}
                    setShowingFullString={setShowingFullString}
                    clearShowingFullString={clearShowingFullString}
                    fullString={fullString}
                />
            })}

            <div className={classNames(streamingStyles.typeCItemArea)}>
                <div className={classNames(streamingStyles.sensorPort)}
                    onMouseEnter={() => { setShowingFullString(dsString) }}
                    onMouseLeave={() => { clearShowingFullString() }}
                    onTouchStart={() => { setShowingFullString(dsString) }}
                    onTouchEnd={() => { clearShowingFullString() }}>
                    {"Type C- " + (isConnectedSpeaker ? dsString : "")}</div>
                {isConnectedSpeaker && fullString == dsString ?
                    <div className={classNames(streamingStyles.fullDataString)}
                        style={{ left: '30px', top: '30px' }}>{dsString}</div> : null}
            </div>
            <div className={classNames(streamingStyles.typeCItemArea)}>
                <div className={classNames(streamingStyles.sensorPort)}
                    onMouseEnter={() => { setShowingFullString(visionString) }}
                    onMouseLeave={() => { clearShowingFullString() }}
                    onTouchStart={() => { setShowingFullString(visionString) }}
                    onTouchEnd={() => { clearShowingFullString() }}>
                    {"Type C- " + (isConnectedVision ? visionString : "")}</div>
                {isConnectedVision && fullString == visionString ?
                    <div className={classNames(streamingStyles.fullDataString)}
                        style={{ left: '30px', top: '30px' }}>{visionString}</div> : null}
            </div>
        </div>
    </div>
}

const SmartSensorItem = (props) => {
    const {
        number,
        type,
        status,
        language,
        setShowingFullString,
        clearShowingFullString,
        fullString
    } = props;
    let sensorString = getSensorDataString(language, SENSOR[type], INDEX.type)
    return <div className={classNames(streamingStyles.sensorItemArea)}>
        <div className={classNames(streamingStyles.sensorPortArea)}>
            <div className={classNames(streamingStyles.sensorPort)}
                onMouseEnter={() => { setShowingFullString(sensorString) }}
                onMouseLeave={() => { clearShowingFullString() }}
                onTouchStart={() => { setShowingFullString(sensorString) }}
                onTouchEnd={() => { clearShowingFullString() }}>
                {"Port " + number + "- " + ((type != "none") ? getSensorDataString(language, SENSOR[type], INDEX.type) : "")}</div>
            {(type != "none") && fullString == sensorString ?
                <div className={classNames(streamingStyles.fullDataString)}
                    style={{ left: '30px', top: '20px' }}>{sensorString}</div>
                : null}
        </div>
        <div className={classNames(streamingStyles.sensorStatusArea)}>{status.map((data, index) => {
            let key = data.split("：")[0];
            let keyString = getSensorDataString(language, SENSOR[type], key);
            let values = data.split("：")[1];
            let valueString = `${getDataValueString(language, SENSOR[type], key, values)}${getDataUnit(language, SENSOR[type], key) != "" ? ` ${getDataUnit(language, SENSOR[type], key)}` : ""}`
            return <div className={classNames(streamingStyles.sensorItem)} key={keyString + index}>
                <div className={classNames(streamingStyles.sensorKey)}
                    onMouseEnter={() => { setShowingFullString(keyString) }}
                    onMouseLeave={() => { clearShowingFullString() }}
                    onTouchStart={() => { setShowingFullString(keyString) }}
                    onTouchEnd={() => { clearShowingFullString() }}>
                    {keyString}</div>
                {fullString == keyString ? <div className={classNames(streamingStyles.fullDataString)}>{keyString}</div> : null}
                <div className={classNames(streamingStyles.sensorValue)}
                    onMouseEnter={() => { setShowingFullString(valueString) }}
                    onMouseLeave={() => { clearShowingFullString() }}
                    onTouchStart={() => { setShowingFullString(valueString) }}
                    onTouchEnd={() => { clearShowingFullString() }}>
                    {valueString}</div>
                {fullString == valueString ? <div className={classNames(streamingStyles.fullValueString)}>{valueString}</div> : null}

            </div>
        })}</div>
    </div>
}

const Controller = (props) => {
    const {
        main,
        minor,
        commandList,
        showCommand
    } = props;
    let language = store.get("locale", DEFAULT_LOCALE);
    return <div>
        <div className={classNames(streamingStyles.headingArea)}>
            <div className={classNames(streamingStyles.headingPoint)} />
            <div className={classNames(streamingStyles.heading)} >
                <FormattedMessage
                    id={"gui.dialog.streaming.index.controller"} />
            </div>
        </div>
        <div className={classNames(streamingStyles.controllerImageArea)} >
            <div className={classNames(streamingStyles.controllerSide)} >
                <img src={controllerSideImg} className={classNames(streamingStyles.controllerSideImg)} alt={"controller upside image"} />
                <Press main={main[RES_BUTTON.L1]} minor={minor[RES_BUTTON.L1]} button={BUTTON.L1} />
                <Press main={main[RES_BUTTON.R1]} minor={minor[RES_BUTTON.R1]} button={BUTTON.R1} />
                <Press main={main[RES_BUTTON.L2]} minor={minor[RES_BUTTON.L2]} button={BUTTON.L2} />
                <Press main={main[RES_BUTTON.R2]} minor={minor[RES_BUTTON.R2]} button={BUTTON.R2} />
            </div>
            <div className={classNames(streamingStyles.controllerFront)} >
                <img src={controllerImg} className={classNames(streamingStyles.controllerImg)} alt={"controller image"} />
                <Press main={main[RES_BUTTON.up]} minor={minor[RES_BUTTON.up]} button={BUTTON.Up} />
                <Press main={main[RES_BUTTON.down]} minor={minor[RES_BUTTON.down]} button={BUTTON.Down} />
                <Press main={main[RES_BUTTON.left]} minor={minor[RES_BUTTON.left]} button={BUTTON.Left} />
                <Press main={main[RES_BUTTON.right]} minor={minor[RES_BUTTON.right]} button={BUTTON.Right} />
                <Press main={main[RES_BUTTON.X]} minor={minor[RES_BUTTON.X]} button={BUTTON.X} />
                <Press main={main[RES_BUTTON.Y]} minor={minor[RES_BUTTON.Y]} button={BUTTON.Y} />
                <Press main={main[RES_BUTTON.A]} minor={minor[RES_BUTTON.A]} button={BUTTON.A} />
                <Press main={main[RES_BUTTON.B]} minor={minor[RES_BUTTON.B]} button={BUTTON.B} />
                <Press main={main[RES_BUTTON.L3]} minor={minor[RES_BUTTON.L3]} button={BUTTON.L3} />
                <Press main={main[RES_BUTTON.R3]} minor={minor[RES_BUTTON.R3]} button={BUTTON.R3} />
            </div>
        </div>
        <div className={classNames(streamingStyles.controllerCommandArea)} >
            {commandList.map((command, index) => {
                let controllerNum = parseInt(command.split(";")[0]);
                const title = CONTROLLER_STRING[language]['controller' + controllerNum]
                let message = command.split(";")[1];
                if (index + 1 > REALTIME_COMMAND_LIMIT) return null
                return <div
                    key={`command${index}`}
                    className={classNames(streamingStyles.commandBackground,
                        (showCommand) ? streamingStyles.fadeIn : streamingStyles.fadeOut)} >
                    <div className={classNames(controllerNum == REALTIME_CONTROLLER.main ? streamingStyles.commandMainController : streamingStyles.commandMinorController)}>{title}</div>
                    <div className={classNames(streamingStyles.commandMsg)}>{message}</div>

                </div>
            })}
        </div >
    </div>
}

const Press = (props) => {
    const {
        main,
        minor,
        button
    } = props;
    const show = main == true || minor == true
    const colorStyle = main == true && minor == true ? streamingStyles.bothPress : main == true ? streamingStyles.mainPress : minor == true ? streamingStyles.minorPress : null
    const sizeStyle = (button == BUTTON.L3 || button == BUTTON.R3) ? streamingStyles.pressBig : streamingStyles.pressSmall;
    const positionStyle = streamingStyles['press' + button]
    if (!show == true) return null;
    return <div className={classNames(sizeStyle, positionStyle, colorStyle)} />
}

StreamingDialog.propTypes = {
    onClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state),

    isBrainRunning: getBrainRunningStatus(state),

    getImageBase64: getImageBase64(state),
    getUIStyle: getUIStyle(state),
    getVisionInitState: getVisionInitState(state),
    getImageHeight: getImageHeight(state),
    getImageWidth: getImageWidth(state),
    getBrainInfo: getBrainInfo(state),
    foregroundDialog: getForegroundDialog(state),
    getWifiVision: getWifiVision(state),
    getEntryBrainScreen: getEntryBrainScreen(state),
    getEntryDeviceStatus: getEntryDeviceStatus(state),
    getControllerResponse: getControllerResponse(state),
    getControllerStatus: getControllerStatus(state),
    getRealEntryDeviceList: getRealEntryDeviceList(state),
    getWifiStatus: getWifiStatus(state),
    getPlatform: getPlatform(state),
    projectTitle: projectTitle(state)
});

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(hideStreamingDialog()),
    clearImageBase64: () => dispatch(setImageBase64(null)),
    clearImageTagList: () => dispatch(setImageTagList([])),
    focusDialog: (dialog) => dispatch(focusDialog(dialog)),
    resetWifiVision: () => dispatch(setWifiVision({})),
    resetWifiStatus: () => dispatch(setWifiStatus({})),
    setWifiStatus: (status) => dispatch(setWifiStatus(status))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamingDialog);