const SHOW_LOADING_BAR = 'loadingBar/SHOW_LOADING_BAR';

const LOADING_PERCENT = 'loadingPercentage';
const SHOW_LOADING = 'showLoading';
const LOADING_TYPE = 'loadingType';

const LOADING_TYPE_NUM = {
    TYPE_FILE: 0,
    TYPE_AUDIO: 1
}

const initialState = {
    [LOADING_TYPE]: LOADING_TYPE_NUM.TYPE_FILE,
    [SHOW_LOADING]: false,
    [LOADING_PERCENT]: 0
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SHOW_LOADING_BAR:
            if (!action.loadingType) {
                action.loadingType = LOADING_TYPE_NUM.TYPE_AUDIO;
            }
            return Object.assign({}, state, {
                [LOADING_TYPE]: action.loadingType,
                [SHOW_LOADING]: action.show,
                [LOADING_PERCENT]: action.percent,
            });
        default:
            return state;
    }
};
const showLoadingBar = (show, percent, loadingType) => ({
    type: SHOW_LOADING_BAR,
    show: show,
    percent: percent,
    loadingType: loadingType
});

const showLoading = state => state.scratchGui.loadingBar[SHOW_LOADING];
const getLoadingPercentage = state => state.scratchGui.loadingBar[LOADING_PERCENT];
const getLoadingType = state => state.scratchGui.loadingBar[LOADING_TYPE];

export {
    reducer as default,
    initialState as loadingBarInitialState,
    LOADING_TYPE_NUM,

    showLoadingBar,
    showLoading,
    getLoadingPercentage,
    getLoadingType
};
