import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import styles from './brain.css';
import b2Line1Img from '../pictures/brain-line/b2_line1.svg'
import b2Line2Img from '../pictures/brain-line/b2_line2.svg'
import b2Line3Img from '../pictures/brain-line/b2_line3.svg'
import b2Line4Img from '../pictures/brain-line/b2_line4.svg'
import b2Line5Img from '../pictures/brain-line/b2_line5.svg'
import b2Line6Img from '../pictures/brain-line/b2_line6.svg'
import smartPortImg from '../pictures/port_entry_smart.svg'
import smartPortHoverImg from '../pictures/port_entry_smart_mouseover.svg'

import {
    getHoveredConnectedDevice,
    isConnectedDeviceHovered
} from '../../../reducers/select-option'
class EntryLine extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }

    isConnectedDeviceHovered(port) {
        let isConnectedDeviceHovered = this.props.isConnectedDeviceHovered;
        if (isConnectedDeviceHovered) {
            let hoveredArray = this.props.getHoveredConnectedDevice;
            if (hoveredArray && hoveredArray.length > 0) {
                isConnectedDeviceHovered = hoveredArray.includes(port);
            }
        }
        return isConnectedDeviceHovered;
    }

    render() {
        const {
            reverse
        } = this.props;
        return (
            <div>
                <div className={classNames(styles.lineEntry6, this.isConnectedDeviceHovered(!reverse ? "6" : "7") ? styles.lineSelected : null)}><img src={b2Line1Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEntry5, this.isConnectedDeviceHovered(!reverse ? "5" : "8") ? styles.lineSelected : null)}><img src={b2Line2Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEntry4, this.isConnectedDeviceHovered(!reverse ? "4" : "9") ? styles.lineSelected : null)}><img src={b2Line3Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEntry3, this.isConnectedDeviceHovered(!reverse ? "3" : "10") ? styles.lineSelected : null)}><img src={b2Line4Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEntry2, this.isConnectedDeviceHovered(!reverse ? "2" : "11") ? styles.lineSelected : null)}><img src={b2Line5Img} alt={"line"} /></div>
                <div className={classNames(styles.lineEntry1, this.isConnectedDeviceHovered(!reverse ? "1" : "12") ? styles.lineSelected : null)}><img src={b2Line6Img} alt={"line"} /></div>
                <div className={classNames(styles.portEntryImg6)}><img src={this.isConnectedDeviceHovered(!reverse ? "6" : "7") ? smartPortHoverImg : smartPortImg} alt={"port image"} /></div>
                <div className={classNames(styles.portEntryImg5)}><img src={this.isConnectedDeviceHovered(!reverse ? "5" : "8") ? smartPortHoverImg : smartPortImg} alt={"port image"} /></div>
                <div className={classNames(styles.portEntryImg4)}><img src={this.isConnectedDeviceHovered(!reverse ? "4" : "9") ? smartPortHoverImg : smartPortImg} alt={"port image"} /></div>
                <div className={classNames(styles.portEntryImg3)}><img src={this.isConnectedDeviceHovered(!reverse ? "3" : "10") ? smartPortHoverImg : smartPortImg} alt={"port image"} /></div>
                <div className={classNames(styles.portEntryImg2)}><img src={this.isConnectedDeviceHovered(!reverse ? "2" : "11") ? smartPortHoverImg : smartPortImg} alt={"port image"} /></div>
                <div className={classNames(styles.portEntryImg1)}><img src={this.isConnectedDeviceHovered(!reverse ? "1" : "12") ? smartPortHoverImg : smartPortImg} alt={"port image"} /></div>
            </div>
        );
    }

}

EntryLine.propTypes = {
    deviceList: PropTypes.array,
    hide: PropTypes.bool,
    reverse: PropTypes.bool
};

const mapStateToProps = state => ({
    getHoveredConnectedDevice: getHoveredConnectedDevice(state),
    isConnectedDeviceHovered: isConnectedDeviceHovered(state),
});

export default connect(
    mapStateToProps,
)(EntryLine);