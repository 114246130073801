import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import Box from '../box/box.jsx';
import styles from './blocks.css';
import { connect } from 'react-redux';

const BlocksComponent = props => {
    const {
        containerRef,
        dragOver,
        isToolboxVisible,
        ...componentProps
    } = props;
    return (
        <Box
            className={classNames(styles.blocks, {
                [styles.dragOver]: dragOver
            }, isToolboxVisible ? styles.toolbox_expand: styles.toolbox_shrink)}
            {...componentProps}
            componentRef={containerRef}
        />
    );
};
BlocksComponent.propTypes = {
    containerRef: PropTypes.func,
    dragOver: PropTypes.bool,
    isToolboxVisible: PropTypes.bool
};

const mapStateToProps = state => ({
    isToolboxVisible: state.scratchGui.toolbox.isToolboxVisible
});

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlocksComponent);
