const eventBlocks = (function () {
    const eventWhenBumperButton =
        `<block type="event_whenbumperbutton"/>`;

    const eventWhenTouchLEDButton =
        `<block type="event_whentouchledbutton"/>`;

    const eventWhenControllerButton =
        `<block type="event_whencontrollerbutton"/>`;

    const eventWhenControllerAxisChanged =
        `<block type="event_whencontrolleraxischanged"/>`;

    const eventWhenFaceDetected =
        `<block type="event_whenfacedetected"/>`;

    const eventWhenBrainButton =
        `<block type="event_whenbrainbutton"/>`;

    const eventWhenStarted =
        `<block type="event_whenstarted"/>`;

    const eventWhenTimerGreaterThan =
        `<block type="event_whentimergreaterthan">
            <value name="VALUE">
                <shadow type="restrict_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>`;

    const eventWhenBroadcastReceived =
        `<block type="event_whenbroadcastreceived"/>`;

    const eventWhenBroadcast =
        `<block type="event_broadcast"/>`;

    const eventWhenBroadcastandWait =
        `<block type="event_broadcastandwait"/>`;

    const eventWhenRFIDDetectsData =
        `<block type="event_whenrfiddetectsdata"/>`;

    return {
        event_whenbumperbutton: eventWhenBumperButton,
        event_whentouchledbutton: eventWhenTouchLEDButton,
        event_whencontrollerbutton: eventWhenControllerButton,
        event_whencontrolleraxischanged: eventWhenControllerAxisChanged,
        event_whenfacedetected: eventWhenFaceDetected,
        event_whenbrainbutton: eventWhenBrainButton,
        event_whenstarted: eventWhenStarted,
        event_whentimergreaterthan: eventWhenTimerGreaterThan,
        event_whenbroadcastreceived: eventWhenBroadcastReceived,
        event_broadcast: eventWhenBroadcast,
        event_broadcastandwait: eventWhenBroadcastandWait,
        event_whenrfiddetectsdata: eventWhenRFIDDetectsData,
    };
})();

export default eventBlocks;


