const SET_FILE_PROCESSING = 'fileManager/SET_FILE_PROCESSING';

const FILE_PROCESSING = 'fileProcessing';

const initialState = {
    [FILE_PROCESSING]: false,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_FILE_PROCESSING:
            return Object.assign({}, state, {
                [FILE_PROCESSING]: action.processing
            });
        default:
            return state;
    }
};
const setFileProcessing = (processing) => ({
    type: SET_FILE_PROCESSING,
    processing: processing
});

const isFileProcessing = state => state.scratchGui.fileManager[FILE_PROCESSING];

export {
    reducer as default,
    initialState as fileManagerInitialState,

    setFileProcessing,
    isFileProcessing
};
