import {
    ENABLE_BRAIN_TYPE_ENTRY,
    ENABLE_BRAIN_TYPE_EDU_AND_ENTRY,
    ENABLE_BRAIN_TYPE_WEB_VR
} from '../config/project-config'

import {
    BRAIN_TYPE
} from '../lib/brains';


const UPDATE_PICKED_BRAIN_TYPE = 'scratch-gui/picked-brain-type/UPDATE_PICKED_BRAIN_TYPE';
const SET_PICKED_BRAIN_TYPE = 'scratch-gui/picked-brain-type/SET_PICKED_BRAIN_TYPE';
const CONFIG_ENABLE_BRAIN_TYPE_ENTRY = 'configEnableBrainTypeEntry';
const CONFIG_ENABLE_BRAIN_TYPE_EDU_AND_ENTRY = 'configEnableBrainTypeEduAndEntry';
const CONFIG_ENABLE_BRAIN_TYPE_WEB_VR = 'configEnableBrainTypeWebVR';

const IS_PICKED_BRAIN_TYPE = 'isPickedBrainType';
const PICKED_BRAIN_TYPE = 'pickedBrainType';

const initialState = {
    [CONFIG_ENABLE_BRAIN_TYPE_ENTRY]: ENABLE_BRAIN_TYPE_ENTRY,
    [CONFIG_ENABLE_BRAIN_TYPE_EDU_AND_ENTRY]: ENABLE_BRAIN_TYPE_EDU_AND_ENTRY,
    [CONFIG_ENABLE_BRAIN_TYPE_WEB_VR]: ENABLE_BRAIN_TYPE_WEB_VR,
    [IS_PICKED_BRAIN_TYPE]: false,
    [PICKED_BRAIN_TYPE]: ENABLE_BRAIN_TYPE_WEB_VR ? BRAIN_TYPE.WEB_VR : BRAIN_TYPE.EDU
}

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case UPDATE_PICKED_BRAIN_TYPE:
            return Object.assign({}, state, {
                [IS_PICKED_BRAIN_TYPE]: action.isPickedBrainType
            });
        case SET_PICKED_BRAIN_TYPE:
            return Object.assign({}, state, {
                [PICKED_BRAIN_TYPE]: action.brainType
            });
        case CONFIG_ENABLE_BRAIN_TYPE_ENTRY:
            return Object.assign({}, state, {
                [CONFIG_ENABLE_BRAIN_TYPE_ENTRY]: action.enable
            });
        case CONFIG_ENABLE_BRAIN_TYPE_EDU_AND_ENTRY:
            return Object.assign({}, state, {
                [CONFIG_ENABLE_BRAIN_TYPE_EDU_AND_ENTRY]: action.enable
            });
        case CONFIG_ENABLE_BRAIN_TYPE_WEB_VR:
            return Object.assign({}, state, {
                [CONFIG_ENABLE_BRAIN_TYPE_WEB_VR]: action.enable
            });
        default:
            return state;
    }
};

const setEnableConfigEntry = (status) => ({
    type: CONFIG_ENABLE_BRAIN_TYPE_ENTRY,
    enable: status
})
const setEnableConfigEduAndEntry = (status) => ({
    type: CONFIG_ENABLE_BRAIN_TYPE_EDU_AND_ENTRY,
    enable: status
})

const setEnableConfigWebVR = (status) => ({
    type: CONFIG_ENABLE_BRAIN_TYPE_WEB_VR,
    enable: status
})

const updatePickedBrainType = () => ({
    type: UPDATE_PICKED_BRAIN_TYPE,
    isPickedBrainType: true
});

const setPickedBrainType = (brainType) => ({
    type: SET_PICKED_BRAIN_TYPE,
    brainType: brainType
})

const isPickedBrainType = (state) => state.scratchGui.isPickedBrainType[IS_PICKED_BRAIN_TYPE];
const getPickedBrainType = (state) => state.scratchGui.isPickedBrainType[PICKED_BRAIN_TYPE];


const isEnableEntry = (state) => state.scratchGui.isPickedBrainType[CONFIG_ENABLE_BRAIN_TYPE_ENTRY];
const isEnableEduAndEntry = (state) => state.scratchGui.isPickedBrainType[CONFIG_ENABLE_BRAIN_TYPE_EDU_AND_ENTRY];
const isEnableWebVR = (state) => state.scratchGui.isPickedBrainType[CONFIG_ENABLE_BRAIN_TYPE_WEB_VR];


export {
    reducer as default,
    initialState as isPickedBrainTypeInitailState,
    CONFIG_ENABLE_BRAIN_TYPE_EDU_AND_ENTRY,
    CONFIG_ENABLE_BRAIN_TYPE_ENTRY,
    setEnableConfigEntry,
    setEnableConfigEduAndEntry,
    setEnableConfigWebVR,
    isEnableEntry,
    isEnableEduAndEntry,
    isEnableWebVR,
    updatePickedBrainType,
    isPickedBrainType,
    setPickedBrainType,
    getPickedBrainType
};