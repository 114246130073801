export default {
    "en": {
        "using-buzzer": {
            "name": "Using Buzzer",
            "direction": "After starting the program, the buzzer plays a continous note until the bumper is pressed. The buzzer will then play Twinkle, Twinkle, Little Star."
        },
        "using-leds": {
            "name": "LED Control",
            "direction": "The bumper acts as a LED light switch. Press the bumper to toggle the LED on or off. Each LED status is saved in a variable block and changes accordingly when the bumper is pressed."
        },
        "repeating-actions": {
            "name": "Using Repeat Actions",
            "direction": "Use the repeat block to repeat the drive and turn actions multiple times. This allows the robot to patrol in a small area."
        },
        "line-tracking": {
            "name": "Line Tracking",
            "direction": "Based on the reflectivity of the line, the line tracking sensor can determine if a black line is detected. Dark surfaces will have less reflectivity. Mount three line tracking sensors side by side facing downward to track the black line on the ground. The black line on the ground is approximately 30mm wide. When the two side trackers detect black line, the robot is off path."
        },
        "detecting-walls": {
            "name": "Using the Bumper",
            "direction": "From the example above, the bumper helps avoid obstacles. Mount the bumper facing forward on the drivetrain. The robot drives backward slightly then stops when the bumper is pressed on impact."
        },
        "detecting-light": {
            "name": "Using Light Sensor",
            "direction": "Line tracker functions as light sensors. The sensor emits light and receives the reflected light. Mount the sensor facing upward on the drivetrain. The robot drives forward when the sensor is blocked by the white paper, and stops when the sensor is not blocked. Light will not be reflected when using a black paper."
        },
        "detecting-distances": {
            "name": "Using Sonar Sensor",
            "direction": "The sonar sensor emits ultrasonic sound waves to calculate the distance to an object. In this example, the sensor is mounted on the drivetrain facing forward, allowing the robot to stop moving and drive back slightly before colliding with the object."
        },
        "move-turn-gyro": {
            "name": "Move and turn with Gyro",
            "direction": "Gyro sensor detects angle changes during motion. It assists drivetrain system to achieve more precise turning control. After starting the program, the robot will process a series of moves and turns. Observer whether these movements match the settings in the program."
        },
        "controller-control-devices": {
            "name": "Controller control devices",
            "direction": "Controller not only can control drivetrain drive but also control devices by using buttons. In this program, one button can activate multiple devices. Press A to move robot and turn on the light. Press B to stop and turn off the light."
        },
        "color-sensor": {
            "name": "Color Sensor",
            "direction": "When Color sensor detects red, the robot moves forward, otherwise stop. The detected color names will be shown on B2 screen."
        },
        "touchled-change-colors": {
            "name": "Touch LED change colors",
            "direction": "Touch LED can show many colors. In this example, by using a variable to count pressing times, LED will show a new color for each touching."
        },
        "color-changingspeed-touchled": {
            "name": "Color changing speed of Touch LED",
            "direction": "The speed of one color fading to another on Touch LED is adjustable. In this example, the speed is set to slow. Press Touch LED to display color switching effect."
        },
        "touchled-brightness": {
            "name": "Touch LED Brightness",
            "direction": "In this example, press Touch LED once, LED will show a breathing effect, repeated cycle of turn on and off slowly."
        }

    },
    "zh-tw": {
        "using-buzzer": {
            "name": "蜂鳴器的應用",
            "direction": "程式開始後，蜂鳴器會持續播放單音，直到按下當作開關的碰撞感應器，接著蜂鳴器會播放小星星。"
        },
        "using-leds": {
            "name": "LED燈控制的應用",
            "direction": "將碰撞感測器當作LED燈開關，按一次打開LED燈，再按一次關閉。每次開關都利用變數去紀錄燈光目前的開關狀態，下一次按碰撞感測器時就可以改變狀態。"
        },
        "repeating-actions": {
            "name": "重複動作的應用",
            "direction": "利用重複的積木，進行多次前進與轉彎的動作，而不需要使用多個前進與轉彎積木，讓機器人在小範圍巡邏。"
        },
        "line-tracking": {
            "name": "循線的積木設計",
            "direction": "循線感測器利用光反射率來辨別是否偵測到黑線，當光線被黑色吸收，反射率會較低。同時使用三顆循線感測器並排朝向地面，地面黑線約為30mm寬，當左右兩側的感應器偵測到黑線，表示偏離路徑了。"
        },
        "detecting-walls": {
            "name": "碰撞感測器的應用",
            "direction": "在這個範例，碰撞感測器做為避障偵測使用，將碰撞感測器安裝在底盤朝向前方，當碰撞感測器被撞到，機器人會後退一點後停止動作。"
        },
        "detecting-light": {
            "name": "光線感測器的應用",
            "direction": "將循線感測器當作光感測器來使用，循線感測器發射光線並接收反射的光線。在這個範例，將感測器當作開關安裝在底盤並朝上，用白紙遮蔽時前進，不遮蔽時停止。如果使用黑色的紙張，光線將被吸收而不會反彈。"
        },
        "detecting-distances": {
            "name": "超音波距離感測器的應用",
            "direction": "超音波距離感測器發出超音波，以感測與物體的距離，在這個範例，將感測器安裝在底盤，並朝向前方，可以在撞到障礙物以前就停止移動，並往後退。"
        },
        "move-turn-gyro": {
            "name": "陀螺儀輔助轉向",
            "direction": "陀螺儀可以偵測運動角度的變化，安裝陀螺儀來輔助底盤動力系統，可以控制較精準的轉向角度。程式開始執行以後，會進行一連串的前進與轉向，觀察這些運動與程式中輸入的數值是否相符合。"
        },
        "controller-control-devices": {
            "name": "遙控器控制多個裝置",
            "direction": "遙控器除了控制底盤驅動外，還可以設定按鍵來控制裝置。一個按鍵可以同時啟動多個裝置，按A可以開始驅動與開啟燈光，按Ｂ可以結束驅動與燈光。"
        },
        "color-sensor": {
            "name": "色彩感測器",
            "direction": "當色彩感測器感應到紅色時機器人會前進，沒有感測到時則會停止，可以在B2主機的螢幕上看到偵測到的顏色名稱是什麼。"
        },
        "touchled-change-colors": {
            "name": "觸碰LED變換顏色",
            "direction": "觸碰LED有許多顏色可以使用，在這個範例，利用變數紀錄按的次數，每按一次可以切換一個顏色。"
        },
        "color-changingspeed-touchled": {
            "name": "觸碰LED換顏色速度",
            "direction": "觸碰LED變換顏色的速度是可以改變的，在這個範例將變換速度設為慢，並且將觸碰LED的感應區當作開關，一觸碰就開始展示兩個顏色燈光的轉換。"
        },
        "touchled-brightness": {
            "name": "觸碰LED亮度變化",
            "direction": "在這個範例，按壓一次觸碰LED，LED會不停緩慢亮起與熄滅，製造呼吸燈的效果。"
        }
    },
    "zh-cn": {
        "using-buzzer": {
            "name": "蜂鸣器的应用",
            "direction": "程序开始后，蜂鸣器会持续播放单音，直到按下当作开关的触碰传感器，接着蜂鸣器会播放小星星。"
        },
        "using-leds": {
            "name": "LED灯控制的应用",
            "direction": "将触碰传感器当作LED灯光开关，按一次打开LED灯，再按一次关闭。 每次开关都利用变量去纪录灯光目前的开关状态，下一次按触碰传感器时就可以改变状态。"
        },
        "repeating-actions": {
            "name": "重复动作的应用",
            "direction": "利用重复的积木，进行多次前进与转弯的动作，而不需要使用多个前进与转弯积木，让机器人在小范围巡逻。"
        },
        "line-tracking": {
            "name": "循线的积木设计",
            "direction": "循线传感器利用光反射率来辨别是否侦测到黑线，当光线被黑色吸收，反射率会较低。 同时使用三颗循线传感器并排朝向地面，地面黑线约为30mm宽，当左右两侧的传感器侦测到黑线，表示偏离路径了。"
        },
        "detecting-walls": {
            "name": "触碰传感器的应用",
            "direction": "在这个范例，触碰传感器做为避障侦测使用，将触碰传感器安装在底盘朝向前方，当触碰传感器被撞到，机器人会后退一点后停止动作。"
        },
        "detecting-light": {
            "name": "光线传感器的应用",
            "direction": "将循线传感器当作光传感器来使用，循线传感器发射光线并接收反射的光线。 在这个范例，将传感器当作开关安装在底盘并朝上，用白纸遮蔽时前进，不遮蔽时停止。 如果使用黑色的纸张，光线将被吸收而不会反弹。"
        },
        "detecting-distances": {
            "name": "超音波距离传感器的应用",
            "direction": "超音波距离传感器发出超音波，以感测与物体的距离，在这个范例，将传感器安装在底盘，并朝向前方，可以在撞到障碍物以前就停止移动，并往后退。"
        },
        "move-turn-gyro": {
            "name": "陀螺仪辅助转向",
            "direction": "陀螺仪可以侦测运动角度的变化，安装陀螺仪来辅助底盘动力系统，可以控制较精准的转向角度。程序开始执行以后，会进行一连串的前进与转向，观察这些运动与程序中输入的数值是否相符合。"
        },
        "controller-control-devices": {
            "name": "手柄控制多个装置",
            "direction": "手柄除了控制底盘驱动外，还可以设定按键来控制装置。 一个按键可以同时启动多个装置，按A可以开始驱动与开启灯光，按B可以结束驱动与灯光。"
        },
        "color-sensor": {
            "name": "色彩传感器",
            "direction": "当色彩传感器感应到红色时机器人会前进，没有感测到时则会停止，可以在B2主机的屏幕上看到侦测到的颜色名称是什么。"
        },
        "touchled-change-colors": {
            "name": "触碰LED变换颜色",
            "direction": "触碰LED有许多颜色可以使用，在这个范例，利用变量纪录按的次数，每按一次可以切换一个颜色。"
        },
        "color-changingspeed-touchled": {
            "name": "触碰LED换颜色速度",
            "direction": "触碰LED变换颜色的速度是可以改变的，在这个范例将变换速度设为慢，并且将触碰LED的感应区当作开关，一触碰就开始展示两个颜色灯光的转换。"
        },
        "touchled-brightness": {
            "name": "触碰LED亮度变化",
            "direction": "在这个范例，按压一次触碰LED，LED会不停缓慢亮起与熄灭，制造呼吸灯的效果。"
        }
    }
}