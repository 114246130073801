import classNames from 'classnames';
import React from 'react';
import visionSettingStyles from './vision-setting-dialog.css'
import dialogStyles from '../dialog.css'
import { FormattedMessage } from 'react-intl';
import snapshotIcon from '../svg/cv_snapshot.svg';
import uploadIcon from '../svg/cv_upload.svg';
import resetIcon from '../svg/cv_reset.svg'
import { DetectedSnapshotTag } from './tag.jsx'
import loadingImg from '../svg/loading.svg'
import { VISION_NAME_RULE, VISION_IMAGE_MODE } from './vision-utils.js';
import { getErrorMsg } from '../../../reducers/dialog';

export const EditNameDialog = (props) => {
    const {
        show,
        name,
        onNameChange,
        onClickConfirm,
        onClickCancel,
        nameValidCode,
        titleId,
        reminderId
    } = props;
    const isNameValid = nameValidCode == VISION_NAME_RULE.VALID;
    if (!show) return null;
    return <div className={classNames(dialogStyles.backdropStyle)}>
        <div className={classNames(dialogStyles.modalStyle, dialogStyles.editProfile)} >
            <div className={classNames(dialogStyles.firmwareHeader)}>
                <FormattedMessage id={titleId} />
            </div>
            <div className={classNames(dialogStyles.visionView)}>
                {/* input name */}
                <div className={classNames(visionSettingStyles.editProfileNameInputArea,
                    (!isNameValid ? visionSettingStyles.inputNameErrorBorder : null))}>
                    <input type="text"
                        name="profileName"
                        placeholder=""
                        value={name}
                        maxLength="20"
                        onChange={e => onNameChange(e)}
                        className={classNames(visionSettingStyles.editProfileInputName)}
                    />
                    {reminderId ? <div className={classNames(visionSettingStyles.invalidReminder)}>
                        <FormattedMessage id={reminderId} />                    </div>
                        : null}
                </div>
                <div className={classNames(dialogStyles.questionFooter)}>
                    <div className={classNames(dialogStyles.questionWhiteBtn, dialogStyles.vision)} onClick={() => onClickCancel()}>
                        <FormattedMessage
                            id="gui.dialog.question.cancel"
                        />
                    </div>
                    <div className={classNames(dialogStyles.questionInterval)} />
                    <div className={classNames(dialogStyles.questionBtn, dialogStyles.vision, !isNameValid ? visionSettingStyles.disable : null)} onClick={() => { if (isNameValid) { onClickConfirm() } }}>
                        <FormattedMessage
                            id="gui.hint.deleteBlocks.confirm"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}


export const ReminderDialog = (props) => {
    const {
        show,
        titleId,
        msgId,
        onClickConfirm
    } = props;
    if (!show) return null;
    return <div className={classNames(dialogStyles.backdropStyle, dialogStyles.error)}>
        <div className={classNames(dialogStyles.modalStyle, dialogStyles.editProfile)} >
            <div className={classNames(dialogStyles.visionView)}>
                {titleId ? <div className={classNames(dialogStyles.firmwareHeader)}>
                    <FormattedMessage id={titleId} />
                </div> : null}
                {msgId ? <div className={classNames(visionSettingStyles.questionContent)}>
                    <FormattedMessage id={msgId} />
                </div> : null}
            </div>
            <div className={classNames(dialogStyles.questionFooter)}>
                <div className={dialogStyles.questionBtn} onClick={() => { onClickConfirm() }}>
                    <FormattedMessage
                        id="gui.hint.deleteBlocks.confirm"
                    />
                </div>
            </div>
        </div>
    </div>
}

export const QuestionDialog = (props) => {
    const {
        show,
        titleId,
        msgId,
        onClickConfirm,
        onClickCancel
    } = props;
    if (!show) return null;
    return <div className={classNames(dialogStyles.backdropStyle, dialogStyles.error)}>
        <div className={classNames(dialogStyles.modalStyle, dialogStyles.editProfile)} >
            <div className={classNames(dialogStyles.visionView)}>
                <div className={classNames(dialogStyles.firmwareHeader)}>
                    <FormattedMessage id={titleId} />
                </div>
                <div className={classNames(dialogStyles.questionContent)}>
                    <FormattedMessage id={msgId} />
                </div>
            </div>
            <div className={classNames(dialogStyles.questionFooter)}>
                <div className={classNames(dialogStyles.questionWhiteBtn, dialogStyles.vision)} onClick={() => onClickCancel()}>
                    <FormattedMessage id="gui.dialog.question.cancel" />
                </div>
                <div className={classNames(dialogStyles.questionInterval)} />
                <div className={classNames(dialogStyles.questionBtn, dialogStyles.vision)} onClick={() => onClickConfirm()}>
                    <FormattedMessage id="gui.hint.deleteBlocks.confirm" />
                </div>
            </div>
        </div>
    </div>
}


export const FaceRegisterDialog = (props) => {
    const {
        show,
        isNew,
        onClickSnapshot,
        onClickUpload,
        onClickCancel,
        isVisionDistribute,
        isVisionConnected,
        getVisionInitStateString
    } = props;
    if (!show) return null;
    return <div className={classNames(dialogStyles.backdropStyle)}>
        <div className={classNames(dialogStyles.modalStyle, dialogStyles.faceRegister)} >
            <div className={classNames(dialogStyles.firmwareHeader)}>
                {(isNew) ? <FormattedMessage id="gui.dialog.vision.profile.photo.add.title" />
                    : <FormattedMessage id="gui.dialog.vision.profile.photo.edit.title" />}
            </div>
            <div className={classNames(dialogStyles.snapshotView)}>
                <div className={classNames(visionSettingStyles.snapContent)}>
                    {(isVisionConnected && isVisionDistribute) ?
                        <div className={classNames(visionSettingStyles.snapshotAndUpdloadArea, visionSettingStyles.faceRegister)}>
                            <SnapshotButton
                                onClickSnapshot={onClickSnapshot}
                                textId={"gui.dialog.vision.button.snapshot"} />
                            <UploadButton
                                onClickUpload={onClickUpload} />
                        </div>
                        : <div className={classNames(visionSettingStyles.visionDisconnectedDialog)}>
                            <div className={classNames(visionSettingStyles.visionDisconnectedDialogText)}>
                                {getVisionInitStateString}
                            </div>
                        </div>
                    }
                </div>
                <div className={classNames(dialogStyles.questionFooter)}>
                    <div className={classNames(dialogStyles.questionBtn, dialogStyles.vision)} onClick={() => { onClickCancel() }}>
                        <FormattedMessage
                            id="gui.dialog.question.cancel"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const TakePhotoDialog = (props) => {
    const {
        show,
        isNew,
        isVisionDistribute,
        isVisionConnected,
        isVisionFreezed,
        imgStream,
        faceResult,
        onClickSnapshot,
        onClickRetry,
        onClickConfirm,
        onClickCancel,
        runCountdonw,
        countdownIcon,
        isAnalyzing,
        hasOnlyFaceFeature,
        hasMultiFaceFeature,
        takingPhoto,
        photoMode,
        getVisionInitStateString,
        imageSize,
        isTakingPhoto,
        onClickStop
    } = props;
    if (!show) return null;
    return <div className={classNames(dialogStyles.backdropStyle)}>
        <div className={classNames(dialogStyles.modalStyle, dialogStyles.faceRegister)} >
            <div className={classNames(dialogStyles.firmwareHeader)}>
                {(isNew) ? <FormattedMessage id="gui.dialog.vision.profile.photo.add.title" />
                    : <FormattedMessage id="gui.dialog.vision.profile.photo.edit.title" />}
            </div>
            <div className={classNames(dialogStyles.snapshotView)}>
                <div className={classNames(visionSettingStyles.snapContent)}>
                    {(isVisionDistribute && isVisionConnected && imgStream) ?
                        <div className={classNames(visionSettingStyles.snapImg)}>
                            <img src={imgStream} id="snapshotImg"
                                style={{
                                    height: imageSize.height,
                                    width: imageSize.width,
                                    marginTop: imageSize.marginTop,
                                    marginLeft: imageSize.marginLeft
                                }} />
                            {/* Display detected tag */}
                            {(isVisionFreezed && faceResult && !isAnalyzing && hasOnlyFaceFeature) ?
                                <DetectedSnapshotTag
                                    detectedTag={faceResult}
                                    photoMode={photoMode}
                                    imageSize={imageSize}
                                /> : null}
                            {/* Retry to take a photo */}
                            {(isVisionFreezed && !isAnalyzing) ? <div className={classNames(visionSettingStyles.resetIcon)} onClick={() => onClickRetry()}>
                                <img src={resetIcon} className={classNames(visionSettingStyles.resetImg)} />
                            </div> : null}
                            {/* Countdown animation */}
                            {(runCountdonw) ? <div className={classNames(visionSettingStyles.countdownIcon)}>
                                <img src={countdownIcon} />
                            </div> : null}
                            {(isAnalyzing) ? <div className={classNames(visionSettingStyles.loadingSubPosition)}>
                                <img src={loadingImg} className={classNames(visionSettingStyles.loading, visionSettingStyles.sub)} />
                            </div> : null}
                        </div>
                        : <div className={classNames(visionSettingStyles.visionDisconnectedDialog)}>
                            <div className={classNames(visionSettingStyles.visionDisconnectedDialogText)}>
                                {getVisionInitStateString}
                            </div>
                        </div>}
                </div>
                {/* Detected has face */}
                {(isVisionFreezed && !hasOnlyFaceFeature && !isAnalyzing) ? <div className={classNames(visionSettingStyles.snapshotNoResult)}>
                    {(hasMultiFaceFeature) ? <FormattedMessage id={(photoMode == VISION_IMAGE_MODE.REALTIME) ? "gui.dialog.vision.profile.photo.multi" : "gui.dialog.vision.profile.picture.multi"} />
                        : <FormattedMessage id={(photoMode == VISION_IMAGE_MODE.REALTIME) ? "gui.dialog.vision.profile.photo.none" : "gui.dialog.vision.profile.picture.none"} />}</div>
                    : null}
                {/* Change button by freeze state */}
                {(takingPhoto || !isVisionFreezed) ?
                    <div className={classNames(dialogStyles.questionFooter)}>
                        <div className={classNames(dialogStyles.questionWhiteBtn, dialogStyles.vision, isAnalyzing || runCountdonw ? visionSettingStyles.disableButton : null)} onClick={() => onClickCancel()}>
                            <FormattedMessage id="gui.dialog.question.cancel" />
                        </div>
                        <div className={classNames(dialogStyles.questionInterval)} />
                        {runCountdonw ?
                            <div className={classNames(dialogStyles.questionBtn, dialogStyles.vision, (!isVisionDistribute || !isVisionConnected || isAnalyzing) ? visionSettingStyles.disableButton : null)} onClick={() => onClickStop()}>
                                <FormattedMessage id="gui.vr.motor.stop" />
                            </div>
                            : <div className={classNames(dialogStyles.questionBtn, dialogStyles.vision, (!isVisionDistribute || !isVisionConnected || isTakingPhoto) ? visionSettingStyles.disableButton : null)} onClick={() => onClickSnapshot()}>
                                <FormattedMessage id="gui.dialog.vision.button.take.photo" />
                            </div>
                        }

                    </div>
                    :
                    <div className={classNames(dialogStyles.questionFooter)}>
                        <div className={classNames(dialogStyles.questionWhiteBtn, dialogStyles.vision, (isAnalyzing || isTakingPhoto) ? visionSettingStyles.disableButton : null)} onClick={() => (isAnalyzing) ? null : onClickCancel()}>
                            <FormattedMessage id="gui.dialog.question.cancel" />
                        </div>
                        <div className={classNames(dialogStyles.questionInterval)} />
                        <div className={classNames(dialogStyles.questionBtn, dialogStyles.vision, (isAnalyzing || !hasOnlyFaceFeature || !isVisionDistribute || !isVisionConnected || isTakingPhoto) ? visionSettingStyles.disableButton : null)} onClick={() => onClickConfirm()}>
                            <FormattedMessage id="gui.hint.deleteBlocks.confirm" />
                        </div>
                    </div>
                }

            </div>
        </div>
    </div>
}

export const SnapshotButton = (props) => {
    const {
        onClickSnapshot,
        textId
    } = props;
    return <VisionButton
        onClickFunction={onClickSnapshot}
        icon={snapshotIcon}
        textId={textId}
    />
}


export const UploadButton = (props) => {
    const {
        onClickUpload,
    } = props;
    return <VisionButton
        onClickFunction={onClickUpload}
        icon={uploadIcon}
        textId="gui.dialog.vision.button.upload"
    />
}

const VisionButton = (props) => {
    const {
        onClickFunction,
        icon,
        textId
    } = props;
    return <div className={classNames(visionSettingStyles.visionBtn)} onClick={onClickFunction} >
        <img src={icon} className={classNames(visionSettingStyles.visionBtnImage)} />
        <div className={classNames(visionSettingStyles.visionBtnText)}>
            <FormattedMessage
                id={textId}
            />
        </div>
    </div>
}



