function validFilename(fname) {
    let rg1=/^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
    let rg2=/^\./; // cannot start with dot (.)
    let rg3=/^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
    let rg4=/^\s*$/;
    return rg1.test(fname)&&!rg2.test(fname)&&!rg3.test(fname)&&!rg4.test(fname);
}

export {
    validFilename
}