import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import styles from './port.css';
import checkIcon from '../pictures/check.svg';
import checkWWIcon from '../pictures/ww/check.svg';
import { uiType, getUIStyle } from '../../../reducers/ui-style';

import {
    openEditPage,
} from '../../../reducers/device-manager-controller';

import {
    isOptionSelected,
    getSelectedOption,
    hoverConnectedDevice,
    cleanConnectedDeviceHovered,
    getHoveredConnectedDevice,
    isConnectedDeviceHovered
} from '../../../reducers/select-option'

import {
    isDeviceSelected,
    selectDeviceToDelete,
    getSelectedDeviceIdArray,

    isDeleteDeviceMode,
    closeDeleteMode
} from '../../../reducers/delete-device';

const MODE = {
    OPTION: "option",
    DELETE: "delete"
}

class ConnectedPort extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            hover: false
        }
    }

    handleClickPortToEdit(port, name, type, id) {
        this.props.openEditPage(port, name, type, id);
        this.handleLeaveDevice()
    }

    handleHoverDevice(id) {
        let deviceInfo = this.props.vm.getDeviceById(id)
        if (deviceInfo && deviceInfo.connectPortArray) {
            this.props.hoverConnectedDevice(deviceInfo.connectPortArray);
        }
        this.setState({ hover: true });
    }

    handleLeaveDevice() {
        this.props.cleanConnectedDeviceHovered()
        this.setState({ hover: false });
    }

    isConnectedDeviceHovered() {
        let isConnectedDeviceHovered = this.props.isConnectedDeviceHovered;
        if (isConnectedDeviceHovered) {
            let hoveredArray = this.props.getHoveredConnectedDevice;
            if (hoveredArray && hoveredArray.length > 0) {
                isConnectedDeviceHovered = hoveredArray.includes(this.props.port);
            }
        }
        return isConnectedDeviceHovered;
    }

    handleClickPortToDelete(connectedDeviceList) {
        if (!connectedDeviceList) return;
        let selectedArray = this.props.getSelectedDeviceIdArray;
        let temp = [];
        let result = [];
        let includePort = selectedArray.includes(this.props.port);
        connectedDeviceList.forEach(device => {
            if (!temp.includes(device.port) && device.id === this.props.id) {
                temp.push(device.port);
            }
        })
        if (!includePort) {
            result = temp.concat(selectedArray);
        } else {
            selectedArray.forEach(port => {
                if (!temp.includes(port)) {
                    result.push(port)
                }
            })
        }
        result.sort();
        this.props.seletDevice(result);
    }

    isPortSelected(mode) {
        let isPortSelected;
        let selectedArray = [];
        if (mode == MODE.OPTION) {
            isPortSelected = this.props.isOptionSelected;
            selectedArray = this.props.getSelectedOption;
        }
        if (mode == MODE.DELETE) {
            isPortSelected = this.props.isDeviceSelected;
            selectedArray = this.props.getSelectedDeviceIdArray;
        }
        if (isPortSelected) {
            if (selectedArray && selectedArray.length > 0) {
                isPortSelected = selectedArray.includes(this.props.port);
            }
        }
        return isPortSelected;
    }

    render() {
        const {
            port,
            name,
            type,
            id,
            connectedDeviceList,
            isBrainLeft
        } = this.props;
        return (
            (this.props.isDeleteDeviceMode) ?
                <div onClick={() => this.handleClickPortToDelete(connectedDeviceList, id)}>
                    <div className={classNames(styles.deletePortBackground)} />
                    <div className={classNames(styles.connectedPort, (!isBrainLeft) ? styles.reverse : null)}>
                        {port}
                    </div>
                    <div className={classNames(styles.text, (!isBrainLeft) ? styles.textRight : null, (!isBrainLeft) ? styles.reverse : null)}>
                        {name}
                    </div>
                    <div className={classNames(((this.isPortSelected(MODE.DELETE)) ? styles.checkedPort : styles.checkedBox), (!isBrainLeft) ? styles.reverse : null)}>
                        {(this.isPortSelected(MODE.DELETE)) ? <img className={classNames(styles.checkedImgSize)} src={this.props.getUIStyle == uiType.ww ? checkWWIcon : checkIcon} /> : null}
                    </div>
                </div>
                : (this.isPortSelected(MODE.OPTION)) ?
                    <div className={classNames(styles.portOptionSelected, (!isBrainLeft) ? styles.reverse : null)}>
                        {port}
                    </div>
                    : <div onClick={() => this.handleClickPortToEdit(port, name, type, id)}
                        onMouseEnter={() => this.handleHoverDevice(id)}
                        onMouseLeave={() => this.handleLeaveDevice()}>
                        <div className={classNames(styles.connectedPortBackground,
                            (this.isConnectedDeviceHovered()) ? styles.connectedPortBackgroundHover : null,
                            (this.isConnectedDeviceHovered()) ? styles.breathPort : null)} />
                        <div className={classNames(styles.connectedPort,
                            (!isBrainLeft) ? styles.reverse : null,
                            (this.isConnectedDeviceHovered()) ? styles.connectedPortHover : null)}
                        >
                            {port}
                        </div>
                        <div className={classNames(styles.text,
                            (!isBrainLeft) ? styles.textRight : null, styles.textWidth,
                            (!isBrainLeft) ? styles.reverse : null,
                            (this.isConnectedDeviceHovered()) ? styles.textHover : null)}>
                            {name}
                        </div>
                    </div>
        );
    }
}

ConnectedPort.propTypes = {
    port: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    getSelectedDeviceIdArray: PropTypes.array,
    connectedDeviceList: PropTypes.array,
    isBrainLeft: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    getSelectedOption: getSelectedOption(state),
    isOptionSelected: isOptionSelected(state),
    isDeviceSelected: isDeviceSelected(state),
    isDeleteDeviceMode: isDeleteDeviceMode(state),
    getSelectedDeviceIdArray: getSelectedDeviceIdArray(state),
    getHoveredConnectedDevice: getHoveredConnectedDevice(state),
    isConnectedDeviceHovered: isConnectedDeviceHovered(state),
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
    openEditPage: (port, name, type, id) => {
        dispatch(openEditPage(port, name, type, id))
        dispatch(closeDeleteMode())
    },
    seletDevice: (array) => dispatch(selectDeviceToDelete(array)),
    hoverConnectedDevice: (array) => dispatch(hoverConnectedDevice(array)),
    cleanConnectedDeviceHovered: () => dispatch(cleanConnectedDeviceHovered())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedPort);


