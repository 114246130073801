import bindAll from 'lodash.bindall';
import PropTypes, { number } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import styles from './edit-page.css';
import VM from 'scratch-vm';
import store from 'store';
import { DEFAULT_LOCALE } from '../../../config/project-config';
import {
    closeDeviceList,
    isEditPageOpen,
    getPortName,
    getPortDevice,
    getPortNumber,
    getPortDeviceId,
    closeAddPage,
    closeEditPage,
    isAddPageOpen,
    setPortArray
} from '../../../reducers/device-manager-controller';
import { EditUtils, STRING_ID, STATE_INDEX, PATH_TYPE, SUPPORT_TYPES, ROTATION, OPTION_TYPE } from './edit-utils.js';
import { Motor, EduDrivertrainAdvance } from './edit-page-device-component.jsx';
import {
    selectPortOption,
    cleanPortOption,
} from '../../../reducers/select-option';
import editImg from '../pictures/device_edit.svg';
import adcSmallWhiteImg from '../pictures/port_edu_adc.svg'
import pwmSmallWhiteImg from '../pictures/port_edu_pwm.svg'
import adcGreenImg from '../pictures/port_edu_adc_green.svg'
import pwmGreenImg from '../pictures/port_edu_pwm_green.svg'
import adcWWImg from '../pictures/port_edu_adc_ww.svg'
import pwmWWImg from '../pictures/port_edu_pwm_ww.svg'
import switchImg from '../pictures/switch_btn.svg';
import drivetrainImg from '../pictures/device_drivetrain_icon.svg';
import pathFrontImg from '../pictures/path-up.svg';
import pathBackImg from '../pictures/path-down.svg';
import { LOW_RES_WIDTH } from '../../../lib/screen-utils.js';
import { setProjectChanged } from '../../../reducers/project-changed';
import { getUIStyle, uiType } from '../../../reducers/ui-style';
import { SYMBOLS_NOT_ALLOWED } from './edit-utils.js';
import exclamationIcon from '../pictures/exclamation_mark.svg';
import crossIcon from '../pictures/cross_mark.svg';

const wheel = {
    left: "left",
    Right: "right",
    defaultSize: "200"
}

const NAME_AVAILABLE = "NAME_AVAILABLE";

const FIX_METHOD = {
    none: 'none',
    left: 'left',
    right: 'right'
}

const initialState = {
    name: null,
    isNameEdited: false,
    adc1: null,
    adc2: null,
    adc3: null,
    pwm1: null,
    pwm2: null,
    isEditName: false,
    path: null,
    leftSize: null,
    rightSize: null,
    rotation: null,
    forwardName: null,
    isEditForwardName: false,
    reverseName: null,
    isEditReverseName: false,
    tempAdc1: null,
    tempAdc2: null,
    tempAdc3: null,
    tempPwm1: null,
    tempPwm2: null,
    tempName: null,
    tempForwardName: null,
    tempReverseName: null,
    expandSelect: null,
    expandPercentage: null,
    advanceSetting: false,
    fixMethod: FIX_METHOD.none,
    fixLeftPercentage: 1,
    fixRightPercentage: 1,
    hoverPortOption: -1
}

class EduEditPage extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = initialState;
    }

    isWWVersion() {
        return this.props.getUIStyle == uiType.ww
    }

    cleanState() {
        this.setState(initialState)
    }

    closeSelect() {
        if (this.state.expandSelect) {
            this.setState({ expandSelect: null })
        }
    }

    handleClickSave() {
        let portArray = this.parsePortArray();
        let device = this.props.deviceType;
        let name = this.isNameValid(this.state.tempName, STATE_INDEX.name) ? this.state.tempName : this.state.name;
        name = name.replace(/^\s*/, "");
        let forwardName = this.state.tempForwardName && this.isNameValid(this.state.tempForwardName, STATE_INDEX.tempForwardName) ? this.state.tempForwardName : this.state.forwardName;
        let reverseName = this.state.tempReverseName && this.isNameValid(this.state.tempReverseName, STATE_INDEX.tempReverseName) ? this.state.tempReverseName : this.state.reverseName;
        let isNameEdited = this.isDeviceNameEdited(name) || this.state.isNameEdited;
        if (!(this.isNameValid(name, STATE_INDEX.name) && this.checkSavePort())) return;
        if (this.props.isAddPageOpen) {
            if (device == SUPPORT_TYPES.threeWireMotor) {
                this.props.vm.addThreeWireMotor(name, portArray, this.state.rotation, forwardName, reverseName, isNameEdited);
            } else if (device == SUPPORT_TYPES.drivetrain) {
                this.props.vm.addEduDrivetrain(name, portArray, this.state.path, this.state.leftSize, this.state.rightSize, isNameEdited, this.state.fixMethod, this.state.fixLeftPercentage, this.state.fixRightPercentage);
            } else {
                this.props.vm.addCommonDevice(device, name, portArray, isNameEdited);
            }
        } else if (this.props.isEditPageOpen) {
            if (device == SUPPORT_TYPES.threeWireMotor) {
                this.props.vm.editThreeWireMotor(this.props.deviceId, name, portArray, this.state.rotation, forwardName, reverseName, isNameEdited);
            } else if (device == SUPPORT_TYPES.drivetrain) {
                this.props.vm.editEduDrivetrain(this.props.deviceId, name, portArray, this.state.path, this.state.leftSize, this.state.rightSize, isNameEdited, this.state.fixMethod, this.state.fixLeftPercentage, this.state.fixRightPercentage);
            } else {
                this.props.vm.editCommonDevice(this.props.deviceId, name, portArray, isNameEdited);
            }
        }
        this.props.setPortArray(portArray);
        // project changed
        this.props.onProjectChanged();
        this.props.closeAddPage();
        this.cleanState();
    }

    checkSavePort() {
        const request = this.getDeviceRequest();
        let selectPort = this.parsePortArray();
        return (request && (selectPort.length == (request.adc + request.pwm)))
    }

    handleClickCancel() {
        this.cleanState();
        this.props.cleanPortOption;
        this.props.closeAddPage();
    }

    handleClickDrivetrainSwitch() {
        if (!this.state.pwm1 || !this.state.pwm2) return;
        let temp = this.state.pwm1;
        this.setState({ pwm1: this.state.pwm2, pwm2: temp });
        setTimeout(() => {
            this.updateDefaultPort();
        }, 50)
    }

    handleClickMotorSwitch() {
        this.handleSaveName();
        this.setState({
            rotation: this.state.rotation == ROTATION.forward ? ROTATION.reverse : ROTATION.forward
        })
    }

    handleClickDelete() {
        // project changed
        this.props.vm.removeDeviceById(this.props.deviceId)
        this.handleClickCancel();
    }

    handleClickPath(path) {
        if (path && this.state.path != path) {
            this.setState({ path: path })
        }
    }

    handleClickEdit(isEditIndex) {
        this.handleSaveName();
        this.setState({
            [isEditIndex]: true
        })
    }

    handleClickRotationRadio(rotation) {
        this.handleSaveName();
        this.setState({
            rotation: rotation
        })
    }

    handleClickAdvanced() {
        this.setState({ advanceSetting: true, expandPercentage: null })
    }

    handleClickBack() {
        this.setState({ advanceSetting: false, expandPercentage: null })
    }

    handleCheckMethod(method) {
        this.setState({ fixMethod: method, expandPercentage: null })
    }

    handleExpandOption(method) {
        this.setState({ expandPercentage: (this.state.expandPercentage == method) ? null : method })
    }

    handleSelectPercentage(method, percentage) {
        if (method == FIX_METHOD.left) {
            this.setState({ fixLeftPercentage: percentage, expandPercentage: null })
        }
        if (method == FIX_METHOD.right) {
            this.setState({ fixRightPercentage: percentage, expandPercentage: null })
        }
    }

    handleSaveName() {
        this.saveName(this.state.tempName, STATE_INDEX.name, STATE_INDEX.tempName, STATE_INDEX.isEditName);
        this.saveName(this.state.tempForwardName, STATE_INDEX.forwardName, STATE_INDEX.tempForwardName, STATE_INDEX.isEditForwardName);
        this.saveName(this.state.tempReverseName, STATE_INDEX.reverseName, STATE_INDEX.tempReverseName, STATE_INDEX.isEditReverseName);
        this.closeSelect()
    }

    saveName(name, nameIndex, tempIndex, isEditIndex) {
        if (!this.state[isEditIndex]) return;
        name = name.replace(/^\s*/, "");
        let validName = this.isNameValid(name, nameIndex);
        if (nameIndex == STATE_INDEX.forwardName || STATE_INDEX.reverseName) {
            validName = this.isNameValid(name, tempIndex) && !EditUtils.isNameContainSp(name);;
        }

        if (nameIndex == STATE_INDEX.name) {
            let list = this.props.vm.getDeviceList();
            let duplicate = list.filter(device => ((this.props.deviceId && this.props.deviceId != device.id && name == device.name) || (!this.props.deviceId && name == device.name)));
            validName = (validName && duplicate.length == 0)
            if (validName) this.isDeviceNameEdited(name);
        }

        this.setState({
            [isEditIndex]: false,
            [nameIndex]: (validName) ? name : this.state[nameIndex],
            [tempIndex]: (validName) ? name : this.state[nameIndex]
        });
    }

    isEnVersion() {
        return store.get("locale", DEFAULT_LOCALE) == "en";
    }

    isNameValid(name, nameIndex) {
        if (name && name.length > 20) return false;
        if (nameIndex && nameIndex == STATE_INDEX.name) {
            return this.props.vm.checkDeviceNameAvailable(name, this.props.deviceId) === NAME_AVAILABLE;
        } else {
            return EditUtils.isNameValid(name) && !EditUtils.isRotationNameDuplicate(name, nameIndex, this.state);
        }
    }

    isDeviceNameEdited(name) {
        let isNameEdited = false
        if (!this.state.adc1 && !this.state.adc2 && !this.state.adc3 && !this.state.pwm1 && !this.state.pwm2) {
            isNameEdited = name != EditUtils.getDeviceDefaultName(this.props.deviceType);
        } else {
            isNameEdited = name != this.getDefaultName();
        }
        this.setState({
            isNameEdited: isNameEdited
        })
        return isNameEdited;
    }

    onNameChange(event, index) {
        this.setState({
            [index]: event.target.value
        })
    }

    setDefaultName() {
        if (this.state.isNameEdited) return;
        this.setState({
            name: this.getDefaultName(),
            tempName: this.getDefaultName()
        })
    }

    getDefaultName() {
        let deviceType = this.props.deviceType;
        switch (deviceType) {
            case SUPPORT_TYPES.drivetrain:
                return EditUtils.getDeviceDefaultName(this.props.deviceType);
            default:
                return EditUtils.getDeviceDefaultName(this.props.deviceType)
                    + ((this.state.pwm1) ? this.state.pwm1 : "")
                    + ((this.state.pwm2) ? this.state.pwm2 : "")
                    + ((this.state.adc1) ? this.state.adc1 : "")
                    + ((this.state.adc2) ? this.state.adc2 : "")
                    + ((this.state.adc3) ? this.state.adc3 : "")
        }
    }

    parsePortArray() {
        let array = [];
        if (this.state.adc1) array.push(this.state.adc1);
        if (this.state.pwm1) array.push(this.state.pwm1);
        if (this.state.adc2) array.push(this.state.adc2);
        if (this.state.pwm2) array.push(this.state.pwm2);
        if (this.state.adc3) array.push(this.state.adc3);
        return array;
    }

    createAvailableList(request, availableAdc, availablePwm) {
        let result = [];
        let availableAdcList = this.createSelectOption(request, 1, availableAdc, 'adc', this.state.adc1);
        let secondAvailableAdcList = this.createSelectOption(request, 2, availableAdc, 'adc', this.state.adc2);
        let thirdAvailableAdcList = this.createSelectOption(request, 3, availableAdc, 'adc', this.state.adc3);
        let availablePwmList = this.createSelectOption(request, 1, availablePwm, 'pwm', this.state.pwm1);
        let secondAvailablePwmList = this.createSelectOption(request, 2, availablePwm, 'pwm', this.state.pwm2);
        if (availablePwmList && availablePwmList.length > 0) result.push({ type: 'pwm1', list: EditUtils.isOptionExist(availablePwmList) ? availablePwmList : null })
        if (secondAvailablePwmList && secondAvailablePwmList.length > 0) result.push({ type: 'pwm2', list: EditUtils.isOptionExist(secondAvailablePwmList) ? secondAvailablePwmList : null })
        if (availableAdcList && availableAdcList.length > 0) result.push({ type: 'adc1', list: EditUtils.isOptionExist(availableAdcList) ? availableAdcList : null })
        if (secondAvailableAdcList && secondAvailableAdcList.length > 0) result.push({ type: 'adc2', list: EditUtils.isOptionExist(secondAvailableAdcList) ? secondAvailableAdcList : null })
        if (thirdAvailableAdcList && thirdAvailableAdcList.length > 0) result.push({ type: 'adc3', list: EditUtils.isOptionExist(thirdAvailableAdcList) ? thirdAvailableAdcList : null })
        return result;
    }

    handleClickSelect(selectType) {
        this.handleSaveName();
        this.setState({ expandSelect: (this.state.expandSelect) ? null : selectType })
    }

    handleClickOption(option, port) {
        this.handleSaveName();
        this.closeSelect();
        this.setState({
            [port]: option
        })
        this.handleMouseHover(-1);
        if (this.props.isAddPageOpen) {
            setTimeout(() => {
                this.updateDefaultPort();
            }, 50)
        }
    }


    handleMouseHover(index) {
        this.setState({ hoverPortOption: index });
    }


    createSelectOption(request, number, optionList, type, selectedStatus) {
        if (!EditUtils.checkRequest(request, number, type)) return null
        optionList.sort();
        const adcImg = this.props.getUIStyle == uiType.ww ? adcWWImg : adcGreenImg;
        const pwmImg = this.props.getUIStyle == uiType.ww ? pwmWWImg : pwmGreenImg;
        return optionList.map((location, index) => {
            let isSelected = EditUtils.isOptionSelected(location, type, this.state) || (location == selectedStatus)
            return <div key={index}>
                <div className={classNames(
                    ((isSelected) ? styles.portOptionNoneHover : styles.portOption),
                    (isSelected) ? styles.optionDisable : null)}
                    onClick={() => { isSelected ? {} : this.handleClickOption(location, type + number) }}
                    onMouseEnter={() => isSelected ? {} : this.handleMouseHover(index)}
                    onMouseOut={() => isSelected ? {} : this.handleMouseHover(-1)}>
                    <div className={classNames(styles.portOptionImg, (isSelected) ? styles.optionDisable : null)}>
                        <img src={(this.state.hoverPortOption == index) ?
                            (type == "adc") ? adcSmallWhiteImg : pwmSmallWhiteImg
                            : (type == "adc") ? adcImg : pwmImg}
                            className={classNames(styles.portOptionImgPosition)} />
                    </div>
                    {location}
                </div>
            </div >
        });
    }

    getSelectImage(type) {
        if (type.includes('adc') && this.state[type]) return (<img className={classNames(this.props.deviceType == SUPPORT_TYPES.drivetrain ?
            styles.eduDrivertrainAdcPwmImgPosition : styles.selectAdcPwmImgPosition, styles.selectAdcPwmImgSize)}
            src={this.isWWVersion() ? adcWWImg : adcGreenImg} />)
        if (type.includes('pwm') && this.state[type]) return (<img className={classNames(this.props.deviceType == SUPPORT_TYPES.drivetrain ?
            styles.eduDrivertrainAdcPwmImgPosition : styles.selectAdcPwmImgPosition, styles.selectAdcPwmImgSize)}
            src={this.isWWVersion() ? pwmWWImg : pwmGreenImg} />)
        return null
    }

    getDeviceInfo() {
        let deviceInfo = this.props.vm.getDeviceById(this.props.deviceId)
        let port = {
            adc: ['B1', 'B2', 'B3', 'B4'],
            pwm: ['A4', 'A3', 'A2', 'A1']
        }
        let tempDevice = {
            adc: [],
            pwm: [],
            isNameEdited: deviceInfo && deviceInfo.isNameEdited ? deviceInfo.isNameEdited : false,
            other: deviceInfo && deviceInfo.other ? deviceInfo.other : null
        }
        if (deviceInfo && deviceInfo.connectPortArray) {
            for (let i = 0; i < deviceInfo.connectPortArray.length; i++) {
                if (port.adc.includes(deviceInfo.connectPortArray[i])) {
                    tempDevice.adc.push(deviceInfo.connectPortArray[i])
                    continue;
                }
                if (port.pwm.includes(deviceInfo.connectPortArray[i])) {
                    tempDevice.pwm.push(deviceInfo.connectPortArray[i])
                    continue;
                }
            }
        }
        return tempDevice;
    }

    getDefaultPort() {
        let tempPort = {
            adc: [],
            pwm: []
        }
        if (!this.props.vm.getAvailablePortAmount(this.props.brainType)) return tempPort;
        var availableAdc = (this.props.vm.getAvailablePortAmount(this.props.brainType).adc instanceof Array) ? this.props.vm.getAvailablePortAmount(this.props.brainType).adc.sort() : [];
        var availablePwm = (this.props.vm.getAvailablePortAmount(this.props.brainType).pwm instanceof Array) ? this.props.vm.getAvailablePortAmount(this.props.brainType).pwm.sort() : [];
        const request = this.getDeviceRequest();
        if (request && request.adc && request.adc > 0 && availableAdc.length >= request.adc) {
            for (let i = 0; i < request.adc; i++) {
                if (availableAdc[i] != this.state.adc1 && availableAdc[i] != this.state.adc2 && availableAdc[i] != this.state.adc3)
                    tempPort.adc.push(availableAdc[i]);
            }
        }
        if (request && request.pwm && request.pwm > 0 && availablePwm.length >= request.pwm) {
            for (let i = 0; i < request.pwm; i++) {
                if (availablePwm[i] != this.state.pwm1 && availablePwm[i] != this.state.pwm2)
                    tempPort.pwm.push(availablePwm[i]);
            }
        }
        return tempPort;
    }

    initState() {
        this.updateDefaultPort();
        this.setState({
            adc1: null,
            adc2: null,
            adc3: null,
            pwm1: null,
            pwm2: null,
            isEditName: false,
            name: EditUtils.getDeviceDefaultName(this.props.deviceType),
            tempName: EditUtils.getDeviceDefaultName(this.props.deviceType),
            advanceSetting: false
        })
        if (this.props.deviceType == SUPPORT_TYPES.drivetrain) this.setState({ path: PATH_TYPE.front, leftSize: wheel.defaultSize, rightSize: wheel.defaultSize });
        if (this.props.deviceType == SUPPORT_TYPES.threeWireMotor) {
            this.setState({
                rotation: ROTATION.forward,
                forwardName: EditUtils.getLocaleString(STRING_ID.motorForward),
                reverseName: EditUtils.getLocaleString(STRING_ID.motorReverse),
                tempForwardName: EditUtils.getLocaleString(STRING_ID.motorForward),
                tempReverseName: EditUtils.getLocaleString(STRING_ID.motorReverse),
                expandPercentage: null,
                fixMethod: FIX_METHOD.none,
                fixLeftPercentage: 1,
                fixRightPercentage: 1
            });
        }
    }

    updateDefaultPort() {
        let defaultPort = this.getDefaultPort()
        this.setState({
            tempAdc1: (!this.state.adc1 && defaultPort.adc && defaultPort.adc.length > 0) ? defaultPort.adc.shift() : null,
            tempAdc2: (!this.state.adc2 && defaultPort.adc && defaultPort.adc.length > 0) ? defaultPort.adc.shift() : null,
            tempAdc3: (!this.state.adc3 && defaultPort.adc && defaultPort.adc.length > 0) ? defaultPort.adc.shift() : null,
            tempPwm1: (!this.state.pwm1 && defaultPort.pwm && defaultPort.pwm.length > 0) ? defaultPort.pwm.shift() : null,
            tempPwm2: (!this.state.pwm2 && defaultPort.pwm && defaultPort.pwm.length > 0) ? defaultPort.pwm.shift() : null
        })
    }

    loadState() {
        let deviceInfo = this.getDeviceInfo()
        this.setState({
            name: this.props.portName,
            isEditName: deviceInfo.isEditName,
            isNameEdited: (deviceInfo.isNameEdited != null && deviceInfo.isNameEdited != 'undefined')
                ? (this.props.portName.includes(EditUtils.getDeviceDefaultName(this.props.deviceType)))
                    ? deviceInfo.isNameEdited : true : false,
            adc1: (deviceInfo.adc && deviceInfo.adc.length > 0) ? deviceInfo.adc.shift() : null,
            adc2: (deviceInfo.adc && deviceInfo.adc.length > 0) ? deviceInfo.adc.shift() : null,
            adc3: (deviceInfo.adc && deviceInfo.adc.length > 0) ? deviceInfo.adc.shift() : null,
            pwm1: (deviceInfo.pwm && deviceInfo.pwm.length > 0) ? deviceInfo.pwm.shift() : null,
            pwm2: (deviceInfo.pwm && deviceInfo.pwm.length > 0) ? deviceInfo.pwm.shift() : null,
            path: (deviceInfo.other && deviceInfo.other.path) ? deviceInfo.other.path : PATH_TYPE.front,
            leftSize: (deviceInfo.other && deviceInfo.other.leftSize) ? deviceInfo.other.leftSize : wheel.defaultSize,
            rightSize: (deviceInfo.other && deviceInfo.other.rightSize) ? deviceInfo.other.rightSize : wheel.defaultSize,
            rotation: (deviceInfo.other && deviceInfo.other.rotation) ? deviceInfo.other.rotation : ROTATION.forward,
            forwardName: (deviceInfo.other && deviceInfo.other.forwardName) ? deviceInfo.other.forwardName : EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.forward'),
            reverseName: (deviceInfo.other && deviceInfo.other.reverseName) ? deviceInfo.other.reverseName : EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.reverse'),
            expandSelect: null,
            tempName: this.props.portName,
            tempForwardName: (deviceInfo.other && deviceInfo.other.forwardName) ? deviceInfo.other.forwardName : EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.forward'),
            tempReverseName: (deviceInfo.other && deviceInfo.other.reverseName) ? deviceInfo.other.reverseName : EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.reverse'),
            advanceSetting: false,
            expandPercentage: null,
            fixMethod: (deviceInfo.other && deviceInfo.other.fixMethod) ? deviceInfo.other.fixMethod : FIX_METHOD.none,
            fixLeftPercentage: (deviceInfo.other && deviceInfo.other.leftPercentage) ? deviceInfo.other.leftPercentage : 1,
            fixRightPercentage: (deviceInfo.other && deviceInfo.other.rightPercentage) ? deviceInfo.other.rightPercentage : 1
        })
    }

    getEditMotorComponent(isEditIndex, tempNameIndex) {
        return (
            (!this.state[isEditIndex]) ?
                <table align="center">
                    <tbody>
                        <tr onClick={() => this.handleClickEdit(isEditIndex)} className={classNames(styles.pointer)}>
                            <td>
                                <div className={classNames(styles.motorName)}>
                                    {this.state[tempNameIndex]}
                                </div>
                            </td>
                            <td>
                                <img src={editImg} className={classNames(styles.editImg)} alt={"edit"} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                : <div className={classNames(styles.inputMotorNameArea, (!this.isNameValid(this.state[tempNameIndex], tempNameIndex)) ? styles.inputNameErrorBorder : null)}>
                    <input type="text"
                        name={tempNameIndex}
                        value={this.state[tempNameIndex]}
                        maxLength="20"
                        onChange={e => this.onNameChange(e, tempNameIndex)}
                        className={classNames(styles.inputMotorName)}
                    />
                </div>
        )
    }

    hintDeviceType() {
        if (!this.state.tempName
            || this.state.tempName.toUpperCase().includes(EditUtils.getDeviceDefaultName(this.props.deviceType, 'en').toUpperCase())
            || this.state.tempName.toUpperCase().includes(EditUtils.getDeviceDefaultName(this.props.deviceType, 'zh-cn').toUpperCase())
            || this.state.tempName.toUpperCase().includes(EditUtils.getDeviceDefaultName(this.props.deviceType, 'zh-tw').toUpperCase())) return null;
        return (<div className={classNames(styles.invalidNameArea, styles.hintDeviceTypeText)}>{EditUtils.getLocaleString(STRING_ID.deviceType) + "：" + EditUtils.getDeviceDefaultName(this.props.deviceType)}</div>)
    }

    hintFixMotor() {
        if (this.state.fixMethod == FIX_METHOD.none) return;
        return <div className={classNames(styles.hintFixMotor)}>
            {(this.state.fixMethod == FIX_METHOD.left) ?
                `${EditUtils.getLocaleString("gui.deviceManagerStage.edu.drivertrain.advanced.fix.left")}${this.state.fixLeftPercentage}％`
                : `${EditUtils.getLocaleString("gui.deviceManagerStage.edu.drivertrain.advanced.fix.right")}${this.state.fixRightPercentage}％`
            }
        </div>
    }

    componentDidMount() {
        this.setState({
            name: (this.props.deviceType) ? EditUtils.getDeviceDefaultName(this.props.deviceType) : this.props.portName,
            tempName: (this.props.deviceType) ? EditUtils.getDeviceDefaultName(this.props.deviceType) : this.props.portName,
        })
        if (this.props.isAddPageOpen) this.initState()
        if (this.props.isEditPageOpen) this.loadState()
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.isAddPageOpen) && (this.props.deviceType && (this.props.deviceType !== prevProps.deviceType))) this.initState()
        if ((this.props.isEditPageOpen)
            && ((this.props.portNumber && this.props.portNumber !== prevProps.portNumber) ||
                (this.props.deviceId && this.props.deviceId !== prevProps.deviceId))) this.loadState()
        if (this.state != prevState) {
            let selectedPortArray = this.parsePortArray();
            if (selectedPortArray && selectedPortArray.length > 0) {
                this.props.selectPortOption(selectedPortArray);
            }
        }
        if (this.state.adc1 != prevState.adc1 || this.state.adc2 != prevState.adc2 || this.state.adc3 != prevState.adc3
            || this.state.pwm1 != prevState.pwm1 || this.state.pwm2 != prevState.pwm2) {
            this.setDefaultName();
        }
    }

    getDeviceRequest() {
        const defaultRequest = { adc: 0, pwm: 0, smart: 0, typeC: 0 };
        return this.props.vm.getRequestPort(this.props.deviceType, this.props.brainType) || defaultRequest;
    }

    getInvalidNameMsg() {
        return (EditUtils.isNameContainSp(this.state.tempName)) ? SYMBOLS_NOT_ALLOWED : this.props.vm.checkDeviceNameAvailable(this.state.tempName, this.props.deviceId)
    }

    render() {
        if (!this.props.isAddPageOpen && !this.props.isEditPageOpen) return null;
        const { intl } = this.props;
        let device = this.props.deviceType;
        var availableAdc = this.props.vm.getAvailablePortAmount(this.props.brainType).adc;
        var availablePwm = this.props.vm.getAvailablePortAmount(this.props.brainType).pwm;
        var isNameAvailable = this.isNameValid(this.state.tempName, STATE_INDEX.name) && !EditUtils.isNameContainSp(this.state.tempName);
        var enableDone = true;
        var enableDelete = false;
        if (this.props.isEditPageOpen) {
            enableDelete = true;
            enableDone = isNameAvailable;
            let tempPort = this.getDeviceInfo()
            if (tempPort.adc && tempPort.adc.length > 0) { availableAdc = availableAdc.concat(tempPort.adc); availableAdc.sort(); }
            if (tempPort.pwm && tempPort.pwm.length > 0) { availablePwm = availablePwm.concat(tempPort.pwm); availablePwm.sort(); }
        }
        if (this.props.isAddPageOpen) {
            enableDone = isNameAvailable && this.checkSavePort();
            if (this.props.isAddPageOpen && (!this.props.vm.isDeviceAvailableToAdd(device, this.props.brainType)
                || !this.props.vm.isPortEnoughForDeviceToAdd(device, this.props.brainType))) return null;
        }
        if (this.props.deviceType == SUPPORT_TYPES.threeWireMotor) {
            enableDone = enableDone && this.isNameValid(this.state.tempForwardName, STATE_INDEX.tempForwardName) && this.isNameValid(this.state.tempReverseName, STATE_INDEX.tempReverseName)
        }
        const request = this.getDeviceRequest();
        var availableListArray = this.createAvailableList(request, availableAdc, availablePwm)
        const hintNameOverflow = (this.state.isEditName && this.state.tempName.length == 20)
        return (
            <div className={classNames(styles.deviceAddPage, styles.deviceAddPageDiff)} >
                {(this.state.advanceSetting) ?
                    // Advanced setting
                    <div>
                        <EduDrivertrainAdvance
                            handleClickBack={() => this.handleClickBack()}
                            state={this.state}
                            handleCheckMethod={(method) => this.handleCheckMethod(method)}
                            handleExpandOption={(method) => this.handleExpandOption(method)}
                            handleSelectPercentage={(method, percentage) => this.handleSelectPercentage(method, percentage)}
                        />
                    </div>
                    : <div>
                        {/* Basic setting */}
                        <div className={classNames(styles.otherArea)} onClick={() => this.handleSaveName()} />
                        <div className={classNames(styles.nameArea, styles.nameAreaDiff)}>
                            {(!this.state.isEditName)
                                ? <table align="center">
                                    <tbody>
                                        <tr onClick={() => this.handleClickEdit(STATE_INDEX.isEditName)} className={classNames(styles.pointer)}>
                                            <td>
                                                <div className={classNames(styles.name)}>
                                                    {this.state.tempName}
                                                </div>
                                            </td>
                                            <td>
                                                <img src={editImg} className={classNames(styles.editImg)} alt={"edit"} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                : <div className={classNames(styles.inputNameArea,
                                    (!isNameAvailable) ?
                                        styles.inputNameErrorBorder : (hintNameOverflow) ? styles.inputNameHintBorder : null)}>
                                    <input
                                        type="text"
                                        name="device_name"
                                        value={this.state.tempName}
                                        maxLength="20"
                                        onChange={e => this.onNameChange(e, STATE_INDEX.tempName)}
                                        className={classNames(styles.inputName)}
                                    />
                                </div>
                            }
                        </div>
                        {(isNameAvailable) ? (hintNameOverflow) ?
                            <div className={classNames(styles.invalidNameArea)}>
                                <table align="center">
                                    <tbody>
                                        <tr>
                                            <td >
                                                <img src={exclamationIcon} className={classNames(styles.hintNameImg)} />
                                            </td>
                                            <td className={classNames(styles.invalidNameText)}>
                                                {EditUtils.getLocaleString(STRING_ID.nameOverflow)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            : this.hintDeviceType() :
                            <div className={classNames(styles.invalidNameArea)}>
                                <table align="center">
                                    <tbody>
                                        <tr>
                                            <td >
                                                <img src={crossIcon} className={classNames(styles.invalidNameImg)} />
                                            </td>
                                            <td className={classNames(styles.invalidNameText)}>
                                                {EditUtils.getNameErrorString(this.getInvalidNameMsg())}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                        {this.state.isEditName ? <div className={classNames(styles.nameCount, (hintNameOverflow) ? styles.nameCountHint : null)}>{`${this.state.tempName.length}/20`}</div> : null}
                        <div className={classNames(styles.editArea)}>
                            <div className={classNames(styles.saveArea)} onClick={() => this.handleSaveName()} />
                            <div className={classNames(styles.text)}>
                                {EditUtils.getSelectMsg(request, this.props.deviceType)}
                            </div>
                            {/* select option area*/}
                            {((request.adc + request.pwm) == 1 && availableListArray.length == 1) ?
                                <div className={classNames(styles.oneSelectPosition, styles.onePortSelect, (!!availableListArray[0].list) ? null : styles.disableSelect)}
                                    onClick={() => { (!!availableListArray[0].list) ? this.handleClickSelect(OPTION_TYPE.port1) : null }}>
                                    <div className={classNames(styles.arrow, styles.onePortArrowPosition)} />
                                    {this.getSelectImage(availableListArray[0].type)}
                                    <div className={classNames(styles.portText, (!this.state.adc1 && !this.state.pwm1) ? styles.defaultPortColor : null)}>
                                        {this.state.adc1 ? this.state.adc1 : this.state.pwm1 ? this.state.pwm1 :
                                            this.state.tempAdc1 ? this.state.tempAdc1 : this.state.tempPwm1 ? this.state.tempPwm1 : null}
                                    </div>
                                    {this.state.expandSelect == OPTION_TYPE.port1 ?
                                        <div className={classNames(styles.portOptionArea)}>
                                            {availableListArray[0].list}
                                        </div> : null
                                    }
                                </div> : null
                            }
                            {
                                ((request.adc + request.pwm) == 2 && availableListArray.length == 2) ?
                                    (device == SUPPORT_TYPES.drivetrain) ?
                                        <div>
                                            <div className={classNames(styles.eduDrivertrainSelectPosition1, styles.eduDrivertrainSelect, (!!availableListArray[0].list) ? null : styles.disableSelect)}
                                                onClick={() => { (!!availableListArray[0].list) ? this.handleClickSelect(OPTION_TYPE.port1) : null }}>

                                                <div className={classNames(styles.arrow, styles.eduDrivertrainArrowPosition)} />
                                                {this.getSelectImage(availableListArray[0].type)}
                                                <div className={classNames(styles.portText, (!this.state.pwm1) ? styles.defaultPortColor : null)}>
                                                    {this.state.pwm1 ? this.state.pwm1 :
                                                        this.state.tempPwm1 ? this.state.tempPwm1 : null}
                                                </div>
                                                {this.state.expandSelect == OPTION_TYPE.port1 ?
                                                    <div className={classNames(styles.eduDrivertrainOptionArea)}>
                                                        {availableListArray[0].list}
                                                    </div>
                                                    : null}
                                            </div>
                                            <div className={classNames(styles.eduDrivertrainSelectPosition2, styles.eduDrivertrainSelect, (!!availableListArray[1].list) ? null : styles.disableSelect)}
                                                onClick={() => { (!!availableListArray[1].list) ? this.handleClickSelect(OPTION_TYPE.port2) : null }}>
                                                <div className={classNames(styles.arrow, styles.eduDrivertrainArrowPosition)} />
                                                {this.getSelectImage(availableListArray[1].type)}
                                                <div className={classNames(styles.portText, (!this.state.adc1 && !this.state.pwm2) ? styles.defaultPortColor : null)}>
                                                    {this.state.adc1 ? this.state.adc1 : this.state.pwm2 ? this.state.pwm2 :
                                                        this.state.tempAdc1 ? this.state.tempAdc1 : this.state.tempPwm2 ? this.state.tempPwm2 : null}
                                                </div>
                                                {this.state.expandSelect == OPTION_TYPE.port2 ?
                                                    <div className={classNames(styles.eduDrivertrainOptionArea)}>
                                                        {availableListArray[1].list}
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                        : <div>
                                            <div className={classNames(styles.twoSelectPosition1, styles.twoPortSelect, (!!availableListArray[0].list) ? null : styles.disableSelect)}
                                                onClick={() => { (!!availableListArray[0].list) ? this.handleClickSelect(OPTION_TYPE.port1) : null }}>

                                                <div className={classNames(styles.arrow, styles.twoPortArrowPosition)} />
                                                {this.getSelectImage(availableListArray[0].type)}
                                                <div className={classNames(styles.portText, (!this.state.pwm1) ? styles.defaultPortColor : null)}>
                                                    {this.state.pwm1 ? this.state.pwm1 :
                                                        this.state.tempPwm1 ? this.state.tempPwm1 : null}
                                                </div>
                                                {this.state.expandSelect == OPTION_TYPE.port1 ?
                                                    <div className={classNames(styles.twoPortOptionArea)}>
                                                        {availableListArray[0].list}
                                                    </div>
                                                    : null}
                                            </div>
                                            <div className={classNames(styles.twoSelectPlusPosition1)}>+</div>
                                            <div className={classNames(styles.twoSelectPosition2, styles.twoPortSelect, (!!availableListArray[1].list) ? null : styles.disableSelect)}
                                                onClick={() => { (!!availableListArray[1].list) ? this.handleClickSelect(OPTION_TYPE.port2) : null }}>
                                                <div className={classNames(styles.arrow, styles.twoPortArrowPosition)} />
                                                {this.getSelectImage(availableListArray[1].type)}
                                                <div className={classNames(styles.portText, (!this.state.adc1 && !this.state.pwm2) ? styles.defaultPortColor : null)}>
                                                    {this.state.adc1 ? this.state.adc1 : this.state.pwm2 ? this.state.pwm2 :
                                                        this.state.tempAdc1 ? this.state.tempAdc1 : this.state.tempPwm2 ? this.state.tempPwm2 : null}
                                                </div>
                                                {this.state.expandSelect == OPTION_TYPE.port2 ?
                                                    <div className={classNames(styles.twoPortOptionArea)}>
                                                        {availableListArray[1].list}
                                                    </div>
                                                    : null}
                                            </div>
                                        </div> : null
                            }
                            {/* motor*/}
                            {(device == SUPPORT_TYPES.threeWireMotor) ?
                                <Motor
                                    handleClickMotorSwitch={() => this.handleClickMotorSwitch()}
                                    getEditMotorComponent={(isEditName, tempName) => this.getEditMotorComponent(isEditName, tempName)}
                                    rotation={this.state.rotation}
                                    isWWVersion={this.isWWVersion()}
                                /> : null}
                            {/* drivetrain*/}
                            {
                                (device == SUPPORT_TYPES.drivetrain) ? <div>
                                    <div className={classNames(styles.eduDrivertrainSelectSwitchPosition, styles.twoPortSwitchPositionDiff,
                                        (!this.state.pwm1 || !this.state.pwm2) ? styles.disableSwitch : null)}
                                        onClick={() => ((this.state.pwm1 || this.state.pwm2)) ? this.handleClickDrivetrainSwitch() : null}>
                                        <img src={switchImg} alt={"switch"} />
                                    </div>
                                    <div className={classNames(styles.eduDrivetrainArea)}>
                                        <div className={classNames(styles.drivetrainLeft)}>{intl.formatMessage({ id: "gui.device.motor" }) + ((this.state.pwm1) ? (" " + this.state.pwm1) : "")}</div>
                                        <div className={classNames(styles.drivetrainRight)}>{intl.formatMessage({ id: "gui.device.motor" }) + ((this.state.pwm2) ? (" " + this.state.pwm2) : "")}</div>
                                        <div className={classNames((this.state.path == PATH_TYPE.front) ? styles.pathUp : styles.pathUpDisable)}
                                            onClick={() => this.handleClickPath(PATH_TYPE.front)}>
                                            <img src={pathFrontImg} alt={"path"} />
                                        </div>
                                        <div className={classNames(styles.drivetrainPosition)}>
                                            <img src={drivetrainImg} className={classNames(styles.drivetrainImg)} alt={"drivetrain"} />
                                        </div>
                                        <div className={classNames((this.state.path == PATH_TYPE.back) ? styles.pathDown : styles.pathDownDisable)}
                                            onClick={() => this.handleClickPath(PATH_TYPE.back)}>
                                            <img src={pathBackImg} alt={"path"} />
                                        </div>
                                        <div className={classNames(styles.drivetrainLeftText)}>L</div>
                                        <div className={classNames(styles.drivetrainRightText)}>R</div>
                                        <div className={classNames(styles.eduDrivetrainAdvanceBtn)}
                                            style={{ lineHeight: this.isEnVersion() ? "15px" : "30px" }}
                                            onClick={() => this.handleClickAdvanced()}
                                        >{intl.formatMessage({ id: "gui.deviceManagerStage.edu.drivertrain.advanced" })}</div>
                                        {(this.state.fixMethod == FIX_METHOD.none) ? null :
                                            <div className={classNames(styles.hintFixLight)} />}
                                        {this.hintFixMotor()}
                                    </div>
                                </div> : null
                            }
                        </div>
                    </div>
                }


                {/* button area */}
                <div className={classNames(styles.line)} />
                <div className={classNames(styles.buttonArea)}>
                    <div className={classNames(styles.saveArea)} onClick={() => this.handleSaveName()} />
                    {(enableDelete) ? <div className={classNames(styles.buttonDelete, styles.buttonDeleteDiff)}
                        style={{ lineHeight: this.isEnVersion() ? "17px" : "34px" }}
                        onClick={() => this.handleClickDelete()}>
                        {EditUtils.getLocaleString(STRING_ID.delete)}
                    </div> : null}
                    <div className={classNames(styles.buttonCancel, styles.buttonCancelDiff)}
                        onClick={() => this.handleClickCancel()}>
                        {EditUtils.getLocaleString(STRING_ID.cancel)}
                    </div>
                    <div className={classNames(enableDone ? styles.buttonDone : styles.disableButtonDone,
                        enableDone ? styles.buttonDoneDiff : styles.disableButtonDoneDiff)}
                        onClick={() => {
                            (enableDone) ? this.handleClickSave() : null
                        }}>
                        {EditUtils.getLocaleString(STRING_ID.done)}
                    </div>
                </div>
            </div >
        );
    }
}

EduEditPage.propTypes = {
    isAddPageOpen: PropTypes.bool,
    isEditPageOpen: PropTypes.bool,
    portNumber: PropTypes.string,
    deviceType: PropTypes.string,
    vm: PropTypes.instanceOf(VM).isRequired,
    parsePortArray: PropTypes.func,
    selectPortOption: PropTypes.func,
    deviceId: PropTypes.string,
    onProjectChanged: PropTypes.func,
};

const mapStateToProps = state => ({
    portNumber: getPortNumber(state),
    isAddPageOpen: isAddPageOpen(state),
    deviceType: getPortDevice(state),
    isEditPageOpen: isEditPageOpen(state),
    portName: getPortName(state),
    deviceId: getPortDeviceId(state),
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
    selectPortOption: (portArray) => { dispatch(selectPortOption(portArray)) },
    closeAddPage: () => {
        dispatch(closeDeviceList());
        dispatch(closeAddPage());
        dispatch(cleanPortOption());
        dispatch(closeEditPage());
    },
    onProjectChanged: () => dispatch(setProjectChanged()),
    setPortArray: (array) => dispatch(setPortArray(array)),
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(EduEditPage));