const SET_SLOT_LIST = 'scratch-gui/slotList/SET_SLOT_LIST';
const SELECT_SLOT = 'scratch-gui/slotList/SELECT_SLOT';

const SLOT_INDEX = "slotIndex"
const SLOT_LIST = "slotList"

const initialState = {
    [SLOT_INDEX]: 0,
    [SLOT_LIST]: [""]
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_SLOT_LIST:
            return Object.assign({}, state, {
                [SLOT_LIST]: action.list
            });
        case SELECT_SLOT:
            return Object.assign({}, state, {
                [SLOT_INDEX]: action.slotIndex
            });
        default:
            return state;
    }
};

const setSlotList = (list) => ({
    type: SET_SLOT_LIST,
    list: list
})

const selectSlot = (slotIndex) => ({
    type: SELECT_SLOT,
    slotIndex: slotIndex
})

const getSlotList = (state) => state.scratchGui.slotList[SLOT_LIST];
const getSelectedSlot = (state) => state.scratchGui.slotList[SLOT_INDEX];

export {
    reducer as default,
    initialState as slotListInitialState,
    setSlotList,
    selectSlot,
    getSlotList,
    getSelectedSlot
};
