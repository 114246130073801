import { STAGE_DISPLAY_SIZES, STAGE_DISPLAY_TYPE } from '../lib/layout-constants.js';

const SET_STAGE_SIZE = 'scratch-gui/StageSize/SET_STAGE_SIZE';
const SET_FULL_SCREEN = 'scratch-gui/StageSize/SET_FULL_SCREEN';

const viewPage = {
    vr: "vr"
};

const initialState = {
    stageSize: STAGE_DISPLAY_SIZES.large,
    stageType: STAGE_DISPLAY_TYPE.deviceManager,
    isFullScreen: {
        vr: false
    }
};

const reducer = function(state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_STAGE_SIZE:
            return Object.assign({}, state, {
                stageSize: action.stageSize,
                stageType: action.stageType
            });
        case SET_FULL_SCREEN:
            return Object.assign({}, state, {
                isFullScreen: {
                    [action.page]: action.isFull
                }
            });
        default:
            return state;
    }
};

const setStageSize = function(stageSize, stageType) {
    return {
        type: SET_STAGE_SIZE,
        stageSize: stageSize,
        stageType: stageType
    };
};

const setFullScreen = (isFull, page) => ({
    type: SET_FULL_SCREEN,
    page: page,
    isFull: isFull
});

const getFullScreen = (state, page) => state.scratchGui.stageSize.isFullScreen[page];

export {
    reducer as
    default,
    initialState as stageSizeInitialState,
    viewPage,
    setStageSize,
    setFullScreen,
    getFullScreen
};