const BATTERY_CONNECTED_STATE = {
    UNCHECKED: -1,
    LOW_POWER: 1,
    CONNECTED: 11, // connect if result is bigger than 1
    LOW_POWER_BY_PAD: 18,
    CHECKING: 99
};

export {
    BATTERY_CONNECTED_STATE
}