import eventBlocks from '../block-vertical-blocks/event';

export default function eventEDU(deviceInfo, blockSeparator) {

    return `
        <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">
            ${deviceInfo.bumperInfo.hasbumper ? `
                ${eventBlocks.event_whenbumperbutton}
                ${blockSeparator}
            `: ``}
            ${deviceInfo.controllerInfo.hasController ? `
                ${eventBlocks.event_whencontrollerbutton}
                ${eventBlocks.event_whencontrolleraxischanged}
                ${blockSeparator}
            `: ``}
            ${eventBlocks.event_whenstarted}
            ${eventBlocks.event_whentimergreaterthan}
            ${blockSeparator}
            ${eventBlocks.event_whenbroadcastreceived}
            ${eventBlocks.event_broadcast}
            ${eventBlocks.event_broadcastandwait}
            ${blockSeparator}
            ${eventBlocks.event_whenrfiddetectsdata}
        </category>
    `;
};