import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import styles from './port.css';

import {
    isOptionSelected,
    getSelectedOption,
    isDeviceHovered,
    getHoveredConnectedDevice,
    isConnectedDeviceHovered
} from '../../../reducers/select-option'

class PointOnBrain extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }

    isPortSelected() {
        let isPortSelected = this.props.isOptionSelected;
        if (isPortSelected) {
            let selectedArray = this.props.getSelectedOption;
            if (selectedArray && selectedArray.length > 0) {
                isPortSelected = selectedArray.includes(this.props.number);
            }
        }
        return isPortSelected;
    }

    isDeviceHovered() {
        let isDeviceHovered = this.props.isDeviceHovered;
        if (isDeviceHovered) {
            let selectedArray = this.props.getSelectedOption;
            if (selectedArray && selectedArray.length > 0) {
                isDeviceHovered = selectedArray.includes(this.props.number);
            }
        }
        return isDeviceHovered;
    }

    isConnectedDeviceHovered() {
        let isConnectedDeviceHovered = this.props.isConnectedDeviceHovered;
        if (isConnectedDeviceHovered) {
            let hoveredArray = this.props.getHoveredConnectedDevice;
            if (hoveredArray && hoveredArray.length > 0) {
                isConnectedDeviceHovered = hoveredArray.includes(this.props.number);
            }
        }
        return isConnectedDeviceHovered;
    }

    render() {
        const {
            isConnected
        } = this.props;
        return (
            (this.isDeviceHovered() || this.isPortSelected()) ?
                <div className={classNames(styles.pointSelected)} />
                : (isConnected) ? (this.isConnectedDeviceHovered()) ?
                    <div className={classNames(styles.pointConnected, styles.breathPoint)} />
                    : <div className={classNames(styles.pointConnected)} />
                    : <div className={classNames(styles.pointDisconnected)} />
        );
    }
}

PointOnBrain.propTypes = {
    number: PropTypes.string,
    isConnected: PropTypes.bool,
    isOptionSelected: PropTypes.bool,
    isDeviceHovered: PropTypes.bool,
};

const mapStateToProps = state => ({
    getSelectedOption: getSelectedOption(state),
    isOptionSelected: isOptionSelected(state),
    isDeviceHovered: isDeviceHovered(state),
    getHoveredConnectedDevice: getHoveredConnectedDevice(state),
    isConnectedDeviceHovered: isConnectedDeviceHovered(state)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PointOnBrain);


