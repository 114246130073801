import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import closeIcon from './svg/close_btn.svg';
import guiLicenses from '../../lib/libraries/HulkScratchGUI_ALL_LICENSES'
import desktopLicenses from '../../lib/libraries/HulkScratchDesktop_ALL_LICENSES'
import {
    platformType,
    getPlatform
} from '../../lib/platform';

class LicenseDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
    }
    shouldComponentUpdate(nextProps) {
        return this.props.show !== nextProps.show;
    }
    renderContentType() {
        let allLicenses = getPlatform() == platformType.Desktop ? desktopLicenses : guiLicenses;
        let licenseList = [];
        Object.entries(allLicenses).forEach((license) => {
            let item = (
                <div key={license[0]} className={styles.aboutLicenseItem}>
                    <div className={styles.aboutLicenseItemId}>
                        {license[1]._id}
                    </div>
                    <div>
                        {'License: ' + license[1].license}
                    </div>
                    <div className={styles.aboutLicenseItemCopyright}>
                        {'Copyright: ' + license[1].copyright}
                    </div>
                </div>)
            licenseList.push(item);
        });
        return (
            <div className={styles.licenseDialogDescription}>{licenseList}</div>
        );
    }
    onClose() {
        this.props.hideQuestionDialog(this.props.type);
        this.props.onClose();
    }

    render() {
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle, styles.license)} ref={this.ref} >
                    <div className={styles.aboutHeader}>
                        <div className={styles.aboutTitle}>
                            <FormattedMessage
                                defaultMessage="License"
                                description="Software Authorization Statement"
                                id="gui.menuBar.license"
                            />
                        </div>
                        <div className={styles.aboutClose} onClick={this.props.onClose}>
                            <img src={closeIcon} />
                        </div>
                    </div>
                    <div className={styles.questionView}>
                        <div className={styles.licenseDialogContent}>
                            {this.renderContentType()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LicenseDialog.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool,
    msg: PropTypes.string,
};

LicenseDialog.defaultProps = {
    onClose: () => { },
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LicenseDialog);