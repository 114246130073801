import drivetrainBlocks from '../block-vertical-blocks/drivetrain';

export default function drivetrainWEBVR(deviceInfo) {

    return `
        ${deviceInfo.drivetrainInfo.hasDrivetrain ? `
            <category name="%{BKY_CATEGORY_DRIVETRAIN}" id="drivetrain" colour="#9966FF" secondaryColour="#774DCB">
                ${drivetrainBlocks.drivetrain_drive}
                ${drivetrainBlocks.drivetrain_turn}
                ${drivetrainBlocks.drivetrain_stopdriving}
                ${drivetrainBlocks.drivetrain_setdrivevelocityto}
                ${drivetrainBlocks.drivetrain_setturnvelocityto}
            </category>`: ``
        }
    `;
};