import classNames from 'classnames';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import VM from 'scratch-vm';
import Renderer from 'scratch-render';

import Converter from 'Converter';
import NewGroupDialog from '../dialog/new-group-dialog.jsx';
import Blocks from '../../containers/blocks.jsx';
import StageWrapper from '../../containers/stage-wrapper.jsx';
import Box from '../box/box.jsx';
import MenuBar from '../menu-bar/menu-bar.jsx';
import JsonReorganize from '../../../lib/json_reorganize';
import {
    isServerNewer,
    filterControllerVer
} from '../../lib/string-utils.js';
import {
    TYPE,
    confirmDeleteController,
    beginRCFirmwareUpdating,
    isRCFirmwareUpdating,
    getControllerInfo
} from '../../reducers/controller'

import layout, { STAGE_SIZE_MODES, STAGE_DISPLAY_TYPE } from '../../lib/layout-constants';
import { resolveStageSize } from '../../lib/screen-utils';
import { BRAIN_TYPE } from '../../lib/brains';
import {
    setEnableConfigEntry,
    setEnableConfigEduAndEntry,
    getPickedBrainType,
    isEnableWebVR
} from '../../reducers/picked-brain-type'
import { USER_GUIDE_STATE } from '../../lib/user-guide-state'
import { getUserGuideCurrentState, updateUserGuideState } from '../../reducers/user-guide';

import styles from './gui.css';

import {
    requestNewProject,
    getDirectNew
} from '../../reducers/project-state';
import {
    projectTitleInitialState,
    setProjectNew,
    setProjectTitle,
    setProjectPreTitle,
    projectTitle,
    projectPreTitle,
} from '../../reducers/project-title';

import {
    setProjectTitleChanged
} from '../../reducers/project-title-changed';

import { showLoading, getLoadingPercentage, getLoadingType } from '../../reducers/loading-bar';
import {
    setFileProcessing,
    isFileProcessing
} from '../../reducers/file-manager';
import downloadBlob from '../../lib/download-blob';

import { setLog } from '../../lib/log';
import AboutDialog from '../dialog/about-dialog.jsx';
import UpdateDialog from '../dialog/update-dialog.jsx';
import QuestionDialog from '../dialog/question-dialog.jsx';
import FirmwareDialog from '../dialog/firmware-dialog.jsx';
import BrainTypePicker from '../dialog/brain-type-picker.jsx';
import HintBlockHelpDialog from '../dialog/hint-block-help-dialog.jsx';
import BlockHelpDialog from '../dialog/block-help-dialog.jsx';
import ErrorDialog from '../dialog/error-dialog.jsx';
import AlertDialog from '../dialog/alert-dialog.jsx';
import WhatsNewDialog from '../dialog/whats-new-dialog.jsx';
import WifiSettingDialog from '../dialog/wifi/wifi-setting-dialog.jsx';
import VisionTutorialDialog from '../dialog/vision/vision-tutorial-dialog.jsx';
import VisionDialog from '../dialog/vision/vision-setting-dialog.jsx';
import LicenseDialog from '../dialog/license-dialog.jsx';
import HistoryDialog from '../dialog/history-dialog.jsx';
import SpeakerSkillDialog from '../dialog/speaker/speaker-skill-dialog.jsx';
import SpeakerCustomizingDialog from '../dialog/speaker/speaker-customizing-dialog.jsx';
import SpeakerConceptDialog from '../dialog/speaker/speaker-concept-dialog.jsx';
import BowserUnsupportedDialog from '../dialog/bowser-unsupported-dialog.jsx';

import { parseDeviceList } from '../device-manager/brain/entry-brain.jsx'
import UpdateRCDialog from '../dialog/update-rc-dialog.jsx';

import {
    DownloadType,
    getBrainDownloadingStatus,
    setBrainDownloadingStatus,
    getBrainBrainSwitchingStatus,
    setBrainSwitchingStatus,
    getBrainFWUpdatingStatus,
    getBrainScriptErasingStatus,
    setBrainScriptErasingStatus,
    getBrainConnectStatus,
    getBrainInfo,
    getAllPortInfo,
    getBrainVer,
    getFirmwareConfig,
    getBrainLoadingStatus,
    setBatteryConnectStatus,
    getBatteryConnectStatus,
    setBrainVer,
    setBrainDownloadType,
    getBrainDownloadType,
    setBrainDownloadAndRun
} from '../../reducers/brain';
import {
    cleanTask,
    taskType
} from '../../reducers/task';

import { BATTERY_CONNECTED_STATE } from '../../lib/battery-connected-state'

import {
    selectSlot,
    setSlotList,
    getSlotList,
    getSelectedSlot,
} from '../../reducers/slot-list';

import UserGuide from '../user-guide/user-guide.jsx';

import { shouldShowUserGuide, updateShouldShowUserGuide, isShowUserGuide, updateShowUserGuide } from '../../reducers/user-guide';
import {
    getWorkspace
} from '../../reducers/block';
import {
    sortImportConcepts,
    getExceedConceptNumber,
    renameDupicatedConceptsOnly,
    SENTENCES_LIMIT,
    CONCEPTS_LIMIT,
    // sortUsingGroups,
    // sortUsingConcepts,
    getExceedIntentNumber,
    sortSentenceDifferentData,
    // sortUsingSentenceData,
    renameSortedDifferentData,
    // concatDifferentData
} from './speaker-import.js'

import log from '../../lib/log.js';
import {
    questionType,
    editBlockType,
    errorType,
    setFeedback,
    getFeedback,
    hideCloseAppDialog,
    isCloseAppDialogShow,
    hideAboutDialog,
    aboutDialogShow,
    hideUpdateDialog,
    updateDialogShow,
    hideQuestionDialog,
    quesitonDialogShow,
    hideFirmwarmUpdateDialog,
    firmwarmUpdateDialogShow,
    pickerType,
    brainTypePickerShow,
    hideBrainTypePicker,
    showBrainTypePicker,
    hideDeviceHelpDialog,
    isDeviceHelpDialogShow,
    isHintBlockHelpDialogShow,
    isBlockHelpDialogShow,
    hideHintBlockHelpDialog,
    showBlockHelpDialog,
    hideBlockHelpDialog,
    showQuestionDialog,
    hideErrorDialog,
    showErrorDialog,
    isAlertDialogShow,
    hideAlertDialog,
    isWhatsNewDialogShow,
    hideWhatsNewDialog,
    hideQuestionDeleteSlotDialog,
    isQuestionDeleteSlotDialogShow,
    getQuestionDeleteSlotIndex,
    isVisionTutorialDialogShow,
    isWifiSettingDialogShow,
    isVisionSettingDialogShow,
    isSpeakerSkillDialogShow,
    isSpeakerCustomizingDialogShow,
    showSpeakerCustomizingDialog,
    isSpeakerConceptDialogShow,
    showSpeakerConceptDialog,
    showFirmwarmUpdateDialog,
    showUpdateRCTutorialDialog,
    hideUpdateRCTutorialDialog,
    isUpdateRCTutorialDialogShow,
    showPadFileOpenDialog,
    isPadFileOpenDialogShow,
    showPadFileSaveDialog,
    isPadFileSaveDialogShow,
    aiSpeechGroupType,
    showAddAISpeechGroupDialog,
    hideAddAISpeechGroupDialog,
    isAddAISpeechGroupDialogShow,
    hideEditAISpeechGroupDialog,
    isEditAISpeechGroupDialogShow,
    getEditAISpeechGroupData,
    hideQuestionDeleteAISpeechGroupDialog,
    isQuestionDeleteAISpeechGroupDialogShow,
    getQuestionDeleteAISpeechGroupData,
    getQuestionDeleteAISpeechGroupCallback,
    hideQuestionDeleteSpeakerIntentArrayDialog,
    isQuestionDeleteSpeakerIntentArrayDialogShow,
    getQuestionDeleteSpeakerIntentArrayCallback,
    setSaveCancel,
    isSaveCancel,
    isBoardGameDialogShow,
    hideBoardGameDialog,
    showVRRemindDialog,
    hideVRRemindDialog,
    isVRRemindDialogShow,
    isStreamingDialogShow,
    hideLicenseDialog,
    licenseDialogShow,
    hideHistoryDialog,
    historyDialogShow,
    showBottomHintDialog,
} from '../../reducers/dialog';
import { setFileList } from '../../reducers/pad-file-list'
import { setProjectUnchanged } from '../../reducers/project-changed';

import {
    setProjectTitleisSave,
    setProjectTitleupdateTitle,
    projectTitleupdateTitle
} from '../../reducers/project-title-changed';

import LocalKey from '../../lib/local-storage-key';
import store from 'store';

import ExampleLibrary from '../../containers/example-library.jsx';

import DeviceHelpDialog from '../dialog/device-help-dialog.jsx';
import PadFileSaveDialog from '../dialog/pad-file-save-dialog.jsx';
import PadFileOpenDialog from '../dialog/pad-file-open-dialog.jsx';
import {
    platformType,
    isPad,
    getPlatform
} from '../../lib/platform';

import {
    Catagory,
    FileAction,
    postMessage
} from '../../lib/postmessage';

import { updateFlyoutVisible, updateToolboxVisible } from '../../reducers/toolbox';

import {
    BluetoothAction
} from '../../lib/bluetooth';

import {
    setEditorDisplayMode,
    setCodeView,
    getEditorDisplayMode
} from '../../reducers/code-editor';
import CodeEditor from '../code-editor/code-editor.jsx';
import { EDIT_MODE } from '../../lib/edit-mode.js';
import CodeViewer from '../code-editor/code-viewer.jsx';

import uiStyle, {
    setUIStyle,
    getUIStyle,
    uiType
} from '../../reducers/ui-style';

import {
    isCheckingImportDevice,
    getRealEntryDeviceList,
    setRealEntryDeviceList,
    enableCheckingImportDevice,
} from '../../reducers/device-manager-controller';

import {
    SPEAKER_DIALOG_ACTION,
    setConceptAction,
    getConceptAction,
    setCustomizingAction,
    getCustomizingAction,
    setEditIntentId,
    getEditIntentId,
    isSentenceImported,
    isConceptsImported,
    getImportSentence,
    getImportConcepts,
    clearImportSentence,
    clearImportConcepts,
    setSortedConcept,
    getSortedConcept,
    clearSortedConcept,
    setSortedSentence,
    getSortedSentence,
    clearSortedSentence
} from '../../reducers/speaker';

import { EditUtils, STATE_INDEX, SUPPORT_TYPES, ROTATION } from '../device-manager/edit-page/edit-utils.js';

import {
    setVRSaveFile,
    getUnityMessageFunc,
    getVRSaveFile,
    getVRUnityContextLoadingProgress,
    getVRLangChange,
    setVRUnityContextLoadingProgress,
} from '../../reducers/vr';

import {
    setTestVersion,
    getTestVersion
} from '../../reducers/test-version';

import { selectLocale, localesInitialState } from '../../reducers/locales';

import {
    ENABLE_BRAIN_TYPE_WEB_VR
} from '../../config/project-config';
import BoardGameDialog from '../dialog/board-game-dialog.jsx';

import AudioLoadingDialog from '../dialog/audio-loading-dialog.jsx';
import WifiDirectionDialog from '../dialog/wifi/wifi-direction-dialog.jsx';
import WifiIpSettingDialog from '../dialog/wifi/wifi-ip-setting-dialog.jsx';
import WifiiPadSettingDialog from '../dialog/wifi/wifi-ipad-setting-dialog.jsx';
import StreamingDialog from '../dialog/wifi/streaming-dialog.jsx';

import { isNeedToHintBrowser } from '../../lib/supported-browser';
import { SocketAction } from '../../lib/socket.js';
import { VISION_INIT_STATE_TYPE, isVisionConnected, isVisionInitiated, resetVisionInitState, setVisionInitState, isVisionRecongInitiated, COLOR_DATA_LIST, DEFAULT_FACE_IDNENTIFICATION_LIST } from '../../reducers/vision.js';
import { cvGetFaceFeature, cvGetFaceRecogResult, cvGetOCRResult, cvgetQRCodeMsg, cvGetTags, cvLoadFaceData, cvSelectColor, cvSetFunction, cvSetOCRLanguage, cvSetTags, cvSnapshot, cvStartRealtime, cvStopRealtime } from '../../lib/cvstream.js';
import PadReceiver from '../pad-receiver/pad-receiver.jsx';
import { COLOR_TAG_AMOUNT, parseDataLanguage } from '../dialog/vision/vision-utils.js';
// Set Download Script waiting timeout
const BrainExcuteScriptTimeout = 30 * 1000;
const EntryProBrainExcuteScriptTimeout = 180 * 1000;
const IPadBrainExcuteScriptTimeout = 120 * 1000;
const CHECK_BATTERY_INFO_TIMEOUT = 3 * 1000;
const RESET_BRAIN_TO_DEFAULT_TIMEOUT = 3 * 1000;

//sync desktop command
const cmd_fw = {
    ENABLE_SKIP: true,
    DISABLE_SKIP: false,
    ENABLE_CHECK_TASK_DIALOG: true,
    DISABLE_CHECK_TASK_DIALOG: false,
}

const CHECK_TASK_TIMING = {
    CONNECT_BRAIN: "connectBrain",
    GET_BT_STATUS_ONLY: "getBtStatusOnly",
    CLICK_DOWNLOAD_SCRIPT: "clickDownloadScript",
    CLICK_UPDATE_BRAIN_FIRMWARE: "clickUpdateBrainFirmware",
    CLICK_UPDATE_RC_FIRMWARE: "clickUpdateRCFirmware",
}
// Cache this value to only retrieve it once the first time.
// Assume that it doesn't change for a session.
let isRendererSupported = null;
var isInitial = false;

var downloadProcees;

class GUIComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleQuestionNoSave',
            'handleQuestionSave',
            'questionSaveCallback',
            'handleKeyDown',
            'handleQuestionOK',
            'onClickDownload',
            'isBlockLimit',
            'isBatteryLimit',
            'isBlocksDisabled',
            'checkBrainTypeError',
            'handleEDUScript',
            'handleEntryProScript',
            'onMenuBarClickSave',
            'resetStoreSavePath',
            'handleExit',
            'handleSoftwareUpdate',
            'showAddCustomizinDialog',
            'showEditCustomizinDialog',
            'showAddConceptDialog',
            'showEditConceptDialog',
            'handleGroupUpdate',
            'unityLoadFinish',
            'initVision',
            'resizeWindow'
        ]);
        console.log('GUIComponent constructor')
        if (!isInitial) {
            let platform = platformType.Web;
            if (!ENABLE_BRAIN_TYPE_WEB_VR && ("standalone" in window.navigator) && !window.navigator.standalone) {
                platform = platformType.iPad;
            }
            store.set(LocalKey.platform, platform);
            this.resetStoreSavePath();
            isInitial = true;
        }
        this.state = {
            isClickWriteScript: null,
            eduMotorLimitScriptJson: "",
            excuteScriptTimestamp: 0,
            isShowBlock: !ENABLE_BRAIN_TYPE_WEB_VR
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.getDialogFeedback !== nextProps.getDialogFeedback ||
            this.props.isShowOpenNewDialog !== nextProps.isShowOpenNewDialog ||
            this.props.isShowSaveNewDialog !== nextProps.isShowSaveNewDialog ||
            this.props.isShowSaveOpenDialog !== nextProps.isShowSaveOpenDialog ||
            this.props.isShowSaveNewWebDialog !== nextProps.isShowSaveNewWebDialog ||
            this.props.isShowSaveOpenWebDialog !== nextProps.isShowSaveOpenWebDialog ||
            this.props.isShowSaveRecentDialog !== nextProps.isShowSaveRecentDialog ||
            this.props.stageSizeMode !== nextProps.stageSizeMode ||
            this.props.isShowStartAppBrainTypePicker !== nextProps.isShowStartAppBrainTypePicker ||
            this.props.isShowNewProjectBrainTypePicker !== nextProps.isShowNewProjectBrainTypePicker ||
            this.props.isDeviceHelpDialogShow !== nextProps.isDeviceHelpDialogShow ||
            this.props.isHintBlockHelpDialogShow !== nextProps.isHintBlockHelpDialogShow ||
            this.props.isAddAISpeechGroupDialogShow !== nextProps.isAddAISpeechGroupDialogShow ||
            this.props.isEditAISpeechGroupDialogShow !== nextProps.isEditAISpeechGroupDialogShow ||
            this.props.isQuestionDeleteAISpeechGroupDialogShow !== nextProps.isQuestionDeleteAISpeechGroupDialogShow ||
            this.props.isQuestionDeleteSpeakerIntentArrayDialogShow !== nextProps.isQuestionDeleteSpeakerIntentArrayDialogShow ||
            this.props.isShowCloseAppDialog !== nextProps.isShowCloseAppDialog ||
            this.props.isShowAboutDialog !== nextProps.isShowAboutDialog ||
            this.props.isShowUpdateDialog !== nextProps.isShowUpdateDialog ||
            this.props.isShowSwitchBrainModeInBTModeDialog !== nextProps.isShowSwitchBrainModeInBTModeDialog ||
            this.props.isShowSwitchBrainModeInRFIDModeDialog !== nextProps.isShowSwitchBrainModeInRFIDModeDialog ||
            this.props.isShowDownloadFailDialog !== nextProps.isShowDownloadFailDialog ||
            this.props.isShowInstallFailDialog !== nextProps.isShowInstallFailDialog ||
            this.props.isShowForceUpdateErrorDialog !== nextProps.isShowForceUpdateErrorDialog ||
            this.props.isShowBlockLimitErrorDialog !== nextProps.isShowBlockLimitErrorDialog ||
            this.props.isShowBrainTypeErrorDialog !== nextProps.isShowBrainTypeErrorDialog ||
            this.props.isQuestionDeleteSlotDialogShow !== nextProps.isQuestionDeleteSlotDialogShow ||
            this.props.isShowLicneseDialog !== nextProps.isShowLicneseDialog ||
            this.props.isShowHistoryDialog !== nextProps.isShowHistoryDialog ||
            this.props.isShowBatteryLowPowerErrorDialog !== nextProps.isShowBatteryLowPowerErrorDialog ||
            this.props.isShowBatteryConnectedErrorDialog !== nextProps.isShowBatteryConnectedErrorDialog ||
            this.props.isShowDownloadBrainScriptTimeoutErrorDialog !== nextProps.isShowDownloadBrainScriptTimeoutErrorDialog ||
            this.props.isShowInstallDriverDialog !== nextProps.isShowInstallDriverDialog ||
            this.props.isShowResetBrainFirmwareDialog !== nextProps.isShowResetBrainFirmwareDialog ||
            this.props.isQuestionDeleteController1Show !== nextProps.isQuestionDeleteController1Show ||
            this.props.isShowFirmwareDialog !== nextProps.isShowFirmwareDialog ||
            this.props.isBlockHelpDialogShow !== nextProps.isBlockHelpDialogShow ||
            this.props.isAlertDialogShow !== nextProps.isAlertDialogShow ||
            this.props.isWhatsNewDialogShow !== nextProps.isWhatsNewDialogShow ||
            this.props.exampleLibraryVisible !== nextProps.exampleLibraryVisible ||
            this.props.isBrainDownloading !== nextProps.isBrainDownloading ||
            this.props.isBrainSwitching !== nextProps.isBrainSwitching ||
            this.props.isBrainFWUpdating !== nextProps.isBrainFWUpdating ||
            this.props.isBrainScriptErasing !== nextProps.isBrainScriptErasing ||
            this.props.isBrainConnect !== nextProps.isBrainConnect ||
            this.props.brainInfo !== nextProps.brainInfo ||
            this.props.brainVer !== nextProps.brainVer ||
            this.props.isBrainLoading !== nextProps.isBrainLoading ||
            this.props.batteryConnectStatus !== nextProps.batteryConnectStatus ||
            this.props.isShowUserGuide !== nextProps.isShowUserGuide ||
            this.props.isQuestionUpdateSoftwareDialogShow !== nextProps.isQuestionUpdateSoftwareDialogShow ||
            this.props.isQuestionUpdateFirmwareDialogShow !== nextProps.isQuestionUpdateFirmwareDialogShow ||
            this.props.isQuestionForceUpdateSoftwareDialogShow !== nextProps.isQuestionForceUpdateSoftwareDialogShow ||
            this.props.isQuestionForceUpdateFirmwareDialogShow !== nextProps.isQuestionForceUpdateFirmwareDialogShow ||
            this.props.isQuestionForceUpdateFirmwareAndBtDialogShow !== nextProps.isQuestionForceUpdateFirmwareAndBtDialogShow ||
            this.props.isQuestionUpdateFirmwareAndBtDialogShow !== nextProps.isQuestionUpdateFirmwareAndBtDialogShow ||
            this.props.isQuestionUpdateBtDialogShow !== nextProps.isQuestionUpdateBtDialogShow ||
            this.props.isQuestionLoaderModeDialogShow !== nextProps.isQuestionLoaderModeDialogShow ||
            this.props.isShowSaveUpdateSoftwareDialog !== nextProps.isShowSaveUpdateSoftwareDialog ||
            this.props.isShowOpenEditorDialog !== nextProps.isShowOpenEditorDialog ||
            this.props.isShowOpenEditorSaveDialog !== nextProps.isShowOpenEditorSaveDialog ||
            this.props.isShowRemindFirmwareUpdateDialog !== nextProps.isShowRemindFirmwareUpdateDialog ||
            this.props.isShowRemindBluetoothUpdateDialog !== nextProps.isShowRemindBluetoothUpdateDialog ||
            this.props.isShowFileExtensionNotMatchDialog !== nextProps.isShowFileExtensionNotMatchDialog ||
            this.props.editorDisplayMode !== nextProps.editorDisplayMode ||
            this.props.isWifiSettingDialogShow !== nextProps.isWifiSettingDialogShow ||
            this.props.isVisionTutorialDialogShow !== nextProps.isVisionTutorialDialogShow ||
            this.props.isVisionSettingDialogShow !== nextProps.isVisionSettingDialogShow ||
            this.props.isSpeakerSkillDialogShow !== nextProps.isSpeakerSkillDialogShow ||
            this.props.isSpeakerCustomizingDialogShow !== nextProps.isSpeakerCustomizingDialogShow ||
            this.props.isSpeakerConceptDialogShow !== nextProps.isSpeakerConceptDialogShow ||
            this.props.isShowDirectNewDialog !== nextProps.isShowDirectNewDialog ||
            this.props.isShowDirectNewSaveDialog !== nextProps.isShowDirectNewSaveDialog ||
            this.props.brainInfo.ssn !== nextProps.brainInfo.ssn ||
            this.props.getUIStyle !== nextProps.getUIStyle ||
            this.props.getRealEntryDeviceList !== nextProps.getRealEntryDeviceList ||
            this.props.isCheckingImportDevice !== nextProps.isCheckingImportDevice ||
            this.props.isShowReplaceDeviceDialog !== nextProps.isShowReplaceDeviceDialog ||
            this.props.isUpdateRCTutorialDialogShow !== nextProps.isUpdateRCTutorialDialogShow ||
            this.props.brainDownloadType !== nextProps.brainDownloadType ||
            this.props.getAllPortInfo !== nextProps.getAllPortInfo ||
            this.props.projectTitleupdateTitle !== nextProps.projectTitleupdateTitle ||
            this.props.isPadFileSaveDialogShow !== nextProps.isPadFileSaveDialogShow ||
            this.props.isPadFileOpenDialogShow !== nextProps.isPadFileOpenDialogShow ||
            this.props.getUnityMessageFunc !== nextProps.getUnityMessageFunc ||
            this.props.getVRSaveFile !== nextProps.getVRSaveFile ||
            this.props.getVRUnityContextLoadingProgress !== nextProps.getVRUnityContextLoadingProgress ||
            this.props.isShowImportDuplicatedDialog !== nextProps.isShowImportDuplicatedDialog ||
            this.props.isShowImportConceptDuplicatedDialog !== nextProps.isShowImportConceptDuplicatedDialog ||
            // this.props.isShowImportUselessDialog !== nextProps.isShowImportUselessDialog ||
            this.props.isShowRemoveCustomizingBlockDialog !== nextProps.isShowRemoveCustomizingBlockDialog ||
            this.props.isShowVRCloseFullscreenDialog !== nextProps.isShowVRCloseFullscreenDialog ||
            this.props.isSentenceImported !== nextProps.isSentenceImported ||
            this.props.isConceptsImported !== nextProps.isConceptsImported ||
            this.props.getTestVersion !== nextProps.getTestVersion ||
            this.props.isFileProcessing !== nextProps.isFileProcessing ||
            this.props.showLoading !== nextProps.showLoading ||
            this.props.loadingPercentage !== nextProps.loadingPercentage ||
            this.props.loadingType !== nextProps.loadingType ||
            this.props.isBoardGameDialogShow !== nextProps.isBoardGameDialogShow ||
            this.props.isVRRemindDialogShow !== nextProps.isVRRemindDialogShow ||
            this.props.isStreamingDialogShow !== nextProps.isStreamingDialogShow ||
            this.state.isShowBlock !== nextState.isShowBlock ||
            this.props.controllerInfo !== nextProps.controllerInfo ||
            this.props.firmwareConfig !== nextProps.firmwareConfig ||
            this.props.isVisionInitiated !== nextProps.isVisionInitiated ||
            this.props.isVisionConnected !== nextProps.isVisionConnected) {
            console.log('GUI shouldComponentUpdate');
            return true;
        }
        return false;
    }

    componentDidMount() {
        window.gui = this;
        window.addEventListener('keydown', this.handleKeyDown);
        if (this.props.getUIStyle == uiType.vr) {
            this.resizeWindow();
            window.addEventListener('resize', this.resizeWindow);
            this.isNeedToShowBottomHintInVR();
        } else {
            let element = document.getElementById("loadingBackground");
            if (element) {
                element.remove();
            }
        }
        let lang = store.get(LocalKey.locale, "zh-tw");
        if (lang == "zh-tw") {
            document.documentElement.lang = "zh-TW";
        } else if (lang == "zh-cn") {
            document.documentElement.lang = "zh-CN";
        } else {
            document.documentElement.lang = "en";
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        if (this.props.getUIStyle == uiType.vr) {
            window.removeEventListener('resize', this.resizeWindow);
        }
    }

    unityLoadFinish() {
        this.props.setVRUnityContextLoadingProgress(1);
        this.setState({
            isShowBlock: true
        });
    }

    resizeWindow(e) {
        if (window.innerWidth < 800 || window.innerHeight < 640) {
            if (!this.props.isVRRemindDialogShow) {
                this.props.showVRRemindDialog();
            }
        } else {
            if (this.props.isVRRemindDialogShow) {
                this.props.hideVRRemindDialog();
            }
        }
    }

    handleKeyDown(e) {
        // console.log('handleKeyDown e: ', e)
        if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {  // ControlLeft = 17
            log.info("handleKeyDown onMenuBarClickSave");
            if (this.props.isFileProcessing || this.props.isBrainDownloading) {
                this.props.onShowErrorDialog(errorType.FILE_PROCESSING);
                return;
            }
            e.preventDefault();
            if (this.props.isProjectNameDialogShow) {
                this.props.setProjectTitle(this.props.projectPreTitle);
                this.props.vm.setProjectName(this.props.projectPreTitle);
                this.props.setProjectTitleChanged(false);
                this.props.hideProjectNameDialog();
            }
            this.onMenuBarClickSave();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.getDialogFeedback) {
            log.info("componentDidUpdate isClickWriteScript = ", this.state.isClickWriteScript);
            if (this.props.isSaveCancel) {
                this.setState({ isClickWriteScript: null });
                this.props.setSaveCancel(false);
            }
            if (this.state.isClickWriteScript && !this.props.projectChanged && store.get(LocalKey.nowSavePath) != "") {
                this.onClickDownload(this.props.getProjectJson());
                this.setState({ isClickWriteScript: null });
            }
            if (this.props.isShowSaveNewDialog) {
                this.questionSaveCallback(questionType.QUESTION_SAVE_NEW);
            }
            else if (this.props.isShowSaveOpenDialog) {
                this.questionSaveCallback(questionType.QUESTION_SAVE_OPEN);
            }
            else if (this.props.isShowSaveOpenWebDialog) {
                this.questionSaveCallback(questionType.QUESTION_SAVE_OPEN_WEB);
            }
            else if (this.props.isShowSaveRecentDialog) {
                this.questionSaveCallback(questionType.QUESTION_SAVE_RECENT);
            }
            else if (this.props.isShowProjectNameDialog) {
                this.questionSaveCallback(questionType.QUESTION_SAVE_PROJECTNAME);
            }
            else if (this.props.isShowCloseAppDialog) {
                this.questionSaveCallback(questionType.QUESTION_CLOSE_APP_SAVE_FILE);
            }
            else if (this.props.isShowSaveUpdateSoftwareDialog) {
                this.questionSaveCallback(questionType.QUESTION_SAVE_UPDATE_SOFTWARE);
            }
            else if (this.props.isShowOpenEditorSaveDialog) {
                this.questionSaveCallback(questionType.QUESTION_OPEN_EDITOR_SAVE);
            }
            else if (this.props.isShowDirectNewSaveDialog) {
                this.questionSaveCallback(questionType.QUESTION_DIRECT_NEW_SAVE);
            }
            else if (this.props.isShowOpenNewDialog) {
                this.questionSaveCallback(questionType.QUESTION_OPEN_NEW);
            }
            else if (this.props.isBoardGameDialogShow) {
                this.questionSaveCallback(questionType.QUESTION_OPEN_EXAMPLE)
            }
            this.props.setDialogFeedback(false);
        }

        //initial brain status
        if (this.props.brainInfo.ssn != prevProps.brainInfo.ssn) {
            this.props.selectSlot(0);
        }

        if (prevProps.isBrainDownloading && !this.props.isBrainDownloading) {
            if (this.brainDownloadingTimeout) {
                clearTimeout(this.brainDownloadingTimeout);
            }
            if (isPad()) {
                if (this.props.brainDownloadType == DownloadType.downloadAndRun) {
                    log.info("GUI setBrainDownloadAndRun = true");
                    this.props.setBrainDownloadAndRun(true);
                }
            }
        }
        if (prevProps.isBrainScriptErasing && !this.props.isBrainScriptErasing) {
            if (this.brainScriptErasingTimeout) {
                clearTimeout(this.brainScriptErasingTimeout);
            }
        }
        if (prevProps.batteryConnectStatus == BATTERY_CONNECTED_STATE.CHECKING &&
            this.props.batteryConnectStatus != BATTERY_CONNECTED_STATE.CHECKING &&
            this.props.batteryConnectStatus > BATTERY_CONNECTED_STATE.UNCHECKED) {
            if (this.checkBatteryConnectTimeout) {
                clearTimeout(this.checkBatteryConnectTimeout);
            }
            this.checkBatteryState();
        }

        if (prevProps.getRealEntryDeviceList != this.props.getRealEntryDeviceList && this.props.getRealEntryDeviceList) {
            this.setRealEntryDeviceNameToReducer();
        }
        if (prevProps.isCheckingImportDevice != this.props.isCheckingImportDevice) {
            this.isDeviceListMatch();
        }

        if (prevProps.projectTitleupdateTitle != this.props.projectTitleupdateTitle && this.props.projectTitleupdateTitle) {
            this.props.setProjectTitleupdateTitle(false);
            this.onMenuBarClickSave(true);
        }

        if (prevProps.getVRSaveFile != this.props.getVRSaveFile && this.props.getVRSaveFile && !this.props.getVRLangChange) {
            this.props.setVRSaveFile(false);
            this.onMenuBarClickSave();
        }

        if (prevProps.isSentenceImported != this.props.isSentenceImported && this.props.isSentenceImported == true) {
            this.checkImportSentence();
        }

        if (prevProps.isConceptsImported != this.props.isConceptsImported && this.props.isConceptsImported == true) {
            this.checkImportConcepts();
        }

        if (prevProps.controllerInfo != this.props.controllerInfo || prevProps.firmwareConfig !== this.props.firmwareConfig) {
            this.checkUpdateController()
        }

        if (prevProps.isVisionInitiated != this.props.isVisionInitiated) {
            if (this.props.isVisionInitiated == true && this.props.isVisionConnected == true)
                this.loadVisisionSettingsFromVm()
        }
    }

    loadVisisionSettingsFromVm() {
        const vmProfiles = this.loadProfiles()
        if (this.props.enrollVisionProfileList && !isPad()) {
            this.props.enrollVisionProfileList(vmProfiles);
        } else {
            this.stream4Pad(SocketAction.cvSendCmd, cvSetTags(vmProfiles));
        }
        const vmColorData = this.loadColorDataList()
        if (this.props.setVisionTagSetting && !isPad()) {
            this.props.setVisionTagSetting(vmColorData);
        } else {
            this.stream4Pad(SocketAction.cvSendCmd, cvSetTags(vmColorData));
        }
    }

    loadColorDataList() {
        let list = [];
        let storelist = store.get('visionData');
        if (storelist) list = storelist[COLOR_DATA_LIST];
        if (!list || !Array.isArray(list) || list.length <= 0) list = this.props.vm.getCvTagSettings();
        if (list && Array.isArray(list) && list.length > 0) {
            list = list.slice(0, COLOR_TAG_AMOUNT);
            list = parseDataLanguage(list);
            store.set('visionData', {
                [COLOR_DATA_LIST]: list
            })
        }
        log.log('loadColorDataList list ', list)
        return list
    }

    loadProfiles() {
        let list = [];
        const vmList = this.props.vm.getCvProfiles() || DEFAULT_FACE_IDNENTIFICATION_LIST;
        if (Array.isArray(vmList)) {
            vmList.forEach(profile => list.push({ name: profile.name, image: profile.image, feature: profile.feature }));
        }
        return vmList;
    }

    checkUpdateController() {
        if (!this.props.controllerInfo) return;
        if (this.isControllerCanUpdate()) this.props.showUpdateRCTutorialDialog();
    }

    isControllerCanUpdate() {
        if (!this.props.controllerInfo) return false;
        let controllerInfoVer = this.props.controllerInfo.ver;
        log.log('isControllerCanUpdate controllerInfoVer ', controllerInfoVer)
        if (controllerInfoVer) {
            let serverControllerVer = (this.props.firmwareConfig && this.props.firmwareConfig['SENSOR']
                && this.props.firmwareConfig['SENSOR']['list']
                && this.props.firmwareConfig['SENSOR']['list']['controller'])
                ? this.props.firmwareConfig['SENSOR']['list']['controller']
                : "0.0.0.1"
            let controller = filterControllerVer(controllerInfoVer) ? filterControllerVer(controllerInfoVer) : "ENTRMC.0.1";
            let server = filterControllerVer(serverControllerVer);
            log.log('isControllerCanUpdate controller ', controller)
            log.log('isControllerCanUpdate server ', server)
            return (isServerNewer(controller, server))
        }
        return false;
    }

    checkImportSentence() {
        const importData = this.props.getImportSentence.sentence;
        if (!importData) {
            this.props.clearImportSentence();
            return;
        }
        const vmData = {
            Groups: Array.from(this.props.vm.getSpeakerGroups()),
            IntentArray: Array.from(this.props.vm.getSpeakerIntentArray()),
            Concepts: Array.from(this.props.vm.getSpeakerConcepts()),
        }
        // const sortGroups = sortUsingGroups(importData);
        // const sortConcepts = sortUsingConcepts(importData);
        if ((importData.IntentArray.length + vmData.IntentArray.length > SENTENCES_LIMIT) ||
            (importData.Concepts.length + vmData.Concepts.length > CONCEPTS_LIMIT)) {
            this.props.onShowErrorDialog(errorType.SPEAKER_CUSTOMIZING_IMPORT_EXCEED,
                {
                    exceedSentences: getExceedIntentNumber(importData.IntentArray, vmData.IntentArray),
                    exceedConcepts: getExceedConceptNumber(importData.Concepts, vmData.Concepts)
                })
            this.props.clearImportSentence();
            return;
        }
        // if (sortConcepts.uselessConcepts.length > 0 || sortGroups.uselessGroups.length > 0) {
        //     this.props.showQuestionDialog(questionType.QUESTION_IMPORT_CUSTOMIZING_USELESS)
        //     return;
        // }
        const sortedDifferentData = sortSentenceDifferentData(importData, vmData);
        if (sortedDifferentData.duplicated.IntentArray.length > 0 ||
            sortedDifferentData.duplicated.Concepts.length > 0 ||
            sortedDifferentData.duplicated.Groups.length > 0) {
            this.props.setSortedSentence(sortedDifferentData.different, sortedDifferentData.duplicated);
            this.props.showQuestionDialog(questionType.QUESTION_IMPORT_CUSTOMIZING_DUPLICATED)
            return;
        }
        // if ((importData.IntentArray.length + vmData.IntentArray.length > SENTENCES_LIMIT) ||
        //     (importData.Concepts.length + vmData.Concepts.length > CONCEPTS_LIMIT)) {
        //     this.props.onShowErrorDialog(errorType.SPEAKER_CUSTOMIZING_IMPORT_EXCEED,
        //         {
        //             exceedSentences: getExceedIntentNumber(importData.IntentArray, vmData.IntentArray),
        //             exceedConcepts: getExceedConceptNumber(importData.Concepts, vmData.Concepts)
        //         })
        //     this.props.clearImportSentence();
        //     return;
        // }
        const groups = this.getRenameGroups(vmData, sortedDifferentData)
        this.props.vm.addSpeakerData(groups, importData.IntentArray, importData.Concepts)
        this.props.clearImportSentence();
    }

    getRenameGroups(vmData, sortedDifferentData) {
        let renameData = renameSortedDifferentData(sortedDifferentData, vmData)
        const groups = renameData.different.Groups.concat(renameData.duplicated.Groups)
        return groups
    }

    // onImportUseless() {
    //     const importData = this.props.getImportSentence.sentence;
    //     const vmData = {
    //         Groups: Array.from(this.props.vm.getSpeakerGroups()),
    //         IntentArray: Array.from(this.props.vm.getSpeakerIntentArray()),
    //         Concepts: Array.from(this.props.vm.getSpeakerConcepts()),
    //     }
    //     if ((importData.IntentArray.length + vmData.IntentArray.length > SENTENCES_LIMIT) ||
    //         (importData.Concepts.length + vmData.Concepts.length) > CONCEPTS_LIMIT) {
    //         this.props.onShowErrorDialog(errorType.SPEAKER_CUSTOMIZING_IMPORT_EXCEED,
    //             {
    //                 exceedSentences: getExceedIntentNumber(importData.IntentArray, vmData.IntentArray),
    //                 exceedConcepts: getExceedConceptNumber(importData.Concepts, vmData.Concepts)
    //             })
    //         this.props.clearImportSentence();
    //         return;
    //     }
    //     const sortedDifferentData = sortSentenceDifferentData(importData, vmData);
    //     if (sortedDifferentData.duplicated.IntentArray.length > 0 ||
    //         sortedDifferentData.duplicated.Concepts.length > 0 ||
    //         sortedDifferentData.duplicated.Groups.length > 0) {
    //         this.props.setSortedSentence(sortedDifferentData.different, sortedDifferentData.duplicated);
    //         this.props.showQuestionDialog(questionType.QUESTION_IMPORT_CUSTOMIZING_DUPLICATED);
    //         return;
    //     }
    //     const groups = this.getRenameGroups(vmData, sortedDifferentData)
    //     this.props.vm.addSpeakerData(groups, importData.IntentArray, importData.Concepts)
    //     this.props.clearImportSentence();
    // }

    // onImportUsingOnly() {
    //     const importData = this.props.getImportSentence.sentence;
    //     const vmData = {
    //         Groups: Array.from(this.props.vm.getSpeakerGroups()),
    //         IntentArray: Array.from(this.props.vm.getSpeakerIntentArray()),
    //         Concepts: Array.from(this.props.vm.getSpeakerConcepts()),
    //     }
    //     const sortedDifferentData = sortSentenceDifferentData(importData, vmData);
    //     const sortedGroups = sortUsingGroups(importData);
    //     const sortedConcepts = sortUsingConcepts(importData);
    //     const sortedUsingData = sortUsingSentenceData(sortedDifferentData, sortedGroups.usingGroups, sortedConcepts.usingConcepts)
    //     if (sortedUsingData.duplicated.IntentArray.length > 0 ||
    //         sortedUsingData.duplicated.Concepts.length > 0) {
    //         this.props.setSortedSentence(sortedUsingData.different, sortedUsingData.duplicated);
    //         this.props.showQuestionDialog(questionType.QUESTION_IMPORT_CUSTOMIZING_DUPLICATED)
    //         return;
    //     }
    //     const groups = this.getRenameGroups(vmData, sortedUsingData)
    //     this.props.vm.addSpeakerData(groups, importData.IntentArray, sortedConcepts.usingConcepts)
    //     this.props.clearImportSentence();
    // }

    checkImportConcepts() {
        console.log('checkImportConcepts')
        let importConcepts = Array.from(this.props.getImportConcepts);
        if (!importConcepts) {
            this.props.clearImportConcepts();
            return;
        }
        let vmConcepts = Array.from(this.props.vm.getSpeakerConcepts());
        let newConcepts = [];
        vmConcepts.forEach(concept => newConcepts.push({ id: concept.id, tag: concept.tag, value: concept.value }))
        let sortedDifferentConcept = sortImportConcepts(importConcepts, newConcepts);
        if (importConcepts.length + newConcepts.length > CONCEPTS_LIMIT) {
            this.props.onShowErrorDialog(errorType.SPEAKER_CUSTOMIZING_IMPORT_EXCEED,
                { exceedSentences: 0, exceedConcepts: getExceedConceptNumber(importConcepts, newConcepts) })
            this.props.clearImportConcepts();
            return;
        }
        if (sortedDifferentConcept.duplicated.length > 0) {
            this.props.setSortedConcept(sortedDifferentConcept.different, sortedDifferentConcept.duplicated)
            this.props.showQuestionDialog(questionType.QUESTION_IMPORT_CONCEPT_DUPLICATED)
            return;
        }
        this.props.vm.addMultiSpeakerConcept(importConcepts)
        this.props.clearImportConcepts();
    }


    onImportDuplicated() {
        if (this.props.isConceptsImported) {
            const vmConcepts = Array.from(this.props.vm.getSpeakerConcepts());
            const sortedDifferentConcept = this.props.getSortedConcept;
            let different = Array.from(sortedDifferentConcept.different);
            let duplicated = Array.from(sortedDifferentConcept.duplicated);
            duplicated = renameDupicatedConceptsOnly(duplicated, vmConcepts);
            different = different.concat(duplicated)
            this.props.vm.addMultiSpeakerConcept(different)
            this.props.clearSortedConcept();
            this.props.clearImportConcepts();
        } else if (this.props.isSentenceImported) {
            const vmData = {
                Groups: Array.from(this.props.vm.getSpeakerGroups()),
                IntentArray: Array.from(this.props.vm.getSpeakerIntentArray()),
                Concepts: Array.from(this.props.vm.getSpeakerConcepts()),
            }
            const sortedDifferentData = this.props.getSortedSentence;
            const renameData = renameSortedDifferentData(sortedDifferentData, vmData)
            const groups = renameData.different.Groups.concat(renameData.duplicated.Groups)
            const intents = renameData.different.IntentArray.concat(renameData.duplicated.IntentArray)
            const concepts = renameData.different.Concepts.concat(renameData.duplicated.Concepts)
            this.props.vm.addSpeakerData(groups, intents, concepts)
            this.props.clearSortedSentence();
            this.props.clearImportSentence();
        }
    }


    resetStoreSavePath() {
        store.set(LocalKey.nowSavePath, "");
        store.set(LocalKey.nowPreSavePath, "");
        store.set(LocalKey.nowOpenRecent, "");
    }

    setInitialFromiPad(data) {
        if (typeof data == 'string') data = JSON.parse(data);
        console.log("setInitialFromiPad ", data);
        if (store.get(LocalKey.locale, "") == "") {
            if (data.uiStyle == uiType.ww) {
                store.set(LocalKey.locale, "zh-tw");
                this.props.selectLocale("zh-tw", data.uiStyle, localesInitialState);
            }
        }
        this.props.setUIStyle(data.uiStyle);
        this.props.setEnableConfigEntry(data.enableEntry);
        this.props.setEnableConfigEduAndEntry(data.enableEntryPro);

        setTimeout(() => {
            console.log("setInitialFromiPad window resize");
            setTimeout(() => window.dispatchEvent(new Event('resize')));
            postMessage(Catagory.Initial, {});
        });
    }

    saveFileFromiPad(isSuccess, path, name) {
        this.props.setProjectTitleisSave(false);
        if (isSuccess) {
            this.props.setProjectUnchanged();
            this.props.setProjectTitleisSave(true);
            this.props.setProjectNew(false);
            store.set(LocalKey.nowSavePath, path);
            store.set(LocalKey.nowPreSavePath, path);
            this.props.setProjectTitle(name);
            this.props.setProjectPreTitle(name);
            this.props.vm.setProjectName(name);
        }
        else {
            // when name is empty, that user cancel save file
            // when name is not empty, that file is not exist 
            if (name != "") {
                this.props.onShowErrorDialog(errorType.FILE_NO_EXIST);
            }
            // when cancel saveas, need to restore path to nowSavePath
            if (path == "") {
                let prePath = store.get(LocalKey.nowPreSavePath, "");
                store.set(LocalKey.nowSavePath, prePath);
            }
        }
        this.props.setDialogFeedback(true);
    }

    updateGUIStatusFromiPad(cmdType, data) {
        console.log(cmdType, data);
    }

    handleQuestionNoSave(type) {
        log.info("handleQuestionNoSave: ", type);
        switch (type) {
            case questionType.QUESTION_SAVE_NEW_WEB:
            case questionType.QUESTION_OPEN_NEW:
                this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
                store.set(LocalKey.nowPreSavePath, "");
                store.set(LocalKey.nowSavePath, "");
                if (this.props.shouldShowUserGuide) {
                    this.props.onUpdateShowUserGuide(true);
                }
                if (!this.props.isEnableWebVR) {
                    if (!this.props.getDirectNew.enable) {
                        this.props.onShowNewProjectBrainTypePicker();
                    }
                }
                break;
            case questionType.QUESTION_DIRECT_NEW:
            case questionType.QUESTION_DIRECT_NEW_SAVE:
            case questionType.QUESTION_SAVE_NEW:
                this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
                this.resetStoreSavePath();
                if (this.props.shouldShowUserGuide) {
                    this.props.onUpdateShowUserGuide(true);
                    if (this.props.onCloseBrowserVideoWindow) {
                        this.props.onCloseBrowserVideoWindow();
                    }
                } else {
                    if (!this.props.isEnableWebVR) {
                        if (!this.props.getDirectNew.enable) {
                            this.props.onShowNewProjectBrainTypePicker();
                        }
                    }
                }
                break;
            case questionType.QUESTION_SAVE_OPEN:
                if (isPad()) {
                    postMessage(Catagory.File, { action: FileAction.open });
                }
                else {
                    this.props.onOpenFileClick();
                }
                break;
            case questionType.QUESTION_SAVE_OPEN_WEB:
                log.info("handleQuestionNoSave Do action: ", type);
                break;
            case questionType.QUESTION_SAVE_RECENT:
                let path = store.get(LocalKey.nowOpenRecent);
                this.props.onOpenRecentFileClick(path);
                store.set(LocalKey.nowOpenRecent, "");
                store.set(LocalKey.nowSavePath, path);
                store.set(LocalKey.nowPreSavePath, path);
                break;
            case questionType.QUESTION_SAVE_UPDATE_SOFTWARE:
                this.props.vm.setProjectSaved();
                this.props.onSoftwareUpdate();
                break;
            case questionType.QUESTION_OPEN_EDITOR:
            case questionType.QUESTION_OPEN_EDITOR_SAVE:
                store.set(LocalKey.nowSavePath, "");
                store.set(LocalKey.nowPreSavePath, "");
                this.props.setCodeView(false);
                this.props.setEditorDisplayMode(EDIT_MODE.PYTHON_MODE);
                this.props.vm.setEditMode(EDIT_MODE.PYTHON_MODE);
                break;
        }
    }

    handleQuestionSave(type) {
        log.info("handleQuestionSave: ", type);
        if (this.props.getPickedBrainType == BRAIN_TYPE.WEB_VR) {
            this.props.getUnityMessageFunc('EventSystem', 'SaveFile');
            return;
        }
        this.onMenuBarClickSave();
    }

    handleQuestionCancel(type) {
        this.props.onUpdateShouldShowUserGuide(false);
    }

    questionSaveCallback(type) {
        log.info("questionSaveCallback type = ", type);
        log.info("projectChanged = ", this.props.projectChanged);
        if (!this.props.projectChanged) {
            switch (type) {
                case questionType.QUESTION_DIRECT_NEW_SAVE:
                case questionType.QUESTION_SAVE_NEW:
                    this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
                    this.resetStoreSavePath();
                    if (this.props.shouldShowUserGuide) {
                        this.props.onUpdateShowUserGuide(true);
                        if (this.props.onCloseBrowserVideoWindow) {
                            this.props.onCloseBrowserVideoWindow();
                        }
                    } else {
                        if (!this.props.isEnableWebVR) {
                            if (!this.props.getDirectNew.enable) {
                                this.props.onShowNewProjectBrainTypePicker();
                            }
                        }
                    }
                    break;
                case questionType.QUESTION_SAVE_OPEN:
                    if (isPad()) {
                        postMessage(Catagory.File, { action: FileAction.open });
                    }
                    else {
                        this.props.onOpenFileClick();
                    }
                    break;
                case questionType.QUESTION_SAVE_RECENT:
                    let path = store.get(LocalKey.nowOpenRecent);
                    this.props.onOpenRecentFileClick(path);
                    store.set(LocalKey.nowOpenRecent, "");
                    store.set(LocalKey.nowSavePath, path);
                    store.set(LocalKey.nowPreSavePath, path);
                    break;
                case questionType.QUESTION_CLOSE_APP_SAVE_FILE:
                    if (getPlatform() == platformType.Desktop) {
                        this.props.onExit();
                    }
                    break;
                case questionType.QUESTION_SAVE_UPDATE_SOFTWARE:
                    this.props.onSoftwareUpdate();
                    break;
                case questionType.QUESTION_OPEN_EDITOR_SAVE:
                    store.set(LocalKey.nowSavePath, "");
                    store.set(LocalKey.nowPreSavePath, "");
                    this.props.setCodeView(false);
                    this.props.setEditorDisplayMode(EDIT_MODE.PYTHON_MODE);
                    this.props.vm.setEditMode(EDIT_MODE.PYTHON_MODE);
                    break;
                case questionType.QUESTION_OPEN_EXAMPLE:
                    this.props.hideBoardGameDialog();
                    break;
            }
        }
        this.props.onRequestCloseCloseApp();
        this.props.onRequestCloseSaveNew();
        this.props.onRequestCloseSaveOpen();
        this.props.onRequestCloseSaveOpenWeb();
        this.props.onRequestCloseSaveRecent();
        this.props.onRequestCloseProjectName();
        this.props.onRequestCloseUpdateSoftware();
        this.props.onRequestCloseEditorSaveDialog();
        this.props.onRequestCloseDirectNewDialog();
    }

    handleQuestionOK(type) {
        switch (type) {
            case questionType.QUESTION_BATTERY_DISCONNECTED:
                this.props.onRequestCloseBatteryDisconnectedError();
                this.handleMotorLimitWriteScript();
                break;
            case questionType.QUESTION_BATTERY_LOW_POWER:
                this.props.onRequestCloseBatteryLowPowerError();
                this.handleMotorLimitWriteScript();
                break;
            case questionType.QUESTION_INSTALL_DRIVER:
                this.props.onRequestCloseInstallDriver();
                this.props.onRunDriver();
                log.info('install drvier')
                break;
            case questionType.QUESTION_FILE_EXTENSION_NOT_MATCH:
                if (!this.props.isEnableWebVR) {
                    this.props.onShowStartAppBrainTypePicker();
                }
                break;
        }
    }

    handleResetBrain() {
        log.info('handleResetBrain')
        if (getPlatform() == platformType.Desktop || isPad()) {
            if (getPlatform() == platformType.Desktop) {
                this.props.handleResetToDefault();
            }
            if (isPad()) {
                postMessage(Catagory.Socket, { "action": SocketAction.delete, "index": 0 });
            }

            let list = ["", "", "", ""];
            this.props.setSlotList(list);

            this.props.setBrainScriptErasingStatus(true);

            //set reset brain to default timeout (reset don't have brain callback)
            setTimeout(() => {
                this.props.setBrainScriptErasingStatus(false);
            }, RESET_BRAIN_TO_DEFAULT_TIMEOUT);
        }
        this.props.onRequestCloseResetBrainFirmwareDialog();
    }

    onClickDownload(json) {
        log.info('onClickDownload');
        this.setState({ isClickWriteScript: json });
        if ((this.props.projectChanged || store.get(LocalKey.nowSavePath) == "") && !this.props.isShowUserGuide) {
            this.props.setBrainDownloadingStatus(false);
            this.onMenuBarClickSave();
        } else {
            if (this.checkBrainTypeError()) {
                if (isPad() || (this.props.brainInfo && this.props.brainInfo.brain_type == BRAIN_TYPE.EDU)) {
                    log.info(`onClickDownload setBrainDownloadingStatus false`)
                    this.props.setBrainDownloadingStatus(false);
                } else {
                    log.info(`onClickDownload setTimeout setBrainDownloadingStatus false`)
                    setTimeout(() => { this.props.setBrainDownloadingStatus(false) }, 0);
                }
                this.props.onShowQuestionBrainTypeDialog();
                return;
            }
            if (getPlatform() == platformType.Desktop) {
                this.props.checkUpdatingTask(cmd_fw.DISABLE_SKIP, cmd_fw.ENABLE_CHECK_TASK_DIALOG, CHECK_TASK_TIMING.CLICK_DOWNLOAD_SCRIPT)
                    .then((taskQueue) => {
                        log.info('onClickDownload checkUpdatingTask taskQueue: ', taskQueue);
                        if (Array.isArray(taskQueue) && taskQueue[0] && taskQueue[0] == taskType.UPDATE_BT_FIRMWARE) return;
                        if (!Array.isArray(taskQueue) || taskQueue.length == 0) {
                            this.writeScript(json);
                        } else {
                            if (Array.isArray(taskQueue) && taskQueue[0] && taskQueue[0] == taskType.UPDATE_FIRMWARE) {
                                setTimeout(() => { this.props.setBrainDownloadingStatus(false) }, 0);
                            } else {
                                this.props.setBrainDownloadingStatus(false);
                            }
                        }
                    }).catch((error) => {
                        log.error('catch, onClickDownload checkUpdatingTask ', error);
                        //check link is match
                        if (error == errorType.DUPLICATELY_CALL_FUNCTION) return;
                        if (this.isLinkVersionMatch(this.props.brainVer, this.props.brainInfo)) {
                            log.info('onClickDownload isLinkVersionMatch');
                            this.writeScript(json);
                        } else {
                            if (error.type && errorType[error.type]) this.props.onShowErrorDialog(error.type)
                            this.props.setBrainDownloadingStatus(false)
                        }
                    })
            } else {
                if (this.isLinkVersionMatch(this.props.brainVer, this.props.brainInfo)) {
                    if (this.isBluetoothVersionMatch(this.props.firmwareConfig, this.props.brainInfo)) {
                        log.info('onClickDownload writeScript');
                        this.writeScript(json);
                    } else {
                        log.info("!isBluetoothVersionMatch");
                        this.props.setBrainDownloadingStatus(false);
                        this.props.onShowRemindBluetoothUpdateDialog();
                    }
                } else {
                    log.info("!isLinkVersionMatch");
                    this.props.setBrainDownloadingStatus(false);
                    this.props.onShowRemindFirmwareUpdateDialog();
                }
            }
            this.setState({ isClickWriteScript: null });
        }
    }


    isLinkVersionMatch(currentVer, brainInfo) {
        log.info("GUI isLinkVersionMatch brainVer: ", JSON.stringify(currentVer));
        log.info("GUI isLinkVersionMatch brainInfo: ", JSON.stringify(brainInfo));

        let systemLinkVer = this.systemLinkVer(brainInfo.brain_type);
        log.info('GUI isLinkVersionMatch systemLinkVer > ' + systemLinkVer + ', currentVer.link > ' + brainInfo.minLink)
        if (brainInfo && brainInfo.minLink != undefined) {
            return brainInfo && systemLinkVer == brainInfo.minLink;
        } else {
            log.info('current brain mLinkVer is undefined');
            return true;
        }
    }

    systemLinkVer(brainType) {
        let version = "0.0.1";
        if (brainType == BRAIN_TYPE.EDU) {
            if (this.props.getTestVersion) {
                log.info("getLinkVersionTest");
                version = JsonReorganize.getLinkVersionTest();
            } else {
                version = JsonReorganize.getLinkVersion();
            }
        }

        if (brainType == BRAIN_TYPE.ENTRY || brainType == BRAIN_TYPE.EDU_AND_ENTRY) {
            let converter = new Converter();
            if (this.props.getTestVersion) {
                log.info("getLinkVersionTest");
                version = converter.getConverterVersionTest();
            } else {
                version = converter.getConverterVersion();
            }
        }
        log.info("brainType: " + brainType + ", systemLinkVer: " + version);
        return version;
    }

    isBluetoothVersionMatch(firmwareConfig, brainInfo) {
        log.info("firmwareConfig: ", JSON.stringify(firmwareConfig));
        log.info("brainInfo: ", JSON.stringify(brainInfo));

        if (firmwareConfig && firmwareConfig['SENSOR'] && firmwareConfig['SENSOR']['list']['btmodule'] && brainInfo.bt) {
            return brainInfo.bt == firmwareConfig['SENSOR']['list']['btmodule'];
        }
        return true;
    }

    onUpdateFirmware(timing) {
        this.props.showFirmwarmUpdateDialog();
        this.props.checkUpdatingTask(cmd_fw.DISABLE_SKIP, cmd_fw.DISABLE_CHECK_TASK_DIALOG, timing).then(() => {
            let updateBrainType = this.props.brainInfo.brain_type;
            if (updateBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
                updateBrainType = BRAIN_TYPE.ENTRY;
            }
            this.props.excuteQueueTask(updateBrainType, this.props.brainInfo.ssn);
        })
    }

    onUpdateRCFirmware() {
        console.log('onUpdateRCFirmware')
        this.props.beginRCFirmwareUpdating();
        this.props.checkUpdatingTask(cmd_fw.DISABLE_SKIP, cmd_fw.DISABLE_CHECK_TASK_DIALOG, CHECK_TASK_TIMING.CLICK_UPDATE_RC_FIRMWARE).then(() => {
            let RCSsn = this.props.controllerInfo && this.props.controllerInfo.ssn ? this.props.controllerInfo.ssn : '0000000'
            this.props.excuteQueueTask(BRAIN_TYPE.EDU, RCSsn);
        })
    }

    doActionWriteScript() {
        log.info("doActionWriteScript");
        let json = this.props.getProjectJson();
        if (getPlatform() != platformType.Web) {
            this.onClickDownload(json);
        }
    }

    writeScript(json) {
        console.log('writeScript');
        if (this.props.isShowUserGuide) {
            this.props.onUpdateUserGuideState(USER_GUIDE_STATE.DOWNLOADING);
        }
        if (this.props.brainInfo.brain_type == BRAIN_TYPE.EDU) {
            this.handleEDUScript(json);
        } else {
            this.handleEntryProScript();
        }
        console.log('writeScript isClickWriteScript: null');
        this.setState({ isClickWriteScript: null });
    }


    handleEDUScript(json) {
        if (this.isBlockLimit()) {
            this.props.onShowQuestionBlockLimitDialog();
            this.props.setBrainDownloadingStatus(false);
        } else if (this.isBatteryLimit()) {
            // send to desktop check show dialog
            if (getPlatform() == platformType.Desktop) {
                this.props.checkBatteryConnected();
            }

            if (isPad()) {
                postMessage(Catagory.Bluetooth, { action: BluetoothAction.getDevice });
            }

            this.props.setBatteryConnectStatus(BATTERY_CONNECTED_STATE.CHECKING);
            this.setState({
                eduMotorLimitScriptJson: json // save json
            })
            this.checkBatteryConnectTimeout = setTimeout(() => {
                log.info('check setTimeout checkBatteryConnected batteryConnectStatus: ' + this.props.batteryConnectStatus)
                if (this.props.batteryConnectStatus == BATTERY_CONNECTED_STATE.CHECKING) {
                    this.props.onShowQuestionDownloadBrainScriptTimeoutDialog();
                    this.props.setBatteryConnectStatus(BATTERY_CONNECTED_STATE.UNCHECKED);
                }
            }, CHECK_BATTERY_INFO_TIMEOUT);
        } else {
            this.callWriteScript(json);
        }
    }

    handleMotorLimitWriteScript() {
        this.props.setBatteryConnectStatus(BATTERY_CONNECTED_STATE.UNCHECKED);
        this.callWriteScript(this.state.eduMotorLimitScriptJson);
        this.setState({ eduMotorLimitScriptJson: "" });
    }

    checkBatteryState() {
        log.info('checkBatteryState batteryConnectStatus: ' + this.props.batteryConnectStatus);
        if (getPlatform() == platformType.Desktop &&
            this.props.batteryConnectStatus <= BATTERY_CONNECTED_STATE.LOW_POWER) {
            //Disconnect battery
            this.props.onShowQuestionBatteryDisconnectedDialog();

        } else if (getPlatform() == platformType.Desktop &&
            this.props.batteryConnectStatus > BATTERY_CONNECTED_STATE.LOW_POWER &&
            this.props.batteryConnectStatus < BATTERY_CONNECTED_STATE.CONNECTED) {
            //battery is low power
            this.props.onShowQuestionBatteryLowPowerDialog();

        } else if (isPad() &&
            this.props.batteryConnectStatus <= BATTERY_CONNECTED_STATE.LOW_POWER_BY_PAD) {
            //battery is low power
            this.props.onShowQuestionBatteryLowPowerDialog();

        } else {
            // battery connected
            this.handleMotorLimitWriteScript();
        }
    }

    callWriteScript(json) {
        var refinedScriptString = this.getRefinedScriptJson();
        if (!refinedScriptString && this.props.editorDisplayMode == EDIT_MODE.BLOCK_MODE) {
            log.error('callWriteScript refinedScriptString is empty')
            this.props.setBrainDownloadingStatus(false);
            this.props.onShowQuestionDownloadFail();
            return;
        }
        let excuteScriptTimeout = BrainExcuteScriptTimeout;
        if (isPad()) {
            excuteScriptTimeout = IPadBrainExcuteScriptTimeout;
            postMessage(Catagory.Bluetooth, { action: BluetoothAction.download, data: refinedScriptString });
        } else {
            this.props.onWriteScriptClick(json, this.getRefinedScriptBrainType());
        }
        this.handleWriteScriptTimeout(excuteScriptTimeout);
    }

    handleEntryProScript() {
        var refinedPythonString = this.getPythonCode();
        if (!refinedPythonString) {
            log.error('handleEntryProScript refinedPythonString is empty')
            this.props.setBrainDownloadingStatus(false);
            this.props.onShowQuestionDownloadFail();
            return;
        }

        let filePath = store.get(LocalKey.nowSavePath);
        if (this.props.isShowUserGuide) {
            filePath = store.get(LocalKey.userGuideEntryPath);
        }

        let excuteScriptTimeout = EntryProBrainExcuteScriptTimeout;
        if (isPad()) {
            excuteScriptTimeout = IPadBrainExcuteScriptTimeout;
            this.props.saveProjectSb3ForRN(this.getSaveScriptContent(), this.getRefinedScriptBrainType())
                .then(content => {
                    postMessage(Catagory.Socket, { action: SocketAction.download, slot: (this.props.getSelectedSlot + 1), data: content });
                });
        } else {
            this.props.onWriteScriptClick(refinedPythonString, this.getRefinedScriptBrainType(), filePath);
        }
        this.handleWriteScriptTimeout(excuteScriptTimeout);
    }


    handleWriteScriptTimeout(excuteScriptTimeout) {
        let excuteScriptTimestamp = new Date().getTime();
        log.info('check setTimeout excute ScriptStatus: ' + excuteScriptTimestamp + ', timeout: ' + excuteScriptTimeout);
        this.setState({
            excuteScriptTimestamp: excuteScriptTimestamp
        })

        this.props.setBrainDownloadingStatus(true);
        this.brainDownloadingTimeout = setTimeout((timestamp) => {
            log.info('check setTimeout download DownloadingStatus: ' + this.props.isBrainDownloading + ' timestamp: ' + timestamp + ', state.excuteScriptTimestamp:' + this.state.excuteScriptTimestamp)
            if (this.props.isBrainDownloading && timestamp == this.state.excuteScriptTimestamp) {
                this.props.onShowQuestionDownloadBrainScriptTimeoutDialog();
                this.props.setBrainDownloadType(DownloadType.download);
                this.props.setBrainDownloadingStatus(false);
            }
        }, excuteScriptTimeout, excuteScriptTimestamp);
    }



    handleDeleteSlotFile() {
        //TODO Use brain type to choose Erase API
        if (getPlatform() == platformType.Desktop) {
            this.props.handleEraseSlot(this.props.getPickedBrainType, this.props.slotIndex + 1);
        }
        if (this.props.slotIndex > -1) {
            let excuteScriptTimeout = BrainExcuteScriptTimeout;
            if (isPad()) {
                excuteScriptTimeout = IPadBrainExcuteScriptTimeout;
                if (this.props.getPickedBrainType == BRAIN_TYPE.EDU) {
                    postMessage(Catagory.Bluetooth, { action: BluetoothAction.delete, index: this.props.slotIndex });
                    this.props.setBrainDownloadType(DownloadType.download);
                } else {
                    postMessage(Catagory.Socket, { action: SocketAction.delete, index: this.props.slotIndex + 1 });
                }
            }
            let list = this.props.getSlotList;
            list[this.props.slotIndex] = "";
            this.props.setSlotList(list);
            this.props.setBrainScriptErasingStatus(true);
            this.brainScriptErasingTimeout = setTimeout(() => {
                log.info('check setTimeout erasing ScriptErasingStatus: ' + this.props.isBrainScriptErasing)
                if (this.props.isBrainScriptErasing) {
                    this.props.onShowQuestionEraseBrainScriptTimeoutDialog('timeout: ' + excuteScriptTimeout);
                    this.props.setBrainScriptErasingStatus(false);
                }
            }, excuteScriptTimeout);
        } else {
            log.error('handleDeleteSlotFile failed slotIndex : ' + this.props.slotIndex)
        }
        this.props.hideQuestionDeleteSlotDialog();
    }

    handleAISpeechDeleteGroup() {
        this.props.aiSpeechDeleteAISpeechGroupCallback(this.props.getWorkspace);
        this.props.hideQuestionDeleteAISpeechGroupDialog();
    }

    handleAISpeechDeleteIntentArray() {
        this.props.aiSpeechDeleteSpeakerIntentArrayCallback(this.props.getWorkspace);
        this.props.hideQuestionDeleteSpeakerIntentArrayDialog();
    }

    isBlockLimit() {
        if (typeof this.props.getWorkspace != 'undefined' &&
            typeof this.props.getWorkspace.blockLimitinEDU) {
            let blockCount = this.props.getWorkspace.getBlockCountOnWorkspace();
            console.log("blockCount = ", blockCount, " blockLimitinEDU = ", this.props.getWorkspace.blockLimitinEDU);
            return blockCount > this.props.getWorkspace.blockLimitinEDU;
        } else {
            return false;
        }

    }

    isBatteryLimit() {
        var allBlocks = this.props.getWorkspace.getAllBlocks(true);
        var firstMotorName = "";
        for (var block of allBlocks) {
            var type = block.type;
            if (type.includes("drivetrain_")) {
                return true;
            } else if (type.includes("motion_3wire_")) {
                var motorName = "";
                var fieldS = block.inputList[0].fieldRow;
                for (var field of fieldS) {
                    if (field.name == "MOTORNAME") {
                        motorName = field.text_;
                        break;
                    }
                }
                if (firstMotorName !== "" && firstMotorName !== motorName) {
                    return true;
                }
                firstMotorName = motorName;
            }
        }
        return false;
    }

    isBlocksDisabled() {
        var allBlocks = this.props.getWorkspace.getAllBlocks(false);
        for (let i = 0; i < allBlocks.length; i++) {
            if (allBlocks[i].isDisabled()) {
                return true;
            }
        }
        return false;
    }

    checkBrainTypeError() {
        console.log(`this.props.brainInfo.brain_type:  ${this.props.brainInfo.brain_type}, getBrainType: ${this.props.getPickedBrainType}`)
        return this.props.brainInfo.brain_type != this.props.getPickedBrainType;
    }

    onMenuBarClickSave(autoSave = false) {
        if (getPlatform() == platformType.Desktop) {
            this.props.onSaveClick();
            if (this.props.isFileProcessing && !autoSave) {
                this.props.onShowErrorDialog(errorType.FILE_PROCESSING);
                return;
            }
            this.props.setFileProcessing(true);
        }
        let ext = "abot";
        if (this.props.getPickedBrainType == BRAIN_TYPE.ENTRY) {
            ext = "bbot";
        } else if (this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
            ext = "cbot";
        }
        this.props.saveProjectSb3(this.getSaveScriptContent(), this.getRefinedScriptBrainType())
            .then(content => {
                switch (getPlatform()) {
                    case platformType.iPad:
                        postMessage(Catagory.File,
                            {
                                action: FileAction.save,
                                name: this.props.projectFilename,
                                data: content,
                                path: store.get(LocalKey.nowSavePath),
                                ext: ext
                            });
                        break;
                    case platformType.RN:
                        console.log("RN content = ", content);
                        let filename = this.props.projectFilename;
                        let reader = new FileReader();
                        let path = store.get(LocalKey.nowSavePath)
                        if (path != "") this.props.vm.setProjectSaved();
                        reader.readAsDataURL(content);
                        reader.onloadend = function () {
                            let base64data = reader.result.split(",")[1];
                            postMessage(Catagory.File,
                                {
                                    action: FileAction.save,
                                    name: filename,
                                    data: base64data,
                                    path: store.get(LocalKey.nowSavePath),
                                    ext: ext
                                });
                        }
                        break;
                    case platformType.Web:
                        downloadBlob(this.props.projectFilename, content, this.props.getPickedBrainType, this.props.editorDisplayMode);
                        this.props.vm.setProjectSaved();
                        this.props.setDialogFeedback(true);
                        break;
                    case platformType.Desktop:
                        if (downloadProcees) {
                            clearTimeout(downloadProcees);
                        }

                        downloadProcees = setTimeout(() => {
                            downloadBlob(this.props.projectFilename, content, this.props.getPickedBrainType, this.props.editorDisplayMode);
                        }, 1000);
                        break;
                }
            });
    }

    getFileDataForPad() {
        return this.props.saveProjectSb3(this.getSaveScriptContent(), this.getRefinedScriptBrainType())
    }

    getSaveScriptContent() {
        if (this.props.getPickedBrainType == BRAIN_TYPE.EDU) {
            return this.getRefinedScriptJson();
        } else if (this.props.getPickedBrainType == BRAIN_TYPE.ENTRY || this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
            return this.getPythonCode();
        }
        return ''
    }

    getRefinedScriptJson() {
        var refinedScriptJson = "";
        try {
            if (this.props.getRefinedScript) {
                refinedScriptJson = this.props.getRefinedScript(this.props.getProjectJson(), this.getRefinedScriptBrainType());
            } else {
                var projectJson = JSON.parse(this.props.getProjectJson());
                refinedScriptJson = JsonReorganize.refineJS(projectJson, this.getRefinedScriptBrainType());
            }
        } catch (e) {
            log.info("refinedScriptJson exception: ", e);
            log.info("refinedScriptJson failed content: ", this.props.getProjectJson());
        }
        log.info("refinedScriptJson: ", refinedScriptJson);
        return refinedScriptJson;
    }

    getPythonCode() {
        if (this.props.editorDisplayMode == EDIT_MODE.BLOCK_MODE) {
            try {
                const generator = new Converter(JSON.parse(this.props.getProjectJson()));
                var code = generator.workspaceToRealCode();
                log.info('Generator Code: ', code);
                return code;
            } catch (e) {
                if (e instanceof TypeError) {
                    log.info('getPythonCode TypeError: ', e)
                } else {
                    log.info('getPythonCode ConverterError: ', e)
                }
                return ''
            }
        } else {
            return this.props.vm.getPythonCode();
        }
    }

    getRefinedScriptBrainType() {
        // brainType  1:Entry/Pro  2:EDU/ENTRY 3: _SOCKET
        var brainType = "1";
        if (this.props.getPickedBrainType == BRAIN_TYPE.EDU) {
            brainType = "2";
        }
        if (this.props.brainInfo.isSocket) brainType = "3";
        return brainType;
    }

    showAddCustomizinDialog() {
        //Call for block and customizing dialog to add concept
        const vmIntentArray = this.props.vm.getSpeakerIntentArray();
        if (vmIntentArray.length >= SENTENCES_LIMIT) {
            this.props.onShowErrorDialog(errorType.SPEAKER_SENTENCE_UP_TO_LIMIT);
        } else {
            this.showSpeakerCustomizinDialog(SPEAKER_DIALOG_ACTION.add);
        }
    }

    showEditCustomizinDialog(intentId) {
        //Call for block and customizing dialog to manage concept
        this.props.setEditIntentId(intentId)
        this.showSpeakerCustomizinDialog(SPEAKER_DIALOG_ACTION.edit)
    }

    showSpeakerCustomizinDialog(action) {
        this.props.setCustomizingAction(action)
        this.props.showSpeakerCustomizingDialog();
    }

    showAddConceptDialog() {
        //Call for block and customizing dialog to add concept
        const vmConcepts = this.props.vm.getSpeakerConcepts();
        if (vmConcepts.length >= CONCEPTS_LIMIT) {
            this.props.onShowErrorDialog(errorType.SPEAKER_CONCEPT_UP_TO_LIMIT);
        } else {
            this.showSpeakerConceptDialog(SPEAKER_DIALOG_ACTION.add);
        }
    }

    showEditConceptDialog() {
        //Call for block and customizing dialog to manage concept
        this.showSpeakerConceptDialog(SPEAKER_DIALOG_ACTION.edit)
    }

    showSpeakerConceptDialog(action) {
        this.props.setConceptAction(action)
        this.props.showSpeakerConceptDialog();
    }

    handleBrainTypePick(type, brain) {
        console.log('handleBrainTypePick: ', type, brain)
        this.props.vm.setBrainType(brain);
        this.props.vm.setEditMode(this.props.editorDisplayMode);
        switch (type) {
            case pickerType.PICKER_NEW_PROJECT:
                this.props.onRequestCloseNewProjectBrainTypePicker();
                break;
            case pickerType.PICKER_START_APP:
                this.props.onRequestCloseStartAppBrainTypePicker();
                break;
        }
        this.props.setProjectUnchanged();
    }

    handleBlocksImportSentencesFile() {
        if (isPad()) {
            postMessage(Catagory.File, { action: FileAction.importSentences });
        } else {
            this.props.onImportSentencesClick();
        }
    }

    handleBrainTypePickerOpenFile() {
        if (isPad()) {
            postMessage(Catagory.File, { action: FileAction.open });
        } else {
            this.props.onOpenFileClick();
        }
    }

    setLocalStorage(data) {
        store.set(data.key, data.value)
    }

    getLocalStorage(key) {
        if (isPad()) {
            let data = store.get(key);
            data = (typeof data != 'string') ? JSON.stringify(data) : data
            console.log(`getLocalStorage ${key} ${data}`)
            postMessage(Catagory.Storage, { action: "get", key: key, value: data });
            return;
        } else {
            return store.get(key);
        }
    }

    isBrainConnected() {
        return !!(this.props.isBrainConnect && this.props.brainInfo.ssn);
    }

    isEnableDownloadAndRunButton() {
        if (this.isBrainConnected()) {
            return !(this.props.isBrainDownloading || this.props.isBrainFWUpdating || this.props.isBrainScriptErasing || this.props.isBrainSwitching
                || this.props.batteryConnectStatus == BATTERY_CONNECTED_STATE.CHECKING);
        } else {
            return false;
        }
    }

    handleExit() {
        let platform = getPlatform();
        if (platform == platformType.Desktop) {
            this.props.onExit();
        } else if (isPad()) {
            postMessage(Catagory.Exit, {});
        }
    }

    handleSoftwareUpdate() {
        log.info("handleSoftwareUpdate");
        if (this.props.projectChanged) {
            this.props.showQuestionDialog(questionType.QUESTION_SAVE_UPDATE_SOFTWARE);
        } else {
            this.props.onSoftwareUpdate();
        }
    }

    handleGroupUpdate(groupName, editType) {
        console.log(`handleGroupUpdate groupName: ${groupName}`);
        switch (editType) {
            case aiSpeechGroupType.NEW_GROUP:
                this.props.vm.addSpeakerGroup(groupName);
                this.props.hideAddAISpeechGroupDialog();
                break;
            case aiSpeechGroupType.EDIT_GROUP:
                console.log(`handleGroupUpdate getGroupId(): ${this.props.aiSpeechEditGroupData.getGroupId()}`);
                if (groupName) {
                    this.props.vm.editSpeakerGroupName(this.props.aiSpeechEditGroupData.getGroupId(), groupName);
                }
                this.props.hideEditAISpeechGroupDialog();
                break;
        }
        this.props.getWorkspace.refreshToolboxSelection_();
    }

    onClickSaveAsFromPad() {
        this.props.showPadFileSaveDialog();
    }
    onClickOpenFromPad() {
        this.props.showPadFileOpenDialog();
    }

    setFileList(list) {
        // For pad
        this.props.setFileList(list);
    }
    setRealEntryDeviceNameToReducer() {
        let realEntryDevice = this.props.getRealEntryDeviceList;
        console.log('setRealEntryDeviceNameToReducer realEntryDevice > ', realEntryDevice)
        if (Array.isArray(realEntryDevice)) {
            realEntryDevice.forEach(portInfo => {
                if (portInfo.device != "") {
                    portInfo.name = EditUtils.getDeviceDefaultName(portInfo.device);
                    if (portInfo.port != "L" && portInfo.port != "R" && portInfo.name != "") {
                        portInfo.name += "-" + portInfo.port;
                    }
                }
            })
            this.props.setRealEntryDeviceList(realEntryDevice);
        }
    }

    isDeviceListMatch() {
        log.info('isDeviceListMatch ', this.props.isCheckingImportDevice)
        if (this.props.isCheckingImportDevice == true
            && ((this.props.brainInfo.brain_type == BRAIN_TYPE.ENTRY && this.props.getPickedBrainType == BRAIN_TYPE.ENTRY) ||
                (this.props.brainInfo.brain_type == BRAIN_TYPE.EDU_AND_ENTRY && this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY))) {
            let realEntryDevice = this.props.getRealEntryDeviceList;
            let vmEntryDevice = parseDeviceList(this.props.vm.getDeviceList());
            log.info('isDeviceListMatch realEntryDevice ', realEntryDevice)
            log.info('isDeviceListMatch vmEntryDevice ', vmEntryDevice)
            if (Array.isArray(realEntryDevice) && Array.isArray(vmEntryDevice)) {
                for (let i = 0; i < realEntryDevice.length; i++) {
                    let isRealDeviceEmpty = realEntryDevice.filter(device => device.device != "").length == 0;
                    if (isRealDeviceEmpty) return;
                    let matchPort = vmEntryDevice.filter(vmDevice => realEntryDevice[i].port == vmDevice.port)[0];
                    if ((realEntryDevice[i].device.includes("motor") || realEntryDevice[i].device.includes("gyro"))
                        && matchPort.type == "drivetrain") continue;
                    if (realEntryDevice[i].device != matchPort.type) {
                        log.info('isDeviceListMatch realEntryDevice[i].device ', realEntryDevice[i].device)
                        log.info('isDeviceListMatch matchPort.type ', matchPort.type)
                        this.props.showQuestionDialog(questionType.QUESTION_REPLACE_DEVICE)
                        return;
                    }
                }
            }
        }
        return;
    }

    replaceDeviceManager() {
        log.info('replaceDeviceManager');
        this.props.vm.clearDeviceList();
        this.setRealEntryDeviceNameToReducer();
        this.props.enableCheckingImportDevice(false);
        let realEntryDevice = this.props.getRealEntryDeviceList;
        if (Array.isArray(realEntryDevice)) {
            let visionNum = 0;
            let speakerNum = 0;
            realEntryDevice.forEach(portInfo => {
                if (portInfo.device != "") {
                    if (portInfo.device == SUPPORT_TYPES.vision) {
                        portInfo.port = "L";
                        visionNum++;
                    }
                    if (portInfo.device == SUPPORT_TYPES.speaker) {
                        portInfo.port = "R";
                        speakerNum++
                    }
                    if (portInfo.device == SUPPORT_TYPES.motor100 || portInfo.device == SUPPORT_TYPES.motor120) {
                        this.props.vm.addMotor100(portInfo.name, [portInfo.port], ROTATION.forward, EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.forward'), EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.reverse'), false);
                    } else if (portInfo.device == SUPPORT_TYPES.motor300 || portInfo.device == SUPPORT_TYPES.motor360) {
                        this.props.vm.addMotor300(portInfo.name, [portInfo.port], ROTATION.forward, EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.forward'), EditUtils.getLocaleString('gui.deviceManagerStage.page.motor.reverse'), false);
                    } else {
                        if (portInfo.device == SUPPORT_TYPES.vision) {
                            if (visionNum == 1) {
                                this.props.vm.addCommonDevice(SUPPORT_TYPES.vision, portInfo.name, [portInfo.port], false);
                            } else {
                                log.warn('Import vision sensor twice')
                            }
                        } else if (portInfo.device == SUPPORT_TYPES.speaker) {
                            if (speakerNum == 1) {
                                this.props.vm.addCommonDevice(SUPPORT_TYPES.speaker, portInfo.name, [portInfo.port], false);
                            } else {
                                log.warn('Import speaker sensor twice')
                            }
                        } else {
                            if (portInfo.device == SUPPORT_TYPES.color) portInfo.device = SUPPORT_TYPES.colorSensor;
                            if (portInfo.device == SUPPORT_TYPES.touch_led) portInfo.device = SUPPORT_TYPES.touchLed;
                            this.props.vm.addCommonDevice(portInfo.device, portInfo.name, [portInfo.port], false);
                        }
                    }
                }
            })
        }
    }

    getImportDevice() {
        let result = []
        const language = store.get(LocalKey.locale, "zh-tw")
        if (this.props.isCheckingImportDevice &&
            (this.props.brainInfo.brain_type == BRAIN_TYPE.ENTRY && this.props.getPickedBrainType == BRAIN_TYPE.ENTRY) ||
            (this.props.brainInfo.brain_type == BRAIN_TYPE.EDU_AND_ENTRY && this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY)) {
            let realEntryDevice = this.props.getRealEntryDeviceList;
            let portString = {
                "en": "Port",
                "zh-tw": "插槽",
                "zh-cn": "接口"
            }
            if (Array.isArray(realEntryDevice)) {
                realEntryDevice.forEach(realDevice => {
                    realDevice.name = EditUtils.getDeviceDefaultName(realDevice.device);
                    if (realDevice.port != "L" && realDevice.port != "R" && realDevice.name != "") {
                        realDevice.name += "-" + realDevice.port;
                    }

                    if (realDevice.device != "") {
                        result.push("．　" + ((realDevice.port == "L" || realDevice.port == "R") ?
                            ("Type-C " + portString[language])
                            : (portString[language] + realDevice.port)) + " - " + realDevice.name);
                    }
                })
            }
            log.info('getImportDevice ', result);
        }
        return result;
    }

    setTestVersion(enable) {
        log.info('setTestVersion ', enable);
        this.props.setTestVersion(enable);
    }

    isNeedToShowBottomHintInVR() {
        if (isNeedToHintBrowser()) {
            console.log(`isNeedToShowBottomHintInVR showBottomHintDialog`)
            this.props.showBottomHintDialog();
        }
    }

    initVision() {
        if (!this.props.isVisionConnected || this.props.isVisionInitiated) return;
        this.props.resetVisionInitState();
        setTimeout(() => {
            this.props.setVisionInitState(VISION_INIT_STATE_TYPE.INITIALIZING);
            postMessage(Catagory.Socket, { "action": SocketAction.startcvRealtime });
        }, 100)
    }

    closeVision() {
        postMessage(Catagory.Socket, { "action": SocketAction.stopcvRealtime });
    }

    stream4Pad(type, cmd) {
        switch (type) {
            case SocketAction.downloadcvPic:
            case SocketAction.cvSendCmd:
                if (this.props.isVisionConnected && this.props.isVisionInitiated) {
                    if (type == SocketAction.downloadcvPic) {
                        postMessage(Catagory.Socket, { "action": type });
                    } else {
                        postMessage(Catagory.Socket, { "action": type, "cmd": cmd });
                    }
                }
                break;
            default:
                postMessage(Catagory.Socket, { "action": type });
                break;
        }
    }

    screenshotVision2Pad(base64Data, projectName, timestamp) {
        if (!projectName || projectName == "") projectName = "screentshot"
        let name = `${projectName}${timestamp}`;
        postMessage(Catagory.Socket, { "action": SocketAction.cvImageSave, "name": name, "data": base64Data });
    }

    render() {
        const {
            authorId,
            authorThumbnailUrl,
            authorUsername,
            basePath,
            blocksTabVisible,
            canChangeLanguage,
            canCreateNew,
            canEditTitle,
            canSave,
            canCreateCopy,
            canUseCloud,
            intl,
            isCreating,
            isFullScreen,
            isPlayerOnly,
            isRtl,
            isShared,
            onLogOut,
            onOpenRegistration,
            onToggleLoginOpen,
            onUpdateProjectTitle,
            onActivateSoundsTab,
            onActivateTab,
            getRefinedScript,
            onClickBrowser,
            onRunDriver,
            onClickSetWriteSlotNumber,
            onClickSlotInfo,
            onProjectTelemetryEvent,
            onSeeCommunity,
            onTelemetryModalCancel,
            onTelemetryModalOptIn,
            onTelemetryModalOptOut,
            showComingSoon,
            stageSizeMode,
            stageTypeMode,
            exampleLibraryVisible,
            vm,
            isShowCloseAppDialog,
            isShowAboutDialog,
            isShowUpdateDialog,
            isShowSwitchBrainModeInBTModeDialog,
            isShowSwitchBrainModeInRFIDModeDialog,
            isShowDownloadFailDialog,
            isShowInstallFailDialog,
            isShowOpenNewDialog,
            isShowSaveNewDialog,
            isShowSaveOpenDialog,
            isShowSaveNewWebDialog,
            isShowSaveOpenWebDialog,
            isShowSaveRecentDialog,
            isShowForceUpdateErrorDialog,
            isShowBlockLimitErrorDialog,
            isShowBrainTypeErrorDialog,
            isShowBatteryLowPowerErrorDialog,
            isShowBatteryConnectedErrorDialog,
            isShowDownloadBrainScriptTimeoutErrorDialog,
            isShowInstallDriverDialog,
            isShowFirmwareDialog,
            isDeviceHelpDialogShow,
            isHintBlockHelpDialogShow,
            isBlockHelpDialogShow,
            isShowSaveUpdateSoftwareDialog,
            isShowOpenEditorDialog,
            isShowOpenEditorSaveDialog,
            isShowRemindFirmwareUpdateDialog,
            isShowRemindBluetoothUpdateDialog,
            isShowFileExtensionNotMatchDialog,
            isAddAISpeechGroupDialogShow,
            isEditAISpeechGroupDialogShow,
            isQuestionDeleteAISpeechGroupDialogShow,
            isQuestionDeleteSpeakerIntentArrayDialogShow,
            isShowVRCloseFullscreenDialog,
            onRequestCloseFirmware,
            onRequestCloseCloseApp,
            onRequestCloseAbout,
            onRequestCloseUpdate,
            onRequestCloseDownloadFail,
            onRequestCloseSaveNew,
            onRequestCloseSaveOpen,
            onRequestCloseSaveOpenWeb,
            onRequestCloseSaveRecent,
            onRequestCloseDeviceHelp,
            onRequestCloseHintBlockHelp,
            onRequestOpenBlockHelp,
            onRequestCloseBlockHelp,
            onRequestCloseLicense,
            onRequestCloseHistory,
            isAlertDialogShow,
            isWhatsNewDialogShow,
            onOpenFileClick,
            onImportSentencesClick,
            onOpenRecentFileClick,
            isShowStartAppBrainTypePicker,
            isShowNewProjectBrainTypePicker,
            onShowNewProjectBrainTypePicker,
            onRequestCloseStartAppBrainTypePicker,
            onRequestCloseNewProjectBrainTypePicker,
            showUserGuide,
            onOutputPDFClick,
            onWriteScriptClick,
            onRunScriptClick,
            onStopScriptClick,
            onRestoreClick,
            outputLog,
            hasMaximizedComment,
            onRestart,
            onExit,
            connectComPortPath,
            setPickedBrainToDesktop,
            updateFlyoutVisibleState,
            updateToolboxVisibleState,
            onRequestCloseErrorDialog,
            handleEraseSlot,
            handleResetToDefault,
            onRequestCloseAlertDialog,
            onRequestCloseWhatsNewDialog,
            isQuestionDeleteSlotDialogShow,
            isShowLicneseDialog,
            isShowHistoryDialog,
            onCloseBrowserVideoWindow,
            editorDisplayMode,
            onClickWifiStatus,
            onClickSwitchWifi,
            onClickWifiList,
            onWifiScanResult,
            onClickAddWifi,
            onClickDeleteWifi,
            onClickSelectWifi,
            onClickWifiScan,
            isShowDirectNewDialog,
            isShowDirectNewSaveDialog,
            checkBluetoothStatus,
            isShowReplaceDeviceDialog,
            isShowUserGuide,
            onClickNew,
            // isShowImportUselessDialog,
            isShowImportDuplicatedDialog,
            isShowImportConceptDuplicatedDialog,
            isBoardGameDialogShow,
            isVRRemindDialogShow,
            isStreamingDialogShow,
            ...componentProps
        } = omit(this.props, 'dispatch');
        if (isRendererSupported === null) {
            isRendererSupported = Renderer.isSupported();
        }
        if (outputLog) {
            console.log('outputLog is exist')
            setLog(outputLog);
        }

        return (<MediaQuery minWidth={layout.fullSizeMinWidth}>{isFullSize => {
            const stageSize = resolveStageSize(stageSizeMode, isFullSize);
            return (
                <Box
                    className={styles.pageWrapper}
                    dir={isRtl ? 'rtl' : 'ltr'}
                    uis={this.props.getUIStyle}
                >

                    {exampleLibraryVisible ? (
                        <ExampleLibrary
                            onUpdateProjectTitle={onUpdateProjectTitle}
                            uis={this.props.getUIStyle}
                            onSave={this.onMenuBarClickSave}
                        />
                    ) : null}
                    <MenuBar
                        authorId={authorId}
                        authorThumbnailUrl={authorThumbnailUrl}
                        authorUsername={authorUsername}
                        canChangeLanguage={canChangeLanguage}
                        canCreateCopy={canCreateCopy}
                        canCreateNew={canCreateNew}
                        canEditTitle={canEditTitle}
                        canSave={canSave}
                        className={styles.menuBarPosition}
                        isShared={isShared}
                        showComingSoon={showComingSoon}
                        getRefinedScript={getRefinedScript}
                        onClickBrowser={onClickBrowser}
                        onRunDriver={onRunDriver}
                        onClickSetWriteSlotNumber={onClickSetWriteSlotNumber}
                        onClickSlotInfo={onClickSlotInfo}
                        onLogOut={onLogOut}
                        onOpenRegistration={onOpenRegistration}
                        onProjectTelemetryEvent={onProjectTelemetryEvent}
                        onSeeCommunity={onSeeCommunity}
                        onToggleLoginOpen={onToggleLoginOpen}
                        onUpdateProjectTitle={onUpdateProjectTitle}
                        onOpenFileClick={onOpenFileClick}
                        checkBluetoothStatus={checkBluetoothStatus}
                        onOpenRecentFileClick={onOpenRecentFileClick}
                        onOutputPDFClick={onOutputPDFClick}
                        onWriteScriptClick={onWriteScriptClick}
                        onRunScriptClick={onRunScriptClick}
                        onClickWifiStatus={onClickWifiStatus}
                        onClickSwitchWifi={onClickSwitchWifi}
                        onClickWifiList={onClickWifiList}
                        onWifiScanResult={onWifiScanResult}
                        onClickAddWifi={onClickAddWifi}
                        onClickDeleteWifi={onClickDeleteWifi}
                        onClickSelectWifi={onClickSelectWifi}
                        onClickWifiScan={onClickWifiScan}
                        onStopScriptClick={onStopScriptClick}
                        connectComPortPath={(path) => {
                            this.props.connectComPortPath(path);
                            this.props.setBrainSwitchingStatus(true);
                        }}
                        isBrainConnected={this.isBrainConnected()}
                        isEnableDownloadAndRunButton={this.isEnableDownloadAndRunButton()}
                        onMenuBarClickSave={this.onMenuBarClickSave}
                        doActionWriteScript={() => this.doActionWriteScript()}
                        handleEraseSlot={handleEraseSlot}
                        handleResetToDefault={handleResetToDefault}
                        onCloseBrowserVideoWindow={onCloseBrowserVideoWindow}
                        connectApPort={this.props.connectApPort}
                        disconnectPort={this.props.disconnectPort}
                        scanWifiApBrainList={this.props.scanWifiApBrainList}
                        setWifiAp={this.props.setWifiAp}
                        startListenApBroadcast={this.props.startListenApBroadcast}
                        stopListenApBroadcast={this.props.stopListenApBroadcast}
                        isControllerCanUpdate={this.isControllerCanUpdate()}
                        isBlocksDisabled={this.isBlocksDisabled}
                    />

                    {(this.props.getUIStyle != uiType.vr) ? <CodeEditor displayMode={editorDisplayMode} vm={vm} /> : null}
                    <Box className={classNames(
                        styles.bodyWrapper,
                        this.props.getPickedBrainType == BRAIN_TYPE.ENTRY || this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY ? styles.entry : null
                    )}
                    >
                        <Box className={styles.flexWrapper}>
                            <Box className={styles.blocksWrapper}>
                                {
                                    this.state.isShowBlock ? (
                                        <Blocks
                                            canUseCloud={canUseCloud}
                                            grow={1}
                                            isVisible={blocksTabVisible}
                                            options={{
                                                media: `${basePath}static/blocks-media/`
                                            }}
                                            stageSize={stageSize}
                                            vm={vm}
                                            showAddCustomizinDialog={this.showAddCustomizinDialog}
                                            showEditCustomizinDialog={this.showEditCustomizinDialog}
                                            showAddConceptDialog={this.showAddConceptDialog}
                                            showEditConceptDialog={this.showEditConceptDialog}
                                            showExtensionBlockNeedWifiDialog={() => { this.props.onShowErrorDialog(errorType.EXTENSION_BLOCK_NEED_WIFI) }}
                                            onClickOpenfile={() => this.handleBrainTypePickerOpenFile()}
                                            onClickImportSentences={() => this.handleBlocksImportSentencesFile()}
                                        />) : null
                                }
                            </Box>
                            <CodeViewer />
                            <StageWrapper
                                isRendererSupported={isRendererSupported}
                                isRtl={isRtl}
                                stageSize={stageSize}
                                stageType={stageTypeMode}
                                vm={vm}
                                setVisionTagSetting={this.props.setVisionTagSetting}
                                getVisionTagSetting={this.props.getVisionTagSetting}
                                onLoadFinish={this.unityLoadFinish}
                            />
                        </Box>
                    </Box>

                    {isShowAboutDialog ?
                        <AboutDialog show={isShowAboutDialog}
                            onClose={onRequestCloseAbout}
                            onUpdateSoftware={this.handleSoftwareUpdate}
                            onUpdateFirmware={() => { this.onUpdateFirmware(CHECK_TASK_TIMING.CLICK_UPDATE_BRAIN_FIRMWARE); }}
                            onUpdateControllerFirmware={() => { this.props.showUpdateRCTutorialDialog() }}
                            isBrainConnected={this.isBrainConnected()}
                            isEnableDownloadAndRunButton={this.isEnableDownloadAndRunButton()}
                            locale={this.props.locale} />
                        : null}
                    {isShowUpdateDialog ?
                        <UpdateDialog show={isShowUpdateDialog}
                            onClose={onRequestCloseUpdate}
                            onUpdate={this.handleSoftwareUpdate} />
                        : null}
                    {isShowLicneseDialog ?
                        <LicenseDialog show={isShowLicneseDialog}
                            onClose={onRequestCloseLicense} />
                        : null}
                    {isShowHistoryDialog ?
                        <HistoryDialog show={isShowHistoryDialog}
                            onClose={onRequestCloseHistory}
                            locale={this.props.locale} />
                        : null}
                    {isShowSwitchBrainModeInBTModeDialog ?
                        <QuestionDialog show={isShowSwitchBrainModeInBTModeDialog}
                            type={questionType.QUESTION_SWITCH_BRAIN_MODE_IN_BT_MODE}
                            onDownload={() => this.doActionWriteScript()} />
                        : null}
                    {isShowSwitchBrainModeInRFIDModeDialog ?
                        <QuestionDialog show={isShowSwitchBrainModeInRFIDModeDialog}
                            type={questionType.QUESTION_SWITCH_BRAIN_MODE_IN_RFID_MODE}
                            onDownload={() => this.doActionWriteScript()} />
                        : null}
                    {isShowDownloadFailDialog ? <QuestionDialog show={isShowDownloadFailDialog} type={questionType.QUESTION_DOWNLOAD_FAIL} /> : null}
                    {isShowInstallFailDialog ?
                        <QuestionDialog show={isShowInstallFailDialog}
                            type={questionType.QUESTION_INSTALL_FAIL}
                            onRestart={onRestart}
                            onExit={onExit} />
                        : null}
                    {isQuestionDeleteSlotDialogShow ?
                        <QuestionDialog show={isQuestionDeleteSlotDialogShow}
                            type={questionType.QUESTION_DELETE_SLOT_FILE}
                            onDelete={() => this.handleDeleteSlotFile()}
                            onClose={() => this.props.hideQuestionDeleteSlotDialog()} />
                        : null}

                    {this.props.isQuestionUpdateSoftwareDialogShow ?
                        <QuestionDialog show={this.props.isQuestionUpdateSoftwareDialogShow} type={questionType.QUESTION_UPDATE_SOFTWARE_FIRST}
                            onUpdate={this.handleSoftwareUpdate} />
                        : null}
                    {this.props.isQuestionUpdateFirmwareDialogShow && this.isEnableDownloadAndRunButton() ?
                        <QuestionDialog show={this.props.isQuestionUpdateFirmwareDialogShow && this.isEnableDownloadAndRunButton()} type={questionType.QUESTION_UPDATE_FIRMWARE_FIRST}
                            onUpdate={() => { this.onUpdateFirmware(CHECK_TASK_TIMING.CLICK_DOWNLOAD_SCRIPT); }} />
                        : null}
                    {this.props.isQuestionForceUpdateSoftwareDialogShow ?
                        <QuestionDialog show={this.props.isQuestionForceUpdateSoftwareDialogShow} type={questionType.QUESTION_FORCE_UPDATE_SOFTWARE}
                            onUpdate={this.handleSoftwareUpdate} />
                        : null}
                    {this.props.isQuestionForceUpdateFirmwareDialogShow && this.isEnableDownloadAndRunButton() ?
                        <QuestionDialog show={this.props.isQuestionForceUpdateFirmwareDialogShow && this.isEnableDownloadAndRunButton()} type={questionType.QUESTION_FORCE_UPDATE_FIRMWARE}
                            onUpdate={() => { this.onUpdateFirmware(CHECK_TASK_TIMING.CLICK_DOWNLOAD_SCRIPT); }} />
                        : null}
                    {this.props.isQuestionLoaderModeDialogShow && this.isEnableDownloadAndRunButton() ?
                        <QuestionDialog show={this.props.isQuestionLoaderModeDialogShow && this.isEnableDownloadAndRunButton()} type={questionType.QUESTION_LOADER_MODE}
                            onUpdate={() => { this.onUpdateFirmware(CHECK_TASK_TIMING.CLICK_DOWNLOAD_SCRIPT); }} />
                        : null}
                    {this.props.isQuestionForceUpdateFirmwareAndBtDialogShow && this.isEnableDownloadAndRunButton() ?
                        <QuestionDialog show={this.props.isQuestionForceUpdateFirmwareAndBtDialogShow && this.isEnableDownloadAndRunButton()} type={questionType.QUESTION_FORCE_UPDATE_BT_AND_BRAIN}
                            onUpdate={() => { this.onUpdateFirmware(CHECK_TASK_TIMING.CLICK_DOWNLOAD_SCRIPT); }} />
                        : null}
                    {this.props.isQuestionUpdateFirmwareAndBtDialogShow && this.isEnableDownloadAndRunButton() ?
                        <QuestionDialog show={this.props.isQuestionUpdateFirmwareAndBtDialogShow && this.isEnableDownloadAndRunButton()} type={questionType.QUESTION_UPDATE_BT_AND_BRAIN_FIRST}
                            onUpdate={() => { this.onUpdateFirmware(CHECK_TASK_TIMING.CLICK_DOWNLOAD_SCRIPT); }} />
                        : null}
                    {this.props.isQuestionUpdateBtDialogShow && this.isEnableDownloadAndRunButton() ?
                        <QuestionDialog show={this.props.isQuestionUpdateBtDialogShow && this.isEnableDownloadAndRunButton()}
                            type={questionType.QUESTION_UPDATE_BT_FIRMWARE_FIRST}
                            onUpdate={() => {
                                this.onUpdateFirmware(CHECK_TASK_TIMING.GET_BT_STATUS_ONLY);
                                this.setState({ isClickWriteScript: null });
                            }}
                            onClose={() => {
                                if (this.state.isClickWriteScript) {
                                    this.writeScript(this.state.isClickWriteScript)
                                }
                            }} />
                        : null}
                    {isShowOpenNewDialog ?
                        <QuestionDialog show={isShowOpenNewDialog}
                            type={questionType.QUESTION_OPEN_NEW}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_OPEN_NEW)}
                            onClose={() => this.handleQuestionCancel(questionType.QUESTION_OPEN_NEW)} />
                        : null}
                    {isShowSaveNewDialog ?
                        <QuestionDialog show={isShowSaveNewDialog}
                            type={questionType.QUESTION_SAVE_NEW}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_SAVE_NEW)}
                            onSave={() => this.handleQuestionSave(questionType.QUESTION_SAVE_NEW)}
                            onClose={() => this.handleQuestionCancel(questionType.QUESTION_SAVE_NEW)} />
                        : null}
                    {isShowSaveOpenDialog ?
                        <QuestionDialog show={isShowSaveOpenDialog}
                            type={questionType.QUESTION_SAVE_OPEN}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_SAVE_OPEN)}
                            onSave={() => this.handleQuestionSave(questionType.QUESTION_SAVE_OPEN)} />
                        : null}
                    {isShowSaveNewWebDialog ?
                        <QuestionDialog show={isShowSaveNewWebDialog}
                            type={questionType.QUESTION_SAVE_NEW_WEB}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_SAVE_NEW_WEB)} />
                        : null}
                    {isShowSaveOpenWebDialog ?
                        <QuestionDialog show={isShowSaveOpenWebDialog}
                            onUpdateProjectTitle={onUpdateProjectTitle}
                            onMenuBarClickSave={this.onMenuBarClickSave}
                            type={questionType.QUESTION_SAVE_OPEN_WEB}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_SAVE_OPEN_WEB)} />
                        : null}
                    {isShowSaveRecentDialog ?
                        <QuestionDialog show={isShowSaveRecentDialog}
                            type={questionType.QUESTION_SAVE_RECENT}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_SAVE_RECENT)}
                            onSave={() => this.handleQuestionSave(questionType.QUESTION_SAVE_RECENT)} />
                        : null}
                    {isShowSaveUpdateSoftwareDialog ?
                        <QuestionDialog show={isShowSaveUpdateSoftwareDialog}
                            type={questionType.QUESTION_SAVE_UPDATE_SOFTWARE}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_SAVE_UPDATE_SOFTWARE)}
                            onSave={() => this.handleQuestionSave(questionType.QUESTION_SAVE_UPDATE_SOFTWARE)} />
                        : null}
                    {isShowReplaceDeviceDialog && !isShowUserGuide ?
                        <QuestionDialog
                            show={isShowReplaceDeviceDialog && !isShowUserGuide}
                            type={questionType.QUESTION_REPLACE_DEVICE}
                            onReplace={() => this.replaceDeviceManager()}
                            onClose={() => { this.props.enableCheckingImportDevice(false); }}
                            importingDevice={this.getImportDevice()} />
                        : null}
                    <FirmwareDialog
                        show={isShowFirmwareDialog}
                        onClose={onRequestCloseFirmware} />
                    <BrainTypePicker show={isShowStartAppBrainTypePicker}
                        pickerType={pickerType.PICKER_START_APP}
                        onPickTypeEntry={() => this.handleBrainTypePick(pickerType.PICKER_START_APP, BRAIN_TYPE.ENTRY)}
                        onPickTypeEdu={() => this.handleBrainTypePick(pickerType.PICKER_START_APP, BRAIN_TYPE.EDU)}
                        onPickTypeEduAndEntry={() => this.handleBrainTypePick(pickerType.PICKER_START_APP, BRAIN_TYPE.EDU_AND_ENTRY)}
                        onPickTypeWebVR={() => this.handleBrainTypePick(pickerType.PICKER_START_APP, BRAIN_TYPE.WEB_VR)}
                        onClickOpenfile={() => this.handleBrainTypePickerOpenFile()}
                        onUpdateProjectTitle={onUpdateProjectTitle}
                        onExit={this.handleExit}
                        setPickedBrainToDesktop={setPickedBrainToDesktop}
                    />
                    <BrainTypePicker show={isShowNewProjectBrainTypePicker}
                        pickerType={pickerType.PICKER_NEW_PROJECT}
                        onPickTypeEntry={() => this.handleBrainTypePick(pickerType.PICKER_NEW_PROJECT, BRAIN_TYPE.ENTRY)}
                        onPickTypeEdu={() => this.handleBrainTypePick(pickerType.PICKER_NEW_PROJECT, BRAIN_TYPE.EDU)}
                        onPickTypeEduAndEntry={() => this.handleBrainTypePick(pickerType.PICKER_NEW_PROJECT, BRAIN_TYPE.EDU_AND_ENTRY)}
                        onPickTypeWebVR={() => this.handleBrainTypePick(pickerType.PICKER_START_APP, BRAIN_TYPE.WEB_VR)}
                        onClickOpenfile={() => this.handleBrainTypePickerOpenFile()}
                        onUpdateProjectTitle={onUpdateProjectTitle}
                        onExit={this.handleExit}
                        setPickedBrainToDesktop={setPickedBrainToDesktop}
                    />
                    {isDeviceHelpDialogShow ? <DeviceHelpDialog show={isDeviceHelpDialogShow} onClose={onRequestCloseDeviceHelp} /> : null}
                    {isHintBlockHelpDialogShow ? <HintBlockHelpDialog show={isHintBlockHelpDialogShow} locale={this.props.locale} onClose={onRequestCloseHintBlockHelp} /> : null}
                    {isBlockHelpDialogShow ? <BlockHelpDialog show={isBlockHelpDialogShow} onClose={onRequestCloseBlockHelp} /> : null}
                    {isShowForceUpdateErrorDialog ?
                        <QuestionDialog show={isShowForceUpdateErrorDialog}
                            type={questionType.QUESTION_FORCE_UPDATE} />
                        : null}
                    {isShowBlockLimitErrorDialog ?
                        <QuestionDialog show={isShowBlockLimitErrorDialog}
                            type={questionType.QUESTION_BLOCK_LIMIT} />
                        : null}
                    {isShowBrainTypeErrorDialog ?
                        <QuestionDialog show={isShowBrainTypeErrorDialog}
                            type={questionType.QUESTION_BRAIN_TYPE} />
                        : null}
                    {isShowBatteryLowPowerErrorDialog ?
                        <QuestionDialog show={isShowBatteryLowPowerErrorDialog}
                            type={questionType.QUESTION_BATTERY_LOW_POWER}
                            onClose={() => this.handleQuestionOK(questionType.QUESTION_BATTERY_LOW_POWER)} />
                        : null}
                    {isShowBatteryConnectedErrorDialog ?
                        <QuestionDialog show={isShowBatteryConnectedErrorDialog}
                            type={questionType.QUESTION_BATTERY_DISCONNECTED}
                            onClose={() => this.handleQuestionOK(questionType.QUESTION_BATTERY_DISCONNECTED)} />
                        : null}
                    {isShowDownloadBrainScriptTimeoutErrorDialog ?
                        <QuestionDialog show={isShowDownloadBrainScriptTimeoutErrorDialog}
                            type={questionType.QUESTION_DOWNLOAD_BRAIN_SCRIPT_TIMEOUT} />
                        : null}
                    {isShowInstallDriverDialog ?
                        <QuestionDialog show={isShowInstallDriverDialog}
                            type={questionType.QUESTION_INSTALL_DRIVER}
                            onInstall={() => this.handleQuestionOK(questionType.QUESTION_INSTALL_DRIVER)} />
                        : null}
                    {this.props.isShowResetBrainFirmwareDialog ?
                        <QuestionDialog show={this.props.isShowResetBrainFirmwareDialog}
                            type={questionType.QUESTION_RESET_BRAIN_FIRMWARE}
                            onDelete={() => this.handleResetBrain()} />
                        : null}
                    {this.props.isQuestionDeleteController1Show ?
                        <QuestionDialog show={this.props.isQuestionDeleteController1Show}
                            type={questionType.QUESTION_DELETE_CONTROLLER_1}
                            onDelete={() => {
                                this.props.confirmDeleteController()
                                this.props.vm.removeControllerByType(TYPE.main)
                            }} />
                        : null}
                    {isShowOpenEditorDialog ?
                        <QuestionDialog
                            show={isShowOpenEditorDialog}
                            type={questionType.QUESTION_OPEN_EDITOR}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_OPEN_EDITOR)}
                        />
                        : null}
                    {isShowOpenEditorSaveDialog ?
                        <QuestionDialog
                            show={isShowOpenEditorSaveDialog}
                            type={questionType.QUESTION_OPEN_EDITOR_SAVE}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_OPEN_EDITOR_SAVE)}
                            onSave={() => this.handleQuestionSave(questionType.QUESTION_OPEN_EDITOR_SAVE)}
                        />
                        : null}
                    {isShowDirectNewDialog ?
                        <QuestionDialog
                            show={isShowDirectNewDialog}
                            type={questionType.QUESTION_DIRECT_NEW}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_DIRECT_NEW)}
                        />
                        : null}
                    {isShowDirectNewSaveDialog ?
                        <QuestionDialog
                            show={isShowDirectNewSaveDialog}
                            type={questionType.QUESTION_DIRECT_NEW_SAVE}
                            onNoSave={() => this.handleQuestionNoSave(questionType.QUESTION_DIRECT_NEW_SAVE)}
                            onSave={() => this.handleQuestionSave(questionType.QUESTION_DIRECT_NEW_SAVE)}
                        />
                        : null}
                    {isShowRemindFirmwareUpdateDialog ?
                        <QuestionDialog
                            show={isShowRemindFirmwareUpdateDialog}
                            type={questionType.QUESTION_REMIND_FIRMWARE_UPDATE}
                        />
                        : null}
                    {isShowRemindBluetoothUpdateDialog ?
                        <QuestionDialog
                            show={isShowRemindBluetoothUpdateDialog}
                            type={questionType.QUESTION_REMIND_BLUETOOTH_UPDATE}
                            onDownload={() => this.writeScript(this.props.getProjectJson())}
                        />
                        : null}
                    {isShowFileExtensionNotMatchDialog ?
                        <QuestionDialog
                            show={isShowFileExtensionNotMatchDialog}
                            type={questionType.QUESTION_FILE_EXTENSION_NOT_MATCH}
                            onClose={() => this.handleQuestionOK(questionType.QUESTION_FILE_EXTENSION_NOT_MATCH)}
                        />
                        : null}
                    <WifiSettingDialog
                        show={this.props.isWifiSettingDialogShow}
                        onClickWifiStatus={onClickWifiStatus}
                        onClickAddWifi={onClickAddWifi}
                        onClickSwitchWifi={onClickSwitchWifi}
                        onClickWifiList={onClickWifiList}
                        onWifiScanResult={onWifiScanResult}
                        onClickDeleteWifi={onClickDeleteWifi}
                        onClickSelectWifi={onClickSelectWifi}
                        onClickWifiScan={onClickWifiScan}
                        scanWifiApBrainList={this.props.scanWifiApBrainList}
                        setWifiAp={this.props.setWifiAp}
                    />
                    <FirmwareDialog show={isShowFirmwareDialog} onClose={onRequestCloseFirmware} />
                    <ErrorDialog
                        onClose={onRequestCloseErrorDialog}
                        onUpdateEdu={() => {
                            this.props.checkUpdatingTask(cmd_fw.DISABLE_SKIP, cmd_fw.DISABLE_CHECK_TASK_DIALOG, CHECK_TASK_TIMING.CLICK_UPDATE_BRAIN_FIRMWARE)
                                .then(() => {
                                    let updateBrainType = this.props.brainInfo.brain_type;
                                    if (updateBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
                                        updateBrainType = BRAIN_TYPE.ENTRY;
                                    }
                                    this.props.excuteQueueTask(updateBrainType, this.props.brainInfo.ssn);
                                })
                        }} />
                    <UserGuide
                        onClickNew={onClickNew}
                        uis={this.props.getUIStyle}
                        onClickSwitchWifi={onClickSwitchWifi}
                        onClickWifiScan={onClickWifiScan}
                        connectApPort={this.props.connectApPort}
                        disconnectPort={this.props.disconnectPort}
                        setWifiAp={this.props.setWifiAp}
                        startListenApBroadcast={this.props.startListenApBroadcast}
                        stopListenApBroadcast={this.props.stopListenApBroadcast}
                    />
                    {isAlertDialogShow ? <AlertDialog show={isAlertDialogShow} onClose={onRequestCloseAlertDialog} /> : null}
                    {isWhatsNewDialogShow ? <WhatsNewDialog show={isWhatsNewDialogShow} onClose={onRequestCloseWhatsNewDialog} /> : null}
                    {this.props.isVisionTutorialDialogShow ? <VisionTutorialDialog show={this.props.isVisionTutorialDialogShow} /> : null}
                    <VisionDialog
                        vm={vm}
                        show={this.props.isVisionSettingDialogShow}
                        initVision={this.props.initVision ? this.props.initVision : this.initVision}
                        closeVision={this.props.closeVision ? this.props.closeVision : this.closeVision}
                        startVisionRealtime={this.props.startVisionRealtime ? this.props.startVisionRealtime : (mode) => { this.stream4Pad(SocketAction.cvSendCmd, cvStartRealtime(mode)); }}
                        setVisionRealtimeMode={this.props.setVisionRealtimeMode ? this.props.setVisionRealtimeMode : (mode) => { this.stream4Pad(SocketAction.cvSendCmd, cvSetFunction(mode)); }}
                        stopVisionRealtime={this.props.stopVisionRealtime ? this.props.stopVisionRealtime : () => { this.stream4Pad(SocketAction.cvSendCmd, cvStopRealtime()); }}
                        setVisionTagSetting={this.props.setVisionTagSetting ? this.props.setVisionTagSetting : (list) => { this.stream4Pad(SocketAction.cvSendCmd, cvSetTags(list)); }}
                        getVisionTagSetting={this.props.getVisionTagSetting ? this.props.getVisionTagSetting : () => { this.stream4Pad(SocketAction.cvSendCmd, cvGetTags()); }}
                        selectVisionColor={this.props.selectVisionColor ? this.props.selectVisionColor : (x, y, width, height) => { this.stream4Pad(SocketAction.cvSendCmd, cvSelectColor([x, y, width, height])); }}
                        setCvOCRLanguage={this.props.setCvOCRLanguage ? this.props.setCvOCRLanguage : (langlist) => { this.stream4Pad(SocketAction.cvSendCmd, cvSetOCRLanguage(langlist)); }}
                        takeVisionSnapshot={this.props.takeVisionSnapshot ? this.props.takeVisionSnapshot : () => { this.stream4Pad(SocketAction.cvSendCmd, cvSnapshot()); }}
                        enrollVisionProfileList={this.props.enrollVisionProfileList ? this.props.enrollVisionProfileList : (profileList) => { this.stream4Pad(SocketAction.cvSendCmd, cvLoadFaceData(profileList)); }}
                        getVisionFaceRecongResult={this.props.getVisionFaceRecongResult ? this.props.getVisionFaceRecongResult : () => { this.stream4Pad(SocketAction.cvSendCmd, cvGetFaceRecogResult()); }}
                        getVisionFaceFeature={this.props.getVisionFaceFeature ? this.props.getVisionFaceFeature : () => { this.stream4Pad(SocketAction.cvSendCmd, cvGetFaceFeature()); }}
                        uploadVisionImage={this.props.uploadVisionImage ? this.props.uploadVisionImage : () => { this.stream4Pad(SocketAction.downloadcvPic); }}
                        getVisionOcrResult={this.props.getVisionOcrResult ? this.props.getVisionOcrResult : (lang) => { this.stream4Pad(SocketAction.cvSendCmd, cvGetOCRResult(lang)); }}
                        getQrCodeResult={this.props.getQrCodeResult ? this.props.getQrCodeResult : () => { this.stream4Pad(SocketAction.cvSendCmd, cvgetQRCodeMsg()); }}
                    />
                    {this.props.isSpeakerSkillDialogShow ?
                        <SpeakerSkillDialog
                            vm={vm}
                            show={this.props.isSpeakerSkillDialogShow}
                        />
                        : null}
                    {this.props.isSpeakerCustomizingDialogShow ?
                        <SpeakerCustomizingDialog
                            vm={vm}
                            show={this.props.isSpeakerCustomizingDialogShow}
                            action={this.props.getCustomizingAction}
                            intentId={this.props.getEditIntentId}
                        />
                        : null}
                    {this.props.isSpeakerConceptDialogShow ?
                        <SpeakerConceptDialog
                            vm={vm}
                            show={this.props.isSpeakerConceptDialogShow}
                            action={this.props.getConceptAction}
                        /> : null}
                    <UpdateRCDialog
                        show={this.props.isUpdateRCTutorialDialogShow}
                        onClose={() => this.props.hideUpdateRCTutorialDialog()}
                        updateFirmware={() => this.onUpdateRCFirmware()}
                    />

                    {isShowCloseAppDialog ?
                        <QuestionDialog show={isShowCloseAppDialog}
                            type={questionType.QUESTION_CLOSE_APP_SAVE_FILE}
                            onNoSave={onExit}
                            onSave={() => this.handleQuestionSave(questionType.QUESTION_CLOSE_APP_SAVE_FILE)} />
                        : null}

                    {isShowImportDuplicatedDialog ?
                        <QuestionDialog show={isShowImportDuplicatedDialog}
                            type={questionType.QUESTION_IMPORT_CUSTOMIZING_DUPLICATED}
                            onImportDuplicated={() => {
                                this.onImportDuplicated();
                            }}
                            onClose={() => {
                                this.props.clearSortedConcept();
                                this.props.clearSortedSentence();
                                this.props.clearImportSentence();
                                this.props.clearImportConcepts();
                            }}
                        />
                        : null}

                    {isShowImportConceptDuplicatedDialog ?
                        <QuestionDialog show={isShowImportConceptDuplicatedDialog}
                            type={questionType.QUESTION_IMPORT_CONCEPT_DUPLICATED}
                            onImportDuplicated={() => {
                                this.onImportDuplicated();
                            }}
                            onClose={() => {
                                this.props.clearSortedConcept();
                                this.props.clearSortedSentence();
                                this.props.clearImportSentence();
                                this.props.clearImportConcepts();
                            }}
                        />
                        : null}

                    {/* {isShowImportUselessDialog ?
                        <QuestionDialog show={isShowImportUselessDialog}
                            type={questionType.QUESTION_IMPORT_CUSTOMIZING_USELESS}
                            onImportUseless={() => {
                                this.onImportUseless();
                            }}
                            onImportUsingOnly={() => {
                                this.onImportUsingOnly();
                            }}
                        />
                        : null} */}

                    {this.props.isShowRemoveCustomizingBlockDialog ?
                        <QuestionDialog show={this.props.isShowRemoveCustomizingBlockDialog}
                            type={questionType.QUESTION_REMOVE_CUSTOMIZING_BLOCK}
                            onDelete={() => { }} />
                        : null}
                    <PadFileOpenDialog />
                    <PadFileSaveDialog getFileDataForPad={() => this.getFileDataForPad()} vm={vm} />
                    {isAddAISpeechGroupDialogShow ?
                        <NewGroupDialog
                            show={isAddAISpeechGroupDialogShow}
                            type={aiSpeechGroupType.NEW_GROUP}
                            vm={vm}
                            onClose={() => this.props.hideAddAISpeechGroupDialog()}
                            onUpdate={(groupName) => this.handleGroupUpdate(groupName, aiSpeechGroupType.NEW_GROUP)}
                        />
                        : null}
                    {isEditAISpeechGroupDialogShow ?
                        <NewGroupDialog
                            show={isEditAISpeechGroupDialogShow}
                            type={aiSpeechGroupType.EDIT_GROUP}
                            vm={vm}
                            onClose={() => this.props.hideEditAISpeechGroupDialog()}
                            onUpdate={(groupName) => this.handleGroupUpdate(groupName, aiSpeechGroupType.EDIT_GROUP)}
                        />
                        : null}
                    {isQuestionDeleteAISpeechGroupDialogShow ?
                        <QuestionDialog
                            show={isQuestionDeleteAISpeechGroupDialogShow}
                            type={questionType.QUESTION_DELETE_AI_SPEECH_GROUP}
                            aiSpeechGroup={this.props.aiSpeechDeleteGroupData}
                            onDelete={() => this.handleAISpeechDeleteGroup()}
                            onClose={() => this.props.hideQuestionDeleteAISpeechGroupDialog()}
                        />
                        : null}
                    {isQuestionDeleteSpeakerIntentArrayDialogShow ?
                        <QuestionDialog
                            show={isQuestionDeleteSpeakerIntentArrayDialogShow}
                            type={questionType.QUESTION_DELETE_SPEAKER_INTENT_ARRAY}
                            onDelete={() => this.handleAISpeechDeleteIntentArray()}
                            onClose={() => this.props.hideQuestionDeleteSpeakerIntentArrayDialog()}
                        />
                        : null}
                    {isShowVRCloseFullscreenDialog ?
                        <QuestionDialog
                            show={isShowVRCloseFullscreenDialog}
                            type={questionType.QUESTION_VR_CLOSE_FULLSCREEN}
                        />
                        : null}
                    {this.props.showLoading ?
                        <AudioLoadingDialog
                            show={this.props.showLoading}
                            onClose={() => { }}
                            percentage={this.props.loadingPercentage}
                            loadingType={this.props.loadingType} />
                        : null}
                    {(this.props.getUIStyle == uiType.vr) ? null :
                        <BoardGameDialog
                            vm={vm}
                            show={isBoardGameDialogShow}
                            onUpdateProjectTitle={onUpdateProjectTitle}
                            onClose={() => this.props.hideBoardGameDialog()}
                            onSave={this.onMenuBarClickSave}
                        />
                    }
                    {isVRRemindDialogShow ?
                        <QuestionDialog
                            show={isVRRemindDialogShow}
                            type={questionType.QUESTION_VR_REMIND} />
                        : null}
                    <WifiDirectionDialog />
                    <WifiIpSettingDialog
                        connectApPort={this.props.connectApPort} />
                    <WifiiPadSettingDialog
                        connectApPort={this.props.connectApPort}
                        stopListenApBroadcast={this.props.stopListenApBroadcast}
                        scanWifiApBrainList={this.props.scanWifiApBrainList}
                        setWifiAp={this.props.setWifiAp}
                        onClickSelectWifi={onClickSelectWifi}
                    />
                    {isStreamingDialogShow ?
                        <StreamingDialog
                            vm={vm}
                            initVision={this.props.initVision ? this.props.initVision : this.initVision}
                            wifiGetScreenText={this.props.wifiGetScreenText ? this.props.wifiGetScreenText : () => { this.stream4Pad(SocketAction.getScreenText); }}
                            wifiGetDevStatus={this.props.wifiGetDevStatus ? this.props.wifiGetDevStatus : () => { this.stream4Pad(SocketAction.getDevStatus); }}
                            wifiGetCtrlerStatus={this.props.wifiGetCtrlerStatus ? this.props.wifiGetCtrlerStatus : () => { this.stream4Pad(SocketAction.getCtrlerStatus); }}
                            wifiStartCtrlerReport={this.props.wifiStartCtrlerReport ? this.props.wifiStartCtrlerReport : () => { this.stream4Pad(SocketAction.startCtrlerReport); }}
                            wifiStopCtrlerReport={this.props.wifiStopCtrlerReport ? this.props.wifiStopCtrlerReport : () => { this.stream4Pad(SocketAction.stopCtrlerReport); }}
                            wifiStartStreaming={this.props.wifiStartStreaming ? this.props.wifiStartStreaming : () => { }}
                            wifiStopStreaming={this.props.wifiStopStreaming ? this.props.wifiStopStreaming : () => { }}
                            screenshotWifiVision={this.props.screenshotWifiVision ? this.props.screenshotWifiVision : this.screenshotVision2Pad}
                            saveRecordingVideo={this.props.saveRecordingVideo ? this.props.saveRecordingVideo : () => { }}
                            startVisionRealtime={this.props.startVisionRealtime ? this.props.startVisionRealtime : () => { this.stream4Pad(SocketAction.cvSendCmd, cvStartRealtime()); }}
                            stopVisionRealtime={this.props.stopVisionRealtime ? this.props.stopVisionRealtime : () => { this.stream4Pad(SocketAction.cvSendCmd, cvStopRealtime()); }}
                            removeTempRecodingVideo={this.props.removeTempRecodingVideo ? this.props.removeTempRecodingVideo : () => { }}
                            getWifiSignal={onClickWifiStatus ? onClickWifiStatus : () => { this.stream4Pad(SocketAction.getWifiStatus); }}
                        />
                        : null}
                    {(isPad()) ? <PadReceiver
                        setVisionTagSetting={(list) => { this.stream4Pad(SocketAction.cvSendCmd, cvSetTags(list)); }} /> : null}

                    {(this.props.getUIStyle == uiType.vr ? <BowserUnsupportedDialog
                        locale={this.props.locale}
                    /> : null)}
                </Box >
            )
        }
        }</MediaQuery >);
    }
}

GUIComponent.propTypes = {
    locale: PropTypes.string.isRequired,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // can be false
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // can be false
    basePath: PropTypes.string,
    blocksTabVisible: PropTypes.bool,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canSave: PropTypes.bool,
    canUseCloud: PropTypes.bool,
    intl: intlShape.isRequired,
    isCreating: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    isPlayerOnly: PropTypes.bool,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    onActivateSoundsTab: PropTypes.func,
    onActivateTab: PropTypes.func,
    getRefinedScript: PropTypes.func,
    onClickBrowser: PropTypes.func,
    onRunDriver: PropTypes.func,
    onClickSetWriteSlotNumber: PropTypes.func,
    onClickSlotInfo: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onTelemetryModalCancel: PropTypes.func,
    onTelemetryModalOptIn: PropTypes.func,
    onTelemetryModalOptOut: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    showComingSoon: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    stageTypeMode: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_TYPE)),
    exampleLibraryVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    isShowCloseAppDialog: PropTypes.bool,
    isShowAboutDialog: PropTypes.bool,
    isShowUpdateDialog: PropTypes.bool,
    isShowSwitchBrainModeInBTModeDialog: PropTypes.bool,
    isShowSwitchBrainModeInRFIDModeDialog: PropTypes.bool,
    isShowDownloadFailDialog: PropTypes.bool,
    isShowInstallFailDialog: PropTypes.bool,
    isShowOpenNewDialog: PropTypes.bool,
    isShowSaveNewDialog: PropTypes.bool,
    isShowSaveOpenDialog: PropTypes.bool,
    isShowSaveRecentDialog: PropTypes.bool,
    isShowForceUpdateErrorDialog: PropTypes.bool,
    isShowBlockLimitErrorDialog: PropTypes.bool,
    isShowBrainTypeErrorDialog: PropTypes.bool,
    isShowBatteryLowPowerErrorDialog: PropTypes.bool,
    isShowBatteryConnectedErrorDialog: PropTypes.bool,
    isShowDownloadBrainScriptTimeoutErrorDialog: PropTypes.bool,
    isShowInstallDriverDialog: PropTypes.bool,
    isShowFirmwareDialog: PropTypes.bool,
    isShowVRCloseFullscreenDialog: PropTypes.bool,
    onRequestCloseCloseApp: PropTypes.func,
    onRequestCloseAbout: PropTypes.func,
    onRequestCloseUpdate: PropTypes.func,
    onRequestCloseLicense: PropTypes.func,
    onRequestCloseHistory: PropTypes.func,
    onRequestCloseDownloadFail: PropTypes.func,
    onRequestCloseSaveNew: PropTypes.func,
    onRequestCloseSaveOpen: PropTypes.func,
    onRequestCloseSaveOpenWeb: PropTypes.func,
    onRequestCloseSaveRecent: PropTypes.func,
    isShowStartAppBrainTypePicker: PropTypes.bool,
    isShowNewProjectBrainTypePicker: PropTypes.bool,
    onShowNewProjectBrainTypePicker: PropTypes.func,
    onRequestCloseStartAppBrainTypePicker: PropTypes.func,
    onRequestCloseNewProjectBrainTypePicker: PropTypes.func,
    onOpenFileClick: PropTypes.func,
    onImportSentencesClick: PropTypes.func,
    onOpenRecentFileClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onOutputPDFClick: PropTypes.func,
    onWriteScriptClick: PropTypes.func,
    onRunScriptClick: PropTypes.func,
    onStopScriptClick: PropTypes.func,
    onRestoreClick: PropTypes.func,
    onClickWifiStatus: PropTypes.func,
    onClickSwitchWifi: PropTypes.func,
    onClickWifiList: PropTypes.func,
    onClickAddWifi: PropTypes.func,
    onClickDeleteWifi: PropTypes.func,
    onClickSelectWifi: PropTypes.func,
    onClickWifiScan: PropTypes.func,
    outputLog: PropTypes.object,
    hasMaximizedComment: PropTypes.bool,
    onSoftwareUpdate: PropTypes.func,
    onRestart: PropTypes.func,
    onExit: PropTypes.func,
    onClickNew: PropTypes.func,
    saveProjectSb3: PropTypes.func,
    projectFilename: PropTypes.string,
    projectChanged: PropTypes.bool,
    setDialogFeedback: PropTypes.func,
    getDialogFeedback: PropTypes.bool,
    connectComPortPath: PropTypes.func,
    isLinkVersionMatch: PropTypes.func,
    isBluetoothVersionMatch: PropTypes.func,
    checkUpdatingTask: PropTypes.func,
    excuteQueueTask: PropTypes.func,
    setPickedBrainToDesktop: PropTypes.func,
    getWorkspace: PropTypes.object,
    updateFlyoutVisibleState: PropTypes.func,
    updateToolboxVisibleState: PropTypes.func,
    onRequestCloseErrorDialog: PropTypes.func,
    isShowProjectNameDialog: PropTypes.bool,
    isShowLicneseDialog: PropTypes.bool,
    isShowHistoryDialog: PropTypes.bool,
    onRequestCloseProjectName: PropTypes.func,
    handleEraseSlot: PropTypes.func,
    handleResetToDefault: PropTypes.func,
    onRequestCloseAlertDialog: PropTypes.func,
    isAlertDialogShow: PropTypes.bool,
    onRequestCloseWhatsNewDialog: PropTypes.func,
    isWhatsNewDialogShow: PropTypes.bool,
    setProjectUnchanged: PropTypes.func,
    setProjectUnchanged: PropTypes.func,
    isQuestionDeleteSlotDialogShow: PropTypes.bool,
    setProjectNew: PropTypes.func,
    setProjectTitle: PropTypes.func,
    setProjectPreTitle: PropTypes.func,
    checkBatteryConnected: PropTypes.func,
    checkBluetoothStatus: PropTypes.func,
    onCloseBrowserVideoWindow: PropTypes.func,
    isShowSaveUpdateSoftwareDialog: PropTypes.bool,
    onRequestCloseUpdateSoftware: PropTypes.func,
    setEditorDisplayMode: PropTypes.func,
    editorDisplayMode: PropTypes.string,
    isShowOpenEditorDialog: PropTypes.bool,
    isShowOpenEditorSaveDialog: PropTypes.bool,
    setCodeView: PropTypes.func,
    isShowRemindFirmwareUpdateDialog: PropTypes.bool,
    isShowRemindBluetoothUpdateDialog: PropTypes.bool,
    onShowRemindFirmwareUpdateDialog: PropTypes.func,
    onShowRemindBluetoothUpdateDialog: PropTypes.func,
    isShowFileExtensionNotMatchDialog: PropTypes.bool,
    getDirectNew: PropTypes.object,
    isShowDirectNewDialog: PropTypes.bool,
    isShowDirectNewSaveDialog: PropTypes.bool,
    getUIStyle: PropTypes.string,
    setBrainDownloadType: PropTypes.func,
    setUIStyle: PropTypes.func,
    setEnableConfigEntry: PropTypes.func,
    setEnableConfigEduAndEntry: PropTypes.func,
    initVision: PropTypes.func,
    closeVision: PropTypes.func,
    startVisionRealtime: PropTypes.func,
    setVisionRealtimeMode: PropTypes.func,
    stopVisionRealtime: PropTypes.func,
    setVisionTagSetting: PropTypes.func,
    getVisionTagSetting: PropTypes.func,
    selectVisionColor: PropTypes.func,
    isCheckingImportDevice: PropTypes.bool,
    onRequestCloseEditorSaveDialog: PropTypes.func,
    brainDownloadType: PropTypes.string,
    setBrainDownloadAndRun: PropTypes.func,
    setProjectTitleupdateTitle: PropTypes.func,
    projectTitleupdateTitle: PropTypes.bool,
    setVRSaveFile: PropTypes.func,
    getUnityMessageFunc: PropTypes.func,
    getVRSaveFile: PropTypes.bool,
    getVRUnityContextLoadingProgress: PropTypes.number,
    isQuestionDeleteAISpeechGroupDialogShow: PropTypes.bool,
    setTestVersion: PropTypes.func,
    getTestVersion: PropTypes.bool,
    selectLocale: PropTypes.func,
    getVRLangChange: PropTypes.bool,
    setSaveCancel: PropTypes.func,
    isSaveCancel: PropTypes.bool,
    showVRRemindDialog: PropTypes.func,
    hideVRRemindDialog: PropTypes.func,
    isVRRemindDialogShow: PropTypes.bool,
    isProjectNameDialogShow: PropTypes.bool,
    projectPreTitle: PropTypes.string,
    setProjectTitleChanged: PropTypes.func,
    hideProjectNameDialog: PropTypes.func,
    setVRUnityContextLoadingProgress: PropTypes.func,
    getSelectedSlot: PropTypes.number,
    isVisionConnected: PropTypes.bool,
    isVisionInitiated: PropTypes.bool,
    resetVisionInitState: PropTypes.func,
    setVisionInitState: PropTypes.func,
};

GUIComponent.defaultProps = {
    basePath: './',
    canChangeLanguage: true,
    canCreateNew: false,
    canEditTitle: false,
    canSave: false,
    canCreateCopy: false,
    canUseCloud: false,
    isCreating: false,
    isShared: false,
    onUpdateProjectTitle: () => { },
    setVisionRealtimeMode: () => { },
    showComingSoon: false,
    stageSizeMode: STAGE_SIZE_MODES.large,
    isShowAboutDialog: false,
    isShowUpdateDialog: false,
    isShowFirmwareDialog: false,
    hasMaximizedComment: false,
    isShowStartAppBrainTypePicker: false,
};

const mapStateToProps = state => ({
    // This is the button's mode, as opposed to the actual current state
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    stageTypeMode: state.scratchGui.stageSize.stageType,
    locale: state.locales.locale,
    isShowCloseAppDialog: isCloseAppDialogShow(state),
    isShowAboutDialog: aboutDialogShow(state),
    isShowUpdateDialog: updateDialogShow(state),
    isShowSwitchBrainModeInBTModeDialog: quesitonDialogShow(state, questionType.QUESTION_SWITCH_BRAIN_MODE_IN_BT_MODE),
    isShowSwitchBrainModeInRFIDModeDialog: quesitonDialogShow(state, questionType.QUESTION_SWITCH_BRAIN_MODE_IN_RFID_MODE),
    isShowDownloadFailDialog: quesitonDialogShow(state, questionType.QUESTION_DOWNLOAD_FAIL),
    isShowInstallFailDialog: quesitonDialogShow(state, questionType.QUESTION_INSTALL_FAIL),
    isShowOpenNewDialog: quesitonDialogShow(state, questionType.QUESTION_OPEN_NEW),
    isShowSaveNewDialog: quesitonDialogShow(state, questionType.QUESTION_SAVE_NEW),
    isShowSaveOpenDialog: quesitonDialogShow(state, questionType.QUESTION_SAVE_OPEN),
    isShowSaveNewWebDialog: quesitonDialogShow(state, questionType.QUESTION_SAVE_NEW_WEB),
    isShowSaveOpenWebDialog: quesitonDialogShow(state, questionType.QUESTION_SAVE_OPEN_WEB),
    isShowSaveRecentDialog: quesitonDialogShow(state, questionType.QUESTION_SAVE_RECENT),
    isShowStartAppBrainTypePicker: brainTypePickerShow(state, pickerType.PICKER_START_APP),
    isShowNewProjectBrainTypePicker: brainTypePickerShow(state, pickerType.PICKER_NEW_PROJECT),
    isShowForceUpdateErrorDialog: quesitonDialogShow(state, questionType.QUESTION_FORCE_UPDATE),
    isShowBlockLimitErrorDialog: quesitonDialogShow(state, questionType.QUESTION_BLOCK_LIMIT),
    isShowBrainTypeErrorDialog: quesitonDialogShow(state, questionType.QUESTION_BRAIN_TYPE),
    isShowBatteryLowPowerErrorDialog: quesitonDialogShow(state, questionType.QUESTION_BATTERY_LOW_POWER),
    isShowBatteryConnectedErrorDialog: quesitonDialogShow(state, questionType.QUESTION_BATTERY_DISCONNECTED),
    isShowDownloadBrainScriptTimeoutErrorDialog: quesitonDialogShow(state, questionType.QUESTION_DOWNLOAD_BRAIN_SCRIPT_TIMEOUT),
    isShowInstallDriverDialog: quesitonDialogShow(state, questionType.QUESTION_INSTALL_DRIVER),
    isShowResetBrainFirmwareDialog: quesitonDialogShow(state, questionType.QUESTION_RESET_BRAIN_FIRMWARE),
    isShowImportDuplicatedDialog: quesitonDialogShow(state, questionType.QUESTION_IMPORT_CUSTOMIZING_DUPLICATED),
    isShowImportConceptDuplicatedDialog: quesitonDialogShow(state, questionType.QUESTION_IMPORT_CONCEPT_DUPLICATED),
    // isShowImportUselessDialog: quesitonDialogShow(state, questionType.QUESTION_IMPORT_CUSTOMIZING_USELESS),
    isShowRemoveCustomizingBlockDialog: quesitonDialogShow(state, questionType.QUESTION_REMOVE_CUSTOMIZING_BLOCK),
    isShowVRCloseFullscreenDialog: quesitonDialogShow(state, questionType.QUESTION_VR_CLOSE_FULLSCREEN),
    hasMaximizedComment: state.scratchGui.toolbox.hasMaximizedComment,
    getProjectJson: state.scratchGui.vm.getProjectJson.bind(state.scratchGui.vm),
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    saveProjectSb3ForRN: state.scratchGui.vm.saveProjectSb3ForRN.bind(state.scratchGui.vm),
    projectFilename: projectTitle(state),
    projectChanged: state.scratchGui.projectChanged,
    getDialogFeedback: getFeedback(state),
    isShowFirmwareDialog: firmwarmUpdateDialogShow(state),
    isDeviceHelpDialogShow: isDeviceHelpDialogShow(state),
    isHintBlockHelpDialogShow: isHintBlockHelpDialogShow(state),
    isBlockHelpDialogShow: isBlockHelpDialogShow(state),
    isBrainConnect: getBrainConnectStatus(state),
    isBrainDownloading: getBrainDownloadingStatus(state),
    isBrainSwitching: getBrainBrainSwitchingStatus(state),
    isBrainLoading: getBrainLoadingStatus(state),
    isBrainFWUpdating: getBrainFWUpdatingStatus(state),
    isBrainScriptErasing: getBrainScriptErasingStatus(state),
    isAddAISpeechGroupDialogShow: isAddAISpeechGroupDialogShow(state),
    isEditAISpeechGroupDialogShow: isEditAISpeechGroupDialogShow(state),
    aiSpeechEditGroupData: getEditAISpeechGroupData(state),
    isQuestionDeleteAISpeechGroupDialogShow: isQuestionDeleteAISpeechGroupDialogShow(state),
    isQuestionDeleteSpeakerIntentArrayDialogShow: isQuestionDeleteSpeakerIntentArrayDialogShow(state),
    aiSpeechDeleteGroupData: getQuestionDeleteAISpeechGroupData(state),
    aiSpeechDeleteAISpeechGroupCallback: getQuestionDeleteAISpeechGroupCallback(state),
    aiSpeechDeleteSpeakerIntentArrayCallback: getQuestionDeleteSpeakerIntentArrayCallback(state),
    brainInfo: getBrainInfo(state),
    getAllPortInfo: getAllPortInfo(state),
    batteryConnectStatus: getBatteryConnectStatus(state),
    hasMaximizedComment: state.scratchGui.toolbox.hasMaximizedComment,
    brainVer: getBrainVer(state, 'current'),
    getPickedBrainType: getPickedBrainType(state),
    getWorkspace: getWorkspace(state),
    shouldShowUserGuide: shouldShowUserGuide(state),
    isShowUserGuide: isShowUserGuide(state),
    userGuideCurrentState: getUserGuideCurrentState(state),
    isShowProjectNameDialog: quesitonDialogShow(state, questionType.QUESTION_SAVE_PROJECTNAME),
    isShowLicneseDialog: licenseDialogShow(state),
    isShowHistoryDialog: historyDialogShow(state),
    isAlertDialogShow: isAlertDialogShow(state),
    isWhatsNewDialogShow: isWhatsNewDialogShow(state),
    isQuestionDeleteSlotDialogShow: isQuestionDeleteSlotDialogShow(state),
    isQuestionUpdateSoftwareDialogShow: quesitonDialogShow(state, questionType.QUESTION_UPDATE_SOFTWARE_FIRST),
    isQuestionUpdateFirmwareDialogShow: quesitonDialogShow(state, questionType.QUESTION_UPDATE_FIRMWARE_FIRST),
    isQuestionForceUpdateSoftwareDialogShow: quesitonDialogShow(state, questionType.QUESTION_FORCE_UPDATE_SOFTWARE),
    isQuestionForceUpdateFirmwareDialogShow: quesitonDialogShow(state, questionType.QUESTION_FORCE_UPDATE_FIRMWARE),
    isQuestionForceUpdateFirmwareAndBtDialogShow: quesitonDialogShow(state, questionType.QUESTION_FORCE_UPDATE_BT_AND_BRAIN),
    isQuestionUpdateFirmwareAndBtDialogShow: quesitonDialogShow(state, questionType.QUESTION_UPDATE_BT_AND_BRAIN_FIRST),
    isQuestionUpdateBtDialogShow: quesitonDialogShow(state, questionType.QUESTION_UPDATE_BT_FIRMWARE_FIRST),
    isQuestionLoaderModeDialogShow: quesitonDialogShow(state, questionType.QUESTION_LOADER_MODE),
    isQuestionDeleteController1Show: quesitonDialogShow(state, questionType.QUESTION_DELETE_CONTROLLER_1),
    slotIndex: getQuestionDeleteSlotIndex(state),
    getSlotList: getSlotList(state),
    isShowSaveUpdateSoftwareDialog: quesitonDialogShow(state, questionType.QUESTION_SAVE_UPDATE_SOFTWARE),
    editorDisplayMode: getEditorDisplayMode(state),
    isShowOpenEditorDialog: quesitonDialogShow(state, questionType.QUESTION_OPEN_EDITOR),
    isShowOpenEditorSaveDialog: quesitonDialogShow(state, questionType.QUESTION_OPEN_EDITOR_SAVE),
    isShowRemindFirmwareUpdateDialog: quesitonDialogShow(state, questionType.QUESTION_REMIND_FIRMWARE_UPDATE),
    isShowRemindBluetoothUpdateDialog: quesitonDialogShow(state, questionType.QUESTION_REMIND_BLUETOOTH_UPDATE),
    isShowFileExtensionNotMatchDialog: quesitonDialogShow(state, questionType.QUESTION_FILE_EXTENSION_NOT_MATCH),
    isWifiSettingDialogShow: isWifiSettingDialogShow(state),
    isVisionTutorialDialogShow: isVisionTutorialDialogShow(state),
    isVisionSettingDialogShow: isVisionSettingDialogShow(state),
    isSpeakerSkillDialogShow: isSpeakerSkillDialogShow(state),
    isSpeakerCustomizingDialogShow: isSpeakerCustomizingDialogShow(state),
    isSpeakerConceptDialogShow: isSpeakerConceptDialogShow(state),
    getDirectNew: getDirectNew(state),
    isShowDirectNewDialog: quesitonDialogShow(state, questionType.QUESTION_DIRECT_NEW),
    isShowDirectNewSaveDialog: quesitonDialogShow(state, questionType.QUESTION_DIRECT_NEW_SAVE),
    getUIStyle: getUIStyle(state),
    isEnableWebVR: isEnableWebVR(state),
    isCheckingImportDevice: isCheckingImportDevice(state),
    getRealEntryDeviceList: getRealEntryDeviceList(state),
    isShowReplaceDeviceDialog: quesitonDialogShow(state, questionType.QUESTION_REPLACE_DEVICE),
    isUpdateRCTutorialDialogShow: isUpdateRCTutorialDialogShow(state),
    controllerInfo: getControllerInfo(state),
    isRCFirmwareUpdating: isRCFirmwareUpdating(state),
    firmwareConfig: getFirmwareConfig(state),
    brainDownloadType: getBrainDownloadType(state),
    projectTitleupdateTitle: projectTitleupdateTitle(state),
    isPadFileOpenDialogShow: isPadFileOpenDialogShow(state),
    isPadFileSaveDialogShow: isPadFileSaveDialogShow(state),
    getConceptAction: getConceptAction(state),
    getUnityMessageFunc: getUnityMessageFunc(state),
    getVRSaveFile: getVRSaveFile(state),
    getVRUnityContextLoadingProgress: getVRUnityContextLoadingProgress(state),
    getCustomizingAction: getCustomizingAction(state),
    getEditIntentId: getEditIntentId(state),
    isSentenceImported: isSentenceImported(state),
    isConceptsImported: isConceptsImported(state),
    getImportSentence: getImportSentence(state),
    getImportConcepts: getImportConcepts(state),
    getSortedConcept: getSortedConcept(state),
    getSortedSentence: getSortedSentence(state),
    getTestVersion: getTestVersion(state),
    getVRLangChange: getVRLangChange(state),
    isSaveCancel: isSaveCancel(state),
    showLoading: showLoading(state),
    loadingPercentage: getLoadingPercentage(state),
    loadingType: getLoadingType(state),
    isBoardGameDialogShow: isBoardGameDialogShow(state),
    isFileProcessing: isFileProcessing(state),
    isVRRemindDialogShow: isVRRemindDialogShow(state),
    isStreamingDialogShow: isStreamingDialogShow(state),
    isProjectNameDialogShow: quesitonDialogShow(state, questionType.QUESTION_SAVE_PROJECTNAME),
    projectPreTitle: projectPreTitle(state),
    getSelectedSlot: getSelectedSlot(state),
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state) && isVisionRecongInitiated(state),
    isStreamingDialogShow: isStreamingDialogShow(state),
});

const mapDispatchToProps = dispatch => ({
    onRequestCloseCloseApp: () => dispatch(hideCloseAppDialog()),
    onRequestCloseAbout: () => dispatch(hideAboutDialog()),
    onRequestCloseUpdate: () => dispatch(hideUpdateDialog()),
    onRequestCloseDownloadFail: () => dispatch(hideQuestionDialog(questionType.QUESTION_DOWNLOAD_FAIL)),
    onRequestCloseInstallDriver: () => dispatch(hideQuestionDialog(questionType.QUESTION_INSTALL_DRIVER)),
    onRequestCloseSaveNew: () => dispatch(hideQuestionDialog(questionType.QUESTION_SAVE_NEW)),
    onRequestCloseSaveOpen: () => dispatch(hideQuestionDialog(questionType.QUESTION_SAVE_OPEN)),
    onRequestCloseSaveOpenWeb: () => dispatch(hideQuestionDialog(questionType.QUESTION_SAVE_OPEN_WEB)),
    onRequestCloseSaveRecent: () => dispatch(hideQuestionDialog(questionType.QUESTION_SAVE_RECENT)),
    onRequestCloseForceUpdateError: () => dispatch(hideQuestionDialog(questionType.QUESTION_FORCE_UPDATE)),
    onRequestCloseBlockLimitError: () => dispatch(hideQuestionDialog(questionType.QUESTION_BLOCK_LIMIT)),
    onRequestCloseBrainTypeError: () => dispatch(hideQuestionDialog(questionType.QUESTION_BRAIN_TYPE)),
    onRequestCloseBatteryLowPowerError: () => dispatch(hideQuestionDialog(questionType.QUESTION_BATTERY_LOW_POWER)),
    onRequestCloseBatteryDisconnectedError: () => dispatch(hideQuestionDialog(questionType.QUESTION_BATTERY_DISCONNECTED)),
    onRequestCloseProjectName: () => dispatch(hideQuestionDialog(questionType.QUESTION_SAVE_PROJECTNAME)),
    onRequestCloseUpdateSoftware: () => dispatch(hideQuestionDialog(questionType.QUESTION_SAVE_UPDATE_SOFTWARE)),
    onRequestCloseResetBrainFirmwareDialog: () => dispatch(hideQuestionDialog(questionType.QUESTION_RESET_BRAIN_FIRMWARE)),
    onRequestCloseFirmware: () => dispatch(hideFirmwarmUpdateDialog()),
    onRequestCloseLicense: () => dispatch(hideLicenseDialog()),
    onRequestCloseHistory: () => dispatch(hideHistoryDialog()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    setDialogFeedback: status => dispatch(setFeedback(status)),
    onShowStartAppBrainTypePicker: () => dispatch(showBrainTypePicker(pickerType.PICKER_START_APP)),
    onShowNewProjectBrainTypePicker: () => dispatch(showBrainTypePicker(pickerType.PICKER_NEW_PROJECT)),
    onRequestCloseStartAppBrainTypePicker: () => dispatch(hideBrainTypePicker(pickerType.PICKER_START_APP)),
    onRequestCloseNewProjectBrainTypePicker: () => dispatch(hideBrainTypePicker(pickerType.PICKER_NEW_PROJECT)),
    onRequestCloseDeviceHelp: () => dispatch(hideDeviceHelpDialog()),
    onRequestCloseHintBlockHelp: () => {
        dispatch(hideHintBlockHelpDialog());
        dispatch(showBlockHelpDialog(getPlatform()));
    },
    onRequestOpenBlockHelp: () => dispatch(showBlockHelpDialog(getPlatform())),
    onRequestCloseBlockHelp: () => dispatch(hideBlockHelpDialog()),
    onRequestCloseAlertDialog: () => dispatch(hideAlertDialog()),
    onRequestCloseWhatsNewDialog: () => dispatch(hideWhatsNewDialog()),
    onShowQuestionDownloadFail: () => dispatch(showQuestionDialog(questionType.QUESTION_DOWNLOAD_FAIL)),
    onShowQuestionForceUpdateDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_FORCE_UPDATE)),
    onShowQuestionBlockLimitDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_BLOCK_LIMIT)),
    onShowQuestionBrainTypeDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_BRAIN_TYPE)),
    onShowQuestionBatteryLowPowerDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_BATTERY_LOW_POWER)),
    onShowQuestionBatteryDisconnectedDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_BATTERY_DISCONNECTED)),
    onShowQuestionDownloadBrainScriptTimeoutDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_DOWNLOAD_BRAIN_SCRIPT_TIMEOUT)),
    onShowQuestionEraseBrainScriptTimeoutDialog: (msg) => dispatch(showErrorDialog(errorType.ERASE_SLOT_FAILED, msg)),
    onShowErrorDialog: (errorType, msg) => dispatch(showErrorDialog(errorType, msg)),
    showSpeakerConceptDialog: () => dispatch(showSpeakerConceptDialog()),
    updateFlyoutVisibleState: isFlyoutVisible => dispatch(updateFlyoutVisible(isFlyoutVisible)),
    updateToolboxVisibleState: isToolboxVisible => dispatch(updateToolboxVisible(isToolboxVisible)),
    onRequestCloseErrorDialog: () => dispatch(hideErrorDialog()),
    onUpdateShouldShowUserGuide: show => dispatch(updateShouldShowUserGuide(show)),
    onUpdateShowUserGuide: show => dispatch(updateShowUserGuide(show)),
    onUpdateUserGuideState: state => dispatch(updateUserGuideState(state)),
    setBrainDownloadingStatus: (dwonloadingStatus) => dispatch(setBrainDownloadingStatus(dwonloadingStatus)),
    setBrainSwitchingStatus: (status) => dispatch(setBrainSwitchingStatus(status)),
    setBrainScriptErasingStatus: status => dispatch(setBrainScriptErasingStatus(status)),
    setProjectUnchanged: () => dispatch(setProjectUnchanged()),
    setProjectTitleisSave: (isSave) => dispatch(setProjectTitleisSave(isSave)),
    hideQuestionDeleteSlotDialog: () => dispatch(hideQuestionDeleteSlotDialog()),
    setSlotList: (list) => dispatch(setSlotList(list)),
    selectSlot: (index) => dispatch(selectSlot(index)),
    setProjectNew: isNew => dispatch(setProjectNew(isNew)),
    setProjectTitle: title => dispatch(setProjectTitle(title)),
    setProjectPreTitle: title => dispatch(setProjectPreTitle(title)),
    cleanTask: () => dispatch(cleanTask()),
    setBatteryConnectStatus: status => dispatch(setBatteryConnectStatus(status)),
    showQuestionDialog: (dialog) => dispatch(showQuestionDialog(dialog)),
    hideQuestionDialog: (dialog) => dispatch(hideQuestionDialog(dialog)),
    updateFirmwareVer: (verType, ver) => dispatch(setBrainVer(verType, ver)),
    setEditorDisplayMode: mode => dispatch(setEditorDisplayMode(mode)),
    setCodeView: show => dispatch(setCodeView(show)),
    onShowRemindFirmwareUpdateDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_REMIND_FIRMWARE_UPDATE)),
    onShowRemindBluetoothUpdateDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_REMIND_BLUETOOTH_UPDATE)),
    confirmDeleteController: () => dispatch(confirmDeleteController()),
    showFirmwarmUpdateDialog: () => dispatch(showFirmwarmUpdateDialog()),
    setBrainDownloadType: type => dispatch(setBrainDownloadType(type)),
    setUIStyle: style => dispatch(setUIStyle(style)),
    setEnableConfigEntry: status => dispatch(setEnableConfigEntry(status)),
    setEnableConfigEduAndEntry: status => dispatch(setEnableConfigEduAndEntry(status)),
    setRealEntryDeviceList: (list) => dispatch(setRealEntryDeviceList(list)),
    enableCheckingImportDevice: (status) => dispatch(enableCheckingImportDevice(status)),
    showUpdateRCTutorialDialog: () => dispatch(showUpdateRCTutorialDialog()),
    hideUpdateRCTutorialDialog: () => dispatch(hideUpdateRCTutorialDialog()),
    beginRCFirmwareUpdating: () => dispatch(beginRCFirmwareUpdating()),
    onRequestCloseEditorSaveDialog: () => dispatch(hideQuestionDialog(questionType.QUESTION_OPEN_EDITOR_SAVE)),
    onRequestCloseDirectNewDialog: () => dispatch(hideQuestionDialog(questionType.QUESTION_DIRECT_NEW_SAVE)),
    setBrainDownloadAndRun: status => dispatch(setBrainDownloadAndRun(status)),
    setProjectTitleupdateTitle: isUpdate => dispatch(setProjectTitleupdateTitle(isUpdate)),
    showPadFileOpenDialog: () => dispatch(showPadFileOpenDialog()),
    showPadFileSaveDialog: () => dispatch(showPadFileSaveDialog()),
    setFileList: (list) => dispatch(setFileList(list)),
    setConceptAction: (action) => dispatch(setConceptAction(action)),
    setVRSaveFile: (status) => dispatch(setVRSaveFile(status)),
    showSpeakerCustomizingDialog: () => dispatch(showSpeakerCustomizingDialog()),
    setCustomizingAction: (action) => dispatch(setCustomizingAction(action)),
    setEditIntentId: (intentId) => dispatch(setEditIntentId(intentId)),
    showAddAISpeechGroupDialog: () => dispatch(showAddAISpeechGroupDialog()),
    hideAddAISpeechGroupDialog: () => dispatch(hideAddAISpeechGroupDialog()),
    hideEditAISpeechGroupDialog: () => dispatch(hideEditAISpeechGroupDialog()),
    hideQuestionDeleteAISpeechGroupDialog: () => dispatch(hideQuestionDeleteAISpeechGroupDialog()),
    hideQuestionDeleteSpeakerIntentArrayDialog: () => dispatch(hideQuestionDeleteSpeakerIntentArrayDialog()),
    clearImportSentence: () => dispatch(clearImportSentence()),
    clearImportConcepts: () => dispatch(clearImportConcepts()),
    setSortedSentence: (different, duplicated) => dispatch(setSortedSentence(different, duplicated)),
    setSortedConcept: (different, duplicated) => dispatch(setSortedConcept(different, duplicated)),
    clearSortedSentence: () => dispatch(clearSortedSentence()),
    clearSortedConcept: () => dispatch(clearSortedConcept()),
    setTestVersion: (enable) => dispatch(setTestVersion(enable)),
    selectLocale: (locale, uiStyle, localesState) => dispatch(selectLocale(locale, uiStyle, localesState)),
    setSaveCancel: (status) => dispatch(setSaveCancel(status)),
    hideBoardGameDialog: () => dispatch(hideBoardGameDialog()),
    setFileProcessing: (processing) => dispatch(setFileProcessing(processing)),
    showVRRemindDialog: () => dispatch(showVRRemindDialog()),
    hideVRRemindDialog: () => dispatch(hideVRRemindDialog()),
    setProjectTitleChanged: change => dispatch(setProjectTitleChanged(change)),
    hideProjectNameDialog: () => dispatch(hideQuestionDialog(questionType.QUESTION_SAVE_PROJECTNAME)),
    showBottomHintDialog: () => dispatch(showBottomHintDialog()),
    setVRUnityContextLoadingProgress: (status) => dispatch(setVRUnityContextLoadingProgress(status)),
    resetVisionInitState: () => dispatch(resetVisionInitState()),
    setVisionInitState: status => dispatch(setVisionInitState(status)),
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(GUIComponent));