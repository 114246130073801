import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './update-rc-dialog.css';
import { FormattedMessage } from 'react-intl';
import rcBootloaderImg from './svg/rc_bootloader.png';
import rcPoweroffImg from './svg/rc_poweroff.svg';
import { getAllControllerPortInfo, getControllerInfo } from '../../reducers/controller';


const DeviceType = {
    RC_LOADER: "RC_LOADER",
    RC: "RC",
}

class UpdateRCDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            page: 1,
            connectedNumber: (Array.isArray(this.props.getAllControllerPortInfo)) ? this.props.getAllControllerPortInfo.length : 0
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.getAllControllerPortInfo != this.props.getAllControllerPortInfo) {
            this.setState({ connectedNumber: (Array.isArray(this.props.getAllControllerPortInfo)) ? this.props.getAllControllerPortInfo.length : 0 })
            if (this.state.page == 2 && this.props.getAllControllerPortInfo.length == 0) {
                this.setState({ page: 3 });
            }
        }
        if (prevProps.getControllerInfo != this.props.getControllerInfo) {
            if (this.state.page == 3 && this.props.getControllerInfo && this.props.getControllerInfo.mode == DeviceType.RC_LOADER) {
                this.setState({ page: 4 });
                this.props.updateFirmware();
            }
        }
    }

    onCloseDialog() {
        this.props.onClose()
        this.setState({ page: 1 })
    }

    getPage() {
        switch (this.state.page) {
            case 1:
                return <ToturialPage1
                    enableButton={this.state.connectedNumber == 1}
                    onClickNext={() => this.setState({ page: 2 })}
                    onSkip={() => this.onCloseDialog()}
                />
            case 2:
                return <ToturialPage2
                    onClickCancel={() => this.onCloseDialog()}
                />
            case 3:
                return <ToturialPage3
                    onClickCancel={() => this.onCloseDialog()}
                />
            case 4:
                return <ToturialPage4
                    onClickConfirm={() => {
                        this.props.updateFirmware();
                        this.onCloseDialog();
                    }}
                />

        }
    }


    render() {
        // Render nothing if the "show" prop is false
        if (!this.props.show) {
            return null;
        }
        return this.getPage();
    }
}

const ToturialPage1 = (props) => {
    return (<div className={classNames(styles.backdropStyle)}>
        <div className={classNames(styles.modalStyle, styles.page1)} >
            <div className={classNames(styles.title)} >
                <FormattedMessage
                    defaultMessage="Remote controller firmware updating."
                    description="Remote controller firmware updating."
                    id="gui.dialog.update.rc.title.page1"
                />
            </div>
            <div className={classNames(styles.content)} >
                <FormattedMessage
                    defaultMessage='The remote control has a new firmware version, please make sure that only one remote control is connected before the update and then click "Next"'
                    description='The remote control has a new firmware version, please make sure that only one remote control is connected before the update and then click "Next"'
                    id="gui.dialog.update.rc.hint.page1"
                />
            </div>
            <div className={classNames(styles.button, styles.page1)}
                onClick={() => { props.onClickNext() }}>
                <FormattedMessage
                    defaultMessage="Next"
                    description="Next"
                    id="gui.dialog.update.rc.next"
                />
            </div>
            <div className={classNames(styles.skip)}
                onClick={() => { props.onSkip() }}>
                <FormattedMessage
                    defaultMessage="Skip"
                    description="Skip"
                    id="gui.dialog.update.rc.skip"
                />
            </div>
        </div>
    </div>)
}

const ToturialPage2 = (props) => {
    return (<div className={classNames(styles.backdropStyle)}>
        <div className={classNames(styles.modalStyle, styles.page2)} >
            <div className={classNames(styles.title)} >
                <FormattedMessage
                    defaultMessage="Please follow the steps below to perform the update"
                    description="Please follow the steps below to perform the update"
                    id="gui.dialog.update.rc.title.page2"
                />
            </div>
            <div className={classNames(styles.content)} >
                <FormattedMessage
                    defaultMessage="1. Please turn off the remote control"
                    description="1. Please turn off the remote control"
                    id="gui.dialog.update.rc.hint.page2"
                />
            </div>
            <div className={classNames(styles.page2Picture)} ><img src={rcPoweroffImg} /></div>
            <div className={classNames(styles.button, styles.page2)}
                onClick={() => { props.onClickCancel() }}>
                <FormattedMessage
                    defaultMessage="Cancel"
                    description="Cancel"
                    id="gui.dialog.update.rc.cancel"
                />
            </div>
        </div>
    </div>
    )
}

const ToturialPage3 = (props) => {
    return (<div className={classNames(styles.backdropStyle)}>
        <div className={classNames(styles.modalStyle, styles.page3)} >
            <div className={classNames(styles.title)} >
                <FormattedMessage
                    defaultMessage="Please follow the steps below to perform the update"
                    description="Please follow the steps below to perform the update"
                    id="gui.dialog.update.rc.title.page2"
                />
            </div>
            <div className={classNames(styles.content)} >
                <FormattedMessage
                    defaultMessage="2. Press and hold L3 and R3, and reconnect the microUSB cable until the remote control displays the blue and purple lights flashing and then release"
                    description="2. Press and hold L3 and R3, and reconnect the microUSB cable until the remote control displays the blue and purple lights flashing and then release"
                    id="gui.dialog.update.rc.hint.page3"
                />
            </div>
            <div className={classNames(styles.page3Picture)} ><img src={rcBootloaderImg} /></div>
            <div className={classNames(styles.button, styles.page3)}
                onClick={() => { props.onClickCancel() }}>
                <FormattedMessage
                    defaultMessage="Cancel"
                    description="Cancel"
                    id="gui.dialog.update.rc.cancel"
                />
            </div>
        </div>
    </div>)
}

const ToturialPage4 = (props) => {
    return (<div className={classNames(styles.backdropStyle)}>
        <div className={classNames(styles.modalStyle, styles.page4)} >
            <div className={classNames(styles.title)} >
                <FormattedMessage
                    defaultMessage="Firmware Update"
                    description="Firmware Update"
                    id="gui.dialog.update.rc.title.page4"
                />
            </div>
            <div className={classNames(styles.content)} >
                <FormattedMessage
                    defaultMessage="When the remote control flashes white light, it means the update is started. Please do not remove the USB during the process to avoid damage to the remote control"
                    description="When the remote control flashes white light, it means the update is started. Please do not remove the USB during the process to avoid damage to the remote control"
                    id="gui.dialog.update.rc.hint.page4"
                />
            </div>
            <div className={classNames(styles.button, styles.page4)}
                onClick={() => { props.onClickConfirm() }}>
                <FormattedMessage
                    defaultMessage="I know"
                    description="I know"
                    id="gui.dialog.update.rc.iknow"
                />
            </div>
        </div>
    </div>)
}

UpdateRCDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    updateFirmware: PropTypes.func.isRequired,
};

UpdateRCDialog.defaultProps = {
    updateFirmware: () => { }
}

const mapStateToProps = (state) => ({
    getAllControllerPortInfo: getAllControllerPortInfo(state),
    getControllerInfo: getControllerInfo(state)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateRCDialog);