const appString = {
    "ww": {
        "en": {
            "appName": "PINBO Lab",
        },
        "zh-tw": {
            "appName": "PINBO 實驗室",
        },
        'zh-cn': {
            "appName": "PINBO Lab编程",
        },
    },

    "cn": {
        "en": {
            "appName": "Aliyun Coding for Education",
        },
        "zh-tw": {
            "appName": "阿里雲編程",
        },
        'zh-cn': {
            "appName": "阿里云编程",
        },
    },

    "vr": {
        "en": {
            "appName": "PINBO Fun",
            "projectName": "PINBO Fun",
        },
        "zh-tw": {
            "appName": "PINBO Fun",
            "projectName": "PINBO Fun",
        },
        'zh-cn': {
            "appName": "PINBO Fun",
            "projectName": "PINBO Fun",
        },
    },
}

const editorMsgsByAsus = {
    "en": {
        "gui.gui.defaultProjectTitle": "My projects",
        "gui.defaultProject.variable": "My variable",
        "gui.defaultProject.variable.numeric": "my numeric variable",
        "gui.defaultProject.variable.string": "my string variable",
        "gui.defaultProject.broadcast": "message1",
        "gui.menuBar.file": "File",
        "gui.menuBar.new": "New file",
        "gui.menuBar.open": "Open",
        "gui.menuBar.recent": "Recent files",
        "gui.menuBar.noRecentFile": "No recent files",
        "gui.menuBar.example": "Examples",
        "gui.menuBar.about": "About",
        "gui.menuBar.license": "Software License Agreement",
        "gui.menuBar.saveToComputer": "Save",
        "gui.menuBar.saveAs": "Save as",
        "gui.menuBar.png": "Save as PNG",
        "gui.menuBar.modify": "Modify",
        "gui.menuBar.redo": "Redo",
        "gui.menuBar.undo": "Undo",
        "gui.menuBar.arrange": "Arrange all Blocks",
        "gui.menuBar.collapseBlock": "Collapse all Blocks",
        "gui.menuBar.expandBlock": "Expand all Blocks",
        "gui.menuBar.newNote": "New Note",
        "gui.menuBar.clear": "Clean up Blocks",
        "gui.menuBar.slot": "Slot",
        "gui.menuBar.download": "Download",
        "gui.menuBar.downloadRun": "Download and run",
        "gui.menuBar.run": "Run",
        "gui.menuBar.stop": "Stop",
        "gui.menuBar.boardgame": "Course",
        "gui.menuBar.streaming": "Streaming",
        "gui.menuBar.brain.disconnected": "-------",
        "gui.menuBar.beginner": "Beginners' guide",
        "gui.menuBar.direction": "Block info",
        "gui.menuBar.deviceDirection": "Device info",
        "gui.menuBar.frequentlyQuestions": "PINBO Lab FAQ",
        "gui.menuBar.course": "Course",
        "gui.menuBar.help": "Resources",
        "gui.menuBar.settings": "Settings",
        "gui.menuBar.account": "Account",
        "gui.menuBar.more": "More",
        "gui.menuBar.codeEditor": "Open code editor",
        "gui.menuBar.blockEditor": "Block editor",
        "gui.exampleLibrary.example": "Example",
        "gui.libraryTags.templates": "Templates",
        "gui.libraryTags.motion": "Motion",
        "gui.libraryTags.drivetrain": "Drivetrain",
        "gui.libraryTags.looks": "Looks",
        "gui.libraryTags.sound": "Sound",
        "gui.libraryTags.events": "Events",
        "gui.libraryTags.control": "Control",
        "gui.libraryTags.sensing": "Sensing",
        "gui.libraryTags.operators": "Operators",
        "gui.libraryTags.variables": "Variables",
        "gui.libraryTags.myblocks": "My Blocks",
        "gui.libraryTags.aispeech": "AI speech",
        "gui.libraryTags.comment": "Comment",
        "gui.libraryTags.extensions": "Extensions",
        "gui.libraryTags.edu": "B1 brain",
        "gui.libraryTags.entry": "B2 brain",
        "gui.libraryTags.allmonitor": "Combination of brains",
        "gui.deviceManagerStage.title.edu": "Device manager",
        "gui.deviceManagerStage.title.entry": "Device manager",
        "gui.deviceManagerStage.title.controller": "Controller settings",
        "gui.deviceManagerStage.page.selectPort": "Please select a port",
        "gui.deviceManagerStage.page.selectTwoPorts": "Please select two ports",
        "gui.deviceManagerStage.page.selectThreePorts": "Please select three ports",
        "gui.deviceManagerStage.page.selectDrivetrainMotorPorts": "Please select two ports for motors",
        "gui.deviceManagerStage.page.selectDrivetrainGryo": "To use the Gyro, please select a port",
        "gui.deviceManagerStage.page.drivertrain.setting.title": "Please set the drivertrain system",
        "gui.deviceManagerStage.page.drivertrain.unit.mm": "cm",
        "gui.deviceManagerStage.page.drivertrain.unit.inch": "inch",
        "gui.deviceManagerStage.page.drivertrain.wheel.size": "Wheel size (diameter)",
        "gui.deviceManagerStage.page.drivertrain.gear.ratio": "Gear ratio (input/output)",
        "gui.deviceManagerStage.page.drivertrain.wheelbase.left.right": "Track width",
        "gui.deviceManagerStage.page.drivertrain.wheelbase.fornt.back": "Wheelbase",
        "gui.deviceManagerStage.page.deleteDevice": "Delete the device",
        "gui.deviceManagerStage.page.delete": "Delete",
        "gui.deviceManagerStage.page.selectAll": "Select all",
        "gui.deviceManagerStage.page.cancel": "Cancel",
        "gui.deviceManagerStage.page.edit": "Edit",
        "gui.deviceManagerStage.page.done": "Done",
        "gui.deviceManager.port.disconnected": "Disconnected",
        "gui.deviceManagerStage.hint.title": "Please ensure your devices and sensors are connected to the same ports as in the device manager configuration.",
        "gui.deviceList.selectDeviceTitle": "Choose the device to add",
        "gui.deviceList.deviceListHintTitle": "Select the device to read device info",
        "gui.deviceManagerStage.page.wheelSize": "Wheel size",
        "gui.deviceManagerStage.page.motorText": "Modify name or rotation direction",
        "gui.deviceManagerStage.page.deviceType": "Device category",
        "gui.deviceManagerStage.page.invalidName": "Invalid name",
        "gui.deviceManagerStage.page.motor.forward": "Forward",
        "gui.deviceManagerStage.page.motor.reverse": "Reverse",
        "gui.deviceManagerStage.edit.normal": "Normal",
        "gui.deviceManagerStage.edit.reverse": "Reverse",
        "gui.deviceManagerStage.edu.drivertrain.advanced": "Advanced settings",
        "gui.deviceManagerStage.edu.drivertrain.advanced.description": "This setting can help balance the power of the motors of drivetrain, please adjust it according to the actual performance.",
        "gui.deviceManagerStage.edu.drivertrain.advanced.donoting": "Do not adjust",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.left": "Left motor decrease",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.left.hint": "(right deflection)",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.right": "Right motor decrease",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.right.hint": "(left deflection)",
        "gui.device.bumper": "Bumper sensor",
        "gui.device.led": "LED light",
        "gui.device.motor": "Motor",
        "gui.device.threeWireMotor": "Motor",
        "gui.device.lightSensor": "Light sensor",
        "gui.device.lineTracker": "Tracker sensor",
        "gui.device.buzzer": "Buzzer",
        "gui.device.ultrasonic": "Sonar sensor",
        "gui.device.drivetrain": "Drivetrain",
        "gui.device.drivetrain.gyro": "Gyro on drivetrain",
        "gui.device.b1": "B1 brain",
        "gui.device.b2": "B2 brain",
        "gui.device.motor100": "Motor",
        "gui.device.motor300": "Motor",
        "gui.device.colorSensor": "Color",
        "gui.device.touchLed": "Touch LED",
        "gui.device.gyro": "Gyro",
        "gui.device.vision": "Vision",
        "gui.device.speaker": "Sound",
        "gui.device.no.device": "No device",
        "gui.controller.buttonList.setControllerTitle": "Controller settings",
        "gui.controller.buttonList.switchDirection": "Switch direction",
        "gui.controller.buttonList.emptyDeviceOption": "Please add {device}",
        "gui.controller.buttonList.chooseDeviceOption": "Please choose a device",
        "gui.controller.newController": "New controller",
        "gui.prompt.symbolsNotAllowed": "Please use only letters and numbers",
        "gui.prompt.letterRequired": "Letters are required at the beginning",
        "gui.prompt.nameRequired": "Please enter Chinese, English, numbers and symbols other than (\\\\|/?\"*:<>.)",
        "gui.prompt.variable.duplicate": "The name already exists",
        "gui.prompt.list.duplicate": "The name already exists",
        "gui.prompt.number.duplicate": "The name already exists",
        "gui.prompt.string.duplicate": "The name already exists",
        "gui.prompt.listLength": "Length of list (1-10)",
        "gui.prompt.noVariable": "There are currently no variables",
        "gui.prompt.noList": "There are currently no lists",
        "gui.prompt.noSounds": "There are currently no audio files",
        "gui.prompt.variable.blockUseHint": "The number displayed at the end is the number of the variables in the workspace.",
        "gui.prompt.list.blockUseHint": "The number displayed at the end is the number of the lists in the workspace.",
        "gui.prompt.audio.blockUseHint": "The number displayed at the end is the number of the audio blocks in the workspace.",
        "gui.prompt.group.duplicate": "The group name already exists",
        "gui.dialog.device.help.title": "Device info",
        "gui.dialog.history.latest": "Latest Update",
        "gui.dialog.history.version.list": "Version History",
        "gui.dialog.history.version.watch": "Watch",
        "gui.dialog.bottom.hint.title": "Your current browser do not support full PINBO Fun experience, please use Chrome for the best experience.",
        "gui.dialog.bottom.hint.button": "Go to download",
        "gui.dialog.about.update": "Software update",
        "gui.dialog.about.firmware.update.hint": "Please connect the brain to the computer to perform the update",
        "gui.dialog.update.current": "Current version:",
        "gui.dialog.update.available": "Available version:",
        "gui.dialog.update.title": "Update available",
        "gui.dialog.update.skip": "Skip",
        "gui.dialog.question.download": "Download",
        "gui.dialog.update.update": "Update now",
        "gui.dialog.question.downloadFail": "An unexpected error occurred during compilation",
        "gui.dialog.question.close": "Close",
        "gui.dialog.question.installFail": "An error occurred while installing the software",
        "gui.dialog.question.exit": "Exit",
        "gui.dialog.question.restart": "Restart",
        "gui.dialog.question.isOpen": "This action will open a new file. Are you sure you want to continue?",
        "gui.dialog.question.isSave": "The file has been modified. Save changes?",
        "gui.dialog.question.isCancel": "The file has not been saved yet. Do you want to give up editing?",
        "gui.dialog.question.cancel": "Cancel",
        "gui.dialog.question.noSave": "Don't save",
        "gui.dialog.question.save": "Save",
        "gui.dialog.question.projectName": "Rename file?",
        "gui.dialog.question.overwrite": "Rename",
        "gui.dialog.question.delete": "Are you sure to delete the files in slot {index} ?",
        "gui.dialog.question.reset.firmware": "Reset the brain will clear programming files, RFID card files and controller settings, are you sure to reset the brain?",
        "gui.dialog.question.projectName.projectTitlePlaceholder": "Project name",
        "gui.dialog.question.projectName.invalid": "The project name can't contain the following characters:",
        "gui.dialog.question.projectName.overflow": "The maximum length of project name has been reached. Please delete some letters. ",
        "gui.dialog.question.projectName.empty": "The project name cannot be empty",
        "gui.dialog.question.switch.brain.mode.in.rfid": "The brain is executing a RFID card file. Are you sure to interrupt and start downloading?",
        "gui.dialog.question.switch.brain.mode.in.bt": "The brain is controlled by a smart phone app.  Are you sure to interrupt and start downloading?",
        "gui.dialog.question.extensionNotMatch": "Wrong file format",
        "gui.dialog.question.import.title": "Device settings",
        "gui.dialog.question.import.device": "The following devices have been detected on the brain :",
        "gui.dialog.question.import.device2": "Do you want to apply device settings to device management ?",
        "gui.dialog.question.import.device3": "*After applying, it will replace the device management settings.",
        "gui.dialog.question.import.confirm": "Yes, replace",
        "gui.dialog.question.import.cancel": "No, skip",
        "gui.dialog.brainTypePicker.title": "New file",
        "gui.dialog.brainTypePicker.reminder": "Select Brain type",
        "gui.dialog.brainTypePicker.open": "Open file",
        "gui.dialog.brainTypePicker.confirm": "New",
        "gui.dialog.brainTypePicker.leave": "Leave",
        "gui.dialog.brainTypePicker.edu": "B1 brain",
        "gui.dialog.brainTypePicker.entry": "B2 brain",
        "gui.dialog.brainTypePicker.entryPlus": "Combination of brains",
        "gui.dialog.brainTypePicker.entryPlusDesc": "B1+B2 Brain",
        "gui.dialog.example.open": "Open example",
        "gui.dialog.example.copyBlock": "Copy block to workspace",
        "gui.dialog.example.error": "The file type does not match and cannot be copied",
        "gui.dialog.example.description": "Example description:",
        "gui.dialog.hint.block.help.title": "How to use block info",
        "gui.dialog.hint.block.help.how.to.use": "Click on the block to open the block description!",
        "gui.dialog.hint.block.help.how.to.use.pad": "Long-press the blocks to open the block description!",
        "gui.dialog.hint.block.help.done": "OK",
        "gui.dialog.block.help.title": "Block info",
        "gui.dialog.question.forceUpdate": "Unable to download because the firmware has not been updated, please update the firmware and try again.",
        "gui.dialog.question.blockLimit": "The maximum number of blocks has been reached and cannot be downloaded.",
        "gui.dialog.question.brainType": "The connected brain does not match the file type and cannot be downloaded. Please check and try again.",
        "gui.dialog.question.batteryConnected": "If you use more than two motors, you need to connect the battery box.",
        "gui.dialog.question.batteryLowPower": "Your battery is low, and it is recommended to replace the battery before use.",
        "gui.dialog.question.downloadBrainScriptTimeout": "Download failed. Please restart the brain or try again.",
        "gui.dialog.question.Iknow": "OK",
        "gui.dialog.question.update.software": "The PINBO Lab version does not match the brain, please update your software",
        "gui.dialog.question.update.firmware": "There is a new version, please update the brain.",
        "gui.dialog.question.delete.controller1": "The master controller needs to be executed through controller 1. Are you sure you want to delete controller 1?",
        "gui.dialog.error.update.network.disconnected": "The network is disconnected, please connect the network to update to the latest version.",
        "gui.dialog.question.force.update.firmware": "The PINBO Lab version does not match the brain. The file cannot be downloaded. Please update the brain firmware. Make sure that the brain and computer is properly connected via USB cable and connects the battery box while updating.",
        "gui.dialog.question.force.update.software": "The brain version does not match the PINBO Lab version and file cannot be downloaded. Please update your PINBO Lab.",
        "gui.dialog.question.firmware.loader.mode": "The brain firmware is abnormal.  Please update the brain firmware. Make sure that the brain and computer is properly connected via USB cable and connects the battery box while updating.",
        "gui.dialog.question.remind.firmaware.update": "The brain firmware version is outdated. Please connect the PC to update it immediately.",
        "gui.dialog.question.remind.bt.update": "The bluetooth module has a new firmware version, please connect to a computer to update.",
        "gui.dialog.question.update.firmwareAndBt": "There is a new version of the brain and bluetooth module, please update to the latest version.",
        "gui.dialog.question.update.bt": "There is a new version of the bluetooth module, please update to the latest version.",
        "gui.dialog.question.force.update.firmwareAndBt": "The PINBO Lab version does not match the brain. The file cannot be downloaded. Please update the brain firmware. Plug bluetooth module into the brain, make sure that the brain and computer is properly connected via USB cable and connects the battery box while updating.",
        "gui.dialog.question.vr.close.fullscreen": "Please shrink the window to use the user guide",
        "gui.dialog.question.vr.remind": "The screen resolution is too small, please adjust the resolution or browser window ratio to operate.",
        "gui.dialog.question.vr.remind1": "Note: You can use Ctrl+Scroll Wheel to adjust the screen ratio.",
        "gui.dialog.error.block.break.position.error": "The project fails to launch as the “exit loop” instruction block in the program is not in place. Make sure that all the “exit loop” instruction blocks should be inside the loop statement.",
        "gui.dialog.error.brain.status.mode": "This task cannot be performed. Check the status of the brain and try again",
        "gui.dialog.error.firmware.updateFailed": "Firmware updating failed. Please try agin.",
        "gui.dialog.error.firmware.update.server.error": "We're undergoing a bit of scheduled maintenance. Please try to update your firmware after a few minutes.",
        "gui.dialog.error.network.disconnected": "Please check your network connection.",
        "gui.dialog.error.erase.slot.failed": "Slot deletion failed, please try agin.",
        "gui.dialog.error.file.duplicate": "A file with the same name already exists. Please use 'save as' to overwrite the file or use another file name.",
        "gui.dialog.error.file.noExist": "File no longer exists",
        "gui.dialog.error.update.fail": "Software update failed. Please try agin.",
        "gui.dialog.error.update.server.noRespond": "The server is not responding, please try again",
        "gui.dialog.error.Iknow": "OK",
        "gui.dialog.error.bt.update.fail": "Bluetooth module update failed, please confirm the connection is normal and try again",
        "gui.dialog.error.bt.update.fail.continue.update.edu": "Bluetooth firmware update failed, please check and try again later",
        "gui.dialog.error.bt.update.fail.continue.update.edu2": "Do not remove the brain. We will continue to update it",
        "gui.dialog.error.bt.update.fail.disconnect.module": "Bluetooth module update failed. Please unplug the brain cable and remove the battery to shut down, wait for 5 seconds and then reconnect",
        "gui.dialog.error.bt.update.fail.dfu.timeout": "Bluetooth firmware update failed. Please unplug the brain cable and remove the battery to shut down, wait for 5 seconds and then reconnect",
        "gui.dialog.error.firmware.update.timeout": "Firmware update failed. Please unplug the brain cable and remove the battery to shut down, wait for 5 seconds and then reconnect",
        "gui.dialog.error.rc.update.fail": "Controller module update failed, please confirm the connection is normal and try again",
        "gui.dialog.error.rc.update.fail.dfu.timeout": "Controller firmware update failed. Please unplug the controller cable, wait for 5 seconds and then reconnect",
        "gui.dialog.error.connecting.failed": "The brain is currently unavailable, please try again later",
        "gui.dialog.error.xlsx.sheetname.invalid": "Enity lists import format is not valid",
        "gui.dialog.error.update.file.crash": "An error occurred during the update, the file has been damaged. Please go to the official website to download the installation file.",
        "gui.dialog.error.reupload": "Re-upload",
        "gui.dialog.whatsNew.title": "What's new",
        "gui.dialog.whatsNew.Iknow": "OK",
        "gui.dialog.editBlock.title.audio": "Edit audio",
        "gui.dialog.editBlock.title.variable": "Edit variables",
        "gui.dialog.editBlock.title.list": "Edit lists",
        "gui.dialog.confirm": "OK",
        "gui.dialog.wifi.title": "WiFi settings",
        "gui.dialog.wifi.scaning": "Scaning...",
        "gui.dialog.wifi.empty.hint": "No available WiFi signal has been found. Please search another network and try again",
        "gui.dialog.wifi.other": "Other networks",
        "gui.dialog.wifi.safe": "Safe",
        "gui.dialog.wifi.unknown": "Unknown",
        "gui.dialog.wifi.connecting": "Connecting",
        "gui.dialog.wifi.connected": "Connected",
        "gui.dialog.wifi.disconnected": "Disconnected",
        "gui.dialog.wifi.interrupt": "Interrupt",
        "gui.dialog.wifi.status.disable": "Disabled",
        "gui.dialog.wifi.status.noSetting": "Not set",
        "gui.dialog.wifi.status.scanning": "Not connected",
        "gui.dialog.wifi.status.connecting": "Connecting",
        "gui.dialog.wifi.status.completed": "Connected",
        "gui.dialog.wifi.status.authFail": "Authentication failed",
        "gui.dialog.wifi.status.linkFail": "Link fail",
        "gui.dialog.update.rc.title.page1": "controller firmware update",
        "gui.dialog.update.rc.hint.page1": `The controller has a new firmware version, please make sure that only one controller is connected before the update and then click "next"`,
        "gui.dialog.update.rc.next": "Next",
        "gui.dialog.update.rc.skip": "Skip",
        "gui.dialog.update.rc.title.page2": "Please follow the steps below to perform the update",
        "gui.dialog.update.rc.hint.page2": "1. Please turn off the controller and remove cable",
        "gui.dialog.update.rc.cancel": "Cancel",
        "gui.dialog.update.rc.hint.page3": "2. Press and hold L3 and R3, and reconnect the micro USB cable until the controller displays  blue and purple lights, and then release",
        "gui.dialog.update.rc.title.page4": "Firmware update",
        "gui.dialog.update.rc.hint.page4": "When the controller flashes white light, it means the update is started. Please do not remove the USB during the process to avoid damage to the controller",
        "gui.dialog.update.rc.iknow": "OK",
        "gui.dialog.wifi.connect": "Connect",
        "gui.dialog.wifi.interupt": "Interupt",
        "gui.dialog.wifi.name": "WiFi name",
        "gui.dialog.wifi.password": "WiFi password",
        "gui.dialog.wifi.password.error": "Incorrect password ",
        "gui.dialog.streaming.title": "Streaming",
        "gui.dialog.streaming.reminder": "Running a file with image recognition will interrupt live streaming",
        "gui.dialog.streaming.index.screen": "B2 screen ",
        "gui.dialog.streaming.index.ssn": "B2 name ",
        "gui.dialog.streaming.index.battery": "Battery ",
        "gui.dialog.streaming.index.connected": "Whether to connect to B1 brain ",
        "gui.dialog.streaming.index.wifi": "WiFi signal strength ",
        "gui.dialog.streaming.index.wifi.signal.high": "High",
        "gui.dialog.streaming.index.wifi.signal.mid": "Mid",
        "gui.dialog.streaming.index.wifi.signal.low": "Low",
        "gui.dialog.streaming.index.wifi.signal.weak": "Weak",
        "gui.dialog.streaming.index.vision": "Vision sensor screen:",
        "gui.dialog.streaming.vision.disconnected.reminder": "Please confirm that the vision sensor is connected to the Type-C port of the brain",
        "gui.dialog.streaming.vision.popup.reminder": "Playing in the vision sensor screen",
        "gui.dialog.streaming.vision.photo.saved": "Saved to the download directory",
        "gui.dialog.streaming.index.sensorinfo": "Sensor information",
        "gui.dialog.streaming.index.controller": "Controller",
        "gui.dialog.streaming.popup.title": "Vision sensor screen",
        "gui.dialog.streaming.brain.disconnected": "Please make sure it is connected to B2 brain",
        "gui.dialog.streaming.connected.b1.already": "Connected to B1",
        "gui.dialog.streaming.connected.b1.none": "Not connected to B1",
        "gui.dialog.streaming.button": "Button",
        "gui.dialog.streaming.button.press": "Pressed",
        "gui.dialog.streaming.button.left.joystick": "Left Joystick",
        "gui.dialog.streaming.button.right.joystick": "Right Joystick",
        "gui.dialog.streaming.button.joystick.up": "Up",
        "gui.dialog.streaming.button.joystick.down": "Down",
        "gui.dialog.streaming.button.joystick.left": "Left",
        "gui.dialog.streaming.button.joystick.right": "Right",
        "gui.dialog.directNew.block": "This action will open a new project. Are you sure to execute it?",
        "gui.dialog.directNew.block.save": "This action will open a new  project. The file has not been saved yet. Do you want to save it?",
        "gui.dialog.ai.speech.group.delete": "Delete [{groupName}] and the blocks in the group?",
        "gui.dialog.ai.speech.IntentArray.delete": "Do you want to delete dialogues blocks?",
        "gui.dialog.wifi.direction1": "Ensure that the brain is B2",
        "gui.dialog.wifi.direction2": "Please check that the brain and the iPad are connected to the same WiFi.",
        "gui.dialog.wifi.direction2.pc": "Please check that the brain and the computer are connected to the same WiFi.",
        "gui.dialog.wifi.direction3": "Make sure the brain is not connected to another computer or iPad",
        "gui.dialog.wifi.direction4": "If all above are confirmed and still can not find the brain, please click \"Connect with IP\" to use IP address to connect the brain.",
        "gui.dialog.wifi.setting.direction1": "On the [Home]>[Settings]>[WiFi Settings] page of the brain, enable [AP Mode]",
        "gui.dialog.wifi.setting.direction2": `Please connect computer or iPad WiFi to "pinboXXXXXX" and return to here to click next step.`,
        "gui.dialog.wifi.setting.direction4": "Go to the brain screen menu, <Settings>-<WiFi Settings>, to see if the WiFi is connected or connect to the correct network.",
        "gui.dialog.wifi.setting.direction5.pc": "Please connect the computer to the same network as the brain's. Come back to this app and select the brain on the list. If there is no brains on the list, please use brain's IP to connect.",
        "gui.dialog.wifi.setting.direction5": "Please connect iPad to the same network as the brain's. Come back to this app and select the brain on the list. If there is no brains on the list, please use brain's IP to connect.",
        "gui.dialog.wifi.next": "Next step",
        "gui.dialog.wifi.retry": "Retry",
        "gui.dialog.wifi.setting.title.pad": "Select the WiFi for the brain",
        "gui.dialog.wifi.setting.title.pc": "Select the WiFi for the brain",
        "gui.dialog.wifi.setting.nofound": "No available WiFi signal has been found. Please search other networks and try again.",
        "gui.dialog.wifi.setting.other": "Other networks...",
        "gui.dialog.wifi.setting.finish": "Finish",
        "gui.dialog.wifi.setting.ip.title1": "If the brain is connected to WiFi, you can find the brain IP",
        "gui.dialog.wifi.setting.ip.title2": "on the [Home]>[Settings]>[WiFi Settings] page of the brain.",
        "gui.dialog.wifi.setting.connect": "Connect",
        "gui.dialog.wifi.setting.connecting": "Connecting",
        "gui.dialog.wifi.setting.connect.error": "The connection failed. Ensure that the brain is not connected to another computer or iPad.",
        "gui.dialog.wifi.setting.connect.ip.error": "The IP format is incorrect. Please try again.",
        "gui.hint.deleteBlocks.confirm": "OK",
        "gui.firmware.brain.firmwareTitle": "Version",
        "gui.firmware.brain.updateTitle": "Update status",
        "gui.firmware.brain.modelTitle": "Brain Type",
        "gui.firmware.brain.isModelDifferent": "Brain type doesn't match",
        "gui.firmware.brain.upToDate": "Up to date",
        "gui.firmware.brain.updateNow": "Update now",
        "gui.firmware.brain.restore": "Reset",
        "gui.firmware.brain.brainName": "Brain Type",
        "gui.firmware.brain.ap.title": "Available Brain",
        "gui.firmware.brain.ap.text": "B2 brains on the network",
        "gui.firmware.brain.ap.reminder": "To connect to the brain via WiFi, please remove brain-computer cable connections.",
        "gui.firmware.brain.ap.setting": "Remote access",
        "gui.firmware.brain.ap.ip": "Connect with IP",
        "gui.firmware.brain.ap.not.found": "Can't find the Brain?",
        "gui.brainInfo.mobile.setting": "Setup WiFi of B2 Brain (Without USB cable)",
        "gui.firmware.dialog.firmwareUpdate": "Firmware updating",
        "gui.firmware.dialog.firmwareUpdate.warning": "Do not remove the USB and maintain the power supply during the process to avoid damage to the brain.",
        "gui.audio.loading.dialog.loading": "Importing audio files...",
        "gui.file.processing.dialog.processing": "File processing, please try again later",
        "gui.file.processing.dialog.processing.time": "(it takes up to 60 seconds)",
        "gui.firmware.controller.name": "Controller name",
        "gui.howtos.intro-move-sayhello-hat.name": "Beginners' guide",
        "gui.modal.backHome": "Back to Home",
        "gui.prompt.submit": "Submit",
        "gui.userGuide.title.welcome": "%APP_NAME%",
        "gui.userGuide.title.blockGuide": "Getting to know the block menu",
        "gui.userGuide.title.howToUseBlock": "How to use a block",
        "gui.userGuide.title.addBlock": "Give it a try!",
        "gui.userGuide.title.addBlockFinish": "Congratulations on learning how to add a new block! What about removing a block?",
        "gui.userGuide.title.deleteBlock": "Give it a try!",
        "gui.userGuide.title.deviceManagerGuide": "How to create more applications?",
        "gui.userGuide.title.newDevice": "How to add a new device (sensor)",
        "gui.userGuide.title.newDeviceSelectPort": "How can the device (sensor) be used correctly",
        "gui.userGuide.title.newDeviceFinish": "New sensor added!",
        "gui.userGuide.title.letDeviceWork": "Try to drag the motor block!",
        "gui.userGuide.title.letDeviceWorkFinish": "Now, you know something about programing. Try to make the robot do some work!",
        "gui.userGuide.title.introBrain": "Let's learn how to make the brain run the flie!",
        "gui.userGuide.title.openBluetoothSetting": "Turn bluetooth on",
        "gui.userGuide.title.bluetoothConnecting": "Please connect to the brain",
        "gui.userGuide.title.introSlot": "View the files in the brain",
        "gui.userGuide.title.downloadAndRun": "Let the brain run the motor program",
        "gui.userGuide.title.downloadFail": "Unable to download the program to the brain",
        "gui.userGuide.title.reconnect": "Please connect to the brain",
        "gui.userGuide.title.disconnect": "Oop! Still can't find the brain",
        "gui.userGuide.title.introDownloadAndRun": "Learning how to let the brain run the motor program",
        "gui.userGuide.title.Finish": "Great! You have learned the basic functions!",
        "gui.userGuide.title.toolbarGuide": "Simulate and scene control",
        "gui.userGuide.title.selectSceneAndEdit": "Select and edit the scene",
        "gui.userGuide.title.putObject": "Put object",
        "gui.userGuide.title.putTerrain": "Put terrain",
        "gui.userGuide.title.drawTrace": "Draw trace",
        "gui.userGuide.title.introSensors": "Great! You've learned the basic operations.",
        "gui.userGuide.message.welcome": "Welcome to %APP_NAME%. Follow the steps below to learn more about %APP_NAME%!",
        "gui.userGuide.message.blockGuide": "The block menu features blocks with different functions that you can combine to design all kinds of scripts.",
        "gui.userGuide.message.howToUseBlock": "To use a block, **select and drag a block to the workspace**. Now give it a try.",
        "gui.userGuide.message.addBlock": "Start block is the starting point of all sequences. **Drag to move the block under the start block**.",
        "gui.userGuide.message.addBlockFinish": "To remove a block, **drag the block back to the block menu**. Now give it a try.",
        "gui.userGuide.message.deleteBlock": "To remove any unused blocks, select and drag a block to the block menu and then release the block when the **trash bin icon** appears.",
        "gui.userGuide.message.deviceManagerGuide": "You can add new devices to generate more blocks in the **device management**. Let us try adding a new motor!",
        "gui.userGuide.message.newDevice": "Select **motor** to begin.",
        "gui.userGuide.message.newDeviceSelectPort": "Select the device that **matches the connected port of the brain**.",
        "gui.userGuide.message.newDeviceFinish": "**the motor blocks** would appear in the block menu after adding a new device. Now, we are going to spin the motor. Do you know how to do it?",
        "gui.userGuide.message.letDeviceWork": "Select and **drag the motor block** below the start block.",
        "gui.userGuide.message.letDeviceWorkFinish": "To run the edited program, connect the brain to your computer and download the coding file to the bain.",
        "gui.userGuide.message.letDeviceWorkFinishPad": "Connect the brain to your iPad first and download the software if you want to run a program.",
        "gui.userGuide.message.introBrain": "This icon indicates the connection status of the brain. Green means “connected”, and the number represents the number of connected brains. Please connect the brain to your computer, and ensure **the motor is connected to port A1**.",
        "gui.userGuide.message.introBrainPad": "This icon indicates the connection status of the brain. Green means “connected”. Please ensure **the motor is connected to port A1**.",
        "gui.userGuide.message.introBrainEntry": "This icon indicates the connection status of the brain. Green means “connected”, and the number represents the number of connected brains. Please connect the brain to your pc, and ensure **the motor is connected to port 1**.",
        "gui.userGuide.message.introBrainEntryPad": "This icon indicates the connection status of the brain. Green means “connected”. Please ensure **the motor is connected to port 1**.",
        "gui.userGuide.message.openBluetoothSetting": "Please turn on bluetooth on your iPad to search for nearby devices.",
        "gui.userGuide.message.bluetoothConnecting": "The led light flashes blue when your device has been connected via bluetooth successfully. Click OK when done.",
        "gui.userGuide.message.wifiConnecting": "Please check that the brain and the compupter are connected to the same WiFi. Select the brain on the list or use brain's IP address to connect and press \"OK\" when it’s connected. If the brain has not yet set up WiFi, click \"Remote access\" to set up.",
        "gui.userGuide.message.wifiConnectingPad": "Please check that the brain and the iPad are connected to the same WiFi. Select the brain on the list or use brain's IP address to connect and press \"OK\" when it’s connected. If the brain has not yet set up WiFi, click \"Remote access\" to set up.",
        "gui.userGuide.message.introSlot": "You may view the files in the brain from **slot**. The figure indicates the number of the cell. Now let's try how to download and run the file!",
        "gui.userGuide.message.downloadAndRun": "Click **download and run**.",
        "gui.userGuide.message.downloadFail": "Please check the firmware version after you have completed the tutorial and try updating again. Click Next to view more tutorials.",
        "gui.userGuide.message.reconnect": "Unable to find the brain. Please check if the brain is properly connected and try again. Click **OK** after the connection is successful.",
        "gui.userGuide.message.disconnect": "Please check if the brain and the USB cable are functioning properly, and try again later.",
        "gui.userGuide.message.introDownloadAndRun": "Click **download** to send current file to the brain while **download and run** lets you send the file and start the current program to spin the motor.",
        "gui.userGuide.message.introSlotDisconnect": "After connecting to the brain, you may view the files in the brain from **slot**. The figure indicates the number of the cell.",
        "gui.userGuide.message.Finish": "Besides controlling motors, you also can make buzzer to hum a melody, and light up LED! Find out more examples on **tutorials - tutorial example**! Now, press **start explore** to the %APP_NAME% world of programing!",
        "gui.userGuide.message.toolbarGuide": "The toolbar allows you to simulate or reset the scene, and you can control the camera to select the angle or the range of visibility.",
        "gui.userGuide.message.selectSceneAndEdit": "In addition to the simulation, you can also select different scenes and edit them as you like.",
        "gui.userGuide.message.putObject": "Open the object menu to select the object you want, and put it in the scene to interact with the PINBO (car).",
        "gui.userGuide.message.putTerrain": "Select the terrain tool and put sandpaper terrains one after another to change the friction of the ground.",
        "gui.userGuide.message.drawTrace": "Use the trace drawing tool to make the PINBO (car) move along the line.",
        "gui.userGuide.message.introSensors": "The PINBO (car) in the scene is equipped with the following sensors. You can start editing the blocks to control your PINBO (car)!",
        "gui.userGuide.welcome.button.skip": "Skip",
        "gui.userGuide.welcome.button.start": "Start",
        "gui.userGuide.button.before": "Previous",
        "gui.userGuide.button.next": "Next",
        "gui.userGuide.button.connectLater": "Connect later",
        "gui.userGuide.button.tryLater": "Try again later",
        "gui.userGuide.button.bluetoothSetting": "Go to settings",
        "gui.userGuide.button.connected": "Already connected",
        "gui.userGuide.button.confirm": "OK",
        "gui.userGuide.button.Iknow": "Got it",
        "gui.userGuide.button.discover": "Start explore",
        "gui.userGuide.button.example": "Examples",
        "gui.userGuide.button.finish": "Finish",
        "gui.userGuide.messageBox.skip": "Skip",
        "gui.brainInfo.mobile.title": "Select brain type",
        "gui.brainInfo.mobile.noSearch": "The brain cannot be found, please make sure the brain is nearby and the blue light of the bluetooth module is flashing and try again, or turn off the controller connected to the brain.",
        "gui.brainInfo.mobile.connect": "Connected",
        "gui.brainInfo.mobile.noConnect": "No connected",
        "gui.brainInfo.mobile.connecting": "Connecting",
        "gui.brainInfo.mobile.type": "Brain type",
        "gui.brainInfo.mobile.searching": "Searching for nearby brain...",
        "gui.brainInfo.mobile.noSearchEntry": "No available brain. Please check that the brain has set up WiFi connection and the iPad are connected to the same network.",
        "gui.crashMessage.label": "Oops! Something went wrong.",
        "gui.crashMessage.description": "We are so sorry, but it looks like %APP_NAME% has crashed. Please refresh your page to try again.",
        "gui.crashMessage.reload": "Reload",
        "gui.brainDriverHint.content": "The driver has not been installed. The software cannot connect to the brain.",
        "gui.brainDriverHint.button": "Install",
        "gui.brainDriverHint.install.dialog": "You need to close the software before installing the driver. After installation, you need to manually restart the software. Are you sure you want to close the software to perform the installation?",
        "gui.device.name.rule.required": "Empty name is not permitted.",
        "gui.device.name.rule.duplicate": "Duplicate name is not permitted.",
        "gui.device.name.rule.nameOverflow": "Up to 20 characters are permitted in a name.",
        "gui.device.name.rule.symbol": "Symbols are not permitted in a name.",
        "gui.customProcedures.myblockModalTitle": "My blocks",
        "gui.customProcedures.addAnInputNumber": "Add number",
        "gui.customProcedures.addAnInputText": "Add text",
        "gui.customProcedures.addAnInputBoolean": "Add boolean",
        "gui.customProcedures.addALabel": "Add label",
        "gui.customProcedures.ok": "Add",
        "gui.block.CommentDefaultText": "Comment",
        "gui.block.eventStarted.upperBound": `Each project is limited to 10 "when started" blocks.`,
        "gui.block.eventStarted.upperBound.edu": `Each project is limited to 10 "when" type blocks.`,
        "gui.block.audioSize.upperBound": "Your audio file format does not match or exceeds 100mb.",
        "gui.codeview.highLight.open": "Open highlight",
        "gui.codeview.codeEditor": "Edit code",
        "gui.codeview.dialog.save": "This action will start a new python programming. The file has not been saved yet. Do you want to save it?",
        "gui.codeview.dialog.question": "This action will open a new python file. Are you sure you want to execute it?",
        "gui.codeEditor.commandButton.importAudio": "Import audio",
        "gui.codeEditor.commandButton.audioManagement": "Audio management",
        "gui.dialog.vision.tutorial.title": "Visionl sensor setting tutorial",
        "gui.dialog.vision.tutorial.page1.hint": "Before setting up, please connect the brain",
        "gui.dialog.vision.tutorial.page1.hint2": "Connect the vision sensor to the Type-C port of the B2 brain.",
        "gui.dialog.vision.tutorial.page2.hint": `The Vision sensor can recognizes colors of objects. Click "new label" and frame the color of the object you want to recognize. If you want to reselect, just re-frame the color area, and then select the label on the right to complete the setting!`,
        "gui.dialog.vision.tutorial.page3.hint": "The Vision sensor can also be used to recognize human faces. To add a new face, use the Vision sensor to shoot or upload a photo from the file. If the person in the photo can be successfully identified, the setting is complete!",
        "gui.dialog.vision.tutorial.page4.hint": "There are also QR code detection and face detection. You can start using it without setting!",
        "gui.deviceManagerStage.page.vision.hint": "Please make sure the vision sensor is connected to the Type-C port for setting",
        "gui.deviceManagerStage.page.speaker.hint": "Please make sure the speaker sensor is connected to the Type-C port for setting",
        "gui.dialog.vision.setting.title": "Vision sensor setting",
        "gui.dialog.vision.tab.color.detection": "Color detection",
        "gui.dialog.vision.tab.qrcode.detection": "QR Code detection",
        "gui.dialog.vision.tab.face.detection": "Face detection",
        "gui.dialog.vision.tab.face.identification": "Face recognition",
        "gui.dialog.vision.tab.text.identification": "Text recognition",
        "gui.dialog.vision.color.tag.name": "Tag name",
        "gui.dialog.vision.color.detect.value": "Value",
        "gui.dialog.vision.color.clear": "Clear",
        "gui.dialog.vision.color.replace": "Replace",
        "gui.dialog.vision.color.add": "Add",
        "gui.dialog.vision.color.add.tag": "Add color tag",
        "gui.dialog.vision.color.end.tag": "Finish tagging",
        "gui.dialog.vision.color.tag.display": "Tag information",
        "gui.dialog.vision.color.detection.data.title": "Settings of color tag:",
        "gui.dialog.vision.color.reminder.title": "Settings of color tag info",
        "gui.dialog.vision.color.reminder.msg": "Vision sensor detects objects' colors. To set up, press \"add color tag\" and draw a bounding box of desiered color on the preview screen. Redraw the box to modify the selection. Click add or replace on the right list to finish the tagging.",
        "gui.dialog.vision.color.detection.result.title": "Results of color detection:",
        "gui.dialog.vision.color.detection.result.none": "No color detected",
        "gui.dialog.vision.qrcode.detection.result.title": "Results of QR code detection:",
        "gui.dialog.vision.color.edit.name.title": "Edit color name",
        "gui.dialog.vision.color.name.empty.default": "Please add color tag",
        "gui.dialog.vision.color.name.default": "COLOR",
        "gui.dialog.vision.color.edit.name.reminder.empty": "The color name cannot be empty",
        "gui.dialog.vision.color.edit.name.reminder.duplicate": "This color name already exists",
        "gui.dialog.vision.color.edit.name.reminder.symbol": "Color name cannot contain symbols",
        "gui.dialog.vision.qrcode.detection.result.title": "Results of QR code detection: ",
        "gui.dialog.vision.qrcode.detection.result.multi": "When there are multiple QR codes in the screen, the largest QR code message will be recognized",
        "gui.dialog.vision.qrcode.snapshot.msg": "Take a photo to identify the QR code in the photo",
        "gui.dialog.vision.qrcode.detection.result.none": "QR code not detected",
        "gui.dialog.vision.snapshot": "Photo identification",
        "gui.dialog.vision.back.realtime": "Preview screen",
        "gui.dialog.vision.face.detection.result.title": "Results of face detection:",
        "gui.dialog.vision.face.detection.result.multi": "When there are multiple faces in the screen, the face with the largest area will be recognized",
        "gui.dialog.vision.face.detection.result": "{number} faces detected",
        "gui.dialog.vision.face.detection.none": "No faces found",
        "gui.dialog.vision.text.detection.none": "Text not recognized",
        "gui.dialog.vision.text.detection.reminder": "Unable to recognize handwritten text",
        "gui.dialog.vision.text.detection.language.tw": "Traditional chinese",
        "gui.dialog.vision.text.detection.language.cn": "Simplified chinese",
        "gui.dialog.vision.text.detection.language.en": "English",
        "gui.dialog.vision.face.identification.regist.title": "Character setting:",
        "gui.dialog.vision.face.identification.result.title": "Results of face recognition:",
        "gui.dialog.vision.button.snapshot": "Snapshot",
        "gui.dialog.vision.button.upload": "Photo",
        "gui.dialog.vision.button.take.photo": "Take a picture",
        "gui.dialog.vision.face.snapshot.msg": "Take a photo or upload a photo to identify the person in the photo",
        "gui.dialog.vision.face.identification.privacy.title": "Privacy setting:",
        "gui.dialog.vision.face.identification.privacy.remove": "Do not save original photos",
        "gui.dialog.vision.face.identification.privacy.remove.reminder": "When saving files, the photos of the characters will be deleted while the characters can still be identified",
        "gui.dialog.vision.face.identification.privacy.save": "Save original photos",
        "gui.dialog.vision.face.identification.privacy.save.reminder": "When saving files, the photos of the characters will be saved",
        "gui.dialog.vision.face.identification.stranger": "Stranger",
        "gui.dialog.vision.face.identification.confidence": "Confidence value",
        "gui.dialog.vision.profile.edit.name.title": "Edit character name",
        "gui.dialog.vision.profile.edit.name.reminder.empty": "The character name cannot be empty",
        "gui.dialog.vision.profile.edit.name.reminder.duplicate": "This character name already exists",
        "gui.dialog.vision.profile.edit.name.reminder.symbol": "The character name cannot contain symbols",
        "gui.dialog.vision.profile.photo.add.title": "Add a photo of a person",
        "gui.dialog.vision.profile.photo.edit.title": "Edit profile photo",
        "gui.dialog.vision.profile.photo.none": "No face is detected in the photo, please take another photo",
        "gui.dialog.vision.profile.photo.multi": "More than one face is detected in the photo, please take another photo",
        "gui.dialog.vision.profile.picture.none": "No face is detected in the photo, please upload again",
        "gui.dialog.vision.profile.picture.multi": "More than one face is detected in the photo, please upload again",
        "gui.dialog.vision.profile.change.photo": "Change photo",
        "gui.dialog.vision.profile.remove.photo": "Remove photo",
        "gui.dialog.vision.profile.remove.feature": "Remove identification data",
        "gui.dialog.vision.reminder.photo.missing.title": "No photo",
        "gui.dialog.vision.reminder.photo.missing.msg": "When saving the file, it was set not to retain the original photo file but retain the ability to recognize people.",
        "gui.dialog.vision.delete.photo.title": "Delete photo",
        "gui.dialog.vision.delete.photo.msg": "Deleting a photo will delete the data that identifies this person. Are you sure you want to delete the photo?",
        "gui.dialog.vision.delete.feature.title": "Delete identification data",
        "gui.dialog.vision.delete.feature.msg": "Are you sure you want to delete the identification data?",
        "gui.dialog.vision.disconnected": "For setting to detect color, please check if the Vision sensor is connected to the B2 brain via Type-C slot.",
        "gui.dialog.vision.occupied": "Vision sensor is currently transmitting data to the Brain and cannot provide a preview screen.",
        "gui.dialog.vision.init": "Vision sensor is currently being initialized and cannot provide preview screen.",
        "gui.dialog.vision.reboot": "An unexpected error occurred, vision sensor is currently being initialized again.",
        "gui.dialog.vision.text.identification.language": "OCR language setting:",
        "gui.dialog.vision.text.identification.all": "All text:",
        "gui.dialog.vision.text.identification.shot.msg": "Take a photo to identify the text in the photo",
        "gui.dialog.vision.text.identification.partial": "Text of each block:",
        "gui.deviceManagerStage.speaker.basic": "Basic settings",
        "gui.deviceManagerStage.speaker.callWord": "Wake-up word - ",
        "gui.deviceManagerStage.speaker.callWord.reminder": `Say "Hey PINBO" or a customized chinese wake-up word to let the robot start listening for instructions.`,
        "gui.deviceManagerStage.speaker.callWord.en": "Wake-up word - ",
        "gui.deviceManagerStage.speaker.callWord.reminder.en": `Say "Hey PINBO" and the robot will start listenging`,
        "gui.deviceManagerStage.speaker.responseWord": "Response - ",
        "gui.deviceManagerStage.speaker.responseWord.reminder": "Instant response on hearing the wake-up word",
        "gui.deviceManagerStage.speaker.mic.watting": "The time to launch microphone - ",
        "gui.deviceManagerStage.speaker.mic.watting.reminder": "Set the length of time for launching microphone",
        "gui.deviceManagerStage.speaker.control.language": "The language used after waking up microphone -",
        "gui.deviceManagerStage.speaker.control.language.reminder": "Please change the language according to your customized wake-up word",
        "gui.deviceManagerStage.speaker.advance": "Advanced settings",
        "gui.deviceManagerStage.speaker.skill": "Intelligent voice skills - ",
        "gui.deviceManagerStage.speaker.skill.reminder": "Open and use voice skills",
        "gui.deviceManagerStage.speaker.skill.title": "Intelligent voice skills",
        "gui.deviceManagerStage.speaker.skill.title.reminder": "Voice skills only support chinese. Select skills to enable, download and run file on b2 brain.",
        "gui.deviceManagerStage.speaker.skill.title.reminder2": `To use skills, please say "hey, PINBO", wait for response and then you may use sentences listed below.`,
        "gui.deviceManagerStage.speaker.skill.location.default": "Default location - ",
        "gui.deviceManagerStage.speaker.skill.location.reminder": "If the location information is required but not mentioned in the voice command, the default location will be applied, for example, if you ask about the weather, the robot will respond with the weather in default location.",
        "gui.deviceManagerStage.speaker.settings": "Go to settings",
        "gui.deviceManagerStage.speaker.switch.on": "On",
        "gui.deviceManagerStage.speaker.switch.off": "Off",
        "gui.deviceManagerStage.speaker.switch.default": "Default",
        "gui.deviceManagerStage.speaker.callWord.rule1": "1. Do not use punctuation or arabic numerals",
        "gui.deviceManagerStage.speaker.callWord.rule2": "2. It is recommended to use 4~6 chinese characters",
        "gui.deviceManagerStage.speaker.callWord.rule3": "3. It is recommended that the pronunciation of each word is as different as possible",
        "gui.deviceManagerStage.speaker.responseWord.rule": "Try to use response words within 5 characters",
        "gui.dialog.speaker.customizing.title.add": "Create a dialogue",
        "gui.dialog.speaker.customizing.title.edit": "Manage the dialogues",
        "gui.dialog.speaker.customizing.group.content": `Plan how the robot will respond: set an "intention" for a question and appoint the ways to ask, then plan the robot's answers.`,
        "gui.dialog.speaker.customizing.group": "Set the group - ",
        "gui.dialog.speaker.customizing.intent.title": "Intention",
        "gui.dialog.speaker.customizing.intent.name.title": "Name of intention",
        "gui.dialog.speaker.customizing.intent.name.reminder.duplicate": "Existed intent name",
        "gui.dialog.speaker.customizing.intent.name.reminder.empty": "Intent name cannot be blank",
        "gui.dialog.speaker.customizing.intent.sentence.title": "Sentences that you want the robot to understand",
        "gui.dialog.speaker.customizing.intent.sentence.content": "Type different sentences for one intention that you want the robot to understand. Using entities in the sentences will help robot understand more than one term at one time. It is recommended that you use less than 20 words for each sentence.",
        "gui.dialog.speaker.customizing.response": "Response",
        "gui.dialog.speaker.customizing.response.sentence.title": "Sentences that you want the robot to respond",
        "gui.dialog.speaker.customizing.response.sentence.content": "The robot will answer as planned when it hears the sentences in the intention.",
        "gui.dialog.speaker.customizing.add.common": "Text",
        "gui.dialog.speaker.customizing.add.concept": "Entities",
        "gui.dialog.speaker.customizing.add.concept.limit": "(reaches the upper limit: 2)",
        "gui.dialog.speaker.group.title.add": "Create dialogue groups",
        "gui.dialog.speaker.concept": "Entities",
        "gui.dialog.speaker.concept.title.add": "Create an entity",
        "gui.dialog.speaker.concept.name": "Name of entity",
        "gui.dialog.speaker.concept.name.delete": "Delete",
        "gui.dialog.speaker.concept.name.reminder.duplicate": "Existed entity name",
        "gui.dialog.speaker.concept.name.reminder.special.word": "Cannot enter special symbols",
        "gui.dialog.speaker.concept.name.reminder.blank.word": "Cannot enter blank",
        "gui.dialog.speaker.concept.content": "entity",
        "gui.dialog.speaker.concept.content.hint": "Please fill in at least one item",
        "gui.dialog.speaker.concept.import": "Import",
        "gui.dialog.speaker.concept.export": "Example",
        "gui.dialog.speaker.concept.empty": "No entities",
        "gui.dialog.speaker.concept.response.heard.first": "Heard ",
        "gui.dialog.speaker.concept.response.heard.second": "Heard 2nd ",
        "gui.dialog.speaker.concept.response.heard.concept": "Heard entity",
        "gui.dialog.speaker.concept.response.heard.limit": "(intention use no entities)",
        "gui.dialog.speaker.concept.title.edit": "Manage the entities",
        "gui.dialog.speaker.concept.enter.name": "Please enter the name of entity",
        "gui.dialog.speaker.concept.enter.concept": "Please enter words",
        "gui.dialog.speaker.concept.enter.intention": "Please enter the name of the intention",
        "gui.dialog.speaker.default.group": "Default group",
        "gui.dialog.question.speaker.import.useless": "There are entities that are not used in dialogues in the imported file. Do you want to import them together?",
        "gui.dialog.question.speaker.import.duplicated": "There are duplicate dialogue groups, dialogues or entities in the imported file. Do you want to import them together?",
        "gui.dialog.question.speaker.import.duplicated.concept": "There are duplicate entities in the imported file. Do you want to import them together?",
        "gui.dialog.question.speaker.import.duplicated.reminder": "Both duplicate content will be retained",
        "gui.dialog.question.speaker.remove.sentence.block": "Do you want to delete dialogues blocks?",
        "gui.dialog.error.speaker.concept.up.to.limit": "The number of entities has reached the limit ({number})",
        "gui.dialog.error.speaker.sentence.up.to.limit": "The number of dialogues has reached the limit ({number})",
        "gui.dialog.error.speaker.concept.exceed": "The number of entities will exceed the limit of ({number})",
        "gui.dialog.error.speaker.sentence.exceed": "The number of dialogues will exceed the limit of ({number})",
        "gui.dialog.error.speaker.concept.upper.bound": "The maximum number of entities in a file is {number}",
        "gui.dialog.error.speaker.sentence.upper.bound": "The maximum number of dialogues in a file is {number}",
        "gui.dialog.error.extension.block.need.wifi": "Extension function blocks need to be connected to the internet to obtain data. If you use such blocks, please remember to connect to Wi-Fi.",
        "gui.vr.close": "Close",
        "gui.vr.motor.forward": "Forward",
        "gui.vr.motor.backward": "Backward",
        "gui.vr.motor.left": "Left",
        "gui.vr.motor.right": "Right",
        "gui.vr.motor.stop": "Stop",
        "gui.vr.led.on": "On",
        "gui.vr.led.off": "Off",
        "gui.vr.bumper.trigger": "Triggered",
        "gui.vr.bumper.noTrigger": "Not triggered",
        "gui.vr.buzzer.note": "Note",
        "gui.vr.buzzer.beat": "Beat",
        "gui.vr.buzzer.music": "Play song",
        "gui.vr.buzzer.music.littlestar": "Little star",
        "gui.vr.buzzer.music.win": "Win",
        "gui.vr.buzzer.music.lose": "Lose",
        "gui.vr.line.left": "Left:",
        "gui.vr.line.center": "Center:",
        "gui.vr.line.right": "Right:",
        "gui.vr.line.detected": "Detected",
        "gui.vr.line.noDetected": "Not detected",
        "gui.vr.sonar.object": "There is an object in front",
        "gui.vr.sonar.noObject": "No objects found",
        "gui.vr.sonar.distance": "Objects found {distance} cm from the front",
        "gui.vr.menuBar.buy": "Course purchase",
        "gui.contextmenu.copy": "Copy",
        "gui.contextmenu.paste": "Paste",
        "gui.boardgame.answer": "Answer",

    },

    "zh-tw": {
        "gui.gui.defaultProjectTitle": "我的專案",
        "gui.defaultProject.variable": "我的變數",
        "gui.defaultProject.variable.numeric": "我的數字變數",
        "gui.defaultProject.variable.string": "我的字串變數",
        "gui.defaultProject.broadcast": "訊息1",
        "gui.menuBar.file": "檔案",
        "gui.menuBar.new": "新建檔案",
        "gui.menuBar.open": "開啟舊檔",
        "gui.menuBar.recent": "最近使用",
        "gui.menuBar.noRecentFile": "沒有最近的檔案",
        "gui.menuBar.example": "學習範例",
        "gui.menuBar.about": "版本資訊",
        "gui.menuBar.license": "軟體授權聲明書",
        "gui.menuBar.saveToComputer": "儲存",
        "gui.menuBar.saveAs": "另存新檔",
        "gui.menuBar.png": "輸出PNG圖檔",
        "gui.menuBar.modify": "編輯",
        "gui.menuBar.redo": "重做",
        "gui.menuBar.undo": "還原上一步",
        "gui.menuBar.arrange": "對齊所有積木",
        "gui.menuBar.collapseBlock": "收合所有積木",
        "gui.menuBar.expandBlock": "展開所有積木",
        "gui.menuBar.newNote": "新增筆記",
        "gui.menuBar.clear": "刪除所有積木",
        "gui.menuBar.slot": "儲存格",
        "gui.menuBar.download": "下載",
        "gui.menuBar.downloadRun": "下載與執行",
        "gui.menuBar.run": "執行",
        "gui.menuBar.stop": "結束執行",
        "gui.menuBar.boardgame": "課程",
        "gui.menuBar.streaming": "直播",
        "gui.menuBar.brain.disconnected": "-------",
        "gui.menuBar.beginner": "新手引導",
        "gui.menuBar.direction": "積木說明",
        "gui.menuBar.deviceDirection": "裝置說明",
        "gui.menuBar.frequentlyQuestions": "PINBO Lab 常見問題",
        "gui.menuBar.course": "課程",
        "gui.menuBar.help": "資源",
        "gui.menuBar.settings": "設定",
        "gui.menuBar.account": "帳戶",
        "gui.menuBar.more": "更多",
        "gui.menuBar.codeEditor": "開啟程式碼",
        "gui.menuBar.blockEditor": "積木編輯",
        "gui.exampleLibrary.example": "範例",
        "gui.libraryTags.templates": "模板",
        "gui.libraryTags.motion": "運轉",
        "gui.libraryTags.drivetrain": "底盤",
        "gui.libraryTags.looks": "外觀",
        "gui.libraryTags.sound": "聲音",
        "gui.libraryTags.events": "事件",
        "gui.libraryTags.control": "控制",
        "gui.libraryTags.sensing": "感測",
        "gui.libraryTags.operators": "運算",
        "gui.libraryTags.variables": "變數",
        "gui.libraryTags.myblocks": "函式積木",
        "gui.libraryTags.aispeech": "智慧語音",
        "gui.libraryTags.comment": "註解",
        "gui.libraryTags.extensions": "延伸功能",
        "gui.libraryTags.edu": "B1主機",
        "gui.libraryTags.entry": "B2主機",
        "gui.libraryTags.allmonitor": "主機合體",
        "gui.deviceManagerStage.title.edu": "裝置管理",
        "gui.deviceManagerStage.title.entry": "裝置管理",
        "gui.deviceManagerStage.title.controller": "遙控器設定",
        "gui.deviceManagerStage.page.selectPort": "請選擇1個插槽",
        "gui.deviceManagerStage.page.selectTwoPorts": "請選擇2個插槽",
        "gui.deviceManagerStage.page.selectThreePorts": "請選擇3個插槽",
        "gui.deviceManagerStage.page.selectDrivetrainMotorPorts": "請選擇2個馬達插槽",
        "gui.deviceManagerStage.page.selectDrivetrainGryo": "若需使用陀螺儀，請選擇一個插槽",
        "gui.deviceManagerStage.page.drivertrain.setting.title": "請設定底盤動力系統",
        "gui.deviceManagerStage.page.drivertrain.unit.mm": "公分",
        "gui.deviceManagerStage.page.drivertrain.unit.inch": "英吋",
        "gui.deviceManagerStage.page.drivertrain.wheel.size": "輪子大小（直徑）",
        "gui.deviceManagerStage.page.drivertrain.gear.ratio": "輸出齒輪比（輸入/輸出）",
        "gui.deviceManagerStage.page.drivertrain.wheelbase.left.right": "左右輪距",
        "gui.deviceManagerStage.page.drivertrain.wheelbase.fornt.back": "前後軸距",
        "gui.deviceManagerStage.page.deleteDevice": "刪除裝置",
        "gui.deviceManagerStage.page.delete": "刪除",
        "gui.deviceManagerStage.page.selectAll": "全選",
        "gui.deviceManagerStage.page.cancel": "取消",
        "gui.deviceManagerStage.page.edit": "編輯",
        "gui.deviceManagerStage.page.done": "完成",
        "gui.deviceManager.port.disconnected": "未連裝置",
        "gui.deviceManagerStage.hint.title": "執行檔案前，請確認主機上的裝置插槽位置與此設定相同",
        "gui.deviceList.selectDeviceTitle": "請選擇欲新增的裝置",
        "gui.deviceList.deviceListHintTitle": "點擊裝置可查看裝置說明",
        "gui.deviceManagerStage.page.wheelSize": "輪子尺寸",
        "gui.deviceManagerStage.page.motorText": "修改名稱或改變方向",
        "gui.deviceManagerStage.page.deviceType": "裝置類別",
        "gui.deviceManagerStage.page.invalidName": "無效命名",
        "gui.deviceManagerStage.page.motor.forward": "正轉",
        "gui.deviceManagerStage.page.motor.reverse": "反轉",
        "gui.deviceManagerStage.edit.normal": "預設方向",
        "gui.deviceManagerStage.edit.reverse": "反向",
        "gui.deviceManagerStage.edu.drivertrain.advanced": "進階設定",
        "gui.deviceManagerStage.edu.drivertrain.advanced.description": "此設定可幫助平衡底盤馬達動力，請依實際狀態調整",
        "gui.deviceManagerStage.edu.drivertrain.advanced.donoting": "不修正",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.left": "左馬達減少",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.left.hint": "(行進時右偏)",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.right": "右馬達減少",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.right.hint": "(行進時左偏)",
        "gui.device.bumper": "碰撞感測器",
        "gui.device.led": "LED燈",
        "gui.device.motor": "馬達",
        "gui.device.threeWireMotor": "馬達",
        "gui.device.lightSensor": "光感測器",
        "gui.device.lineTracker": "循線感測器",
        "gui.device.buzzer": "蜂鳴器",
        "gui.device.ultrasonic": "超音波距離感測器",
        "gui.device.drivetrain": "底盤動力系統",
        "gui.device.drivetrain.gyro": "底盤陀螺儀",
        "gui.device.b1": "B1主機",
        "gui.device.b2": "B2主機",
        "gui.device.motor100": "馬達",
        "gui.device.motor300": "馬達",
        "gui.device.colorSensor": "色彩感測器",
        "gui.device.touchLed": "觸碰LED感測器",
        "gui.device.gyro": "陀螺儀",
        "gui.device.vision": "視覺感測器",
        "gui.device.speaker": "智慧語音感測器",
        "gui.device.no.device": "沒有裝置",
        "gui.controller.buttonList.setControllerTitle": "請設定您的馬達遙控",
        "gui.controller.buttonList.switchDirection": "切換方向",
        "gui.controller.buttonList.emptyDeviceOption": "請先新增{device}",
        "gui.controller.buttonList.chooseDeviceOption": "請選擇一個裝置",
        "gui.controller.newController": "新增遙控器",
        "gui.prompt.symbolsNotAllowed": "請移除符號只使用文字",
        "gui.prompt.letterRequired": "開頭需要英文",
        "gui.prompt.nameRequired": "可輸入中文、英文、數字、除 (\\\\|/?\"*:<>.) 以外的符號",
        "gui.prompt.variable.duplicate": "該名稱已經存在",
        "gui.prompt.list.duplicate": "該名稱已經存在",
        "gui.prompt.number.duplicate": "該名稱已經存在",
        "gui.prompt.string.duplicate": "該名稱已經存在",
        "gui.prompt.listLength": "欄位數量(1-10)",
        "gui.prompt.noVariable": "目前沒有變數",
        "gui.prompt.noList": "目前沒有清單",
        "gui.prompt.noSounds": "目前沒有音檔",
        "gui.prompt.variable.blockUseHint": "末端顯示的數字，為該變數在工作區的數目。",
        "gui.prompt.list.blockUseHint": "末端顯示的數字，為該清單在工作區的數目。",
        "gui.prompt.audio.blockUseHint": "末端顯示的數字，為音檔積木在工作區的數目。",
        "gui.prompt.group.duplicate": "該群組名稱已經存在",
        "gui.dialog.device.help.title": "裝置說明",
        "gui.dialog.history.latest": "最近更新",
        "gui.dialog.history.version.list": "版本紀錄",
        "gui.dialog.history.version.watch": "查看",
        "gui.dialog.bottom.hint.title": "目前所使用的瀏覽器無法完整體驗PINBO Fun，請使用Chrome瀏覽器，以獲得最佳體驗。",
        "gui.dialog.bottom.hint.button": "前往下載",
        "gui.dialog.about.update": "軟體更新",
        "gui.dialog.about.firmware.update.hint": "請將主機連接電腦執行更新",
        "gui.dialog.update.current": "目前版本: ",
        "gui.dialog.update.available": "可用版本: ",
        "gui.dialog.update.title": "有可用的更新",
        "gui.dialog.update.skip": "略過",
        "gui.dialog.question.download": "仍要下載",
        "gui.dialog.update.update": "立即更新",
        "gui.dialog.question.downloadFail": "編譯中發生非預期錯誤",
        "gui.dialog.question.close": "關閉",
        "gui.dialog.question.installFail": "安裝軟體時發生錯誤",
        "gui.dialog.question.exit": "軟體關閉",
        "gui.dialog.question.restart": "重新開啟軟體",
        "gui.dialog.question.isOpen": "此動作將開啟新檔案，確定要執行嗎?",
        "gui.dialog.question.isSave": "檔案已經修改，是否儲存檔案？",
        "gui.dialog.question.isCancel": "你的檔案尚未儲存，是否要放棄編輯？",
        "gui.dialog.question.cancel": "取消",
        "gui.dialog.question.noSave": "不要儲存",
        "gui.dialog.question.save": "儲存",
        "gui.dialog.question.projectName": "是否變更檔名？",
        "gui.dialog.question.overwrite": "變更檔名",
        "gui.dialog.question.delete": "是否要刪除儲存格{index}的檔案?",
        "gui.dialog.question.reset.firmware": "重置主機後，將清除主機的儲存資料，包含軟體編程檔案，程式卡檔案與遙控設定，確定要重置主機嗎？",
        "gui.dialog.question.projectName.projectTitlePlaceholder": "專案名稱",
        "gui.dialog.question.projectName.invalid": "專案名稱不能含以下字元:",
        "gui.dialog.question.projectName.overflow": "專案名稱已達字數上限，請減少字數。",
        "gui.dialog.question.projectName.empty": "專案名稱不可為空白",
        "gui.dialog.question.switch.brain.mode.in.rfid": "主機正在執行程式卡編程，是否要中斷並開始下載",
        "gui.dialog.question.switch.brain.mode.in.bt": "主機正在執行手機遙控任務，是否要中斷並開始下載",
        "gui.dialog.question.extensionNotMatch": "檔案格式錯誤",
        "gui.dialog.question.import.title": "裝置設定",
        "gui.dialog.question.import.device": "偵測到您的主機有以下裝置 :",
        "gui.dialog.question.import.device2": "請問是否套用裝置設定於裝置管理?",
        "gui.dialog.question.import.device3": "*套用後將會取代檔案中的裝置管理設定",
        "gui.dialog.question.import.confirm": "是，套用",
        "gui.dialog.question.import.cancel": "否，略過",
        "gui.dialog.brainTypePicker.title": "新建檔案",
        "gui.dialog.brainTypePicker.reminder": "請選擇主機型態",
        "gui.dialog.brainTypePicker.open": "開啟舊檔",
        "gui.dialog.brainTypePicker.confirm": "新建檔案",
        "gui.dialog.brainTypePicker.leave": "離開軟體",
        "gui.dialog.brainTypePicker.edu": "B1主機",
        "gui.dialog.brainTypePicker.entry": "B2主機",
        "gui.dialog.brainTypePicker.entryPlus": "主機合體",
        "gui.dialog.brainTypePicker.entryPlusDesc": "當B1和B2主機組合時",
        "gui.dialog.example.open": "開啟範例檔案",
        "gui.dialog.example.copyBlock": "複製積木至工作區",
        "gui.dialog.example.error": "檔案類型不符，無法複製",
        "gui.dialog.example.description": "範例說明：",
        "gui.dialog.hint.block.help.title": "如何使用積木說明",
        "gui.dialog.hint.block.help.how.to.use": "右擊積木，即可開啟對應的積木說明喔！",
        "gui.dialog.hint.block.help.how.to.use.pad": "按住積木，即可開啟對應的積木說明喔！",
        "gui.dialog.hint.block.help.done": "我知道了",
        "gui.dialog.block.help.title": "積木說明",
        "gui.dialog.question.forceUpdate": "因韌體尚未更新，所以無法下載，請更新韌體後再試。",
        "gui.dialog.question.blockLimit": "積木數量已達上限，無法下載",
        "gui.dialog.question.brainType": "您連結主機與檔案類型不相符無法下載，請確認後再試",
        "gui.dialog.question.batteryConnected": "如果使用兩顆以上的馬達，請務必接上電池盒，並確認電量充足，才能正常使用。",
        "gui.dialog.question.batteryLowPower": "您的電池盒電量偏低，建議更換電池再使用",
        "gui.dialog.question.downloadBrainScriptTimeout": "下載失敗，請將主機重新開機，或再重新下載。",
        "gui.dialog.question.Iknow": "我知道了",
        "gui.dialog.question.update.software": "軟體版本與主機不匹配，請更新您的編程軟體。",
        "gui.dialog.question.update.firmware": "主機有新的韌體版本，請更新您的主機韌體。",
        "gui.dialog.question.delete.controller1": "目前需透過遙控器1來執行遙控，確定要刪除遙控器1？",
        "gui.dialog.error.update.network.disconnected": "網路未連線，請完成網路連線，才能更新至最新版本",
        "gui.dialog.question.force.update.firmware": "PINBO Lab版本與主機不匹配，檔案無法下載。請更新主機韌體，更新時請確認主機和電腦使用USB線連接，並使用電池盒。",
        "gui.dialog.question.force.update.software": "軟體版本與主機不匹配，使得編程檔案無法正常下載。請更新您的編程軟體。",
        "gui.dialog.question.firmware.loader.mode": "主機版本異常。請更新主機韌體，更新時請確認主機和電腦使用USB線連接，並使用電池盒。",
        "gui.dialog.question.remind.firmaware.update": "主機韌體版本過舊，請立即連接電腦更新",
        "gui.dialog.question.remind.bt.update": "藍牙模組有新的韌體版本，請連接電腦執行更新",
        "gui.dialog.question.update.firmwareAndBt": "主機與藍牙模組有新的韌體版本，請立即更新。",
        "gui.dialog.question.update.bt": "藍牙模組有新的韌體版本，請立即更新您的藍牙模組。",
        "gui.dialog.question.force.update.firmwareAndBt": "PINBO Lab版本與主機不匹配，檔案無法下載。請更新主機韌體，更新時請將藍牙模組插上主機、確認主機和電腦使用USB線連接，並使用電池盒。",
        "gui.dialog.question.vr.close.fullscreen": "請縮小視窗才能使用新手引導",
        "gui.dialog.question.vr.remind": "螢幕解析度過小，請調整解析度或瀏覽器視窗比例進行操作。",
        "gui.dialog.question.vr.remind1": "備註：可使用Ctrl+滾輪來調整螢幕比例。",
        "gui.dialog.error.block.break.position.error": "由於專案中的“退出迴圈”積木位置放置錯誤，導致專案無法正常執行。所有“退出迴圈”積木都必須放在迴圈類積木內部。",
        "gui.dialog.error.brain.status.mode": "無法執行此任務，請確認主機狀態後再試一次",
        "gui.dialog.error.firmware.updateFailed": "韌體更新失敗，請再試一次。",
        "gui.dialog.error.firmware.update.server.error": "伺服器維護中，請稍後再嘗試更新主機韌體。",
        "gui.dialog.error.network.disconnected": "網路連線異常，請檢查。",
        "gui.dialog.error.erase.slot.failed": "刪除儲存格失敗，請再試一次",
        "gui.dialog.error.file.duplicate": "已經有同名的檔案存在，請使用「另存新檔」覆蓋檔案，或更換為其他檔名。",
        "gui.dialog.error.file.noExist": "檔案已不存在",
        "gui.dialog.error.update.fail": "軟體更新失敗，請再試一次。",
        "gui.dialog.error.update.server.noRespond": "伺服器沒有回應，請再試一次。",
        "gui.dialog.error.Iknow": "我知道了",
        "gui.dialog.error.bt.update.fail": "藍牙模組更新失敗，請確認連接正常後再試一次",
        "gui.dialog.error.bt.update.fail.continue.update.edu": "藍牙韌體更新失敗，請確認稍後再試",
        "gui.dialog.error.bt.update.fail.continue.update.edu2": "請勿移除主機，將繼續為您更新主機",
        "gui.dialog.error.bt.update.fail.disconnect.module": "藍牙模組更新失敗，請將主機連接線拔起並移除電池關機，等待5秒後重新連接",
        "gui.dialog.error.bt.update.fail.dfu.timeout": "藍牙韌體更新失敗，請將主機連接線拔起並移除電池關機，等待5秒後重新連接",
        "gui.dialog.error.rc.update.fail": "遙控器更新失敗，請確認連接正常後再試一次",
        "gui.dialog.error.rc.update.fail.dfu.timeout": "遙控器更新失敗，請將遙控器連接線拔起，等待5秒後重新連接",
        "gui.dialog.error.firmware.update.timeout": "韌體更新失敗，請將主機連接線拔起並移除電池關機，等待5秒後重新連接",
        "gui.dialog.error.connecting.failed": "主機目前無法使用，請稍後再試",
        "gui.dialog.error.xlsx.sheetname.invalid": "字詞表格格式不正確",
        "gui.dialog.error.update.file.crash": "更新時發生錯誤，檔案已毀損，請手動至官網下載安裝檔案。",
        "gui.dialog.error.reupload": "重新上傳",
        "gui.dialog.whatsNew.title": "新功能上線",
        "gui.dialog.whatsNew.Iknow": "我知道了",
        "gui.dialog.editBlock.title.audio": "管理音檔",
        "gui.dialog.editBlock.title.variable": "管理變數",
        "gui.dialog.editBlock.title.list": "管理清單",
        "gui.dialog.confirm": "確定",
        "gui.dialog.wifi.title": "WiFi網路設定",
        "gui.dialog.wifi.scaning": "搜尋中...",
        "gui.dialog.wifi.empty.hint": "尚未搜尋到可用的WiFi訊號，請搜尋其他網路再試一次",
        "gui.dialog.wifi.other": "其他網路",
        "gui.dialog.wifi.safe": "安全",
        "gui.dialog.wifi.unknown": "未知",
        "gui.dialog.wifi.connecting": "連線中",
        "gui.dialog.wifi.connected": "已連線",
        "gui.dialog.wifi.disconnected": "未連線",
        "gui.dialog.wifi.interrupt": "中斷",
        "gui.dialog.wifi.status.disable": "未開啟",
        "gui.dialog.wifi.status.noSetting": "未設定",
        "gui.dialog.wifi.status.scanning": "未連線",
        "gui.dialog.wifi.status.connecting": "連線中",
        "gui.dialog.wifi.status.completed": "已連線",
        "gui.dialog.wifi.status.authFail": "認證錯誤",
        "gui.dialog.wifi.status.linkFail": "連線錯誤",
        "gui.dialog.update.rc.title.page1": "遙控器韌體更新",
        "gui.dialog.update.rc.hint.page1": "遙控器有新的韌體版本，請確認更新前僅連接一台遙控器然後按“下一步”",
        "gui.dialog.update.rc.next": "下一步",
        "gui.dialog.update.rc.skip": "略過",
        "gui.dialog.update.rc.title.page2": "請依照以下步驟執行更新",
        "gui.dialog.update.rc.hint.page2": "1.請將遙控器關機並移除連接線",
        "gui.dialog.update.rc.cancel": "取消更新",
        "gui.dialog.update.rc.hint.page3": "2.按著L3與R3不放，並重新接上micro USB線，直到遙控器顯示藍色與紫色燈閃爍即可放開",
        "gui.dialog.update.rc.title.page4": "韌體更新",
        "gui.dialog.update.rc.hint.page4": "當遙控器顯示白燈閃爍代表開始進行更新，過程中請勿移除USB以免造成遙控器損毀",
        "gui.dialog.update.rc.iknow": "我知道了",
        "gui.dialog.wifi.connect": "連線",
        "gui.dialog.wifi.interupt": "中斷連線",
        "gui.dialog.wifi.name": "WiFi名稱",
        "gui.dialog.wifi.password": "WiFi密碼",
        "gui.dialog.wifi.password.error": "密碼輸入錯誤",
        "gui.dialog.streaming.title": "直播",
        "gui.dialog.streaming.reminder": "執行檔案時使用影像辨識功能將會中斷直播",
        "gui.dialog.streaming.index.screen": "主機螢幕：",
        "gui.dialog.streaming.index.ssn": "主機名稱：",
        "gui.dialog.streaming.index.battery": "電量：",
        "gui.dialog.streaming.index.connected": "是否連接B1主機：",
        "gui.dialog.streaming.index.wifi": "WiFi強度：",
        "gui.dialog.streaming.index.wifi.signal.high": "強",
        "gui.dialog.streaming.index.wifi.signal.mid": "中",
        "gui.dialog.streaming.index.wifi.signal.low": "弱",
        "gui.dialog.streaming.index.wifi.signal.weak": "極弱",
        "gui.dialog.streaming.index.vision": "視覺感測器畫面：",
        "gui.dialog.streaming.vision.disconnected.reminder": "請確認視覺感測器已連接至主機的type-C孔",
        "gui.dialog.streaming.vision.popup.reminder": "在視覺感測器視窗播放中",
        "gui.dialog.streaming.vision.photo.saved": "已儲存至下載目錄",
        "gui.dialog.streaming.index.sensorinfo": "裝置資訊:",
        "gui.dialog.streaming.index.controller": "遙控器:",
        "gui.dialog.streaming.popup.title": "視覺感測器畫面",
        "gui.dialog.streaming.brain.disconnected": "請確認已連接B2主機",
        "gui.dialog.streaming.connected.b1.already": "已連接B1",
        "gui.dialog.streaming.connected.b1.none": "未連接B1",
        "gui.dialog.streaming.button": "按鍵",
        "gui.dialog.streaming.button.press": "壓下",
        "gui.dialog.streaming.button.left.joystick": "左搖桿",
        "gui.dialog.streaming.button.right.joystick": "右搖桿",
        "gui.dialog.streaming.button.joystick.up": "上",
        "gui.dialog.streaming.button.joystick.down": "下",
        "gui.dialog.streaming.button.joystick.left": "左",
        "gui.dialog.streaming.button.joystick.right": "右",
        "gui.dialog.directNew.block": "此動作將開啟新的專案，確定要執行嗎？",
        "gui.dialog.directNew.block.save": "此動作將開啟新的專案，目前檔案尚未儲存，是否要儲存？",
        "gui.dialog.ai.speech.group.delete": "刪除[{groupName}]以及群組中的積木?",
        "gui.dialog.ai.speech.IntentArray.delete": "刪除自訂對話積木?",
        "gui.dialog.wifi.direction1": "請確認主機為B2",
        "gui.dialog.wifi.direction2": "請確認已設定主機的WiFi，並將平板連至同一WiFi",
        "gui.dialog.wifi.direction2.pc": "請確認已設定主機的WiFi，並將電腦連至同一WiFi",
        "gui.dialog.wifi.direction3": "請確認主機未與其他PC或平板連線",
        "gui.dialog.wifi.direction4": "若已完成以上確認仍找不到主機，請點擊\"輸入主機IP\"以進行連線",
        "gui.dialog.wifi.setting.direction1": "請在主機上的[首頁]>[設定]>[WiFi設定]頁面中，將[熱點連線模式]設定為開啟",
        "gui.dialog.wifi.setting.direction2": "請將電腦或平板的WiFi連至”pinboXXXXXX”網路，並回到此畫面點擊下一步。",
        "gui.dialog.wifi.setting.direction4": "請在主機上的[首頁]>[設定]>[WiFi設定]頁面中查看是否顯示[已連線]，或是[連接WiFi]是否為您選擇的WiFi。",
        "gui.dialog.wifi.setting.direction5.pc": "請將電腦連線至與主機相同的WiFi，並返回此app選擇要連線的主機。若找不到同網路下的主機，可輸入主機上顯示的IP來連線。",
        "gui.dialog.wifi.setting.direction5": "請將平板連線至與主機相同的WiFi，並返回此app選擇要連線的主機。若找不到同網路下的主機，可輸入主機上顯示的IP來連線。",
        "gui.dialog.wifi.next": "下一步",
        "gui.dialog.wifi.retry": "重新設定",
        "gui.dialog.wifi.setting.title.pad": "選擇主機要使用的WiFi",
        "gui.dialog.wifi.setting.title.pc": "選擇主機要使用的WiFi",
        "gui.dialog.wifi.setting.nofound": "尚未搜尋到可用的WiFi訊號，請搜尋其他網路再試一次",
        "gui.dialog.wifi.setting.other": "其他網路...",
        "gui.dialog.wifi.setting.finish": "完成",
        "gui.dialog.wifi.setting.ip.title1": "若主機已連線至WiFi，可以在主機上的",
        "gui.dialog.wifi.setting.ip.title2": "[首頁]>[設定]>[WiFi設定]頁面上找到主機IP",
        "gui.dialog.wifi.setting.connect": "連線",
        "gui.dialog.wifi.setting.connecting": "連線中...",
        "gui.dialog.wifi.setting.connect.error": "連線失敗，請確認主機未與其他PC或平板連線",
        "gui.dialog.wifi.setting.connect.ip.error": "IP格式錯誤，請重新輸入",
        "gui.hint.deleteBlocks.confirm": "確定",
        "gui.firmware.brain.firmwareTitle": "韌體版本",
        "gui.firmware.brain.updateTitle": "更新狀態",
        "gui.firmware.brain.modelTitle": "主機型號",
        "gui.firmware.brain.isModelDifferent": "與目前主機的型號不符",
        "gui.firmware.brain.upToDate": "已是最新版本",
        "gui.firmware.brain.updateNow": "立即更新",
        "gui.firmware.brain.restore": "重置主機",
        "gui.firmware.brain.brainName": "主機名稱",
        "gui.firmware.brain.ap.title": "可連線主機",
        "gui.firmware.brain.ap.text": "目前同網域B2主機",
        "gui.firmware.brain.ap.reminder": "若要用WiFi連線主機， 請移除與電腦的實體連線",
        "gui.firmware.brain.ap.setting": "遠端連線設定",
        "gui.firmware.brain.ap.ip": "輸入主機IP",
        "gui.firmware.brain.ap.not.found": "找不到主機？",
        "gui.brainInfo.mobile.setting": "設定主機WiFi (不使用USB連接線)",
        "gui.firmware.dialog.firmwareUpdate": "韌體更新中",
        "gui.firmware.dialog.firmwareUpdate.warning": "韌體更新中，請勿移除USB線，並維持電源供應，以免造成主機損毀。",
        "gui.audio.loading.dialog.loading": "音檔匯入中...",
        "gui.file.processing.dialog.processing": "檔案處理中，請稍後再試",
        "gui.file.processing.dialog.processing.time": "(最多需等待60秒)",
        "gui.firmware.controller.name": "遙控器名稱",
        "gui.howtos.intro-move-sayhello-hat.name": "新手引導",
        "gui.modal.backHome": "回主頁",
        "gui.prompt.submit": "提交",
        "gui.userGuide.title.welcome": "%APP_NAME%",
        "gui.userGuide.title.blockGuide": "認識程式積木區",
        "gui.userGuide.title.howToUseBlock": "如何使用程式積木？",
        "gui.userGuide.title.addBlock": "動手試試看！",
        "gui.userGuide.title.addBlockFinish": "恭喜您學會新增程式積木！那麼當有不想用的積木，該怎麼做？",
        "gui.userGuide.title.deleteBlock": "再動手試試看！",
        "gui.userGuide.title.deviceManagerGuide": "如何讓專案有更多的應用？",
        "gui.userGuide.title.newDevice": "如何新增裝置（感測器）？",
        "gui.userGuide.title.newDeviceSelectPort": "如何讓裝置（感測器）可以被正確使用？",
        "gui.userGuide.title.newDeviceFinish": "恭喜您已經成功新增裝置（感測器）！",
        "gui.userGuide.title.letDeviceWork": "試著拖拉馬達積木吧！",
        "gui.userGuide.title.letDeviceWorkFinish": "恭喜您學會了一些程式的基礎知識，試著讓造型動起來吧！",
        "gui.userGuide.title.introBrain": "來學會如何讓主機執行程式吧！",
        "gui.userGuide.title.openBluetoothSetting": "開啟藍牙功能",
        "gui.userGuide.title.bluetoothConnecting": "請連接您的主機",
        "gui.userGuide.title.introSlot": "查看主機內的檔案",
        "gui.userGuide.title.downloadAndRun": "讓主機執行馬達程式吧！",
        "gui.userGuide.title.downloadFail": "無法將程式檔案下載至主機",
        "gui.userGuide.title.reconnect": "請連接您的主機",
        "gui.userGuide.title.disconnect": "哎呀！還是找不到主機",
        "gui.userGuide.title.introDownloadAndRun": "學習如何讓主機執行馬達程式",
        "gui.userGuide.title.Finish": "太棒了！您已經學會基本操作！",
        "gui.userGuide.title.toolbarGuide": "模擬與場景控制",
        "gui.userGuide.title.selectSceneAndEdit": "場景選擇與編輯",
        "gui.userGuide.title.putObject": "放置物件",
        "gui.userGuide.title.putTerrain": "放置地板",
        "gui.userGuide.title.drawTrace": "繪製循線",
        "gui.userGuide.title.introSensors": "太棒了！你已經學會基本操作",
        "gui.userGuide.message.welcome": "歡迎來到%APP_NAME%，跟著以下步驟，一起來認識%APP_NAME%吧！",
        "gui.userGuide.message.blockGuide": "這是程式積木區，有不同功能的程式積木，讓您可以組合出各式各樣的程式情境，來學學如何使用吧！",
        "gui.userGuide.message.howToUseBlock": "當要使用積木時，需** 點選並拖拉積木至工作區 **，換您試試看。",
        "gui.userGuide.message.addBlock": "請試著將程式積木** 拖拉至「當開始」下方 **，「當開始」是所有動作的第一步喔！",
        "gui.userGuide.message.addBlockFinish": "您可以** 拖回積木區刪除 **，換您試試看。",
        "gui.userGuide.message.deleteBlock": "請點擊不想用的積木並拖拉回程式積木區，直到看到** 垃圾桶標誌 **即可放開滑鼠。",
        "gui.userGuide.message.deviceManagerGuide": "這是** 裝置管理區 **，您可以透過新增裝置（感測器），產生更多與裝置（感測器）相關的程式積木，讓我們來試試新增馬達吧！",
        "gui.userGuide.message.newDevice": "首先，請先點** 馬達 **。",
        "gui.userGuide.message.newDeviceSelectPort": "接著，請選擇裝置（感測器）** 對應主機的插槽編號**。",
        "gui.userGuide.message.newDeviceFinish": "新增裝置（感測器）後，可於程式積木區看到** 馬達的程式積木 **，現在我們要讓主機連接的馬達轉動，您知道怎麼做了嗎？",
        "gui.userGuide.message.letDeviceWork": "請點選並** 拖拉馬達積木 **，將它放置在「當開始」積木之後。",
        "gui.userGuide.message.letDeviceWorkFinish": "當您完成編程，想讓造型動起來時，必須先下載程式到主機，這時務必要將主機與電腦連接唷！",
        "gui.userGuide.message.letDeviceWorkFinishPad": "想下載程式到主機，並執行您的程式時，請務必先將主機與平板連接。",
        "gui.userGuide.message.introBrain": "這個圖示會顯示與主機連接的狀態，綠色代表「有連上主機」，數字代表「主機的數量」，請將主機與電腦連接，並確認** 馬達插在主機的A1插槽 **。",
        "gui.userGuide.message.introBrainPad": "這個圖示會顯示與主機連接的狀態，綠色代表「有連上主機」，請確認將** 馬達裝置插在主機的 A1 插槽 **。",
        "gui.userGuide.message.introBrainEntry": "這個圖示會顯示與主機連接的狀態，綠色代表「有連上主機」，數字代表「主機的數量」，請將主機與電腦連接，並確認** 馬達插在主機的插槽1 **。",
        "gui.userGuide.message.introBrainEntryPad": "這個圖示會顯示平板與主機連接的狀態，綠色代表「有連上主機」，請確認** 馬達插在主機的插槽1 **。",
        "gui.userGuide.message.openBluetoothSetting": "請先開啟平板的藍牙功能，開始搜尋附近可使用的主機。",
        "gui.userGuide.message.bluetoothConnecting": "請確認主機已經接上藍牙模組並顯示** 藍燈閃爍 **，接著選擇要配對的主機，當成功連上主機後按下** 確定 **。",
        "gui.userGuide.message.wifiConnecting": "請確認主機已連上與電腦相同的WiFi，在選單中選擇要連線的主機或輸入主機上顯示的IP位址，成功連上後請按下** 確定 **。若主機沒有設定WiFi，可點擊「遠端連線設定」為主機設定。",
        "gui.userGuide.message.wifiConnectingPad": "請確認主機已連上與平板相同的WiFi，在選單中選擇要連線的主機或輸入主機上顯示的IP位址，成功連上後請按下** 確定 **。若主機沒有設定WiFi，可點擊「遠端連線設定」為主機設定。",
        "gui.userGuide.message.introSlot": "您可以在** 儲存格 **看到主機內的檔案，數字代表「程式檔案的數量」，來試試如何下載與執行程式吧！",
        "gui.userGuide.message.downloadAndRun": "請點擊** 下載與執行 **。",
        "gui.userGuide.message.downloadFail": "請於新手引導結束後，檢查主機韌體版本並進行升級，點擊「下一步」繼續了解更多新手引導。",
        "gui.userGuide.message.reconnect": "偵測不到主機，請確認正確連線後再試一次，當連接成功後按下** 確定 **。",
        "gui.userGuide.message.disconnect": "請確認主機與 USB 傳輸線都正常運作，稍後再試一次。",
        "gui.userGuide.message.introDownloadAndRun": "按下**「下載」**可將程式檔案下載到主機內，按下**「下載與執行」**，則可以立即看到程式執行的結果，讓馬達運轉唷！",
        "gui.userGuide.message.introSlotDisconnect": "接上主機後，您可以在** 儲存格 **看到主機內的檔案，數字代表「程式檔案的數量」。",
        "gui.userGuide.message.Finish": "除了控制馬達運轉，您還可以使用蜂鳴器創作樂曲，或是透過 LED 燈增添燈光效果，更多豐富的教學都在** 教學-學習範例 **，快快點擊** 開始探索 **進入%APP_NAME%的世界吧！",
        "gui.userGuide.message.toolbarGuide": "透過工具列，可以開始進行場景模擬或重置，也可以控制鏡頭來調整你想看到的視角或範圍",
        "gui.userGuide.message.selectSceneAndEdit": "可以選擇不同的場景地圖，並且編輯自己想要的場景",
        "gui.userGuide.message.putObject": "開啟物件選單，選擇想要使用的物件並且放置於場景中，可以與 PINBO（車子）做互動",
        "gui.userGuide.message.putTerrain": "選擇地板工具後，可於場景中連續放置砂紙地板，以改變地面磨擦力",
        "gui.userGuide.message.drawTrace": "配合循線感測器使用，選擇畫線工具來繪製路線，讓 PINBO（車子）跟著線段移動",
        "gui.userGuide.message.introSensors": "場景中的 PINBO（車子）包含下面各項感測器。你可以透過左邊積木區的程式積木，開始撰寫程式應用來控制你的 PINBO（車子）囉！",
        "gui.userGuide.welcome.button.skip": "略過",
        "gui.userGuide.welcome.button.start": "開始",
        "gui.userGuide.button.before": "上一步",
        "gui.userGuide.button.next": "下一步",
        "gui.userGuide.button.connectLater": "稍後再連接",
        "gui.userGuide.button.tryLater": "稍後再試",
        "gui.userGuide.button.bluetoothSetting": "前往設定",
        "gui.userGuide.button.connected": "我已經接上",
        "gui.userGuide.button.confirm": "確定",
        "gui.userGuide.button.Iknow": "我知道了",
        "gui.userGuide.button.discover": "開始探索",
        "gui.userGuide.button.example": "查看範例",
        "gui.userGuide.button.finish": "結束",
        "gui.userGuide.messageBox.skip": "略過",
        "gui.brainInfo.mobile.title": "選擇主機",
        "gui.brainInfo.mobile.noSearch": "找不到主機，請確認主機在附近且藍牙模組閃爍藍燈後再試一次，或是將與主機連線中的遙控器關機",
        "gui.brainInfo.mobile.connect": "已連線",
        "gui.brainInfo.mobile.noConnect": "未連線",
        "gui.brainInfo.mobile.connecting": "連線中",
        "gui.brainInfo.mobile.type": "主機型號",
        "gui.brainInfo.mobile.searching": "正在搜尋附近的主機...",
        "gui.brainInfo.mobile.noSearchEntry": "找不到主機，請確認已設定主機的WiFi並已將平板連至同一WiFi",
        "gui.crashMessage.label": "哎呀！發生了一些錯誤。",
        "gui.crashMessage.description": "很抱歉，看起來 %APP_NAME% 崩潰了，請重新整理你的頁面，再試一次吧！",
        "gui.crashMessage.reload": "重新載入",
        "gui.brainDriverHint.content": "尚未安裝驅動程式，軟體無法與主機連線。",
        "gui.brainDriverHint.button": "立即安裝",
        "gui.brainDriverHint.install.dialog": "需先關閉軟體，才可安裝驅動程式，安裝後需手動重啟軟體，確定要關閉軟體執行安裝嗎？",
        "gui.device.name.rule.required": "裝置名稱不可為空",
        "gui.device.name.rule.duplicate": "裝置名稱不可重複",
        "gui.device.name.rule.nameOverflow": "裝置名稱上限為20字",
        "gui.device.name.rule.symbol": "裝置名稱不可包含特殊符號",
        "gui.customProcedures.myblockModalTitle": "自訂積木",
        "gui.customProcedures.addAnInputNumber": "添加數字",
        "gui.customProcedures.addAnInputText": "添加文字",
        "gui.customProcedures.addAnInputBoolean": "添加布林值",
        "gui.customProcedures.addALabel": "添加描述",
        "gui.customProcedures.ok": "新增",
        "gui.block.CommentDefaultText": "註解",
        "gui.block.eventStarted.upperBound": "最多可以使用10個「當開始」積木",
        "gui.block.eventStarted.upperBound.edu": "最多可以使用10個「當...類型積木」",
        "gui.block.audioSize.upperBound": "您的音檔格式不符或大小超過100MB",
        "gui.codeview.highLight.open": "對照程式碼",
        "gui.codeview.codeEditor": "編輯程式碼",
        "gui.codeview.dialog.save": "此動作將開啟新的Python編程，目前檔案尚未儲存，是否要儲存?",
        "gui.codeview.dialog.question": "此動作將開啟新的Python檔案，確定要執行嗎?",
        "gui.codeEditor.commandButton.importAudio": "匯入音訊",
        "gui.codeEditor.commandButton.audioManagement": "音訊管理",
        "gui.dialog.vision.tutorial.title": "視覺感測器設定教學",
        "gui.dialog.vision.tutorial.page1.hint": "開始設定前，請連接主機",
        "gui.dialog.vision.tutorial.page1.hint2": "並將視覺感測器連接至B2主機的 Type-C 插孔",
        "gui.dialog.vision.tutorial.page2.hint": "視覺感測器可以辨識物體顏色，按下新增標籤後，在畫面中框出要辨識的顏色，若要重新選擇只要重新框線，接著於右方選擇標籤即設定完成 !",
        "gui.dialog.vision.tutorial.page3.hint": "視覺感測器還可以用來辨識人臉，按下新增人物後，選擇用視覺感測器拍攝或是從檔案上傳該人物的照片，若可成功辨識照片中的人物即設定完成 !",
        "gui.dialog.vision.tutorial.page4.hint": "另外還有QR code偵測與人臉偵測，不需要設定即可開始使用喔!",
        "gui.deviceManagerStage.page.vision.hint": "請確認有將「視覺感測器」連接至Type-C孔，以進行設定。",
        "gui.deviceManagerStage.page.speaker.hint": "請確認有將「智慧語音感測器」連接至Type-C孔，以進行設定。",
        "gui.dialog.vision.setting.title": "視覺感測器設定",
        "gui.dialog.vision.tab.color.detection": "顏色辨識",
        "gui.dialog.vision.tab.qrcode.detection": "QR Code辨識",
        "gui.dialog.vision.tab.face.detection": "人臉偵測",
        "gui.dialog.vision.tab.face.identification": "人臉辨識",
        "gui.dialog.vision.tab.text.identification": "文字辨識",
        "gui.dialog.vision.color.tag.name": "標籤名稱",
        "gui.dialog.vision.color.detect.value": "偵測值",
        "gui.dialog.vision.color.clear": "清空設定",
        "gui.dialog.vision.color.replace": "取代",
        "gui.dialog.vision.color.add": "新增",
        "gui.dialog.vision.color.add.tag": "新增顏色標籤",
        "gui.dialog.vision.color.end.tag": "結束標記",
        "gui.dialog.vision.color.tag.display": "資訊標籤呈現",
        "gui.dialog.vision.color.detection.data.title": "顏色標籤設定：",
        "gui.dialog.vision.color.reminder.title": "顏色標籤設定說明",
        "gui.dialog.vision.color.reminder.msg": "視覺感測器可以辨識物體顏色，按下新增標籤後，在畫面中框出要辨識的顏色，若要重新選擇，只要重新框線，接著於右方選擇標籤即設定完成!",
        "gui.dialog.vision.color.detection.result.title": "顏色辨識結果：",
        "gui.dialog.vision.color.detection.result.none": "未偵測到顏色",
        "gui.dialog.vision.color.name.empty.default": "請新增顏色標籤",
        "gui.dialog.vision.color.name.default": "顏色",
        "gui.dialog.vision.color.edit.name.title": "編輯顏色名稱",
        "gui.dialog.vision.color.edit.name.reminder.empty": "此顏色名稱不可為空",
        "gui.dialog.vision.color.edit.name.reminder.duplicate": "此顏色名稱已存在",
        "gui.dialog.vision.color.edit.name.reminder.symbol": "顏色名稱不可包含符號",
        "gui.dialog.vision.qrcode.detection.result.title": "QR code辨識結果：",
        "gui.dialog.vision.qrcode.detection.result.multi": "當畫面中有多個QR code時，會辨識面積最大的QR code訊息",
        "gui.dialog.vision.qrcode.snapshot.msg": "拍照以辨識照片中的QR code",
        "gui.dialog.vision.qrcode.detection.result.none": "未偵測到QR code",
        "gui.dialog.vision.snapshot": "拍照辨識",
        "gui.dialog.vision.back.realtime": "回到預覽畫面",
        "gui.dialog.vision.face.detection.result.title": "人臉偵測結果：",
        "gui.dialog.vision.face.detection.result.multi": "當畫面中有多張人臉時，會辨識面積最大的人臉",
        "gui.dialog.vision.face.detection.result": "偵測到 {number} 個人臉",
        "gui.dialog.vision.face.detection.none": "未偵測到人臉",
        "gui.dialog.vision.text.detection.none": "未辨識到文字",
        "gui.dialog.vision.text.detection.reminder": "無法辨識手寫文字",
        "gui.dialog.vision.text.detection.language.tw": "繁體中文",
        "gui.dialog.vision.text.detection.language.cn": "簡體中文",
        "gui.dialog.vision.text.detection.language.en": "英文",
        "gui.dialog.vision.face.identification.regist.title": "人物設定：",
        "gui.dialog.vision.face.identification.result.title": "人臉辨識結果：",
        "gui.dialog.vision.button.snapshot": "拍照辨識",
        "gui.dialog.vision.button.upload": "照片辨識",
        "gui.dialog.vision.button.take.photo": "拍照",
        "gui.dialog.vision.face.snapshot.msg": "拍照或上傳照片以辨識照片中的人物",
        "gui.dialog.vision.face.identification.privacy.title": "照片隱私設定：",
        "gui.dialog.vision.face.identification.privacy.remove": "不保留照片原檔案",
        "gui.dialog.vision.face.identification.privacy.remove.reminder": "儲存檔案時不會保留現在能看到的人物照片，但仍保留辨識能力",
        "gui.dialog.vision.face.identification.privacy.save": "保留照片原檔案",
        "gui.dialog.vision.face.identification.privacy.save.reminder": "儲存檔案時會保留現在能看到的人物照片",
        "gui.dialog.vision.face.identification.stranger": "陌生人",
        "gui.dialog.vision.face.identification.confidence": "信心值",
        "gui.dialog.vision.profile.edit.name.title": "編輯人物名稱",
        "gui.dialog.vision.profile.edit.name.reminder.empty": "此人物名稱不可為空",
        "gui.dialog.vision.profile.edit.name.reminder.duplicate": "此人物名稱已存在",
        "gui.dialog.vision.profile.edit.name.reminder.symbol": "人物名稱不可包含符號",
        "gui.dialog.vision.profile.photo.add.title": "新增人物照片",
        "gui.dialog.vision.profile.photo.edit.title": "更換照片",
        "gui.dialog.vision.profile.photo.none": "照片中沒有偵測到人臉，請重新拍照",
        "gui.dialog.vision.profile.photo.multi": "照片中偵測到超過一個人臉，請重新拍照",
        "gui.dialog.vision.profile.picture.none": "照片中沒有偵測到人臉，請重新上傳",
        "gui.dialog.vision.profile.picture.multi": "照片中偵測到超過一個人臉，請重新上傳",
        "gui.dialog.vision.profile.change.photo": "更換照片",
        "gui.dialog.vision.profile.remove.photo": "刪除照片",
        "gui.dialog.vision.profile.remove.feature": "刪除辨識資料",
        "gui.dialog.vision.reminder.photo.missing.title": "沒有照片",
        "gui.dialog.vision.reminder.photo.missing.msg": "曾在儲存檔案時設定不保留照片原檔但保留辨識人物的能力",
        "gui.dialog.vision.delete.photo.title": "刪除照片",
        "gui.dialog.vision.delete.photo.msg": "刪除照片會將辨識此人物的資料一併刪除，確定要刪除照片？",
        "gui.dialog.vision.delete.feature.title": "刪除辨識資料",
        "gui.dialog.vision.delete.feature.msg": "確定要刪除辨識此人物的資料？",
        "gui.dialog.vision.disconnected": "請確認視覺感測器連接至B2主機Type-C孔以進行設定",
        "gui.dialog.vision.occupied": "視覺感測器目前正在傳輸資料給主機，無法提供預覽畫面",
        "gui.dialog.vision.init": "視覺感測器目前正在初始化，無法提供預覽畫面",
        "gui.dialog.vision.reboot": "視覺感測器發生異常，目前正在重新初始化",
        "gui.dialog.vision.text.identification.language": "辨識語言設定：",
        "gui.dialog.vision.text.identification.all": "所有文字：",
        "gui.dialog.vision.text.identification.shot.msg": "拍照以辨識照片中的文字",
        "gui.dialog.vision.text.identification.partial": "各區塊文字：",
        "gui.deviceManagerStage.speaker.basic": "基本設定",
        "gui.deviceManagerStage.speaker.callWord": "喚醒詞 - ",
        "gui.deviceManagerStage.speaker.callWord.reminder": `說"Hey PINBO"或自訂的中文喚醒詞，讓機器人開始聆聽指令。`,
        "gui.deviceManagerStage.speaker.callWord.en": "Wake-up Word - ",
        "gui.deviceManagerStage.speaker.callWord.reminder.en": `Say "Hey PINBO" and the robot will start listenging`,
        "gui.deviceManagerStage.speaker.responseWord": "應聲詞 - ",
        "gui.deviceManagerStage.speaker.responseWord.reminder": "聽到喚醒詞的立即回應",
        "gui.deviceManagerStage.speaker.mic.watting": "麥克風開啟時間 - ",
        "gui.deviceManagerStage.speaker.mic.watting.reminder": "設定麥克風開啟時間長度",
        "gui.deviceManagerStage.speaker.control.language": "喚醒後聽的語言 - ",
        "gui.deviceManagerStage.speaker.control.language.reminder": "請配合自訂對話內容更換語系",
        "gui.deviceManagerStage.speaker.advance": "進階設定",
        "gui.deviceManagerStage.speaker.skill": "智慧語音技能 - ",
        "gui.deviceManagerStage.speaker.skill.reminder": "開啟即用的語音技能",
        "gui.deviceManagerStage.speaker.skill.title": "智慧語音技能",
        "gui.deviceManagerStage.speaker.skill.title.reminder": "語音技能目前僅支援中文，點選啟用技能並下載到主機執行檔案。",
        "gui.deviceManagerStage.speaker.skill.title.reminder2": "使用技能時，請對著智慧語音感測器說喚醒詞，例如“拼寶拼寶！”等待應聲回應就可以試著講下面的句子囉！",
        "gui.deviceManagerStage.speaker.skill.location.default": "預設地點 - ",
        "gui.deviceManagerStage.speaker.skill.location.reminder": "當使用到地點資訊而語音命令沒有特別指定時，將使用設定的地點，例如詢問天氣，將回應預設地點的天氣。",
        "gui.deviceManagerStage.speaker.settings": "進入設定",
        "gui.deviceManagerStage.speaker.switch.on": "開啟",
        "gui.deviceManagerStage.speaker.switch.off": "關閉",
        "gui.deviceManagerStage.speaker.switch.default": "預設值",
        "gui.deviceManagerStage.speaker.callWord.rule1": "1. 不可使用標點符號或阿拉伯數字",
        "gui.deviceManagerStage.speaker.callWord.rule2": "2. 建議用 4~6 個漢字",
        "gui.deviceManagerStage.speaker.callWord.rule3": "3. 建議每個字的發音盡量不同",
        "gui.deviceManagerStage.speaker.responseWord.rule": "盡量使用5個字以內的應聲詞",
        "gui.dialog.speaker.customizing.title.add": "建立一組自訂對話",
        "gui.dialog.speaker.customizing.title.edit": "管理自訂對話",
        "gui.dialog.speaker.customizing.group.content": "設計機器人如何回答你的問題：為一種問題設定一個「意圖」並指定各種問法，然後設定機器人的回應",
        "gui.dialog.speaker.customizing.group": "設定群組 - ",
        "gui.dialog.speaker.customizing.intent.title": "意圖",
        "gui.dialog.speaker.customizing.intent.name.title": "意圖名稱",
        "gui.dialog.speaker.customizing.intent.name.reminder.duplicate": "這個意圖名稱已存在",
        "gui.dialog.speaker.customizing.intent.name.reminder.empty": "意圖名稱不可為空白",
        "gui.dialog.speaker.customizing.intent.sentence.title": "希望機器人聽懂的句子",
        "gui.dialog.speaker.customizing.intent.sentence.content": "寫下你希望機器人聽懂的句子，相同的意圖可以有很多種說法，將不同說法分別寫在這裡。在句子中使用字詞集合，可以讓機器人一次聽懂多個字詞。建議句子長度在20個字以下，機器人會比較容易聽懂。",
        "gui.dialog.speaker.customizing.response": "回應",
        "gui.dialog.speaker.customizing.response.sentence.title": "希望機器人回應的句子",
        "gui.dialog.speaker.customizing.response.sentence.content": "當聽到意圖中的句子，機器人會照設計的句子回答。",
        "gui.dialog.speaker.customizing.add.common": "文字區段",
        "gui.dialog.speaker.customizing.add.concept": "字詞集合",
        "gui.dialog.speaker.customizing.add.concept.limit": "(已達上限:2個)",
        "gui.dialog.speaker.group.title.add": "建立對話群組",
        "gui.dialog.speaker.concept": "字詞集合",
        "gui.dialog.speaker.concept.title.add": "建立字詞集合",
        "gui.dialog.speaker.concept.name": "字詞集合名稱",
        "gui.dialog.speaker.concept.name.delete": "刪除字詞",
        "gui.dialog.speaker.concept.name.reminder.duplicate": "此字詞集合名稱已存在",
        "gui.dialog.speaker.concept.name.reminder.special.word": "不可輸入特殊符號",
        "gui.dialog.speaker.concept.name.reminder.blank.word": "不可輸入空白",
        "gui.dialog.speaker.concept.content": "字詞",
        "gui.dialog.speaker.concept.content.hint": "至少需填寫一樣",
        "gui.dialog.speaker.concept.import": "匯入字詞",
        "gui.dialog.speaker.concept.export": "下載範例",
        "gui.dialog.speaker.concept.empty": "目前無任何字詞集合",
        "gui.dialog.speaker.concept.response.heard.first": "聽到的",
        "gui.dialog.speaker.concept.response.heard.second": "聽到的第二個",
        "gui.dialog.speaker.concept.response.heard.concept": "聽到的字詞集合",
        "gui.dialog.speaker.concept.response.heard.limit": "(意圖未使用字詞集合)",
        "gui.dialog.speaker.concept.title.edit": "管理字詞集合",
        "gui.dialog.speaker.concept.enter.name": "請輸入字詞集合名稱",
        "gui.dialog.speaker.concept.enter.concept": "請輸入字詞",
        "gui.dialog.speaker.concept.enter.intention": "請輸入意圖名稱",
        "gui.dialog.speaker.default.group": "預設群組",
        "gui.dialog.question.speaker.import.useless": "匯入的檔案中有自訂對話未使用到的對話群組或字詞集合，是否要一起匯入？",
        "gui.dialog.question.speaker.import.duplicated": "有重複的自訂對話、對話群組或字詞集合，\n確定繼續匯入？",
        "gui.dialog.question.speaker.import.duplicated.concept": "有重複的字詞集合，\n確定繼續匯入？",
        "gui.dialog.question.speaker.import.duplicated.reminder": "重複的內容兩個皆會保留",
        "gui.dialog.question.speaker.remove.sentence.block": "刪除自訂對話積木？",
        "gui.dialog.error.speaker.concept.up.to.limit": "字詞集合數量已達上限({number}個)",
        "gui.dialog.error.speaker.sentence.up.to.limit": "自訂語句數量已達上限({number}個)",
        "gui.dialog.error.speaker.concept.exceed": "字詞集合數量會超過上限{number}組",
        "gui.dialog.error.speaker.sentence.exceed": "自訂對話數量會超過上限{number}組",
        "gui.dialog.error.speaker.concept.upper.bound": "一個檔案的字詞集合上限為{number}組",
        "gui.dialog.error.speaker.sentence.upper.bound": "一個檔案的自訂對話上限為{number}組",
        "gui.dialog.error.extension.block.need.wifi": "延伸功能積木需要連接網路才能取得資料，若使用此類積木，請記得開啟主機Wi-Fi",
        "gui.vr.close": "關閉",
        "gui.vr.motor.forward": "前進",
        "gui.vr.motor.backward": "後退",
        "gui.vr.motor.left": "左轉",
        "gui.vr.motor.right": "右轉",
        "gui.vr.motor.stop": "停止",
        "gui.vr.led.on": "開啟",
        "gui.vr.led.off": "關閉",
        "gui.vr.bumper.trigger": "已觸發",
        "gui.vr.bumper.noTrigger": "未觸發",
        "gui.vr.buzzer.note": "音階",
        "gui.vr.buzzer.beat": "節奏",
        "gui.vr.buzzer.music": "播放歌曲",
        "gui.vr.buzzer.music.littlestar": "小星星",
        "gui.vr.buzzer.music.win": "勝利",
        "gui.vr.buzzer.music.lose": "失敗",
        "gui.vr.line.left": "左: ",
        "gui.vr.line.center": "中: ",
        "gui.vr.line.right": "右: ",
        "gui.vr.line.detected": "偵測到",
        "gui.vr.line.noDetected": "未偵測到",
        "gui.vr.sonar.object": "前方有物件",
        "gui.vr.sonar.noObject": "沒有發現物件",
        "gui.vr.sonar.distance": "與前方距離 {distance} cm 發現物件",
        "gui.vr.menuBar.buy": "課程購買",
        "gui.contextmenu.copy": "複製",
        "gui.contextmenu.paste": "貼上",
        "gui.boardgame.answer": "解答",

    },


    "zh-cn": {
        "gui.gui.defaultProjectTitle": "我的项目",
        "gui.defaultProject.variable": "我的变量",
        "gui.defaultProject.variable.numeric": "我的数字变量",
        "gui.defaultProject.variable.string": "我的字串变量",
        "gui.defaultProject.broadcast": "信息1",
        "gui.menuBar.file": "文件",
        "gui.menuBar.new": "新建文件",
        "gui.menuBar.open": "打开文件",
        "gui.menuBar.recent": "打开最近的文件",
        "gui.menuBar.noRecentFile": "没有最近的文件",
        "gui.menuBar.example": "学习范例",
        "gui.menuBar.about": "版本信息",
        "gui.menuBar.license": "软件授权声明书",
        "gui.menuBar.saveToComputer": "保存",
        "gui.menuBar.saveAs": "另存为",
        "gui.menuBar.png": "输出PNG图像格式",
        "gui.menuBar.modify": "编辑",
        "gui.menuBar.redo": "重做",
        "gui.menuBar.undo": "撤销",
        "gui.menuBar.arrange": "对齐所有积木",
        "gui.menuBar.collapseBlock": "收起所有积木",
        "gui.menuBar.expandBlock": "展开所有积木",
        "gui.menuBar.newNote": "新建笔记",
        "gui.menuBar.clear": "删除所有积木",
        "gui.menuBar.slot": "插槽",
        "gui.menuBar.download": "下载",
        "gui.menuBar.downloadRun": "下载与运行",
        "gui.menuBar.run": "运行",
        "gui.menuBar.stop": "结束运行",
        "gui.menuBar.boardgame": "课程",
        "gui.menuBar.streaming": "直播",
        "gui.menuBar.brain.disconnected": "-------",
        "gui.menuBar.beginner": "新手引导",
        "gui.menuBar.direction": "积木说明",
        "gui.menuBar.deviceDirection": "设备说明",
        "gui.menuBar.frequentlyQuestions": "PINBO Lab 常见问题",
        "gui.menuBar.course": "课程",
        "gui.menuBar.help": "资源",
        "gui.menuBar.settings": "设置",
        "gui.menuBar.account": "账户",
        "gui.menuBar.more": "更多",
        "gui.menuBar.codeEditor": "开启程序码",
        "gui.menuBar.blockEditor": "积木编辑",
        "gui.exampleLibrary.example": "范例",
        "gui.libraryTags.templates": "模板",
        "gui.libraryTags.motion": "运动",
        "gui.libraryTags.drivetrain": "底盘",
        "gui.libraryTags.looks": "外观",
        "gui.libraryTags.sound": "声音",
        "gui.libraryTags.events": "事件",
        "gui.libraryTags.control": "控制",
        "gui.libraryTags.sensing": "传感",
        "gui.libraryTags.operators": "运算",
        "gui.libraryTags.variables": "变量",
        "gui.libraryTags.myblocks": "自制积木",
        "gui.libraryTags.aispeech": "智能语音",
        "gui.libraryTags.comment": "备注",
        "gui.libraryTags.extensions": "扩展功能",
        "gui.libraryTags.edu": "B1主机",
        "gui.libraryTags.entry": "B2主机",
        "gui.libraryTags.allmonitor": "主机合体",
        "gui.deviceManagerStage.title.edu": "设备管理",
        "gui.deviceManagerStage.title.entry": "设备管理",
        "gui.deviceManagerStage.title.controller": "手柄设置",
        "gui.deviceManagerStage.page.selectPort": "请选择1个接口",
        "gui.deviceManagerStage.page.selectTwoPorts": "请选择2个接口",
        "gui.deviceManagerStage.page.selectThreePorts": "请选择3个接口",
        "gui.deviceManagerStage.page.selectDrivetrainMotorPorts": "请选择2个电机接口",
        "gui.deviceManagerStage.page.selectDrivetrainGryo": "若需使用陀螺仪，请选择一个接口",
        "gui.deviceManagerStage.page.drivertrain.setting.title": "请设置动力传导系统",
        "gui.deviceManagerStage.page.drivertrain.unit.mm": "厘米",
        "gui.deviceManagerStage.page.drivertrain.unit.inch": "英寸",
        "gui.deviceManagerStage.page.drivertrain.wheel.size": "轮子大小（直径）",
        "gui.deviceManagerStage.page.drivertrain.gear.ratio": "输出齿轮比（输入/输出）",
        "gui.deviceManagerStage.page.drivertrain.wheelbase.left.right": "左右轮距",
        "gui.deviceManagerStage.page.drivertrain.wheelbase.fornt.back": "前后轴距",
        "gui.deviceManagerStage.page.deleteDevice": "删除设备",
        "gui.deviceManagerStage.page.delete": "删除",
        "gui.deviceManagerStage.page.selectAll": "全选",
        "gui.deviceManagerStage.page.cancel": "取消",
        "gui.deviceManagerStage.page.edit": "编辑",
        "gui.deviceManagerStage.page.done": "完成",
        "gui.deviceManager.port.disconnected": "未连接设备",
        "gui.deviceManagerStage.hint.title": "运行文件前，请确认主机上的设备接口位置与此设置相同",
        "gui.deviceList.selectDeviceTitle": "请选择要添加的设备",
        "gui.deviceList.deviceListHintTitle": "点击设备可查看设备说明",
        "gui.deviceManagerStage.page.wheelSize": "轮子大小",
        "gui.deviceManagerStage.page.motorText": "修改名称或改变方向",
        "gui.deviceManagerStage.page.deviceType": "设备类型",
        "gui.deviceManagerStage.page.invalidName": "无效命名",
        "gui.deviceManagerStage.page.motor.forward": "正转",
        "gui.deviceManagerStage.page.motor.reverse": "反转",
        "gui.deviceManagerStage.edit.normal": "预设方向",
        "gui.deviceManagerStage.edit.reverse": "反向",
        "gui.deviceManagerStage.edu.drivertrain.advanced": "高级设置",
        "gui.deviceManagerStage.edu.drivertrain.advanced.description": "此设定可帮助平衡底盘电机动力，请依实际状态调整",
        "gui.deviceManagerStage.edu.drivertrain.advanced.donoting": "不修正",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.left": "左电机减少",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.left.hint": "(行进时右偏)",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.right": "右电机减少",
        "gui.deviceManagerStage.edu.drivertrain.advanced.fix.right.hint": "(行进时左偏)",
        "gui.device.bumper": "触碰传感器",
        "gui.device.led": "LED指示灯",
        "gui.device.motor": "电机",
        "gui.device.threeWireMotor": "电机",
        "gui.device.lightSensor": "光传感器",
        "gui.device.lineTracker": "循线传感器",
        "gui.device.buzzer": "蜂鸣器",
        "gui.device.ultrasonic": "超声波传感器",
        "gui.device.drivetrain": "动力传导系统",
        "gui.device.drivetrain.gyro": "底盘陀螺仪",
        "gui.device.b1": "B1主机",
        "gui.device.b2": "B2主机",
        "gui.device.motor100": "电机",
        "gui.device.motor300": "电机",
        "gui.device.colorSensor": "色彩传感器",
        "gui.device.touchLed": "触碰LED传感器",
        "gui.device.gyro": "陀螺仪",
        "gui.device.vision": "视觉传感器",
        "gui.device.speaker": "智能语音传感器",
        "gui.device.no.device": "无装置",
        "gui.controller.buttonList.setControllerTitle": "请设置您的电机遥控",
        "gui.controller.buttonList.switchDirection": "切换方向",
        "gui.controller.buttonList.emptyDeviceOption": "请先添加{device}",
        "gui.controller.buttonList.chooseDeviceOption": "请选择一个设备",
        "gui.controller.newController": "添加手柄",
        "gui.prompt.symbolsNotAllowed": "请移除符号只使用文字",
        "gui.prompt.letterRequired": "请以英文开头",
        "gui.prompt.nameRequired": "可输入中文、英文、数字、除(\\\\|/?\"*:<>.)以外的字符",
        "gui.prompt.variable.duplicate": "该名称已经存在",
        "gui.prompt.list.duplicate": "该名称已经存在",
        "gui.prompt.number.duplicate": "该名称已经存在",
        "gui.prompt.string.duplicate": "该名称已经存在",
        "gui.prompt.listLength": "栏位数量(1-10)",
        "gui.prompt.noVariable": "当前没有变量",
        "gui.prompt.noList": "当前没有列表",
        "gui.prompt.noSounds": "当前没有音頻",
        "gui.prompt.variable.blockUseHint": "末端显示的数字，为该变量在工作区的数量。",
        "gui.prompt.list.blockUseHint": "末端显示的数字，为该列表在工作区的数量。",
        "gui.prompt.audio.blockUseHint": "末端显示的数字，为音频积木在工作区的数量。",
        "gui.prompt.group.duplicate": "该群组名称已经存在",
        "gui.dialog.device.help.title": "设备信息",
        "gui.dialog.history.latest": "最近更新",
        "gui.dialog.history.version.list": "版本纪录",
        "gui.dialog.history.version.watch": "查看",
        "gui.dialog.bottom.hint.title": "当前使用的浏览器无法完整体验 PINBO Fun，请使用 Chrome 浏览器，以获得最佳体验。",
        "gui.dialog.bottom.hint.button": "前往下载",
        "gui.dialog.about.update": "软件更新",
        "gui.dialog.about.firmware.update.hint": "请将主机连接电脑执行更新",
        "gui.dialog.update.current": "当前版本: ",
        "gui.dialog.update.available": "可用版本: ",
        "gui.dialog.update.title": "有可用的更新",
        "gui.dialog.update.skip": "略过",
        "gui.dialog.question.download": "仍要下载",
        "gui.dialog.update.update": "立即更新",
        "gui.dialog.question.downloadFail": "编译中发生非预期错误",
        "gui.dialog.question.close": "关闭",
        "gui.dialog.question.installFail": "安装软件时发生错误",
        "gui.dialog.question.exit": "软件关闭",
        "gui.dialog.question.restart": "重启软件",
        "gui.dialog.question.isOpen": "此动作将开启新文件，确定要执行吗?",
        "gui.dialog.question.isSave": "文件已经修改，是否保存文件？",
        "gui.dialog.question.isCancel": "您的文件尚未保存，是否要放弃编辑？",
        "gui.dialog.question.cancel": "取消",
        "gui.dialog.question.noSave": "取消保存",
        "gui.dialog.question.save": "保存",
        "gui.dialog.question.projectName": "是否修改文件名？",
        "gui.dialog.question.overwrite": "修改文件名",
        "gui.dialog.question.delete": "是否要删除插槽{index}的文件？",
        "gui.dialog.question.reset.firmware": "重置主机后，将清除主机的储存资料，包含软件编程档案，程序卡档案与遥控设定，确定要重置主机吗？",
        "gui.dialog.question.projectName.projectTitlePlaceholder": "项目名称",
        "gui.dialog.question.projectName.invalid": "项目名称不能含以下字符:",
        "gui.dialog.question.projectName.overflow": "项目名称已达字数上限，请减少字数。",
        "gui.dialog.question.projectName.empty": "项目名称不可为空白",
        "gui.dialog.question.switch.brain.mode.in.rfid": "主机正在执行程序卡编程，是否要中断并开始下载",
        "gui.dialog.question.switch.brain.mode.in.bt": "主机正在执行手机远程遥控，是否要中断并开始下载",
        "gui.dialog.question.extensionNotMatch": "文件格式错误",
        "gui.dialog.question.import.title": "设备设置",
        "gui.dialog.question.import.device": "侦测到您的主机有以下设备: ",
        "gui.dialog.question.import.device2": "请问是否将设备设置应用于设备管理?",
        "gui.dialog.question.import.device3": "*应用后将会新建或取代文件中的设备管理设置",
        "gui.dialog.question.import.confirm": "是，应用",
        "gui.dialog.question.import.cancel": "否，略过",
        "gui.dialog.brainTypePicker.title": "新建文件",
        "gui.dialog.brainTypePicker.reminder": "请选择主机型态",
        "gui.dialog.brainTypePicker.open": "打开文件",
        "gui.dialog.brainTypePicker.confirm": "新建文件",
        "gui.dialog.brainTypePicker.leave": "退出软件",
        "gui.dialog.brainTypePicker.edu": "B1主机",
        "gui.dialog.brainTypePicker.entry": "B2主机",
        "gui.dialog.brainTypePicker.entryPlus": "主机合体",
        "gui.dialog.brainTypePicker.entryPlusDesc": "当B1和B2主机组合时",
        "gui.dialog.example.open": "打开范例文件",
        "gui.dialog.example.copyBlock": "复制积木至工作区",
        "gui.dialog.example.error": "文件类型不符，无法复制",
        "gui.dialog.example.description": "范例说明：",
        "gui.dialog.hint.block.help.title": "如何使用积木说明",
        "gui.dialog.hint.block.help.how.to.use": "右击积木，即可打开对应的积木说明哦！",
        "gui.dialog.hint.block.help.how.to.use.pad": "按住积木，即可打开对应的积木说明哦！",
        "gui.dialog.hint.block.help.done": "我明白了",
        "gui.dialog.block.help.title": "积木说明",
        "gui.dialog.question.forceUpdate": "因固件还没有更新，所以无法下载，请更新固件后再试",
        "gui.dialog.question.blockLimit": "积木数量已达上限，无法下载",
        "gui.dialog.question.brainType": "您连接的主机与文件类型不相符无法下载，请确认后再试",
        "gui.dialog.question.batteryConnected": "如果使用两个以上的电机，请务必接上电池盒，并确认电量充足，才能正常使用",
        "gui.dialog.question.batteryLowPower": "您的电池盒电量偏低，建议更换电池再使用",
        "gui.dialog.question.downloadBrainScriptTimeout": "下载失败，请重启主机，或再重新下载",
        "gui.dialog.question.Iknow": "我明白了",
        "gui.dialog.question.update.software": "软件版本与主机不匹配，请更新您的编程软件",
        "gui.dialog.question.update.firmware": "主机有新的固件版本，请更新您的主机固件",
        "gui.dialog.question.delete.controller1": "当前需通过手柄１来执行遥控，确认要删除手柄１？",
        "gui.dialog.error.update.network.disconnected": "无线网络未连接，请连接网络以更新至最新版本",
        "gui.dialog.question.force.update.firmware": "PINBO Lab版本与主机不匹配，档案无法下载。 请更新主机固件，更新时请确认主机和电脑使用USB线连接，并使用电池盒。",
        "gui.dialog.question.force.update.software": "软件版本与主机不匹配，使得编程文件无法正常下载，请更新您的编程软件。",
        "gui.dialog.question.firmware.loader.mode": "主机版本异常。 请更新主机固件，更新时请确认主机和电脑使用USB线连接，并使用电池盒。",
        "gui.dialog.question.remind.firmaware.update": "主机固件版本过旧，请立即连接电脑更新",
        "gui.dialog.question.remind.bt.update": "蓝牙模块有新的固件版本，请连接电脑执行更新",
        "gui.dialog.question.update.firmwareAndBt": "主机与蓝牙模块有新的固件版本，请立即更新。",
        "gui.dialog.question.update.bt": "蓝牙模块有新的固件版本，请立即更新您的蓝牙模块。",
        "gui.dialog.question.force.update.firmwareAndBt": "PINBO Lab版本与主机不匹配，文件无法下载。 请更新主机固件，更新时请将蓝牙模块插上主机、确认主机和电脑使用USB线连接，并使用电池盒。",
        "gui.dialog.question.vr.close.fullscreen": "请缩小窗口才能使用新手引导",
        "gui.dialog.question.vr.remind": "萤幕解析度过小，请调整解析度或浏览器视窗比例进行操作。",
        "gui.dialog.question.vr.remind1": "备注：可使用Ctrl+滚轮来调整萤幕比例。",
        "gui.dialog.error.block.break.position.error": "由于程序中的“退出循环”指令块放置不对位，项目无法启动。所有“退出循环”指令块都必须放在循环语句内部。",
        "gui.dialog.error.brain.status.mode": "无法执行此任务，请确认主机状态后再试一次",
        "gui.dialog.error.firmware.updateFailed": "固件更新失败，请再试一次。",
        "gui.dialog.error.firmware.update.server.error": "服务器正在维护，请稍后再尝试更新主机固件。",
        "gui.dialog.error.network.disconnected": "无线网络连接异常，请检查。",
        "gui.dialog.error.erase.slot.failed": "清除插槽失败，请再试一次",
        "gui.dialog.error.file.duplicate": "已经有同名的文件存在，请使用「另存为」覆盖文件，或更换为其他文件名称。",
        "gui.dialog.error.file.noExist": "文件已不存在",
        "gui.dialog.error.update.fail": "软件更新失败，请再试一次。",
        "gui.dialog.error.update.server.noRespond": "服务器没有响应，请再试一次。",
        "gui.dialog.error.Iknow": "我明白了",
        "gui.dialog.error.bt.update.fail": "蓝牙模块更新失败，请确认连接正常后再试一次",
        "gui.dialog.error.bt.update.fail.continue.update.edu": "蓝牙固件更新失败，请确认稍后再试",
        "gui.dialog.error.bt.update.fail.continue.update.edu2": "请勿移除主机，将继续为您更新主机",
        "gui.dialog.error.bt.update.fail.disconnect.module": "蓝牙模块更新失败，请将主机连接线拔起并移除电池关机，等待5秒后重新连接",
        "gui.dialog.error.bt.update.fail.dfu.timeout": "蓝牙固件更新失败，请将主机连接线拔起并移除电池关机，等待5秒后重新连接",
        "gui.dialog.error.firmware.update.timeout": "固件更新失败，请将主机连接线拔起并移除电池关机，等待5秒后重新连接",
        "gui.dialog.error.rc.update.fail": "手柄更新失败，请确认连接正常后再试一次",
        "gui.dialog.error.rc.update.fail.dfu.timeout": "手柄固件更新失败，请将手柄连接线拔起，等待5秒后重新连接",
        "gui.dialog.error.connecting.failed": "主机目前无法使用，请稍后再试",
        "gui.dialog.error.xlsx.sheetname.invalid": "字词表格格式不正确",
        "gui.dialog.error.update.file.crash": "更新时发生错误，文件已毁损，请手动至官网下载安装档案。",
        "gui.dialog.error.reupload": "重新上传",
        "gui.dialog.whatsNew.title": "新功能上线",
        "gui.dialog.whatsNew.Iknow": "我明白了",
        "gui.dialog.editBlock.title.audio": "管理音频文件",
        "gui.dialog.editBlock.title.variable": "管理变量",
        "gui.dialog.editBlock.title.list": "管理列表",
        "gui.dialog.confirm": "确定",
        "gui.dialog.wifi.title": "WiFi网络设置",
        "gui.dialog.wifi.scaning": "正在搜索...",
        "gui.dialog.wifi.empty.hint": "尚未搜索到可用的WiFi信号，请搜索其他网络再试一次",
        "gui.dialog.wifi.other": "其他网络",
        "gui.dialog.wifi.safe": "安全",
        "gui.dialog.wifi.unknown": "未知",
        "gui.dialog.wifi.connecting": "正在连接",
        "gui.dialog.wifi.connected": "已连接",
        "gui.dialog.wifi.disconnected": "未连接",
        "gui.dialog.wifi.interrupt": "中断",
        "gui.dialog.wifi.status.disable": "未开启",
        "gui.dialog.wifi.status.noSetting": "未设置",
        "gui.dialog.wifi.status.scanning": "未连接",
        "gui.dialog.wifi.status.connecting": "正在连接",
        "gui.dialog.wifi.status.completed": "已连接",
        "gui.dialog.wifi.status.authFail": "认证错误",
        "gui.dialog.wifi.status.linkFail": "连接失败",
        "gui.dialog.update.rc.title.page1": "手柄固件更新",
        "gui.dialog.update.rc.hint.page1": "手柄有新的固件版本，请确认更新前仅连接一个手柄然后按“下一步”",
        "gui.dialog.update.rc.next": "下一步",
        "gui.dialog.update.rc.skip": "略过",
        "gui.dialog.update.rc.title.page2": "请依照以下步骤执行更新",
        "gui.dialog.update.rc.hint.page2": "1.请将手柄关机并移除连接线",
        "gui.dialog.update.rc.cancel": "取消更新",
        "gui.dialog.update.rc.hint.page3": "2.按着L3与R3不放，并重新接上micro USB线，直到手柄显示蓝色与紫色灯闪烁即可放开",
        "gui.dialog.update.rc.title.page4": "固件更新",
        "gui.dialog.update.rc.hint.page4": "当手柄显示白灯闪烁代表开始进行更新，过程中请勿移除USB以免造成手柄损坏",
        "gui.dialog.update.rc.iknow": "我知道了",
        "gui.dialog.wifi.connect": "连接",
        "gui.dialog.wifi.interupt": "中断连接",
        "gui.dialog.wifi.name": "WiFi名称",
        "gui.dialog.wifi.password": "WiFi密码",
        "gui.dialog.wifi.password.error": "密码输入错误",
        "gui.dialog.streaming.title": "直播",
        "gui.dialog.streaming.reminder": "执行文件时使用图像辨识功能将会中断直播",
        "gui.dialog.streaming.index.screen": "主机屏幕：",
        "gui.dialog.streaming.index.ssn": "主机名称：",
        "gui.dialog.streaming.index.battery": "电量：",
        "gui.dialog.streaming.index.connected": "是否连接B1主机：",
        "gui.dialog.streaming.index.wifi": "WiFi强度：",
        "gui.dialog.streaming.index.wifi.signal.high": "强",
        "gui.dialog.streaming.index.wifi.signal.mid": "中",
        "gui.dialog.streaming.index.wifi.signal.low": "弱",
        "gui.dialog.streaming.index.wifi.signal.weak": "极弱",
        "gui.dialog.streaming.index.vision": "视觉传感器画面：",
        "gui.dialog.streaming.vision.disconnected.reminder": "请确认视觉传感器已连接至主机的Type-C插孔",
        "gui.dialog.streaming.vision.popup.reminder": "正在视觉传感器窗口播放",
        "gui.dialog.streaming.vision.photo.saved": "已保存至下载目录",
        "gui.dialog.streaming.index.sensorinfo": "设备信息：",
        "gui.dialog.streaming.index.controller": "手柄：",
        "gui.dialog.streaming.popup.title": "视觉传感器话面",
        "gui.dialog.streaming.brain.disconnected": "请确认已连接B2主机",
        "gui.dialog.streaming.connected.b1.already": "已连接B1",
        "gui.dialog.streaming.connected.b1.none": "未连接B1",
        "gui.dialog.streaming.button": "按键",
        "gui.dialog.streaming.button.press": "压下",
        "gui.dialog.streaming.button.left.joystick": "左摇杆",
        "gui.dialog.streaming.button.right.joystick": "右摇杆",
        "gui.dialog.streaming.button.joystick.up": "上",
        "gui.dialog.streaming.button.joystick.down": "下",
        "gui.dialog.streaming.button.joystick.left": "左",
        "gui.dialog.streaming.button.joystick.right": "右",
        "gui.dialog.directNew.block": "此动作将打开新的项目，确定要执行吗？",
        "gui.dialog.directNew.block.save": "此动作将打开新的项目，当前文件尚未保存，是否要保存？",
        "gui.dialog.ai.speech.group.delete": "删除[{groupName}]以及群组中的积木?",
        "gui.dialog.ai.speech.IntentArray.delete": "删除自定义对话积木?",
        "gui.dialog.wifi.direction1": "请确认主机为B2",
        "gui.dialog.wifi.direction2": "请确认已设定主机的WiFi，并将平板连至同一WiFi",
        "gui.dialog.wifi.direction2.pc": "请确认已设定主机的WiFi，并将电脑连至同一WiFi",
        "gui.dialog.wifi.direction3": "请确认主机未与其他电脑或平板连接",
        "gui.dialog.wifi.direction4": "若已完成以上确认仍找不到主机，请点击\"输入主机IP\"以进行连线",
        "gui.dialog.wifi.setting.direction1": "请在主机上的[首页]>[设置]>[WiFi设置]页面中，将[热点连接模式]设置为开启",
        "gui.dialog.wifi.setting.direction2": "请将电脑或平板的WiFi连至”pinboXXXXXX”网路，并回到此画面点击下一步。",
        "gui.dialog.wifi.setting.direction4": "请在主机上的[主页]>[设置]>[WiFi设定]页面中查看是否显示[已连接]，或是[连接WiFi]是否为您选择的WiFi。",
        "gui.dialog.wifi.setting.direction5.pc": "请将电脑连接至与主机相同的WiFi，并返回此app选择要连线的主机。 若找不到同网络下的主机，可输入主机上显示的IP来联机。",
        "gui.dialog.wifi.setting.direction5": "请将平板连线至与主机相同的WiFi，并返回此app选择要连线的主机。 若找不到同网络下的主机，可输入主机上显示的IP来联机。",
        "gui.dialog.wifi.next": "下一步",
        "gui.dialog.wifi.retry": "重新设定",
        "gui.dialog.wifi.setting.title.pad": "选择主机要使用的WiFi",
        "gui.dialog.wifi.setting.title.pc": "选择主机要使用的WiFi",
        "gui.dialog.wifi.setting.nofound": "尚未搜索到可用的WiFi信号，请搜索其他网络再试一次",
        "gui.dialog.wifi.setting.other": "其他网络…",
        "gui.dialog.wifi.setting.finish": "完成",
        "gui.dialog.wifi.setting.ip.title1": "若主机已连接至WiFi，可以在主机上的",
        "gui.dialog.wifi.setting.ip.title2": "[首页]>[设置]>[WiFi设置]页面上找到主机IP",
        "gui.dialog.wifi.setting.connect": "连接",
        "gui.dialog.wifi.setting.connecting": "正在连接",
        "gui.dialog.wifi.setting.connect.error": "连接失败，请确认主机未与其他电脑或平板连接",
        "gui.dialog.wifi.setting.connect.ip.error": "IP格式错误，请重新输入",
        "gui.hint.deleteBlocks.confirm": "确定",
        "gui.firmware.brain.firmwareTitle": "固件版本",
        "gui.firmware.brain.updateTitle": "更新状态",
        "gui.firmware.brain.modelTitle": "主机型号",
        "gui.firmware.brain.isModelDifferent": "与当前主机的型号不符",
        "gui.firmware.brain.upToDate": "已是最新版本",
        "gui.firmware.brain.updateNow": "立即更新",
        "gui.firmware.brain.restore": "重置主机",
        "gui.firmware.brain.brainName": "主机名称",
        "gui.firmware.brain.ap.title": "可连接主机",
        "gui.firmware.brain.ap.text": "当前同网域B2主机",
        "gui.firmware.brain.ap.reminder": "如果您要用WiFi连接主机， 请删除与电脑的实体连接",
        "gui.firmware.brain.ap.setting": "远程连接设定",
        "gui.firmware.brain.ap.ip": "输入主机IP",
        "gui.firmware.brain.ap.not.found": "找不到主机？",
        "gui.brainInfo.mobile.setting": "设置主机WiFi (不使用USB连接线)",
        "gui.firmware.dialog.firmwareUpdate": "正在更新固件",
        "gui.firmware.dialog.firmwareUpdate.warning": "正在更新固件，请勿移除USB线，并维持电源供应，以免造成主机损坏",
        "gui.audio.loading.dialog.loading": "正在导入音频文件...",
        "gui.file.processing.dialog.processing": "正在处理文件，请稍后再试",
        "gui.file.processing.dialog.processing.time": "(最多需等待60秒)",
        "gui.firmware.controller.name": "手柄名称",
        "gui.howtos.intro-move-sayhello-hat.name": "新手引导",
        "gui.modal.backHome": "回主页",
        "gui.prompt.submit": "提交",
        "gui.userGuide.title.welcome": "%APP_NAME%",
        "gui.userGuide.title.blockGuide": "认识程序积木区",
        "gui.userGuide.title.howToUseBlock": "如何使用程序积木呢？",
        "gui.userGuide.title.addBlock": "动手试一试！",
        "gui.userGuide.title.addBlockFinish": "恭喜您学会添加程序积木！那么当有不想用的积木，该怎么做呢？",
        "gui.userGuide.title.deleteBlock": "再动手试一试！",
        "gui.userGuide.title.deviceManagerGuide": "如何让项目有更多的应用？",
        "gui.userGuide.title.newDevice": "如何添加设备（传感器）？",
        "gui.userGuide.title.newDeviceSelectPort": "如何让设备（传感器）可以被正确使用？",
        "gui.userGuide.title.newDeviceFinish": "恭喜您已成功添加设备（传感器）！",
        "gui.userGuide.title.letDeviceWork": "试着拖动电机积木吧！",
        "gui.userGuide.title.letDeviceWorkFinish": "恭喜您学会了一些编程的基础知识，试着让造型动起来吧！",
        "gui.userGuide.title.introBrain": "来学习如何让主机执行编程吧！",
        "gui.userGuide.title.openBluetoothSetting": "开启蓝牙功能",
        "gui.userGuide.title.bluetoothConnecting": "请连接您的主机",
        "gui.userGuide.title.introSlot": "查看主机内的文件",
        "gui.userGuide.title.downloadAndRun": "让主机执行电机程序吧！",
        "gui.userGuide.title.downloadFail": "无法将编程文件下载至主机",
        "gui.userGuide.title.reconnect": "请连接您的主机",
        "gui.userGuide.title.disconnect": "哎哟！还是找不到主机",
        "gui.userGuide.title.introDownloadAndRun": "学习如何让主机执行电机程序",
        "gui.userGuide.title.Finish": "优秀！您已经学会基本功能！",
        "gui.userGuide.title.toolbarGuide": "模拟与场景控制",
        "gui.userGuide.title.selectSceneAndEdit": "场景选择与编辑",
        "gui.userGuide.title.putObject": "放置物件",
        "gui.userGuide.title.putTerrain": "放置地板",
        "gui.userGuide.title.drawTrace": "绘制循线",
        "gui.userGuide.title.introSensors": "太棒了！你已经学会基本操作",
        "gui.userGuide.message.welcome": "欢迎来到%APP_NAME%，跟着以下步骤，一起来认识%APP_NAME%吧！",
        "gui.userGuide.message.blockGuide": "这是程序积木区，有不同功能的程序积木，让您可以组合出各式各样的脚本情境，来学学如何使用吧！",
        "gui.userGuide.message.howToUseBlock": "当要使用积木时，需** 点击并拖动积木至工作区 **，换您试试看。",
        "gui.userGuide.message.addBlock": "请试着将积木** 拖动至 “当开始” 积木下方 **，“当开始”积木是所有动作的第一步哦 。",
        "gui.userGuide.message.addBlockFinish": "您可以** 拖回积木区删除 **，换您试试看。",
        "gui.userGuide.message.deleteBlock": "请点击不想用的积木并拖动回程序积木区，直到看到** 垃圾箱图标 **即可放开鼠标。",
        "gui.userGuide.message.deviceManagerGuide": "这是** 设备管理区 **，您可以通过添加设备（传感器），产生更多与设备（传感器）相关的程序积木，让我们来试试添加电机设备吧！",
        "gui.userGuide.message.newDevice": "首先，请先点** 电机 **。",
        "gui.userGuide.message.newDeviceSelectPort": "接着，请选择设备（传感器）** 对应主机的接口编号**。",
        "gui.userGuide.message.newDeviceFinish": "添加设备（传感器）后 ，可在程序积木区看到** 电机的程序积木 **，现在我们要让主机连接的电机转动，您知道怎么做了吗？",
        "gui.userGuide.message.letDeviceWork": "请点击并** 拖动电机积木 **，将它设置在 “开始” 积木之后。",
        "gui.userGuide.message.letDeviceWorkFinish": "当您完成编程，想让造型动起来时，必须先下载程序到主机，这时务必要将主机与电脑连接唷！",
        "gui.userGuide.message.letDeviceWorkFinishPad": "想下载编程到主机，并执行您的编程时，请务必先将主机与平板连接。",
        "gui.userGuide.message.introBrain": "这个图标会显示与主机连接的状态，绿色代表「已连接主机」，数字代表「主机的数量」，请将主机与电脑连接，并确认** 电机插在主机的 A1 接口 **。",
        "gui.userGuide.message.introBrainPad": "这个图标会显示与主机连接的状态，绿色代表「已连接主机」，请确认将** 电机插在主机的 A1 接口 **。",
        "gui.userGuide.message.introBrainEntry": "这个图标会显示与主机连接的状态，绿色代表「已连接主机」，数字代表「主机的数量」，请将主机与电脑连接，并确认** 电机插在主机的接口1 **。",
        "gui.userGuide.message.introBrainEntryPad": "这个图标会显示与主机连接的状态，绿色代表「已连接主机」，请确认将** 电机设备插在主机的接口1 **。",
        "gui.userGuide.message.openBluetoothSetting": "请先开启平板的蓝牙功能，即可开始搜索附近的主机。",
        "gui.userGuide.message.bluetoothConnecting": "请确认主机已连接蓝牙模块并显示** 蓝灯闪烁 **，然后选择要配对的主机，当成功连上主机后请点击** 确定 **。",
        "gui.userGuide.message.wifiConnecting": "请确认主机已连上与电脑相同的WiFi，在菜单中选择要连线的主机或输入主机上显示的IP地址，成功连上后请按下** 确定 **。若主机没有设定WiFi，可点击「远程连接设置」为主机设置。",
        "gui.userGuide.message.wifiConnectingPad": "请确认主机已连上与平板相同的WiFi，在菜单中选择要连线的主机或输入主机上显示的IP地址，成功连上后请按下** 确定 **。若主机没有设定WiFi，可点击「远程连接设置」为主机设置。",
        "gui.userGuide.message.introSlot": "您可以在** 插槽 **看到主机内的文件，数字代表「编程文件」，来试试怎么下载与执行文件吧！",
        "gui.userGuide.message.downloadAndRun": "请点击** 下载与执行 **。",
        "gui.userGuide.message.downloadFail": "请在新手引导结束后，检查主机固件版本并进行更新，点击「下一步」继续了 解更多新手引导。",
        "gui.userGuide.message.reconnect": "侦测不到主机，请确认正确连接后再试一次，当连接成功后请按下** 确定 **。",
        "gui.userGuide.message.disconnect": "请确认主机与 USB 数据线皆正常运行，稍后再试一次。",
        "gui.userGuide.message.introDownloadAndRun": "按下**「下载」**可将编程文件下载到主机内，按下**「下载与执行」**，则可以立即看到程序执行的结果，让电机运转哦！",
        "gui.userGuide.message.introSlotDisconnect": "连接上主机后，您可以在** 插槽 **看到主机内的文件，数字代表「编程文件数」。",
        "gui.userGuide.message.Finish": "除了控制电机运转，您还可以使用蜂鸣器创作乐曲，通过 LED 指示灯添加灯光效果！更多丰富的教程都在** 教学-学习范例 **，快快点击** 开始探索 **进入%APP_NAME%的世界吧！",
        "gui.userGuide.message.toolbarGuide": "通过工具栏，可以开始进行场景模拟或重置，也可以控制镜头来调整你想看到的视角或范围",
        "gui.userGuide.message.selectSceneAndEdit": "可以选择不同的场景地图，并且编辑自己想要的场景",
        "gui.userGuide.message.putObject": "开启物件菜单，选择想要使用的物件并且放置于场景中，可以与 PINBO（车子）做互动",
        "gui.userGuide.message.putTerrain": "选择地板工具后，可在场景中连续放置砂纸地板，以改变地面磨擦力",
        "gui.userGuide.message.drawTrace": "配合循线传感器使用，选择划线工具来绘制路线，让 PINBO（车子）跟着线段移动",
        "gui.userGuide.message.introSensors": "场景中的 PINBO（车子）包含下面各项 传感器。你可以通过左边积木区的程序积木，开始撰写程序应用来控制你的 PINBO（车子）咯！",
        "gui.userGuide.welcome.button.skip": "略过",
        "gui.userGuide.welcome.button.start": "开始",
        "gui.userGuide.button.before": "上一步",
        "gui.userGuide.button.next": "下一步",
        "gui.userGuide.button.connectLater": "稍后再连接",
        "gui.userGuide.button.tryLater": "稍后再试",
        "gui.userGuide.button.bluetoothSetting": "前往设置",
        "gui.userGuide.button.connected": "我已经连接",
        "gui.userGuide.button.confirm": "确定",
        "gui.userGuide.button.Iknow": "我明白了",
        "gui.userGuide.button.discover": "开始探索",
        "gui.userGuide.button.example": "查看范例",
        "gui.userGuide.button.finish": "结束",
        "gui.userGuide.messageBox.skip": "略过",
        "gui.brainInfo.mobile.title": "选择主机",
        "gui.brainInfo.mobile.noSearch": "找不到主机，请确认主机在附近且蓝牙模块闪烁蓝灯后再试一次，或是将与主机连线中的手柄关机",
        "gui.brainInfo.mobile.connect": "已连接",
        "gui.brainInfo.mobile.noConnect": "未连接",
        "gui.brainInfo.mobile.connecting": "正在连接",
        "gui.brainInfo.mobile.type": "主机型号",
        "gui.brainInfo.mobile.searching": "正在寻找附近的主机...",
        "gui.brainInfo.mobile.noSearchEntry": "找不到主机，请确认已设定主机的WiFi并已将平板连至同一WiFi",
        "gui.crashMessage.label": "哎哟！发生了一些问题。",
        "gui.crashMessage.description": "很抱歉，%APP_NAME%出故障了。请刷新页面再试试。",
        "gui.crashMessage.reload": "重新加载",
        "gui.brainDriverHint.content": "尚未安装驱动程序，软件无法与主机连接。",
        "gui.brainDriverHint.button": "立即安装",
        "gui.brainDriverHint.install.dialog": "需先关闭软件，才可安装驱动程序，安装后需手动重启软件，确认要关闭软件执行安装吗？",
        "gui.device.name.rule.required": "设备名称不可为空",
        "gui.device.name.rule.duplicate": "设备名称不可重复",
        "gui.device.name.rule.nameOverflow": "设备名称上限为20字符",
        "gui.device.name.rule.symbol": "设备名称不可包含特殊字符",
        "gui.customProcedures.myblockModalTitle": "自定义积木",
        "gui.customProcedures.addAnInputNumber": "添加数字",
        "gui.customProcedures.addAnInputText": "添加文字",
        "gui.customProcedures.addAnInputBoolean": "添加布尔逻辑",
        "gui.customProcedures.addALabel": "添加描述",
        "gui.customProcedures.ok": "添加",
        "gui.block.CommentDefaultText": "备注",
        "gui.block.eventStarted.upperBound": "最多可以使用10个「当开始」积木",
        "gui.block.eventStarted.upperBound.edu": `最多可以使用10个「当...类型积木」`,
        "gui.block.audioSize.upperBound": "您的音频文件格式不符或大小超过100MB",
        "gui.codeview.highLight.open": "对照程序码",
        "gui.codeview.codeEditor": "编辑程序码",
        "gui.codeview.dialog.save": "此动作将打开新的Python编程，当前文件尚未保存，是否要保存？",
        "gui.codeview.dialog.question": "此动作将打开新的Python文件，确定要执行吗？",
        "gui.codeEditor.commandButton.importAudio": "导入音频",
        "gui.codeEditor.commandButton.audioManagement": "音频管理",
        "gui.dialog.vision.tutorial.title": "视觉传感器设置教学",
        "gui.dialog.vision.tutorial.page1.hint": "开始设置前，请连接主机",
        "gui.dialog.vision.tutorial.page1.hint2": "并将视觉传感器连接至B2主机的 Type-C 插孔。",
        "gui.dialog.vision.tutorial.page2.hint": "视觉传感器可以识别物体颜色，按下新建标签后，在屏幕中框选要识别的颜色，若要重新选择，只需重新框选，然后在右方选择标签即设置完成 !",
        "gui.dialog.vision.tutorial.page3.hint": "视觉传感器还可以用来识别人脸，按下新建人物后，选择用视觉传感器拍摄或是从文件上传该人物的照片，若可成功识别照片中的人物即设置完成 !",
        "gui.dialog.vision.tutorial.page4.hint": "另外还有二维码侦测与人脸侦测，不需要设置即可开始使用哦!",
        "gui.deviceManagerStage.page.vision.hint": "请确认将「视觉传感器」连接至Type-C插孔，以进行设置。",
        "gui.deviceManagerStage.page.speaker.hint": "请确认将「智能语音传感器」连接至Type-C插孔，以进行设置。",
        "gui.dialog.vision.setting.title": "视觉传感器设置",
        "gui.dialog.vision.tab.color.detection": "颜色侦测",
        "gui.dialog.vision.tab.qrcode.detection": "二维码扫码侦测",
        "gui.dialog.vision.tab.face.detection": "人脸侦测",
        "gui.dialog.vision.tab.face.identification": "人脸识别",
        "gui.dialog.vision.tab.text.identification": "文字识别",
        "gui.dialog.vision.color.tag.name": "标签名称",
        "gui.dialog.vision.color.detect.value": "侦测值",
        "gui.dialog.vision.color.clear": "清空设置",
        "gui.dialog.vision.color.replace": "取代",
        "gui.dialog.vision.color.add": "新建",
        "gui.dialog.vision.color.add.tag": "新建颜色标签",
        "gui.dialog.vision.color.end.tag": "结束标记",
        "gui.dialog.vision.color.tag.display": "信息标签呈现",
        "gui.dialog.vision.color.detection.data.title": "颜色标签设置：",
        "gui.dialog.vision.color.reminder.title": "颜色标签设置说明",
        "gui.dialog.vision.color.reminder.msg": "视觉感测器可以辨识物体颜色，按下新增标签后，在画面中框出要辨识的颜色，若要重新选择，只要重新框线，接着于右方选择标签即设定完成!",
        "gui.dialog.vision.color.detection.result.title": "颜色侦测结果：",
        "gui.dialog.vision.color.detection.result.none": "未侦测到颜色",
        "gui.dialog.vision.color.edit.name.title": "编辑颜色名称",
        "gui.dialog.vision.color.name.empty.default": "请新增颜色标签",
        "gui.dialog.vision.color.name.default": "颜色",
        "gui.dialog.vision.color.edit.name.reminder.empty": "此颜色名称不可为空",
        "gui.dialog.vision.color.edit.name.reminder.duplicate": "此颜色名称已存在",
        "gui.dialog.vision.color.edit.name.reminder.symbol": "颜色名称不可包含符号",
        "gui.dialog.vision.qrcode.detection.result.title": "二维码侦测结果：",
        "gui.dialog.vision.qrcode.detection.result.multi": "当画面中有多个二维码时，会识别面积最大的二维码信息",
        "gui.dialog.vision.qrcode.snapshot.msg": "拍照以扫码侦测照片中的二维码",
        "gui.dialog.vision.qrcode.detection.result.none": "未侦测到二维码",
        "gui.dialog.vision.snapshot": "拍照识别",
        "gui.dialog.vision.back.realtime": "回到预览画面",
        "gui.dialog.vision.face.detection.result.title": "人脸侦测结果：",
        "gui.dialog.vision.face.detection.result.multi": "当画面中有多张人脸时，会侦测面积最大的人脸",
        "gui.dialog.vision.face.detection.result": "侦测到 {number} 张人脸",
        "gui.dialog.vision.face.detection.none": "未侦测到人脸",
        "gui.dialog.vision.text.detection.none": "未识别到文字",
        "gui.dialog.vision.text.detection.reminder": "无法识别手写文字",
        "gui.dialog.vision.text.detection.language.tw": "繁体中文",
        "gui.dialog.vision.text.detection.language.cn": "简体中文",
        "gui.dialog.vision.text.detection.language.en": "英文",
        "gui.dialog.vision.face.identification.regist.title": "人物设置：",
        "gui.dialog.vision.face.identification.result.title": "人脸识别结果：",
        "gui.dialog.vision.button.snapshot": "拍照识别",
        "gui.dialog.vision.button.upload": "照片识别",
        "gui.dialog.vision.button.take.photo": "拍照",
        "gui.dialog.vision.face.snapshot.msg": "拍照或上传照片以识别照片中的人物",
        "gui.dialog.vision.face.identification.privacy.title": "照片隐私设置：",
        "gui.dialog.vision.face.identification.privacy.remove": "不保留照片原文件",
        "gui.dialog.vision.face.identification.privacy.remove.reminder": "保存文件时不会保留现在能看到的人物照片，但仍保留识别能力",
        "gui.dialog.vision.face.identification.privacy.save": "保留照片原文件",
        "gui.dialog.vision.face.identification.privacy.save.reminder": "保存文件时会保留现在能看到的人物照片",
        "gui.dialog.vision.face.identification.stranger": "陌生人",
        "gui.dialog.vision.face.identification.confidence": "信心值",
        "gui.dialog.vision.profile.edit.name.title": "编辑人物名称",
        "gui.dialog.vision.profile.edit.name.reminder.empty": "此人物名称不可为空",
        "gui.dialog.vision.profile.edit.name.reminder.duplicate": "此人物名称已存在",
        "gui.dialog.vision.profile.edit.name.reminder.symbol": "人物名称不可包含符号",
        "gui.dialog.vision.profile.photo.add.title": "添加人物照片",
        "gui.dialog.vision.profile.photo.edit.title": "更换照片",
        "gui.dialog.vision.profile.photo.none": "照片中没有侦测到人脸，请重新拍照",
        "gui.dialog.vision.profile.photo.multi": "照片中侦测到超过一个人脸，请重新拍照",
        "gui.dialog.vision.profile.picture.none": "照片中没有侦测到人脸，请重新上传",
        "gui.dialog.vision.profile.picture.multi": "照片中侦测到超过一个人脸，请重新上传",
        "gui.dialog.vision.profile.change.photo": "更换照片",
        "gui.dialog.vision.profile.remove.photo": "删除照片",
        "gui.dialog.vision.profile.remove.feature": "删除识别数据",
        "gui.dialog.vision.reminder.photo.missing.title": "没有照片",
        "gui.dialog.vision.reminder.photo.missing.msg": "曾在保存文件时设置不保留照片原文件但保留识别人物的能力",
        "gui.dialog.vision.delete.photo.title": "删除照片",
        "gui.dialog.vision.delete.photo.msg": "删除照片会将识别此人物的数据一并删除，确定要删除照片？",
        "gui.dialog.vision.delete.feature.title": "删除识别数据",
        "gui.dialog.vision.delete.feature.msg": "确定要删除识别此人物的数据？",
        "gui.dialog.vision.disconnected": "请确认视觉传感器连接至B2主机Type-C插孔以进行设置",
        "gui.dialog.vision.occupied": "视觉传感器当前正在传输数据给主机，无法提供预览画面",
        "gui.dialog.vision.init": "视觉传感器当前正在初始化，无法提供预览画面",
        "gui.dialog.vision.reboot": "视觉传感器发生错误，当前正在重新初始化",
        "gui.dialog.vision.text.identification.language": "识别语言设置：",
        "gui.dialog.vision.text.identification.all": "所有文字：",
        "gui.dialog.vision.text.identification.shot.msg": "拍照以识别照片中的文字",
        "gui.dialog.vision.text.identification.partial": "各区块文字：",
        "gui.deviceManagerStage.speaker.basic": "基本设置",
        "gui.deviceManagerStage.speaker.callWord": "唤醒词 - ",
        "gui.deviceManagerStage.speaker.callWord.reminder": `说"Hey PINBO"或自定义的中文唤醒词，让机器人开始聆听命令。`,
        "gui.deviceManagerStage.speaker.callWord.en": "Wake-up Word - ",
        "gui.deviceManagerStage.speaker.callWord.reminder.en": `Say "Hey PINBO" and the robot will start listenging`,
        "gui.deviceManagerStage.speaker.responseWord": "应声词 - ",
        "gui.deviceManagerStage.speaker.responseWord.reminder": "听到唤醒词的立即回应",
        "gui.deviceManagerStage.speaker.mic.watting": "麦克风开启时间 - ",
        "gui.deviceManagerStage.speaker.mic.watting.reminder": "设置麦克风开启时间长度",
        "gui.deviceManagerStage.speaker.control.language": "唤醒后听到的语言 - ",
        "gui.deviceManagerStage.speaker.control.language.reminder": "请配合自定义对话内容更换语系",
        "gui.deviceManagerStage.speaker.advance": "高级设置",
        "gui.deviceManagerStage.speaker.skill": "智能语音技能 - ",
        "gui.deviceManagerStage.speaker.skill.reminder": "开启即用的语音技能",
        "gui.deviceManagerStage.speaker.skill.title": "智能语音技能",
        "gui.deviceManagerStage.speaker.skill.title.reminder": "语音技能目前仅支持中文，点击启用技能并下载到主机执行文件。",
        "gui.deviceManagerStage.speaker.skill.title.reminder2": "使用技能时，请对着智能语音传感器说唤醒词，例如“拼宝拼宝！ ”等待应声回应就可以试着讲下面的句子啰！",
        "gui.deviceManagerStage.speaker.skill.location.default": "预设地点 - ",
        "gui.deviceManagerStage.speaker.skill.location.reminder": "当使用到地点信息功能时，若语音命令没有特别指定地点，将会回应预设的地点，例如询问天气，将回应预设地点的天气",
        "gui.deviceManagerStage.speaker.settings": "进入设置",
        "gui.deviceManagerStage.speaker.switch.on": "开启",
        "gui.deviceManagerStage.speaker.switch.off": "关闭",
        "gui.deviceManagerStage.speaker.switch.default": "默认值",
        "gui.deviceManagerStage.speaker.callWord.rule1": "1. 不可使用标点符号或阿拉伯数字",
        "gui.deviceManagerStage.speaker.callWord.rule2": "2. 建议用 4~6 个汉字",
        "gui.deviceManagerStage.speaker.callWord.rule3": "3. 建议每个字的发音尽量不同",
        "gui.deviceManagerStage.speaker.responseWord.rule": "尽量使用5个字以内的应声词",
        "gui.dialog.speaker.customizing.title.add": "建立一组自定义对话",
        "gui.dialog.speaker.customizing.title.edit": "管理自定义对话",
        "gui.dialog.speaker.customizing.group.content": "设计机器人如何回答你的问题：为一种问题设置一个「意图 」并指定各种问法，然后设置机器人的回应",
        "gui.dialog.speaker.customizing.group": "设置群组 - ",
        "gui.dialog.speaker.customizing.intent.title": "意图",
        "gui.dialog.speaker.customizing.intent.name.title": "意图名称",
        "gui.dialog.speaker.customizing.intent.name.reminder.duplicate": "这个意图名称已存在",
        "gui.dialog.speaker.customizing.intent.name.reminder.empty": "意图名称不可为空白",
        "gui.dialog.speaker.customizing.intent.sentence.title": "希望机器人听懂的句子",
        "gui.dialog.speaker.customizing.intent.sentence.content": "写下你希望机器人听懂的句子，相同的意图可以有很多种说法，将不同说法分别写在这里。在句子中使用字词集合，可以让机器人一次听懂多个字词。建议句子长度在20个字以下，机器人会比较容易听懂。",
        "gui.dialog.speaker.customizing.response": "回应",
        "gui.dialog.speaker.customizing.response.sentence.title": "希望机器人回应的句子",
        "gui.dialog.speaker.customizing.response.sentence.content": "当听到意图中的句子，机器人会照设计的句子回答。",
        "gui.dialog.speaker.customizing.add.common": "文字区段",
        "gui.dialog.speaker.customizing.add.concept": "字词集合",
        "gui.dialog.speaker.customizing.add.concept.limit": "(已达上限:2个)",
        "gui.dialog.speaker.group.title.add": "建立对话群组",
        "gui.dialog.speaker.concept": "字词集合",
        "gui.dialog.speaker.concept.title.add": "建立字词集合",
        "gui.dialog.speaker.concept.name": "字词集合名称",
        "gui.dialog.speaker.concept.name.delete": "删除字词",
        "gui.dialog.speaker.concept.name.reminder.duplicate": "此字词集合名称已存在",
        "gui.dialog.speaker.concept.name.reminder.special.word": "不可输入特殊符号",
        "gui.dialog.speaker.concept.name.reminder.blank.word": "不可输入空白",
        "gui.dialog.speaker.concept.content": "字词",
        "gui.dialog.speaker.concept.content.hint": "至少需填写一样",
        "gui.dialog.speaker.concept.import": "导入字词",
        "gui.dialog.speaker.concept.export": "下载范例",
        "gui.dialog.speaker.concept.empty": "当前无任何字词集合",
        "gui.dialog.speaker.concept.response.heard.first": "听到的",
        "gui.dialog.speaker.concept.response.heard.second": "听到的第二个",
        "gui.dialog.speaker.concept.response.heard.concept": "听到的字词集合",
        "gui.dialog.speaker.concept.response.heard.limit": "(意图未使用字词集合)",
        "gui.dialog.speaker.concept.title.edit": "管理字词集合",
        "gui.dialog.speaker.concept.enter.name": "请输入字词集合名称",
        "gui.dialog.speaker.concept.enter.concept": "请输入字词",
        "gui.dialog.speaker.concept.enter.intention": "请输入意图名称",
        "gui.dialog.speaker.default.group": "预设群组",
        "gui.dialog.question.speaker.import.useless": "导入的文件中有自定义对话未使用到的对话群组或字词集合，是否要一起导入？",
        "gui.dialog.question.speaker.import.duplicated": "有重复的自定义对话、对话群组或字词集合，\n确定继续导入？",
        "gui.dialog.question.speaker.import.duplicated.concept": "有重复的字词集合，\n确定继续汇入？",
        "gui.dialog.question.speaker.import.duplicated.reminder": "重复的内容两个皆会保留",
        "gui.dialog.question.speaker.remove.sentence.block": "删除自定义对话积木？",
        "gui.dialog.error.speaker.concept.up.to.limit": "字词集合数量已达上限({number}个)",
        "gui.dialog.error.speaker.sentence.up.to.limit": "自定义语句数量已达上限({number}个)",
        "gui.dialog.error.speaker.concept.exceed": "字词集合数量会超过上限{number}组",
        "gui.dialog.error.speaker.sentence.exceed": "自定义对话数量会超过上限{number}组",
        "gui.dialog.error.speaker.concept.upper.bound": "一个文件的字词集合上限为{number}组",
        "gui.dialog.error.speaker.sentence.upper.bound": "一个文件的自定义对话上限为{number}组",
        "gui.dialog.error.extension.block.need.wifi": "扩展功能积木需要连接网络才能取得数据，若使用此类积木，请记得开启主机Wi-Fi",
        "gui.vr.close": "关闭",
        "gui.vr.motor.forward": "前进",
        "gui.vr.motor.backward": "后退",
        "gui.vr.motor.left": "左转",
        "gui.vr.motor.right": "右转",
        "gui.vr.motor.stop": "停止",
        "gui.vr.led.on": "开启",
        "gui.vr.led.off": "关闭",
        "gui.vr.bumper.trigger": "已触发",
        "gui.vr.bumper.noTrigger": "未触发",
        "gui.vr.buzzer.note": "音阶",
        "gui.vr.buzzer.beat": "节奏",
        "gui.vr.buzzer.music": "播放歌曲",
        "gui.vr.buzzer.music.littlestar": "小星星",
        "gui.vr.buzzer.music.win": "胜利",
        "gui.vr.buzzer.music.lose": "失败",
        "gui.vr.line.left": "左: ",
        "gui.vr.line.center": "中: ",
        "gui.vr.line.right": "右: ",
        "gui.vr.line.detected": "侦测到",
        "gui.vr.line.noDetected": "未侦测到",
        "gui.vr.sonar.object": "前方有物件",
        "gui.vr.sonar.noObject": "没有发现物件",
        "gui.vr.sonar.distance": "与前方距离 {distance} cm 发现物件",
        "gui.vr.menuBar.buy": "课程购买",
        "gui.contextmenu.copy": "复制",
        "gui.contextmenu.paste": "粘贴",
        "gui.boardgame.answer": "解答",

    }
}


export {
    editorMsgsByAsus,
    appString
}