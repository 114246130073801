const BluetoothStatus = {
    unauthorized: "unauthorized",
    connectfail: "connectfail",
    poweredOn: "poweredOn",
    poweredOff: "poweredOff",
    scaning: "scaning",
    scanStop: "scanstop",
    connecting: "connecting",
    connected: "connected",
    disconnected: "disconnected",
    connectFail: "connectfail",
    writting: "writting"
};

const BluetoothAction = {
    unauthorized: "unauthorized",
    open: "open",
    refresh: "refresh",
    connect: "connect",
    disconnect: "disconnect",
    download: "download",
    run: "run",
    stop: "stop",
    delete: "delete",
    getDevice: "getDevice",
    getBrainRunningStatus: "getBrainRunningStatus"
};

const BluetoothDownload = {
    start: "start",
    downloading: "downloading",
    end: "end"
};

export {
    BluetoothStatus,
    BluetoothAction,
    BluetoothDownload
}