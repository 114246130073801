const colourVR = {
    colours: {
        motion: {
            primary: "#C9FF70",
            secondary: "#B0E25D",
            tertiary: "#B0E25D",
            quaternary: "#FFFFFF"
        },
        looks: {
            primary: "#88C9FF",
            secondary: "#76B1E1",
            tertiary: "#76B1E1",
            quaternary: "#FFFFFF"
        },
        sounds: {
            primary: "#C1F6FF",
            secondary: "#82D4E3",
            tertiary: "#82D4E3",
            quaternary: "#FFFFFF"
        },
        control: {
            primary: "#FFDD9A",
            secondary: "#ECCC8C",
            tertiary: "#ECCC8C",
            quaternary: "#FFFFFF"
        },
        event: {
            primary: "#53E8F9",
            secondary: "#4EBDC8",
            tertiary: "#4EBDC8",
            quaternary: "#FFFFFF"
        },
        sensing: {
            primary: "#FCF6A2",
            secondary: "#DDD55F",
            tertiary: "#DDD55F",
            quaternary: "#FFFFFF"
        },
        pen: {
            primary: "#0fBD8C",
            secondary: "#0DA57A",
            tertiary: "#0B8E69",
            quaternary: "#FFFFFF"
        },
        operators: {
            primary: "#C2BEFF",
            secondary: "#A09DD5",
            tertiary: "#A09DD5",
            quaternary: "#FFFFFF"
        },
        comments: {
            primary: "#F8D4E6",
            secondary: "#F3B2D2",
            tertiary: "#F3B2D2",
            quaternary: "#FFFFFF"
        },
        data: {
            primary: "#F9C5B7",
            secondary: "#D9A99D",
            tertiary: "#D9A99D",
            quaternary: "#FFFFFF"
        },
        drivetrain: {
            primary: "#96F1B6",
            secondary: "#74C992",
            tertiary: "#74C992",
            quaternary: "#FFFFFF"
        },
        // This is not a new category, but rather for differentiation
        // between lists and scalar variables.
        data_lists: {
            primary: "#F9C5B7",
            secondary: "#D9A99D",
            tertiary: "#D9A99D",
            quaternary: "#FFFFFF"
        },
        // my Block
        more: {
            primary: "#FFA6CB",
            secondary: "#E192B2",
            tertiary: "#E192B2",
            quaternary: "#FFFFFF"
        },
        toolboxHover: "#354052",
        categoryText: "#354052",
    }
}

export default colourVR;