import { defineMessages } from 'react-intl';
import sharedMessages from '../shared-messages';
import { DEFAULT_BLOCKID } from '../../config/block-config';

let messages = defineMessages({
    meow: {
        defaultMessage: 'Meow',
        description: 'Name for the meow sound',
        id: 'gui.defaultProject.meow'
    },
    variable: {
        defaultMessage: 'my variable',
        description: 'Name for the default variable',
        id: 'gui.defaultProject.variable'
    },
    numericVariable: {
        defaultMessage: 'my numeric variable',
        description: 'Name for the default numeric variable',
        id: 'gui.defaultProject.variable.numeric'
    },
    stringVariable: {
        defaultMessage: 'my string variable',
        description: 'Name for the default string variable',
        id: 'gui.defaultProject.variable.string'
    },
    broadcastVar: {
        defaultMessage: 'message1',
        description: 'Name for the default broadcast',
        id: 'gui.defaultProject.broadcast'
    }
});

messages = { ...messages, ...sharedMessages };

// use the default message if a translation function is not passed
const defaultTranslator = msgObj => msgObj.defaultMessage;

/**
 * Generate a localized version of the default project
 * @param {function} translateFunction a function to use for translating the default names
 * @return {object} the project data json for the default project
 */
const projectData = translateFunction => {
    const translator = translateFunction || defaultTranslator;
    return ({
        targets: [
            {
                isStage: true,
                name: 'Stage',
                variables: {
                    '`jEk@4|i[#Fk?(8x)AV.-my variable': [
                        translator(messages.numericVariable),
                        0
                    ]
                },
                string_variable: {
                    'f2a/iC7MJIkclqFTvQsb': [
                        translator(messages.stringVariable),
                        0
                    ]
                },
                lists: {},
                broadcasts: {
                    ".C7@/LFr8sl_L7P_%uE=": translator(messages.broadcastVar)
                },
                blocks: {
                    [DEFAULT_BLOCKID.event_whenstarted]: {
                        "opcode": "event_whenstarted",
                        "next": null,
                        "parent": null,
                        "inputs": {
                        },
                        "fields": {
                        },
                        "shadow": false,
                        "topLevel": true,
                        "x": document.body.offsetWidth * 0.5,
                        "y": document.body.offsetHeight * 0.4
                    }
                },
                sounds: [],
                volume: 100
            }
        ],
        meta: {
            semver: '3.0.0',
            vm: '0.1.0',
            agent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/65.0.3325.181 Safari/537.36' // eslint-disable-line max-len
        }
    });
};


export default projectData;
