import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import styles from './brain.css';
import Port from '../port/port.jsx';
import EduPoint from './edu-point.jsx';
import EduLine from './edu-line.jsx';
import VM from 'scratch-vm';
import {
    openDeviceList,
    isEditPageOpen,
    isAddPageOpen,
    isPortOpen
} from '../../../reducers/device-manager-controller';


import {
    cleanPortOption,
} from '../../../reducers/select-option';

import {
    isDeviceSelected,
    selectDeviceToDelete,
    cleanSeletedDevice,
    getSelectedDeviceIdArray,
    isDeleteDeviceMode,
    openDeleteMode,
    closeDeleteMode
} from '../../../reducers/delete-device';

import brainImg from '../pictures/brain_edu.png'

const messagesId = {
    delete: "gui.deviceManagerStage.page.delete",
    selectAll: "gui.deviceManagerStage.page.selectAll",
    cancel: "gui.deviceManagerStage.page.cancel",
    edit: "gui.deviceManagerStage.page.edit"
}

const parseDeviceList = deviceList => {

    var result = [
        { port: "B1", portType: "adc", type: "", name: "", id: "" },
        { port: "B2", portType: "adc", type: "", name: "", id: "" },
        { port: "B3", portType: "adc", type: "", name: "", id: "" },
        { port: "B4", portType: "adc", type: "", name: "", id: "" },
        { port: "A4", portType: "pwm", type: "", name: "", id: "" },
        { port: "A3", portType: "pwm", type: "", name: "", id: "" },
        { port: "A2", portType: "pwm", type: "", name: "", id: "" },
        { port: "A1", portType: "pwm", type: "", name: "", id: "" },
    ]

    result.forEach(element => {
        deviceList.forEach(device => {
            if (device.connectPortArray.includes(element.port)) {
                element.type = device.type;
                element.name = device.name;
                element.id = device.id;
            }
        });
    });
    return result;
}

class EduBrain extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            deviceList: parseDeviceList(props.vm.getDeviceList())
        }
    }

    parseDevicePort(deviceList) {
        let result = []
        deviceList.forEach(device => {
            if (device.id && device.id.length > 0) {
                result.push(device.port)
            }
        });
        return result;
    }

    parseDeviceId(deviceList) {
        let selectedDeviceArray = this.props.getSelectedDeviceIdArray;
        let result = [];
        selectedDeviceArray.forEach(port => {
            deviceList.forEach(device => {
                if (device.port == port && !result.includes(device.id)) {
                    result.push(device.id)
                }
            });
        })
        return result;
    }

    handleClickTrashcan(deviceList) {
        if (!this.isTrashcanEnable()) return
        if (this.props.isDeleteDeviceMode) {
            this.props.vm.removeMultiDeviceById(this.parseDeviceId(deviceList))
            if (this.props.isDeviceSelected) {
                this.props.closeDelete();
            }
        } else {
            this.props.openDelete()
        }
        this.props.openDeviceList()
    }

    handleClickSelectAll(deviceList) {
        if (deviceList && this.props.isDeleteDeviceMode) {
            var deviceIdArray = this.parseDevicePort(deviceList);
            (this.props.getSelectedDeviceIdArray.length == deviceIdArray.length) ? this.props.cleanSeletedDevice() : this.props.seletDevice(deviceIdArray);
        }
    }

    handleClickBack() {
        if (this.props.isDeleteDeviceMode) {
            this.props.closeDelete();
        }
    }

    isTrashcanEnable() {
        return (this.props.vm.getDeviceList() && this.props.vm.getDeviceList().length > 0 && !this.props.isEditPageOpen && !this.props.isAddPageOpen)
    }

    componentDidUpdate(prevProps) {
        if (this.props.isDeviceListOpen != prevProps.isDeviceListOpen) this.setState({ deviceList: parseDeviceList(this.props.vm.getDeviceList()) })
    }

    render() {
        const {
            vm,
        } = this.props;
        var deviceList = parseDeviceList(vm.getDeviceList());
        return (
            <div>
                {this.props.isDeleteDeviceMode ?
                    <div>
                        <div className={classNames((this.props.isDeviceSelected) ? styles.trashcan : styles.trashcanDisable,
                            (this.props.isDeviceSelected) ? styles.trashcanDiff : styles.trashcanDisableDiff)} onClick={() => this.handleClickTrashcan(deviceList)}>
                            <FormattedMessage id={messagesId.delete} />
                        </div>
                        <div className={classNames(styles.closeDeleteMode, styles.closeDeleteModeDiff)} onClick={() => this.handleClickBack()}>
                            <FormattedMessage id={messagesId.cancel} />
                        </div>
                        <div className={classNames(styles.selectAll, styles.selectAllDiff)} onClick={() => this.handleClickSelectAll(this.state.deviceList)}>
                            <FormattedMessage id={messagesId.selectAll} />
                        </div>
                    </div> :
                    <div className={classNames((this.isTrashcanEnable()) ? styles.trashcan : styles.trashcanDisable,
                        (this.isTrashcanEnable()) ? styles.trashcanDiff : styles.trashcanDisableDiff)} onClick={() => this.handleClickTrashcan(deviceList)}>
                        <FormattedMessage id={messagesId.edit} />
                    </div>}
                <div className={classNames(styles.brainTopEduOnly)}>
                    <div className={classNames(styles.brainEduImg)}>
                        <img src={brainImg} className={classNames(styles.brainEduSize)} alt={'brain picture'}/>
                    </div>
                    <div className={classNames(styles.portArea, styles.portAreaDiff)}>
                        <div className={classNames(styles.portEdu1, styles.portPosition)}>
                            <Port index={0} connectedDeviceList={deviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu2, styles.portPosition)}>
                            <Port index={1} connectedDeviceList={deviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu3, styles.portPosition)}>
                            <Port index={2} connectedDeviceList={deviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu4, styles.portPosition)}>
                            <Port index={3} connectedDeviceList={deviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu5, styles.portPosition)}>
                            <Port index={4} connectedDeviceList={deviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu6, styles.portPosition)}>
                            <Port index={5} connectedDeviceList={deviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu7, styles.portPosition)}>
                            <Port index={6} connectedDeviceList={deviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEdu8, styles.portPosition)}>
                            <Port index={7} connectedDeviceList={deviceList} isBrainLeft={true} vm={vm} />
                        </div>
                        <EduLine />
                        <EduPoint deviceList={deviceList} />
                    </div>
                </div>
            </div >
        );
    }
}

EduBrain.propTypes = {
    isEditPageOpen: PropTypes.bool,
    isAddPageOpen: PropTypes.bool,
    isDeleteDeviceMode: PropTypes.bool,
    isDeviceListOpen: PropTypes.bool,
    getSelectedDeviceIdArray: PropTypes.array,
    vm: PropTypes.instanceOf(VM).isRequired
};


const mapStateToProps = state => ({
    isEditPageOpen: isEditPageOpen(state),
    isAddPageOpen: isAddPageOpen(state),
    isDeleteDeviceMode: isDeleteDeviceMode(state),
    getSelectedDeviceIdArray: getSelectedDeviceIdArray(state),
    isDeviceSelected: isDeviceSelected(state),
    isDeviceListOpen: isPortOpen(state)
});

const mapDispatchToProps = dispatch => ({
    closeDelete: () => dispatch(closeDeleteMode()),
    openDelete: () => dispatch(openDeleteMode()),
    cleanSeletedDevice: () => dispatch(cleanSeletedDevice()),
    seletDevice: (array) => dispatch(selectDeviceToDelete(array)),
    openDeviceList: (number) => dispatch(openDeviceList(number)),
    cleanPortOption: () => dispatch(cleanPortOption()),
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(EduBrain));