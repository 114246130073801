import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './brain.css';
import Point from '../port/point-on-brain.jsx';

class EduPoint extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }


    render() {
        const {
            deviceList
        } = this.props;
        return (
            <div>
                <div className={classNames(styles.point1)}>
                    <Point
                        number={deviceList[0].port}
                        isConnected={!!deviceList[0].type} />
                </div>
                <div className={classNames(styles.point2)}>
                    <Point
                        number={deviceList[1].port}
                        isConnected={!!deviceList[1].type} />
                </div>
                <div className={classNames(styles.point3)}>
                    <Point
                        number={deviceList[2].port}
                        isConnected={!!deviceList[2].type} />
                </div>
                <div className={classNames(styles.point4)}>
                    <Point
                        number={deviceList[3].port}
                        isConnected={!!deviceList[3].type} />
                </div>
                <div className={classNames(styles.point5)}>
                    <Point
                        number={deviceList[4].port}
                        isConnected={!!deviceList[4].type} />
                </div>
                <div className={classNames(styles.point6)}>
                    <Point
                        number={deviceList[5].port}
                        isConnected={!!deviceList[5].type} />
                </div>
                <div className={classNames(styles.point7)}>
                    <Point
                        number={deviceList[6].port}
                        isConnected={!!deviceList[6].type} />
                </div>
                <div className={classNames(styles.point8)}>
                    <Point
                        number={deviceList[7].port}
                        isConnected={!!deviceList[7].type} />
                </div>
            </div>
        );
    }

}

EduPoint.propTypes = {
    deviceList: PropTypes.array
};

export default EduPoint;