const SET_PROJECT_TITLE_CHANGED = 'projectTitleChanged/SET_PROJECT_TITLE_CHANGED';
const SET_PROJECT_TITLE_IS_SAVE = 'projectTitleChanged/SET_PROJECT_TITLE_IS_SAVE';
const SET_PROJECT_TITLE_OVERFLOW = 'projectTitleChanged/SET_PROJECT_TITLE_OVERFLOW';
const SET_PROJECT_TITLE_IS_EDIT = 'projectTitleChanged/SET_PROJECT_TITLE_IS_EDIT';
const SET_PROJECT_TITLE_UPATE_TITLE = 'projectTitleChanged/SET_PROJECT_TITLE_UPATE_TITLE';

const TITLE_CHANGED = 'titleChanged';
const IS_SAVE = 'isSave';
const TITLE_OVERFLOW = 'titleOverflow';
const IS_EDIT = 'isEdit';
const UPATE_TITLE = 'updateTitle';

const initialState = {
    [TITLE_CHANGED]: false,
    [IS_SAVE]: false,
    [TITLE_OVERFLOW]: false,
    [IS_EDIT]: false,
    [UPATE_TITLE]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_PROJECT_TITLE_CHANGED:
        return Object.assign({}, state, {
            [TITLE_CHANGED]: action.change
        });
    case SET_PROJECT_TITLE_IS_SAVE:
        return Object.assign({}, state, {
            [IS_SAVE]: action.isSave
        });
    case SET_PROJECT_TITLE_OVERFLOW:
        return Object.assign({}, state, {
            [TITLE_OVERFLOW]: action.isOverflow
        });
    case SET_PROJECT_TITLE_IS_EDIT:
        return Object.assign({}, state, {
            [IS_EDIT]: action.isEdit
        });
    case SET_PROJECT_TITLE_UPATE_TITLE:
        return Object.assign({}, state, {
            [UPATE_TITLE]: action.isUpdate
        });
    default:
        return state;
    }
};

const setProjectTitleChanged = change => ({
    type: SET_PROJECT_TITLE_CHANGED,
    change: change
});

const setProjectTitleisSave = isSave => ({
    type: SET_PROJECT_TITLE_IS_SAVE,
    isSave: isSave
});

const setProjectTitleOverflow = isOverflow => ({
    type: SET_PROJECT_TITLE_OVERFLOW,
    isOverflow: isOverflow
});

const setProjectTitleisEdit = isEdit => ({
    type: SET_PROJECT_TITLE_IS_EDIT,
    isEdit: isEdit
});

const setProjectTitleupdateTitle = isUpdate => ({
    type: SET_PROJECT_TITLE_UPATE_TITLE,
    isUpdate: isUpdate
});

const projectTitleChanged = state => state.scratchGui.projectTitleChanged[TITLE_CHANGED];
const projectTitleisSave = state => state.scratchGui.projectTitleChanged[IS_SAVE];
const projectTitleOverflow = state => state.scratchGui.projectTitleChanged[TITLE_OVERFLOW];
const projectTitleisEdit = state => state.scratchGui.projectTitleChanged[IS_EDIT];
const projectTitleupdateTitle = state => state.scratchGui.projectTitleChanged[UPATE_TITLE];

export {
    reducer as default,
    initialState as projectTitleChangedInitialState,
    setProjectTitleChanged,
    setProjectTitleisSave,
    setProjectTitleOverflow,
    setProjectTitleisEdit,
    setProjectTitleupdateTitle,
    projectTitleChanged,
    projectTitleisSave,
    projectTitleOverflow,
    projectTitleisEdit,
    projectTitleupdateTitle
};
