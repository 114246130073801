import { defineMessages, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Box from '../box/box.jsx';
import Selector from '../selector/selector.jsx';

import styles from './prompt.css';

const PromptComponent = props => (
    <div className={styles.backdropStyle}>
        <div className={styles.modalStyle}>
            <div className={styles.body}>
                <div className={styles.header}>
                        {props.title}
                </div>
                <div className={styles.content}>
                    {props.label}
                    <input
                        type={"text"}
                        autoFocus
                        className={classNames(styles.variableNameTextInput, (props.showWarningText != null && props.showWarningText) ? styles.error : null)}
                        defaultValue={props.defaultValue}
                        name={props.label}
                        onChange={props.onChange}
                        onFocus={props.onFocus}
                        onKeyPress={props.onKeyPress}
                    />
                    <div className={styles.infoMessage}>
                        {props.showWarningText == null? " ": props.showWarningText}
                    </div>
                    {
                        props.showListOptions ?
                        (
                            <div>
                                <FormattedMessage
                                    defaultMessage="Length of list (1-10)"
                                    description="Length of list"
                                    id="gui.prompt.listLength"
                                />
                                <Selector
                                    className={styles.select}
                                    defaultValue="1"
                                    list={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                    onChange={props.onListLengthSelection}
                                />
                            </div>
                        ) : null
                    }
                </div>

                <div className={styles.footer}>
                    <button
                        className={styles.cancelButton}
                        onClick={props.onCancel}
                    >
                        <FormattedMessage
                            defaultMessage="Cancel"
                            description="Button in prompt for cancelling the dialog"
                            id="gui.prompt.cancel"
                        />
                    </button>
                    <button
                        className={classNames(styles.okButton, (props.showWarningText || props.lockOKButton) ? styles.disabled : null)}
                        onClick={props.onOk}
                    >
                        <FormattedMessage
                            defaultMessage="Submit"
                            description="Button in prompt for confirming the dialog"
                            id="gui.dialog.confirm"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
);

PromptComponent.propTypes = {
    defaultValue: PropTypes.string,
    label: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    showWarningText: PropTypes.object,
    onListLengthSelection: PropTypes.func.isRequired,
    lockOKButton: PropTypes.bool.isRequired,
};

export default PromptComponent;
