import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import store from 'store';
import VM from 'scratch-vm';

import {
    DownloadType,
    setBrainDownloadingStatus,
    setBrainSwitchingStatus,
    setBrainDownloadAndRun,
    setBrainFWUpdatingStatus,
    setBrainScriptErasingStatus,
    setBrainConnectStatus,
    setBrainRunningStatus,
    setBrainStatus,
    setDriverInstallStatus,
    setBrainUpdateStatus,
    setBrainInfo,
    setAllPortInfo,
    setBrainVer,
    setBrainLoadingStatus,
    setBatteryConnectStatus,
    setFirmwareConfig,
    setBrainBtVer,
    setCheckBluetoothStatus,
    setApBrainList,
    setBrainWifiList,
    setBrainConnectState,
    WIFI_CONNECT_STATE,
    setEntryBrainScreen,
    setEntryDeviceStatus,
    getBrainInfo,
    getBrainVer,
    getAllPortInfo,
    getBrainBtVer,
    getBrainDownloadType,
    BRAIN_STATUS_TYPE
} from '../../reducers/brain';

import {
    setEnableConfigEntry,
    setEnableConfigEduAndEntry,
    setEnableConfigWebVR,
    updatePickedBrainType
} from '../../reducers/picked-brain-type'

import {
    setEnableAISpeech
} from '../../reducers/speaker'

import {
    setFileProcessing,
    isFileProcessing
} from '../../reducers/file-manager'

import {
    setControllerInfo,
    getControllerInfo,
    setAllControllerPortInfo,
    getAllControllerPortInfo,
    setEnableEduController,
    isRCFirmwareUpdating,
    endRCFirmwareUpdating,
    setControllerResponse,
    setControllerStatus,
    resetControllerStatus
} from '../../reducers/controller'

import {
    setFeedback,
    showUpdateDialog,
    hideStartAppBrainTypePicker,
    showFirmwarmUpdateDialog,
    hideFirmwarmUpdateDialog,
    showErrorDialog,
    showCloseAppDialog,
    errorType,
    showQuestionDialog,
    hideQuestionDialog,
    hideUpdateRCTutorialDialog,
    questionType,
    setSaveCancel,
    showWhatsNewDialog
} from '../../reducers/dialog'

import {
    setTaskProgress,
    cleanTask,
    newTask,
    runTask,
    finishTask,
    taskType,
    taskEnqueue,
    taskDequeue,
    cleanTaskQueue,
    getTaskQueue,
    getFirstTaskInQueue,
    setUpdatingComPort,
    getUpdatingComPort
} from '../../reducers/task'

import {
    setSlotList,
} from '../../reducers/slot-list';

import {
    setImageTagList,
    setImagePixel,
    setImageBase64,
    getImageTagList,
    getImageBase64,
    setColorDataList,
    setColorDataDefaultList,
    getColorDataList,
    setSelectedColor,
    setVisionConnected,
    setTempFaceFeature,
    isVisionConnected,
    setVisionInitiated,
    isVisionInitiated,
    setUploadImagePath,
    setVisionInitState,
    resetVisionInitState,
    VISION_INIT_STATE_TYPE,
    setWifiVision,
    setVisionRecongInitiated,
    setVisionColorDefaultListInitiated
} from '../../reducers/vision';

import {
    setProjectPreTitle,
    setProjectNew
} from '../../reducers/project-title';

import {
    setProjectTitleisSave,
    setProjectTitleupdateTitle
} from '../../reducers/project-title-changed';

import {
    onReloadProject,
    getIsShowingProject
} from '../../reducers/project-state';

import {
    Catagory,
    postMessage
} from '../../lib/postmessage';

import { setUpdate } from '../../reducers/software-update';

import {
    setConnectedWifi,
    setWifiList,
    setWifiScanResult,
    setWifiStatus
} from '../../reducers/wifi-list'

import {
    setUIStyle,
} from '../../reducers/ui-style';

import {
    closeSlotMenu,
    closeBrainInfoMenu
} from '../../reducers/menus';

import {
    setRealEntryDeviceList,
    clearRealEntryDeviceList,
    enableCheckingImportDevice,
    getRealEntryDeviceList
} from '../../reducers/device-manager-controller';
import log from '../../lib/log';

import { VISION_IMAGE_MODE, VISION_NAME_RULE, IS_ANALYZING, OPEN_IMAGE_FILE_FAILED, VISION_DEFAULT_LANGUAGE_MAPPING, OPEN_IMAGE_FILE_SUCCESS } from '../dialog/vision/vision-utils.js';
import { EditUtils } from '../device-manager/edit-page/edit-utils.js';
import {
    DeviceType,
    WifiStatus,
    parseRealDeviceListToReducer,
    VisionInitResponse,
    VisionCmdResponse,
    WriteScriptCmd,
    ErrorScriptCmd
} from './pad-reveiver-utils';
const { DEFAULT_LOCALE } = require('../../config/project-config.js');

var connectBrainData = null;
var linkConnectPort = null;
var portConnecting = null;
var lastConnectedPort = null;
var checkImportDevice = false;
var loadingBarTimeout = null;

const delay1sec = 1 * 1000; // 1 sec
const delay3sec = 3 * 1000; // 3 sec
const EMPTY_DATA = 'N/A'
class PadReceiver extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);

    }

    componentDidMount() {
        window.padReceiver = this;
    }

    parseDataToJson(data) {
        return (typeof data == 'string') ? JSON.parse(data) : data
    }

    setiPadWifiSsid(ssid) {
        log.info("setiPadWifiSsid ", ssid);
        this.props.setBrainConnectState(WIFI_CONNECT_STATE.SUCCESS);
    }

    setiPadWifiList(list) {
        log.info("setiPadWifiList ", list);
        this.props.setBrainWifiList(JSON.parse(decodeURIComponent(list.replace(/\\x/g, '%'))));
    }

    setEntryApList(list) {
        log.info("setEntryApList ", list);
        list = this.parseDataToJson(list);
        log.info("list ", list);
        this.props.setApBrainList(list);
    }

    setSlotInfo(info) {
        if (typeof info == 'string') info = JSON.parse(info);
        let slotList = [];
        for (let key in info) {
            if (key != 'cmd') {
                slotList.push(info[key])
            }
        }
        log.info("setSlotInfo ", slotList);
        this.props.setSlotList(slotList);
    }

    setBrainInfo(data) {
        data = this.parseDataToJson(data);
        if (data) {
            this.updateBrainUI(data);
            if (data.brain_type == DeviceType.EDU_HULK) this.props.updateConnectedWifi(EMPTY_DATA);
            if (data.brain_type == DeviceType.ENTRY_HULK || data.brain_type == DeviceType.EDU_AND_ENTRY_HULK) {
                this.enableCheckingImportDevice(false);
                setTimeout(() => {
                    checkImportDevice = true;
                    postMessage(Catagory.Socket, { action: 'getPortState' })
                }, delay1sec);
            } else {
                this.props.hideQuestionDialog(questionType.QUESTION_REPLACE_DEVICE)
            }
            // Get Wifi Status
            if (data.brain_type == DeviceType.ENTRY_HULK || data.brain_type == DeviceType.EDU_AND_ENTRY_HULK) {
                setTimeout(() => {
                    log.info('handleUpdateBrainInfo query wifi status');
                    this.handleClickWifiStatus();
                }, delay3sec);
            }

            if (data.isSocket == true) {
                this.props.updateAllPortInfo([data]);
            }
        } else {
            lastConnectedPort = null;
            connectBrainData = null;
            this.props.enableCheckingImportDevice(false);
            this.props.updateBrainRunningStatus(false);
            this.props.setVisionInitiated(false);
            this.props.setVisionConnected(false);
            this.props.setVisionColorDefaultListInitiated(false);
            this.updateBrainUI(null);
        }
        this.props.closeBrainInfoMenu();
        portConnecting = null;
    }

    enableCheckingImportDevice(status) {
        log.info('enableCheckingImportDevice status > ', status)
        this.props.enableCheckingImportDevice(status);
    }

    updateBrainUI(deviceInfo) {
        if (deviceInfo != undefined) {
            this.props.updateBrainConnectStatus(true);
            this.props.updateDriverStatus(true);
            this.props.updateBrainLoadingStatus(false);
            this.props.updateBrainInfo(deviceInfo);
            // this.setFirmwareUpdateInfo(deviceInfo);
            // this.checkUpdatingTask(cmd_fw.ENABLE_SKIP, cmd_fw.ENABLE_CHECK_TASK_DIALOG, CHECK_TASK_TIMING.CONNECT_BRAIN);
        } else {
            this.props.updateBrainInfo({});
            this.props.updateBrainConnectStatus(false);
            this.props.updateBrainDownloadingStatus(false);
            this.props.updateBrainFWUpdatingStatus(false);
            // this.setFirmwareUpdateInfo({});
            this.props.updateBrainScriptErasingStatus(false);
            // this.hideFirmwarmUpdateDialog("updateBrainUI");
        }
        this.props.updateBrainUpdateStatus(false);
    }

    handleGetPortState(data) {
        if (data && data != "") {
            let connected = (data.usb_r == 'vision' || data.usb_l == 'vision');
            this.props.setVisionConnected(connected);
            if (connected) {
                setTimeout(() => { this.initVision(); }, delay1sec)
            } else {
                this.props.setVisionInitiated(false)
                this.props.setVisionColorDefaultListInitiated(false);
            }
            this.props.setRealEntryDeviceList(parseRealDeviceListToReducer(data));
            log.info('handleGetPortState > checkImportDevice > ', checkImportDevice);
            if (checkImportDevice) {
                this.enableCheckingImportDevice(true);
            }
            checkImportDevice = false;
        }
    }

    handleWifiStatus(data) {
        log.info("handleWifiStatus: " + JSON.stringify(data));
        let wifiStatus = {
            state: data.state,
            conn_ssid: data.conn_ssid,
            current_ap: data.current_ap,
            selected_ap: data.selected_ap,
            rssi: data.rssi
        }
        this.props.updateWifiStatus(wifiStatus);
        this.props.updateConnectedWifi(data.conn_ssid);

        if (WifiStatus.CONNECTING == data.state) {
            setTimeout(() => {
                this.handleClickWifiStatus();
            }, delay1sec);
        }
    }

    handleClickWifiStatus() {
        postMessage(Catagory.Socket, { action: 'wifistatus' });
    }

    initVision() {
        log.info(`app initVision this.props.isVisionConnected :${this.props.isVisionConnected}, this.props.isVisionInitiated :${this.props.isVisionInitiated}`)
        if (!this.props.isVisionConnected || this.props.isVisionInitiated) return;
        this.props.resetVisionInitState();
        setTimeout(() => {
            this.props.setVisionInitState(VISION_INIT_STATE_TYPE.INITIALIZING);
            postMessage(Catagory.Socket, { action: 'cvInit' })
        }, 100)
    }

    handleSetVisionInitiatedStatus(data) {
        log.info('handleSetVisionInitiatedStatus ', data);
        if (data.res) {
            if (data.res == VisionInitResponse.TRUE) {
                this.props.setVisionInitiated(true);
            } else if (data.res == VisionInitResponse.NONE) {
                this.props.setVisionRecongInitiated(true);
                this.props.setVisionInitState(VISION_INIT_STATE_TYPE.SUCCESS)
                this.props.setVisionTagSetting([])
            } else if (data.res == VisionInitResponse.FAILED) {
                this.props.setVisionRecongInitiated(false);
                // this.props.setVisionColorDefaultListInitiated(false);
                this.props.setVisionInitState(VISION_INIT_STATE_TYPE.FAILED);
                // this.closeVision();
                if (data.cmd && data.cmd == VisionCmdResponse.CV_EXIT) {
                    log.info('handleSetVisionInitiatedStatus setVisionInitiated(false)');
                    this.props.setVisionInitiated(false);
                    this.props.showErrorDialog(errorType.VISION_REBOOT);
                    setTimeout(() => this.initVision(), delay1sec);
                }
            } else if (data.res == VisionInitResponse.FALSE) {
                this.props.setVisionInitiated(false);
                this.props.setVisionRecongInitiated(false);
                // this.props.setVisionColorDefaultListInitiated(false);
                if (data.occupied == VisionInitResponse.OCCUPIED_VM) {
                    this.props.setVisionInitState(VISION_INIT_STATE_TYPE.OCCUPY);
                }
                //  else {
                // this.closeVision();
                // }
            } else if (data.res == VisionInitResponse.INTERRUPT) {
                this.props.setVisionInitiated(false);
                this.props.setVisionRecongInitiated(false);
                // this.props.setVisionColorDefaultListInitiated(false);
                if (data.occupied) {
                    this.props.setVisionInitState(VISION_INIT_STATE_TYPE.INTERRUPT)
                }
            }
        } else {
            this.props.setVisionInitiated(false);
            this.props.setVisionRecongInitiated(false);
            // this.props.setVisionColorDefaultListInitiated(false);
            this.props.setVisionInitState(VISION_INIT_STATE_TYPE.FAILED)
            // this.closeVision();
        }
    }

    handleGetVisionRealtime(data) {
        if (data.res) {
            let stream = data.res.image ? data.res.image : "";
            let tagList = data.res.detected_objects ? data.res.detected_objects : [];
            let width = data.res.frame_width ? data.res.frame_width : 320;
            let height = data.res.frame_height ? data.res.frame_height : 240;
            this.props.setImageBase64(stream);
            this.props.setImagePixel(width, height);
            if (!Array.isArray(this.props.getImageTagList) ||
                (Array.isArray(tagList) &&
                    (tagList.length != this.props.getImageTagList.length ||
                        (tagList.length > 0 && tagList[0] != this.props.getImageTagList[0])))) {
                this.props.setImageTagList(tagList);
            }
        } else {
            log.warn('handleGetVisionRealtime invaild data > ', data)
        }
    }

    handleGetVisionSelectColorResponse(data) {
        if (data.res) {
            let color = data.res;
            log.info('handleGetVisionSelectColorResponse color > ', color)
            this.props.setSelectedColor(color);
        } else {
            log.warn('handleGetVisionSelectColorResponse invaild data > ', data)
        }
    }

    handleSetVisionTempFaceFeature(res) {
        log.info('handleSetVisionTempFaceFeature ', res);
        if (res && Array.isArray(res)) {
            this.props.setTempFaceFeature(res);
        } else {
            this.props.setUploadImagePath('openImageFileFailed');
            this.props.showErrorDialog(errorType.FILE_FORMAT_INVALID);
        }
    }
    handleGetFaceRecongResult(res) {
        log.info('handleGetFaceRecongResult ', res)
        if (res && Array.isArray(res)) {
            this.props.setImageTagList(res);
        } else {
            this.props.setUploadImagePath('openImageFileFailed');
            this.props.showErrorDialog(errorType.FILE_FORMAT_INVALID);
        }
    }
    handleGetOCRResult(res) {
        log.info('handleGetOCRResult ', res)
        this.props.setImageTagList(res);
    }
    handleGetQRCodeResult(res) {
        log.info('handleGetQRCodeResult ', res)
        this.props.setImageTagList(res);
    }
    setVisionColorDefaultListInitiated(status) {
        this.props.setVisionColorDefaultListInitiated(status)
    }
    handleGetVisionError(data) {
        log.warn('handleGetVisionError ', data);
        //Show dialog if connected
        this.props.setVisionInitiated(false);
        if (data.res.includes("No such device")) {
            this.props.setVisionConnected(false);
        }
    }
    handlePortClear(data) {
        log.info("handlePortClear data: ", data);
        let deviceType = data.deviceType;
        linkConnectPort = null;
        portConnecting = null;
        let connectIp = store.get('ip');
        log.info("handlePortClear connectIp: ", connectIp);
        if (connectIp == data) {
            this.handleUpdateBrainInfo(event, null)
            this.props.updateAllPortInfo([]);
            this.props.updateWifiStatus({
                state: null,
                conn_ssid: null,
                current_ap: null,
                selected_ap: null,
                rssi: null
            });
            this.props.updateBrainRunningStatus(false);
            this.props.setVisionInitiated(false);
            this.props.setVisionConnected(false);
            this.props.updateConnectedWifi(EMPTY_DATA);
            store.set('ip', "");
        }
    }

    setUploadImagePath(path) {
        this.props.setUploadImagePath(path)
    }

    // image =  { data: data, uri: decodeURI(uri) }
    handleOpenVsionUploadImage(image) {
        log.info("handleOpenVsionUploadImage");
        this.props.setImageBase64("");
        setTimeout(() => {
            if (image) {
                try {
                    this.props.setUploadImagePath(image.uri);
                    log.info("handleOpenVsionUploadImage image.uri ", image.uri);
                    this.props.setImageBase64(image.data);
                    this.props.setImagePixel(image.width, image.height);
                } catch (e) {
                    console.error('handleOpenVsionUploadImage e ', e)
                    this.props.setUploadImagePath('openImageFileFailed');
                    this.props.showErrorDialog(errorType.FILE_FORMAT_INVALID);
                }
            }
        }, 100)
    }

    handleWriteScriptStatus(data) {
        log.info('handleWriteScriptStatus: ' + JSON.stringify(data));
        switch (data.res) {
            case WriteScriptCmd.START:
                console.log('handleWriteScriptStatus: START');
                this.props.updateBrainDownloadingStatus(true);
                this.props.runTask(taskType.DOWNLOAD_SCRIPT);
                this.props.setTaskProgress(0, taskType.DOWNLOAD_SCRIPT);
                if (this.props.getBrainInfo && this.props.getBrainInfo.brain_type != DeviceType.EDU_HULK) {
                    loadingBarTimeout = setTimeout(this.props.setTaskProgress(95, taskType.DOWNLOAD_SCRIPT), 3000);
                }
                break;
            case WriteScriptCmd.END:
                console.log('handleWriteScriptStatus: END');
                this.props.updateBrainDownloadingStatus(false);
                log.info("this.props.brainDownloadType = ", this.props.brainDownloadType);
                if (this.props.brainDownloadType == DownloadType.downloadAndRun) {
                    log.info("this.props.brainDownloadType == DownloadType.downloadAndRun");
                    this.props.setBrainDownloadAndRun(true);
                }
                loadingBarTimeout = null;
                this.props.finishTask(taskType.DOWNLOAD_SCRIPT)
                break;
            case ErrorScriptCmd.CANCEL:
                loadingBarTimeout = null;
                this.props.updateBrainDownloadingStatus(false);
                this.props.cleanTask();
                this.props.showQuestionDialog(questionType.QUESTION_DOWNLOAD_BRAIN_SCRIPT_TIMEOUT);
                break;
            default:
                loadingBarTimeout = null;
                if (data.res <= 100) {
                    console.log('handleWriteScriptStatus: ' + data.res);
                    if (data.res % 4 == 0) this.props.setTaskProgress(data.res, taskType.DOWNLOAD_SCRIPT);
                } else {
                    log.error('handleWriteBinStatus unknown res : ' + data.res);

                }
                break;
        }
    }

    handleUpdateScriptRunningStatus(data) {
        switch (parseInt(data.res)) {
            case BRAIN_STATUS_TYPE.IDLE:
            case BRAIN_STATUS_TYPE.RFID_MODE:
            case BRAIN_STATUS_TYPE.BLUETOOTH_MODE:
            case BRAIN_STATUS_TYPE.DOWNLOAD_SCRIPT:
            case BRAIN_STATUS_TYPE.BLUETOOTH_DOWNLOAD_PHONE_SCRIPT:
            case BRAIN_STATUS_TYPE.BLUETOOTH_DOWNLOAD_BLOCKLY_SCRIPT:
                this.props.updateBrainRunningStatus(false);
                this.props.updateBrainStatus(data.res);
                break;
            case BRAIN_STATUS_TYPE.RUNNING:
                this.props.updateBrainRunningStatus(true);
                this.props.updateBrainStatus(data.res);
                break;
        }
    }

    handleUpdateBrainType(res) {
        const newType = res.res;
        let brainInfo = this.props.getBrainInfo;
        if (brainInfo.brain_type != newType) {
            log.info(`handleUpdateBrainType prev.type: ${brainInfo.brain_type}, next.type: ${newType}`)
            brainInfo.brain_type = newType;
            this.props.updateBrainInfo(brainInfo);
        }
    }

    handleSetEntryScreen(data) {
        log.info('handleSetEntryScreen ', data);
        this.props.setEntryBrainScreen(data)
    }
    handleSetDeviceStatus(data) {
        log.info('handleSetDeviceStatus');
        this.props.setEntryDeviceStatus(data)
    }
    handleSetControllerStatus(data) {
        log.info('handleSetControllerStatus ', data);
        this.props.setControllerStatus(data)
    }
    handleSetRealtimeController(data) {
        log.info('handleSetRealtimeController ', data);
        this.props.setControllerResponse(data)
    }

    render() {
        return null
    }
}


PadReceiver.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    projectChanged: state.scratchGui.projectChanged,
    brainDownloadType: getBrainDownloadType(state),
    allPortInfo: getAllPortInfo(state),
    controllerInfo: getControllerInfo(state),
    allControllerInfo: getAllControllerPortInfo(state),
    isShowingProject: getIsShowingProject(state.scratchGui.projectState.loadingState),
    vm: state.scratchGui.vm,
    getBrainVer: getBrainVer(state, 'current'),
    getBrainInfo: getBrainInfo(state),
    getTaskQueue: getTaskQueue(state),
    getFirstTaskInQueue: getFirstTaskInQueue(state),
    getBrainBtVer: getBrainBtVer(state),
    getUpdatingComPort: getUpdatingComPort(state),
    getImageTagList: getImageTagList(state),
    getColorDataList: getColorDataList(state),
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state),
    isRCFirmwareUpdating: isRCFirmwareUpdating(state),
    isFileProcessing: isFileProcessing(state),
    getRealEntryDeviceList: getRealEntryDeviceList(state)
});

const mapDispatchToProps = dispatch => ({
    setBrainConnectState: (state) => dispatch(setBrainConnectState(state)),
    setBrainWifiList: (list) => dispatch(setBrainWifiList(list)),
    setApBrainList: list => dispatch(setApBrainList(list)),
    updateRecentFilePathsState: paths => dispatch(updateRecentFilePaths(paths)),
    updateBrainDownloadingStatus: status => dispatch(setBrainDownloadingStatus(status)),
    setBrainSwitchingStatus: status => dispatch(setBrainSwitchingStatus(status)),
    updateBrainLoadingStatus: status => dispatch(setBrainLoadingStatus(status)),
    updateBrainFWUpdatingStatus: status => dispatch(setBrainFWUpdatingStatus(status)),
    updateBrainScriptErasingStatus: status => dispatch(setBrainScriptErasingStatus(status)),
    updateCheckBluetoothStatus: status => dispatch(setCheckBluetoothStatus(status)),
    updateBrainConnectStatus: status => dispatch(setBrainConnectStatus(status)),
    updateBrainRunningStatus: status => dispatch(setBrainRunningStatus(status)),
    updateBrainStatus: status => dispatch(setBrainStatus(status)),
    updateBrainUpdateStatus: status => dispatch(setBrainUpdateStatus(status)),

    updateEnableConfigEntryStatus: status => dispatch(setEnableConfigEntry(status)),
    updateEnableConfigEduAndEntryStatus: status => dispatch(setEnableConfigEduAndEntry(status)),
    updateEnableConfigWebVRStatus: status => dispatch(setEnableConfigWebVR(status)),
    updateUIStyle: style => dispatch(setUIStyle(style)),

    updateEnableConfigAISpeechStatus: status => dispatch(setEnableAISpeech(status)),

    updateEnableEduController: status => dispatch(setEnableEduController(status)),

    closeBrainInfoMenu: status => dispatch(closeBrainInfoMenu(status)),
    closeSlotMenu: status => dispatch(closeSlotMenu(status)),

    updateDriverStatus: status => dispatch(setDriverInstallStatus(status)),
    setSlotList: list => dispatch(setSlotList(list)),
    updateBrainInfo: info => dispatch(setBrainInfo(info)),
    updateAllPortInfo: deivces => dispatch(setAllPortInfo(deivces)),

    updateControllerInfo: info => dispatch(setControllerInfo(info)),
    updateAllControllerPortInfo: controllers => dispatch(setAllControllerPortInfo(controllers)),

    updateWifiStatus: state => dispatch(setWifiStatus(state)),
    updateConnectedWifi: ssid => dispatch(setConnectedWifi(ssid)),
    updateWifiList: list => dispatch(setWifiList(list)),
    updateWifiScanResult: list => dispatch(setWifiScanResult(list)),

    updateFirmwareVer: (verType, ver) => dispatch(setBrainVer(verType, ver)),
    onShowUpdateDialog: () => dispatch(showUpdateDialog()),
    showFirmwarmUpdateDialog: () => dispatch(showFirmwarmUpdateDialog()),
    onSetBatteryConnectStatus: status => dispatch(setBatteryConnectStatus(status)),
    hideFirmwarmUpdateDialog: () => dispatch(hideFirmwarmUpdateDialog()),
    setDialogFeedback: status => dispatch(setFeedback(status)),
    onHideStartAppBrainTypePicker: () => dispatch(hideStartAppBrainTypePicker()),
    onUpdatePickedBrainType: () => dispatch(updatePickedBrainType()),
    setTaskProgress: (percentage, taskName) => dispatch(setTaskProgress(percentage, taskName)),
    cleanTask: () => dispatch(cleanTask()),
    newTask: (taskName) => dispatch(newTask(taskName)),
    runTask: (taskName) => dispatch(runTask(taskName)),
    finishTask: (taskName) => dispatch(finishTask(taskName)),
    showErrorDialog: (errorType, msg) => dispatch(showErrorDialog(errorType, msg)),
    showQuestionDialog: (questionType) => dispatch(showQuestionDialog(questionType)),
    hideQuestionDialog: (questionType) => dispatch(hideQuestionDialog(questionType)),
    showCloseAppDialog: () => dispatch(showCloseAppDialog()),
    setProjectPreTitle: title => dispatch(setProjectPreTitle(title)),
    setProjectTitleisSave: isSave => dispatch(setProjectTitleisSave(isSave)),
    setProjectNew: isNew => dispatch(setProjectNew(isNew)),
    setBrainDownloadAndRun: status => dispatch(setBrainDownloadAndRun(status)),
    setUpdate: state => dispatch(setUpdate(state)),
    taskEnqueue: (task) => dispatch(taskEnqueue(task)),
    taskDequeue: () => dispatch(taskDequeue()),
    cleanTaskQueue: () => dispatch(cleanTaskQueue()),
    setFirmwareConfig: (config) => dispatch(setFirmwareConfig(config)),
    showWhatsNewDialog: () => dispatch(showWhatsNewDialog()),
    setBrainBtVer: (ver) => dispatch(setBrainBtVer(ver)),
    setUpdatingComPort: (com) => dispatch(setUpdatingComPort(com)),
    clearUpdatingComPort: () => dispatch(setUpdatingComPort('COM')),
    setImageTagList: (list) => dispatch(setImageTagList(list)),
    setUploadImagePath: (path) => dispatch(setUploadImagePath(path)),
    setImagePixel: (width, height) => dispatch(setImagePixel(width, height)),
    setImageBase64: (stream) => dispatch(setImageBase64(stream)),
    setSelectedColor: (color) => dispatch(setSelectedColor(color)),
    setColorDataList: (list) => dispatch(setColorDataList(list)),
    setColorDataDefaultList: (list) => dispatch(setColorDataDefaultList(list)),
    setVisionConnected: (status) => dispatch(setVisionConnected(status)),
    setVisionInitiated: (status) => dispatch(setVisionInitiated(status)),
    setTempFaceFeature: (feature) => dispatch(setTempFaceFeature(feature)),
    onReloadProject: () => dispatch(onReloadProject()),
    setRealEntryDeviceList: (list) => dispatch(setRealEntryDeviceList(list)),
    clearRealEntryDeviceList: () => dispatch(clearRealEntryDeviceList()),
    enableCheckingImportDevice: (status) => dispatch(enableCheckingImportDevice(status)),
    endRCFirmwareUpdating: () => dispatch(endRCFirmwareUpdating()),
    hideUpdateRCTutorialDialog: () => dispatch(hideUpdateRCTutorialDialog()),
    setProjectTitleupdateTitle: isUpdate => dispatch(setProjectTitleupdateTitle(isUpdate)),
    setVisionInitState: (state) => dispatch(setVisionInitState(state)),
    resetVisionInitState: (state) => dispatch(resetVisionInitState(state)),
    setSaveCancel: (status) => dispatch(setSaveCancel(status)),
    setFileProcessing: (processing) => dispatch(setFileProcessing(processing)),

    setEntryBrainScreen: (res) => dispatch(setEntryBrainScreen(res)),
    setEntryDeviceStatus: (res) => dispatch(setEntryDeviceStatus(res)),
    setControllerResponse: (res) => dispatch(setControllerResponse(res)),
    setControllerStatus: (res) => dispatch(setControllerStatus(res)),
    resetControllerStatus: (res) => dispatch(resetControllerStatus(res)),
    setWifiVision: (res) => dispatch(setWifiVision(res)),
    setVisionRecongInitiated: (state) => dispatch(setVisionRecongInitiated(state)),
    setVisionColorDefaultListInitiated: (state) => dispatch(setVisionColorDefaultListInitiated(state)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PadReceiver);