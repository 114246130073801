import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';

import {
    getAlertType,
    getAlertMsg,
    getAlertCallback,
} from '../../reducers/dialog';

class AlertDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            // 'addListeners',
            // 'removeListeners',
            // 'handleClick',
            'handleClickBtn',
            'ref'
        ]);
    }
    componentDidMount() {
        // if (this.props.show) this.addListeners();
    }
    componentDidUpdate(prevProps) {
        // if (this.props.show && !prevProps.show) this.addListeners();
        // if (!this.props.show && prevProps.show) this.removeListeners();
    }
    componentWillUnmount() {
        // this.removeListeners();
    }
    // addListeners() {
    //     document.addEventListener('mouseup', this.handleClick);
    // }
    // removeListeners() {
    //     document.removeEventListener('mouseup', this.handleClick);
    // }
    handleClickBtn(confirm) {
        if (this.props.callback) {
            this.props.callback(confirm);
        }
        this.props.onClose();
    }
    // handleClick(e) {
    //     if (e.target.className != "goog-menuitem-checkbox" && e.target.className != "goog-menuitem-content" && !e.target.classList.contains("goog-menuitem")) {
    //         if (this.props.show && !this.dialog.contains(e.target)) {
    //             this.props.onClose();
    //         }
    //     }
    // }
    ref(c) {
        this.dialog = c;
    }
    renderButton() {
        if (this.props.type == "confirm") {
            return (
                <div className={styles.questionFooter}>
                    <div className={styles.questionWhiteBtn} onClick={() => this.handleClickBtn(false)}>
                        <FormattedMessage
                            id="gui.dialog.question.cancel"
                        />
                    </div>
                    <div className={styles.questionInterval} />
                    <div className={styles.questionBtn} onClick={() => this.handleClickBtn(true)}>
                        <FormattedMessage
                            id="gui.hint.deleteBlocks.confirm"
                        />
                    </div>
                </div>
            )
        } else if (this.props.type == "alert") {
            return (
                <div className={styles.questionFooter}>
                    <div className={styles.questionBtn} onClick={() => this.handleClickBtn(true)}>
                        <FormattedMessage
                            id="gui.hint.deleteBlocks.confirm"
                        />
                    </div>
                </div>
            )
        }
    }
    render() {
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle, styles.question)} ref={this.ref}>
                    <div className={styles.questionView} >
                        <div className={styles.questionContent}>
                            {this.props.msg}
                        </div>
                        <div className={styles.questionFooter}>
                            {this.renderButton()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AlertDialog.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool,
    msg: PropTypes.string,
    callback: PropTypes.func,
    type: PropTypes.string,
};

AlertDialog.defaultProps = {
    onClose: () => { },
}

const mapStateToProps = state => ({
    msg: getAlertMsg(state),
    type: getAlertType(state),
    callback: getAlertCallback(state),
});

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertDialog);