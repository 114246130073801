import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './brain-driver-hint.css';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import log from '../../lib/log.js';

import {
    questionType,
    showQuestionDialog
} from '../../reducers/dialog';


class BrainDriverHint extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
        };
    }

    handleClickInstallDriver() {
        log.info('handleClickInstallDriver')
        this.props.onShowQuestionInstallDriverDialog();
    }

    render() {
        if (!this.props.show) {
            return null
        }
        return (
            <div className={styles.brainDriverHint} >
                <div className={styles.brainDriverHintDialog} >
                    <div className={styles.brainDriverHintContent}>
                        <FormattedMessage
                            id="gui.brainDriverHint.content"
                        />
                    </div>
                    <div className={styles.brainDriverHintButtonArea}>
                        <div className={styles.brainDriverHintButton}
                            onClick={() => { this.handleClickInstallDriver() }} >
                            <FormattedMessage
                                id="gui.brainDriverHint.button"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BrainDriverHint.propTypes = {
    show: PropTypes.bool.isRequired,
};

BrainDriverHint.defaultPropTypes = {
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    onShowQuestionInstallDriverDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_INSTALL_DRIVER)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BrainDriverHint);