const SELECT_PORT_OPTION = 'scratch-gui/device/SELECT_PORT_OPTION'
const CLEAN_PORT_OPTION = 'scratch-gui/device/CLEAN_PORT_OPTION'
const HOVER_DEVICE = 'scratch-gui/device/HOVER_DEVICE'

const HOVER_CONNECTED_DEVICE = 'scratch-gui/device/HOVER_CONNECTED_DEVICE'
const CLEAN_CONNECTED_DEVICE_HOVERED = 'scratch-gui/device/CLEAN_CONNECTED_DEVICE_HOVERED'


const SELECTED_PORT_ARRAY = 'selectPortArray';
const IS_DEVICE_HOVERED = 'isDeviceHovered';
const IS_OPTION_SELECTED = 'isOptionSelected';

const HOVER_CONNECTED_DEVICE_PORT_ARRAY = 'hoverConnectedDevicePortArray';
const IS_CONNECTED_DEVICE_HOVERED = 'isConnectedDeviceHovered';

const initialState = {
    [SELECTED_PORT_ARRAY]: [],
    [IS_OPTION_SELECTED]: false,
    [IS_DEVICE_HOVERED]: false,

    [HOVER_CONNECTED_DEVICE_PORT_ARRAY]: [],
    [IS_CONNECTED_DEVICE_HOVERED]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SELECT_PORT_OPTION:
            return Object.assign({}, state, {
                [SELECTED_PORT_ARRAY]: action.portArray,
                [IS_OPTION_SELECTED]: true,
            });
        case CLEAN_PORT_OPTION:
            return Object.assign({}, state, {
                [SELECTED_PORT_ARRAY]: [],
                [IS_OPTION_SELECTED]: false,
                [IS_DEVICE_HOVERED]: false,
            });
        case HOVER_DEVICE:
            return Object.assign({}, state, {
                [SELECTED_PORT_ARRAY]: action.portArray,
                [IS_DEVICE_HOVERED]: true,
            });


        case HOVER_CONNECTED_DEVICE:
            return Object.assign({}, state, {
                [HOVER_CONNECTED_DEVICE_PORT_ARRAY]: action.portArray,
                [IS_CONNECTED_DEVICE_HOVERED]: true,
            });
        case CLEAN_CONNECTED_DEVICE_HOVERED:
            return Object.assign({}, state, {
                [HOVER_CONNECTED_DEVICE_PORT_ARRAY]: [],
                [IS_CONNECTED_DEVICE_HOVERED]: false,
            });
        default:
            return state;
    }
};

const selectPortOption = (portArray) => ({
    portArray: portArray,
    type: SELECT_PORT_OPTION
})

const cleanPortOption = () => ({
    type: CLEAN_PORT_OPTION
})

const isOptionSelected = state => state.scratchGui.selectOption[IS_OPTION_SELECTED];
const getSelectedOption = state => state.scratchGui.selectOption[SELECTED_PORT_ARRAY];

const hoverDevice = (portArray) => ({
    portArray: portArray,
    type: HOVER_DEVICE
})
const isDeviceHovered = state => state.scratchGui.selectOption[IS_DEVICE_HOVERED];


const hoverConnectedDevice = (portArray) => ({
    portArray: portArray,
    type: HOVER_CONNECTED_DEVICE
})
const cleanConnectedDeviceHovered = () => ({
    type: CLEAN_CONNECTED_DEVICE_HOVERED
})
const getHoveredConnectedDevice = state => state.scratchGui.selectOption[HOVER_CONNECTED_DEVICE_PORT_ARRAY];
const isConnectedDeviceHovered = state => state.scratchGui.selectOption[IS_CONNECTED_DEVICE_HOVERED];
export {
    reducer as default,
    initialState as selectOptionInitialState,

    selectPortOption,
    cleanPortOption,
    isOptionSelected,
    getSelectedOption,

    hoverDevice,
    isDeviceHovered,

    getHoveredConnectedDevice,
    hoverConnectedDevice,
    cleanConnectedDeviceHovered,
    isConnectedDeviceHovered
};
