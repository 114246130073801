import {
    platformType,
    isPad,
    getPlatform
} from './platform';

import {
    Catagory,
    LogType,
    postMessage
} from './postmessage';

var log;

class LogSystem {
    constructor() {
        this.localeFormat = 'en-GB'
    }
    info(...msg) {
        if (log) {
            log.info(msg)
        } else {
            if (isPad()) {
                postMessage(Catagory.Log, {type: LogType.info, content: msg.toString()})
            }
            console.info(new Date(Date.now()).toLocaleString(this.localeFormat) + ": " + msg)
        }
    }
    debug(...msg) {
        if (log) {
            log.debug(msg)
        } else {
            if (isPad()) {
                postMessage(Catagory.Log, {type: LogType.debug, content: msg.toString()})
            }
            console.debug(new Date(Date.now()).toLocaleString(this.localeFormat) + ": " + msg)
        }
    }
    log(...msg) {
        if (log) {
            log.info(msg)
        } else {
            if (isPad()) {
                postMessage(Catagory.Log, {type: LogType.default, content: msg.toString()})
            }
            console.log(new Date(Date.now()).toLocaleString(this.localeFormat) + ": " + msg)
        }
    }
    warn(...msg) {
        if (log) {
            log.warn(msg)
        } else {
            if (isPad()) {
                postMessage(Catagory.Log, {type: LogType.default, content: msg.toString()})
            }
            console.warn(new Date(Date.now()).toLocaleString(this.localeFormat) + ": " + msg)
        }
    }
    error(...msg) {
        if (log) {
            log.info(msg)
        } else {
            if (isPad()) {
                postMessage(Catagory.Log, {type: LogType.error, content: msg.toString()})
            }
            console.error(new Date(Date.now()).toLocaleString(this.localeFormat) + ": " + msg)
        }
    }
}

var logSystem = new LogSystem();
function setLog(logObj) {
    if (!log) {
        if (logObj) {
            log = logObj;
            log.info('initial log is success');
        } else {
            console.error('initial log is failed');
        }
    }
}

export {
    logSystem as default,
    setLog,
}; 
