import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import VM from 'scratch-vm';
import store from 'store';
import ButtonString from './string.js';
import { DEFAULT_LOCALE } from '../../../config/project-config';

import {
    BUTTON,
    GROUP_BUTTON,
    TYPE,
    ROTATION,
    POWER_TRAIN_MODE,
    getSelectedControllerType,
    setSelectedControllerType,
    getSelectedGroupButton,
    setSelectedGroupButton,

    setSelectedDevice,
    cleanSelectedDevice,
    cleanSelectedGroupButton,
} from '../../../reducers/controller';

import styles from './button-list.css';
import classNames from 'classnames';

const messages = defineMessages({
    setControllerTitle: {
        defaultMessage: "Controller Setting",
        description: "Select Controller title",
        id: "gui.controller.buttonList.setControllerTitle"
    },
    emptyDeviceOption: {
        defaultMessage: "Please bind the {device}",
        description: "Ask to bind the device",
        id: "gui.controller.buttonList.emptyDeviceOption"
    },
    chooseDeviceOption: {
        defaultMessage: "Please choose a device",
        description: "Ask to choose the device",
        id: "gui.controller.buttonList.chooseDeviceOption"
    },
    switchDirection: {
        defaultMessage: "Switch",
        description: "Switch Direction",
        id: "gui.controller.buttonList.switchDirection"
    }
})

const initialState = {
    seletcedButton: null,
    expandOption: null,
    [GROUP_BUTTON.Drivetrain]: { device: null, deviceId: null, mode: null },
    [GROUP_BUTTON.UpDown]: { device: null, deviceId: null, forward: null, reverse: null },
    [GROUP_BUTTON.LeftRight]: { device: null, deviceId: null, forward: null, reverse: null },
    [GROUP_BUTTON.AB]: { device: null, deviceId: null, forward: null, reverse: null },
    [GROUP_BUTTON.XY]: { device: null, deviceId: null, forward: null, reverse: null },
    [GROUP_BUTTON.L1L2]: { device: null, deviceId: null, forward: null, reverse: null },
    [GROUP_BUTTON.R1R2]: { device: null, deviceId: null, forward: null, reverse: null }
};

const OPTION = {
    delete: 'delete'
}

const closeExpandState = {
    expandOption: null,
}

class controllerButtonList extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = initialState;
    }

    componentDidMount() {
        this.parseControllerSetting();
    }

    closeExpand() {
        if (this.state.expandOption) {
            this.setState(closeExpandState);
        }
    }

    updateController() {
        if (this.props.type) {
            this.props.setSelectedControllerType(this.props.type);
        }
    }

    updateSelectedDevice(device, dir1, dir2) {
        if (this.props.type) {
            this.props.setSelectedDevice(device, dir1, dir2);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.type != this.props.type) {
            this.setState(initialState);
            this.props.cleanSelectedGroupButton();
        }
        if (prevProps.type != this.props.type
            || prevProps.show != this.props.show
            || prevProps.motorList != this.props.motorList
            || prevProps.drivetrain != this.props.drivetrain) {
            this.parseControllerSetting();
        }

    }

    parseControllerSetting() {
        let list = this.props.vm.getControllerList();
        let type = this.props.type ? this.props.type : (list[0] ? TYPE.main : list[1] ? TYPE.minor : null)
        let controller = this.props.vm.getControllerButtonSetting(type);
        if (controller) {
            this.setState(controller);
        }
    }

    hasController() {
        let list = this.props.vm.getControllerList();
        if (!list) return false;
        for (let i = 0; i < list.length; i++) {
            if (!!list[i]) {
                return true;
            }
        }
    }

    getButtonTitle(groupButton) {
        let language = store.get("locale", DEFAULT_LOCALE);
        let firstButton = "";
        let secondButton = "";
        let firstDirection = "";
        let secondDirection = "";
        switch (groupButton) {
            case GROUP_BUTTON.Drivetrain:
                return ButtonString[language]['joystick'];
            case GROUP_BUTTON.UpDown:
                firstButton = ButtonString[language][BUTTON.Up];
                secondButton = ButtonString[language][BUTTON.Down];
                firstDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].forward == BUTTON.Up) ? ButtonString[language][ROTATION.forward] : ButtonString[language][ROTATION.reverse];
                secondDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].reverse == BUTTON.Down) ? ButtonString[language][ROTATION.reverse] : ButtonString[language][ROTATION.forward];
                break;
            case GROUP_BUTTON.LeftRight:
                firstButton = ButtonString[language][BUTTON.Left];
                secondButton = ButtonString[language][BUTTON.Right];
                firstDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].forward == BUTTON.Left) ? ButtonString[language][ROTATION.forward] : ButtonString[language][ROTATION.reverse];
                secondDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].reverse == BUTTON.Right) ? ButtonString[language][ROTATION.reverse] : ButtonString[language][ROTATION.forward];
                break;
            case GROUP_BUTTON.AB:
                firstButton = ButtonString[language][BUTTON.A];
                secondButton = ButtonString[language][BUTTON.B];
                firstDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].forward == BUTTON.A) ? ButtonString[language][ROTATION.forward] : ButtonString[language][ROTATION.reverse];
                secondDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].reverse == BUTTON.B) ? ButtonString[language][ROTATION.reverse] : ButtonString[language][ROTATION.forward];
                break;
            case GROUP_BUTTON.XY:
                firstButton = ButtonString[language][BUTTON.X];
                secondButton = ButtonString[language][BUTTON.Y];
                firstDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].forward == BUTTON.X) ? ButtonString[language][ROTATION.forward] : ButtonString[language][ROTATION.reverse];
                secondDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].reverse == BUTTON.Y) ? ButtonString[language][ROTATION.reverse] : ButtonString[language][ROTATION.forward];
                break;
            case GROUP_BUTTON.L1L2:
                firstButton = ButtonString[language][BUTTON.L1];
                secondButton = ButtonString[language][BUTTON.L2];
                firstDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].forward == BUTTON.L1) ? ButtonString[language][ROTATION.forward] : ButtonString[language][ROTATION.reverse];
                secondDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].reverse == BUTTON.L2) ? ButtonString[language][ROTATION.reverse] : ButtonString[language][ROTATION.forward];
                break;
            case GROUP_BUTTON.R1R2:
                firstButton = ButtonString[language][BUTTON.R1];
                secondButton = ButtonString[language][BUTTON.R2];
                firstDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].forward == BUTTON.R1) ? ButtonString[language][ROTATION.forward] : ButtonString[language][ROTATION.reverse];
                secondDirection = (!this.state[groupButton] || !this.state[groupButton].device) ? "" : (this.state[groupButton] && this.state[groupButton].reverse == BUTTON.R2) ? ButtonString[language][ROTATION.reverse] : ButtonString[language][ROTATION.forward];
                break;
        }
        return <div>{ButtonString[language]['button'] + "，" + firstButton + "("}<div className={classNames(styles.firstDirection, firstDirection == ButtonString[language][ROTATION.forward] ? styles.forwardColor : styles.reverseColor)}>{firstDirection}</div>{") / " + secondButton + "("}<div className={classNames(styles.secondDirection, secondDirection == ButtonString[language][ROTATION.forward] ? styles.forwardColor : styles.reverseColor)}>{secondDirection}</div>{")"}</div>
    }

    handleClickGroupButton(groupButton) {
        this.closeExpand();
        this.setState({ seletcedButton: groupButton });
        this.props.setSelectedGroupButton(groupButton);
    }

    handleClickSwitch(groupButton) {
        this.props.vm.setMotorButton(this.props.type, this.state[groupButton].deviceId, this.state[groupButton].reverse, this.state[groupButton].forward);
        this.setState({
            [groupButton]: {
                device: this.state[groupButton].device,
                deviceId: this.state[groupButton].deviceId,
                forward: this.state[groupButton].reverse,
                reverse: this.state[groupButton].forward
            }
        });
        this.closeExpand();
        this.updateSelectedDevice(this.state[groupButton].deviceId, this.state[groupButton].reverse, this.state[groupButton].forward)
    }

    isSwitchEnable(groupButton) {
        return this.state[groupButton] && !!this.state[groupButton].device
    }

    handleClickOptionList(groupButton) {
        this.closeExpand();
        this.setState({ expandOption: groupButton });
    }

    parseButtonToButton(groupButton) {
        let result = [];
        switch (groupButton) {
            case GROUP_BUTTON.Drivetrain:
                result.push(GROUP_BUTTON.Drivetrain);
                break;
            case GROUP_BUTTON.UpDown:
                result.push(BUTTON.Up);
                result.push(BUTTON.Down);
                break;
            case GROUP_BUTTON.LeftRight:
                result.push(BUTTON.Left);
                result.push(BUTTON.Right);
                break;
            case GROUP_BUTTON.AB:
                result.push(BUTTON.A);
                result.push(BUTTON.B);
                break;
            case GROUP_BUTTON.XY:
                result.push(BUTTON.X);
                result.push(BUTTON.Y);
                break;
            case GROUP_BUTTON.L1L2:
                result.push(BUTTON.L1);
                result.push(BUTTON.L2);
                break;
            case GROUP_BUTTON.R1R2:
                result.push(BUTTON.R1);
                result.push(BUTTON.R2);
                break;
            default:
                break;
        }
        return result;
    }

    isOptionSelected(option, groupButton) {
        if (groupButton == GROUP_BUTTON.Drivetrain) {
            return this.state[GROUP_BUTTON.Drivetrain].mode && this.state[GROUP_BUTTON.Drivetrain].mode == option
        } else {
            return option == this.state[GROUP_BUTTON.UpDown].device || option == this.state[GROUP_BUTTON.LeftRight].device
                || option == this.state[GROUP_BUTTON.AB].device || option == this.state[GROUP_BUTTON.XY].device
                || option == this.state[GROUP_BUTTON.L1L2].device || option == this.state[GROUP_BUTTON.R1R2].device
        }
    }

    handleClickOption(option, groupButton) {
        this.closeExpand();
        if (groupButton == GROUP_BUTTON.Drivetrain) {
            if (option.mode == OPTION.delete) {
                this.setState({ [GROUP_BUTTON.Drivetrain]: { device: null, deviceId: null, mode: null } });
                this.props.vm.cleanDrivetrainButton(this.props.type);
            } else {
                this.setState({ [GROUP_BUTTON.Drivetrain]: { device: option.name, deviceId: this.props.drivetrain.id, mode: option.mode } });
                this.props.vm.setDrivetrainButton(this.props.type, this.props.drivetrain.id, option.mode);
            }
            this.updateSelectedDevice(this.props.drivetrain.id, option.mode);
        } else {
            let buttonArray = this.parseButtonToButton(groupButton);
            if (option.mode == OPTION.delete) {
                this.setState({ [groupButton]: { device: null, deviceId: null, forward: null, reverse: null } });
                this.props.vm.cleanMotorButton(this.props.type, buttonArray[0], buttonArray[1]);
            } else {
                this.setState({ [groupButton]: { device: option.name, deviceId: option.id, forward: buttonArray[0], reverse: buttonArray[1] } });
                this.props.vm.setMotorButton(this.props.type, option.id, buttonArray[0], buttonArray[1]);
            }
            this.updateSelectedDevice(option.id, buttonArray[0], buttonArray[1])
        }
    }

    getDrivetrainModeString(mode) {
        let language =store.get("locale", DEFAULT_LOCALE);
        return "(" + ButtonString[language][mode] + ")";
    }

    createDeleteOption() {
        let language = store.get("locale", DEFAULT_LOCALE);
        return { name: ButtonString[language][OPTION.delete], mode: OPTION.delete };
    }

    createSelectOption(groupButton) {
        let optionList = [];
        if (groupButton == GROUP_BUTTON.Drivetrain) {
            if (!this.props.drivetrain) return;
            optionList.push({ name: this.props.drivetrain.name + this.getDrivetrainModeString(POWER_TRAIN_MODE.left), mode: POWER_TRAIN_MODE.left });
            optionList.push({ name: this.props.drivetrain.name + this.getDrivetrainModeString(POWER_TRAIN_MODE.right), mode: POWER_TRAIN_MODE.right });
            optionList.push({ name: this.props.drivetrain.name + this.getDrivetrainModeString(POWER_TRAIN_MODE.tank), mode: POWER_TRAIN_MODE.tank });
            optionList.push({ name: this.props.drivetrain.name + this.getDrivetrainModeString(POWER_TRAIN_MODE.direct), mode: POWER_TRAIN_MODE.direct });
            if (!!this.getSelectedOption(groupButton)) optionList.push(this.createDeleteOption());
            optionList = optionList.map((location, index) =>
                <div key={index}>
                    <div className={classNames(styles.option, (this.isOptionSelected(location.mode, groupButton)) ? styles.optionHide : null)}
                        onClick={() => { this.handleClickOption(location, groupButton) }}>
                        {location.name}
                    </div>
                </div >
            );
        } else {
            optionList = optionList.concat(this.props.motorList);
            if (!optionList instanceof Array || optionList.length < 1) return;
            optionList.sort();
            if (!!this.getSelectedOption(groupButton)) optionList.push(this.createDeleteOption());
            optionList = optionList.map((location, index) => {
                return (this.isOptionSelected(location.name, groupButton)) ? null : <div key={index}>
                    <div className={classNames(styles.option, (this.isOptionSelected(location.name, groupButton)) ? styles.optionHide : null)}
                        onClick={() => { this.handleClickOption(location, groupButton) }}>
                        {location.name}
                    </div>
                </div >
            });
        }
        return (this.isOptionExist(optionList)) ? optionList : null;
    }

    isOptionExist(optionList) {
        if (!optionList) return false;
        let hasOption = false;
        for (let i = 0; i < optionList.length; i++) {
            if (optionList[i]) {
                hasOption = true;
                break;
            }
        }
        return hasOption;
    }

    getDevice(groupButton) {
        let language = store.get("locale", DEFAULT_LOCALE);
        return (groupButton == GROUP_BUTTON.Drivetrain)
            ? ButtonString[language]['drivetrain']
            : ButtonString[language]['motor']
    }

    getSelectedOption(groupButton) {
        if (!this.state[groupButton].device) return null
        let selectedOptionText = this.state[groupButton].device;
        if (groupButton == GROUP_BUTTON.Drivetrain
            && !selectedOptionText.includes(this.getDrivetrainModeString(POWER_TRAIN_MODE.left))
            && !selectedOptionText.includes(this.getDrivetrainModeString(POWER_TRAIN_MODE.right))
            && !selectedOptionText.includes(this.getDrivetrainModeString(POWER_TRAIN_MODE.tank))
            && !selectedOptionText.includes(this.getDrivetrainModeString(POWER_TRAIN_MODE.direct))) {
            selectedOptionText = this.state[GROUP_BUTTON.Drivetrain].device + this.getDrivetrainModeString(this.state[GROUP_BUTTON.Drivetrain].mode)
        }
        return selectedOptionText
    }

    getButtonComponent(groupButton) {
        return (
            <ButtonComponent
                device={this.getDevice(groupButton)}
                title={this.getButtonTitle(groupButton)}
                enableSwitch={this.isSwitchEnable(groupButton)}
                showSwitch={groupButton != GROUP_BUTTON.Drivetrain}
                handleClickGroupButton={() => this.handleClickGroupButton(groupButton)}
                handleClickSwitch={() => this.handleClickSwitch(groupButton)}
                handleClickOptionList={() => this.handleClickOptionList(groupButton)}
                isChecked={this.state.seletcedButton === groupButton}
                optionList={this.createSelectOption(groupButton)}
                isOptionExpand={this.state.expandOption == groupButton}
                seletcedDevice={this.getSelectedOption(groupButton)}
            />
        )
    }

    render() {
        if (!this.hasController()) { return null; }
        return (
            <div className={classNames(styles.content)}>
                <div className={classNames(styles.setControllerTitle)}>
                    <FormattedMessage
                        {...messages.setControllerTitle}
                    />
                </div>
                <div className={classNames(styles.listArea)}>
                    {this.getButtonComponent(GROUP_BUTTON.Drivetrain)}
                    {this.getButtonComponent(GROUP_BUTTON.UpDown)}
                    {this.getButtonComponent(GROUP_BUTTON.LeftRight)}
                    {this.getButtonComponent(GROUP_BUTTON.AB)}
                    {this.getButtonComponent(GROUP_BUTTON.XY)}
                    {this.getButtonComponent(GROUP_BUTTON.L1L2)}
                    {this.getButtonComponent(GROUP_BUTTON.R1R2)}
                </div>
            </div >
        );
    }
}



const ButtonComponent = (props) => {
    return (
        <div className={classNames(styles.buttonBorder, (props.isChecked) ? styles.buttonChecked : null)}>
            <div className={classNames(styles.buttonArea)}
                onClick={() => props.handleClickGroupButton()}>
                <div className={classNames(styles.buttonTitle)}>
                    {props.title}
                </div>

                <div className={classNames(styles.buttonOption, (props.optionList) ? null : styles.disable)}
                    onClick={() => (props.optionList) ? props.handleClickOptionList() : null}>
                    <div className={classNames(styles.optionText)} >
                        {(props.seletcedDevice)
                            ? props.seletcedDevice
                            : (props.optionList) ?
                                <FormattedMessage
                                    {...messages.chooseDeviceOption}
                                />
                                : <FormattedMessage
                                    values={{ device: `${props.device}` }}
                                    {...messages.emptyDeviceOption}
                                />}
                    </div>
                    <div className={classNames(styles.arrow)} />
                </div>
                {(props.showSwitch)
                    ? <div className={classNames(styles.switchButton, (!props.enableSwitch) ? styles.disable : null)}
                        onClick={() => (props.enableSwitch) ? props.handleClickSwitch() : null} >
                        <div className={classNames(styles.switchText)} >
                            <FormattedMessage
                                {...messages.switchDirection}
                            />
                        </div>
                    </div>
                    : null}

                {(props.optionList && props.isOptionExpand)
                    ? <div className={classNames(styles.optionArea)} >
                        {props.optionList}
                    </div> : null}
            </div>
        </div>
    )
}

controllerButtonList.propTypes = {
    show: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    motorList: PropTypes.arrayOf(PropTypes.object),
    drivetrain: PropTypes.object,
};

const mapStateToProps = state => ({
    show: !!getSelectedControllerType(state),
    type: getSelectedControllerType(state),
    groupButton: getSelectedGroupButton(state),
});

const mapDispatchToProps = dispatch => ({
    setSelectedGroupButton: (groupButton) => dispatch(setSelectedGroupButton(groupButton)),
    setSelectedControllerType: (type) => dispatch(setSelectedControllerType(type)),
    cleanSelectedGroupButton: () => dispatch(cleanSelectedGroupButton()),
    setSelectedDevice: (device, dir1, dir2) => dispatch(setSelectedDevice(device, dir1, dir2)),
    cleanSelectedDevice: () => dispatch(cleanSelectedDevice()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(controllerButtonList);