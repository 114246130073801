import Log from '../lib/log.js';
const SET_PROGRESS_PERCENTAGE = 'scratch-gui/task/SET_PROGRESS_PERCENTAGE'
const NEW_TASK = 'scratch-gui/task/NEW_TASK'
const RUN_TASK = 'scratch-gui/task/RUN_TASK'
const FINISH_TASK = 'scratch-gui/task/FINISH_TASK'
const CLEAN_ALL_TASKS = 'scratch-gui/task/CLEAN_ALL_TASKS'
const TASK_ENQUEUE = 'scratch-gui/task/TASK_ENQUEUE'
const TASK_DEQUEUE = 'scratch-gui/task/TASK_DEQUEUE'
const CLEAN_TASK_QUEUE = 'scratch-gui/task/CLEAN_TASK_QUEUE'

const TASK = 'task';
const PERCENTAGE = 'percentage';
const TASK_NAME = 'taskName';

const TASK_QUEUE = 'taskQueue';
const UPDATING_COM = 'updatingCom'

const TASK_ARRAY = 'taskArray';
const WAITTING = 'waitting';
const IN_PROGRESS = 'inProgress';
const COMPLETED = 'compeleted';

const initialState = {
    [TASK]: {
        [PERCENTAGE]: -1,
        [TASK_NAME]: ""
    },
    [TASK_ARRAY]: {
        [WAITTING]: [],
        [IN_PROGRESS]: "",
        [COMPLETED]: []
    },
    [TASK_QUEUE]: [],
    [UPDATING_COM]: 'COM'
};

const taskType = {
    DOWNLOAD_SCRIPT: "download_script",
    DOWNLOAD_FIRMWARE: "download_firmware",
    UPDATE_FIRMWARE: "update_firmware",
    UPDATE_SYSTEM: "update_system",
    UPDATE_SOFTWARE: "update_software",
    UPDATE_BT_FIRMWARE: "update_bluetooth_firmware",
    UPDATE_RC_FIRMWARE: "update_remote_controller_firmware"
}
var wattingList = [];
var completedList = [];
var tempTaskQueue = [];

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case UPDATING_COM:
            return Object.assign({}, state, {
                [action.type]: action.sn
            });
        case NEW_TASK:
            wattingList = (state[TASK_ARRAY][WAITTING] && Array.isArray(state[TASK_ARRAY][WAITTING]) && state[TASK_ARRAY][WAITTING].length > 0)
                ? [...state[TASK_ARRAY][WAITTING], action.taskName]
                : [action.taskName];
            Log.info('NEW_TASK ' + action.taskName)
            return Object.assign({}, state, {
                [TASK_ARRAY]: {
                    [WAITTING]: wattingList,
                    [IN_PROGRESS]: state[TASK_ARRAY][IN_PROGRESS],
                    [COMPLETED]: state[TASK_ARRAY][COMPLETED]
                }
            });
        case RUN_TASK:
            Log.info('RUN_TASK ' + action.taskName)
            Log.info('RUN_TASK state[TASK_ARRAY][WAITTING] : ' + state[TASK_ARRAY][WAITTING])
            wattingList = (state[TASK_ARRAY][WAITTING] && Array.isArray(state[TASK_ARRAY][WAITTING]) && state[TASK_ARRAY][WAITTING].length > 0)
                ? state[TASK_ARRAY][WAITTING].filter(task => task !== action.taskName)
                : [];
            Log.info('RUN_TASK wattingList : ' + wattingList)
            return Object.assign({}, state, {
                [TASK_ARRAY]: {
                    [WAITTING]: (wattingList && Array.isArray(wattingList) && wattingList.length > 0) ? wattingList : [],
                    [IN_PROGRESS]: action.taskName,
                    [COMPLETED]: state[TASK_ARRAY][COMPLETED]
                }
            });
        case SET_PROGRESS_PERCENTAGE:
            return Object.assign({}, state, {
                [TASK]: {
                    [PERCENTAGE]: action.percentage,
                    [TASK_NAME]: action.taskName
                }
            });
        case FINISH_TASK:
            if (!state[TASK][TASK_NAME] || state[TASK][TASK_NAME] == "") return state;
            completedList = (state[TASK_ARRAY][COMPLETED] && Array.isArray(state[TASK_ARRAY][COMPLETED]) && state[TASK_ARRAY][COMPLETED].length > 0)
                ? [...state[TASK_ARRAY][COMPLETED], action.taskName]
                : [action.taskName];
            Log.info('FINISH_TASK ' + action.taskName)
            return Object.assign({}, state, {
                [TASK]: {
                    [PERCENTAGE]: -1,
                    [TASK_NAME]: ""
                },
                [TASK_ARRAY]: {
                    [WAITTING]: state[TASK_ARRAY][WAITTING],
                    [IN_PROGRESS]: "",
                    [COMPLETED]: completedList
                }
            });
        case CLEAN_ALL_TASKS:
            Log.info('CLEAN_ALL_TASKS')
            return Object.assign({}, state, {
                [TASK]: {
                    [PERCENTAGE]: -1,
                    [TASK_NAME]: ""
                },
                [TASK_ARRAY]: {
                    [WAITTING]: [],
                    [IN_PROGRESS]: "",
                    [COMPLETED]: []
                }
            });
        case TASK_ENQUEUE:
            tempTaskQueue = (state[TASK_QUEUE] && Array.isArray(state[TASK_QUEUE]) && state[TASK_QUEUE].length > 0)
                ? [...state[TASK_QUEUE], action.taskName]
                : [action.taskName];
            Log.info('TASK_ENQUEUE ' + action.taskName)
            return Object.assign({}, state, {
                [TASK_QUEUE]: tempTaskQueue
            });
        case TASK_DEQUEUE:
            if ((state[TASK_QUEUE] && Array.isArray(state[TASK_QUEUE]) && state[TASK_QUEUE].length > 0)) {
                tempTaskQueue = state[TASK_QUEUE];
                tempTaskQueue.shift();
            } else {
                tempTaskQueue = []
            }
            Log.info('TASK_DEQUEUE')
            return Object.assign({}, state, {
                [TASK_QUEUE]: tempTaskQueue
            });
        case CLEAN_TASK_QUEUE:
            Log.info('CLEAN_ALL_TASKS')
            return Object.assign({}, state, {
                [TASK_QUEUE]: []
            });
        default:
            return state;
    }
};

const setTaskProgress = (percentage, taskName) => ({
    type: SET_PROGRESS_PERCENTAGE,
    percentage: percentage,
    taskName: taskName
})

const newTask = (taskName) => ({
    type: NEW_TASK,
    taskName: taskName
})

const runTask = (taskName) => ({
    type: RUN_TASK,
    taskName: taskName
})

const finishTask = (taskName) => ({
    type: FINISH_TASK,
    taskName: taskName
})

const cleanTask = () => ({
    type: CLEAN_ALL_TASKS
})

const getTaskProgress = state => state.scratchGui.task[TASK];
const getInProgressTask = state => state.scratchGui.task[TASK_ARRAY][IN_PROGRESS];
const getTaskArray = state => state.scratchGui.task[TASK_ARRAY];

const taskEnqueue = (taskName) => ({
    type: TASK_ENQUEUE,
    taskName: taskName
})

const taskDequeue = () => ({
    type: TASK_DEQUEUE
})

const cleanTaskQueue = () => ({
    type: CLEAN_TASK_QUEUE
})

const setUpdatingComPort = (comPort) => ({
    type: UPDATING_COM,
    sn: comPort
})

const getUpdatingComPort = state => state.scratchGui.task[UPDATING_COM];

const getTaskQueue = state => state.scratchGui.task[TASK_QUEUE];
const getFirstTaskInQueue = state => (state.scratchGui.task[TASK_QUEUE] && state.scratchGui.task[TASK_QUEUE].length > 0)
    ? state.scratchGui.task[TASK_QUEUE][0] : null;

export {
    reducer as default,
    initialState as taskInitialState,
    setTaskProgress,
    cleanTask,
    getTaskProgress,
    newTask,
    runTask,
    finishTask,
    getInProgressTask,
    getTaskArray,
    taskType,
    taskEnqueue,
    taskDequeue,
    cleanTaskQueue,
    getTaskQueue,
    getFirstTaskInQueue,
    setUpdatingComPort,
    getUpdatingComPort
};
