const SELECTED_CONTROLLER_TYPE = "selected_controller_type"
const SELECTED_BUTTON = "selected_button"
const SELECTED_DEVICE = "selected_device"
const CLEAN_CONTROLLER = "clean_controller"
const SELECTED_TAB = "selected_tab"
const ENABLE_CONTROLLER = "enable_controller"
const DELETE_CONTROLLER1 = "delete_controller1"
const CONTROLLER_INFO = 'controllerInfo'
const ALL_CONTROLLER_PORT_INFO = 'allControllerPortInfo'
const CONTROLLER_STATUS = 'controllerStatus'
const CONTROLLER_RESPONSE = 'controllerResuponse'
const IS_RC_FIRMWARE_UPDATING = 'isRCFirmwareUpdating'
const TAB_INDEX = {
    brain: 'brain',
    controller: 'controller'
}

const DEFAULT_CONTROLLER_INFO = {
    ver: '0.0.0.1',
    ssn: '11534654623456'
}

const INIT_CONTROLLER_STATUS = {
    "controller1": { "up": false, "down": false, "left": false, "right": false, "A": false, "B": false, "X": false, "Y": false, "L1": false, "L2": false, "L3": false, "R1": false, "R2": false, "R3": false, "L_AXIS_X": 0, "L_AXIS_Y": 0, "R_AXIS_X": 0, "R_AXIS_Y": 0 },
    "controller2": { "up": false, "down": false, "left": false, "right": false, "A": false, "B": false, "X": false, "Y": false, "L1": false, "L2": false, "L3": false, "R1": false, "R2": false, "R3": false, "L_AXIS_X": 0, "L_AXIS_Y": 0, "R_AXIS_X": 0, "R_AXIS_Y": 0 }
}

const initialState = {
    [SELECTED_CONTROLLER_TYPE]: null,
    [SELECTED_BUTTON]: null,
    [SELECTED_DEVICE]: null,
    [SELECTED_TAB]: TAB_INDEX.brain,
    [ENABLE_CONTROLLER]: true,
    [DELETE_CONTROLLER1]: false,
    [CONTROLLER_INFO]: null,
    [ALL_CONTROLLER_PORT_INFO]: [],
    [IS_RC_FIRMWARE_UPDATING]: false,
    [CONTROLLER_STATUS]: INIT_CONTROLLER_STATUS,
    [CONTROLLER_RESPONSE]: {}
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SELECTED_CONTROLLER_TYPE:
            return Object.assign({}, state, {
                [SELECTED_CONTROLLER_TYPE]: action.content,
                [SELECTED_BUTTON]: null,
                [SELECTED_DEVICE]: null
            });
        case SELECTED_BUTTON:
        case SELECTED_DEVICE:
        case SELECTED_TAB:
        case ENABLE_CONTROLLER:
        case DELETE_CONTROLLER1:
        case IS_RC_FIRMWARE_UPDATING:
            return Object.assign({}, state, {
                [action.type]: action.content
            });
        case CLEAN_CONTROLLER:
            return Object.assign({}, state, {
                [SELECTED_CONTROLLER_TYPE]: null,
                [SELECTED_BUTTON]: null,
                [SELECTED_DEVICE]: null,
                [SELECTED_TAB]: TAB_INDEX.brain,
                [DELETE_CONTROLLER1]: false
            });
        case CONTROLLER_INFO:
            return Object.assign({}, state, {
                [action.type]: action.info,
            });
        case ALL_CONTROLLER_PORT_INFO:
            return Object.assign({}, state, {
                [action.type]: action.controllers
            });
        case CONTROLLER_STATUS:
            return Object.assign({}, state, {
                [action.type]: action.status
            });
        case CONTROLLER_RESPONSE:
            return Object.assign({}, state, {
                [action.type]: action.response
            });
        default:
            return state;
    }
};

const setEnableEduController = (enable) => ({
    type: ENABLE_CONTROLLER,
    content: enable
});


const confirmDeleteController = () => ({
    type: DELETE_CONTROLLER1,
    content: true
});

const resetDeleteController = () => ({
    type: DELETE_CONTROLLER1,
    content: false
});

const setSelectedControllerType = controller => ({
    type: SELECTED_CONTROLLER_TYPE,
    content: controller
});

const setSelectedGroupButton = button => ({
    type: SELECTED_BUTTON,
    content: button
});

const setSelectedDevice = (device, direction1, direction2) => ({
    type: SELECTED_DEVICE,
    content: device + direction1 + direction2
});

const setSelectedTab = (tab) => ({
    type: SELECTED_TAB,
    content: tab
});

const setControllerInfo = info => ({
    type: CONTROLLER_INFO,
    info: info
});

const setAllControllerPortInfo = controllers => ({
    type: ALL_CONTROLLER_PORT_INFO,
    controllers: controllers
});

const setControllerStatus = status => ({
    type: CONTROLLER_STATUS,
    status: status
});

const setControllerResponse = response => ({
    type: CONTROLLER_RESPONSE,
    response: response
});
const cleanSelectedControllerType = () => setSelectedControllerType(null);

const cleanSelectedGroupButton = () => setSelectedGroupButton(null);

const cleanSelectedDevice = () => setSelectedDevice(null, null, null);

const cleanSelectedTab = () => setSelectedTab(null);

const cleanControllerReducer = () => ({ type: CLEAN_CONTROLLER })

const getSelectedControllerType = state => state.scratchGui.controller[SELECTED_CONTROLLER_TYPE];
const getSelectedGroupButton = state => state.scratchGui.controller[SELECTED_BUTTON];
const getSelectedDevice = state => state.scratchGui.controller[SELECTED_DEVICE];
const getSelectedTab = state => state.scratchGui.controller[SELECTED_TAB];
const getControllerInfo = state => state.scratchGui.controller[CONTROLLER_INFO];
const getAllControllerPortInfo = state => state.scratchGui.controller[ALL_CONTROLLER_PORT_INFO];

const isControllerEnable = state => state.scratchGui.controller[ENABLE_CONTROLLER];
const isDeleteControllerConfirm = state => state.scratchGui.controller[DELETE_CONTROLLER1];
const getControllerStatus = state => state.scratchGui.controller[CONTROLLER_STATUS];
const getControllerResponse = state => state.scratchGui.controller[CONTROLLER_RESPONSE];
const resetControllerStatus = () => setControllerStatus(INIT_CONTROLLER_STATUS)
const beginRCFirmwareUpdating = () => ({
    type: IS_RC_FIRMWARE_UPDATING,
    content: true
});

const endRCFirmwareUpdating = () => ({
    type: IS_RC_FIRMWARE_UPDATING,
    content: false
});

const isRCFirmwareUpdating = state => state.scratchGui.controller[IS_RC_FIRMWARE_UPDATING];

const ROTATION = {
    forward: "forward",
    reverse: "reverse"
}

const TYPE = {
    main: "main",
    minor: "minor"
}

const POWER_TRAIN_MODE = {
    left: "leftDrive",
    right: "rightDrive",
    tank: "tankDrive",
    direct: "directDrive"
}


const GROUP_BUTTON = {
    Drivetrain: "drivetrain",
    L3R3: 'L3R3',
    UpDown: 'UpDown',
    LeftRight: 'LeftRight',
    AB: 'AB',
    XY: 'XY',
    L1L2: 'L1L2',
    R1R2: 'R1R2',
}

const BUTTON = {
    Up: "Up",
    Down: "Down",
    Left: "Left",
    Right: "Right",
    X: "X",
    Y: "Y",
    A: "A",
    B: "B",
    L1: "L1",
    R1: "R1",
    L2: "L2",
    R2: "R2",
    L3: "L3",
    R3: "R3",
}
const RES_BUTTON = {
    up: "up",
    down: "down",
    left: "left",
    right: "right",
    X: "X",
    Y: "Y",
    A: "A",
    B: "B",
    L1: "L1",
    R1: "R1",
    L2: "L2",
    R2: "R2",
    L3: "L3",
    R3: "R3",
    L_AXIS_X:"L_AXIS_X",
    L_AXIS_Y:"L_AXIS_Y",
    R_AXIS_X:"R_AXIS_X",
    R_AXIS_Y:"R_AXIS_Y"
}

export {
    reducer as default,
    initialState as controllerInitialState,
    BUTTON,
    POWER_TRAIN_MODE,
    GROUP_BUTTON,
    TYPE,
    ROTATION,
    TAB_INDEX,
    RES_BUTTON,
    setSelectedControllerType,
    getSelectedControllerType,
    cleanSelectedControllerType,

    setSelectedGroupButton,
    getSelectedGroupButton,
    cleanSelectedGroupButton,

    setSelectedDevice,
    getSelectedDevice,
    cleanSelectedDevice,

    setSelectedTab,
    getSelectedTab,
    cleanSelectedTab,
    cleanControllerReducer,

    setEnableEduController,
    isControllerEnable,

    confirmDeleteController,
    resetDeleteController,
    isDeleteControllerConfirm,

    setControllerInfo,
    getControllerInfo,
    setAllControllerPortInfo,
    getAllControllerPortInfo,

    beginRCFirmwareUpdating,
    endRCFirmwareUpdating,
    isRCFirmwareUpdating,

    setControllerResponse,
    getControllerResponse,
    setControllerStatus,
    getControllerStatus,
    resetControllerStatus,
    INIT_CONTROLLER_STATUS
}