const EN_SENSOR = {
    "motor120": {
        "type": "M2 Motor",
        "velocity": "Speed",
        "angle": "Angle",
        "turns": "Turns"
    },
    "motor360": {
        "type": "M3 Motor",
        "velocity": "Speed",
        "angle": "Angle",
        "turns": "Turns"
    },
    "touch_led": {
        "type": "Touch LED",
        "touched": "Status",
        "led_mode": "LED",
        "led": "LED",
        "color": "Color name"
    },
    "color": {
        "type": "Color",
        "detected": "Near an object?",
        "color": "Color Detected",
        "hue": "Hue",
        "ambient": "Brightness",
        "bright": "LED"
    },
    "gyro": {
        "type": "Gyro",
        "angle": "Angle",
        "spin": "Spin",
        "heading": "Heading",
        "rotation": "Rotation"
    },
    "vision": {
        "type": "vision"
    },
    "ds": {
        "type": "Sound"
    }
}

const TW_SENSOR = {
    "motor120": {
        "type": "M2 馬達",
        "velocity": "轉動速度",
        "angle": "角度",
        "turns": "圈數"
    },
    "motor360": {
        "type": "M3 馬達",
        "velocity": "轉動速度",
        "angle": "角度",
        "turns": "圈數"
    },
    "touch_led": {
        "type": "觸碰LED感測器",
        "touched": "狀態",
        "led_mode": "LED燈",
        "led": "LED燈",
        "color": "顏色"
    },
    "color": {
        "type": "色彩感測器",
        "detected": "靠近目標?",
        "color": "偵測顏色",
        "hue": "色調度數",
        "ambient": "環境亮度",
        "bright": "LED亮度"
    },
    "gyro": {
        "type": "陀螺儀",
        "angle": "角度",
        "spin": "旋轉",
        "heading": "定位角度",
        "rotation": "轉向角度"
    },
    "vision": {
        "type": "視覺感測器"
    },
    "ds": {
        "type": "智慧語音感測器"
    }
}

const CN_SENSOR = {
    "motor120": {
        "type": "M2 电机",
        "velocity": "转动速度",
        "angle": "角度",
        "turns": "圈数"
    },
    "motor360": {
        "type": "M3 电机",
        "velocity": "转动速度",
        "angle": "角度",
        "turns": "圈数"
    },
    "touch_led": {
        "type": "触碰LED传感器",
        "touched": "状态",
        "led_mode": "LED灯",
        "led": "LED灯",
        "color": "颜色"
    },
    "color": {
        "type": "色彩传感器",
        "detected": "靠近目标?",
        "color": "侦测颜色",
        "hue": "色调度数",
        "ambient": "环境亮度",
        "bright": "LED亮度"
    },
    "gyro": {
        "type": "陀螺仪",
        "angle": "角度",
        "spin": "旋转",
        "heading": "定位角度",
        "rotation": "转向角度"
    },
    "vision": {
        "type": "视觉传感器"
    },
    "ds": {
        "type": "智能语音传感器"
    }
}

const EN_UNIT = {
    "motor120": {
        "velocity": "rpm",
        "angle": "Degrees",
        "turns": ""
    },
    "motor360": {
        "velocity": "rpm",
        "angle": "Degrees",
        "turns": ""
    },
    "touch_led": {
        "led_mode": "",
        "color": ""
    },
    "color": {
        "detected": "",
        "color": "",
        "hue": "",
        "ambient": "%",
        "bright": "%"
    },
    "gyro": {
        "angle": "",
        "spin": "",
        "heading": "",
        "rotation": ""
    }
}

const TW_UNIT = {
    "motor120": {
        "velocity": "rpm",
        "angle": "度",
        "turns": ""
    },
    "motor360": {
        "velocity": "rpm",
        "angle": "度",
        "turns": ""
    },
    "touch_led": {
        "led_mode": "",
        "color": ""
    },
    "color": {
        "detected": "",
        "color": "",
        "hue": "",
        "ambient": "%",
        "bright": "%"
    },
    "gyro": {
        "angle": "",
        "spin": "",
        "heading": "",
        "rotation": ""
    }
}

const CN_UNIT = {
    "motor120": {
        "velocity": "rpm",
        "angle": "度",
        "turns": ""
    },
    "motor360": {
        "velocity": "rpm",
        "angle": "度",
        "turns": ""
    },
    "touch_led": {
        "led_mode": "",
        "color": ""
    },
    "color": {
        "detected": "",
        "color": "",
        "hue": "",
        "ambient": "%",
        "bright": "%"
    },
    "gyro": {
        "angle": "",
        "spin": "",
        "heading": "",
        "rotation": ""
    }
}

const EN_VALUE = {
    "motor120": {
    },
    "motor360": {
    },
    "touch_led": {
        "touched": {
            "true": "Touched",
            "false": "Untouched"
        },
        "led_mode": {
            "on": "ON",
            "off": "OFF"
        },
        "led": {
            "on": "ON",
            "off": "OFF"
        },
        "color": {
            "undefined": "No Color",
            "red": "Red",
            "orange": "Orange",
            "yellow": "Yellow",
            "green": "Green",
            "skyblue": "Sky Blue",
            "blue": "Blue",
            "purple": "Purple",
            "violet": "Violet",
            "white": "White",
            "pink": "Pink",
            "custom": "Custom",
        }
    },
    "color": {
        "detected": {
            "true": "Yes",
            "false": "No"
        },
        "color": {
            "undefined": "No Color",
            "red": "Red",
            "orange": "Orange",
            "yellow": "Yellow",
            "green": "Green",
            "skyblue": "Sky Blue",
            "blue": "Blue",
            "purple": "Purple",
            "violet": "Violet",
            "white": "White",
            "pink": "Pink",
            "custom": "Custom",
        }
    },
    "gyro": {
    }
}

const TW_VALUE = {
    "motor120": {
    },
    "motor360": {
    },
    "touch_led": {
        "touched": {
            "true": "被觸碰",
            "false": "未觸碰"
        },
        "led_mode": {
            "on": "開",
            "off": "關"
        },
        "led": {
            "on": "開",
            "off": "關"
        },
        "color": {
            "undefined": "無顏色",
            "red": "紅色",
            "orange": "橘色",
            "yellow": "黃色",
            "green": "綠色",
            "skyblue": "天藍色",
            "blue": "藍色",
            "purple": "紫色",
            "violet": "亮紫色",
            "white": "白色",
            "pink": "粉紅色",
            "custom": "自訂",
        }
    },
    "color": {
        "detected": {
            "true": "是",
            "false": "否"
        },
        "color": {
            "undefined": "無顏色",
            "red": "紅色",
            "orange": "橘色",
            "yellow": "黃色",
            "green": "綠色",
            "skyblue": "天藍色",
            "blue": "藍色",
            "purple": "紫色",
            "violet": "亮紫色",
            "white": "白色",
            "pink": "粉紅色",
            "custom": "自訂",
        }
    },
    "gyro": {
    }
}

const CN_VALUE = {
    "motor120": {
    },
    "motor360": {
    },
    "touch_led": {
        "touched": {
            "true": "被触碰",
            "false": "未触碰"
        },
        "led_mode": {
            "on": "开",
            "off": "关"
        },
        "led": {
            "on": "开",
            "off": "关"
        },
        "color": {
            "undefined": "无颜色",
            "red": "红色",
            "orange": "橘色",
            "yellow": "黄色",
            "green": "绿色",
            "skyblue": "天蓝色",
            "blue": "蓝色",
            "purple": "紫色",
            "violet": "亮紫色",
            "white": "白色",
            "pink": "粉红色",
            "custom": "自定义",
        }
    },
    "color": {
        "detected": {
            "true": "是",
            "false": "否"
        },
        "color": {
            "undefined": "无颜色",
            "red": "红色",
            "orange": "橘色",
            "yellow": "黄色",
            "green": "绿色",
            "skyblue": "天蓝色",
            "blue": "蓝色",
            "purple": "紫色",
            "violet": "亮紫色",
            "white": "白色",
            "pink": "粉红色",
            "custom": "自定义",
        }
    },
    "gyro": {
    }
}

export const getSensorDataString = (language, sensor, index) => {
    let stringJson = EN_SENSOR;
    if (language == 'zh-tw') {
        stringJson = TW_SENSOR;
    } else if (language == 'en') {
        stringJson = EN_SENSOR;
    } else if (language == 'zh-cn') {
        stringJson = CN_SENSOR;
    }
    return stringJson[sensor] && stringJson[sensor][index] ? stringJson[sensor][index] : index
}

export const getDataUnit = (language, sensor, index) => {
    let stringJson = EN_UNIT;
    if (language == 'zh-tw') {
        stringJson = TW_UNIT;
    } else if (language == 'en') {
        stringJson = EN_UNIT;
    } else if (language == 'zh-cn') {
        stringJson = CN_UNIT;
    }
    return stringJson[sensor] && stringJson[sensor][index] ? stringJson[sensor][index] : ""
}

export const getDataValueString = (language, sensor, index, value) => {
    let stringJson = EN_VALUE;
    let stringValue = value.toString()
    if (language == 'zh-tw') {
        stringJson = TW_VALUE;
    } else if (language == 'en') {
        stringJson = EN_VALUE;
    } else if (language == 'zh-cn') {
        stringJson = CN_VALUE;
    }
    if (stringJson[sensor] && stringJson[sensor][index] && stringJson[sensor] == SENSOR.motor120 && stringJson[sensor][index] == "turns") {
        let numValue = parseFloat(stringValue)
        if (numValue > 0) stringValue = "+" + stringValue
    }
    return stringJson[sensor] && stringJson[sensor][index] && stringJson[sensor][index][stringValue] ? stringJson[sensor][index][stringValue] : stringValue
}

export const SENSOR = {
    motor120: "motor120",
    motor360: "motor360",
    touch_led: "touch_led",
    color: "color",
    gyro: "gyro",
    vision: "vision",
    ds: "ds",
    speaker: "ds",
    none: ""
}

export const INDEX = {
    type: "type"
}
