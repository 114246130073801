import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';

import SubMenuComponent from '../components/menu/submenu.jsx';

class SubMenu extends React.Component {
    constructor (props) {
        super(props);
    }
    render () {
        const {
            children,
            ...props
        } = this.props;
        return (
            <SubMenuComponent
                {...props}
            >
                {children}
            </SubMenuComponent>
        );
    }
}

SubMenu.propTypes = {
    children: PropTypes.node
};

export default SubMenu;
