import arrow_mouse from './images/arrow_mouse.png'
import arrow_mode_right_1 from './images/arrow_mode_right_1.png'
import arrow_mode_right_2 from './images/arrow_mode_right_2.png'
import arrow_mode_right_3 from './images/arrow_mode_right_3.png'
import arrow_mode_right_4 from './images/arrow_mode_right_4.png'
import arrow_mode_right_5 from './images/arrow_mode_right_5.png'
import arrow_mode_right_6 from './images/arrow_mode_right_6.png'
import arrow_mode_right_7 from './images/arrow_mode_right_7.png'
import arrow_mode_right_8 from './images/arrow_mode_right_8.png'

import long_right_arrow_1 from './images/long_right_arrow_1.png'
import long_right_arrow_2 from './images/long_right_arrow_2.png'
import long_right_arrow_3 from './images/long_right_arrow_3.png'
import long_right_arrow_4 from './images/long_right_arrow_5.png'
import long_right_arrow_5 from './images/long_right_arrow_4.png'
import long_right_arrow_6 from './images/long_right_arrow_5.png'
import long_right_arrow_7 from './images/long_right_arrow_6.png'
import long_right_arrow_8 from './images/long_right_arrow_7.png'

import { ANIMATION_MODE } from './animation-mode'

const FRAMES_ARROW_RIGHT = [
    arrow_mode_right_1,
    arrow_mode_right_2,
    arrow_mode_right_3,
    arrow_mode_right_4,
    arrow_mode_right_5,
    arrow_mode_right_6,
    arrow_mode_right_7,
    arrow_mode_right_8
]

const FRAMES_LONG_RIGHT_ARROW = [
    long_right_arrow_1,
    long_right_arrow_2,
    long_right_arrow_3,
    long_right_arrow_4,
    long_right_arrow_5,
    long_right_arrow_6,
    long_right_arrow_7,
    long_right_arrow_8
]

const ANIMATION_MOUSE_TRANSLATE = {
    "type": ANIMATION_MODE.TRANSLATE,
    "interval": 30,
    "start": 15,
    "stop": 30,
    "end": 45,
    "source": arrow_mouse,
}

const ANIMATION_ARROW_RIGHT = {
    "type": ANIMATION_MODE.PLAY_FRAME,
    "frames": FRAMES_ARROW_RIGHT,
    "interval": 100,
}

const ANIMATION_LONG_RIGHT_ARROW = {
    "type": ANIMATION_MODE.PLAY_FRAME,
    "frames": FRAMES_LONG_RIGHT_ARROW,
    "interval": 100,
}

export {
    ANIMATION_MOUSE_TRANSLATE,
    ANIMATION_ARROW_RIGHT,
    ANIMATION_LONG_RIGHT_ARROW,
}