const ENABLE_BRAIN_TYPE_ENTRY = true;
const ENABLE_BRAIN_TYPE_EDU_AND_ENTRY = true;
const ENABLE_BRAIN_TYPE_WEB_VR = true;

const PROJECT_STYLE = 'vr';

const DEFAULT_LOCALE = "zh-tw";

const ENABLE_AI_SPEECH = true;

export {
    ENABLE_BRAIN_TYPE_ENTRY,
    ENABLE_BRAIN_TYPE_EDU_AND_ENTRY,
    ENABLE_BRAIN_TYPE_WEB_VR,
    PROJECT_STYLE,
    ENABLE_AI_SPEECH,
    DEFAULT_LOCALE
}