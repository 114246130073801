import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import VM from 'scratch-vm';
import store from 'store';

import { DEFAULT_LOCALE } from '../../../config/project-config';
import {
    openAddPage,
    isPortOpen,
    getPortNumber,
    isCheckingImportDevice
} from '../../../reducers/device-manager-controller';

import {
    hoverDevice,
    cleanPortOption,
} from '../../../reducers/select-option';

import { uiType, getUIStyle } from '../../../reducers/ui-style';

import {
    isDeleteDeviceMode,
} from '../../../reducers/delete-device';

import {
    showDeviceHelpDialog,
    isDeviceHelpDialogShow,
    showVisionTutorialDialog,
    showVisionSettingDialog,
    isVisionSettingDialogShow,
    hideStreamingDialog
} from '../../../reducers/dialog';
import {
    isTagInformationEnable,
    COLOR_DATA_LIST,
    IS_TAG_INFORMATION_ENABLE,
    QRCODE_RESULT,
    DEFAULT_COLOR_DATA_LIST,

    setLaunchSettingWay,
    LAUNCH_FROM,
} from '../../../reducers/vision';
import {
    getPlatform
} from '../../../lib/platform'
import drivetrain from '../pictures/device_drivetrain_icon.svg';
import motor100 from '../pictures/device_motor_icon.svg';
import motor300 from '../pictures/device_motor_icon.svg';
import vision from '../pictures/device_visionsensor_icon.svg';
import speaker from '../pictures/device_mic_speaker_icon.svg';
import gyro from '../pictures/device_gyro_icon.svg';
import touchLed from '../pictures/device_touchled_icon.svg';
import colorSensor from '../pictures/device_colorsensor_icon.svg';
import help from '../pictures/help.svg';

import drivetrainWW from '../pictures/ww/device_drivetrain_icon.svg';
import motor100WW from '../pictures/ww/device_motor_icon.svg';
import motor300WW from '../pictures/ww/device_motor_icon.svg';
import visionWW from '../pictures/ww/device_visionsensor_icon.svg';
import speakerWW from '../pictures/ww/device_mic_speaker_icon.svg';
import gyroWW from '../pictures/ww/device_gyro_icon.svg';
import touchLedWW from '../pictures/ww/device_touchled_icon.svg';
import colorSensorWW from '../pictures/ww/device_colorsensor_icon.svg';
import helpWW from '../pictures/ww/help.svg';

import bumper from '../pictures/device_bumper_icon.svg';
import led from '../pictures/device_led_icon.svg';
import motor from '../pictures/device_motor_icon.svg';
import lineTracker from '../pictures/device_linetracker_icon.svg';
import buzzer from '../pictures/device_buzzer_icon.svg';
import ultrasonic from '../pictures/device_ultrasonic_icon.png';

import bumperWW from '../pictures/ww/device_bumper_icon.svg';
import ledWW from '../pictures/ww/device_led_icon.svg';
import motorWW from '../pictures/ww/device_motor_icon.svg';
import lineTrackerWW from '../pictures/ww/device_linetracker_icon.svg';
import buzzerWW from '../pictures/ww/device_buzzer_icon.svg';
import ultrasonicWW from '../pictures/ww/device_ultrasonic_icon.png';

import styles from './device-list.css';
import classNames from 'classnames';
import { DeviceItem } from './device-list-component.jsx'

const messages = defineMessages({
    selectDeviceTitle: {
        defaultMessage: "Select device",
        description: "Select device title",
        id: "gui.deviceList.selectDeviceTitle"
    },
    drivetrain: {
        defaultMessage: "Power Train",
        description: "drivetrain",
        id: "gui.device.drivetrain"
    },
    motor100: {
        defaultMessage: "Motor100",
        description: "motor100",
        id: "gui.device.motor100"
    },
    motor300: {
        defaultMessage: "Motor300",
        description: "motor300",
        id: "gui.device.motor300"
    },
    colorSensor: {
        defaultMessage: "Color",
        description: "Color Sensor",
        id: "gui.device.colorSensor"
    },
    touchLed: {
        defaultMessage: "TouchLED",
        description: "Touch LED",
        id: "gui.device.touchLed"
    },
    gyro: {
        defaultMessage: "Gyro",
        description: "Gyro",
        id: "gui.device.gyro"
    },
    vision: {
        defaultMessage: "Vision",
        description: "Vision",
        id: "gui.device.vision"
    },
    speaker: {
        defaultMessage: "Sound",
        description: "Mic Speaker",
        id: "gui.device.speaker"
    },
    bumper: {
        defaultMessage: "Bumper Sensor",
        description: "Bumper Sensor",
        id: "gui.device.bumper"
    },
    led: {
        defaultMessage: "LED light",
        description: "LED light",
        id: "gui.device.led"
    },
    motor: {
        defaultMessage: "Motor",
        description: "motor",
        id: "gui.device.motor"
    },
    threeWireMotor: {
        defaultMessage: "Motor",
        description: "threeWireMotor",
        id: "gui.device.threeWireMotor"
    },
    lineTracker: {
        defaultMessage: "Tracker sensor",
        description: "Tracker sensor",
        id: "gui.device.lineTracker"
    },
    buzzer: {
        defaultMessage: "Buzzer",
        description: "buzzer",
        id: "gui.device.buzzer"
    },
    ultrasonic: {
        defaultMessage: "Ultrasonic",
        description: "ultrasonic",
        id: "gui.device.ultrasonic"
    },
    brainEdu: {
        defaultMessage: "B1 Controller",
        description: "B1 Controller",
        id: "gui.device.b1"
    },
    brainEntry: {
        defaultMessage: "B2 Controller",
        description: "B1 Controller",
        id: "gui.device.b2"
    }

})

const imageTypes = {
    drivetrain: drivetrain,
    motor100: motor100,
    motor300: motor300,
    colorSensor: colorSensor,
    touchLed: touchLed,
    gyro: gyro,
    vision: vision,
    speaker: speaker,
    drivetrainWW: drivetrainWW,
    motor100WW: motor100WW,
    motor300WW: motor300WW,
    colorSensorWW: colorSensorWW,
    touchLedWW: touchLedWW,
    gyroWW: gyroWW,
    visionWW: visionWW,
    speakerWW: speakerWW,
    motor: motor,
    threeWireMotor: motor,
    lineTracker: lineTracker,
    buzzer: buzzer,
    led: led,
    ultrasonic: ultrasonic,
    bumper: bumper,
    drivetrain: drivetrain,
    motorWW: motorWW,
    threeWireMotorWW: motorWW,
    lineTrackerWW: lineTrackerWW,
    buzzerWW: buzzerWW,
    ledWW: ledWW,
    ultrasonicWW: ultrasonicWW,
    bumperWW: bumperWW,
    drivetrainWW: drivetrainWW
}

const supportTypes = {
    drivetrain: 'drivetrain',
    motor100: 'motor100',
    motor300: 'motor300',
    colorSensor: 'colorSensor',
    touchLed: 'touchLed',
    gyro: 'gyro',
    vision: 'vision',
    speaker: 'speaker',
    motor: 'motor',
    threeWireMotor: 'threeWireMotor',
    lineTracker: 'lineTracker',
    buzzer: 'buzzer',
    led: 'led',
    ultrasonic: 'ultrasonic',
    bumper: 'bumper',
}

const DEVICE_LIST = "EduEntryDeviceList"

class EduEntryDeviceList extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            selectedRadio: null,
            skipedDeviceHint: store.get('skipedDeviceHint'),
            refreshByImport: false,
            refreshByVision: false,
            hoverDevice: ''
        }
    }

    skipHint() {
        if (!this.state.skipedDeviceHint) {
            this.setState({ skipedDeviceHint: true });
            store.set('skipedDeviceHint', true);
        }
    }

    handleClickDevice(deviceType) {
        if (this.isDeviceEnable(deviceType)) {
            this.skipHint();
            if (deviceType == supportTypes.vision) {
                this.openCvDialog()
            } else {
                this.props.openAddPage(this.props.portNumber, deviceType)
            }
            this.props.cleanPortOption()
        }
    }

    openCvDialog() {
        if (!store.get('skipVisionTutorial')) {
            this.props.showVisionTutorialDialog();
        } else {
            this.props.showVisionSettingDialog();
        }
        this.props.setLaunchSettingWay(LAUNCH_FROM.ADD)
        if (!store.get('visionData')) {
            store.set('visionData', {
                [COLOR_DATA_LIST]: DEFAULT_COLOR_DATA_LIST,
                [IS_TAG_INFORMATION_ENABLE]: this.props.isTagInformationEnable,
            })
        }
    }

    handleClickHelp() {
        this.skipHint();
        this.props.openHelpDialog();
    }

    handleHoverDevice(deviceType) {
        if (this.isDeviceEnable(deviceType)) {
            let request = this.props.vm.getRequestPort(deviceType, this.props.brainType);
            let availablePort = this.props.vm.getAvailablePortAmount(this.props.brainType);
            let hintArray = [];
            if (request && request.smart > 0) hintArray = hintArray.concat(availablePort.smart);
            if (request && request.typeC > 0) hintArray = hintArray.concat(availablePort.typeC);
            if (request && request.adc > 0) hintArray = hintArray.concat(availablePort.adc);
            if (request && request.pwm > 0) hintArray = hintArray.concat(availablePort.pwm);
            this.props.hoverDevice(hintArray);
            this.setState({ hoverDevice: deviceType });
        }
    }

    handleHoverOff() {
        if (!this.props.isDeleteDeviceMode) {
            this.props.cleanPortOption();
            this.setState({ hoverDevice: '' });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.portNumber !== prevProps.portNumber || this.props.isDeleteDeviceMode !== prevProps.isDeleteDeviceMode) {
            this.setState({ selectedRadio: null })
        }
        if (prevProps.isCheckingImportDevice !== this.props.isCheckingImportDevice) {
            this.setState({ refreshByImport: this.props.isCheckingImportDevice });
        }
        if (this.props.isVisionSettingDialogShow != prevProps.isVisionSettingDialogShow) {
            this.setState({ refreshByVision: this.props.isVisionSettingDialogShow });
        }
    }

    isDeviceEnable(deviceType) {
        return this.props.vm.isDeviceAvailableToAdd(deviceType, this.props.brainType) && this.props.vm.isPortEnoughForDeviceToAdd(deviceType, this.props.brainType) && !this.props.isDeleteDeviceMode
    }

    getDeviceItem(deviceType) {
        return (
            <DeviceItem
                handleClickDevice={() => this.handleClickDevice(deviceType)}
                handleHoverDevice={() => this.handleHoverDevice(deviceType)}
                handleHoverOff={() => this.handleHoverOff()}
                isDeviceEnable={this.isDeviceEnable(deviceType)}
                isHover={this.state.hoverDevice == deviceType}
                image={this.props.getUIStyle == uiType.ww ? imageTypes[deviceType + 'WW'] : imageTypes[deviceType]}
                type={deviceType}
                deviceList={DEVICE_LIST}
                isChecked={this.state.selectedRadio === deviceType}
                messages={messages}
            />
        )
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        let language = store.get("locale", DEFAULT_LOCALE);
        return (
            <div className={classNames(styles.content)}>
                <div className={classNames(styles.selectDeviceTitle)}>
                    <FormattedMessage
                        {...messages.selectDeviceTitle}
                    />
                    <img className={classNames(styles.help,
                        (this.state.skipedDeviceHint) ? null : styles.breatheHelp)}
                        src={this.props.getUIStyle == uiType.ww ? helpWW : help}
                        onClick={() => { this.handleClickHelp() }}
                        alt={"help icon"} />
                </div>
                <div className={classNames(styles.tableArea)}>
                    <div className={classNames(styles.listTitleArea)} >
                        <div className={classNames(styles.listDivide, language == 'en' ? styles.listDivideEn : null)} />
                        <div className={classNames(styles.listBrainTitile)}>
                            <FormattedMessage
                                {...messages.brainEdu}
                            /></div>
                        <div className={classNames(styles.listDivide, language == 'en' ? styles.listDivideEn : null)} />
                    </div>
                    <table className={classNames(styles.tableComboContent)}>
                        <tbody>
                            <tr>
                                {this.getDeviceItem(supportTypes.threeWireMotor)}
                                {this.getDeviceItem(supportTypes.bumper)}
                                {this.getDeviceItem(supportTypes.lineTracker)}
                                {this.getDeviceItem(supportTypes.ultrasonic)}
                            </tr>
                            <tr>
                                {this.getDeviceItem(supportTypes.buzzer)}
                                {this.getDeviceItem(supportTypes.led)}
                            </tr>
                        </tbody>
                    </table>
                    <div className={classNames(styles.listTitleArea)} >
                        <div className={classNames(styles.listDivide, language == 'en' ? styles.listDivideEn : null)} />
                        <div className={classNames(styles.listBrainTitile)}><FormattedMessage
                            {...messages.brainEntry}
                        /></div>
                        <div className={classNames(styles.listDivide, language == 'en' ? styles.listDivideEn : null)} />
                    </div>
                    <table className={classNames(styles.tableComboContent)}>
                        <tbody>
                            <tr>
                                {this.getDeviceItem(supportTypes.drivetrain)}
                                {this.getDeviceItem(supportTypes.motor100)}
                                {this.getDeviceItem(supportTypes.motor300)}
                                {this.getDeviceItem(supportTypes.touchLed)}
                            </tr>
                            <tr>
                                {this.getDeviceItem(supportTypes.colorSensor)}
                                {this.getDeviceItem(supportTypes.vision)}
                                {this.getDeviceItem(supportTypes.speaker)}
                                {this.getDeviceItem(supportTypes.gyro)}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}


EduEntryDeviceList.propTypes = {
    init: PropTypes.bool,
    show: PropTypes.bool,
    portNumber: PropTypes.string,
    hoverDevice: PropTypes.func,
    cleanPortOption: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
    brainType: PropTypes.string
};

const mapStateToProps = state => ({
    show: isPortOpen(state),
    portNumber: getPortNumber(state),
    isDeleteDeviceMode: isDeleteDeviceMode(state),
    isDeviceHelpDialogShow: isDeviceHelpDialogShow(state),
    getUIStyle: getUIStyle(state),
    isCheckingImportDevice: isCheckingImportDevice(state),
    isVisionSettingDialogShow: isVisionSettingDialogShow(state),
});

const mapDispatchToProps = dispatch => ({
    openAddPage: (portNum, deviceType) => {
        dispatch(openAddPage(portNum, deviceType))
    },
    hoverDevice: (portArray) => { dispatch(hoverDevice(portArray)) },
    cleanPortOption: () => dispatch(cleanPortOption()),
    openHelpDialog: () => dispatch(showDeviceHelpDialog(getPlatform())),
    showVisionTutorialDialog: () => dispatch(showVisionTutorialDialog()),
    showVisionSettingDialog: () => {
        dispatch(hideStreamingDialog())
        dispatch(showVisionSettingDialog())
    },
    showVisionTutorialDialog: () => dispatch(showVisionTutorialDialog()),
    setLaunchSettingWay: (way, name, id) => dispatch(setLaunchSettingWay(way, name, id))

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EduEntryDeviceList);