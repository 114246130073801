import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './selector.css';
import { FormattedMessage } from 'react-intl';

const dropdownHeight = 107;

class Selector extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleListShow',
            'handleChange',
            'handleClickOutside',
            'addListeners',
            'removeListeners',
            'renderList',
            'refSelector'
        ]);

        this.state = {
            value: this.props.defaultValue,
            showList: false,
            dropdown: true
        }
        this.dropdown = null;
    }

    componentDidMount() {
        this.addListeners();
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    addListeners() {
        document.addEventListener('mouseup', this.handleClickOutside);
    }

    removeListeners() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }

    handleListShow() {
        let dropdown = true;
        let selectorTop = this.selector.getBoundingClientRect().top;
        let contentTop = 10000; // dropdown when cannot get bounding
        if (this.props.getEditBlockContent) {
            contentTop = this.props.getEditBlockContent().getBoundingClientRect().top + 2/* margin top */;
        }
        if ((selectorTop - contentTop) > dropdownHeight) {
            dropdown = false;
        }
        this.setState({
            showList: !this.state.showList,
            dropdown: dropdown
        });
    }

    handleChange(item) {
        this.setState({
            value: item
        });
        this.props.onChange(item);
    }

    handleClickOutside(e) {
        if (this.state.showList && !this.selector.contains(e.target)) {
            this.setState({
                showList: false
            });
        }
    }

    renderList() {
        if (this.state.showList) {
            return (
                <ul className={classNames(styles.selectBarContent, !this.state.dropdown ? styles.dropup : null)}>
                    {
                        this.props.list.map((item, index) => (
                            <li key={index} className={styles.selectItem} onClick={() => this.handleChange(item)}>
                                {item}
                            </li>
                        ))
                    }
                </ul>
            )
        }
    }

    refSelector(selector) {
        this.selector = selector;
    }

    render() {
        return (
            <div
                className={classNames(
                    styles.selectBar,
                    this.state.showList ? styles.active : null,
                    this.props.className
                )}
                onClick={this.handleListShow}
                ref={this.refSelector}
            >
                <div className={styles.selectBarText}>
                    <span id="lang-select-bar-text">{this.state.value}</span>
                    <div className={styles.arrow}></div>
                </div>
                {this.renderList()}
            </div>
        );
    }
}

Selector.propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    list: PropTypes.array,
    onChange: PropTypes.func,
    getEditBlockContent: PropTypes.func,
};

export default Selector;