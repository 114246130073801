import React from 'react';
import bindAll from 'lodash.bindall';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {
    projectTitle,
    setProjectTitle
} from '../reducers/project-title';

/* Higher Order Component to get and set the project title
 * @param {React.Component} WrappedComponent component to receive project title related props
 * @returns {React.Component} component with project loading behavior
 */
const TitledHOC = function (WrappedComponent) {
    class TitledComponent extends React.Component {
        constructor (props) {
            super(props);
            bindAll(this, [
                'handleUpdateProjectTitle'
            ]);
        }
        handleUpdateProjectTitle (newTitle) {
            this.props.setProjectTitle(newTitle);
            this.props.vm.setProjectName(newTitle);
        }
        render () {
            return (
                <WrappedComponent
                    canEditTitle
                    projectTitle={this.props.projectTitle}
                    onUpdateProjectTitle={this.handleUpdateProjectTitle}
                    {...this.props}
                />
            );
        }
    }

    TitledComponent.propTypes = {
        projectTitle: PropTypes.string,
        setProjectTitle: PropTypes.func,
        vm: PropTypes.shape({
            setProjectName: PropTypes.func
        })
    };

    const mapStateToProps = state => ({
        projectTitle: projectTitle(state),
        vm: state.scratchGui.vm
    });
    
    const mapDispatchToProps = dispatch => ({
        setProjectTitle: title => dispatch(setProjectTitle(title))
    });

    return connect(mapStateToProps, mapDispatchToProps)(TitledComponent);
};

export {
    TitledHOC as default
};
