import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import store from 'store';
import { DEFAULT_LOCALE } from '../../config/project-config';

import { getPickedBrainType } from '../../reducers/picked-brain-type'

import helpIcon from '../../../common-svg/help-orange.svg'
import closeIcon from './svg/close_btn.svg';

class ExampleDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            // 'addListeners',
            // 'removeListeners',
            'handleClick',
            'ref'
        ]);
    }
    componentDidMount() {
        // if (this.props.show) this.addListeners();
    }
    componentDidUpdate(prevProps) {
        // if (this.props.show && !prevProps.show) this.addListeners();
        // if (!this.props.show && prevProps.show) this.removeListeners();
    }
    componentWillUnmount() {
        // this.removeListeners();
    }
    addListeners() {
        // document.addEventListener('mouseup', this.handleClick);
    }
    removeListeners() {
        // document.removeEventListener('mouseup', this.handleClick);
    }
    handleClick(e) {
        if (this.props.show && !this.dialog.contains(e.target)) {
            this.props.onClose();
        }
    }
    ref(c) {
        this.dialog = c;
    }
    isEnVersion() {
        return store.get("locale", DEFAULT_LOCALE) == "en";
    }
    render() {
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle, styles.example)} ref={this.ref}>
                    <div className={styles.exampleHeader}>
                        <div className={styles.exampleTitle}>
                            {this.props.data.name}
                        </div>
                        <div className={styles.aboutClose} onClick={this.props.onClose}>
                            <img src={closeIcon} />
                        </div>
                    </div>
                    <div className={styles.exampleContent}>
                        <div className={styles.exampleImageFrame}>
                            <img className={styles.exampleImage} src={this.props.data.blockImg} />
                        </div>
                        <div className={styles.exampleDescriptionContent}>
                            <FormattedMessage
                                defaultMessage="Example description"
                                description="example dialog description text"
                                id="gui.dialog.example.description"
                            />
                            <textarea className={styles.exampleDescription} defaultValue={this.props.data.direction} readOnly draggable={false} />
                        </div>
                        {
                            this.props.data.brainType.includes(this.props.getBrainType) ? null :
                                (
                                    <div className={styles.exampleError}>
                                        <img className={styles.exampleErrorImg} src={helpIcon} />
                                        <FormattedMessage
                                            defaultMessage="ExampleError"
                                            description="example dialog error text"
                                            id="gui.dialog.example.error"
                                        />
                                    </div>
                                )
                        }
                    </div>
                    <div className={styles.exampleFooter}>
                        <div className={classNames(styles.questionWhiteBtn, styles.example)} onClick={this.props.onOpenQuestionDialog}>
                            <FormattedMessage
                                defaultMessage="ExampleOpenButton"
                                description="example dialog open button"
                                id="gui.dialog.example.open"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={
                            classNames(
                                styles.questionBtn,
                                styles.example,
                                this.props.data.brainType.includes(this.props.getBrainType) ? null : styles.disable
                            )}
                            style={{ lineHeight: this.isEnVersion() ? "17px" : "34px" }}
                            onClick={this.props.onCopyBlocks}>
                            <FormattedMessage
                                defaultMessage="ExampleCopyBlockButton"
                                description="example dialog copy block button"
                                id="gui.dialog.example.copyBlock"
                            />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

ExampleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onOpenQuestionDialog: PropTypes.func.isRequired,
    onCopyBlocks: PropTypes.func,
    data: PropTypes.object,
    getBrainType: PropTypes.string,
    show: PropTypes.bool
};

const mapStateToProps = state => ({
    getBrainType: getPickedBrainType(state)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExampleDialog);