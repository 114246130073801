const OPEN_DEVICE_LIST = 'scratch-gui/deviceManager/OPEN_DEVICE_LIST';
const CLOSE_DEVICE_LIST = 'scratch-gui/deviceManager/CLOSE_DEVICE_LIST';
const OPEN_EDIT_PAGE = 'scratch-gui/deviceManager/OPEN_EDIT_PAGE';
const CLOSE_EDIT_PAGE = 'scratch-gui/deviceManager/CLOSE_EDIT_PAGE';
const OPEN_ADD_PAGE = 'scratch-gui/deviceManager/OPEN_ADD_PAGE';
const CLOSE_ADD_PAGE = 'scratch-gui/deviceManager/CLOSE_ADD_PAGE';

const IS_DEVICE_LIST_OPEN = 'isDeviceListOpen';
const IS_EDIT_PAGE_OPEN = 'isEditPageOpen';
const IS_ADD_PAGE_OPEN = 'isAddPageOpen';
const PORT_NUMBER = 'portNumber';
const PORT_NAME = 'portName';
const PORT_DEVICE = 'portDevice';
const PORT_DEVICE_ID = 'portDeviceId';
const PORT_ARRAY = 'portArray';
const IS_DEVICE_MANAGER_VISIBLE = 'isDeviceManagerVisible';

const DEVICE_MANAGER_VISIBLE = 'scratch-gui/device/DEVICE_MANAGER_PAGE_VISIBLE';

const SET_ENTRY_REAL_DEVICE_LIST = 'scratch-gui/deviceManager/SET_ENTRY_REAL_DEVICE_LIST';
const ENTRY_REAL_DEVICE_LIST = 'entryDeviceList';
const IS_CHECKING_IMPORT_DEVCICE = 'isCheckingImportDevice';

const initialState = {
    [PORT_NUMBER]: "A",
    [IS_DEVICE_LIST_OPEN]: true,
    [IS_EDIT_PAGE_OPEN]: false,
    [PORT_DEVICE_ID]: "",
    [PORT_DEVICE]: "",
    [IS_ADD_PAGE_OPEN]: false,
    [ENTRY_REAL_DEVICE_LIST]: null,
    [IS_CHECKING_IMPORT_DEVCICE]: false,
    [PORT_ARRAY]: [],
    [IS_DEVICE_MANAGER_VISIBLE]: true
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case OPEN_DEVICE_LIST:
            return Object.assign({}, state, {
                [PORT_NUMBER]: action.number,
                [IS_DEVICE_LIST_OPEN]: true,
                [IS_ADD_PAGE_OPEN]: false,
                [IS_EDIT_PAGE_OPEN]: false
            })
        case CLOSE_DEVICE_LIST:
            return Object.assign({}, state, {
                [PORT_NUMBER]: action.number,
                [IS_DEVICE_LIST_OPEN]: false,
                [IS_ADD_PAGE_OPEN]: false,
                [IS_EDIT_PAGE_OPEN]: false
            })
        case OPEN_EDIT_PAGE:
            return Object.assign({}, state, {
                [PORT_NUMBER]: action.number,
                [PORT_NAME]: action.name ? action.name : action.deviceType + action.number,
                [PORT_DEVICE]: action.deviceType,
                [PORT_DEVICE_ID]: action.id,
                [IS_ADD_PAGE_OPEN]: false,
                [IS_DEVICE_LIST_OPEN]: false,
                [IS_EDIT_PAGE_OPEN]: true
            })
        case CLOSE_EDIT_PAGE:
            return Object.assign({}, state, {
                [PORT_NUMBER]: action.number,
                [PORT_NAME]: action.name ? action.name : action.deviceType + action.number,
                [PORT_DEVICE]: action.deviceType,
                [PORT_DEVICE_ID]: action.id,
                [IS_ADD_PAGE_OPEN]: false,
                [IS_DEVICE_LIST_OPEN]: false,
                [IS_EDIT_PAGE_OPEN]: false
            })
        case OPEN_ADD_PAGE:
            return Object.assign({}, state, {
                [PORT_NUMBER]: action.number,
                [PORT_DEVICE]: action.deviceType,
                [IS_ADD_PAGE_OPEN]: true,
                [IS_DEVICE_LIST_OPEN]: false,
                [IS_EDIT_PAGE_OPEN]: false
            })
        case CLOSE_ADD_PAGE:
            return Object.assign({}, state, {
                [PORT_NUMBER]: action.number,
                [PORT_DEVICE]: action.deviceType,
                [IS_ADD_PAGE_OPEN]: false,
                [IS_DEVICE_LIST_OPEN]: false,
                [IS_EDIT_PAGE_OPEN]: false
            });
        case IS_CHECKING_IMPORT_DEVCICE:
            return Object.assign({}, state, {
                [IS_CHECKING_IMPORT_DEVCICE]: action.enable
            });

        case SET_ENTRY_REAL_DEVICE_LIST:
            return Object.assign({}, state, {
                [ENTRY_REAL_DEVICE_LIST]: action.list
            });
        case PORT_ARRAY:
            return Object.assign({}, state, {
                [PORT_ARRAY]: action.portArray
            });
        case DEVICE_MANAGER_VISIBLE:
            return Object.assign({}, state, {
                [IS_DEVICE_MANAGER_VISIBLE]: action.isDeviceManagerVisible
            });
        default:
            return state;
    }
};
const openDeviceList = (portNum) => ({
    number: portNum,
    type: OPEN_DEVICE_LIST,
});
const closeDeviceList = (portNum) => {
    return {
        number: portNum,
        type: CLOSE_DEVICE_LIST,
    }
};

const openEditPage = (portNum, name, deviceType, id) => ({
    number: portNum,
    name: name,
    deviceType: deviceType,
    id: id,
    type: OPEN_EDIT_PAGE
});

const closeEditPage = () => ({
    number: "",
    name: "",
    deviceType: "",
    id: "",
    type: CLOSE_EDIT_PAGE,
});

const openAddPage = (portNum, deviceType) => ({
    number: portNum,
    deviceType: deviceType,
    type: OPEN_ADD_PAGE,
});

const closeAddPage = (portNum, deviceType) => ({
    number: portNum,
    deviceType: deviceType,
    type: CLOSE_ADD_PAGE,
});

const setPortArray = (portArray) => ({
    portArray: portArray,
    type: PORT_ARRAY,
});

const getPortArray = state => state.scratchGui.deviceManager[PORT_ARRAY];

const isPortOpen = state => state.scratchGui.deviceManager[IS_DEVICE_LIST_OPEN];
const isAddPageOpen = state => state.scratchGui.deviceManager[IS_ADD_PAGE_OPEN];
const isEditPageOpen = state => state.scratchGui.deviceManager[IS_EDIT_PAGE_OPEN];
const getPortNumber = state => state.scratchGui.deviceManager[PORT_NUMBER];
const getPortName = state => state.scratchGui.deviceManager[PORT_NAME];
const getPortDevice = state => state.scratchGui.deviceManager[PORT_DEVICE];
const getPortDeviceId = state => state.scratchGui.deviceManager[PORT_DEVICE_ID];

const setRealEntryDeviceList = (deviceList) => {
    return {
        type: SET_ENTRY_REAL_DEVICE_LIST,
        list: deviceList,
    }
}

const clearRealEntryDeviceList = () => {
    return {
        type: SET_ENTRY_REAL_DEVICE_LIST,
        list: null,
    }
}

const getRealEntryDeviceList = state => {
    return state.scratchGui.deviceManager[ENTRY_REAL_DEVICE_LIST];
}
const enableCheckingImportDevice = (status) => {
    return {
        type: IS_CHECKING_IMPORT_DEVCICE,
        enable: status
    }
}
const isCheckingImportDevice = state => {
    return state.scratchGui.deviceManager[IS_CHECKING_IMPORT_DEVCICE];
}

const setDeviceManagerVisible = function (visible) {
    return {
        type: DEVICE_MANAGER_VISIBLE,
        isDeviceManagerVisible: visible
    };
};

const getDeviceManagerVisible = state => {
    return state.scratchGui.deviceManager[IS_DEVICE_MANAGER_VISIBLE];
};

export {
    reducer as default,
    initialState as deviceManagerInitialState,
    openDeviceList,
    closeDeviceList,
    isPortOpen,
    getPortNumber,

    openEditPage,
    closeEditPage,
    isEditPageOpen,
    getPortName,
    getPortDevice,
    getPortDeviceId,

    openAddPage,
    closeAddPage,
    isAddPageOpen,

    setRealEntryDeviceList,
    getRealEntryDeviceList,
    clearRealEntryDeviceList,

    enableCheckingImportDevice,
    isCheckingImportDevice,

    setPortArray,
    getPortArray,

    setDeviceManagerVisible,
    getDeviceManagerVisible
};
