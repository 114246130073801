import { EDIT_MODE } from './edit-mode.js';
import { BRAIN_TYPE } from './brains.js';

export default (filename, blob, brainType, editMode) => {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    let extension = '';
    if (brainType == BRAIN_TYPE.EDU) {
        extension = 'abot';
    } else if (brainType == BRAIN_TYPE.WEB_VR) {
        extension = 'vr';
    } else {
        if (brainType == BRAIN_TYPE.ENTRY) {
            extension = 'bbot';
        }
        if (brainType == BRAIN_TYPE.EDU_AND_ENTRY) {
            extension = 'cbot';
        }
    }

    // Use special ms version if available to get it working on Edge.
    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename + "." + extension);
        return;
    }

    if ('download' in HTMLAnchorElement.prototype) {
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = filename + "." + extension;
        downloadLink.type = blob.type;
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
    } else {
        // iOS Safari, open a new page and set href to data-uri
        let popup = window.open('', '_blank');
        const reader = new FileReader();
        reader.onloadend = function () {
            popup.location.href = reader.result;
            popup = null;
        };
        reader.readAsDataURL(blob);
    }
};
