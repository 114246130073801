import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './brain-info.css';
import { connect } from 'react-redux';
import { BRAIN_TYPE } from '../../lib/brains'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import {
    showFirmwarmUpdateDialog,
    hideFirmwarmUpdateDialog,
    showErrorDialog, errorType,
    showQuestionDialog,
    questionType,
    showWifiSettingDialog
} from '../../reducers/dialog';
import MenuBarMenu from './menu-bar-menu.jsx';
import { MenuItem, MenuDisableItem } from '../menu/menu.jsx';
import {
    openBrainInfoList,
    closeBrainInfoList,
    brainInfoListOpen
} from '../../reducers/menus';
import {
    BRAIN_STATUS_TYPE,
    getBrainStatus,
    getBrainModelName,
    getApBrainList
} from '../../reducers/brain';
import store from 'store';
import { getPickedBrainType } from '../../reducers/picked-brain-type'
import {
    cleanTask
} from '../../reducers/task';

import {
    getUIStyle,
    uiType
} from '../../reducers/ui-style';
import classNames from 'classnames';
import arrow from './svg/arrow_ dialogue.svg';
import {
    getConnectedWifi,
    getWifiStatus,
    wifiStatus,
    setWifiList
} from '../../reducers/wifi-list'
import log from '../../lib/log.js';
import loadingImg from './svg/loading.svg';
import { EditUtils } from '../device-manager/edit-page/edit-utils.js'
import {
    showWifiDirectionDialog,
    showWifiiPadSettingDialog,
    showWifiIpSettingDialog
} from '../../reducers/dialog';
import { DEFAULT_LOCALE } from '../../config/project-config';
import moreImg from './svg/more_circle.svg';
import moreHoverCnImg from './svg/more_circle_hover_cn.svg';
import moreHoverWwImg from './svg/more_circle_hover_ww.svg';
import exclamationIcon from './svg/exclamation_mark.svg';

const messages = defineMessages({
    firmwareTitle: {
        defaultMessage: 'Firmware Version',
        description: 'Firmware Version',
        id: 'gui.firmware.brain.firmwareTitle'
    },
    updateTitle: {
        defaultMessage: 'Updated Status',
        description: 'Updated Status',
        id: 'gui.firmware.brain.updateTitle'
    },
    modelTitle: {
        defaultMessage: 'Model Name',
        description: 'Model Name',
        id: 'gui.firmware.brain.modelTitle'
    },
    isModelDifferent: {
        defaultMessage: "Model doesn't match",
        description: "Model doesn't match",
        id: 'gui.firmware.brain.isModelDifferent'
    },
    upToDate: {
        defaultMessage: 'Already up to date.',
        description: 'Already up to date.',
        id: 'gui.firmware.brain.upToDate'
    },
    updateNow: {
        defaultMessage: 'Update Now',
        description: 'Update Now',
        id: 'gui.firmware.brain.updateNow'
    },
    restore: {
        defaultMessage: 'Restore original',
        description: 'Restore original',
        id: 'gui.firmware.brain.restore'
    },
    brainName: {
        defaultMessage: 'Brain name',
        description: 'Brain name',
        id: 'gui.firmware.brain.brainName'
    },
    disable: {
        defaultMessage: 'Disable',
        id: 'gui.dialog.wifi.status.disable'
    },
    noSetting: {
        defaultMessage: 'No Setting',
        id: 'gui.dialog.wifi.status.noSetting'
    },
    scanning: {
        defaultMessage: 'Scanning',
        id: 'gui.dialog.wifi.status.scanning'
    },
    connecting: {
        defaultMessage: 'Connecting',
        id: 'gui.dialog.wifi.status.connecting'
    },
    completed: {
        defaultMessage: 'Completed',
        id: 'gui.dialog.wifi.status.completed'
    },
    authFail: {
        defaultMessage: 'Authentication failed',
        id: 'gui.dialog.wifi.status.authFail'
    },
    linkFail: {
        defaultMessage: 'Link Fail',
        id: 'gui.dialog.wifi.status.linkFail'
    }
})

const showDropdownLimit = 1
const brainSNDisplayNum = 7;
const EMPTY_DATA = "N/A"
const INIT_STATE = {
    isConnectUsb: false,
    isMorePopup: false,
    isHoverMore: false,
    isWifiConnectingBrain: -1,
    isWifiConnectingFailed: -1
}
var timeoutIdConnectingFailed = null
const CONNECTING_TIMEOUT = 30 * 1000
class BrainInfo extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'addListeners',
            'removeListeners',
            'handleClick',
            'ref'
        ]);
        this.state = INIT_STATE
    }

    componentDidMount() {
        if (this.props.show) this.addListeners();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show != this.props.show) {
            if (this.props.show == true) {
                this.setState(INIT_STATE);
                this.addListeners();
                this.updateIsConnectUsb();
                this.clearConnectingFailedTimeoutId();
                this.setState({
                    isWifiConnectingBrain: -1,
                    isWifiConnectingFailed: -1
                });
                if (this.props.startListenApBroadcast) this.props.startListenApBroadcast();
            } else {
                this.removeListeners();
                if (this.props.stopListenApBroadcast) this.props.stopListenApBroadcast();
            }
        }
        if (prevProps.brainInfo != this.props.brainInfo) {
            this.clearConnectingFailedTimeoutId();
            this.updateIsConnectUsb();
            this.setState({
                isWifiConnectingBrain: -1,
                isWifiConnectingFailed: -1
            })
            this.props.close();
        }
    }

    clearConnectingFailedTimeoutId() {
        if (timeoutIdConnectingFailed) {
            clearTimeout(timeoutIdConnectingFailed)
        }
        timeoutIdConnectingFailed = null;
    }

    updateIsConnectUsb() {
        log.info(`updateIsConnectUsb ${this.state.isConnectUsb} to ${this.props.brainInfo && this.props.brainInfo.isSocket != undefined && this.props.brainInfo.isSocket == false}`)
        this.setState({ isConnectUsb: this.props.brainInfo && this.props.brainInfo.isSocket != undefined && this.props.brainInfo.isSocket == false })

    }

    componentWillUnmount() {
        this.removeListeners();
    }

    onClickWifiSetting() {
        log.info('onClickWifiSetting');
        if (this.props.brainInfo.brain_type &&
            (this.props.brainInfo.brain_type == BRAIN_TYPE.ENTRY ||
                this.props.brainInfo.brain_type == BRAIN_TYPE.EDU_AND_ENTRY)) {
            log.info('wifiStatus is: ', this.props.wifiStatus);
            this.props.setWifiList([]);

            if (this.props.wifiStatus.state == wifiStatus.DISABLED) {
                log.info('enable wifi');
                if (this.props.onClickSwitchWifi) this.props.onClickSwitchWifi(true);
            } else {
                if (this.props.onClickWifiScan) this.props.onClickWifiScan();
            }
            this.props.showWifiSettingDialog();
        }
    }

    filterBrainVer = (brainVer) => (brainVer.includes('MB')) ? brainVer.split("-")[0].split("_").filter(e => e.includes("MB"))[0] : EMPTY_DATA

    isBrainTypeSame() {
        let brainType = this.props.brainInfo.brain_type ? this.props.brainInfo.brain_type.toUpperCase() : EMPTY_DATA;
        let pickBrainType = this.props.pickBrainType ? this.props.pickBrainType.toUpperCase() : EMPTY_DATA;
        return brainType == pickBrainType || brainType == EMPTY_DATA;
    }

    getWifiStatusString() {
        switch (this.props.wifiStatus.state) {
            case wifiStatus.DISABLED:
                return <FormattedMessage {...messages.disable} />
            case wifiStatus.NO_SETTING:
                return <FormattedMessage {...messages.noSetting} />
            case wifiStatus.SCANNING:
                return <FormattedMessage {...messages.scanning} />
            case wifiStatus.CONNECTING:
                return <FormattedMessage {...messages.connecting} />
            case wifiStatus.COMPLETED:
                return this.props.wifiConnectedStatus;
            case wifiStatus.AUTH_FAIL:
                return <FormattedMessage {...messages.authFail} />
            case wifiStatus.LINK_FAIL:
                return <FormattedMessage {...messages.linkFail} />
            default:
                return EMPTY_DATA
        }
    }

    addListeners() {
        document.addEventListener('mouseup', this.handleClick);
    }

    removeListeners() {
        document.removeEventListener('mouseup', this.handleClick);
    }

    handleClick(e) {
        if (this.props.show && !this.brainInfo.contains(e.target)) {
            this.props.onRequestCloseBrainInfo();
            this.props.close();
        }
    }

    onClickApBrain(index) {
        log.info('onClickApBrain index ', index)
        if (this.state.isConnectUsb) return
        let apBrainList = this.props.getApBrainList;
        this.clearConnectingFailedTimeoutId();
        if (apBrainList[index]) {
            log.info('onClickApBrain connect to ', apBrainList[index])
            this.props.connectApPort(apBrainList[index].path)
            this.setState({ isWifiConnectingBrain: apBrainList[index].path, isWifiConnectingFailed: -1 })
            timeoutIdConnectingFailed = setTimeout(() => { this.setState({ isWifiConnectingFailed: apBrainList[index].path, isWifiConnectingBrain: -1 }) }, CONNECTING_TIMEOUT)
        }
    }

    onClickInterrupt() {
        if (!this.props.brainInfo) return;
        if (this.props.disconnectPort) this.props.disconnectPort(this.props.brainInfo.path)
    }

    onClickBrainMore() {
        this.setState({ isMorePopup: !this.state.isMorePopup })
    }

    onHoverMore(hover) {
        this.setState({ isHoverMore: hover })
    }

    onClickReset() {
        console.log('onClickReset')
        this.props.showQuestionResetBrainFirmwareDialog();
        this.props.onRequestCloseBrainInfo();
        this.props.close();
    }

    isEnVersion() {
        return store.get("locale", DEFAULT_LOCALE) == "en";
    }

    getApBrainListOnSameWifi() {
        let connectedBrain = this.props.brainInfo;
        let apBrainList = [];
        let isConnectingWithWiFi = false;
        if (connectedBrain && connectedBrain.isSocket == true) {
            apBrainList.push({ ssn: connectedBrain.ssn, path: connectedBrain.path })
            isConnectingWithWiFi = true;
        }
        let getApBrainList = this.props.getApBrainList
        if (Array.isArray(getApBrainList) && getApBrainList.length > 0) {
            getApBrainList.forEach(data => apBrainList.push(data));
        }
        apBrainList = this.filterApBrainList(apBrainList)
        let brainInfo = this.props.brainInfo;
        return apBrainList.length > 0 ? <div>
            <div className={classNames(styles.wifiApListArea, this.state.isConnectUsb ? styles.disable : null)}>
                {apBrainList.map((ap, index) => {
                    const isConnected = ap.path == brainInfo.path
                    const isConnecting = ap.path == this.state.isWifiConnectingBrain
                    const isConnectingFailed = ap.path == this.state.isWifiConnectingFailed
                    return <div
                        key={ap.ssn + index}
                        className={classNames(styles.apItemArea)}
                        onClick={() => isConnected ? {} : this.onClickApBrain(isConnectingWithWiFi ? (index - 1) : index)}>
                        <div className={classNames(styles.apBrainName)}>
                            {ap.ssn.length >= brainSNDisplayNum ?
                                ap.ssn.substr(ap.ssn.length - brainSNDisplayNum, brainSNDisplayNum) :
                                ap.ssn
                            }
                            {isConnected || isConnecting || isConnectingFailed ? "(" : null}
                            {isConnected ? <FormattedMessage id={"gui.dialog.wifi.connected"} />
                                : null}
                            {isConnecting ? <FormattedMessage id={"gui.dialog.wifi.connecting"} />
                                : null}
                            {isConnectingFailed ? <FormattedMessage id={"gui.dialog.wifi.status.linkFail"} />
                                : null}
                            {isConnected || isConnecting || isConnectingFailed ? ")" : null}
                        </div>
                        {isConnected ?
                            <div
                                className={classNames(styles.apInterrupt)}
                                onClick={() => this.onClickInterrupt()}>
                                <FormattedMessage id={"gui.dialog.wifi.interrupt"} />
                            </div>
                            : <div className={classNames(styles.apBrainStatus)}>
                                <FormattedMessage id={"gui.dialog.wifi.disconnected"} />
                            </div>
                        }
                    </div>
                })}
            </div>

            <div className={classNames(styles.centerLine)} /></div> : null

    }

    filterApBrainList(list) {
        let result = [];
        if (Array.isArray(list)) {
            let apMap = new Map();
            list.forEach(ap => {
                if (!apMap.has(ap.path)) {
                    apMap.set(ap.path, ap)
                }
            })
            if (apMap.size > 0) {
                for (let [key, value] of apMap) {
                    result.push(value)
                }
            }
        }
        return result
    }

    ref(c) {
        this.brainInfo = c;
    }



    render() {
        if (!this.props.show) {
            return null;
        }
        var selectedIndex = this.props.allPortInfo ? this.props.allPortInfo.findIndex(portInfo => this.props.brainInfo.path == portInfo.path) : -1;
        var brainInfo = this.props.brainInfo.ssn ? this.props.brainInfo : {
            ssn: EMPTY_DATA,
            brain_type: EMPTY_DATA
        }
        const isBrainConnected = brainInfo.ssn != EMPTY_DATA;
        const isConnectUsb = this.state.isConnectUsb;
        const isConnectedEntry = (brainInfo.brain_type == BRAIN_TYPE.ENTRY || brainInfo.brain_type == BRAIN_TYPE.EDU_AND_ENTRY) && isConnectUsb;
        const isPickedEntryProject = this.props.pickBrainType == BRAIN_TYPE.ENTRY || this.props.pickBrainType == BRAIN_TYPE.EDU_AND_ENTRY;
        return (
            <div className={classNames(styles.brainInfo)} ref={this.ref} >
                <span className={classNames(styles.arrow_t_int)}></span>
                <span className={classNames(styles.arrow_t_out)}></span>
                <div className={classNames(styles.brainArea)}>
                    <div className={classNames(styles.brainInfoTextArea)}>
                        <div className={classNames(styles.brainTitle)}><FormattedMessage {...messages.brainName} /></div>
                        <div className={classNames(styles.brainNameSelect)} onMouseUp={() => {
                            this.setState({ isMorePopup: false })
                            this.props.onClickBrainInfo()
                        }}>
                            <div className={classNames(styles.brainName)}>
                                {(brainInfo.ssn.length >= brainSNDisplayNum) ?
                                    brainInfo.ssn.substr(brainInfo.ssn.length - brainSNDisplayNum, brainSNDisplayNum) :
                                    brainInfo.ssn}
                            </div>
                            {this.props.allPortInfo.length > showDropdownLimit && this.props.isEnableDownloadAndRunButton ?
                                <img className={classNames(styles.brainInfoArrow)} src={arrow} />
                                : null}
                            {this.props.allPortInfo.length > showDropdownLimit && this.props.isEnableDownloadAndRunButton ?
                                <MenuBarMenu
                                    className={classNames(styles.brainInfoMenu)}
                                    open={this.props.brainInfoListOpen}
                                    place={'center'}
                                    onRequestClose={this.props.onRequestCloseBrainInfo}
                                    innerMenuclassName={classNames(styles.menu)}
                                >
                                    {this.props.allPortInfo ?
                                        this.props.allPortInfo
                                            .map((portInfo, i) => (
                                                <MenuItem
                                                    key={i}
                                                    className={i != selectedIndex ? styles.menuItem : styles.menuItemHighlight}
                                                    isRtl={this.props.isRtl}
                                                    onClick={i != selectedIndex ? () => {
                                                        this.props.onRequestCloseBrainInfo();
                                                        this.props.handleConnectComPortPath(portInfo.path);
                                                    } :
                                                        () => {
                                                            this.props.onRequestCloseBrainInfo();
                                                        }}
                                                >
                                                    <div class={classNames(
                                                        i == 0 ? styles.rectangleItem1 : null,
                                                        i == 1 ? styles.rectangleItem2 : null,
                                                        i == 2 ? styles.rectangleItem3 : null,
                                                        i == 3 ? styles.rectangleItem4 : null,
                                                        i == 4 ? styles.rectangleItem5 : null
                                                    )} />
                                                    <div class={styles.text}>{
                                                        i != selectedIndex ?
                                                            portInfo.productId :
                                                            (brainInfo.ssn.length >= brainSNDisplayNum) ?
                                                                portInfo.productId + "-" + brainInfo.ssn.substr(brainInfo.ssn.length - brainSNDisplayNum, brainSNDisplayNum) :
                                                                portInfo.productId + "-" + brainInfo.ssn
                                                    }</div>
                                                </MenuItem>
                                            ))
                                        : null}
                                </MenuBarMenu> : null}
                        </div>
                        <div className={classNames(styles.more, !isBrainConnected ? styles.disable : null)}
                            onClick={() => !isBrainConnected ? {} : this.onClickBrainMore()}
                            onMouseOver={() => !isBrainConnected ? {} : this.onHoverMore(true)}
                            onMouseOut={() => !isBrainConnected ? {} : this.onHoverMore(false)}
                        >
                            {this.state.isMorePopup || this.state.isHoverMore ? <img src={this.props.getUIStyle == uiType.ww ? moreHoverWwImg : moreHoverCnImg} /> : <img src={moreImg} />}
                        </div>
                        {this.state.isMorePopup ?
                            <div className={classNames(styles.restoreOption)}
                                onClick={() => this.onClickReset()}><FormattedMessage {...messages.restore} /></div>
                            : null}
                    </div>
                </div>
                <div className={classNames(styles.centerLine)}></div>
                <div className={classNames(styles.brainInfoItemArea)}>
                    <div className={classNames(styles.brainInfoTextArea)}>
                        <div className={classNames(styles.title, styles.titleModel)}><FormattedMessage {...messages.modelTitle} /></div>

                        <div className={this.isBrainTypeSame() ? classNames(styles.content, styles.contentBrainTypeSame) : classNames(styles.content)}>
                            {this.props.brainModelName || EMPTY_DATA}
                        </div>

                        {this.isBrainTypeSame() ? null :
                            <div className={classNames(styles.hintArea)}>
                                <img src={exclamationIcon} className={classNames(styles.hint)} />
                                <div className={classNames(styles.hintBrainTypeDifferent)}><FormattedMessage {...messages.isModelDifferent} /></div>
                            </div>
                        }
                    </div>
                </div>
                <div className={classNames(styles.centerLine)} />
                <div className={classNames(styles.brainInfoItemArea)}>
                    <div className={classNames(styles.brainInfoTextArea)}>
                        <div className={classNames(styles.title)}><FormattedMessage {...messages.firmwareTitle} /></div>
                        <div className={classNames(styles.content)}>
                            {this.filterBrainVer(this.props.firmwareVer.brain)}
                        </div>
                    </div>
                </div>
                <div className={classNames(styles.centerLine)} />
                <div>
                    <div className={classNames(styles.brainInfoItemArea)}>
                        <div className={classNames(styles.brainInfoTextArea)}>
                            <div className={classNames(styles.title)}>
                                <FormattedMessage
                                    defaultMessage="Wifi Setting"
                                    description="Wifi Setting dialog title"
                                    id="gui.dialog.wifi.title"
                                /></div>
                            <div className={classNames(styles.content, styles.wifiNameText)}>
                                {this.getWifiStatusString()}
                            </div>

                        </div>
                        <div className={classNames(styles.wifiSettingButton,
                            isConnectedEntry ? null : styles.disable)} onClick={() => { isConnectedEntry ? this.onClickWifiSetting() : null }}>
                            <FormattedMessage
                                defaultMessage="Setting"
                                description="setting"
                                id="gui.menuBar.settings"
                            /></div>
                    </div>
                </div>
                {isPickedEntryProject ? <div><div className={classNames(styles.centerLine)} />
                    <div className={classNames(styles.brainInfoItemArea)}>
                        <div className={classNames(styles.brainInfoTextArea)}>
                            <div className={classNames(styles.title)}>
                                <FormattedMessage id={"gui.firmware.brain.ap.title"} />
                            </div>
                            <div className={classNames(styles.content)}
                                style={this.isEnVersion() ? {
                                    maxWidth: "122px",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-word",
                                    marginTop: "10px",
                                    height: "31px"
                                } : {}}>
                                <FormattedMessage id={"gui.firmware.brain.ap.text"} />
                            </div>
                            {isConnectUsb ? null :
                                <div className={classNames(styles.loadingArea)} >
                                    <img src={loadingImg} className={classNames(styles.loading)} />
                                </div>}
                        </div>
                        {isConnectUsb ?
                            <div className={classNames(styles.ipReminderArea)}
                                style={this.isEnVersion() ? {
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-word",
                                    margin: "5px",
                                    height: "34px"
                                } : {}}>
                                <img src={exclamationIcon} className={classNames(styles.hint)}
                                    style={this.isEnVersion() ? {
                                        display: "initial",
                                        float: "left",
                                    } : {}} />
                                <div className={classNames(styles.hintBrainTypeDifferent)}
                                    style={this.isEnVersion() ? {
                                        display: "initial"
                                    } : {}}>
                                    <FormattedMessage id={"gui.firmware.brain.ap.reminder"} />
                                </div>
                            </div>
                            : null}

                    </div>
                    <div className={classNames(styles.centerLine)} />

                    {this.getApBrainListOnSameWifi()}

                    <div className={classNames(styles.brainInfoItemArea)}>
                        <div className={classNames(styles.settingButton, isConnectUsb ? styles.disable : null)}
                            style={(EditUtils.getTextWidth(EditUtils.getLocaleString("gui.firmware.brain.ap.setting"), 14) > 90) ? { whiteSpace: "pre-wrap", padding: "0 5px", lineHeight: "14px" } : {}}
                            onClick={isConnectUsb ? () => { } : this.props.showWifiiPadSettingDialog}>
                            <FormattedMessage id={"gui.firmware.brain.ap.setting"} />
                        </div>
                        <div className={classNames(styles.middleLine)} />
                        <div className={classNames(styles.settingButton, isConnectUsb ? styles.disable : null)}
                            style={(EditUtils.getTextWidth(EditUtils.getLocaleString("gui.firmware.brain.ap.ip"), 14) > 90) ? { whiteSpace: "pre-wrap", padding: "0 5px", lineHeight: "14px" } : {}}
                            onClick={isConnectUsb ? () => { } : this.props.showWifiIpSettingDialog}>
                            <FormattedMessage id={"gui.firmware.brain.ap.ip"} />
                        </div>

                    </div>
                    <div className={classNames(styles.centerLine)} />
                    <div className={classNames(styles.brainInfoItemArea)}>
                        {/* for test */}
                        <div className={classNames(styles.notFoundBrain)} onClick={this.props.showWifiDirectionDialog}>
                            <FormattedMessage id={"gui.firmware.brain.ap.not.found"} />
                        </div>
                    </div>
                </div> : null
                }
            </div>
        );
    }
}

BrainInfo.propTypes = {
    show: PropTypes.bool.isRequired,
    firmwareVer: PropTypes.object,
    brainInfo: PropTypes.object,
    allPortInfo: PropTypes.arrayOf(PropTypes.shape({
        locationId: PropTypes.string,
        manufacturer: PropTypes.string,
        path: PropTypes.string,
        pnpId: PropTypes.string,
        productId: PropTypes.string,
        serialNumber: PropTypes.string,
        vendorId: PropTypes.string
    })),
    onClickSwitchWifi: PropTypes.func,
    onClickWifiScan: PropTypes.func,
    close: PropTypes.func,
    updateFirmware: PropTypes.func,
    limitVer: PropTypes.object,
    onClickBrainInfo: PropTypes.func,
    onRequestCloseBrainInfo: PropTypes.func,
    brainInfoListOpen: PropTypes.bool,
    handleConnectComPortPath: PropTypes.func,
};

const mapStateToProps = state => ({
    brainInfoListOpen: brainInfoListOpen(state),
    pickBrainType: getPickedBrainType(state),
    brainStatus: getBrainStatus(state),
    brainModelName: getBrainModelName(state),
    wifiConnectedStatus: getConnectedWifi(state),
    wifiStatus: getWifiStatus(state),
    getApBrainList: getApBrainList(state),
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
    onShowBrainStatusModeErrorDialog: (msg) => dispatch(showErrorDialog(errorType.BRAIN_STATUS_MODE_ERROR, msg)),
    onClickFirmwareUpdate: () => dispatch(showFirmwarmUpdateDialog()),
    closeFirmwareUpdate: () => dispatch(hideFirmwarmUpdateDialog()),
    showErrorDialog: (errorType, msg) => dispatch(showErrorDialog(errorType, msg)),
    showQuestionResetBrainFirmwareDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_RESET_BRAIN_FIRMWARE)),
    onClickBrainInfo: () => dispatch(openBrainInfoList()),
    onRequestCloseBrainInfo: () => dispatch(closeBrainInfoList()),
    cleanTask: () => dispatch(cleanTask()),
    showWifiSettingDialog: () => dispatch(showWifiSettingDialog()),
    setWifiList: (list) => dispatch(setWifiList(list)),
    showWifiDirectionDialog: () => dispatch(showWifiDirectionDialog()),
    showWifiiPadSettingDialog: () => dispatch(showWifiiPadSettingDialog()),
    showWifiIpSettingDialog: () => dispatch(showWifiIpSettingDialog()),
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BrainInfo);