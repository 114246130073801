import PropTypes from 'prop-types';
import React from 'react';
import SubMenu from '../../containers/submenu.jsx';

const MenuSubMenu = ({
    children,
    place = 'right'
}) => (
    <SubMenu
        place={place}
    >
        {children}
    </SubMenu>
);

MenuSubMenu.propTypes = {
    children: PropTypes.node,
    place: PropTypes.oneOf(['left', 'right'])
};

export default MenuSubMenu;
