import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import {
    questionType,
    getQuestionDeleteSlotIndex
} from '../../reducers/dialog';

import {
    projectTitleChanged,
    projectTitleOverflow
} from '../../reducers/project-title-changed';

import {
    projectIsNew,
    projectTitle
} from '../../reducers/project-title';

import {
    hideQuestionDialog
} from '../../reducers/dialog';

import {
    getDirectNew
} from '../../reducers/project-state';

import SBFileUploader from '../../containers/sb-file-uploader.jsx';

import { validFilename } from '../../lib/validFilename';
import { EDIT_MODE } from '../../lib/edit-mode';

class QuestionDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'addListeners',
            'removeListeners',
            'handleClick',
            'renderContentType',
            'renderButtonType',
            'onUpdateAndClose',
            'onDownloadAndClose',
            'onNoSave',
            'onClose',
            'onDelete',
            'ref',
            'onReplace'
        ]);
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
    }
    componentWillUnmount() {
    }
    addListeners() {
        document.addEventListener('mouseup', this.handleClick);
    }
    removeListeners() {
        document.removeEventListener('mouseup', this.handleClick);
    }
    handleClick(e) {
        if (this.props.show && !this.dialog.contains(e.target)) {
            this.onClose();
        }
    }

    renderContentType() {
        switch (this.props.type) {
            case questionType.QUESTION_DOWNLOAD_FAIL:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionDownloadFail"
                        description="question dialog download fail"
                        id="gui.dialog.question.downloadFail"
                    />
                );
            case questionType.QUESTION_INSTALL_FAIL:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionInstallFail"
                        description="question dialog install fail"
                        id="gui.dialog.question.installFail"
                    />
                );
            case questionType.QUESTION_OPEN_NEW:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionOpen"
                        description="question dialog open"
                        id="gui.dialog.question.isOpen"
                    />
                );
            case questionType.QUESTION_SAVE_NEW_WEB:
            case questionType.QUESTION_SAVE_OPEN_WEB:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionOpen"
                        description="question dialog leave save"
                        id="gui.dialog.question.isCancel"
                    />
                );
            case questionType.QUESTION_CLOSE_APP_SAVE_FILE:
            case questionType.QUESTION_SAVE_NEW:
            case questionType.QUESTION_SAVE_OPEN:
            case questionType.QUESTION_SAVE_RECENT:
            case questionType.QUESTION_OPEN_EXAMPLE:
            case questionType.QUESTION_SAVE_UPDATE_SOFTWARE:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionSave"
                        description="question dialog save"
                        id="gui.dialog.question.isSave"
                    />
                );
            case questionType.QUESTION_FORCE_UPDATE:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionForceUpdate"
                        description="question dialog forceUpdate"
                        id="gui.dialog.question.forceUpdate"
                    />
                );
            case questionType.QUESTION_BLOCK_LIMIT:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionBlockLimit"
                        description="question dialog blockLimit"
                        id="gui.dialog.question.blockLimit"
                    />
                );
            case questionType.QUESTION_BRAIN_TYPE:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionBrainType"
                        description="question dialog brainType"
                        id="gui.dialog.question.brainType"
                    />
                );
            case questionType.QUESTION_BATTERY_LOW_POWER:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionBatteryConnected"
                        description="question dialog battery connected"
                        id="gui.dialog.question.batteryLowPower"
                    />
                );
            case questionType.QUESTION_BATTERY_DISCONNECTED:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionBatteryConnected"
                        description="question dialog battery connected"
                        id="gui.dialog.question.batteryConnected"
                    />
                );
            case questionType.QUESTION_DOWNLOAD_BRAIN_SCRIPT_TIMEOUT:
                return (
                    <FormattedMessage
                        defaultMessage="QuestionBatteryConnected"
                        description="question dialog battery connected"
                        id="gui.dialog.question.downloadBrainScriptTimeout"
                    />
                );
            case questionType.QUESTION_SAVE_PROJECTNAME:
                if (this.props.projectTitle !== undefined && this.props.projectTitle.length == 0 || /^\s*$/.test(this.props.projectTitle)) {
                    return (
                        <div>
                            <FormattedMessage
                                defaultMessage="File name cannot be empty"
                                description="question dialog project name empty"
                                id="gui.dialog.question.projectName.empty"
                            />
                        </div>
                    )
                }
                if (!validFilename(this.props.projectTitle)) {
                    console.log("File name is invalid");
                    const preserve = "\\|/?\"*:<>."
                    return (
                        <div>
                            <FormattedMessage
                                defaultMessage="File name cannot contain the following characters:"
                                description="question dialog project name invalid"
                                id="gui.dialog.question.projectName.invalid"
                            />
                            <br />
                            <div className={styles.invalidFileName} >
                                {preserve}
                            </div>
                        </div>
                    );
                } else {
                    console.log("File name is valid");
                }
                if (this.props.projectTitleOverflow) {
                    return (
                        <div>
                            <FormattedMessage
                                defaultMessage="The maximum number of words has reached 50 words and cannot be entered."
                                description="question dialog project name overflow"
                                id="gui.dialog.question.projectName.overflow"
                            />
                        </div>
                    );
                }
                return (
                    <FormattedMessage
                        defaultMessage="QuestionProjectName"
                        description="question dialog project name"
                        id="gui.dialog.question.projectName"
                    />
                );
            case questionType.QUESTION_DELETE_SLOT_FILE:
                return (
                    <FormattedMessage
                        defaultMessage="Do you want to delete the file of slot {index} ?"
                        description="question dialog delete slot file"
                        id="gui.dialog.question.delete"
                        values={{ index: `${this.props.slotIndex + 1}` }}
                    />
                );
            case questionType.QUESTION_RESET_BRAIN_FIRMWARE:
                return (
                    <FormattedMessage
                        defaultMessage="Do you want to factory reset?"
                        description="Do you want to factory reset?"
                        id="gui.dialog.question.reset.firmware"
                    />
                );
            case questionType.QUESTION_INSTALL_DRIVER:
                return (
                    <FormattedMessage
                        defaultMessage="You need to close the software before installing the driver. After installation, you need to manually restart the software. Are you sure you want to close the software to perform the installation?"
                        description="question dialog install driver"
                        id="gui.brainDriverHint.install.dialog"
                    />
                );

            case questionType.QUESTION_UPDATE_SOFTWARE_FIRST:
                return (
                    <FormattedMessage
                        defaultMessage="The software version does not match the main controller, please update your software"
                        description="The software version does not match the main controller, please update your software"
                        id="gui.dialog.question.update.software"
                    />
                );

            case questionType.QUESTION_UPDATE_FIRMWARE_FIRST:
                return (
                    <FormattedMessage
                        defaultMessage="There is a new version of the main controller, please update your main controller"
                        description="There is a new version of the main controller, please update your main controller"
                        id="gui.dialog.question.update.firmware"
                    />
                );
            case questionType.QUESTION_UPDATE_BT_AND_BRAIN_FIRST:
                return (
                    <FormattedMessage
                        defaultMessage="There is a new version of the main controller and bluetooth module, please update your main controller and bluetooth module"
                        description="There is a new version of the main controller and bluetooth module, please update your main controller and bluetooth module"
                        id="gui.dialog.question.update.firmwareAndBt"
                    />
                );

            case questionType.QUESTION_UPDATE_BT_FIRMWARE_FIRST:
                return (
                    <FormattedMessage
                        defaultMessage="There is a new version of the bluetooth module, please update your bluetooth module"
                        description="There is a new version of the bluetooth module, please update your bluetooth module"
                        id="gui.dialog.question.update.bt"
                    />
                );
            case questionType.QUESTION_FORCE_UPDATE_BT_AND_BRAIN:
                return (
                    <FormattedMessage
                        defaultMessage="The main controller version does not match the software version and script cannot be downloaded. Please update your main controller and bluetooth module"
                        description="The main controller version does not match the software version and script cannot be downloaded. Please update your main controller and bluetooth module"
                        id="gui.dialog.question.force.update.firmwareAndBt"
                    />
                );
            case questionType.QUESTION_FORCE_UPDATE_SOFTWARE:
                return (
                    <FormattedMessage
                        defaultMessage="The main controller version does not match the software version and script cannot be downloaded. Please update your software"
                        description="The main controller version does not match the software version and script cannot be downloaded. Please update your software"
                        id="gui.dialog.question.force.update.software"
                    />
                );
            case questionType.QUESTION_FORCE_UPDATE_FIRMWARE:
                return (
                    <FormattedMessage
                        defaultMessage="The main controller version does not match the software version and script cannot be downloaded. Please update your main controller"
                        description="The main controller version does not match the software version and script cannot be downloaded. Please update your main controller"
                        id="gui.dialog.question.force.update.firmware"
                    />
                );
            case questionType.QUESTION_LOADER_MODE:
                return (
                    <FormattedMessage
                        defaultMessage="The main controller version is abnormal, please update your main controller immediately, and confirm the USB connection and continuous power supply (battery box is recommended)"
                        description="The main controller version is abnormal, please update your main controller immediately, and confirm the USB connection and continuous power supply (battery box is recommended)"
                        id="gui.dialog.question.firmware.loader.mode"
                    />
                );
            case questionType.QUESTION_SWITCH_BRAIN_MODE_IN_RFID_MODE:
                return (
                    <FormattedMessage
                        defaultMessage="The main controller need switch to pc mode."
                        description="The main controller need switch to pc mode."
                        id="gui.dialog.question.switch.brain.mode.in.rfid"
                    />
                );
            case questionType.QUESTION_SWITCH_BRAIN_MODE_IN_BT_MODE:
                return (
                    <FormattedMessage
                        defaultMessage="The main controller need switch to pc mode."
                        description="The main controller need switch to pc mode."
                        id="gui.dialog.question.switch.brain.mode.in.bt"
                    />
                );
            case questionType.QUESTION_OPEN_EDITOR:
                return (
                    <FormattedMessage
                        defaultMessage="This action will open a new python file, are you sure you want to execute it?"
                        description="This action will open a new python file, are you sure you want to execute it?"
                        id="gui.codeview.dialog.question"
                    />
                );
            case questionType.QUESTION_OPEN_EDITOR_SAVE:
                return (
                    <FormattedMessage
                        defaultMessage="This action will open a new python file. The file has not been saved yet. Are you sure you want to execute it?"
                        description="This action will open a new python file. The file has not been saved yet. Are you sure you want to execute it?"
                        id="gui.codeview.dialog.save"
                    />
                );
            case questionType.QUESTION_REMIND_FIRMWARE_UPDATE:
                return (
                    <FormattedMessage
                        defaultMessage="Please use the desktop version to update the firmware"
                        description="Please use the desktop version to update the firmware"
                        id="gui.dialog.question.remind.firmaware.update"
                    />
                );
            case questionType.QUESTION_REMIND_BLUETOOTH_UPDATE:
                return (
                    <FormattedMessage
                        defaultMessage="Please use the desktop version to update the bluetooth module"
                        description="Please use the desktop version to update the bluetooth module"
                        id="gui.dialog.question.remind.bt.update"
                    />
                );
            case questionType.QUESTION_DELETE_CONTROLLER_1:
                return (
                    <FormattedMessage
                        defaultMessage="Do you want to remove controller1(Main) ?"
                        description="Do you want to remove controller1?"
                        id="gui.dialog.question.delete.controller1"
                    />
                );
            case questionType.QUESTION_DIRECT_NEW:
                if (this.props.getDirectNew.editMode == EDIT_MODE.BLOCK_MODE) {
                    return (
                        <FormattedMessage
                            defaultMessage="This action will open a new python file, are you sure you want to execute it?"
                            description="This action will open a new python file, are you sure you want to execute it?"
                            id="gui.dialog.directNew.block"
                        />
                    );
                } else {
                    return (
                        <FormattedMessage
                            defaultMessage="This action will open a new python file, are you sure you want to execute it?"
                            description="This action will open a new python file, are you sure you want to execute it?"
                            id="gui.codeview.dialog.question"
                        />
                    );
                }
            case questionType.QUESTION_DIRECT_NEW_SAVE:
                if (this.props.getDirectNew.editMode == EDIT_MODE.BLOCK_MODE) {
                    return (
                        <FormattedMessage
                            defaultMessage="This action will open a new python file, are you sure you want to execute it?"
                            description="This action will open a new python file, are you sure you want to execute it?"
                            id="gui.dialog.directNew.block.save"
                        />
                    );
                } else {
                    return (
                        <FormattedMessage
                            defaultMessage="This action will open a new python file. The file has not been saved yet. Are you sure you want to execute it?"
                            description="This action will open a new python file. The file has not been saved yet. Are you sure you want to execute it?"
                            id="gui.codeview.dialog.save"
                        />
                    );
                }
            case questionType.QUESTION_FILE_EXTENSION_NOT_MATCH:
                return (
                    <FormattedMessage
                        defaultMessage="File has wrong format"
                        description="File has wrong format"
                        id="gui.dialog.question.extensionNotMatch"
                    />
                );
            case questionType.QUESTION_REPLACE_DEVICE:
                return (
                    <div>
                        <div className={classNames(styles.importTitle)} >
                            <FormattedMessage
                                defaultMessage="Device Settings"
                                description="Device Settings"
                                id="gui.dialog.question.import.title"
                            />
                        </div>
                        <p />
                        <FormattedMessage
                            defaultMessage="The following devices have been detected on your main controller :"
                            description="The following devices have been detected on your main controller :"
                            id="gui.dialog.question.import.device"
                        />
                        <p />
                        {this.getImportDeviceUI(this.props.importingDevice)}
                        <p />
                        <FormattedMessage
                            defaultMessage="Do you want to apply device settings to device management ?"
                            description="Do you want to apply device settings to device management ?"
                            id="gui.dialog.question.import.device2"
                        />
                        <br />
                        <FormattedMessage
                            defaultMessage="*Remind you to add or replace the device management settings in the file after applying"
                            description="*Remind you to add or replace the device management settings in the file after applying"
                            id="gui.dialog.question.import.device3"
                        />
                        <p />
                    </div>
                );
            case questionType.QUESTION_DELETE_AI_SPEECH_GROUP:
                let groupName = this.props.aiSpeechGroup.getGroupName();
                let textLength = 20;
                if (groupName && groupName.length > textLength) {
                    groupName = groupName.substring(0, textLength) + "..."
                }
                return (
                    <FormattedMessage
                        defaultMessage="Delete [{groupName}] and the blocks in the group?"
                        description="Delete [{groupName}] and the blocks in the group?"
                        id="gui.dialog.ai.speech.group.delete"
                        values={{ groupName: groupName }}
                    />);
            case questionType.QUESTION_DELETE_SPEAKER_INTENT_ARRAY:
                return (
                    <FormattedMessage
                        defaultMessage="Do you want to delete dialogues blocks?"
                        description="Do you want to delete dialogues blocks?"
                        id="gui.dialog.ai.speech.IntentArray.delete"
                    />);
            case questionType.QUESTION_REMOVE_CUSTOMIZING_BLOCK:
                return (
                    <div className={classNames(styles.importContentArea)}>
                        <FormattedMessage
                            defaultMessage="Do you want to delete customizing sentences blocks ?"
                            description="Do you want to delete customizing sentences blocks ?"
                            id="gui.dialog.question.speaker.remove.sentence.block"
                        />
                    </div>
                );
            case questionType.QUESTION_IMPORT_CUSTOMIZING_USELESS:
                return (
                    <div className={classNames(styles.importContentArea)}>
                        <FormattedMessage
                            defaultMessage="There are concepts that are not used in customizing sentences in the imported file. Do you want to import them together?"
                            description="There are concepts that are not used in customizing sentences in the imported file. Do you want to import them together?"
                            id="gui.dialog.question.speaker.import.useless"
                        />
                    </div>
                );
            case questionType.QUESTION_IMPORT_CUSTOMIZING_DUPLICATED:
                return (
                    <div className={classNames(styles.wordBreak)}>
                        <div className={classNames(styles.importContentArea)}>
                            <FormattedMessage
                                defaultMessage="There are duplicate groups, customizing sentences or concepts in the imported file. Do you want to import them together?"
                                description="There are duplicate groups, customizing sentences or concepts in the imported file. Do you want to import them together?"
                                id="gui.dialog.question.speaker.import.duplicated"
                            />
                        </div>
                        <div className={classNames(styles.importExceedReminderArea)}>
                            <div className={classNames(styles.importExceedReminder)}>
                                <FormattedMessage
                                    defaultMessage="Both duplicate content will be retained"
                                    description="Both duplicate content will be retained"
                                    id="gui.dialog.question.speaker.import.duplicated.reminder"
                                />
                            </div>
                        </div>
                    </div>
                );
            case questionType.QUESTION_IMPORT_CONCEPT_DUPLICATED:
                return (
                    <div className={classNames(styles.wordBreak)}>
                        <div className={classNames(styles.importContentArea)}>
                            <FormattedMessage
                                defaultMessage="There are concepts in the imported file. Do you want to import them together?"
                                description="There are concepts in the imported file. Do you want to import them together?"
                                id="gui.dialog.question.speaker.import.duplicated.concept"
                            />
                        </div>
                        <div className={classNames(styles.importExceedReminderArea)}>
                            <div className={classNames(styles.importExceedReminder)}>
                                <FormattedMessage
                                    defaultMessage="Both duplicate content will be retained"
                                    description="Both duplicate content will be retained"
                                    id="gui.dialog.question.speaker.import.duplicated.reminder"
                                />
                            </div>
                        </div>
                    </div>
                );
            case questionType.QUESTION_VR_CLOSE_FULLSCREEN:
                return (
                    <FormattedMessage
                        defaultMessage="gui.dialog.question.vr.close.fullscreen"
                        description="vr close fullscreen?"
                        id="gui.dialog.question.vr.close.fullscreen"
                    />
                );
            case questionType.QUESTION_VR_REMIND:
                return (
                    <div>
                        <div>
                            <FormattedMessage
                                defaultMessage="The window is too small, please enlarge the window for operation"
                                description="VR remind"
                                id="gui.dialog.question.vr.remind"
                            />
                        </div>
                        <div>
                            <FormattedMessage
                                defaultMessage="The window is too small, please enlarge the window for operation"
                                description="VR remind"
                                id="gui.dialog.question.vr.remind1"
                            />
                        </div>
                    </div>
                );
        }
    }
    getImportDeviceUI(importingDevice) {
        return importingDevice.map((device, index) => { return (<div key={index}>{device}</div>) })
    }
    renderButtonType() {
        switch (this.props.type) {
            case questionType.QUESTION_DOWNLOAD_FAIL:
            case questionType.QUESTION_FORCE_UPDATE:
            case questionType.QUESTION_BLOCK_LIMIT:
            case questionType.QUESTION_BRAIN_TYPE:
            case questionType.QUESTION_BATTERY_DISCONNECTED:
            case questionType.QUESTION_BATTERY_LOW_POWER:
            case questionType.QUESTION_DOWNLOAD_BRAIN_SCRIPT_TIMEOUT:
            case questionType.QUESTION_REMIND_FIRMWARE_UPDATE:
            case questionType.QUESTION_FILE_EXTENSION_NOT_MATCH:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionIknowButton"
                                description="question dialog Iknow button"
                                id="gui.dialog.question.Iknow"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_REMIND_BLUETOOTH_UPDATE:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionIknowButton"
                                description="question dialog Iknow button"
                                id="gui.dialog.question.Iknow"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={this.onDownloadAndClose}>
                            <FormattedMessage
                                defaultMessage="Download"
                                description="question dialog download button"
                                id="gui.dialog.question.download"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_INSTALL_FAIL:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={this.props.onExit}>
                            <FormattedMessage
                                defaultMessage="QuestionExitButton"
                                description="question dialog exit button"
                                id="gui.dialog.question.exit"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={this.props.onRestart}>
                            <FormattedMessage
                                defaultMessage="QuestionRestartButton"
                                description="question dialog restart button"
                                id="gui.dialog.question.restart"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_SAVE_OPEN_WEB:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />

                        <SBFileUploader
                            onClose={this.onNoSave}
                            onUpdateProjectTitle={this.props.onUpdateProjectTitle}
                        >
                            {(className, renderFileInput, handleLoadProject) => (
                                <div className={styles.questionBtn}
                                    onClick={handleLoadProject} >
                                    <FormattedMessage
                                        defaultMessage="Confirm"
                                        description="Confirm"
                                        id="gui.dialog.confirm"
                                    />
                                    {renderFileInput()}
                                </div>
                            )}
                        </SBFileUploader>
                    </div>);
            case questionType.QUESTION_SAVE_NEW_WEB:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn}
                            onClick={this.onNoSave} >
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="Confirm"
                                id="gui.dialog.confirm"
                            />
                        </div>
                    </div>);
            case questionType.QUESTION_CLOSE_APP_SAVE_FILE:
            case questionType.QUESTION_SAVE_NEW:
            case questionType.QUESTION_SAVE_OPEN:
            case questionType.QUESTION_SAVE_RECENT:
            case questionType.QUESTION_OPEN_EXAMPLE:
            case questionType.QUESTION_SAVE_UPDATE_SOFTWARE:
            case questionType.QUESTION_OPEN_EDITOR_SAVE:
            case questionType.QUESTION_DIRECT_NEW_SAVE:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={this.onNoSave}>
                            <FormattedMessage
                                defaultMessage="QuestionNoSaveButton"
                                description="question dialog no save button"
                                id="gui.dialog.question.noSave"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={this.props.onSave}>
                            <FormattedMessage
                                defaultMessage="QuestionSaveButton"
                                description="question dialog save button"
                                id="gui.dialog.question.save"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_SAVE_PROJECTNAME:
                if (!validFilename(this.props.projectTitle) || this.props.projectTitleOverflow) {
                    console.log("File name is invalid");
                    return (<div></div>);
                } else {
                    console.log("File name is valid");
                }
                return (
                    <div className={classNames(styles.questionFooter, styles.projectName)}>
                        <div className={classNames(styles.questionWhiteBtn, styles.projectName)} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div
                            className={classNames(
                                styles.questionBtn,
                                styles.projectName,
                                this.props.projectTitleChanged ? null : styles.disable
                            )}
                            onClick={this.props.onSaveAs}
                        >
                            <FormattedMessage
                                defaultMessage="QuestionSaveAsButton"
                                description="question dialog saveas button"
                                id="gui.menuBar.saveAs"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div
                            className={classNames(
                                styles.questionBtn,
                                styles.projectName,
                                (this.props.projectTitleChanged && !this.props.projectIsNew) ? null : styles.disable
                            )}
                            onClick={this.props.onSave}
                        >
                            <FormattedMessage
                                defaultMessage="QuestionOverwriteButton"
                                description="question dialog overwrite button"
                                id="gui.dialog.question.overwrite"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_DELETE_SLOT_FILE:
            case questionType.QUESTION_DELETE_CONTROLLER_1:
            case questionType.QUESTION_RESET_BRAIN_FIRMWARE:
            case questionType.QUESTION_REMOVE_CUSTOMIZING_BLOCK:
            case questionType.QUESTION_DELETE_AI_SPEECH_GROUP:
            case questionType.QUESTION_DELETE_SPEAKER_INTENT_ARRAY:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={this.onDelete}>
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="question dialog confirm button"
                                id="gui.hint.deleteBlocks.confirm"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_REPLACE_DEVICE:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={() => this.onClose()}>
                            <FormattedMessage
                                defaultMessage="No, Skip"
                                description="No, Skip"
                                id="gui.dialog.question.import.cancel"
                            />
                        </div>
                        <div className={styles.questionDeviceInterval} />
                        <div className={styles.questionBtn} onClick={() => this.onReplace()}>
                            <FormattedMessage
                                defaultMessage="Yes, Replace"
                                description="Yes, Replace"
                                id="gui.dialog.question.import.confirm"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_INSTALL_DRIVER:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={this.props.onInstall}>
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="question dialog confirm button"
                                id="gui.hint.deleteBlocks.confirm"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_UPDATE_SOFTWARE_FIRST:
            case questionType.QUESTION_UPDATE_FIRMWARE_FIRST:
            case questionType.QUESTION_UPDATE_BT_FIRMWARE_FIRST:
            case questionType.QUESTION_UPDATE_BT_AND_BRAIN_FIRST:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="Skip"
                                description="Skip"
                                id="gui.dialog.update.skip"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={() => this.onUpdateAndClose()}>
                            <FormattedMessage
                                defaultMessage="Update Immediately"
                                description="Update Immediately"
                                id="gui.dialog.update.update"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_FORCE_UPDATE_SOFTWARE:
            case questionType.QUESTION_FORCE_UPDATE_FIRMWARE:
            case questionType.QUESTION_FORCE_UPDATE_BT_AND_BRAIN:
            case questionType.QUESTION_LOADER_MODE:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionBtn} onClick={() => this.onUpdateAndClose()}>
                            <FormattedMessage
                                defaultMessage="Update Immediately"
                                description="Update Immediately"
                                id="gui.dialog.update.update"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_SWITCH_BRAIN_MODE_IN_BT_MODE:
            case questionType.QUESTION_SWITCH_BRAIN_MODE_IN_RFID_MODE:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={this.onDownloadAndClose}>
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="Confirm"
                                id="gui.dialog.confirm"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_OPEN_NEW:
            case questionType.QUESTION_DIRECT_NEW:
            case questionType.QUESTION_OPEN_EDITOR:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={this.onNoSave}>
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="question dialog confirm button"
                                id="gui.hint.deleteBlocks.confirm"
                            />
                        </div>
                    </div>
                );

            case questionType.QUESTION_IMPORT_CUSTOMIZING_USELESS:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={() => this.onImportUsingOnly()}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={() => this.onImportUseless()}>
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="question dialog confirm button"
                                id="gui.hint.deleteBlocks.confirm"
                            />
                        </div>
                    </div>
                );

            case questionType.QUESTION_IMPORT_CUSTOMIZING_DUPLICATED:
            case questionType.QUESTION_IMPORT_CONCEPT_DUPLICATED:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={() => this.onImportDuplicated()}>
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="question dialog confirm button"
                                id="gui.hint.deleteBlocks.confirm"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_VR_CLOSE_FULLSCREEN:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionBtn} onClick={this.onClose}>
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="question dialog confirm button"
                                id="gui.hint.deleteBlocks.confirm"
                            />
                        </div>
                    </div>
                );
            case questionType.QUESTION_VR_REMIND:
                return (
                    <div className={styles.questionFooter}></div>
                );
        }
    }

    onImportUseless() {
        this.props.onImportUseless();
        this.onClose();
    }

    onImportDuplicated() {
        this.props.onImportDuplicated();
        this.onClose();
    }

    onImportUsingOnly() {
        this.props.onImportUsingOnly();
        this.onClose();
    }

    onDownloadAndClose() {
        this.props.hideQuestionDialog(this.props.type);
        this.props.onDownload();
    }

    onUpdateAndClose() {
        this.props.hideQuestionDialog(this.props.type);
        this.props.onUpdate();
    }

    onNoSave() {
        this.props.hideQuestionDialog(this.props.type);
        this.props.onNoSave();
    }

    onClose() {
        this.props.hideQuestionDialog(this.props.type);
        this.props.onClose();
    }

    onDelete() {
        this.props.hideQuestionDialog(this.props.type);
        this.props.onDelete();
    }

    onReplace() {
        this.props.hideQuestionDialog(this.props.type);
        this.props.onReplace();
    }

    ref(c) {
        this.dialog = c;
    }

    render() {
        if (this.props.type == questionType.QUESTION_SAVE_PROJECTNAME) {
            return (
                <div className={this.props.class} ref={this.ref} >
                    <div className={classNames(styles.questionContent, this.props.projectTitleOverflow ? styles.projectNameHint : styles.projectName)} >
                        {this.renderContentType()}
                    </div>
                    {this.renderButtonType()}
                </div>
            );
        }

        if (this.props.type == questionType.QUESTION_REPLACE_DEVICE) {
            return (
                <div className={styles.backdropStyle}>
                    <div className={classNames(styles.modalStyle, styles.implortDevice)} ref={this.ref} >
                        <div className={classNames(styles.questionContent, styles.projectName, styles.importContent)} >
                            {this.renderContentType()}
                        </div>
                        {this.renderButtonType()}
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle, styles.question)} ref={this.ref} >
                    <div className={styles.questionView}>
                        <div className={styles.questionContent}>
                            {this.renderContentType()}
                        </div>
                        {this.renderButtonType()}
                    </div>
                </div>
            </div>
        );
    }
}

QuestionDialog.propTypes = {
    onClose: PropTypes.func,
    onRestart: PropTypes.func,
    onExit: PropTypes.func,
    onNoSave: PropTypes.func,
    onSave: PropTypes.func,
    onSaveAs: PropTypes.func,
    class: PropTypes.string,
    type: PropTypes.string,
    show: PropTypes.bool,
    projectTitleChanged: PropTypes.bool,
    projectTitleOverflow: PropTypes.bool,
    projectIsNew: PropTypes.bool,
    projectTitle: PropTypes.string,
    onDelete: PropTypes.func,
    onReplace: PropTypes.func,
    onInstall: PropTypes.func,
    onUpdate: PropTypes.func,
    onDownload: PropTypes.func,
    hideQuestionDialog: PropTypes.func,
    getDirectNew: PropTypes.object,
    importingDevice: PropTypes.array,
    aiSpeechGroup: PropTypes.object,
    onImportDuplicated: PropTypes.func,
    onImportUseless: PropTypes.func,
    onImportUsingOnly: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
};

QuestionDialog.defaultProps = {
    onClose: () => { },
    onRestart: () => { },
    onExit: () => { },
    onNoSave: () => { },
    onSave: () => { },
    onSaveAs: () => { },
    onDelete: () => { },
    onReplace: () => { },
    onInstall: () => { },
    onUpdate: () => { },
    onDownload: () => { },
    onImportDuplicated: () => { },
    onImportUseless: () => { },
    onImportUsingOnly: () => { }
}

const mapStateToProps = state => ({
    projectTitleChanged: projectTitleChanged(state),
    projectTitleOverflow: projectTitleOverflow(state),
    projectIsNew: projectIsNew(state),
    projectTitle: projectTitle(state),
    slotIndex: getQuestionDeleteSlotIndex(state),
    getDirectNew: getDirectNew(state)
});

const mapDispatchToProps = dispatch => ({
    hideQuestionDialog: questionType => dispatch(hideQuestionDialog(questionType))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuestionDialog);