import {
    platformType,
    isPad,
    getPlatform
} from './platform';

let Catagory = {
    File: "File",
    Bluetooth: "Bluetooth",
    Course: "Course",
    Exit: "Exit",
    Initial: "Initial",
    Server: "Server",
    Storage: "Storage",
    Log: "Log",
    Url: "Url",
    Socket: "Socket",
};

let FileAction = {
    new: "new",
    open: "open",
    save: "save",
    load: "load",
    wirte: "write",
    importSentences: "importSentences",
};

let LogType = {
    debug: "debug",
    info: "info",
    error: "error",
    default: "default"
}

function postMessage(catagory, json) {
    if (!isPad()) return;
    if (getPlatform() == platformType.iPad) {
        if (window.webkit == undefined) return;
        switch (catagory) {
            case Catagory.File:
                webkit.messageHandlers.file.postMessage(json);
                break;
            case Catagory.Bluetooth:
                webkit.messageHandlers.bluetooth.postMessage(json);
                break;
            case Catagory.Course:
                webkit.messageHandlers.course.postMessage(json);
                break;
            case Catagory.Exit:
                webkit.messageHandlers.exit.postMessage(json);
                break;
            case Catagory.Initial:
                webkit.messageHandlers.initial.postMessage(json);
                break;
            case Catagory.Server:
                webkit.messageHandlers.server.postMessage(json);
                break;
            case Catagory.Log:
                webkit.messageHandlers.log.postMessage(json);
                break;
            case Catagory.Url:
                webkit.messageHandlers.url.postMessage(json);
            case Catagory.Socket:
                webkit.messageHandlers.socket.postMessage(json);
                break;
        }
    }

    if (getPlatform() == platformType.RN) {
        json.catagory = catagory;
        window.ReactNativeWebView.postMessage(JSON.stringify(json));
    }
}

export {
    Catagory,
    FileAction,
    LogType,
    postMessage
};