const DEVICE_CHANGED = 'scratch-gui/device/DEVICE_CHANGED';

const initialState = {
    isDeviceChanged: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case DEVICE_CHANGED:
            return Object.assign({}, state, {
                isDeviceChanged: action.isDeviceChanged
            });
        default:
            return state;
    }
};

const setDeviceChanged = function (isDeviceChanged) {
    return {
        type: DEVICE_CHANGED,
        isDeviceChanged: isDeviceChanged
    };
};

export {
    reducer as default,
    initialState as deviceInitialState,
    setDeviceChanged
};
