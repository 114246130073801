import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import styles from './port.css';
import DisconnectedPort from './disconnected-port.jsx';
import ConnectedPort from './connected-port.jsx';

import { BRAIN_TYPE } from '../../../lib/brains';
import { getBrainInfo, getAllPortInfo } from '../../../reducers/brain';
import { getPickedBrainType, } from '../../../reducers/picked-brain-type';
import { getRealEntryDeviceList } from '../../../reducers/device-manager-controller';
import { uiType, getUIStyle } from '../../../reducers/ui-style';
import hint from '../pictures/help.svg';
import hintWW from '../pictures/ww/help.svg';
import exclamationIcon from '../pictures/exclamation_mark.svg';
import log from '../../../lib/log.js';

class Port extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            getRealEntryDeviceList: props.getRealEntryDeviceList
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.getRealEntryDeviceList != this.props.getRealEntryDeviceList) {
            this.setState({ getRealEntryDeviceList: this.props.getRealEntryDeviceList })
        }
        if (prevProps.getAllPortInfo != this.props.getAllPortInfo && Array.isArray(this.props.getAllPortInfo) && this.props.getAllPortInfo.length == 0) {
            console.log('this.props.getBrainInfo == null')
            this.setState({ getRealEntryDeviceList: null })
        }
    }

    isConnectedDeviceDifferent() {
        if (Array.isArray(this.props.getAllPortInfo) && this.props.getAllPortInfo.length == 0) return false;
        if ((this.props.getBrainInfo.brain_type == BRAIN_TYPE.ENTRY && this.props.getPickedBrainType == BRAIN_TYPE.ENTRY) ||
            (this.props.getBrainInfo.brain_type == BRAIN_TYPE.EDU_AND_ENTRY && this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY)) {
            let devicePort = this.props.connectedDeviceList[this.props.index].port;
            let deviceType = this.props.connectedDeviceList[this.props.index].type;
            let deviceOther = this.props.connectedDeviceList[this.props.index].other;
            let realEntryDevice = this.props.getRealEntryDeviceList;
            if (Array.isArray(realEntryDevice)) {
                let realDevice = realEntryDevice.filter(device => device.port == devicePort)[0];
                if (!realDevice && deviceType) return true;
                if (realDevice && realDevice.device != undefined && typeof (realDevice.device) == 'string') {
                    if (realDevice.device.includes('motor') && deviceType == "drivetrain") return false;
                    if (realDevice.device.includes('gyro') && deviceType == "drivetrain" && deviceOther == "gyro") return false;
                    if (realDevice.device != deviceType) log.info(devicePort + '-realDevice.device: ' + realDevice.device + ", DM deviceType: " + deviceType)
                    return realDevice.device != deviceType;
                } else {
                    log.warn('isConnectedDeviceDifferent unknow realDevice', realDevice)
                    log.warn('isConnectedDeviceDifferent unknow deviceType', deviceType)
                }
            }
        }
        return false;
    }

    render() {
        const {
            index,
            connectedDeviceList,
            isBrainLeft,
            vm
        } = this.props;
        return (
            <div className={classNames((this.props.hide) ? styles.hide : styles.fadeIn, styles.port)}>
                {connectedDeviceList[index].type ?
                    this.props.hide ?
                        null : <div><ConnectedPort
                            port={connectedDeviceList[index].port}
                            name={connectedDeviceList[index].name}
                            type={connectedDeviceList[index].type}
                            id={connectedDeviceList[index].id}
                            connectedDeviceList={connectedDeviceList}
                            isBrainLeft={isBrainLeft}
                            vm={vm} />
                            {this.isConnectedDeviceDifferent() ?
                                <img src={exclamationIcon} className={classNames(styles.hint, !isBrainLeft ? styles.hintInverse : null)} />
                                : null}
                        </div>
                    : <div><DisconnectedPort
                        port={connectedDeviceList[index].port}
                        name={connectedDeviceList[index].name}
                        isBrainLeft={isBrainLeft} />
                        {this.isConnectedDeviceDifferent() ?
                            <img src={exclamationIcon} className={classNames(styles.hint, !isBrainLeft ? styles.hintInverse : null)} />
                            : null}
                    </div>
                }
            </div>
        );
    }
}

Port.propTypes = {
    index: PropTypes.number,
    connectedDeviceList: PropTypes.array,
    hide: PropTypes.bool,
    isBrainLeft: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    getRealEntryDeviceList: getRealEntryDeviceList(state),
    getPickedBrainType: getPickedBrainType(state),
    getBrainInfo: getBrainInfo(state),
    getUIStyle: getUIStyle(state),
    getAllPortInfo: getAllPortInfo(state)
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Port);


