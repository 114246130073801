import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import PromptComponent from '../components/prompt/prompt.jsx';
import { FormattedMessage } from 'react-intl';
import { variableRegSp } from '../lib/variable-utils';
import { PROMPT_TYPE } from '../reducers/speaker'
import VM from 'scratch-vm';
import { titleMatches } from 'selenium-webdriver/lib/until';

const SYMBOLS_NOT_ALLOWED = 'SYMBOLS_NOT_ALLOWED';
const NAME_REQUIRED = 'NAME_REQUIRED';
const LIST_LENGTH_REQUIRED = 'LIST_LENGTH_REQUIRED';
const NAME_DUPLICATE = 'NAME_DUPLICATE';

let message = {
    duplicate: {
        variable: {
            defaultMessage: "Symbols Duplicate",
            description: "Warning message",
            id: "gui.prompt.variable.duplicate",
        },
        list: {
            defaultMessage: "Symbols Duplicate",
            description: "Warning message",
            id: "gui.prompt.list.duplicate",
        },
        group: {
            id: "gui.prompt.group.duplicate"
        }
    },
    symbolNotAllowed: <FormattedMessage
        defaultMessage="Symbols Not Allowed"
        description="Warning message"
        id="gui.prompt.symbolsNotAllowed"
    />,
    required: <FormattedMessage
        defaultMessage="Name Required"
        description="Warning message"
        id="gui.prompt.nameRequired"
    />
};

class Prompt extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleOk',
            'handleCancel',
            'handleChange',
            'validateWord',
            'validateListLength',
            'handleKeyPress',
            'handleListLengthSelection'
        ]);
        this.state = {
            inputValue: '', // 與不修改名稱按確定有關，不可亂更改此值
            globalSelected: true,
            cloudSelected: false,
            canAddCloudVariable: (props.vm && props.vm.runtime.canAddCloudVariable()) || false,
            listLength: 1
        };
        this.showWarningText = null; // affect the button disabled
        this.lockOKButton = (this.props.defaultValue ? false : true); // affect the button disabled
    }
    handleKeyPress(event) {
        if (event.key === 'Enter' && !this.validateWord(this.state.inputValue) && !this.lockOKButton) this.handleOk();
    }
    handleFocus(event) {
        event.target.select();
    }
    handleOk() {
        if (!this.showWarningText && !this.lockOKButton) {
            this.props.onOk(this.state.inputValue, {
                scope: this.state.globalSelected ? 'global' : 'local',
                isCloud: this.state.cloudSelected,
                listLength: this.state.listLength
            });
        }
    }
    handleCancel() {
        this.props.onCancel();
    }
    handleChange(e) {
        switch (this.validateWord(e.target.value)) {
            case SYMBOLS_NOT_ALLOWED:
                this.showWarningText = message.symbolNotAllowed;
                break;
            case NAME_DUPLICATE:
                if (this.props.type == PROMPT_TYPE.var) {
                    if (this.props.showListOptions) {
                        this.showWarningText = <FormattedMessage
                            values={{ name: `${e.target.value}` }}
                            {...message.duplicate.list}
                        />;
                    } else {
                        this.showWarningText = <FormattedMessage
                            values={{ name: `${e.target.value}` }}
                            {...message.duplicate.variable}
                        />;
                    }
                } else {
                    this.showWarningText = <FormattedMessage
                        values={{ name: `${e.target.value}` }}
                        {...message.duplicate.group}
                    />;
                }
                break;
            case NAME_REQUIRED:
                this.showWarningText = message.required;
                break;
            default:
                this.showWarningText = null
                this.lockOKButton = false;
        }
        this.setState({ inputValue: e.target.value });
    }
    validateWord(word) {
        // check only letter, number and down line
        word = word.replace(/^\s*/, "");
        if (word.length === 0) return NAME_REQUIRED;

        for (let i = 0; i < this.props.names.length; i++) {
            if (this.props.names[i] == word) return NAME_DUPLICATE;
        }
        if (variableRegSp.test(word)) return SYMBOLS_NOT_ALLOWED;
    }

    validateListLength(number) {
        if (this.props.showListOptions) {
            if (number == 0) return LIST_LENGTH_REQUIRED;
        }
    }
    handleListLengthSelection(item) {
        this.setState({ listLength: item });
    }
    render() {
        return (
            <PromptComponent
                defaultValue={this.props.defaultValue}
                label={this.props.label}
                showListOptions={this.props.showListOptions}
                title={this.props.title}
                onCancel={this.handleCancel}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onKeyPress={this.handleKeyPress}
                onOk={this.handleOk}
                showWarningText={this.showWarningText}
                onListLengthSelection={this.handleListLengthSelection}
                lockOKButton={this.lockOKButton}
            />
        );
    }
}

Prompt.propTypes = {
    defaultValue: PropTypes.string,
    label: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    showListOptions: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    names: PropTypes.array,
    vm: PropTypes.instanceOf(VM)
};

export default Prompt;
