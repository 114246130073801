import {
    editBlockType
} from '../../../reducers/dialog';
import ScratchBlocks from 'scratch-blocks';

const soundBlocks = (function () {
    const soundPlayNote =
        `<block type="sound_playnote">
            <value name="NOTE">
                <shadow type="note">
                    <field name="NOTE">60</field>
                </shadow>
            </value>
            <value name="BEATS">
                <shadow type="math_number">
                    <field name="NUM">0.25</field>
                </shadow>
            </value>
        </block>`;

    const soundRestforBeats =
        `<block type="sound_restforbeats">
            <value name="BEATS">
                <shadow type="math_number">
                    <field name="NUM">0.25</field>
                </shadow>
            </value>
        </block>`;

    const soundSetTempoto =
        `<block type="sound_settempoto">
            <value name="TEMPO">
                <shadow type="math_number">
                    <field name="NUM">60</field>
                </shadow>
            </value>
        </block>`;

    const soundChangeTempoby =
        `<block type="sound_changetempoby">
            <value name="TEMPO">
                <shadow type="math_number">
                    <field name="NUM">20</field>
                </shadow>
            </value>
        </block>`;

    const soundTempo =
        `<block type="sound_tempo"/>`;

    const soundPlay =
        `<block type="sound_play"/>`;

    const soundPlaySound =
        `<block type="sound_play_sound"/>`;

    const soundPlayRecording =
        `<block type="sound_play_recording"/>`;

    const soundImportAudioButton = function (workspace) {
        let callbackKey = "IMPORT_AUDIO";
        let msg = ScratchBlocks.Msg.SOUND_IMPORT_AUDIO;
        workspace.registerButtonCallback(callbackKey, () => {
            workspace.importFile(ScratchBlocks.addSound)
        });
        return `<button text="${msg}" callbackKey="${callbackKey}"/>`
    };

    const soundAudioManagementButton = function (workspace, handleAudioManagementStart) {
        let callbackKey = "AUDIO_MANAGEMENT";
        let msg = ScratchBlocks.Msg.SOUND_AUDIO_MANAGEMENT;
        workspace.registerButtonCallback(callbackKey, () => {
            handleAudioManagementStart(() => { }, editBlockType.sound_audio)
        });
        return `<button text="${msg}" callbackKey="${callbackKey}"/>`
    };

    const soundPlayAudio = function (soundName) {
        return `<block type="sound_play_audio">
                    <value name="SOUND_MENU">
                        <shadow type="sound_sounds_menu">
                            <field name="SOUND_MENU">${soundName}</field>
                        </shadow>
                    </value>
                </block>`};

    const soundSetSpeakerVolumnto =
        `<block type="sound_set_speaker_volume_to">
            <value name="VOLUME">
                <shadow type="math_integer">
                    <field name="NUM">50</field>
                </shadow>
            </value>
        </block>`;

    const soundStopPlayingSound =
        `<block type="sound_stop_playing_sound"/>`;

    const soundStartRecordingforSeconds =
        `<block type="sound_start_recording_for_seconds"/>`;

    const soundPlayNoteVR =
        `<block type="sound_playnote_vr">
            <value name="NOTE">
                <shadow type="note">
                    <field name="NOTE">60</field>
                </shadow>
            </value>
            <value name="BEATS">
                <shadow type="math_number">
                    <field name="NUM">0.25</field>
                </shadow>
            </value>
        </block>`;

    const soundRestforBeatsVR =
        `<block type="sound_restforbeats_vr">
            <value name="BEATS">
                <shadow type="math_number">
                    <field name="NUM">0.25</field>
                </shadow>
            </value>
        </block>`;

    const soundPlayVR =
        `<block type="sound_play_vr"/>`;

    return {
        EDU: {
            sound_playnote: soundPlayNote,
            sound_restforbeats: soundRestforBeats,
            sound_settempoto: soundSetTempoto,
            sound_changetempoby: soundChangeTempoby,
            sound_tempo: soundTempo,
            sound_play: soundPlay
        },
        Entry: {
            sound_play_sound: soundPlaySound,
            sound_play_recording: soundPlayRecording,
            sound_import_audio_button: soundImportAudioButton,
            sound_audio_management_button: soundAudioManagementButton,
            sound_play_audio: soundPlayAudio,
            sound_set_speaker_volume_to: soundSetSpeakerVolumnto,
            sound_stop_playing_sound: soundStopPlayingSound,
            sound_start_recording_for_seconds: soundStartRecordingforSeconds
        },
        WebVR: {
            sound_playnote_vr: soundPlayNoteVR,
            sound_restforbeats_vr: soundRestforBeatsVR,
            sound_settempoto: soundSetTempoto,
            sound_changetempoby: soundChangeTempoby,
            sound_tempo: soundTempo,
            sound_play_vr: soundPlayVR
        }
    };
})();

export default soundBlocks;


