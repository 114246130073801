import motionBlocks from '../block-vertical-blocks/motion';

export default function motionEDUandEntry(targetId, deviceInfo, blockSeparator) {
    let motionBlocksWithTarget = motionBlocks(targetId);

    return `
        ${deviceInfo.motorInfo.hasMotor || deviceInfo.threeWireMotorInfo.hasThreeWireMotor ? `
            <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
                ${motionBlocksWithTarget.motion_spin}
                ${deviceInfo.motorInfo.hasMotor ? `
                    ${motionBlocksWithTarget.motion_spindegree}
                    ${motionBlocksWithTarget.motion_spinpositiondegree}
                ` : ``}
                ${blockSeparator}
                ${motionBlocksWithTarget.motion_stop}
                ${blockSeparator}
                ${deviceInfo.motorInfo.hasMotor ? `${motionBlocksWithTarget.motion_setpositiondegree}` : ``}
                ${motionBlocksWithTarget.motion_setvelocitypercent_eduandentry}
                ${deviceInfo.motorInfo.hasMotor ? `
                    ${motionBlocksWithTarget.motion_setstopping}
                    ${motionBlocksWithTarget.motion_setmaxtorque}
                    ${motionBlocksWithTarget.motion_settimeout}
                ` : ``}
            </category>` : ``
        }
    `;
};