import { applyMiddleware, compose, combineReducers } from 'redux';
import assetDragReducer, { assetDragInitialState } from './asset-drag';
import cardsReducer, { cardsInitialState } from './cards';
import colorPickerReducer, { colorPickerInitialState } from './color-picker';
import codeEditorReducer, { codeEditorInitialState } from './code-editor';
import customProceduresReducer, { customProceduresInitialState } from './custom-procedures';
import blockDragReducer, { blockDragInitialState } from './block-drag';
import hoveredTargetReducer, { hoveredTargetInitialState } from './hovered-target';
import menuReducer, { menuInitialState } from './menus';
import modalReducer, { modalsInitialState } from './modals';
import modeReducer, { modeInitialState } from './mode';
import projectChangedReducer, { projectChangedInitialState } from './project-changed';
import projectStateReducer, { projectStateInitialState } from './project-state';
import projectTitleReducer, { projectTitleInitialState } from './project-title';
import fontsLoadedReducer, { fontsLoadedInitialState } from './fonts-loaded';
import restoreDeletionReducer, { restoreDeletionInitialState } from './restore-deletion';
import stageSizeReducer, { stageSizeInitialState } from './stage-size';
import targetReducer, { targetsInitialState } from './targets';
import timeoutReducer, { timeoutInitialState } from './timeout';
import toolboxReducer, { toolboxInitialState } from './toolbox';
import deviceReducer, { deviceInitialState } from './device';
import vmReducer, { vmInitialState } from './vm';
import vmStatusReducer, { vmStatusInitialState } from './vm-status';
import workspaceMetricsReducer, { workspaceMetricsInitialState } from './workspace-metrics';
import throttle from 'redux-throttle';

import decks from '../lib/libraries/decks/index.jsx';

import dialogReducer, { dialogInitialState } from './dialog';
import deviceManagerReducer, { deviceManagerInitialState } from './device-manager-controller';
import selectOptionReducer, { selectOptionInitialState } from './select-option';
import deleteDeviceReducer, { deleteDeviceInitialState } from './delete-device';
import recentFilePathsReducer, { recentFilePathsInitialState } from './recentfile-path';
import brainReducer, { brainInitialState } from './brain';
import pickedBrainTypeReducer, { isPickedBrainTypeInitailState } from './picked-brain-type';
import blockReducer, { blocksInitialState } from './block';
import deviceHelpSelectedReducer, { deviceHelpSelectedInitialState } from './device-help-select';
import taskReducer, { taskInitialState } from './task';
import userGuideReducer, { userGuideInitailState } from './user-guide';
import slotListReducer, { slotListInitialState } from './slot-list';
import speakerReducer, { speakerInitialState } from './speaker';
import fileListReducer, { fileListInitialState } from './pad-file-list';
import bluetoothReducer, { bluetoothInitialState } from './bluetooth';
import projectTitleChangedReducer, { projectTitleChangedInitialState } from './project-title-changed';
import controllerReducer, { controllerInitialState } from './controller';
import softwareUpdateReducer, { softwareUpdateInitialState } from './software-update';
import wifiReducer, { wifiInitialState } from './wifi-list';
import visionReducer, { visionInitialState } from './vision';
import uiStyleReducer, { uiStyleInitialState } from './ui-style';
import vrReducer, { vrInitialState } from './vr';
import testVersionReducer, { testVersionInitialState } from './test-version';
import loadingBarReducer, { loadingBarInitialState } from './loading-bar';
import fileManagerReducer, { fileManagerInitialState } from './file-manager';

const guiMiddleware = compose(applyMiddleware(throttle(300, { leading: true, trailing: true })));

const guiInitialState = {
    assetDrag: assetDragInitialState,
    blockDrag: blockDragInitialState,
    cards: cardsInitialState,
    colorPicker: colorPickerInitialState,
    customProcedures: customProceduresInitialState,
    mode: modeInitialState,
    hoveredTarget: hoveredTargetInitialState,
    stageSize: stageSizeInitialState,
    menus: menuInitialState,
    modals: modalsInitialState,
    projectChanged: projectChangedInitialState,
    projectState: projectStateInitialState,
    projectTitle: projectTitleInitialState,
    fontsLoaded: fontsLoadedInitialState,
    restoreDeletion: restoreDeletionInitialState,
    targets: targetsInitialState,
    timeout: timeoutInitialState,
    toolbox: toolboxInitialState,
    device: deviceInitialState,
    vm: vmInitialState,
    vmStatus: vmStatusInitialState,
    workspaceMetrics: workspaceMetricsInitialState,
    dialog: dialogInitialState,
    deviceManager: deviceManagerInitialState,
    selectOption: selectOptionInitialState,
    deleteDevice: deleteDeviceInitialState,
    task: taskInitialState,
    recentFilePaths: recentFilePathsInitialState,
    brain: brainInitialState,
    isPickedBrainType: isPickedBrainTypeInitailState,
    block: blocksInitialState,
    deviceHelpSelected: deviceHelpSelectedInitialState,
    showUserGuide: userGuideInitailState,
    slotList: slotListInitialState,
    fileList: fileListInitialState,
    bluetooth: bluetoothInitialState,
    projectTitleChanged: projectTitleChangedInitialState,
    controller: controllerInitialState,
    codeEditor: codeEditorInitialState,
    softwareUpdate: softwareUpdateInitialState,
    wifi: wifiInitialState,
    vision: visionInitialState,
    uiStyle: uiStyleInitialState,
    speaker: speakerInitialState,
    vr: vrInitialState,
    testVersion: testVersionInitialState,
    loadingBar: loadingBarInitialState,
    fileManager: fileManagerInitialState
};

const initPlayer = function (currentState) {
    return Object.assign(
        {},
        currentState,
        {
            mode: {
                isFullScreen: currentState.mode.isFullScreen,
                isPlayerOnly: true,
                // When initializing in player mode, make sure to reset
                // hasEverEnteredEditorMode
                hasEverEnteredEditor: false
            }
        }
    );
};
const initFullScreen = function (currentState) {
    return Object.assign(
        {},
        currentState,
        {
            mode: {
                isFullScreen: true,
                isPlayerOnly: currentState.mode.isPlayerOnly,
                hasEverEnteredEditor: currentState.mode.hasEverEnteredEditor
            }
        }
    );
};

const initEmbedded = function (currentState) {
    return Object.assign(
        {},
        currentState,
        {
            mode: {
                showBranding: true,
                isFullScreen: true,
                isPlayerOnly: true,
                hasEverEnteredEditor: false
            }
        }
    );
};

const initTutorialCard = function (currentState, deckId) {
    return Object.assign(
        {},
        currentState,
        {
            cards: {
                visible: true,
                content: decks,
                activeDeckId: deckId,
                expanded: true,
                step: 0,
                x: 0,
                y: 0,
                dragging: false
            }
        }
    );
};

const initTelemetryModal = function (currentState) {
    return Object.assign(
        {},
        currentState,
        {
            modals: {
                telemetryModal: true // this key must match `MODAL_TELEMETRY` in modals.js
            }
        }
    );
};

const guiReducer = combineReducers({
    assetDrag: assetDragReducer,
    blockDrag: blockDragReducer,
    cards: cardsReducer,
    codeEditor: codeEditorReducer,
    colorPicker: colorPickerReducer,
    customProcedures: customProceduresReducer,
    mode: modeReducer,
    hoveredTarget: hoveredTargetReducer,
    stageSize: stageSizeReducer,
    menus: menuReducer,
    modals: modalReducer,
    projectChanged: projectChangedReducer,
    projectState: projectStateReducer,
    projectTitle: projectTitleReducer,
    fontsLoaded: fontsLoadedReducer,
    restoreDeletion: restoreDeletionReducer,
    targets: targetReducer,
    timeout: timeoutReducer,
    toolbox: toolboxReducer,
    device: deviceReducer,
    vm: vmReducer,
    vmStatus: vmStatusReducer,
    workspaceMetrics: workspaceMetricsReducer,
    dialog: dialogReducer,
    deviceManager: deviceManagerReducer,
    selectOption: selectOptionReducer,
    deleteDevice: deleteDeviceReducer,
    recentFilePaths: recentFilePathsReducer,
    brain: brainReducer,
    isPickedBrainType: pickedBrainTypeReducer,
    block: blockReducer,
    deviceHelpSelected: deviceHelpSelectedReducer,
    task: taskReducer,
    showUserGuide: userGuideReducer,
    slotList: slotListReducer,
    fileList: fileListReducer,
    bluetooth: bluetoothReducer,
    projectTitleChanged: projectTitleChangedReducer,
    controller: controllerReducer,
    softwareUpdate: softwareUpdateReducer,
    wifi: wifiReducer,
    vision: visionReducer,
    uiStyle: uiStyleReducer,
    speaker: speakerReducer,
    vr: vrReducer,
    testVersion: testVersionReducer,
    loadingBar: loadingBarReducer,
    fileManager: fileManagerReducer
});

export {
    guiReducer as default,
    guiInitialState,
    guiMiddleware,
    initEmbedded,
    initFullScreen,
    initPlayer,
    initTelemetryModal,
    initTutorialCard
};
