import { USER_GUIDE_STATE } from '../lib/user-guide-state'
import { ENABLE_BRAIN_TYPE_WEB_VR } from '../config/project-config'

const UPDATE_SHOULD_SHOW_USER_GUIDE = 'scratch-gui/user-guide/UPDATE_SHOULD_SHOW_USER_GUIDE'
const UPDATE_SHOW_USER_GUIDE = 'scratch-gui/user-guide/UPDATE_SHOW_USER_GUIDE';
const UPDATE_CURRENT_STATE = 'scratch-gui/user-guide/UPDATE_CURRENT_STATE';

const SHOULD_SHOW_USER_GUIDE = 'shouldShowUserGuide'
const SHOW_USER_GUIDE = 'showUserGuide';
const CURRENT_STATE = 'currentState';

const initialState = {
    [SHOULD_SHOW_USER_GUIDE]: false,
    [SHOW_USER_GUIDE]: false,
    [CURRENT_STATE]: ENABLE_BRAIN_TYPE_WEB_VR ? USER_GUIDE_STATE.VR_TOOLBAR_GUIDE : USER_GUIDE_STATE.BLOCK_GUIDE
}

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case UPDATE_SHOULD_SHOW_USER_GUIDE:
            return Object.assign({}, state, {
                [SHOULD_SHOW_USER_GUIDE]: action.shouldShowUserGuide
            });
        case UPDATE_SHOW_USER_GUIDE:
            if (!action.showUserGuide) {
                return Object.assign({}, state, {
                    [SHOW_USER_GUIDE]: action.showUserGuide,
                    [CURRENT_STATE]: ENABLE_BRAIN_TYPE_WEB_VR ? USER_GUIDE_STATE.VR_TOOLBAR_GUIDE : USER_GUIDE_STATE.BLOCK_GUIDE
                });
            }
            return Object.assign({}, state, {
                [SHOW_USER_GUIDE]: action.showUserGuide,
                [SHOULD_SHOW_USER_GUIDE]: false
            });
        case UPDATE_CURRENT_STATE:
            return Object.assign({}, state, {
                [CURRENT_STATE]: action.currentState
            });
        default:
            return state;
    }
};

const updateShouldShowUserGuide = (show) => ({
    type: UPDATE_SHOULD_SHOW_USER_GUIDE,
    shouldShowUserGuide: show
});

const updateShowUserGuide = (show) => ({
    type: UPDATE_SHOW_USER_GUIDE,
    showUserGuide: show
});

const updateUserGuideState = (currentState) => ({
    type: UPDATE_CURRENT_STATE,
    currentState: currentState,
});

const shouldShowUserGuide = (state) => state.scratchGui.showUserGuide[SHOULD_SHOW_USER_GUIDE];
const isShowUserGuide = (state) => state.scratchGui.showUserGuide[SHOW_USER_GUIDE];
const getUserGuideCurrentState = (state) => state.scratchGui.showUserGuide[CURRENT_STATE];

export {
    reducer as default,
    initialState as userGuideInitailState,
    shouldShowUserGuide,
    isShowUserGuide,
    getUserGuideCurrentState,
    updateShouldShowUserGuide,
    updateShowUserGuide,
    updateUserGuideState
};