import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { uiType, getUIStyle } from '../../reducers/ui-style';

import blockHelpDescriptionTWIcon from './svg/block_help_description_tw.svg';
import blockHelpDescriptionCNIcon from './svg/block_help_description_cn.svg';
import blockHelpDescriptionENIcon from './svg/block_help_description_en.svg';
import blockHelpDescriptionTWIconWW from './svg/ww/block_help_description_tw.svg';
import blockHelpDescriptionCNIconWW from './svg/ww/block_help_description_cn.svg';
import blockHelpDescriptionENIconWW from './svg/ww/block_help_description_en.svg';
import { isPad } from '../../lib/platform';


class HintBlockHelpDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }
    getIcon() {
        if (this.props.getUIStyle == uiType.cn) {
            switch (this.props.locale) {
                case 'zh-tw':
                    return (<img src={blockHelpDescriptionTWIcon} />);
                case 'zh-cn':
                    return (<img src={blockHelpDescriptionCNIcon} />);
                case 'en':
                    return (<img src={blockHelpDescriptionENIcon} />);
            }
        } else {
            switch (this.props.locale) {
                case 'zh-tw':
                    return (<img src={blockHelpDescriptionTWIconWW} />);
                case 'zh-cn':
                    return (<img src={blockHelpDescriptionCNIconWW} />);
                case 'en':
                    return (<img src={blockHelpDescriptionENIconWW} />);
            }
        }
    }
    render() {
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.hintStyle, styles.hintBlockHelp)}>
                    <div className={styles.hintBlockHeader}>
                        <div className={styles.hintBlockTitle}>
                            <FormattedMessage
                                defaultMessage="How to use Block Help"
                                description="Hint Block Help title"
                                id="gui.dialog.hint.block.help.title"
                            />
                        </div>
                    </div>
                    <div className={styles.hintBlockDescription}>
                        <FormattedMessage
                            id={isPad() ? "gui.dialog.hint.block.help.how.to.use.pad" : "gui.dialog.hint.block.help.how.to.use"}
                        />
                    </div>
                    <div className={styles.hintBlockDescriptionIcon}>
                        {this.getIcon()}
                    </div>
                    <div className={styles.hintBlockDescriptionBtn} onClick={() => this.props.onClose()}>
                        <FormattedMessage
                            defaultMessage="Done"
                            description="Hint Block Help Description Done Button"
                            id="gui.dialog.hint.block.help.done"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

HintBlockHelpDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    getUIStyle: PropTypes.string

};

const mapStateToProps = state => ({
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(HintBlockHelpDialog));