import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';



class WriteScript extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'writeScript'
        ]);
    }

    writeScript() {
        var projectJson = JSON.parse(this.props.getProjectJson());
        console.log('runScript projectJson: ', projectJson);
        return projectJson;
    }

    render() {
        const {
            children
        } = this.props;
        return children(
            this.props.className,
            this.writeScript
        );
    }
}

WriteScript.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    getProjectJson: PropTypes.func,
    getLocale: PropTypes.func
};
WriteScript.defaultProps = {
    className: ''
};

const mapStateToProps = state => ({
    getProjectJson: state.scratchGui.vm.getProjectJson.bind(state.scratchGui.vm),
    getLocale: state.scratchGui.vm.getLocale.bind(state.scratchGui.vm)
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(WriteScript);