import classNames from 'classnames';
import React from 'react';
import log from '../../../lib/log.js';
import wifiStyles from './wifi-setting-dialog.css';
import { editorMsgsByAsus } from '../../../locales/editor-msgs'
import eyeImg from '../svg/eye.svg'
import eyeCloseImg from '../svg/eye_close.svg'
import store from 'store';
import { DEFAULT_LOCALE } from '../../../config/project-config';
import { FormattedMessage } from 'react-intl';

export const WifiAp = props => {
    const {
        isWifiSelected,
        isWifiExpand,
        onClickWifiAp,
        ap,
        ssid,
        index,
        password,
        wifiStatus,
        isWifiConnected,
        onClickCancel,
        onClickConfirm,
        onPasswordChange,
        switchPasswordVisiable,
        isPasswordVisiable,
        onClickInteruptWifi,
        onClickExpandWifiSetting,
        haspw,
        hideConnectButton
    } = props

    return <div className={classNames(isWifiSelected ? wifiStyles.wifiSelected : null)}
        onClick={() => onClickWifiAp()}>
        {(isWifiExpand) ?
            <ExpandAp
                ssid={ssid}
                index={index}
                password={password}
                wifiStatus={wifiStatus}
                isWifiConnected={isWifiConnected}
                isWifiSelected={isWifiSelected}
                onClickCancel={() => onClickCancel()}
                onClickConfirm={() => onClickConfirm()}
                onPasswordChange={onPasswordChange}
                switchPasswordVisiable={() => switchPasswordVisiable()}
                isPasswordVisiable={isPasswordVisiable}
                haspw={haspw}
            />
            : <ShrinkAp
                ap={ap}
                ssid={ssid}
                index={index}
                wifiStatus={wifiStatus}
                isWifiConnected={isWifiConnected}
                isWifiSelected={isWifiSelected}
                onClickInteruptWifi={() => onClickInteruptWifi()}
                onClickExpandWifiSetting={() => onClickExpandWifiSetting(index)}
                hideConnectButton={hideConnectButton}
            />
        }
    </div>
}

export const OtherAp = props => {
    const {
        isWifiExpand,
        isWifiSelected,
        onClickWifiAp,
        index,
        ssid,
        onApNameChange,
        isPasswordVisiable,
        switchPasswordVisiable,
        password,
        onPasswordChange,
        onClickCancel,
        onClickConfirm,
        onClickExpandWifiSetting,
        haspw
    } = props;
    return isWifiExpand ?
        <div className={classNames(wifiStyles.otherExpand)}>
            <div className={classNames(isWifiSelected ? wifiStyles.wifiSelected : null)}
                onClick={() => onClickWifiAp(index)}>
                <div className={classNames(wifiStyles.otherWifiTextAreaExpand)}>
                    <div className={classNames(wifiStyles.wifiName)}>
                        <FormattedMessage
                            defaultMessage="Other"
                            description="Other wifi Setting"
                            id="gui.dialog.wifi.other"
                        /></div>
                </div>
                <input
                    type="text"
                    name="apName"
                    placeholder={getText("gui.dialog.wifi.name")}
                    value={ssid}
                    style={(ssid) ? { "color": "#000" } : null}
                    maxLength="20"
                    onChange={onApNameChange}
                    className={classNames(wifiStyles.input, wifiStyles.otherInput)} />
                <div className={classNames(wifiStyles.eyesOtherPosition)} >
                    <img src={isPasswordVisiable ? eyeCloseImg : eyeImg}
                        className={classNames(wifiStyles.eyesSize)}
                        onClick={() => switchPasswordVisiable()}
                    />
                </div>
                <input
                    type={!isPasswordVisiable ? "password" : "text"}
                    name="password"
                    onPaste={() => false}
                    onDragEnter={() => false}
                    onContextMenu={() => false}
                    placeholder={getText("gui.dialog.wifi.password")}
                    value={password}
                    style={(password && !isPasswordVisiable) ? { "letterSpacing": "10px", "color": "#000" } : { "letterSpacing": "0px" }}
                    maxLength="20"
                    onChange={onPasswordChange}
                    className={classNames(wifiStyles.input, wifiStyles.otherInput, password ? wifiStyles.inputActive : null)} />
                <div className={classNames(wifiStyles.button, wifiStyles.cancelButton)}
                    onClick={() => onClickCancel()}>
                    <div className={classNames(wifiStyles.buttonTextColor, wifiStyles.buttonText)}>
                        <FormattedMessage
                            defaultMessage="Cancel"
                            description="Cancel wifi Setting"
                            id="gui.dialog.question.cancel"
                        /></div>
                </div>
                <div className={classNames(wifiStyles.button, wifiStyles.confirmButton,
                    checkApPasswordValid(password, haspw) && ssid.length > 0 ? null : wifiStyles.disableButton)}
                    onClick={() => checkApPasswordValid(password, haspw) && ssid.length > 0 ? onClickConfirm(props.index) : null}>
                    <div className={classNames(wifiStyles.whiteText, wifiStyles.buttonText)}>
                        <FormattedMessage
                            defaultMessage="Done"
                            description="Done"
                            id="gui.deviceManagerStage.page.done"
                        /></div>
                </div>
            </div>
        </div> :
        <div className={classNames(wifiStyles.wifiListShrink)}
            onClick={() => {
                onClickWifiAp(index)
                onClickExpandWifiSetting(index)
            }}>
            <div className={classNames(wifiStyles.wifiTextAreaShrink)}>
                <FormattedMessage
                    id="gui.dialog.wifi.setting.other"
                />
            </div>
        </div>
}

export const getText = (id) => {
    let language = store.get("locale", DEFAULT_LOCALE);
    return editorMsgsByAsus[language][id]
}

const checkApPasswordValid = (password, haspw) => {
    return (password && password.length >= 8) || password.length == 0 || haspw
}

const ExpandAp = (props) => {
    return <div className={classNames(wifiStyles.wifiListExpand)}>
        <div className={classNames(wifiStyles.wifiTextAreaExpand)}>
            <div className={classNames(wifiStyles.wifiName)}>{props.ssid}</div>
        </div>
        <div className={classNames(wifiStyles.eyesPosition)} >
            <img src={props.isPasswordVisiable ? eyeCloseImg : eyeImg}
                className={classNames(wifiStyles.eyesSize)}
                onClick={() => props.switchPasswordVisiable()}
            />
        </div>
        <input
            type={!props.isPasswordVisiable ? "password" : "text"}
            name="password"
            onPaste={() => false}
            onDragEnter={() => false}
            onContextMenu={() => false}
            placeholder={getText("gui.dialog.wifi.password")}
            value={props.password}
            maxLength="20"
            style={(props.password && !props.isPasswordVisiable) ? { "letterSpacing": "10px" } : { "letterSpacing": "0px" }}
            onChange={props.onPasswordChange}
            className={classNames(wifiStyles.input, props.password ? wifiStyles.inputActive : null)} />
        <div className={classNames(wifiStyles.button, wifiStyles.cancelButton)}
            onClick={() => props.onClickCancel()}>
            <div className={classNames(wifiStyles.buttonTextColor, wifiStyles.buttonText)}>
                <FormattedMessage
                    defaultMessage="Cancel"
                    description="Cancel wifi Setting"
                    id="gui.dialog.question.cancel"
                /></div>
        </div>
        <div className={classNames(wifiStyles.button, wifiStyles.confirmButton,
            checkApPasswordValid(props.password, props.haspw) ? null : wifiStyles.disableButton)}
            onClick={() => checkApPasswordValid(props.password, props.haspw) ? props.onClickConfirm(props.index) : null}>
            <div className={classNames(wifiStyles.whiteText, wifiStyles.buttonText)}>
                <FormattedMessage
                    defaultMessage="Done"
                    description="Done"
                    id="gui.deviceManagerStage.page.done"
                /></div>
        </div>
    </div>
}

const ShrinkAp = (props) => {
    const showState = (props.wifiStatus && (props.wifiStatus.conn_ssid == props.ssid) ||
        (props.wifiStatus.selected_ap == props.ssid) ||
        (props.wifiStatus.current_ap == props.ssid))
    return <div className={classNames(wifiStyles.wifiListShrink)} onClick={() => props.hideConnectButton ? props.onClickExpandWifiSetting(props.index) : {}}>
        <div className={classNames(wifiStyles.wifiTextAreaShrink)}>
            <div className={classNames(wifiStyles.wifiName, !showState ? wifiStyles.wifiNameCenter : null)}>{props.ssid}</div>
            <div className={classNames(wifiStyles.wifiSecurity)}>
                {
                    (props.ap.state && props.ap.stateString) ? props.ap.stateString : showState ? props.wifiStatus.stateString : null
                }
            </div>
        </div>
        {props.isWifiSelected ?
            (props.isWifiConnected) ?
                <div className={classNames(wifiStyles.button, wifiStyles.interuptWifiButton)}
                    onClick={() => props.onClickInteruptWifi()}>
                    <div className={classNames(wifiStyles.whiteText, wifiStyles.buttonText)}>
                        <FormattedMessage
                            defaultMessage="Interupt"
                            description="Interupt wifi connection"
                            id="gui.dialog.wifi.interupt"
                        />
                    </div>
                </div>
                : props.hideConnectButton ? null : <div className={classNames(wifiStyles.button, wifiStyles.connectWifiButton)}
                    onClick={() => props.onClickExpandWifiSetting(props.index)}>
                    <div className={classNames(wifiStyles.buttonTextColor, wifiStyles.buttonText)}>
                        <FormattedMessage
                            defaultMessage="Connect"
                            description="Connect wifi connection"
                            id="gui.dialog.wifi.connect"
                        />
                    </div>
                </div>
            : null}
    </div>
}


