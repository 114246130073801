const sensingBlocks = (function () {
    const sensingResetTimer =
        `<block type="sensing_resettimer"/>`;

    const sensingTimerinSeconds =
        `<block type="sensing_timerinseconds"/>`;

    const sensingCursorRow =
        `<block type="sensing_cursorrow"/>`;

    const sensingBrainButtonPressed =
        `<block type="sensing_brainbuttonpressed"/>`;

    // Controller
    const sensingControllerButtonPressed =
        `<block type="sensing_controllerbuttonpressed"/>`;

    const sensingControllerPosition =
        `<block type="sensing_controllerposition"/>`;

    // Motor
    const sensingMotorisDone =
        `<block type="sensing_motorisdone"/>`;

    const sensingMotorisSpinning =
        `<block type="sensing_motorisspinning"/>`;

    const sensingMotorPosition =
        `<block type="sensing_motorposition"/>`;

    const sensingMotorVelocity =
        `<block type="sensing_motorvelocity"/>`;

    const sensingMotorCurrent =
        `<block type="sensing_motorcurrent"/>`;

    // Drivetrain
    const sensingDrivetrainDriveisDone =
        `<block type="sensing_drivetraindriveisdone"/>`;

    const sensingDrivetrainDriveisMoving =
        `<block type="sensing_drivetraindriveismoving"/>`;

    const sensingDrivetrainDriveHeading =
        `<block type="sensing_drivetraindriveheading"/>`;

    const sensingDrivetrainDriveRotation =
        `<block type="sensing_drivetraindriverotation"/>`;

    const sensingDrivetrainDriveVelocityin =
        `<block type="sensing_drivetraindrivevelocityin"/>`;

    const sensingDrivetrainDriveCurrentin =
        `<block type="sensing_drivetraindrivecurrentin"/>`;

    // Bumper
    const sensingBumperPressed =
        `<block type="sensing_bumperpressed"/>`;

    const sensingBumperPressedVR =
        `<block type="sensing_bumperpressed_vr"/>`;

    // TouchLED
    const sensingTouchLEDPressed =
        `<block type="sensing_touchledpressed"/>`;

    // Gyro
    const sensingGyroCalibrateforSeconds =
        `<block type="sensing_gyrocalibrateforseconds"/>`;

    const sensingGyroSetHeadingtoDegree =
        `<block type="sensing_gyrosetheadingtodegree">
            <value name="DEGREE">
                <shadow type="degree_circle">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>`;

    const sensingGyroSetRotationtoDegree =
        `<block type="sensing_gyrosetrotationtodegree">
            <value name="DEGREE">
                <shadow type="degree_circle_rotation">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>`;

    const sensingGyroHeadinginDegree =
        `<block type="sensing_gyroheadingindegree"/>`;

    const sensingGyroRotationinDegree =
        `<block type="sensing_gyrorotationindegree"/>`;

    // Line tracker
    const sensingLineTrackerReflectivityin =
        `<block type="sensing_linetrackerreflectivityin"/>`;

    const sensingLineTrackerReflectivityinVR =
        `<block type="sensing_linetrackerreflectivityin_vr"/>`;

    // Ultrasonic
    const sensingSonarfoundObject =
        `<block type="sensing_sonarfoundobject"/>`;

    const sensingSonarDistancein =
        `<block type="sensing_sonardistancein"/>`;

    const sensingSonarfoundObjectVR =
        `<block type="sensing_sonarfoundobject_vr"/>`;

    const sensingSonarDistanceinVR =
        `<block type="sensing_sonardistancein_vr"/>`;

    // Color
    const sensingColorisNearObject =
        `<block type="sensing_colorisnearobject"/>`;

    const sensingColorDetects =
        `<block type="sensing_colordetects"/>`;

    const sensingColorName =
        `<block type="sensing_colorname"/>`;

    const sensingColorHueinDegrees =
        `<block type="sensing_colorhueindegrees"/>`;

    const sensingColorBrightnessin =
        `<block type="sensing_colorbrightnessin"/>`;

    // Vision
    const sensingVisionTakeaSnapshot =
        `<block type="sensing_visiontakeasnapshot"/>`;

    const sensingVisionQRCodeinThePicture =
        `<block type="sensing_visionqrcodeinthepicture"/>`;

    const sensingVisionQRCodeMessage =
        `<block type="sensing_visionqrcodemessage"/>`;

    const sensingVisionObjectExistsinThePicture =
        `<block type="sensing_visionobjectexistsinthepicture"/>`;

    const sensingVisionDetectedObjectCount =
        `<block type="sensing_visiondetectedobjectcount"/>`;

    const sensingVisionOfDetectedObject =
        `<block type="sensing_visionofdetectedobject">
            <value name="OBJECT">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>`;

    const sensingDetectingFace =
        `<block type="sensing_detectingface"/>`;

    const sensingFaceDetected =
        `<block type="sensing_facedetected"/>`;

    const sensingVisionWhoseFaceInThePicture =
        `<block type="sensing_visionWhoseFaceInThePicture"/>`;

    const sensingVisionInThePicture =
        `<block type="sensing_visionInThePicture"/>`;

    const sensingVisionProbabilityOfInThePicture =
        `<block type="sensing_visionProbabilityOfInThePicture"/>`;

    const sensingVisionOCRResult =
        `<block type="sensing_visionOCRResult"/>`;

    // RFID
    const sensingRFIDDetectedData =
        `<block type="sensing_rfiddetecteddata"/>`;

    return {
        sensing_resettimer: sensingResetTimer,
        sensing_timerinseconds: sensingTimerinSeconds,
        sensing_cursorrow: sensingCursorRow,
        sensing_brainbuttonpressed: sensingBrainButtonPressed,
        // Controller
        sensing_controllerbuttonpressed: sensingControllerButtonPressed,
        sensing_controllerposition: sensingControllerPosition,
        // Motor
        sensing_motorisdone: sensingMotorisDone,
        sensing_motorisspinning: sensingMotorisSpinning,
        sensing_motorposition: sensingMotorPosition,
        sensing_motorvelocity: sensingMotorVelocity,
        sensing_motorcurrent: sensingMotorCurrent,
        // Drivetrain
        sensing_drivetraindriveisdone: sensingDrivetrainDriveisDone,
        sensing_drivetraindriveismoving: sensingDrivetrainDriveisMoving,
        sensing_drivetraindriveheading: sensingDrivetrainDriveHeading,
        sensing_drivetraindriverotation: sensingDrivetrainDriveRotation,
        sensing_drivetraindrivevelocityin: sensingDrivetrainDriveVelocityin,
        sensing_drivetraindrivecurrentin: sensingDrivetrainDriveCurrentin,
        // Bumper
        sensing_bumperpressed: sensingBumperPressed,
        sensing_bumperpressed_vr: sensingBumperPressedVR,
        // TouchLED
        sensing_touchledpressed: sensingTouchLEDPressed,
        // Gyro
        sensing_gyrocalibrateforseconds: sensingGyroCalibrateforSeconds,
        sensing_gyrosetheadingtodegree: sensingGyroSetHeadingtoDegree,
        sensing_gyrosetrotationtodegree: sensingGyroSetRotationtoDegree,
        sensing_gyroheadingindegree: sensingGyroHeadinginDegree,
        sensing_gyrorotationindegree: sensingGyroRotationinDegree,
        // Line tracker
        sensing_linetrackerreflectivityin: sensingLineTrackerReflectivityin,
        sensing_linetrackerreflectivityin_vr: sensingLineTrackerReflectivityinVR,
        // Ultrasonic
        sensing_sonarfoundobject: sensingSonarfoundObject,
        sensing_sonardistancein: sensingSonarDistancein,
        sensing_sonarfoundobject_vr: sensingSonarfoundObjectVR,
        sensing_sonardistancein_vr: sensingSonarDistanceinVR,
        // Color
        sensing_colorisnearobject: sensingColorisNearObject,
        sensing_colordetects: sensingColorDetects,
        sensing_colorname: sensingColorName,
        sensing_colorhueindegrees: sensingColorHueinDegrees,
        sensing_colorbrightnessin: sensingColorBrightnessin,
        // Vision
        sensing_visiontakeasnapshot: sensingVisionTakeaSnapshot,
        sensing_visionqrcodeinthepicture: sensingVisionQRCodeinThePicture,
        sensing_visionqrcodemessage: sensingVisionQRCodeMessage,
        sensing_visionobjectexistsinthepicture: sensingVisionObjectExistsinThePicture,
        sensing_visiondetectedobjectcount: sensingVisionDetectedObjectCount,
        sensing_visionofdetectedobject: sensingVisionOfDetectedObject,
        sensing_detectingface: sensingDetectingFace,
        sensing_facedetected: sensingFaceDetected,
        sensing_visionWhoseFaceInThePicture: sensingVisionWhoseFaceInThePicture,
        sensing_visionInThePicture: sensingVisionInThePicture,
        sensing_visionProbabilityOfInThePicture: sensingVisionProbabilityOfInThePicture,
        sensing_visionOCRResult: sensingVisionOCRResult,
        // RFID
        sensing_rfiddetecteddata: sensingRFIDDetectedData
    };
})();

export default sensingBlocks;


