import log from '../lib/log.js';

const SET_TEST_VERSION = 'test-version/SET_TEST_VERSION';

const ENABLE_TEST = 'enable_test';

const initialState = {
    [ENABLE_TEST]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_TEST_VERSION:
            console.log("SET_TEST_VERSION");
            return Object.assign({}, state, {
                [ENABLE_TEST]: action.enable
            });
        default:
            return state;
    }
};

const setTestVersion = (enable) => ({
    type: SET_TEST_VERSION,
    enable: enable
});

const getTestVersion = (state) => state.scratchGui.testVersion[ENABLE_TEST];

export {
    reducer as default,
    initialState as testVersionInitialState,
    setTestVersion,
    getTestVersion,
};
