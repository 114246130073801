import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import { hidePadFileSaveDialog, isPadFileSaveDialogShow } from '../../reducers/dialog.js'
import { getPickedBrainType } from '../../reducers/picked-brain-type.js'
import { projectTitle, setProjectTitle } from '../../reducers/project-title';
import {
    Catagory,
    FileAction,
    postMessage
} from '../../lib/postmessage';
import VM from 'scratch-vm';
import LocalKey from '../../lib/local-storage-key';
import store from 'store';

import { BRAIN_TYPE } from '../../lib/brains';
class PadFileSaveDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            tempName: this.props.projectTitle
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.projectTitle != this.props.projectTitle) {
            this.setState({ tempName: this.props.projectTitle })
        }
    }
    handleClickCancel() {
        this.setState({ tempName: this.props.projectTitle })
        store.set(LocalKey.nowSavePath, store.get(LocalKey.nowPreSavePath));
        this.props.close();
    }
    handleClickSave() {
        this.props.setProjectTitle(this.state.tempName);
        let ext = ".abot";
        if (this.props.brainType == BRAIN_TYPE.ENTRY) {
            ext = ".bbot";
        } else if (this.props.brainType == BRAIN_TYPE.EDU_AND_ENTRY) {
            ext = ".cbot";
        }
        let filename = this.state.tempName + ext;
        this.props.getFileDataForPad().then((data) => {
            let reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function () {
                let base64data = reader.result.split(",")[1];
                postMessage(Catagory.File, { action: FileAction.wirte, name: filename, data: base64data });
            }
        })
        this.props.vm.setProjectSaved();
        this.props.close();
    }
    onNameChange(event) {
        this.setState({
            tempName: event.target.value
        })
    }


    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div className={classNames(styles.backdropStyle)}>
                <div className={classNames(styles.modalStyle, styles.savefile)}>
                    <div className={styles.aboutHeader}>
                        <div className={styles.updateTitle}>
                            <FormattedMessage
                                defaultMessage="Save As"
                                description="Save As"
                                id="gui.menuBar.saveAs"
                            />
                        </div>
                    </div>
                    <div className={classNames(styles.inputNameArea)}>
                        <input type="text"
                            name="device_name"
                            value={this.state.tempName}
                            maxLength="20"
                            onChange={e => this.onNameChange(e)}
                            className={classNames(styles.inputName)}
                        />
                    </div>
                    <div className={styles.questionFooter}>
                        <div className={styles.questionWhiteBtn} onClick={() => this.handleClickCancel()}>
                            <FormattedMessage
                                defaultMessage="QuestionCancelButton"
                                description="question dialog cancel button"
                                id="gui.dialog.question.cancel"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={() => this.handleClickSave()}>
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="question dialog confirm button"
                                id="gui.hint.deleteBlocks.confirm"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PadFileSaveDialog.propTypes = {
    getFileDataForPad: PropTypes.func.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
};

PadFileSaveDialog.defaultProps = {
}

const mapStateToProps = state => ({
    show: isPadFileSaveDialogShow(state),
    brainType: getPickedBrainType(state),
    projectTitle: projectTitle(state)
});

const mapDispatchToProps = dispatch => ({
    close: () => dispatch(hidePadFileSaveDialog()),
    setProjectTitle: (title) => dispatch(setProjectTitle(title))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PadFileSaveDialog);