import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import VM from 'scratch-vm';
import store from 'store';

import {
    openAddPage,
    isPortOpen,
    getPortNumber,
} from '../../../reducers/device-manager-controller';

import {
    hoverDevice,
    cleanPortOption,
} from '../../../reducers/select-option';

import { uiType, getUIStyle } from '../../../reducers/ui-style';

import {
    isDeleteDeviceMode,
} from '../../../reducers/delete-device';

import {
    showDeviceHelpDialog,
    isDeviceHelpDialogShow
} from '../../../reducers/dialog';
import {
    getPlatform
} from '../../../lib/platform'
import bumper from '../pictures/device_bumper_icon.svg';
import led from '../pictures/device_led_icon.svg';
import motor from '../pictures/device_motor_icon.svg';
import lineTracker from '../pictures/device_linetracker_icon.svg';
import buzzer from '../pictures/device_buzzer_icon.svg';
import ultrasonic from '../pictures/device_ultrasonic_icon.png';
import drivetrain from '../pictures/device_drivetrain_icon.svg';
import help from '../pictures/help.svg';


import bumperWW from '../pictures/ww/device_bumper_icon.svg';
import ledWW from '../pictures/ww/device_led_icon.svg';
import motorWW from '../pictures/ww/device_motor_icon.svg';
import lineTrackerWW from '../pictures/ww/device_linetracker_icon.svg';
import buzzerWW from '../pictures/ww/device_buzzer_icon.svg';
import ultrasonicWW from '../pictures/ww/device_ultrasonic_icon.png';
import drivetrainWW from '../pictures/ww/device_drivetrain_icon.svg';
import helpWW from '../pictures/ww/help.svg';

import styles from './device-list.css';
import classNames from 'classnames';

import { DeviceItem } from './device-list-component.jsx'

const messages = defineMessages({
    selectDeviceTitle: {
        defaultMessage: "Select device",
        description: "Select device title",
        id: "gui.deviceList.selectDeviceTitle"
    },
    bumper: {
        defaultMessage: "Bumper Sensor",
        description: "Bumper Sensor",
        id: "gui.device.bumper"
    },
    led: {
        defaultMessage: "LED Light",
        description: "LED Light",
        id: "gui.device.led"
    },
    motor: {
        defaultMessage: "Motor",
        description: "motor",
        id: "gui.device.motor"
    },
    threeWireMotor: {
        defaultMessage: "Motor",
        description: "threeWireMotor",
        id: "gui.device.threeWireMotor"
    },
    lineTracker: {
        defaultMessage: "Tracker sensor",
        description: "Tracker sensor",
        id: "gui.device.lineTracker"
    },
    buzzer: {
        defaultMessage: "Buzzer",
        description: "buzzer",
        id: "gui.device.buzzer"
    },
    ultrasonic: {
        defaultMessage: "Ultrasonic",
        description: "ultrasonic",
        id: "gui.device.ultrasonic"
    },
    drivetrain: {
        defaultMessage: "Power Train",
        description: "drivetrain",
        id: "gui.device.drivetrain"
    }
})

const imageTypes = {
    motor: motor,
    threeWireMotor: motor,
    lineTracker: lineTracker,
    buzzer: buzzer,
    led: led,
    ultrasonic: ultrasonic,
    bumper: bumper,
    drivetrain: drivetrain,
    motorWW: motorWW,
    threeWireMotorWW: motorWW,
    lineTrackerWW: lineTrackerWW,
    buzzerWW: buzzerWW,
    ledWW: ledWW,
    ultrasonicWW: ultrasonicWW,
    bumperWW: bumperWW,
    drivetrainWW: drivetrainWW
}

const supportTypes = {
    motor: 'motor',
    threeWireMotor: 'threeWireMotor',
    lineTracker: 'lineTracker',
    buzzer: 'buzzer',
    led: 'led',
    ultrasonic: 'ultrasonic',
    bumper: 'bumper',
    drivetrain: 'drivetrain',
}

const DEVICE_LIST = "EduDeviceList"

class EduDeviceList extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            selectedRadio: null,
            skipedDeviceHint: store.get('skipedDeviceHint'),
            hoverDevice: ''
        }
    }

    skipHint() {
        if (!this.state.skipedDeviceHint) {
            this.setState({ skipedDeviceHint: true });
            store.set('skipedDeviceHint', true);
        }
    }

    handleClickDevice(deviceType) {
        if (this.isDeviceEnable(deviceType)) {
            this.skipHint();
            this.props.openAddPage(this.props.portNumber, deviceType)
            this.props.cleanPortOption()
        }
    }

    handleClickHelp() {
        this.skipHint();
        this.props.openHelpDialog();
    }

    handleHoverDevice(deviceType) {
        if (this.isDeviceEnable(deviceType)) {
            let request = this.props.vm.getRequestPort(deviceType);
            let availablePort = this.props.vm.getAvailablePortAmount();
            let hintArray = [];
            if (request && request.adc > 0) hintArray = hintArray.concat(availablePort.adc);
            if (request && request.pwm > 0) hintArray = hintArray.concat(availablePort.pwm);
            this.props.hoverDevice(hintArray);
            this.setState({ hoverDevice: deviceType })
        }
    }

    handleHoverOff() {
        if (!this.props.isDeleteDeviceMode) {
            this.props.cleanPortOption();
            this.setState({ hoverDevice: '' })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.portNumber !== prevProps.portNumber || this.props.isDeleteDeviceMode !== prevProps.isDeleteDeviceMode) {
            this.setState({ selectedRadio: null })
        }
    }

    isDeviceEnable(deviceType) {
        return this.props.vm.isDeviceAvailableToAdd(deviceType) && this.props.vm.isPortEnoughForDeviceToAdd(deviceType) && !this.props.isDeleteDeviceMode
    }

    getDeviceItem(deviceType) {
        return (
            <DeviceItem
                handleClickDevice={() => this.handleClickDevice(deviceType)}
                handleHoverDevice={() => this.handleHoverDevice(deviceType)}
                handleHoverOff={() => this.handleHoverOff()}
                isDeviceEnable={this.isDeviceEnable(deviceType)}
                isHover={this.state.hoverDevice == deviceType}
                image={this.props.getUIStyle == uiType.ww ? imageTypes[deviceType + 'WW'] : imageTypes[deviceType]}
                type={deviceType}
                deviceList={DEVICE_LIST}
                isChecked={this.state.selectedRadio === deviceType}
                messages={messages}
            />
        )
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div className={classNames(styles.content)}>
                <div className={classNames(styles.selectDeviceTitle)}>
                    <FormattedMessage
                        {...messages.selectDeviceTitle}
                    />
                    <img className={classNames(styles.help,
                        (this.state.skipedDeviceHint) ? null : styles.breatheHelp)}
                        src={this.props.getUIStyle == uiType.ww ? helpWW : help}
                        onClick={() => { this.handleClickHelp() }}
                        alt={"help icon"} />
                </div>
                <div className={classNames(styles.tableArea)}>
                    <table className={classNames(styles.tableContent)}>
                        <tbody>
                            <tr>
                                {this.getDeviceItem(supportTypes.drivetrain)}
                                {this.getDeviceItem(supportTypes.threeWireMotor)}
                                {this.getDeviceItem(supportTypes.bumper)}
                                {this.getDeviceItem(supportTypes.lineTracker)}
                            </tr>
                            <tr>
                                {this.getDeviceItem(supportTypes.ultrasonic)}
                                {this.getDeviceItem(supportTypes.buzzer)}
                                {this.getDeviceItem(supportTypes.led)}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}

EduDeviceList.propTypes = {
    init: PropTypes.bool,
    show: PropTypes.bool,
    portNumber: PropTypes.string,
    hoverDevice: PropTypes.func,
    cleanPortOption: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    show: isPortOpen(state),
    portNumber: getPortNumber(state),
    isDeleteDeviceMode: isDeleteDeviceMode(state),
    isDeviceHelpDialogShow: isDeviceHelpDialogShow(state),
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
    openAddPage: (portNum, deviceType) => {
        dispatch(openAddPage(portNum, deviceType))
    },
    hoverDevice: (portArray) => { dispatch(hoverDevice(portArray)) },
    cleanPortOption: () => dispatch(cleanPortOption()),
    openHelpDialog: () => dispatch(showDeviceHelpDialog(getPlatform()))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EduDeviceList);