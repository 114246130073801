import React from 'react';
import classNames from 'classnames';

import styles from './edit-page.css';
import {
    EditUtils,
    STRING_ID,
    STATE_INDEX,
    PATH_TYPE,
    SUPPORT_TYPES,
    ROTATION,
    OPTION_TYPE,
    HINT_WHEELBASE,
    UNIT_OF_LENGTH,
    DEFAULT_CALL_WORD,
    mappingLanguage
} from './edit-utils.js';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import reverseImg from '../pictures/motor_reverse.svg';
import forwardImg from '../pictures/motor_forward.svg';
import reverseWWImg from '../pictures/ww/motor_reverse.svg';
import forwardWWImg from '../pictures/ww/motor_forward.svg';
import plusImg from '../pictures/plus.svg';
import minusImg from '../pictures/minus.svg';
import switchImg from '../pictures/switch_btn.svg';
import drivetrainImg from '../pictures/device_drivetrain_icon.svg';
import pathFrontImg from '../pictures/path-up.svg';
import pathBackImg from '../pictures/path-down.svg';
import wheelbaseLRImg from '../pictures/wheelbase_left_right.svg';
import wheelbaseFBImg from '../pictures/wheelbase_front_back.svg';

import alarmIcon from '../pictures/small_icon_alarm.svg';
import calculatorIcon from '../pictures/small_icon_calculator.svg';
import converterIcon from '../pictures/small_icon_converter.svg';
import jokeIcon from '../pictures/small_icon_joke.svg';
import timeIcon from '../pictures/small_icon_time.svg';
import translatorIcon from '../pictures/small_icon_translator.svg';
import weatherIcon from '../pictures/small_icon_weather.svg';
import writeIcon from '../pictures/small_icon_write.svg';
import commonIcon from '../../dialog/svg/skill/skill-common-icon.svg'
import infoIcon from '../pictures/driver_info.svg';
import infoHoverIcon from '../pictures/driver_info_hover.svg';

const ICON_SIZE = {
    small: 'small',
    normal: 'normal'
}

const SKILL = {
    common: "common",
    weather: "weather",
    alarm: "alarm",
    calculator_converter: "calculator_converter",
    translator: "translator",
    joke: "joke",
    write: "write",
    time: "time",
}

export const CALL_WORD_RULE = {
    VALID: 0,
    EMPTY: 1,
    SPECIAL_SYMBOL: 2
}

export const Motor = (props) => {
    const reverse = props.isWWVersion ? reverseWWImg : reverseImg;
    const forward = props.isWWVersion ? forwardWWImg : forwardImg;

    const leftImg = props.rotation == ROTATION.forward ? forward : reverse;
    const rightImg = props.rotation == ROTATION.reverse ? forward : reverse;
    return (
        <div className={classNames(styles.motorRotationArea, styles.motorRotationAreaDiff)}>
            <div className={classNames(styles.motorText)}>
                {EditUtils.getLocaleString(STRING_ID.motorText)}</div>
            {/* foward */}
            <div className={classNames(styles.motorPlusPosition)}><img src={plusImg} alt={"motor plus position"} /></div>
            <div className={classNames(styles.motorForwardImg)}><img src={leftImg} alt={"motor forward image"} /></div>
            <div className={classNames(styles.motorForwardNameArea)}>
                {props.getEditMotorComponent(STATE_INDEX.isEditForwardName, STATE_INDEX.tempForwardName)}
            </div>
            {/* switch */}
            <div className={classNames(styles.motorSwitchArea)} onClick={() => props.handleClickMotorSwitch()}>
                <div className={classNames(styles.switchImg)}><img src={switchImg} alt={"switch image"} /></div>
            </div>
            {/* reverse */}
            <div className={classNames(styles.motorMinusPosition)}><img src={minusImg} alt={"motor minus position"} /></div>
            <div className={classNames(styles.motorReverseImg)}><img src={rightImg} alt={"motor reverse image"} /></div>
            <div className={classNames(styles.motorReverseNameArea)}>
                {props.getEditMotorComponent(STATE_INDEX.isEditReverseName, STATE_INDEX.tempReverseName)}
            </div>
        </div>
    )
}

export const SelectOption = (props) => {
    return (
        <div className={classNames(props.styleSelectPosition, props.styleSelect, (props.enable) ? null : styles.disableSelect)}
            onClick={() => { (props.enable) ? props.handleClickSelect() : null }}>
            <div className={classNames(styles.arrow, props.styleArrowPosition, (props.enable) ? null : styles.disableSelect)} />
            {props.imgPortType ? props.imgPortType : null}
            <div className={classNames(props.styleSeletedText, !props.isPortSelected ? styles.defaultPortColor : null)}>
                {props.selectedPort}
            </div>
            {props.isSelectExpand ?
                <div className={classNames(props.styleOptionArea)}>
                    {props.optionList}
                </div> : null}
        </div>
    )
}

export const EntryDriverTrain = (props) => {
    return (
        <div className={classNames(!props.state.isCheckedGyro ? styles.entryDrivetrainAreaExpand : styles.entryDrivetrainArea)} >
            <div>
                <SelectOption
                    styleSelectPosition={styles.threeSelectPosition1}
                    styleArrowPosition={styles.threePortArrowPosition}
                    imgPortType={props.getSelectImage(props.availableListArray[0].type, ICON_SIZE.small)}
                    styleSelect={styles.threePortSelect}
                    styleSeletedText={styles.portText}
                    handleClickSelect={() => { props.handleClickSelect(OPTION_TYPE.port1) }}
                    isPortSelected={!!props.state.smart1}
                    selectedPort={props.getSelectedPort(OPTION_TYPE.port1)}
                    isSelectExpand={props.isSelectExpand(OPTION_TYPE.port1)}
                    styleOptionArea={styles.threePortOptionArea}
                    optionList={props.availableListArray[0].list}
                    enable={!!props.availableListArray[0].list}
                />
                <div className={classNames(styles.threeSelectSwitch, (props.state.smart1 && props.state.smart2) ? null : styles.disableSwitch)}
                    onClick={() => (props.state.smart1 && props.state.smart2) ? props.handleClickDrivertrainSwitch() : {}}>
                    <img className={classNames(styles.threeSelectSwitchImg)} src={switchImg} alt={"switch"} />
                </div>
                <SelectOption
                    styleSelectPosition={styles.threeSelectPosition2}
                    styleArrowPosition={styles.threePortArrowPosition}
                    imgPortType={props.getSelectImage(props.availableListArray[1].type, ICON_SIZE.small)}
                    styleSelect={styles.threePortSelect}
                    styleSeletedText={styles.portText}
                    handleClickSelect={() => { props.handleClickSelect(OPTION_TYPE.port2) }}
                    isPortSelected={!!props.state.smart2}
                    selectedPort={props.getSelectedPort(OPTION_TYPE.port2)}
                    isSelectExpand={props.isSelectExpand(OPTION_TYPE.port2)}
                    styleOptionArea={styles.threePortOptionArea}
                    optionList={props.availableListArray[1].list}
                    enable={!!props.availableListArray[1].list}
                />
                <div className={classNames(styles.selectGryoText)}>{EditUtils.getLocaleString(STRING_ID.selectDrivetrainGryo)}</div>
                <div className={classNames(styles.gryoCheckboxPosition, (props.state.isCheckedGyro) ? styles.gryoCheckboxChecked : styles.gryoCheckbox)}
                    onClick={() => { props.handleCheckGyro() }}
                />
                <SelectOption
                    styleSelectPosition={styles.threeSelectPosition3}
                    styleArrowPosition={styles.threePortArrowPosition}
                    imgPortType={props.getSelectImage(props.availableListArray[2].type, ICON_SIZE.small)}
                    styleSelect={styles.gryoPortSelect}
                    styleSeletedText={styles.portText}
                    handleClickSelect={() => { props.handleClickSelect(OPTION_TYPE.port3) }}
                    isPortSelected={!!props.state.smart3}
                    selectedPort={props.getSelectedPort(OPTION_TYPE.port3)}
                    isSelectExpand={props.isSelectExpand(OPTION_TYPE.port3)}
                    styleOptionArea={styles.threePortOptionArea}
                    optionList={props.availableListArray[2].list}
                    enable={!!props.availableListArray[2].list && props.availableListArray[2].list.length >= 3 && (props.state.isCheckedGyro)}
                />
            </div>
            {/* Unit Setting */}
            <div className={classNames(styles.entryDrivetrainUnitSettingArea)}>
                <div className={classNames(styles.drivertrainSettingTitle)}>
                    {EditUtils.getLocaleString(STRING_ID.drivertrainSetting)}</div>
                <div className={classNames(styles.drivertrainUnit, styles.drivertrainUnitMM, (props.state.wheelUnit == UNIT_OF_LENGTH.cm) ? styles.drivertrainUnitChecked : null)}
                    onClick={() => props.handleCheckUnit(UNIT_OF_LENGTH.cm)}>
                    {EditUtils.getLocaleString(STRING_ID.unitOfMM)}</div>
                <div className={classNames(styles.drivertrainUnit, styles.drivertrainUnitInch, (props.state.wheelUnit == UNIT_OF_LENGTH.inches) ? styles.drivertrainUnitChecked : null)}
                    onClick={() => props.handleCheckUnit(UNIT_OF_LENGTH.inches)}>
                    {EditUtils.getLocaleString(STRING_ID.unitOfInch)}</div>
            </div>
            {/* Wheel size & Gear ratio Setting */}
            <div className={classNames(styles.entryDrivetrainBasicSettingArea)}>
                <div className={classNames(styles.drivertrainWheelSizeSettingArea)}>
                    <div className={classNames(styles.drivertrainWheelSettingTitle)}>
                        {EditUtils.getLocaleString(STRING_ID.editWheelSize)}
                    </div>
                    <input type="text"
                        name="wheel_size"
                        value={props.state.wheelSize}
                        maxLength="6"
                        onChange={e => props.onNameChange(e, STATE_INDEX.wheelSize)}
                        className={classNames(styles.drivertrainWheelInput)} />
                    <div className={classNames(styles.drivertrainWheelUnit)}>
                        {EditUtils.getLocaleString(props.state.wheelUnit == UNIT_OF_LENGTH.cm ? STRING_ID.unitOfMM : STRING_ID.unitOfInch)}
                    </div>
                </div>

                <div className={classNames(styles.drivertainDivide)} />

                <div className={classNames(styles.drivertrainGearRatioSettingArea)}>
                    <div className={classNames(styles.drivertrainGearRatioSettingTitle)}>
                        {EditUtils.getLocaleString(STRING_ID.gearRatio)}
                    </div>
                    <input type="text"
                        name="wheel_first_ratio"
                        value={props.state.firstRatioOfWheel}
                        maxLength="3"
                        onChange={e => props.onNameChange(e, STATE_INDEX.firstRatioOfWheel)}
                        className={classNames(styles.drivertainRatioInput, styles.drivertainRatioFirstPostion)} />
                    <div className={classNames(styles.drivertainRatioDivide)}>：</div>
                    <input type="text"
                        name="wheel_second_ratio"
                        value={props.state.secondRatioOfWheel}
                        maxLength="3"
                        onChange={e => props.onNameChange(e, STATE_INDEX.secondRatioOfWheel)}
                        className={classNames(styles.drivertainRatioInput, styles.drivertainRatioSecondPostion)} />
                </div>
            </div>
            {/* Wheelbase Setting */}
            {
                (!props.state.isCheckedGyro) ?
                    <div className={classNames(styles.entryDrivetrainAdvanceSettingArea)}>
                        <div className={classNames(styles.drivertrainWheelbaseSettingArea)}>
                            <div className={classNames(styles.drivertrainWheelSettingTitle)}>
                                {EditUtils.getLocaleString(STRING_ID.wheelbaseLR)}
                            </div>
                            <div className={classNames(styles.drivertrainWheelbaseHint)}
                                onMouseEnter={() => props.handleHoverHint(HINT_WHEELBASE.LeftRight)}
                                onMouseLeave={() => props.handleHoverHint('')}><img src={props.state.hoverWheelHint == HINT_WHEELBASE.LeftRight ? infoHoverIcon : infoIcon} className={classNames(styles.drivertrainWheelInfoIcon)} alt={"wheel icon"} /></div>
                            {(props.state.hoverWheelHint == HINT_WHEELBASE.LeftRight) ?
                                <div className={classNames(styles.drivertrainWheelbaseLRImgPosition)}>
                                    <img className={classNames(styles.drivertrainWheelbaseLRImgSize)}
                                        src={wheelbaseLRImg} alt={"wheel base"} />
                                </div> : null}
                            <input type="text"
                                name="wheel_left_rigth_wheelbase"
                                value={props.state.wheelbaseLR}
                                maxLength="6"
                                onChange={e => props.onNameChange(e, STATE_INDEX.wheelbaseLR)}
                                className={classNames(styles.drivertrainWheelInput)} />
                            <div className={classNames(styles.drivertrainWheelUnit)}>
                                {EditUtils.getLocaleString(props.state.wheelUnit == UNIT_OF_LENGTH.cm ? STRING_ID.unitOfMM : STRING_ID.unitOfInch)}
                            </div>
                        </div>

                        <div className={classNames(styles.drivertainDivide)} />

                        <div className={classNames(styles.drivertrainWheelbaseSettingArea)}>
                            <div className={classNames(styles.drivertrainWheelSettingTitle, styles.drivertrainWheeRightPosition)}>
                                {EditUtils.getLocaleString(STRING_ID.wheelbaseFB)}
                            </div>
                            <div className={classNames(styles.drivertrainWheelbaseHint)}
                                onMouseEnter={() => props.handleHoverHint(HINT_WHEELBASE.FrontBack)}
                                onMouseLeave={() => props.handleHoverHint('')}><img src={props.state.hoverWheelHint == HINT_WHEELBASE.FrontBack ? infoHoverIcon : infoIcon} className={classNames(styles.drivertrainWheelInfoIcon)} alt={"wheel icon"} /></div>
                            {(props.state.hoverWheelHint == HINT_WHEELBASE.FrontBack) ?
                                <div className={classNames(styles.drivertrainWheelbaseFBImgPosition)}>
                                    <img className={classNames(styles.drivertrainWheelbaseFBImgSize)}
                                        src={wheelbaseFBImg} alt={"wheel base"} />
                                </div> : null}
                            <input type="text"
                                name="wheel_front_back_wheelbase"
                                value={props.state.wheelbaseFB}
                                maxLength="6"
                                onChange={e => props.onNameChange(e, STATE_INDEX.wheelbaseFB)}
                                className={classNames(styles.drivertrainWheelInput, styles.drivertrainWheeRightPosition)} />
                            <div className={classNames(styles.drivertrainWheelUnit)}>
                                {EditUtils.getLocaleString(props.state.wheelUnit == UNIT_OF_LENGTH.cm ? STRING_ID.unitOfMM : STRING_ID.unitOfInch)}
                            </div>
                        </div>
                    </div>
                    : null
            }

            {/* Drivertrain Img Area */}
            <div className={classNames(styles.entryDrivetrainImgArea, !props.state.isCheckedGyro ? styles.entryDrivetrainImgAreaExpand : null)}>
                <div className={classNames(styles.drivetrainLeft)}>
                    {EditUtils.getLocaleString(STRING_ID.motor) + ((props.state.smart1) ? (" " + props.state.smart1) : "")}</div>
                <div className={classNames(styles.drivetrainRight)}>
                    {EditUtils.getLocaleString(STRING_ID.motor) + ((props.state.smart2) ? (" " + props.state.smart2) : "")}</div>
                <div className={classNames((props.state.path == PATH_TYPE.front) ? styles.pathUp : styles.pathUpDisable)}
                    onClick={() => props.handleClickPath(PATH_TYPE.front)}>
                    <img src={pathFrontImg} alt={"path"} />
                </div>
                <div className={classNames(styles.drivetrainPosition)}>
                    <img src={drivetrainImg} className={classNames(styles.drivetrainImg)} alt={EditUtils.getLocaleString(STRING_ID.drivetrain)} />
                </div>
                <div className={classNames((props.state.path == PATH_TYPE.back) ? styles.pathDown : styles.pathDownDisable)}
                    onClick={() => props.handleClickPath(PATH_TYPE.back)}>
                    <img src={pathBackImg} alt={"back"} />
                </div>
                <div className={classNames(styles.drivetrainLeftText)}>L</div>
                <div className={classNames(styles.drivetrainRightText)}>R</div>
            </div>
        </div >
    )
}

const FIX_METHOD = {
    none: 'none',
    left: 'left',
    right: 'right'
}

export const EduDrivertrainAdvance = (props) => {
    const {
        handleClickBack,
        state,
        handleCheckMethod,
        handleExpandOption,
        handleSelectPercentage
    } = props;
    return (
        <div>
            <div className={classNames(styles.backArrow, styles.backArrowPosition)}
                onClick={handleClickBack} />

            <div className={classNames(styles.advanceSettingTitle)}>{EditUtils.getLocaleString('gui.deviceManagerStage.edu.drivertrain.advanced')}</div>
            <div className={classNames(styles.advanceSettingDescription)}>{EditUtils.getLocaleString('gui.deviceManagerStage.edu.drivertrain.advanced.description')}</div>
            <div className={classNames(styles.firstCheckboxArea)}>
                <div className={classNames(styles.checkbox, state.fixMethod == FIX_METHOD.none ? styles.checked : null)}
                    onClick={() => handleCheckMethod(FIX_METHOD.none)} />
                <div className={classNames(styles.checkboxText)}>{EditUtils.getLocaleString("gui.deviceManagerStage.edu.drivertrain.advanced.donoting")}</div>
            </div>
            <div className={classNames(styles.secondCheckboxArea)}>
                <div className={classNames(styles.checkbox, state.fixMethod == FIX_METHOD.left ? styles.checked : null)}
                    onClick={() => handleCheckMethod(FIX_METHOD.left)} />
                <div className={classNames(styles.checkboxText)}>{EditUtils.getLocaleString("gui.deviceManagerStage.edu.drivertrain.advanced.fix.left")}</div>
                <div className={classNames(styles.percentageOptionItem, styles.percentageOptionText)}
                    onClick={() => { handleExpandOption(FIX_METHOD.left) }}>{state.fixLeftPercentage}</div>
                <div className={classNames(styles.arrow, styles.percentageArrowPosition)}
                    onClick={() => { handleExpandOption(FIX_METHOD.left) }} />
                <div className={classNames(styles.checkboxPercentage)}>％</div>
                <div className={classNames(styles.checkboxHint)}>{EditUtils.getLocaleString("gui.deviceManagerStage.edu.drivertrain.advanced.fix.left.hint")}</div>
                <PercentageOptionList
                    show={state.expandPercentage == FIX_METHOD.left}
                    selectedIndex={state.fixLeftPercentage}
                    handleSelectPercentage={handleSelectPercentage}
                    method={state.expandPercentage} />
            </div>
            <div className={classNames(styles.thirdCheckboxArea)}>
                <div className={classNames(styles.checkbox, state.fixMethod == FIX_METHOD.right ? styles.checked : null)}
                    onClick={() => handleCheckMethod(FIX_METHOD.right)} />
                <div className={classNames(styles.checkboxText)}>{EditUtils.getLocaleString("gui.deviceManagerStage.edu.drivertrain.advanced.fix.right")}</div>
                <div className={classNames(styles.percentageOptionItem, styles.percentageOptionText)}
                    onClick={() => { handleExpandOption(FIX_METHOD.right) }}>{state.fixRightPercentage}</div>
                <div className={classNames(styles.arrow, styles.percentageArrowPosition)}
                    onClick={() => { handleExpandOption(FIX_METHOD.right) }} />
                <div className={classNames(styles.checkboxPercentage)}>％</div>
                <div className={classNames(styles.checkboxHint)}>{EditUtils.getLocaleString("gui.deviceManagerStage.edu.drivertrain.advanced.fix.right.hint")}</div>
                <PercentageOptionList
                    show={state.expandPercentage == FIX_METHOD.right}
                    selectedIndex={state.fixRightPercentage}
                    handleSelectPercentage={handleSelectPercentage}
                    method={state.expandPercentage} />
            </div>
        </div>
    )
}

const PercentageOptionList = (props) => {
    const {
        show,
        selectedIndex,
        handleSelectPercentage,
        method
    } = props;
    if (!show) return null;
    return (
        <div className={classNames(styles.PercentageOptionList)}>
            <div className={classNames(styles.percentageOptionArea)}>
                <div className={classNames(styles.percentageOptionHalfArea)}>
                    <PercentageOption
                        selectedIndex={selectedIndex}
                        index={1}
                        method={method}
                        handleSelectPercentage={handleSelectPercentage} />
                    <PercentageOption
                        selectedIndex={selectedIndex}
                        index={2}
                        method={method}
                        handleSelectPercentage={handleSelectPercentage} />
                    <PercentageOption
                        selectedIndex={selectedIndex}
                        index={3}
                        method={method}
                        handleSelectPercentage={handleSelectPercentage} />
                    <PercentageOption
                        selectedIndex={selectedIndex}
                        index={4}
                        method={method}
                        handleSelectPercentage={handleSelectPercentage} />
                    <PercentageOption
                        selectedIndex={selectedIndex}
                        index={5}
                        method={method}
                        handleSelectPercentage={handleSelectPercentage} />
                </div>
                <div className={classNames(styles.percentageOptionDivide)} />
                <div className={classNames(styles.percentageOptionHalfArea)}>
                    <PercentageOption
                        selectedIndex={selectedIndex}
                        index={6}
                        method={method}
                        handleSelectPercentage={handleSelectPercentage} />
                    <PercentageOption
                        selectedIndex={selectedIndex}
                        index={7}
                        method={method}
                        handleSelectPercentage={handleSelectPercentage} />
                    <PercentageOption
                        selectedIndex={selectedIndex}
                        index={8}
                        method={method}
                        handleSelectPercentage={handleSelectPercentage} />
                    <PercentageOption
                        selectedIndex={selectedIndex}
                        index={9}
                        method={method}
                        handleSelectPercentage={handleSelectPercentage} />
                    <PercentageOption
                        selectedIndex={selectedIndex}
                        index={10}
                        method={method}
                        handleSelectPercentage={handleSelectPercentage} />

                </div>
            </div>
        </div>
    )
}

const PercentageOption = (props) => {
    const {
        selectedIndex,
        index,
        method,
        handleSelectPercentage
    } = props;
    return (
        <div className={classNames(styles.percentageOption,
            (selectedIndex == index) ? styles.percentageOptionSelected : null)}
            onClick={() => handleSelectPercentage(method, index)}>{index}</div>
    )
}

export const SmartSpeaker = (props) => {
    const {
        state,
        onMethodSwitch,
        onClickSkillSetting,
        handleExpandOption,
        handleSelectOption,
        onNameChange,
        systemLanguage,
        controlLanguageList,
        handleClickDefault,
        handleClickInput,
        isCallWordValid,
        enableSkillList
    } = props;
    return (
        <div className={classNames(styles.speakerEditArea)}>
            <div className={classNames(styles.speakerTitle)}>
                <FormattedMessage id={"gui.deviceManagerStage.speaker.basic"} />
            </div>
            <div className={classNames(styles.speakerBasicArea, (state.controlLanguage == mappingLanguage.zh) ? (systemLanguage != mappingLanguage.English) ? styles.speakerBasicZhArea : styles.speakerBasicZhAreaEN : styles.speakerBasicENArea,
                (state.focusInput == STATE_INDEX.callWord) ? (systemLanguage != mappingLanguage.English) ? styles.speakerBasicAreaExpanded : styles.speakerBasicAreaExpandedEN : null)}>
                <div className={classNames((state.controlLanguage == mappingLanguage.en) ? styles.speakerCallwordRowArea : (systemLanguage != mappingLanguage.English) ? styles.speakerCallwordRowArea : styles.speakerCallwordRowAreaEN)}>
                    {(state.controlLanguage == mappingLanguage.en) ?
                        <div >
                            <div className={classNames(styles.speakerText)}>
                                <FormattedMessage id={"gui.deviceManagerStage.speaker.callWord.en"} />
                            </div>
                            <div className={classNames(styles.speakerReminderCallwordText)}>
                                <FormattedMessage id={"gui.deviceManagerStage.speaker.callWord.reminder.en"} />
                            </div>
                        </div> :
                        <div className={classNames((systemLanguage != mappingLanguage.English) ? null : styles.speakerCallwordTextAreaEN)}>
                            <div className={classNames(styles.speakerText)}>
                                <FormattedMessage id={"gui.deviceManagerStage.speaker.callWord"} />
                            </div>
                            <div className={classNames((systemLanguage != mappingLanguage.English) ? styles.speakerReminderCallwordText : styles.speakerReminderCallwordTextEN)}>
                                <FormattedMessage id={"gui.deviceManagerStage.speaker.callWord.reminder"} />
                            </div>

                        </div>}
                    {(state.controlLanguage == mappingLanguage.en) ?
                        <div className={classNames(styles.inputSpeakerArea)}
                            style={{ boxShadow: "none", border: "solid 1px #12aec2" }}>
                            <div
                                className={classNames(styles.inputSpeaker)}
                                style={{ cursor: "default" }} >
                                {DEFAULT_CALL_WORD.en}
                            </div>
                        </div>
                        : <div className={classNames(styles.inputSpeakerArea,
                            (isCallWordValid != CALL_WORD_RULE.VALID) ? styles.inputNameErrorBorder : null,
                            (state.focusInput == STATE_INDEX.callWord && isCallWordValid == CALL_WORD_RULE.VALID) ? styles.inputSpeakerAreaFocus : null)}>
                            <div>
                                <input
                                    type="text"
                                    name="call_name"
                                    value={state.callWord}
                                    maxLength="20"
                                    onChange={e => onNameChange(e, STATE_INDEX.callWord)}
                                    className={classNames(styles.inputSpeaker, (state.focusInput == STATE_INDEX.callWord) ? styles.inputSpeakerFocus : null)}
                                    onClick={() => handleClickInput(STATE_INDEX.callWord)}
                                />
                                <div className={classNames(styles.speakerDefault)}
                                    onClick={() => handleClickDefault(STATE_INDEX.callWord)}>
                                    <FormattedMessage id={"gui.deviceManagerStage.speaker.switch.default"} />
                                </div>
                            </div>
                        </div>
                    }
                    <SwitchButton
                        enble={state.isCallEnable}
                        onMethodSwitch={() => onMethodSwitch(STATE_INDEX.isCallEnable)}
                    />
                </div>
                {(state.focusInput == STATE_INDEX.callWord) ?
                    <div className={classNames(styles.speakerCallWordReminderArea)}>
                        <div className={classNames(styles.speakerRuleText, isCallWordValid == CALL_WORD_RULE.SPECIAL_SYMBOL ? styles.invalid : null)}>
                            <FormattedMessage id={"gui.deviceManagerStage.speaker.callWord.rule1"} />
                        </div>
                        <div className={classNames(styles.speakerRuleText)}>
                            <FormattedMessage id={"gui.deviceManagerStage.speaker.callWord.rule2"} />
                        </div>
                        <div className={classNames(styles.speakerRuleText)}>
                            <FormattedMessage id={"gui.deviceManagerStage.speaker.callWord.rule3"} />
                        </div>
                    </div>
                    : null}
                <div className={classNames(styles.speakerRowArea)}>
                    <div className={classNames(styles.speakerText)}>
                        <FormattedMessage id={"gui.deviceManagerStage.speaker.responseWord"} />
                    </div>
                    <div className={classNames(styles.speakerReminderText)}>
                        <FormattedMessage id={"gui.deviceManagerStage.speaker.responseWord.reminder"} />
                    </div>
                    <div className={classNames(styles.inputSpeakerArea, (state.focusInput == STATE_INDEX.responseWord) ? styles.inputSpeakerAreaFocus : null)}>
                        <input
                            type="text"
                            name="response"
                            value={state.responseWord}
                            maxLength="20"
                            onChange={e => onNameChange(e, STATE_INDEX.responseWord)}
                            className={classNames(styles.inputSpeaker, (state.focusInput == STATE_INDEX.responseWord) ? styles.inputSpeakerFocus : null)}
                            onClick={() => handleClickInput(STATE_INDEX.responseWord)}
                        />
                        <div className={classNames(styles.speakerDefault)}
                            onClick={() => handleClickDefault(STATE_INDEX.responseWord)}>
                            <FormattedMessage id={"gui.deviceManagerStage.speaker.switch.default"} />
                        </div>
                    </div>
                    <SwitchButton
                        enble={state.isResponseEnable}
                        onMethodSwitch={() => onMethodSwitch(STATE_INDEX.isResponseEnable)}
                    />
                    {(state.focusInput == STATE_INDEX.responseWord) ?
                        <div className={classNames(styles.speakerRuleText)}>
                            <FormattedMessage id={"gui.deviceManagerStage.speaker.responseWord.rule"} />
                        </div>
                        : null}
                </div>
                <div className={classNames((systemLanguage != mappingLanguage.English) ? styles.speakerRowArea : styles.speakerRowAreaEN)}>
                    <div className={classNames(styles.speakerText)}>
                        <FormattedMessage id={"gui.deviceManagerStage.speaker.control.language"} />
                    </div>
                    <div className={classNames((systemLanguage != mappingLanguage.English) ? styles.speakerReminderText : styles.speakerReminderTextEN)}>
                        <FormattedMessage id={"gui.deviceManagerStage.speaker.control.language.reminder"} />
                    </div>
                    <SpeakerOption
                        handleExpandOption={() => handleExpandOption(OPTION_TYPE.controlLanguage)}
                        handleSelectOption={(option) => handleSelectOption(option, STATE_INDEX.controlLanguage)}
                        selectedOption={state.controlLanguage}
                        isOptionExpand={state.expandSelect == OPTION_TYPE.controlLanguage}
                        optionList={controlLanguageList}
                    />
                </div>
            </div>
            {/* advance setting */}
            <div className={classNames(styles.speakerTitle)}>
                <FormattedMessage id={"gui.deviceManagerStage.speaker.advance"} />
            </div>
            <div className={classNames(styles.speakerAdvanceArea)}>
                <div className={classNames(styles.speakerRowArea)}>
                    <div className={classNames(styles.speakerText)}>
                        <FormattedMessage id={"gui.deviceManagerStage.speaker.skill"} />
                    </div>
                    <div className={classNames(styles.speakerReminderText)}>
                        <FormattedMessage id={"gui.deviceManagerStage.speaker.skill.reminder"} />
                    </div>
                    <div className={classNames(styles.speakerSkillSetting)}
                        onClick={() => onClickSkillSetting()}>
                        <FormattedMessage id={"gui.deviceManagerStage.speaker.settings"} />
                    </div>
                    <div className={classNames(styles.speakerSkillSmallIconArea)}>
                        {enableSkillList.includes(SKILL.calculator_converter) ? <img src={calculatorIcon} className={classNames(styles.speakerSkillSmallIcon)} /> : null}
                        {enableSkillList.includes(SKILL.alarm) ? <img src={alarmIcon} className={classNames(styles.speakerSkillSmallIcon)} alt={"alarm icon"} /> : null}
                        {enableSkillList.includes(SKILL.common) ? <img src={commonIcon} className={classNames(styles.speakerSkillSmallIcon)} alt={"common icon"} /> : null}
                        {enableSkillList.includes(SKILL.time) ? <img src={timeIcon} className={classNames(styles.speakerSkillSmallIcon)} alt={"time icon"} /> : null}
                        {enableSkillList.includes(SKILL.joke) ? <img src={jokeIcon} className={classNames(styles.speakerSkillSmallIcon)} alt={"joke icon"} /> : null}
                        {enableSkillList.includes(SKILL.weather) ? <img src={weatherIcon} className={classNames(styles.speakerSkillSmallIcon)} alt={"weather icon"} /> : null}
                        {enableSkillList.includes(SKILL.write) ? <img src={writeIcon} className={classNames(styles.speakerSkillSmallIcon)} alt={"write icon"} /> : null}
                        {enableSkillList.includes(SKILL.translator) ? <img src={translatorIcon} className={classNames(styles.speakerSkillSmallIcon)} alt={"translator icon"} /> : null}
                    </div>
                </div>
            </div>
        </div >
    )
}

const SwitchButton = (props) => {
    return <div className={classNames(styles.switchArea)}
        onClick={() => { props.onMethodSwitch() }}>
        <div className={classNames(styles.switchButton, (props.enble) ? styles.switchButtonEnable : styles.switchButtonDisable)} />
        <div className={classNames(styles.speakerSwitchText, styles.speakerSwitchOff, (!props.enble) ? styles.switchOnColor : null)}>
            <FormattedMessage id={"gui.deviceManagerStage.speaker.switch.off"} />
        </div>
        <div className={classNames(styles.speakerSwitchText, styles.speakerSwitchOn, (props.enble) ? styles.switchOnColor : null)}>
            <FormattedMessage id={"gui.deviceManagerStage.speaker.switch.on"} />
        </div>
    </div>
}

const SpeakerOption = (props) => {
    const {
        handleExpandOption,
        selectedOption,
        isOptionExpand,
        optionList,
        handleSelectOption
    } = props
    return <div className={classNames(styles.speakerOptionPositionArea)}
        onClick={() => handleExpandOption()}>
        <div className={classNames(styles.speakerOptionText)}>
            {selectedOption}
        </div>
        <div className={classNames(styles.speakerArrow, styles.speakerArrowPosition)} />
        {isOptionExpand ?
            <div className={classNames(styles.speakerOptionExpandArea)}>
                {optionList.map((option, index) => {
                    return <div className={classNames(styles.speakerOptionItem)} key={index}
                        onClick={() => handleSelectOption(option)}>
                        <div className={classNames(styles.speakerOption)}>
                            {option}
                        </div>
                    </div>
                })}
            </div> : null}
    </div>
}