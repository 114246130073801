import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import visionSettingStyles from './vision-setting-dialog.css'
import { DetectedTextTag, parseTag } from './tag.jsx'

import {
    isVisionConnected,
    isVisionInitiated,
    isVisionRecongInitiated,
    getImageTagList,
    setImageTagList,
} from '../../../reducers/vision.js'
import { ANALYZING_TIMEOUT, STATE_COMMAND_DELAY } from './vision-utils'
import { EditUtils } from '../../device-manager/edit-page/edit-utils'
import loadingImg from '../svg/loading.svg'

import { SnapshotButton } from './vision-other-dialog.jsx'
import store from 'store';
import log from '../../../lib/log';

var LANGUAGE_LIST = ["繁中", "簡中", "英文"];
const LANGUAGE_ID = {
    "繁中": "gui.dialog.vision.text.detection.language.tw",
    "簡中": "gui.dialog.vision.text.detection.language.cn",
    "英文": "gui.dialog.vision.text.detection.language.en",
}
const mappingLanguageList = {
    "繁中": "chi_tra",
    "簡中": "chi_sim",
    "英文": "eng",
    "chi_tra": "繁中",
    "chi_sim": "簡中",
    "eng": "英文",
}
var cancelTimeoutId = null;
class VisionTextIdentification extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = {
            tagList: this.props.getImageTagList,
            isImageFreezed: false,
            identifyLanguage: LANGUAGE_LIST[0],
            isOptionExpand: false,
            isAnalyzing: false,
            eng: [],
            chi_tra: [],
            chi_sim: []
        }
    }


    componentDidMount() {
        if (this.props.isVisionDistribute) {
            this.refreshTagList();
            this.props.startVisionRealtime('VisionTextIdentification.componentDidMount');
        }
        this.setState({
            isAnalyzing: false,
            isImageFreezed: false,
            identifyLanguage: store.get('identifyLanguage', LANGUAGE_LIST[0])
        });
        this.clearLangList();
    }

    componentWillUnmount() {
        this.clearAnalyzingState();
        this.clearImage();
        this.props.clearReducerImage();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.getImageTagList != this.props.getImageTagList) {
            this.refreshTagList();
        }
        if (prevProps.isVisionInitiated != this.props.isVisionInitiated) {
            if (this.props.isVisionConnected == true) {
                if (this.props.isVisionInitiated == true) {
                    this.props.startVisionRealtime('VisionTextIdentification.componentDidUpdate prevProps.isVisionInitiated != this.props.isVisionInitiated');
                } else {
                    this.clearAnalyzingState();
                    this.props.initVision();
                    this.clearImage();
                }
            } else {
                this.clearAnalyzingState();
                this.clearImage();
            }
        }
    }

    clearAnalyzingState() {
        log.info('clearAnalyzingState')
        this.clearAnalyzingTimeout();
        this.setState({
            isAnalyzing: false,
            isImageFreezed: false
        });
    }

    clearAnalyzingTimeout() {
        log.info('clearAnalyzingTimeout')
        if (cancelTimeoutId) {
            clearTimeout(cancelTimeoutId)
            cancelTimeoutId = null;
        }
    }

    clearImage() {
        log.info('clearImage')
        this.props.clearImageTagList();
        this.setState({ tagList: [], isImageFreezed: false });
    }

    clearLangList() {
        this.setState({
            eng: [],
            chi_tra: [],
            chi_sim: []
        })
    }

    getDetectedTagAllInfo() {
        if (!this.props.isVisionDistribute || !this.props.realtimeImage) return <div className={classNames(visionSettingStyles.disable)}><FormattedMessage id="gui.dialog.vision.text.identification.shot.msg" /></div>;
        if (!this.state.isImageFreezed) return <FormattedMessage id="gui.dialog.vision.text.identification.shot.msg" />;
        if (this.state.isImageFreezed && !this.state.isAnalyzing) {
            if (this.state.tagList && Array.isArray(this.state.tagList) && this.state.tagList.length > 0) {
                let allString = ""
                this.state.tagList.forEach(data => (data.lang && mappingLanguageList[this.state.identifyLanguage] == data.lang) ? allString = allString + data.text + " " : null);
                return <div className={classNames(visionSettingStyles.textIdentificationTitle)}>{allString}</div>
            } else {
                return <div className={classNames(visionSettingStyles.textIdentificationTitle)}>{
                    <FormattedMessage id={"gui.dialog.vision.text.detection.none"} />}
                </div>
            }
        }
    }

    getDetectedTagInfo() {
        if (!this.props.isVisionDistribute || !this.props.realtimeImage) return <div className={classNames(visionSettingStyles.disable)}><FormattedMessage id="gui.dialog.vision.text.identification.shot.msg" /></div>;
        if (!this.state.isImageFreezed) return <FormattedMessage id="gui.dialog.vision.text.identification.shot.msg" />;
        if (this.state.isImageFreezed && !this.state.isAnalyzing)
            if (this.state.tagList && Array.isArray(this.state.tagList) && this.state.tagList.length > 0) {
                return this.state.tagList.map((data, index) => {
                    if (data.tag && data.lang && mappingLanguageList[this.state.identifyLanguage] == data.lang) {
                        const tag = parseTag(data)
                        return <div key={index} className={classNames(visionSettingStyles.textIdentificationTitle)}>{`${(index + 1)}. ${tag.text}`}</div>
                    }
                })
            } else {
                return <div className={classNames(visionSettingStyles.textIdentificationTitle)}>{
                    <FormattedMessage id={"gui.dialog.vision.text.detection.none"} />}
                </div>
            }
    }

    createTagLayout() {
        if (!this.state.tagList || !Array.isArray(this.state.tagList) || this.state.tagList.length < 1) return null;
        return this.state.tagList.map((data, index) => (data.lang && mappingLanguageList[this.state.identifyLanguage] == data.lang) ?
            <DetectedTextTag
                key={index}
                detectedTag={data}
                enableTag={this.props.enableTag}
                index={index + 1}
            /> : null
        )
    }

    switchFreezeState() {
        const nextFreezed = !this.state.isImageFreezed;
        if (!this.props.isVisionDistribute) return;
        this.setState({ isImageFreezed: nextFreezed })
        if (this.props.isVisionDistribute) {
            this.clearAnalyzingTimeout();

            if (nextFreezed == false) {
                this.props.startVisionRealtime('VisionTextIdentification.switchFreezeState nextFreezed == false');
                this.clearLangList();
            } else {
                this.props.stopVisionRealtime('VisionTextIdentification.switchFreezeState nextFreezed == true');
                this.setState({ isAnalyzing: true });

                setTimeout(() => {
                    this.setState({ isAnalyzing: true });
                    this.props.takeVisionSnapshot();
                    this.props.setTabLock(true);
                    setTimeout(() => {
                        if (this.props.getVisionOcrResult) this.props.getVisionOcrResult(mappingLanguageList[this.state.identifyLanguage])
                    }, STATE_COMMAND_DELAY * 5);

                    cancelTimeoutId = setTimeout(() => {
                        if (this.state.isAnalyzing) {
                            log.info('OCR Unlock isAnalyzing by timeout')
                            this.props.setTabLock(false);
                            this.props.closeVision();
                            this.clearAnalyzingState();
                            this.clearImage();
                        }
                    }, ANALYZING_TIMEOUT);

                }, STATE_COMMAND_DELAY * 5)
            }

        }
    }

    createShotButton() {
        return !this.props.isVisionDistribute || !this.props.realtimeImage ? null
            : !this.state.isImageFreezed ?
                <div className={classNames(visionSettingStyles.snapshot, visionSettingStyles.textIdentification)}>
                    <SnapshotButton
                        onClickSnapshot={() => this.switchFreezeState()}
                    />
                </div>
                : this.state.isAnalyzing ? <div className={classNames(visionSettingStyles.loadingMainPosition)} ><img src={loadingImg} className={classNames(visionSettingStyles.loading, visionSettingStyles.main)} /></div>
                    : <div className={classNames(visionSettingStyles.freezeButton, (this.props.isVisionDistribute) ? null : visionSettingStyles.disableButton)}
                        onClick={() => this.switchFreezeState()}>
                        <FormattedMessage
                            id={"gui.dialog.vision.back.realtime"}
                        />
                    </div>
    }

    refreshTagList() {
        var visionRealtimeResult = this.props.getImageTagList || [];
        this.clearAnalyzingTimeout();
        this.props.setTabLock(false);
        if (visionRealtimeResult && Array.isArray(visionRealtimeResult) && visionRealtimeResult.length > 0) {
            visionRealtimeResult = visionRealtimeResult.filter(data => data.lang && data.lang == mappingLanguageList[this.state.identifyLanguage]);
            this.setState({
                tagList: visionRealtimeResult[0] && visionRealtimeResult[0].ocr_result ?
                    visionRealtimeResult[0].ocr_result : visionRealtimeResult,
                [mappingLanguageList[this.state.identifyLanguage]]: visionRealtimeResult[0] && visionRealtimeResult[0].ocr_result ?
                    visionRealtimeResult[0].ocr_result : visionRealtimeResult,
                isAnalyzing: false
            });
        } else {
            this.setState({ tagList: visionRealtimeResult, isAnalyzing: false });
        }
    }

    onClickSelect() {
        if (this.state.isAnalyzing) return;
        this.setState({ isOptionExpand: !this.state.isOptionExpand });
    }

    onClickOption(index) {
        if (this.state.isAnalyzing) return;
        store.set('identifyLanguage', LANGUAGE_LIST[index]);
        this.setState({ identifyLanguage: LANGUAGE_LIST[index], isOptionExpand: false });
        if (this.state.isImageFreezed) {
            const langList = this.state[mappingLanguageList[LANGUAGE_LIST[index]]]
            if (Array.isArray(langList) && langList.length > 0) {
                this.setState({ tagList: langList })
            } else {
                this.clearAnalyzingTimeout();
                if (this.props.getVisionOcrResult) {
                    this.props.setTabLock(true);
                    this.setState({ isAnalyzing: true });
                    this.props.getVisionOcrResult(mappingLanguageList[LANGUAGE_LIST[index]])
                }
                cancelTimeoutId = setTimeout(() => {
                    if (this.state.isAnalyzing) {
                        log.info('OCR select language Unlock isAnalyzing by timeout')
                        this.props.setTabLock(false);
                        this.props.closeVision();
                        this.clearAnalyzingState();
                        this.clearImage();
                    }
                }, ANALYZING_TIMEOUT);
            }
        }
    }

    render() {
        return (
            <div className={classNames(visionSettingStyles.visionContentArea)}>
                <div className={classNames(visionSettingStyles.visionArea)}>
                    <div className={classNames(visionSettingStyles.visionBarArea)}>
                        <div className={classNames(visionSettingStyles.enableTagTitle)}>
                            <FormattedMessage
                                defaultMessage="Enable Tag"
                                description="Enable Tag Display"
                                id="gui.dialog.vision.color.tag.display"
                            />
                            <div className={classNames(visionSettingStyles.tagArea, this.props.enableTag ? visionSettingStyles.enableTagArea : visionSettingStyles.disableTagArea)}
                                onClick={() => this.props.switchTagInfo()}>
                                <div className={classNames(visionSettingStyles.enableButton,
                                    this.props.enableTag ? visionSettingStyles.enableTag : visionSettingStyles.disableTag)} />
                            </div>
                        </div>
                    </div>
                    <div className={classNames(visionSettingStyles.visionImgArea)}>
                        {(this.props.isVisionDistribute && this.props.realtimeImage) ? <img src={this.props.realtimeImage} id={'visionImg'}
                            className={classNames(visionSettingStyles.visionImgSize)} /> :
                            <div className={classNames(visionSettingStyles.visionDisconnected)}>
                                <div className={classNames(visionSettingStyles.visionDisconnectedText)}>
                                    {this.props.getVisionInitStateString && this.props.getVisionInitStateString != "" ? this.props.getVisionInitStateString :
                                        <img src={loadingImg} className={classNames(visionSettingStyles.loading, visionSettingStyles.main)} />}
                                </div>
                            </div>}
                    </div>
                    <div className={classNames(visionSettingStyles.visionImgArea)}>
                        {(this.state.isImageFreezed) ?
                            <div className={classNames(visionSettingStyles.relativeArea)}>
                                {this.createTagLayout()}
                            </div>
                            : null}
                        {this.createShotButton()}
                    </div>
                </div >
                <div className={classNames(visionSettingStyles.visionInfoArea)}>
                    <div className={classNames(visionSettingStyles.textIdentificationLanguageArea)} >
                        <div className={classNames(visionSettingStyles.textIdentificationTitle, visionSettingStyles.language)} >
                            <div className={classNames(visionSettingStyles.visionPoint, visionSettingStyles.textTitle)} />
                            <FormattedMessage
                                id="gui.dialog.vision.text.identification.language"
                            />
                        </div>
                        <div className={classNames(visionSettingStyles.textLanguageSelectedArea,
                            (this.props.isVisionDistribute || !this.state.isImageFreezed || !this.state.isAnalyzing) ? null : visionSettingStyles.disable)}
                            onClick={() => this.onClickSelect()}                        >
                            <div className={classNames(visionSettingStyles.textLanguageSelectedText)}>
                                <FormattedMessage
                                    id={LANGUAGE_ID[this.state.identifyLanguage]}
                                />
                            </div>
                            <div className={classNames(visionSettingStyles.textLanguageSelectedArrow,
                                (this.props.isVisionDistribute) ? null : visionSettingStyles.disable)} />
                            {this.state.isOptionExpand ?
                                <div className={classNames(visionSettingStyles.textLanguageOptionArea)}>
                                    {LANGUAGE_LIST.map((language, index) => {
                                        return <div className={classNames(visionSettingStyles.textLanguageOption)}
                                            key={index}
                                            onClick={() => this.onClickOption(index)}>
                                            {EditUtils.getLocaleString(LANGUAGE_ID[language])}
                                        </div>
                                    })}
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className={classNames(visionSettingStyles.textIdentificationTitle, visionSettingStyles.all)} >
                        <div className={classNames(visionSettingStyles.visionPoint, visionSettingStyles.textAll)} />
                        <FormattedMessage
                            id="gui.dialog.vision.text.identification.all"
                        />
                    </div>
                    <div className={classNames(visionSettingStyles.visionResultReminder, visionSettingStyles.text)}>
                        <FormattedMessage id="gui.dialog.vision.text.detection.reminder" />
                    </div>
                    <div className={classNames(visionSettingStyles.textIdentificationAllArea)} >
                        <div className={classNames(visionSettingStyles.textIdentificationAllBlock)} >
                            {this.getDetectedTagAllInfo()}
                        </div>
                    </div>
                    <div className={classNames(visionSettingStyles.textIdentificationTitle, visionSettingStyles.partcial)} >
                        <div className={classNames(visionSettingStyles.visionPoint, visionSettingStyles.textPartcial)} />
                        <FormattedMessage
                            id="gui.dialog.vision.text.identification.partial"
                        />
                    </div>
                    <div className={classNames(visionSettingStyles.visionResultReminder, visionSettingStyles.text)}>
                        <FormattedMessage id="gui.dialog.vision.text.detection.reminder" />
                    </div>
                    <div className={classNames(visionSettingStyles.textIdentificationPartialArea)} >
                        <div className={classNames(visionSettingStyles.textIdentificationPartialBlock)} >
                            {this.getDetectedTagInfo()}
                        </div>
                    </div>
                </div >

            </div >
        );
    }
}

VisionTextIdentification.propTypes = {
};

const mapStateToProps = state => ({
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state) && isVisionRecongInitiated(state),
    getImageTagList: getImageTagList(state)
});

const mapDispatchToProps = dispatch => ({
    clearImageTagList: () => dispatch(setImageTagList([]))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisionTextIdentification);