import sensingBlocks from '../block-vertical-blocks/sensing';

export default function sensingEDU(deviceInfo, blockSeparator) {

    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8">
        ${sensingBlocks.sensing_resettimer}
        ${sensingBlocks.sensing_timerinseconds}
        ${deviceInfo.controllerInfo.hasController ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_controllerbuttonpressed}
            ${sensingBlocks.sensing_controllerposition}
        `: ``}
        ${deviceInfo.bumperInfo.hasbumper ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_bumperpressed}
        `: ``}
        ${deviceInfo.lineTrackerInfo.haslineTracker ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_linetrackerreflectivityin}
        `: ``}
        ${deviceInfo.ultrasonicInfo.hasultrasonic ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_sonarfoundobject}
            ${sensingBlocks.sensing_sonardistancein}
        `: ``}
        ${blockSeparator}
        ${sensingBlocks.sensing_rfiddetecteddata}
    </category>
    `;
};