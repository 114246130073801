import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes, { number } from 'prop-types';
import React from 'react';
import dialogStyles from '../dialog.css';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { EditUtils, SUPPORT_TYPES, STRING_ID } from '../../device-manager/edit-page/edit-utils.js'
import {
    platformType,
    getPlatform,
    isPad,
    isReactNative,
} from '../../../lib/platform'

import log from '../../../lib/log';
import {
    focusDialog,
    getForegroundDialog,
    FOREGROUND_INDEX,
    BACKGROUND_INDEX,
    DIALOG_STREAMING_POPUP,
} from '../../../reducers/dialog';
import {
    isVisionConnected,
    isVisionInitiated,
    setImageBase64,
    getImageBase64,
    setImageTagList,
    getVisionInitState,
    VISION_INIT_STATE_TYPE,
    getImageHeight,
    getImageWidth,
    getWifiVision
} from '../../../reducers/vision';
import streamingStyles from './streaming-dialog.css'
import VM from 'scratch-vm';
import store from 'store';
import { getUIStyle, uiType } from '../../../reducers/ui-style';
import closeIcon from '../svg/close_btn.svg';

import videoStopImg from './img/stop.svg'

import videoStartImg from './img/video.svg'

import videoCloseImg from './img/close.svg'

import takePictureImg from './img/picture.svg'

const TITLE_HEIGHT = 58;

const DIALOG_WIDTH = 640;
const DIALOG_HEIGHT = 539;

const DIRECTION = {
    horizontal: "horizontal",
    vertical: "vertical"
}
const ACTION = {
    move: 'move',
}
const EMPTY_DATA = 'N/A';
const INIT_STATE = {
    translateX: (window.innerWidth - DIALOG_WIDTH) / 2,
    translateY: (window.innerHeight - DIALOG_HEIGHT) / 2,
    z_index: BACKGROUND_INDEX,
    isBrainConnected: false
}
class StreamingPopupDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = INIT_STATE;
        this.moving = false;
        this.lastX = null;
        this.lastY = null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.getBrainInfo != this.props.getBrainInfo) {
            this.setState({ isBrainConnected: this.props.getBrainInfo && this.props.getBrainInfo.isSocket })
        }
        if (this.props.foregroundDialog && this.props.foregroundDialog != prevProps.foregroundDialog) {
            this.setState({ z_index: (this.props.foregroundDialog == this.props.dialogType) ? FOREGROUND_INDEX : BACKGROUND_INDEX })
        }
    }

    componentDidMount() {
        if (isPad()) {
            window.addEventListener('touchstart', e => { this.onMouseDown(e) });
            window.addEventListener('touchmove', e => { this.onMouseMove(e) });
            window.addEventListener('touchend', e => { this.onMouseUp(e) });
        } else {
            window.addEventListener('mousedown', e => { this.onMouseDown(e) });
            window.addEventListener('mousemove', e => { this.onMouseMove(e) });
            window.addEventListener('mouseup', e => { this.onMouseUp(e) });
        }
        this.props.focusDialog(DIALOG_STREAMING_POPUP)
    }

    componentWillUnmount() {
        if (isPad()) {
            window.removeEventListener('touchstart', e => { this.onMouseDown(e) });
            window.removeEventListener('touchmove', e => { this.onMouseMove(e) });
            window.removeEventListener('touchend', e => { this.onMouseUp(e) });
        } else {
            window.removeEventListener('mousedown', e => { this.onMouseDown(e) });
            window.removeEventListener('mousemove', e => { this.onMouseMove(e) });
            window.removeEventListener('mouseup', e => { this.onMouseUp(e) });
        }
        this.props.focusDialog();
    }

    onMouseDown(e) {
        if (this.props.foregroundDialog != DIALOG_STREAMING_POPUP || e.button != 0) return
        // this.setState({ frame: Z_INDEX.ignore, mask: Z_INDEX.focus })
        e.stopPropagation();
        if (this.state.action == ACTION.move) {
            this.moving = true;
        }
    }

    onMouseUp(e) {
        if (this.props.foregroundDialog != DIALOG_STREAMING_POPUP || e.button != 0) return
        // this.setState({ frame: Z_INDEX.focus, mask: Z_INDEX.ignore })
        e.stopPropagation();
        this.adjustDialogPosition();
        this.moving = false;
        this.lastX = null;
        this.lastY = null;
        this.setState({ action: "" })
    }

    onMouseMove(e) {
        if (this.props.foregroundDialog != DIALOG_STREAMING_POPUP || e.button != 0) return
        this.moving && this.onMove(e);
    }

    onMove(e) {
        if (!this.moving) return
        var x = e.clientX, y = e.clientY;
        if (isPad()) {
            x = e.pageX;
            y = e.pageY;
        }
        if (isReactNative()) {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        }
        if (this.lastX && this.lastY) {
            let dx = x - this.lastX;
            let dy = y - this.lastY;
            this.moveX = this.state.translateX + dx;
            this.moveY = this.state.translateY + dy;
            this.setState({
                translateX: this.state.translateX + dx,
                translateY: this.state.translateY + dy,
            })
        }
        this.lastX = x;
        this.lastY = y;
    }

    adjustDialogPosition() {
        let adjustTransX = this.calculateMove(this.state.translateX, DIALOG_WIDTH / 5, window.innerWidth, DIRECTION.horizontal);
        let adjustTransY = this.calculateMove(this.state.translateY, TITLE_HEIGHT, window.innerHeight, DIRECTION.vertical)
        this.setState({
            translateX: adjustTransX,
            translateY: adjustTransY,
        })
        if (this.moveX != adjustTransX) this.moveX = adjustTransX;
        if (this.moveY != adjustTransY) this.moveY = adjustTransY;
    }

    calculateMove(move, dialogLength, maxLength, direction) {
        let min = (direction && direction == DIRECTION.horizontal) ? (dialogLength - DIALOG_WIDTH) : 0;
        let max = maxLength - dialogLength
        return this.calculateLength(move, min, max)
    }

    calculateLength(length, min, max) {
        if (min < length && length < max) {
            return length
        } else if (min >= length) {
            return min
        } else {
            return max
        }
    }

    isWWVersion() {
        return this.props.getUIStyle == uiType.ww
    }

    onClickClose() {
        this.props.onClose();
    }

    onMoveDialog() {
        this.props.focusDialog(DIALOG_STREAMING_POPUP)
        this.setState({ action: ACTION.move })
    }
    render() {
        const {
            isPhotoSaved,
            imgBase64,
            // isVideoMode,
            isEnVersion,
            screenShot,
            onClickTakeShot,
            // onClickStopVideo,
            // onClickStartVideo,
            // onClickCloseVideo
        } = this.props
        return (
            <div className={classNames(dialogStyles.floatStyle)}
                style={{ top: this.state.translateY + 'px', left: this.state.translateX + 'px', zIndex: this.state.z_index }}>
                <div className={classNames(dialogStyles.floatModalStyle)}
                    style={{ height: DIALOG_HEIGHT + 'px', width: DIALOG_WIDTH + 'px', overflow: "hidden" }}>
                    <div
                        className={classNames(dialogStyles.helpHeaderArea)}>
                        <div
                            className={classNames(dialogStyles.helpHeader)}
                            onTouchStart={() => this.onMoveDialog()}
                            onMouseDown={() => this.onMoveDialog()}
                            style={{
                                height: TITLE_HEIGHT + "px",
                                padding: 0 + "px",
                                cursor: "move"
                            }}>
                            <div style={{ width: "100%" }}>
                                <div className={classNames(streamingStyles.popupTitile)} style={{ lineHeight: TITLE_HEIGHT + 'px' }}>
                                    <FormattedMessage
                                        id={"gui.dialog.streaming.popup.title"} /></div>
                            </div>
                        </div>
                        <div className={classNames(dialogStyles.helpClose)}
                            onClick={() => this.onClickClose()}
                            style={{ position: "absolute" }}>
                            <img src={closeIcon} />
                        </div>
                    </div>
                    <div className={classNames(streamingStyles.popupContentArea)}>
                        <div className={classNames(streamingStyles.photoSaved, isPhotoSaved ? streamingStyles.fadeIn : streamingStyles.fadeOut)}
                            style={{ lineHeight: isEnVersion ? '15px' : '30px' }}>
                            <FormattedMessage
                                id={"gui.dialog.streaming.vision.photo.saved"} /></div>

                        <div className={classNames(streamingStyles.shot, screenShot ? streamingStyles.shotFadeIn : streamingStyles.shotFadeOut)} />
                        {imgBase64 ?
                            <img src={`data:image/jpeg;base64, ${imgBase64}`} id={'visionImg'}
                                className={classNames(streamingStyles.popupIframe)} />
                            // : this.props.src != "" ?
                            //     <iframe src={'//' + this.props.src}
                            //         className={classNames(streamingStyles.popupIframe)}
                            //         frameBorder="0"
                            //     /> 
                            : null}
                        {/* {!isVideoMode ? */}
                        <div className={classNames(streamingStyles.popupTakeshot)}
                            onClick={() => onClickTakeShot()}>
                            <img src={takePictureImg} className={classNames(streamingStyles.popupIcon)} />
                        </div>
                        {/* : <div className={classNames(streamingStyles.popupTakeshot)}
                                onClick={() => onClickStopVideo()}>
                                <img src={videoStopImg} className={classNames(streamingStyles.popupIcon)} />
                            </div>} */}
                        {/* {!isVideoMode ?
                            <div className={classNames(streamingStyles.popupVideo)}
                                onClick={() => onClickStartVideo()}>
                                <img src={videoStartImg} className={classNames(streamingStyles.popupIcon)} />
                            </div>
                            : <div className={classNames(streamingStyles.popupVideo)}
                                onClick={() => onClickCloseVideo()}>
                                <img src={videoCloseImg} className={classNames(streamingStyles.popupIcon)} />
                            </div>} */}
                    </div>
                </div >
            </div >
        );
    }
}

StreamingPopupDialog.propTypes = {
    onClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state),

    getImageBase64: getImageBase64(state),
    getUIStyle: getUIStyle(state),
    getVisionInitState: getVisionInitState(state),
    getImageHeight: getImageHeight(state),
    getImageWidth: getImageWidth(state),

    foregroundDialog: getForegroundDialog(state),
    getWifiVision: getWifiVision(state)
});

const mapDispatchToProps = dispatch => ({
    clearImageBase64: () => dispatch(setImageBase64()),
    focusDialog: (dialog) => dispatch(focusDialog(dialog))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamingPopupDialog);