const SET_PROJECT_TITLE = 'projectTitle/SET_PROJECT_TITLE';
const SET_PROJECT_PRE_TITLE = 'projectTitle/SET_PROJECT_PRE_TITLE';
const SET_PROJECT_NEW = 'projectTitle/SET_PROJECT_NEW';

const PRE_TITLE = 'preTitle';
const TITLE= 'title';
const NEW= 'new';

const initialState = {
    [PRE_TITLE]: "",
    [TITLE]: "",
    [NEW]: true
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_PROJECT_TITLE:
        return Object.assign({}, state, {
            [TITLE]: action.title
        });
    case SET_PROJECT_PRE_TITLE:
        return Object.assign({}, state, {
            [PRE_TITLE]: action.title
        });
    case SET_PROJECT_NEW:
        return Object.assign({}, state, {
            [NEW]: action.isNew
        });
    default:
        return state;
    }
};
const setProjectTitle = title => ({
    type: SET_PROJECT_TITLE,
    title: title
});
const setProjectPreTitle = title => ({
    type: SET_PROJECT_PRE_TITLE,
    title: title
});
const setProjectNew = isNew => ({
    type: SET_PROJECT_NEW,
    isNew: isNew
});

const projectTitle = state => state.scratchGui.projectTitle[TITLE];
const projectPreTitle = state => state.scratchGui.projectTitle[PRE_TITLE];
const projectIsNew = state => state.scratchGui.projectTitle[NEW];

export {
    reducer as default,
    initialState as projectTitleInitialState,
    setProjectTitle,
    setProjectPreTitle,
    setProjectNew,
    projectTitle,
    projectPreTitle,
    projectIsNew
};
