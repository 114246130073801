import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import store from 'store';
import { getAllPortInfo, getBrainVer, getBrainInfo, getFirmwareConfig, MB_TYPE } from '../../reducers/brain';
import { getAllControllerPortInfo, getControllerInfo, isRCFirmwareUpdating } from '../../reducers/controller';
import { showUpdateRCTutorialDialog, showLicenseDialog, showHistoryDialog } from '../../reducers/dialog';
import { EditUtils } from '../device-manager/edit-page/edit-utils.js'
import { BRAIN_TYPE } from '../../lib/brains';
import closeIcon from './svg/close_btn.svg';
import licenseArrowIcon from './svg/license-arrow.svg'

import eduBrainImg from '../device-manager/pictures/brain_edu.png'
import entryBrainImg from '../device-manager/pictures/brain_entry.png'
import guiLicenses from '../../lib/libraries/HulkScratchGUI_ALL_LICENSES.js'
import desktopLicenses from '../../lib/libraries/HulkScratchDesktop_ALL_LICENSES.js'
import controllerImg from '../device-manager/pictures/controller.png'

import logoImg from './svg/logo.png';
import logoWWImg from './svg/ww/logo.svg';


import { appString } from '../../locales/editor-msgs';
import {
    uiType,
    getUIStyle
} from '../../reducers/ui-style';

import {
    platformType,
    isPad,
    getPlatform
} from '../../lib/platform';

import {
    isServerNewer,
    filterBrainVer,
    filterControllerVer
} from '../../lib/string-utils.js';

import {
    Catagory,
    postMessage
} from '../../lib/postmessage';

import {
    getTestVersion
} from '../../reducers/test-version';

import packageJson from '../../../package.json';

import LocalKey from '../../lib/local-storage-key';
import { parseReleaseNoteJson } from './utilities';

class AboutDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleClickUpdateSoftware',
            'showDebugServerInfo',
            'ref'
        ]);
        this.licenseList = [];
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
    }
    componentWillUnmount() {
    }

    readLicenseJson() {
        if (this.licenseList.length > 0) {
            return
        }
        if (getPlatform() != platformType.Web) {
            let releaseNoteObj = [];
            let item = null;
            let versionObj = null;
            let releaseNotes = parseReleaseNoteJson(this.props.locale);
            let showVersionCount = 3;
            if (releaseNotes && releaseNotes.length > 0) {
                for (let i = 0; i < releaseNotes.length && i < showVersionCount; i++) {
                    versionObj = (
                        <tr className={styles.aboutContentTrTitle}>
                            <td colspan="4">{releaseNotes[i]['version']} - {releaseNotes[i]['time']}</td>
                        </tr>);
                    releaseNoteObj.push(versionObj);
                    if (releaseNotes[i]['data'][this.props.locale] && releaseNotes[i]['data'][this.props.locale].length > 0) {
                        releaseNotes[i]['data'][this.props.locale].forEach((paragraph) => {
                            let content = [];
                            paragraph.content.forEach((word) => {
                                if (word.length > 0) {
                                    item = (
                                        <tr className={styles.aboutContentTr}>
                                            <td width="5%"></td>
                                            {(paragraph.title.length > 0) ? <td width="5%"></td> : null}
                                            <td width="1%" className={styles.aboutContentTd}>．</td>
                                            {(paragraph.title.length > 0) ?
                                                <td>{word}</td> :
                                                <td colspan="2">{word}</td>
                                            }
                                        </tr>);
                                    content.push(item);
                                }
                            })
                            if (paragraph.title.length > 0) {
                                item = (
                                    <tr className={styles.aboutContentTrTitle}>
                                        <td width="5%"></td>
                                        <td colspan="3">{paragraph.title}</td>
                                    </tr>);
                                releaseNoteObj.push(item);
                            }
                            releaseNoteObj.push(content);
                        })
                    }
                }
            }
            this.licenseList.push(
                <table className={styles.aboutContentTable}>
                    <tbody>
                        {releaseNoteObj}
                    </tbody>
                </table>
            );
        } else {
            let allLicenses = getPlatform() == platformType.Desktop ? desktopLicenses : guiLicenses;
            Object.entries(allLicenses).forEach((license) => {
                let item = (
                    <div key={license[0]} className={styles.aboutLicenseItem}>
                        <div className={styles.aboutLicenseItemId}>
                            {license[1]._id}
                        </div>
                        <div>
                            {'License: ' + license[1].license}
                        </div>
                        <div className={styles.aboutLicenseItemCopyright}>
                            {'Copyright: ' + license[1].copyright}
                        </div>
                    </div>)
                this.licenseList.push(item);
            });
        }
    }

    handleClickUpdateSoftware() {
        this.props.onClose();
        if (this.props.isBrainConnected && !this.props.isEnableDownloadAndRunButton) return;
        this.props.onUpdateSoftware();
    }

    handleClickUpdateFirmware() {
        if (!this.props.isEnableDownloadAndRunButton) return;
        this.props.onClose();
        this.props.onUpdateFirmware();
    }

    handleClickUpdateControllerVerFirmware() {
        console.log('handleClickUpdateControllerVerFirmware')
        this.props.onClose();
        this.props.showUpdateRCTutorialDialog();
    }

    ref(c) {
        this.dialog = c;
    }

    getBrainImage() {
        if (!this.props.brainInfo) return eduBrainImg;
        switch (this.props.brainInfo.brain_type) {
            case BRAIN_TYPE.ENTRY:
            case BRAIN_TYPE.EDU_AND_ENTRY:
                return entryBrainImg;
            case BRAIN_TYPE.EDU:
            default:
                return eduBrainImg;
        }
    }

    getLogoImage() {
        if (this.props.getUIStyle == uiType.cn) {
            return logoImg;
        } else {
            //WW & VR ico
            return logoWWImg;
        }
    }

    parseServerBrainVer() {
        let updateBrainType = this.props.brainInfo.brain_type;
        if (updateBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
            updateBrainType = BRAIN_TYPE.ENTRY;
        }
        return (this.props.firmwareConfig
            && this.props.firmwareConfig[MB_TYPE[updateBrainType]]
            && this.props.firmwareConfig[MB_TYPE[updateBrainType]]['lastest'])
            ? this.props.firmwareConfig[MB_TYPE[updateBrainType]]['lastest']
            : "0.0.0.1"
    }

    parseControllerVer() {
        return (this.props.firmwareConfig && this.props.firmwareConfig['SENSOR']
            && this.props.firmwareConfig['SENSOR']['list']
            && this.props.firmwareConfig['SENSOR']['list']['controller'])
            ? this.props.firmwareConfig['SENSOR']['list']['controller']
            : "0.0.0.1"
    }

    showDebugServerInfo() {
        console.log("showDebugServerInfo ", store.get(LocalKey.debugMode, ""));
        if (store.get(LocalKey.debugMode, "") == true) {
            return ("(Server type: " + store.get(LocalKey.serverType, "stage") + ", " +
                (this.props.getTestVersion ? "test version: enable" : "test version: disable") + ")");
        }
        return null;
    }

    getShortVersion(version) {
        if (version) {
            return version.split("-").shift();
        } else {
            return version;
        }
    }

    render() {
        this.readLicenseJson();
        let serverBrainVer = this.parseServerBrainVer();
        let serverControllerVer = this.parseControllerVer();
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle,
                    (this.props.isBrainConnected || this.props.isControllerConnected) ? styles.connectOne : null,
                    (this.props.isBrainConnected && this.props.isControllerConnected) ? styles.connectTwo : null,
                )}
                    ref={this.ref}>
                    <div className={styles.aboutHeader}>
                        <div className={styles.aboutTitle}>
                            <FormattedMessage
                                defaultMessage="About"
                                description="about dialog title"
                                id="gui.menuBar.about"
                            />
                        </div>
                        <div className={styles.aboutClose} onClick={this.props.onClose}>
                            <img src={closeIcon} alt={"close icon"} />
                        </div>
                    </div>

                    <div className={classNames(styles.softwareContent)}>
                        <div className={styles.aboutContent}>
                            <img className={styles.aboutLogo} src={this.getLogoImage()} onClick={() => postMessage(Catagory.Server, {})} alt={EditUtils.getLocaleString('gui.menuBar.about')} />
                            <div className={styles.aboutContentCol}>
                                <div className={styles.aboutContentTitle}>
                                    {appString[this.props.getUIStyle]["en"]["appName"]}
                                </div>
                                <div className={styles.aboutContentVersion}>
                                    {
                                        EditUtils.getLocaleString('gui.dialog.update.current') + this.getShortVersion((getPlatform() == platformType.Web ? packageJson.version : store.get("currentVersion")))
                                    }
                                    {
                                        getPlatform() == platformType.Web ? null : <br />
                                    }
                                    {
                                        getPlatform() == platformType.Web ? null : EditUtils.getLocaleString('gui.dialog.update.available') + this.getShortVersion((store.get("availableVersion") || store.get("currentVersion")))
                                    }
                                    {
                                        store.get(LocalKey.debugMode, "") == true ? <br /> : null
                                    }
                                    {
                                        this.showDebugServerInfo()
                                    }
                                </div>
                            </div>
                            {store.get("SoftwareUpdate") ?
                                <div className={classNames(styles.aboutButton, styles.updateImmediatelyButton)} onClick={() => this.handleClickUpdateSoftware()}>
                                    <FormattedMessage
                                        defaultMessage="Update Immediately"
                                        description="Update Immediately"
                                        id="gui.dialog.update.update"
                                    />
                                </div>
                                : null}
                        </div>
                        <div className={classNames(styles.aboutDivider, styles.up)} />
                        {getPlatform() != platformType.Web ?
                            (<div className={styles.latestUpdateContent}>
                                <div className={styles.aboutLatestUpdateTitle}>
                                    <FormattedMessage
                                        defaultMessage="Latest Update"
                                        description="Latest Update"
                                        id="gui.dialog.history.latest"
                                    />
                                </div>
                                <div className={classNames(styles.aboutButton, styles.latestUpdateContentButton)} onClick={() => this.props.onShowHistoryDialog()}>
                                    <FormattedMessage
                                        defaultMessage="Version History"
                                        description="Version History"
                                        id="gui.dialog.history.version.list"
                                    />
                                </div>
                            </div>) : null}
                        <div className={styles.aboutDescription}>{this.licenseList}</div>
                        {getPlatform() != platformType.Web ?
                            (<div className={classNames(styles.aboutDivider, styles.up)} />) : null}
                        {getPlatform() != platformType.Web ?
                            (<div className={styles.aboutLincense}
                                onClick={() => this.props.onShowLicenseDialog()}>
                                <FormattedMessage
                                    defaultMessage="License"
                                    description="Software Authorization Statement"
                                    id="gui.menuBar.license"
                                />
                                <div className={classNames(styles.licenseArrowIcon)} >
                                    <img src={licenseArrowIcon} />
                                </div>
                            </div>) : null}
                    </div>
                    {(this.props.isBrainConnected) ?
                        <BrainAbout
                            getBrainImage={this.getBrainImage()}
                            brainType={this.props.brainInfo.brain_type}
                            brainSSN={this.props.brainInfo.ssn}
                            brainVer={this.props.brainInfo.brain_ver}
                            serverBrainVer={serverBrainVer}
                            handleClickUpdateFirmware={() => this.handleClickUpdateFirmware()}
                            isEnableDownloadAndRunButton={this.props.isEnableDownloadAndRunButton}
                        />
                        : null}
                    {(this.props.isControllerConnected) ?
                        <ControllerAbout
                            controllerSSN={this.props.controllerInfo.ssn}
                            controllerVer={this.props.controllerInfo.ver}
                            serverControllerVer={serverControllerVer}
                            isRCFirmwareUpdating={this.props.isRCFirmwareUpdating}
                            handleClickUpdateControllerVerFirmware={() => this.handleClickUpdateControllerVerFirmware()}
                            isUpdating={false}
                        />
                        : null}
                </div>
            </div >
        );
    }
}

const BrainAbout = (props) => {
    let brain = filterBrainVer(props.brainVer);
    let server = filterBrainVer(props.serverBrainVer);
    return (
        <div className={classNames(styles.firmwareContent)}>
            <div className={classNames(styles.aboutBrainPosition)}>
                <img src={props.getBrainImage} className={classNames(styles.aboutBrain)}></img>
            </div>
            <div className={classNames(styles.firmwareContentCol)}>
                <div className={classNames(styles.aboutContentTitle)}>
                    {props.brainSSN}
                </div>
                <div className={classNames(styles.aboutContentVersion)}>
                    {EditUtils.getLocaleString('gui.dialog.update.current') + (brain ? brain : "0.0.0.1")} <br />
                    {EditUtils.getLocaleString('gui.dialog.update.available') + (server ? server : "0.0.0.1")} <br />
                </div>
                {
                    server && server != "0.0.0.1" && isServerNewer(brain, server) && isPad() ? (
                        <div className={styles.firmwareUpdateHint} >
                            <FormattedMessage
                                defaultMessage="Please connect the main controller to the computer to perform the update"
                                description="Firmware update hint"
                                id="gui.dialog.about.firmware.update.hint" />
                        </div>
                    ) : null
                }
            </div>
            {server && server != "0.0.0.1" && isServerNewer(brain, server) ?
                getPlatform() == platformType.Desktop ? (
                    <div className={classNames(styles.firmwareUpdateButton,
                        !props.isEnableDownloadAndRunButton ? styles.disableUpdateButton : null)}
                        onClick={() => props.handleClickUpdateFirmware()}>
                        <FormattedMessage
                            defaultMessage="Update Immediately"
                            description="Update Immediately"
                            id="gui.dialog.update.update" />
                    </div>) : null
                : null
            }
        </div>
    )
}

const ControllerAbout = (props) => {
    const {
        controllerVer,
        serverControllerVer,
        controllerSSN,
        isRCFirmwareUpdating,
        isUpdating,
        handleClickUpdateControllerVerFirmware
    } = props
    let controller = filterControllerVer(controllerVer) ? filterControllerVer(controllerVer) : "ENTRMC.0.1";
    let server = filterControllerVer(serverControllerVer);
    return (
        <div className={classNames(styles.firmwareContent)}>
            <div className={classNames(styles.aboutControllerPosition)}>
                <img src={controllerImg} className={classNames(styles.aboutController)}></img>
            </div>
            <div className={classNames(styles.firmwareContentCol)}>
                <div className={classNames(styles.aboutContentTitle)}>
                    {controllerSSN}
                </div>
                <div className={classNames(styles.aboutContentVersion)}>
                    {EditUtils.getLocaleString('gui.dialog.update.current') + (controller ? controller : "0.0.0.1")} <br />
                    {EditUtils.getLocaleString('gui.dialog.update.available') + (server ? server : "0.0.0.1")} <br />
                </div>
            </div>
            {server && server != "0.0.0.1" && isServerNewer(controller, server) && !isRCFirmwareUpdating ?
                <div className={classNames(styles.firmwareUpdateButton,
                    isUpdating ? styles.disableUpdateButton : null)}
                    onClick={() => handleClickUpdateControllerVerFirmware()}>
                    <FormattedMessage
                        defaultMessage="Update Immediately"
                        description="Update Immediately"
                        id="gui.dialog.update.update" />
                </div>
                : null
            }
        </div>
    )
}

AboutDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onUpdateSoftware: PropTypes.func.isRequired,
    onUpdateFirmware: PropTypes.func.isRequired,
    onUpdateControllerFirmware: PropTypes.func.isRequired,
    show: PropTypes.bool,
    isEnableDownloadAndRunButton: PropTypes.bool,
    controllerInfo: PropTypes.object,
    isControllerConnected: PropTypes.bool,
    getUIStyle: PropTypes.string,
    getTestVersion: PropTypes.bool,
    locale: PropTypes.string,
};

AboutDialog.defaultProps = {
    onUpdateSoftware: () => { },
    onUpdateFirmware: () => { },
    onUpdateControllerFirmware: () => { },
};

const mapStateToProps = state => ({
    allPortInfo: getAllPortInfo(state),
    currentBrainVer: getBrainVer(state, 'current'),
    firmwareConfig: getFirmwareConfig(state),
    brainInfo: getBrainInfo(state),
    controllerInfo: getControllerInfo(state),
    getAllControllerPortInfo: getAllControllerPortInfo(state),
    isControllerConnected: getControllerInfo(state) != null,
    getUIStyle: getUIStyle(state),
    isRCFirmwareUpdating: isRCFirmwareUpdating(state),
    getTestVersion: getTestVersion(state),
});

const mapDispatchToProps = dispatch => ({
    showUpdateRCTutorialDialog: () => dispatch(showUpdateRCTutorialDialog()),
    onShowLicenseDialog: () => dispatch(showLicenseDialog()),
    onShowHistoryDialog: () => dispatch(showHistoryDialog()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AboutDialog);