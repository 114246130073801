const UPDATE_WIFI_LIST = 'scratch-gui/wifi/UPDATE_WIFI_LIST';
const UPDATE_WIFI_SCAN_RESULT = 'scratch-gui/wifi/UPDATE_WIFI_SCAN_RESULT';
const UPDATE_CONNECTED_WIFI = 'scratch-gui/wifi/UPDATE_CONNECTED_WIFI';
const UPDATE_WIFI_STATUS = 'scratch-gui/wifi/UPDATE_WIFI_STATUS';

const WIFI_LIST = "wifiList";
const WIFI_SCAN_RESULT = "wifiscanresult";
const CONNECTED_WIFI = "connectedWifi";
const WIFI_STATUS = "wifiStatus";


const wifiStatus = {
    DISABLED: "DISABLED",
    NO_SETTING: "NO_SETTING",
    SCANNING: "SCANNING",
    CONNECTING: "CONNECTING",
    COMPLETED: "COMPLETED",
    AUTH_FAIL: "AUTH_FAIL",
    LINK_FAIL: "LINK_FAIL"
}

const initialState = {
    [WIFI_LIST]: [],
    [CONNECTED_WIFI]: "",
    [WIFI_STATUS]: {},
    [WIFI_SCAN_RESULT]: [],
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case UPDATE_WIFI_LIST:
            return Object.assign({}, state, {
                [WIFI_LIST]: action.wifiList
            });
        case UPDATE_CONNECTED_WIFI:
            return Object.assign({}, state, {
                [CONNECTED_WIFI]: action.connectedWifi
            });
        case UPDATE_WIFI_STATUS:
            return Object.assign({}, state, {
                [WIFI_STATUS]: action.wifiStatus
            });
        case UPDATE_WIFI_SCAN_RESULT:
            return Object.assign({}, state, {
                [WIFI_SCAN_RESULT]: action.wifiScanResult
            });
        default:
            return state;
    }
};

const interuptConnectedWifi = () => ({
    type: UPDATE_CONNECTED_WIFI,
    connectedWifi: null
});

const setWifiStatus = status => ({
    type: UPDATE_WIFI_STATUS,
    wifiStatus: status
});

const setConnectedWifi = apName => ({
    type: UPDATE_CONNECTED_WIFI,
    connectedWifi: apName
});

const setWifiList = wifilist => ({
    type: UPDATE_WIFI_LIST,
    wifiList: wifilist
});

const setWifiScanResult = wifiscanresult => ({
    type: UPDATE_WIFI_SCAN_RESULT,
    wifiScanResult: wifiscanresult
});


const getWifiStatus = state => state.scratchGui.wifi[WIFI_STATUS];
const getWifiScanResult = state => state.scratchGui.wifi[WIFI_SCAN_RESULT];
const getWifiList = state => state.scratchGui.wifi[WIFI_LIST];
const getConnectedWifi = state => state.scratchGui.wifi[CONNECTED_WIFI];

export {
    reducer as default,
    initialState as wifiInitialState,

    getConnectedWifi,
    setConnectedWifi,
    getWifiStatus,
    setWifiStatus,
    interuptConnectedWifi,
    getWifiList,
    setWifiList,
    getWifiScanResult,
    setWifiScanResult,
    wifiStatus,
}