import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape, defineMessages } from 'react-intl';

import exampleTags from '../lib/libraries/example-tags';
import exampleBrainTags from '../lib/libraries/example-brain-tags';

import { DEFAULT_LOCALE } from '../config/project-config';
import ExampleLibraryComponent from '../components/library/example-library.jsx';

import { connect } from 'react-redux';

import {
    closeExampleLibrary
} from '../reducers/modals';

import {
    projectTitle,
    setProjectPreTitle
} from '../reducers/project-title';

import sharedMessages from '../lib/shared-messages';

import ExampleDialog from '../components/dialog/example-dialog.jsx';
import QuestionDialog from '../components/dialog/question-dialog.jsx';
import {
    questionType,
    setFeedback,
    getFeedback,
    showExampleDialog,
    hideExampleDialog,
    exampleDialogShow,
    showQuestionDialog,
    hideQuestionDialog,
    quesitonDialogShow,
} from '../reducers/dialog';

import {
    getWorkspace
} from '../reducers/block';

import exampleString from '../../static/example/string.js';
import exampleImage from '../../static/example/image.js';
import exampleFile from '../../static/example/file.js';
import deepmerge from 'deepmerge';
import store from 'store';

import downloadBlob from '../lib/download-blob';
import { BLOCKS_DEFAULT_SCALE } from '../lib/layout-constants';
import { getPickedBrainType } from '../reducers/picked-brain-type'
import { getEditorDisplayMode } from '../reducers/code-editor';
import { DEFAULT_BLOCKID } from '../config/block-config';

import {
    platformType,
    getPlatform
} from '../lib/platform';

import log from '../lib/log.js';
import LocalKey from '../lib/local-storage-key';

const messages = defineMessages({
    exampleLibraryTitle: {
        defaultMessage: 'Example',
        description: 'Heading for the help/example library',
        id: 'gui.exampleLibrary.example'
    }
});

class ExampleLibrary extends React.PureComponent {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleItemSelect',
            'handleOpenExample',
            'handleOpenQuestionDialog',
            'handleCopyBlocks',
            'handleNoSave',
            'handleSave'
        ]);
        this.state = {
            selected: {}
        }
    }

    componentDidUpdate() {
        if (this.props.getDialogFeedback) {
            if (this.props.isShowOpenExampleDialog) {
                if (!this.props.projectChanged) {
                    console.log("Save finish to handleOpenExample");
                    this.handleOpenExample(this.state.selected);
                }
            }
            this.props.setDialogFeedback(false);
        }
    }

    componentWillUnmount() {
        this.props.onRequestCloseExample();
    }

    handleItemSelect(item) {
        console.log("item: ", item)
        //show selected example dialog
        this.setState({
            selected: item
        });
        console.log("getCanvas = ", this.props.getWorkspace.getCanvas());
        this.props.onShowExampleDialog();
    }

    handleOpenExample(item) {
        console.log("item: ", item)

        const {
            vm,
            onRequestClose,
            onRequestCloseExample,
            onUpdateProjectTitle,
            setProjectPreTitle,
            getWorkspace
        } = this.props;
        // reset workspace scale
        setTimeout(() => {
            if (getWorkspace) {
                let workspaceMetrics = { scrollX: 0, scrollY: 0, scale: BLOCKS_DEFAULT_SCALE }
                getWorkspace.scrollX = workspaceMetrics.scrollX;
                getWorkspace.scrollY = workspaceMetrics.scrollY;
                getWorkspace.scale = workspaceMetrics.scale;
                getWorkspace.setScale(workspaceMetrics.scale)
                getWorkspace.resize();
            }
        });
        // if (getWorkspace) {
        //     let workspaceMetrics = { scrollX: 0, scrollY: 0, scale: BLOCKS_DEFAULT_SCALE }
        //     getWorkspace.scrollX = workspaceMetrics.scrollX;
        //     getWorkspace.scrollY = workspaceMetrics.scrollY;
        //     getWorkspace.scale = workspaceMetrics.scale;
        //     getWorkspace.setScale(workspaceMetrics.scale)
        //     getWorkspace.resize();
        // }

        // reset start block position
        let resetProjectData = JSON.parse(item.workspace);
        let centerX = 100;
        let blockHeaderID = ["!5g-lQYkGvcn!|DZpya^", "-}KW[4;S9L9P4)U8Y(vQ"];
        if (resetProjectData && resetProjectData.targets && resetProjectData.targets[0].blocks[DEFAULT_BLOCKID.event_whenstarted]) {
            resetProjectData.targets[0].blocks[DEFAULT_BLOCKID.event_whenstarted].x = centerX;
            resetProjectData.targets[0].blocks[DEFAULT_BLOCKID.event_whenstarted].y = 220;
            // reset comments position
            if (resetProjectData.targets[0].comments["m8rC]l:-m~bBDWb+otUU"]) {
                resetProjectData.targets[0].comments["m8rC]l:-m~bBDWb+otUU"].x = centerX;
                resetProjectData.targets[0].comments["m8rC]l:-m~bBDWb+otUU"].y = 0
                resetProjectData.targets[0].comments["m8rC]l:-m~bBDWb+otUU"].width = 305;
                resetProjectData.targets[0].comments["m8rC]l:-m~bBDWb+otUU"].height = 200;
            }
            // reset second or more start block position
            let blockKey = DEFAULT_BLOCKID.event_whenstarted;
            let offsetY = 220;
            for (const id of blockHeaderID) {
                console.log("blockKey = ", blockKey);
                if (resetProjectData.targets[0].blocks[id]) {
                    let count = 1;
                    do {
                        count += 1;
                        blockKey = resetProjectData.targets[0].blocks[blockKey].next;
                    } while (blockKey);
                    console.log("count = ", count);
                    offsetY = offsetY + count * 40;
                    resetProjectData.targets[0].blocks[id].x = centerX;
                    resetProjectData.targets[0].blocks[id].y = offsetY;

                    // Change blockKey when id is exist
                    blockKey = id;
                }
            }
        }
        //open selected example
        vm.loadProject(JSON.stringify(resetProjectData)).then(() => {
            console.log("success, project name: ", item.name);
            setProjectPreTitle(item.name);
            onUpdateProjectTitle(item.name);
            store.set(LocalKey.nowPreSavePath, "");
            store.set(LocalKey.nowSavePath, "");
        }).catch(error => {
            console.log(error);
        });
        onRequestCloseExample();
        onRequestClose();
    }

    handleOpenQuestionDialog() {
        if (this.props.projectChanged) {
            this.props.onShowOpenExampleDialog();
        }
        else {
            this.handleOpenExample(this.state.selected);
        }
    }

    handleCopyBlocks() {
        log.log("handleCopyBlocks");
        const {
            vm,
            onRequestClose,
            onRequestCloseExample
        } = this.props;
        let item = this.state.selected;
        let resetProjectData = JSON.parse(item.workspace);
        vm.loadVariables(JSON.stringify(resetProjectData)).then(() => {
            for (var index in this.state.selected.copy) {
                log.log(`this.state.selected.copy[${index}] = ${this.state.selected.copy[index]}`);
                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(this.state.selected.copy[index], "text/xml");
                let updateBound = this.props.getWorkspace.getBlocksBoundingBox();
                xmlDoc.getElementsByTagName("block")[0].setAttribute("x", updateBound.x + updateBound.width + 10);
                xmlDoc.getElementsByTagName("block")[0].setAttribute("y", updateBound.y);
                this.props.getWorkspace.pasteBlock_(xmlDoc.getElementsByTagName("block")[0]);
            }
            this.props.getWorkspace.copyBlockCenter();
            onRequestCloseExample();
            onRequestClose();
        }).catch(error => {
            log.error("handleCopyBlocks error: " + error);
        });
    }

    handleNoSave() {
        this.handleOpenExample(this.state.selected);
    }

    handleSave() {
        this.props.onSave();
    }

    render() {
        // const examplesLibraryContent = deepmerge(exampleString[store.get("locale", DEFAULT_LOCALE)], exampleFile[store.get("locale",DEFAULT_LOCALE)]);
        const examplesLibraryContent = deepmerge.all([exampleString[store.get("locale", DEFAULT_LOCALE)], exampleFile[store.get("locale", DEFAULT_LOCALE)], exampleImage[this.props.uis][store.get("locale", DEFAULT_LOCALE)]]);
        console.log("examplesLibraryContent: ", examplesLibraryContent)
        let hasTags = [];
        const examplesLibraryThumbnailData = Object.keys(examplesLibraryContent)
            .map(id => {
                hasTags = hasTags.concat(
                    examplesLibraryContent[id].tags.filter((element) => !hasTags.includes(element))
                );
                return ({
                    icon: examplesLibraryContent[id].icon,
                    blockImg: examplesLibraryContent[id].img,
                    level: examplesLibraryContent[id].level,
                    id: id,
                    name: examplesLibraryContent[id].name,
                    direction: examplesLibraryContent[id].direction,
                    featured: true,
                    tags: examplesLibraryContent[id].tags,
                    mainTag: examplesLibraryContent[id].mainTag,
                    brainType: examplesLibraryContent[id].brainType,
                    hidden: examplesLibraryContent[id].hidden || false,
                    copy: examplesLibraryContent[id].copy,
                    workspace: examplesLibraryContent[id].workspace,
                })
            });
        examplesLibraryThumbnailData.sort((a, b) => (a.level - b.level));    //leve desc
        console.log("hasTags = ", hasTags);
        let filterTags = exampleTags.filter((element) => hasTags.includes(element.tag));

        if (!this.props.visible) return null;
        return (
            <div>
                <ExampleLibraryComponent
                    filterable
                    data={examplesLibraryThumbnailData}
                    id="exampleLibrary"
                    tags={filterTags}
                    brainTags={exampleBrainTags}
                    title={this.props.intl.formatMessage(messages.exampleLibraryTitle)}
                    visible={this.props.visible}
                    onItemSelected={this.handleItemSelect}
                    onRequestClose={this.props.onRequestClose}
                    uis={this.props.uis}
                />
                {this.props.isShowExampleDialog ?
                    <ExampleDialog
                        show={this.props.isShowExampleDialog}
                        onClose={this.props.onRequestCloseExample}
                        onOpenQuestionDialog={this.handleOpenQuestionDialog}
                        onCopyBlocks={this.handleCopyBlocks}
                        data={this.state.selected}
                    />
                    : null}
                {this.props.isShowOpenExampleDialog ?
                    <QuestionDialog
                        show={this.props.isShowOpenExampleDialog}
                        type={questionType.QUESTION_OPEN_EXAMPLE}
                        onNoSave={this.handleNoSave}
                        onSave={this.handleSave}
                    />
                    : null}
            </div>
        );
    }
}

ExampleLibrary.propTypes = {
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    projectChanged: PropTypes.bool,
    projectFilename: PropTypes.string,
    vm: PropTypes.shape({
        loadProject: PropTypes.func,
        saveProjectSb3: PropTypes.func
    }),
    isShowExampleDialog: PropTypes.bool,
    onShowExampleDialog: PropTypes.func,
    onRequestCloseExample: PropTypes.func,
    isShowOpenExampleDialog: PropTypes.bool,
    onShowOpenExampleDialog: PropTypes.func,
    onRequestCloseOpenExample: PropTypes.func,
    setDialogFeedback: PropTypes.func,
    getDialogFeedback: PropTypes.bool,
    getWorkspace: PropTypes.object,
    setProjectPreTitle: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    getPickedBrainType: PropTypes.string,
    editorDisplayMode: PropTypes.string,
    uis: PropTypes.string,
    onSave: PropTypes.func,
};

const mapStateToProps = state => ({
    visible: state.scratchGui.modals.exampleLibrary,
    projectId: state.scratchGui.projectState.projectId,
    projectChanged: state.scratchGui.projectChanged,
    projectFilename: projectTitle(state),
    vm: state.scratchGui.vm,
    isShowExampleDialog: exampleDialogShow(state),
    isShowOpenExampleDialog: quesitonDialogShow(state, questionType.QUESTION_OPEN_EXAMPLE),
    getDialogFeedback: getFeedback(state),
    getWorkspace: getWorkspace(state),
    getPickedBrainType: getPickedBrainType(state),
    editorDisplayMode: getEditorDisplayMode(state)
});

const mapDispatchToProps = dispatch => ({
    onRequestClose: () => dispatch(closeExampleLibrary()),
    onShowExampleDialog: () => dispatch(showExampleDialog()),
    onRequestCloseExample: () => dispatch(hideExampleDialog()),
    onShowOpenExampleDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_OPEN_EXAMPLE)),
    onRequestCloseOpenExample: () => dispatch(hideQuestionDialog(questionType.QUESTION_OPEN_EXAMPLE)),
    setDialogFeedback: status => dispatch(setFeedback(status)),
    setProjectPreTitle: title => dispatch(setProjectPreTitle(title))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExampleLibrary));
