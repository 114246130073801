import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import closeIcon from './svg/close_btn.svg';
import { parseReleaseNoteJson } from './utilities';

class HistoryDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'setExpand'
        ]);
        this.state = {
            expand: [],
        }
    }
    componentDidMount() {
        this.setState({ expand: [] });
    }
    componentDidUpdate(prevProps) {
    }

    onClose() {
        this.props.onClose();
    }

    getContent(releaseNote, locale) {
        let releaseNoteObj = [];
        let item = null;
        if (releaseNote['data'][locale] && releaseNote['data'][locale].length > 0) {
            releaseNote['data'][locale].forEach((paragraph) => {
                let content = [];
                paragraph.content.forEach((word) => {
                    if (word.length > 0) {
                        item = (
                            <tr className={styles.historyContentTr}>
                                {(paragraph.title.length > 0) ? <td width="5%"></td> : null}
                                <td width="1%" className={styles.historyContentTd}>．</td>
                                <td width="94%">{word}</td>
                            </tr>);
                        content.push(item);
                    }
                })
                if (paragraph.title.length > 0) {
                    item = (
                        <tr className={styles.historyContentTrTitle}>
                            <td colspan="3">{paragraph.title}</td>
                        </tr>);
                    releaseNoteObj.push(item);
                }
                releaseNoteObj.push(content);
            })
        }
        return (
            <table className={styles.historyContentTable}>
                <tbody>
                    {releaseNoteObj}
                </tbody>
            </table>
        );
    }

    setExpand(index) {
        let expand = this.state.expand;
        if (this.state.expand && this.state.expand.length > 0) {
            let position = this.state.expand.indexOf(index);
            if (position != -1) {
                expand.splice(position, 1);
            } else {
                expand.push(index);
            }
        } else {
            expand.push(index);
        }
        this.setState({ expand: expand });
    }

    getVersionCards() {
        let cards = [];
        parseReleaseNoteJson(this.props.locale).forEach((releaseNote, index) => {
            let item = (
                <VersionCard
                    key={index}
                    index={index}
                    locale={this.props.locale}
                    releaseNote={releaseNote}
                    setExpand={this.setExpand}
                    expandList={this.state.expand}
                    getContent={this.getContent}
                />)
            cards.push(item);
        })

        return cards;
    }

    render() {
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle, styles.license)} >
                    <div className={styles.aboutHeader}>
                        <div className={styles.aboutTitle}>
                            <FormattedMessage
                                defaultMessage="Version History"
                                description="Version History"
                                id="gui.dialog.history.version.list"
                            />
                        </div>
                        <div className={styles.aboutClose} onClick={this.props.onClose}>
                            <img src={closeIcon} />
                        </div>
                    </div>
                    <div className={classNames(styles.questionView, styles.history)}>
                        {this.getVersionCards()}
                    </div>
                </div>
            </div>
        );
    }
}

const VersionCard = (props) => {
    const {
        key,
        index,
        locale,
        releaseNote,
        setExpand,
        expandList,
        getContent
    } = props
    let lineHeight = 0;
    releaseNote['data'][locale].forEach((paragraph) => {
        if (paragraph['content'].title) {
            lineHeight += 20;
        }
        lineHeight += paragraph['content'].length * 17;
    })
    return <div className={styles.historyContent}>
        <div className={styles.historyCard}>
            <div key={releaseNote.version} className={styles.aboutLicenseItem}>
                <div className={styles.aboutLicenseItemVersion}>
                    {releaseNote.version}
                </div>
                <div className={styles.aboutLicenseItemTime}>
                    {releaseNote.time}
                </div>
            </div>
            <div className={classNames(styles.aboutButton, styles.latestUpdateContentButton)} onClick={() => setExpand(index)}>
                <FormattedMessage
                    defaultMessage="Watch"
                    description="Watch"
                    id="gui.dialog.history.version.watch"
                />
            </div>
        </div>
        {expandList.indexOf(index) != -1 ? <div className={styles.historyData} style={{ height: `${lineHeight}px` }}>
            {getContent(releaseNote, locale)}
        </div> : null}
    </div>

}

HistoryDialog.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool,
    locale: PropTypes.string,
};

HistoryDialog.defaultProps = {
    onClose: () => { },
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HistoryDialog);