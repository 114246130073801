import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import styles from './animation.css';
import { ANIMATION_TYPE } from './animation-type';
import { ANIMATION_MODE } from './animation-mode';

import {
    ANIMATION_MOUSE_TRANSLATE,
    ANIMATION_ARROW_RIGHT,
    ANIMATION_LONG_RIGHT_ARROW
} from './animations.js';

class Animation extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, ['next', 'getAnimation']);
        this.animation = this.getAnimation();
        if (this.animation.type == ANIMATION_MODE.PLAY_FRAME) {
            this.images = this.animation.frames;
        } else if (this.animation.type == ANIMATION_MODE.TRANSLATE) {
            this.image = this.animation.source;
            this.translateFrames = this.animation.stop - this.animation.start;
            this.dx = (this.props.endX - this.props.startX) / this.translateFrames;
            this.dy = (this.props.endY - this.props.startY) / this.translateFrames;
            this.opacityChange = 1 / (this.animation.end - this.animation.stop);
        }
        this.isRunning = true;
        this.state = {
            index: 0,
            opacity: 1.0,
            x: this.props.startX,
            y: this.props.startY,
        };
    }

    componentDidMount() {
        this.next();
    }

    componentDidUpdate(prevProps) {
        if (this.animation.type == ANIMATION_MODE.TRANSLATE) {
            if (this.props.startX != prevProps.startX || this.props.startY != prevProps.startY ||
                this.props.endX != prevProps.endX || this.props.endY != prevProps.endY) {
                // update translate dx and dy
                this.dx = (this.props.endX - this.props.startX) / this.translateFrames;
                this.dy = (this.props.endY - this.props.startY) / this.translateFrames;
            }
        }
    }

    componentWillUnmount() {
        this.isRunning = false;
    }

    getAnimation() {
        switch (this.props.animation) {
            case ANIMATION_TYPE.MOUSE_TRANSLATE:
                return ANIMATION_MOUSE_TRANSLATE;
            case ANIMATION_TYPE.ARROW_RIGHT:
                return ANIMATION_ARROW_RIGHT;
            case ANIMATION_TYPE.LONG_RIGHT_ARROW:
                return ANIMATION_LONG_RIGHT_ARROW;
        }
    }

    next() {
        if (!this.isRunning) {
            return;
        }
        setTimeout(() => {
            switch (this.animation.type) {
                case ANIMATION_MODE.PLAY_FRAME:
                    this.setState({ index: (this.state.index + 1) % this.images.length });
                    break;
                case ANIMATION_MODE.TRANSLATE:
                    let nextIndex = (this.state.index + 1) % this.animation.end;
                    if (nextIndex < this.animation.start) {
                        this.setState({
                            index: nextIndex,
                            x: this.props.startX,
                            y: this.props.startY,
                            opacity: 1.0
                        });
                    } else if (nextIndex < this.animation.stop) {
                        this.setState({
                            index: nextIndex,
                            x: this.props.startX + this.dx * (nextIndex - this.animation.start + 1),
                            y: this.props.startY + this.dy * (nextIndex - this.animation.start + 1)
                        })
                    } else if (nextIndex < this.animation.end) {
                        this.setState({
                            index: nextIndex,
                            x: this.props.endX,
                            y: this.props.endY,
                            opacity: 1 - this.opacityChange * (nextIndex - this.animation.stop + 1)
                        });
                    }
                    break;
            }
            this.next();
        }, this.animation.interval);
    }

    render() {
        switch (this.animation.type) {
            case ANIMATION_MODE.PLAY_FRAME:
                return (
                    <img className={styles.animationFit} src={this.images[this.state.index]} alt={"animation"} />
                )
            case ANIMATION_MODE.TRANSLATE:
                return (
                    <img className={styles.arrow} style={{ left: `${this.state.x}px`, top: `${this.state.y}px`, opacity: `${this.state.opacity}` }} src={this.image} alt={"arrow"} />
                )
        }
    }
}

Animation.propTypes = {
    animation: PropTypes.number.isRequired,
    startX: PropTypes.number,
    startY: PropTypes.number,
    endX: PropTypes.number,
    endY: PropTypes.number,
};

Animation.defaultProps = {
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Animation));