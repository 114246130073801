import log from '../lib/log.js';

const SET_BLOCKS = 'scratch-gui/block/SET_BLOCKS';
const SET_WORKSPACE = 'scratch-gui/block/SET_WORKSPACE';
const SET_BLOCK_HELP_SELECT_ITEM = 'scratch-gui/block/BLOCK_HELP_SELECT_ITEM';
const SET_BLOCK_HELP_SELECT_TYPE = 'scratch-gui/block/BLOCK_HELP_SELECT_TYPE';


const BLOCKS = 'blocks';
const WORKSPACE = 'workspace';
const BLOCK_HELP_SELECT_ITEM = 'blockhelpselectitem'
const BLOCK_HELP_SELECT_TYPE = 'blockhelpselecttype'

const defaultBlocks = undefined;
const defaultWorkspace = undefined;
const initialState = {
    [BLOCKS]: defaultBlocks,
    [WORKSPACE]: defaultWorkspace,
    [BLOCK_HELP_SELECT_ITEM]: "",
    [BLOCK_HELP_SELECT_TYPE]: ""
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_BLOCKS:
            console.log("SET_BLOCKS");
            return Object.assign({}, state, {
                [BLOCKS]: action.blocks
            });
        case SET_WORKSPACE:
            console.log("SET_WORKSPACE");
            return Object.assign({}, state, {
                [WORKSPACE]: action.workspace
            });
        case SET_BLOCK_HELP_SELECT_ITEM:
            return Object.assign({}, state, {
                [BLOCK_HELP_SELECT_ITEM]: action.blcokHelpBlockItem
            });
        case SET_BLOCK_HELP_SELECT_TYPE:
            return Object.assign({}, state, {
                [BLOCK_HELP_SELECT_TYPE]: action.blcokHelpBlockType
            });
        default:
            return state;
    }
};

const setBlocks = (blocks) => ({
    type: SET_BLOCKS,
    blocks: blocks
});

const setWorkspace = (workspace) => {
    log.info('block reducers setWorkspace: ' + typeof workspace);
    return ({
        type: SET_WORKSPACE,
        workspace: workspace
    })
};

const setBlockHelpBlockItem = (blcokHelpBlockItem) => ({
    type: SET_BLOCK_HELP_SELECT_ITEM,
    blcokHelpBlockItem: blcokHelpBlockItem
});

const setBlockHelpBlockType = (blcokHelpBlockType) => ({
    type: SET_BLOCK_HELP_SELECT_TYPE,
    blcokHelpBlockType: blcokHelpBlockType
});

const getBlockHelpBlockItem = (state) => state.scratchGui.block[BLOCK_HELP_SELECT_ITEM];
const getBlockHelpBlockType = (state) => state.scratchGui.block[BLOCK_HELP_SELECT_TYPE];

const getWorkspace = (state) => {
    if (typeof state.scratchGui.block[WORKSPACE] != 'object') {
        log.info('block reducers getWorkspace: ' + typeof state.scratchGui.block[WORKSPACE]);
    }
    return state.scratchGui.block[WORKSPACE];
};

export {
    reducer as default,
    initialState as blocksInitialState,
    setBlocks,
    setWorkspace,
    getWorkspace,
    setBlockHelpBlockItem,
    getBlockHelpBlockItem,
    setBlockHelpBlockType,
    getBlockHelpBlockType
};
