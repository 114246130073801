import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import styles from './brain.css';
import Port from '../port/port.jsx';
import TypeCPort from '../port/type-c-port.jsx';
import EntryPoint from './entry-point.jsx';
import EntryLine from './entry-line.jsx';
import VM from 'scratch-vm';
import {
    openDeviceList,
    isEditPageOpen,
    isAddPageOpen,
    isPortOpen,
    isCheckingImportDevice,
    getPortArray,
    setPortArray
} from '../../../reducers/device-manager-controller';

import arrowImg from '../pictures/arrow.svg'
import arrowWWImg from '../pictures/ww/arrow.svg'

import {
    isVisionSettingDialogShow,
} from '../../../reducers/dialog';
import {
    cleanPortOption,
} from '../../../reducers/select-option';

import {
    EditUtils,
    SUPPORT_TYPES,
} from '../edit-page/edit-utils.js';

import {
    getSelectedTab,
    TAB_INDEX
} from '../../../reducers/controller';

import {
    clearSkillList,
    clearSkillLocation
} from '../../../reducers/speaker';
import {
    isDeviceSelected,
    selectDeviceToDelete,
    cleanSeletedDevice,
    getSelectedDeviceIdArray,
    isDeleteDeviceMode,
    openDeleteMode,
    closeDeleteMode
} from '../../../reducers/delete-device';

import brainImg from '../pictures/brain_entry.png'

import {
    getUIStyle,
    uiType
} from '../../../reducers/ui-style';
import {
    getColorDataDefaultList,
} from '../../../reducers/vision';

const messagesId = {
    delete: "gui.deviceManagerStage.page.delete",
    selectAll: "gui.deviceManagerStage.page.selectAll",
    cancel: "gui.deviceManagerStage.page.cancel",
    edit: "gui.deviceManagerStage.page.edit"
}

export const parseDeviceList = deviceList => {
    var result = [
        { port: "1", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "2", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "3", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "4", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "5", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "6", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "L", portType: "typeC", type: "", name: "", id: "", other: "" },
        { port: "12", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "11", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "10", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "9", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "8", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "7", portType: "smart", type: "", name: "", id: "", other: "" },
        { port: "R", portType: "typeC", type: "", name: "", id: "", other: "" },
    ]
    if (Array.isArray(deviceList)) {
        result.forEach(element => {
            deviceList.forEach(device => {
                if (device.connectPortArray.includes(element.port)) {
                    element.type = device.type;
                    element.name = device.name;
                    element.id = device.id;
                }
                if (device.other && device.other.gyro && device.other.gyro == element.port) {
                    element.other = 'gyro'
                    element.name = device.other.gyroName ? device.other.gyroName : EditUtils.getLocaleString("gui.device.drivetrain.gyro");
                }
            });
        });
    }
    return result;
}

const POSTION = {
    LEFT: 'left',
    RIGHT: 'right'
}

const PORT_ON_IMAGE_POSITION = {
    LEFT: ["7", "8", "9", "10", "11", "12"],
    RIGHT: ["1", "2", "3", "4", "5", "6", "L", "R"]

}

class EntryBrain extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            brainPosition: POSTION.RIGHT,
            refreshByImport: false,
            refreshByVision: false,
            arrowImg: this.props.getUIStyle == uiType.ww ? arrowWWImg : arrowImg,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isDeviceListOpen != prevProps.isDeviceListOpen) this.setState({ deviceList: parseDeviceList(this.props.vm.getDeviceList()) })
        if (this.props.getSelectedTab != prevProps.getSelectedTab && this.props.getSelectedTab == TAB_INDEX.brain) this.setState({ brainPosition: POSTION.RIGHT });
        if (this.props.isCheckingImportDevice != prevProps.isCheckingImportDevice) this.setState({ refreshByImport: this.props.isCheckingImportDevice });
        if (this.props.isVisionSettingDialogShow != prevProps.isVisionSettingDialogShow) {
            this.setState({ refreshByVision: this.props.isVisionSettingDialogShow });
        }
        if (prevProps.getPortArray != this.props.getPortArray) {
            this.autoSwitchBrain();
        }
    }

    autoSwitchBrain() {
        let portArray = Array.from(this.props.getPortArray);
        if (!Array.isArray(portArray) || portArray.length <= 0) return;
        portArray.sort((a, b) => a - b);
        this.setState({ brainPosition: PORT_ON_IMAGE_POSITION.LEFT.includes(portArray[0]) ? POSTION.LEFT : POSTION.RIGHT })
        this.props.cleanPortArray();
    }

    parseDevicePort(deviceList) {
        let result = []
        deviceList.forEach(device => {
            if (device.id && device.id.length > 0) {
                result.push(device.port)
            }
        });
        return result;
    }

    parseDeviceId(deviceList) {
        let selectedDeviceArray = this.props.getSelectedDeviceIdArray;
        let result = [];
        selectedDeviceArray.forEach(port => {
            deviceList.forEach(device => {
                if (device.port == port && !result.includes(device.id)) {
                    result.push(device.id)
                }
            });
        })
        return result;
    }

    handleClickTrashcan(deviceList) {
        if (!this.isTrashcanEnable()) return
        if (this.props.isDeleteDeviceMode) {
            let isIncludeVision = deviceList.filter(portInfo => portInfo.type == SUPPORT_TYPES.vision)[0]
            if (isIncludeVision) {
                this.props.vm.initCvTagSettings(this.props.colorDataDefaultList);
                this.props.vm.clearCvProfiles();
                this.props.vm.clearProfilePhoteSave();
            }
            let isIncludeSpeaker = deviceList.filter(portInfo => portInfo.type == SUPPORT_TYPES.speaker)[0]
            if (isIncludeSpeaker) {
                this.props.vm.clearSpeakerSettings()
                this.props.clearSkillList()
                this.props.clearSkillLocation()
            }
            this.props.vm.removeMultiDeviceById(this.parseDeviceId(deviceList))
            if (this.props.isDeviceSelected) {
                this.props.closeDelete();
            }
        } else {
            this.props.openDelete()
        }
        this.props.openDeviceList()
    }

    handleClickSelectAll(deviceList) {
        if (deviceList && this.props.isDeleteDeviceMode) {
            var deviceIdArray = this.parseDevicePort(deviceList);
            (this.props.getSelectedDeviceIdArray.length == deviceIdArray.length) ? this.props.cleanSeletedDevice() : this.props.seletDevice(deviceIdArray);
        }
    }

    handleClickBack() {
        if (this.props.isDeleteDeviceMode) {
            this.props.closeDelete();
        }
    }

    handleClickMove(direction) {
        if (this.state.brainPosition != direction) {
            this.setState({ brainPosition: direction })
        }
    }

    isTrashcanEnable() {
        return (this.props.vm.getDeviceList() && this.props.vm.getDeviceList().length > 0 && !this.props.isEditPageOpen && !this.props.isAddPageOpen)
    }

    isBrainLeft() {
        return this.state.brainPosition == POSTION.LEFT;
    }

    render() {
        const {
            vm,
        } = this.props;
        var deviceList = parseDeviceList(vm.getDeviceList());
        return (
            <div>
                {this.props.isDeleteDeviceMode ?
                    <div>
                        <div className={classNames((this.props.isDeviceSelected) ? styles.trashcan : styles.trashcanDisable,
                            (this.props.isDeviceSelected) ? styles.trashcanDiff : styles.trashcanDisableDiff)} onClick={() => this.handleClickTrashcan(deviceList)}>
                            <FormattedMessage id={messagesId.delete} />
                        </div>
                        <div className={classNames(styles.closeDeleteMode, styles.closeDeleteModeDiff)} onClick={() => this.handleClickBack()}>
                            <FormattedMessage id={messagesId.cancel} />
                        </div>
                        <div className={classNames(styles.selectAll, styles.selectAllDiff)} onClick={() => this.handleClickSelectAll(deviceList)}>
                            <FormattedMessage id={messagesId.selectAll} />
                        </div>
                    </div> :
                    <div className={classNames((this.isTrashcanEnable()) ? styles.trashcan : styles.trashcanDisable,
                        (this.isTrashcanEnable()) ? styles.trashcanDiff : styles.trashcanDisableDiff)} onClick={() => this.handleClickTrashcan(deviceList)}>
                        <FormattedMessage id={messagesId.edit} />
                    </div>}
                {(this.isBrainLeft()) ?
                    <div className={classNames(styles.buttonLeft)} onClick={() => this.handleClickMove(POSTION.RIGHT)}><img src={this.state.arrowImg} alt={'right arrow'} /></div>
                    : <div className={classNames(styles.buttonRight)} onClick={() => this.handleClickMove(POSTION.LEFT)}><img src={this.state.arrowImg} alt={'left arrow'} /></div>
                }
                <div className={classNames(styles.brainTopEntryOnly, (this.isBrainLeft()) ? styles.brainLeftEntryLeftOnly : styles.brainLeftEntryRightOnly, styles.brainImgAnimation)}>
                    <div className={classNames(styles.brainEntryImg, styles.brainImgAnimation)}>
                        <img src={brainImg} className={classNames(styles.brainEntrySize)} alt={'brain picture'} />
                    </div>
                    <div className={classNames(styles.portEntryArea, styles.portAreaAnimation, (this.isBrainLeft()) ? styles.portAreaLeftLeft : styles.portAreaRightLeft)}>
                        <div className={classNames(styles.portEntry1, styles.portEntryPosition)}>
                            <Port index={0} connectedDeviceList={deviceList} hide={this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry2, styles.portEntryPosition)}>
                            <Port index={1} connectedDeviceList={deviceList} hide={this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry3, styles.portEntryPosition)}>
                            <Port index={2} connectedDeviceList={deviceList} hide={this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry4, styles.portEntryPosition)}>
                            <Port index={3} connectedDeviceList={deviceList} hide={this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry5, styles.portEntryPosition)}>
                            <Port index={4} connectedDeviceList={deviceList} hide={this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry6, styles.portEntryPosition)}>
                            <Port index={5} connectedDeviceList={deviceList} hide={this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <EntryLine reverse={false} />
                        <EntryPoint deviceList={deviceList} />
                    </div>
                    <div className={classNames(styles.portEntryArea, styles.portAreaAnimation, (this.isBrainLeft()) ? styles.portAreaLeftRight : styles.portAreaRightRight)}>
                        <div className={classNames(styles.portEntry1, styles.portEntryPosition)}>
                            <Port index={7} connectedDeviceList={deviceList} hide={!this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry2, styles.portEntryPosition)}>
                            <Port index={8} connectedDeviceList={deviceList} hide={!this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry3, styles.portEntryPosition)}>
                            <Port index={9} connectedDeviceList={deviceList} hide={!this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry4, styles.portEntryPosition)}>
                            <Port index={10} connectedDeviceList={deviceList} hide={!this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry5, styles.portEntryPosition)}>
                            <Port index={11} connectedDeviceList={deviceList} hide={!this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <div className={classNames(styles.portEntry6, styles.portEntryPosition)}>
                            <Port index={12} connectedDeviceList={deviceList} hide={!this.isBrainLeft()} isBrainLeft={this.isBrainLeft()} vm={vm} />
                        </div>
                        <EntryLine reverse={true} />
                        <EntryPoint deviceList={deviceList.slice(7)} />
                        <TypeCPort index={6} connectedDeviceList={deviceList} hide={false} isFirst={true} vm={vm} />
                        <TypeCPort index={13} connectedDeviceList={deviceList} hide={false} isFirst={deviceList[6].id == ""} vm={vm} />
                    </div>
                </div>
            </div >
        );
    }
}

EntryBrain.propTypes = {
    isEditPageOpen: PropTypes.bool,
    isAddPageOpen: PropTypes.bool,
    isDeleteDeviceMode: PropTypes.bool,
    isDeviceListOpen: PropTypes.bool,
    getSelectedDeviceIdArray: PropTypes.array,
    vm: PropTypes.instanceOf(VM).isRequired,
};


const mapStateToProps = state => ({
    isEditPageOpen: isEditPageOpen(state),
    isAddPageOpen: isAddPageOpen(state),
    isDeleteDeviceMode: isDeleteDeviceMode(state),
    getSelectedDeviceIdArray: getSelectedDeviceIdArray(state),
    isDeviceSelected: isDeviceSelected(state),
    isDeviceListOpen: isPortOpen(state),
    getSelectedTab: getSelectedTab(state),
    isCheckingImportDevice: isCheckingImportDevice(state),
    isVisionSettingDialogShow: isVisionSettingDialogShow(state),
    getPortArray: getPortArray(state),
    getUIStyle: getUIStyle(state),
    colorDataDefaultList: getColorDataDefaultList(state)
});

const mapDispatchToProps = dispatch => ({
    closeDelete: () => dispatch(closeDeleteMode()),
    openDelete: () => dispatch(openDeleteMode()),
    cleanSeletedDevice: () => dispatch(cleanSeletedDevice()),
    seletDevice: (array) => dispatch(selectDeviceToDelete(array)),
    openDeviceList: (number) => dispatch(openDeviceList(number)),
    cleanPortOption: () => dispatch(cleanPortOption()),
    cleanPortArray: () => dispatch(setPortArray([])),
    clearSkillList: () => dispatch(clearSkillList()),
    clearSkillLocation: () => dispatch(clearSkillLocation()),
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryBrain));