import sensingBlocks from '../block-vertical-blocks/sensing';

export default function sensingWEBVR(deviceInfo, blockSeparator) {

    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8">
        ${sensingBlocks.sensing_resettimer}
        ${sensingBlocks.sensing_timerinseconds}
        ${deviceInfo.bumperInfo.hasbumper ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_bumperpressed_vr}
        `: ``}
        ${deviceInfo.lineTrackerInfo.haslineTracker ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_linetrackerreflectivityin_vr}
        `: ``}
        ${deviceInfo.ultrasonicInfo.hasultrasonic ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_sonarfoundobject_vr}
            ${sensingBlocks.sensing_sonardistancein_vr}
        `: ``}
    </category>
    `;
};