import sensingBlocks from '../block-vertical-blocks/sensing';

export default function sensingEntry(deviceInfo, blockSeparator) {

    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8">
        ${sensingBlocks.sensing_resettimer}
        ${sensingBlocks.sensing_timerinseconds}
        ${blockSeparator}
        ${sensingBlocks.sensing_cursorrow}
        ${blockSeparator}
        ${sensingBlocks.sensing_brainbuttonpressed}
        ${deviceInfo.controllerInfo.hasController ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_controllerbuttonpressed}
            ${sensingBlocks.sensing_controllerposition}
        `: ``}
        ${deviceInfo.motorInfo.hasMotor ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_motorisdone}
            ${sensingBlocks.sensing_motorisspinning}
            ${blockSeparator}
            ${sensingBlocks.sensing_motorposition}
            ${sensingBlocks.sensing_motorvelocity}
            ${sensingBlocks.sensing_motorcurrent}
        `: ``}
        ${deviceInfo.drivetrainInfo.hasDrivetrain ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_drivetraindriveisdone}
            ${sensingBlocks.sensing_drivetraindriveismoving}
            ${blockSeparator}
        `: ``}
        ${deviceInfo.drivetrainInfo.hasDrivetrainwithGyro ? `
            ${sensingBlocks.sensing_drivetraindriveheading}
            ${sensingBlocks.sensing_drivetraindriverotation}
        `: ``}
        ${deviceInfo.drivetrainInfo.hasDrivetrain ? `
            ${sensingBlocks.sensing_drivetraindrivevelocityin}
            ${sensingBlocks.sensing_drivetraindrivecurrentin}
        `: ``}
        ${deviceInfo.touchLedInfo.hastouchLed ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_touchledpressed}
        `: ``}
        ${deviceInfo.gyroInfo.hasgyro || deviceInfo.drivetrainInfo.hasDrivetrainwithGyro ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_gyrocalibrateforseconds}
        `: ``}
        ${deviceInfo.gyroInfo.hasgyro ? `
            ${sensingBlocks.sensing_gyrosetheadingtodegree}
            ${sensingBlocks.sensing_gyrosetrotationtodegree}
            ${blockSeparator}
            ${sensingBlocks.sensing_gyroheadingindegree}
            ${sensingBlocks.sensing_gyrorotationindegree}
        `: ``}
        ${deviceInfo.colorSensorInfo.hascolorSensor ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_colorisnearobject}
            ${blockSeparator}
            ${sensingBlocks.sensing_colordetects}
            ${sensingBlocks.sensing_colorname}
            ${sensingBlocks.sensing_colorhueindegrees}
            ${sensingBlocks.sensing_colorbrightnessin}
        `: ``}
        ${deviceInfo.visionInfo.hasvision ? `
            ${blockSeparator}
            ${sensingBlocks.sensing_visiontakeasnapshot}
            ${sensingBlocks.sensing_visionqrcodeinthepicture}
            ${sensingBlocks.sensing_visionqrcodemessage}
            ${sensingBlocks.sensing_visionobjectexistsinthepicture}
            ${sensingBlocks.sensing_visiondetectedobjectcount}
            ${sensingBlocks.sensing_visionofdetectedobject}
            ${blockSeparator}
            ${sensingBlocks.sensing_detectingface}
            ${sensingBlocks.sensing_facedetected}
            ${blockSeparator}
            ${sensingBlocks.sensing_visionWhoseFaceInThePicture}
            ${sensingBlocks.sensing_visionInThePicture}
            ${sensingBlocks.sensing_visionProbabilityOfInThePicture}
        `: ``}
    </category>
    `;
};