import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import store from 'store';

import { connect } from 'react-redux';

import { updateTargets } from '../reducers/targets';
import { updateBlockDrag } from '../reducers/block-drag';
import { setProjectChanged, setProjectUnchanged } from '../reducers/project-changed';
import { setRunningState, setTurboState, setStartedState } from '../reducers/vm-status';
import { setDeviceChanged } from '../reducers/device';
import { closeDeviceList, openDeviceList, enableCheckingImportDevice } from '../reducers/device-manager-controller';
import { cleanPortOption, } from '../reducers/select-option';
import { closeDeleteMode, } from '../reducers/delete-device';
import { STAGE_SIZE_MODES, STAGE_DISPLAY_TYPE } from '../lib/layout-constants';
import {
    setStageSize,
    viewPage,
    setFullScreen
} from '../reducers/stage-size';
import { hideDeviceHelpDialog, hideBlockHelpDialog, hideBoardGameDialog, hideStreamingDialog } from '../reducers/dialog';
import { cleanTask } from '../reducers/task';
import { cleanControllerReducer, cleanSelectedTab } from '../reducers/controller';
import { updateFlyoutVisible, updateToolboxVisible } from '../reducers/toolbox';
import { setColorDataList, setFaceIdentificationList } from '../reducers/vision';
import { clearTempConcepts } from '../reducers/speaker';
import {
    getWorkspace
} from '../reducers/block';
import {
    setEditorDisplayMode,
    setCode,
    setCodeView,
    setUndoState,
    setRedoState,
    setNewEditor
} from '../reducers/code-editor';
import {
    getDirectNew,
    resetDirectNew
} from '../reducers/project-state';
import {
    platformType,
    getPlatform,
    isPad
} from '../lib/platform';
import {
    BRAIN_TYPE
} from '../lib/brains.js';

import {
    getUnityMessageFunc
} from '../reducers/vr';

import {
    setPickedBrainType
} from '../reducers/picked-brain-type'
import { Catagory, FileAction, postMessage } from './postmessage';
import { projectIsNew } from '../reducers/project-title';

/*
 * Higher Order Component to manage events emitted by the VM
 * @param {React.Component} WrappedComponent component to manage VM events for
 * @returns {React.Component} connected component with vm events bound to redux
 */
const vmListenerHOC = function (WrappedComponent) {
    class VMListener extends React.Component {
        constructor(props) {
            super(props);
            bindAll(this, [
                'handleKeyDown',
                'handleKeyUp',
                'handleProjectChanged',
                'handleTargetsUpdate',
                'handleProjectSaved',
                'loadProjectFinish',
                'projectLoaded',
                'showToolboxandFlyout',
                'handleDeviceChanged'
            ]);
            // We have to start listening to the vm here rather than in
            // componentDidMount because the HOC mounts the wrapped component,
            // so the HOC componentDidMount triggers after the wrapped component
            // mounts.
            // If the wrapped component uses the vm in componentDidMount, then
            // we need to start listening before mounting the wrapped component.
            this.props.vm.on('targetsUpdate', this.handleTargetsUpdate);
            this.props.vm.on('BLOCK_DRAG_UPDATE', this.props.onBlockDragUpdate);
            this.props.vm.on('TURBO_MODE_ON', this.props.onTurboModeOn);
            this.props.vm.on('TURBO_MODE_OFF', this.props.onTurboModeOff);
            this.props.vm.on('PROJECT_RUN_START', this.props.onProjectRunStart);
            this.props.vm.on('PROJECT_RUN_STOP', this.props.onProjectRunStop);
            this.props.vm.on('PROJECT_CHANGED', this.handleProjectChanged);
            this.props.vm.on('PROJECT_SAVED', this.handleProjectSaved);
            this.props.vm.on('RUNTIME_STARTED', this.props.onRuntimeStarted);
            this.props.vm.on('PROJECT_START', this.props.onGreenFlag);
            this.props.vm.on('DEVICE_CHANGED', this.handleDeviceChanged);
            this.props.vm.on('LOAD_DEVICE_FINISHED', this.loadProjectFinish);
            this.props.vm.on('PROJECT_LOADED', this.projectLoaded);
        }
        componentDidMount() {
            if (this.props.attachKeyboardEvents) {
                document.addEventListener('keydown', this.handleKeyDown);
                document.addEventListener('keyup', this.handleKeyUp);
            }
            this.props.vm.postIOData('userData', { username: this.props.username });
        }
        projectLoaded() {
            this.props.onProjectLoaded();
        }
        loadProjectFinish() {
            if (isPad()) {
                if (!this.props.projectIsNew && this.props.getDirectNew.enable != null) {
                    postMessage(Catagory.File, { action: FileAction.new, type: this.props.vm.getBrainType() });
                }
            }
            if (this.props.getDirectNew.enable) {
                console.log("loadProjectFinish direct new project:" + JSON.stringify(this.props.getDirectNew));
                this.props.vm.setBrainType(this.props.getDirectNew.brainType);
                this.props.vm.setEditMode(this.props.getDirectNew.editMode);
                this.props.setPickedBrainType(this.props.getDirectNew.brainType);
                this.props.resetDirectNew();
            }
            if ((this.props.vm.getBrainType() == BRAIN_TYPE.ENTRY) ||
                (this.props.vm.getBrainType() == BRAIN_TYPE.EDU_AND_ENTRY)) {
                this.props.enableCheckingImportDevice(false);
                setTimeout(() => {
                    this.props.enableCheckingImportDevice(true);
                }, 500)
            } else if (this.props.vm.getBrainType() == BRAIN_TYPE.WEB_VR) {
                console.log("loadProjectFinish getVRJson: ", this.props.vm.getVRJson());
                if (this.props.getUnityMessageFunc) {
                    if (this.props.vm.getVRJson() != "") {
                        this.props.getUnityMessageFunc('EventSystem', 'OpenFile', this.props.vm.getVRJson());
                    }
                    this.props.getUnityMessageFunc('EventSystem', 'SetVrSize', '0');
                }
            }
            console.log("loadProjectFinish setEditMode: ", this.props.vm.getEditMode());
            this.props.setEditorDisplayMode(this.props.vm.getEditMode());
            if (getPlatform() == platformType.Desktop) this.props.setPickedBrainToDesktop(this.props.vm.getBrainType());
            this.props.onLoadDeviceFinished();
            if (this.props.getWorkspace) {
                // To avoid checking multiple times in same state
                this.props.getWorkspace.resetWarningDeviceList();
            }
            this.props.setUndoState(false);
            this.props.setRedoState(false);
            this.props.setNewEditor(true);
            this.props.setCodeView(false);
            this.props.setCode(this.props.vm.getPythonCode());
            this.props.setFullScreen(false, viewPage.vr);
            this.props.clearTempConcepts();
            this.showToolboxandFlyout();
            this.loadVisionData();
        }
        loadVisionData() {
            console.log('loadVisionData > ', this.props.vm.getCvTagSettings())
            this.removeStoreData('visionData');
            this.removeStoreData('visionTab');
            this.removeStoreData('identifyLanguage');
            store.set('isPhotoSave', this.props.vm.getProfilePhoteSave() || false);
            store.set('enableTagInfo', true);
            if (this.props.vm.getIsCvTagsChanged()) {
                this.props.setColorDataList(this.props.vm.getCvTagSettings());
            }
            this.props.setFaceIdentificationList(this.props.vm.getCvProfiles());
        }
        removeStoreData(key) {
            if (store.get(key)) {
                store.remove(key);
            }
        }
        componentDidUpdate(prevProps) {
            if (prevProps.username !== this.props.username) {
                this.props.vm.postIOData('userData', { username: this.props.username });
            }

            // Re-request a targets update when the shouldUpdateTargets state changes to true
            // i.e. when the editor transitions out of fullscreen/player only modes
            if (this.props.shouldUpdateTargets && !prevProps.shouldUpdateTargets) {
                this.props.vm.emitTargetsUpdate(false /* Emit the event, but do not trigger project change */);
            }
        }
        componentWillUnmount() {
            this.props.vm.removeListener('targetsUpdate', this.handleTargetsUpdate);
            this.props.vm.removeListener('BLOCK_DRAG_UPDATE', this.props.onBlockDragUpdate);
            this.props.vm.removeListener('TURBO_MODE_ON', this.props.onTurboModeOn);
            this.props.vm.removeListener('TURBO_MODE_OFF', this.props.onTurboModeOff);
            this.props.vm.removeListener('PROJECT_RUN_START', this.props.onProjectRunStart);
            this.props.vm.removeListener('PROJECT_RUN_STOP', this.props.onProjectRunStop);
            this.props.vm.removeListener('PROJECT_CHANGED', this.handleProjectChanged);
            this.props.vm.removeListener('PROJECT_SAVED', this.handleProjectSaved);
            this.props.vm.removeListener('RUNTIME_STARTED', this.props.onRuntimeStarted);
            this.props.vm.removeListener('PROJECT_START', this.props.onGreenFlag);
            this.props.vm.removeListener('DEVICE_CHANGED', this.handleDeviceChanged);
            this.props.vm.removeListener('LOAD_DEVICE_FINISHED', this.loadProjectFinish);
            this.props.vm.removeListener('PROJECT_LOADED', this.projectLoaded);
            if (this.props.attachKeyboardEvents) {
                document.removeEventListener('keydown', this.handleKeyDown);
                document.removeEventListener('keyup', this.handleKeyUp);
            }
        }
        handleProjectChanged() {
            if (this.props.shouldUpdateProjectChanged && !this.props.projectChanged) {
                this.props.onProjectChanged();
            }
        }

        handleDeviceChanged() {
            this.props.onDeviceChanged();
            this.props.onProjectChanged();
        }
        handleProjectSaved() {
            if (this.props.shouldUpdateProjectChanged && this.props.projectChanged) {
                this.props.onProjectSaved();
            }
        }
        handleTargetsUpdate(data) {
            if (this.props.shouldUpdateTargets) {
                this.props.onTargetsUpdate(data);
            }
        }
        handleKeyDown(e) {
            // Don't capture keys intended for Blockly inputs.
            if (e.target !== document && e.target !== document.body) return;

            this.props.vm.postIOData('keyboard', {
                keyCode: e.keyCode,
                key: e.key,
                isDown: true
            });

            // Prevent space/arrow key from scrolling the page.
            if (e.keyCode === 32 || // 32=space
                (e.keyCode >= 37 && e.keyCode <= 40)) { // 37, 38, 39, 40 are arrows
                e.preventDefault();
            }
        }
        handleKeyUp(e) {
            // Always capture up events,
            // even those that have switched to other targets.
            this.props.vm.postIOData('keyboard', {
                keyCode: e.keyCode,
                key: e.key,
                isDown: false
            });

            // E.g., prevent scroll.
            if (e.target !== document && e.target !== document.body) {
                e.preventDefault();
            }
        }
        showToolboxandFlyout() {
            if (this.props.getWorkspace) {
                if (this.props.getWorkspace.getFlyout()) {
                    this.props.getWorkspace.getFlyout().setVisible(true);
                    this.props.updateFlyoutVisibleState(true);
                }
                if (this.props.getWorkspace.toolbox_) {
                    this.props.getWorkspace.toolbox_.setVisible(true);
                    this.props.updateToolboxVisibleState(true);
                }
            }
        }
        render() {
            const {
                /* eslint-disable no-unused-vars */
                attachKeyboardEvents,
                projectChanged,
                shouldUpdateTargets,
                shouldUpdateProjectChanged,
                onBlockDragUpdate,
                onGreenFlag,
                onKeyDown,
                onKeyUp,
                onTargetsUpdate,
                onProjectChanged,
                onProjectRunStart,
                onProjectRunStop,
                onProjectSaved,
                onRuntimeStarted,
                onTurboModeOff,
                onTurboModeOn,
                onDeviceChanged,
                onLoadDeviceFinished,
                updateFlyoutVisibleState,
                updateToolboxVisibleState,
                /* eslint-enable no-unused-vars */
                ...props
            } = this.props;
            return <WrappedComponent {...props} />;
        }
    }
    VMListener.propTypes = {
        attachKeyboardEvents: PropTypes.bool,
        onBlockDragUpdate: PropTypes.func.isRequired,
        onGreenFlag: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        onProjectChanged: PropTypes.func.isRequired,
        onProjectRunStart: PropTypes.func.isRequired,
        onProjectRunStop: PropTypes.func.isRequired,
        onProjectSaved: PropTypes.func.isRequired,
        onRuntimeStarted: PropTypes.func.isRequired,
        onTargetsUpdate: PropTypes.func.isRequired,
        onTurboModeOff: PropTypes.func.isRequired,
        onTurboModeOn: PropTypes.func.isRequired,
        projectChanged: PropTypes.bool,
        shouldUpdateTargets: PropTypes.bool,
        shouldUpdateProjectChanged: PropTypes.bool,
        username: PropTypes.string,
        vm: PropTypes.instanceOf(VM).isRequired,
        onDeviceChanged: PropTypes.func,
        onLoadDeviceFinished: PropTypes.func,
        setPickedBrainToDesktop: PropTypes.func,
        onProjectLoaded: PropTypes.func,
        setEditorDisplayMode: PropTypes.func,
        setCodeView: PropTypes.func,
        setUndoState: PropTypes.func,
        setRedoState: PropTypes.func,
        setNewEditor: PropTypes.func,
        getDirectNew: PropTypes.object,
        resetDirectNew: PropTypes.func,
        getWorkspace: PropTypes.object,
        updateFlyoutVisibleState: PropTypes.func,
        updateToolboxVisibleState: PropTypes.func,
        setFullScreen: PropTypes.func,
        getUnityMessageFunc: PropTypes.func,
        setPickedBrainType: PropTypes.func,
        projectIsNew: PropTypes.bool,
    };
    VMListener.defaultProps = {
        attachKeyboardEvents: true,
        onGreenFlag: () => ({})
    };
    const mapStateToProps = state => ({
        projectChanged: state.scratchGui.projectChanged,
        // Do not emit target or project updates in fullscreen or player only mode
        // or when recording sounds (it leads to garbled recordings on low-power machines)
        shouldUpdateTargets: !state.scratchGui.mode.isFullScreen && !state.scratchGui.mode.isPlayerOnly &&
            !state.scratchGui.modals.soundRecorder,
        // Do not update the projectChanged state in fullscreen or player only mode
        shouldUpdateProjectChanged: !state.scratchGui.mode.isFullScreen && !state.scratchGui.mode.isPlayerOnly,
        vm: state.scratchGui.vm,
        username: state.session && state.session.session && state.session.session.user ?
            state.session.session.user.username : '',
        getDirectNew: getDirectNew(state),
        getWorkspace: getWorkspace(state),
        getUnityMessageFunc: getUnityMessageFunc(state),
        projectIsNew: projectIsNew(state),
    });
    const mapDispatchToProps = dispatch => ({
        onTargetsUpdate: data => {
            dispatch(updateTargets(data.targetList, data.editingTarget));
        },
        onBlockDragUpdate: areBlocksOverGui => {
            dispatch(updateBlockDrag(areBlocksOverGui));
        },
        onProjectRunStart: () => dispatch(setRunningState(true)),
        onProjectRunStop: () => dispatch(setRunningState(false)),
        onProjectChanged: () => dispatch(setProjectChanged()),
        onProjectSaved: () => dispatch(setProjectUnchanged()),
        onRuntimeStarted: () => dispatch(setStartedState(true)),
        onTurboModeOn: () => dispatch(setTurboState(true)),
        onTurboModeOff: () => dispatch(setTurboState(false)),
        onDeviceChanged: () => dispatch(setDeviceChanged(true)),
        onLoadDeviceFinished: () => {
            dispatch(closeDeviceList())
            dispatch(openDeviceList())
            dispatch(cleanPortOption())
            dispatch(closeDeleteMode())
            dispatch(cleanSelectedTab())
            dispatch(cleanControllerReducer())
            dispatch(cleanTask())
            dispatch(setStageSize(STAGE_SIZE_MODES.large, STAGE_DISPLAY_TYPE.deviceManager));
            dispatch(setDeviceChanged(true));
            dispatch(hideDeviceHelpDialog());
            dispatch(hideBlockHelpDialog());
            dispatch(hideBoardGameDialog());
            dispatch(hideStreamingDialog());
        },
        enableCheckingImportDevice: (status) => dispatch(enableCheckingImportDevice(status)),
        onProjectLoaded: () => dispatch(setProjectUnchanged()),
        setEditorDisplayMode: mode => dispatch(setEditorDisplayMode(mode)),
        setCode: code => dispatch(setCode(code)),
        setCodeView: show => dispatch(setCodeView(show)),
        setUndoState: state => dispatch(setUndoState(state)),
        setRedoState: state => dispatch(setRedoState(state)),
        setNewEditor: state => dispatch(setNewEditor(state)),
        resetDirectNew: () => dispatch(resetDirectNew()),
        updateFlyoutVisibleState: isFlyoutVisible => dispatch(updateFlyoutVisible(isFlyoutVisible)),
        updateToolboxVisibleState: isToolboxVisible => dispatch(updateToolboxVisible(isToolboxVisible)),
        setColorDataList: (tagSettings) => dispatch(setColorDataList(tagSettings)),
        setFaceIdentificationList: (profileList) => dispatch(setFaceIdentificationList(profileList)),
        setFullScreen: (isFull, page) => dispatch(setFullScreen(isFull, page)),
        setPickedBrainType: (brainType) => dispatch(setPickedBrainType(brainType)),
        clearTempConcepts: () => dispatch(clearTempConcepts())
    });
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(VMListener);
};

export default vmListenerHOC;
