const SET_UPDATE = 'scratch-gui/software-update/SET_UPDATE';

const UPDATE = 'update';

const initialState = {
    [UPDATE]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_UPDATE:
            return Object.assign({}, state, {
                [UPDATE]: action.state
            });
        default:
            return state;
    }
};

const setUpdate = (state) => ({
    type: SET_UPDATE,
    state: state
});

const getUpdate = (state) => state.scratchGui.softwareUpdate[UPDATE];

export {
    reducer as default,
    initialState as softwareUpdateInitialState,
    setUpdate,
    getUpdate
};