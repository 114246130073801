export const SpeakerSkillSentence = {
    common: {
        zh: '一般語句',
        en: 'Common',
        sentence: ['"太大聲了"', '"小聲一點"', '"最大聲"'],
    },
    weather: {
        zh: '天氣',
        en: 'Weather',
        sentence: ['"今天要帶傘嗎"', '"東京明天的天氣"', '"週六要防曬嗎"'],
    },
    alarm: {
        zh: '計時器',
        en: 'Alarm',
        sentence: ['"倒數15分鐘"', '"取消計時"'],
    },
    calculator_converter: {
        zh: '計算機與換算單位',
        en: 'Calculator & Converter',
        sentence: ['"20加上15乘上30等於多少"', '"5加3減2是多少"', '"一美元是多少台幣"', '"2.5公尺是幾公分"'],
    },
    translator: {
        zh: '翻譯',
        en: 'Translator',
        sentence: ['"請問怎麼翻譯"', '"蘋果的英文怎麼說"', '"翻譯明天會議是幾點"'],
    },
    joke: {
        zh: '笑話',
        en: 'Joke',
        sentence: ['"我要聽笑話"', '"來點好笑的"'],
    },
    write: {
        zh: '國學常識',
        en: 'Write',
        sentence: ['"有沒有杜甫的詩"', '"兩袖清風的解釋"', '"謀事在人成事在天的意思"'],
    },
    time: {
        zh: '世界時間',
        en: 'Time',
        sentence: ['"紐約幾點"', '"法國今天是幾月幾號"', '"現在幾點"', '"今天是農曆幾號"'],
    },
}

export const SupportLocale = {
    en: "en",
    tw: "zh-tw",
    cn: "zh-cn"
}


export const SpeakerCityMapTable = {
    "en": {
        "Taipei": "Taipei",
        "New Taipei": "New Taipei",
        "Taoyuan": "Taoyuan",
        "Taichung": "Taichung",
        "Tainan": "Tainan",
        "Kaohsiung": "Kaohsiung",
        "Bangkok": "Bangkok",
        "Beijing": "Beijing",
        "Chongqing": "Chongqing",
        "Delhi": "Delhi",
        "Dhaka, Bangladesh": "Dhaka, Bangladesh",
        "Ho Chi Minh City": "Ho Chi Minh City",
        "Hong Kong": "Hong Kong",
        "Islamabad": "Islamabad",
        "Jakarta": "Jakarta",
        "Kabul": "Kabul",
        "Karachi": "Karachi",
        "Kuala Lumpur": "Kuala Lumpur",
        "Manila": "Manila",
        "Moscow": "Moscow",
        "Mumbai": "Mumbai",
        "Saint Petersburg, RU": "Saint Petersburg, RU",
        "Seoul": "Seoul",
        "Shanghai": "Shanghai",
        "Singapore": "Singapore",
        "Tokyo": "Tokyo",
        "Anchorage": "Anchorage",
        "Atlanta": "Atlanta",
        "Chicago": "Chicago",
        "Denver": "Denver",
        "Edmonton": "Edmonton",
        "Guadalajara": "Guadalajara",
        "Honolulu": "Honolulu",
        "Houston": "Houston",
        "Los Angeles": "Los Angeles",
        "Mexico City": "Mexico City",
        "Miami": "Miami",
        "Montreal": "Montreal",
        "New York": "New York",
        "Ottawa": "Ottawa",
        "Phoenix": "Phoenix",
        "San Francisco": "San Francisco",
        "Seattle": "Seattle",
        "Toronto": "Toronto",
        "Vancouver": "Vancouver",
        "Washington, DC": "Washington, DC",
        "Amsterdam": "Amsterdam",
        "Athens": "Athens",
        "Berlin": "Berlin",
        "Bucharest": "Bucharest",
        "Budapest": "Budapest",
        "Copenhagen": "Copenhagen",
        "Dublin": "Dublin",
        "Helsinki": "Helsinki",
        "Kyiv": "Kyiv",
        "Lisbon": "Lisbon",
        "London": "London",
        "Madrid": "Madrid",
        "Minsk": "Minsk",
        "Oslo": "Oslo",
        "Paris": "Paris",
        "Prague": "Prague",
        "Rome": "Rome",
        "Stockholm": "Stockholm",
        "Vienna": "Vienna",
        "Warsaw": "Warsaw",
        "Bridgetown, Barbados": "Bridgetown, Barbados",
        "Fort-de-france": "Fort-de-france",
        "George Town, Cayman Islands": "George Town, Cayman Islands",
        "Guatemala City": "Guatemala City",
        "Havana, Cuba": "Havana, Cuba",
        "Kingston, Jamaica": "Kingston, Jamaica",
        "Managua": "Managua",
        "Nassau": "Nassau",
        "Panama City, PA": "Panama City, PA",
        "Port of Spain": "Port of Spain",
        "Port-au-prince": "Port-au-prince",
        "San Jose, San José, CR": "San Jose, San José, CR",
        "San Juan, Puerto Rico": "San Juan, Puerto Rico",
        "San Salvador,  El Salvador": "San Salvador,  El Salvador",
        "Santiago De Cuba": "Santiago De Cuba",
        "Santo Domingo": "Santo Domingo",
        "Abu Dhabi": "Abu Dhabi",
        "Aleppo": "Aleppo",
        "Amman": "Amman",
        "Ankara": "Ankara",
        "Baghdad": "Baghdad",
        "Basra": "Basra",
        "Beirut": "Beirut",
        "Damascus": "Damascus",
        "Doha": "Doha",
        "Dubai": "Dubai",
        "Istanbul": "Istanbul",
        "Jeddah": "Jeddah",
        "Jerusalem": "Jerusalem",
        "Kuwait City": "Kuwait City",
        "Manama": "Manama",
        "Muscat": "Muscat",
        "Riyadh": "Riyadh",
        "Sana'a": "Sana'a",
        "Tehran": "Tehran",
        "Adelaide": "Adelaide",
        "Auckland": "Auckland",
        "Bairiki": "Bairiki",
        "Brisbane": "Brisbane",
        "Canberra": "Canberra",
        "Christchurch": "Christchurch",
        "Gold Coast": "Gold Coast",
        "Honiara": "Honiara",
        "Melbourne": "Melbourne",
        "Newcastle": "Newcastle",
        "Noumea": "Noumea",
        "Pago Pago": "Pago Pago",
        "Perth": "Perth",
        "Port Moresby": "Port Moresby",
        "Port Vila": "Port Vila",
        "Suva": "Suva",
        "Sydney": "Sydney",
        "Wellington": "Wellington",
        "Asuncion": "Asuncion",
        "Bogota": "Bogota",
        "Brasília": "Brasília",
        "Buenos Aires": "Buenos Aires",
        "Cali": "Cali",
        "Caracas": "Caracas",
        "Córdoba": "Córdoba",
        "Guayaquil": "Guayaquil",
        "La Paz": "La Paz",
        "Lima": "Lima",
        "Manaus": "Manaus",
        "Medellin": "Medellin",
        "Montevideo": "Montevideo",
        "Paramaribo": "Paramaribo",
        "Quito": "Quito",
        "Recife": "Recife",
        "Rio de Janeiro": "Rio de Janeiro",
        "Salvador": "Salvador",
        "Santiago, Chile": "Santiago, Chile",
        "São Paulo, Brazil": "São Paulo, Brazil",
        "Addis Ababa": "Addis Ababa",
        "Alexandria, Egypt": "Alexandria, Egypt",
        "Algiers": "Algiers",
        "Bujumbura": "Bujumbura",
        "Cairo": "Cairo",
        "Cape Town": "Cape Town",
        "Casablanca": "Casablanca",
        "Dakar": "Dakar",
        "Dar es Salaam": "Dar es Salaam",
        "Harare": "Harare",
        "Johannesburg": "Johannesburg",
        "Khartoum": "Khartoum",
        "Kinshasa": "Kinshasa",
        "Lagos": "Lagos",
        "Luanda": "Luanda",
        "Mogadishu": "Mogadishu",
        "Nairobi": "Nairobi",
        "Tripoli": "Tripoli",
        "Tunis": "Tunis"
    },
    "zh-tw": {
        "Taipei": "台北",
        "New Taipei": "新北",
        "Taoyuan": "桃園",
        "Taichung": "台中",
        "Tainan": "台南",
        "Kaohsiung": "高雄",
        "Bangkok": "曼谷",
        "Beijing": "北京",
        "Chongqing": "重慶",
        "Delhi": "德里",
        "Dhaka, Bangladesh": "達卡, 孟加拉",
        "Ho Chi Minh City": "胡志明市",
        "Hong Kong": "香港",
        "Islamabad": "伊斯蘭馬巴德",
        "Jakarta": "雅加達",
        "Kabul": "喀布爾",
        "Karachi": "喀拉蚩",
        "Kuala Lumpur": "吉隆坡",
        "Manila": "馬尼拉",
        "Moscow": "莫斯科",
        "Mumbai": "孟買",
        "Saint Petersburg, RU": "聖彼得堡, 俄羅斯",
        "Seoul": "首爾",
        "Shanghai": "上海",
        "Singapore": "新加坡",
        "Tokyo": "東京",
        "Anchorage": "安克拉治",
        "Atlanta": "亞特蘭大",
        "Chicago": "芝加哥",
        "Denver": "丹佛",
        "Edmonton": "艾德蒙頓",
        "Guadalajara": "瓜達拉哈拉",
        "Honolulu": "檀香山",
        "Houston": "休士頓",
        "Los Angeles": "洛杉磯",
        "Mexico City": "墨西哥市",
        "Miami": "邁阿密",
        "Montreal": "蒙特婁",
        "New York": "紐約市",
        "Ottawa": "渥太華",
        "Phoenix": "鳳凰城",
        "San Francisco": "舊金山",
        "Seattle": "西雅圖",
        "Toronto": "多倫多",
        "Vancouver": "溫哥華",
        "Washington, DC": "華盛頓哥倫比亞特區",
        "Amsterdam": "阿姆斯特丹",
        "Athens": "雅典",
        "Berlin": "柏林",
        "Bucharest": "布加勒斯特",
        "Budapest": "布達佩斯",
        "Copenhagen": "哥本哈根",
        "Dublin": "都柏林",
        "Helsinki": "赫爾辛基",
        "Kyiv": "基輔",
        "Lisbon": "里斯本",
        "London": "倫敦",
        "Madrid": "馬德里",
        "Minsk": "明斯克",
        "Oslo": "奧斯陸",
        "Paris": "巴黎",
        "Prague": "布拉格",
        "Rome": "羅馬",
        "Stockholm": "斯德哥爾摩",
        "Vienna": "維也納",
        "Warsaw": "華沙",
        "Bridgetown, Barbados": "布里奇敦,巴貝多",
        "Fort-de-france": "法國堡",
        "George Town, Cayman Islands": "喬治城,開曼群島",
        "Guatemala City": "瓜地馬拉市",
        "Havana, Cuba": "哈瓦那,古巴",
        "Kingston, Jamaica": "金士頓,牙買加",
        "Managua": "馬拿瓜",
        "Nassau": "拿梭",
        "Panama City, PA": "巴拿馬",
        "Port of Spain": "西班牙港",
        "Port-au-prince": "太子港",
        "San Jose, San José, CR": "聖荷西, 哥斯大黎加",
        "San Juan, Puerto Rico": "聖胡安, 波多黎各",
        "San Salvador,  El Salvador": "聖薩爾瓦多, 薩爾瓦多",
        "Santiago De Cuba": "聖地亞哥, 古巴",
        "Santo Domingo": "聖塔多明哥",
        "Abu Dhabi": "阿布達比",
        "Aleppo": "阿勒頗",
        "Amman": "安曼",
        "Ankara": "安卡拉",
        "Baghdad": "巴格達",
        "Basra": "巴斯拉",
        "Beirut": "貝魯特",
        "Damascus": "大馬士革",
        "Doha": "多哈",
        "Dubai": "杜拜",
        "Istanbul": "伊斯坦堡",
        "Jeddah": "吉達",
        "Jerusalem": "耶路撒冷",
        "Kuwait City": "科威特城",
        "Manama": "麥納瑪",
        "Muscat": "馬開斯特",
        "Riyadh": "利雅德",
        "Sana'a": "薩那",
        "Tehran": "德黑蘭",
        "Adelaide": "阿得萊德",
        "Auckland": "奧克蘭",
        "Bairiki": "塔拉瓦",
        "Brisbane": "布利斯班",
        "Canberra": "坎培拉",
        "Christchurch": "基督城",
        "Gold Coast": "黃金海岸",
        "Honiara": "荷尼阿拉",
        "Melbourne": "墨爾本",
        "Newcastle": "新堡",
        "Noumea": "努美阿",
        "Pago Pago": "巴哥巴哥",
        "Perth": "珀斯",
        "Port Moresby": "摩爾斯貝港",
        "Port Vila": "維拉",
        "Suva": "蘇瓦",
        "Sydney": "雪梨",
        "Wellington": "威靈頓",
        "Asuncion": "亞松森",
        "Bogota": "波哥大",
        "Brasília": "巴西利亞",
        "Buenos Aires": "布宜諾斯艾利斯",
        "Cali": "卡利",
        "Caracas": "加拉加斯",
        "Córdoba": "哥多華",
        "Guayaquil": "瓜亞基爾",
        "La Paz": "拉巴斯",
        "Lima": "利馬",
        "Manaus": "馬瑙斯",
        "Medellin": "麥德林",
        "Montevideo": "蒙特維多",
        "Paramaribo": "巴拉馬利波",
        "Quito": "基多",
        "Recife": "累西腓",
        "Rio de Janeiro": "里約熱內盧",
        "Salvador": "薩爾瓦多",
        "Santiago, Chile": "聖地亞哥, 智利",
        "São Paulo, Brazil": "聖保羅, 巴西",
        "Addis Ababa": "阿迪斯阿貝巴",
        "Alexandria, Egypt": "亞歷山大, 埃及",
        "Algiers": "阿爾及爾",
        "Bujumbura": "布瓊布拉",
        "Cairo": "開羅",
        "Cape Town": "開普頓",
        "Casablanca": "卡薩布蘭卡",
        "Dakar": "達卡, 塞內加爾",
        "Dar es Salaam": "三蘭港",
        "Harare": "哈拉雷",
        "Johannesburg": "約翰尼斯堡",
        "Khartoum": "喀土木, 蘇丹",
        "Kinshasa": "金夏沙",
        "Lagos": "拉哥斯",
        "Luanda": "魯安達",
        "Mogadishu": "摩加迪休",
        "Nairobi": "奈洛比",
        "Tripoli": "的黎波里",
        "Tunis": "突尼斯"
    },
    'zh-cn': {
        "Taipei": "台北",
        "New Taipei": "新北",
        "Taoyuan": "桃园",
        "Taichung": "臺中",
        "Tainan": "臺南",
        "Kaohsiung": "高雄",
        "Bangkok": "曼谷",
        "Beijing": "北京",
        "Chongqing": "重庆",
        "Delhi": "德里",
        "Dhaka, Bangladesh": "达卡市, 孟加拉国",
        "Ho Chi Minh City": "胡志明市",
        "Hong Kong": "香港",
        "Islamabad": "伊斯兰堡",
        "Jakarta": "雅加达",
        "Kabul": "喀布尔",
        "Karachi": "卡拉奇",
        "Kuala Lumpur": "吉隆坡",
        "Manila": "马尼拉",
        "Moscow": "莫斯科",
        "Mumbai": "孟买",
        "Saint Petersburg, RU": "圣彼得堡, 俄罗斯",
        "Seoul": "首尔",
        "Shanghai": "上海",
        "Singapore": "新加坡",
        "Tokyo": "东京",
        "Anchorage": "安克拉治-",
        "Atlanta": "亚特兰大",
        "Chicago": "芝加哥",
        "Denver": "丹佛",
        "Edmonton": "埃德蒙顿-",
        "Guadalajara": "瓜达拉哈拉",
        "Honolulu": "檀香山",
        "Houston": "休斯敦",
        "Los Angeles": "洛杉矶",
        "Mexico City": "墨西哥城",
        "Miami": "迈阿密",
        "Montreal": "蒙特利尔",
        "New York": "纽约",
        "Ottawa": "渥太华",
        "Phoenix": "凤凰城",
        "San Francisco": "旧金山",
        "Seattle": "西雅图",
        "Toronto": "多伦多",
        "Vancouver": "温哥华",
        "Washington, DC": "华盛顿",
        "Amsterdam": "阿姆斯特丹",
        "Athens": "雅典",
        "Berlin": "柏林",
        "Bucharest": "布加勒斯特",
        "Budapest": "布达佩斯",
        "Copenhagen": "哥本哈根",
        "Dublin": "都柏林",
        "Helsinki": "赫尔辛基",
        "Kyiv": "基辅",
        "Lisbon": "里斯本",
        "London": "伦敦",
        "Madrid": "马德里",
        "Minsk": "明斯克",
        "Oslo": "奥斯陆-",
        "Paris": "巴黎",
        "Prague": "布拉格",
        "Rome": "罗马",
        "Stockholm": "斯德哥尔摩",
        "Vienna": "维也纳",
        "Warsaw": "华沙",
        "Bridgetown, Barbados": "布里奇敦, 巴巴多斯",
        "Fort-de-france": "法兰西堡",
        "George Town, Cayman Islands": "乔治市, 开曼群岛",
        "Guatemala City": "瓜地马拉市",
        "Havana, Cuba": "哈瓦那, 古巴",
        "Kingston, Jamaica": "金斯顿, 牙买加",
        "Managua": "马纳瓜",
        "Nassau": "拿骚",
        "Panama City, PA": "巴拿马城",
        "Port of Spain": "西班牙港",
        "Port-au-prince": "太子港",
        "San Jose, San José, CR": "圣荷西, 哥斯达黎加",
        "San Juan, Puerto Rico": "圣胡安, 波多黎各",
        "San Salvador,  El Salvador": "圣萨尔瓦多, 萨尔瓦多",
        "Santiago De Cuba": "圣地亚哥-德古巴",
        "Santo Domingo": "圣多明哥",
        "Abu Dhabi": "阿布扎比",
        "Aleppo": "阿勒颇",
        "Amman": "安曼",
        "Ankara": "安卡拉-",
        "Baghdad": "巴格达",
        "Basra": "巴士拉",
        "Beirut": "贝鲁特",
        "Damascus": "大马士革",
        "Doha": "多哈",
        "Dubai": "迪拜",
        "Istanbul": "伊斯坦布尔",
        "Jeddah": "吉达",
        "Jerusalem": "耶路撒冷",
        "Kuwait City": "科威特城",
        "Manama": "麦纳麦",
        "Muscat": "马斯喀特",
        "Riyadh": "利雅得",
        "Sana'a": "萨那",
        "Tehran": "德黑兰",
        "Adelaide": "阿德莱德",
        "Auckland": "奥克兰都会区",
        "Bairiki": "拜里基岛",
        "Brisbane": "布里斯班",
        "Canberra": "堪培拉",
        "Christchurch": "基督城",
        "Gold Coast": "黄金海岸",
        "Honiara": "霍尼亚拉",
        "Melbourne": "墨尔本",
        "Newcastle": "纽卡斯尔",
        "Noumea": "努美阿",
        "Pago Pago": "帕果帕果",
        "Perth": "伯斯",
        "Port Moresby": "莫尔兹比港",
        "Port Vila": "维拉港",
        "Suva": "苏瓦",
        "Sydney": "悉尼",
        "Wellington": "惠灵顿",
        "Asuncion": "亚松森",
        "Bogota": "波哥大",
        "Brasília": "巴西利亚",
        "Buenos Aires": "布宜诺斯艾利斯",
        "Cali": "卡利",
        "Caracas": "加拉加斯",
        "Córdoba": "科尔多瓦",
        "Guayaquil": "瓜亚基尔",
        "La Paz": "拉巴斯",
        "Lima": "利马",
        "Manaus": "马瑙斯",
        "Medellin": "麦德林",
        "Montevideo": "蒙得维的亚",
        "Paramaribo": "帕拉马里博",
        "Quito": "基多",
        "Recife": "累西腓",
        "Rio de Janeiro": "里约热内卢",
        "Salvador": "萨尔瓦多",
        "Santiago, Chile": "圣地亚哥-德智利",
        "São Paulo, Brazil": "圣保罗, 巴西",
        "Addis Ababa": "亚的斯亚贝巴",
        "Alexandria, Egypt": "亚历山德里亚",
        "Algiers": "阿尔及尔",
        "Bujumbura": "布琼布拉",
        "Cairo": "开罗",
        "Cape Town": "开普敦",
        "Casablanca": "卡萨布兰卡",
        "Dakar": "达喀尔, 赛内加尔",
        "Dar es Salaam": "达累斯萨拉姆",
        "Harare": "哈拉雷",
        "Johannesburg": "约翰内斯堡",
        "Khartoum": "喀土穆, 苏丹",
        "Kinshasa": "金沙萨",
        "Lagos": "拉哥斯",
        "Luanda": "罗安达",
        "Mogadishu": "摩加迪沙",
        "Nairobi": "内罗毕",
        "Tripoli": "的黎波里",
        "Tunis": "突尼斯市"
    },
}

export const SpeakerSupportCity = [
    "Taipei",
    "New Taipei",
    "Taoyuan",
    "Taichung",
    "Tainan",
    "Kaohsiung",
    "Bangkok",
    "Beijing",
    "Chongqing",
    "Delhi",
    "Dhaka, Bangladesh",
    "Ho Chi Minh City",
    "Hong Kong",
    "Islamabad",
    "Jakarta",
    "Kabul",
    "Karachi",
    "Kuala Lumpur",
    "Manila",
    "Moscow",
    "Mumbai",
    "Saint Petersburg, RU",
    "Seoul",
    "Shanghai",
    "Singapore",
    "Tokyo",
    "Anchorage",
    "Atlanta",
    "Chicago",
    "Denver",
    "Edmonton",
    "Guadalajara",
    "Honolulu",
    "Houston",
    "Los Angeles",
    "Mexico City",
    "Miami",
    "Montreal",
    "New York",
    "Ottawa",
    "Phoenix",
    "San Francisco",
    "Seattle",
    "Toronto",
    "Vancouver",
    "Washington, DC",
    "Amsterdam",
    "Athens",
    "Berlin",
    "Bucharest",
    "Budapest",
    "Copenhagen",
    "Dublin",
    "Helsinki",
    "Kyiv",
    "Lisbon",
    "London",
    "Madrid",
    "Minsk",
    "Oslo",
    "Paris",
    "Prague",
    "Rome",
    "Stockholm",
    "Vienna",
    "Warsaw",
    "Bridgetown, Barbados",
    "Fort-de-france",
    "George Town, Cayman Islands",
    "Guatemala City",
    "Havana, Cuba",
    "Kingston, Jamaica",
    "Managua",
    "Nassau",
    "Panama City, PA",
    "Port of Spain",
    "Port-au-prince",
    "San Jose, San José, CR",
    "San Juan, Puerto Rico",
    "San Salvador,  El Salvador",
    "Santiago De Cuba",
    "Santo Domingo",
    "Abu Dhabi",
    "Aleppo",
    "Amman",
    "Ankara",
    "Baghdad",
    "Basra",
    "Beirut",
    "Damascus",
    "Doha",
    "Dubai",
    "Istanbul",
    "Jeddah",
    "Jerusalem",
    "Kuwait City",
    "Manama",
    "Muscat",
    "Riyadh",
    "Sana'a",
    "Tehran",
    "Adelaide",
    "Auckland",
    "Bairiki",
    "Brisbane",
    "Canberra",
    "Christchurch",
    "Gold Coast",
    "Honiara",
    "Melbourne",
    "Newcastle",
    "Noumea",
    "Pago Pago",
    "Perth",
    "Port Moresby",
    "Port Vila",
    "Suva",
    "Sydney",
    "Wellington",
    "Asuncion",
    "Bogota",
    "Brasília",
    "Buenos Aires",
    "Cali",
    "Caracas",
    "Córdoba",
    "Guayaquil",
    "La Paz",
    "Lima",
    "Manaus",
    "Medellin",
    "Montevideo",
    "Paramaribo",
    "Quito",
    "Recife",
    "Rio de Janeiro",
    "Salvador",
    "Santiago, Chile",
    "São Paulo, Brazil",
    "Addis Ababa",
    "Alexandria, Egypt",
    "Algiers",
    "Bujumbura",
    "Cairo",
    "Cape Town",
    "Casablanca",
    "Dakar",
    "Dar es Salaam",
    "Harare",
    "Johannesburg",
    "Khartoum",
    "Kinshasa",
    "Lagos",
    "Luanda",
    "Mogadishu",
    "Nairobi",
    "Tripoli",
    "Tunis"
]