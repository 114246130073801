const SELECT_DEVICE = 'scratch-gui/deviceHelpSelected/SELECT_DEVICE';
const DEVICE_TYPE = "deviceType"

const initialState = {
    [DEVICE_TYPE]: ""
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SELECT_DEVICE:
            return Object.assign({}, state, {
                [DEVICE_TYPE]: action.deviceType
            });
        default:
            return state;
    }
};

const selectDevice = (deviceType) => ({
    type: SELECT_DEVICE,
    deviceType: deviceType
})

const getSelectedDevice = (state) => state.scratchGui.deviceHelpSelected[DEVICE_TYPE];

export {
    reducer as default,
    initialState as deviceHelpSelectedInitialState,
    selectDevice,
    getSelectedDevice
};
