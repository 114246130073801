import {
    platformType
} from '../lib/platform'
const SHOW_DIALOG = 'scratch-gui/dialog/SHOW_DIALOG';
const HIDE_DIALOG = 'scratch-gui/dialog/HIDE_DIALOG';
const SET_FEEDBACK = 'scratch-gui/dialog/SET_FEEDBACK';
const FOCUS_DIALOG = 'scratch-gui/dialog/FOCUS_DIALOG';
const SET_SAVECANCEL = 'scratch-gui/dialog/SET_SAVECANCEL';

const DIALOG_ABOUT = 'aboutDialog';
const DIALOG_LICENSE = 'licenseDialog';
const DIALOG_HISTORY = 'historyDialog';
const DIALOG_UPDATE = 'updateDialog';
const DIALOG_QUESTION = 'questionDialog';
const FEEDBACK_STATUS = 'feedbackStatus';
const IS_SAVE_CANCEL = 'isSaveCancel';

const QUESTION_CLOSE_APP_SAVE_FILE = 'questionCloseAppSaveFile';
const QUESTION_DOWNLOAD_FAIL = 'questionDownloadFail';
const QUESTION_INSTALL_FAIL = 'questionInstallFail';
const QUESTION_OPEN_NEW = 'questionOpenNew'
const QUESTION_SAVE_NEW = 'questionSaveNew';
const QUESTION_SAVE_NEW_WEB = 'questionSaveNewWeb';
const QUESTION_SAVE_OPEN = 'questionSaveOpen';
const QUESTION_SAVE_OPEN_WEB = 'questionSaveOpenWeb';
const QUESTION_SAVE_RECENT = 'questionSaveRecent';
const QUESTION_OPEN_EXAMPLE = 'questionOpenExample'
const QUESTION_SAVE_PROJECTNAME = 'questionSaveProjectName';
const QUESTION_INSTALL_DRIVER = 'questionInstallDriver';
const QUESTION_SAVE_UPDATE_SOFTWARE = 'questionSaveUpdateSoftware';

const QUESTION_FORCE_UPDATE = 'questionForceUpdate';
const QUESTION_BLOCK_LIMIT = 'questionBlockLimit';
const QUESTION_BRAIN_TYPE = 'questionBrainType';
const QUESTION_BATTERY_DISCONNECTED = 'questionBatteryDisconnected';
const QUESTION_BATTERY_LOW_POWER = 'questionBatteryLowPower';
const QUESTION_DOWNLOAD_BRAIN_SCRIPT_TIMEOUT = 'questionDownloadBrainScriptTimeout';

const QUESTION_DELETE_SLOT_FILE = 'questionDeleteSlotFile';
const QUESTION_RESET_BRAIN_FIRMWARE = 'questionResetBrainFirmware';

const QUESTION_UPDATE_SOFTWARE_FIRST = 'questionUpdateSoftwareFirst';
const QUESTION_UPDATE_FIRMWARE_FIRST = 'questionUpdateFirmwareFirst';
const QUESTION_FORCE_UPDATE_SOFTWARE = 'questionForceUpdateSoftware';
const QUESTION_FORCE_UPDATE_FIRMWARE = 'questionForceUpdateFirmware';
const QUESTION_LOADER_MODE = 'questionLoaderMode';
const QUESTION_SWITCH_BRAIN_MODE_IN_BT_MODE = 'questionSwitchBrainModeInBTMode';
const QUESTION_SWITCH_BRAIN_MODE_IN_RFID_MODE = 'questionSwitchBrainModeInRFIDMode';
const QUESTION_FORCE_UPDATE_BT_AND_BRAIN = 'questionForceUpdateBtAndBrain';
const QUESTION_UPDATE_BT_AND_BRAIN_FIRST = 'questionUpdateBtAndBrainFirst';
const QUESTION_UPDATE_BT_FIRMWARE_FIRST = 'questionUpdateBtFirst';

const QUESTION_OPEN_EDITOR = 'questionOpenEditor';
const QUESTION_OPEN_EDITOR_SAVE = 'questionOpenEditorSave';

const QUESTION_DIRECT_NEW = 'questionDirectNew';
const QUESTION_DIRECT_NEW_SAVE = 'questionDirectNewSave';
const QUESTION_FILE_EXTENSION_NOT_MATCH = 'questionFileExtentionNotMatch';

const QUESTION_REPLACE_DEVICE = "questionReplaceDevice"

const QUESTION_REMOVE_CUSTOMIZING_BLOCK = "questionRemoveCustomizingBlock"
const QUESTION_IMPORT_CUSTOMIZING_DUPLICATED = "questionImportCustomizingDuplicated"
const QUESTION_IMPORT_CONCEPT_DUPLICATED = "questionImportConceptDuplicated"
const QUESTION_IMPORT_CUSTOMIZING_USELESS = "questionImportCustomizingUseless"

const QUESTION_REMIND_FIRMWARE_UPDATE = 'questionRemindFirmwareUpdate';
const QUESTION_REMIND_BLUETOOTH_UPDATE = 'questionRemindBluetoothUpdate';

const QUESTION_DELETE_CONTROLLER_1 = 'questionDeleteController1';

const QUESTION_DELETE_AI_SPEECH_GROUP = 'questionDeleteAISpeechGroup';
const QUESTION_DELETE_SPEAKER_INTENT_ARRAY = 'questionDeleteSpeakerIntentArray';

const QUESTION_VR_CLOSE_FULLSCREEN = 'questionVrCloseFullscreen';
const QUESTION_VR_REMIND = 'questionVrRemind';

const DIALOG_AUDIO_UPPER_BOUND = 'errorAudioUpperBound';

const DIALOG_FIRMWARM_UPDATE = 'firmwarmUpdateDialog';
const DIALOG_EXAMPLE = 'exampleDialog';
const DIALOG_DEVICE_HELP = 'devicehelpDialog';
const DIALOG_BOARD_GAME = 'boardGameDialog';
const DIALOG_WIFI_SETTING = 'wifiSettingDialog';

const BRAIN_TYPE_PICKER = 'brainTypePicker';
const PICKER_START_APP = 'startAppBrainTypePicker';
const PICKER_NEW_PROJECT = 'newProjectBrainTypePicker';

const DIALOG_HINT_BLOCK_HELP = 'hintblockhelpDialog';
const DIALOG_BLOCK_HELP = 'blockhelpDialog';

const DIALOG_VISION_TUTORIAL = 'visionTutorialDialog';
const DIALOG_VISION_SETTING = 'visionSettingDialog';
const DIALOG_SPEAKER_SKILL_SETTING = 'speakerSkillSettingDialog';
const DIALOG_SPEAKER_CUSTOMIZING_SETTING = 'speakerCustomizingSettingDialog';
const DIALOG_SPEAKER_CONCEPT = 'speakerConceptDialog';

const DIALOG_STREAMING = 'streamingDialog'
const DIALOG_STREAMING_POPUP = 'streamingDialogPopup'
const DIALOG_ALERT = 'alertDialog';
const IS_SHOW = 'isShow';
const DIALOG_ALERT_TYPE = 'alertType';
const DIALOG_ALERT_MSG = 'alertMsg';
const DIALOG_ALERT_CALLBACK = 'alertCallback';

const DIALOG_BOTTOM_HINT = 'bottomHintDialog'

const DIALOG_ERROR = 'errorDialog';
const DIALOG_ERROR_MSG = 'errorMsg';
const DIALOG_ERROR_TYPE = 'errorType';
const FOREGROUND_DIALOG = 'foregroundDialog';
const INDEX = 'index'

const DIALOG_WHATS_NEW = 'whatsNewDialog';
const DIALOG_EDIT_BLOCK = 'editBlockDialog';
const DIALOG_UPDATE_RC_TUTORIAL = 'updateRCTutorial';

const DIALOG_PAD_SAVE_FILE = 'savePadFile'
const DIALOG_PAD_OPEN_FILE = 'openPadFile'

const DIALOG_ADD_AI_SPEECH_GROUP = 'addAISpeechGroup'
const DIALOG_EDIT_AI_SPEECH_GROUP = 'editAISpeechGroup'

const DIALOG_WIFI_DIRECTION = 'wifiDirectionDialog';
const DIALOG_WIFI_IPAD_SETTING = 'wifiipadSettingDialog';
const DIALOG_WIFI_IP_SETTING = 'wifiIpSettingDialog';

const FOREGROUND_INDEX = 1030;
const BACKGROUND_INDEX = 1029;

const GROUP_DATA = 'groupData';
const GROUP_CALLBACK = 'groupCallback';
const INTENT_ARRAY_CALLBACK = 'IntentArrayCallback';

const initialState = {
    [DIALOG_ABOUT]: false,
    [DIALOG_UPDATE]: false,
    [DIALOG_LICENSE]: false,
    [DIALOG_HISTORY]: false,
    [QUESTION_CLOSE_APP_SAVE_FILE]: false,
    [QUESTION_DOWNLOAD_FAIL]: false,
    [QUESTION_INSTALL_FAIL]: false,
    [QUESTION_OPEN_NEW]: false,
    [QUESTION_SAVE_NEW]: false,
    [QUESTION_SAVE_NEW_WEB]: false,
    [QUESTION_SAVE_OPEN]: false,
    [QUESTION_SAVE_OPEN_WEB]: false,
    [QUESTION_SAVE_RECENT]: false,
    [QUESTION_OPEN_EXAMPLE]: false,
    [QUESTION_SAVE_PROJECTNAME]: false,
    [QUESTION_SAVE_UPDATE_SOFTWARE]: false,
    [QUESTION_INSTALL_DRIVER]: false,
    [QUESTION_RESET_BRAIN_FIRMWARE]: false,
    [QUESTION_OPEN_EDITOR]: false,
    [QUESTION_OPEN_EDITOR_SAVE]: false,
    [QUESTION_REMIND_FIRMWARE_UPDATE]: false,
    [QUESTION_REMIND_BLUETOOTH_UPDATE]: false,
    [QUESTION_DIRECT_NEW]: false,
    [QUESTION_DIRECT_NEW_SAVE]: false,
    [QUESTION_REPLACE_DEVICE]: false,
    [QUESTION_REMOVE_CUSTOMIZING_BLOCK]: false,
    [QUESTION_IMPORT_CUSTOMIZING_DUPLICATED]: false,
    [QUESTION_IMPORT_CONCEPT_DUPLICATED]: false,
    [QUESTION_IMPORT_CUSTOMIZING_USELESS]: false,
    [QUESTION_UPDATE_FIRMWARE_FIRST]: false,
    [QUESTION_UPDATE_SOFTWARE_FIRST]: false,
    [QUESTION_FORCE_UPDATE_FIRMWARE]: false,
    [QUESTION_FORCE_UPDATE_SOFTWARE]: false,
    [QUESTION_FORCE_UPDATE_BT_AND_BRAIN]: false,
    [QUESTION_UPDATE_BT_AND_BRAIN_FIRST]: false,
    [QUESTION_UPDATE_BT_FIRMWARE_FIRST]: false,
    [QUESTION_LOADER_MODE]: false,
    [QUESTION_SWITCH_BRAIN_MODE_IN_BT_MODE]: false,
    [QUESTION_SWITCH_BRAIN_MODE_IN_RFID_MODE]: false,
    [QUESTION_FORCE_UPDATE]: false,
    [QUESTION_BLOCK_LIMIT]: false,
    [QUESTION_BRAIN_TYPE]: false,
    [QUESTION_BATTERY_LOW_POWER]: false,
    [QUESTION_BATTERY_DISCONNECTED]: false,
    [QUESTION_DOWNLOAD_BRAIN_SCRIPT_TIMEOUT]: false,
    [QUESTION_FILE_EXTENSION_NOT_MATCH]: false,
    [QUESTION_DELETE_SLOT_FILE]: {
        [IS_SHOW]: false,
        [INDEX]: -1
    },
    [FEEDBACK_STATUS]: false,
    [IS_SAVE_CANCEL]: false,
    [DIALOG_FIRMWARM_UPDATE]: false,
    [BRAIN_TYPE_PICKER]: {
        [PICKER_START_APP]: false,
        [PICKER_NEW_PROJECT]: false,
    },
    [DIALOG_EXAMPLE]: false,
    [DIALOG_DEVICE_HELP]: false,
    [DIALOG_BOARD_GAME]: false,
    [DIALOG_HINT_BLOCK_HELP]: false,
    [DIALOG_BLOCK_HELP]: false,
    [DIALOG_ERROR]: {
        [IS_SHOW]: false,
        [DIALOG_ERROR_TYPE]: '',
        [DIALOG_ERROR_MSG]: 'Unknown',
    },
    [DIALOG_ALERT]: {
        [IS_SHOW]: false,
        [DIALOG_ALERT_TYPE]: '',
        [DIALOG_ALERT_MSG]: '',
        [DIALOG_ALERT_CALLBACK]: null,
    },
    [FOREGROUND_DIALOG]: '',
    [DIALOG_WHATS_NEW]: false,
    [DIALOG_EDIT_BLOCK]: false,
    [DIALOG_WIFI_SETTING]: false,
    [DIALOG_VISION_TUTORIAL]: false,
    [DIALOG_VISION_SETTING]: false,
    [DIALOG_SPEAKER_SKILL_SETTING]: false,
    [DIALOG_SPEAKER_CUSTOMIZING_SETTING]: false,
    [DIALOG_SPEAKER_CONCEPT]: false,
    [DIALOG_STREAMING]: false,
    [DIALOG_UPDATE_RC_TUTORIAL]: false,
    [DIALOG_PAD_SAVE_FILE]: false,
    [DIALOG_PAD_OPEN_FILE]: false,

    [DIALOG_ADD_AI_SPEECH_GROUP]: false,
    [DIALOG_EDIT_AI_SPEECH_GROUP]: {
        [IS_SHOW]: false,
        [GROUP_DATA]: null
    },
    [QUESTION_DELETE_AI_SPEECH_GROUP]: {
        [IS_SHOW]: false,
        [GROUP_DATA]: null,
        [GROUP_CALLBACK]: null
    },
    [QUESTION_DELETE_SPEAKER_INTENT_ARRAY]: {
        [IS_SHOW]: false,
        [INTENT_ARRAY_CALLBACK]: null
    },
    [DIALOG_AUDIO_UPPER_BOUND]: false,
    [DIALOG_WIFI_DIRECTION]: false,
    [DIALOG_WIFI_IPAD_SETTING]: false,
    [DIALOG_WIFI_IP_SETTING]: false,

    [QUESTION_VR_CLOSE_FULLSCREEN]: false,
    [QUESTION_VR_REMIND]: false,
    [DIALOG_BOTTOM_HINT]: false,
};

const questionType = {
    QUESTION_CLOSE_APP_SAVE_FILE: QUESTION_CLOSE_APP_SAVE_FILE,
    QUESTION_DOWNLOAD_FAIL: QUESTION_DOWNLOAD_FAIL,
    QUESTION_INSTALL_FAIL: QUESTION_INSTALL_FAIL,
    QUESTION_OPEN_NEW: QUESTION_OPEN_NEW,
    QUESTION_SAVE_NEW: QUESTION_SAVE_NEW,
    QUESTION_SAVE_NEW_WEB: QUESTION_SAVE_NEW_WEB,
    QUESTION_SAVE_OPEN: QUESTION_SAVE_OPEN,
    QUESTION_SAVE_OPEN_WEB: QUESTION_SAVE_OPEN_WEB,
    QUESTION_SAVE_RECENT: QUESTION_SAVE_RECENT,
    QUESTION_OPEN_EXAMPLE: QUESTION_OPEN_EXAMPLE,
    QUESTION_SAVE_PROJECTNAME: QUESTION_SAVE_PROJECTNAME,
    QUESTION_INSTALL_DRIVER: QUESTION_INSTALL_DRIVER,
    QUESTION_SAVE_UPDATE_SOFTWARE: QUESTION_SAVE_UPDATE_SOFTWARE,
    QUESTION_FORCE_UPDATE: QUESTION_FORCE_UPDATE,
    QUESTION_BLOCK_LIMIT: QUESTION_BLOCK_LIMIT,
    QUESTION_BRAIN_TYPE: QUESTION_BRAIN_TYPE,
    QUESTION_BATTERY_DISCONNECTED: QUESTION_BATTERY_DISCONNECTED,
    QUESTION_BATTERY_LOW_POWER: QUESTION_BATTERY_LOW_POWER,
    QUESTION_DOWNLOAD_BRAIN_SCRIPT_TIMEOUT: QUESTION_DOWNLOAD_BRAIN_SCRIPT_TIMEOUT,

    QUESTION_DELETE_SLOT_FILE: QUESTION_DELETE_SLOT_FILE,
    QUESTION_RESET_BRAIN_FIRMWARE: QUESTION_RESET_BRAIN_FIRMWARE,
    QUESTION_UPDATE_FIRMWARE_FIRST: QUESTION_UPDATE_FIRMWARE_FIRST,
    QUESTION_UPDATE_SOFTWARE_FIRST: QUESTION_UPDATE_SOFTWARE_FIRST,
    QUESTION_FORCE_UPDATE_FIRMWARE: QUESTION_FORCE_UPDATE_FIRMWARE,
    QUESTION_FORCE_UPDATE_SOFTWARE: QUESTION_FORCE_UPDATE_SOFTWARE,
    QUESTION_FORCE_UPDATE_BT_AND_BRAIN: QUESTION_FORCE_UPDATE_BT_AND_BRAIN,
    QUESTION_UPDATE_BT_AND_BRAIN_FIRST: QUESTION_UPDATE_BT_AND_BRAIN_FIRST,
    QUESTION_UPDATE_BT_FIRMWARE_FIRST: QUESTION_UPDATE_BT_FIRMWARE_FIRST,
    QUESTION_LOADER_MODE: QUESTION_LOADER_MODE,
    QUESTION_SWITCH_BRAIN_MODE_IN_BT_MODE: QUESTION_SWITCH_BRAIN_MODE_IN_BT_MODE,
    QUESTION_SWITCH_BRAIN_MODE_IN_RFID_MODE: QUESTION_SWITCH_BRAIN_MODE_IN_RFID_MODE,
    QUESTION_OPEN_EDITOR: QUESTION_OPEN_EDITOR,
    QUESTION_OPEN_EDITOR_SAVE: QUESTION_OPEN_EDITOR_SAVE,
    QUESTION_REMIND_FIRMWARE_UPDATE: QUESTION_REMIND_FIRMWARE_UPDATE,
    QUESTION_REMIND_BLUETOOTH_UPDATE: QUESTION_REMIND_BLUETOOTH_UPDATE,
    QUESTION_DELETE_CONTROLLER_1: QUESTION_DELETE_CONTROLLER_1,
    QUESTION_DIRECT_NEW: QUESTION_DIRECT_NEW,
    QUESTION_DIRECT_NEW_SAVE: QUESTION_DIRECT_NEW_SAVE,
    QUESTION_FILE_EXTENSION_NOT_MATCH: QUESTION_FILE_EXTENSION_NOT_MATCH,
    QUESTION_REPLACE_DEVICE: QUESTION_REPLACE_DEVICE,

    QUESTION_DELETE_AI_SPEECH_GROUP: QUESTION_DELETE_AI_SPEECH_GROUP,
    QUESTION_DELETE_SPEAKER_INTENT_ARRAY: QUESTION_DELETE_SPEAKER_INTENT_ARRAY,
    QUESTION_REMOVE_CUSTOMIZING_BLOCK: QUESTION_REMOVE_CUSTOMIZING_BLOCK,
    QUESTION_IMPORT_CUSTOMIZING_USELESS: QUESTION_IMPORT_CUSTOMIZING_USELESS,
    QUESTION_IMPORT_CUSTOMIZING_DUPLICATED: QUESTION_IMPORT_CUSTOMIZING_DUPLICATED,
    QUESTION_IMPORT_CONCEPT_DUPLICATED: QUESTION_IMPORT_CONCEPT_DUPLICATED,
    QUESTION_VR_CLOSE_FULLSCREEN: QUESTION_VR_CLOSE_FULLSCREEN,
    QUESTION_VR_REMIND: QUESTION_VR_REMIND
};

const pickerType = {
    PICKER_START_APP: PICKER_START_APP,
    PICKER_NEW_PROJECT: PICKER_NEW_PROJECT,
};

const errorType = {
    NETWORK_DISCONNECTED: 'network_disconnected',
    FILE_NO_EXIST: 'file_no_exist',
    FILE_NAME_DUPLICATE: 'file_name_duplicate',
    FIRMWARE_UPDATE_SERVER_ERROR: 'firmware_update_server_error',
    FIRMWARE_UPDATE_FAILED: 'firmware_update_failed',
    UPDATE_NETWORK_DISCONNECTED: 'update_network_disconnected',
    DOWNLOAD_FILE_FAILED: 'download_file_failed',
    UPDATE_FAIL_ON_READONLY: 'update_fail_on_readonly',
    UPDATE_FAIL_SIZE_WRONG: 'upadte_fail_size_wrong',
    UPDATE_FAIL_CHECKSUM_MISMATCH: 'update_fail_checksum_mismatch',
    UPDATE_FILE_NOT_FOUND: 'update_file_not_found',
    UPDATE_FAIL_SERVER_TIMEOUT: 'update_fail_server_timeout',
    UPDATE_FAIL_HTTP_ERROR: 'update_fail_http_error',
    OVER_EVENT_STARTED_UPPER_BOUND: 'over_event_started_upper_bound',
    OVER_EVENT_STARTED_UPPER_BOUND_EDU: 'over_event_started_upper_bound_edu',
    UPDATE_SOFTWARE_FIRST: 'update_software_first',
    ERASE_SLOT_FAILED: 'rase_slot_failed',
    BRAIN_STATUS_MODE_ERROR: 'brain_status_mode_error',
    BREAK_POSITION_ILEGAL: 'break_position_ilegal',
    FIRMWARE_UPDATE_TIMEOUT: 'firmware_update_timeout',
    RC_UPDATE_FAIL: 'rc_update_fail',
    RC_UPDATE_FAIL_DISCONNECT_MODULE: 'rc_update_fail_disconnect_module',
    RC_UPDATE_FAIL_DFU_TIMEOUT: 'rc_update_fail_dfu_timeout',
    BT_UPDATE_FAIL: 'bt_update_fail',
    BT_UPDATE_FAIL_CONTINUE_UPDATE_EDU: 'bt_update_fail_continue_update_edu',
    BT_UPDATE_FAIL_DISCONNECT_MODULE: 'bt_update_fail_disconnect_module',
    BT_UPDATE_FAIL_DFU_TIMEOUT: 'bt_update_fail_dfu_timeout',
    SPEAKER_CUSTOMIZING_IMPORT_EXCEED: 'speaker_customizing_import_exceed',
    SPEAKER_SENTENCE_UP_TO_LIMIT: 'speaker_sentence_up_to_limit',
    SPEAKER_CONCEPT_UP_TO_LIMIT: 'speaker_concept_up_to_limit',
    EXTENSION_BLOCK_NEED_WIFI: 'extension_block_need_wifi',
    CONNECT_BRAIN_FAILED: 'connnect_brain_failed',
    OVER_AUDIO_SIZE_UPPER_BOUND: 'over_audio_size_upper_bound',
    DUPLICATELY_CALL_FUNCTION: 'duplcately_call_function',
    XLSX_SHEETNAME_INVALID: 'xlsx_sheetname_invalid',
    FILE_PROCESSING: 'file_processing',
    XLSX_FILE_INVALID: 'xlsx_file_invalid',
    FILE_FORMAT_INVALID: 'file_format_invalid',
    VISION_REBOOT: 'vision_reboot',
    UPDATE_SOFTWARE_CRASH: 'update_software_crash'
};

const editBlockType = {
    number_variable: "number_variable",
    string_variable: "string_variable",
    boolean_variable: "boolean_variable",
    number_list: "list",
    string_list: "string_list",

    sound_audio: "sound_audio",

    ai_speech_group: "ai_speech_group"
}

const aiSpeechGroupType = {
    NEW_GROUP: "new_ai_speech_group",
    EDIT_GROUP: "edit_ai_speech_group"
}

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SHOW_DIALOG:
            console.log('SHOW_DIALOG ', action.dialog)
            if (action.dialog == DIALOG_QUESTION) {
                return Object.assign({}, state, {
                    [action.question]: true
                });
            } else if (action.dialog == BRAIN_TYPE_PICKER) {
                return Object.assign({}, state, {
                    [BRAIN_TYPE_PICKER]: {
                        [action.picker]: true
                    }
                });
            } else if (action.dialog == DIALOG_ERROR) {
                return Object.assign({}, state, {
                    [DIALOG_ERROR]: {
                        [IS_SHOW]: true,
                        [DIALOG_ERROR_TYPE]: action.errorType,
                        [DIALOG_ERROR_MSG]: action.msg
                    }
                });
            } else if (action.dialog == DIALOG_ALERT) {
                return Object.assign({}, state, {
                    [DIALOG_ALERT]: {
                        [IS_SHOW]: true,
                        [DIALOG_ALERT_TYPE]: action.alertType,
                        [DIALOG_ALERT_MSG]: action.msg,
                        [DIALOG_ALERT_CALLBACK]: action.callback
                    }
                })
            } else if (action.platform && (action.platform == platformType.iPad || action.platform == platformType.RN) && (action.dialog == DIALOG_DEVICE_HELP || action.dialog == DIALOG_BLOCK_HELP)) {
                return Object.assign({}, state, {
                    [DIALOG_DEVICE_HELP]: (action.dialog == DIALOG_DEVICE_HELP),
                    [DIALOG_BLOCK_HELP]: (action.dialog == DIALOG_BLOCK_HELP)
                });
            } else if (action.dialog == QUESTION_DELETE_SLOT_FILE) {
                return Object.assign({}, state, {
                    [QUESTION_DELETE_SLOT_FILE]: {
                        [IS_SHOW]: true,
                        [INDEX]: action.index
                    }
                })
            } else if (action.dialog == QUESTION_DELETE_AI_SPEECH_GROUP) {
                return Object.assign({}, state, {
                    [QUESTION_DELETE_AI_SPEECH_GROUP]: {
                        [IS_SHOW]: true,
                        [GROUP_DATA]: action.group,
                        [GROUP_CALLBACK]: action.callback
                    }
                })
            } else if (action.dialog == QUESTION_DELETE_SPEAKER_INTENT_ARRAY) {
                return Object.assign({}, state, {
                    [QUESTION_DELETE_SPEAKER_INTENT_ARRAY]: {
                        [IS_SHOW]: true,
                        [INTENT_ARRAY_CALLBACK]: action.callback
                    }
                })
            } else if (action.dialog == QUESTION_VR_CLOSE_FULLSCREEN) {
                return Object.assign({}, state, {
                    [QUESTION_VR_CLOSE_FULLSCREEN]: {
                        [IS_SHOW]: true,
                    }
                })
            } else if (action.dialog == DIALOG_EDIT_AI_SPEECH_GROUP) {
                return Object.assign({}, state, {
                    [DIALOG_EDIT_AI_SPEECH_GROUP]: {
                        [IS_SHOW]: true,
                        [GROUP_DATA]: action.group
                    }
                })
            } else if (action.dialog == DIALOG_AUDIO_UPPER_BOUND) {
                return Object.assign({}, state, {
                    [DIALOG_AUDIO_UPPER_BOUND]: true,
                    [DIALOG_ERROR]: {
                        [IS_SHOW]: false,
                        [DIALOG_ERROR_TYPE]: action.errorType
                    }
                })
            } else {
                return Object.assign({}, state, {
                    [action.dialog]: true
                });
            }
        case HIDE_DIALOG:
            if (action.dialog == DIALOG_QUESTION) {
                return Object.assign({}, state, {
                    [action.question]: false
                });
            } else if (action.dialog == BRAIN_TYPE_PICKER) {
                return Object.assign({}, state, {
                    [BRAIN_TYPE_PICKER]: {
                        [action.picker]: false
                    }
                });
            } else if (action.dialog == DIALOG_ERROR) {
                return Object.assign({}, state, {
                    [DIALOG_ERROR]: {
                        [IS_SHOW]: false,
                        [DIALOG_ERROR_TYPE]: '',
                        [DIALOG_ERROR_MSG]: 'Unknown'
                    }
                });
            } else if (action.dialog == DIALOG_ALERT) {
                return Object.assign({}, state, {
                    [DIALOG_ALERT]: {
                        [IS_SHOW]: false,
                        [DIALOG_ALERT_TYPE]: '',
                        [DIALOG_ALERT_MSG]: '',
                        [DIALOG_ALERT_CALLBACK]: null
                    }
                });
            } else if (action.dialog == QUESTION_DELETE_SLOT_FILE) {
                return Object.assign({}, state, {
                    [QUESTION_DELETE_SLOT_FILE]: {
                        [IS_SHOW]: false,
                        [INDEX]: -1
                    }
                })
            } else if (action.dialog == QUESTION_DELETE_AI_SPEECH_GROUP) {
                return Object.assign({}, state, {
                    [QUESTION_DELETE_AI_SPEECH_GROUP]: {
                        [IS_SHOW]: false,
                        [GROUP_DATA]: null,
                        [GROUP_CALLBACK]: null
                    }
                })
            } else if (action.dialog == QUESTION_DELETE_SPEAKER_INTENT_ARRAY) {
                return Object.assign({}, state, {
                    [QUESTION_DELETE_SPEAKER_INTENT_ARRAY]: {
                        [IS_SHOW]: false,
                        [INTENT_ARRAY_CALLBACK]: null
                    }
                })
            } else if (action.dialog == QUESTION_VR_CLOSE_FULLSCREEN) {
                return Object.assign({}, state, {
                    [QUESTION_VR_CLOSE_FULLSCREEN]: {
                        [IS_SHOW]: false,
                    }
                })
            } else if (action.dialog == DIALOG_EDIT_AI_SPEECH_GROUP) {
                return Object.assign({}, state, {
                    [DIALOG_EDIT_AI_SPEECH_GROUP]: {
                        [IS_SHOW]: false,
                        [GROUP_DATA]: null
                    }
                })
            } else if (action.dialog == DIALOG_AUDIO_UPPER_BOUND) {
                return Object.assign({}, state, {
                    [DIALOG_AUDIO_UPPER_BOUND]: false,
                    [DIALOG_ERROR]: {
                        [IS_SHOW]: false,
                        [DIALOG_ERROR_TYPE]: '',
                        [DIALOG_ERROR_MSG]: 'Unknown'
                    }
                })
            } else {
                return Object.assign({}, state, {
                    [action.dialog]: false
                });
            }
        case SET_SAVECANCEL:
        case SET_FEEDBACK:
            return Object.assign({}, state, {
                [action.dialog]: action.status
            });
        case FOCUS_DIALOG:
            return Object.assign({}, state, {
                [FOREGROUND_DIALOG]: action.dialog
            });
        default:
            return state;
    }
};

const focusDialog = dialog => ({
    type: FOCUS_DIALOG,
    dialog: dialog
})

const showDialog = (dialog, platform) => ({
    type: SHOW_DIALOG,
    dialog: dialog,
    platform: platform
});
const hideDialog = dialog => ({
    type: HIDE_DIALOG,
    dialog: dialog
});

const showQuestionDialog = question => ({
    type: SHOW_DIALOG,
    dialog: DIALOG_QUESTION,
    question: question
});
const hideQuestionDialog = question => ({
    type: HIDE_DIALOG,
    dialog: DIALOG_QUESTION,
    question: question
});

const setFeedback = status => ({
    type: SET_FEEDBACK,
    dialog: FEEDBACK_STATUS,
    status: status
});

const setSaveCancel = status => ({
    type: SET_SAVECANCEL,
    dialog: IS_SAVE_CANCEL,
    status: status
});

const showBrainTypePicker = picker => ({
    type: SHOW_DIALOG,
    dialog: BRAIN_TYPE_PICKER,
    picker: picker
});

const hideBrainTypePicker = picker => ({
    type: HIDE_DIALOG,
    dialog: BRAIN_TYPE_PICKER,
    picker: picker
});

const showErrorDialog = (errorType, msg) => ({
    type: SHOW_DIALOG,
    dialog: DIALOG_ERROR,
    errorType: errorType,
    msg: msg
});

const showAudioUpperBoundErrorDialog = (errorType) => ({
    type: SHOW_DIALOG,
    dialog: DIALOG_AUDIO_UPPER_BOUND,
    errorType: errorType,
});

const showQuestionDeleteSlotDialog = index => ({
    type: SHOW_DIALOG,
    dialog: QUESTION_DELETE_SLOT_FILE,
    index: index
});

const showQuestionDeleteAISpeechGroupDialog = (group, callback) => ({
    type: SHOW_DIALOG,
    dialog: QUESTION_DELETE_AI_SPEECH_GROUP,
    group: group,
    callback: callback
});

const showQuestionDeleteSpeakerIntentArrayDialog = (callback) => ({
    type: SHOW_DIALOG,
    dialog: QUESTION_DELETE_SPEAKER_INTENT_ARRAY,
    callback: callback
});

const showWifiSettingDialog = () => showDialog(DIALOG_WIFI_SETTING);
const hideWifiSettingDialog = () => hideDialog(DIALOG_WIFI_SETTING);
const isWifiSettingDialogShow = state => state.scratchGui.dialog[DIALOG_WIFI_SETTING];

const showVisionTutorialDialog = () => showDialog(DIALOG_VISION_TUTORIAL);
const hideVisionTutorialDialog = () => hideDialog(DIALOG_VISION_TUTORIAL);
const isVisionTutorialDialogShow = state => state.scratchGui.dialog[DIALOG_VISION_TUTORIAL];

const showVisionSettingDialog = () => showDialog(DIALOG_VISION_SETTING);
const hideVisionSettingDialog = () => hideDialog(DIALOG_VISION_SETTING);
const isVisionSettingDialogShow = state => state.scratchGui.dialog[DIALOG_VISION_SETTING];

const showStreamingDialog = () => showDialog(DIALOG_STREAMING);
const hideStreamingDialog = () => hideDialog(DIALOG_STREAMING);
const isStreamingDialogShow = state => state.scratchGui.dialog[DIALOG_STREAMING];

const showSpeakerSkillDialog = () => showDialog(DIALOG_SPEAKER_SKILL_SETTING);
const hideSpeakerSkillDialog = () => hideDialog(DIALOG_SPEAKER_SKILL_SETTING);
const isSpeakerSkillDialogShow = state => state.scratchGui.dialog[DIALOG_SPEAKER_SKILL_SETTING];

const showSpeakerCustomizingDialog = () => showDialog(DIALOG_SPEAKER_CUSTOMIZING_SETTING);
const hideSpeakerCustomizingDialog = () => hideDialog(DIALOG_SPEAKER_CUSTOMIZING_SETTING);
const isSpeakerCustomizingDialogShow = state => state.scratchGui.dialog[DIALOG_SPEAKER_CUSTOMIZING_SETTING];

const showSpeakerConceptDialog = () => showDialog(DIALOG_SPEAKER_CONCEPT);
const hideSpeakerConceptDialog = () => hideDialog(DIALOG_SPEAKER_CONCEPT);
const isSpeakerConceptDialogShow = state => state.scratchGui.dialog[DIALOG_SPEAKER_CONCEPT];

const hideQuestionDeleteSlotDialog = () => hideDialog(QUESTION_DELETE_SLOT_FILE);
const isQuestionDeleteSlotDialogShow = state => state.scratchGui.dialog[QUESTION_DELETE_SLOT_FILE][IS_SHOW];
const getQuestionDeleteSlotIndex = state => state.scratchGui.dialog[QUESTION_DELETE_SLOT_FILE][INDEX];

const hideErrorDialog = () => hideDialog(DIALOG_ERROR);

const isErrorDialogShow = state => state.scratchGui.dialog[DIALOG_ERROR][IS_SHOW];
const getErrorType = state => state.scratchGui.dialog[DIALOG_ERROR][DIALOG_ERROR_TYPE];
const getErrorMsg = state => state.scratchGui.dialog[DIALOG_ERROR][DIALOG_ERROR_MSG];

const getFeedback = state => state.scratchGui.dialog[FEEDBACK_STATUS];

const showCloseAppDialog = () => showQuestionDialog(QUESTION_CLOSE_APP_SAVE_FILE);
const hideCloseAppDialog = () => hideQuestionDialog(QUESTION_CLOSE_APP_SAVE_FILE);
const isCloseAppDialogShow = state => quesitonDialogShow(state, QUESTION_CLOSE_APP_SAVE_FILE);

const showAboutDialog = () => showDialog(DIALOG_ABOUT);
const hideAboutDialog = () => hideDialog(DIALOG_ABOUT);
const aboutDialogShow = state => state.scratchGui.dialog[DIALOG_ABOUT];

const showUpdateDialog = () => showDialog(DIALOG_UPDATE);
const hideUpdateDialog = () => hideDialog(DIALOG_UPDATE);
const updateDialogShow = state => state.scratchGui.dialog[DIALOG_UPDATE];

const showLicenseDialog = () => showDialog(DIALOG_LICENSE);
const hideLicenseDialog = () => hideDialog(DIALOG_LICENSE);
const licenseDialogShow = state => state.scratchGui.dialog[DIALOG_LICENSE];

const showHistoryDialog = () => showDialog(DIALOG_HISTORY);
const hideHistoryDialog = () => hideDialog(DIALOG_HISTORY);
const historyDialogShow = state => state.scratchGui.dialog[DIALOG_HISTORY];

const showFirmwarmUpdateDialog = () => showDialog(DIALOG_FIRMWARM_UPDATE);
const hideFirmwarmUpdateDialog = () => hideDialog(DIALOG_FIRMWARM_UPDATE);
const firmwarmUpdateDialogShow = state => state.scratchGui.dialog[DIALOG_FIRMWARM_UPDATE];

const showExampleDialog = () => showDialog(DIALOG_EXAMPLE);
const hideExampleDialog = () => hideDialog(DIALOG_EXAMPLE);
const exampleDialogShow = state => state.scratchGui.dialog[DIALOG_EXAMPLE];

const showDeviceHelpDialog = (platform) => showDialog(DIALOG_DEVICE_HELP, platform);
const hideDeviceHelpDialog = () => hideDialog(DIALOG_DEVICE_HELP);
const isDeviceHelpDialogShow = state => state.scratchGui.dialog[DIALOG_DEVICE_HELP];

const showBoardGameDialog = () => showDialog(DIALOG_BOARD_GAME);
const hideBoardGameDialog = () => hideDialog(DIALOG_BOARD_GAME);
const isBoardGameDialogShow = state => state.scratchGui.dialog[DIALOG_BOARD_GAME];

const quesitonDialogShow = (state, question) => state.scratchGui.dialog[question];

const showStartAppBrainTypePicker = () => showBrainTypePicker(PICKER_START_APP);
const hideStartAppBrainTypePicker = () => hideBrainTypePicker(PICKER_START_APP);
const brainTypePickerShow = (state, picker) => state.scratchGui.dialog[BRAIN_TYPE_PICKER][picker];

const showHintBlockHelpDialog = () => showDialog(DIALOG_HINT_BLOCK_HELP);
const hideHintBlockHelpDialog = () => hideDialog(DIALOG_HINT_BLOCK_HELP);
const isHintBlockHelpDialogShow = state => state.scratchGui.dialog[DIALOG_HINT_BLOCK_HELP];

const showBlockHelpDialog = (platform) => showDialog(DIALOG_BLOCK_HELP, platform);
const hideBlockHelpDialog = () => hideDialog(DIALOG_BLOCK_HELP);
const isBlockHelpDialogShow = state => state.scratchGui.dialog[DIALOG_BLOCK_HELP];

const showAlertDialog = (alertType, msg, callback) => ({
    type: SHOW_DIALOG,
    dialog: DIALOG_ALERT,
    alertType: alertType,
    msg: msg,
    callback: callback
});
const hideAlertDialog = () => hideDialog(DIALOG_ALERT);
const isAlertDialogShow = state => state.scratchGui.dialog[DIALOG_ALERT][IS_SHOW];
const getAlertType = state => state.scratchGui.dialog[DIALOG_ALERT][DIALOG_ALERT_TYPE];
const getAlertMsg = state => state.scratchGui.dialog[DIALOG_ALERT][DIALOG_ALERT_MSG];
const getAlertCallback = state => state.scratchGui.dialog[DIALOG_ALERT][DIALOG_ALERT_CALLBACK];

const getForegroundDialog = state => state.scratchGui.dialog[FOREGROUND_DIALOG];
const isWhatsNewDialogShow = state => state.scratchGui.dialog[DIALOG_WHATS_NEW];
const showWhatsNewDialog = () => showDialog(DIALOG_WHATS_NEW);
const hideWhatsNewDialog = () => hideDialog(DIALOG_WHATS_NEW);

const showEditBlockDialog = () => showDialog(DIALOG_EDIT_BLOCK);
const hideEditBlockDialog = () => hideDialog(DIALOG_EDIT_BLOCK);
const editBlockDialogShow = state => state.scratchGui.dialog[DIALOG_EDIT_BLOCK];

const showUpdateRCTutorialDialog = () => showDialog(DIALOG_UPDATE_RC_TUTORIAL);
const hideUpdateRCTutorialDialog = () => hideDialog(DIALOG_UPDATE_RC_TUTORIAL);
const isUpdateRCTutorialDialogShow = state => state.scratchGui.dialog[DIALOG_UPDATE_RC_TUTORIAL];


const showPadFileSaveDialog = () => showDialog(DIALOG_PAD_SAVE_FILE);
const hidePadFileSaveDialog = () => hideDialog(DIALOG_PAD_SAVE_FILE);
const isPadFileSaveDialogShow = state => state.scratchGui.dialog[DIALOG_PAD_SAVE_FILE];

const showPadFileOpenDialog = () => showDialog(DIALOG_PAD_OPEN_FILE);
const hidePadFileOpenDialog = () => hideDialog(DIALOG_PAD_OPEN_FILE);
const isPadFileOpenDialogShow = state => state.scratchGui.dialog[DIALOG_PAD_OPEN_FILE];

const showAddAISpeechGroupDialog = () => showDialog(DIALOG_ADD_AI_SPEECH_GROUP);
const hideAddAISpeechGroupDialog = () => hideDialog(DIALOG_ADD_AI_SPEECH_GROUP);
const isAddAISpeechGroupDialogShow = state => state.scratchGui.dialog[DIALOG_ADD_AI_SPEECH_GROUP];

const showEditAISpeechGroupDialog = (group) => ({
    type: SHOW_DIALOG,
    dialog: DIALOG_EDIT_AI_SPEECH_GROUP,
    group: group
});
const hideEditAISpeechGroupDialog = () => hideDialog(DIALOG_EDIT_AI_SPEECH_GROUP);
const isEditAISpeechGroupDialogShow = state => state.scratchGui.dialog[DIALOG_EDIT_AI_SPEECH_GROUP][IS_SHOW];
const getEditAISpeechGroupData = state => state.scratchGui.dialog[DIALOG_EDIT_AI_SPEECH_GROUP][GROUP_DATA];

const hideQuestionDeleteAISpeechGroupDialog = () => hideDialog(QUESTION_DELETE_AI_SPEECH_GROUP);
const isQuestionDeleteAISpeechGroupDialogShow = state => state.scratchGui.dialog[QUESTION_DELETE_AI_SPEECH_GROUP][IS_SHOW];
const getQuestionDeleteAISpeechGroupData = state => state.scratchGui.dialog[QUESTION_DELETE_AI_SPEECH_GROUP][GROUP_DATA];
const getQuestionDeleteAISpeechGroupCallback = state => state.scratchGui.dialog[QUESTION_DELETE_AI_SPEECH_GROUP][GROUP_CALLBACK];

const hideQuestionDeleteSpeakerIntentArrayDialog = () => hideDialog(QUESTION_DELETE_SPEAKER_INTENT_ARRAY);
const isQuestionDeleteSpeakerIntentArrayDialogShow = state => state.scratchGui.dialog[QUESTION_DELETE_SPEAKER_INTENT_ARRAY][IS_SHOW];
const getQuestionDeleteSpeakerIntentArrayCallback = state => state.scratchGui.dialog[QUESTION_DELETE_SPEAKER_INTENT_ARRAY][INTENT_ARRAY_CALLBACK];

const hideAudioUpperBoundErrorDialog = () => hideDialog(DIALOG_AUDIO_UPPER_BOUND);
const isAudioUpperBoundErrorDialogShow = state => state.scratchGui.dialog[DIALOG_AUDIO_UPPER_BOUND];

const showWifiDirectionDialog = () => showDialog(DIALOG_WIFI_DIRECTION);
const hideWifiDirectionDialog = () => hideDialog(DIALOG_WIFI_DIRECTION);
const isWifiDirectionDialogShow = state => state.scratchGui.dialog[DIALOG_WIFI_DIRECTION];

const showWifiiPadSettingDialog = () => showDialog(DIALOG_WIFI_IPAD_SETTING);
const hideWifiiPadSettingDialog = () => hideDialog(DIALOG_WIFI_IPAD_SETTING);
const isWifiiPadSettingDialogShow = state => state.scratchGui.dialog[DIALOG_WIFI_IPAD_SETTING];

const showWifiIpSettingDialog = () => showDialog(DIALOG_WIFI_IP_SETTING);
const hideWifiIpSettingDialog = () => hideDialog(DIALOG_WIFI_IP_SETTING);
const isWifiIpSettingDialogShow = state => state.scratchGui.dialog[DIALOG_WIFI_IP_SETTING];

const isSaveCancel = state => state.scratchGui.dialog[IS_SAVE_CANCEL];

const showVRRemindDialog = () => showQuestionDialog(QUESTION_VR_REMIND);
const hideVRRemindDialog = () => hideQuestionDialog(QUESTION_VR_REMIND);
const isVRRemindDialogShow = state => quesitonDialogShow(state, QUESTION_VR_REMIND);

const showBottomHintDialog = () => showDialog(DIALOG_BOTTOM_HINT);
const hideBottomHintDialog = () => hideDialog(DIALOG_BOTTOM_HINT);
const isBottomHintDialogShow = state => state.scratchGui.dialog[DIALOG_BOTTOM_HINT];

export {
    reducer as default,
    initialState as dialogInitialState,
    questionType,
    setFeedback,
    getFeedback,
    showCloseAppDialog,
    hideCloseAppDialog,
    isCloseAppDialogShow,
    showAboutDialog,
    hideAboutDialog,
    aboutDialogShow,
    showUpdateDialog,
    hideUpdateDialog,
    updateDialogShow,
    showQuestionDialog,
    hideQuestionDialog,
    quesitonDialogShow,
    showFirmwarmUpdateDialog,
    hideFirmwarmUpdateDialog,
    firmwarmUpdateDialogShow,
    pickerType,
    brainTypePickerShow,
    showBrainTypePicker,
    hideBrainTypePicker,
    showStartAppBrainTypePicker,
    hideStartAppBrainTypePicker,
    showExampleDialog,
    hideExampleDialog,
    exampleDialogShow,
    showDeviceHelpDialog,
    hideDeviceHelpDialog,
    isDeviceHelpDialogShow,

    showHintBlockHelpDialog,
    hideHintBlockHelpDialog,
    isHintBlockHelpDialogShow,

    showBlockHelpDialog,
    hideBlockHelpDialog,
    isBlockHelpDialogShow,

    showAlertDialog,
    hideAlertDialog,
    isAlertDialogShow,
    getAlertMsg,
    getAlertCallback,
    getAlertType,

    showWhatsNewDialog,
    hideWhatsNewDialog,
    isWhatsNewDialogShow,

    showErrorDialog,
    hideErrorDialog,
    errorType,
    getErrorType,
    getErrorMsg,
    isErrorDialogShow,
    focusDialog,
    getForegroundDialog,

    showQuestionDeleteSlotDialog,
    hideQuestionDeleteSlotDialog,
    isQuestionDeleteSlotDialogShow,
    getQuestionDeleteSlotIndex,

    showBoardGameDialog,
    hideBoardGameDialog,
    isBoardGameDialogShow,

    DIALOG_DEVICE_HELP,
    DIALOG_BLOCK_HELP,
    DIALOG_BOARD_GAME,
    DIALOG_STREAMING,
    DIALOG_STREAMING_POPUP,
    FOREGROUND_INDEX,
    BACKGROUND_INDEX,

    editBlockType,
    showEditBlockDialog,
    hideEditBlockDialog,
    editBlockDialogShow,

    showWifiSettingDialog,
    hideWifiSettingDialog,
    isWifiSettingDialogShow,

    showVisionTutorialDialog,
    hideVisionTutorialDialog,
    isVisionTutorialDialogShow,

    showVisionSettingDialog,
    hideVisionSettingDialog,
    isVisionSettingDialogShow,

    showUpdateRCTutorialDialog,
    hideUpdateRCTutorialDialog,
    isUpdateRCTutorialDialogShow,

    showPadFileSaveDialog,
    hidePadFileSaveDialog,
    isPadFileSaveDialogShow,

    showPadFileOpenDialog,
    hidePadFileOpenDialog,
    isPadFileOpenDialogShow,

    showSpeakerSkillDialog,
    hideSpeakerSkillDialog,
    isSpeakerSkillDialogShow,

    showStreamingDialog,
    hideStreamingDialog,
    isStreamingDialogShow,

    showSpeakerCustomizingDialog,
    hideSpeakerCustomizingDialog,
    isSpeakerCustomizingDialogShow,

    showSpeakerConceptDialog,
    hideSpeakerConceptDialog,
    isSpeakerConceptDialogShow,

    showAddAISpeechGroupDialog,
    hideAddAISpeechGroupDialog,
    isAddAISpeechGroupDialogShow,

    showEditAISpeechGroupDialog,
    hideEditAISpeechGroupDialog,
    isEditAISpeechGroupDialogShow,
    getEditAISpeechGroupData,

    aiSpeechGroupType,
    showQuestionDeleteAISpeechGroupDialog,
    hideQuestionDeleteAISpeechGroupDialog,
    isQuestionDeleteAISpeechGroupDialogShow,
    getQuestionDeleteAISpeechGroupData,
    getQuestionDeleteAISpeechGroupCallback,

    showQuestionDeleteSpeakerIntentArrayDialog,
    hideQuestionDeleteSpeakerIntentArrayDialog,
    isQuestionDeleteSpeakerIntentArrayDialogShow,
    getQuestionDeleteSpeakerIntentArrayCallback,

    showAudioUpperBoundErrorDialog,
    hideAudioUpperBoundErrorDialog,
    isAudioUpperBoundErrorDialogShow,

    showWifiDirectionDialog,
    hideWifiDirectionDialog,
    isWifiDirectionDialogShow,

    showWifiiPadSettingDialog,
    hideWifiiPadSettingDialog,
    isWifiiPadSettingDialogShow,

    showWifiIpSettingDialog,
    hideWifiIpSettingDialog,
    isWifiIpSettingDialogShow,

    setSaveCancel,
    isSaveCancel,

    showVRRemindDialog,
    hideVRRemindDialog,
    isVRRemindDialogShow,

    showLicenseDialog,
    hideLicenseDialog,
    licenseDialogShow,

    showHistoryDialog,
    hideHistoryDialog,
    historyDialogShow,

    showBottomHintDialog,
    hideBottomHintDialog,
    isBottomHintDialogShow
};
