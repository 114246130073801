import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import React from 'react';
import { STAGE_SIZE_MODES, STAGE_DISPLAY_TYPE } from '../../lib/layout-constants';
import { getPickedBrainType, updatePickedBrainType, isEnableWebVR } from '../../reducers/picked-brain-type'
import { BRAIN_TYPE, DeviceStateCmd } from '../../lib/brains'

import log from '../../lib/log.js';
import { isServerNewer, filterBrainVer } from '../../lib/string-utils.js';

import VM from 'scratch-vm';

import { DEFAULT_LOCALE } from '../../config/project-config';
import Box from '../box/box.jsx';
import SBFileUploader from '../../containers/sb-file-uploader.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import { MenuItem, MenuDisableItem } from '../menu/menu.jsx';
import ProjectTitleInput from './project-title-input.jsx';
import MenuBarHOC from '../../containers/menu-bar-hoc.jsx';
import WriteScript from '../../containers/run-script.jsx';
import BrainInfo from './brain-info.jsx';
import SlotInfo from './slot-info.jsx';
import ControllerInfo from './controller-info.jsx'

import { openExampleLibrary } from '../../reducers/modals';
import { viewPage, setStageSize, getFullScreen } from '../../reducers/stage-size';
import {
    isFileProcessing
} from '../../reducers/file-manager';
import {
    projectTitleInitialState,
    projectTitle
} from '../../reducers/project-title';

import {
    onReloadProject,
    autoUpdateProject,
    manualUpdateProject,
    requestNewProject,
    setDirectNew,
    resetDirectNew
} from '../../reducers/project-state';

import {
    getWorkspace,
    setBlockHelpBlockType
} from '../../reducers/block';

import {
    getControllerInfo,
    getAllControllerPortInfo,
    isRCFirmwareUpdating
} from '../../reducers/controller';

import {
    setSlotList,
    getSlotList,
} from '../../reducers/slot-list';
import { matchExtension } from '../../lib/file-uploader';

import {
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    openHelpMenu,
    closeHelpMenu,
    helpMenuOpen,
    openSettingMenu,
    closeSettingMenu,
    settingMenuOpen,
    openMoreMenu,
    closeMoreMenu,
    moreMenuOpen,
    isSlotMenuOpen,
    openSlotMenu,
    closeSlotMenu,
    brainInfoMenuOpen,
    openBrainInfoMenu,
    closeBrainInfoMenu,
    openControllerInfoMenu,
    closeControllerInfoMenu,
    isControllerInfoMenuOpen,
    closeAllMenu
} from '../../reducers/menus';

import styles from './menu-bar.css';

import {
    errorType,
    questionType,
    showAboutDialog,
    showQuestionDialog,
    hideQuestionDialog,
    quesitonDialogShow,
    pickerType,
    showBrainTypePicker,
    showHintBlockHelpDialog,
    showBlockHelpDialog,
    showDeviceHelpDialog,
    showBoardGameDialog,
    showErrorDialog,
    showStreamingDialog,
    hideVisionSettingDialog,
    hideStartAppBrainTypePicker
} from '../../reducers/dialog';
import { getSelectedSlot } from '../../reducers/slot-list';
import { selectLocale, localesInitialState } from '../../reducers/locales';

import {
    activateDeck
} from '../../reducers/cards';

import MenuSubMenu from './menu-submenu.jsx';

import {
    BRAIN_STATUS_TYPE,
    DownloadType,
    getDriverInstallStatus,
    getBrainDownloadingStatus,
    getBrainRunningStatus,
    getBrainConnectStatus,
    getBrainUpdateStatus,
    getBrainFWUpdatingStatus,
    getBrainStatus,
    getBrainInfo,
    setBrainVer,
    getBrainVer,
    getFirmwareConfig,
    getAllPortInfo,
    setBrainDownloadAndRun,
    getBrainDownloadAndRun,
    setBrainDownloadType,
    setCheckBluetoothStatus,
    getCheckBluetoothStatus,
    setBrainDownloadingStatus,
    MB_TYPE,
    forceOpenBrainInfo,
    getForceOpenBrainInfoStatus,
    setBrainRunningStatus,
    setBrainStatus
} from '../../reducers/brain';

import {
    platformType,
    isPad,
    getPlatform,
    isReactNative
} from '../../lib/platform';

import {
    Catagory,
    FileAction,
    postMessage
} from '../../lib/postmessage';

import {
    getTaskProgress,
    getTaskArray,
    getInProgressTask,
    cleanTask,
    taskType
} from '../../reducers/task';

import BrainDriverHint from './brain-driver-hint.jsx';
import BrainInfoMobile from './brain-info-mobile.jsx';
import {
    BluetoothStatus,
    BluetoothAction
} from '../../lib/bluetooth';

import QuestionDialog from '../dialog/question-dialog.jsx';
import { setProjectTitleisSave } from '../../reducers/project-title-changed';
import {
    setProjectPreTitle,
    setProjectNew
} from '../../reducers/project-title';

import LocalKey from '../../lib/local-storage-key';

import store from 'store';

import brainOnIcon from './svg/brain_on.svg';
import brainOffIcon from './svg/brain_off.svg';
import brainUpdateIcon from './svg/brain_on.svg';
import brainUpdateCursorIcon from './svg/brain_updated_cursor.svg';
import brainOnIconiPad from './svg/brain_on_ipad.svg';
import newIcon from './svg/new.svg';
import blockTag from './svg/blocks_tag.svg';
import blockTagWW from './svg/ww/blocks_tag.svg';
import blockTagDisable from './svg/blocks_tag_disable.svg';
import pythonTag from './svg/python_tag.svg';
import pythonTagWW from './svg/ww/python_tag.svg';
import controllerIcon from './svg/controller.svg';
import controllerIconWhite from './svg/controller_white.svg';
import exclamationIcon from './svg/exclamation_mark.svg';

import { isShowUserGuide, updateShouldShowUserGuide, shouldShowUserGuide, updateShowUserGuide, updateUserGuideState, getUserGuideCurrentState } from '../../reducers/user-guide';
import { USER_GUIDE_STATE } from '../../lib/user-guide-state'
import { EDIT_MODE } from '../../lib/edit-mode';
import {
    getEditorDisplayMode,
    getEditor,
    getUndoState,
    getRedoState,
    setCodeView,
    getCodeViewState,
    setHighLightMode,
    getHighLightMode
} from '../../reducers/code-editor';

import { getUpdate } from '../../reducers/software-update';

import * as svg from 'save-svg-as-png';

import {
    uiType,
    getUIStyle
} from '../../reducers/ui-style';

import {
    getUnityMessageFunc,
    setVRSaveFile,
    setVRLangChange,
    getVRSaveFile,
    getVRLangChange,
    setVRIsReady,
    getVRIsReady
} from '../../reducers/vr';
import { SocketAction } from '../../lib/socket';

import blockHelpIndex from '../../../static/help/blockhelp/index.json'
import { EditUtils } from '../device-manager/edit-page/edit-utils.js';

const MenuItemTooltip = ({ id, isRtl, children, className }) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

const ariaMessages = defineMessages({
    undo: {
        id: 'gui.menuBar.undo',
        defaultMessage: 'Undo',
        description: 'Menu bar item for undo action'
    },
    redo: {
        id: 'gui.menuBar.redo',
        defaultMessage: 'Redo',
        description: 'Menu bar item for redo action'
    },
    arrageBlock: {
        id: 'gui.menuBar.arrange',
        defaultMessage: 'Arrange Blocks',
        description: 'Menu bar item for arrange blocks'
    },
    collapseBlock: {
        id: 'gui.menuBar.collapseBlock',
        defaultMessage: 'Collapse All Blocks',
        description: 'Menu bar item for collapse Block'
    },
    expandBlock: {
        id: 'gui.menuBar.expandBlock',
        defaultMessage: 'Expand All Blocks',
        description: 'Menu bar item for Expand blocks'
    },
    clear: {
        id: 'gui.menuBar.clear',
        defaultMessage: 'Delete all Block',
        description: 'Menu bar item for delete all block'
    },
});

function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

const brainSNDisplayNum = 7;

class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleOpenEditMenu',
            'handleClickDirectNew',
            'handleClickNew',
            'handleClickOpen',
            'handleClickOpenRecent',
            'handleClickOpenExample',
            'handleClickOpenBlockHelp',
            'handleClickAbout',
            'handleClickSave',
            'handleClickCourse',
            'handleClickSaveAsCopy',
            'handleRestoreOption',
            'handleClickOutputPNG',
            'handleClickRedo',
            'handleClickUndo',
            'handleClickCopy',
            'handleClickPaste',
            'handleClickArrageBlock',
            'handleClickClearAllBlock',
            'initialDownloadStatus',
            'handleClickRunScript',
            'handleClickStopScript',
            'handleClickMore',
            'handleClickHelp',
            'hendleClickOpenDeviceHelp',
            'hendleClickOpenFAQ',
            'handleBeginnerGuide',
            'handleBrainTypeSrc',
            'handleConnectComPortPath',
            'handleResize',
            'handleClickBrain',
            'handleClickSlotMenu',
            'handleProjectNameDialogClose',
            'hasAnyMenuOpen',
            'handleClickCodeEditor',
            'getOS',
            'isFileCanWrite',
            'getDefaultHelpBlockType'
        ]);

        this.state = {
            isShowLogoIcon: true,
            brainIcon: brainOffIcon,
            isShowMore: false,
            percentage: 0,
            edit: {
                hasUndoStack: false,
                hasRedoStack: false,
                isCleanUp: false,
                hasBlocks: false
            },
            isExecuteDownload: false,
            isFirmwareCanUpdate: false,
            lang: store.get("locale")
        }
        this.os = this.getOS();
    }

    updateProgress() {
        let progress = this.props.taskProgress;
        if (progress && progress.percentage && progress.percentage <= 100 && progress.percentage >= 0) {
            this.setState({
                percentage: progress.percentage
            });
            if (this.props.taskName == taskType.DOWNLOAD_SCRIPT && progress.percentage == 100) {
                this.props.cleanTask();
            }
        }
    }

    handleFirmwareCanUpdateChange() {
        let lastestVer = "0.0.0.1";
        if (this.props.firmwareConfig && this.props.firmwareConfig[MB_TYPE[this.props.brainInfo.brain_type]]) {
            lastestVer = this.props.firmwareConfig[MB_TYPE[this.props.brainInfo.brain_type]]['lastest']
        }
        if (this.props.isBrainConnected && this.props.brainVer) {
            this.setState({
                isFirmwareCanUpdate: this.checkIsFirmwareCanUpdate(this.props.brainVer.brain, lastestVer)
            })
        } else {
            this.setState({ isFirmwareCanUpdate: false })
        }
    }

    checkIsFirmwareCanUpdate(currentVer, lastestVer) {
        currentVer = filterBrainVer(currentVer)
        lastestVer = filterBrainVer(lastestVer)
        return (currentVer && lastestVer && lastestVer != "0.0.0.1")
            ? isServerNewer(currentVer, lastestVer)
            : false
    }

    componentDidMount() {
        window.menubar = this;
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.handleBrainTypeSrc();
        window.addEventListener('click', this.clickListener);
    }

    clickListener(event) {
        if (isPad()) {
            if (event.target.matches('a[href^="blob:"]')) {
                event.preventDefault();
                (async el => {
                    const url = el.href;
                    const blob = await fetch(url).then(r => r.blob());
                    var fileReader = new FileReader();
                    fileReader.onload = function (e) { window.open(el.href = e.target.result, el.target || '_self'); }
                    fileReader.readAsDataURL(blob);
                })(event.target);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.taskName == taskType.DOWNLOAD_SCRIPT && this.props.taskProgress != prevProps.taskProgress) {
            this.updateProgress()
        }

        if (prevProps.isCheckBluetooth != this.props.isCheckBluetooth && !this.props.isCheckBluetooth) {
            log.info('doActionWriteScript after CheckBluetooth');
            this.props.doActionWriteScript();
        }

        if (prevProps.isBrainConnected != this.props.isBrainConnected
            || prevProps.isBrainUpdate != this.props.isBrainUpdate
            || prevProps.allPortInfo != this.props.allPortInfo
            || prevProps.isBrainConnect != this.props.isBrainConnect
            || prevProps.isBrainFWUpdating != this.props.isBrainFWUpdating) {
            this.handleBrainTypeSrc();
            this.handleFirmwareCanUpdateChange();
        }

        if ((this.props.brainVer != prevProps.brainVer)
            || (this.props.firmwareConfig != prevProps.firmwareConfig)) {
            this.handleFirmwareCanUpdateChange();
        }
        if (prevProps.getBrainDownloadAndRun != this.props.getBrainDownloadAndRun && this.props.getBrainDownloadAndRun) {
            log.info("prevProps.getBrainDownloadAndRun != this.props.getBrainDownloadAndRun");
            this.props.setBrainDownloadAndRun(false);
            this.handleClickRunScript();
        }
        if (this.props.isShowUserGuide && this.props.userGuideCurrentState == USER_GUIDE_STATE.DOWNLOADING) {
            if (!this.props.isBrainDownloading && prevProps.isBrainDownloading) {
                log.info('[User Guide] FINISH download progress is finished');
                this.props.onUpdateUserGuideState(USER_GUIDE_STATE.FINISH);
            }
            if (!this.props.isBrainConnected) {
                this.showDownloadFail();
                this.props.setBrainDownloadingStatus(false);
            }
        }

        if (this.props.getVRSaveFile && this.props.getVRLangChange) {
            this.props.setVRSaveFile(false);
            this.props.onChangeLanguage(this.state.lang, this.props.getUIStyle, localesInitialState);
        }

        if (prevProps.getForceOpenBrainInfoStatus != this.props.getForceOpenBrainInfoStatus && this.props.getForceOpenBrainInfoStatus) {
            this.props.forceOpenBrainInfo(false);
            this.handleClickBrain();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    getOS() {
        var os = "Not known";
        var platform = window.navigator.platform
        if (platform === "MacIntel" && window.navigator.maxTouchPoints > 1) {
            os = "iPad iOS 13";
        }
        else {
            os = "Mac OS";
        }
        if (platform === "Win32") {
            os = "Windows OS";
        }
        log.info('getOS: ' + os);
        return os;
    }

    handleResize() {
        if (window.innerWidth < 1024) {
            this.setState({
                isShowMore: true,
                isShowLogoIcon: false
            });
        }
        else {
            this.setState({
                isShowMore: false,
                isShowLogoIcon: true
            });
        }
    }

    hasAnyMenuOpen() {
        if (isPad()) {
            return true;
        }
        return (this.props.fileMenuOpen ||
            this.props.editMenuOpen ||
            this.props.helpMenuOpen ||
            this.props.settingMenuOpen ||
            this.props.moreMenuOpen ||
            this.props.isSlotMenuOpen ||
            this.props.brainInfoMenuOpen ||
            this.props.isControllerInfoMenuOpen
        );
    }

    handleBrainTypeSrc() {
        this.setState({
            brainIcon: this.props.isBrainConnected ?
                this.props.isBrainFWUpdating ? brainUpdateIcon
                    : (isPad()) ? brainOnIconiPad : brainOnIcon
                : brainOffIcon
        })
    }

    handleOpenEditMenu() {
        if (this.props.editorDisplayMode == EDIT_MODE.BLOCK_MODE) {
            let checkBlockMinCount = 0;
            if (this.props.pickBrainType == BRAIN_TYPE.WEB_VR) {
                checkBlockMinCount = 1; //Always exists event_whenstarted
            }

            this.setState({
                edit: {
                    hasUndoStack: this.props.getWorkspace.hasUndoStack(),
                    hasRedoStack: this.props.getWorkspace.hasRedoStack(),
                    isCleanUp: this.props.getWorkspace.isCleanUp(),
                    hasBlocks: this.props.getWorkspace.getBlockCountOnWorkspace() > checkBlockMinCount ? true : false
                }
            })
        } else {
            this.setState({
                edit: {
                    hasUndoStack: this.props.getUndoState,
                    hasRedoStack: this.props.getRedoState
                }
            })
        }
        this.props.onClickEdit();
    }

    handleClickRedo() {
        if (this.props.editorDisplayMode == EDIT_MODE.BLOCK_MODE) {
            this.props.getWorkspace.undo(true);
        } else {
            this.props.getEditor.trigger('', 'redo');
        }
        this.onRequestClose("edit");
    }
    handleClickUndo() {
        if (this.props.editorDisplayMode == EDIT_MODE.BLOCK_MODE) {
            this.props.getWorkspace.undo(false);
        } else {
            this.props.getEditor.trigger('', 'undo');
        }
        this.onRequestClose("edit");
    }
    handleClickCopy() {
        this.props.getEditor.trigger('source', 'editor.action.clipboardCopyAction');
        this.onRequestClose("edit");
    }
    handleClickPaste() {
        let editor = this.props.getEditor;
        navigator.clipboard.readText().then(function (text) {
            let selection = editor.getSelection();
            editor.executeEdits('', [{
                range: new monaco.Range(selection.startLineNumber, selection.startColumn, selection.endLineNumber, selection.endColumn),
                text: text
            }])
        });
        this.onRequestClose("edit");
    }
    handleClickArrageBlock() {
        this.props.getWorkspace.cleanUp();
        this.onRequestClose("edit");
    }
    handleClickClearAllBlock() {
        this.props.getWorkspace.deleteAllBlocks();
        this.onRequestClose("edit");
    }

    handleBeginnerGuide() {
        this.onRequestClose("help");
        this.onRequestClose("more");

        if (this.props.isEnableWebVR && this.props.getVRFullScreen) {
            this.props.onShowQuestionVRCloseFullScreentDialog();
        } else {
            this.props.onUpdateShouldShowUserGuide(true);
            setTimeout(() => this.handleClickNew({ enable: true, brainType: this.props.pickBrainType, editMode: EDIT_MODE.BLOCK_MODE }));
        }
    }
    handleClickOpenExample() {
        log.info('handleClickOpenExample');
        this.props.onOpenExampleLibrary();
        this.onRequestClose("help");
        this.onRequestClose("more");
    }
    handleClickOpenBlockHelp() {
        log.info("handleClickOpenBlockHelp");
        this.props.setBlockHelpBlockTypeToState(this.getDefaultHelpBlockType());
        if (store.get("firstHintBlockHelp")) {
            this.props.onRequestOpenBlockHelp();
        } else {
            store.set("firstHintBlockHelp", true);
            this.props.onRequestOpenHintBlockHelp();
        }
        this.onRequestClose("help");
        this.onRequestClose("more");
    }
    getDefaultHelpBlockType() {
        let defaultHelpBlockType = 'motion_3wire_spin';
        let type = this.props.pickBrainType || "EDU";
        let supportBlocks = blockHelpIndex.support[type];
        let category = null;
        let block = null;
        let categoryEntries = Object.entries(blockHelpIndex.category);
        loop1:
        for (let j = 0; j < categoryEntries.length; j++) {
            category = categoryEntries[j];
            let categorySupportBlocks = category[1].block;
            for (let i = 0; i < categorySupportBlocks.length; i++) {
                block = categorySupportBlocks[i];
                if (supportBlocks && supportBlocks.includes(block)) {
                    if (blockHelpIndex.block[block] && blockHelpIndex.block[block].block_svg && blockHelpIndex.block[block].block_desc) {
                        defaultHelpBlockType = block;
                        break loop1;
                    }
                }
            }
        }
        return defaultHelpBlockType;
    }
    handleClickCourse() {
        console.log("handleClickCourse");
        if (isPad()) {
            postMessage(Catagory.Course, { "url": "https://www.google.com" });
        }
        if (!this.props.showComingSoon) {
            this.props.onClickBrowser("https://www.google.com");
        }
        this.onRequestClose("more");
        if (this.props.isShowUserGuide) {
            this.props.onUpdateShowUserGuide(false);
        }
    }
    hendleClickOpenDeviceHelp() {
        console.log("hendleClickOpenDeviceHelp");
        this.props.openHelpDialog();
        this.onRequestClose("help");
        this.onRequestClose("more");
    }
    hendleClickOpenFAQ() {
        console.log("hendleClickOpenFAQ");
        if (this.props.getUIStyle != uiType.vr) {
            if (getPlatform() == platformType.Desktop) {
                window.open("https://edtech.asus.com/tw/pinbo-faq/", "_blank")
            } else {
                if (isPad()) {
                    postMessage(Catagory.Url, { "url": "https://edtech.asus.com/tw/pinbo-faq/" });
                }
            }
        }
        this.onRequestClose("help");
        this.onRequestClose("more");
    }
    handleConnectComPortPath(comPath) {
        log.info(`handleConnectComPortPath: ${comPath}`)
        this.props.connectComPortPath(comPath)
    }
    handleClickLang(locale) {
        log.info("handleClickLang: " + locale);
        if (this.props.pickBrainType == BRAIN_TYPE.WEB_VR) {
            // need to save vr map json and then change language
            this.props.getUnityMessageFunc('EventSystem', 'SaveFile');
            this.props.setVRLangChange(true);
            if (locale != store.get("locale")) {
                this.props.setVRIsReady(false);
            }
            this.setState({
                lang: locale
            });
        } else {
            this.props.onChangeLanguage(locale, this.props.getUIStyle, localesInitialState);
        }
        this.onRequestClose("setting");
        this.onRequestClose("more");
    }
    handleClickAbout() {
        log.info("handleClickAbout");
        this.props.onClickAbout();
        this.onRequestClose("setting");
        this.onRequestClose("more");
    }
    handleClickDirectNew(directNew) {
        this.props.setDirectNew(directNew.enable, directNew.brainType, directNew.editMode);
        if (this.props.projectChanged) {
            if (getPlatform() == platformType.Web) {
                //Show save Open dialog for web version
                this.props.onShowQuestionSaveNewWebDialog();
            } else {
                this.props.onShowDirectNewSaveDialog();
            }
        } else {
            this.props.onShowDirectNewDialog();
        }
    }
    isFileCanWrite() {
        if (this.props.isFileProcessing || this.props.isBrainDownloading) {
            this.props.onShowFileProcessingErrorDialog();
            return false;
        }
        return true;
    }
    handleClickNew(directNew) {
        // const readyToReplaceProject = this.props.confirmReadyToReplaceProject(
        //     this.props.intl.formatMessage(sharedMessages.replaceProjectWarning)
        // );
        // this.onRequestClose("file");

        // if (readyToReplaceProject) {
        //     console.log("readyToReplaceProject = ", readyToReplaceProject);
        //     console.log("this.props.canSave = ", this.props.canSave);
        //     this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
        //
        if (!this.isFileCanWrite()) {
            return;
        }
        if (directNew) {
            this.props.setDirectNew(directNew.enable, directNew.brainType, directNew.editMode);
        } else {
            this.props.resetDirectNew();
        }

        console.log("projectChanged = ", this.props.projectChanged);
        if (this.props.projectChanged) {
            if (getPlatform() == platformType.Web) {
                //Show save Open dialog for web version
                this.props.onShowQuestionSaveNewWebDialog();
            } else {
                this.props.onShowQuestionSaveNewDialog();
            }
        } else {
            this.props.onShowQuestionOpenNewDialog();
        }
        this.onRequestClose("file");
    }

    handleClickOpen() {
        if (!this.isFileCanWrite()) {
            return;
        }
        if (this.props.projectChanged) {
            if (getPlatform() == platformType.Web) {
                //Show save Open dialog for web version
                this.props.onShowQuestionSaveOpenWebDialog();
            } else {
                this.props.onShowQuestionSaveOpenDialog();
            }
        }
        else {
            if (isPad()) {
                postMessage(Catagory.File, { action: FileAction.open });
            }
            else {
                this.props.onOpenFileClick();
            }
        }
        this.onRequestClose("file");
    }

    openFileFromReactNative(base64Data, name, path, fileType) {
        log.info(`openFileFromReactNative ${name} . ${fileType}`)
        if (fileType != 'abot' && fileType != 'bbot' && fileType != 'cbot') {
            this.props.showErrorDialog(errorType.FILE_FORMAT_INVALID);
        } else {
            this.openFileFromiPad(base64Data, name, path)
        }
    }

    openFileFromiPad(base64Data, name, path) {
        const {
            vm,
            onUpdateProjectTitle,
            setProjectPreTitle,
            setProjectNew,
            hideStartAppBrainTypePicker,
            onPickedBrainType,
            onMenuBarClickSave
        } = this.props;
        setProjectNew(false);
        vm.loadProject(_base64ToArrayBuffer(base64Data)).then(() => {
            if (matchExtension(path, this.props.vm.getBrainType(), false)) {
                onUpdateProjectTitle(name);
                setProjectPreTitle(name);
                vm.setProjectName(name);
                onMenuBarClickSave();
            } else {
                this.props.onShowQuestionExtensionErrDialog();
                this.props.onReloadProject();
            }
        }).catch(error => {
            console.log(error);
        });
        store.set(LocalKey.nowSavePath, path);
        store.set(LocalKey.nowPreSavePath, path);
        hideStartAppBrainTypePicker();
        onPickedBrainType();
    }

    importSentencesFileFromPad(base64Data) {
        this.props.vm.loadSentences(_base64ToArrayBuffer(base64Data));
    }

    handleClickOpenRecent(path) {
        if (!this.isFileCanWrite()) {
            return;
        }
        if (this.props.projectChanged) {
            store.set(LocalKey.nowOpenRecent, path);
            this.props.onShowQuestionSaveRecentDialog();
        }
        else {
            store.set(LocalKey.nowOpenRecent, "");
            store.set(LocalKey.nowSavePath, path);
            store.set(LocalKey.nowPreSavePath, path);
            this.props.onOpenRecentFileClick(path);
        }
        this.onRequestClose("file");
    }
    handleClickOutputPNG() {
        console.log("handleClickOutputPNG");
        let styleSvg = document.createElement("style");
        styleSvg.setAttribute("type", "text/css");
        let styleFont = document.createTextNode(".blocklyText { font-size: 11.5px; } .blocklyWarning text { font-size: 11.5px; }");
        styleSvg.appendChild(styleFont);
        let workspaceSvg = this.props.getWorkspace.getParentSvg().cloneNode(true);
        let blockBoundingBox = this.props.getWorkspace.getBlocksBoundingBox();
        console.log("blockBoundingBox = ", blockBoundingBox);
        let isWarning = workspaceSvg.getElementsByClassName("blocklyWarning")[0].getAttribute("display");
        let offsetX = 40, offsetY = 40;
        let expandWidth = 460;
        if (!isWarning) {
            offsetY = 70;
            if (blockBoundingBox.width + 80 < expandWidth) {
                expandWidth = expandWidth - (blockBoundingBox.width + 80);
            } else {
                expandWidth = 0;
            }
        }
        console.log("expandWidth = ", expandWidth);
        workspaceSvg.setAttribute("width", blockBoundingBox.width + 80 + expandWidth);
        workspaceSvg.setAttribute("height", blockBoundingBox.height + 80);
        workspaceSvg.setAttribute("uis", this.props.getUIStyle);
        workspaceSvg.appendChild(styleSvg);
        let elements = workspaceSvg.getElementsByClassName("blocklyBubbleCanvas");
        while (elements.length > 0) {
            workspaceSvg.getElementsByClassName("blocklyBubbleCanvas")[0].parentNode.removeChild(elements[0]);
        }
        workspaceSvg.getElementsByClassName("blocklyBlockCanvas")[0].setAttribute("transform", `translate(${offsetX - blockBoundingBox.x}, ${offsetY - blockBoundingBox.y})`);
        workspaceSvg.getElementsByClassName("blocklyWarning")[0].setAttribute("transform", `translate(${offsetX}, ${0})`);
        svg.saveSvgAsPng(workspaceSvg, "blocks.png");
        this.onRequestClose("file");
    }
    handleClickSave() {
        if (this.props.pickBrainType == BRAIN_TYPE.WEB_VR) {
            this.props.getUnityMessageFunc('EventSystem', 'SaveFile');
            return;
        }
        this.props.onMenuBarClickSave();
        this.onRequestClose("file");
    }
    handleClickSaveAsCopy() {
        store.set(LocalKey.nowSavePath, "");
        this.handleClickSave();
    }
    onRequestClose(type) {
        if (type === "file") {
            this.props.onRequestCloseFile();
        }
        else if (type === "edit") {
            this.props.onRequestCloseEdit();
        }
        else if (type === "help") {
            this.props.onRequestCloseHelp();
        }
        else if (type === "setting") {
            this.props.onRequestCloseSetting();
        }
        else if (type === "more") {
            this.props.onRequestCloseMore();
        }
        else if (type === "brain") {
            if (isPad()) {
                this.setState({
                    isBrainInfoMobileOpen: false
                });
            }
            else {
                this.setState({
                    isBrainOpen: false
                });
            }
        }
    }
    handleRestoreOption(restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }

    initialDownloadStatus() {
        this.setState({
            percentage: 0,
            isExecuteDownload: true
        });
        setTimeout(() => {
            this.setState({
                isExecuteDownload: false
            });
        }, 1000);
    }

    handleClickWriteScript(downloadProjectCallback, isRun) {
        this.props.closeSlotMenu();
        let json = downloadProjectCallback();
        log.info("handleClickWriteScript brainStatus: " + this.props.brainStatus);
        if (!this.isFileCanWrite()) {
            return;
        }
        if (this.props.isEnableDownloadAndRunButton && !this.state.isExecuteDownload) {
            log.info("handleClickWriteScript json: " + JSON.stringify(json));
            this.initialDownloadStatus();
            if (!this.props.vm.isProcessLegal()) {
                this.props.onShowBreakPositionErrorDialog();
                return;
            }
            let isBlocksDisabled = this.props.isBlocksDisabled();
            log.info(`handleClickWriteScript this.props.isBlocksDisabled: ${isBlocksDisabled}`);
            if (isBlocksDisabled) {
                log.info("handleClickWriteScript Blocks has disabled true");
                return;
            }
            //BT Download Mode (Deny Write Script)
            if (this.props.brainStatus == BRAIN_STATUS_TYPE.BLUETOOTH_DOWNLOAD_PHONE_SCRIPT ||
                this.props.brainStatus == BRAIN_STATUS_TYPE.BLUETOOTH_DOWNLOAD_BLOCKLY_SCRIPT) {
                this.props.onShowBrainStatusModeErrorDialog();
                return;
            }

            //IDLE Mode
            log.info("isRun: ", isRun);
            this.props.setBrainDownloadAndRun(false);
            if (isRun) {
                this.props.setBrainDownloadType(DownloadType.downloadAndRun);
                log.info("DownloadType: ", DownloadType.downloadAndRun);
            } else {
                this.props.setBrainDownloadType(DownloadType.download);
                log.info("DownloadType: ", DownloadType.download);
            }

            //RFID or BT Mode (phone controll)
            if (this.props.brainStatus == BRAIN_STATUS_TYPE.BLUETOOTH_MODE) {
                this.props.onShowQuestionSwitchBrainModeInBTModeDialog();
                return;
            }
            if (this.props.brainStatus == BRAIN_STATUS_TYPE.RFID_MODE) {
                this.props.onShowQuestionSwitchBrainModeInRFIDModeDialog();
                return;
            }

            //Show Software update dialog when force update is true
            if (store.get("SoftwareForceUpdate", false)) {
                this.props.onShowQuestionUpdateSoftwareFirstDialog();
                return;
            }

            //Set Downloading
            if (isPad() || (this.props.brainInfo && this.props.brainInfo.brain_type &&
                this.props.brainInfo.brain_type == BRAIN_TYPE.EDU)) {
                log.info("handleClickWriteScript setBrainDownloadingStatus");
                this.props.setBrainDownloadingStatus(true);
            } else {
                setTimeout(() => {
                    log.info("handleClickWriteScript setTimeout setBrainDownloadingStatus");
                    this.props.setBrainDownloadingStatus(true)
                }, 0);
            }

            //Check Bluetooth status (EDU Only)
            if (getPlatform() == platformType.Desktop &&
                this.props.brainInfo && this.props.brainInfo.brain_type &&
                this.props.brainInfo.brain_type == BRAIN_TYPE.EDU &&
                this.props.brainInfo.deviceState != DeviceStateCmd.LOADER) {
                this.props.setCheckBluetoothStatus(true);
                this.props.checkBluetoothStatus();
            } else {
                this.props.doActionWriteScript();
            }

        } else if (this.props.isShowUserGuide) {
            this.showDownloadFail();
        }
    }

    handleClickRunScript() {
        this.props.closeSlotMenu();
        log.info("handleClickRunScript")
        if (this.props.isEnableDownloadAndRunButton) {
            log.info('handleClickRunScript');
            if (isPad()) {
                this.props.setBrainStatus(BRAIN_STATUS_TYPE.RUNNING);
                this.props.setBrainRunningStatus(true);
                if (this.props.pickBrainType == BRAIN_TYPE.EDU) {
                    postMessage(Catagory.Bluetooth, { "action": BluetoothAction.run });
                } else {
                    postMessage(Catagory.Socket, { "action": SocketAction.run, "slot": this.props.getSelectedSlot + 1 });
                }
            }
            else {
                if (this.props.showComingSoon) {
                    console.log(`handleClickRunScript`)
                } else {
                    this.props.onRunScriptClick();
                }
            }
        }
    }

    handleClickMore() {
        this.props.onClickMore();
        if (this.props.isShowUserGuide) {
            this.props.onUpdateShowUserGuide(false);
        }
    }

    showDownloadFail() {
        log.info('[User Guide] DOWNLOAD_FAIL brain is disconnect');
        this.props.onUpdateUserGuideState(USER_GUIDE_STATE.DOWNLOAD_FAIL);
    }

    handleClickHelp() {
        this.props.onClickHelp();
        if (this.props.isShowUserGuide) {
            this.props.onUpdateShowUserGuide(false);
        }
    }

    handleClickStopScript() {
        log.info('handleClickStopScript');
        if (isPad()) {
            if (this.props.pickBrainType == BRAIN_TYPE.EDU) {
                postMessage(Catagory.Bluetooth, { "action": BluetoothAction.stop });
            } else {
                postMessage(Catagory.Socket, { "action": SocketAction.stop });
            }
        }
        else {
            if (this.props.showComingSoon) {
                console.log('handleClickStopScript');
            } else {
                this.props.onStopScriptClick();
            }
        }
    }

    handleClickController() {
        if (this.props.isControllerConnected) {
            if (this.props.isControllerInfoMenuOpen) {
                this.props.closeControllerInfoMenu();
            } else {
                this.props.openControllerInfoMenu();
            }
        }
    }

    handleClickBrain() {
        if (isPad()) {
            if (this.props.pickBrainType == BRAIN_TYPE.EDU) {
                if (store.get(LocalKey.bluetoothStatus) == BluetoothStatus.poweredOff) {
                    postMessage(Catagory.Bluetooth, { "action": BluetoothAction.open });
                } else if (store.get(LocalKey.bluetoothStatus) == BluetoothStatus.unauthorized) {
                    postMessage(Catagory.Bluetooth, { "action": BluetoothAction.unauthorized });
                } else {
                    this.props.onClickBrain();
                }
            } else {
                this.props.onClickBrain();
            }
        }
        else {
            console.log('handleClickBrain ', this.props.isEnableDownloadAndRunButton)
            // if (!this.props.isEnableDownloadAndRunButton) return
            // if (this.props.isBrainConnected) {
            this.props.onClickBrain();
            // }
            if (!this.props.brainInfoMenuOpen) {
                log.info('brainInfo: ' + JSON.stringify(this.props.brainInfo));
                log.info('handleClickBrain: ' + JSON.stringify(this.props.allPortInfo));

                if (this.props.brainInfo && this.props.brainInfo.brain_type &&
                    (this.props.brainInfo.brain_type == BRAIN_TYPE.ENTRY ||
                        this.props.brainInfo.brain_type == BRAIN_TYPE.EDU_AND_ENTRY)) {
                    this.props.onClickWifiStatus();
                }
            }
        }
    }

    handleClickSlotMenu() {
        if (!this.props.isSlotMenuOpen) {
            if (getPlatform() == platformType.Desktop) {
                this.props.onClickSlotInfo();
            }
            if (isReactNative()) {
                postMessage(Catagory.Socket, { "action": SocketAction.getProjNames });
            }
            this.props.openSlotMenu();
        }
    }

    checkVersion(current, target) {
        let currentArray = current.split('.').map(Number);
        let targetArray = target.split('.').map(Number);
        let isCurrentOld = false;
        if (currentArray[0] < targetArray[0]) {
            isCurrentOld = true;
        } else {
            if (currentArray[1] < targetArray[1]) {
                isCurrentOld = true;
            } else {
                if (currentArray[2] < targetArray[2]) {
                    isCurrentOld = true;
                }
            }
        }
        return isCurrentOld
    }

    isBrainTypeSame() {
        let brainType = this.props.brainInfo.brain_type ? this.props.brainInfo.brain_type.toUpperCase() : "EDU";
        let pickBrainType = this.props.pickBrainType ? this.props.pickBrainType.toUpperCase() : "EDU";
        return brainType == pickBrainType;
    }

    handleProjectNameDialogClose() {
        this.props.setProjectTitleisSave(false);
        this.props.onRequestCloseProjectNameDialog();
    }

    handleClickCodeEditor() {
        this.props.setHighLightMode(!this.props.getHighLightMode)
        this.props.setCodeView(!this.props.getCodeViewState);
    }

    isEnVersion() {
        return store.get("locale", DEFAULT_LOCALE) == "en";
    }

    render() {
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.new"
            />
        );
        const openFileMessage = (
            <FormattedMessage
                defaultMessage="Open File"
                description="Menu bar item for opening a project"
                id="gui.menuBar.open"
            />
        );
        const recentMessage = (
            <FormattedMessage
                defaultMessage="Recent Use"
                description="Menu bar item for recent used project"
                id="gui.menuBar.recent"
            />
        );
        const outputPNGMessage = (
            <FormattedMessage
                defaultMessage="Output PNG"
                description="Menu bar item for output png"
                id="gui.menuBar.png"
            />
        );
        const openExampleMessage = (
            <FormattedMessage
                defaultMessage="Open Example"
                description="Menu bar item for open a example project"
                id="gui.menuBar.example"
            />
        );
        const locales = {
            "en": {
                "name": "English",
                "abbreviation": "EN",
                "style": styles.settingEN,
                "hover": styles.settingEN.hoverable,
                "active": styles.settingEN.active
            },
            "zh-tw": {
                "name": "繁體中文",
                "abbreviation": "繁",
                "style": styles.settingTW,
                "hover": styles.settingTW.hoverable,
                "active": styles.settingTW.active
            },
            "zh-cn": {
                "name": "简体中文",
                "abbreviation": "简",
                "style": styles.settingCN,
                "hover": styles.settingCN.hoverable,
                "active": styles.settingCN.active
            }
        };
        return (
            <Box
                onClick={() => this.props.getWorkspace.onClearWidget()}
                className={classNames(
                    this.props.className,
                    styles.menuBarAll
                )}
            >
                <div className={styles.menuBar}
                    style={this.isEnVersion() ? {
                        fontSize: "11.5px"
                    } : {}}>
                    <div className={styles.mainMenu}>
                        <div className={styles.leftSpace}>
                            <div className={styles.logoRegion} >
                                {
                                    this.state.isShowLogoIcon ?
                                        (
                                            <img className={styles.logoIcon} alt={EditUtils.getImgAltPlusPlatformLocaleString('gui.menuBar.logo.icon.alt')} />
                                        ) : null
                                }
                                {/* <img className={styles.logoText} /> */}
                            </div>
                            <div className={styles.fileGroup}>
                                <div
                                    className={classNames(styles.menuBarItem,
                                        styles.file,
                                        this.hasAnyMenuOpen() ? null : styles.hoverable,
                                        this.hasAnyMenuOpen() ? null : styles.file.hoverable,
                                        {
                                            [styles.active]: this.props.fileMenuOpen,
                                            [styles.file.active]: this.props.fileMenuOpen
                                        }
                                    )}
                                    onMouseUp={this.props.onClickFile}
                                >
                                    <div className={styles.menuItemShow}>
                                        <FormattedMessage
                                            defaultMessage="File"
                                            description="Text for file dropdown menu"
                                            id="gui.menuBar.file"
                                        />
                                    </div>
                                    <MenuBarMenu
                                        className={classNames(styles.menuBarMenu)}
                                        open={this.props.fileMenuOpen}
                                        place={this.props.isRtl ? 'left' : 'right'}
                                        onRequestClose={this.props.onRequestCloseFile}
                                    >
                                        {this.props.isBrainDownloading || this.props.isFileProcessing ? (
                                            <MenuDisableItem
                                                isRtl={this.props.isRtl}
                                            >
                                                {newProjectMessage}
                                            </MenuDisableItem>
                                        ) : (<MenuItem
                                            isRtl={this.props.isRtl}
                                            onClick={() => this.handleClickNew()}
                                        >
                                            {newProjectMessage}
                                        </MenuItem>)}

                                        {getPlatform() == platformType.Web ?
                                            (
                                                (!this.props.projectChanged) ?
                                                    (<SBFileUploader
                                                        canSave={this.props.canSave}
                                                        userOwnsProject={this.props.userOwnsProject}
                                                        onUpdateProjectTitle={this.props.onUpdateProjectTitle}
                                                    >
                                                        {(className, renderFileInput, handleLoadProject) => (
                                                            <MenuItem
                                                                className={className}
                                                                onClick={handleLoadProject}>
                                                                {openFileMessage}
                                                                {renderFileInput()}
                                                            </MenuItem>
                                                        )}
                                                    </SBFileUploader>)
                                                    : (<MenuItem
                                                        onClick={this.handleClickOpen}>
                                                        {openFileMessage}
                                                    </MenuItem>)
                                            )
                                            : (this.props.isBrainDownloading || this.props.isFileProcessing ? (
                                                <MenuDisableItem
                                                    isRtl={this.props.isRtl}
                                                >
                                                    {openFileMessage}
                                                </MenuDisableItem>
                                            ) : (<MenuItem
                                                onClick={this.handleClickOpen}>
                                                {openFileMessage}
                                            </MenuItem>))
                                        }
                                        {getPlatform() == platformType.Desktop ?
                                            (this.props.isBrainDownloading || this.props.isFileProcessing ? (
                                                <MenuDisableItem
                                                    isRtl={this.props.isRtl}
                                                >
                                                    {recentMessage}
                                                </MenuDisableItem>
                                            ) : (<MenuItem
                                                isRtl={this.props.isRtl}
                                                onClick={() => { }}
                                            >
                                                {recentMessage}
                                                <MenuSubMenu
                                                    place={'right'}
                                                >
                                                    {
                                                        this.props.recentFilePaths.length == 0 ?
                                                            (
                                                                <MenuItem
                                                                    isRtl={this.props.isRtl}
                                                                    onClick={() => { }}
                                                                >
                                                                    <FormattedMessage
                                                                        defaultMessage="No recent files"
                                                                        description="No recent files"
                                                                        id="gui.menuBar.noRecentFile"
                                                                    />
                                                                </MenuItem>
                                                            )
                                                            :
                                                            this.props.recentFilePaths.map(path => {
                                                                const name = path.replace(/^.*(\\|\/|\:)/, '');
                                                                return (
                                                                    <MenuItem
                                                                        key={`recent-${name}`}
                                                                        isRtl={this.props.isRtl}
                                                                        onClick={() => this.handleClickOpenRecent(path)}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                    }
                                                </MenuSubMenu>
                                            </MenuItem>)) : null
                                        }
                                        {this.props.isBrainDownloading || this.props.isFileProcessing ? (
                                            <MenuDisableItem
                                                isRtl={this.props.isRtl}
                                            >
                                                <FormattedMessage
                                                    defaultMessage="Save"
                                                    description="Menu bar item for saving a project to your computer"
                                                    id="gui.menuBar.saveToComputer"
                                                />
                                            </MenuDisableItem>
                                        ) : (<MenuItem
                                            onClick={this.handleClickSave}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Save"
                                                description="Menu bar item for saving a project to your computer"
                                                id="gui.menuBar.saveToComputer"
                                            />
                                        </MenuItem>)}

                                        {this.props.getUIStyle == uiType.vr ? null :
                                            (this.props.isBrainDownloading || this.props.isFileProcessing ? (
                                                <MenuDisableItem
                                                    isRtl={this.props.isRtl}
                                                >
                                                    <FormattedMessage
                                                        defaultMessage="Save As"
                                                        description="Menu bar item for saving a project to your computer"
                                                        id="gui.menuBar.saveAs"
                                                    />
                                                </MenuDisableItem>
                                            ) : (<MenuItem
                                                onClick={this.handleClickSaveAsCopy}
                                            >
                                                <FormattedMessage
                                                    defaultMessage="Save As"
                                                    description="Menu bar item for saving a project to your computer"
                                                    id="gui.menuBar.saveAs"
                                                />
                                            </MenuItem>))
                                        }

                                        {this.props.getUIStyle == uiType.vr ? null :
                                            (this.props.isBrainDownloading || this.props.isFileProcessing ? (
                                                <MenuDisableItem
                                                    isRtl={this.props.isRtl}
                                                >
                                                    {outputPNGMessage}
                                                </MenuDisableItem>
                                            ) : (<MenuItem
                                                isRtl={this.props.isRtl}
                                                onClick={this.handleClickOutputPNG}
                                            >
                                                {outputPNGMessage}
                                            </MenuItem>))
                                        }
                                    </MenuBarMenu>
                                </div>
                                <div
                                    className={classNames(styles.menuBarItem,
                                        styles.edit,
                                        this.hasAnyMenuOpen() ? null : styles.hoverable,
                                        this.hasAnyMenuOpen() ? null : styles.edit.hoverable,
                                        {
                                            [styles.active]: this.props.editMenuOpen,
                                            [styles.edit.active]: this.props.editMenuOpen
                                        }
                                    )}
                                    onMouseUp={this.handleOpenEditMenu}
                                >
                                    <div className={styles.menuItemShow}>
                                        <FormattedMessage
                                            defaultMessage="Modify"
                                            description="Menu bar item for modify action" // eslint-disable-line max-len
                                            id="gui.menuBar.modify"
                                        />
                                    </div>
                                    {this.props.editorDisplayMode == EDIT_MODE.BLOCK_MODE ?
                                        <MenuBarMenu
                                            className={classNames(styles.menuBarMenu)}
                                            open={this.props.editMenuOpen}
                                            place={this.props.isRtl ? 'left' : 'right'}
                                            onRequestClose={this.props.onRequestCloseEdit}
                                        >
                                            {this.state.edit.hasUndoStack ? (
                                                <MenuItem
                                                    isRtl={this.props.isRtl}
                                                    onClick={this.handleClickUndo}
                                                >
                                                    <FormattedMessage {...ariaMessages.undo} />
                                                </MenuItem>
                                            ) : (
                                                <MenuDisableItem
                                                    isRtl={this.props.isRtl}
                                                >
                                                    <FormattedMessage {...ariaMessages.undo} />
                                                </MenuDisableItem>
                                            )}
                                            {this.state.edit.hasRedoStack ? (
                                                <MenuItem
                                                    isRtl={this.props.isRtl}
                                                    onClick={this.handleClickRedo}
                                                >
                                                    <FormattedMessage {...ariaMessages.redo} />
                                                </MenuItem>
                                            ) : (
                                                <MenuDisableItem
                                                    isRtl={this.props.isRtl}                                            >
                                                    <FormattedMessage {...ariaMessages.redo} />
                                                </MenuDisableItem>
                                            )}
                                            {!this.props.hasMaximizedComment && this.state.edit.isCleanUp ? (
                                                <MenuItem
                                                    isRtl={this.props.isRtl}
                                                    onClick={this.handleClickArrageBlock}
                                                >
                                                    <FormattedMessage {...ariaMessages.arrageBlock} />
                                                </MenuItem>
                                            ) : (
                                                <MenuDisableItem
                                                    isRtl={this.props.isRtl}
                                                >
                                                    <FormattedMessage {...ariaMessages.arrageBlock} />
                                                </MenuDisableItem>
                                            )}
                                            {!this.props.hasMaximizedComment && this.state.edit.hasBlocks ? (
                                                <MenuItem
                                                    isRtl={this.props.isRtl}
                                                    onClick={this.handleClickClearAllBlock}
                                                >
                                                    <FormattedMessage {...ariaMessages.clear} />
                                                </MenuItem>
                                            ) : (
                                                <MenuDisableItem
                                                    isRtl={this.props.isRtl}
                                                >
                                                    <FormattedMessage {...ariaMessages.clear} />
                                                </MenuDisableItem>
                                            )}
                                        </MenuBarMenu>
                                        : <MenuBarMenu
                                            className={classNames(styles.menuBarMenu)}
                                            open={this.props.editMenuOpen}
                                            place={this.props.isRtl ? 'left' : 'right'}
                                            onRequestClose={this.props.onRequestCloseEdit}
                                        >
                                            <MenuItem
                                                isRtl={this.props.isRtl}
                                                onClick={this.handleClickCopy}
                                            >
                                                <FormattedMessage id="gui.contextmenu.copy" />
                                            </MenuItem>
                                            <MenuItem
                                                isRtl={this.props.isRtl}
                                                onClick={this.handleClickPaste}
                                            >
                                                <FormattedMessage id="gui.contextmenu.paste" />
                                            </MenuItem>
                                        </MenuBarMenu>}
                                </div>
                                <div className={classNames(styles.menuBarItem, styles.growable, styles.projectInput)}>
                                    <ProjectTitleInput
                                        className={classNames(styles.titleFieldGrowable)}
                                        onUpdateProjectTitle={this.props.onUpdateProjectTitle}
                                        onSave={this.handleClickSaveAsCopy}
                                    />
                                    <div className={styles.projectName}>
                                        {this.props.isShowProjecNameDialog ?
                                            <QuestionDialog
                                                class={styles.projectNameDialog}
                                                show={this.props.isShowProjecNameDialog}
                                                type={questionType.QUESTION_SAVE_PROJECTNAME}
                                                onClose={this.handleProjectNameDialogClose}
                                                onSaveAs={this.handleClickSaveAsCopy}
                                                onSave={this.handleClickSave}
                                            />
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.getUIStyle == uiType.vr ? (
                            <div className={styles.accountInfoGroup}>

                                {/* 
                                <a href="https://edtech.asus.com/tw/pinbo-buy/" target="_blank" rel="noreferrer noopener">
                                    <div className={styles.buy} onClick={() => { }} >
                                        <FormattedMessage
                                            defaultMessage="Course purchase"
                                            description="Menu bar item for course purchase"
                                            id="gui.vr.menuBar.buy"
                                        />
                                    </div>
                                </a>
                                 */}

                                <div
                                    className={classNames(
                                        styles.menuBarItem,
                                        styles.help,
                                        this.hasAnyMenuOpen() ? null : styles.hoverable,
                                        this.hasAnyMenuOpen() ? null : styles.help.hoverable,
                                        {
                                            [styles.active]: this.props.helpMenuOpen,
                                            [styles.help.active]: this.props.helpMenuOpen
                                        }
                                    )}
                                    onMouseUp={this.handleClickHelp}
                                >
                                    <div className={styles.menuItemShow}>
                                        <FormattedMessage
                                            defaultMessage="Help"
                                            description="Menu bar item for help"
                                            id="gui.menuBar.help"
                                        />
                                    </div>
                                    <MenuBarMenu
                                        className={classNames(styles.menuBarMenu)}
                                        open={this.props.helpMenuOpen}
                                        place={'left'}
                                        onRequestClose={this.props.onRequestCloseHelp}
                                    >
                                        <MenuItem
                                            className={this.props.getVRIsReady ? null : styles.menuBarMenuItemDisable}
                                            isRtl={this.props.isRtl}
                                            onClick={this.handleBeginnerGuide}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Beginner Guide"
                                                description="Menu bar item for beginner guide"
                                                id="gui.menuBar.beginner"
                                            />
                                        </MenuItem>
                                        <MenuItem
                                            isRtl={this.props.isRtl}
                                            onClick={this.handleClickOpenExample}
                                        >
                                            {openExampleMessage}
                                        </MenuItem>
                                        <MenuItem
                                            isRtl={this.props.isRtl}
                                            onClick={this.handleClickOpenBlockHelp}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Block Direction"
                                                description="Menu bar item for block direction"
                                                id="gui.menuBar.direction"
                                            />
                                        </MenuItem>
                                    </MenuBarMenu>
                                </div>

                                <div
                                    className={classNames(
                                        styles.menuBarItem,
                                        locales[store.get("locale", DEFAULT_LOCALE)].style,
                                        this.hasAnyMenuOpen() ? null : styles.hoverable,
                                        this.hasAnyMenuOpen() ? null : locales[store.get("locale", DEFAULT_LOCALE)].hover,
                                        {
                                            [styles.active]: this.props.settingMenuOpen,
                                            [locales[store.get("locale", DEFAULT_LOCALE)].active]: this.props.settingMenuOpen
                                        }
                                    )}
                                    onMouseUp={this.props.onClickSetting}
                                >
                                    {
                                        this.props.getUpdate || this.state.isFirmwareCanUpdate || this.props.isControllerCanUpdate ? (
                                            <div className={styles.hasUpdate} />
                                        ) : null
                                    }
                                    <div className={styles.menuItemShow}>

                                        <FormattedMessage
                                            defaultMessage="Settings"
                                            description="Menu bar item for settings"
                                            id="gui.menuBar.settings"
                                        />
                                    </div>
                                    <MenuBarMenu
                                        className={classNames(styles.menuBarMenu)}
                                        open={this.props.settingMenuOpen}
                                        place={'left'}
                                        onRequestClose={this.props.onRequestCloseSetting}
                                    >
                                        {
                                            Object.keys(locales)
                                                .map(locale => (
                                                    <MenuItem
                                                        className={this.props.getVRIsReady ? null : styles.menuBarMenuItemDisable}
                                                        key={locale}
                                                        isRtl={this.props.isRtl}
                                                        onClick={() => { this.handleClickLang(locale) }}>
                                                        {locales[locale].name}
                                                    </MenuItem>
                                                ))
                                        }

                                        <div className={classNames(styles.line)} />
                                        <MenuItem
                                            isRtl={this.props.isRtl}
                                            onClick={this.handleClickAbout}
                                        >
                                            <div className={styles.aboutNew}>
                                                <FormattedMessage
                                                    defaultMessage="About"
                                                    description="Menu bar item for open about"
                                                    id="gui.menuBar.about"
                                                />
                                                {
                                                    this.props.getUpdate || this.state.isFirmwareCanUpdate || this.props.isControllerCanUpdate ? (
                                                        <img src={newIcon} />
                                                    ) : null
                                                }
                                            </div>
                                        </MenuItem>
                                    </MenuBarMenu>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.accountInfoGroup}>

                                {/* ControllerInfo */}
                                {this.props.isControllerConnected ?
                                    <div
                                        className={classNames(
                                            styles.menuBarItem, styles.controllerIcon,
                                            (isPad() || this.props.isControllerConnected) &&
                                                this.hasAnyMenuOpen() ? styles.hoverable : null
                                        )}
                                        onMouseUp={() => this.handleClickController()}
                                    >
                                        <div className={styles.menuItemShow}>
                                            {(getPlatform() == platformType.Desktop && this.props.isRCFirmwareUpdating) ?
                                                <div className={classNames(styles.rcUpdateBackground)}>
                                                    <div className={classNames(styles.brainUpdateCursor, styles.rotateAni)}>
                                                        <img src={brainUpdateCursorIcon} className={classNames(styles.brainUpdateCursorSize)} />
                                                    </div>
                                                </div>
                                                : null}
                                            {`${this.props.getControllerInfo ? (this.props.getControllerInfo.ssn.length >= brainSNDisplayNum) ?
                                                this.props.getControllerInfo.ssn.substr(this.props.getControllerInfo.ssn.length - brainSNDisplayNum, brainSNDisplayNum) :
                                                this.props.getControllerInfo.ssn : '0000'}`}
                                        </div>
                                        {getPlatform() == platformType.Desktop ?
                                            <ControllerInfo
                                                show={this.props.isControllerInfoMenuOpen}
                                                controllerInfo={this.props.getControllerInfo}
                                                allControllerInfo={this.props.getAllControllerPortInfo}
                                                onClose={() => this.props.closeControllerInfoMenu()} />
                                            : null}
                                    </div>
                                    : null}

                                <div id="menubar-brain-info"
                                    className={classNames(
                                        styles.menuBarItem,
                                        this.hasAnyMenuOpen() ? null : styles.hoverable,
                                        {
                                            [styles.active]: this.props.brainInfoMenuOpen,
                                        }
                                    )}
                                    onMouseUp={this.handleClickBrain}
                                >
                                    <div className={styles.menuItemShow}>
                                        <img
                                            className={styles.menuItemIcon}
                                            src={this.state.brainIcon}
                                            alt={"brain icon"}
                                        />
                                        {(this.props.isBrainConnected && this.props.isBrainFWUpdating) ?
                                            <div className={classNames(styles.brainUpdateBackground)}>
                                                <div className={classNames(styles.brainUpdateCursor, styles.rotateAni)}>
                                                    <img src={brainUpdateCursorIcon} className={classNames(styles.brainUpdateCursorSize)} />
                                                </div>
                                            </div>
                                            : null}
                                        {this.props.isBrainConnected ?
                                            this.isBrainTypeSame() ? null : <img src={exclamationIcon} className={classNames(styles.hint)} />
                                            : null}
                                        {this.props.isBrainConnected && getPlatform() == platformType.Desktop && !this.props.isBrainFWUpdating ?
                                            <div className={classNames(styles.brainIndex)}>
                                                {this.props.allPortInfo.length}
                                            </div> : null}
                                        {!this.props.isBrainConnected ?
                                            <FormattedMessage
                                                defaultMessage="Disconnected"
                                                description="Menu bar item for brain disconnected status"
                                                id="gui.menuBar.brain.disconnected"
                                            /> :
                                            (this.props.isBrainDownloading) ?
                                                <ProgressBar
                                                    percentage={this.state.percentage}
                                                    brainType={this.props.pickBrainType}
                                                />
                                                : `${(this.props.brainInfo.ssn.length >= brainSNDisplayNum) ?
                                                    this.props.brainInfo.ssn.substr(this.props.brainInfo.ssn.length - brainSNDisplayNum, brainSNDisplayNum) :
                                                    this.props.brainInfo.ssn}`}
                                    </div>
                                    {
                                        (isPad()) ? (
                                            <BrainInfoMobile show={this.props.brainInfoMenuOpen} onRequestClose={this.props.onRequestCloseBrain} />
                                        ) : null
                                    }
                                    <BrainDriverHint show={!this.props.isDriverInstall} />
                                    {
                                        getPlatform() == platformType.Desktop ? (
                                            <BrainInfo
                                                show={this.props.brainInfoMenuOpen
                                                    // && this.props.isEnableDownloadAndRunButton
                                                }
                                                // firmwareVer={this.props.brainInfo.version ? this.props.brainInfo.version : this.props.brainVer.current}
                                                brainInfo={this.props.brainInfo}
                                                allPortInfo={this.props.allPortInfo}
                                                updateFirmware={this.props.updateFirmware}
                                                firmwareVer={this.props.brainVer}
                                                limitVer={this.props.brainMinVer}
                                                latestVer={this.props.brainLatestVer}
                                                close={this.props.onRequestCloseBrain}
                                                onClickSwitchWifi={this.props.onClickSwitchWifi}
                                                onClickWifiScan={this.props.onClickWifiScan}
                                                handleConnectComPortPath={(comPath) => { this.handleConnectComPortPath(comPath) }}
                                                isEnableDownloadAndRunButton={this.props.isEnableDownloadAndRunButton}
                                                connectApPort={this.props.connectApPort}
                                                disconnectPort={this.props.disconnectPort}
                                                setWifiAp={this.props.setWifiAp}
                                                startListenApBroadcast={this.props.startListenApBroadcast}
                                                stopListenApBroadcast={this.props.stopListenApBroadcast}
                                            />)
                                            : null
                                    }
                                </div>
                                <div id="menubar-slot-info"
                                    className={classNames(styles.slotInfoGroup)}>
                                    {(this.props.isEnableDownloadAndRunButton) ?
                                        <div
                                            className={classNames(
                                                styles.menuBarItem,
                                                styles.slot,
                                                this.hasAnyMenuOpen() ? null : styles.hoverable,
                                                this.hasAnyMenuOpen() ? null : styles.slot.hoverable,
                                                {
                                                    [styles.active]: this.props.isSlotMenuOpen,
                                                    [styles.slot.active]: this.props.isSlotMenuOpen,
                                                }
                                            )}
                                            onMouseUp={this.handleClickSlotMenu}
                                        >
                                            <div className={styles.menuItemShow}>
                                                <div className={styles.slotIndex}>
                                                    {this.props.getSelectedSlot + 1}
                                                </div>
                                                <div className={styles.slotName}>
                                                    <FormattedMessage
                                                        defaultMessage="Slot"
                                                        description="Menu bar item for slot"
                                                        id="gui.menuBar.slot"
                                                    />
                                                </div>
                                            </div>
                                            <SlotInfo
                                                show={this.props.isSlotMenuOpen}
                                                handleEraseSlot={this.props.handleEraseSlot}
                                                onClickSetWriteSlotNumber={this.props.onClickSetWriteSlotNumber}
                                                close={this.props.closeSlotMenu}
                                            />
                                        </div>
                                        : <div
                                            className={classNames(
                                                styles.menuBarItem,
                                                styles.slotnobrain
                                            )}>
                                            <div className={styles.menuItemShow}>
                                                <FormattedMessage
                                                    defaultMessage="Slot"
                                                    description="Menu bar item for slot"
                                                    id="gui.menuBar.slot"
                                                />
                                            </div>
                                        </div>
                                    }

                                </div>
                                <WriteScript>{(className, runScriptCallback) => (
                                    <div id="menubar-download"
                                        className={(this.props.isEnableDownloadAndRunButton && !this.props.isBrainRunning) ?
                                            classNames(
                                                styles.menuBarItem,
                                                styles.download,
                                                this.hasAnyMenuOpen() ? null : styles.hoverable,
                                                this.hasAnyMenuOpen() ? null : styles.download.hoverable,
                                            ) : classNames(
                                                styles.menuBarItem,
                                                styles.downloadnobrain
                                            )
                                        }
                                        onClick={() => {
                                            if (this.props.isEnableDownloadAndRunButton && !this.props.isBrainRunning) {
                                                this.handleClickWriteScript(runScriptCallback, false)
                                            }
                                        }}
                                    >
                                        <div className={styles.menuItemShow}>
                                            <FormattedMessage
                                                defaultMessage="download"
                                                description="Menu bar item for download to device" // eslint-disable-line max-len
                                                id="gui.menuBar.download"
                                            />
                                        </div>
                                    </div>
                                )}</WriteScript>

                                <div className={classNames(styles.divider)} />

                                {this.props.isBrainConnected && this.props.isBrainRunning &&
                                    (!this.props.isShowUserGuide || (this.props.isShowUserGuide && this.props.userGuideCurrentState == USER_GUIDE_STATE.FINISH)) ?
                                    <div
                                        className={classNames(
                                            styles.menuBarItem,
                                            styles.twice,
                                            styles.stop,
                                            this.hasAnyMenuOpen() ? null : styles.hoverable,
                                            this.hasAnyMenuOpen() ? null : styles.stop.hoverable
                                        )}
                                        onClick={this.handleClickStopScript}
                                    >
                                        <div className={styles.menuItemShow}>
                                            <FormattedMessage
                                                defaultMessage="Stop"
                                                description="Menu bar item for stop action"
                                                id="gui.menuBar.stop"
                                            />
                                        </div>
                                    </div>
                                    :
                                    <WriteScript>{(className, runScriptCallback) => (
                                        <div id="menubar-download-and-run"
                                            className={this.props.isEnableDownloadAndRunButton ?
                                                classNames(
                                                    styles.menuBarItem,
                                                    styles.twice,
                                                    styles.downloadPlay,
                                                    this.hasAnyMenuOpen() ? null : styles.hoverable,
                                                    this.hasAnyMenuOpen() ? null : styles.downloadPlay.hoverable
                                                ) : classNames(
                                                    styles.menuBarItem,
                                                    styles.twice,
                                                    styles.downloadPlaynobrain
                                                )}
                                            onClick={() => this.props.isEnableDownloadAndRunButton ? this.handleClickWriteScript(runScriptCallback, true) : this.props.isShowUserGuide ? this.showDownloadFail() : {}}
                                        >
                                            <div className={styles.menuItemShow}>
                                                <FormattedMessage
                                                    defaultMessage="Download and Run"
                                                    description="Menu bar item for download and run action"
                                                    id="gui.menuBar.downloadRun"
                                                />
                                            </div>
                                        </div>
                                    )}</WriteScript>
                                }

                                {this.props.pickBrainType == BRAIN_TYPE.ENTRY || this.props.pickBrainType == BRAIN_TYPE.EDU_AND_ENTRY ?
                                    <div
                                        className={classNames(
                                            styles.menuBarItem,
                                            styles.streamingIcon,
                                            this.hasAnyMenuOpen() ? null : styles.hoverable,
                                        )}
                                        onClick={() => {
                                            this.props.showStreamingDialog()
                                            this.props.closeAllMenu();
                                        }}
                                    >
                                        <div className={styles.menuItemShow}>
                                            <FormattedMessage id="gui.menuBar.streaming" />
                                        </div>
                                    </div>
                                    : null}

                                <div className={classNames(styles.divider)} />

                                {this.props.pickBrainType == BRAIN_TYPE.EDU ?
                                    <div
                                        className={classNames(
                                            styles.menuBarItem,
                                            styles.boardgameIcon,
                                            this.hasAnyMenuOpen() ? null : styles.hoverable,
                                        )}
                                        onClick={() => {
                                            this.props.showBoardGameDialog();
                                            this.props.closeAllMenu();
                                        }}
                                    >
                                        <div className={styles.menuItemShow}>
                                            <FormattedMessage id="gui.menuBar.boardgame" />
                                        </div>
                                    </div>
                                    : null}

                                {
                                    this.state.isShowMore ?
                                        (
                                            <div
                                                className={classNames(
                                                    styles.menuBarItem,
                                                    styles.more,
                                                    this.hasAnyMenuOpen() ? null : styles.hoverable,
                                                    this.hasAnyMenuOpen() ? null : styles.more.hoverable,
                                                    {
                                                        [styles.active]: this.props.moreMenuOpen,
                                                        [styles.more.active]: this.props.moreMenuOpen,
                                                    }
                                                )}
                                                onMouseUp={this.handleClickMore}
                                            >
                                                {
                                                    this.props.getUpdate || this.state.isFirmwareCanUpdate || this.props.isControllerCanUpdate ? (
                                                        <div className={styles.hasUpdate} />
                                                    ) : null
                                                }
                                                <div className={styles.menuItemShow}>

                                                    <FormattedMessage
                                                        defaultMessage="More"
                                                        description="Menu bar item for more"
                                                        id="gui.menuBar.more"
                                                    />
                                                </div>
                                                <MenuBarMenu
                                                    className={classNames(styles.menuBarMenu)}
                                                    open={this.props.moreMenuOpen}
                                                    place={'left'}
                                                    onRequestClose={this.props.onRequestCloseMore}
                                                >

                                                    {/* Course display CN Sku Only */}
                                                    {(this.props.getUIStyle == uiType.cn) ? (
                                                        <MenuItem
                                                            isRtl={this.props.isRtl}
                                                            onClick={this.handleClickCourse}
                                                        >
                                                            <div className={styles.moreItemShow}>
                                                                <img
                                                                    className={classNames(styles.menuItemIcon, styles.moreSchool)}
                                                                />
                                                                <FormattedMessage
                                                                    defaultMessage="Course"
                                                                    description="Menu bar item for course"
                                                                    id="gui.menuBar.course"
                                                                />
                                                            </div>
                                                        </MenuItem>
                                                    ) : null}

                                                    <MenuItem
                                                        isRtl={this.props.isRtl}
                                                    >
                                                        <div className={styles.moreItemShow}>
                                                            <img
                                                                className={classNames(styles.menuItemIcon, styles.moreHelp)}
                                                            />
                                                            <FormattedMessage
                                                                defaultMessage="Help"
                                                                description="Menu bar item for help"
                                                                id="gui.menuBar.help"
                                                            />
                                                        </div>
                                                        <MenuSubMenu
                                                            place={'left'}
                                                        >
                                                            <MenuItem
                                                                isRtl={this.props.isRtl}
                                                                onClick={this.handleBeginnerGuide}
                                                            >
                                                                <FormattedMessage
                                                                    defaultMessage="Beginner Guide"
                                                                    description="Menu bar item for beginner guide"
                                                                    id="gui.menuBar.beginner"
                                                                />
                                                            </MenuItem>
                                                            <MenuItem
                                                                isRtl={this.props.isRtl}
                                                                onClick={this.handleClickOpenExample}
                                                            >
                                                                {openExampleMessage}
                                                            </MenuItem>
                                                            <MenuItem
                                                                isRtl={this.props.isRtl}
                                                                onClick={this.handleClickOpenBlockHelp}
                                                            >
                                                                <FormattedMessage
                                                                    defaultMessage="Block Direction"
                                                                    description="Menu bar item for block direction"
                                                                    id="gui.menuBar.direction"
                                                                />
                                                            </MenuItem>
                                                            <MenuItem
                                                                isRtl={this.props.isRtl}
                                                                onClick={this.hendleClickOpenDeviceHelp}
                                                            >
                                                                <FormattedMessage
                                                                    defaultMessage="Device Direction"
                                                                    description="Menu bar item for device direction"
                                                                    id="gui.menuBar.deviceDirection"
                                                                />
                                                            </MenuItem>
                                                            {(this.props.getUIStyle != uiType.vr) ? (
                                                                <MenuItem
                                                                    isRtl={this.props.isRtl}
                                                                    onClick={this.hendleClickOpenFAQ}
                                                                >
                                                                    <FormattedMessage
                                                                        defaultMessage="PINBO Lab FAQ"
                                                                        description="Menu bar item for PINBO FAQ"
                                                                        id="gui.menuBar.frequentlyQuestions"
                                                                    />
                                                                </MenuItem>
                                                            ) : null}
                                                        </MenuSubMenu>
                                                    </MenuItem>
                                                    <MenuItem
                                                        isRtl={this.props.isRtl}
                                                    >
                                                        <div className={styles.moreItemShow}>
                                                            <div className={styles.moreItemSetting}>
                                                                <img
                                                                    className={classNames(styles.menuItemIcon, styles.settings, styles.moreSettings)}
                                                                />
                                                                <div className={
                                                                    classNames(
                                                                        styles.moreItemSettingText,
                                                                        store.get("locale", DEFAULT_LOCALE) == "en" ? styles.en : styles.zh
                                                                    )}
                                                                >
                                                                    {locales[store.get("locale", DEFAULT_LOCALE)].abbreviation}
                                                                </div>
                                                            </div>
                                                            {
                                                                this.props.getUpdate || this.state.isFirmwareCanUpdate || this.props.isControllerCanUpdate ? (
                                                                    <div className={classNames(styles.hasUpdate, styles.menu)} />
                                                                ) : null
                                                            }
                                                            <FormattedMessage
                                                                defaultMessage="Settings"
                                                                description="Menu bar item for settings"
                                                                id="gui.menuBar.settings"
                                                            />
                                                        </div>
                                                        <MenuSubMenu
                                                            place={'left'}
                                                        >
                                                            {
                                                                Object.keys(locales)
                                                                    .map(locale => (
                                                                        <MenuItem
                                                                            key={locale}
                                                                            isRtl={this.props.isRtl}
                                                                            onClick={() => { this.handleClickLang(locale) }}>
                                                                            {locales[locale].name}
                                                                        </MenuItem>
                                                                    ))
                                                            }
                                                            <div className={classNames(styles.line)} />
                                                            <MenuItem
                                                                isRtl={this.props.isRtl}
                                                                onClick={this.handleClickAbout}
                                                            >
                                                                <div className={styles.aboutNew}>
                                                                    <FormattedMessage
                                                                        defaultMessage="About"
                                                                        description="Menu bar item for open about"
                                                                        id="gui.menuBar.about"
                                                                    />
                                                                    {
                                                                        this.props.getUpdate || this.state.isFirmwareCanUpdate || this.props.isControllerCanUpdate ? (
                                                                            <img src={newIcon} />
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            </MenuItem>
                                                        </MenuSubMenu>
                                                    </MenuItem>
                                                </MenuBarMenu>
                                            </div>
                                        ) :
                                        (
                                            <div className={styles.accountInfoGroup}>

                                                {/* Course display CN Sku Only */}
                                                {(this.props.getUIStyle == uiType.cn) ? (
                                                    <div
                                                        className={classNames(
                                                            styles.menuBarItem,
                                                            styles.school,
                                                            this.hasAnyMenuOpen() ? null : styles.hoverable,
                                                            this.hasAnyMenuOpen() ? null : styles.school.hoverable
                                                        )}
                                                        onClick={this.handleClickCourse}
                                                    >
                                                        <div className={styles.menuItemShow}>
                                                            <FormattedMessage
                                                                defaultMessage="Course"
                                                                description="Menu bar item for course"
                                                                id="gui.menuBar.course"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}

                                                <div
                                                    className={classNames(
                                                        styles.menuBarItem,
                                                        styles.help,
                                                        this.hasAnyMenuOpen() ? null : styles.hoverable,
                                                        this.hasAnyMenuOpen() ? null : styles.help.hoverable,
                                                        {
                                                            [styles.active]: this.props.helpMenuOpen,
                                                            [styles.help.active]: this.props.helpMenuOpen
                                                        }
                                                    )}
                                                    onMouseUp={this.handleClickHelp}
                                                >
                                                    <div className={styles.menuItemShow}>
                                                        <FormattedMessage
                                                            defaultMessage="Help"
                                                            description="Menu bar item for help"
                                                            id="gui.menuBar.help"
                                                        />
                                                    </div>
                                                    <MenuBarMenu
                                                        className={classNames(styles.menuBarMenu)}
                                                        open={this.props.helpMenuOpen}
                                                        place={'left'}
                                                        onRequestClose={this.props.onRequestCloseHelp}
                                                    >
                                                        <MenuItem
                                                            isRtl={this.props.isRtl}
                                                            onClick={this.handleBeginnerGuide}
                                                        >
                                                            <FormattedMessage
                                                                defaultMessage="Beginner Guide"
                                                                description="Menu bar item for beginner guide"
                                                                id="gui.menuBar.beginner"
                                                            />
                                                        </MenuItem>
                                                        <MenuItem
                                                            isRtl={this.props.isRtl}
                                                            onClick={this.handleClickOpenExample}
                                                        >
                                                            {openExampleMessage}
                                                        </MenuItem>
                                                        <MenuItem
                                                            isRtl={this.props.isRtl}
                                                            onClick={this.handleClickOpenBlockHelp}
                                                        >
                                                            <FormattedMessage
                                                                defaultMessage="Block Direction"
                                                                description="Menu bar item for block direction"
                                                                id="gui.menuBar.direction"
                                                            />
                                                        </MenuItem>
                                                        <MenuItem
                                                            isRtl={this.props.isRtl}
                                                            onClick={this.hendleClickOpenDeviceHelp}
                                                        >
                                                            <FormattedMessage
                                                                defaultMessage="Device Direction"
                                                                description="Menu bar item for device direction"
                                                                id="gui.menuBar.deviceDirection"
                                                            />
                                                        </MenuItem>
                                                        {(this.props.getUIStyle != uiType.vr) ? (
                                                            <MenuItem
                                                                isRtl={this.props.isRtl}
                                                                onClick={this.hendleClickOpenFAQ}
                                                            >
                                                                <FormattedMessage
                                                                    defaultMessage="PINBO Lab FAQ"
                                                                    description="Menu bar item for PINBO FAQ"
                                                                    id="gui.menuBar.frequentlyQuestions"
                                                                />
                                                            </MenuItem>
                                                        ) : null}
                                                    </MenuBarMenu>
                                                </div>

                                                <div
                                                    className={classNames(
                                                        styles.menuBarItem,
                                                        locales[store.get("locale", DEFAULT_LOCALE)].style,
                                                        this.hasAnyMenuOpen() ? null : styles.hoverable,
                                                        this.hasAnyMenuOpen() ? null : locales[store.get("locale", DEFAULT_LOCALE)].hover,
                                                        {
                                                            [styles.active]: this.props.settingMenuOpen,
                                                            [locales[store.get("locale", DEFAULT_LOCALE)].active]: this.props.settingMenuOpen
                                                        }
                                                    )}
                                                    onMouseUp={this.props.onClickSetting}
                                                >
                                                    {
                                                        this.props.getUpdate || this.state.isFirmwareCanUpdate || this.props.isControllerCanUpdate ? (
                                                            <div className={styles.hasUpdate} />
                                                        ) : null
                                                    }
                                                    <div className={styles.menuItemShow}>
                                                        <FormattedMessage
                                                            defaultMessage="Settings"
                                                            description="Menu bar item for settings"
                                                            id="gui.menuBar.settings"
                                                        />
                                                    </div>
                                                    <MenuBarMenu
                                                        className={classNames(styles.menuBarMenu)}
                                                        open={this.props.settingMenuOpen}
                                                        place={'left'}
                                                        onRequestClose={this.props.onRequestCloseSetting}
                                                    >
                                                        {
                                                            Object.keys(locales)
                                                                .map(locale => (
                                                                    <MenuItem
                                                                        key={locale}
                                                                        isRtl={this.props.isRtl}
                                                                        onClick={() => { this.handleClickLang(locale) }}>
                                                                        {locales[locale].name}
                                                                    </MenuItem>
                                                                ))
                                                        }

                                                        <div className={classNames(styles.line)} />
                                                        <MenuItem
                                                            isRtl={this.props.isRtl}
                                                            onClick={this.handleClickAbout}
                                                        >
                                                            <div className={styles.aboutNew}>
                                                                <FormattedMessage
                                                                    defaultMessage="About"
                                                                    description="Menu bar item for open about"
                                                                    id="gui.menuBar.about"
                                                                />
                                                                {
                                                                    this.props.getUpdate || this.state.isFirmwareCanUpdate || this.props.isControllerCanUpdate ? (
                                                                        <img src={newIcon} />
                                                                    ) : null
                                                                }
                                                            </div>
                                                        </MenuItem>
                                                    </MenuBarMenu>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                        )
                    }

                </div>
                {
                    this.props.pickBrainType == BRAIN_TYPE.ENTRY || this.props.pickBrainType == BRAIN_TYPE.EDU_AND_ENTRY ? (
                        <div className={styles.entryTagBackground}>
                            <div className={styles.entryTag}>
                                <div
                                    className={this.props.editorDisplayMode == EDIT_MODE.BLOCK_MODE ? styles.selectedTag : styles.unSelectedTag}
                                    onMouseUp={() => this.handleClickDirectNew({
                                        enable: true,
                                        brainType: this.props.pickBrainType,
                                        editMode: EDIT_MODE.BLOCK_MODE
                                    })}
                                >
                                    <div className={styles.tagContent}>
                                        <FormattedMessage
                                            defaultMessage="Open block editor"
                                            description="Menu bar item for block editor"
                                            id="gui.menuBar.blockEditor"
                                        />
                                        <img className={styles.tagContentIcon} src={this.props.editorDisplayMode == EDIT_MODE.BLOCK_MODE ? (this.props.getUIStyle == uiType.ww ? blockTagWW : blockTag) : blockTagDisable} alt={EditUtils.getLocaleString('gui.menuBar.blockEditor')} />
                                    </div>
                                </div>
                                <div
                                    className={this.props.editorDisplayMode == EDIT_MODE.PYTHON_MODE ? styles.selectedTag : styles.unSelectedTag}
                                    onMouseUp={() => this.handleClickDirectNew({
                                        enable: true,
                                        brainType: this.props.pickBrainType,
                                        editMode: EDIT_MODE.PYTHON_MODE
                                    })}
                                >
                                    <div className={styles.tagContent}>
                                        <FormattedMessage
                                            defaultMessage="Open code editor"
                                            description="Menu bar item for code editor"
                                            id="gui.menuBar.codeEditor"
                                        />
                                        <img className={styles.tagContentIcon} src={this.props.getUIStyle == uiType.ww ? pythonTagWW : pythonTag} alt={EditUtils.getLocaleString('gui.menuBar.codeEditor')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </Box>
        );
    }
}

MenuBar.propTypes = {
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    stageTypeMode: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_TYPE)),
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canSave: PropTypes.bool,
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    editMenuOpen: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    loginMenuOpen: PropTypes.bool,
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickSave: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenStage: PropTypes.func,
    onCloseStage: PropTypes.func,
    onProjectTelemetryEvent: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    projectTitle: PropTypes.string,
    sessionExists: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    showComingSoon: PropTypes.bool,
    userOwnsProject: PropTypes.bool,
    username: PropTypes.string,
    vm: PropTypes.instanceOf(VM).isRequired,
    onOpenFileClick: PropTypes.func,
    onClickHelp: PropTypes.func,
    onRequestCloseHelp: PropTypes.func,
    helpMenuOpen: PropTypes.bool,
    onClickSetting: PropTypes.func,
    onRequestCloseSetting: PropTypes.func,
    settingMenuOpen: PropTypes.bool,
    onClickMore: PropTypes.func,
    onRequestCloseMore: PropTypes.func,
    moreMenuOpen: PropTypes.bool,
    onChangeLanguage: PropTypes.func,
    onActivateDeck: PropTypes.func,
    recentFilePaths: PropTypes.array,
    onOpenRecentFileClick: PropTypes.func,
    onOutputPDFClick: PropTypes.func,
    onWriteScriptClick: PropTypes.func,
    onRunScriptClick: PropTypes.func,
    onStopScriptClick: PropTypes.func,
    isBrainRunning: PropTypes.bool,
    isBrainConnect: PropTypes.bool,
    isBrainConnected: PropTypes.bool,
    isBrainUpdate: PropTypes.bool,
    hasMaximizedComment: PropTypes.bool,
    onClickBrowser: PropTypes.func,
    onClickSlotInfo: PropTypes.func,
    getRefinedScript: PropTypes.func,
    projectChanged: PropTypes.bool,
    onShowQuestionSaveNewDialog: PropTypes.func,
    onShowQuestionOpenNewDialog: PropTypes.func,
    onShowQuestionSaveOpenDialog: PropTypes.func,
    onShowQuestionSaveNewWebDialog: PropTypes.func,
    onShowQuestionSaveOpenWebDialog: PropTypes.func,
    onShowQuestionSaveRecentDialog: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func,
    onShowNewProjectBrainTypePicker: PropTypes.func,
    onRequestOpenHintBlockHelp: PropTypes.func,
    onRequestOpenBlockHelp: PropTypes.func,
    setBlockHelpBlockTypeToState: PropTypes.func,
    connectComPortPath: PropTypes.func,
    fetchFirmwareConfig: PropTypes.func,
    updateFirmware: PropTypes.func,
    isEnableDownloadAndRunButton: PropTypes.bool,
    onMenuBarClickSave: PropTypes.func,
    doActionWriteScript: PropTypes.func,
    isShowUserGuide: PropTypes.bool,
    onRequestCloseProjectNameDialog: PropTypes.func,
    isShowProjecNameDialog: PropTypes.bool,
    setProjectTitleisSave: PropTypes.func,
    handleEraseSlot: PropTypes.func,
    onClickSetWriteSlotNumber: PropTypes.func,
    setProjectPreTitle: PropTypes.func,
    setProjectNew: PropTypes.func,
    onClickBrain: PropTypes.func,
    onRequestCloseBrain: PropTypes.func,
    brainInfoMenuOpen: PropTypes.bool,
    isStarted: PropTypes.bool,
    setBrainDownloadAndRun: PropTypes.func,
    getBrainDownloadAndRun: PropTypes.bool,
    setBrainDownloadType: PropTypes.func,
    checkBluetoothStatus: PropTypes.func,
    getWorkspace: PropTypes.object,
    onCloseBrowserVideoWindow: PropTypes.func,
    editorDisplayMode: PropTypes.string,
    getEditor: PropTypes.object,
    getUndoState: PropTypes.bool,
    getRedoState: PropTypes.bool,
    getUpdate: PropTypes.bool,
    setHighLightMode: PropTypes.func,
    getHighLightMode: PropTypes.bool,
    shouldShowUserGuide: PropTypes.bool,
    setDirectNew: PropTypes.func,
    resetDirectNew: PropTypes.func,
    onClickSwitchWifi: PropTypes.func,
    onClickWifiScan: PropTypes.func,
    onClickWifiStatus: PropTypes.func,
    onShowDirectNewDialog: PropTypes.func,
    onShowDirectNewSaveDialog: PropTypes.func,
    getUIStyle: PropTypes.string,
    onShowQuestionExtensionErrDialog: PropTypes.func,
    isRCFirmwareUpdating: PropTypes.bool,
    getUnityMessageFunc: PropTypes.func,
    setVRSaveFile: PropTypes.func,
    setVRLangChange: PropTypes.func,
    getVRSaveFile: PropTypes.bool,
    getVRLangChange: PropTypes.bool,
    getVRFullScreen: PropTypes.bool,
    setVRIsReady: PropTypes.func,
    getVRIsReady: PropTypes.bool,
    setBrainRunningStatus: PropTypes.func,
    setBrainStatus: PropTypes.func,
    isBlocksDisabled: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        stageSizeMode: state.scratchGui.stageSize.stageSize,
        stageTypeMode: state.scratchGui.stageSize.stageType,
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        helpMenuOpen: helpMenuOpen(state),
        settingMenuOpen: settingMenuOpen(state),
        moreMenuOpen: moreMenuOpen(state),
        isRtl: state.locales.isRtl,
        languageMenuOpen: languageMenuOpen(state),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        projectTitle: projectTitle(state),
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        username: user ? user.username : null,
        userOwnsProject: ownProps.authorUsername && user &&
            (ownProps.authorUsername === user.username),
        vm: state.scratchGui.vm,
        recentFilePaths: state.scratchGui.recentFilePaths.paths,
        isBrainRunning: getBrainRunningStatus(state),
        isBrainConnect: getBrainConnectStatus(state),
        isBrainUpdate: getBrainUpdateStatus(state),
        isBrainFWUpdating: getBrainFWUpdatingStatus(state),
        isCheckBluetooth: getCheckBluetoothStatus(state),
        brainStatus: getBrainStatus(state),
        brainInfo: getBrainInfo(state),
        allPortInfo: getAllPortInfo(state),
        brainVer: getBrainVer(state, 'current'),
        firmwareConfig: getFirmwareConfig(state),
        projectChanged: state.scratchGui.projectChanged,
        getProjectJson: state.scratchGui.vm.getProjectJson.bind(state.scratchGui.vm),
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
        projectFilename: projectTitle(state),
        hasMaximizedComment: state.scratchGui.toolbox.hasMaximizedComment,
        updatePickedBrainType: updatePickedBrainType(state),
        shouldShowUserGuide: shouldShowUserGuide(state),
        userGuideCurrentState: getUserGuideCurrentState(state),
        isShowUserGuide: isShowUserGuide(state),
        isSlotMenuOpen: isSlotMenuOpen(state),
        getSelectedSlot: getSelectedSlot(state),
        taskProgress: getTaskProgress(state),
        taskName: getInProgressTask(state),
        taskArray: getTaskArray(state),
        isBrainDownloading: getBrainDownloadingStatus(state),
        isShowProjecNameDialog: quesitonDialogShow(state, questionType.QUESTION_SAVE_PROJECTNAME),
        isDriverInstall: getDriverInstallStatus(state),
        brainInfoMenuOpen: brainInfoMenuOpen(state),
        isStarted: state.scratchGui.vmStatus.running,
        getBrainDownloadAndRun: getBrainDownloadAndRun(state),
        pickBrainType: getPickedBrainType(state),
        getWorkspace: getWorkspace(state),
        editorDisplayMode: getEditorDisplayMode(state),
        getEditor: getEditor(state),
        getUndoState: getUndoState(state),
        getRedoState: getRedoState(state),
        getCodeViewState: getCodeViewState(state),
        getUpdate: getUpdate(state),
        getHighLightMode: getHighLightMode(state),
        getUIStyle: getUIStyle(state),
        isEnableWebVR: isEnableWebVR(state),
        getControllerInfo: getControllerInfo(state),
        getAllControllerPortInfo: getAllControllerPortInfo(state),
        isControllerInfoMenuOpen: isControllerInfoMenuOpen(state),
        isControllerConnected: getControllerInfo(state) != null,
        isRCFirmwareUpdating: isRCFirmwareUpdating(state),
        getUnityMessageFunc: getUnityMessageFunc(state),
        getVRSaveFile: getVRSaveFile(state),
        getVRLangChange: getVRLangChange(state),
        getVRFullScreen: getFullScreen(state, viewPage.vr),
        getVRIsReady: getVRIsReady(state),
        isFileProcessing: isFileProcessing(state),
        getForceOpenBrainInfoStatus: getForceOpenBrainInfoStatus(state),
        getSlotList: getSlotList(state),
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenHelp: () => dispatch(setStageSize(STAGE_SIZE_MODES.large, STAGE_DISPLAY_TYPE.help)),
    onOpenDeviceManage: () => dispatch(setStageSize(STAGE_SIZE_MODES.large, STAGE_DISPLAY_TYPE.deviceManager)),
    onCloseStage: () => dispatch(setStageSize(STAGE_SIZE_MODES.small)),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickHelp: () => dispatch(openHelpMenu()),
    onRequestCloseHelp: () => dispatch(closeHelpMenu()),
    onClickSetting: () => dispatch(openSettingMenu()),
    onRequestCloseSetting: () => dispatch(closeSettingMenu()),
    onClickMore: () => dispatch(openMoreMenu()),
    onRequestCloseMore: () => dispatch(closeMoreMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickAbout: () => dispatch(showAboutDialog()),
    onOpenExampleLibrary: () => dispatch(openExampleLibrary()),
    onChangeLanguage: (locale, uiStyle, localesState) => dispatch(selectLocale(locale, uiStyle, localesState)),
    onActivateDeck: id => dispatch(activateDeck(id)),
    setBrainVer: (verType, ver) => dispatch(setBrainVer(verType, ver)),
    onShowQuestionOpenNewDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_OPEN_NEW)),
    onShowQuestionSaveNewDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_SAVE_NEW)),
    onShowQuestionSaveOpenDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_SAVE_OPEN)),
    onShowQuestionSaveNewWebDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_SAVE_NEW_WEB)),
    onShowQuestionSaveOpenWebDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_SAVE_OPEN_WEB)),
    onShowQuestionSaveRecentDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_SAVE_RECENT)),
    onShowNewProjectBrainTypePicker: () => dispatch(showBrainTypePicker(pickerType.PICKER_NEW_PROJECT)),
    onShowBrainStatusModeErrorDialog: (msg) => dispatch(showErrorDialog(errorType.BRAIN_STATUS_MODE_ERROR, msg)),
    onShowBreakPositionErrorDialog: (msg) => dispatch(showErrorDialog(errorType.BREAK_POSITION_ILEGAL, msg)),
    onShowFileProcessingErrorDialog: () => dispatch(showErrorDialog(errorType.FILE_PROCESSING)),
    showErrorDialog: (errorType) => dispatch(showErrorDialog(errorType)),
    onShowQuestionSwitchBrainModeInRFIDModeDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_SWITCH_BRAIN_MODE_IN_RFID_MODE)),
    onShowQuestionSwitchBrainModeInBTModeDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_SWITCH_BRAIN_MODE_IN_BT_MODE)),
    onShowQuestionUpdateSoftwareFirstDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_UPDATE_SOFTWARE_FIRST)),
    onShowQuestionVRCloseFullScreentDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_VR_CLOSE_FULLSCREEN)),
    openHelpDialog: () => dispatch(showDeviceHelpDialog(getPlatform())),
    onRequestOpenHintBlockHelp: () => dispatch(showHintBlockHelpDialog()),
    onRequestOpenBlockHelp: () => dispatch(showBlockHelpDialog(), getPlatform()),
    setBlockHelpBlockTypeToState: (blockType) => dispatch(setBlockHelpBlockType(blockType)),
    onUpdateShouldShowUserGuide: show => dispatch(updateShouldShowUserGuide(show)),
    onUpdateShowUserGuide: show => dispatch(updateShowUserGuide(show)),
    onUpdateUserGuideState: state => dispatch(updateUserGuideState(state)),
    openSlotMenu: () => dispatch(openSlotMenu()),
    closeSlotMenu: () => dispatch(closeSlotMenu()),
    cleanTask: () => dispatch(cleanTask()),
    onRequestCloseProjectNameDialog: () => dispatch(hideQuestionDialog(questionType.QUESTION_SAVE_PROJECTNAME)),
    setProjectTitleisSave: isSave => dispatch(setProjectTitleisSave(isSave)),
    hideStartAppBrainTypePicker: () => dispatch(hideStartAppBrainTypePicker()),
    setProjectPreTitle: title => dispatch(setProjectPreTitle(title)),
    setProjectNew: isNew => dispatch(setProjectNew(isNew)),
    onPickedBrainType: () => dispatch(updatePickedBrainType()),
    onClickBrain: () => dispatch(openBrainInfoMenu()),
    onRequestCloseBrain: () => dispatch(closeBrainInfoMenu()),
    setBrainDownloadAndRun: status => dispatch(setBrainDownloadAndRun(status)),
    setBrainDownloadType: type => dispatch(setBrainDownloadType(type)),
    setCheckBluetoothStatus: status => dispatch(setCheckBluetoothStatus(status)),
    setBrainDownloadingStatus: status => dispatch(setBrainDownloadingStatus(status)),
    setCodeView: show => dispatch(setCodeView(show)),
    setDirectNew: (enable, brainType, editMode) => dispatch(setDirectNew(enable, brainType, editMode)),
    resetDirectNew: () => dispatch(resetDirectNew()),
    setHighLightMode: show => dispatch(setHighLightMode(show)),
    onShowDirectNewDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_DIRECT_NEW)),
    onShowDirectNewSaveDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_DIRECT_NEW_SAVE)),
    onReloadProject: () => dispatch(onReloadProject()),
    onShowQuestionExtensionErrDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_FILE_EXTENSION_NOT_MATCH)),
    openControllerInfoMenu: () => dispatch(openControllerInfoMenu()),
    closeControllerInfoMenu: () => dispatch(closeControllerInfoMenu()),
    setVRSaveFile: status => dispatch(setVRSaveFile(status)),
    setVRLangChange: status => dispatch(setVRLangChange(status)),
    setVRIsReady: status => dispatch(setVRIsReady(status)),
    showBoardGameDialog: () => dispatch(showBoardGameDialog()),
    showStreamingDialog: () => {
        dispatch(hideVisionSettingDialog())
        dispatch(showStreamingDialog())
    },
    closeAllMenu: () => dispatch(closeAllMenu()),
    forceOpenBrainInfo: (open) => dispatch(forceOpenBrainInfo(open)),
    setBrainRunningStatus: status => dispatch(setBrainRunningStatus(status)),
    setBrainStatus: status => dispatch(setBrainStatus(status)),
    setSlotList: list => dispatch(setSlotList(list))
});

MenuBar.defaultProps = {
    isRCFirmwareUpdating: false
}

const ProgressBar = (props) => {
    return (
        <div className={styles.progressBar}>
            <div className={props.brainType == BRAIN_TYPE.EDU ? styles.filler : (props.percentage > 95 ? styles.fillerEntryFast : styles.fillerEntry)} style={{ width: `${props.percentage}%` }} />
        </div >
    )
}

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MenuBar);
