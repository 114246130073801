import messages from './tag-messages.js';
export default [
    // {tag: 'templates', intlLabel: messages.templates},
    {tag: 'motion', intlLabel: messages.motion},
    {tag: 'drivetrain', intlLabel: messages.drivetrain},
    {tag: 'looks', intlLabel: messages.looks},
    {tag: 'sound', intlLabel: messages.sound},
    {tag: 'events', intlLabel: messages.events},
    {tag: 'control', intlLabel: messages.control},
    {tag: 'sensing', intlLabel: messages.sensing},
    {tag: 'operators', intlLabel: messages.operators},
    {tag: 'variables', intlLabel: messages.variables},
    {tag: 'comment', intlLabel: messages.comment}
];
