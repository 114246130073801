const SELECT_DEVICE_TO_DELETE = 'scratch-gui/device/SELECT_DEVICE_TO_DELETE'
const CLEAN_SELECTED_DEVICE = 'scratch-gui/device/CLEAN_SELECTED_DEVICE'

const CLOSE_DELETE_MODE = 'scratch-gui/device/CLOSE_DELETE_MODE'
const OPEN_DELETE_MODE = 'scratch-gui/device/OPEN_DELETE_MODE'

const SELECTED_DEVICE_ID_ARRAY = 'selectDeviceIdArray';
const IS_DEVICE_SELECTED = 'isDeviceSelected';
const IS_DELETE_DEVICE_MODE = 'isDeleteDeviceMode';

const initialState = {
    [SELECTED_DEVICE_ID_ARRAY]: [],
    [IS_DEVICE_SELECTED]: false,
    [IS_DELETE_DEVICE_MODE]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SELECT_DEVICE_TO_DELETE:
            return Object.assign({}, state, {
                [SELECTED_DEVICE_ID_ARRAY]: action.portArray,
                [IS_DEVICE_SELECTED]: true
            });
        case CLEAN_SELECTED_DEVICE:
        case OPEN_DELETE_MODE:
            return Object.assign({}, state, {
                [SELECTED_DEVICE_ID_ARRAY]: [],
                [IS_DEVICE_SELECTED]: false,
                [IS_DELETE_DEVICE_MODE]: true,
            });
        case CLOSE_DELETE_MODE:
            return Object.assign({}, state, {
                [IS_DELETE_DEVICE_MODE]: false,
            });
        default:
            return state;
    }
};

const selectDeviceToDelete = (portArray) => ({
    portArray: portArray,
    type: (portArray.length > 0) ? SELECT_DEVICE_TO_DELETE : CLEAN_SELECTED_DEVICE
})

const openDeleteMode = () => ({
    type: OPEN_DELETE_MODE
})

const cleanSeletedDevice = () => ({
    type: CLEAN_SELECTED_DEVICE
})

const closeDeleteMode = () => ({
    type: CLOSE_DELETE_MODE
})

const isDeviceSelected = state => state.scratchGui.deleteDevice[IS_DEVICE_SELECTED];
const isDeleteDeviceMode = state => state.scratchGui.deleteDevice[IS_DELETE_DEVICE_MODE];
const getSelectedDeviceIdArray = state => state.scratchGui.deleteDevice[SELECTED_DEVICE_ID_ARRAY];

export {
    reducer as default,
    initialState as deleteDeviceInitialState,

    isDeviceSelected,
    selectDeviceToDelete,
    cleanSeletedDevice,
    getSelectedDeviceIdArray,

    isDeleteDeviceMode,
    openDeleteMode,
    closeDeleteMode
};
