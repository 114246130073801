import commentTagIcon from './svg/comment_tag.svg';
import controlTagIcon from './svg/control_tag.svg';
import drivetrainTagIcon from './svg/drivetrain_tag.svg';
import eventsTagIcon from './svg/events_tag.svg';
import looksTagIcon from './svg/looks_tag.svg';
import motionTagIcon from './svg/motion_tag.svg';
import operatorsTagIcon from './svg/operators_tag.svg';
import sensingTagIcon from './svg/sensing_tag.svg';
import soundTagIcon from './svg/sound_tag.svg';
import variablesTagIcon from './svg/variables_tag.svg';
import commentTagIconWW from './svg/ww/comment_tag.svg';
import controlTagIconWW from './svg/ww/control_tag.svg';
import drivetrainTagIconWW from './svg/ww/drivetrain_tag.svg';
import eventsTagIconWW from './svg/ww/events_tag.svg';
import looksTagIconWW from './svg/ww/looks_tag.svg';
import motionTagIconWW from './svg/ww/motion_tag.svg';
import operatorsTagIconWW from './svg/ww/operators_tag.svg';
import sensingTagIconWW from './svg/ww/sensing_tag.svg';
import soundTagIconWW from './svg/ww/sound_tag.svg';
import variablesTagIconWW from './svg/ww/variables_tag.svg';
import tagVR from './svg/vr/tag.svg';

const tagColorCN = {
    "all": {
        "color": "linear-gradient(to bottom, #EDEDED, #B9B9B9",
        "tagIcon": ""
    },
    "motion": {
        "color": "linear-gradient(to bottom, #D3DA67, #89B534)",
        "tagIcon": motionTagIcon
    },
    "drivetrain": {
        "color": "linear-gradient(to bottom, #2EEA81, #04A24D)",
        "tagIcon": drivetrainTagIcon
    },
    "looks": {
        "color": "linear-gradient(to bottom, #64DFFF, #398BFC)",
        "tagIcon": looksTagIcon
    },
    "sound": {
        "color": "linear-gradient(to bottom, #60F0F1, #038081)",
        "tagIcon": soundTagIcon
    },
    "events": {
        "color": "linear-gradient(to bottom, #71D2FF, #0859B1)",
        "tagIcon": eventsTagIcon
    },
    "control": {
        "color": "linear-gradient(to bottom, #FDCF44, #F29606)",
        "tagIcon": controlTagIcon
    },
    "sensing": {
        "color": "linear-gradient(to bottom, #F4AE7E, #EE8046)",
        "tagIcon": sensingTagIcon
    },
    "operators": {
        "color": "linear-gradient(to bottom, #DDB89B, #C09471)",
        "tagIcon": operatorsTagIcon
    },
    "variables": {
        "color": "linear-gradient(to bottom, #D29CFF, #AB29FB)",
        "tagIcon": variablesTagIcon
    },
    "comment": {
        "color": "linear-gradient(to bottom, #C5C6FA, #6775C4)",
        "tagIcon": commentTagIcon
    }
};

const tagColorWW = {
    "all": {
        "color": "linear-gradient(to bottom, #e9e9e9, #e9e9e9",
        "tagIcon": ""
    },
    "motion": {
        "color": "linear-gradient(to bottom, #C9FF70, #C9FF70)",
        "tagIcon": motionTagIconWW
    },
    "drivetrain": {
        "color": "linear-gradient(to bottom, #96F1B6, #96F1B6)",
        "tagIcon": drivetrainTagIconWW
    },
    "looks": {
        "color": "linear-gradient(to bottom, #88C9FF, #88C9FF)",
        "tagIcon": looksTagIconWW
    },
    "sound": {
        "color": "linear-gradient(to bottom, #C1F6FF, #C1F6FF)",
        "tagIcon": soundTagIconWW
    },
    "events": {
        "color": "linear-gradient(to bottom, #53E8F9, #53E8F9)",
        "tagIcon": eventsTagIconWW
    },
    "control": {
        "color": "linear-gradient(to bottom, #FFDD9A, #FFDD9A)",
        "tagIcon": controlTagIconWW
    },
    "sensing": {
        "color": "linear-gradient(to bottom, #FCF6A2, #FCF6A2)",
        "tagIcon": sensingTagIconWW
    },
    "operators": {
        "color": "linear-gradient(to bottom, #DFB696, #DFB696)",
        "tagIcon": operatorsTagIconWW
    },
    "variables": {
        "color": "linear-gradient(to bottom, #C187F6, #C187F6)",
        "tagIcon": variablesTagIconWW
    },
    "comment": {
        "color": "linear-gradient(to bottom, #C2BEFF, #C2BEFF)",
        "tagIcon": commentTagIconWW
    }
};

const tagColorVR = {
    "all": {
        "color": "linear-gradient(to bottom, #EDEDED, #B9B9B9",
        "tagIcon": ""
    },
    "motion": {
        "color": "linear-gradient(to bottom, #f7f8b2, #f7f8b2)",
        "tagIcon": tagVR
    },
    "drivetrain": {
        "color": "linear-gradient(to bottom, #d9ff99, #d9ff99)",
        "tagIcon": tagVR
    },
    "looks": {
        "color": "linear-gradient(to bottom, #d6e5ff, #d6e5ff)",
        "tagIcon": tagVR
    },
    "sound": {
        "color": "linear-gradient(to bottom, #a9ffe7, #a9ffe7)",
        "tagIcon": tagVR
    },
    "events": {
        "color": "linear-gradient(to bottom, #cafaff, #cafaff)",
        "tagIcon": tagVR
    },
    "control": {
        "color": "linear-gradient(to bottom, #f6d5c6, #f6d5c6)",
        "tagIcon": tagVR
    },
    "sensing": {
        "color": "linear-gradient(to bottom, #f5c29e, #f5c29e)",
        "tagIcon": tagVR
    },
    "operators": {
        "color": "linear-gradient(to bottom, #d3c7ff, #d3c7ff)",
        "tagIcon": tagVR
    },
    "variables": {
        "color": "linear-gradient(to bottom, #ffd4f6, #ffd4f6)",
        "tagIcon": tagVR
    },
    "comment": {
        "color": "linear-gradient(to bottom, #ffafc5, #ffafc5)",
        "tagIcon": tagVR
    }
};

export {
    tagColorCN,
    tagColorWW,
    tagColorVR
}