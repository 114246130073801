import drivetrainBlocks from '../block-vertical-blocks/drivetrain';

export default function drivetrainEDUandEntry(deviceInfo) {

    return `
        ${deviceInfo.drivetrainInfo.hasDrivetrain ? `
            <category name="%{BKY_CATEGORY_DRIVETRAIN}" id="drivetrain" colour="#9966FF" secondaryColour="#774DCB">
                ${drivetrainBlocks.drivetrain_drive}
                ${drivetrainBlocks.drivetrain_drivefor}
                ${drivetrainBlocks.drivetrain_turn}
                ${drivetrainBlocks.drivetrain_turnfor}
                ${deviceInfo.drivetrainInfo.hasDrivetrainwithGyro ? `
                    ${drivetrainBlocks.drivetrain_turntoheading}
                    ${drivetrainBlocks.drivetrain_turntorotation}
                `: ``}
                ${drivetrainBlocks.drivetrain_stopdriving}
                ${drivetrainBlocks.drivetrain_setdrivevelocityto_entry}
                ${drivetrainBlocks.drivetrain_setturnvelocityto_entry}
                ${drivetrainBlocks.drivetrain_setdrivestoppingto}
                ${drivetrainBlocks.drivetrain_setdrivetimeoutto}
                ${deviceInfo.drivetrainInfo.hasDrivetrainwithGyro ? `
                    ${drivetrainBlocks.drivetrain_setdriveheadingto}
                    ${drivetrainBlocks.drivetrain_setdriverotationto}
                `: ``}
            </category>`: ``
        }
    `;
};