import usingBuzzerIcon_CN from './img/cn/using-buzzer.svg';
import usingBuzzerImg_CN_EN from './img/cn/en/using-buzzer.png';
import usingBuzzerImg_CN_TW from './img/cn/tw/using-buzzer.png';
import usingBuzzerImg_CN_CN from './img/cn/cn/using-buzzer.png';
import usingLedsIcon_CN from './img/cn/using-leds.svg';
import usingLedsImg_CN_EN from './img/cn/en/using-leds.png';
import usingLedsImg_CN_TW from './img/cn/tw/using-leds.png';
import usingLedsImg_CN_CN from './img/cn/cn/using-leds.png';
import repeatingActionsIcon_CN from './img/cn/repeating-actions.svg';
import repeatingActionsImg_CN_EN from './img/cn/en/repeating-actions.png';
import repeatingActionsImg_CN_TW from './img/cn/tw/repeating-actions.png';
import repeatingActionsImg_CN_CN from './img/cn/cn/repeating-actions.png';
import lineTrackingIcon_CN from './img/cn/line-tracking.svg';
import lineTrackingImg_CN_EN from './img/cn/en/line-tracking.png';
import lineTrackingImg_CN_TW from './img/cn/tw/line-tracking.png';
import lineTrackingImg_CN_CN from './img/cn/cn/line-tracking.png';
import detectingWallsIcon_CN from './img/cn/detecting-walls.svg';
import detectingWallsImg_CN_EN from './img/cn/en/detecting-walls.png';
import detectingWallsImg_CN_TW from './img/cn/tw/detecting-walls.png';
import detectingWallsImg_CN_CN from './img/cn/cn/detecting-walls.png';
import detectingLightIcon_CN from './img/cn/detecting-light.svg';
import detectingLightImg_CN_EN from './img/cn/en/detecting-light.png';
import detectingLightImg_CN_TW from './img/cn/tw/detecting-light.png';
import detectingLightImg_CN_CN from './img/cn/cn/detecting-light.png';
import detectingDistancesIcon_CN from './img/cn/detecting-distances.svg';
import detectingDistancesImg_CN_EN from './img/cn/en/detecting-distances.png';
import detectingDistancesImg_CN_TW from './img/cn/tw/detecting-distances.png';
import detectingDistancesImg_CN_CN from './img/cn/cn/detecting-distances.png';
// entry
import moveTurnGyroImg_CN from './img/cn/move-turn-gyro.svg';
// import moveTurnGyroImg_CN_EN from './img/cn/en/move-turn-gyro.png';
import moveTurnGyroImg_CN_TW from './img/cn/tw/move-turn-gyro.png';
// import moveTurnGyroImg_CN_CN from './img/cn/cn/move-turn-gyro.png';
import controllerControlDevicesImg_CN from './img/cn/controller-control-devices.svg';
// import controllerControlDevicesImg_CN_EN from './img/cn/en/controller-control-devices.png';
import controllerControlDevicesImg_CN_TW from './img/cn/tw/controller-control-devices.png';
// import controllerControlDevicesImg_CN_CN from './img/cn/cn/controller-control-devices.png';
import colorSensorImg_CN from './img/cn/color-sensor.svg';
// import colorSensorImg_CN_EN from './img/cn/en/color-sensor.png';
import colorSensorImg_CN_TW from './img/cn/tw/color-sensor.png';
// import colorSensorImg_CN_CN from './img/cn/cn/color-sensor.png';
import touchledChangeColorsImg_CN from './img/cn/touchled-change-colors.svg';
// import touchledChangeColorsImg_CN_EN from './img/cn/en/touchled-change-colors.png';
import touchledChangeColorsImg_CN_TW from './img/cn/tw/touchled-change-colors.png';
// import touchledChangeColorsImg_CN_CN from './img/cn/cn/touchled-change-colors.png';
import colorChangingspeedTouchledImg_CN from './img/cn/color-changingspeed-touchled.svg';
// import colorChangingspeedTouchledImg_CN_EN from './img/cn/en/color-changingspeed-touchled.png';
import colorChangingspeedTouchledImg_CN_TW from './img/cn/tw/color-changingspeed-touchled.png';
// import colorChangingspeedTouchledImg_CN_CN from './img/cn/cn/color-changingspeed-touchled.png';
import touchledBrightnessImg_CN from './img/cn/touchled-brightness.svg';
// import touchledBrightnessImg_CN_EN from './img/cn/en/touchled-brightness.png';
import touchledBrightnessImg_CN_TW from './img/cn/tw/touchled-brightness.png';
// import touchledBrightnessImg_CN_CN from './img/cn/cn/touchled-brightness.png';


import usingBuzzerIcon_WW from './img/ww/using-buzzer.svg';
import usingBuzzerImg_WW_EN from './img/ww/en/using-buzzer.png';
import usingBuzzerImg_WW_TW from './img/ww/tw/using-buzzer.png';
import usingBuzzerImg_WW_CN from './img/ww/cn/using-buzzer.png';
import usingLedsIcon_WW from './img/ww/using-leds.svg';
import usingLedsImg_WW_EN from './img/ww/en/using-leds.png';
import usingLedsImg_WW_TW from './img/ww/tw/using-leds.png';
import usingLedsImg_WW_CN from './img/ww/cn/using-leds.png';
import repeatingActionsIcon_WW from './img/ww/repeating-actions.svg';
import repeatingActionsImg_WW_EN from './img/ww/en/repeating-actions.png';
import repeatingActionsImg_WW_TW from './img/ww/tw/repeating-actions.png';
import repeatingActionsImg_WW_CN from './img/ww/cn/repeating-actions.png';
import lineTrackingIcon_WW from './img/ww/line-tracking.svg';
import lineTrackingImg_WW_EN from './img/ww/en/line-tracking.png';
import lineTrackingImg_WW_TW from './img/ww/tw/line-tracking.png';
import lineTrackingImg_WW_CN from './img/ww/cn/line-tracking.png';
import detectingWallsIcon_WW from './img/ww/detecting-walls.svg';
import detectingWallsImg_WW_EN from './img/ww/en/detecting-walls.png';
import detectingWallsImg_WW_TW from './img/ww/tw/detecting-walls.png';
import detectingWallsImg_WW_CN from './img/ww/cn/detecting-walls.png';
import detectingLightIcon_WW from './img/ww/detecting-light.svg';
import detectingLightImg_WW_EN from './img/ww/en/detecting-light.png';
import detectingLightImg_WW_TW from './img/ww/tw/detecting-light.png';
import detectingLightImg_WW_CN from './img/ww/cn/detecting-light.png';
import detectingDistancesIcon_WW from './img/ww/detecting-distances.svg';
import detectingDistancesImg_WW_EN from './img/ww/en/detecting-distances.png';
import detectingDistancesImg_WW_TW from './img/ww/tw/detecting-distances.png';
import detectingDistancesImg_WW_CN from './img/ww/cn/detecting-distances.png';
// entry
import moveTurnGyroImg_WW from './img/ww/move-turn-gyro.svg';
import moveTurnGyroImg_WW_EN from './img/ww/en/move-turn-gyro.png';
import moveTurnGyroImg_WW_TW from './img/ww/tw/move-turn-gyro.png';
import moveTurnGyroImg_WW_CN from './img/ww/cn/move-turn-gyro.png';
import controllerControlDevicesImg_WW from './img/ww/controller-control-devices.svg';
import controllerControlDevicesImg_WW_EN from './img/ww/en/controller-control-devices.png';
import controllerControlDevicesImg_WW_TW from './img/ww/tw/controller-control-devices.png';
import controllerControlDevicesImg_WW_CN from './img/ww/cn/controller-control-devices.png';
import colorSensorImg_WW from './img/ww/color-sensor.svg';
import colorSensorImg_WW_EN from './img/ww/en/color-sensor.png';
import colorSensorImg_WW_TW from './img/ww/tw/color-sensor.png';
import colorSensorImg_WW_CN from './img/ww/cn/color-sensor.png';
import touchledChangeColorsImg_WW from './img/ww/touchled-change-colors.svg';
import touchledChangeColorsImg_WW_EN from './img/ww/en/touchled-change-colors.png';
import touchledChangeColorsImg_WW_TW from './img/ww/tw/touchled-change-colors.png';
import touchledChangeColorsImg_WW_CN from './img/ww/cn/touchled-change-colors.png';
import colorChangingspeedTouchledImg_WW from './img/ww/color-changingspeed-touchled.svg';
import colorChangingspeedTouchledImg_WW_EN from './img/ww/en/color-changingspeed-touchled.png';
import colorChangingspeedTouchledImg_WW_TW from './img/ww/tw/color-changingspeed-touchled.png';
import colorChangingspeedTouchledImg_WW_CN from './img/ww/cn/color-changingspeed-touchled.png';
import touchledBrightnessImg_WW from './img/ww/touchled-brightness.svg';
import touchledBrightnessImg_WW_EN from './img/ww/en/touchled-brightness.png';
import touchledBrightnessImg_WW_TW from './img/ww/tw/touchled-brightness.png';
import touchledBrightnessImg_WW_CN from './img/ww/cn/touchled-brightness.png';

export default {
    "cn": {
        "en": {
            "using-buzzer": {
                "icon": usingBuzzerIcon_CN,
                "img": usingBuzzerImg_CN_EN
            },
            "using-leds": {
                "icon": usingLedsIcon_CN,
                "img": usingLedsImg_CN_EN
            },
            "repeating-actions": {
                "icon": repeatingActionsIcon_CN,
                "img": repeatingActionsImg_CN_EN
            },
            "line-tracking": {
                "icon": lineTrackingIcon_CN,
                "img": lineTrackingImg_CN_EN
            },
            "detecting-walls": {
                "icon": detectingWallsIcon_CN,
                "img": detectingWallsImg_CN_EN
            },
            "detecting-light": {
                "icon": detectingLightIcon_CN,
                "img": detectingLightImg_CN_EN
            },
            "detecting-distances": {
                "icon": detectingDistancesIcon_CN,
                "img": detectingDistancesImg_CN_EN
            },
            "move-turn-gyro": {
                "icon": moveTurnGyroImg_CN,
                "img": moveTurnGyroImg_CN_TW
            },
            "controller-control-devices": {
                "icon": controllerControlDevicesImg_CN,
                "img": controllerControlDevicesImg_CN_TW
            },
            "color-sensor": {
                "icon": colorSensorImg_CN,
                "img": colorSensorImg_CN_TW
            },
            "touchled-change-colors": {
                "icon": touchledChangeColorsImg_CN,
                "img": touchledChangeColorsImg_CN_TW
            },
            "color-changingspeed-touchled": {
                "icon": colorChangingspeedTouchledImg_CN,
                "img": colorChangingspeedTouchledImg_CN_TW
            },
            "touchled-brightness": {
                "icon": touchledBrightnessImg_CN,
                "img": touchledBrightnessImg_CN_TW
            }
        },
        "zh-tw": {
            "using-buzzer": {
                "icon": usingBuzzerIcon_CN,
                "img": usingBuzzerImg_CN_TW
            },
            "using-leds": {
                "icon": usingLedsIcon_CN,
                "img": usingLedsImg_CN_TW
            },
            "repeating-actions": {
                "icon": repeatingActionsIcon_CN,
                "img": repeatingActionsImg_CN_TW
            },
            "line-tracking": {
                "icon": lineTrackingIcon_CN,
                "img": lineTrackingImg_CN_TW
            },
            "detecting-walls": {
                "icon": detectingWallsIcon_CN,
                "img": detectingWallsImg_CN_TW
            },
            "detecting-light": {
                "icon": detectingLightIcon_CN,
                "img": detectingLightImg_CN_TW
            },
            "detecting-distances": {
                "icon": detectingDistancesIcon_CN,
                "img": detectingDistancesImg_CN_TW
            },
            "move-turn-gyro": {
                "icon": moveTurnGyroImg_CN,
                "img": moveTurnGyroImg_CN_TW
            },
            "controller-control-devices": {
                "icon": controllerControlDevicesImg_CN,
                "img": controllerControlDevicesImg_CN_TW
            },
            "color-sensor": {
                "icon": colorSensorImg_CN,
                "img": colorSensorImg_CN_TW
            },
            "touchled-change-colors": {
                "icon": touchledChangeColorsImg_CN,
                "img": touchledChangeColorsImg_CN_TW
            },
            "color-changingspeed-touchled": {
                "icon": colorChangingspeedTouchledImg_CN,
                "img": colorChangingspeedTouchledImg_CN_TW
            },
            "touchled-brightness": {
                "icon": touchledBrightnessImg_CN,
                "img": touchledBrightnessImg_CN_TW
            }
        },
        "zh-cn": {
            "using-buzzer": {
                "icon": usingBuzzerIcon_CN,
                "img": usingBuzzerImg_CN_CN
            },
            "using-leds": {
                "icon": usingLedsIcon_CN,
                "img": usingLedsImg_CN_CN
            },
            "repeating-actions": {
                "icon": repeatingActionsIcon_CN,
                "img": repeatingActionsImg_CN_CN
            },
            "line-tracking": {
                "icon": lineTrackingIcon_CN,
                "img": lineTrackingImg_CN_CN
            },
            "detecting-walls": {
                "icon": detectingWallsIcon_CN,
                "img": detectingWallsImg_CN_CN
            },
            "detecting-light": {
                "icon": detectingLightIcon_CN,
                "img": detectingLightImg_CN_CN
            },
            "detecting-distances": {
                "icon": detectingDistancesIcon_CN,
                "img": detectingDistancesImg_CN_CN
            },
            "move-turn-gyro": {
                "icon": moveTurnGyroImg_CN,
                "img": moveTurnGyroImg_CN_TW
            },
            "controller-control-devices": {
                "icon": controllerControlDevicesImg_CN,
                "img": controllerControlDevicesImg_CN_TW
            },
            "color-sensor": {
                "icon": colorSensorImg_CN,
                "img": colorSensorImg_CN_TW
            },
            "touchled-change-colors": {
                "icon": touchledChangeColorsImg_CN,
                "img": touchledChangeColorsImg_CN_TW
            },
            "color-changingspeed-touchled": {
                "icon": colorChangingspeedTouchledImg_CN,
                "img": colorChangingspeedTouchledImg_CN_TW
            },
            "touchled-brightness": {
                "icon": touchledBrightnessImg_CN,
                "img": touchledBrightnessImg_CN_TW
            }
        }
    },
    "ww": {
        "en": {
            "using-buzzer": {
                "icon": usingBuzzerIcon_WW,
                "img": usingBuzzerImg_WW_EN
            },
            "using-leds": {
                "icon": usingLedsIcon_WW,
                "img": usingLedsImg_WW_EN
            },
            "repeating-actions": {
                "icon": repeatingActionsIcon_WW,
                "img": repeatingActionsImg_WW_EN
            },
            "line-tracking": {
                "icon": lineTrackingIcon_WW,
                "img": lineTrackingImg_WW_EN
            },
            "detecting-walls": {
                "icon": detectingWallsIcon_WW,
                "img": detectingWallsImg_WW_EN
            },
            "detecting-light": {
                "icon": detectingLightIcon_WW,
                "img": detectingLightImg_WW_EN
            },
            "detecting-distances": {
                "icon": detectingDistancesIcon_WW,
                "img": detectingDistancesImg_WW_EN
            },
            "move-turn-gyro": {
                "icon": moveTurnGyroImg_WW,
                "img": moveTurnGyroImg_WW_EN
            },
            "controller-control-devices": {
                "icon": controllerControlDevicesImg_WW,
                "img": controllerControlDevicesImg_WW_EN
            },
            "color-sensor": {
                "icon": colorSensorImg_WW,
                "img": colorSensorImg_WW_EN
            },
            "touchled-change-colors": {
                "icon": touchledChangeColorsImg_WW,
                "img": touchledChangeColorsImg_WW_EN
            },
            "color-changingspeed-touchled": {
                "icon": colorChangingspeedTouchledImg_WW,
                "img": colorChangingspeedTouchledImg_WW_EN
            },
            "touchled-brightness": {
                "icon": touchledBrightnessImg_WW,
                "img": touchledBrightnessImg_WW_EN
            }
        },
        "zh-tw": {
            "using-buzzer": {
                "icon": usingBuzzerIcon_WW,
                "img": usingBuzzerImg_WW_TW
            },
            "using-leds": {
                "icon": usingLedsIcon_WW,
                "img": usingLedsImg_WW_TW
            },
            "repeating-actions": {
                "icon": repeatingActionsIcon_WW,
                "img": repeatingActionsImg_WW_TW
            },
            "line-tracking": {
                "icon": lineTrackingIcon_WW,
                "img": lineTrackingImg_WW_TW
            },
            "detecting-walls": {
                "icon": detectingWallsIcon_WW,
                "img": detectingWallsImg_WW_TW
            },
            "detecting-light": {
                "icon": detectingLightIcon_WW,
                "img": detectingLightImg_WW_TW
            },
            "detecting-distances": {
                "icon": detectingDistancesIcon_WW,
                "img": detectingDistancesImg_WW_TW
            },
            "move-turn-gyro": {
                "icon": moveTurnGyroImg_WW,
                "img": moveTurnGyroImg_WW_TW
            },
            "controller-control-devices": {
                "icon": controllerControlDevicesImg_WW,
                "img": controllerControlDevicesImg_WW_TW
            },
            "color-sensor": {
                "icon": colorSensorImg_WW,
                "img": colorSensorImg_WW_TW
            },
            "touchled-change-colors": {
                "icon": touchledChangeColorsImg_WW,
                "img": touchledChangeColorsImg_WW_TW
            },
            "color-changingspeed-touchled": {
                "icon": colorChangingspeedTouchledImg_WW,
                "img": colorChangingspeedTouchledImg_WW_TW
            },
            "touchled-brightness": {
                "icon": touchledBrightnessImg_WW,
                "img": touchledBrightnessImg_WW_TW
            }
        },
        "zh-cn": {
            "using-buzzer": {
                "icon": usingBuzzerIcon_WW,
                "img": usingBuzzerImg_WW_CN
            },
            "using-leds": {
                "icon": usingLedsIcon_WW,
                "img": usingLedsImg_WW_CN
            },
            "repeating-actions": {
                "icon": repeatingActionsIcon_WW,
                "img": repeatingActionsImg_WW_CN
            },
            "line-tracking": {
                "icon": lineTrackingIcon_WW,
                "img": lineTrackingImg_WW_CN
            },
            "detecting-walls": {
                "icon": detectingWallsIcon_WW,
                "img": detectingWallsImg_WW_CN
            },
            "detecting-light": {
                "icon": detectingLightIcon_WW,
                "img": detectingLightImg_WW_CN
            },
            "detecting-distances": {
                "icon": detectingDistancesIcon_WW,
                "img": detectingDistancesImg_WW_CN
            },
            "move-turn-gyro": {
                "icon": moveTurnGyroImg_WW,
                "img": moveTurnGyroImg_WW_CN
            },
            "controller-control-devices": {
                "icon": controllerControlDevicesImg_WW,
                "img": controllerControlDevicesImg_WW_CN
            },
            "color-sensor": {
                "icon": colorSensorImg_WW,
                "img": colorSensorImg_WW_CN
            },
            "touchled-change-colors": {
                "icon": touchledChangeColorsImg_WW,
                "img": touchledChangeColorsImg_WW_CN
            },
            "color-changingspeed-touchled": {
                "icon": colorChangingspeedTouchledImg_WW,
                "img": colorChangingspeedTouchledImg_WW_CN
            },
            "touchled-brightness": {
                "icon": touchledBrightnessImg_WW,
                "img": touchledBrightnessImg_WW_CN
            }
        }
    },
    "vr": {
        "en": {
            "using-buzzer": {
                "icon": usingBuzzerIcon_WW,
                "img": usingBuzzerImg_WW_EN
            },
            "using-leds": {
                "icon": usingLedsIcon_WW,
                "img": usingLedsImg_WW_EN
            },
            "repeating-actions": {
                "icon": repeatingActionsIcon_WW,
                "img": repeatingActionsImg_WW_EN
            },
            "line-tracking": {
                "icon": lineTrackingIcon_WW,
                "img": lineTrackingImg_WW_EN
            },
            "detecting-walls": {
                "icon": detectingWallsIcon_WW,
                "img": detectingWallsImg_WW_EN
            },
            "detecting-light": {
                "icon": detectingLightIcon_WW,
                "img": detectingLightImg_WW_EN
            },
            "detecting-distances": {
                "icon": detectingDistancesIcon_WW,
                "img": detectingDistancesImg_WW_EN
            },
            "move-turn-gyro": {
                "icon": moveTurnGyroImg_WW,
                "img": moveTurnGyroImg_WW_EN
            },
            "controller-control-devices": {
                "icon": controllerControlDevicesImg_WW,
                "img": controllerControlDevicesImg_WW_EN
            },
            "color-sensor": {
                "icon": colorSensorImg_WW,
                "img": colorSensorImg_WW_EN
            },
            "touchled-change-colors": {
                "icon": touchledChangeColorsImg_WW,
                "img": touchledChangeColorsImg_WW_EN
            },
            "color-changingspeed-touchled": {
                "icon": colorChangingspeedTouchledImg_WW,
                "img": colorChangingspeedTouchledImg_WW_EN
            },
            "touchled-brightness": {
                "icon": touchledBrightnessImg_WW,
                "img": touchledBrightnessImg_WW_EN
            }
        },
        "zh-tw": {
            "using-buzzer": {
                "icon": usingBuzzerIcon_WW,
                "img": usingBuzzerImg_WW_TW
            },
            "using-leds": {
                "icon": usingLedsIcon_WW,
                "img": usingLedsImg_WW_TW
            },
            "repeating-actions": {
                "icon": repeatingActionsIcon_WW,
                "img": repeatingActionsImg_WW_TW
            },
            "line-tracking": {
                "icon": lineTrackingIcon_WW,
                "img": lineTrackingImg_WW_TW
            },
            "detecting-walls": {
                "icon": detectingWallsIcon_WW,
                "img": detectingWallsImg_WW_TW
            },
            "detecting-light": {
                "icon": detectingLightIcon_WW,
                "img": detectingLightImg_WW_TW
            },
            "detecting-distances": {
                "icon": detectingDistancesIcon_WW,
                "img": detectingDistancesImg_WW_TW
            },
            "move-turn-gyro": {
                "icon": moveTurnGyroImg_WW,
                "img": moveTurnGyroImg_WW_TW
            },
            "controller-control-devices": {
                "icon": controllerControlDevicesImg_WW,
                "img": controllerControlDevicesImg_WW_TW
            },
            "color-sensor": {
                "icon": colorSensorImg_WW,
                "img": colorSensorImg_WW_TW
            },
            "touchled-change-colors": {
                "icon": touchledChangeColorsImg_WW,
                "img": touchledChangeColorsImg_WW_TW
            },
            "color-changingspeed-touchled": {
                "icon": colorChangingspeedTouchledImg_WW,
                "img": colorChangingspeedTouchledImg_WW_TW
            },
            "touchled-brightness": {
                "icon": touchledBrightnessImg_WW,
                "img": touchledBrightnessImg_WW_TW
            }
        },
        "zh-cn": {
            "using-buzzer": {
                "icon": usingBuzzerIcon_WW,
                "img": usingBuzzerImg_WW_CN
            },
            "using-leds": {
                "icon": usingLedsIcon_WW,
                "img": usingLedsImg_WW_CN
            },
            "repeating-actions": {
                "icon": repeatingActionsIcon_WW,
                "img": repeatingActionsImg_WW_CN
            },
            "line-tracking": {
                "icon": lineTrackingIcon_WW,
                "img": lineTrackingImg_WW_CN
            },
            "detecting-walls": {
                "icon": detectingWallsIcon_WW,
                "img": detectingWallsImg_WW_CN
            },
            "detecting-light": {
                "icon": detectingLightIcon_WW,
                "img": detectingLightImg_WW_CN
            },
            "detecting-distances": {
                "icon": detectingDistancesIcon_WW,
                "img": detectingDistancesImg_WW_CN
            },
            "move-turn-gyro": {
                "icon": moveTurnGyroImg_WW,
                "img": moveTurnGyroImg_WW_CN
            },
            "controller-control-devices": {
                "icon": controllerControlDevicesImg_WW,
                "img": controllerControlDevicesImg_WW_CN
            },
            "color-sensor": {
                "icon": colorSensorImg_WW,
                "img": colorSensorImg_WW_CN
            },
            "touchled-change-colors": {
                "icon": touchledChangeColorsImg_WW,
                "img": touchledChangeColorsImg_WW_CN
            },
            "color-changingspeed-touchled": {
                "icon": colorChangingspeedTouchledImg_WW,
                "img": colorChangingspeedTouchledImg_WW_CN
            },
            "touchled-brightness": {
                "icon": touchledBrightnessImg_WW,
                "img": touchledBrightnessImg_WW_CN
            }
        }
    }
}