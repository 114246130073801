const motionBlocks = (targetId) => {
    const motionSpin =
        `<block id="motion_spin" type="motion_spin"/>`;

    const motionSpinDegree =
        `<block id="motion_spindegree" type="motion_spindegree">
            <value name="NUMBER">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
            </value>
        </block>`;

    const motionSpinPositionDegree =
        `<block id="${targetId}_motion_spinpositiondegree" type="motion_spinpositiondegree">
            <value name="NUMBER">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
            </value>
        </block>`;

    const motionStop =
        `<block id="${targetId}_motion_stop" type="motion_stop"/>`;

    const motionSetPositionDegree =
        `<block id="${targetId}_motion_setpositiondegree" type="motion_setpositiondegree">
            <value name="NUMBER">
                <shadow type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>`;

    const motionSetVelocityPercentEntry =
        `<block id="${targetId}_motion_setvelocitypercent_entry" type="motion_setvelocitypercent_entry">
            <value name="NUMBER">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>
        </block>`;

    const motionSetVelocityPercentEDUandEntry =
        `<block id="${targetId}_motion_setvelocitypercent_eduandentry" type="motion_setvelocitypercent_eduandentry">
            <value name="NUMBER">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>
        </block>`;

    const motionSetStopping =
        `<block id="${targetId}_motion_setstopping" type="motion_setstopping"/>`;

    const motionSetMaxtorque =
        `<block id="${targetId}_motion_setmaxtorque" type="motion_setmaxtorque">
            <value name="NUMBER">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>
        </block>`;

    const motionSetTimeout =
        `<block id="${targetId}_motion_settimeout" type="motion_settimeout">
            <value name="NUMBER">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>`;

    const motion3wireSpin =
        `<block id="motion_3wire_spin" type="motion_3wire_spin"/>`;

    const motion3wireStop =
        `<block id="${targetId}_motion_3wire_stop" type="motion_3wire_stop"/>`;

    const motion3wireSetVelocityPercent =
        `<block id="${targetId}_motion_setvelocitypercent" type="motion_3wire_setvelocitypercent">
            <value name="DEGREE">
                <shadow type="degree">
                    <field name="DEGREE">50</field>
                </shadow>
            </value>
        </block>`;

    return {
        motion_spin: motionSpin,
        motion_spindegree: motionSpinDegree,
        motion_spinpositiondegree: motionSpinPositionDegree,
        motion_stop: motionStop,
        motion_setpositiondegree: motionSetPositionDegree,
        motion_setvelocitypercent_entry: motionSetVelocityPercentEntry,
        motion_setvelocitypercent_eduandentry: motionSetVelocityPercentEDUandEntry,
        motion_setstopping: motionSetStopping,
        motion_setmaxtorque: motionSetMaxtorque,
        motion_settimeout: motionSetTimeout,

        motion_3wire_spin: motion3wireSpin,
        motion_3wire_stop: motion3wireStop,
        motion_3wire_setvelocitypercent: motion3wireSetVelocityPercent,
    };
};

export default motionBlocks;