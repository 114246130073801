import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import dialogStyles from '../dialog.css';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import log from '../../../lib/log';
import store from 'store';
import { DEFAULT_LOCALE } from '../../../config/project-config';
import { EditUtils } from '../../device-manager/edit-page/edit-utils';
import {
    hideSpeakerCustomizingDialog,
    showSpeakerConceptDialog,
    showAddAISpeechGroupDialog,
    showErrorDialog,
    errorType,
    isSpeakerConceptDialogShow
} from '../../../reducers/dialog';
import {
    getWorkspace
} from '../../../reducers/block';

import deleteIcon from '../svg/delete_btn.svg';
import exclamationIcon from '../svg/exclamation_mark.svg';
import { SPEAKER_DIALOG_ACTION, setConceptAction } from '../../../reducers/speaker';
import styles from './speaker-dialog.css'
import VM from 'scratch-vm';
import editImg from '../svg/edit_pen.svg'
import checkIcon from '../svg/check.svg'
import checkWWIcon from '../svg/ww/check.svg';
import { uiType, getUIStyle } from '../../../reducers/ui-style';
import { CONCEPTS_LIMIT } from '../../gui/speaker-import'
import { isPad } from '../../../lib/platform';

const TITLE_HEIGHT = 54

const EXPAND_OPTION_TYPE = {
    CREATE_SENTENCE: "createSentence",
    SELECT_GROUP: "selectGroup"
}

const CREATE_SENTENCE_TYPE = {
    INTENT: "intent",
    RESPONSE: "response"
}

const conceptKey = ":var"
const createGroupTag = "&creatGroup"
const SENTENCE_ARRAY_LIMIT = 5;
const CONCEPT_IN_INTENT_SENTENCE_LIMIT = 2;
const emptyConceptKey = "<" + conceptKey

let touchendFunc = () => { };
let mouseupFunc = () => { };
let resizeFunc = () => { };
class SpeakerCustomizingDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            intentName: "",
            intentSentence: [[], [], [], [], []],
            responseSentence: [[], [], [], [], []],
            isOptionExpanded: null,
            editIntentIndex: -1,
            editResponseIndex: -1,
            autoFocus: false,
            focusInput: [null, null, null],
            focusConcept: [null, null, null],
            groupName: "",
            groupId: "",
            intentId: "",
            editSentence: [null, null],
            firstConceptList: [],
            secondConceptList: [],
            intentWarning: false,
            responseWarning: false,
            dropdown: true
        }

    }

    componentDidMount() {
        if (this.props.action == SPEAKER_DIALOG_ACTION.add) {
            this.initAddSentence();
        } else if (this.props.action == SPEAKER_DIALOG_ACTION.edit) {
            this.loadEditSentence();
        }
        resizeFunc = e => {
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener('resize', resizeFunc);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', resizeFunc);
        window.removeEventListener('mouseup', mouseupFunc);
        window.removeEventListener('touchend', touchendFunc);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isSpeakerConceptDialogShow !== this.props.isSpeakerConceptDialogShow
            && this.props.isSpeakerConceptDialogShow == false) {
            this.refreshEmptyConcept();
        }
        if (prevState.autoFocusForIPad != this.state.autoFocus && this.state.autoFocus == true) {
            this.setState({
                autoFocus: false
            });
            let word = document.getElementById(`word-${this.state.focusInput[2]}-${this.state.focusInput[1]}-${(this.state.focusInput[0])}`);
            if (word) {
                word.focus();
            }
        }
    }

    initAddSentence() {
        const group = this.checkDefaultGroup()
        setTimeout(() => {
            this.setState({
                intentName: "",
                intentSentence: [[], [], [], [], []],
                responseSentence: [[], [], [], [], []],
                isOptionExpanded: null,
                editIntentIndex: -1,
                editResponseIndex: -1,
                focusInput: [null, null, null],
                focusConcept: [null, null, null],
                groupName: group.name,
                groupId: group.id,
                intentId: "",
                editSentence: [null, null],
                intentWarning: false,
                responseWarning: false
            })
        }, 100)
    }

    checkDefaultGroup() {
        const groups = this.getSpeakerGroups()
        if (groups.length == 0) {
            this.props.vm.addSpeakerGroup(EditUtils.getLocaleString("gui.dialog.speaker.default.group"));
        }
        return this.getSpeakerGroups()[0]
    }

    loadEditSentence() {
        const intentId = this.props.intentId
        if (intentId) {
            const intent = this.props.vm.getSpeakerIntentById(intentId);
            let intentSentence = [].concat(intent.pattern)
            while (intentSentence.length < SENTENCE_ARRAY_LIMIT) intentSentence.push([])
            let responseSentence = [].concat(intent.response)
            while (responseSentence.length < SENTENCE_ARRAY_LIMIT) responseSentence.push([])
            const group = this.props.vm.getSpeakerGroupById(intent.group)
            this.setState({
                intentName: intent.IntentName,
                intentSentence: intentSentence,
                responseSentence: responseSentence,
                isOptionExpanded: null,
                editIntentIndex: -1,
                editResponseIndex: -1,
                focusInput: [null, null, null],
                focusConcept: [null, null, null],
                editSentence: [null, null],
                groupName: group ? group.name : this.checkDefaultGroup().name,
                groupId: group ? group.id : this.checkDefaultGroup().id,
                intentId: intentId,
                intentWarning: false,
                responseWarning: false
            })
        } else {
            this.initAddSentence();
        }
    }

    onClickGroup() {
        if (this.state.isOptionExpanded != EXPAND_OPTION_TYPE.SELECT_GROUP) {
            this.setState({ isOptionExpanded: EXPAND_OPTION_TYPE.SELECT_GROUP })
        } else {
            this.clearState();
        }
        this.clearSentenceEditMode();
    }

    onSelectGroup(groupIndex) {
        const groups = this.getSpeakerGroups()
        this.setState({
            groupId: groups[groupIndex].id,
            groupName: groups[groupIndex].name
        })
        this.clearState();
    }

    getContent() {
        return <div className={classNames(styles.customizingContentArea)}>
            <IntentSettings
                onNameChange={e => this.onNameChange(e)}
                state={this.state}
                onClickCreatWord={(index) => this.onClickCreatWord(index, CREATE_SENTENCE_TYPE.INTENT)}
                onCreateConcept={(index) => this.onCreateConcept(index, CREATE_SENTENCE_TYPE.INTENT)}
                onCreateCommon={(index) => this.onCreateCommon(index, CREATE_SENTENCE_TYPE.INTENT)}
                onWordChange={(e, wordIndex, sentenceIndex) => this.onWordChange(e, wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.INTENT)}
                onWordInputFocus={(wordIndex, sentenceIndex) => this.onWordInputFocus(wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.INTENT)}
                isWordInputFocus={(wordIndex, sentenceIndex) => this.isWordInputFocus(wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.INTENT)}
                onConceptOptionFocus={(selector, scrollDiv, wordIndex, sentenceIndex) => this.onConceptOptionFocus(selector, scrollDiv, wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.INTENT)}
                isConceptOptionFocus={(wordIndex, sentenceIndex) => this.isConceptOptionFocus(wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.INTENT)}
                onSelectConcept={(conceptIndex, wordIndex, sentenceIndex) => this.onSelectConcept(conceptIndex, wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.INTENT)}
                onCreateNewConcept={() => this.onCreateNewConcept()}
                isIntentNameDuplicate={() => this.isIntentNameDuplicate(this.state.intentName, this.state.intentId)}
                isIntentNameContainSpecialWord={() => this.isIntentNameContainSpecialWord(this.state.intentName)}
                onSentenceEdit={(sentenceIndex) => this.onSentenceEdit(sentenceIndex, CREATE_SENTENCE_TYPE.INTENT)}
                isSentenceEditMode={(sentenceIndex) => this.isSentenceEditMode(sentenceIndex, CREATE_SENTENCE_TYPE.INTENT)}
                onRemoveComponent={(wordIndex, sentenceIndex) => this.onRemoveComponent(wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.INTENT)}
                clearSentenceEditMode={() => this.clearSentenceEditMode()}
                sentenceType={CREATE_SENTENCE_TYPE.INTENT}
                isIntentCreatedConcept={true}
                conceptOption={this.props.vm.getSpeakerConcepts()}
                vmConceptOption={this.props.vm.getSpeakerConcepts()}
                uiStyle={this.props.getUIStyle}
                isWordAreaContainSp={(word) => this.isWordAreaContainSp(word)}
            />
            <ResponseSettings
                state={this.state}
                onClickCreatWord={(index) => this.onClickCreatWord(index, CREATE_SENTENCE_TYPE.RESPONSE)}
                onCreateConcept={(index) => this.onCreateConcept(index, CREATE_SENTENCE_TYPE.RESPONSE)}
                onCreateCommon={(index) => this.onCreateCommon(index, CREATE_SENTENCE_TYPE.RESPONSE)}
                onWordChange={(e, wordIndex, sentenceIndex) => this.onWordChange(e, wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.RESPONSE)}
                onWordInputFocus={(wordIndex, sentenceIndex) => this.onWordInputFocus(wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.RESPONSE)}
                isWordInputFocus={(wordIndex, sentenceIndex) => this.isWordInputFocus(wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.RESPONSE)}
                onConceptOptionFocus={(selector, scrollDiv, wordIndex, sentenceIndex) => this.onConceptOptionFocus(selector, scrollDiv, wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.RESPONSE)}
                isConceptOptionFocus={(wordIndex, sentenceIndex) => this.isConceptOptionFocus(wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.RESPONSE)}
                onSelectConcept={(conceptIndex, wordIndex, sentenceIndex) => this.onSelectConcept(conceptIndex, wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.RESPONSE)}
                onCreateNewConcept={() => this.onCreateNewConcept()}
                onSentenceEdit={(sentenceIndex) => this.onSentenceEdit(sentenceIndex, CREATE_SENTENCE_TYPE.RESPONSE)}
                isSentenceEditMode={(sentenceIndex) => this.isSentenceEditMode(sentenceIndex, CREATE_SENTENCE_TYPE.RESPONSE)}
                onRemoveComponent={(wordIndex, sentenceIndex) => this.onRemoveComponent(wordIndex, sentenceIndex, CREATE_SENTENCE_TYPE.RESPONSE)}
                clearSentenceEditMode={() => this.clearSentenceEditMode()}
                sentenceType={CREATE_SENTENCE_TYPE.RESPONSE}
                isIntentCreatedConcept={this.isIntentCreatedConcept()}
                conceptOption={this.getResponseConceptOption()}
                vmConceptOption={this.props.vm.getSpeakerConcepts()}
                uiStyle={this.props.getUIStyle}
                isWordAreaContainSp={(word) => this.isWordAreaContainSp(word)}
            />
        </div>
    }

    isIntentCreatedConcept() {
        let intentArr = [].concat(this.state.intentSentence)
        let isIntentCreatedConcept = false;
        for (let i = 0; i < intentArr.length; i++) {
            const sentence = intentArr[i];
            for (let j = 0; j < sentence.length; j++) {
                const word = sentence[j];
                if (word.includes(":var") && !word.includes(emptyConceptKey)) {
                    isIntentCreatedConcept = true;
                    break;
                }
            }
            if (isIntentCreatedConcept) break;
        }
        return isIntentCreatedConcept
    }

    onCreateNewGroup() {
        this.props.showAddAISpeechGroupDialog();
        this.setState({
            focusConcept: [null, null, null],
            focusInput: [null, null, null]
        })
        this.clearState()
    }

    onCreateNewConcept() {
        this.setState({ focusConcept: [null, null, null] })
        const vmConcepts = this.props.vm.getSpeakerConcepts();
        if (vmConcepts.length >= CONCEPTS_LIMIT) {
            this.props.showErrorDialog(errorType.SPEAKER_CONCEPT_UP_TO_LIMIT);
        } else {
            this.props.setConceptAction(SPEAKER_DIALOG_ACTION.add)
            this.props.showSpeakerConceptDialog();
        }
        this.clearState()
    }

    onSelectConcept(conceptIndex, wordIndex, sentenceIndex, sentenceType) {
        this.setState({ focusConcept: [null, null, null] })
        let sentenceArr = [].concat(this.getEditSentenceArr(sentenceType))
        let sentence = [].concat(sentenceArr[sentenceIndex]);
        const reg = /[<>]/g
        let wordArr = sentence[wordIndex].replace(reg, "");
        wordArr = wordArr.split(":")
        if (sentenceType == CREATE_SENTENCE_TYPE.INTENT) {
            const concepts = this.props.vm.getSpeakerConcepts();
            wordArr[0] = "<" + concepts[conceptIndex].tag;
            wordArr[2] = concepts[conceptIndex].id + ">";
        } else {
            const concepts = this.getResponseConceptOption()
            wordArr[0] = "<" + concepts[conceptIndex].tag;
            wordArr[2] = concepts[conceptIndex].id;
            wordArr[3] = concepts[conceptIndex].order + ">";
        }
        sentence[wordIndex] = wordArr.join(":");
        sentenceArr[sentenceIndex] = sentence;
        this.setEditSentenceArr(sentenceArr, sentenceType)
        this.clearState()
    }

    getIntentExistConceptId(intentSentence) {
        let firstConceptMap = new Map();
        let secondCconceptMap = new Map();
        const reg = /[<>]/g
        intentSentence.forEach(sentence => {
            let firstConcept = { id: "", tag: "" };
            let secondConcept = { id: "", tag: "" };
            sentence.forEach(word => {
                if (word.includes(conceptKey) && !word.includes(emptyConceptKey)) {
                    let concept = word.replace(reg, "").split(":");
                    if (!firstConcept.id) {
                        firstConcept = { id: concept[2], tag: concept[0] };
                    } else {
                        secondConcept = { id: concept[2], tag: concept[0] };
                    }
                }
            })
            if (firstConcept.id) firstConceptMap.set(firstConcept.id, firstConcept)
            if (secondConcept.id) {
                if (firstConcept.id != secondConcept.id) {
                    firstConceptMap.set(secondConcept.id, secondConcept)
                } else {
                    secondCconceptMap.set(secondConcept.id, secondConcept)
                }
            }
        })
        let firstConceptArr = [];
        let secondCconceptArr = [];
        for (let [k, v] of firstConceptMap) {
            firstConceptArr.push(v)
        }
        for (let [k, v] of secondCconceptMap) {
            secondCconceptArr.push(v)
        }
        return {
            firstConceptList: firstConceptArr,
            secondConceptList: secondCconceptArr
        }
    }

    getResponseConceptOption() {
        let optionList = [];
        let conceptList = this.getIntentExistConceptId(this.state.intentSentence)
        let firstConceptArr = conceptList.firstConceptList;
        let secondConceptArr = conceptList.secondConceptList;
        if (firstConceptArr.length > 0) {
            firstConceptArr.forEach(concept => {
                if (concept) {
                    optionList.push({ id: concept.id, tag: concept.tag, order: 1 });
                    if (secondConceptArr.length > 0 && concept.id == secondConceptArr[0].id) {
                        optionList.push({ id: concept.id, tag: concept.tag, order: 2 });
                        secondConceptArr.shift();
                    }
                }
            })
        }
        return optionList;
    }

    onSentenceEdit(sentenceIndex, sentenceType) {
        this.setState({
            editSentence: [sentenceIndex, sentenceType],
            focusConcept: [null, null, null],
            focusInput: [null, null, null]
        })
        this.clearState();
    }

    isSentenceEditMode(sentenceIndex, sentenceType) {
        const editSentence = this.state.editSentence
        return editSentence[0] == sentenceIndex && editSentence[1] == sentenceType
    }

    clearSentenceEditMode() {
        if (this.state.editSentence[0] != null) {
            this.setState({ editSentence: [null, null] })
        }
    }

    onRemoveComponent(wordIndex, sentenceIndex, sentenceType) {
        let sentenceArr = [].concat(this.getEditSentenceArr(sentenceType))
        let sentence = [].concat(sentenceArr[sentenceIndex]);
        sentence.splice(wordIndex, 1);
        if (sentence.length == 0) {
            sentenceArr.splice(sentenceIndex, 1);
            sentenceArr.push([])
            this.clearSentenceEditMode();
        } else {
            sentenceArr[sentenceIndex] = sentence;
        }
        this.setEditSentenceArr(sentenceArr, sentenceType)
        this.isSentenceContainSp(null, wordIndex, sentenceIndex, sentenceType);
    }

    onWordInputFocus(wordIndex, sentenceIndex, sentenceType) {
        this.setState({
            autoFocus: true,
            focusInput: [wordIndex, sentenceIndex, sentenceType],
            focusConcept: [null, null, null]
        });
        this.addClickListener();
        this.clearSentenceEditMode();
        this.clearState();
    }

    isWordInputFocus(wordIndex, sentenceIndex, sentenceType) {
        const focus = this.state.focusInput
        return focus[0] == wordIndex && focus[1] == sentenceIndex && focus[2] == sentenceType
    }

    onWordChange(e, wordIndex, sentenceIndex, sentenceType) {
        if (e.target.value.includes(":")) return;
        let sentenceArr = [].concat(this.getEditSentenceArr(sentenceType))
        let sentence = [].concat(sentenceArr[sentenceIndex]);
        this.isSentenceContainSp(e.target.value, wordIndex, sentenceIndex, sentenceType);
        sentence[wordIndex] = e.target.value;
        sentenceArr[sentenceIndex] = sentence;
        this.setEditSentenceArr(sentenceArr, sentenceType)
    }

    onConceptOptionFocus(selector, scrollDiv, wordIndex, sentenceIndex, sentenceType) {
        const dropdownHeight = 142;
        let dropdown = true;
        let selectorTop = selector.getBoundingClientRect().top;
        let contentTop = 10000; // dropdown when cannot get bounding
        if (scrollDiv) {
            contentTop = scrollDiv.getBoundingClientRect().top;
        }
        if ((selectorTop - contentTop) > dropdownHeight) {
            dropdown = false;
        }

        if (!this.isConceptOptionFocus(wordIndex, sentenceIndex, sentenceType)) {
            this.setState({
                focusConcept: [wordIndex, sentenceIndex, sentenceType],
                focusInput: [null, null, null],
                dropdown: dropdown
            })
        } else {
            this.setState({
                focusConcept: [null, null, null],
                dropdown: dropdown
            })
        }
        this.clearSentenceEditMode();
        this.clearState();
    }

    isConceptOptionFocus(wordIndex, sentenceIndex, sentenceType) {
        const focus = this.state.focusConcept
        return focus[0] != null && focus[0] == wordIndex && focus[1] == sentenceIndex && focus[2] == sentenceType
    }

    addClickListener() {
        if (!isPad()) {
            mouseupFunc = e => {
                let isFocus = false;
                isFocus = this.state.focusInput.filter(index => index != null).length > 0;
                if (isFocus) {
                    this.setState({
                        focusInput: [null, null, null]
                    })
                    window.removeEventListener('mouseup', mouseupFunc);
                }
            }
            window.addEventListener('mouseup', mouseupFunc);
        } else {
            touchendFunc = e => {
                let isFocus = false;
                isFocus = this.state.focusInput.filter(index => index != null).length > 0;
                if (isFocus) {
                    this.setState({
                        focusInput: [null, null, null]
                    })
                    window.removeEventListener('touchend', touchendFunc);
                }
            }
            window.addEventListener('touchend', touchendFunc);

        }
    }



    onClickCreatWord(index, sentenceType) {
        if (!this.state.isOptionExpanded) {
            if (sentenceType == CREATE_SENTENCE_TYPE.INTENT) {
                this.setState({
                    isOptionExpanded: EXPAND_OPTION_TYPE.CREATE_SENTENCE,
                    editIntentIndex: index,
                    focusInput: [null, null, null],
                    focusConcept: [null, null, null]
                })
            } else if (sentenceType == CREATE_SENTENCE_TYPE.RESPONSE) {
                this.setState({
                    isOptionExpanded: EXPAND_OPTION_TYPE.CREATE_SENTENCE,
                    editResponseIndex: index,
                    focusInput: [null, null, null],
                    focusConcept: [null, null, null]
                })
            }
            this.clearSentenceEditMode();
        } else {
            this.clearState();
        }
    }

    getEditSentenceArr(sentenceType) {
        if (sentenceType == CREATE_SENTENCE_TYPE.INTENT) {
            return this.state.intentSentence;
        } else if (sentenceType == CREATE_SENTENCE_TYPE.RESPONSE) {
            return this.state.responseSentence;
        }
    }

    setEditSentenceArr(sentenceArr, sentenceType) {
        if (sentenceType == CREATE_SENTENCE_TYPE.INTENT) {
            this.setIntent(sentenceArr);
        } else if (sentenceType == CREATE_SENTENCE_TYPE.RESPONSE) {
            this.setResponse(sentenceArr);
        }
    }

    refreshEmptyConcept() {
        const concepts = this.props.vm.getSpeakerConcepts();
        if (concepts.length > 0) {
            let intentArr = [].concat(this.state.intentSentence)
            intentArr.forEach(sentence => {
                sentence.forEach((word, index) => {
                    if (word.includes(emptyConceptKey)) {
                        let wordContent = word.split(":")
                        sentence[index] = "<" + concepts[0].tag + ":" + wordContent[1] + ":" + concepts[0].id + ">";
                    }
                })
            })
            this.setEditSentenceArr(intentArr, CREATE_SENTENCE_TYPE.INTENT);
        } else {
            let intentArr = [].concat(this.state.intentSentence)
            intentArr.forEach((sentence, index) => {
                intentArr[index] = sentence.filter(word => !word.includes(emptyConceptKey));
            })
            this.setEditSentenceArr(intentArr, CREATE_SENTENCE_TYPE.INTENT);
        }
    }

    onCreateConcept(index, sentenceType) {
        const sentenceArr = this.getEditSentenceArr(sentenceType)
        const sentence = sentenceArr[index];
        let varAmount = 1;
        sentence.forEach(word => { if (word.includes(conceptKey)) varAmount++ })
        const concepts = this.props.vm.getSpeakerConcepts();
        //get intent exist concept
        if (concepts.length > 0) {
            if (sentenceType == CREATE_SENTENCE_TYPE.INTENT) {
                this.onCreateWord(index, sentenceType, "<" + concepts[0].tag + conceptKey + varAmount + ":" + concepts[0].id + ">");
            } else {
                let defaultConcept = this.getResponseConceptOption();
                this.onCreateWord(index, sentenceType, "<" + defaultConcept[0].tag + conceptKey + varAmount + ":" + defaultConcept[0].id + ":" + 1 + ">");
            }
        } else {
            this.onCreateWord(index, sentenceType, emptyConceptKey + varAmount + ":>");
            this.onCreateNewConcept();
        }
    }

    onCreateCommon(index, sentenceType) {
        this.onCreateWord(index, sentenceType, "")
    }

    onCreateWord(index, sentenceType, newWord) {
        let sentenceArr = [].concat(this.getEditSentenceArr(sentenceType))
        let sentence = [].concat(sentenceArr[index]);
        sentence.push(newWord);
        sentenceArr[index] = sentence;
        this.setEditSentenceArr(sentenceArr, sentenceType)
        this.onWordInputFocus(sentence.length - 1, index, sentenceType);
        this.clearState()
    }

    setIntent(sentenceList) {
        this.setState({ intentSentence: sentenceList })
    }

    setResponse(sentenceList) {
        this.setState({ responseSentence: sentenceList })
    }

    clearState() {
        this.setState({
            isOptionExpanded: null,
            editIntentIndex: -1,
            editResponseIndex: -1
        })
    }

    onNameChange(event) {
        this.setState({
            intentName: event.target.value
        })
        this.clearSentenceEditMode();
    }

    onClickCancel() {
        this.props.hideSpeakerCustomizingDialog();
        this.clearSentenceEditMode();
    }

    onClickConfirm() {
        this.clearSentenceEditMode();
        const intention = this.parseIntention()
        const intentArr = intention.intentArr;
        const intentMap = intention.intentMap;
        const responseArray = this.parseResponse(intentMap)
        if (this.props.action == SPEAKER_DIALOG_ACTION.add) {
            this.props.vm.addSpeakerIntent(this.state.intentName, this.state.groupId, intentArr, responseArray)
        } else if (this.props.action == SPEAKER_DIALOG_ACTION.edit) {
            this.props.vm.editSpeakerIntent(this.state.intentId, this.state.intentName, this.state.groupId, intentArr, responseArray)
        }
        this.props.getWorkspace.refreshToolboxSelection_();
        this.props.hideSpeakerCustomizingDialog();
    }

    parseIntention() {
        let intentArr = [].concat(this.state.intentSentence)
        intentArr = intentArr.map(sentence => sentence.filter(word => word != "" && !word.includes(emptyConceptKey))).filter(sentence => sentence != "");
        let varNum = 1;
        let varMap = new Map();
        intentArr.forEach(sentence => {
            let conceptList = []
            sentence.forEach((word, index) => {
                if (word.includes(conceptKey)) {
                    const reg = /[<>]/g
                    let wordContent = word.replace(reg, "");
                    wordContent = wordContent.split(":")
                    conceptList.push(wordContent[2])
                    if (!varMap.has(wordContent[2])) {
                        varMap.set(wordContent[2], [varNum]);
                        sentence[index] = "<" + wordContent[0] + conceptKey + varNum + ":" + wordContent[2] + ">"
                        varNum++
                    } else {
                        let numArr = varMap.get(wordContent[2])
                        let times = conceptList.filter(id => id == wordContent[2]).length - 1;
                        let newNum = numArr[0] + (CONCEPT_IN_INTENT_SENTENCE_LIMIT * SENTENCE_ARRAY_LIMIT) * times;
                        if (!numArr.includes(newNum)) {
                            numArr.push(newNum);
                            varMap.set(wordContent[2], numArr);
                        }
                        sentence[index] = "<" + wordContent[0] + conceptKey + newNum + ":" + wordContent[2] + ">"
                    }
                }
            }
            )
        })
        return {
            intentArr: intentArr,
            intentMap: varMap
        }
    }

    parseResponse(intentMap) {
        let responseArr = [].concat(this.state.responseSentence)
        responseArr = responseArr.map(sentence => sentence.filter(word => word != "")).filter(sentence => sentence != "");
        const existVarMap = intentMap
        responseArr.forEach(sentence => {
            sentence.forEach((word, index) => {
                if (word.includes(conceptKey)) {
                    const reg = /[<>]/g
                    let wordContent = word.replace(reg, "");
                    wordContent = wordContent.split(":")
                    if (!existVarMap.has(wordContent[2])) {
                        sentence[index] = "<" + wordContent[0] + conceptKey + "$:" + wordContent[2] + ":" + wordContent[3] + ">"
                    } else {
                        let numList = existVarMap.get(wordContent[2])
                        let varNum = "$";
                        if (wordContent[3]) {
                            if (wordContent[3].charAt(0) == "1" && numList[0]) {
                                varNum = numList[0]
                            }
                            if (wordContent[3].charAt(0) == "2" && numList[1]) {
                                varNum = numList[1]
                            }
                        }
                        sentence[index] = "<" + wordContent[0] + conceptKey + varNum + ":" + wordContent[2] + ":" + wordContent[3] + ">"
                    }
                }
            })
        })
        return responseArr
    }

    isConfirmEnable() {
        let intentArr = [].concat(this.state.intentSentence)
        intentArr = intentArr.map(sentence => sentence.filter(word => word != "" && !word.includes(emptyConceptKey))).filter(sentence => sentence != "" && sentence.length > 0);
        let responseArray = [].concat(this.state.responseSentence)
        responseArray = responseArray.map(sentence => sentence.filter(word => word != "" && !word.includes(emptyConceptKey))).filter(sentence => sentence != "" && sentence.length > 0);
        return this.state.intentName != "" && !this.isIntentNameDuplicate(this.state.intentName, this.state.intentId) && !this.isIntentNameContainSpecialWord(this.state.intentName) && intentArr.length > 0 && !this.state.intentWarning && !this.state.responseWarning
    }

    getSpeakerGroups() {
        return this.props.vm.getSpeakerGroups()
    }

    getGroupsOtion() {
        let option = [].concat(this.props.vm.getSpeakerGroups());
        option.push({ id: createGroupTag, name: createGroupTag, expanded: false })
        return option;
    }

    isIntentNameDuplicate(name, id) {
        if (this.props.action == SPEAKER_DIALOG_ACTION.add) {
            return this.isNewIntentNameDuplicate(name)
        } else if (this.props.action == SPEAKER_DIALOG_ACTION.edit) {
            return this.isEditIntentNameDuplicate(name, id)
        }
    }

    isIntentNameContainSpecialWord(word) {
        let spaceReg = /\s/;
        return spaceReg.test(word);
    }

    isNewIntentNameDuplicate(name) {
        const intents = Array.from(this.props.vm.getSpeakerIntentArray());
        return name != "" && intents.filter(intent => intent.IntentName == name).length > 0;
    }

    isEditIntentNameDuplicate(name, intentId) {
        const intents = Array.from(this.props.vm.getSpeakerIntentArray());
        return name != "" && intents.filter((intent) => intent.IntentName == name && intent.id != intentId).length > 0;
    }

    isWordAreaContainSp(word) {
        // check only letter, number and down line
        if (!word || word == "") return false;
        let invalid = false;
        let spReg = /^[A-Za-z0-9\u4e00-\u9fa5]$/;
        for (let i = 0; i < word.length; i++) {
            if (!spReg.test(word[i])) {
                invalid = true;
            }
        }
        return invalid;
    }

    isSentenceContainSp(word, wordIndex, sentenceIndex, sentenceType) {
        let invalid = false;
        if (this.isWordAreaContainSp(word)) {
            invalid = true;
        }
        let sentence = null;
        if (sentenceType == CREATE_SENTENCE_TYPE.INTENT) {
            sentence = this.state.intentSentence;
        } else {
            sentence = this.state.responseSentence;
        }
        // check only letter, number and down line
        let spReg = /^[A-Za-z0-9\u4e00-\u9fa5]$/;

        for (let x = 0; x < sentence.length; x++) {
            for (let y = 0; y < sentence[x].length; y++) {
                if (x == sentenceIndex && y == wordIndex) {
                    continue;
                }
                if (/^<\S+:var\S+:concept-\S+:?>/.test(sentence[x][y])) {
                    continue;
                }
                for (let i = 0; i < sentence[x][y].length; i++) {
                    if (!spReg.test(sentence[x][y][i])) {
                        invalid = true;
                    }
                }
            }
        }
        if (sentenceType == CREATE_SENTENCE_TYPE.INTENT) {
            if (invalid) {
                this.setState({
                    intentWarning: true
                })
            } else {
                this.setState({
                    intentWarning: false
                })
            }
        }
        if (sentenceType == CREATE_SENTENCE_TYPE.RESPONSE) {
            if (invalid) {
                this.setState({
                    responseWarning: true
                })
            } else {
                this.setState({
                    responseWarning: false
                })
            }
        }
        return invalid;
    }

    render() {
        return (
            <div className={classNames(dialogStyles.backdropStyle)}>
                <div className={classNames(dialogStyles.floatStyle)}>
                    <div className={classNames(styles.customizingDialogStyle)}
                        style={{ width: this.state.width + "px", height: this.state.height + "px" }}>
                        <div
                            className={classNames(dialogStyles.helpHeader, styles.customizingTitle)}
                            style={{ height: TITLE_HEIGHT + "px", padding: 0 + "px" }}
                        >
                            <div className={classNames(styles.speakerTitle)}
                                style={{ height: TITLE_HEIGHT + "px" }}>
                                {this.props.action == SPEAKER_DIALOG_ACTION.add ?
                                    <FormattedMessage
                                        id={"gui.dialog.speaker.customizing.title.add"}
                                    />
                                    : <FormattedMessage
                                        id={"gui.dialog.speaker.customizing.title.edit"}
                                    />}

                            </div>
                        </div>
                        <div className={classNames(styles.customizingGroupArea)}>
                            <div className={classNames(getLocale() != "en" ? styles.customizingGroupTitle : styles.customizingGroupTitleEN)}>
                                <FormattedMessage
                                    id={"gui.dialog.speaker.customizing.group"}
                                />
                            </div>
                            <div className={classNames(styles.customizingGroupSelected)}
                                onClick={() => this.onClickGroup()}>
                                <div className={classNames(styles.customizingGroupSelectedText)}>
                                    {this.state.groupName}
                                </div>
                                <div className={classNames(styles.customizingGroupSelectedArrow)} />
                                {(this.state.isOptionExpanded == EXPAND_OPTION_TYPE.SELECT_GROUP) ?
                                    <div className={classNames(styles.customizingGroupOptionArea)}>
                                        {this.getGroupsOtion().map((group, groupIndex) => {
                                            return (groupIndex == this.getGroupsOtion().length - 1) ?
                                                <div
                                                    key={'group' + groupIndex}
                                                    onClick={() => this.onCreateNewGroup()}
                                                    className={classNames(styles.customizingGroupOption)}>
                                                    <FormattedMessage
                                                        id={"gui.dialog.speaker.group.title.add"}
                                                    />
                                                </div>
                                                : <div
                                                    key={'group' + groupIndex}
                                                    onClick={() => this.onSelectGroup(groupIndex)}
                                                    className={classNames(styles.customizingGroupOption)}>
                                                    {group.name}
                                                </div>
                                        })}
                                    </div> : null}
                            </div>
                            <div className={classNames(styles.customizingGroupContent)}>
                                <FormattedMessage
                                    id={"gui.dialog.speaker.customizing.group.content"}
                                />
                            </div>
                        </div>
                        {this.getContent()}
                        <div className={classNames(styles.customizingButtonArea)}>
                            <div className={classNames(styles.cancelButton, styles.customizingCancel)}
                                onClick={() => this.onClickCancel()}>
                                <FormattedMessage
                                    id={'gui.deviceManagerStage.page.cancel'}
                                />
                            </div>
                            <div className={classNames(styles.confirmButton, styles.customizingConfirm,
                                (!this.isConfirmEnable()) ? styles.disableConfirm : null)}
                                onClick={() => {
                                    (this.isConfirmEnable()) ? this.onClickConfirm() : null
                                }
                                }>
                                <FormattedMessage
                                    id={"gui.dialog.confirm"}
                                />
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        );
    }
}

const IntentSettings = (props) => {
    const {
        onNameChange,
        state,
        onClickCreatWord,
        onCreateConcept,
        onCreateCommon,
        onWordChange,
        onWordInputFocus,
        isWordInputFocus,
        onConceptOptionFocus,
        isConceptOptionFocus,
        onSelectConcept,
        onCreateNewConcept,
        isIntentNameDuplicate,
        isIntentNameContainSpecialWord,
        onSentenceEdit,
        isSentenceEditMode,
        onRemoveComponent,
        sentenceType,
        isIntentCreatedConcept,
        conceptOption,
        vmConceptOption,
        clearSentenceEditMode,
        uiStyle,
        isWordAreaContainSp
    } = props
    var scrollDiv = null;
    function ref(div) {
        scrollDiv = div
    }
    return <div className={classNames(styles.customizingHalfContentArea, styles.customizingIntentArea)}>
        <div className={classNames(styles.customizingHalfTitle)}>
            <FormattedMessage
                id={"gui.dialog.speaker.customizing.intent.title"}
            />
        </div>
        <div className={classNames(styles.customizingItemDot)} />
        <div className={classNames(styles.customizingItemTitle)}
            style={{ maxWidth: '65px' }}>
            <FormattedMessage
                id={"gui.dialog.speaker.customizing.intent.name.title"}
            />
        </div>
        <div className={classNames(styles.customizingInputSpeakerArea,
            (isIntentNameDuplicate() || isIntentNameContainSpecialWord()) ? styles.customizingInputSpeakerError : null)}>
            <input
                type="text"
                name="intent"
                autoComplete="off"
                value={state.intentName}
                placeholder={EditUtils.getLocaleString("gui.dialog.speaker.concept.enter.intention")}
                onChange={e => onNameChange(e)}
                className={classNames(styles.customizingInputSpeaker)}
            />
        </div>

        <div className={classNames(styles.customizingInputReminder)}>
            {isIntentNameDuplicate() ?
                <FormattedMessage
                    id={"gui.dialog.speaker.customizing.intent.name.reminder.duplicate"}
                /> : null}
            {isIntentNameContainSpecialWord() ?
                <FormattedMessage
                    id={"gui.dialog.speaker.concept.name.reminder.blank.word"}
                /> : null}
        </div>


        <div className={classNames(styles.customizingItemDot)} />
        <div className={classNames(styles.customizingItemTitle, styles.innerTitle)}>
            <FormattedMessage
                id={"gui.dialog.speaker.customizing.intent.sentence.title"}
            />
            <div className={classNames(styles.inputCommonWordAreaInputReminder)}>
                {state.intentWarning ?
                    <FormattedMessage
                        id={"gui.dialog.speaker.concept.name.reminder.special.word"}
                    /> : null}
            </div>
        </div>
        <div className={classNames(styles.customizingItemContent)}>
            <FormattedMessage
                id={"gui.dialog.speaker.customizing.intent.sentence.content"}
            />
        </div>
        <div className={classNames(styles.customizingIntentSentenceArea)} ref={ref}>
            <SentenceList
                sentenceList={state.intentSentence}
                editIndex={state.editIntentIndex}
                onSelectConcept={(conceptIndex, wordIndex, sentenceIndex) => onSelectConcept(conceptIndex, wordIndex, sentenceIndex)}
                onCreateNewConcept={() => onCreateNewConcept()}
                onClickCreatWord={(sentenceIndex) => onClickCreatWord(sentenceIndex)}
                onCreateConcept={(sentenceIndex) => onCreateConcept(sentenceIndex)}
                onCreateCommon={(sentenceIndex) => onCreateCommon(sentenceIndex)}
                onWordChange={(e, wordIndex, sentenceIndex) => { onWordChange(e, wordIndex, sentenceIndex) }}
                onWordInputFocus={(wordIndex, sentenceIndex) => onWordInputFocus(wordIndex, sentenceIndex)}
                isWordInputFocus={(wordIndex, sentenceIndex) => isWordInputFocus(wordIndex, sentenceIndex)}
                onConceptOptionFocus={(selector, wordIndex, sentenceIndex) => onConceptOptionFocus(selector, scrollDiv, wordIndex, sentenceIndex)}
                isConceptOptionFocus={(wordIndex, sentenceIndex) => isConceptOptionFocus(wordIndex, sentenceIndex)}
                onSentenceEdit={(sentenceIndex) => onSentenceEdit(sentenceIndex)}
                isSentenceEditMode={(sentenceIndex) => isSentenceEditMode(sentenceIndex)}
                onRemoveComponent={(wordIndex, sentenceIndex) => onRemoveComponent(wordIndex, sentenceIndex)}
                sentenceType={sentenceType}
                isIntentCreatedConcept={isIntentCreatedConcept}
                conceptOption={conceptOption}
                vmConceptOption={vmConceptOption}
                clearSentenceEditMode={clearSentenceEditMode}
                uiStyle={uiStyle}
                isWordAreaContainSp={(word) => isWordAreaContainSp(word)}
                dropdown={state.dropdown}
            />
        </div>
    </div>
}

const getLocale = () => {
    return store.get("locale", DEFAULT_LOCALE);
}

const ResponseSettings = (props) => {
    const {
        state,
        onClickCreatWord,
        onCreateConcept,
        onCreateCommon,
        onWordChange,
        onWordInputFocus,
        isWordInputFocus,
        onConceptOptionFocus,
        isConceptOptionFocus,
        onSelectConcept,
        onCreateNewConcept,
        onSentenceEdit,
        isSentenceEditMode,
        onRemoveComponent,
        sentenceType,
        isIntentCreatedConcept,
        conceptOption,
        vmConceptOption,
        clearSentenceEditMode,
        uiStyle,
        isWordAreaContainSp
    } = props
    var scrollDiv = null;
    function ref(div) {
        scrollDiv = div
    }
    return <div className={classNames(styles.customizingHalfContentArea, styles.customizingResponseArea)}>
        <div className={classNames(styles.customizingHalfTitle)}>
            <FormattedMessage
                id={"gui.dialog.speaker.customizing.response"}
            />
        </div>
        <div className={classNames(styles.customizingItemDot)} />
        <div className={classNames(styles.customizingItemTitle, styles.innerTitle)}>
            <FormattedMessage
                id={"gui.dialog.speaker.customizing.response.sentence.title"}
            />
            <div className={classNames(styles.inputCommonWordAreaInputReminder)}>
                {state.responseWarning ?
                    <FormattedMessage
                        id={"gui.dialog.speaker.concept.name.reminder.special.word"}
                    /> : null}
            </div>
        </div>
        <div className={classNames(styles.customizingItemContent)}>
            <FormattedMessage
                id={"gui.dialog.speaker.customizing.response.sentence.content"}
            />
        </div>
        <div className={classNames(styles.customizingResponseSentenceArea)} ref={ref}>
            <SentenceList
                sentenceList={state.responseSentence}
                editIndex={state.editResponseIndex}
                onClickCreatWord={(sentenceIndex) => onClickCreatWord(sentenceIndex)}
                onCreateConcept={(sentenceIndex) => onCreateConcept(sentenceIndex)}
                onCreateCommon={(sentenceIndex) => onCreateCommon(sentenceIndex)}
                onWordChange={(e, wordIndex, sentenceIndex) => { onWordChange(e, wordIndex, sentenceIndex) }}
                onWordInputFocus={(wordIndex, sentenceIndex) => onWordInputFocus(wordIndex, sentenceIndex)}
                isWordInputFocus={(wordIndex, sentenceIndex) => isWordInputFocus(wordIndex, sentenceIndex)}
                onConceptOptionFocus={(selector, wordIndex, sentenceIndex) => onConceptOptionFocus(selector, scrollDiv, wordIndex, sentenceIndex)}
                isConceptOptionFocus={(wordIndex, sentenceIndex) => isConceptOptionFocus(wordIndex, sentenceIndex)}
                onSelectConcept={(conceptIndex, wordIndex, sentenceIndex) => onSelectConcept(conceptIndex, wordIndex, sentenceIndex)}
                onCreateNewConcept={() => onCreateNewConcept()}
                onSentenceEdit={(sentenceIndex) => onSentenceEdit(sentenceIndex)}
                isSentenceEditMode={(sentenceIndex) => isSentenceEditMode(sentenceIndex)}
                onRemoveComponent={(wordIndex, sentenceIndex) => onRemoveComponent(wordIndex, sentenceIndex)}
                sentenceType={sentenceType}
                isIntentCreatedConcept={isIntentCreatedConcept}
                conceptOption={conceptOption}
                vmConceptOption={vmConceptOption}
                clearSentenceEditMode={clearSentenceEditMode}
                uiStyle={uiStyle}
                isWordAreaContainSp={(word) => isWordAreaContainSp(word)}
                dropdown={state.dropdown}
            />
        </div>
    </div>
}

const SentenceList = (props) => {
    const {
        sentenceList,
        editIndex,
        onClickCreatWord,
        onCreateConcept,
        onCreateCommon,
        onWordChange,
        onWordInputFocus,
        isWordInputFocus,
        onConceptOptionFocus,
        isConceptOptionFocus,
        onSelectConcept,
        onCreateNewConcept,
        onSentenceEdit,
        isSentenceEditMode,
        onRemoveComponent,
        sentenceType,
        isIntentCreatedConcept,
        conceptOption,
        vmConceptOption,
        clearSentenceEditMode,
        uiStyle,
        isWordAreaContainSp,
        dropdown
    } = props
    var firstEmptyIndex = -1
    for (let i = 0; i < sentenceList.length; i++) {
        if (!sentenceList[i] || sentenceList[i].length == 0) {
            firstEmptyIndex = i;
            break;
        }
    }
    return sentenceList.map((sentence, sentenceIndex) => {
        return <div key={'intent' + sentenceIndex}>
            {(sentence.length == 0) ?
                <EmptySentence
                    enable={firstEmptyIndex == sentenceIndex}
                    active={editIndex == sentenceIndex}
                    onClickCreatWord={() => onClickCreatWord(sentenceIndex)}
                    onCreateConcept={() => onCreateConcept(sentenceIndex)}
                    onCreateCommon={() => onCreateCommon(sentenceIndex)}
                    isIntentCreatedConcept={sentenceType == CREATE_SENTENCE_TYPE.INTENT ? true : isIntentCreatedConcept}
                    isFinalSentence={sentenceIndex == sentenceList.length - 1}
                    sentenceType={sentenceType} />
                :
                <div className={classNames(styles.relative)}>
                    <Sentence
                        sentence={[].concat(sentence)}
                        onWordChange={(e, wordIndex) => { onWordChange(e, wordIndex, sentenceIndex) }}
                        onSelectConcept={(conceptIndex, wordIndex) => onSelectConcept(conceptIndex, wordIndex, sentenceIndex)}
                        onCreateNewConcept={() => onCreateNewConcept()}
                        onClickCreatWord={() => onClickCreatWord(sentenceIndex)}
                        active={editIndex == sentenceIndex}
                        onCreateConcept={() => onCreateConcept(sentenceIndex)}
                        onCreateCommon={() => onCreateCommon(sentenceIndex)}
                        onWordInputFocus={(wordIndex) => onWordInputFocus(wordIndex, sentenceIndex)}
                        isWordInputFocus={(wordIndex) => isWordInputFocus(wordIndex, sentenceIndex)}
                        onConceptOptionFocus={(selector, wordIndex) => onConceptOptionFocus(selector, wordIndex, sentenceIndex)}
                        isConceptOptionFocus={(wordIndex) => isConceptOptionFocus(wordIndex, sentenceIndex)}
                        isSentenceEditMode={() => isSentenceEditMode(sentenceIndex)}
                        onRemoveComponent={(wordIndex) => onRemoveComponent(wordIndex, sentenceIndex)}
                        isIntentCreatedConcept={sentenceType == CREATE_SENTENCE_TYPE.INTENT ? true : isIntentCreatedConcept}
                        sentenceType={sentenceType}
                        sentenceIndex={sentenceIndex}
                        conceptOption={conceptOption}
                        vmConceptOption={vmConceptOption}
                        isFinalSentence={sentenceIndex == sentenceList.length - 1}
                        isWordAreaContainSp={(word) => isWordAreaContainSp(word)}
                        dropdown={dropdown}
                    />
                    {!isSentenceEditMode(sentenceIndex) ?
                        <div className={classNames(styles.editImgPosition)}
                            onClick={() => onSentenceEdit(sentenceIndex)}>
                            <img src={editImg} alt={"edit"} />
                        </div>
                        : <div className={classNames(styles.editImgPosition)}
                            onClick={() => clearSentenceEditMode()}>
                            <img src={uiStyle == uiType.ww ? checkWWIcon : checkIcon} alt={"check"} />
                        </div>}
                </div>
            }
        </div>
    })
}


const EmptySentence = (props) => {
    const {
        enable,
        active,
        onClickCreatWord,
        onCreateConcept,
        onCreateCommon,
        isIntentCreatedConcept,
        isFinalSentence,
        sentenceType
    } = props
    return <div className={classNames(styles.relative)}>
        <div className={classNames(styles.customizingCreateSentence,
            (active) ? styles.active :
                (enable) ? styles.enable : styles.disable)}
            onClick={() => (enable) ? onClickCreatWord() : null}>
            +
        </div>
        {active ?
            <div className={classNames(styles.createWordOptionAreaNewPosition, isFinalSentence ? styles.createWordOptionAreaNewUpPosition : null)}>
                <CreateSentenceOption
                    enableConcept={true}
                    onCreateConcept={onCreateConcept}
                    onCreateCommon={onCreateCommon}
                    isIntentCreatedConcept={isIntentCreatedConcept}
                    sentenceType={sentenceType}
                />
            </div>
            : null}
    </div>
}

const CreateSentenceOption = (props) => {
    const {
        enableConcept,
        onCreateConcept,
        onCreateCommon,
        isIntentCreatedConcept,
        sentenceType
    } = props
    return <div className={classNames(styles.createWordOptionArea)}>
        <div className={classNames(styles.createWordOption)}
            style={{ lineHeight: (getLocale() == 'en') ? "16px" : "32px" }}
            onClick={() => onCreateCommon()}>
            <FormattedMessage
                id={"gui.dialog.speaker.customizing.add.common"}
            />
        </div>
        {(!isIntentCreatedConcept) ?
            <div className={classNames(styles.createWordOptionDisable)}>
                <div>
                    <FormattedMessage id={"gui.dialog.speaker.concept.response.heard.concept"} />
                </div>
                <div style={{ fontSize: (getLocale() == 'en') ? "7px" : "10px" }}>
                    <FormattedMessage id={"gui.dialog.speaker.concept.response.heard.limit"} />
                </div>
            </div>
            : (enableConcept)
                ? <div className={classNames(styles.createWordOption)}
                    onClick={() => onCreateConcept()}>
                    {(sentenceType == CREATE_SENTENCE_TYPE.INTENT) ?
                        <FormattedMessage
                            id={"gui.dialog.speaker.customizing.add.concept"}
                        /> :
                        <FormattedMessage
                            id={"gui.dialog.speaker.concept.response.heard.concept"}
                        />
                    }
                </div>
                : <div className={classNames(styles.createWordOptionDisable)}>
                    <div><FormattedMessage
                        id={"gui.dialog.speaker.customizing.add.concept"}
                    />
                    </div>
                    <div style={{ fontSize: (getLocale() == 'en') ? "7px" : "10px" }}><FormattedMessage
                        id={"gui.dialog.speaker.customizing.add.concept.limit"}
                    />
                    </div>
                </div>}

    </div>
}

const Sentence = (props) => {
    const {
        sentence,
        onWordChange,
        onSelectConcept,
        onCreateNewConcept,
        onClickCreatWord,
        active,
        onCreateConcept,
        onCreateCommon,
        onWordInputFocus,
        isWordInputFocus,
        onConceptOptionFocus,
        isConceptOptionFocus,
        isSentenceEditMode,
        onRemoveComponent,
        isIntentCreatedConcept,
        sentenceType,
        sentenceIndex,
        conceptOption,
        vmConceptOption,
        isFinalSentence,
        isWordAreaContainSp,
        dropdown
    } = props
    const enableConcept = sentenceType == CREATE_SENTENCE_TYPE.RESPONSE
        || (sentenceType == CREATE_SENTENCE_TYPE.INTENT
            && sentence.filter(word => word && word.includes(conceptKey)).length < CONCEPT_IN_INTENT_SENTENCE_LIMIT)
    return <div className={classNames(styles.customizingSentence)}>
        {sentence.map((word, wordIndex) => {
            if (word.includes(conceptKey)) {
                return <Concept
                    key={'concept' + wordIndex}
                    word={word}
                    onConceptOptionFocus={(selector) => onConceptOptionFocus(selector, wordIndex)}
                    isConceptOptionFocus={() => isConceptOptionFocus(wordIndex)}
                    onSelectConcept={(conceptIndex) => onSelectConcept(conceptIndex, wordIndex)}
                    onCreateNewConcept={() => onCreateNewConcept()}
                    isSentenceEditMode={() => isSentenceEditMode()}
                    onRemoveComponent={() => onRemoveComponent(wordIndex)}
                    isIntentCreatedConcept={isIntentCreatedConcept}
                    sentenceType={sentenceType}
                    conceptOption={conceptOption}
                    vmConceptOption={vmConceptOption}
                    dropdown={dropdown}
                />
            } else {
                return <CommonWord
                    key={'common' + wordIndex}
                    word={word}
                    onWordChange={(e) => onWordChange(e, wordIndex)}
                    onWordInputFocus={() => onWordInputFocus(wordIndex)}
                    isWordInputFocus={() => isWordInputFocus(wordIndex)}
                    isSentenceEditMode={() => isSentenceEditMode()}
                    onRemoveComponent={() => onRemoveComponent(wordIndex)}
                    isWordAreaContainSp={(word) => isWordAreaContainSp(word)}
                    id={'word-' + sentenceType + '-' + sentenceIndex + '-' + wordIndex}
                />
            }
        })}
        {isSentenceEditMode() ? null : <div className={classNames(styles.appendWord)}
            onClick={() => onClickCreatWord()}
        >+
            {active ?
                <div className={classNames(
                    sentenceType == CREATE_SENTENCE_TYPE.INTENT ? styles.createWordOptionAreaAppendPositionIntent : styles.createWordOptionAreaAppendPositionResponse, isFinalSentence ? styles.createWordOptionAreaAppendUpPosition : null)}>
                    <CreateSentenceOption
                        enableConcept={enableConcept}
                        onCreateConcept={onCreateConcept}
                        onCreateCommon={onCreateCommon}
                        isIntentCreatedConcept={isIntentCreatedConcept}
                        sentenceType={sentenceType}
                    />
                </div>
                : null}
        </div>}

    </div>

}

const BASE_WORD_WIDTH = 30
const CHAR_WIDTH = 6.5

const CommonWord = (props) => {
    const {
        word,
        onWordChange,
        onWordInputFocus,
        isWordInputFocus,
        isSentenceEditMode,
        onRemoveComponent,
        isWordAreaContainSp,
        id
    } = props
    let length = 0;
    for (let i = 0; i < word.length; i++) {
        length += Math.floor((word.charCodeAt(i).toString().length) / 2)
    }
    return (!isSentenceEditMode()) ? <div className={classNames(styles.inputCommonWordArea,
        (isWordInputFocus()) ? styles.inputAreaFocus : null,
        (isWordAreaContainSp(word)) ? styles.inputSpeakerAreaError : null)}
        style={{ width: length * CHAR_WIDTH + BASE_WORD_WIDTH + "px" }}
        onClick={() => onWordInputFocus()}>
        <input
            id={id}
            type="text"
            name="intent"
            autoComplete="off"
            value={word}
            placeholder=""
            onChange={e => onWordChange(e)}
            className={classNames(styles.inputCommonWord,
                (isWordInputFocus()) ? styles.inputFocus : null)}
        />
    </div> : <div className={classNames(styles.inputCommonWordArea, styles.removeMode)}
        style={{ width: length * CHAR_WIDTH + BASE_WORD_WIDTH + "px" }}>
        <div className={classNames(styles.inputCommonWord)}>{word}</div>
        <div className={classNames(styles.removeImgBackground)} onClick={() => onRemoveComponent()} ><img src={deleteIcon} className={classNames(styles.removeImg)} /></div>
    </div>
}

const createConceptTag = "&creatConcept"

const Concept = (props) => {
    const {
        word,
        onSelectConcept,
        onConceptOptionFocus,
        isConceptOptionFocus,
        onCreateNewConcept,
        isSentenceEditMode,
        onRemoveComponent,
        isIntentCreatedConcept,
        sentenceType,
        conceptOption,
        vmConceptOption,
        dropdown
    } = props
    const reg = /[<>]/g
    let wordContent = word.replace(reg, "");
    wordContent = wordContent.split(":")
    let isConceptExist = true;
    let isIntentInConcept = isIntentCreatedConcept;
    if (wordContent[0] && wordContent[0].length > 0) {
        const vmConcept = vmConceptOption.filter(concept => wordContent[2] == concept.id);
        isConceptExist = vmConcept.length > 0
        if (isConceptExist) {
            wordContent[0] = vmConcept[0].tag
        }
        if (sentenceType == CREATE_SENTENCE_TYPE.RESPONSE) {
            isIntentInConcept = isIntentCreatedConcept && conceptOption.filter(concept => wordContent[2] == concept.id && wordContent[3].toString() == concept.order.toString()).length > 0;
        }
    }
    let option = [].concat(conceptOption);
    if (sentenceType == CREATE_SENTENCE_TYPE.INTENT) option.push({ tag: createConceptTag, id: createConceptTag })
    let dropupMargin = 0;
    if (!dropdown) {
        let length = option.length > 4 ? 5 : option.length;
        dropupMargin = (length + 1) * -32 - 2 /* padding */;
    }
    return (!isSentenceEditMode()) ? <div className={classNames(styles.conceptSelected,
        (isConceptOptionFocus()) ? styles.inputAreaFocus : null)}
        onClick={(e) => onConceptOptionFocus(e.target)}>

        {!isConceptExist || !isIntentInConcept ?
            <img src={exclamationIcon} className={classNames(styles.conceptNotExist)} />
            : null}
        <div className={classNames(styles.conceptContent, (isConceptOptionFocus()) ? styles.inputFocus : null)}>
            {sentenceType == CREATE_SENTENCE_TYPE.RESPONSE ? wordContent[3].toString() == "1" ?
                <FormattedMessage id={"gui.dialog.speaker.concept.response.heard.first"} />
                : <FormattedMessage id={"gui.dialog.speaker.concept.response.heard.second"} /> : null}
            {wordContent[0]}
        </div>
        <div className={classNames(styles.conceptSelectedArrow,
            (isConceptOptionFocus()) ? styles.conceptSelectedArrowFocus : null)} />

        {(isConceptOptionFocus()) ?
            <div className={classNames(styles.conceptOptionArea, (option.length == 0 ? styles.conceptOptionAreaDisplayNone : null))} style={{ marginTop: (!dropdown ? `${dropupMargin}px` : null) }}>
                {option.map((concept, conceptIndex) => {
                    return (conceptIndex == option.length - 1) && (sentenceType == CREATE_SENTENCE_TYPE.INTENT) ?
                        <div
                            key={'concept' + conceptIndex}
                            className={classNames(styles.conceptOption)}
                            onClick={() => onCreateNewConcept()}>
                            <FormattedMessage
                                id={"gui.dialog.speaker.concept.title.add"}
                            />
                        </div>
                        : <div
                            key={'concept' + conceptIndex}
                            className={classNames(styles.conceptOption,
                                ((sentenceType == CREATE_SENTENCE_TYPE.INTENT && wordContent[2] == concept.id)
                                    || (sentenceType == CREATE_SENTENCE_TYPE.RESPONSE && wordContent[2] == concept.id && wordContent[3] == concept.order))
                                    ? styles.conceptOptionSelected : null)}
                            onClick={() => onSelectConcept(conceptIndex)}
                        >{sentenceType == CREATE_SENTENCE_TYPE.RESPONSE ? concept.order.toString() == "1" ?
                            <FormattedMessage id={"gui.dialog.speaker.concept.response.heard.first"} />
                            : <FormattedMessage id={"gui.dialog.speaker.concept.response.heard.second"} /> : null}
                            {concept.tag}</div>
                })}
            </div>
            : null
        }
    </div > : <div className={classNames(styles.conceptSelected, styles.removeMode)}>
        {!isConceptExist || !isIntentInConcept ?
            <img src={exclamationIcon} className={classNames(styles.conceptNotExist)} />
            : null}
        <div className={classNames(styles.conceptContent)}>{sentenceType == CREATE_SENTENCE_TYPE.RESPONSE ? wordContent[3].toString() == "1" ?
            <FormattedMessage id={"gui.dialog.speaker.concept.response.heard.first"} />
            : <FormattedMessage id={"gui.dialog.speaker.concept.response.heard.second"} /> : null}
            {wordContent[0]}</div>
        <div className={classNames(styles.conceptSelectedArrow)} />
        <div className={classNames(styles.removeImgBackground)} onClick={() => onRemoveComponent()} ><img src={deleteIcon} className={classNames(styles.removeImg)} /></div>
    </div>
}
SpeakerCustomizingDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
    action: PropTypes.string.isRequired,
    intentId: PropTypes.string,
    getWorkspace: PropTypes.object
};

const mapStateToProps = state => ({
    getWorkspace: getWorkspace(state),
    isSpeakerConceptDialogShow: isSpeakerConceptDialogShow(state),
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
    hideSpeakerCustomizingDialog: () => dispatch(hideSpeakerCustomizingDialog()),
    setConceptAction: (action) => dispatch(setConceptAction(action)),
    showSpeakerConceptDialog: () => dispatch(showSpeakerConceptDialog()),
    showAddAISpeechGroupDialog: () => dispatch(showAddAISpeechGroupDialog()),
    showErrorDialog: (errorType) => dispatch(showErrorDialog(errorType))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpeakerCustomizingDialog);