import keyMirror from 'keymirror';


const DeviceStateCmd = {
    VM: "vm",
    LOADER: "loader"
}

const BRAIN_TYPE = keyMirror({
    ENTRY: 1,
    EDU: 2,
    EDU_AND_ENTRY: 3,
    WEB_VR: 4
});

export {
    BRAIN_TYPE,
    DeviceStateCmd
}