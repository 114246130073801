import PropTypes from 'prop-types';
import React from 'react';
import Modal from '../../containers/modal.jsx';
import Box from '../box/box.jsx';
import { defineMessages, injectIntl, intlShape, FormattedMessage } from 'react-intl';

import booleanInputIcon from './icon--boolean-input.svg';
import numberInputIcon from './icon--number-input.svg';
import wordInputIcon from './icon--word-input.svg';
import classNames from 'classnames';
import styles from './custom-procedures.css';

const messages = defineMessages({
    myblockModalTitle: {
        defaultMessage: 'Make a Block',
        description: 'Title for the modal where you create a custom block.',
        id: 'gui.customProcedures.myblockModalTitle'
    }
});

const getLabelIconSvg = () => (
    // import from'./icon--label.svg';
    <svg width="56px" height="48px" viewBox="0 0 56 48" version="1.1" className={styles.optionIcon}>
        <defs>
            <linearGradient x1="50%" y1="3.04933147%" x2="50%" y2="96.2559615%" id="linearGradient-1">
                <stop stopColor="#C5C6FA" offset="0%"></stop>
                <stop stopColor="#6775C4" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1-6-2-4_new" transform="translate(-1143.000000, -576.000000)">
                <g id="3" transform="translate(1143.000000, 576.000000)">
                    <rect id="Rectangle-Copy-4" fill="url(#linearGradient-1)" x="0" y="0" width="56" height="48" rx="4"></rect>
                    <rect id="Rectangle-Copy-7" fill="#FFFFFF" x="10" y="10" width="36" height="28" rx="2.7"></rect>
                    <text id="text" className={styles.textFont} fontSize="14" fontWeight="400" fill="#7B7B7B">
                        <tspan x="16.0678711" y="28">text</tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>
)

const CustomProcedures = props => (
    <Modal
        className={styles.modalContent}
        contentLabel={props.intl.formatMessage(messages.myblockModalTitle)}
        // onRequestClose={props.onCancel}
        isCustomProcedure={props.isCustomProcedure}
        uis={props.uis}
    >
        <Box
            className={styles.workspace}
            componentRef={props.componentRef}
        />
        <Box className={styles.body}>
            <div className={styles.optionsRow}>
                <div
                    className={classNames(styles.optionCard, (props.showWarningText) ? styles.optionCardDisabled : null)}
                    role="button"
                    tabIndex="0"
                    onClick={props.onAddNumber}
                >
                    <img
                        className={styles.optionIcon}
                        src={numberInputIcon}
                    />
                    <div className={styles.optionTitle}>
                        <FormattedMessage
                            defaultMessage="Add an number input"
                            description="Label for button to add a number input"
                            id="gui.customProcedures.addAnInputNumber"
                        />
                    </div>
                </div>
                <div
                    className={classNames(styles.optionCard, (props.showWarningText) ? styles.optionCardDisabled : null)}
                    role="button"
                    tabIndex="0"
                    onClick={props.onAddText}
                >
                    <img
                        className={styles.optionIcon}
                        src={wordInputIcon}
                    />
                    <div className={styles.optionTitle}>
                        <FormattedMessage
                            defaultMessage="Add an text input"
                            description="Label for button to add a text input"
                            id="gui.customProcedures.addAnInputText"
                        />
                    </div>
                </div>
                <div
                    className={classNames(styles.optionCard, (props.showWarningText) ? styles.optionCardDisabled : null)}
                    role="button"
                    tabIndex="0"
                    onClick={props.onAddBoolean}
                >
                    <img
                        className={styles.optionIcon}
                        src={booleanInputIcon}
                    />
                    <div className={styles.optionTitle}>
                        <FormattedMessage
                            defaultMessage="Add an input"
                            description="Label for button to add a boolean input"
                            id="gui.customProcedures.addAnInputBoolean"
                        />
                    </div>
                </div>
                <div
                    className={classNames(styles.optionCard, (props.showWarningText) ? styles.optionCardDisabled : null)}
                    role="button"
                    tabIndex="0"
                    onClick={props.onAddLabel}
                >
                    {getLabelIconSvg()}
                    <div className={styles.optionTitle}>
                        <FormattedMessage
                            defaultMessage="Add a label"
                            description="Label for button to add a label"
                            id="gui.customProcedures.addALabel"
                        />
                    </div>
                </div>
            </div>
            <Box className={styles.buttonRow}>
                <button
                    className={styles.buttonWhite}
                    onClick={props.onCancel}
                >
                    <FormattedMessage
                        defaultMessage="Cancel"
                        description="Label for button to cancel custom procedure edits"
                        id="gui.customProcedures.cancel"
                    />
                </button>
                <button
                    className={classNames(styles.button, (props.showWarningText) ? styles.disabled : null)}
                    onClick={props.onOk}
                >
                    <FormattedMessage
                        defaultMessage="OK"
                        description="Label for button to save new custom procedure"
                        id="gui.customProcedures.ok"
                    />
                </button>
            </Box>
        </Box>
    </Modal>
);

CustomProcedures.propTypes = {
    componentRef: PropTypes.func.isRequired,
    intl: intlShape,
    onAddBoolean: PropTypes.func.isRequired,
    onAddLabel: PropTypes.func.isRequired,
    onAddNumber: PropTypes.func.isRequired,
    onAddText: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    onToggleWarp: PropTypes.func.isRequired,
    warp: PropTypes.bool.isRequired,
    isCustomProcedure: PropTypes.bool.isRequired,
    showWarningText: PropTypes.string,
    uis: PropTypes.string,
};

export default injectIntl(CustomProcedures);
