const colourCN = {
    colours: {
        motion: {
            primary: "motion",
            secondary: "#92A73F",
            tertiary: "#92A73F",
            quaternary: "#92A73F"
        },
        looks: {
            primary: "looks",
            secondary: "#149AEE",
            tertiary: "#149AEE",
            quaternary: "#149AEE"
        },
        sounds: {
            primary: "sound",
            secondary: "#049394",
            tertiary: "#049394",
            quaternary: "#049394"
        },
        control: {
            primary: "control",
            secondary: "#E2A817",
            tertiary: "#E2A817",
            quaternary: "#E2A817"
        },
        event: {
            primary: "events",
            secondary: "#1876BE",
            tertiary: "#1876BE",
            quaternary: "#1876BE"
        },
        sensing: {
            primary: "sensing",
            secondary: "#BF6635",
            tertiary: "#BF6635",
            quaternary: "#BF6635"
        },
        pen: {
            primary: "#0fBD8C",
            secondary: "#0DA57A",
            tertiary: "#0B8E69",
            quaternary: "#0B8E69"
        },
        operators: {
            primary: "operators",
            secondary: "#A67E5E",
            tertiary: "#A67E5E",
            quaternary: "#A67E5E"
        },
        comments: {
            primary: "comments",
            secondary: "#EB3153",
            tertiary: "#EB3153",
            quaternary: "#EB3153"
        },
        data: {
            primary: "variables",
            secondary: "#8E44CB",
            tertiary: "#8E44CB",
            quaternary: "#8E44CB"
        },
        drivetrain: {
            primary: "drivetrain",
            secondary: "#179871",
            tertiary: "#179871",
            quaternary: "#179871"
        },
        // This is not a new category, but rather for differentiation
        // between lists and scalar variables.
        data_lists: {
            primary: "variables",
            secondary: "#8E44CB",
            tertiary: "#8E44CB",
            quaternary: "#8E44CB"
        },
        // my Block
        more: {
            primary: "myBlock",
            secondary: "#5762A8",
            tertiary: "#5762A8",
            quaternary: "#5762A8"
        },
        ai_speech: {
            primary: "aiSpeech",
            secondary: "#DE5C8C",
            tertiary: "#DE5C8C",
            quaternary: "#DE5C8C"
        },
        extensions: {
            primary: "extensions",
            secondary: "#CF3128",
            tertiary: "#CF3128",
            quaternary: "#CF3128"
        },
        toolboxHover: "#4C97FF",
        categoryText: "#FFFFFF",
    }
}

export default colourCN;