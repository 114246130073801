import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styles from '../dialog.css';
import wifiStyles from './wifi-dialog.css';

import {
    isPad
} from '../../../lib/platform'
import {
    isWifiDirectionDialogShow,
    hideWifiDirectionDialog
} from '../../../reducers/dialog';

import direction1 from './img/direction1.png';
import direction2 from './img/direction2.png';
import direction3 from './img/direction3.png';

class WifiDirectionDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }
    render() {
        // Render nothing if the "show" prop is false
        if (!this.props.show) {
            return null;
        }
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle, styles.wifiDirection)}>
                    <div className={styles.wifiHeader}>
                        <div className={styles.wifiTitle}>
                            <FormattedMessage
                                defaultMessage="no find master"
                                description="no find master"
                                id="gui.firmware.brain.ap.not.found"
                            />
                        </div>
                    </div>
                    <div className={wifiStyles.wifiDirectionContent}>
                        <div className={wifiStyles.wifiDirectionContentTop}>
                            <div className={wifiStyles.wifiDirectionContentItem}>
                                <img src={direction1} className={wifiStyles.wifiDirectionImg} />
                                <div>
                                    <FormattedMessage
                                        defaultMessage="direction1"
                                        description="direction1"
                                        id="gui.dialog.wifi.direction1"
                                    />
                                </div>
                            </div>
                            <div className={classNames(wifiStyles.wifiDirectionContentItem, wifiStyles.center)}>

                                {isPad() ? <div>
                                    <img src={direction2} className={wifiStyles.wifiDirectionImg} />
                                    <div>
                                        <FormattedMessage
                                            defaultMessage="direction2"
                                            description="direction2"
                                            id="gui.dialog.wifi.direction2"
                                        />
                                    </div>
                                </div> : <div>
                                    <img src={direction2} className={wifiStyles.wifiDirectionImg} />
                                    <div>
                                        <FormattedMessage
                                            defaultMessage="direction2"
                                            description="direction2"
                                            id="gui.dialog.wifi.direction2.pc"
                                        />
                                    </div>
                                </div>}

                            </div>
                            <div className={wifiStyles.wifiDirectionContentItem}>
                                <img src={direction3} className={wifiStyles.wifiDirectionImg} />
                                <div>
                                    <FormattedMessage
                                        defaultMessage="direction3"
                                        description="direction3"
                                        id="gui.dialog.wifi.direction3"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={wifiStyles.wifiDirectionContentLine}></div>
                        <div className={wifiStyles.wifiDirectionContentBottom}>
                            <FormattedMessage
                                defaultMessage="direction4"
                                description="direction4"
                                id="gui.dialog.wifi.direction4"
                            />
                        </div>
                    </div>

                    <div className={classNames(wifiStyles.wifiDirectionButton, wifiStyles.wifiDirectionCenter)} onClick={this.props.closeDialog}>
                        <FormattedMessage
                            defaultMessage="confirm"
                            description="confirm"
                            id="gui.dialog.confirm"
                        />
                    </div>
                </div>
            </div >
        );
    }
}

WifiDirectionDialog.propTypes = {
    show: PropTypes.bool,
    closeDialog: PropTypes.func,
};

const mapStateToProps = state => ({
    show: isWifiDirectionDialogShow(state),
});

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(hideWifiDirectionDialog()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WifiDirectionDialog);