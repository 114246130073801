const UPDATE_RECENTFILEPATHS = 'scratch-gui/recentfile-path/UPDATE_RECENTFILEPATHS';

const initialState = {
    paths: [],
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case UPDATE_RECENTFILEPATHS:
            return Object.assign({}, state, {
                paths: action.paths
            });
        default:
            return state;
    }
};

const updateRecentFilePaths = function (pathArray) {
    return {
        type: UPDATE_RECENTFILEPATHS,
        paths: pathArray
    };
};

export {
    reducer as default,
    initialState as recentFilePathsInitialState,
    updateRecentFilePaths
};
