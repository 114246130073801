import classNames from 'classnames';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styles from './user-guide.css';
import { isShowUserGuide, getUserGuideCurrentState, updateShowUserGuide, updateUserGuideState } from '../../reducers/user-guide';
import store from 'store';
import pathArrowRightImg from './svg/arrow-right.svg';
import pathArrowLeftImg from './svg/arrow-left.svg';
import dropDownListImg from './svg/drop-down-list.svg';
import dropDownListHoverImg from './svg/drop-down-list-hover.svg';
import dropDownListEntryImg from './svg/drop-down-list-entry.svg';
import dropDownListHoverEntryImg from './svg/drop-down-list-hover-entry.svg';
import dropDownListWWImg from './svg/drop-down-list-ww.svg';
import dropDownListHoverWWImg from './svg/drop-down-list-hover-ww.svg';
import dropDownListEntryWWImg from './svg/drop-down-list-entry-ww.svg';
import dropDownListHoverEntryWWImg from './svg/drop-down-list-hover-entry-ww.svg';
import blockThreeWireMotorContourEn from './svg/en/block_three_wire_spin_contour.svg'
import blockThreeWireMotorContourZh from './svg/zh-cn/block_three_wire_spin_contour.svg'
import blockMotionSpinContourEn from './svg/en/block_motion_spin_contour.svg'
import blockMotionSpinContourZh from './svg/zh-cn/block_motion_spin_contour.svg'
import blockWaitContourEn from './svg/en/block_wait_contour.svg'
import blockWaitContourZh from './svg/zh-cn/block_wait_contour.svg'
import MessageBox from './message-box/message-box.jsx'
import Animation from './animation/animation.jsx';

import { ANIMATION_TYPE } from './animation/animation-type';
import { USER_GUIDE_STATE } from '../../lib/user-guide-state'
import { updateToolboxVisible, updateFlyoutVisible } from '../../reducers/toolbox';
import { setStageSize } from '../../reducers/stage-size';
import { STAGE_SIZE_MODES, STAGE_DISPLAY_TYPE } from '../../lib/layout-constants';
import { DEFAULT_LOCALE } from '../../config/project-config';
import {
    openAddPage,
    closeAddPage,
    closeDeviceList,
} from '../../reducers/device-manager-controller';

import {
    cleanPortOption,
} from '../../reducers/select-option';

const arrowPadding = 20;
const blockWaitImgWidth = 101;

import {
    getBrainRunningStatus,
    getBrainConnectStatus,
    getBrainInfo,
    getBrainStatus,
    getAllPortInfo,
    getBrainVer,
} from '../../reducers/brain';

import {
    closeBrainInfoMenu,
} from '../../reducers/menus';

import {
    getWorkspace
} from '../../reducers/block';
import {
    isPickedBrainType,
    getPickedBrainType,
    isEnableWebVR
} from '../../reducers/picked-brain-type'
import {
    getUnityMessageFunc,
    getVRUnityContextLoadingProgress
} from '../../reducers/vr';

import { BRAIN_TYPE } from '../../lib/brains';
import BrainInfoMobile from '../menu-bar/brain-info-mobile.jsx';
import BrainInfo from '../menu-bar/brain-info.jsx';

import {
    platformType,
    isPad,
    getPlatform
} from '../../lib/platform';

import { uiType } from '../../reducers/ui-style';
import log from '../../lib/log.js';

class UserGuide extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleConnectComPortPath',
        ]);
        this.state = {
            windowWidth: window.innerWidth,
            blockWaitContourImg: blockWaitContourZh,
            blockThreeWireMotorContourImg: blockThreeWireMotorContourZh,
            blockMotionSpinImg: blockMotionSpinContourZh,
            dropDownListImgSrc: dropDownListImg,
            dropDownListImgEntrySrc: dropDownListEntryImg,
            dropDownListImgWWSrc: dropDownListWWImg,
            dropDownListImgEntryWWSrc: dropDownListEntryWWImg,
        }
    }

    componentDidMount() {
        log.info("UserGuide componentDidMount");
        this.initUserGuideBlocksandImg();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isPickedBrainType && !prevProps.isPickedBrainType) {
            this.initUserGuide();
        }

        if (!this.props.isEnableWebVR && prevProps.isEnableWebVR) {
            this.initUserGuide();
        }

        if (prevProps.getVRUnityContextLoadingProgress < 1 && !this.props.getVRUnityContextLoadingProgress < 1) {
            this.initUserGuide();
        }

        if (this.props.currentState == USER_GUIDE_STATE.DELETE_BLOCK_DRAGGING) {
            this.props.getWorkspace.getBlockTrashcanSvg().setVisible(true);
            this.props.getWorkspace.getBlockTrashcanSvg().lockVisible(true);
        } else if (prevProps.currentState == USER_GUIDE_STATE.DELETE_BLOCK_DRAGGING) {
            this.props.getWorkspace.getBlockTrashcanSvg().lockVisible(false);
            this.props.getWorkspace.getBlockTrashcanSvg().setVisible(false);
        }

        var startState = USER_GUIDE_STATE.BLOCK_GUIDE;
        if (this.props.currentState == startState &&
            this.props.currentState != prevProps.currentState &&
            prevProps.currentState != USER_GUIDE_STATE.WELCOME) {
            this.props.getWorkspace.setUserGuideDraggingState(false);
            this.props.getWorkspace.clearUndo();
        }

        if (this.props.currentState == USER_GUIDE_STATE.HOW_TO_USE_BLOCK &&
            this.props.currentState != prevProps.currentState) {
            var allBlocks = this.props.getWorkspace.getAllBlocks(false);
            for (var block of allBlocks) {
                if (block.type == "event_whenstarted") {
                    this.startBlock = block;
                    break;
                }
            }
            this.updateStartBlockPosition();
        }

        if (this.props.currentState == USER_GUIDE_STATE.NEW_DEVICE_FINISH &&
            this.props.currentState != prevProps.currentState) {
            this.updatePositionState();
        }
    }

    initUserGuide() {
        log.info("initUserGuide");
        if (!store.get("hasShowUserGuide", false)) {
            log.info("initUserGuide first start user guide");
            this.props.onUpdateUserGuideState(this.props.isEnableWebVR ?
                USER_GUIDE_STATE.VR_TOOLBAR_GUIDE : USER_GUIDE_STATE.WELCOME);
            this.props.onUpdateShowUserGuide(true);
        }
        this.initUserGuideBlocksandImg();
    }

    initUserGuideBlocksandImg() {
        log.info("initUserGuideBlocksandImg");
        if (!this.props.isEnableWebVR) {
            window.addEventListener('resize', () => {
                this.setState({ windowWidth: window.innerWidth })
                this.handleResize();
            });
            this.initDragBlockWidthHeight();
            this.initBlockContourImage();
        }
    }

    updateStartBlockPosition() {
        var flyoutLeftButton = document.getElementById("flyoutLeftButton");
        const style = getComputedStyle(flyoutLeftButton)
        var left = parseInt(style.left, 0);
        var maxCenter = ((window.screen.availWidth - 390) + left) / 2;
        // desktop minimum width = 1024
        var ratio = window.screen.availWidth == 1024 ? 1 : (Math.max(1024, window.innerWidth) - 1024) / (window.screen.availWidth - 1024);
        let moveToX = (isPad()) ? left + 15 : left + (maxCenter - left) * ratio;
        var moveToY = 0.33 * window.innerHeight;
        this.props.getWorkspace.updateStartBlockPosition(moveToX, moveToY);
        this.updatePositionState();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if (this.props.showUserGuide) {
            this.updateStartBlockPosition();
        }
    }

    getBlockWidth(type) {
        var flyoutWorkspace = this.props.getWorkspace.getFlyout().getWorkspace();
        var flyoutBlocks = flyoutWorkspace.getAllBlocks(false);
        for (var block of flyoutBlocks) {
            if (block.type == type) {
                return block.getHeightWidth().width;
            }
        }
    }

    getStartBlockPosition() {
        var x, y;
        if (this.startBlock) {
            var blockPos = this.props.getWorkspace.getBlockPosition(this.startBlock);
            var workspaceOffset = this.props.getWorkspace.getWorkspaceOffset();
            x = blockPos.x - workspaceOffset.x;
            y = blockPos.y - workspaceOffset.y - 10;
        }
        return { x: x, y: y };
    }

    updatePositionState() {
        var startPos = this.getStartBlockPosition();
        let toolboxWidth = window.innerHeight * 0.235;
        if (window.innerHeight < 834) {
            toolboxWidth = window.innerHeight * 0.173;
        }
        let waitBlock = {
            top: this.hasMenubarTag() ? 132 : 96,
            left: toolboxWidth + 10
        }
        let contourBlock = {
            top: startPos.y + 45
        }
        this.setState({
            startBlock: { left: startPos.x, top: startPos.y },
            waitBlock: { top: waitBlock.top, left: waitBlock.left },
            contourBlock: { top: contourBlock.top },
            lineSvgWidthHeight: { width: startPos.x - waitBlock.left - arrowPadding, height: contourBlock.top - waitBlock.top + 38 },
            dottedLineLength: (startPos.x - waitBlock.left - arrowPadding) * 0.6,
            startPoint: { top: 19, left: 0 },
            endPoint: { top: contourBlock.top - waitBlock.top + 22, left: startPos.x - waitBlock.left - arrowPadding }
        });
    }

    initBlockContourImage() {
        switch (store.get("locale", DEFAULT_LOCALE)) {
            case "en":
                this.setState({
                    blockWaitContourImg: blockWaitContourEn,
                    blockThreeWireMotorContourImg: blockThreeWireMotorContourEn,
                    blockMotionSpinImg: blockMotionSpinContourEn,
                });
                break;
            case "zh-cn":
                this.setState({
                    blockWaitContourImg: blockWaitContourZh,
                    blockThreeWireMotorContourImg: blockThreeWireMotorContourZh,
                    blockMotionSpinImg: blockMotionSpinContourZh,
                });
                break;
            case "zh-tw":
                this.setState({
                    blockWaitContourImg: blockWaitContourZh,
                    blockThreeWireMotorContourImg: blockThreeWireMotorContourZh,
                    blockMotionSpinImg: blockMotionSpinContourZh,
                });
                break;
        }
    }

    initDragBlockWidthHeight() {
        this.draggedBlock = {
            width: blockWaitImgWidth,
            height: 40
        }
    }

    handleConnectComPortPath(comPath) {
        log.info(`handleConnectComPortPath: ${comPath}`)
        this.props.connectComPortPath(comPath)
    }

    handleAddMotorToDeviceManager() {
        console.log('handleAddMotorToDeviceManager')
        const { intl } = this.props;
        let motorString = intl.formatMessage({ id: "gui.device.motor" });
        let forwardString = intl.formatMessage({ id: "gui.deviceManagerStage.page.motor.forward" });
        let reverseString = intl.formatMessage({ id: "gui.deviceManagerStage.page.motor.reverse" });
        if (this.props.pickBrainType == BRAIN_TYPE.EDU) {
            this.props.vm.addThreeWireMotor(motorString + 'A1', ['A1'], 'forward', forwardString, reverseString);
        } else {
            this.props.vm.addMotor100(motorString + '100-1', ['1'], 'forward', forwardString, reverseString);
        }
    }

    handleClose() {
        this.props.onUpdateShowUserGuide(false);
        this.props.getWorkspace.setUserGuideDraggingState(false);
        this.props.getWorkspace.clearUndo();
        if (this.props.isEnableWebVR) {
            this.props.getUnityMessageFunc("EventSystem", "Teach", '0');
        }
    }

    handleClickDropDownList() {
        //Add Motor Device
        this.handleAddMotorToDeviceManager();
        setTimeout(() => this.handleClickNextState(), 100); // fix: getBlockWidth is undefined
    }

    handleClickStart() {
        this.props.onUpdateUserGuideState(USER_GUIDE_STATE.START);
    }

    handleClickNextState() {
        this.props.onUpdateUserGuideState(this.props.currentState + 1);
    }

    hasMenubarTag() {
        return this.props.pickBrainType != BRAIN_TYPE.EDU;
    }

    renderDragLayer() {
        var topLayer, leftLayer, rightLayer, bottomLayer;
        var rightLayerClass;
        switch (this.props.currentState) {
            case USER_GUIDE_STATE.ADD_BLOCK:
                rightLayerClass = styles.dragOverlayAddBlockRight;
                bottomLayer = {
                    height: `${document.body.offsetHeight - (this.state.waitBlock.top + 40)}px`
                };
                break;
            case USER_GUIDE_STATE.DELETE_BLOCK:
                topLayer = {
                    height: `${this.state.startBlock.top + 45}px`
                };
                leftLayer = {
                    top: `${this.state.startBlock.top + 45}px`,
                    width: `${this.state.startBlock.left}px`
                }
                rightLayer = {
                    top: `${this.state.startBlock.top + 45}px`,
                    left: `${this.state.startBlock.left + this.draggedBlock.width}px`,
                    width: `${document.body.offsetWidth - (this.state.startBlock.left + this.draggedBlock.width)}px`,
                };
                bottomLayer = {
                    top: `${this.state.contourBlock.top + 40}px`,
                    height: `${document.body.offsetHeight - (this.state.contourBlock.top + 40)}px`
                };
                break;
            case USER_GUIDE_STATE.LET_DEVICE_WORK:
                rightLayerClass = styles.dragOverlayLetDeviceWorkRight;
                bottomLayer = {
                    height: `${document.body.offsetHeight - (this.state.waitBlock.top + 40)}px`
                };
                break;
        }
        return (
            <div>
                <div className={classNames(styles.dragOverlay, styles.top, this.hasMenubarTag() ? styles.entry : null)} style={topLayer} />
                <div className={classNames(styles.dragOverlay, styles.left, this.hasMenubarTag() ? styles.entry : null)} style={leftLayer} />
                <div className={classNames(styles.dragOverlay, styles.right, this.hasMenubarTag() ? styles.entry : null, rightLayerClass)} style={rightLayer} />
                <div className={classNames(styles.dragOverlay, styles.bottom, this.hasMenubarTag() ? styles.entry : null)} style={bottomLayer} />
            </div>
        )
    }

    updateMenubarHighlightPos() {
        switch (this.props.currentState) {
            case USER_GUIDE_STATE.INTRO_BRAIN:
            case USER_GUIDE_STATE.OPEN_BLUETOOTH_SETTING:
            case USER_GUIDE_STATE.BLUETOOTH_CONNECTING:
            case USER_GUIDE_STATE.WIFI_CONNECTING:
            case USER_GUIDE_STATE.RECONNECT:
            case USER_GUIDE_STATE.DISCONNECT:
            case USER_GUIDE_STATE.INTRO_SLOT_DISCONNECT:
            case USER_GUIDE_STATE.INTRO_SLOT:
            case USER_GUIDE_STATE.INTRO_DOWNLOAD_AND_RUN:
            case USER_GUIDE_STATE.DOWNLOAD_AND_RUN:
            case USER_GUIDE_STATE.DOWNLOADING:
                var r = document.querySelector(':root');
                var brainInfoPos = document.getElementById('menubar-brain-info').getBoundingClientRect();
                var slotInfoPos = document.getElementById('menubar-slot-info').getBoundingClientRect();
                var downloadPos = document.getElementById('menubar-download').getBoundingClientRect();
                var downloadAndRunPos = document.getElementById('menubar-download-and-run').getBoundingClientRect();
                r.style.setProperty('--menu-bar-brain-highlight-left', `${brainInfoPos.x}px`);
                r.style.setProperty('--menu-bar-slot-highlight-left', `${slotInfoPos.x}px`);
                r.style.setProperty('--menu-bar-download-highlight-left', `${downloadPos.x}px`);
                r.style.setProperty('--menu-bar-download-and-run-highlight-left', `${downloadAndRunPos.x}px`);
                r.style.setProperty('--menu-bar-intro-download-and-run-highlight-width', `${downloadAndRunPos.right - downloadPos.x}px`);
                r.style.setProperty('--menu-bar-download-and-run-highlight-width', `${downloadAndRunPos.right - downloadAndRunPos.x}px`);
                r.style.setProperty('--menu-bar-downloading-highlight-width', `${downloadAndRunPos.right - brainInfoPos.x}px`);
                break;
        }
    }

    renderOverlay() {
        var opacityStyle = styles.opacityOverlay;
        var preHighlightStyle, highlightStyle;
        var prePosStyle, posStyle;
        var renderPreOverlay = false;
        var renderMenubarOverlay = false;
        var renderDragOverlay = false;
        var renderVROverlay = false;
        var menuBarLeftStyle, menuBarRightStyle;
        this.updateMenubarHighlightPos();

        switch (this.props.currentState) {
            case USER_GUIDE_STATE.WELCOME:
            case USER_GUIDE_STATE.LET_DEVICE_WORK_FINISH:
            case USER_GUIDE_STATE.DOWNLOAD_FAIL:
                highlightStyle = classNames(styles.none, styles.animationFadeIn);
                break;
            case USER_GUIDE_STATE.BLOCK_GUIDE:
                highlightStyle = classNames(styles.blockGuide, this.hasMenubarTag() ? styles.entry : null, this.hasMenubarTag() ? styles.animationEntryBlockGuideScaleIn : styles.animationBlockGuideScaleIn);
                break;
            case USER_GUIDE_STATE.HOW_TO_USE_BLOCK:
                renderPreOverlay = true;
                preHighlightStyle = classNames(styles.blockGuide, this.hasMenubarTag() ? styles.entry : null, styles.animationFadeOut);
                highlightStyle = classNames(styles.howToUseBlock, this.hasMenubarTag() ? styles.entry : null, styles.animationFadeIn);
                posStyle = {
                    width: `${this.draggedBlock.width + 20}px`
                };
                break;
            case USER_GUIDE_STATE.ADD_BLOCK:
                opacityStyle = classNames(styles.opacityOverlay, styles.pointerEvents);
                renderDragOverlay = true;
                highlightStyle = classNames(styles.addBlock, this.hasMenubarTag() ? styles.entry : null);
                posStyle = {
                    width: `${this.draggedBlock.width + 20}px`
                };
                break;
            case USER_GUIDE_STATE.ADD_BLOCK_FINISH:
                highlightStyle = classNames(styles.addBlockFinish, styles.animationFadeIn);
                posStyle = {
                    left: `${this.state.startBlock.left - 13}px`,
                    top: `${this.state.contourBlock.top - 6}px`,
                    width: `${this.draggedBlock.width + 20}px`
                };
                break;
            case USER_GUIDE_STATE.DELETE_BLOCK:
                opacityStyle = classNames(styles.opacityOverlay, styles.pointerEvents);
                renderDragOverlay = true;
                highlightStyle = styles.deleteBlock;
                posStyle = {
                    left: `${this.state.startBlock.left - 13}px`,
                    top: `${this.state.contourBlock.top - 6}px`,
                    width: `${this.draggedBlock.width + 20}px`
                };
                break;
            case USER_GUIDE_STATE.DEVICE_MANAGER_GUIDE:
                highlightStyle = classNames(styles.deviceManagerGuide, this.hasMenubarTag() ? styles.entry : null, this.hasMenubarTag() ? styles.animationEntryDeviceManagerGuideScaleIn : styles.animationDeviceManagerGuideScaleIn);
                break;
            case USER_GUIDE_STATE.NEW_DEVICE:
                let devicePosStyle = styles.newDevice;
                let deviceAnimationStyle = isPad() ? styles.animationNewDevicePadScaleIn : styles.animationNewDeviceScaleIn;
                if (this.props.pickBrainType == BRAIN_TYPE.ENTRY) {
                    devicePosStyle = styles.newDeviceEntry;
                    deviceAnimationStyle = isPad() ? styles.animationEntryNewDevicePadScaleIn : styles.animationEntryNewDeviceScaleIn;
                } else if (this.props.pickBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
                    devicePosStyle = styles.newDeviceB1B2;
                    deviceAnimationStyle = isPad() ? styles.animationB1B2NewDevicePadScaleIn : styles.animationB1B2NewDeviceScaleIn;
                }
                devicePosStyle = classNames(devicePosStyle, isPad() ? styles.pad : null);
                highlightStyle = classNames(devicePosStyle, deviceAnimationStyle);
                break;
            case USER_GUIDE_STATE.NEW_DEVICE_SELECT_PORT:
                highlightStyle = classNames(styles.newDeviceSelectPort, styles.animationFadeIn);
                var height = Math.max(480, document.body.offsetHeight - 480);
                posStyle = {
                    top: `${document.body.offsetHeight - height + 10}px`
                };
                break;
            case USER_GUIDE_STATE.NEW_DEVICE_FINISH:
                highlightStyle = classNames(styles.newDeviceFinish, this.hasMenubarTag() ? styles.entry : null, styles.animationFadeIn);
                posStyle = {
                    width: `${this.draggedBlock.width + 20}px`
                };
                break;
            case USER_GUIDE_STATE.LET_DEVICE_WORK:
                opacityStyle = classNames(styles.opacityOverlay, styles.pointerEvents);
                renderDragOverlay = true;
                highlightStyle = classNames(styles.letDeviceWork, this.hasMenubarTag() ? styles.entry : null);
                posStyle = {
                    width: `${this.draggedBlock.width + 20}px`
                };
                break;
            case USER_GUIDE_STATE.INTRO_BRAIN:
                highlightStyle = classNames(styles.menuBar, styles.introBrain, styles.animationFadeIn);
                break;
            case USER_GUIDE_STATE.OPEN_BLUETOOTH_SETTING:
            case USER_GUIDE_STATE.BLUETOOTH_CONNECTING:
            case USER_GUIDE_STATE.WIFI_CONNECTING:
                highlightStyle = classNames(styles.menuBar, styles.introBrain);
                break;
            case USER_GUIDE_STATE.RECONNECT:
            case USER_GUIDE_STATE.DISCONNECT:
                highlightStyle = classNames(styles.menuBar, styles.brain);
                break;
            case USER_GUIDE_STATE.INTRO_SLOT_DISCONNECT:
            case USER_GUIDE_STATE.INTRO_SLOT:
                highlightStyle = classNames(styles.menuBar, styles.slot, styles.animationSlotTranslate);
                break;
            case USER_GUIDE_STATE.INTRO_DOWNLOAD_AND_RUN:
                highlightStyle = classNames(styles.introDownloadAndRun, styles.animationIntroDownloadAndRunTranslate);
                break;
            case USER_GUIDE_STATE.DOWNLOAD_AND_RUN:
                renderMenubarOverlay = true;
                opacityStyle = classNames(styles.opacityOverlay, styles.pointerEvents);
                highlightStyle = classNames(styles.downloadAndRun, styles.downloadAndRun, styles.animationDownloadAndRunTranslate);
                var downloadAndRunPos = document.getElementById('menubar-download-and-run').getBoundingClientRect();
                menuBarLeftStyle = {
                    width: `${downloadAndRunPos.left}px`
                }
                menuBarRightStyle = {
                    width: `${document.body.offsetWidth - downloadAndRunPos.right}px`,
                    left: `${downloadAndRunPos.right}px`
                };
                break;
            case USER_GUIDE_STATE.DOWNLOADING:
                highlightStyle = classNames(styles.downloading, styles.animationDownloadingTranslate);
                break;
            case USER_GUIDE_STATE.FINISH:
            case USER_GUIDE_STATE.VR_INTRO_SENSORS:
                highlightStyle = classNames(styles.none);
                break;
            case USER_GUIDE_STATE.VR_TOOLBAR_GUIDE:
            case USER_GUIDE_STATE.VR_SELECT_SCENE_AND_EDIT:
            case USER_GUIDE_STATE.VR_PUT_OBJECT:
            case USER_GUIDE_STATE.VR_PUT_TERRAIN:
            case USER_GUIDE_STATE.VR_DRAW_TRACE:
                renderVROverlay = true;
                break;
        }
        return (
            <div className={opacityStyle}>
                <div key={this.props.currentState} className={classNames(styles.highlightOverlay, highlightStyle)} style={posStyle} />

                {renderPreOverlay ?
                    <div key="user-guide-pre-overlay" className={classNames(styles.highlightOverlay, preHighlightStyle)} style={prePosStyle} /> : null}

                {renderMenubarOverlay ?
                    <div>
                        <div className={classNames(styles.blockEventOverlay, styles.left)} style={menuBarLeftStyle} />
                        <div className={classNames(styles.blockEventOverlay, styles.right)} style={menuBarRightStyle} />
                        <div className={classNames(styles.blockEventOverlay, styles.bottom)} />
                    </div> : null}

                {renderDragOverlay ?
                    this.renderDragLayer() : null}
                {renderVROverlay ?
                    <div>
                        <div className={classNames(styles.vrOverlay, styles.top)} />
                        <div className={classNames(styles.vrOverlay, styles.right)} />
                        <div className={classNames(styles.vrOverlay, styles.left)} />
                        <div className={classNames(styles.vrOverlay, styles.bottom)} />
                    </div> : null}
            </div>
        )
    }

    renderVRBackground() {
        if (this.props.isEnableWebVR) {
            switch (this.props.currentState) {
                case USER_GUIDE_STATE.VR_TOOLBAR_GUIDE:
                    this.props.getUnityMessageFunc("EventSystem", "Teach", '1');
                    return (
                        <div className={classNames(styles.vrView, styles.toolbarGuide)}></div>
                    );
                case USER_GUIDE_STATE.VR_SELECT_SCENE_AND_EDIT:
                    this.props.getUnityMessageFunc("EventSystem", "Teach", '2');
                    return (
                        <div className={classNames(styles.vrView, styles.selectSceneAndEdit)}></div>
                    );
                case USER_GUIDE_STATE.VR_PUT_OBJECT:
                    this.props.getUnityMessageFunc("EventSystem", "Teach", '3');
                    return (
                        <div className={classNames(styles.vrView, styles.putObject)}></div>
                    );
                case USER_GUIDE_STATE.VR_PUT_TERRAIN:
                    this.props.getUnityMessageFunc("EventSystem", "Teach", '4');
                    return (
                        <div className={classNames(styles.vrView, styles.putTerrain)}></div>
                    );
                case USER_GUIDE_STATE.VR_DRAW_TRACE:
                    this.props.getUnityMessageFunc("EventSystem", "Teach", '5');
                    return (
                        <div className={classNames(styles.vrView, styles.drawTrace)}></div>
                    );
                case USER_GUIDE_STATE.VR_INTRO_SENSORS:
                    this.props.getUnityMessageFunc("EventSystem", "Teach", '6');
                default:
                    return null;
            }
        }
    }

    isBrainConnected() {
        return this.props.isBrainConnect && this.props.brainInfo.ssn;
    }

    renderLine() {
        var borderRadius = document.body.offsetWidth < 1124 ? 30 : 60;
        var middle = (this.state.endPoint.left - this.draggedBlock.width + this.state.startPoint.left) / 2;
        var path = `M${this.state.startPoint.left} ${this.state.startPoint.top}
                L${middle - borderRadius} ${this.state.startPoint.top}
                Q${middle} ${this.state.startPoint.top} ${middle} ${this.state.startPoint.top + borderRadius}
                L${middle} ${this.state.endPoint.top - borderRadius}
                Q${middle} ${this.state.endPoint.top} ${middle + borderRadius} ${this.state.endPoint.top}
                L${this.state.endPoint.left - this.draggedBlock.width} ${this.state.endPoint.top}`
        return path;
    }

    renderDottedLine() {
        var vh = document.body.offsetHeight;
        var path = `M0 3
                L${vh * 30.5 - 20} 3`
        return path;
    }

    getLineTextContainer() {
        var left = (this.state.startPoint.left + this.state.endPoint.left - this.draggedBlock.width - 77) / 2;
        var top = (this.state.startPoint.top + this.state.endPoint.top - 32) / 2
        return {
            left: left,
            top: top
        }
    }

    getArrowStyle() {
        switch (this.props.currentState) {
            case USER_GUIDE_STATE.ADD_BLOCK:
            case USER_GUIDE_STATE.LET_DEVICE_WORK:
                return {
                    left: this.state.startBlock.left - arrowPadding,
                    top: this.state.startBlock.top + arrowPadding + 38
                }
            case USER_GUIDE_STATE.DELETE_BLOCK:
                return {
                    left: this.state.startBlock.left - this.state.dottedLineLength - 20 - arrowPadding,
                    top: this.state.startBlock.top + arrowPadding + 34
                }
        }
    }

    getDropDownListImg() {
        let dropDownListStyle = styles.dropDownListEntryImg;
        let dropDownListSrc = this.props.uis == uiType.ww ? this.state.dropDownListImgEntryWWSrc : this.state.dropDownListImgEntrySrc;
        if (this.props.pickBrainType == BRAIN_TYPE.EDU) {
            dropDownListStyle = styles.dropDownListImg;
            dropDownListSrc = this.props.uis == uiType.ww ? this.state.dropDownListImgWWSrc : this.state.dropDownListImgSrc;
        }
        return <img id="dropDownListImg" className={dropDownListStyle}
            src={dropDownListSrc} alt={"dropdown list image"} />
    }

    handleMouseEnter() {
        this.setState({
            dropDownListImgSrc: dropDownListHoverImg,
            dropDownListImgEntrySrc: dropDownListHoverEntryImg,
            dropDownListImgWWSrc: dropDownListHoverWWImg,
            dropDownListImgEntryWWSrc: dropDownListHoverEntryWWImg,
        })
    }

    handleMouseOut() {
        this.setState({
            dropDownListImgSrc: dropDownListImg,
            dropDownListImgEntrySrc: dropDownListEntryImg,
            dropDownListImgWWSrc: dropDownListWWImg,
            dropDownListImgEntryWWSrc: dropDownListEntryWWImg
        })
    }

    renderContent() {
        switch (this.props.currentState) {
            case USER_GUIDE_STATE.ADD_BLOCK:
                let addPathTextContainer = this.getLineTextContainer();
                return (
                    <div>
                        <div className={styles.blockPath} style={{ left: `${this.state.waitBlock.left + this.draggedBlock.width + 8}px`, top: `${this.state.waitBlock.top}px`, width: `${this.state.lineSvgWidthHeight.width - this.draggedBlock.width - 8}px` }}>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={this.state.lineSvgWidthHeight.width - this.draggedBlock.width - 8} height={this.state.lineSvgWidthHeight.height}>
                                <path stroke="white" strokeWidth="5" fill="none" d={this.renderLine()} />
                            </svg>
                            <img className={classNames(styles.img)} style={{ left: `${this.getArrowStyle().left}px`, top: `${this.getArrowStyle().top}px` }} src={pathArrowRightImg} alt={"arrow"} />
                            <div className={classNames(styles.pathTextContainer)} style={{ left: `${addPathTextContainer.left}px`, top: `${addPathTextContainer.top}px` }} alt={"path"}>
                                Move
                            </div>
                        </div>
                        <Animation animation={ANIMATION_TYPE.MOUSE_TRANSLATE} startX={this.state.waitBlock.left + this.draggedBlock.width - 20} startY={this.state.waitBlock.top + 20} endX={this.state.startBlock.left + 80} endY={this.state.startBlock.top + 70} />
                        <img className={classNames(styles.img)} style={{ left: `${this.state.startBlock.left}px`, top: `${this.state.contourBlock.top}px` }} src={this.state.blockWaitContourImg} alt={"block wait contour image"} />
                    </div>
                );
            case USER_GUIDE_STATE.NEW_DEVICE:
                setTimeout(function () {
                    document.getElementById('userGuideNewDeviceRightArrow').style.visibility = "visible";
                }, 500);
                let motorIconPos = classNames(styles.deviceMotorIcon, isPad() ? styles.pad : null);
                let arrowPos = classNames(styles.posNewDevice, isPad() ? styles.pad : null);
                if (this.props.pickBrainType == BRAIN_TYPE.ENTRY) {
                    motorIconPos = classNames(motorIconPos, styles.entry, isPad() ? styles.pad : null);
                    arrowPos = classNames(arrowPos, styles.entry, isPad() ? styles.pad : null);
                } else if (this.props.pickBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
                    motorIconPos = classNames(motorIconPos, styles.b1b2, isPad() ? styles.pad : null);
                    arrowPos = classNames(arrowPos, styles.b1b2, isPad() ? styles.pad : null);
                }
                return (
                    <div>
                        <div className={styles.deviceManagerPanel} >
                            <div className={motorIconPos} onClick={() => this.handleClickNextState()} />
                            <div id='userGuideNewDeviceRightArrow' className={classNames(styles.rightArrow, arrowPos)}>
                                <Animation animation={ANIMATION_TYPE.LONG_RIGHT_ARROW} />
                            </div>
                        </div>
                    </div>
                );
            case USER_GUIDE_STATE.NEW_DEVICE_SELECT_PORT:
                setTimeout(function () {
                    if (document.getElementById('dropDownListImg')) {
                        document.getElementById('dropDownListImg').style.visibility = "visible";
                    }
                    if (document.getElementById('dropDownList')) {
                        document.getElementById('dropDownList').style.visibility = "visible";
                    }
                    if (document.getElementById('dropDownListArrow')) {
                        document.getElementById('dropDownListArrow').style.visibility = "visible";
                    }
                }, 1000);
                return (
                    <div>
                        <div className={styles.deviceManagerPanel} >
                            <div className={styles.deviceContent}>
                                {this.getDropDownListImg()}
                                <div id="dropDownList" className={styles.dropDownList} onMouseEnter={() => this.handleMouseEnter()} onMouseOut={() => this.handleMouseOut()} onClick={() => this.handleClickDropDownList()} ></div>
                                <div id="dropDownListArrow" className={classNames(styles.rightArrow, styles.posNewDeviceSelectPort)}>
                                    <Animation animation={ANIMATION_TYPE.ARROW_RIGHT} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case USER_GUIDE_STATE.DELETE_BLOCK:
                return (
                    <div>
                        <svg className={styles.blockPath} style={{ left: `${this.state.startBlock.left - this.state.dottedLineLength - 20}px`, top: `${this.state.startBlock.top + 60}px` }} version="1.1" xmlns="http://www.w3.org/2000/svg" width={this.state.dottedLineLength} height="10px">
                            <path stroke="white" strokeWidth="4" strokeDasharray="1,8" fill="none" strokeLinecap="round" d={this.renderDottedLine()} />
                        </svg>
                        <img className={classNames(styles.img)} style={{ left: `${this.getArrowStyle().left}px`, top: `${this.getArrowStyle().top}px` }} src={pathArrowLeftImg} alt={"path"} />
                        <div className={classNames(styles.pathTextContainer)} style={{ left: `${(this.state.startBlock.left - this.state.dottedLineLength - 20) + (this.state.dottedLineLength - 77) / 2}px`, top: `${this.state.startBlock.top + 44}px` }}>
                            Delete
                        </div>
                        <Animation animation={ANIMATION_TYPE.MOUSE_TRANSLATE} startX={this.state.startBlock.left + this.draggedBlock.width - 30} startY={this.state.startBlock.top + 70} endX={this.state.waitBlock.left + 90} endY={this.state.startBlock.top + 70} />
                    </div>
                );
            case USER_GUIDE_STATE.LET_DEVICE_WORK:
                let lineTextContainer = this.getLineTextContainer();
                return (
                    <div>
                        <div className={styles.blockPath} style={{ left: `${this.state.waitBlock.left + this.draggedBlock.width + 8}px`, top: `${this.state.waitBlock.top}px`, width: `${this.state.lineSvgWidthHeight.width - this.draggedBlock.width - 8}px` }}>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={this.state.lineSvgWidthHeight.width - this.draggedBlock.width - 8} height={this.state.lineSvgWidthHeight.height}>
                                <path stroke="white" strokeWidth="5" fill="none" d={this.renderLine()} />
                            </svg>
                            <img className={classNames(styles.img)} style={{ left: `${this.getArrowStyle().left}px`, top: `${this.getArrowStyle().top}px` }} src={pathArrowRightImg} alt={"path"} />
                            <div className={classNames(styles.pathTextContainer)} style={{ left: `${lineTextContainer.left}px`, top: `${lineTextContainer.top}px` }}>
                                Move
                            </div>
                        </div>
                        <Animation animation={ANIMATION_TYPE.MOUSE_TRANSLATE} startX={this.state.waitBlock.left + this.draggedBlock.width - 20} startY={this.state.waitBlock.top + 20} endX={this.state.startBlock.left + this.draggedBlock.width - 20} endY={this.state.startBlock.top + 70} />
                        <img className={classNames(styles.img)} style={{ left: `${this.state.startBlock.left}px`, top: `${this.state.contourBlock.top}px` }} src={this.props.pickBrainType == BRAIN_TYPE.EDU ? this.state.blockThreeWireMotorContourImg : this.state.blockMotionSpinImg} alt={"block wait image"} />
                    </div>
                );
            case USER_GUIDE_STATE.VR_DRAW_TRACE:
                return (
                    <div>
                        <div className={styles.drawTraceAnimation} />
                        <div className={styles.drawTraceArrowAnimation} />
                    </div>
                );
            case USER_GUIDE_STATE.VR_PUT_TERRAIN:
                return (
                    <div className={styles.putTerrainAnimation} />
                );
        }
    }

    scrollToCategory(category) {
        let res = this.props.getWorkspace.toolbox_.setSelectedCategoryByName(category);
        if (!res) {
            log.info("scrollToCategory category " + category + " res: " + res);
            setTimeout(() => this.scrollToCategory(category), 0);
        }
    }

    preProcessState() {
        switch (this.props.currentState) {
            case USER_GUIDE_STATE.BLOCK_GUIDE:
                this.changeFlyoutState(true);
                this.props.getWorkspace.setUserGuideDraggingState(true);
                break;
            case USER_GUIDE_STATE.HOW_TO_USE_BLOCK:
                this.scrollToCategory("%{BKY_CATEGORY_CONTROL}");
                this.draggedBlock.width = this.getBlockWidth("control_wait");
                break;
            case USER_GUIDE_STATE.ADD_BLOCK:
                this.changeFlyoutState(true);
                this.props.getWorkspace.setUserGuideDraggingState(true);
                break;
            case USER_GUIDE_STATE.ADD_BLOCK_DRAGGING:
                this.changeFlyoutState(false);
                break;
            case USER_GUIDE_STATE.ADD_BLOCK_FINISH:
                this.changeFlyoutState(true);
                break;
            case USER_GUIDE_STATE.DELETE_BLOCK:
                this.draggedBlock.width = this.getBlockWidth("control_wait");
                this.props.getWorkspace.setIsUserGuideBlockDeletable(true);
                break;
            case USER_GUIDE_STATE.NEW_DEVICE_SELECT_PORT:
                if (this.props.pickBrainType == BRAIN_TYPE.EDU) {
                    this.props.openAddPage(undefined, 'threeWireMotor')
                } else {
                    this.props.openAddPage(undefined, 'motor100')
                }
                break;
            case USER_GUIDE_STATE.NEW_DEVICE_FINISH:
                this.props.closeAddPage();
                this.scrollToCategory("%{BKY_CATEGORY_MOTION}");
                if (this.props.pickBrainType == BRAIN_TYPE.EDU) {
                    this.draggedBlock.width = this.getBlockWidth("motion_3wire_spin");
                } else {
                    this.draggedBlock.width = this.getBlockWidth("motion_spin");
                }
                break;
            case USER_GUIDE_STATE.LET_DEVICE_WORK:
                this.changeFlyoutState(true);
                break;
            case USER_GUIDE_STATE.LET_DEVICE_WORK_DRAGGING:
                this.props.getWorkspace.setIsUserGuideBlockDeletable(false);
                this.changeFlyoutState(false);
                break;
            case USER_GUIDE_STATE.LET_DEVICE_WORK_FINISH:
                this.changeFlyoutState(true);
                break;
        }
    }

    changeFlyoutState(show) {
        this.props.getWorkspace.getFlyout().setVisible(show);
        this.props.updateFlyoutVisibleState(show);
        if (show) {
            this.props.getWorkspace.toolbox_.setVisible(true);
            this.props.updateToolboxVisibleState(true);
        }
        this.props.getWorkspace.recordCachedAreas();
    }

    render() {
        if (!this.props.showUserGuide) {
            return null;
        }
        this.preProcessState();
        return (
            <div>
                {this.renderOverlay()}
                {this.renderVRBackground()}
                <div className={styles.contentLayer} >
                    {this.renderContent()}
                    <MessageBox uis={this.props.uis} onClose={() => this.handleClose()} onBrainConnected={() => this.isBrainConnected()} startBlock={this.state.startBlock} hasMenubarTag={() => this.hasMenubarTag()} ></MessageBox>
                </div>
                {
                    getPlatform() == platformType.Desktop ? (
                        <div className={styles.brainInfoDesktop}>
                            <BrainInfo
                                show={this.props.currentState == USER_GUIDE_STATE.WIFI_CONNECTING}
                                brainInfo={this.props.brainInfo}
                                allPortInfo={this.props.allPortInfo}
                                updateFirmware={this.props.updateFirmware}
                                firmwareVer={this.props.brainVer}
                                limitVer={this.props.brainMinVer}
                                latestVer={this.props.brainLatestVer}
                                close={this.props.onRequestCloseBrain}
                                onClickSwitchWifi={this.props.onClickSwitchWifi}
                                onClickWifiScan={this.props.onClickWifiScan}
                                handleConnectComPortPath={(comPath) => { this.handleConnectComPortPath(comPath) }}

                                connectApPort={this.props.connectApPort}
                                disconnectPort={this.props.disconnectPort}
                                setWifiAp={this.props.setWifiAp}
                                startListenApBroadcast={this.props.startListenApBroadcast}
                                stopListenApBroadcast={this.props.stopListenApBroadcast}
                            />
                        </div>
                    ) : isPad() ? (
                        <div className={styles.brainInfo}>
                            <BrainInfoMobile show={this.props.currentState == USER_GUIDE_STATE.BLUETOOTH_CONNECTING || this.props.currentState == USER_GUIDE_STATE.WIFI_CONNECTING} onRequestClose={() => { }} />
                        </div>
                    ) : null
                }
            </div>
        )
    }
}

UserGuide.propTypes = {
    isBrainRunning: PropTypes.bool,
    isBrainConnect: PropTypes.bool,
    brainInfo: PropTypes.object,
    getWorkspace: PropTypes.object,
    updateFlyoutVisibleState: PropTypes.func,
    updateToolboxVisibleState: PropTypes.func,
    onOpenDeviceManager: PropTypes.func,
    onCloseDeviceManager: PropTypes.func,
    vm: PropTypes.shape({
        addThreeWireMotor: PropTypes.func,
        addMotor100: PropTypes.func,
    }),
    onShowStartAppBrainTypePicker: PropTypes.func,
    getUnityMessageFunc: PropTypes.func,
    getVRUnityContextLoadingProgress: PropTypes.number,
    updateFirmware: PropTypes.func,
    onRequestCloseBrain: PropTypes.func,
    onClickSwitchWifi: PropTypes.func,
    onClickWifiScan: PropTypes.func,
};

UserGuide.defaultProps = {
    onClickNew: PropTypes.func,
    uis: PropTypes.string
};

const mapStateToProps = state => ({
    showUserGuide: isShowUserGuide(state),
    currentState: getUserGuideCurrentState(state),
    isBrainRunning: getBrainRunningStatus(state),
    isBrainConnect: getBrainConnectStatus(state),
    brainInfo: getBrainInfo(state),
    allPortInfo: getAllPortInfo(state),
    brainVer: getBrainVer(state, 'current'),

    brainStatus: getBrainStatus(state),
    getWorkspace: getWorkspace(state),
    vm: state.scratchGui.vm,
    isPickedBrainType: isPickedBrainType(state),
    pickBrainType: getPickedBrainType(state),
    isEnableWebVR: isEnableWebVR(state),
    getUnityMessageFunc: getUnityMessageFunc(state),
    getVRUnityContextLoadingProgress: getVRUnityContextLoadingProgress(state),
});

const mapDispatchToProps = dispatch => ({
    onUpdateShowUserGuide: show => dispatch(updateShowUserGuide(show)),
    onUpdateUserGuideState: state => dispatch(updateUserGuideState(state)),
    updateFlyoutVisibleState: isFlyoutVisible => {
        dispatch(updateFlyoutVisible(isFlyoutVisible));
    },
    updateToolboxVisibleState: isToolboxVisible => {
        dispatch(updateToolboxVisible(isToolboxVisible));
    },
    openAddPage: (portNum, deviceType) => {
        dispatch(openAddPage(portNum, deviceType))
    },
    closeAddPage: () => {
        dispatch(closeDeviceList());
        dispatch(closeAddPage());
        dispatch(cleanPortOption())
    },
    onOpenDeviceManager: () => dispatch(setStageSize(STAGE_SIZE_MODES.large, STAGE_DISPLAY_TYPE.deviceManager)),
    onCloseDeviceManager: () => dispatch(setStageSize(STAGE_SIZE_MODES.small)),
    onRequestCloseBrain: () => dispatch(closeBrainInfoMenu()),
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserGuide));