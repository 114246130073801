import bowser from 'bowser';

const minVersions = {
    chrome: '63',
    msedge: '15',
    firefox: '57',
    safari: '11'
};

/**
 * Helper function to determine if the browser is supported at all.
 * @returns {boolean} False if the platform is definitely not supported.
 */
const supportedBrowser = () => {
    if (bowser.msie) {
        return false;
    }
    return true;
};

/**
 * Helper function to determine if the browser meets the minimum recommended version
 * @returns {boolean} False if the browser isn't a recommended browser, or doesn't
 * meet the minimum version for recommended browsers.
 * NOTE: uses strict_mode==true so that any browser not listed in the minVersions
 *   always returns false
 */

const recommendedBrowser = () =>
    !bowser.isUnsupportedBrowser(minVersions, true) ||
    window.navigator.userAgent.toLowerCase().indexOf('googlebot') !== -1;

const isNeedToHintBrowser = () => {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
        // is Google Chrome on IOS
        return false;
    } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
    ) {
        // is Google Chrome
        return false;
    } else {
        // not Google Chrome 
        if (!!window.navigator.userAgent.match(/Ipad/i) ||
            !!(window.navigator.userAgent.match(/safari/i) && !window.navigator.userAgent.match(/Chrome/i))) {
            return false;
        } else {
            return true;
        }
    }
}

export {
    supportedBrowser as default,
    recommendedBrowser,
    isNeedToHintBrowser
};
