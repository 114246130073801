import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styles from '../dialog.css';
import wifiStyles from './wifi-dialog.css';

import closeIcon from '../svg/close_btn.svg';
import { getBrainInfo } from '../../../reducers/brain';
import {
    isWifiIpSettingDialogShow,
    hideWifiIpSettingDialog
} from '../../../reducers/dialog';
import { isPad } from '../../../lib/platform';
import { Catagory, postMessage } from '../../../lib/postmessage';
import {
    SocketAction
} from '../../../lib/socket';
const CONNECT_TIMEOUT = 30 * 1000

const INIT_STATE = {
    ip1: '',
    ip2: '',
    ip3: '',
    ip4: '',
    isIpWrong: false,
    isConnecting: false,
    isConnectFailed: false
}
class WifiIpSettingDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'onChangeIp'
        ]);
        this.state = INIT_STATE
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
        if (prevProps.show != this.props.show) {
            this.setState(INIT_STATE)
        }
        if (prevProps.getBrainInfo != this.props.getBrainInfo && this.props.getBrainInfo.isSocket != undefined) {
            this.props.closeDialog();
        }
    }
    componentWillUnmount() {
    }

    onChangeIp(e) {
        if (this.state.isConnecting) return;
        if (this.state.isIpWrong) this.setState({ isIpWrong: false })
        if (this.state.isConnectFailed) this.setState({ isConnectFailed: false })

        let regx = /[0-9]/;
        if (regx.test(e.target.value) || e.target.value == "") {
            switch (e.target.id) {
                case 'ip1':
                    this.setState({
                        ip1: e.target.value
                    });
                    break;
                case 'ip2':
                    this.setState({
                        ip2: e.target.value
                    });
                    break;
                case 'ip3':
                    this.setState({
                        ip3: e.target.value
                    });
                    break;
                case 'ip4':
                    this.setState({
                        ip4: e.target.value
                    });
                    break;
                default:
                    break;
            }
        }
    }
    onClickConnect() {

        if (this.state.isIpWrong) this.setState({ isIpWrong: false })
        if (this.state.isConnectFailed) this.setState({ isConnectFailed: false })
        let ip = [this.state.ip1, this.state.ip2, this.state.ip3, this.state.ip4]
        ip = ip.join(".");
        let regx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/

        if (regx.test(ip)) {
            if (this.props.connectApPort) this.props.connectApPort(ip);
            if (isPad()) {
                postMessage(Catagory.Socket, { "action": SocketAction.connect, "ip": ip });
            }
            this.setState({ isConnecting: true })
            setTimeout(() => { this.setState({ isConnectFailed: true, isConnecting: false }) }, CONNECT_TIMEOUT)
        } else {
            this.setState({ isIpWrong: true })
        }
    }
    render() {
        // Render nothing if the "show" prop is false
        if (!this.props.show) {
            return null;
        }
        const isConnecting = this.state.isConnecting
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle, styles.wifiIpSetting)}>
                    <div className={styles.wifiHeader}>
                        <div className={styles.wifiTitle}>
                            <FormattedMessage
                                defaultMessage="Input ip"
                                description="input ip"
                                id="gui.firmware.brain.ap.ip"
                            />
                        </div>
                        <div className={styles.aboutClose} onClick={this.props.closeDialog}>
                            <img src={closeIcon} />
                        </div>
                    </div>
                    <div className={wifiStyles.wifiIpsettingContent}>
                        <div className={wifiStyles.wifiIpsettingContentText}>
                            <div>
                                <FormattedMessage
                                    id="gui.dialog.wifi.setting.ip.title1"
                                /></div>
                            <div>
                                <FormattedMessage
                                    id="gui.dialog.wifi.setting.ip.title2"
                                /></div>
                        </div>
                        <div className={wifiStyles.wifiIpContent}>
                            <input id="ip1" type="text"
                                disabled={isConnecting}
                                maxLength="3"
                                autoComplete="off"
                                value={this.state.ip1}
                                className={classNames(wifiStyles.wifiIpInput, this.state.ip1 == '' ? wifiStyles.empty : wifiStyles.finish,
                                    isConnecting ? styles.disable : null)} onChange={this.onChangeIp} />
                            <div className={classNames(styles.dot)} />
                            <input id="ip2" type="text"
                                disabled={isConnecting}
                                maxLength="3"
                                autoComplete="off"
                                value={this.state.ip2}
                                className={classNames(wifiStyles.wifiIpInput, this.state.ip2 == '' ? wifiStyles.empty : wifiStyles.finish,
                                    isConnecting ? styles.disable : null)} onChange={this.onChangeIp} />
                            <div className={classNames(styles.dot)} />
                            <input id="ip3" type="text"
                                disabled={isConnecting}
                                maxLength="3"
                                autoComplete="off"
                                value={this.state.ip3}
                                className={classNames(wifiStyles.wifiIpInput, this.state.ip3 == '' ? wifiStyles.empty : wifiStyles.finish,
                                    isConnecting ? styles.disable : null)} onChange={this.onChangeIp} />
                            <div className={classNames(styles.dot)} />
                            <input id="ip4" type="text"
                                disabled={isConnecting}
                                maxLength="3"
                                autoComplete="off"
                                value={this.state.ip4}
                                className={classNames(wifiStyles.wifiIpInput, this.state.ip4 == '' ? wifiStyles.empty : wifiStyles.finish,
                                    isConnecting ? styles.disable : null)} onChange={this.onChangeIp} />
                        </div>
                        <div className={wifiStyles.wifiIpRemid}>
                            {isConnecting ?
                                <div className={wifiStyles.wifiIpConnecting}>
                                    <FormattedMessage
                                        defaultMessage="Connecting"
                                        description="connecting"
                                        id="gui.dialog.wifi.setting.connecting"

                                    />
                                </div> : null}

                            {this.state.isConnectFailed ? <div className={wifiStyles.wifiIpError}>
                                <FormattedMessage
                                    id="gui.dialog.wifi.setting.connect.error"
                                />
                            </div> : null}
                            {this.state.isIpWrong ? <div className={wifiStyles.wifiIpError}>
                                <FormattedMessage
                                    id="gui.dialog.wifi.setting.connect.ip.error"
                                />
                            </div> : null}
                        </div>
                        <div className={classNames(wifiStyles.wifiDirectionButton, wifiStyles.wifiDirectionCenter, isConnecting ? styles.disable : null)}
                            onClick={() => isConnecting ? {} : this.onClickConnect()}>
                            <FormattedMessage
                                defaultMessage="Connect"
                                description="connect"
                                id="gui.dialog.wifi.setting.connect"
                            />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

WifiIpSettingDialog.propTypes = {
    show: PropTypes.bool,
    closeDialog: PropTypes.func,
};

const mapStateToProps = state => ({
    show: isWifiIpSettingDialogShow(state),
    getBrainInfo: getBrainInfo(state)
});

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(hideWifiIpSettingDialog()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WifiIpSettingDialog);