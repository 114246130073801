import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import visionSettingStyles from './vision-setting-dialog.css'
import cancel_icon from '../svg/cancel_btn.svg'
import {
    platformType,
    isPad,
    getPlatform,
    isReactNative
} from '../../../lib/platform';
import { DetectedColorTag, parseFixedToFloat, parseTag, TAG_TYPE, scaleUpTagSize } from './tag.jsx'
import VM from 'scratch-vm';
import log from '../../../lib/log';
import {
    getColorDataList,
    setColorDataList,
    getImageTagList,
    getSelectedColor,
    isVisionConnected,
    isVisionInitiated,
    isVisionRecongInitiated,
    setImageTagList,
    getColorDataDefaultList,
    VISION_INIT_STATE_TYPE,
    isVisionColorDefaultListInitiated,
    COLOR_DATA_LIST
} from '../../../reducers/vision.js'

import {
    EditNameDialog, ReminderDialog,
} from './vision-other-dialog.jsx'
import loadingImg from '../svg/loading.svg'
import editIcon from '../svg/cv_edit.svg'
import editWWIcon from '../svg/ww/cv_edit.svg'
import helpIcon from '../svg/help.svg';
import { CACNEL_MARK_TIMEOUT, COLOR_TAG_AMOUNT, VISION_NAME_RULE, STATE_COMMAND_DELAY, parseDataLanguage } from './vision-utils'
import { EditUtils } from '../../device-manager/edit-page/edit-utils';
const store = require('store');
const { DEFAULT_LOCALE } = require('../../../config/project-config.js');
const IMAGE_PREVIEW_WIDTH = 640;
const IMAGE_PREVIEW_HEIGHT = 480;
const MIN_MARK_EDGE = 8;
const MIN_WINDOW_WIDTH = 1260;
let mousedownFunc = () => { };
let mousemoveFunc = () => { };
let mouseupFunc = () => { };
let resizeFunc = () => { };
class VisionColor extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = {
            dataList: [],
            // tagList: this.props.getImageTagList,
            sliderIndex: null,
            sliderIndexValue: null,
            sliderFillerWidth: null,
            isImageFreezed: false,
            beginMark: false,
            markTagX: Number.MIN_SAFE_INTEGER,
            markTagY: Number.MIN_SAFE_INTEGER,
            markTagWidth: Number.MIN_SAFE_INTEGER,
            markTagHeight: Number.MIN_SAFE_INTEGER,
            fixMark: false,
            value: 0,
            showEditDialog: false,
            editIndex: -1,
            tempEditName: null,
            showReminderDialog: false,
            freezedStream: null
        }
        this.onMoveSliderBar = false;
        this.onClickReplace = false;
        this.onClickSliderBarListener = false
        this.onMoveMark = false;
        this.lastX = null;
        this.lastY = null;
    }

    componentDidMount() {
        let list = this.loadColorDataList();
        if (this.props.isVisionDistribute) {
            this.props.setVisionTagSetting(list);
            // this.refreshTagList();
            this.props.startVisionRealtime('VisionColor.componentDidMount');
        }
        this.setLockScrollbar(false);
        this.setState({ dataList: list });
        this.props.vm.restoreCvTagSettings(list);
        this.addEventListener();
    }

    componentWillUnmount() {
        this.removeEventListener();
        this.setState({
            sliderIndex: null,
            sliderIndexValue: null,
            sliderFillerWidth: null
        })
    }

    loadColorDataList() {
        let list = [];
        let storelist = store.get('visionData');
        if (storelist) list = storelist[COLOR_DATA_LIST];
        if (!list || !Array.isArray(list) || list.length <= 0) list = this.props.vm.getCvTagSettings();
        if (!list || !Array.isArray(list) || list.length <= 0) list = this.props.getColorDataList;
        if (!list || !Array.isArray(list) || list.length <= 0) list = this.props.colorDataDefaultList;
        if (list && Array.isArray(list) && list.length > 0) {
            list = list.slice(0, COLOR_TAG_AMOUNT)
            list = parseDataLanguage(list);
            store.set('visionData', {
                [COLOR_DATA_LIST]: list
            })
        }
        log.log('loadColorDataList list ', list)
        return list
    }

    componentDidUpdate(prevProps, prevStates) {
        if (prevProps.getColorDataList !== this.props.getColorDataList) {
            this.setState({ dataList: parseDataLanguage(this.props.getColorDataList) })
        }
        // if (prevProps.getImageTagList != this.props.getImageTagList) {
        //     this.refreshTagList();
        // }
        if (prevProps.isVisionColorDefaultListInitiated != this.props.isVisionColorDefaultListInitiated && this.props.isVisionColorDefaultListInitiated) {
            let list = parseDataLanguage(this.props.vm.getCvTagSettings());
            this.props.setVisionTagSetting(list);
        }
        if (prevProps.show != this.props.show && this.props.show == true) {
            let list = this.loadColorDataList();
            if (this.props.isVisionDistribute) {
                this.props.setVisionTagSetting(list);
                // this.refreshTagList();
            }
        }
        if (prevProps.isVisionInitiated != this.props.isVisionInitiated) {
            if (this.props.isVisionConnected == true) {
                if (this.props.isVisionInitiated == true) {
                    let list = this.loadColorDataList();
                    this.refreshListState(list)
                    if (!this.state.isImageFreezed) {
                        this.props.startVisionRealtime('VisionColor.componentDidUpdate prevProps.isVisionInitiated != this.props.isVisionInitiated');
                    }
                } else {
                    this.setLockScrollbar(false);
                    this.props.initVision();
                    this.onCancelEditDailog();
                    this.clearImage();
                }
            } else {
                this.setLockScrollbar(false);
                this.onCancelEditDailog();
                this.clearImage();
            }
        }
    }

    moveScrollbarToTop() {
        let content = document.getElementById('visionContentArea')
        if (content) {
            content.scrollTop = 0;
            content.scrollLeft = 0;
        }
    }

    setLockScrollbar(lock) {
        let content = document.getElementById('visionContentArea')
        let windowWidth = window.innerWidth;
        console.log(`setLockScrollbar ${lock} windowWidth ${windowWidth}`)
        if (content) {
            if (windowWidth <= MIN_WINDOW_WIDTH) {
                content.style.overflowY = lock ? 'hidden' : 'auto';
            } else {
                content.style.overflowY = 'hidden';
            }
        }
        let colorSettingListArea = document.getElementById('colorSettingListArea')
        if (colorSettingListArea) {
            if (windowWidth <= MIN_WINDOW_WIDTH) {
                colorSettingListArea.style.overflowY = lock ? 'hidden' : 'auto';
            } else {
                colorSettingListArea.style.overflowY = 'auto';
            }
        }
    }

    clearImage() {
        this.props.clearImageTagList();
        this.setState({
            // tagList: [],
            isImageFreezed: false
        });
    }

    addEventListener() {
        mousedownFunc = e => { this.onMouseDown(e) };
        mousemoveFunc = e => { this.onMouseMove(e) };
        mouseupFunc = e => { this.onMouseUp(e) };
        resizeFunc = e => {
            this.setLockScrollbar(false);
            this.moveScrollbarToTop();
        };
        if (isPad()) {
            window.addEventListener('touchstart', mousedownFunc);
            window.addEventListener('touchmove', mousemoveFunc);
            window.addEventListener('touchend', mouseupFunc);
        } else {
            window.addEventListener('mousedown', mousedownFunc);
            window.addEventListener('mousemove', mousemoveFunc);
            window.addEventListener('mouseup', mouseupFunc);
        }

        window.addEventListener('resize', resizeFunc);
    }

    removeEventListener() {
        if (isPad()) {
            window.removeEventListener('touchstart', mousedownFunc);
            window.removeEventListener('touchmove', mousemoveFunc);
            window.removeEventListener('touchend', mouseupFunc);
        } else {
            window.removeEventListener('mousedown', mousedownFunc);
            window.removeEventListener('mousemove', mousemoveFunc);
            window.removeEventListener('mouseup', mouseupFunc);
        }
        window.removeEventListener('resize', resizeFunc);
    }

    onMouseDown(e) {
        if (this.state.sliderIndex && this.onMoveSliderBar) {
            let value = this.state.dataList[this.state.sliderIndex - 1].bar;
            let width = parseFixedToFloat(((value / 10) * SLIDER_WIDTH), 0)
            this.setState({
                sliderIndexValue: value,
                sliderFillerWidth: width
            })
            this.onMoveMark = false;
            this.onClickSliderBarListener = false;
        }
        if (this.onMoveMark) {
            this.onMoveSliderBar = false;
            this.onClickSliderBarListener = false;
            this.setState({ markTagWidth: -1, markTagHeight: -1 })
            this.setState({ beginMark: true })
            this.moveScrollbarToTop();
        }
        if (this.state.sliderIndex && this.onClickSliderBarListener) {
            this.onClickSliderBar(e)
            this.onMoveSliderBar = false;
            this.onMoveMark = false;
        }
    }

    onMouseMove(e) {
        if (this.state.sliderIndex && this.onMoveSliderBar) {
            this.setLockScrollbar(true)
            var x = e.clientX;
            if (isPad()) {
                x = e.pageX;
            }
            if (isReactNative()) {
                x = e.touches[0].clientX;
            }
            if (this.lastX) {
                let dx = x - this.lastX;
                let move = this.state.sliderFillerWidth + dx;
                let width = parseFixedToFloat(this.calculateMove(move, 0, SLIDER_WIDTH), 1)
                let value = this.calculateMove(parseFixedToFloat(((width / SLIDER_WIDTH) * 10), 1), 0.1, 10.0);
                this.setState({
                    sliderIndexValue: value,
                    sliderFillerWidth: width,
                })
            }
            this.lastX = x;
        }
        if (this.onMoveMark && this.state.beginMark && !this.state.fixMark) {
            this.setLockScrollbar(true)
            var x = e.clientX;
            var y = e.clientY;
            if (isPad()) {
                x = e.pageX;
                y = e.pageY;
            }
            if (isReactNative()) {
                x = e.touches[0].clientX;
                y = e.touches[0].clientY;
            }
            if (this.state.markTagX == Number.MIN_SAFE_INTEGER) {
                let imgPostion = this.getImagePosition();
                this.setState({ markTagX: x - imgPostion.x, markTagY: y - imgPostion.y })
            }
            if (this.lastX && this.lastY) {
                let dx = x - this.lastX;
                let dy = y - this.lastY;
                let width = this.state.markTagWidth != Number.MIN_SAFE_INTEGER ?
                    this.calculateMove(this.state.markTagWidth + dx, (0 - this.state.markTagX), (IMAGE_PREVIEW_WIDTH - this.state.markTagX))
                    : 0;
                let height = this.state.markTagHeight != Number.MIN_SAFE_INTEGER ?
                    this.calculateMove(this.state.markTagHeight + dy, (0 - this.state.markTagY), (IMAGE_PREVIEW_HEIGHT - this.state.markTagY))
                    : 0;
                this.setState({
                    markTagWidth: width,
                    markTagHeight: height,
                })
            }
            this.lastX = x;
            this.lastY = y;
        }
    }

    getImagePosition() {
        var obj = document.getElementById('visionImg')
        var curleft = 0;
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curleft += obj.offsetLeft;
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
            return { x: curleft, y: curtop }
        }
    }

    initMarkTag() {
        this.setState({
            markTagX: Number.MIN_SAFE_INTEGER,
            markTagY: Number.MIN_SAFE_INTEGER,
            markTagHeight: Number.MIN_SAFE_INTEGER,
            markTagWidth: Number.MIN_SAFE_INTEGER,
            beginMark: false,
            fixMark: false
        })
        if (this.cancelTimeout) clearTimeout(this.cancelTimeout)
    }

    onMouseUp(e) {
        if (!this.onMoveSliderBar && !this.onClickSliderBarListener && this.state.sliderIndex) this.setState({
            sliderIndex: null,
            sliderIndexValue: null,
            sliderFillerWidth: null
        });
        if (this.onClickSliderBarListener) {
            this.onClickSliderBarListener = false;
        }
        if (this.onMoveSliderBar) {
            this.setLockScrollbar(false)
            this.setColorDataValue(this.state.sliderIndex - 1, this.state.sliderIndexValue)
            this.onMoveSliderBar = false;
            this.setState({
                sliderIndexValue: null,
                sliderFillerWidth: null,
            })
            this.lastX = null;
        }
        if (this.onMoveMark && this.state.beginMark) {
            this.setLockScrollbar(false)
            if (this.props.isVisionDistribute) {
                let markResult = this.getMarkResult()
                if (markResult.x >= 0 && markResult.y >= 0
                    && markResult.width >= MIN_MARK_EDGE && markResult.width <= IMAGE_PREVIEW_WIDTH
                    && markResult.height >= MIN_MARK_EDGE && markResult.height <= IMAGE_PREVIEW_HEIGHT) {
                    const resizeWidth = this.props.imageWidth / IMAGE_PREVIEW_WIDTH;
                    const resizeHeight = this.props.imageHeight / IMAGE_PREVIEW_HEIGHT;
                    this.props.selectVisionColor(
                        Math.round(markResult.x * resizeWidth),
                        Math.round(markResult.y * resizeHeight),
                        Math.round(markResult.width * resizeWidth),
                        Math.round(markResult.height * resizeHeight),
                    )
                    this.setState({ fixMark: true })
                    this.cancelTimeout = setTimeout(() => { this.initMarkTag(); }, CACNEL_MARK_TIMEOUT)
                } else {
                    this.initMarkTag();
                }
            }
            this.onMoveMark = false
            this.lastX = null;
            this.lastY = null;
        }
    }

    calculateMove(move, min, max) {
        if (min < move && move < max) {
            return move
        } else if (min >= move) {
            return min
        } else {
            return max
        }
    }

    // refreshTagList() {
    //     this.setState({ tagList: this.props.isVisionDistribute ? this.props.getImageTagList : [] });
    // }

    refreshListState(list) {
        if (!this.props.isVisionDistribute) return;
        this.props.vm.setCvTagSettings(list);
        this.setState({ dataList: list });
        if (this.props.isVisionDistribute) {
            console.log('refreshListState ', list)
            this.props.setVisionTagSetting(list);
            store.set('visionData', {
                [COLOR_DATA_LIST]: list
            })
        }
    }

    setColorDataValue(index, value) {
        let list = parseDataLanguage(this.state.dataList)
        list[index].bar = value;
        this.setState({ value: value });
        this.refreshListState(list)
    }

    clearColorDataValue(index) {
        let list = parseDataLanguage(this.state.dataList);
        list[index] = { color_name: null, bar: 5.0, bgr: [-1, -1, -1], lowerbound: [-1, -1, -1], upperbound: [-1, -1, -1] }
        this.setState({ sliderIndex: null });
        this.refreshListState(list);
    }

    createTagLayout() {
        if (!this.props.getImageTagList || !Array.isArray(this.props.getImageTagList) || this.props.getImageTagList.length < 1) return null;
        return this.props.getImageTagList.map((data, index) =>
            <DetectedColorTag
                key={index}
                detectedTag={data}
                index={index + 1}
                enableTag={this.props.enableTag}
                imageWidth={this.props.imageWidth}
                imageHeight={this.props.imageHeight}
            />
        )
    }

    expandSliderbar(sliderIndex) {
        this.setState({ sliderIndex: (this.state.sliderIndex && this.state.sliderIndex == sliderIndex) ? null : sliderIndex })
    }

    setListenerEnable(enable) {
        this.onMoveSliderBar = enable
    }

    setClickListenerEnable(enable) {
        this.onClickSliderBarListener = enable
    }

    onClickReplaceData(index) {
        this.onClickReplace = true;
        let list = parseDataLanguage(this.state.dataList);
        if (list[index].color_name) {
            this.replaceData(index)
        } else {
            this.onClickEditColorName(index)
        }
    }

    replaceData(index) {
        let list = parseDataLanguage(this.state.dataList);
        let selectColor = this.props.getSelectedColor;
        console.log('replaceData ', selectColor)
        if (Array.isArray(selectColor) && selectColor.length == 3) {
            list[index].bgr = selectColor[0]
            list[index].upperbound = selectColor[1];
            list[index].lowerbound = selectColor[2];
        }
        this.onClickReplace = false;
        this.refreshListState(list);
        this.switchFreezeState(false);
        this.initMarkTag();
    }

    getMarkResult() {
        let x = this.state.markTagX;
        let y = this.state.markTagY;
        let w = this.state.markTagWidth;
        let h = this.state.markTagHeight;
        if (w < 0) x = x + w;
        if (h < 0) y = y + h;
        let result = {
            x: parseFixedToFloat(x),
            y: parseFixedToFloat(y),
            width: parseFixedToFloat(Math.abs(w)),
            height: parseFixedToFloat(Math.abs(h)),
            cx: parseFixedToFloat(x + (Math.abs(w) / 2)),
            cy: parseFixedToFloat(y + (Math.abs(h)) / 2)
        }
        return result;
    }

    onClickSliderBar(subEvent) {
        let objSliderBar = document.getElementById('SliderBar' + (this.state.sliderIndex));
        let objPosition = this.getObjPosition(objSliderBar);
        var mainEvent = subEvent ? subEvent : window.event;
        let windowX = 0
        if (isPad()) {
            windowX = subEvent.pageX;
        } else {
            windowX = mainEvent.clientX
        }

        if (isReactNative()) {
            windowX = subEvent.touches[0].clientX;
        }
        let dx = windowX - objPosition.x
        let value = parseFixedToFloat(parseFloat(dx * 10 / SLIDER_WIDTH));

        if (value < 1) value = 0.1;
        if (value > 10) value = 10;
        let width = parseFixedToFloat(this.calculateMove(value, 0, SLIDER_WIDTH), 1);
        this.setState({
            sliderIndexValue: value,
            sliderFillerWidth: width,
        })
        this.setColorDataValue(this.state.sliderIndex - 1, value)
    }

    getObjPosition(element) {
        var x = 0;
        var y = 0;
        while (element) {
            x += element.offsetLeft - element.scrollLeft + element.clientLeft;
            y += element.offsetTop - element.scrollLeft + element.clientTop;
            element = element.offsetParent;
        }

        return { x: x, y: y };
    }

    createColorSettingLayout() {
        const isVisionDistribute = this.props.isVisionDistribute
        return this.state.dataList.map((data, index) =>
            <ColorData
                key={index}
                index={index + 1}
                listLength={this.state.dataList.length}
                name={data.color_name}
                value={data.bar.toFixed(1)}
                clearColorDataValue={() => isVisionDistribute ? this.clearColorDataValue(index) : null}
                expandSliderbar={() => isVisionDistribute && data.color_name ? this.expandSliderbar(index + 1) : null}
                setListenerEnable={() => isVisionDistribute ? this.setListenerEnable(true) : null}
                sliderIndex={this.state.sliderIndex}
                indexValue={this.state.sliderIndexValue}
                replaceData={() => isVisionDistribute ? this.onClickReplaceData(index) : null}
                isImageFreezed={this.state.isImageFreezed}
                isVisionDistribute={isVisionDistribute}
                isFixMark={this.state.fixMark}
                enableClickListenerEnable={() => this.setClickListenerEnable(true)}
                isWWVersion={this.props.isWWVersion}
                onClickEditColorName={(index) => this.onClickEditColorName(index)}
            />
        )
    }

    switchFreezeState(force) {
        const nextFreezeState = force && force != undefined ? force : !this.state.isImageFreezed
        if (!this.props.isVisionDistribute) return;
        if (this.cancelTimeout) clearTimeout(this.cancelTimeout)
        this.setState({ isImageFreezed: nextFreezeState, freezedStream: this.props.realtimeImage })
        if (this.props.isVisionDistribute) {
            if (nextFreezeState == false) {
                this.setLockScrollbar(false);
                this.initMarkTag();
                this.props.startVisionRealtime('VisionColor.switchFreezeState nextFreezeState == false')
            } else {
                this.moveScrollbarToTop()
                this.props.stopVisionRealtime('VisionColor.switchFreezeState nextFreezeState == true')
            }
        }
    }

    isEnVersion() {
        return store.get("locale", DEFAULT_LOCALE) == "en";
    }

    createFreezeButton() {
        return (
            <div className={classNames(!this.state.isImageFreezed ?
                visionSettingStyles.freezeButton :
                visionSettingStyles.endFreezeButton, (this.props.isVisionDistribute) ? null : visionSettingStyles.disable)}
                onClick={() => (this.props.isVisionDistribute) ? this.switchFreezeState(!this.state.isImageFreezed) : {}}
                style={this.isEnVersion() ? { padding: "0 6px" } : {}}>
                {(!this.state.isImageFreezed) ? '＋ ' : '× '}
                <FormattedMessage
                    id={(!this.state.isImageFreezed) ? "gui.dialog.vision.color.add.tag" : "gui.dialog.vision.color.end.tag"}
                />
            </div>)
    }

    getDetectedColorTagInfo() {
        const list = this.props.getImageTagList;
        const isNone = !this.props.isVisionDistribute || !list || !Array.isArray(list) || list.length == 0 || list[0].type !== TAG_TYPE.color
        if (isNone) {
            return <div><FormattedMessage id="gui.dialog.vision.color.detection.result.none" /></div>
        }
        return this.props.getImageTagList.map((data, index) => {
            if (data.tag) {
                let tag = parseTag(data)
                return <div key={index}>{`${(index + 1)}. ${tag.tag}, Width:${tag.width}, Height:${tag.height}, CX: ${tag.center_x}, CY: ${tag.center_y}`}</div>
            }
        }
        )
    }
    onClickEditColorName(index) {
        if (!this.props.isVisionDistribute) return;
        let list = parseDataLanguage(this.state.dataList);
        console.log(`onClickEditColorName ${index}. ${list[index].color_name}`)
        let tempName = list[index].color_name ? list[index].color_name : this.getDefultColorName(index + 1)
        this.setState({ showEditDialog: true, editIndex: index, tempEditName: tempName, sliderIndex: null })

    }
    onConfirmEditDialog() {
        let list = parseDataLanguage(this.state.dataList);
        let index = this.state.editIndex
        list[index].color_name = this.state.tempEditName;
        if (this.onClickReplace) {
            this.props.vm.setCvTagSettings(list);
            this.setState({ dataList: list });
            setTimeout(() => { this.replaceData(index) }, STATE_COMMAND_DELAY)
        } else {
            this.refreshListState(list);
        }
        this.onCancelEditDailog()
    }
    onCancelEditDailog() {
        this.onClickReplace = false;
        this.setState({ showEditDialog: false, editIndex: -1, tempEditName: null, sliderIndex: null })
    }

    onNameChange(event) {
        let input = event.target.value
        this.setState({ tempEditName: input });
    }

    isNameValid(name) {
        if (!name || typeof name !== 'string') return VISION_NAME_RULE.EMPTY;
        name = name.replace(/^\s*/, "");
        if (name.length === 0) return VISION_NAME_RULE.EMPTY;
        let valid = VISION_NAME_RULE.VALID;
        let regSp = /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\_)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/;
        for (let i = 0; i < name.length; i++) {
            if (regSp.test(name[i])) return VISION_NAME_RULE.INVALID;
        }
        const colorList = this.state.dataList
        if (Array.isArray(colorList) && colorList.length > 0) {
            colorList.forEach((data, index) => { if (data.color_name == name && index != this.state.editIndex) valid = VISION_NAME_RULE.DUPLICATE; })
        }
        return valid
    }

    getNameInvalidId(nameValidCode) {
        if (nameValidCode == VISION_NAME_RULE.DUPLICATE) return "gui.dialog.vision.color.edit.name.reminder.duplicate"
        if (nameValidCode == VISION_NAME_RULE.EMPTY) return "gui.dialog.vision.color.edit.name.reminder.empty"
        if (nameValidCode == VISION_NAME_RULE.INVALID) return "gui.dialog.vision.color.edit.name.reminder.symbol"
        return null
    }

    getDefultColorName(index) {
        return EditUtils.getLocaleString("gui.dialog.vision.color.name.default") + index
    }

    enableReminderDialog(enable) {
        this.setState({ showReminderDialog: enable })
    }

    render() {
        return (
            <div className={classNames(visionSettingStyles.visionContentArea)}
                id={'visionContentArea'}
            >
                <div className={classNames(visionSettingStyles.visionArea)}>
                    <div className={classNames(visionSettingStyles.visionBarArea)}>
                        <div className={classNames(visionSettingStyles.enableTagTitle)}>
                            <FormattedMessage
                                defaultMessage="Enable Tag"
                                description="Enable Tag Display"
                                id="gui.dialog.vision.color.tag.display"
                            />
                            <div className={classNames(visionSettingStyles.tagArea, this.props.enableTag ? visionSettingStyles.enableTagArea : visionSettingStyles.disableTagArea)}
                                onClick={() => this.props.switchTagInfo()}>
                                <div className={classNames(visionSettingStyles.enableButton,
                                    this.props.enableTag ? visionSettingStyles.enableTag : visionSettingStyles.disableTag)} />
                            </div>
                        </div>

                    </div>
                    <div className={classNames(visionSettingStyles.visionImgArea)}>
                        {(this.props.isVisionDistribute && this.props.realtimeImage) ? <img src={this.state.isImageFreezed ? this.state.freezedStream : this.props.realtimeImage} id={'visionImg'} alt={"vision image"}
                            className={classNames(visionSettingStyles.visionImgSize)} /> :
                            <div className={classNames(visionSettingStyles.visionDisconnected)}>
                                <div className={classNames(visionSettingStyles.visionDisconnectedText)}>
                                    {this.props.getVisionInitStateString && this.props.getVisionInitStateString != "" ? this.props.getVisionInitStateString :
                                        <img src={loadingImg} className={classNames(visionSettingStyles.loading, visionSettingStyles.main)} alt={"loading image"} />}
                                </div>
                            </div>}

                    </div>
                    <div className={classNames(visionSettingStyles.visionImgArea)}>
                        <div className={classNames(visionSettingStyles.relativeArea)}>
                            {this.createTagLayout()}
                            {/* <DetectedColorTag /> */}
                        </div>
                    </div>
                    {this.state.isImageFreezed ?
                        <div className={classNames(visionSettingStyles.visionImgArea, visionSettingStyles.forground)}
                            onMouseDown={() => { if (this.state.fixMark) { this.initMarkTag() } this.onMoveMark = true }}
                            onTouchStart={() => { if (this.state.fixMark) { this.initMarkTag() } this.onMoveMark = true }}
                        >
                            {this.state.beginMark
                                && this.state.markTagX != Number.MIN_SAFE_INTEGER
                                && Math.abs(this.state.markTagWidth) >= MIN_MARK_EDGE
                                && Math.abs(this.state.markTagHeight) >= MIN_MARK_EDGE ?
                                <MarkTag
                                    x={this.state.markTagX}
                                    y={this.state.markTagY}
                                    width={this.state.markTagWidth}
                                    height={this.state.markTagHeight}
                                    cancelMark={() => this.initMarkTag()}
                                /> : null}
                        </div> : null}
                    {this.createFreezeButton()}
                </div >
                <div className={classNames(visionSettingStyles.colorSettingArea)}>
                    <div className={classNames(visionSettingStyles.colorSettingDataArea)}>
                        <div className={classNames(visionSettingStyles.visionDetectionResultTitle)} >
                            <div className={classNames(visionSettingStyles.visionPoint)} />
                            <FormattedMessage
                                id="gui.dialog.vision.color.detection.data.title"
                            />
                            <img src={helpIcon}
                                className={classNames(visionSettingStyles.colorInfoIcon)}
                                onClick={() => { this.enableReminderDialog(true) }}
                                alt={EditUtils.getLocaleString("gui.dialog.vision.color.detection.data.title")} />
                        </div>

                        <div className={classNames(visionSettingStyles.tagTitle, visionSettingStyles.tagNameTitle)}>
                            <FormattedMessage
                                defaultMessage="Tag Name"
                                description="Tag Name"
                                id="gui.dialog.vision.color.tag.name"
                            />
                        </div >
                        <div className={classNames(visionSettingStyles.tagTitle, visionSettingStyles.tagValueTitle)}>
                            <FormattedMessage
                                defaultMessage="Value"
                                description="Detection Value"
                                id="gui.dialog.vision.color.detect.value"
                            />
                        </div >
                        <div className={classNames(visionSettingStyles.tagTitle, visionSettingStyles.tagClearTitle)}>
                            <FormattedMessage
                                defaultMessage="Clear"
                                description="Clear Setting"
                                id="gui.dialog.vision.color.clear"
                            />
                        </div >
                        <div className={classNames(visionSettingStyles.colorSettingListArea,
                            this.props.isVisionDistribute ? null : visionSettingStyles.disable)}
                            id={'colorSettingListArea'}>
                            {this.createColorSettingLayout()}
                        </div>
                    </div>
                    <div className={classNames(visionSettingStyles.visionDetectionResultTitle, visionSettingStyles.color)} >
                        <div className={classNames(visionSettingStyles.visionPoint)} />
                        <FormattedMessage
                            id="gui.dialog.vision.color.detection.result.title"
                        />
                    </div>
                    <div className={classNames(visionSettingStyles.colorDataDisplayArea)}>
                        <div className={classNames(visionSettingStyles.colorDataDisplayScroll)}>
                            <div className={classNames(visionSettingStyles.colorDataDisplayBlock, this.props.isVisionDistribute ? null : visionSettingStyles.disable)}>
                                {this.getDetectedColorTagInfo()}
                            </div>
                        </div>
                    </div>
                </div >
                {this.state.showEditDialog ? <EditNameDialog
                    show={this.state.showEditDialog && this.state.editIndex != -1}
                    index={this.state.editIndex}
                    name={this.state.tempEditName}
                    onNameChange={(event) => { this.onNameChange(event) }}
                    onClickConfirm={() => { this.onConfirmEditDialog() }}
                    onClickCancel={() => { this.onCancelEditDailog() }}
                    nameValidCode={this.isNameValid(this.state.tempEditName)}
                    titleId={"gui.dialog.vision.color.edit.name.title"}
                    reminderId={this.getNameInvalidId(this.isNameValid(this.state.tempEditName))}
                /> : null}
                {(this.state.showReminderDialog) ? <ReminderDialog
                    show={true}
                    onClickConfirm={() => { this.enableReminderDialog(false) }}
                    titleId="gui.dialog.vision.color.reminder.title"
                    msgId="gui.dialog.vision.color.reminder.msg"
                /> : null}
            </div >
        );

    }
}

const MarkTag = props => {
    let recMargin = calculateMargin(props.width, props.height)
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");
    ctx.font = "16px Arial";
    let text = ctx.measureText('＋');
    return (
        <div style={{
            marginLeft: props.x + 'px',
            marginTop: props.y + 'px',
            position: 'absolute'
        }}>
            <div className={classNames(visionSettingStyles.relativeArea)}>
                <div style={{
                    marginLeft: recMargin.x + Math.abs(props.width) - 12 + 'px',
                    marginTop: recMargin.y - 12 + 'px',
                    position: 'absolute',
                    cursor: 'pointer',
                    zIndex: 2
                }}
                    onClick={() => props.cancelMark()}>
                    <img src={cancel_icon} alt={"cancel icon"} />
                </div>

                <div className={classNames(visionSettingStyles.markRectangle)}
                    style={{
                        width: Math.abs(props.width),
                        height: Math.abs(props.height),
                        marginLeft: recMargin.x + 'px',
                        marginTop: recMargin.y + 'px',
                        position: 'absolute'
                    }} />
            </div>
        </div>
    )
}

const calculateMargin = (w, h) => {
    if (w >= 0 && h >= 0) return { x: 0, y: 0 };
    if (w < 0 && h >= 0) return { x: w, y: 0 };
    if (w >= 0 && h < 0) return { x: 0, y: h };
    if (w < 0 && h < 0) return { x: w, y: h };
}

const ColorData = props => {
    return (
        <div className={classNames(visionSettingStyles.colorDataArea)}>
            {(props.isImageFreezed ?
                <div className={classNames(visionSettingStyles.replaceBtn, props.isFixMark ? null : visionSettingStyles.disable)}
                    onClick={() => props.isFixMark ? props.replaceData() : {}}>
                    <FormattedMessage
                        id={props.name ? "gui.dialog.vision.color.replace" : "gui.dialog.vision.color.add"} />
                </div>
                : null)}
            <div className={classNames(visionSettingStyles.colorIndex)}
                style={(props.isImageFreezed) ? { marginLeft: '77px' } : null}>{props.index + '.'}</div >
            <div className={classNames(visionSettingStyles.colorName)}
                style={(props.isImageFreezed) ? { marginLeft: '95px', width: '100px' } : null}>
                <div className={classNames(visionSettingStyles.colorNameInput, props.isVisionDistribute && props.name ? null : visionSettingStyles.disable, props.isImageFreezed ? visionSettingStyles.freezeColorNameInput : null)}
                >{props.name ? props.name : <FormattedMessage id="gui.dialog.vision.color.name.empty.default" />}
                </div>
                {props.name ?
                    <div className={classNames(visionSettingStyles.colorNameEdit)} >
                        <img src={props.isWWVersion ? editWWIcon : editIcon}
                            className={classNames(visionSettingStyles.profileEditIcon, props.isVisionDistribute ? null : visionSettingStyles.disable)}
                            onClick={() => props.onClickEditColorName(props.index - 1)}
                            alt={props.name}
                        />
                    </div>
                    : null}
            </div>
            <div className={classNames(visionSettingStyles.colorDetectValue,
                props.name ? null : visionSettingStyles.disable,
                props.sliderIndex == props.index ? visionSettingStyles.expandSliderbar : null)}
                onClick={() => { props.name ? props.expandSliderbar() : null }}>
                {((props.sliderIndex == props.index) && props.indexValue) ? props.indexValue.toFixed(1) : props.value}
            </div >
            {(props.sliderIndex == props.index) ?
                <SliderBar
                    index={props.index}
                    indexValue={props.indexValue}
                    value={props.value}
                    enableListener={props.setListenerEnable}
                    max={10.0}
                    listLength={props.listLength}
                    enableClickListenerEnable={props.enableClickListenerEnable}
                /> : null}
            <div className={classNames(visionSettingStyles.clearButton, props.isVisionDistribute && props.name ? null : visionSettingStyles.disable)}
                onClick={() => { props.name ? props.clearColorDataValue() : null }}>
                <FormattedMessage
                    defaultMessage="Clear"
                    description="Clear Setting"
                    id="gui.dialog.vision.color.clear"
                />
            </div >
        </div>
    )
}


const SLIDER_WIDTH = 158
const INDEX_MARGIN_LEFT = 13
const INDEX_RADIUS = 6

const SliderBar = props => {
    const existValue = props.indexValue && props.indexValue * 1 >= 0 ? props.indexValue : props.value
    let percentage = parseFixedToFloat(((existValue * 1) / props.max), 2)
    return (
        <div className={classNames(visionSettingStyles.sliderBackgroundArea, props.index == props.listLength ? visionSettingStyles.dropup : null)}>
            <div className={classNames(visionSettingStyles.sliderBackground)}>
                <div className={classNames(visionSettingStyles.relativeArea)}>
                    <div id={'SliderBar' + props.index}
                        className={classNames(visionSettingStyles.sliderBarOnClickArea)}
                        onMouseDown={() => { props.enableClickListenerEnable() }}
                        onTouchStart={() => { props.enableClickListenerEnable() }}>
                        <div className={classNames(visionSettingStyles.sliderBar)} />
                        <div className={classNames(visionSettingStyles.sliderBarFiller)}
                            style={{ width: parseFixedToFloat(percentage * SLIDER_WIDTH, 0) + 'px' }} />
                        <div className={classNames(visionSettingStyles.sliderBarIndex)}
                            style={{ marginLeft: parseFixedToFloat((percentage * SLIDER_WIDTH) - INDEX_RADIUS, 0) + 'px' }}
                            onMouseDown={() => { props.enableListener() }}
                            onTouchStart={() => { props.enableListener() }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

VisionColor.propTypes = {
    initVision: PropTypes.func,
    startVisionRealtime: PropTypes.func,
    stopVisionRealtime: PropTypes.func,
    setVisionTagSetting: PropTypes.func,
    getVisionTagSetting: PropTypes.func,
    selectVisionColor: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    getColorDataList: getColorDataList(state),
    colorDataDefaultList: getColorDataDefaultList(state),
    getImageTagList: getImageTagList(state),
    getSelectedColor: getSelectedColor(state),
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state) && isVisionRecongInitiated(state),
    isVisionColorDefaultListInitiated: isVisionColorDefaultListInitiated(state)
});

const mapDispatchToProps = dispatch => ({
    setColorDataList: (list) => dispatch(setColorDataList(list)),
    clearImageTagList: () => dispatch(setImageTagList([]))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisionColor);