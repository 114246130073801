import { test } from "bowser";

const SET_FILE_LIST = 'scratch-gui/fileList/SET_FILE_LIST';
const SELECT_FILE = 'scratch-gui/fileList/SELECT_FILE';

const FILE_INDEX = "fileIndex"
const FILE_LIST = "fileList"

const initialState = {
    [FILE_INDEX]: 0,
    [FILE_LIST]: []
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_FILE_LIST:
            return Object.assign({}, state, {
                [FILE_LIST]: action.list
            });
        case SELECT_FILE:
            return Object.assign({}, state, {
                [FILE_INDEX]: action.fileIndex
            });
        default:
            return state;
    }
};

const setFileList = (list) => ({
    type: SET_FILE_LIST,
    list: list
})

const selectFile = (fileIndex) => ({
    type: SELECT_FILE,
    fileIndex: fileIndex
})

const getFileList = (state) => state.scratchGui.fileList[FILE_LIST];
const getSelectedFile = (state) => state.scratchGui.fileList[FILE_INDEX];

export {
    reducer as default,
    initialState as fileListInitialState,
    setFileList,
    selectFile,
    getFileList,
    getSelectedFile
};
