import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import dialogStyles from '../dialog.css';
import visionTutorialStyles from './vision-tutorial-dialog.css';
import { FormattedMessage } from 'react-intl';
import log from '../../../lib/log.js';
import store from 'store';
import {
    hideVisionTutorialDialog,
    showVisionSettingDialog,
    hideStreamingDialog
} from '../../../reducers/dialog';
import page1Img from '../svg/cv_tutorial_page1.svg';
import page2Img from '../svg/cv_tutorial_page2.svg';
import page3Img from '../svg/cv_tutorial_page3.png';
import page4Img from '../svg/cv_tutorial_page4.svg';
const IMAGE_ARRAY = [page1Img, page2Img, page3Img, page4Img]

const PAGE = {
    FIRST: 0,
    SECOND: 1,
    THIRD: 2,
    FOURTH: 3,
    A_PAGE: 1,
    START: 0,
    END: 3
}

class VisionTutorialDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            page: 0,
            imageSrc: page1Img
        }
    }

    onClickBefore() {
        this.setState({ page: this.state.page - PAGE.A_PAGE >= PAGE.START ? this.state.page - PAGE.A_PAGE : this.state.page })
    }

    onClickNext() {
        this.setState({ page: this.state.page + PAGE.A_PAGE <= PAGE.END ? this.state.page + PAGE.A_PAGE : this.state.page })
    }

    onClickFinish() {
        this.onClickSkip();
    }

    onClickSkip() {
        //Launch vision sensor dialog
        store.set('skipVisionTutorial', true);
        this.setState({ page: PAGE.START })
        this.props.showVisionSettingDialog();
        this.props.onClose();
    }

    getFirstHint() {
        switch (this.state.page) {
            case PAGE.FIRST:
                return <FormattedMessage id="gui.dialog.vision.tutorial.page1.hint" />
            case PAGE.SECOND:
                return <FormattedMessage id="gui.dialog.vision.tutorial.page2.hint" />
            case PAGE.THIRD:
                return <FormattedMessage id="gui.dialog.vision.tutorial.page3.hint" />
            case PAGE.FOURTH:
                return <FormattedMessage id="gui.dialog.vision.tutorial.page4.hint" />

        }
    }

    render() {
        return (
            <div className={classNames(dialogStyles.backdropStyle)}>
                <div className={classNames(dialogStyles.modalStyle, dialogStyles.visionTutorial)}>
                    <div className={classNames(dialogStyles.aboutHeader)}>
                        <div className={classNames(dialogStyles.aboutTitle)}>
                            <FormattedMessage
                                defaultMessage="Vision Sensor Tutorial"
                                description="Vision Sensor Tutorial dialog title"
                                id="gui.dialog.vision.tutorial.title"
                            />
                        </div>
                    </div>
                    <div className={classNames(visionTutorialStyles.tutorialContentArea)}>
                        <div className={classNames(visionTutorialStyles.tutorialHintTextArea)}>
                            <div className={classNames(visionTutorialStyles.tutorialHintText, visionTutorialStyles.tutorialHintTextFirstLine)}>
                                {this.getFirstHint()}
                            </div>
                            {(this.state.page == PAGE.FIRST) ? <div className={classNames(visionTutorialStyles.tutorialHintText)}>
                                <FormattedMessage
                                    defaultMessage="And connect the vision sensor to the Type-C port of the Entry."
                                    description="Before setting up, please connect the Entry to the computer, And connect the vision sensor to the Type-C port of the Entry."
                                    id="gui.dialog.vision.tutorial.page1.hint2"
                                />
                            </div> : null}
                        </div>
                        <div ><img src={IMAGE_ARRAY[this.state.page]} className={classNames(visionTutorialStyles.tutorialImg)} /></div>
                        <div className={classNames(visionTutorialStyles.tutorialButtonArea)}>
                            {(this.state.page > PAGE.START) ? <div className={classNames(visionTutorialStyles.tutorialLeftButton)}
                                onClick={() => this.onClickBefore()}>
                                <FormattedMessage
                                    defaultMessage="Before"
                                    description="Before step"
                                    id="gui.userGuide.button.before"
                                />
                            </div> : null}
                            {(this.state.page < PAGE.END) ? <div className={classNames(this.state.page == 0 ? visionTutorialStyles.tutorialCenterButton : visionTutorialStyles.tutorialRightButton)}
                                onClick={() => this.onClickNext()}>
                                <FormattedMessage
                                    defaultMessage="Next"
                                    description="Next step"
                                    id="gui.userGuide.button.next"
                                />
                            </div> : null}
                            {(this.state.page == PAGE.END) ? <div className={classNames(visionTutorialStyles.tutorialRightButton)}
                                onClick={() => this.onClickFinish()}>
                                <FormattedMessage
                                    defaultMessage="Finish"
                                    description="Finish"
                                    id="gui.deviceManagerStage.page.done"
                                />
                            </div> : null}
                            <div className={classNames(visionTutorialStyles.tutorialSkipButton)}
                                onClick={() => this.onClickSkip()}>
                                <FormattedMessage
                                    defaultMessage="Skip"
                                    description="Skip Tutorial"
                                    id="gui.userGuide.welcome.button.skip"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

VisionTutorialDialog.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool,
};

VisionTutorialDialog.defaultProps = {
    onClose: () => { },
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(hideVisionTutorialDialog()),
    showVisionSettingDialog: () => {
        dispatch(hideStreamingDialog())
        dispatch(showVisionSettingDialog())
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VisionTutorialDialog);