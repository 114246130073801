const drivetrainBlocks = (function () {
    const drivetrainDrive =
        `<block type="drivetrain_drive"/>`;

    const drivetrainDrivefor =
        `<block type="drivetrain_drivefor">
            <value name="DISTANCE">
                <shadow type="math_number">
                    <field name="NUM">100</field>
                </shadow>
            </value>
        </block>`;

    const drivetrainTurn =
        `<block type="drivetrain_turn"/>`;

    const drivetrainTurnfor =
        `<block type="drivetrain_turnfor">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
            </value>
        </block>`;

    const drivetrainTurntoHeading =
        `<block type="drivetrain_turntoheading">
            <value name="DEGREES">
                <shadow type="degree_circle">
                    <field name="DEGREE">90</field>
                </shadow>
            </value>
        </block>`;

    const drivetrainTurntoRotation =
        `<block type="drivetrain_turntorotation">
            <value name="DEGREES">
                <shadow type="degree_circle_rotation">
                    <field name="DEGREE">90</field>
                </shadow>
            </value>
        </block>`;

    const drivetrainStopDriving =
        `<block type="drivetrain_stopdriving"/>`;

    const drivetrainSetDriveVelocityto =
        `<block type="drivetrain_setdrivevelocityto">
            <value name="DEGREE">
                <shadow type="degree">
                    <field name="DEGREE">50</field>
                </shadow>
            </value>
        </block>`;

    const drivetrainSetTurnVelocityto =
        `<block type="drivetrain_setturnvelocityto">
            <value name="DEGREE">
                <shadow type="degree">
                    <field name="DEGREE">50</field>
                </shadow>
            </value>
        </block>`;

    const drivetrainSetDriveVelocitytoEntry =
        `<block type="drivetrain_setdrivevelocityto_entry">
            <value name="NUMBER">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>
        </block>`;

    const drivetrainSetTurnVelocitytoEntry =
        `<block type="drivetrain_setturnvelocityto_entry">
            <value name="NUMBER">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>
        </block>`;

    const drivetrainSetDriveStoppingTo =
        `<block type="drivetrain_setdrivestoppingto"/>`;

    const drivetrainSetDriveTimeoutTo =
        `<block type="drivetrain_setdrivetimeoutto">
            <value name="SECONDS">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>`;

    const drivetrainSetDriveHeadingTo =
        `<block type="drivetrain_setdriveheadingto">
            <value name="DEGREE">
                <shadow type="degree_circle">
                    <field name="DEGREE">0</field>
                </shadow>
            </value>
        </block>`;

    const drivetrainSetDriveRotationTo =
        `<block type="drivetrain_setdriverotationto">
            <value name="DEGREE">
                <shadow type="degree_circle_rotation">
                    <field name="DEGREE">0</field>
                </shadow>
            </value>
        </block>`;

    return {
        drivetrain_drive: drivetrainDrive,
        drivetrain_drivefor: drivetrainDrivefor,
        drivetrain_turn: drivetrainTurn,
        drivetrain_turnfor: drivetrainTurnfor,
        drivetrain_turntoheading: drivetrainTurntoHeading,
        drivetrain_turntorotation: drivetrainTurntoRotation,
        drivetrain_stopdriving: drivetrainStopDriving,
        drivetrain_setdrivevelocityto: drivetrainSetDriveVelocityto,
        drivetrain_setturnvelocityto: drivetrainSetTurnVelocityto,

        drivetrain_setdrivevelocityto_entry: drivetrainSetDriveVelocitytoEntry,
        drivetrain_setturnvelocityto_entry: drivetrainSetTurnVelocitytoEntry,
        drivetrain_setdrivestoppingto: drivetrainSetDriveStoppingTo,
        drivetrain_setdrivetimeoutto: drivetrainSetDriveTimeoutTo,
        drivetrain_setdriveheadingto: drivetrainSetDriveHeadingTo,
        drivetrain_setdriverotationto: drivetrainSetDriveRotationTo
    };
})();

export default drivetrainBlocks;


