const UPDATE_LIST = 'scratch-gui/bluetooth/UPDATE_LIST';
const BLUETOOTH_LIST = "bluetoothList";

const initialState = {
    [BLUETOOTH_LIST]: []
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case UPDATE_LIST:
            return Object.assign({}, state, {
                [BLUETOOTH_LIST]: action.list
            });
        default:
            return state;
    }
};

const updateList = list => ({
    type: UPDATE_LIST,
    list: list
});

const getList = state => state.scratchGui.bluetooth[BLUETOOTH_LIST];

export {
    reducer as default,
    initialState as bluetoothInitialState,
    updateList,
    getList
}