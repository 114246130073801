exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Noto+Sans+SC&display=swap);", ""]);

// module
exports.push([module.id, "html,\nbody,\n.index_app_3Qs6X {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 800px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n\n    overflow-x: hidden;\n    \n    font-weight: normal;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: normal;\n    letter-spacing: normal;\n    font-family: 'Roboto', 'Noto Sans TC', 'Noto Sans SC', '\\5FAE\\8EDF\\6B63\\9ED1\\9AD4', 'sans-serif';\n    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n::-webkit-scrollbar {\n    width: 10px;\n    height: 10px;\n}\n::-webkit-scrollbar-thumb {\n    border-radius: 5px;\n    border: solid 2px transparent;\n    -webkit-box-shadow: inset 0 0 10px 10px #CECDCE;\n            box-shadow: inset 0 0 10px 10px #CECDCE;\n}\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n* { -webkit-box-sizing: border-box; box-sizing: border-box; }\n", ""]);

// exports
exports.locals = {
	"app": "index_app_3Qs6X"
};