import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './vr-viewer.css';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import store from 'store';

import {
    getWorkspace
} from '../../reducers/block';


import { DEFAULT_LOCALE } from '../../config/project-config';
import {
    viewPage,
    setFullScreen,
    getFullScreen
} from '../../reducers/stage-size';
import { STAGE_SIZE_MODES } from '../../lib/layout-constants';

import {
    BRAIN_TYPE
} from '../../lib/brains';

import deviceIndex from '../../../static/help/deviceInfo/index.json'

import bumperIcon from './svg/device-bumper.svg';
import buzzerIcon from './svg/device-buzzer.svg';
import linetrackerIcon from './svg/device-linetracker.svg';
import drivetrainIcon from './svg/device-drivetrain.svg';
import sonicIcon from './svg/device-sonic.png';
import ledIcon from './svg/device-led.svg';
import moreIcon from './svg/more.svg';

import {
    errorType,
    showErrorDialog
} from '../../reducers/dialog';

import {
    setUnityMessageFunc,
    setVRSaveFile,
    setVRUnityContextLoadingProgress,
    setVRLangChange,
    getVRLangChange,
    setVRIsReady,
    getVRIsReady
} from '../../reducers/vr';
import { EditUtils } from '../device-manager/edit-page/edit-utils.js';

const messages = defineMessages({
    bumper: {
        defaultMessage: "Bumper Sensor",
        description: "Bumper Sensor",
        id: "gui.device.bumper"
    },
    led: {
        defaultMessage: "LED light",
        description: "LED light",
        id: "gui.device.led"
    },
    lineTracker: {
        defaultMessage: "Tracker sensor",
        description: "Tracker sensor",
        id: "gui.device.lineTracker"
    },
    buzzer: {
        defaultMessage: "Buzzer",
        description: "buzzer",
        id: "gui.device.buzzer"
    },
    drivetrain: {
        defaultMessage: "Driver Train",
        description: "drivetrain",
        id: "gui.device.drivetrain"
    },
    ultrasonic: {
        defaultMessage: "Ultrasonic",
        description: "ultrasonic",
        id: "gui.device.ultrasonic"
    },
    close: {
        defaultMessage: "Close",
        description: "close button text",
        id: "gui.vr.close"
    },
    led_on: {
        defaultMessage: "On",
        description: "led on",
        id: "gui.vr.led.on"
    },
    led_off: {
        defaultMessage: "Off",
        description: "led off",
        id: "gui.vr.led.off"
    },
    motor_forward: {
        defaultMessage: "Forward",
        description: "motor forward",
        id: "gui.vr.motor.forward"
    },
    motor_backward: {
        defaultMessage: "Backward",
        description: "motor backward",
        id: "gui.vr.motor.backward"
    },
    motor_left: {
        defaultMessage: "Left",
        description: "motor left",
        id: "gui.vr.motor.left"
    },
    motor_right: {
        defaultMessage: "Right",
        description: "motor right",
        id: "gui.vr.motor.right"
    },
    motor_stop: {
        defaultMessage: "Stop",
        description: "motor stop",
        id: "gui.vr.motor.stop"
    },
    bumper_trigger: {
        defaultMessage: "Trigger",
        description: "bumper trigger",
        id: "gui.vr.bumper.trigger"
    },
    bumper_no_trigger: {
        defaultMessage: "No trigger",
        description: "bumper no trigger",
        id: "gui.vr.bumper.noTrigger"
    },
    buzzer_note: {
        defaultMessage: "Note",
        description: "buzzer note",
        id: "gui.vr.buzzer.note"
    },
    buzzer_beat: {
        defaultMessage: "Beat",
        description: "buzzer beat",
        id: "gui.vr.buzzer.beat"
    },
    buzzer_music: {
        defaultMessage: "Play music",
        description: "buzzer music",
        id: "gui.vr.buzzer.music"
    },
    buzzer_littlestar: {
        defaultMessage: "little star",
        description: "buzzer music",
        id: "gui.vr.buzzer.music.littlestar"
    },
    buzzer_win: {
        defaultMessage: "win",
        description: "buzzer music",
        id: "gui.vr.buzzer.music.win"
    },
    buzzer_lose: {
        defaultMessage: "lose",
        description: "buzzer music",
        id: "gui.vr.buzzer.music.lose"
    },
    line_left: {
        defaultMessage: "Left: ",
        description: "line left",
        id: "gui.vr.line.left"
    },
    line_center: {
        defaultMessage: "Center: ",
        description: "line center",
        id: "gui.vr.line.center"
    },
    line_right: {
        defaultMessage: "Right: ",
        description: "line right",
        id: "gui.vr.line.right"
    },
    line_detected: {
        defaultMessage: "Detected",
        description: "line detected",
        id: "gui.vr.line.detected"
    },
    line_no_detected: {
        defaultMessage: "No detected",
        description: "line no detected",
        id: "gui.vr.line.noDetected"
    },
    sonar_object: {
        defaultMessage: "There is an object in front",
        description: "sonar find object",
        id: "gui.vr.sonar.object"
    },
    sonar_no_object: {
        defaultMessage: "No objects found",
        description: "sonar not find object",
        id: "gui.vr.sonar.noObject"
    },
    sonar_distance: {
        defaultMessage: "Objects found {distance} mm from the front",
        description: "sonar distance object",
        id: "gui.vr.sonar.distance"
    }
});

class VRViewer extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'onClickDeviceMore',
            'onClickCloseDeviceMore',
            'onBumperStateChange',
            'onObjectDistanceUpdate',
            'onSonarStateChange',
            'onIRreflectiveUpdate',
            'start',
            'stop',
            'reset',
            'zoomIn',
            'zoomOut',
            'onLedStatusChange',
            'onMotorStatusChange',
            'onBuzzerStatusChange',
            'onBeepStatus',
            'unityContextProgress',
            'unityContextLoaded',
            'endInit',
            'onSaveFile',
            'showDeviceHeight'
        ]);
        this.state = {
            device_description: null,
            led_status: false,
            motor_status: messages.motor_stop,
            bumper_status: false,
            object_distance: 0,
            object_detect: false,
            buzzer_status: {
                note: 60,
                beat: 60,
                music: ""
            },
            time: 0,
            ir_status_left: false,
            ir_status_center: false,
            ir_status_right: false,
        };

        props.vm.setVRPostMessageFunc(this.postVRMessage);
        this.list = this.parseJson();
    }

    componentDidMount() {
        this.props.setFullScreen(false, viewPage.vr);
        this.onMsgCallback = this.onMsg.bind(this);
        window.addEventListener("message", this.onMsgCallback);
        this.props.vm.runtime.addListener('led_status_change', this.onLedStatusChange);
        this.props.vm.runtime.addListener('motor_status_change', this.onMotorStatusChange);
        this.props.vm.runtime.addListener('buzzer_status_change', this.onBuzzerStatusChange);
        this.props.vm.on('LOAD_DEVICE_FINISHED', this.reset);

        this.props.setUnityMessageFunc(this.postVRMessage);
    }

    componentDidUpdate(preProps, preState) {
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.onMsgCallback);
        this.props.vm.runtime.removeListener('led_status_change', this.onLedStatusChange);
        this.props.vm.runtime.removeListener('motor_status_change', this.onMotorStatusChange);
        this.props.vm.runtime.removeListener('buzzer_status_change', this.onBuzzerStatusChange);
    }

    onMsg(event) {
        if (typeof event.data !== "object") return;
        if (!event.data.type) return;
        if (event.data.message !== "HulkScratchVR") return;
        switch (event.data.type) {
            case "loaded":
                this.unityContextLoaded();
                break;
            case "start":
                this.start();
                break;
            case "stop":
                this.stop();
                break;
            case "reset":
                this.reset();
                break;
            case "bumper_state_change":
                this.onBumperStateChange(event.data.state);
                break;
            case "object_distance_update":
                this.onObjectDistanceUpdate(event.data.state);
                break;
            case "sonar_state_change":
                this.onSonarStateChange(event.data.state);
                break;
            case "playing_time_update":
                this.onPlayingTimeUpdate(event.data.state);
                break;
            case "ir_reflective_update":
                this.onIRreflectiveUpdate(event.data.state);
                break;
            case "beep_status":
                this.onBeepStatus(event.data.state);
                break;
            case "maximize_window":
                this.zoomIn();
                break;
            case "minimize_window":
                this.zoomOut();
                break;
            case "save_file":
                this.onSaveFile(event.data.state);
                break;
            case "progress":
                this.unityContextProgress(event.data.state);
                break;
            case "end_init":
                this.endInit();
                break;
        }
    }

    postVRMessage(cmd, func, msg) {
        var iframe = document.getElementById('unityIFrame');
        iframe.contentWindow.postMessage({
            command: cmd,
            func: func,
            message: msg
        }, '*');
    }

    onBumperStateChange(press) {
        // console.log("bumper_state_change press = ", press);
        this.props.vm.runtime.emit('UPDATE_BUMPER_STATE', press);
        if (this.state.bumper_status != press) {
            this.setState({
                bumper_status: press
            });
        }
    }

    onObjectDistanceUpdate(distance) {
        this.props.vm.runtime.emit('UPDATE_SONAR_DISTANCE', distance);
        this.setState({
            object_distance: parseInt(distance)
        });
    }

    onSonarStateChange(isObjectDiscovered) {
        // console.log("sonar_state_change isObjectDiscovered = ", isObjectDiscovered);
        this.props.vm.runtime.emit('UPDATE_SONAR_FIND_OBJECT', isObjectDiscovered);
        if (!isObjectDiscovered) {
            this.props.vm.runtime.emit('UPDATE_SONAR_DISTANCE', 1800);
        }
        if (this.state.object_detect != isObjectDiscovered) {
            this.setState({
                object_detect: isObjectDiscovered
            });
        }
    }

    onPlayingTimeUpdate(time) {
        if (this.state.time != parseInt(time)) {
            this.setState({
                time: parseInt(time)
            })
            this.props.vm.runtime.emit('UPDATE_TIMER', parseInt(time));
        }
    };

    onIRreflectiveUpdate(json) {
        // console.log("ir_reflective_update json = ", json);
        if (json != undefined) {
            let directionArray = ["LightSensorLeft", "LightSensorMid", "LightSensorRight"];
            let direction = ["left", "middle", "right"];
            let value = json.split(",")[1];
            this.props.vm.runtime.emit('UPDATE_IR_REFLECTIVE', direction[directionArray.indexOf(json.split(",")[0])], value);
            let ir_status = parseInt(value) == 0 ? false : true;
            switch (direction[directionArray.indexOf(json.split(",")[0])]) {
                case "left":
                    if (ir_status != this.state.ir_status_left) {
                        this.setState({
                            ir_status_left: ir_status
                        });
                    }
                    break;
                case "middle":
                    if (ir_status != this.state.ir_status_center) {
                        this.setState({
                            ir_status_center: ir_status
                        });
                    }
                    break;
                case "right":
                    if (ir_status != this.state.ir_status_right) {
                        this.setState({
                            ir_status_right: ir_status
                        });
                    }
                    break;
            }
        }
    }

    onLedStatusChange(isOn) {
        // console.log("led_status_change isOn = ", isOn);
        this.setState({
            led_status: isOn
        });
    }

    onMotorStatusChange(status) {
        // console.log("motor_status_change status = ", status);
        if (status == "forward") {
            this.setState({
                motor_status: messages.motor_forward
            });
        } else if (status == "reverse") {
            this.setState({
                motor_status: messages.motor_backward
            });
        } else if (status == "left") {
            this.setState({
                motor_status: messages.motor_left
            });
        } else if (status == "right") {
            this.setState({
                motor_status: messages.motor_right
            });
        } else {
            this.setState({
                motor_status: messages.motor_stop
            });
        }
    }

    onBuzzerStatusChange(note, beat, music) {
        // console.log("buzzer_status_change note = ", note, " beat = ", beat, " music = ", music);
        if (music != "") {
            this.setState({
                buzzer_status: {
                    note: this.state.buzzer_status.note,
                    beat: this.state.buzzer_status.beat,
                    music: music
                }
            });
        } else {
            this.setState({
                buzzer_status: {
                    note: note,
                    beat: beat,
                    music: ""
                }
            });
        }
    }

    onBeepStatus(status) {
        // console.log("onBeepStatus = ", status == "start" ? true : false);
        this.props.vm.runtime.emit('UPDATE_PLAYING_STATUS', status == "start" ? true : false);
    }

    unityContextProgress(progress) {
        console.log("unityContextProgress progress = ", progress);
        // this.props.setVRUnityContextLoadingProgress(progress);
    }

    addUnityContextListener() {
        // this.postVRMessage("unityContextOn", "loaded"); // need to set in VRUnity project
        this.postVRMessage("unityContextOn", "start");
        this.postVRMessage("unityContextOn", "stop");
        this.postVRMessage("unityContextOn", "reset");
        this.postVRMessage("unityContextOn", "bumper_state_change");
        this.postVRMessage("unityContextOn", "object_distance_update");
        this.postVRMessage("unityContextOn", "sonar_state_change");
        this.postVRMessage("unityContextOn", "playing_time_update");
        // this.postVRMessage("unityContextOn", "ir_reflective_update"); // need to set in VRUnity project
        this.postVRMessage("unityContextOn", "beep_status");
        // this.postVRMessage("unityContextOn", "maximize_window"); // need to set in VRUnity project
        // this.postVRMessage("unityContextOn", "minimize_window"); // need to set in VRUnity project
        this.postVRMessage("unityContextOn", "save_file");
        this.postVRMessage("unityContextOn", "progress");
        this.postVRMessage("unityContextOn", "end_init");
    }

    unityContextLoaded() {
        this.addUnityContextListener();
        this.reset();
        setTimeout(() => {
            this.postVRMessage('EventSystem', 'SetLanguage', this.props.locale == "en" ? '2' : this.props.locale == "zh-cn" ? '1' : '0');
            // show vr unity after setting language
            // this.props.setVRUnityContextLoadingProgress(1);
            this.props.onLoadFinish();
            let element = document.getElementById("loadingBackground");
            element.remove();
        }, 1000);
        setTimeout(() => {
            if (this.props.getVRLangChange) {
                // reload vr map after change language
                this.props.setVRLangChange(false);
                this.postVRMessage('EventSystem', 'OpenFile', this.props.vm.getVRJson());
            }
        }, 5000);
    }

    onSaveFile(json) {
        console.log("onSaveFile json = ", json);
        this.props.vm.setVRJson(json);
        this.props.setVRSaveFile(true);
    }

    endInit() {
        console.log("vr end init");
        this.props.setVRIsReady(true);
    }

    parseJson() {
        let type = BRAIN_TYPE.WEB_VR;
        let language = store.get("locale", DEFAULT_LOCALE);
        let supportDeviceList = deviceIndex.support[type];
        let list = [];
        Object.entries(deviceIndex.deviceInfo).forEach((device) => {
            if (supportDeviceList && supportDeviceList.includes(device[0])) {
                list.push({
                    type: device[0],
                    name: device[1][language].name,
                    description: device[1][language].description,
                    hint: device[1][language].hint,
                    update: device[1].update,
                    icon: device[1].icon
                })
            }
        })
        return list;
    }

    onClickDeviceMore(device_id) {
        let content;
        this.list.forEach(device => {
            if (device.type == device_id) {
                content = device
            }
        });
        this.setState({
            device_description: content
        });
        this.setScrollbarToTop();
    }

    onClickCloseDeviceMore() {
        this.setState({
            device_description: null
        });
        this.setScrollbarToTop();
    }

    setScrollbarToTop() {
        let content = document.getElementById('vrStatus')
        if (content) {
            content.scrollTop = 0;
            content.scrollLeft = 0;
        }
    }

    start() {
        if (!this.props.vm.greenFlag()) {
            this.postVRMessage('EventSystem', 'Reset', '');
            this.props.onShowBreakPositionErrorDialog();
        }
    }

    stop() {
        this.props.vm.stopAll();
        this.postVRMessage('EventSystem', 'StopDriving', '')
        this.setState({
            motor_status: messages.motor_stop
        });
    }

    reset() {
        this.props.vm.stopAll();
        this.resetVMStatus();
        this.setState({
            led_status: false,
            motor_status: messages.motor_stop,
            bumper_status: false,
            object_distance: 0,
            buzzer_status: {
                note: 60,
                beat: 60,
                music: ""
            },
            ir_status: false,
        });
    }

    zoomIn() {
        this.props.setFullScreen(true, viewPage.vr);
        this.props.getWorkspace.onClearWidget();
    }

    zoomOut() {
        this.props.setFullScreen(false, viewPage.vr);
    }

    resetVMStatus() {
        this.props.vm.runtime.emit('UPDATE_BUMPER_STATE', false);
        this.props.vm.runtime.emit('UPDATE_SONAR_DISTANCE', 1800);
        this.props.vm.runtime.emit('UPDATE_SONAR_FIND_OBJECT', false);
        this.props.vm.runtime.emit('UPDATE_BUZZER_STATE', 60);
        this.props.vm.runtime.emit('RESET_TIMER');
    }

    showDeviceHeight(type) {
        if (this.props.locale == "en") {
            switch (type) {
                case "drivetrain":
                    return styles.drivetrainEn;
                case "bumper":
                    return styles.bumperEn;
                case "lineTracker":
                    return styles.lineTrackerEn;
                case "buzzer":
                    return styles.buzzerEn;
                case "ultrasonic":
                    return styles.ultrasonicEn;
                case "led":
                    return styles.ledEn;
            }
        }
        switch (type) {
            case "drivetrain":
                return styles.drivetrainCh;
            case "bumper":
                return styles.bumperCh;
            case "lineTracker":
                return styles.lineTrackerCh;
            case "buzzer":
                return styles.buzzerCh;
            case "ultrasonic":
                return styles.ultrasonicCh;
            case "led":
                return styles.ledCh;
        }
        return null;
    }

    render() {
        return (
            <div fullscreen={this.props.getVRFullScreen ? "true" : "false"}
                className={classNames(
                    styles.vrBody,
                    this.props.stageSize == STAGE_SIZE_MODES.small ? styles.vrBodyHidden : null,
                    this.props.getVRFullScreen ? styles.full : null
                )}
            >
                <div className={styles.vrContent}>
                    <iframe src="static/vr/vrIframe/index.html"
                        id="unityIFrame"
                        height={this.props.getVRFullScreen ? "100%" : "504px"}
                        width="100%"
                        frameBorder="0"
                        scrolling="no"
                        title="pinbo unity" />
                </div>
                <div className={styles.vrStatus} id="vrStatus">
                    {
                        this.state.device_description ? (
                            <div className={classNames(
                                styles.vrDescriptionContent,
                                this.showDeviceHeight(this.state.device_description.type)
                            )}>
                                <div className={styles.vrDescriptionTitle}>
                                    <img className={styles.vrDeviceIcon} draggable="false" src={this.state.device_description.icon} />
                                    <div>{this.state.device_description.name}</div>
                                </div>
                                <div className={styles.vrDescriptionFrame}>
                                    <iframe
                                        src={this.state.device_description.hint}
                                        height="100%"
                                        width="100%"
                                        frameBorder="0"
                                        scrolling="overlay"
                                    />
                                </div>
                                <div className={styles.vrButton} onClick={this.onClickCloseDeviceMore} >
                                    <FormattedMessage {...messages.close} />
                                </div>
                            </div>
                        ) : (
                            <div className={styles.vrFlexRow}>
                                <div className={styles.vrDevice}>
                                    <div className={styles.vrIconContent}>
                                        <img className={styles.vrIcon} src={drivetrainIcon} draggable="false" alt={EditUtils.getLocaleString('gui.device.drivetrain')} />
                                        <img className={styles.vrMoreIcon} src={moreIcon} draggable="false" onClick={() => this.onClickDeviceMore("drivetrain")} alt={'more'} />
                                    </div>
                                    <div className={styles.vrDeviceName}>
                                        <FormattedMessage {...messages.drivetrain} />
                                    </div>
                                    <div className={styles.vrMessage}>
                                        <FormattedMessage {...this.state.motor_status} />
                                    </div>
                                </div>
                                <div className={styles.vrDevice}>
                                    <div className={styles.vrIconContent}>
                                        <img className={styles.vrIcon} src={bumperIcon} draggable="false" alt={EditUtils.getLocaleString('gui.device.bumper')} />
                                        <img className={styles.vrMoreIcon} src={moreIcon} draggable="false" onClick={() => this.onClickDeviceMore("bumper")} alt={'more'} />
                                    </div>
                                    <div className={styles.vrDeviceName}>
                                        <FormattedMessage {...messages.bumper} />
                                    </div>
                                    <div className={styles.vrMessage}>
                                        {
                                            this.state.bumper_status ? (
                                                <FormattedMessage {...messages.bumper_trigger} />
                                            ) : (
                                                <FormattedMessage {...messages.bumper_no_trigger} />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={styles.vrDevice}>
                                    <div className={styles.vrIconContent}>
                                        <img className={styles.vrIcon} src={linetrackerIcon} draggable="false" alt={EditUtils.getLocaleString('gui.device.lineTracker')} />
                                        <img className={styles.vrMoreIcon} src={moreIcon} draggable="false" onClick={() => this.onClickDeviceMore("lineTracker")} alt={'more'} />
                                    </div>
                                    <div className={styles.vrDeviceName}>
                                        <FormattedMessage {...messages.lineTracker} />
                                    </div>
                                    <div className={styles.vrMessage}>
                                        <div className={styles.vrLine}>
                                            <FormattedMessage {...messages.line_left} />
                                            {
                                                this.state.ir_status_left ? (
                                                    <FormattedMessage {...messages.line_detected} />
                                                ) : (
                                                    <FormattedMessage {...messages.line_no_detected} />
                                                )
                                            }
                                        </div>
                                        <div className={styles.vrLine}>
                                            <FormattedMessage {...messages.line_center} />
                                            {
                                                this.state.ir_status_center ? (
                                                    <FormattedMessage {...messages.line_detected} />
                                                ) : (
                                                    <FormattedMessage {...messages.line_no_detected} />
                                                )
                                            }
                                        </div>
                                        <div className={styles.vrLine}>
                                            <FormattedMessage {...messages.line_right} />
                                            {
                                                this.state.ir_status_right ? (
                                                    <FormattedMessage {...messages.line_detected} />
                                                ) : (
                                                    <FormattedMessage {...messages.line_no_detected} />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.vrDevice}>
                                    <div className={styles.vrIconContent}>
                                        <img className={styles.vrIcon} src={buzzerIcon} draggable="false" alt={EditUtils.getLocaleString('gui.device.buzzer')} />
                                        <img className={styles.vrMoreIcon} src={moreIcon} draggable="false" onClick={() => this.onClickDeviceMore("buzzer")} alt={'more'} />
                                    </div>
                                    <div className={styles.vrDeviceName}>
                                        <FormattedMessage {...messages.buzzer} />
                                    </div>
                                    <div className={styles.vrMessage}>
                                        {
                                            this.state.buzzer_status.music == "" ? (
                                                <div>
                                                    <div><FormattedMessage {...messages.buzzer_note} />: {this.state.buzzer_status.note}</div>
                                                    <div><FormattedMessage {...messages.buzzer_beat} />: {this.state.buzzer_status.beat}</div>
                                                </div>
                                            ) : (
                                                <div><FormattedMessage {...messages.buzzer_music} />:
                                                    {
                                                        this.state.buzzer_status.music == "win" ? (<FormattedMessage {...messages.buzzer_win} />) :
                                                            this.state.buzzer_status.music == "lose" ? (<FormattedMessage {...messages.buzzer_lose} />) :
                                                                (<FormattedMessage {...messages.buzzer_littlestar} />)
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={styles.vrDevice}>
                                    <div className={styles.vrIconContent}>
                                        <img className={styles.vrIcon} src={sonicIcon} draggable="false" alt={EditUtils.getLocaleString('gui.device.ultrasonic')} />
                                        <img className={styles.vrMoreIcon} src={moreIcon} draggable="false" onClick={() => this.onClickDeviceMore("ultrasonic")} alt={'more'} />
                                    </div>
                                    <div className={styles.vrDeviceName}>
                                        <FormattedMessage {...messages.ultrasonic} />
                                    </div>
                                    <div className={styles.vrMessage}>
                                        {
                                            this.state.object_detect ? (
                                                <FormattedMessage {...messages.sonar_distance} values={{ distance: this.state.object_distance }} />
                                            ) : (
                                                <FormattedMessage {...messages.sonar_no_object} />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={styles.vrDevice}>
                                    <div className={styles.vrIconContent}>
                                        <img className={styles.vrIcon} src={ledIcon} draggable="false" alt={EditUtils.getLocaleString('gui.device.led')} />
                                        <img className={styles.vrMoreIcon} src={moreIcon} draggable="false" onClick={() => this.onClickDeviceMore("led")} alt={'more'} />
                                    </div>
                                    <div className={styles.vrDeviceName}>
                                        <FormattedMessage {...messages.led} />
                                    </div>
                                    <div className={styles.vrMessage}>
                                        {
                                            this.state.led_status ? (
                                                <FormattedMessage {...messages.led_on} />
                                            ) : (
                                                <FormattedMessage {...messages.led_off} />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {/* <div className={styles.vrFlexTest}>
                        <div className={styles.vrButton} onClick={this.driveForward}>
                        Forward
                        </div>
                        <div className={styles.vrButton} onClick={this.driveBackward}>
                        Backward
                        </div>
                        <div className={styles.vrButton} onClick={this.turnLeft}>
                        turnLeft
                        </div>
                        <div className={styles.vrButton} onClick={this.turnRight}>
                        turnRight
                        </div>
                        <div className={styles.vrButton} onClick={this.pause}>
                        pause
                        </div>
                        <div className={styles.vrButton} onClick={this.playAudio}>
                        play
                        </div>
                        <div className={styles.vrButton} onClick={this.stop}>
                        stop
                        </div>
                        <div className={styles.vrButton} onClick={this.reset}>
                        reset
                        </div>
                        <div className={styles.vrButton} onClick={this.zoom}>
                        zoom
                        </div>
                        <div className={styles.vrButton} onClick={this.openLED}>
                        openLED
                        </div>
                        <div className={styles.vrButton} onClick={this.closeLED}>
                        closeLED
                        </div>
                        <div className={styles.vrButton} onClick={this.setPencilColor}>
                        setPencilColor
                        </div>
                        <div className={styles.vrButton} onClick={this.startWriting}>
                        startWriting
                        </div>
                        <div className={styles.vrButton} onClick={this.stopWriting}>
                        stopWriting
                        </div>
                    </div> */}

                </div>
            </div>
        );
    }
}

VRViewer.propTypes = {
    stageSize: PropTypes.string,
    vm: PropTypes.object,
    stageSizeMode: PropTypes.string,
    setFullScreen: PropTypes.func,
    getVRFullScreen: PropTypes.bool,
    onShowBreakPositionErrorDialog: PropTypes.func,
    setUnityMessageFunc: PropTypes.func,
    setVRSaveFile: PropTypes.func,
    setVRUnityContextLoadingProgress: PropTypes.func,
    setVRLangChange: PropTypes.func,
    getVRLangChange: PropTypes.bool,
    setVRIsReady: PropTypes.func,
    getVRIsReady: PropTypes.bool,
    locale: PropTypes.string,
    getWorkspace: PropTypes.object,
    onLoadFinish: PropTypes.func,
};

const mapStateToProps = state => ({
    getVRFullScreen: getFullScreen(state, viewPage.vr),
    getVRLangChange: getVRLangChange(state),
    getVRIsReady: getVRIsReady(state),
    vm: state.scratchGui.vm,
    locale: state.locales.locale,
    getWorkspace: getWorkspace(state),
});

const mapDispatchToProps = dispatch => ({
    setFullScreen: (isFull, page) => dispatch(setFullScreen(isFull, page)),
    onShowBreakPositionErrorDialog: (msg) => dispatch(showErrorDialog(errorType.BREAK_POSITION_ILEGAL, msg)),
    setUnityMessageFunc: (func) => dispatch(setUnityMessageFunc(func)),
    setVRSaveFile: (status) => dispatch(setVRSaveFile(status)),
    setVRUnityContextLoadingProgress: (status) => dispatch(setVRUnityContextLoadingProgress(status)),
    setVRLangChange: status => dispatch(setVRLangChange(status)),
    setVRIsReady: status => dispatch(setVRIsReady(status)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VRViewer);