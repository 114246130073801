import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import {
    editBlockType
} from '../../reducers/dialog';

import close from './svg/close.svg';
import variableNum from './svg/variable_number.svg';
import variableWord from './svg/variable_word.svg';

import variableNumDark from './svg/variable_number_dark.svg';
import variableWordDark from './svg/variable_word_dark.svg';
import variableBooleanDark from './svg/variable_boolean_dark.svg';

import { uiType, getUIStyle } from '../../reducers/ui-style';
import { variableRegSp } from '../../lib/variable-utils';
import Selector from '../selector/selector.jsx';

import { getPickedBrainType } from '../../reducers/picked-brain-type'
import { BRAIN_TYPE } from '../../lib/brains';

let SYMBOLS_NOT_ALLOWED = "gui.prompt.symbolsNotAllowed";
let NAME_REQUIRED = "gui.prompt.nameRequired";
let message = {
    duplicate: {
        number: {
            defaultMessage: "Symbols Duplicate",
            description: "Warning message",
            id: "gui.prompt.number.duplicate",
        },
        string: {
            defaultMessage: "Symbols Duplicate",
            description: "Warning message",
            id: "gui.prompt.string.duplicate",
        }
    },
    symbolNotAllowed: <FormattedMessage
        defaultMessage="Symbols Not Allowed"
        description="Warning message"
        id="gui.prompt.symbolsNotAllowed"
    />,
    required: <FormattedMessage
        defaultMessage="Name Required"
        description="Warning message"
        id="gui.prompt.nameRequired"
    />
};

class BlockEditDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'checkConfirmEnable',
            'checkNameIsValid',
            'handleChange',
            'handleDelete',
            'handleSelectChange',
            'renderContentType',
            'getTitle',
            'setEditBlockContentRef'
        ]);
        this.state = {
            data: this.props.data,
            confirmDisable: false
        };
        this.editBlockContent = null;
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
    }
    componentWillUnmount() {
    }

    checkConfirmEnable(data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].warning) {
                return;
            }
        }
        console.log("all data no warning");
        this.setState({
            confirmDisable: false
        });
    }

    checkNameIsValid(data) {
        let isValid = true;
        let allVariable = data.concat(this.props.nonData);
        for (let i = 0; i < allVariable.length; i++) {
            allVariable[i].warning = null;
            for (let j = 0; j < allVariable.length; j++) {
                if (i == j)
                    continue;
                if (allVariable[i].name === allVariable[j].name /*&& data[i].type === data[j].type*/) {
                    if (allVariable[i].type == "list") {
                        allVariable[i].warning = <FormattedMessage
                            values={{ name: `${allVariable[i].name}` }}
                            {...message.duplicate.number}
                        />;
                    } else {
                        allVariable[i].warning = <FormattedMessage
                            values={{ name: `${allVariable[i].name}` }}
                            {...message.duplicate.string}
                        />;
                    }
                    break;
                }
            }
            if (allVariable[i].warning == null) {
                if (variableRegSp.test(allVariable[i].name)) {
                    allVariable[i].warning = message.symbolNotAllowed;
                }
                if (allVariable[i].name.length == 0) {
                    allVariable[i].warning = message.required;
                }
            }
            if (allVariable[i].warning != null) {
                isValid = false;
            }
        }
        return isValid;
    }

    handleChange(e) {
        let data = this.state.data;
        data[e.target.id].name = e.target.value;
        let isValid = this.checkNameIsValid(data);
        this.setState({
            data: data,
            confirmDisable: !isValid
        });
        if (isValid) {
            this.checkConfirmEnable(data);
        }
    }

    handleDelete(index) {
        console.log("handleDelete index = ", index);
        let data = this.state.data;
        data.splice(index, 1);
        let isValid = this.checkNameIsValid(data);
        this.setState({
            data: data,
            confirmDisable: !isValid
        });
        if (isValid) {
            this.checkConfirmEnable(data);
        }
    }

    handleSelectChange(index, number) {
        console.log("handleSelectChange index = ", index);
        let data = this.state.data;
        data[index].listLength = number;
        this.setState({
            data: data
        });
    }

    renderContentType() {
        switch (this.props.type) {
            case editBlockType.number_variable:
            case editBlockType.string_variable:
            case editBlockType.boolean_variable:
                if (this.state.data.length == 0) {
                    return (
                        <div className={styles.editBlockEmpty}>
                            <FormattedMessage
                                defaultMessage="There are currently no editable audio"
                                description="no editable audio"
                                id="gui.prompt.noVariable"
                            />
                        </div>
                    )
                }
                return (
                    <div className={styles.editBlockContentVariable}>
                        {
                            this.state.data.map((json, id) => {
                                console.log("id = ", id, " variable data json = ", json);
                                if (this.props.getBrainType == BRAIN_TYPE.EDU || this.props.getBrainType == BRAIN_TYPE.WEB_VR) {
                                    if (json.type == editBlockType.string_variable || json.type == editBlockType.boolean_variable) {
                                        return;
                                    }
                                }
                                return (
                                    <div className={styles.editBlockItemView} key={id + json.id}>
                                        <div className={styles.editBlockItem}>

                                            <div className={classNames(
                                                styles.editBlockUseBlockType,
                                                json.warning == null ? null : styles.error
                                            )}>
                                                <div className={classNames(styles.editBlockUseBlockTypeCenter)}>
                                                    {(json.type == editBlockType.number_variable) ?
                                                        <img src={this.props.getUIStyle == uiType.cn ? variableNum : variableNumDark} /> :
                                                        ((json.type == editBlockType.string_variable) ?
                                                            <img src={this.props.getUIStyle == uiType.cn ? variableWord : variableWordDark} /> :
                                                            <img src={this.props.getUIStyle == uiType.cn ? null : variableBooleanDark} />)}
                                                </div>
                                            </div>

                                            <input
                                                className={classNames(
                                                    styles.editBlockInput,
                                                    json.warning == null ? null : styles.error
                                                )}
                                                type={"text"}
                                                maxLength="200"
                                                defaultValue={json.name}
                                                id={id}
                                                onChange={this.handleChange}
                                            />
                                            <div className={classNames(
                                                styles.editBlockUseCount,
                                                json.warning == null ? null : styles.error
                                            )}>
                                                {json.count}
                                            </div>
                                            <img className={styles.editBlockClose} src={close} onClick={() => this.handleDelete(id)} />
                                        </div>
                                        <div className={styles.editBlockError}>
                                            {json.warning}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
            case editBlockType.number_list:
                if (this.state.data.length == 0) {
                    return (
                        <div className={styles.editBlockEmpty}>
                            <FormattedMessage
                                defaultMessage="There is currently no editable list"
                                description="no list data"
                                id="gui.prompt.noList"
                            />
                        </div>
                    )
                }
                return (
                    <div className={styles.editBlockContentList}>
                        {
                            this.state.data.map((json, id) => {
                                console.log("id = ", id, " list data json = ", json);
                                return (
                                    <div className={styles.editBlockItemView} key={id + json.id}>
                                        <div className={styles.editBlockItem}>
                                            <div className={classNames(
                                                styles.editBlockUseBlockType,
                                                json.warning == null ? null : styles.error
                                            )}>
                                                <div className={classNames(styles.editBlockUseBlockTypeCenter)}>
                                                    {(json.type == editBlockType.number_list) ?
                                                        <img src={this.props.getUIStyle == uiType.cn ? variableNum : variableNumDark} /> :
                                                        ((json.type == editBlockType.string_list) ?
                                                            <img src={this.props.getUIStyle == uiType.cn ? variableWord : variableWordDark} /> :
                                                            <img src={this.props.getUIStyle == uiType.cn ? null : variableBooleanDark} />)}
                                                </div>
                                            </div>
                                            <input
                                                className={classNames(
                                                    styles.editBlockInput,
                                                    json.warning == null ? null : styles.error
                                                )}
                                                type={"text"}
                                                maxLength="200"
                                                defaultValue={json.name}
                                                id={id}
                                                onChange={this.handleChange}
                                            />
                                            <div className={classNames(
                                                styles.editBlockUseCount,
                                                json.warning == null ? null : styles.error
                                            )}>
                                                {json.count}
                                            </div>
                                            <img className={styles.editBlockClose} src={close} onClick={() => this.handleDelete(id)} />
                                        </div>
                                        <div className={classNames(styles.editBlockError, styles.pseudHeight)}>
                                            {json.warning}
                                        </div>
                                        <div className={styles.editBlockSelectText}>
                                            <FormattedMessage
                                                defaultMessage="Length of list (1-10)"
                                                description="Length of list"
                                                id="gui.prompt.listLength"
                                            />
                                        </div>
                                        {/* 
                                        <div className={styles.editBlockSelect}>
                                            <select id={id} onChange={this.handleSelectChange} defaultValue={json.listLength} className={styles.editBlockOptionArea}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                ...
                                            </select>
                                        </div> */}
                                        <Selector
                                            className={styles.select}
                                            defaultValue={json.listLength}
                                            list={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                            onChange={(num) => this.handleSelectChange(id, num)}
                                            getEditBlockContent={() => this.getEditBlockContentRef()}
                                        />
                                        <div className={styles.editBlockDivider} />
                                    </div>
                                )
                            })
                        }
                    </div>
                );
            case editBlockType.sound_audio:
                if (this.state.data.length == 0) {
                    return (
                        <div className={styles.editBlockEmpty}>
                            <FormattedMessage
                                defaultMessage="There are currently no editable variables"
                                description="no variable data"
                                id="gui.prompt.noSounds"
                            />
                        </div>
                    )
                }
                return (
                    <div className={styles.editBlockContentVariable}>
                        {
                            this.state.data.map((json, id) => {
                                console.log("id = ", id, " variable data json = ", json);
                                return (
                                    <div className={styles.editBlockItemView} key={id + json.id}>
                                        <div className={styles.editBlockItem}>
                                            <input
                                                className={classNames(
                                                    styles.editBlockInput,
                                                    json.warning == null ? null : styles.error
                                                )}
                                                type={"text"}
                                                maxLength="200"
                                                defaultValue={json.name}
                                                id={id}
                                                onChange={this.handleChange}
                                            />
                                            <div className={classNames(
                                                styles.editBlockUseCount,
                                                json.warning == null ? null : styles.error
                                            )}>
                                                {json.count}
                                            </div>
                                            <img className={styles.editBlockClose} src={close} onClick={() => this.handleDelete(id)} />
                                        </div>
                                        <div className={styles.editBlockError}>
                                            {json.warning}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
        }
    }

    getTitle() {
        if (this.props.type == editBlockType.number_variable || this.props.type == editBlockType.string_variable || this.props.type == editBlockType.boolean_variable) {
            return <FormattedMessage
                defaultMessage="Edit variable"
                description="edit block dialog variable title"
                id="gui.dialog.editBlock.title.variable"
            />
        } else if (this.props.type == editBlockType.number_list || this.props.type == editBlockType.string_list) {
            return <FormattedMessage
                defaultMessage="Edit list"
                description="edit block dialog list title"
                id="gui.dialog.editBlock.title.list"
            />
        } else {
            return <FormattedMessage
                defaultMessage="Edit audio"
                description="edit block dialog audio title"
                id="gui.dialog.editBlock.title.audio"
            />
        }
    }

    getHint() {
        if (this.state.data.length != 0) {
            if (this.props.type == editBlockType.number_list) {
                return <div className={styles.editBlockUseHint}>
                    <FormattedMessage
                        defaultMessage="Block use hint"
                        description="edit block dialog block use hint"
                        id="gui.prompt.list.blockUseHint"
                    />
                </div>
            } else if (this.props.type == editBlockType.number_variable || this.props.type == editBlockType.string_variable || this.props.type == editBlockType.boolean_variable) {
                return <div className={styles.editBlockUseHint}>
                    <FormattedMessage
                        defaultMessage="Block use hint"
                        description="edit block dialog block use hint"
                        id="gui.prompt.variable.blockUseHint"
                    />
                </div>
            } else {
                return <div className={styles.editBlockUseHint}>
                    <FormattedMessage
                        defaultMessage="Block use hint"
                        description="edit block dialog block use hint"
                        id="gui.prompt.audio.blockUseHint"
                    />
                </div>
            }
        }
    }

    onScroll = () => {
    }

    setEditBlockContentRef(el) {
        this.editBlockContent = el;
    }

    getEditBlockContentRef() {
        return this.editBlockContent;
    }

    render() {
        // Render nothing if the "show" prop is false
        if (!this.props.show) {
            return null;
        }

        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(
                    styles.modalStyle,
                    this.props.type == editBlockType.number_variable || this.props.type == editBlockType.string_variable ||
                        this.props.type == editBlockType.boolean_variable || this.props.type == editBlockType.string_variable
                        ? styles.editBlockVariable : styles.editBlockList
                )}>
                    <div className={styles.questionView}>
                        <div className={styles.editBlockHeader}>
                            <div className={styles.editBlockTitle}>
                                {this.getTitle()}
                            </div>
                        </div>
                        {this.getHint()}
                        <div className={classNames(
                            styles.editBlockContent,
                            this.state.data.length == 0 ? styles.empty : null
                        )} onScroll={this.onScroll} ref={this.setEditBlockContentRef}>
                            {this.renderContentType()}
                        </div>
                        <div className={styles.questionFooter}>
                            <div className={styles.questionWhiteBtn} onClick={this.props.onClose}>
                                <FormattedMessage
                                    defaultMessage="Cancel"
                                    description="edit block dialog cancel button"
                                    id="gui.dialog.question.cancel"
                                />
                            </div>
                            <div className={styles.questionInterval} />
                            <div className={classNames(
                                styles.questionBtn,
                                this.state.confirmDisable ? styles.disable : null
                            )} onClick={() => this.props.onConfirm(this.state.data)}>
                                <FormattedMessage
                                    defaultMessage="Confirm"
                                    description="edit block dialog confirm button"
                                    id="gui.dialog.confirm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockEditDialog.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    type: PropTypes.string,
    show: PropTypes.bool,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string,
            name: PropTypes.string,
            listLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            count: PropTypes.number,
            warning: PropTypes.string
        })
    ),
    nonData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string,
            name: PropTypes.string,
            listLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            count: PropTypes.number,
            warning: PropTypes.string
        })
    ),
    getUIStyle: PropTypes.string
};

BlockEditDialog.defaultProps = {
    onClose: () => { },
    onConfirm: () => { }
}

const mapStateToProps = state => ({
    getUIStyle: getUIStyle(state),
    getBrainType: getPickedBrainType(state)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockEditDialog);