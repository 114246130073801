import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import fileImg from './svg/file.svg';
import eduBrainImg from './svg/edu_brain.png';
import entryBrainImg from './svg/entry_brain.svg';
import entryPlusBrainImg from './svg/B1_plus_B2.png';
import comingSoonImg from './svg/coming_soon.svg';
import { BRAIN_TYPE } from '../../lib/brains'
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import folderImg from './svg/file.svg';
import folderWWImg from './svg/ww/file.svg';
import { pickerType, showBrainTypePicker } from '../../reducers/dialog';
import SBFileUploader from '../../containers/sb-file-uploader.jsx';
import { platformType, getPlatform, isPad } from '../../lib/platform';
import { uiType, getUIStyle } from '../../reducers/ui-style';
import {
    isEnableEntry,
    isEnableEduAndEntry,
    isEnableWebVR,
    getPickedBrainType,
    updatePickedBrainType,
    isPickedBrainType,
    setPickedBrainType
} from '../../reducers/picked-brain-type'
import {
    enableCheckingImportDevice
} from '../../reducers/device-manager-controller'

import store from 'store';

import {
    getIsShowingProject
} from '../../reducers/project-state';
import { Catagory, FileAction, postMessage } from '../../lib/postmessage';
import { EditUtils } from '../device-manager/edit-page/edit-utils.js';

class BrainTypePicker extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (!this.props.isEnableWebVR) {
            if (!this.props.isPickedBrainType) {
                if (store.get("hasShowUserGuide", false)) {
                    this.props.onShowStartAppBrainTypePicker();
                } else {
                    this.props.onShowNewProjectBrainTypePicker();
                }
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (!this.props.isEnableWebVR && prevProps.isEnableWebVR) {
            if (store.get("hasShowUserGuide", false) && !this.props.isPickedBrainType) {
                this.props.onShowStartAppBrainTypePicker();
            }
        }
    }
    componentWillUnmount() {
    }

    handleClickBrain(brainType) {
        this.props.setPickedBrainType(brainType);
    }

    handleConfirmBtnClick() {
        console.log("select brainType: ", this.props.getPickedBrainType);
        if (getPlatform() == platformType.Desktop) this.props.setPickedBrainToDesktop(this.props.getPickedBrainType);
        switch (this.props.getPickedBrainType) {
            case BRAIN_TYPE.ENTRY:
                this.props.onPickTypeEntry();
                this.props.onPickedBrainType();
                this.props.enableCheckingImportDevice(false);
                setTimeout(() => {
                    this.props.enableCheckingImportDevice(true);
                }, 500)
                break;
            case BRAIN_TYPE.EDU:
                this.props.onPickTypeEdu();
                this.props.onPickedBrainType();
                break;
            case BRAIN_TYPE.EDU_AND_ENTRY:
                this.props.onPickTypeEduAndEntry();
                this.props.onPickedBrainType();
                this.props.enableCheckingImportDevice(false);
                setTimeout(() => {
                    this.props.enableCheckingImportDevice(true);
                }, 500)
                break;
            case BRAIN_TYPE.WEB_VR:
                this.props.onPickTypeWebVR();
                this.props.onPickedBrainType();
                break;
        }
        if (isPad()) {
            postMessage(Catagory.File, { action: FileAction.new, type: this.props.getPickedBrainType })
        }
    }

    render() {
        // Render nothing if the "show" prop is false
        if (!this.props.show) {
            return null;
        }
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle, styles.picker)}>
                    <div className={styles.pickerHeader}>
                        <div className={styles.pickerTitle}>
                            <FormattedMessage
                                defaultMessage="Brain type picker title"
                                description="brain type picker title"
                                id="gui.dialog.brainTypePicker.title"
                            />
                        </div>

                        {this.props.pickerType == pickerType.PICKER_START_APP ? (
                            getPlatform() == platformType.Web ?
                                (
                                    <SBFileUploader userOwnsProject={true} onUpdateProjectTitle={this.props.onUpdateProjectTitle} >
                                        {(className, renderFileInput, handleLoadProject) => (
                                            <div className={styles.pickerOpenFileBtn} onClick={handleLoadProject}>
                                                <img src={this.props.getUIStyle == uiType.ww ? folderWWImg : folderImg} className={styles.openFileBtnIcon} alt={EditUtils.getLocaleString("gui.dialog.brainTypePicker.open")} />
                                                <div className={styles.openFileBtnText} >
                                                    <FormattedMessage
                                                        defaultMessage="Open file"
                                                        description="brain type picker open file"
                                                        id="gui.dialog.brainTypePicker.open"
                                                    />
                                                    {renderFileInput()}
                                                </div>
                                            </div>
                                        )}
                                    </SBFileUploader>
                                ) : (
                                    <div className={classNames(styles.pickerOpenFileBtn, this.props.isShowingProject ? null : styles.disable)} onClick={this.props.onClickOpenfile}>
                                        <img src={this.props.getUIStyle == uiType.ww ? folderWWImg : folderImg} className={styles.openFileBtnIcon} alt={EditUtils.getLocaleString("gui.dialog.brainTypePicker.open")} />
                                        <div className={styles.openFileBtnText}>
                                            <FormattedMessage
                                                defaultMessage="Open file"
                                                description="brain type picker open file"
                                                id="gui.dialog.brainTypePicker.open"
                                            />
                                        </div>
                                    </div>
                                )
                        ) : null}
                    </div>
                    <div className={styles.pickerReminder}>
                        <FormattedMessage
                            defaultMessage="Brain type picker reminder"
                            description="brain type picker reminder"
                            id="gui.dialog.brainTypePicker.reminder"
                        />
                    </div>
                    <div className={styles.brainTypePickerContent}>
                        <div className={this.props.getPickedBrainType == BRAIN_TYPE.EDU ? styles.pickerItemSelected : styles.pickerItem} onClick={() => this.handleClickBrain(BRAIN_TYPE.EDU)}>
                            <img className={styles.pickerImage} src={eduBrainImg} alt={EditUtils.getLocaleString("gui.dialog.brainTypePicker.edu")} />
                            <div className={styles.pickerLabel}>
                                <FormattedMessage
                                    defaultMessage="BrainTypePickerEdu"
                                    description="brain type picker edu"
                                    id="gui.dialog.brainTypePicker.edu" />
                            </div>
                        </div>
                        <div className={this.props.getPickedBrainType == BRAIN_TYPE.ENTRY ? styles.pickerItemSelected : styles.pickerItem} onClick={() => this.props.isEnableEntry ? this.handleClickBrain(BRAIN_TYPE.ENTRY) : null}>
                            {this.props.isEnableEntry ? null :
                                <div className={styles.pickerComingSoon}>
                                    <img src={comingSoonImg} />
                                    <div className={styles.pickerComingSoonText}>Coming soon!</div>
                                </div>
                            }
                            <img className={styles.pickerImage} src={entryBrainImg} alt={EditUtils.getLocaleString("gui.dialog.brainTypePicker.entry")} />
                            <div className={classNames(styles.pickerLabel, this.props.isEnableEntry ? null : styles.comingSoon)}>
                                <FormattedMessage
                                    defaultMessage="BrainTypePickerEntry"
                                    description="brain type picker entry"
                                    id="gui.dialog.brainTypePicker.entry" />
                            </div>
                        </div>
                        <div className={this.props.getPickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY ? styles.pickerItemSelected : styles.pickerItem} onClick={() => this.props.isEnableEduAndEntry ? this.handleClickBrain(BRAIN_TYPE.EDU_AND_ENTRY) : null}>
                            {this.props.isEnableEduAndEntry ? null :
                                <div className={styles.pickerComingSoon}>
                                    <img src={comingSoonImg} />
                                    <div className={styles.pickerComingSoonText}>Coming soon!</div>
                                </div>
                            }
                            <img className={styles.pickerImage} src={entryPlusBrainImg} alt={EditUtils.getLocaleString("gui.dialog.brainTypePicker.entryPlus")} />
                            <div className={classNames(styles.pickerLabel, this.props.isEnableEduAndEntry ? null : styles.comingSoon)}>
                                <FormattedMessage
                                    defaultMessage="BrainTypePickerPro"
                                    description="brain type picker pro"
                                    id="gui.dialog.brainTypePicker.entryPlus" />
                                <div className={styles.pickerLabelDesc}>
                                    <FormattedMessage
                                        defaultMessage="BrainTypePickerProDesc"
                                        description="brain type picker pro desc"
                                        id="gui.dialog.brainTypePicker.entryPlusDesc"
                                        values={{ newline: <br /> }} />
                                </div>
                            </div>
                        </div>

                        {
                            (this.props.isEnableWebVR) ?
                                <div className={this.props.getPickedBrainType == BRAIN_TYPE.WEB_VR ? styles.pickerItemSelected : styles.pickerItem} onClick={() => this.handleClickBrain(BRAIN_TYPE.WEB_VR)}>
                                    <img className={styles.pickerImage} src={eduBrainImg} alt={EditUtils.getLocaleString("gui.dialog.brainTypePicker.webvr")} />
                                    <div className={styles.pickerLabel}>
                                        <FormattedMessage
                                            defaultMessage="BrainTypePickerWebVR"
                                            description="brain type picker Web VR"
                                            id="gui.dialog.brainTypePicker.webvr" />
                                    </div>
                                </div> :
                                null
                        }
                    </div>
                    <div className={classNames(styles.pickerButtonContent,
                        (this.props.isEnableEntry || this.props.isEnableEduAndEntry) ? null : styles.marginTop,
                        this.props.isShowingProject ? null : styles.disable)}>
                        {this.props.pickerType == pickerType.PICKER_START_APP ? (
                            <div className={styles.pickerButtonWhite} onClick={this.props.onExit}>
                                <FormattedMessage
                                    defaultMessage="leave"
                                    description="brain type picker leave"
                                    id="gui.dialog.brainTypePicker.leave"
                                />
                            </div>) : null
                        }
                        <div className={styles.pickerButton} onClick={() => this.handleConfirmBtnClick()}>
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="brain type picker confirm"
                                id="gui.dialog.brainTypePicker.confirm"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BrainTypePicker.propTypes = {
    onPickTypeEntry: PropTypes.func.isRequired,
    onPickTypeEdu: PropTypes.func.isRequired,
    onPickTypeEduAndEntry: PropTypes.func.isRequired,
    onClickOpenfile: PropTypes.func,
    pickerType: PropTypes.string,
    show: PropTypes.bool,
    onUpdateProjectTitle: PropTypes.func,
    onShowStartAppBrainTypePicker: PropTypes.func,
    onShowNewProjectBrainTypePicker: PropTypes.func,
    onExit: PropTypes.func,
    isShowingProject: PropTypes.bool,
    setPickedBrainToDesktop: PropTypes.func
};

const mapStateToProps = state => ({
    isPickedBrainType: isPickedBrainType(state),
    isShowingProject: getIsShowingProject(state.scratchGui.projectState.loadingState),
    getPickedBrainType: getPickedBrainType(state),
    isEnableEntry: isEnableEntry(state),
    isEnableEduAndEntry: isEnableEduAndEntry(state),
    isEnableWebVR: isEnableWebVR(state),
    getUIStyle: getUIStyle(state)
});

const mapDispatchToProps = dispatch => ({
    onPickedBrainType: () => dispatch(updatePickedBrainType()),
    onShowStartAppBrainTypePicker: () => dispatch(showBrainTypePicker(pickerType.PICKER_START_APP)),
    onShowNewProjectBrainTypePicker: () => dispatch(showBrainTypePicker(pickerType.PICKER_NEW_PROJECT)),
    setPickedBrainType: (brainType) => dispatch(setPickedBrainType(brainType)),
    enableCheckingImportDevice: (status) => dispatch(enableCheckingImportDevice(status))
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(BrainTypePicker));