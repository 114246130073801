import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import log from '../../lib/log.js';

import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import {
    setBrainVer,
    getBrainVer
} from '../../reducers/brain';
import {
    getTaskProgress,
    getTaskArray,
    getInProgressTask,
    cleanTask,
    taskType
} from '../../reducers/task';
import {
    showErrorDialog,
    errorType
} from '../../reducers/dialog';
import {
    setBrainFWUpdatingStatus
} from '../../reducers/brain';

const messages = defineMessages({
    firmwareUpdate: {
        defaultMessage: 'Firmware update',
        description: 'Firmware update',
        id: 'gui.firmware.dialog.firmwareUpdate'
    },
    warning: {
        defaultMessage: 'Please do not remove usb device in process running.',
        description: 'firmwareUpdate warning',
        id: 'gui.firmware.dialog.firmwareUpdate.warning'
    }
})

class FirmwareDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
        this.state = {
            percentage: 0,
            inUpdateProgress: false
        }
    }

    shouldComponentUpdate(nextProps) {
        return (this.props.taskProgress != nextProps.taskProgress) ||
            (this.props.taskArray != nextProps.taskArray) ||
            (this.props.taskName != nextProps.taskName) ||
            (this.props.show != nextProps.show);
    }

    updateProgress() {
        let progress = this.props.taskProgress;
        if (progress && progress.percentage && progress.percentage <= 100 && progress.percentage >= 0) {
            this.setState({
                percentage: progress.percentage
            })
            if ((this.props.taskName == taskType.UPDATE_FIRMWARE
                || this.props.taskName == taskType.UPDATE_SYSTEM
                || this.props.taskName == taskType.DOWNLOAD_FIRMWARE)
                && progress.percentage == 100) {
                this.setState({ percentage: 0, inUpdateProgress: false })
                log.info('this.props.taskArray ', this.props.taskArray);
                if (this.props.taskArray['waitting'].length == 0) {
                    log.info('firmware dialog this.props.taskArray[waitting].length == 0 this.props.onClose()');
                    this.props.onClose();
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.taskName != prevProps.taskName
            && (this.props.taskName == taskType.UPDATE_FIRMWARE
                || this.props.taskName == taskType.UPDATE_SYSTEM
                || this.props.taskName == taskType.DOWNLOAD_FIRMWARE)) {
            this.setState({
                inUpdateProgress: true,
                percentage: 0
            })
        }
        if ((this.props.taskName == taskType.UPDATE_FIRMWARE
            || this.props.taskName == taskType.UPDATE_SYSTEM
            || this.props.taskName == taskType.DOWNLOAD_FIRMWARE)
            && this.props.taskProgress != prevProps.taskProgress) {
            this.updateProgress();
        }
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div className={styles.backdropStyle}>
                <div className={styles.firmwareStyle} ref={this.ref}>
                    <div className={styles.firmwareHeader}>
                        <FormattedMessage {...messages.firmwareUpdate} />
                    </div>
                    <div className={styles.firmwareView}>
                        <div className={styles.firmwareText}>
                            <FormattedMessage {...messages.warning} />
                        </div>
                        <div className={styles.questionFooter}>
                            <div className={styles.questionBtn} onClick={() => this.props.onClose()}>
                                <FormattedMessage
                                    id="gui.dialog.question.Iknow"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

FirmwareDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    getBrainVer: PropTypes.object,
    taskProgress: PropTypes.object,
};

const ProgressBar = (props) => {
    return (
        <div className={styles.progressBar}>
            {(props.percentage != 0) ? <Filler percentage={props.percentage} /> : null}
        </div>
    )
}

const Filler = (props) => {
    return <div className={styles.filler} style={{ width: `${props.percentage}%` }} />
}

const mapStateToProps = state => ({
    getBrainVer: getBrainVer(state, 'current'),
    taskProgress: getTaskProgress(state),
    taskName: getInProgressTask(state),
    taskArray: getTaskArray(state)
});

const mapDispatchToProps = dispatch => ({
    setBrainVer: (verType, ver) => dispatch(setBrainVer(verType, ver)),
    cleanTask: () => dispatch(cleanTask()),
    showErrorDialog: (errorType) => dispatch(showErrorDialog(errorType)),
    setBrainFWUpdatingStatus: (status) => dispatch(setBrainFWUpdatingStatus(status)),
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FirmwareDialog);