import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import React from 'react';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

import BufferedInputHOC from '../forms/buffered-input-hoc.jsx';
import Input from '../forms/input.jsx';
const BufferedInput = BufferedInputHOC(Input);

import {
    questionType,
    showQuestionDialog,
    hideQuestionDialog,
    quesitonDialogShow
} from '../../reducers/dialog';

import {
    setProjectTitleChanged
} from '../../reducers/project-title-changed';

import {
    setProjectChanged
} from '../../reducers/project-changed';

import {
    projectTitle,
    setProjectTitle
} from '../../reducers/project-title';

import styles from './project-title-input.css';

const messages = defineMessages({
    projectTitlePlaceholder: {
        id: 'gui.dialog.question.projectName.projectTitlePlaceholder',
        description: 'Placeholder for project title when blank',
        defaultMessage: 'Project title here'
    }
});

class ProjectTitleInput extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleUpdateProjectTitle',
            'handleChange'
        ]);
    }
    // call onUpdateProjectTitle if it is defined (only defined when gui
    // is used within scratch-www)
    handleUpdateProjectTitle(newTitle) {
        if (this.props.onUpdateProjectTitle) {
            console.log("handleUpdateProjectTitle");
            this.props.onUpdateProjectTitle(newTitle);
            this.props.setProjectTitle(newTitle);
        }
    }

    handleChange(change, show) {
        this.props.setProjectTitleChanged(change);
        this.props.setProjectChanged(!!change);
        if (show) {
            if (!this.props.isProjecNameDialogShow) {
                this.props.showProjectNameDialog();
            }
        }
        else {
            if (this.props.isProjecNameDialogShow) {
                this.props.hideProjectNameDialog();
            }
        }
    }

    render() {
        return (
            <BufferedInput
                className={classNames(styles.titleField, this.props.className)}
                maxLength="50"
                placeholder={this.props.intl.formatMessage(messages.projectTitlePlaceholder)}
                tabIndex="0"
                type="text"
                value={this.props.projectTitle}
                onSubmit={this.handleUpdateProjectTitle}
                onChange={this.handleChange}
                saveProject={this.props.onSave}
            />
        );
    }
}

ProjectTitleInput.propTypes = {
    className: PropTypes.string,
    intl: intlShape.isRequired,
    onUpdateProjectTitle: PropTypes.func,
    onSave: PropTypes.func,
    projectTitle: PropTypes.string,
    showProjectNameDialog: PropTypes.func,
    hideProjectNameDialog: PropTypes.func,
    isProjecNameDialogShow: PropTypes.bool,
    setProjectTitleChanged: PropTypes.func,
    vm: PropTypes.shape({
        setProjectName: PropTypes.func
    })
};

const mapStateToProps = state => ({
    projectTitle: projectTitle(state),
    isProjecNameDialogShow: quesitonDialogShow(state, questionType.QUESTION_SAVE_PROJECTNAME),
    vm: state.scratchGui.vm
});

const mapDispatchToProps = dispatch => ({
    showProjectNameDialog: () => dispatch(showQuestionDialog(questionType.QUESTION_SAVE_PROJECTNAME)),
    hideProjectNameDialog: () => dispatch(hideQuestionDialog(questionType.QUESTION_SAVE_PROJECTNAME)),
    setProjectTitleChanged: change => dispatch(setProjectTitleChanged(change)),
    setProjectTitle: (title) => dispatch(setProjectTitle(title)),
    setProjectChanged: change => dispatch(setProjectChanged(change))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectTitleInput));
