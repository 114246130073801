import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styles from '../dialog.css';
import wifiStyles from './wifi-dialog.css';
import log from '../../../lib/log.js';
import closeIcon from '../svg/close_btn.svg';
import {
    isWifiiPadSettingDialogShow,
    hideWifiiPadSettingDialog
} from '../../../reducers/dialog';

import wifiSettinStyles from './wifi-setting-dialog.css';
import {
    setBrainWifiList,
    getBrainWifiList,
} from '../../../reducers/brain';
import setting1 from './img/setting1.png';
import setting2 from './img/setting2.png';

import {
    wifiStatus,
    setConnectedWifi,
} from '../../../reducers/wifi-list'

import {
    Catagory,
    postMessage
} from '../../../lib/postmessage';

import {
    SocketAction
} from '../../../lib/socket';

import {
    isPad
} from '../../../lib/platform'
import {
    setBrainConnectState,
    getBrainConnectState,
    WIFI_CONNECT_STATE,
    forceOpenBrainInfo
} from '../../../reducers/brain'
import {
    WifiAp,
    OtherAp,
    getText
} from './wifiAP.jsx'
import store from 'store';

const PAGE_STATE = {
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
    fifth: 5
}
const DEFAULT_AP_IP = '192.168.0.1';
const KEY_SSID = 'ssid';
const KEY_HASPW = 'haspw';
const INTERVAL_LOOP_TIME = 10 * 1000
var scanIntervalId = null
var connectApIntervalId = null;
class WifiiPadSettingDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'closeDialog',
            'next'
        ]);
        this.state = this.getInitState();
    }

    getInitState() {
        return {
            flow: PAGE_STATE.first,
            wifiScanResult: [{ ssid: "Other" }],
            connectedWifi: this.props.getConnectedWifi,
            wifiStatus: this.getFormatWifiStatus(),
            selectedAp: "",
            expandAp: "",
            apName: "",
            password: "",
            isPasswordVisiable: false,
            lockScanList: true,
            editIndex: -1
        }
    }

    setWifiApConnect() {
        console.log("setWifiApConnect");
        this.props.setBrainConnectState(WIFI_CONNECT_STATE.SUCCESS);
    }

    setiPadWifiList(list) {
        console.log("setiPadWifiList ", list);
        this.props.setBrainWifiList(JSON.parse(decodeURIComponent(list.replace(/\\x/g, '%'))));
    }

    componentDidMount() {
        window.wifiiPadSettingDialog = this;
        this.setState(this.getInitState());
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.show != prevProps.show) {
            if (this.props.show == false) {
                this.setState(this.getInitState());
                this.clearIntervalId(scanIntervalId)
                this.clearIntervalId(connectApIntervalId)
                this.props.setBrainWifiList([]);
            }
        }
        if (prevProps.getBrainWifiList != this.props.getBrainWifiList) {
            this.updateWifiList();
        }
        if (prevState.flow != this.state.flow && this.state.flow == PAGE_STATE.third) {
            this.clearIntervalId(scanIntervalId)
            if (this.props.scanWifiApBrainList) {
                this.props.scanWifiApBrainList()
                scanIntervalId = setInterval(() => this.props.scanWifiApBrainList(), INTERVAL_LOOP_TIME)
                console.log('scanWifiApBrainList scanIntervalId ', scanIntervalId)
            }
            if (isPad()) {
                postMessage(Catagory.Socket, { "action": SocketAction.scanWifi });
                scanIntervalId = setInterval(() => postMessage(Catagory.Socket, { "action": SocketAction.scanWifi }), INTERVAL_LOOP_TIME)
            }
            this.updateWifiList();
        }
        if (prevState.flow != this.state.flow && this.state.flow == PAGE_STATE.second) {
            this.setState({ lockScanList: true })
            this.connectToAp();
        }
        if (prevProps.getBrainConnectState != this.props.getBrainConnectState) {
            console.log('prevProps.getBrainConnectState != this.props.getBrainConnectState ', this.props.getBrainConnectState)
            if (this.props.getBrainConnectState == WIFI_CONNECT_STATE.SUCCESS) {
                this.clearIntervalId(connectApIntervalId)
                this.setState({ lockScanList: false })
            } else if (this.props.getBrainConnectState == WIFI_CONNECT_STATE.FAILED) {
                this.setState({ lockScanList: true })
                this.connectToAp();
            }
        }
        if (prevState.editIndex !== this.state.editIndex) {
            this.setState({ password: "" })
        }
    }

    clearIntervalId(id) {
        console.log('clearIntervalId ', id)
        clearInterval(id)
        id = null
    }

    connectToAp() {
        this.clearIntervalId(connectApIntervalId)
        if (this.props.connectApPort) {
            this.props.connectApPort(DEFAULT_AP_IP)
            connectApIntervalId = setInterval(() => this.props.connectApPort(DEFAULT_AP_IP), INTERVAL_LOOP_TIME)
            console.log('connectToAp connectApIntervalId ', connectApIntervalId)
            this.props.setBrainConnectState(WIFI_CONNECT_STATE.CONNECTING);
        }
        if (isPad()) {
            postMessage(Catagory.Socket, { "action": SocketAction.getWifiSsid });
            connectApIntervalId = setInterval(() => postMessage(Catagory.Socket, { "action": SocketAction.getWifiSsid }), INTERVAL_LOOP_TIME);
            this.props.setBrainConnectState(WIFI_CONNECT_STATE.CONNECTING);
        }
    }

    getWifiStateString(state) {
        log.info('getWifiStateString: ', state);
        switch (state) {
            case wifiStatus.DISABLED:
                return getText("gui.dialog.wifi.status.disable");
            case wifiStatus.NO_SETTING:
                return getText("gui.dialog.wifi.status.noSetting");
            case wifiStatus.SCANNING:
                return getText("gui.dialog.wifi.status.scanning");
            case wifiStatus.CONNECTING:
                return getText("gui.dialog.wifi.status.connecting");
            case wifiStatus.COMPLETED:
                return getText("gui.dialog.wifi.status.completed");
            case wifiStatus.AUTH_FAIL:
                return getText("gui.dialog.wifi.status.authFail");
            case wifiStatus.LINK_FAIL:
                return getText("gui.dialog.wifi.status.linkFail");
            default:
                return state;
        }
    }

    getFormatWifiStatus() {
        let status = {}
        status.state = wifiStatus.SCANNING
        status.stateString = getText("gui.dialog.wifi.status.scanning");
        return status;
    }

    onClickWifiAp(index) {
        this.setState({ selectedAp: this.state.wifiScanResult[index].ssid })
    }

    onClickExpandWifiSetting(index) {
        this.setState({
            expandAp: this.state.wifiScanResult[index] && this.state.wifiScanResult[index].ssid ? this.state.wifiScanResult[index].ssid : "Other",
            editIndex: index, isPasswordVisiable: false,
        })
    }

    switchPasswordVisiable() {
        this.setState({ isPasswordVisiable: !this.state.isPasswordVisiable })
    }

    onApNameChange(event) {
        let input = event.target.value
        this.setState({
            apName: input
        })
    }

    onPasswordChange(event) {
        let input = event.target.value
        let regSp = /[(\")]/;
        if (regSp.test(input)) return;
        this.setState({
            password: input
        })
    }

    onClickCancel() {
        this.setState({
            expandAp: "",
            apName: "",
            password: "",
            editIndex: -1
        })
    }

    getApNameFromIndex(index) {
        let apName = ""
        if (index == this.state.wifiScanResult.length - 1) {
            apName = this.state.apName
        } else {
            apName = this.state.wifiScanResult[index].ssid
        }
        return apName;
    }

    onClickConfirm(index) {
        // connect wifi by index, name and password
        let apName = this.getApNameFromIndex(index);
        if (isPad()) {
            postMessage(Catagory.Socket, { "action": SocketAction.connectWifi, "ssid": apName, "password": this.state.password });
        } else {
            this.props.setWifiAp(apName, this.state.password);
        }
        this.next(PAGE_STATE.fourth)
        this.onClickCancel();
        // this.props.closeDialog();
    }

    onClickInteruptWifi(index) {
        // disconnect wifi by index and name
        let apName = this.getApNameFromIndex(index);
        this.props.onClickDeleteWifi(apName);
        this.props.interuptConnectedWifi();
    }
    updateWifiList() {
        console.log('updateWifiList')
        this.setState({ wifiScanResult: this.parseWifiList() });
    }
    parseWifiList() {
        let wifiObj = this.props.getBrainWifiList;
        let objSize = wifiObj.size;
        let tempMap = new Map();
        if (!isNaN(Number(objSize))) {
            for (let i = 0; i < Number(objSize); i++) {
                if (!tempMap.has(wifiObj[KEY_SSID + i])) {
                    tempMap.set(wifiObj[KEY_SSID + i], wifiObj[KEY_HASPW + i])
                }
            }
        }
        console.log('parseWifiList tempMap ', tempMap)
        let scanResult = [];
        if (tempMap.size > 0) {
            for (let [key, value] of tempMap) {
                scanResult.push({ ssid: decodeURIComponent(key.replaceAll("\\\\x", "%")), haspw: value })
            }
        }
        console.log('parseWifiList tempMap ', scanResult)

        return scanResult.concat({ ssid: "Other" });
    }

    getCheckHintTitleMsg() {
        if (this.state.wifiScanResult.length == 1 && this.state.wifiScanResult[0].ssid == "Other") {
            //Not exists ap
            return (
                <div className={classNames(wifiSettinStyles.wifiSettingHint)}>
                    <FormattedMessage
                        id={(this.state.wifiStatus.state != wifiStatus.SCANNING) ? "gui.dialog.wifi.empty.hint" : "gui.dialog.wifi.scaning"}
                    />
                </div>
            )
        }
    }


    getWifiListLayout() {
        let wifiScanResult = this.state.wifiScanResult;
        log.info('getWifiListLayout wifiScanResult: ', this.state.wifiScanResult);

        let listLayout = [];
        listLayout = wifiScanResult.map((ap, index) => <div key={index}>
            {ap.ssid !== "Other" ?
                <WifiAp
                    isWifiSelected={this.state.editIndex == index}
                    isWifiExpand={this.state.expandAp == ap.ssid}
                    onClickExpandWifiSetting={() => this.onClickExpandWifiSetting(index)}
                    onClickWifiAp={() => this.onClickWifiAp(index)}
                    ap={ap}
                    ssid={ap.ssid}
                    index={index}
                    password={this.state.password}
                    wifiStatus={this.state.wifiStatus}
                    isWifiConnected={this.state.connectedWifi == ap.ssid}
                    onClickCancel={() => this.onClickCancel()}
                    onClickConfirm={() => this.onClickConfirm(index)}
                    onPasswordChange={e => this.onPasswordChange(e)}
                    onClickInteruptWifi={() => this.onClickInteruptWifi(index)}
                    switchPasswordVisiable={() => this.switchPasswordVisiable()}
                    isPasswordVisiable={this.state.isPasswordVisiable}
                    haspw={ap.haspw}
                    hideConnectButton={true}
                />
                :
                <OtherAp
                    isWifiSelected={this.state.editIndex == index}
                    isWifiExpand={this.state.expandAp == ap.ssid}
                    onClickExpandWifiSetting={() => this.onClickExpandWifiSetting(index)}
                    onClickWifiAp={() => this.onClickWifiAp(index)}
                    ap={ap}
                    ssid={this.state.apName}
                    index={index}
                    password={this.state.password}
                    wifiStatus={this.state.wifiStatus}
                    isWifiConnected={this.state.connectedWifi == ap.ssid}
                    onClickCancel={() => this.onClickCancel()}
                    onClickConfirm={() => this.onClickConfirm(index)}
                    onApNameChange={(e) => this.onApNameChange(e)}
                    onPasswordChange={(e) => this.onPasswordChange(e)}
                    onClickInteruptWifi={() => this.onClickInteruptWifi(index)}
                    switchPasswordVisiable={() => this.switchPasswordVisiable()}
                    isPasswordVisiable={this.state.isPasswordVisiable}
                    haspw={false}
                />}
        </div>)
        return listLayout;
    }

    next(goto) {
        this.setState({
            flow: goto
        });
    }

    closeDialogAndOpenBrainInfo() {
        this.props.closeDialog();
        this.props.forceOpenBrainInfo(true);
    }

    closeDialog() {
        if (isPad()) {
            postMessage(Catagory.Socket, { "action": SocketAction.disconnectWifi });
        }
        this.props.closeDialog();
    }

    render() {
        // Render nothing if the "show" prop is false
        if (!this.props.show) {
            return null;
        }
        return (
            <div className={styles.backdropStyle}>
                <div className={classNames(styles.modalStyle, styles.wifiIpadSetting)}>
                    <div className={styles.wifiHeader}>
                        <div className={styles.wifiTitle}>
                            <FormattedMessage
                                defaultMessage="Setting master"
                                description="setting master"
                                id="gui.brainInfo.mobile.setting"
                            />
                        </div>
                        {this.state.flow != PAGE_STATE.fifth ?
                            <div className={styles.aboutClose} onClick={this.closeDialog}>
                                <img src={closeIcon} />
                            </div> : null}
                    </div>
                    {
                        this.state.flow == PAGE_STATE.first ? (
                            <div>
                                <div className={wifiStyles.wifiSettingContent}>
                                    <div className={wifiStyles.wifiSettingContentText1}>
                                        <FormattedMessage
                                            defaultMessage="Setting direction1"
                                            description="setting direction1"
                                            id="gui.dialog.wifi.setting.direction1"
                                        />
                                    </div>
                                    <img src={setting1} className={wifiStyles.wifiDirectionImg} />
                                </div>

                                <div className={classNames(wifiStyles.wifiDirectionButton, wifiStyles.wifiDirectionCenter)} onClick={() => this.next(PAGE_STATE.second)}>
                                    <FormattedMessage
                                        defaultMessage="next"
                                        description="next"
                                        id="gui.dialog.wifi.next"
                                    />
                                </div>
                            </div>
                        ) : null
                    }
                    {
                        this.state.flow == PAGE_STATE.second ? (
                            <div>
                                <div className={wifiStyles.wifiSettingContent}>
                                    <div className={wifiStyles.wifiSettingContentText2}>
                                        <FormattedMessage
                                            defaultMessage="Setting direction2"
                                            description="setting direction2"
                                            id="gui.dialog.wifi.setting.direction2"
                                        />
                                    </div>
                                    <img src={setting2} className={wifiStyles.wifiDirectionImg} />
                                </div>
                                <div className={classNames(wifiStyles.wifiDirectionButton, wifiStyles.wifiDirectionCenter,
                                    this.state.lockScanList ? styles.disable : null)}
                                    onClick={() => this.state.lockScanList ? {} : this.next(PAGE_STATE.third)}>
                                    <FormattedMessage
                                        defaultMessage="next"
                                        description="next"
                                        id="gui.dialog.wifi.next"
                                    />
                                </div>
                            </div>
                        ) : null
                    }
                    {this.state.flow == PAGE_STATE.third ? (
                        <div className={classNames(wifiSettinStyles.wifiSettingArea)}>
                            <div className={classNames(wifiSettinStyles.wifiSettingDivide)} />
                            <div className={classNames(wifiSettinStyles.wifiApListArea)}>
                                <div className={classNames(wifiSettinStyles.wifiApListTitle)}>
                                    <FormattedMessage
                                        id={isPad() ? "gui.dialog.wifi.setting.title.pad" : "gui.dialog.wifi.setting.title.pc"}
                                    />
                                </div>
                                <div className={classNames(wifiSettinStyles.wifiApListDevide)} />
                                {this.getCheckHintTitleMsg()}
                                {this.getWifiListLayout()}
                            </div>
                        </div>
                    ) : null
                    }
                    {this.state.flow == PAGE_STATE.fourth ? (
                        <div>
                            <div className={wifiStyles.wifiSettingContent}>
                                <div className={wifiStyles.wifiSettingContentText4}>
                                    <FormattedMessage
                                        id="gui.dialog.wifi.setting.direction4"
                                    />
                                </div>
                            </div>
                            <div className={classNames(wifiStyles.wifiDirectionButton, wifiStyles.wifiDirectionLeft)} onClick={() => this.next(PAGE_STATE.first)}>
                                <FormattedMessage
                                    id="gui.dialog.wifi.retry"
                                />
                            </div>
                            <div className={classNames(wifiStyles.wifiDirectionButton, wifiStyles.wifiDirectionRight)} onClick={() => this.next(PAGE_STATE.fifth)}>
                                <FormattedMessage
                                    id="gui.dialog.wifi.next"
                                />
                            </div>
                        </div>
                    ) : null
                    }
                    {this.state.flow == PAGE_STATE.fifth ? (
                        <div>
                            <div className={wifiStyles.wifiSettingContent}>
                                <div className={wifiStyles.wifiSettingContentText5}>
                                    {
                                        isPad() ? (
                                            <FormattedMessage
                                                id="gui.dialog.wifi.setting.direction5"
                                            />
                                        ) : (
                                            <FormattedMessage
                                                id="gui.dialog.wifi.setting.direction5.pc"
                                            />
                                        )
                                    }
                                </div>
                            </div>

                            <div className={classNames(wifiStyles.wifiDirectionButton, wifiStyles.wifiDirectionCenter)} onClick={() => this.closeDialogAndOpenBrainInfo()}>
                                <FormattedMessage
                                    id="gui.dialog.wifi.setting.finish"
                                />
                            </div>
                        </div>
                    ) : null
                    }
                </div>
            </div >
        );
    }
}

WifiiPadSettingDialog.propTypes = {
    show: PropTypes.bool,
    closeDialog: PropTypes.func,
};

const mapStateToProps = state => ({
    show: isWifiiPadSettingDialogShow(state),
    getBrainWifiList: getBrainWifiList(state),
    getBrainConnectState: getBrainConnectState(state)
});

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(hideWifiiPadSettingDialog()),
    setConnectedWifi: (apName) => dispatch(setConnectedWifi(apName)),
    setBrainConnectState: (state) => dispatch(setBrainConnectState(state)),
    setBrainWifiList: (list) => dispatch(setBrainWifiList(list)),
    forceOpenBrainInfo: (open) => dispatch(forceOpenBrainInfo(open)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WifiiPadSettingDialog);