export const DeviceType = {
    EDU_HULK: "EDU",
    ENTRY_HULK: "ENTRY",
    EDU_AND_ENTRY_HULK: "EDU_AND_ENTRY",
    RC_LOADER: "RC_LOADER",
    RC: "RC",
    BT: "BT",
    ENTRY_SOCKET: "ENTRY_SOCKET",
    RB101: "RB101"
}


export const WifiStatus = {
    DISABLED: "DISABLED",
    NO_SETTING: "NO_SETTING",
    SCANNING: "SCANNING",
    CONNECTING: "CONNECTING",
    COMPLETED: "COMPLETED",
    AUTH_FAIL: "AUTH_FAIL",
    LINK_FAIL: "LINK_FAIL"
}

const deviceMapping = {
    touch_led: "touchLed",
    color: "colorSensor",
    motor120: "motor100",
    motor360: "motor300",
    gyro: "gyro",
    vision: "vision",
    speaker: "speaker",
    ds: "ds"
}


export const VisionInitResponse = {
    TRUE: "True",
    FALSE: "False",
    INTERRUPT: "Interrupt",
    FAILED: "Failed",
    NONE: "None",
    OCCUPIED_PC: "pc",
    OCCUPIED_VM: "vm",
}

export const VisionCmdResponse = {
    CV_INIT: 'cv_init',
    CV_EXIT: 'cv_exit',
    CV_REALTIME: 'cv_realtime',
    CV_GETTAGS: 'cv_gettags',
    CV_ERROR: 'cv_error',
    CV_SELECT_COLOR: 'cv_selectcolor',
    CV_INIT_FACE_RECONGMODULE: 'cv_initfacerecogmodule',
    CV_UPLOAD_IMAGE: 'cv_uploadimage',
    CV_GET_FACE_FEATURE: 'cv_getfacefeature',
    CV_LOAD_FACE_DATA: 'cv_loadfacedata',
    CV_GET_FACE_RESULT: 'cv_getfaceresult',
    CV_GET_ORC_RESULT: 'cv_getocrresult',
    CV_GET_QRCODE_MSG: 'cv_getqrcodemsg'
}

export const parseRealDeviceListToReducer = (list) => {
    let result = [
        { port: "1", device: "", name: "" },
        { port: "2", device: "", name: "" },
        { port: "3", device: "", name: "" },
        { port: "4", device: "", name: "" },
        { port: "5", device: "", name: "" },
        { port: "6", device: "", name: "" },
        { port: "7", device: "", name: "" },
        { port: "8", device: "", name: "" },
        { port: "9", device: "", name: "" },
        { port: "10", device: "", name: "" },
        { port: "11", device: "", name: "" },
        { port: "12", device: "", name: "" },
        { port: "R", device: "", name: "" },
        { port: "L", device: "", name: "" }
    ]
    let deviceArray = Object.values(list);
    for (let i = 1; i < deviceArray.length; i++) {
        let realDevice = deviceMapping[deviceArray[i]] ? deviceMapping[deviceArray[i]] : "";
        if (realDevice == deviceMapping.ds) realDevice = deviceMapping.speaker
        result[i - 1].device = realDevice;
    }
    return result;
}



export const EraseScriptCmd = {
    START: 2003,
    END: 2013,
}

export const WriteScriptCmd = {
    START: 2002,
    END: 2012,
}

export const ErrorScriptCmd = {
    CANCEL: 2021
}
