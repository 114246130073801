import soundBlocks from '../block-vertical-blocks/sound';

export default function soundEDUandEntry(deviceInfo, soundName, blockSeparator) {

    return `
        ${(deviceInfo.buzzerInfo.hasbuzzer || deviceInfo.speakerInfo.hasspeaker) ? `
            <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
                ${deviceInfo.buzzerInfo.hasbuzzer ? `
                    ${soundBlocks.EDU.sound_playnote}
                    ${soundBlocks.EDU.sound_restforbeats}
                    ${soundBlocks.EDU.sound_settempoto}
                    ${soundBlocks.EDU.sound_changetempoby}
                    ${soundBlocks.EDU.sound_tempo}
                    ${soundBlocks.EDU.sound_play}
                ` : ``}
                ${deviceInfo.speakerInfo.hasspeaker ? `
                    ${soundBlocks.Entry.sound_play_audio(soundName)}
                    ${soundBlocks.Entry.sound_play_sound}
                    ${soundBlocks.Entry.sound_play_recording}
                    ${soundBlocks.Entry.sound_set_speaker_volume_to}
                    ${soundBlocks.Entry.sound_stop_playing_sound}
                    ${blockSeparator}
                    ${soundBlocks.Entry.sound_start_recording_for_seconds}
                ` : ``}
            </category>`: ``
        }
    `;
};