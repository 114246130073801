import ScratchBlocks from 'scratch-blocks';
import { BRAIN_TYPE } from '../lib/brains';
import { DEVICE_INFO } from '../lib/deviceInfo';

import { motionEDU, drivetrainEDU, looksEDU, soundEDU, eventEDU, sensingEDU } from './blocks/block-vertical-edu';
import { motionEntry, drivetrainEntry, looksEntry, soundEntry, eventEntry, sensingEntry, aiSpeechEntry, extensionsEntry } from './blocks/block-vertical-entry';
import { motionEDUandEntry, drivetrainEDUandEntry, looksEDUandEntry, soundEDUandEntry, eventEDUandEntry, sensingEDUandEntry } from './blocks/block-vertical-edu-and-entry';
import { drivetrainWEBVR, looksWEBVR, soundWEBVR, sensingWEBVR } from './blocks/block-vertical-web-vr';

const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="20" img="separator-line"/>'; // At default scale, about 28px

const externalSeparator = '<sep gap="20"/>'; // At default scale, about 28px

const motion = function (pickedBrainType, targetId, deviceInfo) {
    if (pickedBrainType == BRAIN_TYPE.EDU) {
        return motionEDU(targetId, deviceInfo);
    } else if (pickedBrainType == BRAIN_TYPE.ENTRY) {
        return motionEntry(targetId, deviceInfo, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
        return motionEDUandEntry(targetId, deviceInfo, blockSeparator);
    }
};

const xmlEscape = function (unsafe) {
    return unsafe.replace(/[<>&'"]/g, c => {
        switch (c) {
            case '<': return '&lt;';
            case '>': return '&gt;';
            case '&': return '&amp;';
            case '\'': return '&apos;';
            case '"': return '&quot;';
        }
    });
};

const drivetrain = function (pickedBrainType, deviceInfo) {
    if (pickedBrainType == BRAIN_TYPE.EDU) {
        return drivetrainEDU(deviceInfo);
    } else if (pickedBrainType == BRAIN_TYPE.ENTRY) {
        return drivetrainEntry(deviceInfo);
    } else if (pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
        return drivetrainEDUandEntry(deviceInfo);
    } else if (pickedBrainType == BRAIN_TYPE.WEB_VR) {
        return drivetrainWEBVR(deviceInfo);
    }
};

const looks = function (pickedBrainType, deviceInfo) {
    if (pickedBrainType == BRAIN_TYPE.EDU) {
        return looksEDU(deviceInfo, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.ENTRY) {
        return looksEntry(deviceInfo, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
        return looksEDUandEntry(deviceInfo, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.WEB_VR) {
        return looksWEBVR(deviceInfo, blockSeparator);
    }
};

const sound = function (pickedBrainType, workspace, deviceInfo, sounds, handleAudioManagementStart) {
    let soundName = sounds.length > 0 ? sounds[sounds.length - 1].name : ScratchBlocks.ScratchMsgs.translate('SHEETMUSIC_IMPORT_FILE', 'import file');
    if (pickedBrainType == BRAIN_TYPE.EDU) {
        return soundEDU(deviceInfo, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.ENTRY) {
        return soundEntry(workspace, deviceInfo, soundName, sounds.length, handleAudioManagementStart, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
        return soundEDUandEntry(deviceInfo, soundName, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.WEB_VR) {
        return soundWEBVR(deviceInfo, blockSeparator);
    }
};

const events = function (pickedBrainType, deviceInfo) {
    if (pickedBrainType == BRAIN_TYPE.EDU) {
        return eventEDU(deviceInfo, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.ENTRY) {
        return eventEntry(deviceInfo, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
        return eventEDUandEntry(deviceInfo, blockSeparator);
    }
};

const control = function () {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        <block type="control_wait">
            <value name="DURATION">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="control_repeat">
            <value name="TIMES">
                <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block id="forever" type="control_forever"/>
        ${blockSeparator}
        <block type="control_if"/>
        <block type="control_if_else"/>
        <block id="wait_until" type="control_wait_until"/>
        <block id="repeat_until" type="control_repeat_until"/>
        <block type="control_while"/>
        ${blockSeparator}
        <block type="control_break"/>
    </category>
    `;
};

const sensing = function (pickedBrainType, deviceInfo) {
    if (pickedBrainType == BRAIN_TYPE.EDU) {
        return sensingEDU(deviceInfo, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.ENTRY) {
        return sensingEntry(deviceInfo, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
        return sensingEDUandEntry(deviceInfo, blockSeparator);
    } else if (pickedBrainType == BRAIN_TYPE.WEB_VR) {
        return sensingWEBVR(deviceInfo, blockSeparator);
    }
};

const operators = function (pickedBrainType) {
    const apple = ScratchBlocks.ScratchMsgs.translate('OPERATORS_JOIN_APPLE', 'apple');
    const banana = ScratchBlocks.ScratchMsgs.translate('OPERATORS_JOIN_BANANA', 'banana');
    const letter = ScratchBlocks.ScratchMsgs.translate('OPERATORS_LETTEROF_APPLE', 'a');
    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
        <block type="operator_add">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_subtract">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_multiply">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_divide">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_random">
            <value name="FROM">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_gt">
            <value name="OPERAND1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_lt">
            <value name="OPERAND1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_equals">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_and"/>
        <block type="operator_or"/>
        <block type="operator_not"/>
        ${((pickedBrainType == BRAIN_TYPE.ENTRY) || (pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY)) ? `
            ${blockSeparator}
            <block type="operator_join">
                <value name="STRING1">
                    <shadow type="text">
                        <field name="TEXT">${apple} </field>
                    </shadow>
                </value>
                <value name="STRING2">
                    <shadow type="text">
                        <field name="TEXT">${banana}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_letter_of">
                <value name="LETTER">
                    <shadow type="math_whole_number">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_length">
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_contains" id="operator_contains">
                <value name="STRING1">
                <shadow type="text">
                    <field name="TEXT">${apple}</field>
                </shadow>
                </value>
                <value name="STRING2">
                <shadow type="text">
                    <field name="TEXT">${letter}</field>
                </shadow>
                </value>
            </block>
        `: ``}
        ${blockSeparator}
        <block type="operator_round">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_mathop">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_remainder">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
    </category>
    `;
};

const variables = function (pickedBrainType, targetId) {
    return `
        ${(pickedBrainType == BRAIN_TYPE.EDU) || (pickedBrainType == BRAIN_TYPE.WEB_VR) ? `
            <category
                name="%{BKY_CATEGORY_VARIABLES}"
                id="${targetId}_variables"
                colour="#FF8C1A"
                secondaryColour="#DB6E00"
                custom="VARIABLE">
            </category>
        `: `
            <category
                name="%{BKY_CATEGORY_VARIABLES}"
                id="${targetId}_variables"
                colour="#FF8C1A"
                secondaryColour="#DB6E00"
                custom="VARIABLE_ENTRY">
            </category>
        `}
    `;
};

const comments = function () {
    const comment = ScratchBlocks.ScratchMsgs.translate('COMMENT_INLINE', 'comment');
    return `
    <category name="%{BKY_CATEGORY_COMMENTS}" id="comments" colour="#C0C0C0" secondaryColour="#888888">
        <block type="comments_normal">
            <value name="MESSAGE">
                <shadow type="restrict_text">
                    <field name="RESTRICT_TEXT">${comment}</field>
                </shadow>
            </value>
        </block>
    </category>
    `;
};

const myBlocks = function (pickedBrainType) {
    return `
        ${((pickedBrainType == BRAIN_TYPE.ENTRY) || (pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY)) ? `
            <category
                name="%{BKY_CATEGORY_MYBLOCKS}"
                id="myBlocks"
                colour="#FF6680"
                secondaryColour="#FF4D6A"
                custom="PROCEDURE">
            </category>
        `: ``}
    `;
};

const aiSpeech = function (pickedBrainType, deviceInfo, isEnableAISpeech) {
    if (pickedBrainType == BRAIN_TYPE.ENTRY) {
        return aiSpeechEntry(deviceInfo, isEnableAISpeech);
    } else if (pickedBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
        return aiSpeechEntry(deviceInfo, isEnableAISpeech);
    }
};

const extensions = function (pickedBrainType, deviceInfo, isEnableAISpeech) {
    const apple = ScratchBlocks.ScratchMsgs.translate('OPERATORS_JOIN_APPLE', 'apple');
    const taipei = ScratchBlocks.ScratchMsgs.translate('LOCATION_TAIPEI', 'Taipei');

    let translateMsg = {
        apple: apple,
        taipei: taipei
    }

    if (pickedBrainType == BRAIN_TYPE.ENTRY) {
        return extensionsEntry(deviceInfo, blockSeparator, translateMsg, isEnableAISpeech);
    }
};

const xmlOpen = '<xml style="display: none">';
const xmlClose = '</xml>';

/**
 * @param {!boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {?string} targetId - The current editing target
 * @param {?Array.<object>} categoriesXML - optional array of `{id,xml}` for categories. This can include both core
 * and other extensions: core extensions will be placed in the normal Scratch order; others will go at the bottom.
 * @property {string} id - the extension / category ID.
 * @property {string} xml - the `<category>...</category>` XML for this extension / category.
 * @returns {string} - a ScratchBlocks-style XML document for the contents of the toolbox.
 */
const makeToolboxXML = function (workspace, pickedBrainType = BRAIN_TYPE.EDU, targetId,
    categoriesXML = [], sounds = [], deviceInfo = DEVICE_INFO, handleAudioManagementStart,
    isEnableAISpeech) {
    const gap = [categorySeparator];

    categoriesXML = categoriesXML.slice();
    const moveCategory = categoryId => {
        const index = categoriesXML.findIndex(categoryInfo => categoryInfo.id === categoryId);
        if (index >= 0) {
            // remove the category from categoriesXML and return its XML
            const [categoryInfo] = categoriesXML.splice(index, 1);
            return categoryInfo.xml;
        }
        // return `undefined`
    };

    const motionXML = moveCategory('motion') || motion(pickedBrainType, targetId, deviceInfo);
    const looksXML = moveCategory('looks') || looks(pickedBrainType, deviceInfo);
    const soundXML = moveCategory('sound') || sound(pickedBrainType, workspace, deviceInfo, sounds, handleAudioManagementStart);
    const eventsXML = moveCategory('event') || events(pickedBrainType, deviceInfo);
    const controlXML = moveCategory('control') || control();
    const sensingXML = moveCategory('sensing') || sensing(pickedBrainType, deviceInfo);
    const operatorsXML = moveCategory('operators') || operators(pickedBrainType);
    const variablesXML = moveCategory('data') || variables(pickedBrainType, targetId);
    const commentsXML = moveCategory('comments') || comments();
    const drivetrainXML = moveCategory('drivetrain') || drivetrain(pickedBrainType, deviceInfo);
    const myBlocksXML = moveCategory('procedures') || myBlocks(pickedBrainType);
    const aiSpeechXML = moveCategory('aiSpeech') || aiSpeech(pickedBrainType, deviceInfo, isEnableAISpeech);
    const extensionsXML = moveCategory('extensions') || extensions(pickedBrainType, deviceInfo, isEnableAISpeech);

    const everything = [
        xmlOpen,
        motionXML, gap,
        drivetrainXML, gap,
        looksXML, gap,
        soundXML, gap,
        eventsXML, gap,
        controlXML, gap,
        sensingXML, gap,
        operatorsXML, gap,
        variablesXML, gap,
        myBlocksXML, gap,
        aiSpeechXML, gap,
        commentsXML, gap,
        extensionsXML
    ];

    for (const extensionCategory of categoriesXML) {
        everything.push(gap, extensionCategory.xml);
    }

    everything.push(xmlClose);
    return everything.join('\n');
};

export default makeToolboxXML;
