import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import visionSettingStyles from './vision-setting-dialog.css'
import { DetectedQRcodeTag, getMainTag } from './tag.jsx'

import log from '../../../lib/log';
import {
    isVisionConnected,
    isVisionInitiated,
    isVisionRecongInitiated,
    getImageTagList,
    setImageTagList
} from '../../../reducers/vision.js'
import { ANALYZING_TIMEOUT, STATE_COMMAND_DELAY, IS_ANALYZING } from './vision-utils'

import {
    SnapshotButton
} from './vision-other-dialog.jsx'
import loadingImg from '../svg/loading.svg'
var cancelTimeoutId = null;
class VisionQrcode extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = {
            tagList: this.props.getImageTagList,
            isImageFreezed: false,
            isAnalyzing: false,
            freezedStream: null
        }
    }

    componentDidMount() {
        if (this.props.isVisionDistribute) {
            this.refreshTagList();
            this.props.startVisionRealtime('VisionQrcode.componentDidMount');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.getImageTagList != this.props.getImageTagList) {
            this.refreshTagList();
        }
        if (prevProps.isVisionInitiated != this.props.isVisionInitiated) {
            if (this.props.isVisionConnected == true) {
                if (this.props.isVisionInitiated == true) {
                    this.props.startVisionRealtime('VisionQrcode.componentDidUpdate prevProps.isVisionInitiated != this.props.isVisionInitiated');
                } else {
                    this.props.initVision();
                    this.clearImage();
                }
            } else {
                this.clearImage();
            }
        }
    }
    clearImage() {
        this.props.clearImageTagList();
        this.setState({ tagList: [], isImageFreezed: false });
    }

    getQrcodeMsg() {
        if (!this.props.isVisionDistribute || !this.props.realtimeImage) return <div className={classNames(visionSettingStyles.disable)}><FormattedMessage id="gui.dialog.vision.qrcode.snapshot.msg" /></div>;
        if (!this.state.isImageFreezed) return <FormattedMessage id="gui.dialog.vision.qrcode.snapshot.msg" />;
        const mainData = getMainTag(this.state.tagList);
        return <div>{(this.state.isImageFreezed) ? this.state.isAnalyzing ? "" :
            (mainData && mainData.message) ? <div>{mainData.message}</div>
                : <FormattedMessage id="gui.dialog.vision.qrcode.detection.result.none" /> :
            <FormattedMessage id="gui.dialog.vision.qrcode.snapshot.msg" />}
        </div>

    }

    createTagLayout() {
        if (!this.state.tagList || !Array.isArray(this.state.tagList) || this.state.tagList.length < 1) return null;
        const mainData = getMainTag(this.state.tagList);
        return this.state.tagList.map((data, index) =>
            <DetectedQRcodeTag
                key={index}
                detectedTag={data}
                mainSize={mainData && mainData.size ? mainData.size : -1}
                enableTag={this.props.enableTag}
                imageWidth={this.props.imageWidth}
                imageHeight={this.props.imageHeight}
            />
        )
    }

    clearAnalyzingTimeout() {
        log.info('clearAnalyzingTimeout')
        if (cancelTimeoutId) {
            clearTimeout(cancelTimeoutId)
            cancelTimeoutId = null;
        }
    }

    clearAnalyzingState() {
        log.info('clearAnalyzingState')
        this.clearAnalyzingTimeout();
        this.setState({
            isAnalyzing: false,
            isImageFreezed: false,
        });
    }

    switchFreezeState() {
        const nextFreezed = !this.state.isImageFreezed;
        if (!this.props.isVisionDistribute) return;
        this.setState({ isImageFreezed: nextFreezed, freezedStream: this.props.realtimeImage })
        if (this.props.isVisionDistribute) {
            this.clearAnalyzingTimeout();

            if (nextFreezed == false) {
                this.props.startVisionRealtime('VisionQrcode.switchFreezeState nextFreezed == false');
                this.clearImage();
            } else {
                this.props.stopVisionRealtime('VisionQrcode.switchFreezeState nextFreezed == true');
                this.setState({ isAnalyzing: true });

                setTimeout(() => {
                    this.props.takeVisionSnapshot();
                    this.props.setTabLock(true);
                    this.props.setImageTagList([IS_ANALYZING]);

                    setTimeout(() => {
                        if (this.props.getQrCodeResult) this.props.getQrCodeResult()
                    }, STATE_COMMAND_DELAY * 5);

                    cancelTimeoutId = setTimeout(() => {
                        if (this.state.isAnalyzing) {
                            log.info('qrcode Unlock isAnalyzing by timeout')
                            this.props.setTabLock(false);
                            this.props.closeVision();
                            this.clearAnalyzingState();
                            this.clearImage();
                        }
                    }, ANALYZING_TIMEOUT);
                }, STATE_COMMAND_DELAY * 5)
            }

        }
    }

    createShotButton() {
        return !this.props.isVisionDistribute || !this.props.realtimeImage ? null
            : !this.state.isImageFreezed ?
                <div className={classNames(visionSettingStyles.snapshot, visionSettingStyles.textIdentification)}>
                    <SnapshotButton
                        onClickSnapshot={() => this.switchFreezeState()}
                        textId={"gui.dialog.vision.snapshot"}
                    />
                </div>
                : this.state.isAnalyzing ? <div className={classNames(visionSettingStyles.loadingMainPosition)} ><img src={loadingImg} className={classNames(visionSettingStyles.loading, visionSettingStyles.main)} /></div>
                    : <div className={classNames(visionSettingStyles.freezeButton, (this.props.isVisionDistribute) ? null : visionSettingStyles.disableButton)}
                        onClick={() => this.switchFreezeState()}>
                        <FormattedMessage
                            id={"gui.dialog.vision.back.realtime"}
                        />
                    </div>
    }

    refreshTagList() {
        var visionRealtimeResult = this.props.getImageTagList;
        if (visionRealtimeResult && Array.isArray(visionRealtimeResult)
            && visionRealtimeResult[0] != IS_ANALYZING) {
            this.setState({ tagList: visionRealtimeResult, isAnalyzing: false });
            this.clearAnalyzingTimeout();
            this.props.setTabLock(false);
        }
    }

    render() {
        return (
            <div className={classNames(visionSettingStyles.visionContentArea)}>
                <div className={classNames(visionSettingStyles.visionArea)}>
                    <div className={classNames(visionSettingStyles.visionBarArea)}>
                        <div className={classNames(visionSettingStyles.enableTagTitle)}>
                            <FormattedMessage
                                defaultMessage="Enable Tag"
                                description="Enable Tag Display"
                                id="gui.dialog.vision.color.tag.display"
                            />
                            <div className={classNames(visionSettingStyles.tagArea, this.props.enableTag ? visionSettingStyles.enableTagArea : visionSettingStyles.disableTagArea)}
                                onClick={() => this.props.switchTagInfo()}>
                                <div className={classNames(visionSettingStyles.enableButton,
                                    this.props.enableTag ? visionSettingStyles.enableTag : visionSettingStyles.disableTag)} />
                            </div>
                        </div>
                    </div>
                    <div className={classNames(visionSettingStyles.visionImgArea)}>
                        {(this.props.isVisionConnected && this.props.isVisionInitiated && this.props.realtimeImage) ? <img src={this.state.isImageFreezed ? this.state.freezedStream : this.props.realtimeImage} id={'visionImg'}
                            className={classNames(visionSettingStyles.visionImgSize)} /> :
                            <div className={classNames(visionSettingStyles.visionDisconnected)}>
                                <div className={classNames(visionSettingStyles.visionDisconnectedText)}>
                                    {this.props.getVisionInitStateString && this.props.getVisionInitStateString != "" ? this.props.getVisionInitStateString :
                                        <img src={loadingImg} className={classNames(visionSettingStyles.loading, visionSettingStyles.main)} />}
                                </div>
                            </div>}

                    </div>
                    <div className={classNames(visionSettingStyles.visionImgArea)}>
                        {(this.state.isImageFreezed) ?
                            <div className={classNames(visionSettingStyles.relativeArea)}>
                                {this.createTagLayout()}
                            </div>
                            : null}
                        {this.createShotButton()}
                    </div>
                </div >
                <div className={classNames(visionSettingStyles.visionInfoArea)}>
                    <div className={classNames(visionSettingStyles.visionDetectionResultTitle)} >
                        <div className={classNames(visionSettingStyles.visionPoint)} />
                        <FormattedMessage
                            defaultMessage="QRcode Detection result："
                            description="QRcode Detection result"
                            id="gui.dialog.vision.qrcode.detection.result.title"
                        />
                    </div>
                    <div className={classNames(visionSettingStyles.visionResultReminder)}>
                        <FormattedMessage id="gui.dialog.vision.qrcode.detection.result.multi" />
                    </div>
                    <div className={classNames(visionSettingStyles.qrcodeDetectionResult)} >
                        {this.getQrcodeMsg()}
                    </div>
                </div >

            </div >
        );
    }
}

VisionQrcode.propTypes = {
};

const mapStateToProps = state => ({
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state) && isVisionRecongInitiated(state),
    getImageTagList: getImageTagList(state)
});

const mapDispatchToProps = dispatch => ({
    clearImageTagList: () => dispatch(setImageTagList([])),
    setImageTagList: (list) => dispatch(setImageTagList(list))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisionQrcode);