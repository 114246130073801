import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import visionSettingStyles from './vision-setting-dialog.css'
import store from 'store';
import VM from 'scratch-vm';
import { DetectedFaceIdentificationTag, getMainTag, parseFixedToFloat } from './tag.jsx'
import { ANALYZING_TIMEOUT, IS_UPLOADING, LINK_COMMAND_DELAY, STATE_COMMAND_DELAY } from './vision-utils'

import log from '../../../lib/log';
import {
    isVisionConnected,
    isVisionInitiated,
    isVisionRecongInitiated,
    getImageTagList,
    setFaceIdentificationList,
    getFaceIdentificationList,
    setImageTagList,
    resetTempFaceFeature,
    setTempFaceFeature,
    getTempFaceFeature,
    setImagePixel,
    DEFAULT_FACE_IDNENTIFICATION_LIST,
    setProfilePhotoSave,
    getImageHeight,
    getImageWidth,
    setUploadImagePath,
    getUploadImagePath,
    setImageBase64
} from '../../../reducers/vision.js'
import {
    EditNameDialog,
    SnapshotButton,
    UploadButton,
    ReminderDialog,
    FaceRegisterDialog,
    TakePhotoDialog,
    QuestionDialog
} from './vision-other-dialog.jsx'


import editIcon from '../svg/cv_edit.svg'
import editWWIcon from '../svg/ww/cv_edit.svg'
import hintIcon from '../svg/cv_hint.svg'
import photoRemoveIcon from '../svg/cv_photo_removed.svg'
import countdonw1Icon from '../svg/cv_countdown_1.svg'
import countdonw2Icon from '../svg/cv_countdown_2.svg'
import countdonw3Icon from '../svg/cv_countdown_3.svg'
import loadingImg from '../svg/loading.svg'
import { VISION_IMAGE_MODE, VISION_NAME_RULE, IS_ANALYZING, OPEN_IMAGE_FILE_FAILED, VISION_DEFAULT_LANGUAGE_MAPPING, OPEN_IMAGE_FILE_SUCCESS } from './vision-utils.js';
import { EditUtils } from '../../device-manager/edit-page/edit-utils.js';
const { DEFAULT_LOCALE } = require('../../../config/project-config.js');

const OTHER_DIALOG_TYPE = {
    NONE: "",
    editName: "editName",
    reminder: "reminder",
    faceRegister: "faceRegister",
    takePhoto: "takePhoto",
    questionDelete: "questionDelete"
}

const EDIT_TYPE = {
    add: "add",
    edit: "edit"
}

const previewWidth = 640;
const previewHeight = 480;
const profileHeightWidth = 43;
const snapshotWidth = 304;
const snapshotHeigth = 256;
var cancelTimeoutId = null;
var countdownTimeoutIdArray = [null, null, null, null, null];
class VisionFaceIdentification extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = {
            tagList: this.props.getImageTagList,
            faceList: [],
            isImageFreezed: false,
            isProfileExpandOption: -1,
            editIndex: -1,
            showDialog: OTHER_DIALOG_TYPE.NONE,
            editType: "",
            tempEditName: "",
            isPhotoSave: false,
            runCountdonw: false,
            countdownIcon: null,
            isAnalyzing: false,
            imageMode: VISION_IMAGE_MODE.REALTIME,
            isPrivacyExpandOption: false,
            tempStream: null,
            freezedStream: null
        }
        this.takingPhoto = false;
        this.showTempStream = false;
        this.isRetry = false;
    }

    componentDidMount() {
        if (this.props.isVisionDistribute) {
            this.refreshTagList();
            this.props.startVisionRealtime('VisionFaceIdentification.componentDidMount');
        }
        this.loadProfilesFromVm();
        this.setState({ isAnalyzing: false, isImageFreezed: false, imageMode: VISION_IMAGE_MODE.REALTIME, isPhotoSave: store.get('isPhotoSave', false) });
        this.takingPhoto = false;
    }

    componentWillUnmount() {
        this.clearAnalyzingState('componentWillUnmount');
        this.clearImage();
        this.props.clearReducerImage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.getImageTagList != this.props.getImageTagList) {
            this.refreshTagList();
        }
        if (prevProps.getUploadImagePath != this.props.getUploadImagePath) {
            this.handleUploadImage();
        }
        if (prevProps.isVisionInitiated != this.props.isVisionInitiated) {
            if (this.props.isVisionConnected == true) {
                if (this.props.isVisionInitiated == true) {
                    this.loadProfilesFromVm();
                    this.props.startVisionRealtime('VisionFaceIdentification.componentDidUpdate prevProps.isVisionInitiated != this.props.isVisionInitiated');
                    this.onCloseDialog();
                } else {
                    this.clearAnalyzingState('prevProps.isVisionInitiated != this.props.isVisionInitiated this.props.isVisionInitiated == false');
                    this.props.initVision();
                    this.clearImage();
                }
            } else {
                this.clearAnalyzingState('prevProps.isVisionInitiated != this.props.isVisionInitiated this.props.isVisionConnected == false');
                this.clearImage();
            }
        }
        if (prevProps.isVisionConnected != this.props.isVisionConnected && this.props.isVisionConnected == false) {
            this.clearAnalyzingState('prevProps.isVisionConnected != this.props.isVisionConnected && this.props.isVisionConnected == false');
            this.clearImage();
        }
        if (prevProps.getTempFaceFeature != this.props.getTempFaceFeature) {
            const nowFeature = this.props.getTempFaceFeature;
            const prevFeature = prevProps.getTempFaceFeature;
            if (Array.isArray(nowFeature) && ((nowFeature.length > 0 && nowFeature[0] != IS_ANALYZING) || (nowFeature.length == 0 && Array.isArray(prevFeature) && prevFeature.length > 0))) {
                this.setState({ isAnalyzing: false });
                this.takingPhoto = false;
            }
        }
        if (prevProps.realtimeImage != this.props.realtimeImage && this.props.realtimeImage) {
            if (this.showTempStream) {
                this.showTempStream = false;
                this.setState({ tempStream: null })
            }
        }
    }

    clearAnalyzingState(from) {
        log.info('clearAnalyzingState from ' + from)
        this.clearAnalyzingTimeout('clearAnalyzingState');
        this.setState({
            isAnalyzing: false,
            isImageFreezed: false,
            imageMode: VISION_IMAGE_MODE.REALTIME
        });
        this.showTempStream = true;
    }

    clearAnalyzingTimeout(from) {
        if (cancelTimeoutId) {
            log.info(`clearAnalyzingTimeout cancelTimeoutId : ${cancelTimeoutId} from ${from}`)
            clearTimeout(cancelTimeoutId)
            cancelTimeoutId = null;
            log.info('clearAnalyzingTimeout clear : ', cancelTimeoutId)
        }
    }

    hasOnlyFaceFeature() {
        const feature = this.props.getTempFaceFeature;
        if (Array.isArray(feature)) {
            return (feature.length == 1 && feature[0] != IS_ANALYZING);
        }
        return false;
    }
    hasMultiFaceFeature() {
        const feature = this.props.getTempFaceFeature;
        if (Array.isArray(feature)) {
            return (feature.length > 1);
        }
        return false;
    }

    clearImage() {
        log.info('clearImage')
        this.props.setImageTagList([]);
        this.setState({ tagList: [], imageMode: VISION_IMAGE_MODE.REALTIME, isImageFreezed: false });
        this.takingPhoto = false;
    }


    refreshTagList() {
        var visionRealtimeResult = this.props.getImageTagList;
        if (visionRealtimeResult && Array.isArray(visionRealtimeResult)
            && this.state.showDialog != OTHER_DIALOG_TYPE.takePhoto
            && visionRealtimeResult[0] != IS_ANALYZING) {
            this.setState({ tagList: visionRealtimeResult, isAnalyzing: false });
            this.clearAnalyzingTimeout('refreshTagList');
            this.props.setTabLock(false);
        }
    }

    handleGetImageResult() {
        this.clearAnalyzingTimeout('handleGetImageResult');
        setTimeout(() => {
            if (this.state.imageMode == VISION_IMAGE_MODE.PICTURE) {
                if (this.state.showDialog == "") {
                    this.props.getVisionFaceRecongResult();
                } else {
                    this.props.getVisionFaceFeature();
                }
                cancelTimeoutId = setTimeout(() => {
                    if (this.state.isAnalyzing) {
                        log.info('uploadImage Unlock isAnalyzing by timeout')
                        this.props.setTabLock(false);
                        this.props.closeVision();
                        this.clearAnalyzingState('handleGetImageResult by timeout');
                        this.clearImage();
                    }
                }, ANALYZING_TIMEOUT);
            }
        }, LINK_COMMAND_DELAY)
    }

    onSelectPhotoSave(save) {
        store.set('isPhotoSave', save);
        this.setState({ isPhotoSave: save, isPrivacyExpandOption: false });
    }

    onClickProfileMore(index) {
        if (this.state.isAnalyzing) return;
        this.setState({ isProfileExpandOption: this.state.isProfileExpandOption == index ? -1 : index, isPrivacyExpandOption: false });
        //expand/shrink option
    }

    onClickPrivacySelected() {
        if (this.state.isAnalyzing) return;
        this.setState({ isPrivacyExpandOption: !this.state.isPrivacyExpandOption, isProfileExpandOption: -1 });
    }

    closeMoreOption() {
        this.setState({ isProfileExpandOption: -1, isPrivacyExpandOption: false });
    }

    onClickProfileAdd(index) {
        //show add photo
        if (!this.props.isVisionDistribute || this.state.isAnalyzing) return;
        if (!this.state.tempStream && this.state.imageMode == VISION_IMAGE_MODE.REALTIME) this.setState({ tempStream: this.props.realtimeImage })
        this.resetTagInfo();
        this.closeMoreOption();
        this.setState({ editIndex: index, showDialog: OTHER_DIALOG_TYPE.faceRegister, editType: EDIT_TYPE.add });
        if (this.state.isImageFreezed) this.switchFreezeState(false, 'onClickProfileAdd');
    }

    onClickSnapshot() {
        if (!this.props.isVisionDistribute) return;
        if (this.props.isVisionDistribute) {
            if (this.props.isVisionDistribute) this.switchFreezeState(false, 'onClickSnapshot');
            this.setState({ showDialog: OTHER_DIALOG_TYPE.takePhoto })
        }
    }

    onClickRetryTakePhoto() {
        this.switchFreezeState(false, 'onClickRetryTakePhoto');
    }

    onClickTakePhoto() {
        this.clearAnalyzingTimeout('onClickTakePhoto');
        if (this.state.isAnalyzing) return;
        if (this.takingPhoto) return;
        if (!this.props.isVisionDistribute) return;
        if (this.props.isVisionDistribute) {
            this.takingPhoto = true;
            this.setState({ runCountdonw: true, countdownIcon: countdonw3Icon })
            this.props.setTempFaceFeature([IS_ANALYZING])
            this.clearCountdownTimeoutIdArray();
            countdownTimeoutIdArray[0] = setTimeout(() => { if (this.props.isVisionDistribute) this.setState({ countdownIcon: countdonw2Icon }) }, 1000)
            countdownTimeoutIdArray[1] = setTimeout(() => { if (this.props.isVisionDistribute) this.setState({ countdownIcon: countdonw1Icon }) }, 2000)
            countdownTimeoutIdArray[2] = setTimeout(() => {
                if (this.props.isVisionDistribute) {
                    this.switchFreezeState(true, 'onClickTakePhoto');
                    this.setState({ runCountdonw: false, countdownIcon: null, isAnalyzing: true });
                }
            }, 3000)
            countdownTimeoutIdArray[3] = setTimeout(() => {
                if (this.props.isVisionDistribute) {
                    this.props.takeVisionSnapshot();
                }
            }, 3500)
            countdownTimeoutIdArray[4] = setTimeout(() => {
                if (this.props.isVisionDistribute) {
                    this.clearAnalyzingTimeout('onClickTakePhoto countdownTimeoutIdArray[3]');
                    this.props.getVisionFaceFeature();
                    cancelTimeoutId = setTimeout(() => {
                        if (this.state.isAnalyzing) {
                            log.info('onClickTakePhoto Unlock isAnalyzing by timeout')
                            this.resetTagInfo();
                            this.takingPhoto = false;
                            this.props.closeVision();
                            this.clearAnalyzingState('onClickTakePhoto by timeout');
                            this.clearImage();
                        }
                    }, ANALYZING_TIMEOUT);
                }
            }, 4000);
        }
    }

    onClickStopTakePhoto() {
        log.info('onClickStopTakePhoto')
        this.takingPhoto = false;
        this.clearCountdownTimeoutIdArray();
        this.setState({ runCountdonw: false, countdownIcon: null, isAnalyzing: false });
        this.resetTagInfo();
        this.clearAnalyzingState('onClickStopTakePhoto');
        if (this.props.isVisionDistribute) {
            this.switchFreezeState(false, 'onClickStopTakePhoto');
        }
    }


    clearCountdownTimeoutIdArray() {
        log.info('clearCountdownTimeoutIdArray')
        countdownTimeoutIdArray.forEach(countdownTimeoutId => {
            if (countdownTimeoutId) {
                clearTimeout(countdownTimeoutId)
            }
        })
        countdownTimeoutIdArray = [null, null, null, null];
    }

    onClickProfileDelete(index) {
        //show delete dialog
        if (this.state.isAnalyzing) return;
        this.setState({ editIndex: index, showDialog: OTHER_DIALOG_TYPE.questionDelete });
        this.closeMoreOption();
    }

    onClickProfileEdit(index) {
        //show edit photo
        if (!this.props.isVisionDistribute || this.state.isAnalyzing) return;
        if (!this.state.tempStream && this.state.imageMode == VISION_IMAGE_MODE.REALTIME) this.setState({ tempStream: this.props.realtimeImage })
        this.closeMoreOption();
        this.resetTagInfo();
        this.setState({ editIndex: index, showDialog: OTHER_DIALOG_TYPE.faceRegister, editType: EDIT_TYPE.edit });
    }

    onClickProfileHint() {
        //show hint dialog
        if (this.state.isAnalyzing) return;
        this.closeMoreOption();
        this.setState({ showDialog: OTHER_DIALOG_TYPE.reminder })
    }

    onClickEditProfileName(index) {
        //show edit name dialog
        if (this.state.isAnalyzing) return;
        this.closeMoreOption();
        const name = "" + this.state.faceList[index].name;
        this.setState({ editIndex: index, showDialog: OTHER_DIALOG_TYPE.editName, tempEditName: name });
    }

    onCloseDialog() {
        this.setState({ editIndex: -1, showDialog: OTHER_DIALOG_TYPE.NONE });
        if (this.state.isImageFreezed || this.state.imageMode == VISION_IMAGE_MODE.PICTURE) {
            this.switchFreezeState(false, 'onCloseDialog');
        }
    }

    onConfirmEditDialog() {
        let list = this.state.faceList;
        let index = this.state.editIndex;
        list[index].name = this.state.tempEditName;
        this.setFactList(list);
        this.onCloseDialog();
    }

    onConfirmTakePhoto() {
        if (!this.hasOnlyFaceFeature() || this.state.isAnalyzing) return
        let list = this.state.faceList;
        const index = this.state.editIndex;
        list[index].image = this.props.realtimeImage;
        list[index].feature = [this.props.getTempFaceFeature[0].ft] || [];
        this.setFactList(list);
        setTimeout(() => {
            this.switchFreezeState(false, 'onConfirmTakePhoto');
            this.onCloseDialog();
            this.resetTagInfo();
        }, STATE_COMMAND_DELAY)
    }

    onConfirmDeleteDialog() {
        const index = this.state.editIndex;
        let list = this.state.faceList
        list[index] = { name: list[index].name, image: "", feature: [] };
        this.setFactList(list);
        this.onCloseDialog();
    }

    onCancelEditDailog() {
        this.setState({ editIndex: -1, showDialog: OTHER_DIALOG_TYPE.NONE });
    }

    onCancelTakePhoto() {
        if (this.state.isImageFreezed) this.switchFreezeState(false, 'onCancelTakePhoto');
        this.resetTagInfo();
        this.onCloseDialog();
    }

    isOptionExpand(index) {
        return this.state.isProfileExpandOption == index;
    }

    isEditNameDialogShow() {
        return this.state.editIndex != -1;
    }

    loadProfilesFromVm() {
        let list = [];
        const vmList = this.props.vm.getCvProfiles() || DEFAULT_FACE_IDNENTIFICATION_LIST;
        if (Array.isArray(vmList)) {
            vmList.forEach(profile => list.push({ name: profile.name, image: profile.image, feature: profile.feature }));
            this.setFactList(vmList);
            this.props.setFaceIdentificationList(list)
            const isPhotoSave = store.get('isPhotoSave', false);
            this.props.setProfilePhotoSave(isPhotoSave)
        }
    }

    setFactList(list) {
        let enrollList = [];
        list.forEach(data => { if (data.feature && Array.isArray(data.feature) && data.feature.length > 0) enrollList.push({ 'name': data.name, 'feature': data.feature }) });
        if (this.props.enrollVisionProfileList) this.props.enrollVisionProfileList(enrollList)
        this.props.vm.setCvProfiles(list);
        this.setState({ faceList: list });
    }

    parseDataLanguage(ProfileDataList) {
        if (ProfileDataList && Array.isArray(ProfileDataList)) {
            const language = store.get("locale", DEFAULT_LOCALE);
            ProfileDataList.forEach(data => VISION_DEFAULT_LANGUAGE_MAPPING[language][data.name] ?
                data.name = VISION_DEFAULT_LANGUAGE_MAPPING[language][data.name] : data.name = data.name)
        }
        return ProfileDataList;
    }

    getProfilesLayout() {
        let list = this.parseDataLanguage(this.state.faceList);
        return <div className={classNames(visionSettingStyles.profileListArea)}>
            {list.map((data, index) =>
                <ProfileItem
                    key={'profile_' + index}
                    index={index + 1}
                    listLength={list.length}
                    profilePhoto={data.image}
                    hasFeature={data.feature && Array.isArray(data.feature) && data.feature.length > 0}
                    profileName={data.name}
                    isOptionExpand={this.isOptionExpand(index)}
                    onClickProfileMore={() => this.onClickProfileMore(index)}
                    onClickProfileAdd={() => this.onClickProfileAdd(index)}
                    onClickProfileEdit={() => this.onClickProfileEdit(index)}
                    onClickProfileDelete={() => this.onClickProfileDelete(index)}
                    onClickEditProfileName={() => this.onClickEditProfileName(index)}
                    onClickProfileHint={() => this.onClickProfileHint()}
                    enableButton={this.props.isVisionConnected && this.props.isVisionInitiated && this.props.realtimeImage && !this.state.isAnalyzing}
                    isWWVersion={this.props.isWWVersion}
                    imageSize={this.getImageResizeData(this.getSrcSize(data.image).height, this.getSrcSize(data.image).width, profileHeightWidth, profileHeightWidth)}
                />
            )}
        </div>
    }

    getSrcSize(src) {
        if (!src || src == "") return { height: profileHeightWidth, width: profileHeightWidth }
        var newImg = new Image();
        newImg.src = src;
        var height = newImg.height;
        var width = newImg.width;

        return { height: height, width: width }
    }

    getPrivacyLayout() {
        return <div className={classNames(visionSettingStyles.privacyArea)} >
            <div className={classNames(visionSettingStyles.privacyTitle)} >
                <div className={classNames(visionSettingStyles.visionPoint, visionSettingStyles.facePrivacy)} />
                <FormattedMessage
                    id="gui.dialog.vision.face.identification.privacy.title"
                />
            </div>
            <div className={classNames(visionSettingStyles.privacySelectedArea)}
                onClick={() => this.onClickPrivacySelected()}>
                <div className={classNames(visionSettingStyles.privacySelectedText)}>
                    <FormattedMessage
                        id={(this.state.isPhotoSave == true) ? "gui.dialog.vision.face.identification.privacy.save" : "gui.dialog.vision.face.identification.privacy.remove"}
                    /></div>
                <div className={classNames(visionSettingStyles.privacyArrow)} />
            </div>
            {(this.state.isPrivacyExpandOption) ?
                <div className={classNames(visionSettingStyles.privacyOptionArea)} >
                    <div className={classNames(visionSettingStyles.privacyOption)}
                        onClick={() => this.onSelectPhotoSave(false)}>
                        <div className={classNames(visionSettingStyles.privacyOptionText)} >
                            <FormattedMessage
                                id="gui.dialog.vision.face.identification.privacy.remove"
                            />
                        </div>
                        <div className={classNames(visionSettingStyles.privacyOptionReminder)} >
                            <FormattedMessage
                                id="gui.dialog.vision.face.identification.privacy.remove.reminder"
                            />
                        </div>
                    </div>
                    <div className={classNames(visionSettingStyles.privacyOption)}
                        onClick={() => this.onSelectPhotoSave(true)}>
                        <div className={classNames(visionSettingStyles.privacyOptionText)}                           >
                            <FormattedMessage
                                id="gui.dialog.vision.face.identification.privacy.save"
                            />
                        </div>
                        <div className={classNames(visionSettingStyles.privacyOptionReminder)} >
                            <FormattedMessage
                                id="gui.dialog.vision.face.identification.privacy.save.reminder"
                            />
                        </div>
                    </div>

                </div>
                : null}
        </div>
    }

    createTagLayout() {
        if (this.state.showDialog == OTHER_DIALOG_TYPE.faceRegister || this.state.showDialog == OTHER_DIALOG_TYPE.takePhoto) return;
        const tagList = this.state.tagList;
        if ((!this.state.isImageFreezed && this.state.imageMode == VISION_IMAGE_MODE.REALTIME) || this.state.showDialog != OTHER_DIALOG_TYPE.NONE) return null;
        if (!tagList || !Array.isArray(tagList) || tagList.length == 0) return null;
        log.info('VisionFaceIdentification tagList > ', tagList)
        const mainTag = getMainTag(tagList);
        const imageSize = this.getImageResizeData(this.props.getImageHeight, this.props.getImageWidth, previewHeight, previewWidth);
        return tagList.map((data, index) =>
            <DetectedFaceIdentificationTag
                key={index}
                detectedTag={data}
                enableTag={this.props.enableTag}
                mainTag={mainTag}
                imgWidth={this.props.getImageWidth}
                imgHeight={this.props.getImageHeight}
                photoMode={this.state.imageMode}
                imageSize={imageSize}
            />
        )
    }

    getDetectedTagInfo() {
        if (!this.state.tagList || !Array.isArray(this.state.tagList)) return null;
        if (this.state.showDialog == OTHER_DIALOG_TYPE.takePhoto
            || this.state.showDialog == OTHER_DIALOG_TYPE.faceRegister
            || this.state.isAnalyzing) return null;
        if (!this.props.isVisionDistribute || !this.props.realtimeImage) return <div className={classNames(visionSettingStyles.disable)}><FormattedMessage id="gui.dialog.vision.face.snapshot.msg" /></div>;
        if (!this.state.isImageFreezed && this.state.imageMode == VISION_IMAGE_MODE.REALTIME) return <FormattedMessage id="gui.dialog.vision.face.snapshot.msg" />
        if (this.state.tagList.length == 0) {
            return <FormattedMessage id="gui.dialog.vision.face.detection.none" />
        }
        const mainTag = getMainTag(this.state.tagList)
        if (mainTag.recog_result && Array.isArray(mainTag.recog_result)) {
            return mainTag.recog_result.map((data, index) => {
                return (data[0] == 'unknown' && data[1] == -1) ?
                    <div key={'getDetectedTagInfo' + index} className={classNames(visionSettingStyles.textIdentificationTitle)}>{EditUtils.getLocaleString('gui.dialog.vision.face.identification.stranger')}</div> :
                    <div key={'getDetectedTagInfo' + index} className={classNames(visionSettingStyles.textIdentificationTitle)}>{`${(data[0])}, ${EditUtils.getLocaleString('gui.dialog.vision.face.identification.confidence')}${parseFixedToFloat(data[1], 1)}%`}</div>
            })
        }
    }

    switchFreezeState(force, from) {
        log.info(`VisionFaceIdentification.switchFreezeState force : ${force} from ${from}`)
        const nextFreezed = force != "undefined" ? force : !this.state.isImageFreezed;
        if (!this.props.isVisionDistribute || this.state.isAnalyzing) return;
        this.clearAnalyzingTimeout('switchFreezeState');
        this.setState({ isImageFreezed: nextFreezed, imageMode: VISION_IMAGE_MODE.REALTIME, freezedStream: this.props.realtimeImage })
        if (from == 'onClickTakePhoto') {
            log.log('onClickTakePhoto this.props.realtimeImage > ', this.props.realtimeImage)
        }
        if (this.props.isVisionDistribute) {
            if (nextFreezed == false) {
                this.clearImage();
                this.props.setTabLock(false);
                this.props.startVisionRealtime('VisionFaceIdentification.switchFreezeState nextFreezed == false');
            } else {
                this.props.stopVisionRealtime('VisionFaceIdentification.switchFreezeState nextFreezed == true');
                this.setState({ isAnalyzing: true });
                if (this.state.showDialog != OTHER_DIALOG_TYPE.takePhoto
                    && this.state.showDialog != OTHER_DIALOG_TYPE.faceRegister) {
                    this.setState({ showDialog: OTHER_DIALOG_TYPE.NONE })
                    setTimeout(() => {
                        this.props.setImageTagList([IS_ANALYZING]);
                        this.props.setTabLock(true);
                        this.props.takeVisionSnapshot();
                        this.clearAnalyzingTimeout('switchFreezeState(true)');
                        setTimeout(() => {
                            this.props.getVisionFaceRecongResult();
                        }, STATE_COMMAND_DELAY * 5)
                        cancelTimeoutId = setTimeout(() => {
                            if (this.state.isAnalyzing) {
                                log.info('switchFreezeState Unlock isAnalyzing by timeout')
                                this.props.setTabLock(false);
                                this.takingPhoto = false;
                                this.props.closeVision();
                                this.clearAnalyzingState(`switchFreezeState(true) by timeout`);
                                this.clearImage();
                            }
                        }, ANALYZING_TIMEOUT);
                    }, STATE_COMMAND_DELAY * 5)
                }
            }
        }
    }

    uploadImageToGetFeature() {
        this.resetTagInfo();
        this.uploadImage();
        this.setState({ showDialog: OTHER_DIALOG_TYPE.faceRegister, imageMode: VISION_IMAGE_MODE.PICTURE })
    }

    uploadImageToGetFaceRecong() {
        if (!this.state.tempStream && this.state.imageMode == VISION_IMAGE_MODE.REALTIME) this.setState({ tempStream: this.props.realtimeImage })
        this.uploadImage();
    }

    uploadImage() {
        if (!this.props.isVisionDistribute) return;
        this.clearAnalyzingTimeout('uploadImage');
        this.setState({ imageMode: VISION_IMAGE_MODE.PICTURE, isImageFreezed: true });
        this.props.stopVisionRealtime('VisionFaceIdentification.uploadImage');
        this.setState({ isAnalyzing: true });
        this.props.clearImageBase64();
        this.props.setTempFaceFeature([IS_ANALYZING])
        this.showTempStream = false;
        setTimeout(() => {
            this.props.setUploadImagePath(IS_UPLOADING);
            this.props.uploadVisionImage();
        }, 3 * STATE_COMMAND_DELAY)
    }

    handleUploadImage() {
        if (!this.props.isVisionConnected || !this.props.isVisionDistribute || this.props.getUploadImagePath == IS_UPLOADING) return;
        console.log('handleUploadImage getUploadImagePath ', this.props.getUploadImagePath)
        if (this.props.getUploadImagePath && this.props.getUploadImagePath != "") {
            if (this.props.getUploadImagePath == OPEN_IMAGE_FILE_FAILED) {
                this.props.resetUploadImagePath();
                this.props.setTabLock(false);
                this.setState({ isAnalyzing: false });
                if (this.state.showDialog == OTHER_DIALOG_TYPE.takePhoto) {
                    if (!this.isRetry) {
                        this.setState({ showDialog: OTHER_DIALOG_TYPE.faceRegister })
                        this.switchFreezeState(false, '!this.isRetry')
                    } else {
                        this.isRetry = false;
                    }
                } else {
                    this.switchFreezeState(false, '!(this.state.showDialog == OTHER_DIALOG_TYPE.takePhoto)')
                }
            } else if (this.props.getUploadImagePath == OPEN_IMAGE_FILE_SUCCESS) {
                this.setState({ isAnalyzing: true });
                this.handleGetImageResult()
            } else {
                if (this.state.showDialog == OTHER_DIALOG_TYPE.faceRegister) {
                    this.props.setTabLock(true);
                    this.setState({ isAnalyzing: true, showDialog: OTHER_DIALOG_TYPE.takePhoto, imageMode: VISION_IMAGE_MODE.PICTURE })
                }
            }
        } else {
            this.switchFreezeState(false, '!(this.props.getUploadImagePath && this.props.getUploadImagePath != "")')
        }
    }

    getShotButton() {
        if (this.state.showDialog == OTHER_DIALOG_TYPE.faceRegister || this.state.showDialog == OTHER_DIALOG_TYPE.takePhoto) return;
        return !this.props.isVisionDistribute || !this.props.realtimeImage ? null
            : !this.state.isImageFreezed && this.state.imageMode == VISION_IMAGE_MODE.REALTIME ?
                <div className={classNames(visionSettingStyles.snapshotAndUpdloadArea)}>
                    <SnapshotButton
                        onClickSnapshot={() => this.switchFreezeState(true, 'SnapshotButton')}
                        textId={"gui.dialog.vision.button.snapshot"} />
                    <UploadButton
                        onClickUpload={() => { this.uploadImageToGetFaceRecong() }} />
                </div>
                : this.state.isAnalyzing && this.state.showDialog == OTHER_DIALOG_TYPE.NONE ? <div className={classNames(visionSettingStyles.loadingMainPosition)} ><img src={loadingImg} className={classNames(visionSettingStyles.loading, visionSettingStyles.main)} alt={"loading"} /></div>
                    : <div className={classNames(visionSettingStyles.freezeButton, (this.props.isVisionDistribute) ? null : visionSettingStyles.disableButton)}
                        onClick={() => this.switchFreezeState(false, 'back.realtime')}>
                        <FormattedMessage
                            id={"gui.dialog.vision.back.realtime"}
                        />
                    </div>
    }

    getEditName() {
        return this.state.editIndex != -1 ? this.state.faceList[this.state.editIndex].name : "";
    }

    onNameChange(event) {
        let input = event.target.value
        this.setState({ tempEditName: input });
    }

    isNameValid(name) {
        if (!name) return VISION_NAME_RULE.EMPTY;
        name = name.replace(/^\s*/, "");
        if (name.length === 0) return VISION_NAME_RULE.EMPTY;
        let valid = VISION_NAME_RULE.VALID;
        if (EditUtils.isNameContainSpExcludeSpace(name)) return VISION_NAME_RULE.INVALID;
        const faceList = this.state.faceList
        if (Array.isArray(faceList) && faceList.length > 0) {
            faceList.forEach((data, index) => { if (data.name == name && index != this.state.editIndex) valid = VISION_NAME_RULE.DUPLICATE; })
        }
        return valid
    }

    getFaceFeatureResult() {
        if (!this.hasOnlyFaceFeature()) return;
        let result = this.props.getTempFaceFeature[0];
        result.imgWidth = this.props.getImageWidth;
        result.imgHeight = this.props.getImageHeight;
        return result;
    }

    resetTagInfo() {
        this.props.resetTempFaceFeature();
        this.props.resetImagePixel();
    }

    getNameInvalidId(nameValidCode) {
        if (nameValidCode == VISION_NAME_RULE.DUPLICATE) return "gui.dialog.vision.profile.edit.name.reminder.duplicate"
        if (nameValidCode == VISION_NAME_RULE.EMPTY) return "gui.dialog.vision.profile.edit.name.reminder.empty"
        if (nameValidCode == VISION_NAME_RULE.INVALID) return "gui.dialog.vision.profile.edit.name.reminder.symbol"
        return null
    }

    getOtherDialog() {
        switch (this.state.showDialog) {
            case OTHER_DIALOG_TYPE.editName:
                return <EditNameDialog
                    show={this.state.showDialog == OTHER_DIALOG_TYPE.editName && this.state.editIndex != -1}
                    index={this.state.editIndex}
                    name={this.state.tempEditName}
                    onNameChange={(event) => { this.onNameChange(event) }}
                    onClickConfirm={() => { this.onConfirmEditDialog() }}
                    onClickCancel={() => { this.onCancelEditDailog() }}
                    nameValidCode={this.isNameValid(this.state.tempEditName)}
                    titleId={"gui.dialog.vision.profile.edit.name.title"}
                    reminderId={this.getNameInvalidId(this.isNameValid(this.state.tempEditName))}
                />
            case OTHER_DIALOG_TYPE.reminder:
                return <ReminderDialog
                    show={this.state.showDialog == OTHER_DIALOG_TYPE.reminder}
                    onClickConfirm={() => { this.onCloseDialog() }}
                    titleId="gui.dialog.vision.reminder.photo.missing.title"
                    msgId="gui.dialog.vision.reminder.photo.missing.msg"
                />
            case OTHER_DIALOG_TYPE.faceRegister:
                return <FaceRegisterDialog
                    show={this.state.showDialog == OTHER_DIALOG_TYPE.faceRegister && this.state.editIndex != -1}
                    isNew={this.state.editType != EDIT_TYPE.edit}
                    onClickSnapshot={() => { this.onClickSnapshot() }}
                    onClickUpload={() => { this.uploadImageToGetFeature(); }}
                    onClickCancel={() => { this.onCloseDialog(); this.showTempStream = true; }}
                    isVisionConnected={this.props.isVisionConnected}
                    isVisionDistribute={this.props.isVisionDistribute}
                    getVisionInitStateString={this.props.getVisionInitStateString}
                />
            case OTHER_DIALOG_TYPE.takePhoto:
                return <TakePhotoDialog
                    show={this.state.showDialog == OTHER_DIALOG_TYPE.takePhoto && this.state.editIndex != -1}
                    isNew={this.state.editType != EDIT_TYPE.edit}
                    isTakingPhoto={this.takingPhoto}
                    imgStream={this.state.isImageFreezed ? this.state.freezedStream : this.props.realtimeImage}
                    faceResult={this.getFaceFeatureResult()}
                    isVisionFreezed={(this.state.imageMode == VISION_IMAGE_MODE.REALTIME) ? this.state.isImageFreezed : true}
                    onClickSnapshot={() => {
                        if (this.takingPhoto) return;
                        if (this.state.imageMode == VISION_IMAGE_MODE.REALTIME) {
                            this.onClickTakePhoto()
                        }
                    }}
                    onClickRetry={() => {
                        this.resetTagInfo();
                        if (this.state.imageMode == VISION_IMAGE_MODE.REALTIME) {
                            this.switchFreezeState(false, 'TakePhotoDialog onClickRetry');
                            this.onClickRetryTakePhoto();
                        } else {
                            this.isRetry = true;
                            this.uploadImageToGetFeature()
                        }
                    }}
                    onClickStop={() => {
                        this.onClickStopTakePhoto();
                    }}
                    onClickConfirm={() => {
                        if (this.takingPhoto) return;
                        this.onConfirmTakePhoto();
                        this.showTempStream = true;
                    }}
                    onClickCancel={() => {
                        if (this.state.isAnalyzing || this.state.runCountdonw) return;
                        if (this.takingPhoto) this.onClickStopTakePhoto();
                        this.onCloseDialog();
                        this.switchFreezeState(false, 'TakePhotoDialog onClickCancel');
                        this.resetTagInfo();
                        this.showTempStream = true;
                    }}
                    countdownIcon={this.state.countdownIcon}
                    runCountdonw={this.state.runCountdonw}
                    isAnalyzing={this.state.isAnalyzing}
                    hasOnlyFaceFeature={this.hasOnlyFaceFeature()}
                    hasMultiFaceFeature={this.hasMultiFaceFeature()}
                    takingPhoto={this.takingPhoto}
                    isVisionConnected={this.props.isVisionConnected}
                    isVisionDistribute={this.props.isVisionDistribute}
                    photoMode={this.state.imageMode}
                    getVisionInitStateString={this.props.getVisionInitStateString}
                    imageSize={this.getImageResizeData(this.props.getImageHeight, this.props.getImageWidth, snapshotHeigth, snapshotWidth)}
                />
            case OTHER_DIALOG_TYPE.questionDelete:
                return <QuestionDialog
                    show={this.state.showDialog == OTHER_DIALOG_TYPE.questionDelete && this.state.editIndex != -1}
                    onClickConfirm={() => { this.onConfirmDeleteDialog() }}
                    onClickCancel={() => { this.onCloseDialog() }}
                    titleId={(this.state.faceList[this.state.editIndex].image != "") ? "gui.dialog.vision.delete.photo.title" : "gui.dialog.vision.delete.feature.title"}
                    msgId={(this.state.faceList[this.state.editIndex].image != "") ? "gui.dialog.vision.delete.photo.msg" : "gui.dialog.vision.delete.feature.msg"}
                />
            default:
                return null;
        }
    }

    getImageResizeData(imgHeight, imgWidth, previewHeight, previewWidth) {
        let height = imgHeight;
        let width = imgWidth;
        let marginTop = 0;
        let marginLeft = 0;
        let finalHeight = previewHeight;
        let finalWidth = previewWidth;
        if (previewHeight / previewWidth < height / width) {
            finalHeight = previewHeight;
            finalWidth = width * (previewHeight / height);
            marginTop = 0;
            marginLeft = (previewWidth - finalWidth) / 2;
        } else {
            finalHeight = height * (previewWidth / width);
            finalWidth = previewWidth;
            marginTop = (previewHeight - finalHeight) / 2;
            marginLeft = 0;
        }
        return {
            height: parseFixedToFloat(finalHeight), width: parseFixedToFloat(finalWidth),
            marginTop: parseFixedToFloat(marginTop), marginLeft: parseFixedToFloat(marginLeft),
            originHeight: parseFixedToFloat(imgHeight), originWidth: parseFixedToFloat(imgWidth)
        }
    }
    render() {
        const imageSize = this.getImageResizeData(this.props.getImageHeight, this.props.getImageWidth, previewHeight, previewWidth);
        return (
            <div className={classNames(visionSettingStyles.visionContentArea)}>
                <div className={classNames(visionSettingStyles.visionArea)}>
                    <div className={classNames(visionSettingStyles.visionBarArea)}>
                        <div className={classNames(visionSettingStyles.enableTagTitle)}>
                            <FormattedMessage
                                defaultMessage="Enable Tag"
                                description="Enable Tag Display"
                                id="gui.dialog.vision.color.tag.display"
                            />
                            <div className={classNames(visionSettingStyles.tagArea, this.props.enableTag ? visionSettingStyles.enableTagArea : visionSettingStyles.disableTagArea)}
                                onClick={() => this.props.switchTagInfo()}>
                                <div className={classNames(visionSettingStyles.enableButton,
                                    this.props.enableTag ? visionSettingStyles.enableTag : visionSettingStyles.disableTag)} />
                            </div>
                        </div>

                    </div>
                    <div className={classNames(visionSettingStyles.visionImgArea)}>
                        <div className={classNames(visionSettingStyles.relativeArea)}>
                            {(this.state.showDialog != OTHER_DIALOG_TYPE.takePhoto && this.state.showDialog != OTHER_DIALOG_TYPE.faceRegister) ?
                                (this.props.isVisionDistribute && this.props.realtimeImage) ?
                                    <div className={classNames(visionSettingStyles.visionImgSize)}>
                                        <img src={this.showTempStream ? this.state.tempStream : this.state.isImageFreezed ? this.state.freezedStream : this.props.realtimeImage} id={'visionImg'}
                                            style={{
                                                height: imageSize.height,
                                                width: imageSize.width,
                                                marginTop: imageSize.marginTop,
                                                // marginLeft: imageSize.marginLeft
                                            }}
                                            alt={"vision image"} />
                                        {this.getShotButton()}
                                    </div> :
                                    <div className={classNames(visionSettingStyles.visionDisconnected)}>
                                        <div className={classNames(visionSettingStyles.visionDisconnectedText)}>
                                            {(this.state.showDialog != OTHER_DIALOG_TYPE.takePhoto
                                                || (this.state.showDialog != OTHER_DIALOG_TYPE.faceRegister)) ?
                                                (this.props.getVisionInitStateString && this.props.getVisionInitStateString != "") ?
                                                    this.props.getVisionInitStateString :
                                                    <img src={loadingImg} className={classNames(visionSettingStyles.loading, visionSettingStyles.main)} alt={"loading"} />
                                                : null}
                                        </div>
                                    </div>
                                : <div className={classNames(visionSettingStyles.visionDisconnected)} />}
                            {this.createTagLayout()}
                        </div>
                    </div>
                </div >
                <div className={classNames(visionSettingStyles.visionInfoArea)}>

                    {this.getPrivacyLayout()}
                    <div className={classNames(visionSettingStyles.faceIdentificationTitle)} >
                        <div className={classNames(visionSettingStyles.visionPoint, visionSettingStyles.faceSetting)} />
                        <FormattedMessage id="gui.dialog.vision.face.identification.regist.title" />
                    </div>
                    <div className={classNames(visionSettingStyles.profileArea)} >
                        {this.getProfilesLayout()}
                    </div>
                    <div className={classNames(visionSettingStyles.faceIdentificationResultArea)} >
                        <div className={classNames(visionSettingStyles.visionDetectionResultTitle, visionSettingStyles.face)} >
                            <div className={classNames(visionSettingStyles.visionPoint)} />
                            <FormattedMessage
                                id="gui.dialog.vision.face.identification.result.title"
                            />
                        </div>
                        <div className={classNames(visionSettingStyles.visionResultReminder)}>
                            <FormattedMessage id="gui.dialog.vision.face.detection.result.multi" />
                        </div>
                        <div className={classNames(visionSettingStyles.faceIdentificationResultBlockScroll)}  >
                            <div className={classNames(visionSettingStyles.faceIdentificationResultBlock)} >
                                {this.getDetectedTagInfo()}
                            </div>
                        </div>
                    </div>
                </div >
                {this.getOtherDialog()}
            </div >
        );
    }
}

const ProfileItem = (props) => {
    const {
        index,
        listLength,
        enableButton,
        profilePhoto,
        profileName,
        hasFeature,
        onClickProfileMore,
        onClickProfileAdd,
        isOptionExpand,
        onClickProfileEdit,
        onClickProfileDelete,
        onClickEditProfileName,
        onClickProfileHint,
        isWWVersion,
        imageSize
    } = props
    const photo = hasFeature && profilePhoto == "" ?
        photoRemoveIcon : profilePhoto && profilePhoto != "" ?
            profilePhoto : null
    return <div className={classNames(visionSettingStyles.profileItemArea)} >
        <div className={classNames(visionSettingStyles.profileIndex)} >{index + "."}</div>
        {hasFeature && profilePhoto == "" ?
            <div className={classNames(visionSettingStyles.profileHint)}
                onClick={() => onClickProfileHint()}><img src={hintIcon} alt={"hint"} /></div>
            : null}

        <div className={classNames(visionSettingStyles.profileMore, hasFeature ? null : visionSettingStyles.disable)}
            onClick={() => (hasFeature) ? onClickProfileMore() : {}} style={{ cursor: (hasFeature) ? "pointer" : "default" }}>
            <div className={classNames(visionSettingStyles.profilePoint)} />
            <div className={classNames(visionSettingStyles.profilePoint)} />
            <div className={classNames(visionSettingStyles.profilePoint)} />
        </div>
        {isOptionExpand && hasFeature ?
            <div className={classNames(visionSettingStyles.profileMoreOptionArea, (index == listLength) ? visionSettingStyles.dropup : null)} >
                <div className={classNames(visionSettingStyles.profileMoreOption, enableButton ? null : visionSettingStyles.disable)}
                    onClick={() => onClickProfileEdit()}>
                    <FormattedMessage id="gui.dialog.vision.profile.change.photo" />
                </div>
                <div className={classNames(visionSettingStyles.profileMoreOption)}
                    onClick={() => onClickProfileDelete()}>
                    {photo != photoRemoveIcon
                        ? <FormattedMessage id="gui.dialog.vision.profile.remove.photo" />
                        : <FormattedMessage id="gui.dialog.vision.profile.remove.feature" />}
                </div>
            </div>
            : null}
        <div className={classNames(visionSettingStyles.profilePhoto)}
            style={{ cursor: (photo || !enableButton) ? "default" : "pointer" }}        >
            {(photo) ?
                <img src={photo}
                    className={classNames(visionSettingStyles.profilePhotoSize)}
                    style={{
                        height: imageSize.height,
                        width: imageSize.width,
                        marginTop: imageSize.marginTop
                    }}
                    alt={"profile photo size"} />
                : <div className={classNames(visionSettingStyles.profilePhotoSize, enableButton ? null : visionSettingStyles.disable)}
                    onClick={() => onClickProfileAdd()}>＋</div>}
        </div>
        <div className={classNames(visionSettingStyles.profileNameArea)} >

            <div className={classNames(visionSettingStyles.profileNameEdit)} >
                <img src={isWWVersion ? editWWIcon : editIcon}
                    className={classNames(visionSettingStyles.profileEditIcon)}
                    onClick={() => onClickEditProfileName()}
                    alt={"profile edit icon"}
                />
            </div>
            <div className={classNames(visionSettingStyles.profileName)} >
                {profileName}
            </div>
        </div>
    </div>
}



VisionFaceIdentification.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    isVisionConnected: isVisionConnected(state),
    isVisionInitiated: isVisionInitiated(state) && isVisionRecongInitiated(state),
    getImageTagList: getImageTagList(state),
    getFaceIdentificationList: getFaceIdentificationList(state),
    getTempFaceFeature: getTempFaceFeature(state),
    getImageHeight: getImageHeight(state),
    getImageWidth: getImageWidth(state),
    getUploadImagePath: getUploadImagePath(state)
});

const mapDispatchToProps = dispatch => ({
    setFaceIdentificationList: (list) => dispatch(setFaceIdentificationList(list)),
    setImageTagList: (list) => dispatch(setImageTagList(list)),
    setTempFaceFeature: (feature) => dispatch(setTempFaceFeature(feature)),
    resetTempFaceFeature: () => dispatch(resetTempFaceFeature()),
    resetImagePixel: () => dispatch(setImagePixel(0, 0)),
    setProfilePhotoSave: (save) => dispatch(setProfilePhotoSave(save)),
    resetUploadImagePath: () => dispatch(setUploadImagePath("")),
    setUploadImagePath: (path) => dispatch(setUploadImagePath(path)),
    clearImageBase64: () => dispatch(setImageBase64("")),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisionFaceIdentification);