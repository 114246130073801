import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import closeIcon from './svg/close_btn.svg';
import defaultScreenIcon from './svg/default_screen.svg';
import { focusDialog, getForegroundDialog, FOREGROUND_INDEX, BACKGROUND_INDEX, DIALOG_BOARD_GAME } from '../../reducers/dialog'
import {
    platformType,
    isPad,
    getPlatform,
    isReactNative
} from '../../lib/platform'

const ACTION = {
    move: 'move',
    resize: 'resize'
}

const CORNER = {
    upper: "upper",
    lower: "lower",
    left: "left",
    right: "right",
}

const DIRECTION = {
    horizontal: "horizontal",
    vertical: "vertical"
}

const TITLE_HEIGHT = 54

const Z_INDEX = {
    focus: 15,
    ignore: 10
}

let mousedownFunc = () => { };
let mousemoveFunc = () => { };
let mouseupFunc = () => { };
let resizeFunc = () => { };

class HelpDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, []);
        this.state = {
            translateX: (window.innerWidth - this.props.width) / 2,
            translateY: (window.innerHeight - this.props.height) / 2,
            resizeX: this.props.width,
            resizeY: this.props.height,
            default_size: true,
            cornerX: CORNER.right,
            cornerY: CORNER.lower,
            action: "",
            z_index: BACKGROUND_INDEX,
            frame: Z_INDEX.focus,
            mask: Z_INDEX.ignore,
            tabWidth: this.props.defaultTabWidth
        };
        this.platform = getPlatform();
        this.moving = false;
        this.resizing = false;
        this.rightPoint = ((window.innerWidth + this.props.width) / 2);
        this.bottomPoint = ((window.innerHeight + this.props.height) / 2);
        this.lastX = null;
        this.lastY = null;
        this.moveX = (window.innerWidth - this.props.width) / 2;
        this.moveY = (window.innerHeight - this.props.height) / 2;
    }

    componentDidUpdate(prevProps) {
        if (this.props.foregroundDialog && this.props.foregroundDialog != prevProps.foregroundDialog) {
            this.setState({ z_index: (this.props.foregroundDialog == this.props.dialogType) ? FOREGROUND_INDEX : BACKGROUND_INDEX })
        }
    }

    componentDidMount() {
        this.props.focusDialog(this.props.dialogType)
        mousedownFunc = e => { this.onMouseDown(e) }
        mousemoveFunc = e => { this.onMouseMove(e) }
        mouseupFunc = e => { this.onMouseUp(e) }
        if (isPad()) {
            window.addEventListener('touchstart', mousedownFunc);
            window.addEventListener('touchmove', mousemoveFunc);
            window.addEventListener('touchend', mouseupFunc);
        } else {
            window.addEventListener('mousedown', mousedownFunc);
            window.addEventListener('mousemove', mousemoveFunc);
            window.addEventListener('mouseup', mouseupFunc);
        }
        resizeFunc = e => { this.adjustDialogPosition(); }
        window.addEventListener('resize', resizeFunc);
    }

    componentWillUnmount() {
        if (isPad()) {
            window.removeEventListener('touchstart', mousedownFunc);
            window.removeEventListener('touchmove', mousemoveFunc);
            window.removeEventListener('touchend', mouseupFunc);
        } else {
            window.removeEventListener('mousedown', mousedownFunc);
            window.removeEventListener('mousemove', mousemoveFunc);
            window.removeEventListener('mouseup', mouseupFunc);
        }
        this.props.focusDialog()
        window.removeEventListener('resize', resizeFunc);
    }

    onMouseDown(e) {
        if (this.props.dialogType != this.props.foregroundDialog || e.button != 0) return
        this.setState({ frame: Z_INDEX.ignore, mask: Z_INDEX.focus })
        e.stopPropagation();
        if (this.state.action == "move") {
            this.moving = true;
        } else if (this.state.action == "resize") {
            this.resizing = true;
            if (this.state.cornerX == CORNER.left) this.rightPoint = this.state.translateX + this.state.resizeX;
            if (this.state.cornerY == CORNER.upper) this.bottomPoint = this.state.translateY + this.state.resizeY;
        }
    }

    onMouseUp(e) {
        if (this.props.dialogType != this.props.foregroundDialog || e.button != 0) return
        this.setState({ frame: Z_INDEX.focus, mask: Z_INDEX.ignore })
        e.stopPropagation();
        this.adjustDialogPosition();
        this.moving = false;
        this.resizing = false;
        this.lastX = null;
        this.lastY = null;
        this.setState({ action: "" })
    }

    adjustDialogPosition() {
        let adjustTransX = this.calculateMove(this.state.translateX, this.state.resizeX / 5, window.innerWidth, DIRECTION.horizontal);
        let adjustTransY = this.calculateMove(this.state.translateY, TITLE_HEIGHT, window.innerHeight, DIRECTION.vertical)
        this.setState({
            translateX: adjustTransX,
            translateY: adjustTransY,
        })
        if (this.moveX != adjustTransX) this.moveX = adjustTransX;
        if (this.moveY != adjustTransY) this.moveY = adjustTransY;
    }

    onMouseMove(e) {
        if (this.props.dialogType != this.props.foregroundDialog || e.button != 0) return
        this.moving && this.onMove(e);
        this.resizing && this.onResize(e);
    }

    onMove(e) {
        if (!this.moving || this.state.action == "") return
        var x = e.clientX, y = e.clientY;
        if (isPad()) {
            x = e.pageX;
            y = e.pageY;
        }
        if (isReactNative()) {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        }
        if (this.lastX && this.lastY) {
            let dx = x - this.lastX;
            let dy = y - this.lastY;
            this.moveX = this.state.translateX + dx;
            this.moveY = this.state.translateY + dy;
            this.setState({
                translateX: this.state.translateX + dx,
                translateY: this.state.translateY + dy,
            })
        }
        this.lastX = x;
        this.lastY = y;
    }

    onResize(e) {
        if (!this.resizing || isPad()) return
        var x = e.clientX, y = e.clientY;
        if (isPad()) {
            x = e.pageX;
            y = e.pageY;
        }
        if (this.lastX && this.lastY) {
            let dx = x - this.lastX;
            let dy = y - this.lastY;

            if (this.state.resizeX == this.props.minWidth &&
                ((this.state.cornerX == CORNER.left && x >= this.moveX) || (this.state.cornerX == CORNER.right && x <= this.moveX + this.state.resizeX))) dx = 0;

            if (this.state.resizeY == this.props.minHeight &&
                ((this.state.cornerY == CORNER.upper && y >= this.moveY) || (this.state.cornerY == CORNER.lower && y <= this.moveY + this.state.resizeY))) dy = 0;

            let rX = this.calculateLength(this.calculateDeltaX(dx), this.props.minWidth, this.calculateMaxX());
            let rY = this.calculateLength(this.calculateDeltaY(dy), this.props.minHeight, this.calculateMaxY());
            rX = rX < window.innerWidth ? rX : window.innerWidth;
            rY = rY < window.innerHeight ? rY : window.innerHeight;
            let transX = (this.state.cornerX == CORNER.left)
                ? this.calculateLength(this.state.translateX + dx, this.rightPoint - rX, this.rightPoint - this.props.minWidth)
                : this.moveX;
            let transY = (this.state.cornerY == CORNER.upper)
                ? this.calculateLength(this.state.translateY + dy, this.bottomPoint - rY, this.bottomPoint - this.props.minHeight)
                : this.moveY;
            this.setState({
                translateX: transX,
                translateY: transY,
                resizeX: rX,
                resizeY: rY,
                default_size: (this.state.resizeX == this.props.width && this.state.resizeY == this.props.height),
                tabWidth: (rX > this.props.defaultTabWidth + this.props.minContentWidth) ? this.props.defaultTabWidth : rX - this.props.minContentWidth
            })
            if (this.props.setDialogSize) this.props.setDialogSize(rX, rY);
        }
        this.lastX = x;
        this.lastY = y;
    }

    calculateMaxX() {
        if (this.state.cornerX == CORNER.left) {
            return this.state.translateX + this.state.resizeX
        } else if (this.state.cornerX == CORNER.right) {
            return window.innerWidth - this.state.translateX
        }
    }

    calculateMaxY() {
        if (this.state.cornerY == CORNER.upper) {
            return this.state.translateY + this.state.resizeY
        } else if (this.state.cornerY == CORNER.lower) {
            return window.innerHeight - this.state.translateY
        }
    }

    calculateDeltaX(delta) {
        if (this.state.cornerX == CORNER.left) {
            return this.state.resizeX - delta;
        } else if (this.state.cornerX == CORNER.right) {
            return this.state.resizeX + delta;
        }
    }

    calculateDeltaY(delta) {
        if (this.state.cornerY == CORNER.upper) {
            return this.state.resizeY - delta;
        } else if (this.state.cornerY == CORNER.lower) {
            return this.state.resizeY + delta;
        }
    }

    calculateMove(move, dialogLenght, maxLength, direction) {
        let min = (direction && direction == DIRECTION.horizontal) ? (dialogLenght - this.state.resizeX) : 0;
        let max = maxLength - dialogLenght
        return this.calculateLength(move, min, max)
    }

    calculateLength(length, min, max) {
        if (min < length && length < max) {
            return length
        } else if (min >= length) {
            return min
        } else {
            return max
        }
    }

    clickClose() {
        this.props.onClose();
        // this.setState({ translateX: 0, translateY: 0, resizeX: this.props.width, resizeY: this.props.height, max_size: false })
    }
    clickDefaultScreen() {
        this.setState({
            default_size: true,
            resizeX: this.props.width,
            resizeY: this.props.height,
            translateX: (window.innerWidth - this.props.width) / 2,
            translateY: (window.innerHeight - this.props.height) / 2,
        })
        // if (this.state.max_size) this.setState({ translateX: 0, translateY: 0, resizeX: this.props.width, resizeY: this.props.height });
    }

    render() {
        if (!this.props.show) { return null; }
        return (
            <div className={classNames(styles.floatStyle)}
                style={{ top: this.state.translateY + 'px', left: this.state.translateX + 'px', zIndex: this.state.z_index }}>
                <div className={classNames(styles.floatModalStyle)}
                    style={{ width: this.state.resizeX + "px", height: this.state.resizeY + "px" }}>
                    <div
                        className={classNames(styles.helpHeaderArea)}>
                        <div
                            className={classNames(styles.helpHeader)}
                            onTouchStart={() => this.setState({ action: ACTION.move })}
                            onMouseDown={() => this.setState({ action: ACTION.move })}
                            style={{ cursor: "move" }}
                        >
                            <div className={classNames(styles.helpTitle)}>
                                <FormattedMessage
                                    id={this.props.titleId}
                                />
                            </div>
                        </div>
                        <div className={classNames(styles.helpClose)} onClick={() => this.clickClose()}>
                            <img src={closeIcon} alt={"close icon"} />
                        </div>
                    </div>

                    <div
                        style={{
                            width: this.state.resizeX + "px",
                            height: this.state.resizeY - TITLE_HEIGHT + "px",
                            zIndex: this.state.frame, position: 'absolute',
                            top: TITLE_HEIGHT + "px"
                        }}>
                        <div style={{ width: this.state.tabWidth + "px" }} className={classNames(styles.tabListArea)} >{this.props.tabList}</div>
                        <div style={{ left: this.state.tabWidth + "px" }} className={classNames(styles.helpMidLine)} />
                        <div style={{
                            left: this.state.tabWidth + 7 + "px",
                            width: this.state.resizeX - this.state.tabWidth + 1 + "px"
                        }} className={classNames(styles.contentArea)} >{this.props.content}</div>
                    </div>
                    {(this.state.action != ACTION.resize) ? null :
                        <div style={{
                            width: this.state.resizeX - this.state.tabWidth + "px",
                            height: this.state.resizeY - TITLE_HEIGHT + "px",
                            zIndex: this.state.mask, position: 'absolute',
                            top: TITLE_HEIGHT,
                            left: this.state.tabWidth
                        }}></div>
                    }

                    <div className={classNames(styles.helpResize, styles.helpResizeUpperLeft)}
                        onMouseDown={() => { this.setState({ cornerX: CORNER.left, cornerY: CORNER.upper, action: ACTION.resize }) }}
                    >
                    </div>
                    <div className={classNames(styles.helpResize, styles.helpResizeUpperRight)}
                        onMouseDown={() => { this.setState({ cornerX: CORNER.right, cornerY: CORNER.upper, action: ACTION.resize }) }}
                    >
                    </div>
                    <div className={classNames(styles.helpResize, styles.helpResizeLowerLeft)}
                        onMouseDown={() => { this.setState({ cornerX: CORNER.left, cornerY: CORNER.lower, action: ACTION.resize }) }}
                    >
                    </div>
                    <div className={classNames(styles.helpResize, styles.helpResizeLowerRight)}
                        onMouseDown={() => { this.setState({ cornerX: CORNER.right, cornerY: CORNER.lower, action: ACTION.resize }) }}
                    >
                    </div>
                </div>
            </div >
        );
    }
}

HelpDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    titleId: PropTypes.string.isRequired,
    dialogType: PropTypes.string,
    defaultTabWidth: PropTypes.number.isRequired,
    minContentWidth: PropTypes.number.isRequired,
    minWidth: PropTypes.number.isRequired,
    minHeight: PropTypes.number.isRequired,
    tabList: PropTypes.object,
    content: PropTypes.object,
};

const mapStateToProps = state => ({
    foregroundDialog: getForegroundDialog(state)
});

const mapDispatchToProps = dispatch => ({
    focusDialog: dialog => dispatch(focusDialog(dialog))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HelpDialog);