import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import log from '../../lib/log.js';
import {
    getErrorMsg,
    isErrorDialogShow,
    getErrorType,
    errorType,
    hideErrorDialog
} from '../../reducers/dialog';
import { CONCEPTS_LIMIT, SENTENCES_LIMIT } from '../gui/speaker-import.js'
import { setBrainDownloadingStatus } from '../../reducers/brain'
import { setFileProcessing } from '../../reducers/file-manager';

class ErrorDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
        ]);
    }
    renderContentType() {
        switch (this.props.type) {
            case errorType.ERASE_SLOT_FAILED:
                return (
                    <FormattedMessage
                        defaultMessage="Erase slot failed. \\nPlease try agin."
                        description="Erase slot failed. \\nPlease try agin."
                        id="gui.dialog.error.erase.slot.failed"
                    />
                );
            case errorType.NETWORK_DISCONNECTED:
                return (
                    <FormattedMessage
                        defaultMessage="Please check your network connection."
                        description="Please check your network connection."
                        id="gui.dialog.error.network.disconnected"
                    />
                );
            case errorType.UPDATE_NETWORK_DISCONNECTED:
                return (
                    <FormattedMessage
                        defaultMessage="The network is disconnected, please connect the network to update the latest version."
                        description="Please check your network connection."
                        id="gui.dialog.error.update.network.disconnected"
                    />
                );
            case errorType.FIRMWARE_UPDATE_SERVER_ERROR:
                return (
                    <FormattedMessage
                        defaultMessage="We're undergoing a bit of scheduled maintenance. \\nPlease try to update your firmware after few minutes."
                        description="We're undergoing a bit of scheduled maintenance."
                        id="gui.dialog.error.firmware.update.server.error"
                    />
                );
            case errorType.FILE_NO_EXIST:
                return (
                    <div>
                        <FormattedMessage
                            defaultMessage="File no exist"
                            description="File no exist"
                            id="gui.dialog.error.file.noExist"
                        />
                        {(this.props.errMsg) ?
                            <div>
                                ({this.props.errMsg})
                            </div> :
                            null
                        }
                    </div>
                );
            case errorType.FILE_NAME_DUPLICATE:
                return (
                    <FormattedMessage
                        defaultMessage="File name is duplicate"
                        description="File name is duplicate"
                        id="gui.dialog.error.file.duplicate"
                    />
                );
            case errorType.FIRMWARE_UPDATE_FAILED:
                return (
                    <FormattedMessage
                        defaultMessage="Firmware updating failed. \\nPlease try agin."
                        description="Firmware updating failed. \\nPlease try agin."
                        id="gui.dialog.error.firmware.updateFailed"
                    />
                );
            case errorType.UPDATE_FILE_NOT_FOUND:
            case errorType.UPDATE_FAIL_CHECKSUM_MISMATCH:
            case errorType.UPDATE_FAIL_SIZE_WRONG:
            case errorType.UPDATE_FAIL_ON_READONLY:
                return (
                    <div>
                        <FormattedMessage
                            defaultMessage="Update fail"
                            description="Update fail"
                            id="gui.dialog.error.update.fail"
                        />
                        {/* <div>
                            ({this.props.errMsg})
                        </div> */}
                    </div>
                );
            case errorType.UPDATE_FAIL_SERVER_TIMEOUT:
            case errorType.UPDATE_FAIL_HTTP_ERROR:
                return (
                    <div>
                        <FormattedMessage
                            defaultMessage="The server is not responding, please try again"
                            description="The server is not responding, please try again"
                            id="gui.dialog.error.update.server.noRespond"
                        />
                        {/* <div>
                            ({this.props.errMsg})
                        </div> */}
                    </div>
                );
            case errorType.OVER_EVENT_STARTED_UPPER_BOUND:
                return (
                    <FormattedMessage
                        defaultMessage='Each project is limited to 10 "when started" blocks.'
                        description="question dialog event_started block over quantity"
                        id="gui.block.eventStarted.upperBound"
                    />
                );
            case errorType.OVER_EVENT_STARTED_UPPER_BOUND_EDU:
                return (
                    <FormattedMessage
                        defaultMessage='Each project is limited to 10 "when" type blocks.'
                        description="question dialog event_started block over quantity"
                        id="gui.block.eventStarted.upperBound.edu"
                    />
                );
            case errorType.OVER_AUDIO_SIZE_UPPER_BOUND:
                return (
                    <FormattedMessage
                        defaultMessage="Your audio file format does not match or the capacity exceeds 1G, please try again."
                        description="question dialog event_started block over quantity"
                        id="gui.block.audioSize.upperBound"
                    />
                );
            case errorType.BRAIN_STATUS_MODE_ERROR:
                return (
                    <FormattedMessage
                        defaultMessage="Brain is busy. \\nPlease waiting."
                        description="Brain is busy. \\nPlease waiting."
                        id="gui.dialog.error.brain.status.mode"
                    />
                );
            case errorType.BREAK_POSITION_ILEGAL:
                return (
                    <FormattedMessage
                        defaultMessage="The project could not start because a 'break' block in the project is incorrectly placed. All 'break' blocks must be placed inside of a loop."
                        description="The project could not start because a 'break' block in the project is incorrectly placed. All 'break' blocks must be placed inside of a loop."
                        id="gui.dialog.error.block.break.position.error"
                    />
                );
            case errorType.BT_UPDATE_FAIL:
                return (
                    <FormattedMessage
                        defaultMessage="Bluetooth module update failed, please confirm the connection is normal and try again"
                        description="Bluetooth module update failed, please confirm the connection is normal and try again"
                        id="gui.dialog.error.bt.update.fail"
                    />
                );
            case errorType.BT_UPDATE_FAIL_CONTINUE_UPDATE_EDU:
                return (
                    <div>
                        <FormattedMessage
                            defaultMessage="Bluetooth firmware update failed, please confirm and try again later"
                            description="Bluetooth firmware update failed, please confirm and try again later"
                            id="gui.dialog.error.bt.update.fail.continue.update.edu"
                        />
                        <p />
                        <FormattedMessage
                            defaultMessage="Do not remove the main controller, it will continue to update the main controller for you"
                            description="Do not remove the main controller, it will continue to update the main controller for you"
                            id="gui.dialog.error.bt.update.fail.continue.update.edu2"
                        />
                    </div>
                );
            case errorType.BT_UPDATE_FAIL_DISCONNECT_MODULE:
                return (
                    <FormattedMessage
                        defaultMessage="Bluetooth module update failed, please reconnect the main controller or try again later"
                        description="Bluetooth module update failed, please reconnect the main controller or try again later"
                        id="gui.dialog.error.bt.update.fail.disconnect.module"
                    />
                );
            case errorType.BT_UPDATE_FAIL_DFU_TIMEOUT:
                return (
                    <FormattedMessage
                        defaultMessage="Bluetooth firmware update failed, please remove the main controller and reconnect"
                        description="Bluetooth firmware update failed, please remove the main controller and reconnect"
                        id="gui.dialog.error.bt.update.fail.dfu.timeout"
                    />
                );
            case errorType.RC_UPDATE_FAIL:
            case errorType.RC_UPDATE_FAIL_DISCONNECT_MODULE:
                return (
                    <FormattedMessage
                        id="gui.dialog.error.rc.update.fail"
                    />
                );
            case errorType.RC_UPDATE_FAIL_DFU_TIMEOUT:
                return (
                    <FormattedMessage
                        id="gui.dialog.error.rc.update.fail.dfu.timeout"
                    />
                );

            case errorType.FIRMWARE_UPDATE_TIMEOUT:
                return (
                    <FormattedMessage
                        defaultMessage="Firmware update failed, please remove the main controller and reconnect"
                        description="Firmware update failed, please remove the main controller and reconnect"
                        id="gui.dialog.error.firmware.update.timeout"
                    />
                );
            case errorType.CONNECT_BRAIN_FAILED:
                return (
                    <FormattedMessage
                        defaultMessage="The main controller is currently unavailable, please try again later"
                        description="The main controller is currently unavailable, please try again later"
                        id="gui.dialog.error.connecting.failed"
                    />
                );
            case errorType.FILE_PROCESSING:
                return (
                    <div>
                        <FormattedMessage
                            defaultMessage="File processing, please try again later"
                            description="File processing, please try again later"
                            id="gui.file.processing.dialog.processing"
                        />
                        <p />
                        <FormattedMessage
                            defaultMessage="It takes up to 60 seconds to wait"
                            description="It takes up to 60 seconds to wait"
                            id="gui.file.processing.dialog.processing.time"
                        />
                    </div>
                );
            case errorType.SPEAKER_SENTENCE_UP_TO_LIMIT:
                return (
                    <div className={classNames(styles.importContentArea)}>
                        <FormattedMessage
                            defaultMessage="The number of sentences has reached the upper limit ({number})"
                            description="The number of sentences has reached the upper limit ({number})"
                            id="gui.dialog.error.speaker.sentence.up.to.limit"
                            values={{ number: SENTENCES_LIMIT }}
                        />
                    </div>
                );
            case errorType.EXTENSION_BLOCK_NEED_WIFI:
                return (
                    <div className={classNames(styles.importContentArea)}>
                        <FormattedMessage
                            defaultMessage="Extension function blocks need to be connected to the Internet to obtain data. If you use such blocks, please remember to connect to wi-fi"
                            description="Extension function blocks need to be connected to the Internet to obtain data. If you use such blocks, please remember to connect to wi-fi"
                            id="gui.dialog.error.extension.block.need.wifi"
                        />
                    </div>
                );
            case errorType.SPEAKER_CONCEPT_UP_TO_LIMIT:
                return (

                    <div className={classNames(styles.importContentArea, styles.importExceedReminderSingleLine)}>
                        <FormattedMessage
                            defaultMessage="The number of concepts has reached the upper limit ({number})"
                            description="The number of concepts has reached the upper limit ({number})"
                            id="gui.dialog.error.speaker.concept.up.to.limit"
                            values={{ number: CONCEPTS_LIMIT }}
                        />
                    </div>
                );
            case errorType.XLSX_SHEETNAME_INVALID:
                return (
                    <FormattedMessage
                        defaultMessage="Enity lists import form is not valid"
                        description="Enity lists import form is not valid"
                        id="gui.dialog.error.xlsx.sheetname.invalid"
                    />
                );

            case errorType.UPDATE_SOFTWARE_CRASH:
                return (
                    <FormattedMessage
                        id="gui.dialog.error.update.file.crash"
                    />
                );
            case errorType.VISION_REBOOT:
                return <FormattedMessage
                    id="gui.dialog.vision.reboot"
                />
            case errorType.FILE_FORMAT_INVALID:
            case errorType.XLSX_FILE_INVALID:
                return (
                    <FormattedMessage
                        defaultMessage="File has wrong format"
                        description="File has wrong format"
                        id="gui.dialog.question.extensionNotMatch"
                    />
                );
            case errorType.SPEAKER_CUSTOMIZING_IMPORT_EXCEED:
                const exceedSentences = this.props.errMsg.exceedSentences;
                const exceedConcepts = this.props.errMsg.exceedConcepts;

                return <div>

                    <div className={classNames(styles.importContentArea)}>
                        {(exceedSentences > 0) ? <div>
                            <FormattedMessage
                                defaultMessage="The number of sentences will exceed the limit of ({number})"
                                description="The number of sentences will exceed the limit of ({number})"
                                id="gui.dialog.error.speaker.sentence.exceed"
                                values={{ number: exceedSentences }}
                            />
                        </div> : null}

                        {(exceedConcepts > 0) ? <div>
                            <FormattedMessage
                                defaultMessage="The number of concepts will exceed the limit of ({number})"
                                description="The number of concepts will exceed the limit of ({number})"
                                id="gui.dialog.error.speaker.concept.exceed"
                                values={{ number: exceedConcepts }}
                            />
                        </div> : null}
                    </div>
                    <div className={classNames(styles.importExceedReminderArea)}>
                        {(exceedSentences > 0) ? <div className={classNames(styles.importExceedReminder)}>
                            <FormattedMessage
                                defaultMessage="The maximum number of sentences in a file is {number}"
                                description="The maximum number of sentences in a file is {number}"
                                id="gui.dialog.error.speaker.sentence.upper.bound"
                                values={{ number: SENTENCES_LIMIT }}
                            />
                        </div> : null}
                        {(exceedConcepts > 0) ? <div className={classNames(styles.importExceedReminder)}>
                            <FormattedMessage
                                defaultMessage="The maximum number of concepts in a file is {number}"
                                description="The maximum number of concepts in a file is {number}"
                                id="gui.dialog.error.speaker.concept.upper.bound"
                                values={{ number: CONCEPTS_LIMIT }}
                            />
                        </div> : null}
                    </div>
                </div>
            default:
                return "Error : " + this.props.errMsg
        }
    }
    renderButtonType() {
        switch (this.props.type) {
            case errorType.FIRMWARE_UPDATE_SERVER_ERROR:
            case errorType.FIRMWARE_UPDATE_FAILED:
            case errorType.NETWORK_DISCONNECTED:
            case errorType.FILE_NO_EXIST:
            case errorType.OVER_EVENT_STARTED_UPPER_BOUND:
            case errorType.OVER_EVENT_STARTED_UPPER_BOUND_EDU:
            case errorType.BRAIN_STATUS_MODE_ERROR:
            case errorType.UPDATE_FAIL_SERVER_TIMEOUT:
            case errorType.UPDATE_FAIL_HTTP_ERROR:
            case errorType.BREAK_POSITION_ILEGAL:
            case errorType.FIRMWARE_UPDATE_TIMEOUT:
            case errorType.BT_UPDATE_FAIL:
            case errorType.BT_UPDATE_FAIL_DISCONNECT_MODULE:
            case errorType.BT_UPDATE_FAIL_DFU_TIMEOUT:
            case errorType.SPEAKER_CUSTOMIZING_IMPORT_EXCEED:
            case errorType.EXTENSION_BLOCK_NEED_WIFI:
            case errorType.CONNECT_BRAIN_FAILED:
            case errorType.FILE_PROCESSING:
            case errorType.SPEAKER_SENTENCE_UP_TO_LIMIT:
            case errorType.SPEAKER_CONCEPT_UP_TO_LIMIT:
            case errorType.RC_UPDATE_FAIL:
            case errorType.RC_UPDATE_FAIL_DISCONNECT_MODULE:
            case errorType.RC_UPDATE_FAIL_DFU_TIMEOUT:
            default:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionBtn} onClick={this.props.onClose}>
                            <FormattedMessage
                                defaultMessage="I know"
                                description="I know"
                                id="gui.dialog.error.Iknow"
                            />
                        </div>
                    </div>
                );
            case errorType.FILE_NAME_DUPLICATE:
            case errorType.UPDATE_NETWORK_DISCONNECTED:
            case errorType.UPDATE_SOFTWARE_CRASH:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionBtn} onClick={() => {
                            this.props.setBrainDownloadingStatus(false)
                            this.props.setFileProcessing(false);
                            this.props.onClose()
                        }}>
                            <FormattedMessage
                                defaultMessage="I know"
                                description="I know"
                                id="gui.dialog.error.Iknow"
                            />
                        </div>
                    </div>
                );
            case errorType.VISION_REBOOT:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionBtn} onClick={() => {
                            this.props.onClose();
                        }}>
                            <FormattedMessage
                                defaultMessage="I know"
                                description="I know"
                                id="gui.dialog.error.Iknow"
                            />
                        </div>
                    </div>
                );
            case errorType.XLSX_FILE_INVALID:
            case errorType.XLSX_SHEETNAME_INVALID:
            case errorType.FILE_FORMAT_INVALID:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionBtn} onClick={this.props.onClose}>
                            <FormattedMessage
                                id="gui.dialog.confirm"
                            />
                        </div>
                    </div>
                );
                break;
            case errorType.BT_UPDATE_FAIL_CONTINUE_UPDATE_EDU:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionBtn} onClick={() => {
                            this.props.onClose()
                            this.props.onUpdateEdu()
                        }}>
                            <FormattedMessage
                                defaultMessage="I know"
                                description="I know"
                                id="gui.dialog.error.Iknow"
                            />
                        </div>
                    </div>
                );
            case errorType.OVER_AUDIO_SIZE_UPPER_BOUND:
                return (
                    <div className={styles.questionFooter}>
                        <div className={styles.questionBtn} onClick={this.props.onClose}>
                            <FormattedMessage
                                defaultMessage="I know"
                                description="I know"
                                id="gui.dialog.error.Iknow"
                            />
                        </div>
                        <div className={styles.questionInterval} />
                        <div className={styles.questionBtn} onClick={() => {
                            this.props.onClose();
                            this.props.onReUpload();
                        }}>
                            <FormattedMessage
                                defaultMessage="re-upload"
                                description="re-upload"
                                id="gui.dialog.error.reupload"
                            />
                        </div>
                    </div>
                );

        }
    }
    render() {
        if (!this.props.show && !this.props.selfDefinedShow) { return null; }
        log.info(this.props.type + " : " + this.props.errMsg)
        return (
            <div className={classNames(styles.backdropStyle, styles.error)}>
                <div className={classNames(styles.modalStyle, styles.question)}>
                    <div className={styles.questionView}>
                        <div className={styles.questionContent}>
                            {this.renderContentType()}
                        </div>
                        {this.renderButtonType()}
                    </div>
                </div>
            </div>
        );
    }
}

ErrorDialog.propTypes = {
    onClose: PropTypes.func,
    onUpdateEdu: PropTypes.func,
    onReUpload: PropTypes.func,
    type: PropTypes.string,
    show: PropTypes.bool,
    selfDefinedShow: PropTypes.bool,
};

ErrorDialog.defaultProps = {
    onClose: () => { },
    onUpdateEdu: () => { },
    onReUpload: () => { },
    selfDefinedShow: false
}


const mapStateToProps = state => ({
    show: isErrorDialogShow(state),
    errMsg: getErrorMsg(state),
    type: getErrorType(state)
});

const mapDispatchToProps = dispatch => ({
    hideErrorDialog: () => dispatch(hideErrorDialog()),
    setBrainDownloadingStatus: (dwonloadingStatus) => dispatch(setBrainDownloadingStatus(dwonloadingStatus)),
    setFileProcessing: (status) => dispatch(setFileProcessing(status))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ErrorDialog);