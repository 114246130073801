import ScratchBlocks from 'scratch-blocks';

const looksBlocks = (function () {
    const hello = ScratchBlocks.ScratchMsgs.translate('LOOKS_HELLO', 'Hello!');
    const looksPrint =
        `<block type="looks_print">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">${hello}</field>
                </shadow>
            </value>
        </block>`;

    const looksSetCursortoRow =
        `<block type="looks_setcursortorow"/>`;

    const looksNextRow =
        `<block type="looks_nextrow"/>`;

    const looksSetPrecisionto =
        `<block type="looks_setprecisionto"/>`;

    const looksClearAllRows =
        `<block type="looks_clearallrows"/>`;

    const looksClearRow =
        `<block type="looks_clearrow"/>`;

    const looksSetLight =
        `<block type="looks_setlight"/>`;

    const looksSetLightVR =
        `<block type="looks_setlight_vr"/>`;

    const looksSetColorto =
        `<block type="looks_setcolorto"/>`;

    const looksSetColortoRGB =
        `<block type="looks_setcolortorgb">
            <value name="RED">
                <shadow type="colour_rgb">
                    <field name="COLOUR">255</field>
                </shadow>
            </value>
            <value name="GREEN">
                <shadow type="colour_rgb">
                    <field name="COLOUR">0</field>
                </shadow>
            </value>
            <value name="BLUE">
                <shadow type="colour_rgb">
                    <field name="COLOUR">0</field>
                </shadow>
            </value>
        </block>`;

    const looksSetFadeto =
        `<block type="looks_setfadeto"/>`;

    const looksSetBrightnessto =
        `<block type="looks_setbrightnessto">
            <value name="BRIGHTNESS">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>
        </block>`;

    const looksColorSetLightto =
        `<block type="looks_colorsetlightto">
            <value name="LIGHT">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>
        </block>`;

    return {
        looks_print: looksPrint,
        looks_setcursortorow: looksSetCursortoRow,
        looks_nextrow: looksNextRow,
        looks_setprecisionto: looksSetPrecisionto,
        looks_clearallrows: looksClearAllRows,
        looks_clearrow: looksClearRow,
        looks_setlight: looksSetLight,
        looks_setlight_vr: looksSetLightVR,
        looks_setcolorto: looksSetColorto,
        looks_setcolortorgb: looksSetColortoRGB,
        looks_setfadeto: looksSetFadeto,
        looks_setbrightnessto: looksSetBrightnessto,
        looks_colorsetlightto: looksColorSetLightto
    };
})();

export default looksBlocks;


