import { uiType } from '../../reducers/ui-style';
import colourWW from './colour-ww';
import colourVR from './colour-vr';
import colourCN from './colour-cn';

const getBlockColour = uiStyle => {
    if (uiStyle == uiType.ww) {
        return colourWW;
    } else if (uiStyle == uiType.vr) {
        return colourVR;
    } else {
        return colourCN;
    }
};

export default getBlockColour;