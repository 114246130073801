import * as monaco from 'monaco-editor';
import classNames from 'classnames';
import React from "react";
import { render } from "react-dom";
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import MonacoEditor from 'react-monaco-editor';
import log from '../../lib/log.js';
import styles from './code-editor.css';
import { FormattedMessage } from 'react-intl';
import {
    getIsShowingProject
} from '../../reducers/project-state';
import {
    setEditor,
    setUndoState,
    setRedoState,
    setNewEditor,
    getNewEditorState
} from '../../reducers/code-editor';
import { EDIT_MODE } from '../../lib/edit-mode.js';
import theme from './theme.js';
import conf from './conf.js';
import language from './language.js';

import messages from '../../lib/libraries/tag-messages.js';

import redoIcon from '../../../common-svg/redo.svg';
import undoIcon from '../../../common-svg/undo.svg';
import zoomInIcon from '../../../common-svg/zoom-in.svg';
import zoomOutIcon from '../../../common-svg/zoom-out.svg';
import zoomInIconWW from '../../../common-svg/zoom-in_ww.svg';
import zoomOutIconWW from '../../../common-svg/zoom-out_ww.svg';
import asusLogoIcon from '../../../common-svg/asus_logo.svg';
import tablist from '../../../static/help/blockhelp/index.json'
import Converter from 'Converter';
import { Stack } from './Stack.js';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import {
    getPickedBrainType,
} from '../../reducers/picked-brain-type'
import { BRAIN_TYPE } from '../../lib/brains';
import ScratchBlocks from 'scratch-blocks';
import {
    editBlockType
} from '../../reducers/dialog';
import {
    getWorkspace
} from '../../reducers/block';

import {
    uiType,
    getUIStyle,
} from '../../reducers/ui-style';

import {
    isPad,
} from '../../lib/platform';

const LANGUAGE_ID = "blockPython";

let editor = null;
let drag = "";
let commandList;
let commandListEntry = [];
let commandListEduAndEntry = [];
let autoCompletionMap;
let autoCompletionMapEntry = new Map();
let autoCompletionMapEduAndEntry = new Map();

// Keywords array for theme
let BLOCK_CLASSES;
let BLOCK_CLASSES_ENTRY = [];
let BLOCK_CLASSES_EDU_AND_ENTRY = [];
let BLOCK_METHODS;
let BLOCK_METHODS_ENTRY = [];
let BLOCK_METHODS_EDU_AND_ENTRY = [];
let BLOCK_CONSTANTS = [];
let BLOCK_CONSTANT_VALUES = [];

let BLOCK_CONDITIONS;
let BLOCK_CONDITIONS_ENTRY = [];
let BLOCK_CONDITIONS_EDU_AND_ENTRY = [];
let BLOCK_CONSTANTS_MAP = new Map();

let preLineLength = 0;
let autoCompletionStack = new Stack();

const menuItemAttributes = {
    className: styles.menuItem
}

class CodeEditor extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'renderCatagory',
            'renderCommandSet',
            'handleClickCatagory',
            'handleFontZoom',
            'editorDidMount',
            'editorWillMount',
            'onChange',
            'handleModelContentChange',
            'getCode',
            'clickMenuItem',
            'handleSoundListUpdate',
            'handleClickCatagoryArrow',
            'setCategoryRef',
            'isCategoryBoundary'
        ]);
        this.props.vm.on('soundListUpdate', this.handleSoundListUpdate);
        this.initialVersion = 0;
        this.currentVersion = 0;
        this.lastVersion = 0;
        this.commadBodyRefs = null;
        this.commandCatagoryRefs = {};
        this.state = {
            first: true,
            selected: 0,
            zoomLevel: 14,
            themeName: "myCustomTheme",
            toolboxCatagoryUpdate: false
        };
        this.monaco = null;
        this.createCommandList();
        this.catagoryPositionIndex = 0;
        this.toolboxCatagoryRefs = [];
        this.sliderMove = false;
    }

    createCommandList() {
        if (commandListEntry.length > 0 && commandListEduAndEntry.length > 0) {
            return;
        }

        let supportBlocks = tablist.support["ENTRY"];
        let supportEduAndEntryBlocks = tablist.support["EDU_AND_ENTRY"];
        const generator = new Converter("");

        // create constant array
        var constants = generator.getConstant();
        Object.entries(constants).forEach((constant) => {
            BLOCK_CONSTANTS_MAP.set(constant[0], constant[1]);
            BLOCK_CONSTANTS.push(constant[0]);
            BLOCK_CONSTANT_VALUES = BLOCK_CONSTANT_VALUES.concat(constant[1]);
        });
        Object.entries(tablist.category).forEach((category) => {
            let categoryId = category[0];
            let categorySupportBlocks = category[1].block;
            let commandSet = [];
            let commandSetEduAndEntry = [];
            categorySupportBlocks.forEach((block) => {
                if (supportBlocks.includes(block)) {
                    let commandInfo = generator.getCodeByOpcode(block);
                    let addCommand = commandInfo.name && commandInfo.code && commandInfo.constantType;
                    if (addCommand) {
                        let command = {
                            name: commandInfo.name,
                            code: commandInfo.code,
                            import: commandInfo.import,
                            constantType: commandInfo.constantType,
                            init: commandInfo.init
                        }
                        commandSet.push(command);
                        this.createAutoCompletionMap(commandInfo, BRAIN_TYPE.ENTRY);
                    }
                }
                if (supportEduAndEntryBlocks.includes(block)) {
                    let commandInfo = generator.getCodeByOpcode(block);
                    let addCommand = commandInfo.name && commandInfo.code && commandInfo.constantType;
                    if (addCommand) {
                        let command = {
                            name: commandInfo.name,
                            code: commandInfo.code,
                            import: commandInfo.import,
                            constantType: commandInfo.constantType,
                            init: commandInfo.init
                        }
                        commandSetEduAndEntry.push(command);
                        this.createAutoCompletionMap(commandInfo, BRAIN_TYPE.EDU_AND_ENTRY);
                    }
                }
            });

            if (commandSet.length > 0) {
                let categoryCommandSet = {
                    tag: categoryId,
                    name: messages[categoryId],
                    commandSet: commandSet
                }
                commandListEntry.push(categoryCommandSet);
            }
            if (commandSetEduAndEntry.length > 0) {
                let categoryCommandSet = {
                    tag: categoryId,
                    name: messages[categoryId],
                    commandSet: commandSetEduAndEntry
                }
                commandListEduAndEntry.push(categoryCommandSet);
            }
        })
    }

    createAutoCompletionMap(commandInfo, type) {
        let codeSplit = commandInfo.code.split('.');
        if (codeSplit.length > 1) {
            let startChar = codeSplit[0].charAt(0);
            if (startChar == startChar.toUpperCase()) {
                // is class
                let blockClass = codeSplit[0];
                let blockMethod = codeSplit[1].split('(')[0];
                let info = {
                    function: blockMethod,
                    constantType: commandInfo.constantType
                }
                let functionInfos = [];
                if (type == BRAIN_TYPE.ENTRY) {
                    if (autoCompletionMapEntry.has(blockClass)) {
                        functionInfos = autoCompletionMapEntry.get(blockClass);
                    } else {
                        BLOCK_CLASSES_ENTRY.push(blockClass);
                    }
                } else {
                    if (autoCompletionMapEduAndEntry.has(blockClass)) {
                        functionInfos = autoCompletionMapEduAndEntry.get(blockClass);
                    } else {
                        BLOCK_CLASSES_EDU_AND_ENTRY.push(blockClass);
                    }
                }
                functionInfos.push(info);
                if (type == BRAIN_TYPE.ENTRY) {
                    autoCompletionMapEntry.set(blockClass, functionInfos);
                    BLOCK_METHODS_ENTRY.push(blockMethod);
                } else {
                    autoCompletionMapEduAndEntry.set(blockClass, functionInfos);
                    BLOCK_METHODS_EDU_AND_ENTRY.push(blockMethod);
                }
            } else {
                // method random.randint
            }
        } else {
            // condition
            let firstChar = commandInfo.code.charAt(0);
            if (firstChar.match(/[a-z]/i)) {
                let selections = [['True', 'False', 'repeat_count'], ['range(10)']];
                let insertText = commandInfo.code;
                Object.entries(selections).forEach((selection) => {
                    for (let replace in selection[1]) {
                        insertText = insertText.replace(selection[1][replace], '${' + (selection[0] + 1) + ':' + selection[1][replace] + '}');
                    }
                });
                let info = {
                    label: commandInfo.name,
                    insertText: insertText,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    kind: monaco.languages.CompletionItemKind.Snippet,
                }
                if (type == BRAIN_TYPE.ENTRY) {
                    BLOCK_CONDITIONS_ENTRY.push(info);
                } else {
                    BLOCK_CONDITIONS_EDU_AND_ENTRY.push(info);
                }
            }
        }
    }

    onChange(newValue) {
        // console.log("onChange setPythonCode:\n" + newValue); // eslint-disable-line no-console
        this.props.vm.setPythonCode(newValue);
        this.handleModelContentChange();
        if (editor) {
            this.createAutoCompleteStack();
        }
    }
    createAutoCompleteStack() {
        let pos = editor.getPosition();

        // backspace to last line
        if (pos.lineNumber > editor.getModel().getLineCount()) {
            return;
        }

        let lineContent = editor.getModel().getLineContent(pos.lineNumber);
        let curPosCol = pos.column;

        // backspace
        if (curPosCol > lineContent.length) {
            return;
        }

        // auto complete or paste
        if (lineContent.length > preLineLength + 1) {
            if (lineContent.charAt(lineContent.length - 1) != ')') {
                curPosCol = lineContent.length;
            }
        }

        autoCompletionStack.clear();
        for (let i = 0; i < curPosCol; ++i) {
            let c = lineContent.charAt(i);
            if (c == ')') {
                while (!autoCompletionStack.isEmpty() && autoCompletionStack.pop() != '(');
                while (!autoCompletionStack.isEmpty() && autoCompletionStack.peek() != '(' && autoCompletionStack.peek() != ',') {
                    autoCompletionStack.pop();
                };
            } else {
                autoCompletionStack.push(c);
            }
        }
        preLineLength = lineContent.length;
        // autoCompletionStack.print();
    }

    handleSoundListUpdate() {
        let { editingTarget: target } = this.props.vm;
        const targetSounds = target.getSounds();
        this.setState({
            mp3_files: targetSounds.map((item) => (
                `"${item.name}` + `.${item.dataFormat}"`
            ))
        });
    }

    handleModelContentChange() {
        // editor.trigger('', 'undo');
        // editor.trigger('', 'redo');
        const versionId = editor.getModel().getAlternativeVersionId();
        // undoing
        if (versionId < this.currentVersion) {
            this.props.setRedoState(true);
            // no more undo possible
            if (versionId === this.initialVersion) {
                this.props.setUndoState(false);
            }
        } else {
            // redoing
            if (versionId <= this.lastVersion) {
                // redoing the last change
                if (versionId == this.lastVersion) {
                    this.props.setRedoState(false);
                }
            } else { // adding new change, disable redo when adding new changes
                this.props.setRedoState(false);
                if (this.currentVersion > this.lastVersion) {
                    this.lastVersion = this.currentVersion;
                }
            }
            this.props.setUndoState(true);
        }
        this.currentVersion = versionId;
    }

    editorDidMount(e, m) {
        // eslint-disable-next-line no-console
        console.log("editorDidMount editor = ", m.editor.getModels());
        editor = e;
        this.monaco = m;
        this.props.setEditor(editor);
        this.initialVersion = editor.getModel().getAlternativeVersionId();
        this.currentVersion = this.initialVersion;
        this.lastVersion = this.initialVersion;
        editor.onMouseMove((e) => {
            this.sliderMove = true;
            if (drag != "") {
                if (e.target.position) {
                    editor.setSelection(new monaco.Selection(
                        e.target.position.lineNumber,
                        e.target.position.column,
                        e.target.position.lineNumber,
                        e.target.position.column));
                    editor.focus();
                }
            }
        });
        editor.onMouseUp((e) => {
            if (isPad()) {
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }
                if (!this.sliderMove) {
                    editor.focus();
                }
            }
            this.sliderMove = false;
            if (!e.event.rightButton) {
                if (drag != "") {
                    editor.executeEdits("",
                        [
                            {
                                range: new monaco.Range(
                                    e.target.position.lineNumber,
                                    e.target.position.column,
                                    e.target.position.lineNumber,
                                    e.target.position.column),
                                text: textWithIndent(e, drag)
                            }
                        ]
                    );
                }
            }

            function getIndent(e) {
                let pivot = "";
                if (e.target.position.lineNumber > 1) {
                    let preLine = e.target.position.lineNumber - 2;
                    let code = editor.getValue();
                    let lines = code.split('\n');
                    let line = lines[preLine];
                    if (line.trim().length > 0 && line.trim()[line.trim().length - 1] == ':') {
                        pivot += '    ';
                    }
                    for (let c in line) {
                        if (line[c] != ' ' && line[c] != '\t') {
                            return pivot;
                        }
                        pivot += line[c];
                    }
                }
                return pivot;
            }

            function textWithIndent(e, drag) {
                let pivot = getIndent(e);
                let commandLines = drag.split("\n")
                let result = "";
                for (let command in commandLines) {
                    if (command == 0 && e.target.position.column != 1) {
                        result += commandLines[command] + '\n';
                    } else {
                        result += pivot + commandLines[command] + '\n';
                    }
                }
                return result.replace('\t', '    ');
            }
        });
    }

    getConstantMembers(constantType, splitCurParam) {
        let completion = [];
        let params = BLOCK_CONSTANTS_MAP.get(constantType);
        if (params) {
            for (let param in params) {
                if (params[param].toLowerCase().includes(splitCurParam[splitCurParam.length - 1].toLowerCase())) {
                    completion.push({
                        label: params[param],
                        kind: monaco.languages.CompletionItemKind.EnumMember,
                        insertText: params[param]
                    })
                }
            }
        }
        return completion;
    }

    getMethodParameterCompletion(functionInfo, parameter) {
        let completion = [];
        let parameters = parameter.split(',');
        let paramIndex = parameters.length - 1;
        let curParam = parameters[paramIndex].trim();
        let paramConstantType = functionInfo.constantType[paramIndex];
        if (paramConstantType) {
            let splitCurParam = curParam.split('.');
            if (splitCurParam.length > 1) {
                if (splitCurParam[0] == paramConstantType) {
                    completion = this.getConstantMembers(paramConstantType, splitCurParam);
                }
            } else {
                if (paramConstantType == "MP3_FILE") {
                    // case for MP3_FILE constant type, only complete EnumMember
                    completion = this.getConstantMembers(paramConstantType, splitCurParam);
                } else {
                    let insertTextString = ''
                    if (paramConstantType.charAt(0) == paramConstantType.charAt(0).toUpperCase()) {
                        insertTextString = paramConstantType;
                    }
                    completion.push({
                        label: paramConstantType,
                        kind: monaco.languages.CompletionItemKind.ENUM,
                        insertText: insertTextString
                    })
                }
            }
        }
        return completion;
    }

    getClassCompletion(key) {
        let completion = [];
        for (let blockClass in BLOCK_CLASSES) {
            if (BLOCK_CLASSES[blockClass].toLowerCase().includes(key.toLowerCase())) {
                completion.push({
                    label: BLOCK_CLASSES[blockClass],
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertText: BLOCK_CLASSES[blockClass],
                })
            }
        }
        return completion;
    }

    getConditionCompletion(key) {
        let completion = [];
        let regularKey = key.replace(/[^a-zA-Z]/, '');
        if (regularKey === "") {
            return completion;
        }
        for (let condition in BLOCK_CONDITIONS) {
            if (BLOCK_CONDITIONS[condition].label.toLowerCase().includes(key.toLowerCase())) {
                let info = {
                    label: BLOCK_CONDITIONS[condition].label,
                    kind: BLOCK_CONDITIONS[condition].kind,
                    insertText: BLOCK_CONDITIONS[condition].insertText,
                    insertTextRules: BLOCK_CONDITIONS[condition].insertTextRules
                }
                completion.push(info);
            }
        }
        return completion;
    }

    createCompletion(word) {
        var completion = [];
        let isFindMethodParameter = false;
        let wordSplit = word.split('(');
        let parameter = "";
        if (wordSplit.length > 1) {
            if (wordSplit[0].split('.').length > 1) {
                isFindMethodParameter = true;
                word = wordSplit[wordSplit.length - 2];
                parameter = wordSplit[wordSplit.length - 1];
            }
        }

        if (word === "") {
            return completion;
        }

        let keys = word.split('.');
        let key = keys[0];
        if (keys.length > 1) { // suggest function
            if (autoCompletionMap.has(key)) {
                let blockFunctions = autoCompletionMap.get(key);
                for (let fun in blockFunctions) {
                    if (isFindMethodParameter) {
                        if (blockFunctions[fun].function == keys[1]) {
                            // get function parameter suggest
                            completion = this.getMethodParameterCompletion(blockFunctions[fun], parameter);
                            return completion;
                        }
                    } else {
                        completion.push({
                            label: blockFunctions[fun].function + '(' + blockFunctions[fun].constantType + ')',
                            kind: monaco.languages.CompletionItemKind.Method,
                            insertText: blockFunctions[fun].function + (blockFunctions[fun].constantType.length == 0 ? '()' : '('),
                            command: blockFunctions[fun].constantType.length == 0 ? null : { id: 'editor.action.triggerSuggest' },
                            // additionalTextEdits: [
                            //     {
                            //         range: {
                            //             startLineNumber: 3,
                            //             startColumn: 1,
                            //             endLineNumber: 3,
                            //             endColumn: 1
                            //         },
                            //         text: "use Symfony\\Component\\Validator\\Validator\\RecursiveContextualValidator;\n\n",
                            //         // forceMoveMarkers: true
                            //     }
                            // ],
                        })
                    }
                }
            }
        } else { // suggest class and condition
            if (!isFindMethodParameter) {
                completion = this.getClassCompletion(key);
                completion = completion.concat(this.getConditionCompletion(key));
            }
        }
        return completion;
    }

    editorWillMount(monaco) {
        if (editor) { // do not config monaco multiple times
            return;
        }

        monaco.editor.defineTheme('myCustomTheme', theme);
        monaco.languages.register({ id: LANGUAGE_ID });
        monaco.languages.setLanguageConfiguration(LANGUAGE_ID, conf);
        BLOCK_CLASSES = BLOCK_CLASSES_ENTRY;
        BLOCK_METHODS = BLOCK_METHODS_ENTRY;
        BLOCK_CONDITIONS = BLOCK_CONDITIONS_ENTRY;
        autoCompletionMap = autoCompletionMapEntry;
        language.blockClasses = BLOCK_CLASSES;
        language.blockMethods = BLOCK_METHODS;
        language.blockConstants = BLOCK_CONSTANTS;
        language.blockConstantValues = BLOCK_CONSTANT_VALUES;
        monaco.languages.setMonarchTokensProvider(LANGUAGE_ID, language);
        monaco.languages.registerCompletionItemProvider(LANGUAGE_ID, {
            triggerCharacters: ['.', '(', ','],
            provideCompletionItems: (model, position) => {
                let contentString = autoCompletionStack.getContentAsString();
                let contents = contentString.split(/[);]/);
                let key = contents[contents.length - 1].trim();
                let completion = this.createCompletion(key);
                return {
                    suggestions: completion
                }
            },
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.displayMode == EDIT_MODE.PYTHON_MODE && this.state.first) {
            console.log("first editor re-layout");
            editor.layout();
            this.setState({
                first: false
            });
        }
        if (prevProps.getNewEditorState != this.props.getNewEditorState && this.props.getNewEditorState) {
            this.initialVersion = editor.getModel().getAlternativeVersionId();
            this.currentVersion = this.initialVersion;
            this.lastVersion = this.initialVersion;
            this.props.setNewEditor(false);
        }
        if (prevProps.pickBrainType != this.props.pickBrainType) {
            if (this.props.pickBrainType == BRAIN_TYPE.ENTRY) {
                BLOCK_CLASSES = BLOCK_CLASSES_ENTRY;
                BLOCK_METHODS = BLOCK_METHODS_ENTRY;
                BLOCK_CONDITIONS = BLOCK_CONDITIONS_ENTRY;
                autoCompletionMap = autoCompletionMapEntry;
                language.blockClasses = BLOCK_CLASSES;
                language.blockMethods = BLOCK_METHODS;
                language.blockConstants = BLOCK_CONSTANTS;
                language.blockConstantValues = BLOCK_CONSTANT_VALUES;
                this.monaco.languages.setMonarchTokensProvider(LANGUAGE_ID, language);
            } else if (this.props.pickBrainType == BRAIN_TYPE.EDU_AND_ENTRY) {
                BLOCK_CLASSES = BLOCK_CLASSES_EDU_AND_ENTRY;
                BLOCK_METHODS = BLOCK_METHODS_EDU_AND_ENTRY;
                BLOCK_CONDITIONS = BLOCK_CONDITIONS_EDU_AND_ENTRY;
                autoCompletionMap = autoCompletionMapEduAndEntry;
                language.blockClasses = BLOCK_CLASSES;
                language.blockMethods = BLOCK_METHODS;
                language.blockConstants = BLOCK_CONSTANTS;
                language.blockConstantValues = BLOCK_CONSTANT_VALUES;
                this.monaco.languages.setMonarchTokensProvider(LANGUAGE_ID, language);
            }
        }
        if (this.state.mp3_files != prevState.mp3_files) {
            BLOCK_CONSTANTS_MAP.set("MP3_FILE", this.state.mp3_files);
        }
        if (this.state.toolboxCatagoryUpdate) {
            this.setState({
                toolboxCatagoryUpdate: false
            });
        }
    }


    componentDidMount() {
        document.addEventListener('mouseup', function (e) {
            drag = "";
        })
        this.commadBodyRefs.addEventListener('scroll', function (e) {
            console.log("scroll e = ", e);
        })
    }

    componentWillUnmount() {
        this.props.vm.removeListener('soundListUpdate', this.handleSoundListUpdate);
    }

    getCode(e) {
        console.log("getCode = ", e.target.id);
        drag = e.target.id;

        if (isPad()) {
            function getIndent() {
                let pivot = "";
                if (editor.getPosition().lineNumber > 1) {
                    let preLine = editor.getPosition().lineNumber - 2;
                    let code = editor.getValue();
                    let lines = code.split('\n');
                    let line = lines[preLine];
                    if (line.trim().length > 0 && line.trim()[line.trim().length - 1] == ':') {
                        pivot += '    ';
                    }
                    for (let c in line) {
                        if (line[c] != ' ' && line[c] != '\t') {
                            return pivot;
                        }
                        pivot += line[c];
                    }
                }
                return pivot;
            }

            function textWithIndent(drag) {
                let pivot = getIndent();
                let commandLines = drag.split("\n")
                let result = "";
                for (let command in commandLines) {
                    if (command == 0 && editor.getPosition().column != 1) {
                        result += commandLines[command] + '\n';
                    } else {
                        result += pivot + commandLines[command] + '\n';
                    }
                }
                return result.replace('\t', '    ');
            }

            e.preventDefault(); // <-- that should not be used in passive
            editor.executeEdits("",
                [
                    {
                        range: new monaco.Range(
                            editor.getPosition().lineNumber,
                            editor.getPosition().column,
                            editor.getPosition().lineNumber,
                            editor.getPosition().column),
                        text: textWithIndent(drag)
                    }
                ]
            );
        }
    }

    handleFontZoom(isIn) {
        if (isIn) {
            // editor.trigger('keyboard', 'editor.action.fontZoomIn');
            editor.updateOptions({ fontSize: this.state.zoomLevel + 1 })
            this.setState({
                zoomLevel: (this.state.zoomLevel + 1)
            });
        } else {
            editor.updateOptions({ fontSize: this.state.zoomLevel - 1 })
            // editor.trigger('keyboard', 'editor.action.fontZoomOut');
            this.setState({
                zoomLevel: (this.state.zoomLevel - 1)
            });
        }
    }

    handleClickCatagory(index) {
        this.setState({
            selected: index
        });
        this.commandCatagoryRefs[index].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    renderCatagory() {
        if (this.props.getUIStyle == uiType.vr) {
            return null;
        }

        const catagoryArray = {
            "cn": [
                {
                    "name": messages.motion,
                    "color": "linear-gradient(to bottom, #D3DA67, #89B534)",
                    "categoryId": 'motion'
                },
                {
                    "name": messages.drivetrain,
                    "color": "linear-gradient(to bottom, #2EEA81, #04A24D)",
                    "categoryId": 'drivetrain'
                },
                {
                    "name": messages.looks,
                    "color": "linear-gradient(to bottom, #64DFFF, #398BFC)",
                    "categoryId": 'looks'
                },
                {
                    "name": messages.sound,
                    "color": "linear-gradient(to bottom, #60F0F1, #038081)",
                    "categoryId": 'sound'
                },
                {
                    "name": messages.events,
                    "color": "linear-gradient(to bottom, #71D2FF, #0859B1)",
                    "categoryId": 'events'
                },
                {
                    "name": messages.control,
                    "color": "linear-gradient(to bottom, #FDCF44, #F29606)",
                    "categoryId": 'control'
                },
                {
                    "name": messages.sensing,
                    "color": "linear-gradient(to bottom, #F4AE7E, #EE8046)",
                    "categoryId": 'sensing'
                },
                {
                    "name": messages.operators,
                    "color": "linear-gradient(to bottom, #DDB89B, #C09471)",
                    "categoryId": 'operators'
                },
                {
                    "name": messages.variables,
                    "color": "linear-gradient(to bottom, #D29CFF, #AB29FB)",
                    "categoryId": 'variables'
                },
                {
                    "name": messages.aispeech,
                    "color": "linear-gradient(to bottom, #FFB1D3, #E65897)",
                    "categoryId": 'aiSpeech'
                },
                {
                    "name": messages.comment,
                    "color": "linear-gradient(to bottom, #C5C6FA, #6775C4)",
                    "categoryId": 'comments'
                }
            ],
            "ww": [
                {
                    "name": messages.motion,
                    "color": "linear-gradient(to bottom, #C9FF70, #C9FF70)",
                    "categoryId": 'motion'
                },
                {
                    "name": messages.drivetrain,
                    "color": "linear-gradient(to bottom, #96F1B6, #96F1B6)",
                    "categoryId": 'drivetrain'
                },
                {
                    "name": messages.looks,
                    "color": "linear-gradient(to bottom, #88C9FF, #88C9FF)",
                    "categoryId": 'looks'
                },
                {
                    "name": messages.sound,
                    "color": "linear-gradient(to bottom, #C1F6FF, #C1F6FF)",
                    "categoryId": 'sound'
                },
                {
                    "name": messages.events,
                    "color": "linear-gradient(to bottom, #53E8F9, #53E8F9)",
                    "categoryId": 'events'
                },
                {
                    "name": messages.control,
                    "color": "linear-gradient(to bottom, #FFDD9A, #FFDD9A)",
                    "categoryId": 'control'
                },
                {
                    "name": messages.sensing,
                    "color": "linear-gradient(to bottom, #FCF6A2, #FCF6A2)",
                    "categoryId": 'sensing'
                },
                {
                    "name": messages.operators,
                    "color": "linear-gradient(to bottom, #DFB696, #DFB696)",
                    "categoryId": 'operators'
                },
                {
                    "name": messages.variables,
                    "color": "linear-gradient(to bottom, #C187F6, #C187F6)",
                    "categoryId": 'variables'
                },
                {
                    "name": messages.aispeech,
                    "color": "linear-gradient(to bottom, #f9c5b7, #f9c5b7)",
                    "categoryId": 'aiSpeech'
                },
                {
                    "name": messages.comment,
                    "color": "linear-gradient(to bottom, #C2BEFF, #C2BEFF)",
                    "categoryId": 'comments'
                }
            ]
        };

        if (this.props.pickBrainType == BRAIN_TYPE.ENTRY) {
            catagoryArray["cn"].push({
                "name": messages.extensions,
                "color": "linear-gradient(to bottom, #FE8982, #D14641)",
                "categoryId": 'extensions'
            });
            catagoryArray["ww"].push({
                "name": messages.extensions,
                "color": "linear-gradient(to bottom, #ffa6cb, #ffa6cb)",
                "categoryId": 'extensions'
            });
        }

        return (
            <div className={classNames(styles.catagoryBar, 'blocklyToolboxDiv')}>
                <div className={classNames(
                    "scratchCategoryMenu scratchCategoryMenuPic",
                    styles.scratchCategoryMenu
                )}>
                    {
                        catagoryArray[this.props.getUIStyle].map((catagoryProps, id) => {
                            return (
                                <div key={id} className={classNames(
                                    "scratchCategoryMenuRow",
                                    (this.toolboxCatagoryRefs[id] && this.toolboxCatagoryRefs[id].show) ? "scratchCategoryMenuRowShow" : "scratchCategoryMenuRowHidden"
                                )} ref={(category) => this.setCategoryRef(id, category)}>
                                    <div key={id}
                                        className={classNames(
                                            "scratchCategoryMenuItem",
                                            "scratchCategoryId-" + catagoryProps.categoryId,
                                            this.state.selected == id ? 'categorySelected' : 'categoryUnselected',
                                            styles.catagoryItem
                                        )}
                                        onClick={() => this.handleClickCatagory(id)}
                                    >
                                        <div className="scratchCategoryMenuInner">
                                            <div className="scratchCategoryItemBubble" />
                                            <div className="scratchCategoryMenuItemLabel">
                                                <FormattedMessage {...catagoryProps.name} ></FormattedMessage>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className={classNames(
                    "scratchCategoryMenuUpButton",
                    "scratchCategoryMenuButtonPosition",
                    this.isCategoryBoundary(-1) ? "scratchCategoryMenuButtonBoundary" : null
                )} onClick={() => this.handleClickCatagoryArrow(-1)}>
                    <img src="./static/blocks-media/block_menu_up_triangle.svg" />
                </div>
                <div className={classNames(
                    "scratchCategoryMenuDownButton",
                    "scratchCategoryMenuButtonPosition",
                    this.isCategoryBoundary(1) ? "scratchCategoryMenuButtonBoundary" : null
                )} onClick={() => this.handleClickCatagoryArrow(1)}>
                    <img src="./static/blocks-media/block_menu_down_triangle.svg" />
                </div>
            </div>
        )
    }

    handleClickCatagoryArrow(next) {
        if (!this.isCategoryBoundary(next)) {
            this.catagoryPositionIndex += next;
            this.toolboxCatagoryRefs.forEach((category, id) => {
                category.show = false;
                if (id >= this.catagoryPositionIndex && id < this.catagoryPositionIndex + ScratchBlocks.Toolbox.MIN_SCREEN_CATEGORY_LENGTH) {
                    category.show = true;
                }
            })
            this.setState({
                toolboxCatagoryUpdate: true
            });
        }
    }

    isCategoryBoundary(next) {
        let index = this.catagoryPositionIndex + next;
        if (index < 0) {
            return true;
        }
        let currentLength = index + ScratchBlocks.Toolbox.MIN_SCREEN_CATEGORY_LENGTH;
        if (currentLength == (this.toolboxCatagoryRefs.length + 1)) {
            return true;
        }
        return false;
    }

    setCategoryRef(id, category) {
        this.toolboxCatagoryRefs[id] = category;
        if (this.toolboxCatagoryRefs[id] && typeof (this.toolboxCatagoryRefs[id].show) == "undefined") {
            if (id < ScratchBlocks.Toolbox.MIN_SCREEN_CATEGORY_LENGTH) {
                this.toolboxCatagoryRefs[id].show = true;
            } else {
                this.toolboxCatagoryRefs[id].show = false;
            }
        }
    }

    renderCommandSet() {
        commandList = this.props.pickBrainType == BRAIN_TYPE.EDU_AND_ENTRY ? commandListEduAndEntry : commandListEntry;
        return (
            <div ref={el => (this.commadBodyRefs = el)} className={styles.commandBody}>
                {
                    commandList.map((commandProps, id) => {
                        return (
                            <div key={id} ref={el => (this.commandCatagoryRefs[id] = el)} className={styles.commandCatagory}>
                                <div className={styles.commandTitle}>
                                    <FormattedMessage {...commandProps.name} ></FormattedMessage>
                                </div>
                                {
                                    (commandProps.tag == "sound") ?
                                        this.renderCommandSoundButton() : null
                                }
                                {
                                    commandProps.commandSet.map((command, id) => {
                                        return (
                                            <div
                                                key={`command-${id}`}
                                                className={styles.commandItem}
                                                onMouseDown={this.getCode}
                                            >
                                                <div className={styles.commandName} id={command.code}>
                                                    {command.name}
                                                </div>
                                                <div className={styles.commandDivider} />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
        )
    }

    renderCommandSoundButton() {
        let { editingTarget: target, runtime } = this.props.vm;
        const stage = runtime.getTargetForStage();
        if (!target) target = stage; // If no editingTarget, use the stage
        let sounds = [];
        if (target) {
            sounds = target.getSounds();
        }
        return (
            <div className={styles.commandButtonGroup}>
                <div className={styles.commandButton} onClick={() => this.handleClickImportSound()}>
                    <FormattedMessage id={"gui.codeEditor.commandButton.importAudio"} ></FormattedMessage>
                </div>
                {sounds && sounds.length > 0 ?
                    <div className={styles.commandButton} onClick={() => this.handleClickManageSound()}>
                        <FormattedMessage id={"gui.codeEditor.commandButton.audioManagement"} ></FormattedMessage>
                    </div> : ''}
            </div>
        )
    }

    handleClickImportSound() {
        this.props.getWorkspace.importFile(ScratchBlocks.addSound)
    }

    handleClickManageSound() {
        ScratchBlocks.handleAudioManagementStart(() => { }, editBlockType.sound_audio)
    }

    clickMenuItem(e, data) {
        switch (data.command) {
            case 'copy':
                editor.trigger('source', 'editor.action.clipboardCopyAction');
                break;
            case 'paste':
                navigator.clipboard.readText().then(function (text) {
                    let selection = editor.getSelection();
                    editor.executeEdits('', [{
                        range: new monaco.Range(selection.startLineNumber, selection.startColumn, selection.endLineNumber, selection.endColumn),
                        text: text
                    }])
                });
                break;
        }
    }

    render() {
        const {
            themeName
        } = this.state;

        const options = {
            selectOnLineNumbers: true,
            roundedSelection: false,
            readOnly: false,
            cursorStyle: "line",
            automaticLayout: true,
            autoIndent: true,
            formatOnPaste: true,
            formatOnType: true,
            contextmenu: false,
            minimap: {
                enabled: false
            },
        };

        return (
            <div className={classNames(styles.codeEditorBody, this.props.displayMode == EDIT_MODE.BLOCK_MODE ? styles.hide : null)}>
                {this.renderCatagory()}
                {this.renderCommandSet()}

                <ContextMenuTrigger id="codeEditorContextMenu" holdToDisplay={1000}>
                    <div className={classNames(styles.editorBody, styles.font)}>
                        <MonacoEditor
                            language={LANGUAGE_ID}
                            value={this.props.vm.getPythonCode()}
                            options={options}
                            onChange={this.onChange}
                            editorDidMount={this.editorDidMount}
                            editorWillMount={this.editorWillMount}
                            theme={themeName}
                        />
                        <div className={styles.zoomBody}>
                            <img
                                src={this.props.getUIStyle == uiType.ww ? zoomInIconWW : zoomInIcon}
                                className={this.state.zoomLevel == 25 ? styles.zoomDisable : styles.zoomEnable}
                                onClick={() => {
                                    this.handleFontZoom(true)
                                }}
                                alt={"zoom in"}
                            />
                            <img
                                className={
                                    classNames(
                                        styles.zoomBetween,
                                        this.state.zoomLevel == 6 ? styles.zoomDisable : styles.zoomEnable
                                    )
                                }
                                src={this.props.getUIStyle == uiType.ww ? zoomOutIconWW : zoomOutIcon}
                                onClick={() => { this.handleFontZoom(false) }}
                                alt={"zoom out"}
                            />
                            <img
                                src={asusLogoIcon}
                                alt={"asus logo"}
                            />
                        </div>
                    </div>
                </ContextMenuTrigger>

                <ContextMenu id="codeEditorContextMenu" className={styles.contextMenu} >
                    <MenuItem data={{ command: 'copy' }} onClick={this.clickMenuItem} attributes={menuItemAttributes}>
                        <FormattedMessage id="gui.contextmenu.copy" />
                    </MenuItem>
                    <MenuItem data={{ command: 'paste' }} onClick={this.clickMenuItem} attributes={menuItemAttributes}>
                        <FormattedMessage id="gui.contextmenu.paste" />
                    </MenuItem>
                </ContextMenu>
            </div>
        );
    }

}

CodeEditor.propTypes = {
    displayMode: PropTypes.string,
    vm: PropTypes.object,
    isShowingProject: PropTypes.bool,
    setEditor: PropTypes.func,
    setNewEditor: PropTypes.func,
    setUndoState: PropTypes.func,
    setRedoState: PropTypes.func,
    getNewEditorState: PropTypes.bool,
    getWorkspace: PropTypes.object,
    getUIStyle: PropTypes.string,
};


const mapStateToProps = state => ({
    isShowingProject: getIsShowingProject(state.scratchGui.projectState.loadingState),
    getNewEditorState: getNewEditorState(state),
    pickBrainType: getPickedBrainType(state),
    getWorkspace: getWorkspace(state),
    getUIStyle: getUIStyle(state),
});

const mapDispatchToProps = dispatch => ({
    setEditor: editor => dispatch(setEditor(editor)),
    setNewEditor: state => dispatch(setNewEditor(state)),
    setUndoState: state => dispatch(setUndoState(state)),
    setRedoState: state => dispatch(setRedoState(state))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CodeEditor);