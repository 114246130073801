// external shadow blocks will deprecated when parent is transferred
// json style in list example:
/*
{
    opcode: 'control_wait',
    tansfer_opcode: 'event_whenbrainbutton',
    inputs: {},
    fields: {
        BUTTONNAME: { name: 'BUTTONNAME', id: undefined, value: 'up' },
        PRESSED: { name: 'PRESSED', id: undefined, value: 'pressed' }
    }
}
*/
const mappingBlocksList = [

]

const getDeprecatedBlocksList = () => {
    return mappingBlocksList.map((item) => {
        return item.opcode;
    });
}

const getMappingBlocksList = () => {
    return mappingBlocksList;
}

export {
    getDeprecatedBlocksList,
    getMappingBlocksList
};