import classNames from 'classnames';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './dialog.css';
import { FormattedMessage } from 'react-intl';
import store from 'store';
import { parseReleaseNoteJson } from './utilities';

class UpdateDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleUpdateClick',
            'ref'
        ]);
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
    }
    componentWillUnmount() {
    }

    handleUpdateClick() {
        this.props.onClose();
        this.props.onUpdate();
    }

    getContent() {
        let releaseNoteObj = [];
        let item = null;
        let versionObj = null;
        let releaseNotes = parseReleaseNoteJson(this.props.locale);
        if (releaseNotes && releaseNotes.length > 0) {
            versionObj = (
                <tr className={styles.aboutContentTrTitle}>
                    <td colspan="4">{releaseNotes[0]['version']}</td>
                </tr>);
            releaseNoteObj.push(versionObj);
            if (releaseNotes[0]['data'][this.props.locale] && releaseNotes[0]['data'][this.props.locale].length > 0) {
                releaseNotes[0]['data'][this.props.locale].forEach((paragraph) => {
                    let content = [];
                    paragraph.content.forEach((word) => {
                        if (word.length > 0) {
                            item = (
                                <tr className={styles.aboutContentTr}>
                                    <td width="5%"></td>
                                    {(paragraph.title.length > 0) ? <td width="5%"></td> : null}
                                    <td width="1%" className={styles.aboutContentTd}>．</td>
                                    {(paragraph.title.length > 0) ?
                                        <td>{word}</td> :
                                        <td colspan="2">{word}</td>
                                    }
                                </tr>);
                            content.push(item);
                        }
                    })
                    if (paragraph.title.length > 0) {
                        item = (
                            <tr className={styles.aboutContentTrTitle}>
                                <td width="5%"></td>
                                <td colspan="3">{paragraph.title}</td>
                            </tr>);
                        releaseNoteObj.push(item);
                    }
                    releaseNoteObj.push(content);
                })
            }
        }
        return (
            <table className={styles.whatsNewContentTable}>
                <tbody>
                    {releaseNoteObj}
                </tbody>
            </table>
        );
    }

    ref(c) {
        this.dialog = c;
    }
    render() {
        return (
            <div className={classNames(styles.backdropStyle, styles.update)}>
                <div className={styles.modalStyle} ref={this.ref}>
                    <div className={styles.aboutHeader}>
                        <div className={styles.updateTitle}>
                            <FormattedMessage
                                defaultMessage="Update Available"
                                description="update dialog title"
                                id="gui.dialog.update.title"
                            />
                        </div>
                    </div>
                    <div className={styles.updateContent}>
                        <div>
                            <FormattedMessage
                                defaultMessage="Current Version: "
                                description="update dialog current version"
                                id="gui.dialog.update.current"
                            />
                            {store.get("currentVersion")}<br />
                        </div>
                        <div>
                            <FormattedMessage
                                defaultMessage="Available Version: "
                                description="update dialog available version"
                                id="gui.dialog.update.available"
                            />
                            {store.get("availableVersion")}
                        </div>
                    </div>
                    <div className={styles.updateContentWord}>
                        {this.getContent()}
                    </div>
                    <div className={styles.updateFooter}>
                        {
                            store.get("SoftwareForceUpdate", false) ? null : (
                                <div className={styles.updateSkipBtn} onClick={this.props.onClose}>
                                    <FormattedMessage
                                        defaultMessage="Skip"
                                        description="update dialog skip button"
                                        id="gui.dialog.update.skip"
                                    />
                                </div>
                            )
                        }
                        <div className={styles.updateUpdateBtn} onClick={this.handleUpdateClick}>
                            <FormattedMessage
                                defaultMessage="Update Immediately"
                                description="update dialog update button"
                                id="gui.dialog.update.update"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

UpdateDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    show: PropTypes.bool,
    locale: PropTypes.string
};

UpdateDialog.defaultProps = {
    onUpdate: () => { }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.locales.locale
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateDialog);