const extensionsBlocks = (function () {
    const extensionsWifiSettingNotificationHighestTempature = (translateMsg) =>
        `<block type="extensions_wifi_setting_notification_highest_tempature">
            <value name="LOCATION">
                <shadow type="text">
                    <field name="TEXT">${translateMsg.taipei}</field>
                </shadow>
            </value>
        </block>`;

    const extensionsWifiSettingNotificationLowestTempature = (translateMsg) =>
        `<block type="extensions_wifi_setting_notification_lowest_tempature">
            <value name="LOCATION">
                <shadow type="text">
                    <field name="TEXT">${translateMsg.taipei}</field>
                </shadow>
            </value>
        </block>`;

    const extensionsWifiSettingNotificationHumidity = (translateMsg) =>
        `<block type="extensions_wifi_setting_notification_humidity">
            <value name="LOCATION">
                <shadow type="text">
                    <field name="TEXT">${translateMsg.taipei}</field>
                </shadow>
            </value>
        </block>`;

    const extensionsWifiSettingNotificationAirQuality = (translateMsg) =>
        `<block type="extensions_wifi_setting_notification_air_quality">
            <value name="LOCATION">
                <shadow type="text">
                    <field name="TEXT">${translateMsg.taipei}</field>
                </shadow>
            </value>
        </block>`;

    const extensionsWifiSettingNotificationCurrentTime = (translateMsg) =>
        `<block type="extensions_wifi_setting_notification_current_time">
            <value name="LOCATION">
                <shadow type="text">
                    <field name="TEXT">${translateMsg.taipei}</field>
                </shadow>
            </value>
        </block>`;

    const extensionsWifiSettingNotificationTranslateTo = (translateMsg) =>
        `<block type="extensions_wifi_setting_notification_translate_to">
            <value name="LOCATION">
                <shadow type="text">
                    <field name="TEXT">${translateMsg.apple}</field>
                </shadow>
            </value>
        </block>`;

    return {
        extensions_wifi_setting_notification_highest_tempature: extensionsWifiSettingNotificationHighestTempature,
        extensions_wifi_setting_notification_lowest_tempature: extensionsWifiSettingNotificationLowestTempature,
        extensions_wifi_setting_notification_humidity: extensionsWifiSettingNotificationHumidity,
        extensions_wifi_setting_notification_air_quality: extensionsWifiSettingNotificationAirQuality,
        extensions_wifi_setting_notification_current_time: extensionsWifiSettingNotificationCurrentTime,
        extensions_wifi_setting_notification_translate_to: extensionsWifiSettingNotificationTranslateTo
    };
})();

export default extensionsBlocks;